import { Suspense } from 'react';
import Calendar from '../../components/Calendar';
import TitleComponent from '../../components/Common/TitleComponent';
//import Footer from '../../components/Layout/Footer';
import Header from '../../components/Layout/Header';

const CalendarPage = (props) => {
  return (
    <>
      <TitleComponent data={{ title: 'Calendar' }} />

      <div className='d-flex flex-column' style={{height:'auto'}}>
        <Suspense fallback='loading'>
          <Header props={props} />
        </Suspense>
        

      </div>
        <div className='flex-fill' >
          <Calendar />
        </div>

    
    </>
  );
};

export default CalendarPage;
