import React, { createContext, useCallback, useContext, useRef, useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

type ConfirmationDialogContextProps = { title: string | React.ReactNode; question: string | React.ReactNode; };

type ConfirmationDialogProps = {
  open: boolean;
  onClose: (...args: any[]) => unknown;
  title: string | React.ReactNode;
  question: string | React.ReactNode;
  onSubmit: (...args: any[]) => unknown;
};

const ConfirmationDialogContext = createContext<
  (props: ConfirmationDialogContextProps) => Promise<boolean>
>(() => Promise.resolve(false));

export const ConfirmationDialog = ({
  open,
  onClose,
  title,
  question,
  onSubmit,
}: ConfirmationDialogProps) => {
  return (
    <Modal isOpen={open} toggle={onClose}>
      <ModalHeader toggle={onClose}>{title}</ModalHeader>
      <ModalBody>
        <div>{question}</div>
      </ModalBody>
      <ModalFooter>
        <button onClick={onClose} className='btn btn-primary'>
          Cancel
        </button>
        <button onClick={onSubmit} className='btn btn-primary'>
          Confirm
        </button>
      </ModalFooter>
    </Modal>
  );
};

type ConfirmationDialogProviderProps = {
  children: React.ReactNode;
};

export const ConfirmationDialogProvider = ({ children }: ConfirmationDialogProviderProps) => {
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState<React.ReactNode>('Confirm your action');
  const [question, setQuestion] = useState<React.ReactNode>('Are you sure to perform this action');

  const confirmed = useRef<any>();

  const confirm = useCallback(
    (p?: ConfirmationDialogContextProps) =>
      new Promise<boolean>((resolve) => {
        setTitle(p?.title || 'Confirm your action');
        setQuestion(p?.question || 'Are you sure to perform this action');
        setOpen(true);
        confirmed.current = (choice: boolean) => {
          resolve(choice);
          setOpen(false);
        };
      }),
    []
  );

  return (
    <ConfirmationDialogContext.Provider value={confirm}>
      {children}
      <ConfirmationDialog
        open={open}
        onClose={() => confirmed.current(false)}
        onSubmit={() => confirmed.current(true)}
        title={title}
        question={question}
      />
    </ConfirmationDialogContext.Provider>
  );
};

export const useConfirm = () => {
  const confirm = useContext(ConfirmationDialogContext);

  if (!confirm) throw new Error('You should use confirm inside ConfirmationDialogProvider');

  return confirm;
};

type ConfirmProps = {
  render: (confirm: ReturnType<typeof useConfirm>) => JSX.Element;
};

/** Use the confirmation dialog in a class component */
export const Confirm = ({ render }: ConfirmProps) => {
  const confirm = useConfirm();

  return render(confirm);
};
