import { Radio, Space } from 'antd';
import { Controller, useFormContext } from 'react-hook-form';
import { DefaultLayout } from '../control-layouts/default-layout';

type FormRadioProps = {
  name: string;
  label: string;
  options: any[];
  getOptionValue?: (option: any) => any;
  getOptionLabel?: (option: any) => any;
  control?: any;
  Layout?: any;
  required?: boolean;
  disabled?: boolean;
  direction?: 'vertical' | 'horizontal';
};

// TODO: move to shared
export const FormRadio = ({
  name,
  label,
  options,
  getOptionValue = (option: any) => option.value,
  getOptionLabel = (option: any) => option.label,
  control,
  Layout = DefaultLayout,
  required = false,
  disabled = false,
  direction = 'vertical',
}: FormRadioProps) => {
  const form = useFormContext();

  if (!control && !form) {
    throw new Error('Either control or useFormContext() is required');
  }

  return (
    <Controller
      rules={{ required: required && `${label} is required` }}
      name={name}
      control={control || form.control}
      render={({ field, fieldState }) => {
        return (
          <Layout
            label={label}
            name={name}
            error={fieldState.error?.message}
            required={required}
            textValue={field.value}
            input={
              <Radio.Group
                disabled={disabled}
                name='radiogroup'
                className='form-control border-0 ps-1'
                value={field.value}
                onChange={field.onChange}
              >
                <Space direction={direction}>
                  {options.map((option: any) => {
                    return (
                      <Radio key={getOptionValue(option)} value={getOptionValue(option)}>
                        {getOptionLabel(option)}
                      </Radio>
                    );
                  })}
                </Space>
              </Radio.Group>
            }
          />
        );
      }}
    />
  );
};
