import * as actionTypes from '../../actions/Admin/actionTypes';
const initState = {
  response: [],
  message: '',
  success: '',

  isPlanList: [],
  isPlanListSuccess: false,

  isPlanAdd: false,
  isPlanAddSuccess: false,
  isPlanAddError: false,

  isPlanDelete: false,
  isPlanDeleteSuccess: false,
  isPlanDeleteError: false,
};

export default (state = initState, action = {}) => {
  switch (action.type) {
    //Add/edit plans
    case actionTypes.CREATE_PLANS_REQUEST:
      return {
        ...state,
        isPlanAdd: false,
        isPlanAddSuccess: false,
        isPlanAddError: false,
        message: '',
        success: '',
      };
    case actionTypes.CREATE_PLANS_SUCCESS:
      return {
        ...state,
        isPlanAdd: action.payload.response.result,
        isPlanAddSuccess: true,
        isPlanAddError: false,
        success: action.payload.response.result.success,
        message: action.payload.response.result.msg
          ? action.payload.response.result.msg
          : 'Record Added successfully',
      };
    case actionTypes.CREATE_PLANS_ERROR:
      return {
        ...state,
        isPlanAdd: false,
        isPlanAddSuccess: false,
        isPlanAddError: true,
        message: '',
        success: '',
      };

    //get all plan list
    case actionTypes.GET_ALL_PLANS_REQUEST:
      return {
        ...state,
        isPlanList: [],
        isPlanListSuccess: false,
        message: '',
        success: '',
      };
    case actionTypes.GET_ALL_PLANS_SUCCESS:
      return {
        ...state,
        isPlanList: action.payload.response.result,
        isPlanListSuccess: true,
        message: action.payload.response.result.success
          ? 'Record Added successfully'
          : action.payload.response.result.msg,
        success: action.payload.response.result.success,
      };
    case actionTypes.GET_ALL_PLANS_ERROR:
      return {
        ...state,
        isPlanList: [],
        isPlanListSuccess: false,
        success: action.payload.error.success,
        message: action.payload.error.msg,
      };

    //Delete plan list
    case actionTypes.DELETE_PLANS_REQUEST:
      return {
        ...state,
        message: '',
        success: '',
        isPlanDelete: false,
        isPlanDeleteSuccess: false,
        isPlanDeleteError: false,
      };
    case actionTypes.DELETE_PLANS_SUCCESS:
      return {
        ...state,
        isPlanDelete: action.payload.response.result,
        isPlanDeleteSuccess: true,
        isPlanDeleteError: false,
        message: action.payload.response.result.success
          ? 'Record Deleted successfully'
          : action.payload.response.result.msg,
        success: action.payload.response.result.success,
      };
    case actionTypes.DELETE_PLANS_ERROR:
      return {
        ...state,
        isPlanDelete: false,
        isPlanDeleteSuccess: false,
        isPlanDeleteError: true,
        success: action.payload.error.success,
        message: action.payload.error.msg,
      };

    default:
      return state;
  }
};
