import { useCallback, useState } from "react";

const useTableSelection = ({ getSelectableRow, getIndex, preparedData }) => {
    const preparedGetSelectableRow = useCallback((row) => getSelectableRow(row), [getSelectableRow]);
  
    const [selectedRows, setSelectedRows] = useState([]);
    const getCheckedRow = useCallback(
      (row) => selectedRows.find((r) => getIndex(row) === getIndex(r)),
      [getIndex, selectedRows]
    );
    const getCheckedAll = useCallback(
      () => preparedData.filter(getSelectableRow).length === selectedRows.length,
      [getSelectableRow, preparedData, selectedRows.length]
    );
  
    const toggleCheck = useCallback(
      (checked, row) => {
        if (!checked)
          return setSelectedRows((rows=[]) => rows.filter((r) => getIndex(row) !== getIndex(r)));
  
        const foundRecord = preparedData.find((r) => getIndex(r) === getIndex(row));
  
        setSelectedRows(((rows) => [...rows, foundRecord]) as any);
      },
      [preparedData]
    );
  
    const toggleCheckAll = useCallback(
      (checked) => {
        if (checked) return setSelectedRows(preparedData.filter(getSelectableRow));
  
        setSelectedRows([]);
      },
      [preparedData, getSelectableRow]
    );
  
    const getSelectedAny = useCallback(() => !!selectedRows.length, [selectedRows.length]);
  
    return {
      getSelectableRow: preparedGetSelectableRow,
      getCheckedRow,
      toggleCheck,
      getCheckedAll,
      toggleCheckAll,
      getSelectedAny,
      selectedRows,
    };
  };

  export default useTableSelection;