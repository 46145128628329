import React from 'react';
import { useCallback, useState, useEffect } from 'react';
import _ from 'lodash';

const SearchComponent = ({ onSearchSubmit }) => {
  const [value, setValue] = useState('');

  const bounce = useCallback(_.debounce(value => onSearchSubmit(value), 500), []);

  useEffect(() => {
    bounce(value);
  }, [value]);

  return (
    <form
      className='form-inline justify-content-end no-print'
      style={{ width: '25vw', marginLeft: 'auto', marginRight: '0px' }}
      onSubmit={(e) => e.preventDefault()}
    >
      <div className='d-flex flex-lg-row align-items-center'>
        <i className='fa fa-search' style={{ marginRight: '8px' }} aria-hidden='true'></i>
        <input
          onChange={(e) => setValue(e.target.value)}
          className='form-control form-control-sm ml-3 w-100'
          type='text'
          placeholder='Search'
          aria-label='Search'
        />
      </div>
    </form>
  );
};

export default SearchComponent;
