import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { getAllEmailAction, deleteEmailAction } from '../../actions/message';
import _ from 'lodash';
import { LightBoxComponent } from './LightBox.component';
import EmailEditor from '../Dialogs/email-editor-modal';
import { EmailView } from './RightSectionRender';
//import EmailPopUp from '../Common/EmailPopUp';

const EmailTab = ({
  location,
  typeId,
  typeRef,
  getAllEmailAction,
  deleteEmailAction,
  allEmails,
  data = {},
}) => {
  const [accountId] = useState(localStorage.getItem('accountId'));
  const [commentList, setCommentList] = useState([]);
  const [showEditEmail, setShowEditEmail] = useState(false);
  const [lightBoxOpen, setLightBoxOpen] = useState(false);
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [attachmentIndex, setAttachmentIndex] = useState(-1);
  const [emailAction, setEmailAction] = useState('new');
  const [emailObjectId, setEmailObjectId] = useState(null);
  const [showEmailDetailModal, setShowEmailDetailModal] = useState(false);
  const [showEmailDetailId, setShowEmailDetailId] = useState(null);
  const [filterText, setFilterText] = useState('');

  useEffect(() => {
    if (location.pathname.includes('email/new')) {
      setShowEditEmail(true);
    } else if (location.pathname.includes('email/view')) {
      const emailId = location.pathname.split('/').pop();
      setShowEmailDetailId(emailId);
      setShowEmailDetailModal(true);
    } else if (location.pathname.includes('email/edit')) {
      const emailId = location.pathname.split('/').pop();
      editDraft({ objectId: emailId });
      setShowEditEmail(true);
    }
    getAllEmails();
  }, [location.pathname, typeId, typeRef]);

  useEffect(() => {
    if (allEmails !== commentList) {
      setCommentList(allEmails);
    }
  }, [allEmails]);

  const formatEmailAttachments = (selectedEmail) => {
    return selectedEmail?.attachments?.map((attachment) => ({
      ...attachment,
      description:
        'Draft Email\n' +
        'to: ' +
        selectedEmail.emailTo?.map((to) => to.value).join(', ') +
        '\n' +
        'sub: ' +
        selectedEmail.emailSub +
        '\n' +
        selectedEmail.emailBody?.replace(/(<([^>]+)>)/gi, ''),
    }));
  };

  const getAllEmails = () => {
    const data = {
      accountId,
      typeId: typeId,
      typeRef: typeRef,
      page: 0,
      displayLimit: 99999999,
    };
    getAllEmailAction(data);
  };

  const cancelDelete = (emailData, confirm) => async () => {
    const confirmed =
      (await confirm?.({
        title: 'Delete draft',
        question: 'Are you sure you want to Delete this draft?',
      })) ?? true;

    if (!confirmed) {
      return;
    }

    const formData = {
      objectId: emailData.objectId,
      typeRef: emailData.typeRef,
      typeId: emailData.typeId,
    };

    await deleteEmailAction(formData);
    getAllEmails();
  };

  const editDraft = (data) => {
    const objectId = data.objectId;
    setEmailObjectId(objectId);

    setEmailAction('EDIT');
    setShowEditEmail(true);
  };

  const copyEmail = (emailData) => {
    const emailObjectId = emailData.objectId;
    setEmailObjectId(emailObjectId);

    setEmailAction('COPY');
    setShowEditEmail(true);
  };

  const forwardEmail = (emailData) => {
    const emailObjectId = emailData.objectId;
    setEmailObjectId(emailObjectId);

    setEmailAction('FORWARD');
    setShowEditEmail(true);
  };

  const replyEmail = (emailData) => {
    const emailObjectId = emailData.objectId;
    setEmailObjectId(emailObjectId);

    setEmailAction('REPLY');
    setShowEditEmail(true);
  };

  const showEditEmailCallback = () => {
    setShowEditEmail(true);
    setEmailObjectId('');
    setEmailAction('new');
  };

  const closeWindowCallback = () => {
    setShowEditEmail(false);
    setEmailObjectId('');
    setEmailAction('new');
  };

  const showHideLightBox = (email, attachmentIndex) => {
    setLightBoxOpen(!lightBoxOpen);
    setSelectedEmail(email);
    setAttachmentIndex(attachmentIndex);
  };

  const closeDetailModal = () => {
    setShowEmailDetailModal(false);
    setShowEmailDetailId(null);
  };

  const sortEmails = (emails) => {
    let rootEmails = emails.filter((email) => !email.replyTo);

    let sortedEmails = rootEmails
      .map((email) => {
        let replies = emails.filter((n) => n.parentEmailId === email.objectId);
        replies.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
        return [email, ...replies];
      })
      .flat();
    return sortedEmails;
  };

  const commentListData =
    commentList?.length > 0 ? (
      sortEmails(commentList).map((v) => (
        <EmailView
          emailData={v}
          editDraft={editDraft}
          copyEmail={copyEmail}
          forwardEmail={forwardEmail}
          replyEmail={replyEmail}
          cancelDelete={cancelDelete}
          showHideLightBox={showHideLightBox}
          filterText={filterText}
        ></EmailView>
      ))
    ) : (
      <div className='outputBox scheduleHtml'>No sent emails or drafts</div>
    );

  const showEditEmailComponent = showEditEmail || emailObjectId;

  return (
    <div>
      {!showEditEmailComponent ? (
        <div className='container'>
          <br />
          <div className='row'>
            <div className='col-md-12'>
              <div className='pageinfo d-flex'>
                <h2> Email </h2>
                <div className='ms-auto'>
                  <input
                    type='text'
                    className='btn-group form-control-sm'
                    placeholder='Search'
                    onChange={(e) => setFilterText(e.target.value)}
                    />
                  <button className='btn btn-primary' onClick={showEditEmailCallback}>
                    New Email
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <br />
          <EmailEditor
            showEditEmail={showEditEmailComponent}
            typeId={typeId}
            typeRef={typeRef}
            objectId={emailObjectId}
            emailAction={emailAction}
            closeWindowCallback={closeWindowCallback}
            data={data}
          ></EmailEditor>
        </div>
      )}
      <Scrollbars style={{ height:'calc(100vh)', minHeight:'500px' }}>{commentListData}</Scrollbars>
      <LightBoxComponent
        attachments={formatEmailAttachments(selectedEmail) ?? []}
        shouldOpen={lightBoxOpen}
        initialIndex={attachmentIndex}
        closeCallback={showHideLightBox}
      />
      {showEmailDetailModal && showEmailDetailId && (
        <EmailView
          emailId={showEmailDetailId}
          typeId={typeId}
          typeRef={typeRef}
          asModal={true}
          editDraft={editDraft}
          copyEmail={copyEmail}
          cancelDelete={cancelDelete}
          showHideLightBox={showHideLightBox}
          toggle={closeDetailModal}
          showModal={true}
        ></EmailView>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { message } = state;
  const { allEmail } = message;
  return {
    allEmails: allEmail && allEmail.length ? allEmail : [],
  };
};

export default withRouter(
  connect(mapStateToProps, {
    getAllEmailAction,
    deleteEmailAction,
  })(EmailTab)
);
