import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactLoading from 'react-loading';
import { notification } from 'antd';
import { appConstants } from '../../../_constants';
import AdminSidebar from '../../Admin/Inc/AdminSideBar.js';
import loaderImage from '../../static/images/loading_spinner.gif';
import { ResetPasswordAction } from '../../../actions/Admin/Account.actions.js';
import TitleComponent from  "../../Common/TitleComponent";
import { callAlertMessage } from '../../../components/Auth/Auth';

// eslint-disable-next-line no-undef
let Validator = require('validatorjs');
let styleData = { color: 'red' };
let formArr = {};
let rules = {
  oldPassword: 'required',
  newPassword: 'required',
  confirmPassword: 'required',
};
let mess = {
  required: 'This field is required',
  alpha: 'This field may only contain letters',
  numeric: 'This field must be a number',
};

let validation = [];
validation = new Validator([], rules, mess);
validation.passes();
validation.fails();

class AccountList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      sessionToken: localStorage.getItem('sessionToken'),
      userId: localStorage.getItem('userId'),
      userInfo: localStorage.getItem('userInfo'),
      formArr: [],
      formData: {},
      passwordError: null,
      confirmPassword: null,
      email: '',
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    let user = JSON.parse(localStorage.getItem('userInfo'));
    if (user && user.username) {
      this.setState({ email: user.username });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.isResetPwdSuccess !== this.props.isResetPwdSuccess &&
      nextProps.isResetPwd.success === 1
    ) {
      callAlertMessage('success', nextProps.message);
      this.props.history.push(`/admin/logout`);
    }

    if (
      nextProps.isResetPwdSuccess !== this.props.isResetPwdSuccess &&
      nextProps.isResetPwd.success === 0
    ) {
      callAlertMessage('error', nextProps.message);
    }
  }

  handleChange = (e) => {
    e.preventDefault();
    let field = this.state.formData;

    if (e.target.name === 'newPassword') {
      if (e.target.value && e.target.value.length <= 4) {
        this.setState({
          passwordError: (
            <small className="text-danger">
              You must enter a password of at least 5 characters.
            </small>
          ),
        });
      } else {
        this.setState({ passwordError: null });
      }

      if (e.target.value !== field.confirmPassword) {
        this.setState({
          confirmPassword: <small className="text-danger">Passwords do not match.</small>,
        });
      } else {
        this.setState({ confirmPassword: null });
      }
    }

    if (e.target.name === 'confirmPassword') {
      if (e.target.value !== field.newPassword) {
        this.setState({
          confirmPassword: <small className="text-danger">Passwords do not match.</small>,
        });
      } else {
        this.setState({ confirmPassword: null });
      }
    }

    field[e.target.name] = e.target.value;

    this.setState({ formData: field });
  };

  handleSubmit(e) {
    e.preventDefault();
    let { formData, email } = this.state;

    let data = {
      email: email ? email : '',
      oldPassword: formData.oldPassword,
      newPassword: formData.newPassword,
    };

    if (this.allValidate(false)) {
      // formData['email'] = email;
      this.props.ResetPasswordAction(data);
    }
  }

  allValidate(check) {
    if (!check) {
      formArr = [];
      Object.keys(rules).forEach(function (key) {
        formArr[key] = 'TT';
      });
      this.setState({
        formArr,
      });
    }
    if (validation.passes()) {
      return 1;
    }
  }

  render() {
    const { formData, formArr, passwordError, confirmPassword } = this.state;

    validation = new Validator(formData, rules, appConstants.RULESMESSAGE);
    validation.passes();
    validation.fails();

    return (
      <div>
        <TitleComponent data={{ title: `Administration - Reset Password` }} />
        <div
          className="noteProcessing"
          style={{ display: this.state.showProcessing ? 'block' : 'none' }}
        >
          <img src={loaderImage} alt="Loading" />
        </div>
        <div className="headingbox">
          <h2 className="heading2">
            Change Password
          </h2>
        </div>

        <div className="reset-pwd-box">
          <div className="row">
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="oldPassword">
                  Old Password<span className="requiredField">*</span>
                </label>
                <input
                  type="password"
                  className="form-control"
                  name="oldPassword"
                  id="oldPassword"
                  placeholder="Old Password*"
                  value={formData.oldPassword}
                  onChange={(e) => {
                    this.handleChange(e);
                  }}
                />
                <span style={styleData} className="error">
                  {formArr.oldPassword && validation.errors.first('oldPassword')}
                </span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="newPassword">
                  New Password<span className="requiredField">*</span>
                </label>
                <input
                  type="password"
                  className="form-control"
                  name="newPassword"
                  id="newPassword"
                  placeholder="New Password*"
                  value={formData.newPassword}
                  onChange={(e) => {
                    this.handleChange(e);
                  }}
                />
                <span style={styleData} className="error">
                  {passwordError}
                  {formArr.newPassword && validation.errors.first('newPassword')}
                </span>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="confirmPassword">
                  Confirm Password
                  <span className="requiredField">*</span>
                </label>
                <input
                  type="password"
                  className="form-control"
                  name="confirmPassword"
                  id="confirmPassword"
                  placeholder="Confirm Password*"
                  value={formData.confirmPassword}
                  onChange={(e) => {
                    this.handleChange(e);
                  }}
                />
                <span style={styleData} className="error">
                  {confirmPassword}
                  {formArr.confirmPassword && validation.errors.first('confirmPassword')}
                </span>
              </div>
            </div>
          </div>
        </div>

        <button
          className="btn btn-primary"
          disabled={passwordError || confirmPassword}
          onClick={(e) => this.handleSubmit(e)}
        >
          Update
        </button>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isResetPwd: state.account.isResetPwd,
    isResetPwdSuccess: state.account.isResetPwdSuccess,
    isResetPwdError: state.account.isResetPwdError,

    success: state.account.success,
    message: state.account.message,
  };
}

export default withRouter(connect(mapStateToProps, { ResetPasswordAction })(AccountList));
