import React, { Suspense } from 'react';
import ReactLoading from 'react-loading';
import { connect } from 'react-redux';
import renderHTML from 'html-react-parser';
import { Link, withRouter } from 'react-router-dom';
import {
  GetLeadSummaryReportAction,
  GetLeadSummaryReportPrefAction,
  SetLeadSummaryReportPrefAction,
} from '../../../actions/reports';
import {
  formatExternalContactData,
  formatInternalContactData,
  formatAddressText,
} from '../../../utils/contact-formatters';
import NotePopUp from '../../Common/NotePopUp';
import {Tooltip} from 'react-tooltip';
import { formatDate } from '../../../utils/date-formatters';
import { matchDataForTable } from '../../../utils/match-for-table';
import { appConstants } from '../../../_constants';
import { customSortByKey, CustomTable, multiSelectFilter } from '../../Common/CustomTable';
import { DateColumnFilter } from '../../Common/CustomTableComponents/DateColumnFilter';
import { SelectColumnFilter } from '../../Common/CustomTableComponents/SelectColumnFilter';
import TitleComponent from '../../Common/TitleComponent';
import Footer from '../../Layout/Footer';
import Header from '../../Layout/Header';
import { formatNote } from '../../../utils/note-formatters';
import { getAllProjectRoleAction } from '../../../actions/projectRole';
import { getAllProjectFieldsAction } from '../../../actions/projectFields';
import { isAllowed, moduleConstants } from '../../../_constants';
import {buildColumnForField, buildDynamicColumns, onCurrency, onText, onDate} from '../../../utils/column-formatters';
import { formatMoney } from '../../../utils/number-formatters';

var sortingOrder = '';

class LeadSummaryReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {},
      formArr: {},
      accountId: localStorage.getItem('accountId'),
      userId: localStorage.getItem('userId'),
      list: [],
      fetchRequest: true,
      comLogo: '',
      totalCount: 0,
      currentPage: 1,
      sizePerPage: appConstants.TableSizePerPageList[2].value,
      searchText: '',
      sortType: 'DESC',
      sortKey: '',
      isWidget: false,
      contactData: {},
      projectRoleTypes: [],
      allProjectFieldTypes: [],
      dynamicColumns: [],
    };
    this.onNoteModalClose = this.onNoteModalClose.bind(this);
  }

  componentDidMount() {
    this.setState({ isWidget: !!this.props?.props?.isWidget });
    this.setState({ sizePerPage: appConstants.TableSizePerPageList[2].value });
    let data = {};
    data['accountId'] = this.state.accountId;
    data['moduleName'] = 'Lead Summary Report';
    data['page'] = 0;
    data['displayLimit'] = this.props?.props?.isWidget ? 5 : this.state.sizePerPage;
    this.props.GetLeadSummaryReportAction(data);
    this.props.getAllProjectRoleAction({
      className: 'projectRoleTypes',
      accountId: this.state.accountId,
    });

    this.props.getAllProjectFieldsAction({
      accountId: this.state.accountId,
      minimumStage: 'lead'
    });

    sortingOrder = '';

    if (
      localStorage.getItem('companyLogo') !== 'undefined' &&
      localStorage.getItem('companyLogo') !== null
    ) {
      this.setState({
        comLogo: localStorage.getItem('companyLogo'),
      });
    } else {
      let constImage = appConstants.CONTENT_CPOST_LOGO_URL;
      this.setState({ comLogo: constImage });
    }
    this.props.GetLeadSummaryReportPrefAction();
  }



  
  buildColumnForRole(filteredRoles, roleCode, hidden = false) {
    const foundRole = filteredRoles.find((role) => role.code == roleCode);
    if(!foundRole)
      return
    const isInternal = foundRole&& foundRole.type === 'internal';

    if (foundRole && isInternal) {
      return (foundRole &&
      {
        Header: foundRole?.shortName ?? roleCode,
          LongHeader: foundRole?.name,
        accessor: 'projectAssignmentsLabel' + roleCode,
        sortType: customSortByKey,
        sortKey: foundRole?.name ?? roleCode,
        Filter: SelectColumnFilter,
        filter: multiSelectFilter,
        width: 60,
        hidden: hidden,
        Cell: (props) => <>{this.formatContactDataInternal('projectAssignmentsMatched' + roleCode, props.row.original)}</>,
      })
    } else {
      return (foundRole &&
      {
        Header: foundRole?.shortName ?? roleCode,
          LongHeader: foundRole?.name,
        accessor: 'projectAssignmentsLabel' + roleCode,
        sortType: customSortByKey,
        sortKey: foundRole?.name ?? roleCode,
        Filter: SelectColumnFilter,
        filter: multiSelectFilter,
        width: 60,
        hidden: hidden,
        Cell: (props) => <>{this.formatContactDataExternal('projectAssignmentsMatched' + roleCode, props.row.original)}</>,
      })
    }
  }

  formatContactDataInternal(cell, row) {
    if (row[cell] && row[cell].length > 0) {
      return formatInternalContactData(row[cell], this.state.contactData);
    } else {
      return <span>-</span>;
    }
  }

  formatContactDataExternal(cell, row) {
    if (row[cell] && row[cell].length > 0) {
      return formatExternalContactData(row[cell], this.state.contactData,false);
    } else {
      return <span>-</span>;
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {

    if (nextProps?.projectRoles?.allProjectRoleTypes) {
      this.setState({
        projectRoleTypes: nextProps.projectRoles.allProjectRoleTypes,
      });
    }

    if (nextProps?.projectFields?.allProjectFieldTypes?.length > 0) {
      this.setState({
        allProjectFieldTypes: nextProps.projectFields.allProjectFieldTypes,
      });
    }

    if (nextProps?.listData?.success === 1) {
      const fieldsToMatch = {
        projectAssignments: { lookupLocation: 'contactData' },
      };

      let matchedDataForTable = matchDataForTable(
        fieldsToMatch,
        nextProps.listData.data,
        nextProps.listData.contactData,
        this.props
      );

      this.setState({
        totalCount: nextProps.listData.count,
        list: matchedDataForTable,
        fetchRequest: false,
        contactData: nextProps.listData.contactData,
      });
    }

    if (nextProps?.listData?.data?.length == 0) {
      this.setState({ fetchRequest: false });
    }
  }



  onCellButton(cell, row, enumObject, rowIndex) {
    return (
      <div>
        <Link to={{ pathname: `/lead-details/` + row.objectId, state: { row } }}>
          {row?.jobName ? row.jobName : '-'}
        </Link>
      </div>
    );
  }

  onGetOpenPop(cell, row, enumObject, rowIndex) {
    let data = '';
    if (row) {
      data = (
        <div className='textRight'>
          <div>
            {row?.currentContractAmount
              ? formatMoney(Math.round(row.currentContractAmount))
              : '-'}
          </div>
          <div>
            {row?.currentGrossProfit
              ? formatMoney(Math.round(row.currentGrossProfit))
              : '-'}
          </div>
        </div>
      );
      return data;
    }
  }



  onGetStatusCell(name, row, enumObject, rowIndex) {
    if (row) {
      return (
        <>
          <div>{row.jobStatusCodesCode ? row.jobStatusCodesCode : ''}</div>
        </>
      );
    } else {
      return <span>-</span>;
    }
  }


  onNoteDetail(cell, row, enumObject, rowIndex) {
    const notes = formatNote(row?.jobNotes);
    return notes ? (
      <a
        className='anchorNote '
        data-type={'reminder'}
        onClick={(e) => {
          this.getModalOpen(e, row);
        }}
      >
        {renderHTML(notes)}
      </a>
    ) : (
      '-'
    );
  }
  
  getModalOpen(e, row) {
    let noteText = row && row.jobNotes ? row.jobNotes : '-';
    let lastNoteId = row && row.lastNoteId ? row.lastNoteId : null;
    this.setState({ noteModal: true, noteData: {objectId:lastNoteId,description: noteText,typeId: row.objectId, typeRef: 'PROJECT'} });
 
  }

  onNoteModalClose() {
    this.setState({ noteModal: false });
  }

  getLeadSummaryReport = () => {
    let data = {};
    let { searchText } = this.state;
    data['accountId'] = this.state.accountId;
    data['moduleName'] = appConstants.reportModuleName['2'];
    data['sortKey'] = this.state.sortKey ? this.state.sortKey : '';
    data['sortType'] = this.state.sortType ? this.state.sortType : '';
    data['page'] = this.state.currentPage - 1;
    data['search'] = searchText;
    data['displayLimit'] = this.state.sizePerPage;
    this.props.GetLeadSummaryReportAction(data);
  };

  _setTableOption() {
    if (this.state.fetchRequest) {
      return (
        <ReactLoading
          className='table-loader'
          type={appConstants.LOADER_TYPE}
          color={appConstants.LOADER_COLOR}
          height={appConstants.LOADER_HEIGHT}
          width={appConstants.LOADER_WIDTH}
        />
      );
    } else {
      return 'No data found..';
    }
  }

  createCustomButtonGroup = (props) => {
    return (
      <div className='pdfLogoBox'>
        <div className='reportHead'>
          <div className='logo'>
            <img src={this.state.comLogo} alt='Upload Logo' />
          </div>
          <h2>Lead Summary Report</h2>
        </div>
      </div>
    );
  };

  buildReportColumns() {

    const hasContractValPermissions = isAllowed(moduleConstants.VIEWCONTRACTVAL, 'anyAssigned');
    const hasProfitValPermissions = isAllowed(moduleConstants.VIEWPROFIT, 'anyAssigned');

    const {
       allProjectFieldTypes
    } = this.state;


    const dynamicFieldsLoaded = allProjectFieldTypes?.length > 0;

    const defaultFieldCodes = ['OCCTYPE','SQFT','NEGMTHD','SCOPE'];

    const customFieldColumns = dynamicFieldsLoaded ? buildDynamicColumns(allProjectFieldTypes.filter(field => !defaultFieldCodes.includes(field.code))) : [];

    const defaultFields = dynamicFieldsLoaded ? allProjectFieldTypes.filter(field => defaultFieldCodes.includes(field.code)) : [];

    
    const isLeadType = true;

    const rolesLoaded = this.state.projectRoleTypes?.length > 0;

    const defaultRolesCodes = ['AX','EST','CL','AR','TN','REB'];


    
    const enabledRoles = this.state.projectRoleTypes.filter(
      (role) => !defaultRolesCodes.includes(role.code) && (!isLeadType || role.isShowOnLeads)
    );
    const defaultRoles = this.state.projectRoleTypes.filter((role) =>
      defaultRolesCodes.includes(role.code) && (!isLeadType || role.isShowOnLeads)
    );

    const defaultColumns = [
      this.buildColumnForRole(defaultRoles,'AX'),
      this.buildColumnForRole(defaultRoles,'EST'),
      {
        Header: 'Bid Date',
        id:'bidDue',
        accessor: (row) =>
          row?.bidDue?.iso
            ? new Date(row.bidDue.iso)
            : new Date(formatDate('1800-03-03T12:47:40.000Z')),
        width: 80,
        filter: DateColumnFilter,
        allowDateRangeFilter: true,
        sortType: 'datetime',
        Cell: (props) => onDate(props.column.id, props.row.original, props.value),
      },
      {
        Header: 'Status',
        accessor: 'jobStatusCodesCode',
        Filter: SelectColumnFilter,
        width: 50,

        Cell: (props) => onText(props.column.id, props.row.original, props.value),
      },
      {
        Header: 'Project Name',
        accessor: 'jobName',
        width: 165,
        Cell: (props) => this.onCellButton(props.column.id, props.row.original, props.value),
      },
      {
        Header: 'Lead Source',
        accessor: 'leadSource',
        width: 130,
        Cell: (props) => <>{onText(props.column.id, props.row.original, props.value)}</>,
      },
      {
        Header: 'Lead Cohort',
        accessor: 'leadCohort',
        width: 130,
        Cell: (props) => <>{onText(props.column.id, props.row.original, props.value)}</>,
      },
      {
        Header: 'Site Address',
        accessor: (row) => formatAddressText(row),
        sortType: 'basic',
        width: 100,
        Cell: (props) => (
          <>
            <Link
              target='_blank'
              rel='noopener noreferrer'
              to={{
                pathname: `https://maps.google.com/?q=` + formatAddressText(props.row.original),
              }}
              data-tooltip-content={props.value}
              data-tooltip-id={props.value}
            >
              {props.value}
            </Link>
          </>
        ),
      },
      buildColumnForField(defaultFields, 'OCCTYPE', false),
      buildColumnForField(defaultFields, 'SQFT', false),
      (hasContractValPermissions && hasProfitValPermissions) && {
        Header: 'Est. Value & Profit',
        id: 'currentContractAmount',
        width: 100,
        Cell: (props) => <>{this.onGetOpenPop(props.column.id, props.row.original, props.value)}</>,
      },
      {
        Header: 'Proj. Start',
        id: 'startDate',
        //filterName: 'Proj. Start / End',
        //filter: StartEndDatesColumnFilter,
        accessor: (row) =>
          row?.startDate?.iso
            ? new Date(row.startDate.iso)
            : new Date(formatDate('1800-03-03T12:47:40.000Z')),
        filter: DateColumnFilter,
        allowDateRangeFilter: true,
        sortType: 'datetime',
        width: 90,
        Cell: (props) => (
          <>{onDate(props.column.id, props.row.original, props.value)}</>
        ),
      },
      {
        Header: 'Proj. End',
        id:'endDate',
        accessor: (row) =>
          row?.endDate?.iso
            ? new Date(row.endDate.iso)
            : new Date(formatDate('1800-03-03T12:47:40.000Z')),
        width: 90,
        filter: DateColumnFilter,
        allowDateRangeFilter: true,
        sortType: 'datetime',
        Cell: (props) => (
          <>{onDate(props.column.id, props.row.original, props.value)}</>
        ),
      },
      buildColumnForField(defaultFields, 'NEGMTHD', false),
      buildColumnForField(defaultFields, 'SCOPE', false),
      this.buildColumnForRole(defaultRoles,'CL'),
      this.buildColumnForRole(defaultRoles,'AR'),
      this.buildColumnForRole(defaultRoles,'REB'),
      this.buildColumnForRole(defaultRoles,'TN'),
      {
        Header: 'Notes',
        accessor: 'jobNotes',
        hidden: true,
        width: 120,
        Cell: (props) => (
          <>
            <span
              data-tooltip-html={props.value}
              data-tooltip-id={props.column.id + ':' + props.row.id}
            >
              {this.onNoteDetail(props.column.id, props.row.original, props.value)}
            </span>
            <Tooltip
              id={props.column.id + ':' + props.row.id}
              delayShow={1000}
              style={{ color: '#fff !important' }}
              place='bottom'
            />
          </>
        ),
      },
    ];


    let columns = [];
    if (enabledRoles) {
      const extraColumns = enabledRoles.map((role) => (
        this.buildColumnForRole([role], role.code,true)));

      if (columns.length == 0) {
        columns = defaultColumns.filter(cols=>cols?.accessor);
      }

      for (let i in extraColumns) {
        const curColumnsAccessors = columns.map((cols) => cols.accessor);
        if (!curColumnsAccessors.includes(extraColumns[i].accessor)) {
          columns = columns.concat(extraColumns[i]);
        }
      }

      for (let i in customFieldColumns) {
        const curColumnsAccessors = columns.map((cols) => cols.accessor);
        if (!curColumnsAccessors.includes(customFieldColumns[i].accessor)) {
          columns = columns.concat(customFieldColumns[i]);
        }
      }
    } else {
      columns = defaultColumns.filter(cols=>cols?.accessor);
    }

    this.setState({ dynamicColumns: columns });

  }
  render() {

    const {
      list, allProjectFieldTypes,dynamicColumns
    } = this.state;

    const { listPref, SetLeadSummaryReportPrefAction } = this.props;

    const dynamicFieldsLoaded = allProjectFieldTypes?.length > 0;

    const rolesLoaded = this.state.projectRoleTypes?.length > 0;

    const dynamicColumnsLoaded = dynamicFieldsLoaded && rolesLoaded;

    
    const dynamicColumnsBuilt = dynamicColumnsLoaded && dynamicColumns.length > 0;

    if(dynamicColumnsLoaded && !dynamicColumnsBuilt){
      this.buildReportColumns();
    }

    const isWidget = !!this.props?.props?.isWidget;

    return (
      <div>
        <div className='ContactListReport'>
          <TitleComponent data={{ title: `Project Lead Sheet` }} />
          {!isWidget && (
            <div>
              <Suspense fallback='loading'>
                <Header props={this.props} />
              </Suspense>
            </div>
          )}
          <div className='contactBox'>
            <div className='container'>
              <div className='row no-break-print'>
                {dynamicColumnsBuilt&&(<div className='col-md-12'>
                  <div
                    className='table-responsive reportTableSection'
                    ref={(el) => (this.componentRef = el)}
                  >
                    {!isWidget && this.createCustomButtonGroup()}
                    <CustomTable
                      columns={dynamicColumns}
                      list={list}
                      preferences={listPref}
                      className='bid-due-table'
                      customTableTitle={!isWidget ? 'Lead Summary Report' : ''}
                      onChangeRowSelection={this.onChangeRowSelection}
                      onSavePreferences={SetLeadSummaryReportPrefAction}
                      isLoading={this.state.fetchRequest}
                      canShowHeaderItems={!isWidget}
                      canShowRowSelection={false}
                      pageSizeArray={isWidget ? [5] : [50, 100, 200]}
                      componentRef={this.componentRef}
                    />
                  </div>
                </div>)}
              </div>
            </div>
          </div>
          {this.state.noteModal && (
              <NotePopUp
                isOpen={this.state.noteModal}
                onClick={this.onNoteModalClose}
                noteInfo={this.state.noteData}
              />
            )}
          {!isWidget && <Footer />}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    listData: state.reports.leadSummaryList,
    listPref: state.reports.leadSummaryListPref,
    projectRoles: state.projectRoleType,
    projectFields: state.projectFields,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    GetLeadSummaryReportAction,
    GetLeadSummaryReportPrefAction,
    SetLeadSummaryReportPrefAction,
    getAllProjectRoleAction,
    getAllProjectFieldsAction
  })(LeadSummaryReport)
);
