import * as actionTypes from './actionTypes';
import * as API from '../api/accountSettings';
import { callAlertMessage } from '../components/Auth/Auth';
import { notification } from 'antd';

//Show company setting details
export const getAccountSettingsRequest = () => ({
  type: actionTypes.GET_EXISTING_ACCOUNT_SETTINGS_REQUEST,
});

export const getAccountSettingSuccess = (response) => ({
  type: actionTypes.GET_EXISTING_ACCOUNT_SETTINGS_SUCCESS,
  payload: {
    response,
  },
});

export const getAccountSettingsError = (error) => ({
  type: actionTypes.GET_EXISTING_ACCOUNT_SETTINGS_ERROR,
  payload: {
    error,
  },
});

export const getAccountSettingsAction = (data) => {
  return (dispatch) => {
    dispatch(getAccountSettingsRequest());
    return API.getAccountSettings(data)
      .then((response) => {
        dispatch(getAccountSettingSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          getAccountSettingsError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

//Edit company setting details
export const updateAccountSettingsRequest = () => ({
  type: actionTypes.UPDATE_ACCOUNT_SETTINGS_REQUEST,
});

export const updateAccountSettingSuccess = (response) => ({
  type: actionTypes.UPDATE_ACCOUNT_SETTINGS_SUCCESS,
  payload: {
    response,
  },
});

export const updateAccountSettingsError = (error) => ({
  type: actionTypes.UPDATE_ACCOUNT_SETTINGS_ERROR,
  payload: {
    error,
  },
});

export const updateAccountSettingsAction = (data) => {
  return (dispatch) => {
    dispatch(updateAccountSettingsRequest());
    return API.updateAccountSettings(data)
      .then((response) => {
        if (response.data.result.success === 1) {
          localStorage.setItem('accountInfo', JSON.stringify(response.data.result.data));

          callAlertMessage('success', 'Record Updated successfully');
        } else {
          callAlertMessage('error', response.data.result.msg);
        }

        dispatch(updateAccountSettingSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          updateAccountSettingsError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

export const setImagesMasterRequest = () => ({
  type: actionTypes.SET_IMAGE_MASTER_REQUEST,
});

export const setImagesMasterSuccess = (response) => ({
  type: actionTypes.SET_IMAGE_MASTER_SUCCESS,
  payload: {
    response,
  },
});

export const setImagesMasterError = (error) => ({
  type: actionTypes.SET_IMAGE_MASTER_ERROR,
  payload: {
    error,
  },
});

export const setImagesMasterAction = (data) => {
  return (dispatch) => {
    dispatch(setImagesMasterRequest());
    return API.setImagesMaster(data)
      .then((response) => {
        dispatch(setImagesMasterSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          setImagesMasterError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

export const getVariableMasterRequest = () => ({
  type: actionTypes.GET_VARIABLE_MASTER_REQUEST,
});

export const getVariableMasterSuccess = (response) => ({
  type: actionTypes.GET_VARIABLE_MASTER_SUCCESS,
  payload: {
    response,
  },
});

export const getVariableMasterError = (error) => ({
  type: actionTypes.GET_VARIABLE_MASTER_ERROR,
  payload: {
    error,
  },
});

export const getVariableMasterAction = (data) => {
  return (dispatch) => {
    dispatch(getVariableMasterRequest());
    return API.getOverheadSettingsHistory(data)
      .then((response) => {
        dispatch(getVariableMasterSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          getVariableMasterError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

export const addEditVariableMasterRequest = () => ({
  type: actionTypes.AddEdit_VARIABLE_MASTER_REQUEST,
});

export const addEditVariableMasterSuccess = (response) => ({
  type: actionTypes.AddEdit_VARIABLE_MASTER_SUCCESS,
  payload: {
    response,
  },
});

export const addEditVariableMasterError = (error) => ({
  type: actionTypes.AddEdit_VARIABLE_MASTER_ERROR,
  payload: {
    error,
  },
});

export const addEditVariableMasterAction = (data) => {
  return (dispatch) => {
    dispatch(addEditVariableMasterRequest());
    return API.addEditAccountSettingsHistoryVar(data)
      .then((response) => {
        if (response.data.result.success === 0) {
          notification.error({ message: response.data.result.msg });
        }

        dispatch(addEditVariableMasterSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          addEditVariableMasterError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

export const addVariableData = (data) => ({
  type: actionTypes.ADD_VARIABLE_DATA,
  payload: data,
});

export const updateVariableDataByIndex = (data, index) => ({
  type: actionTypes.UPDATE_VARIABLE_DATA_BY_INDEX,
  payload: data,
  index,
});
