import React, { Suspense } from 'react';

import { CustomDateInput } from '../../../shared/custom-fields-controls/form-controls/custom-date-input';
import ReactLoading from 'react-loading';
import { connect } from 'react-redux';
import {Tooltip} from 'react-tooltip';
import { Link, withRouter } from 'react-router-dom';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { getAllProjectRoleAction } from '../../../actions/projectRole';
import {
  GetNoBidReportAction,
  GetNoBidReportPrefAction,
  SetNoBidReportPrefAction,
} from '../../../actions/reports';
import {
  formatExternalContactData,
  formatInternalContactData,
} from '../../../utils/contact-formatters';
import {
  formatDate,
  formatDateObject,
  startDateFormatWithoutTimeUTCF,
} from '../../../utils/date-formatters';
import { matchDataForTable } from '../../../utils/match-for-table';
import { appConstants } from '../../../_constants';
import { customSortByKey, CustomTable, multiSelectFilter } from '../../Common/CustomTable';
import {
  DateColumnFilter,
} from '../../Common/CustomTableComponents/DateColumnFilter';
import { SelectColumnFilter } from '../../Common/CustomTableComponents/SelectColumnFilter';
import TitleComponent from '../../Common/TitleComponent';
import Footer from '../../Layout/Footer';
import Header from '../../Layout/Header';
import { getAllProjectFieldsAction } from '../../../actions/projectFields';
import { isAllowed, moduleConstants } from '../../../_constants';
import {buildColumnForField, buildDynamicColumns, onDate} from '../../../utils/column-formatters';
import { formatMoney } from '../../../utils/number-formatters';


var sortingOrder = '';

class NoBidReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {},
      accountId: localStorage.getItem('accountId'),
      userId: localStorage.getItem('userId'),
      list: [],
      modal: false,
      fetchRequest: true,
      comLogo: '',
      totalCount: 0,
      currentPage: 1,
      sizePerPage: appConstants.TableSizePerPageList[2].value,
      searchText: '',
      selectDateBox: {
        startDate: formatDateObject().subtract(1, 'years'),
        nowDate: true,
      },
      dynamicColumns: [],
      sortType: 'DESC',
      sortKey: '',
      contactData: {},
      projectRoleTypes: [],
      allProjectFieldTypes: [],
    };

    this.onPageChange = this.onPageChange.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.listData &&
      nextProps.listData.success &&
      nextProps.listData.success === 1
    ) {
      let fieldsToMatch = {
        projectAssignments: {lookupLocation: 'contactData'}
      };

      let matchedDataForTable = matchDataForTable(
        fieldsToMatch,
        nextProps.listData.data,
        nextProps.listData.contactData,
        this.props
      );

      if (nextProps?.projectRoles?.allProjectRoleTypes) {
        this.setState({
          projectRoleTypes: nextProps.projectRoles.allProjectRoleTypes,
        });
      }
      if (nextProps?.projectFields?.allProjectFieldTypes?.length > 0) {
        this.setState({
          allProjectFieldTypes: nextProps.projectFields.allProjectFieldTypes,
        });
      }
      this.setState({
        totalCount: nextProps.listData.count,
        list: matchedDataForTable,
        fetchRequest: false,
        contactData: nextProps.listData.contactData,
      });
    }

    if (nextProps.listData && nextProps.listData.data && nextProps.listData.data.length === 0) {
      this.setState({ fetchRequest: false });
    }
  }

  
  buildColumnForRole(filteredRoles, roleCode, hidden = false) {
    const foundRole = filteredRoles.find((role) => role.code == roleCode);
    if(!foundRole)
      return
    const isInternal = foundRole&& foundRole.type === 'internal';

    if (foundRole && isInternal) {
      return (foundRole &&
      {
        Header: foundRole?.shortName ?? roleCode,
          LongHeader: foundRole?.name,
        accessor: 'projectAssignmentsLabel' + roleCode,
        sortType: customSortByKey,
        sortKey: foundRole?.name ?? roleCode,
        Filter: SelectColumnFilter,
        filter: multiSelectFilter,
        width: 60,
        hidden: hidden,
        Cell: (props) => <>{this.formatContactDataInternal('projectAssignmentsMatched' + roleCode, props.row.original)}</>,
      })
    } else {
      return (foundRole &&
      {
        Header: foundRole?.shortName ?? roleCode,
          LongHeader: foundRole?.name,
        accessor: 'projectAssignmentsLabel' + roleCode,
        sortType: customSortByKey,
        sortKey: foundRole?.name ?? roleCode,
        Filter: SelectColumnFilter,
        filter: multiSelectFilter,
        width: 60,
        hidden: hidden,
        Cell: (props) => <>{this.formatContactDataExternal('projectAssignmentsMatched' + roleCode, props.row.original)}</>,
      })
    }
  }

  formatContactDataInternal(cell, row) {
    if (row[cell] && row[cell].length > 0) {
      return formatInternalContactData(row[cell], this.state.contactData);
    } else {
      return <span>-</span>;
    }
  }

  formatContactDataExternal(cell, row) {
    if (row[cell] && row[cell].length > 0) {
      return formatExternalContactData(row[cell], this.state.contactData,false);
    } else {
      return <span>-</span>;
    }
  }



  componentDidMount() {
    if (
      localStorage.getItem('companyLogo') !== 'undefined' &&
      localStorage.getItem('companyLogo') !== null
    ) {
      this.setState({
        comLogo: localStorage.getItem('companyLogo'),
      });
    } else {
      let constImage = appConstants.CONTENT_CPOST_LOGO_URL;
      this.setState({ comLogo: constImage });
    }

    let _this = this;
    setTimeout(function () {
      _this.setState({ fetchRequest: false });
    }, 3000);
    this.filteredData(null);
    sortingOrder = '';
    this.props.GetNoBidReportPrefAction();

    this.props.getAllProjectRoleAction({
      className: 'projectRoleTypes',
      accountId: this.state.accountId,
    });
    this.props.getAllProjectFieldsAction({
      accountId: this.state.accountId,
      minimumStage: 'bidding',
    });
  }
  toggle(val) {
    this.setState({ modal: !this.state.modal });
  }

  filteredData(e) {
    this.setState({ fetchRequest: true });
    this.setState({ sizePerPage: appConstants.TableSizePerPageList[2].value });
    e?.preventDefault();
    let data = {};
    data['accountId'] = this.state.accountId;
    data['moduleName'] = appConstants.reportModuleName['9'];
    data['startDate'] = startDateFormatWithoutTimeUTCF('1800-03-03T12:47:40.000Z');
    data['page'] = 0;
    data['displayLimit'] = this.state.sizePerPage;
    data['search'] = this.state.searchText;
    data['sortKey'] = this.state.sortKey;
    data['sortType'] = this.state.sortType;
    this.setState({ modal: false });
    this.props.GetNoBidReportAction(data);
  }

  handleDatePicker = (name, date) => {
    let field = this.state.selectDateBox;
    field[name] = date;
    if (date === null) {
      field[name] = formatDateObject();
    } else {
      field[name] = formatDateObject(date);
    }
    this.setState({
      selectDateBox: field,
    });
  };


  onCellButton(cell, value, row, rowIndex) {
    return (
      <div>
        <Link to={{ pathname: `/project-details/` + row.objectId, state: { row } }}>
          {value ?? ''}
        </Link>
      </div>
    );
  }

  onGetOpenPop(cell, row, enumObject, rowIndex) {
    let data = '';
    if (row) {
      data = (
        <div className='textRight'>
          <div>
            {row?.currentContractAmount
              ? formatMoney(Math.round(row.currentContractAmount))
              : '-'}
          </div>
          <div>
            {row?.currentGrossProfit
              ? formatMoney(Math.round(row.currentGrossProfit))
              : '-'}
          </div>
        </div>
      );
      return data;
    }
  }

  createCustomButtonGroup = (props) => {
    return (
      <div className='pdfLogoBox'>
        <div className='reportHead'>
          <div className='logo'>
            <img src={this.state.comLogo} alt='Upload Logo' />
          </div>
          <h2>No Bid Report</h2>
        </div>
      </div>
    );
  };

  _setTableOption() {
    if (this.state.fetchRequest) {
      return (
        <ReactLoading
          className='table-loader'
          type={appConstants.LOADER_TYPE}
          color={appConstants.LOADER_COLOR}
          height={appConstants.LOADER_HEIGHT}
          width={appConstants.LOADER_WIDTH}
        />
      );
    } else {
      return 'No data found..';
    }
  }

  onPageChange(page, sizePerPage) {
    let data = {};
    data['accountId'] = this.state.accountId;
    data['moduleName'] = appConstants.reportModuleName['9'];
    data['startDate'] = startDateFormatWithoutTimeUTCF(this.state.selectDateBox.startDate);
    // if (this.state.searchText === "") {
    //     data['page'] = (page === -1) ? 0 : page - 1;
    //     data['displayLimit'] = sizePerPage;
    // } else {
    data['page'] = page === -1 ? 0 : page - 1;
    data['displayLimit'] = sizePerPage;
    data['search'] = this.state.searchText;
    //}

    data['sortKey'] = this.state.sortKey;
    data['sortType'] = this.state.sortType;

    this.setState({ sizePerPage: sizePerPage });
    this.setState({ currentPage: page });
    this.props.GetNoBidReportAction(data);
  }

  onSortChange(sortName, sortOrder) {
    if (this.state && this.state.list && this.state.list.length > 0) {
      let data = {};
      data['sortKey'] = sortName;

      if (sortName === 'jobNumFormatted') {
        data['sortKey'] = 'jobNum';
      } else if (sortName === appConstants.roleList.tenantArr) {
        data['sortKey'] = appConstants.roleList.tenantName;
      } else if (sortName === appConstants.roleList.brokerArr) {
        data['sortKey'] = appConstants.roleList.brokerName;
      } else if (sortName === appConstants.roleList.architectArr) {
        data['sortKey'] = appConstants.roleList.architectName;
      } else if (sortName === appConstants.roleList.buildingOwnerArr) {
        data['sortKey'] = appConstants.roleList.buildingOwnerName;
      } else if (sortName === appConstants.roleList.accountExecArr) {
        data['sortKey'] = appConstants.roleList.accountExecName;
      }

      this.setState({ sortKey: data['sortKey'] });

      if (sortOrder === 'asc') {
        data['sortType'] = 'ASC';
        sortingOrder = '-asc';
        this.setState({ sortType: 'ASC' });
      } else {
        data['sortType'] = 'DESC';
        sortingOrder = '-desc';
        this.setState({ sortType: 'DESC' });
      }
      data['accountId'] = this.state.accountId;
      data['moduleName'] = appConstants.reportModuleName['9'];
      data['startDate'] = startDateFormatWithoutTimeUTCF(this.state.selectDateBox.startDate);
      data['page'] = this.state.currentPage - 1;
      data['displayLimit'] = this.state.sizePerPage;
      data['search'] = this.state.searchText;
      this.props.GetNoBidReportAction(data);
    }
  }



  

  

  buildReportColumns() {
    const hasContractValPermissions = isAllowed(moduleConstants.VIEWCONTRACTVAL, 'anyAssigned');
    const hasProfitValPermissions = isAllowed(moduleConstants.VIEWPROFIT, 'anyAssigned');

    const { list, allProjectFieldTypes } = this.state;

    const dynamicFieldsLoaded = allProjectFieldTypes?.length > 0;

    const defaultFieldCodes = ['OCCTYPE','SQFT','NEGMTHD'];

    const customFieldColumns = dynamicFieldsLoaded
      ? buildDynamicColumns(
          allProjectFieldTypes.filter((field) => !defaultFieldCodes.includes(field.code))
        )
      : [];

    const defaultFields = dynamicFieldsLoaded
      ? allProjectFieldTypes.filter((field) => defaultFieldCodes.includes(field.code))
      : [];


    const isLeadType = false;

    const rolesLoaded = this.state.projectRoleTypes?.length > 0;

    const defaultRolesCodes = ['AX','PO','AR','REB','TEN'];

    const dynamicColumnsLoaded = dynamicFieldsLoaded && rolesLoaded;

    
    const enabledRoles = this.state.projectRoleTypes.filter(
      (role) => !defaultRolesCodes.includes(role.code) && (!isLeadType || role.isShowOnLeads)
    );
    const defaultRoles = this.state.projectRoleTypes.filter((role) =>
      defaultRolesCodes.includes(role.code) && (!isLeadType || role.isShowOnLeads)
    );


    const defaultColumns = [
      {
        Header: 'Job No.',
        accessor: 'jobNumFormatted',
        sortType: 'basic',
        width: 80,
        hidden: true,
        Cell: (props) => (
          <>
            <span data-tooltip-content={props.value} data-tooltip-id={props.value}>
              {this.onCellButton(props.column.id, props.value, props.row.original)}
            </span>
            <Tooltip id={props.value} delayShow={1000} place='bottom'  />
       
         </>
        ),
      },
      {
        Header: 'Alt Job#',
        accessor: 'altJobNum',
        width: 80,
        hidden: true,
        Cell: (props) => this.onCellButton(props.column.id, props.value, props.row.original),
      },
      this.buildColumnForRole(defaultRoles,'AX'),
      {
        Header: 'Lead Date',
        accessor: (row) =>
          row?.leadDate?.iso
            ? new Date(row.leadDate.iso)
            : new Date(formatDate('1800-03-03T12:47:40.000Z')),
        id: 'leadDate',
        width: 90,
        Cell: (props) => <>{onDate(props.column.id, props.row.original, props.value)}</>,
        filter: DateColumnFilter,
        allowDateRangeFilter: true,
        sortType: 'datetime',
      },
      {
        Header: 'Project Name',
        accessor: 'jobName',
        width: 170,
        Cell: (props) => this.onCellButton(props.column.id, props.value, props.row.original),
      },
      buildColumnForField(defaultFields, 'OCCTYPE', false),
      buildColumnForField(defaultFields, 'SQFT', false),
      hasContractValPermissions && hasProfitValPermissions && {
        Header: 'Estimated Value & Profit',
        accessor: 'currentContractAmount',
        width: 90,
        Cell: (props) => this.onGetOpenPop(props.column.id, props.row.original, props.value),
      },
      {
        Header: 'Projected Start Date',
        id:'startDate',
        accessor: (row) =>
          row?.startDate?.iso
            ? new Date(row.startDate.iso)
            : new Date(formatDate('1800-03-03T12:47:40.000Z')),
        id: 'startDate',
        width: 90,
        Cell: (props) => (
          <>{onDate(props.column.id, props.row.original, props.value)}</>
        ),
        allowDateRangeFilter: true,
        sortType: 'datetime',
      },
      {
        Header: 'Projected End Date',
        id:'endDate',
        accessor: (row) =>
          row?.endDate?.iso
            ? new Date(row.endDate.iso)
            : new Date(formatDate('1800-03-03T12:47:40.000Z')),
        width: 90,
        allowDateRangeFilter: true,
        sortType: 'datetime',
        Cell: (props) => (
          <>{onDate(props.column.id, props.row.original, props.value)}</>
        ),
      },
      {
        Header: 'Bid Date',
        id:'bidDue',
        accessor: (row) =>
          row?.bidDue?.iso
            ? new Date(row.bidDue.iso)
            : new Date(formatDate('1800-03-03T12:47:40.000Z')),
        width: 90,
        allowDateRangeFilter: true,
        sortType: 'datetime',
        hidden:true,
        Cell: (props) => (
          <>{onDate(props.column.id, props.row.original, props.value)}</>
        ),
      },
      buildColumnForField(defaultFields, 'NEGMTHD', false),
      this.buildColumnForRole(defaultRoles,'PO'),
      this.buildColumnForRole(defaultRoles,'AR'),
      this.buildColumnForRole(defaultRoles,'REB'),
      this.buildColumnForRole(defaultRoles,'TEN'),
    ];

    let columns = [];
    if (enabledRoles) {
      const extraColumns = enabledRoles.map((role) => (
        this.buildColumnForRole([role], role.code,true)));

      if (columns.length == 0) {
        columns = defaultColumns.filter(cols=>cols?.accessor);
      }

      for (let i in extraColumns) {
        const curColumnsAccessors = columns.map((cols) => cols.accessor);
        if (!curColumnsAccessors.includes(extraColumns[i].accessor)) {
          columns = columns.concat(extraColumns[i]);
        }
      }

      for (let i in customFieldColumns) {
        const curColumnsAccessors = columns.map((cols) => cols.accessor);
        if (!curColumnsAccessors.includes(customFieldColumns[i].accessor)) {
          columns = columns.concat(customFieldColumns[i]);
        }
      }
    } else {
      columns = defaultColumns.filter(cols=>cols?.accessor);
    }

    this.setState({ dynamicColumns: columns });

  }
  render() {
  

    const {
      list, allProjectFieldTypes, dynamicColumns
    } = this.state;


    const { listPref, SetNoBidReportPrefAction } = this.props;

    
    const dynamicFieldsLoaded = allProjectFieldTypes?.length > 0;

    const rolesLoaded = this.state.projectRoleTypes?.length > 0;

    const dynamicColumnsLoaded = dynamicFieldsLoaded && rolesLoaded;

    
    const dynamicColumnsBuilt = dynamicColumnsLoaded && dynamicColumns.length > 0;

    if(dynamicColumnsLoaded && !dynamicColumnsBuilt){
      this.buildReportColumns();
    }

    return (
      <div>
        <div className='ContactListReport'>
          <TitleComponent data={{ title: `No Bid Report` }} />
          <div>
            <Suspense fallback='loading'>
              <Header props={this.props} />
            </Suspense>
          </div>
          <div className='contactBox'>
            <div className='container'>
              <div className='row no-break-print'>
              {dynamicColumnsBuilt&&(<div className='col-md-12'>
                  <div
                    className='table-responsive reportTableSection'
                    ref={(el) => (this.componentRef = el)}
                  >
                    {this.createCustomButtonGroup()}
                    <CustomTable
                      columns={dynamicColumns}
                      list={list}
                      preferences={listPref}
                      className='no-bid-table'
                      customTableTitle='No Bid Report'
                      onSavePreferences={(pref) => SetNoBidReportPrefAction(pref)}
                      isLoading={this.state.fetchRequest}
                      canShowRowSelection={false}
                      componentRef={this.componentRef}
                    />
                  </div>
                </div>)}
              </div>
            </div>
          </div>
          <Footer />
        </div>

        <Modal
          backdrop='static'
          keyboard
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggle}>Select Earliest Date</ModalHeader>
          <ModalBody>
            <div className='container'>
              <div className='row'>
                <div className='col-md-12'>
                  <span>
                    The report will contain all jobs whose lead date falls after the selected date.
                  </span>
                </div>
              </div>
              <br />
              <div className='row'>
                <div className='col-md-12'>
                  <div className='form-group'>
                    <label htmlFor='startDate'>Earliest Date to Include</label>
                    <CustomDateInput
                      className='form-control'
                      selected={this.state.selectDateBox.startDate}
                      onChange={this.handleDatePicker.bind(this, 'startDate')}
                      dateFormat={appConstants.DATEFORMATPICKER}
                      required
                      closeOnSelect
                      placeholderText='Click to select a date'
                      todayButton='Today'
                      showMonthDropdown
                    />
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button className='btn btn-primary' onClick={this.toggle}>
              Cancel
            </button>
            <button className='btn btn-primary' onClick={(e) => this.filteredData(e)}>
              Next
            </button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    listData: state.reports.noBidReportList,
    listPref: state.reports.noBidReportListPref,
    projectRoles: state.projectRoleType,
    projectFields: state.projectFields,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    GetNoBidReportAction,
    GetNoBidReportPrefAction,
    SetNoBidReportPrefAction,
    getAllProjectRoleAction,
    getAllProjectFieldsAction,
  })(NoBidReport)
);
