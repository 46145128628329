import { formatMoney } from '../../../../utils/number-formatters';
import coStyles from '../../summary-financial-modal.module.scss';

type StatusProps = {
  projectData: any;
  changeOrders: Array<any>;
  onAfterSubmit?: any;
};

export const Status = ({ projectData = {}, changeOrders = [] }: StatusProps) => {
  const { originalContractAmount, contractAmount } = projectData;
  const PCOsSum = changeOrders
    .filter((co) => co.isPCO && !co.notApproved)
    .reduce((acc, co) => acc + co.coTotalAmount, 0);

  return (
    <table className={`${coStyles.coTable} ${coStyles.statusTable}`}>
      <thead>
        <tr>
          <th colSpan={2}>Contract Status</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Original Contract Amount</td>
          <td>{formatMoney(originalContractAmount)}</td>
        </tr>
        <tr>
          <td>Contract Revisions</td>
          <td>{formatMoney(contractAmount - originalContractAmount)}</td>
        </tr>
        <tr>
          <td>
            <b>Current Contract Amount</b>
          </td>
          <td>{formatMoney(contractAmount)}</td>
        </tr>
        <tr>
          <td>Including PCOs</td>
          <td>{formatMoney(contractAmount + PCOsSum)}</td>
        </tr>
      </tbody>
    </table>
  );
};
