import * as actionTypes from '../../actions/Admin/actionTypes';

const initState = {
  contactRole: {},
  allContactRole: [],
  message: '',
  isCreated: false,
  success: null,
};

export default (state = initState, action = {}) => {
  switch (action.type) {
    case actionTypes.CREATE_CONTACT_ROLE_REQUEST:
      return {
        ...state,
        contactRole: {},
        message: '',
      };
    case actionTypes.CREATE_CONTACT_ROLE_SUCCESS:
      return {
        allContactRole: state.allContactRole.concat(action.payload.response.result.data),
        isCreated: true,
        success: action.payload.response.result.success,
        message: action.payload.response.result.success
          ? 'Record Added successfully'
          : action.payload.response.result.msg,
      };

    case actionTypes.CREATE_CONTACT_ROLE_ERROR:
      return {
        ...state,
        isCreated: false,
        success: action.payload.error.success,
        message: action.payload.error.msg,
      };

    case actionTypes.GET_ALL_CONTACT_ROLE_REQUEST:
      return {
        ...state,
        contactRole: {},
        message: '',
      };

    case actionTypes.GET_ALL_CONTACT_ROLE_SUCCESS:
      return {
        ...state,
        allContactRole: action.payload.response.result.data.data,
        message: '',
      };

    case actionTypes.GET_ALL_CONTACT_ROLE_ERROR:
      return {
        ...state,
        message: '',
      };

    case actionTypes.UPDATE_CONTACT_ROLE_REQUEST:
      return {
        ...state,
        contactRole: {},
        message: '',
      };
    case actionTypes.UPDATE_CONTACT_ROLE_SUCCESS:
      return {
        ...state,
        allContactRole: action.payload.response.result.contactRoles,
        isCreated: true,
        success: action.payload.response.result.success,
        message: action.payload.response.result.success
          ? 'Record Updated Successfully'
          : action.payload.response.result.msg,
      };

    case actionTypes.UPDATE_CONTACT_ROLE_ERROR:
      return {
        ...state,
        isCreated: false,
        success: action.payload.error.success,
        message: action.payload.error.msg,
      };

    case actionTypes.DELETE_CONTACT_ROLE_REQUEST:
      return {
        ...state,
        contactRole: {},
        message: '',
      };
    case actionTypes.DELETE_CONTACT_ROLE_SUCCESS:
      return {
        ...state,
        //  allContactRole: state.allContactRole,action.payload.response.result.roleType
        allContactRole: action.payload.response.result.contactRoles,
        isCreated: true,
        success: action.payload.response.result.success,
        message: action.payload.response.result.success
          ? 'Record Deleted Successfully'
          : action.payload.response.result.msg,
      };

    case actionTypes.DELETE_CONTACT_ROLE_ERROR:
      return {
        ...state,
        isCreated: false,
        success: action.payload.error.success,
        message: action.payload.error.msg,
      };

    default:
      return state;
  }
};
