import * as actionTypes from './actionTypes';
import * as API from '../api/projectFields';


export const getAllProjectFieldsRequest = () => ({
  type: actionTypes.GET_ALL_PROJECT_FIELDS_REQUEST,
});

export const getAllProjectFieldsSuccess = (response) => ({
  type: actionTypes.GET_ALL_PROJECT_FIELDS_SUCCESS,
  payload: {
    response,
  },
});

export const getAllProjectFieldsError = (error) => ({
  type: actionTypes.GET_ALL_PROJECT_FIELDS_ERROR,
  payload: {
    error,
  },
});

export const getAllProjectFieldsAction = (data) => {
  return (dispatch) => {
    dispatch(getAllProjectFieldsRequest());
    return API.getAllProjectFields(data)
      .then((response) => {
        dispatch(getAllProjectFieldsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          getAllProjectFieldsError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

