import { FormRadio } from '../../form-controls/form-radio';
import { CustomFieldsCommonProps } from '../constants';

type CustomRadioProps = {
  options: any[];
  direction?: 'horizontal' | 'vertical';
} & CustomFieldsCommonProps;

export const CustomRadio = ({ options = [], direction, ...props }: CustomRadioProps) => {
  return <FormRadio {...props} direction={direction} options={options} />;
};
