import React, { Suspense } from 'react';
import { Button } from 'react-bootstrap';
import axios from 'axios';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Header from '../Layout/Header.js';
import Footer from '../Layout/Footer.js';
import { appConstants } from '../../_constants';
import './../../index.css';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

import DatePickerWrapper from '../../shared/datepicker-wrapper/datepicker-wrapper';
import moment from 'moment';

import { connect } from 'react-redux';
import {
  createProjectTasksAction,
  getAllProjectTasksAction,
  updateProjectTasksAction,
  deleteProjectTasksAction,
} from '../../actions/projectTasks';
import { getAllProjectAction } from '../../actions/project';
import { withRouter } from 'react-router-dom';
import TitleComponent from "../Common/TitleComponent";
import CustomTable from '../Common/CustomTable.jsx';

import { onCurrency, onDate, onDateTime, onText } from '../../utils/column-formatters';
//UNUSED

// eslint-disable-next-line no-undef
let Validator = require('validatorjs');
let formArr = {};
let rules = {
  taskName: 'required',
  startDT: 'required',
  endDT: 'required',
  status: 'required',
  comment: 'required',
  projectId: 'required',
};
let mess = {
  required: 'This field is required',
  alpha: 'This field may only contain letters',
  numeric: 'This field must be a number',
};
let validation = [];
validation = new Validator([], rules, mess);
validation.passes();
validation.fails();

class ProjectTask extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hidden: true, // for use objectid hide in table
      sessionToken: localStorage.getItem('sessionToken'),
      userId: localStorage.getItem('userId'),
      accountId: localStorage.getItem('accountId'),
      list: [],
      formArr: [],
      modal: false,
      saveType: 'POST',
      optionStatus: [],
      optionProject: [],
      selectBox: {},
      formData: {},
      successUploading: true,
      errorUploading: true,
      message: '',
    };
    this.toggle = this.toggle.bind(this);
  }

  UNSAFE_componentWillMount() {
    var optionStatus = this.state.optionStatus;
    appConstants.TaskStatusOption.map((v) => {
      if (v.objectId !== '' && v.username !== '') {
        optionStatus.push({ value: v.name, label: v.name });
      }
      this.setState({ optionStatus });
      return true;
    });

    this.list();
    this.getProjectList();
  }

  componentDidMount() {
    this.list();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ list: nextProps.tasks });
    this.setState({ optionProject: nextProps.allProjects });
  }

  addProjectTask(event) {
    event.preventDefault();
    let formData = this.state.formData;
    formData['status'] = this.state.formData.status.value;
    formData['userId'] = this.state.userId;
    formData['accountId'] = this.state.accountId;
    formData['assignTo'] = this.state.userId;
    formData['projectId'] = 'EeZ1MfZNfu';
    formData['trash'] = true;

    if (this.state.saveType === 'POST') this.props.createProjectTasksAction(formData);
    else {
      this.props.updateProjectTasksAction(formData);
    }
    this.setState({ modal: !this.state.modal });
  }

  toggle(val) {
    if (val === 'POST') {
      this.setState({
        formData: {},
      });
    } else {
      var selectBox = this.state.selectBox;
      this.state.optionStatus.map((v) => {
        if (v.value !== '') {
          if (v.value === this.state.formData.UserFieldsType) {
            selectBox.UserFieldsType = { value: v.value, label: v.label };
          }
        }
        return true;
      });
      this.setState({ selectBox: selectBox, saveType: 'PUT' });
    }
    this.setState({ modal: !this.state.modal });
  }

  list() {
    this.props.getAllProjectTasksAction({ where: { projectId: 'EeZ1MfZNfu' } });
  }

  handleChange = (e) => {
    e.preventDefault();
    let formData = this.state.formData;
    formData[e.target.name] = e.target.value;
    this.setState({ formData });
  };

  allValidate(check) {
    if (!check) {
      formArr = [];
      Object.keys(rules).forEach(function (key) {
        formArr[key] = 'TT';
      });
      this.setState({
        formArr,
      });
    }
    if (validation.passes()) {
      return 1;
    }
  }

  getProjectList() {
    let data = {};
    data['accountId'] = 'vDdJ2GbHtV';
    data['userId'] = this.state.userId;
    this.props.getAllProjectAction(data);
  }

  handleChange = (e) => {
    e.preventDefault();
    let formData = this.state.formData;
    formData[e.target.name] = e.target.value;
    this.setState({ formData });
  };

  onClickProductSelected(cell, row, rowIndex) {
    this.setState({
      formData: row,
    });
    this.toggle('PUT');
  }
  cellButton(cell, row, enumObject, rowIndex) {
    return (
      <i
        className="fa fa-pencil-square-o"
        aria-hidden="true"
        onClick={() => this.onClickProductSelected(cell, row, rowIndex)}
      ></i>
    );
  }
  showStatus(cell, row) {
    //console.log(row);
    return row.status; // Show the Status label
  }
  showProjectName(cell, row) {
    return row.projectId.className; // Show the Project Name
  }
  showStartDT(cell, row) {
    return moment(row.startDT).format(appConstants.DATEFORMAT); // Show the DATEFORMAT
  }
  showEndDT(cell, row) {
    return moment(row.endDT).format(appConstants.DATEFORMAT); // Show the DATEFORMAT
  }

  dateFormate(cell, row, enumObject, rowIndex) {
    return (
      <i
        className="fa fa-pencil-square-o"
        aria-hidden="true"
        onClick={() => this.onClickProductSelected(cell, row, rowIndex)}
      ></i>
    );
  }
  createCustomDeleteButton = (onBtnClick) => {
    return "<button style={ { color: 'red' } } onClick={ onBtnClick }>Delete it!!!</button>";
  };

  handleDeleteButtonClick = (rowKeys) => {
    let tifOptions = [];
    console.log(rowKeys);
    tifOptions = Object.keys(rowKeys).map(function (key) {
      return {
        method: 'PUT',
        path: `/parse/classes/projectTask/${rowKeys[key]}`,
        body: { trash: true },
      };
    });
    console.log(tifOptions);
    axios({
      method: 'POST',
      url: appConstants.WEB_SERVICE_URL + '/batch',
      headers: this.state.headers,
      data: {
        requests: tifOptions,
      },
    })
      .then((response) => {
        if (response && response.data) {
          this.list();
          console.log(response);
          this.setState({
            successUploading: false,
          });
        }
      })
      .catch((error) => {
        const myObjStr = JSON.parse(error.request.response);
        this.setState({
          errorUploading: false,
          message: myObjStr.error,
        });
      });
  };

  //About delete
  deleteButton = (onBtnClick) => {
    return (
      <btn className="btn btn-danger" onClick={(e) => this.handleDeleteButtonClick(onBtnClick)}>
        Delete Account
      </btn>
    );
  };

  handleChangeProjects = (projects) => {
    let field = this.state.formData;
    field['projectId'] = projects;
    this.setState({
      formData: field,
    });
  };

  handleChangeStatus = (status) => {
    let field = this.state.formData;
    field['status'] = status;
    this.setState({
      formData: field,
    });
  };

  handleDatePickerStart = (date) => {
    let field = this.state.formData;
    field['startDT'] = date;
    this.setState({
      formData: field,
      dateStart: date.format(appConstants.DATEFORMAT),
    });
  };

  handleDatePickerEnd = (date) => {
    let field = this.state.formData;
    field['endDT'] = date;
    this.setState({
      formData: field,
      dateEnd: date.format(appConstants.DATEFORMAT),
    });
  };

  render() {
    let styleData = { color: 'red' };
    validation = new Validator(this.state.formData, rules, mess);
    validation.passes();
    validation.fails();

    const selectRowProp = {
      mode: 'checkbox',
    };

    var classTitle = '';
    if (this.state.formData.objectId) {
      classTitle = 'Edit Task';
    }
    if (!this.state.formData.objectId) {
      classTitle = 'Add Task';
    }

    let tableOption = appConstants.TableOption;
    tableOption['afterDeleteRow'] = this.handleDeleteButtonClick;
    tableOption['paginationShowsTotal'] = this.renderShowsTotal;

    const columns = [
      { id: 'Task Name', Header: 'Task Name', accessor: 'taskName' },
      { id: 'Project Name', Header: 'Project Name', accessor: 'projectName' },
      { id: 'Start Date', Header: 'Start Date', accessor: 'startDate', Cell: ({ column, row, value }) => <>{onDateTime(column.id, row.original, value)}</> },
      { id: 'End Date', Header: 'End Date', accessor: 'endDate', Cell: ({ column, row, value }) => <>{onDateTime(column.id, row.original, value)}</> },
      { id: 'Status', Header: 'Status', accessor: 'status' },
      { id: 'Created On', Header: 'Created On', accessor: 'createdAt', Cell: ({ column, row, value }) => <>{onDateTime(column.id, row.original, value)}</> },
      { id: 'Comment', Header: 'Comment', accessor: 'comment' },
      { id: 'Action', Header: 'Action', accessor: '', Cell: ({ column, row }) => <>{this.cellButton(column, row)}</> },
    ];

    return (
      <div className="ContactListReport">
        <TitleComponent data={{ title: `Project Tasks` }} />
        <Suspense fallback="loading"><Header props={this.props} /></Suspense>
        <div className="">
          <div className="container">
            <div id="" className="tab-content" role="tablist">
              <div>
                <div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="pageinfo">
                        <h2> Task List </h2>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div className="row">
                      <div className="col-md-12">
                        <Button
                          className="btn btn-primary addButton"
                          onClick={() => this.toggle('POST')}
                        >
                          <i className="fa fa-plus"></i> Add
                        </Button>
                        <Button
                          className="btn btn-primary addButton"
                          onClick={() => this.handleDeleteButtonClick(this.state.selectBox)}
                        >
                          <i className="fa fa-trash"></i> Delete
                        </Button>
                        <CustomTable
                          columns={columns.filter(Boolean)}
                          list={this.state.list}
                          inlineEdit={false}
                          canShowExport={false}
                          canShowPagination={true}
                          canShowGlobalFilter={false}
                          canShowColumnSettings={false}
                          canShowDateFilter={false}
                          canShowRowSelection={true}
                          useControlledState={(state) => ({ ...state })}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />

        <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
          <ModalHeader toggle={this.toggle}>{classTitle}</ModalHeader>
          <ModalBody>
            <div className={'alert alert-danger ' + (this.state.errorUploading ? 'd-none' : '')}>
              <strong>{this.state.message}!</strong>
            </div>
            <div className={'alert alert-success ' + (this.state.successUploading ? 'd-none' : '')}>
              <strong>Record successfully Added !</strong>
            </div>
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label htmlFor="taskName"> Task Name </label>
                    <input
                      type="text"
                      className="form-control"
                      name="taskName"
                      id="taskName"
                      value={this.state.formData.taskName}
                      onChange={(e) => {
                        this.handleChange(e);
                      }}
                    />
                    <span style={styleData}>
                      {this.state.formArr.taskName && validation.errors.first('taskName')}
                    </span>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="taskName"> Project Name </label>
                    <Select
                      name="projectId"
                      components={makeAnimated()}
                      value={this.state.formData.projectId}
                      onChange={this.handleChangeProjects}
                      options={this.state.optionProject}
                    />
                    <span style={styleData}>
                      {this.state.formArr.projectId && validation.errors.first('projectId')}
                    </span>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="startDT"> Start Date and Time </label>
                    <DatePickerWrapper
                      className="form-control"
                      selected={moment(this.state.formData.startDT)}
                      onChange={this.handleDatePickerStart}
                      showTimeSelect
                      timeFormat="h:mm a"
                      timeIntervals={15}
                      dateFormat={appConstants.DATEFORMATPICKER}
                      timeCaption="time"
                      placeholderText="Click to select a date"
                      selectsEnd
                      todayButton="Today"
                      showMonthDropdown
                      startDate={moment(this.state.formData.startDT)}
                      endDate={moment(this.state.formData.endDT)}
                    />
                    <span style={styleData}>
                      {this.state.formArr.startDT && validation.errors.first('startDT')}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="endDT"> End Date and Time</label>
                    <DatePickerWrapper
                      className="form-control"
                      selected={moment(this.state.formData.endDT)}
                      onChange={this.handleDatePickerEnd}
                      showTimeSelect
                      timeFormat="h:mm a"
                      timeIntervals={15}
                      dateFormat={appConstants.DATEFORMATPICKER}
                      timeCaption="time"
                      placeholderText="Click to select a date"
                      selectsEnd
                      todayButton="Today"
                      showMonthDropdown
                      startDate={moment(this.state.formData.startDT)}
                      endDate={moment(this.state.formData.endDT)}
                    />

                    <span style={styleData}>
                      {this.state.formArr.endDT && validation.errors.first('endDT')}
                    </span>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="status">Status</label>
                    <Select
                      name="status"
                      components={makeAnimated()}
                      value={this.state.formData.status}
                      onChange={this.handleChangeStatus}
                      options={this.state.optionStatus}
                    />
                    <span style={styleData}>
                      {this.state.formArr.status && validation.errors.first('status')}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label htmlFor="endDT"> Comment </label>
                    <textarea
                      className="form-control"
                      rows="5"
                      name="comment"
                      id="endDT"
                      value={this.state.formData.comment}
                      onChange={(e) => {
                        this.handleChange(e);
                      }}
                    ></textarea>
                    <span style={styleData}>
                      {this.state.formArr.comment && validation.errors.first('comment')}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button className="btn btn-primary" onClick={this.toggle}>
              Cancel
            </Button>
            <Button className="btn btn-primary" onClick={(e) => this.addProjectTask(e)}>
              Submit
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    allProjects: state.project.allProjects,
    tasks: state.projectTasks.tasks,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    createProjectTasksAction,
    getAllProjectTasksAction,
    updateProjectTasksAction,
    deleteProjectTasksAction,
    getAllProjectAction,
  })(ProjectTask)
);
