import { useEffect, useState } from 'react';
import { Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { getAllBuildingTypeAction } from '../../../actions/buildingType';
import { selectOptionTemplateBuildingType } from '../../../selectors/buildingType';
import { useAppDispatch } from '../../../shared/hooks/use-app-dispatch';
import { useAppSelector } from '../../../shared/hooks/use-app-selector';
import { FormProvider, useForm } from 'react-hook-form';
import FormInput from '../../../shared/form-controls/form-input';
import FormSelect from '../../../shared/form-controls/form-select';
import { HorizontalLayout } from '../../../shared/form-controls/control-layouts/horizontal-layout';

type ClientReferenceReportModalProps = {
  open: boolean;
  onClose: () => unknown;
  onSubmit: (data) => unknown;
  data: any;
};

export const ClientReferenceReportModal = ({ open, onClose, onSubmit, data, }: ClientReferenceReportModalProps) => {
  const form = useForm();
  const { control, handleSubmit, reset, } = form;
  const dispatch = useAppDispatch();
  const [period, setPeriod] = useState<string>('');
  const optionTemplateBuildingType = useAppSelector(selectOptionTemplateBuildingType);

  useEffect(() => {
    if (!data) return;

    reset(data);
    setPeriod(data.period);
  }, [data, open]);

  useEffect(() => {
    if (!open) return;

    dispatch(getAllBuildingTypeAction({ className: 'buildingTypes' }));
  }, [dispatch, open]);

  const handleChange = (e) => {
    setPeriod(e.target.value);
    form.setValue('period', e.target.value);
  };

  return (
    <Modal
      backdrop='static'
      keyboard
      isOpen={open}
      toggle={onClose}
    >
      <ModalHeader toggle={onClose}>Select</ModalHeader>
      <ModalBody>
        <FormProvider {...form}>
          <div className='container'>
            <div className='form-group'>
              <div className='row'>
                <div className='col-md-12'>
                  <label>
                    <strong>1. Choose</strong>
                  </label>
                </div>
              </div>
              <div className='row'>
                <div className='col-md-12'>
                  <Input
                    type='radio'
                    name='period'
                    value={'active&delivered'}
                    checked={period === 'active&delivered'}
                    onClick={handleChange}
                  />{' '}
                  Show Active and Delivered Jobs Only
                </div>
              </div>
              <div className='row'>
                <div className='col-md-12'>
                  <Input
                    type='radio'
                    name='period'
                    value={'active'}
                    checked={period === 'active'}
                    onClick={handleChange}
                  />{' '}
                  Show Active Jobs Only
                </div>
              </div>
              <div className='row'>
                <div className='col-md-12'>
                  <Input
                    type='radio'
                    name='period'
                    value={'delivered'}
                    checked={period === 'delivered'}
                    onClick={handleChange}
                  />{' '}
                  Show Delivered Jobs Only
                </div>
              </div>
             <br/>
            </div>
            <div className='form-group'>
              <div className='row'>
                <div className='col-md-12 mb-1'>
                  <label>
                    <strong>2. (Optional):</strong> To limit the report to a particular building
                    type, select the building type from the list.
                  </label>
                </div>
              </div>
              <FormSelect
                label='Select building type'
                name='buildingTypeCode'
                control={control}
                options={optionTemplateBuildingType}
                Layout={HorizontalLayout}
                isClearable
              />
            </div>
            <div className='form-group'>
              <div className='row'>
                <div className='col-md-12 mb-1'>
                  <label>
                    <strong>3. (Optional):</strong> In the field at the right, enter any minimum
                    criteria for selecting jobs for this report.
                  </label>
                </div>
              </div>
            </div>
            <FormInput
              label='Size (sq.ft.)'
              name='size'
              control={control}
              placeholder='0'
              Layout={HorizontalLayout}
              type={'number'}
            />
            <FormInput
              label='Contract ($)'
              type={'number'}
              name='contractAmount'
              control={control}
              placeholder='0'
              Layout={HorizontalLayout}
            />
            <FormInput
              label='Year Built'
              type={'number'}
              name='year'
              control={control}
              placeholder='0'
              Layout={HorizontalLayout}
            />
          </div>
        </FormProvider>
      </ModalBody>
      <ModalFooter>
        <button className='btn btn-primary' onClick={onClose}>
          Cancel
        </button>
        <button className='btn btn-primary' onClick={handleSubmit(onSubmit)}>
          Next
        </button>
      </ModalFooter>
    </Modal>
  );
};
