import React, { useState, useEffect } from 'react';
import { CustomTable } from '../../Common/CustomTable';
import { SelectColumnFilter } from '../../Common/CustomTableComponents/SelectColumnFilter';
import { DateColumnFilter } from '../../Common/CustomTableComponents/DateColumnFilter';
import { appConstants } from '../../../_constants';
import loaderImage from '../../static/images/loading_spinner.gif';
import axios from 'axios';
import TitleComponent from "../../Common/TitleComponent";
import { formatDateObjectOrNull } from '../../../utils/date-formatters.js';

const UserList = () => {
    const [userId] = useState(localStorage.getItem('userId'));
    const [list, setList] = useState([]);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(500);
    const [total, setTotal] = useState(0);

    useEffect(() => {
        getDataList();
        getDataListCount();
    }, [page, limit]);

    const getDataList = async () => {
        // TODO: setup axios and redux toolkit
        setShowProcessing(true);
        const { data } = await axios.post(
            'admin/adminUsersData',
            {
                adminId: userId,
            },
            {
                baseURL: appConstants.WEB_SERVICE_URL_WITHOUT_PARSE,
                headers: {
                    'X-Parse-Application-Id': appConstants.PARSE_APPLICATION_ID,
                    'X-Parse-REST-API-Key': appConstants.PARSE_REST_API_KEY,
                    'X-Parse-Session-Token': localStorage.getItem('sessionToken'),
                    authorization: localStorage.getItem('JWTTOKEN'),
                },
                params: {
                    limit: 9999,
                    offset: (page - 1) * limit,
                },
            },
        );
        setShowProcessing(false);

        setList(data);
    };

    const getDataListCount = async () => {
        // TODO: setup axios and redux toolkit
        const { data } = await axios.post(
            'admin/adminUsersTotalCount',
            {
                adminId: userId,
            },
            {
                baseURL: appConstants.WEB_SERVICE_URL_WITHOUT_PARSE,
                headers: {
                    'X-Parse-Application-Id': appConstants.PARSE_APPLICATION_ID,
                    'X-Parse-REST-API-Key': appConstants.PARSE_REST_API_KEY,
                    'X-Parse-Session-Token': localStorage.getItem('sessionToken'),
                    authorization: localStorage.getItem('JWTTOKEN'),
                },
            },
        );

        setTotal(data.total);
    };

    const onPageChange = (_, page) => {
        setPage(page);
    };

    const onLimitChange = (e) => {
        setLimit(e.target.value);
        setPage(1);
    };

    const formatDateCell = (cell, row, accessor) => {
        if (row && row[accessor]) {
            return (
                <div>{row[accessor] ?? ''}</div>
            );
        } else {
            return <span>-</span>;
        }
    };

    const formatBooleanCell = (cell, row, accessor) => {
        if (row && row[accessor]) {
            return (
                <div>{row[accessor] == true ? 'true' : 'false'}</div>
            );
        } else {
            return <span>-</span>;
        }
    };

    const columns = [
        {
            Header: 'UserId',
            accessor: '_id',
            width: 60,
            hidden: true,
        },
        {
            Header: 'First Name',
            accessor: 'firstName',
            width: 50,
        },
        {
            Header: 'Last Name',
            accessor: 'lastName',
            width: 50,
        },
        {
            Header: 'User Email',
            accessor: 'userEmail',
            Filter: SelectColumnFilter,
            width: 80,
        },
        {
            Header: 'User Title',
            accessor: 'title',
            width: 80,
        },
        {
            Header: 'Account Name',
            accessor: 'cs.companyName',
            Filter: SelectColumnFilter,
            width: 80,
        },
        {
            Header: 'Account Id',
            accessor: 'cs._id',
            Filter: SelectColumnFilter,
            width: 60,
        },
        {
            Header: 'DateCreated',
            id: 'createdDate',
            accessor: (row) =>
                row?.createdAt
                    ? new Date(row.createdAt)
                    : formatDateObjectOrNull(row.lastLogin),
            width: 90,
            filter: DateColumnFilter,
            allowDateRangeFilter: true,
            Cell: (props) => (
                <>{formatDateCell(props.column.id, props.row.original, 'createdAt')}</>
            ),
        },
        {
            Header: 'Last Login',
            id: 'lastLogin',
            accessor: (row) =>
                row?.lastLogin
                    ? new Date(row.lastLogin)
                    : formatDateObjectOrNull(row.lastLogin),
            width: 90,
            filter: DateColumnFilter,
            allowDateRangeFilter: true,
            Cell: (props) => (
                <>{formatDateCell(props.column.id, props.row.original, 'lastLogin')}</>
            ),
        },
        {
            Header: 'Verified',
            id: 'userEmailActivation',
            accessor: (row) => (row?.userEmailActivation ? 'true' : 'false'),
            width: 80,
            Filter: SelectColumnFilter,
            Cell: (props) => (
                <>{formatBooleanCell(props.column.id, props.row.original, 'userEmailActivation')}</>
            ),
        },
    ];
    

    const pagesCount = Math.floor(total / limit) + 1;
    const [showProcessing, setShowProcessing] = useState(false);
    const [listDataPref, setListDataPref] = useState({});

    return (
        <div>
            <TitleComponent data={{ title: `Administration - All Users` }} />
            <div className='noteProcessing' style={{ display: showProcessing ? 'block' : 'none' }}>
                <img src={loaderImage} alt='Loading' />
            </div>
            <div className='row'>
                <div className='col-md-12 sortable-table'>
                    <CustomTable
                        columns={columns}
                        list={list}
                        preferences={listDataPref}
                        className='user-list-table'
                        customTableTitle={'User List'}
                        canShowDateFilter={true}
                        //onChangeRowSelection={this.onChangeRowSelection}
                        //onSavePreferences={this.props.SetAdminUserListReportPrefAction}
                        isLoading={showProcessing}
                        canShowHeaderItems={true}
                        canShowRowSelection={false}
                        pageSizeArray={[50, 100, 200]}
                        //componentRef={this.componentRef}
                    />
                </div>
            </div>
        </div>
    );
};

export default UserList;
