import * as actionTypes from '../actions/actionTypes';

const initState = {
  allProjectFieldTypes: [],
  message: '',
  isfetched: false,
  isCreated: false,
  success: null,
};

export default (state = initState, action = {}) => {
  switch (action.type) {
    case actionTypes.GET_ALL_PROJECT_FIELDS_REQUEST:
      return {
        ...state,
        allProjectFieldTypes: [],
        message: '',
      };

    case actionTypes.GET_ALL_PROJECT_FIELDS_SUCCESS:
      return {
        ...state,
        allProjectFieldTypes: action.payload.response.result.projectFields,
        message: '',
      };

    case actionTypes.GET_ALL_PROJECT_FIELDS_ERROR:
      return {
        ...state,
        message: '',
      };

    default:
      return state;
  }
};
