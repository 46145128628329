import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { getTermsConditionAction } from '../../actions/account';
import { selectNavLinkAction } from '../../actions/sidebar.js';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import bannerImage from '../static/images/cpostLogo.png';
import { formatDateYear } from '../../utils/date-formatters';

function Footer(props) {
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const [contactUsModal, setContactUsModal] = useState(false);
  const [mailSubject, setMailSubject] = useState('');
  const mailTo = 'success@commandtech.io';
  const dispatch = useDispatch();
  const location = useLocation();

  const handleClick = (event, index) => {
    dispatch(selectNavLinkAction(index));
  };

  const contactUsMail = () => {
    setContactUsModal(true);
  };

  const toggle = () => {
    setContactUsModal(!contactUsModal);
  };

  const mailForSub = (e) => {
    setMailSubject(e.target.value);
    if (e.target.value) {
      window.location.assign('mailto:' + mailTo + '?subject=' + e.target.value);
      toggle();
    }
  };

  const onRefresh = () => {
    // window.location.reload();
  };

  return (
    <footer>
      <div className="container no-print">
        <div className="row">
          <div className="col-sm-6 d-flex align-items-center">
            <div className="footLeft">
              <ul className="clearfix social">
                <li>
                  <a onClick={contactUsMail}>Contact us</a>
                </li>
                <li>
                  <a href="https://www.commandpost.ai/terms" target="_blank">
                    Terms and conditions
                  </a>
                </li>
                <li>
                  <a href="https://www.commandpost.ai/privacy" target="_blank">
                    Privacy policy
                  </a>
                </li>

              </ul>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="footRight clearfix">
              <div className="footerLogo">
                <img
                  src={
                    localStorage.getItem('companyLogo') === undefined
                      ? localStorage.getItem('companyLogo')
                      : bannerImage
                  }
                  alt="Command Post Logo"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright no-print">
        <div className="container">
          &copy; 1997-{formatDateYear()} Command Technologies, Inc. All rights reserved.
        </div>
      </div>

      <Modal
        backdrop="static"
        keyboard={true}
        isOpen={contactUsModal}
        toggle={toggle}
        className={props.className}
      >
        <ModalHeader toggle={toggle}>Contact Us</ModalHeader>
        <ModalBody>
          <div className="col-md-12 row">
            <p>If you have any questions or concerns, feel free to reach out to us:</p>
            <div className="footContactPopup checkboxinline">
              <center>
                <label>
                  <input
                    className="sales-input"
                    type="radio"
                    name="mailSub"
                    id="sales"
                    value="Sales"
                    onClick={mailForSub}
                  />
                  Sales
                </label>

                <label>
                  <input
                    className="problem-input"
                    type="radio"
                    name="mailSub"
                    id="problem"
                    value="Report a problem"
                    onClick={mailForSub}
                  />
                  Report a problem
                </label>

                <label>
                  <input
                    className="feature-input"
                    type="radio"
                    name="mailSub"
                    id="feature"
                    value="Suggest new feature"
                    onClick={mailForSub}
                  />
                  Suggest new feature
                </label>
              </center>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button className="btn btn-primary" onClick={toggle}>
            Cancel
          </button>
        </ModalFooter>
      </Modal>
    </footer>
  );
}

function mapStateToProps(state) {
  return {
    termsResponse: state.account.termsResponse,
  };
}

export default Footer;
