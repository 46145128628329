import { notification } from 'antd';
import { useEffect, useMemo, useState, forwardRef, useImperativeHandle } from 'react';
import { FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { getContactDetailAction, getContactDetailsActionBasic } from '../../actions/contact';
import { AffiliationsSection, useAffiliationForm } from '../../forms/affiliation-form';
import { useContactCustomFieldsForm } from '../../forms/affiliation-form';
import { UpdateContactForm, useUpdateContactForm } from '../../forms/update-contact-form';
import { getCompanyContactAffiliations } from '../../modules/companies';
import {
  clearGetContactAffiliations,
  clearSaveContact,
  contactRolesDropdown,
  removeContactPicture,
  saveContact,
  saveContactAffiliations,
} from '../../modules/contacts';
import { selectSaveContact } from '../../modules/contacts/selectors';
//import { LogoList } from '../../shared/logo-list';
import { FormerAffiliationsModal } from '../former-affiliations-modal';
import { SearchAffiliationModal } from '../search-affiliation-modal';
import { Userpilot } from 'userpilot';
import { MatchingContactsProvider } from '../../forms/affiliation-form/matching-contacts-context';
import { ConfirmMatchingContactsModal } from './confirm-matching-contacts-modal';
import moment from 'moment';
import { strictPhoneArray } from '../../utils/formatters';

type AddContactModalProps = {
  open: boolean;
  onClose: () => unknown;
  contact: any;
  isAddToCompany: boolean;
  isQuickAdd: boolean;
  makeEmployee?: boolean;
  preAffiliations?: Array<string>;
  isImport?: boolean;
  onSubmit: (arg?) => Promise<unknown>;
  initialSection?: string;
};

const AddContactModal = forwardRef(
  (
    {
      open = false,
      onClose = () => void 0,
      isAddToCompany = false,
      contact: propsContact = null,
      makeEmployee = false,
      isQuickAdd = false,
      preAffiliations = [],
      isImport = false,
      onSubmit = () => Promise.resolve(),
      initialSection = '', //onSubmit = (arg) => Promise.resolve(),
    }: AddContactModalProps,
    ref
  ) => {
    const { data: savedContact } = useSelector(selectSaveContact);
    useImperativeHandle(ref, () => ({ setContact, setAffiliations }), []);

    const [contact, setContactData] = useState(propsContact);

    const dispatch: any = useDispatch();

    const [loadingContact, setLoadingContact] = useState(true);


    useEffect(() => {
      if (propsContact && Object.keys(propsContact).length > 1) {
        setContactData(propsContact);
        setLoadingContact(false);
      } else if (propsContact && propsContact.contactId) {
        (async () => {
          const contactDataLocal = await dispatch(getContactDetailsActionBasic({ type: 'contacts', id: propsContact.contactId }));

          setContactData(prepareContactForModalView(contactDataLocal));
          setLoadingContact(false);
        })();

      }

      else if (savedContact && Object.keys(savedContact).length > 1) {
        setContactData(savedContact);
        setLoadingContact(false);
      }

      else {
        setContactData({});
        setLoadingContact(false);
      }
    }, [savedContact, propsContact]);

    const prepareContactForModalView = (oldContactInfo) => {
      if (oldContactInfo?.objectId||oldContactInfo?._id)
        return {
          _id: oldContactInfo.objectId || oldContactInfo._id,
          phoneArr: strictPhoneArray(oldContactInfo.phoneArr),
          address1: oldContactInfo.address1,
          address2: oldContactInfo.address2,
          city: oldContactInfo.city,
          zipCode: oldContactInfo.zipCode,
          state: oldContactInfo.state,
          country: oldContactInfo.country,
          isEmployee: !!oldContactInfo.isEmployee,
          defaultAccess: !!oldContactInfo.defaultAccess,
          //email: oldContactInfo.email,
          firstName: oldContactInfo.firstName,
          middleName: oldContactInfo.middleName,
          lastName: oldContactInfo.lastName,
          prefix: oldContactInfo.prefix,
          suffix: oldContactInfo.suffix,
          contactTitle: oldContactInfo.contactTitle,
          userType: oldContactInfo.userType?.objectId || oldContactInfo.userType?._id,
          contactRole: oldContactInfo.contactRole?.objectId || oldContactInfo.contactRole?._id,
          venderArr: oldContactInfo.venderArr,
          comments: oldContactInfo.comments,
          userImage: oldContactInfo.userImage,
          dateOfBirthday: oldContactInfo.dateOfBirthday && moment(oldContactInfo.dateOfBirthday.iso),
        };
  
      return null;
    };
    

    const _id = (contact as any)?.objectId || (contact as any)?._id;
    const isUpdate = !!_id;

    const _userImage = isUpdate && contact?.userImage;

    const [saveSubmitting, setSaveSubmitting] = useState(false);
    const [saveAndAddNextSubmitting, setSaveAndAddNextSubmitting] = useState(false);
    const [minimapActiveSection, setMinimapActiveSection] = useState<string>('');

    const [matchingModalOpen, setMatchingModalOpen] = useState(false);
    const [validationData, setValidationData] = useState<any[]>([]);

    useEffect(() => {
      if(open)
      {
        dispatch(contactRolesDropdown());
        dispatch(getCompanyContactAffiliations());
      }
    }, [open]);

    const contactForm = useUpdateContactForm({ contact, isAddToCompany, open });
    
    const affiliationForm = useAffiliationForm({
      open,
      makeEmployee,
      isUpdate,
      isImport,
      contactId: _id,
    });
    const [customFieldsForm, CustomFields] = useContactCustomFieldsForm({
      open,
      affiliationFormWatch: affiliationForm.watch,
    });

    useEffect(() => {
      if (isUpdate) {
        contactForm.reset({ ...contact }, { keepDefaultValues: true });
      }
    }, [contact]);

    const formerAffiliations = affiliationForm.watch('formerAffiliations');
    const affiliationsFetched = affiliationForm.watch('affiliationsFetched');

    const personalAffiliationAddresses = affiliationForm.watch('affiliations.0.addressArr', []);
    const personalAffiliationEmails = affiliationForm.watch('affiliations.0.emailArr', []);
    const personalAffiliationPhones = affiliationForm.watch('affiliations.0.phoneArr', []);

    const [isPersonalInfoVisible, setIsPersonalInfoVisible] = useState(false);

    const doesPersonalInfoExists = useMemo(
      () =>
        personalAffiliationAddresses.length !== 0 ||
        personalAffiliationEmails.length !== 0 ||
        personalAffiliationPhones.length !== 0,
      [personalAffiliationAddresses, personalAffiliationEmails, personalAffiliationPhones]
    );

    const [resetKey, setResetKey] = useState(0);

    useEffect(() => {
      if (affiliationsFetched && initialSection!=='' && initialSection !== minimapActiveSection) {
        setMinimapActiveSection(initialSection);
      }
    }, [affiliationsFetched, initialSection]);

    useEffect(() => {
      if (!open) {
        setIsPersonalInfoVisible(false);
      }
    }, [open]);

    if (doesPersonalInfoExists && !isPersonalInfoVisible) {
      setIsPersonalInfoVisible(true);
    }

    useEffect(() => {
      if (makeEmployee && affiliationsFetched) {
        // if former affiliations, reactivate
        const formerAffiliations = affiliationForm.getValues().formerAffiliations;
        const affiliations = affiliationForm.getValues().affiliations;
        const formerAccountAffiliation =
          formerAffiliations.find((aff) => aff.isAccountAffiliation) || null;
        if (formerAccountAffiliation) {
          formerAccountAffiliation.active = true;
          affiliationForm.setValue('affiliations', [...affiliations, formerAccountAffiliation]);
          formerAffiliations.splice(
            formerAffiliations.findIndex((aff) => aff.isAccountAffiliation),
            1
          );
        }
      }
    }, [makeEmployee, affiliationsFetched]);

    const isShowAddPersonalInfoButton = !isPersonalInfoVisible && !doesPersonalInfoExists;

    const setContact = (contact) => contactForm.reset({ ...contact }, { keepDefaultValues: true });
    const setAffiliations = (affiliations) => {
      affiliationForm.setValue('affiliations', [...affiliations]);
    };

    const handleSubmit = async (isSubmitAndAddNext) => {
      try {
        setSaveAndAddNextSubmitting(isSubmitAndAddNext);
        setSaveSubmitting(!isSubmitAndAddNext);

        let contactData: any = null;
        let affiliationData: any = null;
        let customFieldsData: any = null;

        const { pictureFile, ...data } = contactForm.getValues() as any;
        const { affiliations, formerAffiliations } = affiliationForm.getValues();

        await contactForm.handleSubmit(async (data) => (contactData = data))();
        await affiliationForm.handleSubmit(async (data) => (affiliationData = data))();
        await customFieldsForm.handleSubmit(async (data) => (customFieldsData = data))();

        if (!contactData || !affiliationData || !customFieldsData) {
          return console.log(
            'Error in contact or affiliation',
            contactForm.formState.errors,
            affiliationForm.formState.errors,
            customFieldsForm.formState.errors
          );
        }

        const handleAffiliationFavoriteItems = (items: any) => {
          if (items?.findIndex((e) => e.isPrimary) === -1)
            return items?.map((e, i) => ({ ...e, isPrimary: i === 0 })) || [];

          return items;
        };

        affiliations.forEach((affiliation, index) => {
          affiliation.emailArr = handleAffiliationFavoriteItems(affiliation.emailArr);
          affiliation.phoneArr = handleAffiliationFavoriteItems(affiliation.phoneArr);
          affiliation.addressArr = handleAffiliationFavoriteItems(affiliation.addressArr);

          affiliation.customFields = customFieldsForm.getValues().affiliations[index];
        });

        const { _id } = await dispatch(saveContact({ contactData: data, pictureFile })).unwrap();

        if (_userImage && !data.userImage) {
          await dispatch(removeContactPicture(_id)).unwrap();
        }
        const savedAffiliations = await dispatch(
          saveContactAffiliations({ affiliations, formerAffiliations, contactId: _id })
        ).unwrap();
        await dispatch(getContactDetailAction({ type: 'contacts', id: _id }));
        notification.success({ message: 'Contact saved!' });

        if (!isUpdate) {
          Userpilot.track('Added Contact');
        } else {
          Userpilot.track('Edited Contact');
        }

        isSubmitAndAddNext
          ? (await onSubmit(), clearForm())
          : (await onSubmit(savedAffiliations), onCloseClick());
      } finally {
        setSaveAndAddNextSubmitting(false);
        setSaveSubmitting(false);

        if (isSubmitAndAddNext) {
          contactForm.reset();

          setResetKey((prev) => prev + 1);
        }
      }
    };

    const [submitAndAddNext, setSubmitAndAddNext] = useState(false);

    const onSubmitClick = async (isSubmitAndAddNext) => {
      if (validationData.length) {
        setMatchingModalOpen(true);
        setSubmitAndAddNext(isSubmitAndAddNext);
        return;
      }

      await handleSubmit(isSubmitAndAddNext);
    };

    const onMatchingContactsConfirm = async () => {
      setMatchingModalOpen(false);
      await handleSubmit(submitAndAddNext);
    };

    const clearForm = async () => {
      await dispatch(clearSaveContact());
      await dispatch(clearGetContactAffiliations());
      setIsPersonalInfoVisible(false);
      contactForm.reset({ ...contact }, { keepDefaultValues: true });
      customFieldsForm.reset({ customFields: [] });
      affiliationForm.reset({
        affiliations: [
          {
            active: true,
            addressArr: [],
            emailArr: [],
            isAccountAffiliation: false,
            phoneArr: [],
            selfAffiliated: true,
          },
        ],
        formerAffiliations: [],
        affiliationsFetched: false,
      });
    };

    const onCloseClick = async () => {
      await dispatch(clearSaveContact());
      await dispatch(clearGetContactAffiliations());
      onClose();
    };

    const [formerModalOpen, setFormerModalOpen] = useState(false);

    const saveButtonText = () => {
      return saveSubmitting
        ? isImport
          ? 'Importing'
          : 'Submitting'
        : isImport
          ? 'Import'
          : 'Save';
    };

    return (
      loadingContact ? null :
      <>
        <Modal style={{ maxWidth: 1000, width: '100%' }} isOpen={open} toggle={onCloseClick}>
          <ModalHeader toggle={onCloseClick}>
            {isUpdate ? ('Edit ' +(makeEmployee?'employee':'contact') + ' details') : 'Add '+(makeEmployee?'employee':'contact')}
          </ModalHeader>
          <ModalBody>
            <div className='flex' key={resetKey}>
              <FormProvider {...contactForm}>
                <MatchingContactsProvider onGroupedValidationDataChange={setValidationData}>
                  <UpdateContactForm
                    personalContactInfoToggle={() =>
                      setIsPersonalInfoVisible(!isPersonalInfoVisible)
                    }
                    isShowAddPersonalInfoButton={isShowAddPersonalInfoButton}
                    CustomFields={CustomFields}
                    personalSlot={
                      <FormProvider {...affiliationForm}>
                        <AffiliationsSection
                          isPersonalSlot
                          contactId={_id}
                          isUpdate={isUpdate}
                          isPersonalInfoVisible={isPersonalInfoVisible}
                        />
                      </FormProvider>
                    }
                    searchAffiliationSlot={
                      <FormProvider {...affiliationForm}>
                        <SearchAffiliationModal preAffiliations={preAffiliations} />
                      </FormProvider>
                    }
                    companySlot={
                      <FormProvider {...affiliationForm}>
                        <AffiliationsSection
                          contactId={_id}
                          isUpdate={isUpdate}
                          CustomFields={CustomFields}
                        />

                        <FormerAffiliationsModal
                          open={formerModalOpen}
                          onClose={() => setFormerModalOpen(false)}
                          CustomFields={CustomFields}
                        />
                      </FormProvider>
                    }
                    initialSection={minimapActiveSection}
                  />

                  <ConfirmMatchingContactsModal
                    open={matchingModalOpen}
                    onClose={() => setMatchingModalOpen(false)}
                    onConfirm={onMatchingContactsConfirm}
                  />
                </MatchingContactsProvider>
              </FormProvider>
            </div>
          </ModalBody>
          <ModalFooter>
            {!!formerAffiliations.length && (
              <Button color='primary' onClick={() => setFormerModalOpen(true)}>
                Show former affiliations
              </Button>
            )}

            <div className='d-flex ms-auto justify-content-end p-2'>
              <Button color='secondary' onClick={onCloseClick}>
                Cancel
              </Button>
              <Button
                color='primary'
                className='ms-2'
                onClick={() => onSubmitClick(false)}
                disabled={saveSubmitting || saveAndAddNextSubmitting}
              >
                {saveButtonText()}
              </Button>
              {!isUpdate && !isQuickAdd && (
                <Button
                  color='primary'
                  className='ms-2'
                  onClick={() => onSubmitClick(true)}
                  disabled={saveSubmitting || saveAndAddNextSubmitting}
                >
                  {saveAndAddNextSubmitting ? 'Submitting' : 'Save & Add Next Contact'}
                </Button>
              )}
            </div>
          </ModalFooter>
        </Modal>
      </>
    );
  }
);

export default AddContactModal;
