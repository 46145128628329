import React from 'react';
import history from '../../../routes/history';
import axios from 'axios';
import { appConstants } from '../../../_constants';

import { clearLoggedInUser } from '../../../api/helper.js';

export function useAuthAdmin () {
  const handleAuthentication = () => {
    let user_info = JSON.parse(localStorage.getItem('userInfo'));
    return localStorage.getItem('baseUserRole') === 'SUPERADMIN' &&
      user_info &&
      (user_info.username === 'superadmin@commandtech.io' ||
        user_info.username === 'superadmin+staging@commandtech.io');
  };

  const logout = () => {
    let expiresAt = localStorage.getItem('sessionToken');
    if (expiresAt !== null) {
      clearLoggedInUser();
      axios({
        method: 'POST',
        url: appConstants.WEB_SERVICE_URL + '/logout/',
        headers: {
          'X-Parse-Application-Id': appConstants.PARSE_APPLICATION_ID,
          'X-Parse-REST-API-Key': appConstants.PARSE_REST_API_KEY,
          'X-Parse-Session-Token': localStorage.getItem('sessionToken'),
          authorization: localStorage.getItem('JWTTOKEN'),
        },
      }).then((response) => {
        history.replace('/admin/login');
      });
    }
    history.replace('/admin/login');
  };

  const isAuthenticated = () => {
    let expiresAt = JSON.parse(localStorage.getItem('expires_at'));
    return expiresAt !== null && new Date().getTime() < expiresAt;
  };

  const getAcronymCode = (name) => {
    return name
      ? (
          name[0].substring(0, 1) +
          name[1].substring(0, 1) +
          name.split(' ').slice(-1).join(' ').substring(0, 1)
        ).toUpperCase()
      : '';
  };

  const getTimeZone = (iso_date) => {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let userTimeZone = '';
    if (userInfo.userTimeZone && userInfo.userTimeZone.value && userInfo.userTimeZone.value.name) {
      userTimeZone = userInfo.userTimeZone.value.name;
    } else {
      userTimeZone = 'America/Los_Angeles';
    }
    return userTimeZone;
  };

  return {
    handleAuthentication,
    logout,
    isAuthenticated,
    getAcronymCode,
    getTimeZone,
  };
};

export default useAuthAdmin;
