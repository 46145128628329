import { memo, useCallback, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { CustomFieldControl } from '../../../shared/custom-fields-controls/form-controls/custom-field-control';
import { ViewLayout } from '../../../shared/form-controls/control-layouts/view-layout';

export const CompanyCustomFieldsView = memo(
  ({ fields = [], values = [] }: any) => {
    const customFieldsForm = useForm({ values });
    const processedFields = useMemo(() => {
      const result = fields.map((field: any, index: number) => {
        if (field.controlType === 'DIVIDER') {
          const nextFields = fields.slice(index + 1);
          const nextDividerIndex = nextFields.findIndex((f: any) => f.controlType === 'DIVIDER');
          const fieldsToCheck = nextDividerIndex !== -1 ? nextFields.slice(0, nextDividerIndex) : nextFields;
          const areAllFieldsEmpty = fieldsToCheck.every((f: any) => {
            const value = customFieldsForm.getValues(f._id);
            return !value && value !== false || (value && value.length==0); // Consider false as non-empty for checkboxes
          });
          return { ...field, shouldRender: !areAllFieldsEmpty };
        }
        return field;
      });
      return result;
    }, [fields, customFieldsForm]);
    const renderList = useCallback(
      (processedFields: any[] = []) =>
        processedFields.map((field: any) => {
          const { _id, required, valueDefinition, controlType, shouldRender } = field;
          if (controlType === 'DIVIDER' && shouldRender === false) {
            return null;
          }
          let viewMode = 'text';
          if (valueDefinition.isUrl) {
            viewMode = 'url';
          } else if (controlType === 'CHECKBOX' || controlType === 'RATE') {
            viewMode = 'component';
          }
          const CustomizedLayout = (props: any) => (
            <ViewLayout {...props} viewMode={viewMode} renderMode='table' />
          );
          const isEmptyField = !customFieldsForm.getValues(_id) && !(controlType === 'DIVIDER')
            || ((controlType === 'MULTI_SELECT'||controlType==='SELECT') && (customFieldsForm.getValues(_id)|| []).length==0);
            
          return (
            !isEmptyField && (
              <CustomFieldControl
                Layout={CustomizedLayout}
                key={_id}
                required={required}
                disabled={true}
                fieldDefinition={field}
              />
            )
          );
        }),
      [customFieldsForm]
    );
    return <FormProvider {...customFieldsForm}>{renderList(processedFields)}</FormProvider>;
  }
);