import React from 'react';
import ReactToPrint from 'react-to-print';
import bannerImage from '../static/images/Cobilogo.png';
import { formatDateWithTime,formatDateObject,formatDate } from '../../utils/date-formatters';
import { roundFormatMoney } from '../../utils/number-formatters';

let data1;

class PrintThisLead extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accountId: localStorage.getItem('accountId'),
      userId: localStorage.getItem('userId'),
      list:
        this.props.leadPrint && this.props.leadPrint.leadData ? this.props.leadPrint.leadData : {},
      type:
        this.props.statusType && this.props.statusType.dataType
          ? this.props.statusType.dataType
          : {},
      comLogo: '',
    };
  }
  componentDidMount() {
    if (localStorage.getItem('companyLogo') !== 'undefined')
      this.setState({
        comLogo: localStorage.getItem('companyLogo'),
      });
  }

  UNSAFE_componentWillMount() {
    if (this.state.comLogo === '') {
      this.setState({
        comLogo: bannerImage,
      });
    }
  }

  render() {
    let list = this.state.list;
    let type = this.state.type;

    let clientPhn = '';
    if (type && type.client && type.client.phone && type.client.phone.length > 0) {
      let item = type.client.phone[0];
      clientPhn = (item.ext ? '(' + item.ext + ')' : '') + ' ' + item.phone;
    }

    let ownerPhn = '';
    if (
      type &&
      type.buildingOwner &&
      type.buildingOwner.phone &&
      type.buildingOwner.phone.length > 0
    ) {
      let item = type.buildingOwner.phone[0];
      ownerPhn = ownerPhn + (item.ext ? '(' + item.ext + ')' : '') + ' ' + item.phone;
    }

    let architectPhn = '';
    if (type && type.architect && type.architect.phone && type.architect.phone.length > 0) {
      let item = type.architect.phone[0];
      architectPhn = (item.ext ? '(' + item.ext + ')' : '') + ' ' + item.phone;
    }

    let brokerPhn = '';
    if (type && type.broker && type.broker.phone && type.broker.phone.length > 0) {
      let item = type.broker.phone[0];
      brokerPhn = (item.ext ? '(' + item.ext + ')' : '') + ' ' + item.phone;
    }

    let tenantPhn = '';
    if (type && type.tenant && type.tenant.phone && type.tenant.phone.length > 0) {
      let item = type.tenant.phone[0];
      tenantPhn = (item.ext ? '(' + item.ext + ')' : '') + ' ' + item.phone;
    }

    let accountExec = '';
    if (list && list.accountExecArr && list.accountExecArr.length > 0) {
      let item = list.accountExecArr[0];
      accountExec = item.userFullName;
    }

    return (
      <div className="ContactListReport leadPrint">
        <div className="contactBox">
          <div className="container">
            <div className="reportHead">
              <h2>Lead Worksheet</h2>
              <h4>Job Name: </h4>
              <p>{list.jobName}</p>
            </div>
            <div className="row searchbar plrPD">
              <div className="col-md-6">
                <div className="field-row">
                  <div className="label-name">Acct. Exec.:</div>
                  <div className="projectHistoryJob ph-field">
                    {accountExec ? accountExec : 'NA'}
                  </div>
                </div>
                <div className="field-row">
                  <div className="label-name">Lead Date:</div>
                  <div className="ph-field">
                    {list.leadDate && list.leadDate.iso
                      ? formatDate(list.leadDate.iso)
                      : ''}
                  </div>
                </div>
                <div className="field-row">
                  <div className="label-name">Lead Source:</div>
                  <div className="ph-field">{list.leadSource}</div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="text-right">
                  <div className="field-row ">
                    <div className="label-name">Job Address:</div>
                    <div className="ph-field">
                      {list.jobAddress ? list.jobAddress : ''}{' '}
                      {list.jobAddress2 ? ', ' + list.jobAddress2 : ''}{' '}
                      {list.jobState ? ', ' + list.jobState : ''}
                      {list.jobCity ? ', ' + list.jobCity : ''}{' '}
                      {list.jobZip ? ', ' + list.jobZip : ''}
                    </div>
                  </div>
                  <div className="field-row">
                    <div className="label-name">Occupancy Type:</div>
                    <div className="ph-field">{type && type.occupancyType ? type.occupancyType : 'NA'}</div>
                  </div>
                  <div className="field-row">
                    <div className="label-name">Negotiating Method:</div>
                    <div className="ph-field">
                      {type && type.negotiatingMethod ? type.negotiatingMethod : 'NA'}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row plrPD">
              <div className="col-md-8">
                <div className="welcomeDescription">
                  <div className="">
                    <ul>
                      <li>
                        <strong>Client Contact:</strong>{' '}
                        {type && type.client && type.client.fName && type.client.lName
                          ? type.client.fName + ' ' + type.client.lName
                          : 'NA'}
                        <p>{clientPhn ? clientPhn : 'NA'}</p>
                      </li>
                      <li>
                        <strong>Owner Contact:</strong>{' '}
                        {type &&
                        type.buildingOwner &&
                        type.buildingOwner.fName &&
                        type.buildingOwner.lName
                          ? type.buildingOwner.fName + ' ' + type.buildingOwner.lName
                          : 'NA'}
                        <p>{ownerPhn ? ownerPhn : 'NA'}</p>
                      </li>
                      <li>
                        Architect Contact:
                        {type && type.architect && type.architect.fName && type.architect.lName
                          ? type.architect.fName + ' ' + type.architect.lName
                          : 'NA'}
                        <p>{architectPhn ? architectPhn : 'NA'}</p>
                      </li>
                      <li>
                        Broker Contact:
                        {type && type.broker && type.broker.fName && type.broker.lName
                          ? type.broker.fName + ' ' + type.broker.lName
                          : 'NA'}
                        <p>{brokerPhn ? brokerPhn : 'NA'}</p>
                      </li>
                      <li>
                        Tenant Contact:
                        {type && type.tenant && type.tenant.fName && type.tenant.lName
                          ? type.tenant.fName + ' ' + type.tenant.lName
                          : 'NA'}
                        <p>{tenantPhn ? tenantPhn : 'NA'}</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="welcomeDescription">
                  <div className="">
                    <span>
                      <strong>Client:</strong>
                    </span>
                    <span>
                      {type && type.client && type.client.company && type.client.company.length > 0
                        ? type.client.company[0].label
                        : 'NA'}
                    </span>
                    <ul>
                      <li>
                        <strong>Job Start:</strong>{' '}
                        {list.startDate && list.startDate.iso
                          ? formatDate(list.startDate.iso)
                          : 'NA'}
                      </li>
                      <li>
                        <strong>Job End:</strong>{' '}
                        {list.endDate && list.endDate.iso
                          ? formatDate(list.endDate.iso)
                          : 'NA'}
                      </li>
                      <li>
                        <strong>Size (Sq. Feet):</strong> {list.sqFeet ? list.sqFeet : 'NA'}
                      </li>
                      <li>
                        <strong>Estimate Contract Amt.:</strong>{' '}
                        {list.estimateBidAmount
                          ? roundFormatMoney(list.estimateBidAmount)
                          : 'NA'}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="row notes1">
              <div className="col-md-12">
                <div>
                  <p>Note:</p>
                </div>
              </div>
            </div>

            <div className="row notes2">
              <div className="col-md-12">
                <div>
                  <p>
                    ____________________________________________________________________________________________________________________________________________________________________________________
                  </p>
                  <p>
                    ____________________________________________________________________________________________________________________________________________________________________________________
                  </p>
                  <p>
                    ____________________________________________________________________________________________________________________________________________________________________________________
                  </p>
                  <p>
                    ____________________________________________________________________________________________________________________________________________________________________________________
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PrintThisLead;
