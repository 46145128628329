import React, { Suspense } from 'react';

import { Link } from 'react-router-dom';
import Header from '../../Layout/Header.js';
import Footer from '../../Layout/Footer.js';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { appConstants } from '../../../_constants';
import {
  GetEmployeeRosterReportAction,
  GetEmployeeRosterReportPrefAction,
  SetEmployeeRosterReportPrefAction,
} from '../../../actions/reports';
import ReactLoading from 'react-loading';
import bannerImage from '../../static/images/Cobilogo.png';
import {
  formatPhoneArrayHTML,
  formatAddressText,
  formatAddressHTML,
} from '../../../utils/contact-formatters.jsx';
import { formatPhone } from '../../../utils/formatters';
import officeImage from '../../../components/static/images/office.png';
import mobileImage from '../../../components/static/images/cell-phone.png';
import phoneImage from '../../../components/static/images/telephone-handle-silhouette.png';
import homeImage from '../../../components/static/images/home.png';

import { customSortByKey, CustomTable, multiSelectFilter } from '../../Common/CustomTable';
import { SelectColumnFilter } from '../../Common/CustomTableComponents/SelectColumnFilter';
import { DateColumnFilter } from '../../Common/CustomTableComponents/DateColumnFilter';
import TitleComponent from '../../Common/TitleComponent';
import { Tooltip } from 'react-tooltip';
import { getAllContactFieldsAction } from '../../../actions/contactFields';
import { formatDate, formatBidDueDate } from '../../../utils/date-formatters.js';
import {buildColumnForField, buildDynamicColumns} from '../../../utils/column-formatters';

class EmployeeRosterReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {},
      accountId: localStorage.getItem('accountId'),
      userId: localStorage.getItem('userId'),
      list: [],
      userType: [],
      comLogo: '',
      allCppRole: [],
      selectBox: {},
      fetchRequest: true,
      totalCount: 0,
      currentPage: 1,
      sizePerPage: appConstants.TableSizePerPageList[2].value,
      searchText: '',
      sortType: 'DESC',
      sortKey: '',
      allContactFieldTypes: [],
      dynamicColumns: [],
    };
    this.onPageChange = this.onPageChange.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let listData = [];
    let i = 0;
    if (nextProps.listData && nextProps.listData.success && nextProps.listData.success === 1) {
      let accountData = nextProps.listData.accountData;
      // this.setState({totalCount:nextProps.listData.count, list :nextProps.listData.data, fetchRequest:false})
      if (nextProps.listData.data && nextProps.listData.data.length > 0) {
        nextProps.listData.data.map((v) => {
          if (v) {
            listData.push(v);
            listData[i].companyPhoneArr = accountData?.phoneArr ? accountData.phoneArr : '';
          }
          i++;
          return true;
        });
        this.setState({
          list: listData,
          totalCount: nextProps.listData.count,
          fetchRequest: false,
        });
      } else {
        this.setState({ list: [], totalCount: 0 });
      }
    }

    if (nextProps.listData && nextProps.listData.data && nextProps.listData.data.length == 0) {
      this.setState({ fetchRequest: false });
    }

    if (nextProps?.contactFields?.allContactFieldTypes?.length > 0) {
      this.setState({
        allContactFieldTypes: nextProps.contactFields.allContactFieldTypes,
      });
    }
  }

  componentDidMount() {
    this.setState({ sizePerPage: appConstants.TableSizePerPageList[2].value });
    let data = {};
    data['accountId'] = this.state.accountId;
    data['moduleName'] = appConstants.reportModuleName['29'];
    data['page'] = 0;
    data['displayLimit'] = this.state.sizePerPage;
    data['sortKey'] = this.state.sortKey;
    data['sortType'] = this.state.sortType;
    data['search'] = this.state.searchText;
    this.props.GetEmployeeRosterReportAction(data);

    if (
      localStorage.getItem('companyLogo') !== 'undefined' &&
      localStorage.getItem('companyLogo') !== null
    ) {
      this.setState({
        comLogo: localStorage.getItem('companyLogo'),
      });
    } else {
      let constImage = appConstants.CONTENT_CPOST_LOGO_URL;
      this.setState({ comLogo: constImage });
    }
    this.props.GetEmployeeRosterReportPrefAction();

    this.props.getAllContactFieldsAction({
      accountId: this.state.accountId,
    });
  }

  createCustomButtonGroup = (props) => {
    return (
      <div className='pdfLogoBox'>
        <div className='reportHead'>
          <div className='logo'>
            <img src={this.state.comLogo} alt='Upload Logo' />
          </div>
          <h2>Employee Roster Report</h2>
        </div>
      </div>
    );
  };

  onContactName(cell, row, enumObject, rowIndex) {
    return (
      <div>
        <Link to={{ pathname: `/contact-details/` + row.contactId }}>{row.displayName ?? '-'}</Link>
      </div>
    );
  }

  onTextField(cell, row, enumObject, rowIndex) {
    let data = row && row[cell] ? row[cell] : '-';
    return data;
  }
  onContactEmail(cell, row, enumObject, rowIndex) {
    let emailHtml = '';
    let isExport = false;
    if (row.original) {
      row = row.original;
      isExport = true;
    }
    if (row && row.emailArr && row.emailArr.length > 0) {
      if (!isExport) {
        row.emailArr.map((item) => {
          if (emailHtml !== '') {
            emailHtml = emailHtml + '<br/>';
          }
          emailHtml = emailHtml + "<a href=mailto:'" + item.email + '>' + item.email + '</a>';
          return true;
        });
        return <div dangerouslySetInnerHTML={{ __html: emailHtml }} />;
      } else {
        return row.emailArr.map((email) => email.email).join(', ');
      }
    } else {
      return <span>-</span>;
    }
  }

  onContactPhone(cell, row, enumObject, rowIndex) {
    let phn = '';
    if (row.original) {
      row = row.original;
      if (row && row.phoneArr && row.phoneArr.length > 0) {
        return row.phoneArr.map((phone) => formatPhone(phone, [], true)).join(', ');
      } else {
        return <span>-</span>;
      }
    }
    if (row && row.phoneArr && row.phoneArr.length > 0) {
      return <div dangerouslySetInnerHTML={{ __html: formatPhoneArrayHTML(row.phoneArr) }} />;
    } else {
      return <span>-</span>;
    }
  }

  onPageChange(page, sizePerPage) {
    this.setState({ fetchRequest: true });
    let data = {};
    data['accountId'] = this.state.accountId;
    data['moduleName'] = appConstants.reportModuleName['29'];
    // if(this.state.searchText===""){
    //   data['page'] = (page===-1)?0:page-1;
    //   data['displayLimit'] = sizePerPage;
    // }else{
    data['page'] = page === -1 ? 0 : page - 1;
    data['displayLimit'] = sizePerPage;
    data['search'] = this.state.searchText;
    data['sortKey'] = this.state.sortKey;
    data['sortType'] = this.state.sortType;
    data['search'] = this.state.searchText;
    //}
    this.setState({ sizePerPage: sizePerPage, currentPage: page });
    this.props.GetEmployeeRosterReportAction(data);
  }

  onSearchChange(text, sizePerPage) {
    this.setState({ fetchRequest: true, list: [] });
    text = text ? text.trim() : '';
    if (text !== '') {
      let data = {};
      data['accountId'] = this.state.accountId;
      data['moduleName'] = appConstants.reportModuleName['29'];
      data['page'] = 0;
      data['search'] = text;
      data['displayLimit'] = this.state.sizePerPage;
      data['sortKey'] = this.state.sortKey;
      data['sortType'] = this.state.sortType;
      this.setState({ sizePerPage: 50 });
      this.setState({ searchText: text });
      this.props.GetEmployeeRosterReportAction(data);
    } else {
      this.componentDidMount();
      this.setState({ searchText: text });
    }
  }

  onSortChange(sortName, sortOrder) {
    if (this.state && this.state.list) {
      let products = this.state.list;
      if (sortOrder === 'asc') {
        products.sort(function (a, b) {
          if (parseInt(a[sortName], 10) > parseInt(b[sortName], 10)) {
            return 1;
          } else if (parseInt(b[sortName], 10) > parseInt(a[sortName], 10)) {
            return -1;
          }
          return 0;
        });
      } else {
        products.sort(function (a, b) {
          if ((a[sortName], 10) > (b[sortName], 10)) {
            return -1;
          } else if ((b[sortName], 10) > (a[sortName], 10)) {
            return 1;
          }
          return 0;
        });
      }

      this.setState({ list: products });
    }
  }

  createCustomButtonGroup = (props) => {
    return (
      <div className='pdfLogoBox'>
        <div className='reportHead'>
          <div className='logo'>
            <img src={this.state.comLogo} alt='Upload Logo' />
          </div>
          <h2>Employee Roster Report</h2>
        </div>
      </div>
    );
  };

  _setTableOption() {
    if (this.state.fetchRequest) {
      return (
        <ReactLoading
          className='table-loader'
          type={appConstants.LOADER_TYPE}
          color={appConstants.LOADER_COLOR}
          height={appConstants.LOADER_HEIGHT}
          width={appConstants.LOADER_WIDTH}
        />
      );
    } else {
      return 'No data found..';
    }
  }

  onGetAddressInfo(cell, row, enumObject, rowIndex) {
    if (row.original) {
      row = row.original;
      if (row && row.addressArr) {
        let addressArr = row.addressArr.map((v) => formatAddressText(v));
        return addressArr?.join('\n');
      }
    }
    if (row && row.addressArr) {
      let addressArr = row.addressArr.map((v) => formatAddressHTML(v));

      return addressArr;
    }
  }




  

  buildReportColumns() {
    const { list, allContactFieldTypes } = this.state;


    const dynamicFieldsLoaded = allContactFieldTypes?.length > 0;

    const defaultFieldCodes = ['TITLE','DEPT','EMPID','INDCNTR'];

    const customFieldColumns = dynamicFieldsLoaded
      ? buildDynamicColumns(
          allContactFieldTypes.filter(
            (field) => !defaultFieldCodes.includes(field.code) && field.stages.includes('employee')
          )
        )
      : [];

    const defaultFields = dynamicFieldsLoaded
      ? allContactFieldTypes.filter((field) => defaultFieldCodes.includes(field.code))
      : [];

    const defaultColumns = [
      {
        Header: 'Full Name',
        accessor: 'firstName',
        sortType: 'basic',
        width: 100,
        Cell: (props) => this.onContactName(props.value, props.row.original),
      },   
      buildColumnForField(defaultFields, 'TITLE',false),
      buildColumnForField(defaultFields, 'DEPT',false),
      buildColumnForField(defaultFields, 'EMPID',false),
      
      {
        Header: 'Contact Phone (Ext)',
        accessor: 'phoneArr',
        width: 60,
        Cell: (props) => this.onContactPhone(props.column.id, props.row.original, props.value),
        getCellExportValue: (row, col) => this.onContactPhone(col, row),
      },
      {
        Header: 'Email',
        accessor: 'emailArr',
        width: 80,
        Cell: (props) => this.onContactEmail(props.column.id, props.row.original, props.value),
        getCellExportValue: (row, col) => this.onContactEmail(col, row),
      },
      {
        Header: 'Address',
        accessor: (row) => row.addressArr.map((v) => formatAddressText(v)),
        width: 130,
        Cell: (props) => this.onGetAddressInfo(props.column.id, props.row.original, props.value),
        getCellExportValue: (row, col) => this.onGetAddressInfo(col, row),
      },
      {
        Header: 'User Type',
        accessor: 'userRoleName',
        Filter: SelectColumnFilter,
        width: 60,
        Cell: (props) => this.onTextField(props.column.id, props.row.original, props.value),
      },
      buildColumnForField(defaultFields, 'INDCNTR',false),
    ];

    let columns = [];
    if (dynamicFieldsLoaded) {
      if (columns.length == 0) {
        columns = defaultColumns.filter((cols) => cols?.accessor);
      }

      for (let i in customFieldColumns) {
        const curColumnsAccessors = columns.map((cols) => cols.accessor);
        if (!curColumnsAccessors.includes(customFieldColumns[i].accessor)) {
          columns = columns.concat(customFieldColumns[i]);
        }
      }
    } else {
      columns = defaultColumns.filter((cols) => cols?.accessor);
    }

    this.setState({ dynamicColumns: columns });

  }

  
  render() {
  
    const {
      list, allContactFieldTypes, dynamicColumns, fetchRequest
    } = this.state;

    
    const dynamicFieldsLoaded = allContactFieldTypes?.length > 0;
    
    const dynamicColumnsBuilt = dynamicFieldsLoaded && dynamicColumns.length > 0;

    if(dynamicFieldsLoaded && !dynamicColumnsBuilt){
      this.buildReportColumns();
    }
    
    const { listPref, SetEmployeeRosterReportPrefAction } = this.props;

    return (
      <div>
        <div className='ContactListReport'>
          <TitleComponent data={{ title: `Employee Roster` }} />
          <div>
            <Suspense fallback='loading'>
              <Header props={this.props} />
            </Suspense>
          </div>
          <div className='contactBox'>
            <div className='container'>
              <div className='row no-break-print'>
                {dynamicColumnsBuilt && (
                  <div className='col-md-12'>
                    <div
                      className='table-responsive reportTableSection'
                      ref={(el) => (this.componentRef = el)}
                    >
                      {this.createCustomButtonGroup()}
                      <CustomTable
                        columns={dynamicColumns}
                        list={list}
                        preferences={listPref}
                        className='bid-due-table'
                        customTableTitle='Employee Roster Report'
                        onChangeRowSelection={this.onChangeRowSelection}
                        onSavePreferences={SetEmployeeRosterReportPrefAction}
                        isLoading={this.state.fetchRequest}
                        canShowRowSelection={false}
                        componentRef={this.componentRef}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    listData: state.reports.employeeRosterList,
    listPref: state.reports.employeeRosterListPref,
    contactFields: state.contactFields,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    GetEmployeeRosterReportAction,
    GetEmployeeRosterReportPrefAction,
    SetEmployeeRosterReportPrefAction,
    getAllContactFieldsAction,
  })(EmployeeRosterReport)
);
