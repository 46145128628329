import * as actionTypes from '../actions/actionTypes';

const initState = {
  project: {},
  allProjects: [],
  message: '',
  isfetched: false,
  isfetchedError: false,
  isProjectUpdating: false,
  isProjectUpdated: false,
  isUpdateStatus: false,
  fetchRequest: false,
  fetchSuccess: false,
  createSuccess: false,
  success: null,
  nxtLead: false,
  isProject: false,
  isProjectError: false,
  isProjectSuccess: false,
  isProjectData: false,
  lastJobData: {},
};

export default (state = initState, action = {}) => {
  switch (action.type) {
    case actionTypes.GET_EXISTING_PROJECT_REQUEST:
      return {
        ...state,
        //buttonActive: true,
        projectData:{},
        message: '',
        fetchRequest: true,
        isProject: false,
        isProjectError: false,
        isProjectSuccess: false,
        isProjectData: false,
      };
    case actionTypes.GET_EXISTING_PROJECT_SUCCESS:
      return {
        ...state,
        //buttonActive: false,
        //project: action.payload.response.result.data,
        projectData: action.payload.response.projectData,
        message: '',
        isfetched: true,
        isfetchedError: false,
        fetchRequest: false,
        isProject: true,
        isProjectError: false,
        isProjectSuccess: true,
        isProjectData: action.payload.response.result,
      };
    case actionTypes.GET_EXISTING_PROJECT_ERROR:
      return {
        ...state,
        //buttonActive: false,
        projectData: {},
        message: 'Something Went Wrong',
        isfetchedError: true,
        fetchRequest: false,
        isProject: false,
        isProjectError: true,
        isProjectSuccess: false,
        isProjectData: false,
      };

    case actionTypes.CREATE_PROJECT_REQUEST:
      return {
        ...state,
        buttonActive: true,
        createProjReq: true,
        allProjects: [],
        message: '',
        isStatus: false,
        createSuccess: false,
      };
    case actionTypes.CREATE_PROJECT_SUCCESS:
      return {
        ...state,
        buttonActive: false,
        createProjReq: false,
        projectCreate: action.payload.response.data,
        success: action.payload.response.data && action.payload.response.data.result.success,
        message: action.payload.response.data.result.msg,
        createSuccess: true,
      };

    case actionTypes.CREATE_PROJECT_ERROR:
      return {
        ...state,
        buttonActive: false,
        createProjReq: false,
        allProjects: [],
        success: action.payload.error.data && action.payload.error.data.result.success,
        message: action.payload.error.data.result.msg,
        createSuccess: false,
      };

    case actionTypes.GET_ALL_PROJECT_REQUEST:
      return {
        ...state,
        buttonActive: true,
        allProjects: [],
        message: '',
        isStatus: false,
        fetchRequest: true,
      };
    case actionTypes.GET_ALL_PROJECT_SUCCESS:
      return {
        ...state,
        buttonActive: false,
        allLead: action.payload.listType == 'lead' ? action.payload.response.result.data : [],
        allProjects:
          action.payload.listType == 'project' ? action.payload.response.result.data : [],
        allProjectsCount: action.payload.response.result.count,
        message: '',
        isStatus: true,
        fetchRequest: false,
        alert: { message: action.payload.response, type: 'alert-success' },
      };
    case actionTypes.GET_ALL_PROJECT_ERROR:
      return {
        ...state,
        buttonActive: false,
        allProjects: [],
        message: action.payload.error,
        isStatus: false,
        alert: { message: action.payload.error, type: 'alert-danger' },
      };

    case actionTypes.UPDATE_PROJECT_REQUEST:
      return {
        ...state,
        buttonActive: true,
        success: '',
        message: '',
        isProjectUpdating: true,
        isProjectUpdated: false,
      };
    case actionTypes.UPDATE_PROJECT_SUCCESS:
      return {
        ...state,
        buttonActive: false,
        success: action.payload.response && action.payload.response,
        message: action.payload.response,
        isProjectUpdating: false,
        isProjectUpdated: action.payload.response === 'Success',
      };
    case actionTypes.UPDATE_PROJECT_ERROR:
      return {
        ...state,
        buttonActive: false,
        message: action.payload.error,
        isProjectUpdating: false,
        isProjectUpdated: false,
      };

    case actionTypes.DELETE_PROJECT_REQUEST:
      return {
        ...state,
        buttonActive: true,
        allProjects: [],
        message: '',
        isStatus: false,
      };
    case actionTypes.DELETE_PROJECT_SUCCESS:
      return {
        ...state,
        buttonActive: false,
        allProjects: action.payload.response.results,
        message: '',
        isStatus: true,
        alert: { message: action.payload.response, type: 'alert-success' },
      };
    case actionTypes.DELETE_PROJECT_ERROR:
      return {
        ...state,
        buttonActive: false,
        allProjects: [],
        message: action.payload.error,
        isStatus: false,
        alert: { message: action.payload.error, type: 'alert-danger' },
      };

    case actionTypes.GET_LAST_JOB_NUM_REQUEST:
      return {
        ...state,
        buttonActive: true,
      };
    case actionTypes.GET_LAST_JOB_NUM_SUCCESS:
      return {
        ...state,
        buttonActive: false,
        lastJobData: action.payload.response.result,
      };
    case actionTypes.GET_LAST_JOB_NUM_ERROR:
      return {
        ...state,
        buttonActive: false,
        lastJobData: {},
      };

    case actionTypes.PROMOTE_LEAD_REQUEST:
      return {
        ...state,
        buttonActive: true,
        isUpdateStatus: false,
        message: '',
      };
    case actionTypes.PROMOTE_LEAD_SUCCESS:
      return {
        ...state,
        buttonActive: false,
        isUpdateStatus: true,
        message: '',
      };
    case actionTypes.PROMOTE_LEAD_ERROR:
      return {
        ...state,
        buttonActive: false,
        message: '',
      };

      case actionTypes.UPDATE_LEAD_STATUS_REQUEST:
        return {
          ...state,
          buttonActive: true,
          isUpdateStatus: false,
          message: '',
        };
      case actionTypes.UPDATE_LEAD_STATUS_SUCCESS:
        return {
          ...state,
          buttonActive: false,
          isUpdateStatus: true,
          message: '',
        };
      case actionTypes.UPDATE_LEAD_STATUS_ERROR:
        return {
          ...state,
          buttonActive: false,
          message: '',
        };

    case actionTypes.UPDATE_EVENT_DATE_REQUEST:
      return {
        ...state,
        buttonActive: true,
        allProjects: [],
        message: '',
        isUpdateStatus: false,
      };
    case actionTypes.UPDATE_EVENT_DATE_SUCCESS:
      console.log(action.payload.response.result);
      return {
        ...state,
        buttonActive: false,
        allProjects: action.payload.response.result,
        success: action.payload.response.result && action.payload.response.result.success,
        message: action.payload.response.result.msg,
        isUpdateStatus: true,
      };
    case actionTypes.UPDATE_EVENT_DATE_ERROR:
      return {
        ...state,
        buttonActive: false,
        allProjects: [],
        success: action.payload.response.result && action.payload.response.result.success,
        message: action.payload.response.result.msg,
        isUpdateStatus: false,
      };
    case actionTypes.UPDATE_LEAD_SECTION_REQUEST:
      return {
        ...state,
        buttonActive: true,
        success: '',
        message: '',
        isUpdateStatus: false,
      };
    case actionTypes.UPDATE_LEAD_SECTION_SUCCESS:
      return {
        ...state,
        buttonActive: false,
        success: action.payload.response && action.payload.response,
        message: action.payload.response,
        isUpdateStatus: true,
      };

    case actionTypes.UPDATE_FILTER_SECTION_SUCCESS:
      return {
        ...state,
        buttonActive: false,
        success: action.payload.response && action.payload.response,
        filterData: action.payload.response,
        filterStatus: action.payload.status,
      };
    default:
      return state;
  }
};
