export const selectAddUserSettings = (state) => state.rtk.settings.addUserSettings;

export const selectUpdateUserSettings = (state) => state.rtk.settings.updateUserSettings;

export const selectAddAdminSettings = (state) => state.rtk.settings.addAdminSettings;

export const selectUpdateAdminSettings = (state) => state.rtk.settings.updateAdminSettings;

export const selectGetAdminSettings = (state) => state.rtk.settings.getAdminSettings;

export const selectGetUserSettings = (state) => state.rtk.settings.getUserSettings;

export const selectEnableUserSetting = (index) => (state) =>
  state.rtk.settings.enableUserSetting[index] || { loading: false, error: null, data: null };

export const selectDisableUserSetting = (index) => (state) =>
  state.rtk.settings.disableUserSetting[index] || { loading: false, error: null, data: null };

export const selectResetUserSetting = (index) => (state) =>
  state.rtk.settings.resetUserSetting[index] || { loading: false, error: null, data: null };

export const selectEnableAdminSetting = (index) => (state) =>
  state.rtk.settings.enableAdminSetting[index] || { loading: false, error: null, data: null };

export const selectDisableAdminSetting = (index) => (state) =>
  state.rtk.settings.disableAdminSetting[index] || { loading: false, error: null, data: null };

export const selectGetPermissionMatrixColumns = (state) =>
  state.rtk.settings.getPermissionMatrixColumns;

export const selectGetPermissionMatrixRows = (state) => state.rtk.settings.getPermissionMatrixRows;

export const selectGetPermissionMatrix = (state) => state.rtk.settings.getPermissionMatrix;

export const selectSetPermissionValue = (state) => state.rtk.settings.setPermissionValue;

export const selectResetDefaultPermissions = (roleId) => (state) =>
  state.rtk.settings.resetDefaultPermissions[roleId] || { loading: false, error: null, data: null };

export const selectGetJobStatusCodesSettings = (state) =>
  state.rtk.settings.getJobStatusCodesSettings;

export const selectGetProjectRoles = (state) => state.rtk.settings.getProjectRoles;

export const selectGetReasonCodesByType = (state) => state.rtk.settings.getReasonCodesByType;

export const selectGetProjectFieldsSettings = (state) =>
  state.rtk.settings.getProjectFieldsSettings;

export const selectGetContactFieldsSettings = (state) =>
  state.rtk.settings.getContactFieldsSettings;

export const selectGetCustomSelectOptions = (state) => state.rtk.settings.getCustomSelectOptions;

export const selectFormTemplates = (state) => state.rtk.settings.getFormTemplates?.data??[];
