import _ from 'lodash';
import { useEffect } from 'react';

export const useDebounce = (cb: any, deps: any[] = [], delay = 500) => {
    const bounce = _.debounce(cb, delay);

    useEffect(() => {
        bounce();

        return bounce.cancel;
    }, deps)
}