import * as actionTypes from './actionTypes';
import * as API from '../api/customFields';
import { callAlertMessage } from '../components/Auth/Auth';

export const createCustomFieldRequest = () => ({
  type: actionTypes.CREATE_CUSTOM_FIELD_REQUEST,
});

export const createCustomFieldSuccess = (response) => ({
  type: actionTypes.CREATE_CUSTOM_FIELD_SUCCESS,
  payload: {
    response,
  },
});

export const createCustomFieldError = (error) => ({
  type: actionTypes.CREATE_CUSTOM_FIELD_ERROR,
  payload: {
    error,
  },
});

export const createCustomFieldAction = (data, props) => {
  let propsTemp = props;
  return (dispatch) => {
    dispatch(createCustomFieldRequest());
    return API.createCustomField(data)
      .then((response) => {
        if (response.data.result.success) {
          callAlertMessage(
            'success',
            response.data.result.msg === 'Error'
              ? response.data.result.msg + ' : ' + response.data.result.data
              : response.data.result.msg
          );
          dispatch(updateCustomFieldSuccess(response.data));
        } else {
          callAlertMessage(
            'error',
            response.data.result.msg === 'Error'
              ? response.data.result.msg + ' : ' + response.data.result.data
              : response.data.result.msg
          );
          dispatch(updateCustomFieldError(response.data));
        }
      })
      .catch((error) => {
        dispatch(
          createCustomFieldError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

export const getAllCustomFieldRequest = () => ({
  type: actionTypes.GET_ALL_CUSTOM_FIELD_REQUEST,
});

export const getAllCustomFieldSuccess = (response) => ({
  type: actionTypes.GET_ALL_CUSTOM_FIELD_SUCCESS,
  payload: {
    response,
  },
});

export const getAllCustomFieldError = (error) => ({
  type: actionTypes.GET_ALL_CUSTOM_FIELD_ERROR,
  payload: {
    error,
  },
});

export const getAllCustomFieldAction = (data) => {
  return (dispatch) => {
    dispatch(getAllCustomFieldRequest());
    return API.getAllCustomField(data)
      .then((response) => {
        dispatch(getAllCustomFieldSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          getAllCustomFieldError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

export const updateCustomFieldRequest = () => ({
  type: actionTypes.UPDATE_CUSTOM_FIELD_REQUEST,
});

export const updateCustomFieldSuccess = (response) => ({
  type: actionTypes.UPDATE_CUSTOM_FIELD_SUCCESS,
  payload: {
    response,
  },
});

export const updateCustomFieldError = (error) => ({
  type: actionTypes.UPDATE_CUSTOM_FIELD_ERROR,
  payload: {
    error,
  },
});

export const updateCustomFieldAction = (data, props) => {
  let propsTemp = props;
  return (dispatch) => {
    dispatch(updateCustomFieldRequest());
    return API.updateCustomField(data)
      .then((response) => {
        if (response.data.result.success) {
          callAlertMessage(
            'success',
            response.data.result.msg === 'Error'
              ? response.data.result.msg + ' : ' + response.data.result.data
              : response.data.result.msg
          );
          dispatch(updateCustomFieldSuccess(response.data));
        } else {
          callAlertMessage(
            'error',
            response.data.result.msg === 'Error'
              ? response.data.result.msg + ' : ' + response.data.result.data
              : response.data.result.msg
          );
          dispatch(updateCustomFieldError(response.data));
        }
      })
      .catch((error) => {
        dispatch(
          updateCustomFieldError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

export const deleteCustomFieldRequest = () => ({
  type: actionTypes.DELETE_CUSTOM_FIELD_REQUEST,
});

export const deleteCustomFieldSuccess = (response) => ({
  type: actionTypes.DELETE_CUSTOM_FIELD_SUCCESS,
  payload: {
    response,
  },
});

export const deleteCustomFieldError = (error) => ({
  type: actionTypes.DELETE_CUSTOM_FIELD_ERROR,
  payload: {
    error,
  },
});

export const deleteCustomFieldAction = (data, props) => {
  let propsTemp = props;
  return (dispatch) => {
    dispatch(deleteCustomFieldRequest());
    return API.deleteCustomField(data)
      .then((response) => {
        if (response.data.result.success) {
          callAlertMessage(
            'success',
            response.data.result.msg === 'Error'
              ? response.data.result.msg + ' : ' + response.data.result.data
              : response.data.result.msg
          );
          dispatch(updateCustomFieldSuccess(response.data));
        } else {
          callAlertMessage(
            'error',
            response.data.result.msg === 'Error'
              ? response.data.result.msg + ' : ' + response.data.result.data
              : response.data.result.msg
          );
          dispatch(updateCustomFieldError(response.data));
        }
      })
      .catch((error) => {
        dispatch(
          deleteCustomFieldError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

export const getCustomFieldRequest = () => ({
  type: actionTypes.GET_CUSTOM_FIELD_REQUEST,
});

export const getCustomFieldSuccess = (response) => ({
  type: actionTypes.GET_CUSTOM_FIELD_SUCCESS,
  payload: {
    response,
  },
});

export const getCustomFieldError = (error) => ({
  type: actionTypes.GET_CUSTOM_FIELD_ERROR,
  payload: {
    error,
  },
});

export const getCustomFieldAction = (data) => {
  return (dispatch) => {
    dispatch(getCustomFieldRequest());
    return API.getAllCustomField(data)
      .then((response) => {
        dispatch(getCustomFieldSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          getCustomFieldError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

export const saveCustomFieldValueRequest = () => ({
  type: actionTypes.SAVE_CUSTOM_FIELD_VALUE_REQUEST,
});

export const saveCustomFieldValueSuccess = (response) => ({
  type: actionTypes.SAVE_CUSTOM_FIELD_VALUE_SUCCESS,
  payload: {
    response,
  },
});

export const saveCustomFieldValueError = (error) => ({
  type: actionTypes.SAVE_CUSTOM_FIELD_VALUE_ERROR,
  payload: {
    error,
  },
});
export const saveCustomFieldValueAction = (data, props) => {
  let propsTemp = props;
  return (dispatch) => {
    dispatch(saveCustomFieldValueRequest());

    return API.saveCustomFieldValue(data)
      .then((response) => {
        if (response.data.result.success) {
          callAlertMessage(
            'success',
            response.data.result.msg === 'Error'
              ? response.data.result.msg + ' : ' + response.data.result.data
              : response.data.result.msg
          );
          dispatch(saveCustomFieldValueSuccess(response.data));
        } else {
          callAlertMessage(
            'error',
            response.data.result.msg === 'Error'
              ? response.data.result.msg + ' : ' + response.data.result.data
              : response.data.result.msg
          );
          dispatch(saveCustomFieldValueError(response.data));
        }
      })
      .catch((error) => {
        dispatch(
          saveCustomFieldValueError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};
