import * as actionTypes from './../Admin/actionTypes.js';
import * as API from '../../api/Admin/plans';

//get all user details
export const getAllPlansRequest = () => ({
  type: actionTypes.GET_ALL_PLANS_REQUEST,
});

export const getAllPlansSuccess = (response) => ({
  type: actionTypes.GET_ALL_PLANS_SUCCESS,
  payload: {
    response,
  },
});

export const getAllPlansError = (error) => ({
  type: actionTypes.GET_ALL_PLANS_ERROR,
  payload: {
    error,
  },
});

export const getAllPlansAction = (data) => {
  return (dispatch) => {
    dispatch(getAllPlansRequest());
    return API.getAllPlans(data)
      .then((response) => {
        dispatch(getAllPlansSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          getAllPlansError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

//get all user details
export const createRequest = () => ({
  type: actionTypes.CREATE_PLANS_REQUEST,
});

export const createSuccess = (response) => ({
  type: actionTypes.CREATE_PLANS_SUCCESS,
  payload: {
    response,
  },
});

export const createError = (error) => ({
  type: actionTypes.CREATE_PLANS_ERROR,
  payload: {
    error,
  },
});

export const createPlansAction = (data) => {
  return (dispatch) => {
    dispatch(createRequest());
    return API.createPlans(data)
      .then((response) => {
        console.log(response);
        dispatch(createSuccess(response.data));
      })
      .catch((error) => {
        dispatch(createError(error.response.data.error.msg));
      });
  };
};
//Delete user details
export const deletePlansRequest = () => ({
  type: actionTypes.DELETE_PLANS_REQUEST,
});

export const deletePlansSuccess = (response) => ({
  type: actionTypes.DELETE_PLANS_SUCCESS,
  payload: {
    response,
  },
});
export const deletePlansError = (error) => ({
  type: actionTypes.DELETE_PLANS_ERROR,
  payload: {
    error,
  },
});

export const deletePlansAction = (data) => {
  return (dispatch) => {
    dispatch(deletePlansRequest());
    return API.deletePlans(data)
      .then((response) => {
        dispatch(deletePlansSuccess(response.data));
      })
      .catch((error) => {
        dispatch(deletePlansError(error.response.data.error.msg));
      });
  };
};
