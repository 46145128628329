import { postRequest } from './helper';

export const getProjectHistoryQuick = (data) => postRequest('functions/viewProjectHistoryQuick', data);

export const getAllChangeOrders = (data) => postRequest('functions/viewChangeOrders', data);

export const updateChangeOrder = (data) => postRequest('functions/updateChangeOrder', data);
export const createChangeOrderStatus = (data) => postRequest('functions/createChangeOrder', data);

// export const deleteChangeOrder = (data) => postRequest('functions/deleteMasters',data);
