import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import TermsAndConditionsPage from '../../pages/terms-and-conditions-page';

type TermsAndConditionsModalProps = {
  open: boolean;
  onClose: () => unknown;
};

export const TermsAndConditionsModal = ({ open, onClose }: TermsAndConditionsModalProps) => {

  return (
    <Modal isOpen={open} toggle={onClose} data-testid="termsAndConditionsPage">
      <ModalHeader toggle={onClose} data-testid="termsAndConditionsPage-header">Terms And Conditions</ModalHeader>
      <ModalBody>
        <TermsAndConditionsPage />
      </ModalBody>
      <ModalFooter>
        <div className='btn btn-primary' onClick={onClose} data-testid="termsAndConditionsPage-button-close">
          Close
        </div>
      </ModalFooter>
    </Modal>
  );
};
