import * as actionTypes from './actionTypes';
import * as API from '../api/home';


export const getAllHomeRequest = () => ({
  type: actionTypes.GET_ALL_HOME_REQUEST,
});

export const getAllHomeSuccess = (response) => ({
  type: actionTypes.GET_ALL_HOME_SUCCESS,
  payload: {
    response,
  },
});

export const getAllHomeError = (error) => ({
  type: actionTypes.GET_ALL_HOME_ERROR,
  payload: {
    error,
  },
});

export const getAllHome = (data) => {
  return (dispatch) => {
    dispatch(getAllHomeRequest());
    return API.getAllHome(data)
      .then((response) => {
        dispatch(getAllHomeSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          getAllHomeError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

//dashboard report
export const getBidMissedWidgetRequest = () => ({
  type: actionTypes.GET_BID_MISSED_WIDGET_REQUEST,
});

export const getBidMissedWidgetSuccess = (response) => ({
  type: actionTypes.GET_BID_MISSED_WIDGET_SUCCESS,
  payload: {
    response,
  },
});

export const getBidMissedWidgetError = (error) => ({
  type: actionTypes.GET_BID_MISSED_WIDGET_ERROR,
  payload: {
    error,
  },
});

export const getBidMissedWidgetAction = (data) => {
  return (dispatch) => {
    dispatch(getBidMissedWidgetRequest());
    return API.getBidMissedWidget(data)
      .then((response) => {
        dispatch(getBidMissedWidgetSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          getBidMissedWidgetError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

//dashboard missing data
export const getMissingDataRequest = () => ({
  type: actionTypes.GET_MISSING_DATA_REQUEST,
});

export const getMissingDataSuccess = (response) => ({
  type: actionTypes.GET_MISSING_DATA_SUCCESS,
  payload: {
    response,
  },
});

export const getMissingDataError = (error) => ({
  type: actionTypes.GET_MISSING_DATA_ERROR,
  payload: {
    error,
  },
});

export const getMissingDataAction = (data) => {
  return (dispatch) => {
    dispatch(getMissingDataRequest());
    return API.getMissingData(data)
      .then((response) => {
        dispatch(getMissingDataSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          getMissingDataError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

//dashboard update close (welcome section)
export const getCloseWelcomeRequest = () => ({
  type: actionTypes.UPDATE_CLOSE_WELCOME_REQUEST,
});

export const getCloseWelcomeSuccess = (response) => ({
  type: actionTypes.UPDATE_CLOSE_WELCOME_SUCCESS,
  payload: {
    response,
  },
});

export const getCloseWelcomeError = (error) => ({
  type: actionTypes.UPDATE_CLOSE_WELCOME_ERROR,
  payload: {
    error,
  },
});

export const getCloseWelcomeAction = (data) => {
  return (dispatch) => {
    dispatch(getCloseWelcomeRequest());
    return API.updateCloseWelcome(data)
      .then((response) => {
        dispatch(getCloseWelcomeSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          getCloseWelcomeError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};
