import * as actionTypes from '../actions/actionTypes';

const initState = {
  assignedProjectToPeople: {},
  assignedProjectToPeopleList: [],
  message: '',
  isfetched: false,
  isfetchedError: false,
};

export default (state = initState, action = {}) => {
  switch (action.type) {
    case actionTypes.CREATE_ASSIGN_PROJECT_TO_PEOPLE_REQUEST:
      return {
        ...state,
        buttonActive: true,
        assignedProjectToPeople: {},
        message: '',
        success: 0,
      };
    case actionTypes.CREATE_ASSIGN_PROJECT_TO_PEOPLE_SUCCESS:
      return {
        ...state,
        buttonActive: false,
        assignedProjectToPeopleList: action.payload.response.result.data,
        success: action.payload.response.result.success,
        message: action.payload.response.result.msg,
      };

    case actionTypes.CREATE_ASSIGN_PROJECT_TO_PEOPLE_ERROR:
      return {
        ...state,
        buttonActive: false,
        message: '',
        success: 0,
      };

    case actionTypes.GET_ALL_ASSIGN_PROJECT_TO_PEOPLE_REQUEST:
      return {
        ...state,
        assignedProjectToPeople: {},
        message: '',
        success: 0,
      };

    case actionTypes.GET_ALL_ASSIGN_PROJECT_TO_PEOPLE_SUCCESS:
      return {
        ...state,
        assignedProjectToPeopleList: action.payload.response.result.data,
        message: '',
        success: 0,
      };

    case actionTypes.GET_ALL_ASSIGN_PROJECT_TO_PEOPLE_ERROR:
      return {
        ...state,
        message: '',
        success: 0,
      };

    // case actionTypes.UPDATE_ASSIGN_PROJECT_TO_PEOPLE_REQUEST:
    //   return {
    //     ...state,
    //     assignedProjectToPeople: {},
    //     message: ''
    //   }
    // case actionTypes.UPDATE_ASSIGN_PROJECT_TO_PEOPLE_SUCCESS:
    //   return {
    //     ...state,
    //     assignedProjectToPeopleList:  action.payload.response.result.contractTypes,
    //     message: ''
    //   }

    // case actionTypes.UPDATE_ASSIGN_PROJECT_TO_PEOPLE_ERROR:
    //   return {
    //     ...state,
    //     message: ''
    //   }

    case actionTypes.DELETE_ASSIGN_PROJECT_TO_PEOPLE_REQUEST:
      return {
        ...state,
        assignedProjectToPeople: {},
        message: '',
        success: 0,
      };
    case actionTypes.DELETE_ASSIGN_PROJECT_TO_PEOPLE_SUCCESS:
      return {
        ...state,
        assignedProjectToPeopleList: state.assignedProjectToPeopleList,
        message: '',
        success: 0,
      };

    case actionTypes.DELETE_ASSIGN_PROJECT_TO_PEOPLE_ERROR:
      return {
        ...state,
        message: '',
        success: 0,
      };

    default:
      return state;
  }
};
