import React, { Suspense } from 'react';

import { Tooltip } from 'react-tooltip';
import { Link, withRouter } from 'react-router-dom';
import Header from '../../Layout/Header.js';
import Footer from '../../Layout/Footer.js';
import { connect } from 'react-redux';
import { appConstants } from '../../../_constants';
import {
  GetLienPeriodReportAction,
  GetLienPeriodReportPrefAction,
  SetLienPeriodReportPrefAction,
} from '../../../actions/reports';
import ReactLoading from 'react-loading';
import NotePopUp from '../../Common/NotePopUp';
import { formatNote } from '../../../utils/note-formatters';
import renderHTML from 'html-react-parser';
import TitleComponent from '../../Common/TitleComponent';
import { SelectColumnFilter } from '../../Common/CustomTableComponents/SelectColumnFilter';
import { CustomTable, multiSelectFilter, customSortByKey } from '../../Common/CustomTable';
import { DateColumnFilter } from '../../Common/CustomTableComponents/DateColumnFilter';
import {
  formatExternalContactData,
  formatInternalContactData,
} from '../../../utils/contact-formatters';
import { matchDataForTable } from '../../../utils/match-for-table.jsx';
import { getAllProjectRoleAction } from '../../../actions/projectRole';
import { getAllProjectFieldsAction } from '../../../actions/projectFields';
import { isAllowed, moduleConstants } from '../../../_constants';
import { buildDynamicColumns, onCurrency, onDate} from '../../../utils/column-formatters';


import {
  formatDate,
} from '../../../utils/date-formatters';

var linePeriodStatus = '';
let sortingOrder = '';

class LienPeriodReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {},
      accountId: localStorage.getItem('accountId'),
      userId: localStorage.getItem('userId'),
      list: [],
      fetchRequest: true,
      comLogo: '',
      totalCount: 0,
      currentPage: 1,
      sizePerPage: appConstants.TableSizePerPageList[2].value,
      searchText: '',
      noteModal: false,
      noteData: '',
      sortType: 'DESC',
      sortKey: '',
      contactData: {},
      projectRoleTypes: [],
      dynamicColumns: [],
    };
    this.onPageChange = this.onPageChange.bind(this);
    this.getModalOpen = this.getModalOpen.bind(this);
    this.onNoteModalClose = this.onNoteModalClose.bind(this);
  }
  componentDidMount() {
    this.setState({ isWidget: !!this.props.props?.isWidget });
    this.setState({ sizePerPage: appConstants.TableSizePerPageList[2].value });
    let data = {};
    data['accountId'] = this.state.accountId;
    data['moduleName'] = appConstants.reportModuleName['24'];
    data['page'] = 0;
    data['displayLimit'] = this.props?.props?.isWidget ? 5 : this.state.sizePerPage;
    data['sortKey'] = this.state.sortKey;
    data['sortType'] = this.state.sortType;
    data['search'] = this.state.searchText;
    this.props.GetLienPeriodReportAction(data);

    sortingOrder = '';

    if (
      localStorage.getItem('companyLogo') !== 'undefined' &&
      localStorage.getItem('companyLogo') !== null
    ) {
      this.setState({
        comLogo: localStorage.getItem('companyLogo'),
      });
    } else {
      let constImage = appConstants.CONTENT_CPOST_LOGO_URL;
      this.setState({ comLogo: constImage });
    }

    this.props.GetLienPeriodReportPrefAction();

    this.props.getAllProjectRoleAction({
      className: 'projectRoleTypes',
      accountId: this.state.accountId,
    });

    this.props.getAllProjectFieldsAction({
      accountId: this.state.accountId,
      minimumStage: 'post-construction',
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps?.projectRoles?.allProjectRoleTypes) {
      this.setState({
        projectRoleTypes: nextProps.projectRoles.allProjectRoleTypes,
      });
    }

    if (nextProps?.projectFields?.allProjectFieldTypes?.length > 0) {
      this.setState({
        allProjectFieldTypes: nextProps.projectFields.allProjectFieldTypes,
      });
    }

    if (nextProps?.listData?.success === 1) {
      nextProps.listData.data.map((v, i) => {
        if (v && v.lienPeriod) {
          if (i === 0) {
            linePeriodStatus = v.lienPeriod;
          }
        }
        return true;
      });
      let fieldsToMatch = {
        projectAssignments: { lookupLocation: 'contactData' },
        scope: {
          lookupLocation: 'jobScope',
          collectionName: 'allJobScope',
          labelField: 'jobScopeName',
        },
      };

      let matchedDataForTable = matchDataForTable(
        fieldsToMatch,
        nextProps.listData.data,
        nextProps.listData.contactData,
        this.props
      );

      this.setState({
        totalCount: nextProps.listData.count,
        list: matchedDataForTable,
        fetchRequest: false,
        contactData: nextProps.listData.contactData,
      });
    }

    if (nextProps.listData?.data?.length == 0) {
      this.setState({ fetchRequest: false });
    }
  }

  buildColumnForRole(filteredRoles, roleCode, hidden = false) {
    const foundRole = filteredRoles.find((role) => role.code == roleCode);
    if (!foundRole) return;
    const isInternal = foundRole && foundRole.type === 'internal';

    if (foundRole && isInternal) {
      return (
        foundRole && {
          Header: foundRole?.shortName ?? roleCode,
          LongHeader: foundRole?.name,
          accessor: 'projectAssignmentsLabel' + roleCode,
          sortType: customSortByKey,
          sortKey: foundRole?.name ?? roleCode,
          Filter: SelectColumnFilter,
          filter: multiSelectFilter,
          width: 60,
          hidden: hidden,
          Cell: (props) => (
            <>
              {this.formatContactDataInternal(
                'projectAssignmentsMatched' + roleCode,
                props.row.original
              )}
            </>
          ),
        }
      );
    } else {
      return (
        foundRole && {
          Header: foundRole?.shortName ?? roleCode,
          LongHeader: foundRole?.name,
          accessor: 'projectAssignmentsLabel' + roleCode,
          sortType: customSortByKey,
          sortKey: foundRole?.name ?? roleCode,
          Filter: SelectColumnFilter,
          filter: multiSelectFilter,
          width: 60,
          hidden: hidden,
          Cell: (props) => (
            <>
              {this.formatContactDataExternal(
                'projectAssignmentsMatched' + roleCode,
                props.row.original
              )}
            </>
          ),
        }
      );
    }
  }

  formatContactDataInternal(cell, row) {
    if (row[cell] && row[cell].length > 0) {
      return formatInternalContactData(row[cell], this.state.contactData);
    } else {
      return <span>-</span>;
    }
  }

  formatContactDataExternal(cell, row) {
    if (row[cell] && row[cell].length > 0) {
      return formatExternalContactData(row[cell], this.state.contactData, false);
    } else {
      return <span>-</span>;
    }
  }


  onJobStatus(cell, row, enumObject, rowIndex) {
    let data = row && row.jobStatusCodesCode ? row.jobStatusCodesCode : '-';
    return data;
  }

  redirectToProject(cell, enumObject, row, rowIndex) {
    if (cell === 'jobNumFormatted' && row) {
      return (
        <div>
          <Link
            to={{
              pathname: `/project-details/` + row.objectId,
              state: { row },
            }}
          >
            {row.jobNumFormatted ? row.jobNumFormatted : '-'}
          </Link>
        </div>
      );
    }

    if (cell === 'jobName' && row) {
      return (
        <div>
          <Link
            to={{
              pathname: `/project-details/` + row.objectId,
              state: { row },
            }}
          >
            {row.jobName ? row.jobName : '-'}
          </Link>
        </div>
      );
    }

    if (cell === 'altJobNum' && row) {
      return (
        <div>
          <Link
            to={{
              pathname: `/project-details/` + row.objectId,
              state: { row },
            }}
          >
            {row.altJobNum ? row.altJobNum : '-'}
          </Link>
        </div>
      );
    }
  }

  onNoteDetail(cell, row, enumObject, rowIndex) {
    //return (row && row.jobNotes) ? row.jobNotes : '-';
    const notes = formatNote(row?.jobNotes);
    return notes ? (
      <a
        className='anchorNote '
        data-type={'reminder'}
        onClick={(e) => {
          this.getModalOpen(e, row);
        }}
      >
        {renderHTML(notes)}
      </a>
    ) : (
      '-'
    );
  }

  onNoteModalClose() {
    this.setState({ noteModal: false });
  }

  getModalOpen(e, row) {
    let rowData = row?.jobNotes ? row.jobNotes : '-';
    this.setState({ noteModal: true, noteData: rowData });
  }

  createCustomButtonGroup = (props) => {
    return (
      <div className='pdfLogoBox'>
        <div className='reportHead'>
          <div className='logo'>
            <img src={this.state.comLogo} alt='Upload Logo' />
          </div>
          <h2>Lien Period Report</h2>
          <div>
            Based on assumed lien period of: {linePeriodStatus ? linePeriodStatus + ' days.' : ''}
          </div>
        </div>
      </div>
    );
  };

  onPageChange(page, sizePerPage) {
    this.setState({ fetchRequest: true });
    let data = {};
    data['accountId'] = this.state.accountId;
    data['moduleName'] = appConstants.reportModuleName['24'];

    data['page'] = page === -1 ? 0 : page - 1;
    data['displayLimit'] = sizePerPage;
    data['search'] = this.state.searchText;

    data['sortKey'] = this.state.sortKey;
    data['sortType'] = this.state.sortType;
    data['search'] = this.state.searchText;
    this.setState({ sizePerPage: sizePerPage });
    this.setState({ currentPage: page });
    this.props.GetLienPeriodReportAction(data);
  }

  _setTableOption() {
    if (this.state.fetchRequest) {
      return (
        <ReactLoading
          className='table-loader'
          type={appConstants.LOADER_TYPE}
          color={appConstants.LOADER_COLOR}
          height={appConstants.LOADER_HEIGHT}
          width={appConstants.LOADER_WIDTH}
        />
      );
    } else {
      return 'No data found..';
    }
  }


  

  buildReportColumns() {
    const hasContractValPermissions = isAllowed(moduleConstants.VIEWCONTRACTVAL, 'anyAssigned');
    //const hasProfitValPermissions = isAllowed(moduleConstants.VIEWPROFIT, 'anyAssigned');

    const { list, allProjectFieldTypes } = this.state;

    const dynamicFieldsLoaded = allProjectFieldTypes?.length > 0;

    const defaultFieldCodes = [];

    const customFieldColumns = dynamicFieldsLoaded
      ? buildDynamicColumns(
          allProjectFieldTypes.filter((field) => !defaultFieldCodes.includes(field.code))
        )
      : [];

    const defaultFields = dynamicFieldsLoaded
      ? allProjectFieldTypes.filter((field) => defaultFieldCodes.includes(field.code))
      : [];

    const isLeadType = false;

    const rolesLoaded = this.state.projectRoleTypes?.length > 0;

    const defaultRolesCodes = ['PO', 'PM'];

    const dynamicColumnsLoaded = dynamicFieldsLoaded && rolesLoaded;

    const enabledRoles = this.state.projectRoleTypes.filter(
      (role) => !defaultRolesCodes.includes(role.code) && (!isLeadType || role.isShowOnLeads)
    );
    const defaultRoles = this.state.projectRoleTypes.filter(
      (role) => defaultRolesCodes.includes(role.code) && (!isLeadType || role.isShowOnLeads)
    );

    const defaultColumns = [
      {
        Header: 'Job No.',
        accessor: 'jobNumFormatted',
        sortType: 'basic',
        width: 80,
        Cell: (props) => this.redirectToProject(props.column.id, props.value, props.row.original),
      },
      {
        Header: 'Alt Job#',
        accessor: 'altJobNum',
        width: 60,
        Cell: (props) => this.redirectToProject(props.column.id, props.value, props.row.original),
      },
      {
        Header: 'Job Name',
        accessor: 'jobName',
        width: 120,
        Cell: (props) => this.redirectToProject(props.column.id, props.value, props.row.original),
      },
      this.buildColumnForRole(defaultRoles, 'PO'),
      {
        Header: 'End Date',
        accessor: (row) =>
          row?.endDate?.iso
            ? new Date(row.endDate.iso)
            : new Date(formatDate('1800-03-03T12:47:40.000Z')),
        id: 'endDate',
        width: 100,
        Cell: (props) => <>{onDate(props.column.id, props.row.original, props.value)}</>,
        filter: DateColumnFilter,
        allowDateRangeFilter: true,
        sortType: 'datetime',
      },
      {
        Header: 'Delivered Date',
        accessor: (row) =>
          row?.deliveredDate?.iso
            ? new Date(row.deliveredDate.iso)
            : new Date(formatDate('1800-03-03T12:47:40.000Z')),
        id: 'deliveredDate',
        sortType: 'datetime',
        width: 100,
        Cell: (props) => <>{onDate(props.column.id, props.row.original, props.value)}</>,
        filter: DateColumnFilter,
        allowDateRangeFilter: true,
      },
      hasContractValPermissions&&{
        Header: 'Contract Amt.',
        accessor: 'currentContractAmount',
        width: 100,
        Cell: (props) => (
          <>{onCurrency(props.column.id, props.row.original, props.value)}</>
        ),
      },

      this.buildColumnForRole(defaultRoles, 'PM'),
      {
        Header: 'Status',
        accessor: 'jobStatusCodesCode',
        width: 60,
        Filter: SelectColumnFilter,
        Cell: (props) => <>{this.onJobStatus(props.column.id, props.row.original, props.value)}</>,
      },
      {
        Header: 'Lien Period Exp.',
        accessor: (row) =>
          row?.lienExpiration?.iso
            ? new Date(row.lienExpiration.iso)
            : new Date(formatDate('1800-03-03T12:47:40.000Z')),
        id: 'lienExpiration',
        sortType: 'datetime',
        width: 100,
        Cell: (props) => <>{onDate(props.column.id, props.row.original, props.value)}</>,
        filter: DateColumnFilter,
        allowDateRangeFilter: true,
      },
      {
        Header: 'Warranty Exp.',
        accessor: (row) =>
          row?.warrExpiration?.iso
            ? new Date(row.warrExpiration.iso)
            : new Date(formatDate('1800-03-03T12:47:40.000Z')),
        id: 'warrExpiration',
        sortType: 'datetime',
        width: 100,
        Cell: (props) => <>{onDate(props.column.id, props.row.original, props.value)}</>,
        filter: DateColumnFilter,
        allowDateRangeFilter: true,
      },
      {
        Header: 'Notes',
        accessor: 'jobNotes',
        hidden: true,
        width: 120,
        Cell: (props) => (
          <>
            <span
              data-tooltip-html={props.value}
              data-tooltip-id={props.column.id + ':' + props.row.id}
            >
              {this.onNoteDetail(props.column.id, props.row.original, props.value)}
            </span>
            <Tooltip
              id={props.column.id + ':' + props.row.id}
              delayShow={1000}
              style={{ color: '#fff !important' }}
              place='bottom'
            />
          </>
        ),
      },
    ];

    let columns = [];
    if (enabledRoles) {
      const extraColumns = enabledRoles.map((role) =>
        this.buildColumnForRole([role], role.code, true)
      );

      if (columns.length == 0) {
        columns = defaultColumns.filter((cols) => cols?.accessor);
      }

      for (let i in extraColumns) {
        const curColumnsAccessors = columns.map((cols) => cols.accessor);
        if (!curColumnsAccessors.includes(extraColumns[i].accessor)) {
          columns = columns.concat(extraColumns[i]);
        }
      }

      for (let i in customFieldColumns) {
        const curColumnsAccessors = columns.map((cols) => cols.accessor);
        if (!curColumnsAccessors.includes(customFieldColumns[i].accessor)) {
          columns = columns.concat(customFieldColumns[i]);
        }
      }
    } else {
      columns = defaultColumns.filter((cols) => cols?.accessor);
    }


    this.setState({ dynamicColumns: columns });

  }

  
  render() {
  
    const {
      list, allProjectFieldTypes, dynamicColumns
    } = this.state;


    const dynamicFieldsLoaded = allProjectFieldTypes?.length > 0;

    const rolesLoaded = this.state.projectRoleTypes?.length > 0;

    const dynamicColumnsLoaded = dynamicFieldsLoaded && rolesLoaded;

    
    const dynamicColumnsBuilt = dynamicColumnsLoaded && dynamicColumns.length > 0;

    if(dynamicColumnsLoaded && !dynamicColumnsBuilt){
      this.buildReportColumns();
    }
    
    const { listPref, SetLienPeriodReportPrefAction } = this.props;

    const isWidget = !!this.props?.props?.isWidget;
    const pageNumbers = isWidget ? [5] : [50, 100, 200];

    return (
      <div>
        <div className='ContactListReport'>
          <TitleComponent data={{ title: `Lien Period Report` }} />
          <div>{!isWidget && <Header props={this.props} />}</div>
          <div className='container'>
            {!isWidget && (
              <>
                <div className='mr-auto p-2 no-print reportHead'>
                  <h2>Lien Period Report </h2>
                </div>
                <div className='row searchbar'>
                  <div className='col-md-12 no-print'>
                    <div>
                      Based on assumed lien period of:{' '}
                      {linePeriodStatus ? linePeriodStatus + ' days.' : ''}
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className='row'>
              {dynamicColumnsBuilt && (
                <div className='col-md-12'>
                  <div
                    className='table-responsive reportTableSection'
                    ref={(el) => (this.componentRef = el)}
                  >
                    {!isWidget && this.createCustomButtonGroup()}
                    <CustomTable
                      columns={dynamicColumns}
                      list={list}
                      preferences={listPref}
                      onChangeRowSelection={this.onChangeRowSelection}
                      onSavePreferences={SetLienPeriodReportPrefAction}
                      canShowHeaderItems={!isWidget}
                      customTableTitle=''
                      isLoading={this.state.fetchRequest}
                      canShowRowSelection={false}
                      componentRef={this.componentRef}
                      pageSizeArray={pageNumbers}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          {this.state.noteModal && (
            <NotePopUp
              isOpen={this.state.noteModal}
              onClick={this.onNoteModalClose}
              noteInfo={this.state.noteData}
            />
          )}
          {!isWidget && <Footer />}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    listData: state.reports.lienPeriodList,
    listPref: state.reports.lienPeriodListPref,
    projectRoles: state.projectRoleType,
    projectFields: state.projectFields,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    GetLienPeriodReportAction,
    GetLienPeriodReportPrefAction,
    SetLienPeriodReportPrefAction,
    getAllProjectRoleAction,
    getAllProjectFieldsAction,
  })(LienPeriodReport)
);
