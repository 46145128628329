
import React, { Suspense } from 'react';
import ReactLoading from 'react-loading';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { getAllJobStatusAction } from '../../../actions/jobStatusCode';
import { getAllProjectRoleAction } from '../../../actions/projectRole';
import { getExistingProjectAction } from '../../../actions/project';
import {
  getAllReasonCodeAction,
  getReasonCodesByStatusCodeAction,
} from '../../../actions/reasonCode';
import {
  GetCustomizeProjectListInfoReportPrefAction,
  GetCustomizeProjectListReportAction,
  SetCustomizeProjectListInfoReportPrefAction,
} from '../../../actions/reports';
import {
  formatExternalContactData,
  formatInternalContactData,
  formatAddressText,
} from '../../../utils/contact-formatters';
import { formatDate, formatDateObject, formatBidDueDate } from '../../../utils/date-formatters';
import { matchDataForTable } from '../../../utils/match-for-table';
import { appConstants } from '../../../_constants';
import { customSortByKey, CustomTable, multiSelectFilter } from '../../Common/CustomTable';
import { SelectColumnFilter } from '../../Common/CustomTableComponents/SelectColumnFilter';
import { DateColumnFilter } from '../../Common/CustomTableComponents/DateColumnFilter';
import TitleComponent from '../../Common/TitleComponent';
import Footer from '../../Layout/Footer';
import Header from '../../Layout/Header';
import { getAllProjectFieldsAction } from '../../../actions/projectFields';
import { isAllowed, moduleConstants } from '../../../_constants';
import {buildColumnForField, buildDynamicColumns, onCurrency, onDate, onBoolean} from '../../../utils/column-formatters';

var sortingOrder = '';
var sortOrders = 'desc';

class CustomizedProjectListReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accountId: localStorage.getItem('accountId'),
      userId: localStorage.getItem('userId'),
      formData: {
        sortType: 'ASC',
        filterDate: formatDateObject(),
        sortKey: '',
      },
      selectBox: {},
      list: [],
      modal: false,
      fetchRequest: true,
      comLogo: '',
      saveType: '',
      totalCount: 0,
      currentPage: 1,
      sizePerPage: appConstants.TableSizePerPageList[2].value,
      searchText: '',
      showProcessing: false,
      projectStatus: false,
      allJobStatusCodes: [],
      allProjectStatus: [],
      jobScope: [],
      reasonCodes: [],
      filterStatus: false,
      subtitle: '',
      filterValue: '',
      contactData: {},
      projectRoleTypes: [],
      allProjectFieldTypes: [],
      dynamicColumns: [],
    };
  }

  UNSAFE_componentWillMount() {
    if (
      localStorage.getItem('companyLogo') !== 'undefined' &&
      localStorage.getItem('companyLogo') !== null
    ) {
      this.setState({
        comLogo: localStorage.getItem('companyLogo'),
      });
    } else {
      let constImage = appConstants.CONTENT_CPOST_LOGO_URL;
      this.setState({ comLogo: constImage });
    }
    
  }

  componentDidMount() {
    let data = {};
    data['accountId'] = this.state.accountId;
    data['userId'] = this.state.userId;
    data['moduleName'] = appConstants.reportModuleName['7'];
    data['page'] = 0;
    data['displayLimit'] = this.state.sizePerPage;
    data['search'] = this.state.searchText;
    this.props.GetCustomizeProjectListReportAction(data);

    sortingOrder = '';

    this.props.getAllJobStatusAction({
      className: 'jobStatusCodes',
      accountId: this.state.accountId,
    });
    this.props.getAllReasonCodeAction({
      className: 'reasonCodes',
      accountId: this.state.accountId,
    });
    this.props.getAllProjectRoleAction({
      className: 'projectRoleTypes',
      accountId: this.state.accountId,
    });
    this.props.getAllProjectFieldsAction({
      accountId: this.state.accountId,
      minimumStage: 'post-construction',
    });
    this.props.GetCustomizeProjectListInfoReportPrefAction();
  }

  buildColumnForRole(filteredRoles, roleCode, hidden = false) {
    const foundRole = filteredRoles.find((role) => role.code == roleCode);
    if (!foundRole) return;
    const isInternal = foundRole && foundRole.type === 'internal';

    if (foundRole && isInternal) {
      return (
        foundRole && {
          Header: foundRole?.shortName ?? roleCode,
          LongHeader: foundRole?.name,
          accessor: 'projectAssignmentsLabel' + roleCode,
          sortType: customSortByKey,
          sortKey: foundRole?.name ?? roleCode,
          Filter: SelectColumnFilter,
          filter: multiSelectFilter,
          width: 60,
          hidden: hidden,
          Cell: (props) => (
            <>
              {this.formatContactDataInternal(
                'projectAssignmentsMatched' + roleCode,
                props.row.original
              )}
            </>
          ),
        }
      );
    } else {
      return (
        foundRole && {
          Header: foundRole?.shortName ?? roleCode,
          LongHeader: foundRole?.name,
          accessor: 'projectAssignmentsLabel' + roleCode,
          sortType: customSortByKey,
          sortKey: foundRole?.name ?? roleCode,
          Filter: SelectColumnFilter,
          filter: multiSelectFilter,
          width: 60,
          hidden: hidden,
          Cell: (props) => (
            <>
              {this.formatContactDataExternal(
                'projectAssignmentsMatched' + roleCode,
                props.row.original
              )}
            </>
          ),
        }
      );
    }
  }

  formatContactDataInternal(cell, row) {
    if (row[cell] && row[cell].length > 0) {
      return formatInternalContactData(row[cell], this.state.contactData);
    } else {
      return <span>-</span>;
    }
  }

  formatContactDataExternal(cell, row) {
    if (row[cell] && row[cell].length > 0) {
      return formatExternalContactData(row[cell], this.state.contactData, false);
    } else {
      return <span>-</span>;
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.listData &&
      nextProps.listData.success &&
      nextProps.listData.success === 1
    ) {
      let list = nextProps.listData.data;
      this.setState({
        contactData: nextProps.listData.contactData,
      });

      if (nextProps?.projectRoles?.allProjectRoleTypes) {
        this.setState({
          projectRoleTypes: nextProps.projectRoles.allProjectRoleTypes,
        });
      }
      if (nextProps.allJobStatusCodes) {
        this.setState({
          allJobStatusCodes: nextProps.allJobStatusCodes,
        });
      }
      if (nextProps.reasonCodes) {
        this.setState({
          reasonCodes: nextProps.reasonCodes,
        });
      }

      if (nextProps.allProjectStatus) {
        this.setState({
          allProjectStatus: nextProps.allProjectStatus,
        });
      }
      if (nextProps.jobScope) {
        this.setState({
          jobScope: nextProps.jobScope,
        });
      }

      if (nextProps?.projectFields?.allProjectFieldTypes?.length > 0) {
        this.setState({
          allProjectFieldTypes: nextProps.projectFields.allProjectFieldTypes,
        });
      }
      if (nextProps.listData && nextProps.listData.success && nextProps.listData.success === 1) {
        let fieldsToMatch = {
          projectAssignments: { lookupLocation: 'contactData' },
        };

        let matchedDataForTable = matchDataForTable(
          fieldsToMatch,
          nextProps.listData.data,
          nextProps.listData.contactData,
          this.props
        );

        this.setState({
          totalCount: nextProps.listData.count,
          list: matchedDataForTable,
          fetchRequest: false,
          contactData: nextProps.listData.contactData,
        });
      }

      if (nextProps.listData && nextProps.listData.data && nextProps.listData.data.length == 0) {
        this.setState({ fetchRequest: false });
      }
    }
  }

  redirectToProject(cell, row, enumObject, rowIndex) {
    if (row && row.objectId && row.jobStatusCodesCode === 'NC') {
      return (
        <span>
          <Link to={{ pathname: `/project-details/${row.objectId}`, state: { row } }}>
            {row.jobNumFormatted ? row.jobNumFormatted : '-'}
          </Link>
        </span>
      );
    } else {
      return (
        <span>
          <Link to={{ pathname: `/project-details/${row.objectId}`, state: { row } }}>
            {row.jobNumFormatted ? row.jobNumFormatted : '-'}
          </Link>
        </span>
      );
    }
  }

  onAltJobNum(cell, row, enumObject, rowIndex) {
    if (row && row.objectId && row.jobStatusCodesCode === 'NC') {
      return (
        <span>
          <Link to={{ pathname: `/lead-details/` + row.objectId, state: { row } }}>
            {row.altJobNum ? row.altJobNum : '-'}
          </Link>
        </span>
      );
    } else {
      return (
        <span>
          <Link
            to={{
              pathname: `/project-details/` + row.objectId,
              state: { row },
            }}
          >
            {row.altJobNum ? row.altJobNum : '-'}
          </Link>
        </span>
      );
    }
  }

  onProjectName(cell, row, enumObject, rowIndex) {
    if (row && row.objectId && row.jobStatusCodesCode === 'NC') {
      return (
        <div>
          <strong>
            <Link to={{ pathname: `/lead-details/` + row.objectId, state: { row } }}>
              {row.jobName ? row.jobName : '-'}
            </Link>
          </strong>
          <br />
          <span>{row.marketingDescription ? row.marketingDescription : ''}</span>
        </div>
      );
    } else {
      return (
        <div>
          <strong>
            <Link
              to={{
                pathname: `/project-details/` + row.objectId,
                state: { row },
              }}
            >
              {row.jobName ? row.jobName : '-'}
            </Link>
          </strong>
          <br />
          <span>{row.marketingDescription ? row.marketingDescription : ''}</span>
        </div>
      );
    }
  }

  onJobStatus(cell, row, enumObject, rowIndex) {
    return (
      <span>
        <div className='linkText'>{row.jobStatusCodesCode ? row.jobStatusCodesCode : '-'}</div>
      </span>
    );
  }
  createCustomButtonGroup = (props) => {
    return (
      <div className='pdfLogoBox'>
        <div className='reportHead'>
          <div className='logo'>
            <img src={this.state.comLogo} alt='Upload Logo' />
          </div>
          <h2>Customized Project List Report</h2>
        </div>

        {this.state.subtitle && (
          <div>
            <h5>
              <strong>Subtitle:</strong> {this.state.subtitle}{' '}
            </h5>
          </div>
        )}
      </div>
    );
  };

  _setTableOption() {
    if (this.state.fetchRequest) {
      return (
        <ReactLoading
          className='table-loader'
          type={appConstants.LOADER_TYPE}
          color={appConstants.LOADER_COLOR}
          height={appConstants.LOADER_HEIGHT}
          width={appConstants.LOADER_WIDTH}
        />
      );
    } else {
      return 'No data found..';
    }
  }

  getCustomizeProjectListReport = () => {
    let data = {};
    let { formData, searchText, filterValue } = this.state;
    data['accountId'] = this.state.accountId;
    data['moduleName'] = appConstants.reportModuleName['7'];
    data['fieldToFilter'] = {
      field: formData.field ? formData.field : '',
      operator: formData.operator ? formData.operator : '',
      value: filterValue,
    };
    data['sortKey'] = this.state.sortName ? this.state.sortName : '';
    data['sortType'] = this.state.sortOrder ? this.state.sortOrder : '';
    data['page'] = this.state.currentPage - 1;
    data['search'] = searchText;
    data['displayLimit'] = this.state.sizePerPage;
    this.props.GetCustomizeProjectListReportAction(data);
  };



  


  buildReportColumns() {
    const hasContractValPermissions = isAllowed(moduleConstants.VIEWCONTRACTVAL, 'anyAssigned');
    //const hasProfitValPermissions = isAllowed(moduleConstants.VIEWPROFIT, 'anyAssigned');

    const { allProjectFieldTypes } = this.state;
    

    const dynamicFieldsLoaded = allProjectFieldTypes?.length > 0;

    const defaultFieldCodes = ['BLDGTYPE','SQFT'];

    const customFieldColumns = dynamicFieldsLoaded ? buildDynamicColumns(allProjectFieldTypes.filter(field => !defaultFieldCodes.includes(field.code))) : [];

    const defaultFields = dynamicFieldsLoaded ? allProjectFieldTypes.filter(field => defaultFieldCodes.includes(field.code)) : [];


    const isLeadType = false;

    const rolesLoaded = this.state.projectRoleTypes?.length > 0;

    const defaultRolesCodes = ['EST', 'CL'];



    const enabledRoles = this.state.projectRoleTypes.filter(
      (role) => !defaultRolesCodes.includes(role.code) && (!isLeadType || role.isShowOnLeads)
    );
    const defaultRoles = this.state.projectRoleTypes.filter(
      (role) => defaultRolesCodes.includes(role.code) && (!isLeadType || role.isShowOnLeads)
    );

    
    const defaultColumns = [
      {
        Header: 'Job No.',
        accessor: appConstants.roleList.jobNumFormatted,
        width: 80,
        Cell: (props) => this.redirectToProject(props.column.id, props.row.original, props.value),
      },
      {
        Header: 'Alt Job#',
        accessor: appConstants.roleList.altJobNum,
        width: 80,
        Cell: (props) => this.onAltJobNum(props.column.id, props.row.original, props.value),
      },
      {
        Header: 'Job Name & Description',
        accessor: appConstants.roleList.jobName,
        width: 260,
        Cell: (props) => this.onProjectName(props.column.id, props.row.original, props.value),
      },
      {
        Header: 'Job Address',
        accessor: (row) => formatAddressText(row),
        sortType: 'basic',
        width: 100,
        Cell: (props) => (
          <>
            <Link
              target='_blank'
              rel='noopener noreferrer'
              to={{
                pathname: `https://maps.google.com/?q=` + formatAddressText(props.row.original),
              }}
              data-tooltip-content={props.value}
              data-tooltip-id={props.value}
            >
              {props.value}
            </Link>
          </>
        ),
      },
      this.buildColumnForRole(defaultRoles, 'EST'),
      this.buildColumnForRole(defaultRoles, 'CL'),
      buildColumnForField(defaultFields, 'BLDGTYPE', false),
      {
        Header: 'Status',
        accessor: appConstants.roleList.jobCode,
        Filter: SelectColumnFilter,
        width: 60,
        Cell: (props) => this.onJobStatus(props.column.id, props.row.original, props.value),
      },
      hasContractValPermissions && {
        Header: 'Contract Amt.',
        accessor: 'currentContractAmount',
        width: 100,
        Cell: (props) => onCurrency(props.column.id, props.row.original, props.value),
      },
      buildColumnForField(defaultFields, 'SQFT', false),
      {
        Header: 'Bid Date',
        id: 'bidDue',
        accessor: (row) =>
          row?.bidDue?.iso
            ? new Date(row.bidDue.iso)
            : new Date(formatDate('1800-03-03T12:47:40.000Z')),
        width: 80,
        hidden: true,
        sortType: 'datetime',
        allowDateRangeFilter: true,
        Cell: (props) => onDate(props.column.id, props.row.original, props.value),
      },
      {
        Header: 'Start Date',
        id: 'startDate',
        accessor: (row) =>
          row?.startDate?.iso
            ? new Date(row.startDate.iso)
            : new Date(formatDate('1800-03-03T12:47:40.000Z')),
        width: 80,
        hidden: true,
        sortType: 'datetime',
        allowDateRangeFilter: true,
        Cell: (props) => onDate(props.column.id, props.row.original, props.value),
      },
      {
        Header: 'End Date',
        id: 'endDate',
        hidden: true,
        accessor: (row) =>
          row?.endDate?.iso
            ? new Date(row.endDate.iso)
            : new Date(formatDate('1800-03-03T12:47:40.000Z')),
        width: 80,
        sortType: 'datetime',
        allowDateRangeFilter: true,
        Cell: (props) => onDate(props.column.id, props.row.original, props.value),
      },
      {
        Header: 'Client Reference',
        accessor: (row) => (row?.isRef ? 'Yes' : 'No'),
        Filter: SelectColumnFilter,
        width: 60,
        hidden: true,
        Cell: (props) => onBoolean(props.column.id, props.row.original, props.value),
      },
    ];

    let columns = [];
    if (enabledRoles) {
      const extraColumns = enabledRoles.map((role) =>
        this.buildColumnForRole([role], role.code, true)
      );

      if (columns.length == 0) {
        columns = defaultColumns.filter((cols) => cols?.accessor);
      }

      for (let i in extraColumns) {
        const curColumnsAccessors = columns.map((cols) => cols.accessor);
        if (!curColumnsAccessors.includes(extraColumns[i].accessor)) {
          columns = columns.concat(extraColumns[i]);
        }
      }

      for (let i in customFieldColumns) {
        const curColumnsAccessors = columns.map((cols) => cols.accessor);
        if (!curColumnsAccessors.includes(customFieldColumns[i].accessor)) {
          columns = columns.concat(customFieldColumns[i]);
        }
      }
    } else {
      columns = defaultColumns.filter((cols) => cols?.accessor);
    }

    this.setState({ dynamicColumns: columns });

  }
  render() {
  
    const {
      list, allProjectFieldTypes, dynamicColumns, fetchRequest
    } = this.state;

    const { listPref, SetCustomizeProjectListInfoReportPrefAction } = this.props;

    const dynamicFieldsLoaded = allProjectFieldTypes?.length > 0;

    const rolesLoaded = this.state.projectRoleTypes?.length > 0;

    const dynamicColumnsLoaded = dynamicFieldsLoaded && rolesLoaded;

    
    const dynamicColumnsBuilt = dynamicColumnsLoaded && dynamicColumns.length > 0;

    if(dynamicColumnsLoaded && !dynamicColumnsBuilt){
      this.buildReportColumns();
    }

    return (
      <div>
        <div className='ContactListReport'>
          <TitleComponent data={{ title: `Customized Project List` }} />
          <div>
            <Suspense fallback='loading'>
              <Header props={this.props} />
            </Suspense>
          </div>
          <div className='contactBox'>
            <div className='container'>
              <div className='row no-break-print'>
                {dynamicColumnsBuilt && (
                  <div className='col-md-12'>
                    <div
                      className='table-responsive reportTableSection'
                      ref={(el) => (this.componentRef = el)}
                    >
                      {this.createCustomButtonGroup()}
                      <CustomTable
                        columns={dynamicColumns}
                        list={list}
                        preferences={listPref}
                        className='customized-project-list-table'
                        customTableTitle='Customized Project List Report'
                        onSavePreferences={SetCustomizeProjectListInfoReportPrefAction}
                        isLoading={fetchRequest}
                        canShowRowSelection={false}
                        componentRef={this.componentRef}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <Footer />
          {/* {this.state.modal &&
                <UpdateStatus props={this.props} onClick={this.toggle} stateName={{modal:true}} rowData={this.state.formData} />
              }*/}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    listData: state.reports.customizedList,
    listPref: state.reports.customizeProjectPref,
    isCustomizedList: state.reports.isCustomizedList,
    isCustomizedListError: state.reports.isCustomizedListError,
    allJobStatusCodes: state.jobStatusCode.allJobStatus,
    allReasonCodeByStatusCode: state.reasonCode.allReasonCodeByStatusCode,
    allProjectStatus: state.jobStatusCode,
    reasonCodes: state.reasonCode,
    projectRoles: state.projectRoleType,
    projectFields: state.projectFields,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    GetCustomizeProjectListReportAction,
    GetCustomizeProjectListInfoReportPrefAction,
    SetCustomizeProjectListInfoReportPrefAction,
    getExistingProjectAction,
    getAllJobStatusAction,
    getAllReasonCodeAction,
    getReasonCodesByStatusCodeAction,
    getAllProjectRoleAction,
    getAllProjectFieldsAction,
  })(CustomizedProjectListReport)
);
