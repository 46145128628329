import React, { useCallback, useEffect, useState } from 'react';
import { Tabs, Tab } from 'react-bootstrap';

import { useDispatch } from 'react-redux';
import { enableAdminSetting, enableUserSetting } from '../../../modules/settings';
import settingsTableColumns from '../../shared-settings-components/settings-table-columns';
import useSettingsData from '../../shared-settings-components/use-settings-data';
import ActionModal from '../action-modal/action-modal';
import AddNewItemModal from '../add-new-item-modal';
import SettingsTable from './SettingsTable';

export const SettingsPage = ({
  singularTitle = 'Item',
  tableTitle = `${singularTitle}s`,
  modelName,
  isSuperAdmin,
}) => {
  const dispatch = useDispatch();

  const [searchValue, setSearchValue] = useState('');
  const [activeTab, setActiveTab] = useState('ActiveItems');

  const [addModalOpened, setAddModalOpened] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const [actionModalOpened, setActionModalOpened] = useState(false);

  const { rows, loading } = useSettingsData({ modelName, isSuperAdmin });

  const onAddClick = useCallback(() => {
    setAddModalOpened(true);
    setIsUpdate(false);
  }, []);

  const onRowClick = useCallback((row) => {
    setAddModalOpened(true);
    setIsUpdate(true);
    setSelectedItem({ ...row });
  }, []);

  const onActionPerform = async (row) => {
    if (row.trash)
      await dispatch(
        !isSuperAdmin
          ? enableUserSetting([modelName, row._id])
          : enableAdminSetting([modelName, row._id])
      ).unwrap();
    else {
      setSelectedItem(row);
      setActionModalOpened(true);
    }
  };

  useEffect(() => {
    const newValue = rows.find((r) => r._id === selectedItem?._id);
    if (!newValue) return;
    setSelectedItem({ ...newValue });
  }, [rows]);

  return (
    <div>
      <div className='d-flex justify-content-between pt-2'>
        <div className='headingbox'>
          <span className='heading2'>{tableTitle}</span>
        </div>
        <div className='row'>
          <div className='col-md-12'>
            <div className='d-flex gap-2'>
              <input
                type='text'
                className='form-control'
                placeholder='Search...'
                onChange={(e) => setSearchValue(e.target.value)}
              />
              <button className='btn btnRight' onClick={onAddClick}>
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
      <Tabs selected={activeTab} onSelect={setActiveTab} defaultActiveKey='ActiveItems' id="controlled-tab-settings">
        <Tab eventKey='ActiveItems' title='Active Items'>
          <SettingsTable
            columns={settingsTableColumns(modelName)}
            data={rows.filter((obj) => !obj.trash)}
            isLoading={loading && !rows.length}
            onRowClicked={onRowClick}
            searchKeyword={searchValue}
          />
        </Tab>
        <Tab eventKey='AllItems' title='All Items'>
          <SettingsTable
            columns={settingsTableColumns(modelName, onActionPerform, isSuperAdmin)}
            data={rows}
            isLoading={loading && !rows.length}
            onRowClicked={onRowClick}
            searchKeyword={searchValue}
          />
        </Tab>
      </Tabs>

      <AddNewItemModal
        singularTitle={singularTitle}
        modelName={modelName}
        isSuperAdmin={isSuperAdmin}
        open={addModalOpened}
        onClose={() => setAddModalOpened(false)}
        initialValue={selectedItem}
        isUpdate={isUpdate}
      />

      <ActionModal
        singularTitle={singularTitle}
        modelName={modelName}
        isSuperAdmin={isSuperAdmin}
        isOpen={actionModalOpened}
        rowData={selectedItem}
        onClose={() => setActionModalOpened(false)}
      />
    </div>
  );
};

export default SettingsPage;
