// TODO: try to configure worker-loader from webpack config
import Worker from 'worker-loader!./worker.js';

const worker = new Worker();

export const heavySearch = async (columns, data, searchKeyword) => {
  const result = await new Promise((resolve) => {
    worker.postMessage({ columns, data, searchKeyword });

    worker.addEventListener('message', (e) => {
      const { result } = e.data;

      resolve(result);
    });
  });

  return result;
};
