import * as actionTypes from './actionTypes';
import * as API from '../api/account';

export const updateAccountInfoRequest = () => ({
  type: actionTypes.UPDATE_ACCOUNT_REQUEST,
});

export const updateAccountInfoSuccess = (response) => ({
  type: actionTypes.UPDATE_ACCOUNT_SUCCESS,
  payload: {
    response,
  },
});

export const updateAccountInfoError = (error) => ({
  type: actionTypes.UPDATE_ACCOUNT_ERROR,
  payload: {
    error,
  },
});

export const updateAccountInfoAction = (data) => {
  return (dispatch) => {
    dispatch(updateAccountInfoRequest());
    return API.updateAccountInfo(data)
      .then((response) => {
        dispatch(updateAccountInfoSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          updateAccountInfoError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

//Terms AND CONDITION
export const GetTermsConditionRequest = () => ({
  type: actionTypes.GET_TERMS_CONDITION_REQUEST,
});

export const GetTermsConditionSuccess = (response) => ({
  type: actionTypes.GET_TERMS_CONDITION_SUCCESS,
  payload: {
    response,
  },
});

export const GetTermsConditionError = (error) => ({
  type: actionTypes.GET_TERMS_CONDITION_ERROR,
  payload: {
    error,
  },
});

export const getTermsConditionAction = (data) => {
  return (dispatch) => {
    dispatch(GetTermsConditionRequest());
    return API.getTermsCondition(data)
      .then((response) => {
        dispatch(GetTermsConditionSuccess(response.data.result));
      })
      .catch((error) => {
        dispatch(
          GetTermsConditionError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};
