import * as actionTypes from '../actions/actionTypes';

const initState = {
  allProjectRoleTypes: [],
  message: '',
  isfetched: false,
  isCreated: false,
  success: null,
};

export default (state = initState, action = {}) => {
  switch (action.type) {
    case actionTypes.GET_ALL_PROJECT_ROLE_REQUEST:
      return {
        ...state,
        allProjectRoleTypes: [],
        message: '',
      };

    case actionTypes.GET_ALL_PROJECT_ROLE_SUCCESS:
      return {
        ...state,
        allProjectRoleTypes: action.payload.response.result.data.data,
        message: '',
      };

    case actionTypes.GET_ALL_PROJECT_ROLE_ERROR:
      return {
        ...state,
        message: '',
      };

    default:
      return state;
  }
};
