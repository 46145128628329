import * as actionTypes from './actionTypes';
import * as API from '../api/projectStatus';
import { callAlertMessage } from '../components/Auth/Auth';

export const getAllProjectStatusRequest = () => ({
  type: actionTypes.GET_ALL_PROJECT_STATUS_REQUEST,
});

export const getAllProjectStatusSuccess = (response) => ({
  type: actionTypes.GET_ALL_PROJECT_STATUS_SUCCESS,
  payload: {
    response,
  },
});

export const getAllProjectStatusError = (error) => ({
  type: actionTypes.GET_ALL_PROJECT_STATUS_ERROR,
  payload: {
    error,
  },
});

export const getAllProjectStatusAction = (data) => {
  return (dispatch) => {
    dispatch(getAllProjectStatusRequest());
    return API.getAllProjectStatus(data)
      .then((response) => {
        dispatch(getAllProjectStatusSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          getAllProjectStatusError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

export const updateProjectStatusRequest = () => ({
  type: actionTypes.UPDATE_PROJECT_STATUS_REQUEST,
});

export const updateProjectStatusSuccess = (response) => ({
  type: actionTypes.UPDATE_PROJECT_STATUS_SUCCESS,
  payload: {
    response,
  },
});

export const updateProjectStatusError = (error) => ({
  type: actionTypes.UPDATE_PROJECT_STATUS_ERROR,
  payload: {
    error,
  },
});

export const updateProjectStatusAction = (data, props) => {
  let propsTemp = props;
  return (dispatch) => {
    dispatch(updateProjectStatusRequest());
    return API.updateProjectStatus(data)
      .then((response) => {
        if (response.data && response.data.result) {
          callAlertMessage(
            response.data.result.success === 1 ? 'success' : 'error',
            response.data.result.msg
          );
          dispatch(updateProjectStatusSuccess(response.data));
        } else {
          if(response.data?.error){
          callAlertMessage(
            'error',
            response.data.error
          );}
          dispatch(updateProjectStatusError(response.data.result));
        }
      })
      .catch((error) => {
        dispatch(
          updateProjectStatusError(
            error.response !== undefined
              ? error.response.data.error
              : { msg: 'Internet Connection Error' }
          )
        );
      });
  };
};