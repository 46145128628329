import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { getProjectHistoryQuickAction } from '../../../actions/changeOrder';
import { selectAllProjectActivities } from '../../../selectors/changeOrder';
import { selectProjectData } from '../../../selectors/project';
import styles from './QuickHistoryModal.module.css';
import { formatDate } from '../../../utils/date-formatters';
import { CustomTable } from '../../Common/CustomTable';
import { onCurrency, onDate, onText } from '../../../utils/column-formatters';

const QuickHistoryModal = ({
  isOpen = false,
  toggle = () => {},
  onToggleChangeEventDateModal = () => {},
  onRowClick = () => {},
}) => {
  const dispatch = useDispatch();

  const projectData = useSelector(selectProjectData);
  const allProjectActivities = useSelector(selectAllProjectActivities);

  useEffect(() => {
    if (isOpen && projectData?.objectId) {
      dispatch(
        getProjectHistoryQuickAction({
          projectId: projectData.objectId,
        })
      );
    }
  }, [dispatch, isOpen, projectData?.objectId]);

  const changeOrderDate = (cell, row, enumObject, rowIndex) => (
    <a onClick={onToggleChangeEventDateModal}>
      {row.eventDate?.iso ? formatDate(row.eventDate.iso) : formatDate(row.createdAt)}
    </a>
  );

  const onChangePerson = (cell, row, value, rowIndex) => {
    if (row && typeof row.eventChangeByUser === 'object' || typeof value === 'object') {
      const { firstName, lastName } = row.eventChangeByUser || value;
      return firstName && lastName ? `${firstName} ${lastName}` : '-';
    } else {
      return <span>-</span>;
    }
  };

  const onReasonCode = (cell, row, enumObject, rowIndex) => {
    if ((row && row.statusReasonCode) || row.startDateReasonCode || row.endDateReasonCode) {
      return (
        <span>
          <strong>Status:</strong> {row.statusReasonCode || 'NA'} <br />
          <strong> Start Date:</strong> {row.startDateReasonCode || 'NA'} <br />
          <strong> End Date:</strong> {row.endDateReasonCode || 'NA'}
        </span>
      );
    } else {
      return <span>-</span>;
    }
  };

  const columns = useMemo(() => [
    { id: 'Date', Header: 'Date', accessor: 'eventDate', Cell: (props) => <>{changeOrderDate(props.column.id, props.row.original, props.value)}</> },
    { id: 'Status', Header: 'Status', accessor: 'jobStatusCodesName', Cell: ({ column, row, value }) => <>{onText(column.id, row.original, value)}</> },
    { id: 'Job End', Header: 'Job end', accessor: 'endDate', Cell: ({ column, row, value }) => <>{onDate(column.id, row.original, value)}</> },
    { id: 'Changed On', Header: 'Changed on', accessor: 'updatedAt', Cell: ({ column, row, value }) => <>{onDate(column.id, row.original, value)}</> },
    { id: 'Changed By', Header: 'Changed by', accessor: 'eventChangeByUser', Cell: ({ column, row, value }) => <>{onChangePerson(column.id, row.original, value)}</> },
    { id: 'Contract', Header: 'Contract', accessor: 'currentContractAmount', Cell: ({ column, row, value }) => <>{onCurrency(column.id, row.original, value)}</> },
    { id: 'Gross Margin', Header: 'Gross margin', accessor: 'currentGrossProfit', Cell: ({ column, row, value }) => <>{onCurrency(column.id, row.original, value)}</> },
    { id: 'Reason Code', Header: 'Reason code', accessor: 'statusReasonCode', Cell: ({ column, row, value }) => <>{onReasonCode(column.id, row.original, value)}</> },
    { id: 'Comment', Header: 'Comment', accessor: 'comment' },
  ], []);

  return (
    <Modal
      id='projectStatusUpdatePopup'
      className={styles.modalDialog}
      backdrop='static'
      isOpen={isOpen}
      toggle={toggle}
    >
      <ModalHeader toggle={toggle}>Quick History</ModalHeader>
      <ModalBody>
        <div className='modalBody'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-12'>
                <div className='table-responsive1'>
                  <CustomTable
                    columns={columns.filter(Boolean)}
                    list={allProjectActivities}
                    inlineEdit={false}
                    canShowExport={false}
                    canShowPagination={true}
                    canShowGlobalFilter={false}
                    canShowColumnSettings={false}
                    canShowDateFilter={false}
                    canShowRowSelection={false}
                    useControlledState={(state) => ({ ...state })}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button className='btn btn-primary' onClick={toggle}>
          Cancel
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default QuickHistoryModal;
