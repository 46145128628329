import _ from 'lodash';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useAppDispatch } from '../../shared/hooks/use-app-dispatch';
import { getUpdateProjectForm } from '../../modules/projects';
import { formatDate } from '../../utils/date-formatters';
import { formatMoney } from '../../utils/number-formatters';
import styles from './progress-suggestions-modal.module.scss';
import { getAccountProjectSettings } from '../../modules/accounts';

type ProgressSuggestionsModalProps = {
  changes: any;
  isOpen: boolean;
  toggle: () => void;
  onUpdateProgress: (data: any) => Promise<void>;
};

export const ProgressSuggestionsModal = ({
  changes = {},
  isOpen = false,
  toggle,
  onUpdateProgress,
}: ProgressSuggestionsModalProps) => {
  const dispatch = useAppDispatch();

  const [isCO, setIsCO] = useState(false);
  
  const [,setAccountSettings] = useState<any>({});

  const [restrictContractAdjustments, setRestrictContractAdjustments] = useState(false);

  useEffect(() => {
    dispatch(getAccountProjectSettings({}))
      .unwrap()
      .then((data: any) => {setAccountSettings({ ...data });
        setRestrictContractAdjustments(data.restrictContractAdjustments)});
  }, []);



  const confirmationMessage = () => {
    const affectedMsg = hasDateChange ? 'a potential delay in the project schedule' : '';
      const profitPart = hasProfitChange ? 'estimated project profit' : '';
      const valuePart = hasTotalChange ? 'total contract amount' : '';
      const hasValueChange = hasProfitChange || hasTotalChange;
      const valueMsg = hasValueChange ? 'a potential change in the ' + (hasProfitChange && hasTotalChange ? profitPart + ' and ' + valuePart : profitPart + valuePart) : '';

    if (isCO) {
      

      return <>This approved change order includes {affectedMsg} {affectedMsg && valueMsg ? 'and' : ''} {valueMsg}.<br /><br />
        Do you wish to update the current project progress with the recommended changes in schedule and profit?</>;
    }
    else {
      return <>This change to the original contract includes {affectedMsg} {affectedMsg && valueMsg ? 'and' : ''} {valueMsg}.<br /><br />
        Do you wish to update the current project progress with the recommended changes in schedule and profit?</>;
    }
  }

  const [projectData, setProjectData] = useState<any>({});

  useEffect(() => {
    if (!_.isEmpty(changes) && changes.projectId) {
      dispatch(getUpdateProjectForm({ projectId: changes.projectId }))
        .unwrap()
        .then((data) => setProjectData({ ...data }));
    }

    if (!_.isEmpty(changes) && changes.isCO) {
      setIsCO(true);
    } else {
      setIsCO(false);
    }

  }, [changes]);

  const [hasDateChange,setHasDateChange] = useState(false);
  const [hasProfitChange,setHasProfitChange] = useState(false);
  const [hasTotalChange,setHasTotalChange] = useState(false);


  useEffect(() => {
    //const hasProjectData = !_.isEmpty(projectData);

    if(isCO){
      const { scheduleImpact, profitDollars, coTotalAmount } = changes;
      //const { contractAmount, grossProfit } = projectData;
      const currentEndDateObj = moment(projectData.endDate).endOf('day')
      const changedEndDateObj = moment(projectData.endDate).endOf('day').add(scheduleImpact, 'days');
      const totalChange = coTotalAmount;
  
      const dateDifference = changedEndDateObj.diff(currentEndDateObj, 'days');

      if(dateDifference != 0){
        setHasDateChange(true);
      }

      if(profitDollars != 0){
        setHasProfitChange(true);
      }

      if(!restrictContractAdjustments && totalChange != 0){
        setHasTotalChange(true);
      }
      
    }else{
      const { totalChange, profitChange, startDateChange, endDateChange } = changes;
      //const { contractAmount, grossProfit } = projectData;
      const currentStartDateObj = moment(projectData.startDate).endOf('day');
      const currentEndDateObj = moment(projectData.endDate).endOf('day')
      const changedStartDateObj = moment(projectData.startDate).endOf('day').add(startDateChange, 'days');
      const changedEndDateObj = moment(projectData.endDate).endOf('day').add(endDateChange, 'days');
  
      const differenceStart = changedStartDateObj.diff(currentStartDateObj, 'days');
      const differenceEnd = changedEndDateObj.diff(currentEndDateObj, 'days');

      if(differenceStart != 0 || differenceEnd != 0){
        setHasDateChange(true);
      }

      if(profitChange != 0){
        setHasProfitChange(true);
      }

      if(!restrictContractAdjustments && totalChange != 0){
        setHasTotalChange(true);
      }
    }


  },[projectData]);


  const getCOChangesTable = useCallback(() => {
    const hasProjectData = !_.isEmpty(projectData);
    const { scheduleImpact, profitDollars, coTotalAmount } = changes;
    const { contractAmount, grossProfit } = projectData;
    const currentEndDateObj = moment(projectData.endDate).endOf('day')
    const changedEndDateObj = moment(projectData.endDate).endOf('day').add(scheduleImpact, 'days');
    const totalChange = coTotalAmount;

    const dateDifference = changedEndDateObj.diff(currentEndDateObj, 'days');

    return (
      <table className={`table ${styles.originalContract}`}>
        <thead>
          <tr>
            <th></th>
            <th>Current Value</th>
            <th>Approved C/O</th>
            <th>Suggested Value</th>
          </tr>
        </thead>
        <tbody>
          {dateDifference != 0 && (
            <tr>
              <td>Anticipated End Date</td>
              {hasProjectData && <td>{formatDate(currentEndDateObj)}</td>}
              <td>{dateDifference > 0 ? '+' : ''}{dateDifference} days</td>
              <td>{formatDate(changedEndDateObj)}</td>
            </tr>
          )}
          {profitDollars != 0 && (
            <tr>
              <td>Est. Gross Profit</td>
              {hasProjectData && <td>{formatMoney(grossProfit)}</td>}
              <td>{profitDollars > 0 ? '+' : ''}{formatMoney(profitDollars)}</td>
              <td>{formatMoney(grossProfit + profitDollars)}</td>
            </tr>
          )}
          {!restrictContractAdjustments && totalChange != 0 && (
            <tr>
              <td>Total Contract Amount</td>
              {hasProjectData && <td>{formatMoney(contractAmount)}</td>}
              <td>{totalChange > 0 ? '+' : ''}{formatMoney(totalChange)}</td>
              <td>{formatMoney(contractAmount + totalChange)}</td>
            </tr>
          )}
        </tbody>
      </table>

    );
  }, [projectData, changes]);

  const getOrigContractChangesTable = useCallback(() => {
    const { totalChange, profitChange, startDateChange, endDateChange } = changes;
    const { contractAmount, grossProfit } = projectData;
    const currentStartDateObj = moment(projectData.startDate).endOf('day');
    const currentEndDateObj = moment(projectData.endDate).endOf('day')
    const changedStartDateObj = moment(projectData.startDate).endOf('day').add(startDateChange, 'days');
    const changedEndDateObj = moment(projectData.endDate).endOf('day').add(endDateChange, 'days');

    const differenceStart = changedStartDateObj.diff(currentStartDateObj, 'days');
    const differenceEnd = changedEndDateObj.diff(currentEndDateObj, 'days');

    return (
      <table className={`table ${styles.originalContract}`}>
        <thead>
          <tr>
            <th></th>
            <th>Current Value</th>
            <th>Changes made to Original Contract</th>
            <th>Suggested Value</th>
          </tr>
        </thead>
        <tbody>
          {differenceStart != 0 && (
            <tr>
              <td>Anticipated Start Date</td>
              <td>{formatDate(currentStartDateObj)}</td>
              <td>{differenceStart > 0 ? '+' : ''}{differenceStart} days</td>
              <td>{formatDate(changedStartDateObj)}</td>
            </tr>
          )}
          {differenceEnd != 0 && (
            <tr>
              <td>Anticipated End Date</td>
              <td>{formatDate(currentEndDateObj)}</td>
              <td>{differenceEnd > 0 ? '+' : ''}{differenceEnd} days</td>
              <td>{formatDate(changedEndDateObj)}</td>
            </tr>
          )}
          {profitChange != 0 && (
            <tr>
              <td>Est. Gross Profit</td>
              <td>{formatMoney(grossProfit)}</td>
              <td>{profitChange > 0 ? '+' : ''}{formatMoney(profitChange)}</td>
              <td>{formatMoney(grossProfit + profitChange)}</td>
            </tr>
          )}
          {!restrictContractAdjustments && totalChange != 0 && (
            <tr>
              <td>Total Contract Amount</td>
              <td>{formatMoney(contractAmount)}</td>
              <td>{totalChange > 0 ? '+' : ''}{formatMoney(totalChange)}</td>
              <td>{formatMoney(contractAmount + totalChange)}</td>
            </tr>
          )}
        </tbody>
      </table>
    );
  }, [projectData, changes]);

  const changesTable = useMemo(() => {
    if (_.isEmpty(changes)) {
      return null;
    }

    return changes.isCO ? getCOChangesTable() : getOrigContractChangesTable();
  }, [changes, projectData]);

  const onSubmit = useCallback(() => {
    toggle();
    onUpdateProgress && onUpdateProgress(changes);
  }, [changes]);

  return (
    <Modal backdrop='static' isOpen={isOpen} toggle={toggle} size='lg'>
      <ModalHeader toggle={toggle}>Suggested changes to project progress</ModalHeader>
      <ModalBody className={styles.wrapper}>
        <span>{confirmationMessage()}</span>
        {changesTable}
      </ModalBody>
      <ModalFooter>
        <button type='button' className='btn btn-primary' onClick={toggle}>
          Only save {isCO ? 'Change Order' : 'Original Contract'}
        </button>
        <button type='button' className='btn btn-primary ms-auto' onClick={onSubmit}>
          Update project progress
        </button>
      </ModalFooter>
    </Modal>
  );
};
