import { useEffect, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import {
  addEditVariableMasterAction,
  addVariableData,
  getVariableMasterAction,
  updateVariableDataByIndex,
} from '../../actions/accountSettings';
import {callAlertMessage} from '../../components/Auth/Auth';
import { selectVariableAddEdit, selectVariableData } from '../../selectors/accountSettings';
import { startDateFormatWithoutTimeUTCF, yearPickerValue } from '../../utils/date-formatters';
import { appConstants } from '../../_constants';

const VariableHistoryModal = ({ isOpen = false, toggle = () => {} }) => {
  const [searchFor, setSearchFor] = useState('');

  const dispatch = useDispatch();

  const { data: variableHistory = [] } = useSelector(selectVariableData);
  const variableAddEdit = useSelector(selectVariableAddEdit);

  useEffect(() => {
    if (isOpen) {
      dispatch(
        getVariableMasterAction({
          accountId: localStorage.getItem('accountId'),
          userId: localStorage.getItem('userId'),
        })
      );
    } else {
      setSearchFor('');
    }
  }, [dispatch, isOpen]);

  useEffect(() => {
    if (!variableAddEdit.success) {
      return;
    }

    (async () => {
      variableAddEdit.success = 0;

      await dispatch(
        getVariableMasterAction({
          accountId: localStorage.getItem('accountId'),
          userId: localStorage.getItem('userId'),
        })
      );

      callAlertMessage('success', variableAddEdit.msg);
    })();
  }, [dispatch, variableAddEdit]);

  const searchVarHistory = (value) => {
    setSearchFor(value);

    dispatch(
      getVariableMasterAction({
        accountId: localStorage.getItem('accountId'),
        userId: localStorage.getItem('userId'),
        search: value.value,
      })
    );
  };

  const handleChangeVar = (x) => (e) => {
    e.persist();

    if (e.target.value.toString().length < 14) {
      dispatch(updateVariableDataByIndex({ [e.target.name]: e.target.value }, x));
    }
  };

  const handleChangeAddVar = (x, name) => (value) => {
    dispatch(updateVariableDataByIndex({ [name]: value }, x));
  };

  const editUpdateVar = (x) => () => {
    dispatch(updateVariableDataByIndex({ editTrue: true }, x));
  };

  const handleValidation = (type, row) => {
    if (type === 'row') {
      dispatch(
        updateVariableDataByIndex(
          {
            monthClass: variableHistory[row].monthNum,
            yearClass: variableHistory[row].year,
          },
          row
        )
      );

      if (!variableHistory[row].monthNum || !variableHistory[row].year) {
        return false;
      }
    }
    return true;
  };

  const saveUpdateVar = (data, x) => () => {
    if (data.monthData && data.yearData) {
      data.monthlyOverheadVariable =
        data.monthlyOverheadVariable === '' ? 0 : parseInt(data.monthlyOverheadVariable, 10);
      data.monthlyOverheadFixed =
        data.monthlyOverheadFixed === '' ? 0 : parseInt(data.monthlyOverheadFixed, 10);
      data.year = data.yearData.value;
      data.monthNum = data.monthData.value;
      data.month = data.monthData.label;
      data.varStartDate = new Date(`${data.month}/01/${data.year}`);
      data.varStartDate = new Date(data.varStartDate.getTime() + 1000 * 60 * 60 * 24);
      data.varStartDate = startDateFormatWithoutTimeUTCF(data.varStartDate);
      data.monthDate = `${data.month}-${data.year}`.replace(/['"]+/g, '');
      data.accountId = localStorage.getItem('accountId');
      data.userId = localStorage.getItem('userId');
    }

    if (handleValidation('row', x)) {
      dispatch(addEditVariableMasterAction(data));
    }
  };

  const curMonth = new Date().month;
  const curYear = new Date().year;

  const variableAddMore = () => {
    dispatch(
      addVariableData({
        editTrue: true,
        month: appConstants.monthNumArray[new Date().month],
        year: '',
        yearData: '',
        monthData: {label:appConstants.monthNumArray[new Date().month],value:curMonth},
        monthlyOverheadVariable: '',
        monthlyOverheadFixed: '',
        monthClass: 'true',
        yearClass: 'true',
        monthlyExVarClass: 'true',
        monthlyExFixedClass: 'true',
      })
    );
  };

  const optionYears = yearPickerValue([]);

  const data = variableHistory.map((val, idx) => {
    let attr = true;
    let showEdit = true;
    let selectDisabled = true;

    if (val.editTrue) {
      showEdit = false;
    }
    if (!val.objectId) {
      showEdit = false;
      selectDisabled = false;
    }
    if (!val.objectId || val.editTrue) {
      attr = false;
    }

    return (
      <tr key={val.objectId ?? idx}>
        <td width='15%'>
          <Select
            name='year'
            className={val.yearClass || val.yearData ? '' : ' error'}
            styles={{
              menuList: (base) => ({ ...base, maxHeight: 128 }),
            }}
            components={makeAnimated()}
            value={val.yearData}
            onChange={handleChangeAddVar(idx, 'yearData')}
            options={optionYears}
            isDisabled={selectDisabled}
          />
        </td>
        <td width='15%'>
          <Select
            name='month'
            className={val.monthClass || val.monthData ? '' : ' error'}
            styles={{
              menuList: (base) => ({ ...base, maxHeight: 128 }),
            }}
            components={makeAnimated()}
            value={val.monthData}
            onChange={handleChangeAddVar(idx, 'monthData')}
            options={appConstants.monthNumArray}
            isDisabled={selectDisabled}
          />
        </td>
        <td width='25%' className='woVarFixed'>
          <input
            type='number'
            className='form-control'
            id='monthlyOverheadVariable'
            name='monthlyOverheadVariable'
            value={val.monthlyOverheadVariable}
            onChange={handleChangeVar(idx)}
            maxlength='12'
            disabled={attr}
          />
        </td>
        <td width='25%' className='woVarFixed'>
          <input
            type='number'
            className='form-control'
            id='monthlyOverheadFixed'
            name='monthlyOverheadFixed'
            value={val.monthlyOverheadFixed}
            onChange={handleChangeVar(idx)}
            maxlength='12'
            disabled={attr}
          />
        </td>
        <td width='10%'>
          {!attr && <button onClick={saveUpdateVar(val, idx)}>Save</button>}

          {showEdit && (
            <button onClick={editUpdateVar(idx)}>
              <i className='fa fa-pencil' aria-hidden='true'></i>
            </button>
          )}
        </td>
      </tr>
    );
  });

  return (
    <Modal backdrop='static' isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Est. Overhead & Variable Costs</ModalHeader>
      <ModalBody>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='ovs'>
                <Select
                  name='year'
                  components={makeAnimated()}
                  value={searchFor}
                  onChange={searchVarHistory}
                  options={optionYears}
                  placeholder='Filter By Year'
                />
              </div>

              <div className='csTableHead'>
                <table width='100%'>
                  <tr>
                    <th width='15%'>Year</th>
                    <th width='15%'>Month</th>
                    <th width='25%'>Estimated Variable Overhead (%)</th>
                    <th width='25%'>Estimated Fixed Monthly Overhead ($)</th>
                    <th width='10%'>Action</th>
                  </tr>
                </table>
              </div>

              <div className='csTableBody'>
                <table width='100%'>
                  <tbody>
                    {data.length === 0 && <h6 className='text-center'>No data found...</h6>}

                    <Scrollbars style={{ height: 350 }}>{data}</Scrollbars>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button className='btn btn-primary' onClick={toggle}>
          Cancel
        </button>
        <button className='btn btn-primary' onClick={variableAddMore}>
          Add More
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default VariableHistoryModal;
