import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { getAllNotesAction, deleteNotesAction, pinNoteAction } from '../../actions/message';
import _ from 'lodash';
import { LightBoxComponent } from './LightBox.component';
import NoteEditor from '../Dialogs/note-editor-modal';
import { NoteView } from './RightSectionRender';
import NotePopUp from '../Common/NotePopUp';
import styles from './right-section.module.scss';

const NotesTab = ({
  location,
  typeId,
  typeRef,
  allMessages,
  getAllNotesAction,
  deleteNotesAction,
  canFollowUp,
}) => {
  const [accountId] = useState(localStorage.getItem('accountId'));
  const [commentList, setCommentList] = useState([]);
  const [showEditNote, setShowEditNote] = useState(false);
  const [followUpNote, setFollowUpNote] = useState(false);
  const [lightBoxOpen, setLightBoxOpen] = useState(false);
  const [selectedNote, setSelectedNote] = useState(null);
  const [attachmentIndex, setAttachmentIndex] = useState(-1);
  const [noteAction, setNoteAction] = useState('NEW');
  const [noteObjectId, setNoteObjectId] = useState(null);
  const [showNoteDetailModal, setShowNoteDetailModal] = useState(false);
  const [showNoteDetailId, setShowNoteDetailId] = useState(null);
  const [filterText, setFilterText] = useState('');


  /*
  useEffect(() => {
    
    
  }, [location.pathname, typeId, typeRef]);*/

  useEffect(() => {
    if (location.pathname.includes('notes/followUp')) {
      setFollowUpNote(true);
      setShowEditNote(true);
      
    }else if (location.pathname.includes('notes/view')) {

      const noteId = location.pathname.split('/').pop();
      setShowNoteDetailId(noteId);
      setShowNoteDetailModal(true);
    
    }else if (location.pathname.includes('notes/add')) {
      setShowEditNote(true);
      window.scrollTo(0, 0);
    }

    getAllNotes();
    
  }, [location.pathname, typeId, typeRef]);

  useEffect(() => {
    if (allMessages !== commentList) {
      setCommentList(allMessages);
    }
  }, [allMessages]);

  const formatNoteAttachments = (selectedNote) => {
    return selectedNote?.attachments?.map((attachment) => ({
      ...attachment,
      description:
        selectedNote.title + '\n' + selectedNote.description?.replace(/(<([^>]+)>)/gi, ''),
    }));
  };

  const getAllNotes = () => {
    const data = {
      accountId,
      typeId: typeId,
      typeRef: typeRef,
      page: 0,
      displayLimit: 99999999,
    };
    getAllNotesAction(data);
  };

  const cancelDelete = (noteData, confirm) => async () => {
    const confirmed =
      (await confirm?.({
        title: 'Delete note',
        question: 'Are you sure you want to Delete this note?',
      })) ?? true;

    if (!confirmed) {
      return;
    }

    const formData = {
      objectId: noteData.objectId,
      typeRef: noteData.typeRef,
      typeId: noteData.typeId,
    };

    await deleteNotesAction(formData);
    getAllNotes();
  };

  const editNote = (data) => {
    const objectId = data.objectId;
    setNoteObjectId(objectId);

    setNoteAction('EDIT');
    setShowEditNote(true);
  };

  const replyToNote = (noteData) => {
    const noteObjectId = noteData.objectId;
    setNoteObjectId(noteObjectId);

    setNoteAction('REPLY');
    setShowEditNote(true);
  };

  const pinNote = async (noteData, shouldPin=true) => {
    const pinData = {
      noteId: noteData.objectId,
      typeRef: noteData.typeRef,
      typeId: noteData.typeId,
      shouldPin: shouldPin,
    };
    await pinNoteAction(pinData);
    getAllNotes();
    
  };

  const showEditNoteCallback = () => {
    setShowEditNote(true);
    setNoteObjectId('');
    setNoteAction('NEW');
  };

  const showEditNoteCallbackFollowUp = () => {
    setFollowUpNote(true);
    setShowEditNote(true);
    setNoteObjectId('');
    setNoteAction('NEW');
  };

  const closeWindowCallback = () => {
    setShowEditNote(false);
    setNoteObjectId('');
    setNoteAction('NEW');
  };

  const showHideLightBox = (note, attachmentIndex) => {
    setLightBoxOpen(!lightBoxOpen);
    setSelectedNote(note);
    setAttachmentIndex(attachmentIndex);
  };

  const closeDetailModal = () => {
    setShowNoteDetailModal(false);
    setShowNoteDetailId(null);
  };

  const sortNotes = (notes) => {
    let rootNotes = notes.filter((note) => !note.replyTo);

    let sortedNotes = rootNotes
      .map((note) => {
        let replies = notes.filter((n) => n.parentNoteId === note.objectId);
        replies.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
        return [note, ...replies];
      })
      .flat();
    return sortedNotes;
  };

  const commentListData =
    commentList?.length > 0 ? (
      sortNotes(commentList).map((v) => (
        <NoteView
          noteData={v}
          editNote={editNote}
          replyToNote={replyToNote}
          cancelDelete={cancelDelete}
          showHideLightBox={showHideLightBox}
          pinNote={pinNote}
          filterText={filterText}
        ></NoteView>
      ))
    ) : (
      <div className='outputBox scheduleHtml'>No note activities</div>
    );

  const showEditNoteComponent = showEditNote || noteObjectId;

  const canFollowUpLocal = canFollowUp||location.pathname.includes('notes/followUp');

  return (
    <div>
      {!showEditNoteComponent ? (
        <div className='container'>
          <br />
          <div className='row'>
            <div className='col-md-12'>
              <div className='pageinfo d-flex'>
                <h2> Notes </h2>
                
                <div className='ms-auto d-flex align-items-start'>
                  <input
                    type='text'
                    className={`${styles.activitySearch} btn-group form-control-sm lh-base me-2`}
                    placeholder='Search'
                    onChange={(e) => setFilterText(e.target.value)}
                  />
                  {canFollowUpLocal ? (
                    
                    <div className='btn-group'>     
                      <button
                        type='button'
                        className='btn btn-secondary'
                        onClick={showEditNoteCallback}
                      >
                        Add Note
                      </button>
                      <button
                        type='button'
                        className='btn btn-secondary dropdown-toggle dropdown-toggle-split'
                        data-bs-toggle='dropdown'
                        aria-expanded='false'
                      >
                        <span className='visually-hidden'>Toggle Dropdown</span>
                      </button>
                      <ul className='dropdown-menu'>
                        <li>
                          <a className='dropdown-item' href='#' onClick={showEditNoteCallbackFollowUp}>
                            Add follow-up note
                          </a>
                        </li>
                      </ul>
                    </div>
                  ) : (
                    <button className='btn btn-primary' onClick={showEditNoteCallback}>
                      Add Note
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <br />
          <NoteEditor
            showEditNote={showEditNoteComponent}
            typeId={typeId}
            typeRef={typeRef}
            objectId={noteObjectId}
            noteAction={noteAction}
            closeWindowCallback={closeWindowCallback}
            followUpNote={followUpNote}
          ></NoteEditor>
        </div>
      )}
      <Scrollbars style={{ height: 'calc(100vh)', minHeight:'500px'}}>{commentListData}</Scrollbars>
      <LightBoxComponent
        attachments={formatNoteAttachments(selectedNote) ?? []}
        shouldOpen={lightBoxOpen}
        initialIndex={attachmentIndex}
        closeCallback={showHideLightBox}
      />
      {showNoteDetailModal && showNoteDetailId && (
        <NoteView
          noteId={showNoteDetailId}
          typeId={typeId}
          typeRef={typeRef}
          asModal={true}
          editNote={editNote}
          replyToNote={replyToNote}
          cancelDelete={cancelDelete}
          showHideLightBox={showHideLightBox}
          toggle={closeDetailModal}
          showModal={true}
        ></NoteView>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { message } = state;
  const { allMessage } = message;
  return {
    allMessages: allMessage && allMessage.length ? allMessage : [],
  };
};

export default withRouter(
  connect(mapStateToProps, {
    getAllNotesAction,
    deleteNotesAction,
    pinNoteAction,
  })(NotesTab)
);
