import React, {Suspense} from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import CreatableSelect from 'react-select/creatable';

import { CustomDateInput } from '../../../shared/custom-fields-controls/form-controls/custom-date-input';
import moment from "moment";
import ReactLoading from 'react-loading';
import { appConstants } from '../../../_constants';
import {
  createCustomFieldAction,
  getAllCustomFieldAction,
  updateCustomFieldAction,
  deleteCustomFieldAction,
} from '../../../actions/customFields';
import CustomTable from "../../Common/CustomTable";
import './../../../index.css';
import TitleComponent from  "../../Common/TitleComponent";

import { UpperCaseName } from '../../../components/Auth/Auth';
import { formatDateWithTime,formatDateObject,formatDate,formatDateObjectOrNull } from '../../../utils/date-formatters';

// eslint-disable-next-line no-undef
let Validator = require('validatorjs');
let styleData = { color: 'red' };
let formArr = {};
let rules = {
  customFieldType: 'required',
  customFieldsLabel: 'required',
  value1: 'required',
  // customFieldsLabel2: 'required',
  // value2            : 'required'
};
let mess = {
  required: 'This field is required',
};
let validation = [];
validation = new Validator([], rules, mess);
validation.passes();
validation.fails();

class CustomFields extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hidden: true, // htmlFor use objectid hide in table
      sessionToken: localStorage.getItem('sessionToken'),
      userId: localStorage.getItem('userId'),
      accountId: localStorage.getItem('accountId'),
      list: [],
      formArr: [],
      modal: false,
      saveType: 'POST',
      selectBox: {},
      formData: {},
      fetchRequest: true,
      isRoleCreated: false,
      successRedirect: false,
      selectedRows: [],
    };

    this.toggle = this.toggle.bind(this);
    this.handleRowSelection = this.handleRowSelection.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.allCustomFields && nextProps.allCustomFields.length > 0){
      this.setState({ list: nextProps.allCustomFields.map((p) => ({ ...p, disabled: p.isSystemMaster === true && localStorage.getItem('loginType') !== 'SUPERADMIN' })), fetchRequest: false });
    }
    if (nextProps.allCustomFields && nextProps.allCustomFields.length === 0) {
      let _this = this;
      setTimeout(function () {
        _this.setState({ fetchRequest: false });
      }, 3000);
    }

    if (nextProps.isRoleCreated === true) {
      this.setState({ modal: false, formData: {}, formArr: [], selectBox: [] });
      this.list();
    }
  }

  list() {
    let data = {};
    data['accountId'] = this.state.accountId;
    this.props.getAllCustomFieldAction(data);
  }

  UNSAFE_componentWillMount() {
    this.list();
  }

  toggle(val) {
    if (val === 'POST') {
      this.setState({ formData: {}, selectBox: [] }); //Open modal htmlFor add
    } else {
      this.setState({ saveType: 'PUT' });
      let selectBox = this.state.selectBox;
      let formData = this.state.formData;

      appConstants.ModuleNamesPage.map((v) => {
        if (formData.customFieldType === v.value) {
          selectBox['customFieldType'] = v;
        }
        return true;
      });
      appConstants.OptionFieldType.map((v) => {
        if (formData.value1 === v.value) {
          selectBox['value1'] = v;
        }
        return true;
      });
      appConstants.OptionFieldType.map((v) => {
        if (formData.value2 === v.value) {
          selectBox['value2'] = v;
        }
        return true;
      });

      this.setState({ formData: this.state.formData, selectBox }); //Open modal htmlFor edit
    }
    this.setState({ modal: !this.state.modal, formArr: [] });
  }

  selectedRow1 = (row) => {
    this.setState({
      formData: row,
    });
    this.state.formData = row;
    this.toggle('PUT'); //Select row to open edit modal
  };

  add(event) {
    event.preventDefault();
    let formData = this.state.formData;
    formData['userId'] = this.state.userId;
    formData['accountId'] = this.state.accountId;
    formData['status'] =
      this.state.selectBox.status && this.state.selectBox.status.value
        ? this.state.selectBox.status.value
        : 'Enabled';
    formData['moduleId'] = this.state.formData.objectId;
    if (this.allValidate(false)) {
      if (this.state.saveType === 'POST') {
        this.props.createCustomFieldAction(formData, this.props);
      } else {
        this.props.updateCustomFieldAction(formData, this.props);
      }
    }
  }

  allValidate(check) {
    if (!check) {
      formArr = [];
      Object.keys(rules).forEach(function (key) {
        formArr[key] = 'TT';
      });
      this.setState({
        formArr,
      });
    }
    if (validation.passes()) {
      return 1;
    }
  }

  handleDeleteButtonClick = () => {
    let data = {};
    const { selectedRows, list } = this.state;
    const keysToDelete = list.filter((f, index) => selectedRows.includes(index.toString())).map((k) => ({
      objectId: k.objectId
    }));
    data['className'] = 'cppCustomFields';
    data['dataArr'] = keysToDelete;
    const confirm = window.confirm("Are you sure to delete?");
    if(confirm) {
      this.props.deleteCustomFieldAction(data);
      this.setState({ successRedirect: true, isRoleCreated: false });
    } else {
      console.log({ confirm });
    }
  };

  handleRowSelection = (ids) => {
    this.setState({ selectedRows: ids });
  }

  handleChange = (e) => {
    e.preventDefault();
    let formData = this.state.formData;
    formData[e.target.name] = e.target.name === "sortPriority" ? Number(e.target.value) : UpperCaseName(e.target.value);
    this.setState({ formData });
  };

  handleChangeSelect = (name, value) => {
    let formData = this.state.formData;
    let selectBox = this.state.selectBox;
    if(Array.isArray(value)) {
      const val = value?.filter(s => s).map((v) => v.value).join('¤§');
      formData[name] = val;
      selectBox[name] = val;
    } else if (moment.isMoment(value)) {
      formData[name] = value.toDate();
      selectBox[name] = value.toDate();
    } else {
      formData[name] = value?.value??'';
      selectBox[name] = value;
    }
    this.setState({
      formData: formData,
    });
  };

  _setTableOption() {
    if (this.state.fetchRequest) {
      return (
        <ReactLoading
          className="table-loader"
          type={appConstants.LOADER_TYPE}
          color={appConstants.LOADER_COLOR}
          height={appConstants.LOADER_HEIGHT}
          width={appConstants.LOADER_WIDTH}
        />
      );
    } else {
      return 'No data found..';
    }
  }

  customFieldType(cell, row, enumObject, rowIndex) {
    switch (row.customFieldType) {
      case 'USER':
        return 'Contacts (Employee)';
      case 'CONTACT':
        return 'External Contacts';
      case 'COMPANY':
        return 'Company';
      case 'PROJECT':
        return 'Leads and Projects';
      default:
        return true;
    }
  }

  renderDefaultValue = () => {
    switch (this.state.selectBox?.value1?.value) {
      case 'URL':
      case 'TEXT':
        return (<input
            type="text"
            className="form-control"
            name="valueDefault"
            id="valueDefault"
            placeholder="Default Value"
            value={this.state.formData.valueDefault}
            onChange={(e) => {
              this.handleChange(e);
            }}
        />);
      case 'CHECKBOX':
        const valueDefault = this.state.formData.valueDefault==='true'?{ value: 'true', label: 'True'}: { value: 'false', label: 'False'};
        return (
            <Select name="valueDefault" options={[{ value: 'true', label: 'True'},
              { value: 'false', label: 'False'},
              ]}
              components={makeAnimated()}
              value={valueDefault}
              placeholder="Select default value for checkbox"
              onChange={this.handleChangeSelect.bind(this, 'valueDefault')}
            />
        );
      case 'DATE':
        return (
            <CustomDateInput
                className="form-control"
                selected={formatDateObjectOrNull(this.state.formData.valueDefault)}
                dateFormat={appConstants.DATEFORMATPICKER}
                placeholderText="Click to select a date"
                name="valueDefault"
                todayButton="Today"
                showMonthDropdown
                isClearable
                onChange={this.handleChangeSelect.bind(this, 'valueDefault')} />
        );
      case 'Dropdown':
      case 'MultiSelectDropDown':
      case 'EditableDropdown':
      case 'MultiSelectEditableDropDown': {
        const valueOptions = this.state.formData.valueOptions;
        const valueDefault = this.state.formData.valueDefault?{ value: this.state.formData.valueDefault, label: this.state.formData.valueDefault}:{};
        const options = valueOptions?.split('¤§')?.filter(s => s)?.map((item) => ({ label: item, value: item }));
        return (
            <Select
                name="valueDefault"
                isClearable
                isDisabled={!options}
                options={options || []}
                components={makeAnimated()}
                value={valueDefault}
                placeholder="Select default value for dropdown"
                onChange={this.handleChangeSelect.bind(this, 'valueDefault')}
            />
        );
      }
      default:
        return (<input
            type="text"
            className="form-control"
            name="valueDefault"
            id="valueDefault"
            placeholder="Default Value"
            value={this.state.formData.valueDefault}
            onChange={(e) => {
              this.handleChange(e);
            }}
        />);
    }
  }

  render() {
    validation = new Validator(this.state.formData, rules, mess);
    validation.passes();
    validation.fails();

    const selectRowProp = {
      mode: 'checkbox',
    };

    //show option, delete, pagination, number of data, search
    let tableOption = appConstants.TableOption;
    tableOption['afterDeleteRow'] = this.handleDeleteButtonClick;
    tableOption['paginationShowsTotal'] = this.renderShowsTotal;
    tableOption['onRowClick'] = this.selectedRow1;
    tableOption['noDataText'] = this._setTableOption();

    const columns = [
      {
        Header: "Type",
        accessor: "customFieldType",
        Cell: (props) => this.customFieldType(props.column.id, props.row.original, props.value),
        width: 290
      },
      {
        Header: "Label",
        accessor: "customFieldsLabel",
        width: 240
      },
      {
        Header: "Value",
        accessor: "value1",
        width: 300
      },
      {
        Header: "Sort Priority",
        accessor: "sortPriority",
        width: 100
      },
    ];

    const fieldsWithOptions = ['Dropdown', 'MultiSelectDropDown', 'EditableDropdown', 'MultiSelectEditableDropDown'];

    const valueOptions = this.state.formData.valueOptions;
    const options = valueOptions?.split('¤§')?.filter(s => s)?.map((item) => ({ label: item, value: item }));

    return (
      <>
        <div className="headingbox">
          <h2 className="heading2">Legacy Custom Fields</h2>
        </div>
        Note these fields will be removed in future releases. Please use the new Contact/Company Fields and Project Fields sections.
        <div className="row">
          <div className="col-md-12">
            {/*<button className="btn btnRight" onClick={() => this.toggle('POST')}>
              Add
            </button>*/}

            <button className="btn btn-primary" style={{cursor: 'pointer', padding: '5px'}} disabled={this.state.selectedRows?.length < 1} onClick={() => this.handleDeleteButtonClick()}>
              <i className="fa fa-trash" aria-hidden="true"></i>&nbsp;Delete
            </button>
            <CustomTable
                columns={columns}
                list={this.state.list}
                isLoading={this.state.fetchRequest}
                canShowColumnSettings={false}
                canShowDateFilter={false}
                canShowExport={false}
                canShowPagination={false}
                onSavePreferences={()=>{}}
                canShowRowSelection={true}
                onRowClicked={(cell, row, e) => this.selectedRow1(row.original)}
                onChangeRowSelection={this.handleRowSelection}/>

          </div>
        </div>

        <Modal
          backdrop="static"
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggle}>
            {this.state.formData.objectId ? 'Edit Custom Field' : 'Add Custom Field'}
          </ModalHeader>
          <ModalBody>
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="roleCode">
                      Page Location<span className="requiredField">*</span>
                    </label>
                    <Select
                      name="customFieldType"
                      components={makeAnimated()}
                      value={this.state.selectBox.customFieldType}
                      onChange={this.handleChangeSelect.bind(this, 'customFieldType')}
                      options={appConstants.ModuleNamesPage}
                    />
                    <span style={styleData} className="error">
                      {this.state.formArr.customFieldType &&
                        validation.errors.first('customFieldType')}
                    </span>
                  </div>{' '}
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="customFieldsLabel">
                      Label Name<span className="requiredField">*</span>
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        name="customFieldsLabel"
                        id="customFieldsLabel"
                        placeholder="Label Name"
                        value={this.state.formData.customFieldsLabel}
                        onChange={(e) => {
                          this.handleChange(e);
                        }}
                    />
                    <span style={styleData} className="error">
                      {this.state.formArr.customFieldsLabel &&
                      validation.errors.first('customFieldsLabel')}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="roleCode">
                      Control Type<span className="requiredField">*</span>
                    </label>
                    <Select
                      name="value1"
                      components={makeAnimated()}
                      value={this.state.selectBox.value1}
                      onChange={this.handleChangeSelect.bind(this, 'value1')}
                      options={appConstants.OptionFieldType}
                    />
                    <span style={styleData} className="error">
                      {this.state.formArr.value1 && validation.errors.first('value1')}
                    </span>
                  </div>
                </div>
                {fieldsWithOptions.includes(this.state.selectBox.value1?.value) && <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="customFieldsOptionsValue">
                      Options
                    </label>
                    <span style={styleData} className="error">
                      {this.state.formArr.value1 && validation.errors.first('valueOptions')}
                    </span>
                    <CreatableSelect
                        name="valueOptions"
                        components={makeAnimated()}
                        value={options}
                        onChange={this.handleChangeSelect.bind(this, 'valueOptions')}
                        options={options}
                        isMulti
                    />
                    <span style={styleData} className="error">
                      {this.state.formArr.valueOptions &&
                      validation.errors.first('valueOptions')}
                    </span>
                  </div>
                </div>}
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="customFieldsDefaultValue">
                      Default Value
                    </label>
                    {this.renderDefaultValue()}
                    <span style={styleData} className="error">
                      {this.state.formArr.customFieldsLabel &&
                      validation.errors.first('valueDefault')}
                    </span>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="sortPriority">
                      Sort Priority
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        name="sortPriority"
                        id="sortPriority"
                        placeholder="Enter Priority 1-10000"
                        value={this.state.formData.sortPriority}
                        onChange={(e) => {
                          this.handleChange(e);
                        }}
                    />
                    <span style={styleData} className="error">
                      {this.state.formArr.customFieldsLabel &&
                      validation.errors.first('sortPriority')}
                    </span>
                  </div>
                </div>
              </div>
              {/* <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="customFieldsLabel2">Label2 Name<span className="requiredField">*</span></label>
                    <input
                      type="text"
                      className="form-control"
                      name="customFieldsLabel2"
                      id="customFieldsLabel2"
                      placeholder="Label2 Name"
                      value={this.state.formData.customFieldsLabel2}
                      onChange={e => {this.handleChange(e);}}/>
                    <span style={styleData} className="error">{this.state.formArr.customFieldsLabel2 && validation.errors.first('customFieldsLabel2')}</span>
                  </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label htmlFor="roleCode">Control Type<span className="requiredField">*</span></label>
                        <Select name="value2"
                          components={makeAnimated()}
                          value={this.state.selectBox.value2}
                          onChange={this.handleChangeSelect.bind(this, "value2")}
                          options={appConstants.OptionFieldType}
                        />
                        <span style={styleData} className="error">{this.state.formArr.value2 && validation.errors.first('value2')}</span>
                    </div>
                </div>
              </div> */}
            </div>
          </ModalBody>
          <ModalFooter>
            <button className="btn btn-primary" onClick={this.toggle}>
              Cancel
            </button>
            <button className="btn btn-primary" onClick={(e) => this.add(e)}>
              Submit
            </button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    allCustomFields: state.customFields.allCustomFields,
    isRoleCreated: state.customFields.isRoleCreated,
    message: state.customFields.message,
    success: state.customFields.success,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    createCustomFieldAction,
    getAllCustomFieldAction,
    updateCustomFieldAction,
    deleteCustomFieldAction,
  })(CustomFields)
);
