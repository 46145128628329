import apiClient from '../../utils/api-client';

export const getResourceScheduleFiltersRequest = () =>
  apiClient.post('parse/functions/resourceScheduleReportFilters');

export const getResourceScheduleRequest = (data) =>
  apiClient.post('parse/functions/resourceScheduleReport', data);

export const getEstimatingScheduleReportRequest = (data) =>
  apiClient.post('reports/getEstimatingScheduleReport', data).then((res) => res.data);

export const getActiveScheduleReportRequest = (data) =>
  apiClient.post('reports/getActiveScheduleReport', data).then((res) => res.data);

export const processTemplateFileRequest = (data) =>
  apiClient.post('reports/processTemplateFile', data).then((res) => res.data);

export const fetchProjectTemplateTagsRequest = (data) =>
  apiClient.post('reports/fetchProjectTemplateTags', data).then((res) => res.data);
