import React, {Suspense} from 'react';

import { Link, withRouter } from 'react-router-dom';
import Header from '../../Layout/Header.js';
import Footer from '../../Layout/Footer.js';
import { connect } from 'react-redux';
import { appConstants } from '../../../_constants';
import {
  GetBidPendingReportAction,
  GetBidPendingReportPrefAction,
  SetBidPendingReportPrefAction,
} from '../../../actions/reports';

import { getAllProjectRoleAction } from '../../../actions/projectRole';
import ReactLoading from 'react-loading';
import NotePopUp from '../../Common/NotePopUp';
import renderHTML from 'html-react-parser';
import TitleComponent from  "../../Common/TitleComponent";
import {CustomTable,multiSelectFilter,customSortByKey} from '../../Common/CustomTable';
import {Tooltip} from 'react-tooltip';
import {DateColumnFilter} from '../../Common/CustomTableComponents/DateColumnFilter';
import {SelectColumnFilter} from '../../Common/CustomTableComponents/SelectColumnFilter';
import {
  formatExternalContactData,
  formatInternalContactData,
} from '../../../utils/contact-formatters';
import { formatDate } from '../../../utils/date-formatters';
import { formatNote } from '../../../utils/note-formatters';
import {
  matchDataForTable
} from '../../../utils/match-for-table.jsx';
import { getAllProjectFieldsAction } from '../../../actions/projectFields';
import { isAllowed, moduleConstants } from '../../../_constants';
import {buildColumnForField, buildDynamicColumns, onCurrency} from '../../../utils/column-formatters';



var sortingOrder = '';

class BidPendingReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {},
      accountId: localStorage.getItem('accountId'),
      userId: localStorage.getItem('userId'),
      list: [],
      fetchRequest: true,
      comLogo: '',
      totalCount: 0,
      currentPage: 1,
      sizePerPage: appConstants.TableSizePerPageList[2].value,
      searchText: '',
      dynamicColumns: [],
      noteModal: false,
      noteData: '',
      sortType: 'DESC',
      sortKey: '',
      contactData: {},
      projectRoleTypes: [],
      allProjectFieldTypes: [],
    };
    this.onPageChange = this.onPageChange.bind(this);
    this.redirectToProject = this.redirectToProject.bind(this);
    this.onNoteDetail = this.onNoteDetail.bind(this);
    this.onNoteModalClose = this.onNoteModalClose.bind(this);
  }

  
  


  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps?.listData?.success === 1 
    ) {
      const fieldsToMatch = {
        projectAssignments: { lookupLocation: 'contactData' },
      };

      let matchedDataForTable=matchDataForTable(fieldsToMatch,nextProps.listData.data,nextProps.listData.contactData,this.props);
            
      this.setState({
          totalCount: nextProps.listData.count,
          list: matchedDataForTable,
          contactData: nextProps.listData.contactData,
          fetchRequest: false,
      });
    }

    if (nextProps?.projectRoles?.allProjectRoleTypes) {
      this.setState({
        projectRoleTypes: nextProps.projectRoles.allProjectRoleTypes,
      });
    }

    if (nextProps?.projectFields?.allProjectFieldTypes?.length > 0) {
      this.setState({
        allProjectFieldTypes: nextProps.projectFields.allProjectFieldTypes,
      });
    }


    if (nextProps.listData && nextProps.listData.data && nextProps.listData.data.length == 0) {
      this.setState({ fetchRequest: false });
    }
  }

  componentDidMount() {
    this.setState({ sizePerPage: appConstants.TableSizePerPageList[2].value });
    let data = {};
    data['accountId'] = this.state.accountId;
    data['moduleName'] = appConstants.reportModuleName['8'];
    data['page'] = 0;
    data['displayLimit'] = 9999999999;
    data['search'] = this.state.searchText;
    data['sortKey'] = this.state.sortKey;
    data['sortType'] = this.state.sortType;
    this.props.GetBidPendingReportAction(data);

    sortingOrder = '';
    if (
      localStorage.getItem('companyLogo') !== 'undefined' &&
      localStorage.getItem('companyLogo') !== null
    ) {
      this.setState({
        comLogo: localStorage.getItem('companyLogo'),
      });
    } else {
      let constImage = appConstants.CONTENT_CPOST_LOGO_URL;
      this.setState({ comLogo: constImage });
    }
    this.props.GetBidPendingReportPrefAction();

    this.props.getAllProjectRoleAction({
      className: 'projectRoleTypes',
      accountId: this.state.accountId,
    });

    this.props.getAllProjectFieldsAction({
      accountId: this.state.accountId,
      minimumStage: 'bidding',
    });
  }

  buildColumnForRole(filteredRoles, roleCode, hidden = false) {
    const foundRole = filteredRoles.find((role) => role.code == roleCode);
    if(!foundRole)
      return
    const isInternal = foundRole&& foundRole.type === 'internal';

    if (foundRole && isInternal) {
      return (foundRole &&
      {
        Header: foundRole?.shortName ?? roleCode,
          LongHeader: foundRole?.name,
        accessor: 'projectAssignmentsLabel' + roleCode,
        sortType: customSortByKey,
        sortKey: foundRole?.name ?? roleCode,
        Filter: SelectColumnFilter,
        filter: multiSelectFilter,
        width: 60,
        hidden: hidden,
        Cell: (props) => <>{this.formatContactDataInternal('projectAssignmentsMatched' + roleCode, props.row.original)}</>,
      })
    } else {
      return (foundRole &&
      {
        Header: foundRole?.shortName ?? roleCode,
          LongHeader: foundRole?.name,
        accessor: 'projectAssignmentsLabel' + roleCode,
        sortType: customSortByKey,
        sortKey: foundRole?.name ?? roleCode,
        Filter: SelectColumnFilter,
        filter: multiSelectFilter,
        width: 60,
        hidden: hidden,
        Cell: (props) => <>{this.formatContactDataExternal('projectAssignmentsMatched' + roleCode, props.row.original)}</>,
      })
    }
  }

  formatContactDataInternal(cell, row) {
    if (row[cell] && row[cell].length > 0) {
      return formatInternalContactData(row[cell], this.state.contactData);
    } else {
      return <span>-</span>;
    }
  }

  formatContactDataExternal(cell, row) {
    if (row[cell] && row[cell].length > 0) {
      return formatExternalContactData(row[cell], this.state.contactData,false);
    } else {
      return <span>-</span>;
    }
  }
  


  onGetProjectSEDate(cell, row, rowIndex) {
    if (row && typeof row.startDate === 'object') {
      return row.startDate && row.startDate.iso
        ? formatDate(row.startDate.iso)
        : '';
    } else {
      return <span>-</span>;
    }
  }

  projectBidDate(cell, row) {
    if (row && typeof row.bidDue === 'object') {
      return row.bidDue && row.bidDue.iso
          ? formatDate(row.bidDue.iso)
          : '';
    } else {
      return <span>-</span>;
    }
  }

  projectStartDate(cell, row) {
    if (row && typeof row.startDate === 'object') {
      return row.startDate && row.startDate.iso
          ? formatDate(row.startDate.iso)
          : '';
    } else {
      return <span>-</span>;
    }
  }

  redirectToProject(cell, row) {
    if (cell === 'jobNumFormatted' && row) {
      return (
        <div>
          <Link
            to={{
              pathname: `/project-details/` + row.objectId,
              state: { row },
            }}
          >
            {row.jobNumFormatted ? row.jobNumFormatted : '-'}
          </Link>
        </div>
      );
    }

    if (cell === 'jobName' && row) {
      return (
        <div>
          <Link
            to={{
              pathname: `/project-details/` + row.objectId,
              state: { row },
            }}
          >
            {row.jobName ? row.jobName : '-'}
          </Link>
        </div>
      );
    }

    if (cell === 'altJobNum' && row) {
      return (
        <div>
          <Link
            to={{
              pathname: `/project-details/` + row.objectId,
              state: { row },
            }}
          >
            {row.altJobNum ? row.altJobNum : '-'}
          </Link>
        </div>
      );
    }
  }

  onJobStatus(cell, row, enumObject, rowIndex) {
    return row && row.jobStatusCodesCode ? row.jobStatusCodesCode : '-';
  }

  onNoteDetail(cell, row, enumObject, rowIndex) {
    // return (row && row.jobNotes) ? row.jobNotes : '-';
    const notes =
      formatNote(row?.jobNotes);
    return notes ? (
      <a
        className="anchorNote "
        data-type={'reminder'}
        onClick={(e) => {
          this.getModalOpen(e, row);
        }}
      >
        {renderHTML(notes)}
      </a>
    ) : (
      '-'
    );
  }

  onNoteModalClose() {
    this.setState({ noteModal: false });
  }

  getModalOpen(e, row) {
    let noteText = row && row.jobNotes ? row.jobNotes : '-';
    let lastNoteId = row && row.lastNoteId ? row.lastNoteId : null;
    this.setState({ noteModal: true, noteData: {objectId:lastNoteId,description: noteText,typeId: row.objectId, typeRef: 'PROJECT'} });
  }


  onPageChange(page, sizePerPage) {
    let data = {};
    data['accountId'] = this.state.accountId;
    data['moduleName'] = appConstants.reportModuleName['8'];
    // if (this.state.searchText === "") {
    //     data['page'] = (page === -1) ? 0 : page - 1;
    //     data['displayLimit'] = sizePerPage;
    // } else {
    data['page'] = page === -1 ? 0 : page - 1;
    data['displayLimit'] = sizePerPage;
    data['search'] = this.state.searchText;
    //}
    data['sortKey'] = this.state.sortKey;
    data['sortType'] = this.state.sortType;
    this.setState({ sizePerPage: sizePerPage });
    this.setState({ currentPage: page });
    this.props.GetBidPendingReportAction(data);
  }

  createCustomButtonGroup = (props) => {
    return (
      <div className="pdfLogoBox">
        <div className="reportHead">
          <div className="logo">
            <img src={this.state.comLogo} alt="Upload Logo" />
          </div>
          <h2>Bids Pending Report</h2>
        </div>
        
      </div>
    );
  };

  _setTableOption() {
    if (this.state.fetchRequest) {
      return (
        <ReactLoading
          className="table-loader"
          type={appConstants.LOADER_TYPE}
          color={appConstants.LOADER_COLOR}
          height={appConstants.LOADER_HEIGHT}
          width={appConstants.LOADER_WIDTH}
        />
      );
    } else {
      return 'No data found..';
    }
  }


  


  buildReportColumns() {
    const hasContractValPermissions = isAllowed(moduleConstants.VIEWCONTRACTVAL, 'anyAssigned');
    const hasProfitValPermissions = isAllowed(moduleConstants.VIEWPROFIT, 'anyAssigned');

    const { list, allProjectFieldTypes } = this.state;
   


    const dynamicFieldsLoaded = allProjectFieldTypes?.length > 0;

    const defaultFieldCodes = ['OCCTYPE','SCOPE'];

    const customFieldColumns = dynamicFieldsLoaded
      ? buildDynamicColumns(
          allProjectFieldTypes.filter((field) => !defaultFieldCodes.includes(field.code))
        )
      : [];

    const defaultFields = dynamicFieldsLoaded
      ? allProjectFieldTypes.filter((field) => defaultFieldCodes.includes(field.code))
      : [];

    const isLeadType = false;

    const rolesLoaded = this.state.projectRoleTypes?.length > 0;

    const defaultRolesCodes = ['EST','CL','PM'];

    const dynamicColumnsLoaded = dynamicFieldsLoaded && rolesLoaded;

    const enabledRoles = this.state.projectRoleTypes.filter(
      (role) => !defaultRolesCodes.includes(role.code) && (!isLeadType || role.isShowOnLeads)
    );
    const defaultRoles = this.state.projectRoleTypes.filter((role) =>
      defaultRolesCodes.includes(role.code) && (!isLeadType || role.isShowOnLeads)
    );

    const defaultColumns = [
      {
        Header: "Job Num.",
        accessor: "jobNumFormatted",
        sortType: 'basic',
        width: 90,
        Cell: (props) => <><span data-tooltip-content={props.value}
                                 data-tooltip-id={props.value}>{this.redirectToProject(props.column.id, props.row.original)}</span>
          <Tooltip id={props.value} delayShow={1000} place="bottom" />
        </>
      },
      {
        Header: "Bid Date",
        accessor:  row => (row?.bidDue?.iso) ? new Date(row.bidDue.iso) :  new Date(formatDate('1800-03-03T12:47:40.000Z')),
        width: 100,
        Cell: (props) => <>{this.projectBidDate(props.column.id, props.row.original, props.value)}</>,
        filter: DateColumnFilter,
        allowDateRangeFilter: true,
        sortType: "datetime",
        id: "bidDue"
      },
      {
        Header: "Alt Job#",
        accessor: "altJobNum",
        width: 90,
        Cell: (props) => this.redirectToProject(props.column.id, props.row.original)
      },
      buildColumnForField(defaultFields, 'SCOPE', false),
      {
        Header: "Start Date",
        accessor:  row => row?.startDate?.iso ? new Date(row.startDate.iso) : new Date(formatDate('1800-03-03T12:47:40.000Z')),
        id: "startDate",
        width: 100,
        Cell: (props) => <>{this.projectStartDate(props.column.id, props.row.original, props.value)}</>,
        filter: DateColumnFilter,
        allowDateRangeFilter: true,
        sortType: "datetime"
      },
      hasContractValPermissions && {
        Header: 'Est. Contract',
        accessor: 'currentContractAmount',
        width: 100,
        Cell: (props) => (
          <>{onCurrency(props.column.id, props.row.original, props.value)}</>
        ),
      },
      hasProfitValPermissions && {
        Header: 'Est. Profit',
        accessor: 'currentGrossProfit',
        width: 100,
        hidden: true,
        Cell: (props) => (
          <>{onCurrency(props.column.id, props.row.original, props.value)}</>
        ),
      },
      {
        Header: "Job Name",
        accessor: "jobName",
        width: 160,
        Cell: (props) => <>{this.redirectToProject(props.column.id, props.row.original, props.value)}</>,
      },
      this.buildColumnForRole(defaultRoles,'EST'),
      this.buildColumnForRole(defaultRoles,'PM'),
      buildColumnForField(defaultFields, 'OCCTYPE', false),
      {
        Header: "Status",
        accessor: "jobStatusCodesName",
        width: 90,
        Filter: SelectColumnFilter,
        Cell: (props) => <>{this.onJobStatus(props.column.id, props.row.original, props.value)}</>,
      },
      this.buildColumnForRole(defaultRoles,'CL'),
      {
        Header: 'Notes',
        accessor: 'jobNotes',
        hidden: true,
        width: 120,
        Cell: (props) => (
          <>
            <span
              data-tooltip-html={props.value}
              data-tooltip-id={props.column.id + ':' + props.row.id}
            >
              {this.onNoteDetail(props.column.id, props.row.original, props.value)}
            </span>
            <Tooltip
              id={props.column.id + ':' + props.row.id}
              delayShow={1000}
              style={{ color: '#fff !important' }}
              place='bottom'
            />
          </>
        ),
      },
    ];
    let columns = [];
    if (enabledRoles) {
      const extraColumns = enabledRoles.map((role) => (
        this.buildColumnForRole([role], role.code,true)));

      if (columns.length == 0) {
        columns = defaultColumns.filter(cols=>cols?.accessor);
      }

      for (let i in extraColumns) {
        const curColumnsAccessors = columns.map((cols) => cols.accessor);
        if (!curColumnsAccessors.includes(extraColumns[i].accessor)) {
          columns = columns.concat(extraColumns[i]);
        }
      }

      for (let i in customFieldColumns) {
        const curColumnsAccessors = columns.map((cols) => cols.accessor);
        if (!curColumnsAccessors.includes(customFieldColumns[i].accessor)) {
          columns = columns.concat(customFieldColumns[i]);
        }
      }
    } else {
      columns = defaultColumns.filter(cols=>cols?.accessor);
    }

    this.setState({ dynamicColumns: columns });

  }
  render() {
  
    const {
      list, allProjectFieldTypes, dynamicColumns
    } = this.state;


    const dynamicFieldsLoaded = allProjectFieldTypes?.length > 0;

    const rolesLoaded = this.state.projectRoleTypes?.length > 0;

    const dynamicColumnsLoaded = dynamicFieldsLoaded && rolesLoaded;

    
    const dynamicColumnsBuilt = dynamicColumnsLoaded && dynamicColumns.length > 0;

    if(dynamicColumnsLoaded && !dynamicColumnsBuilt){
      this.buildReportColumns();
    }
    
    const { listPref, SetBidPendingReportPrefAction } = this.props;

    const isWidget = !!this.props?.props?.isWidget;


    return (
      <div>
        <div className="ContactListReport">
        <TitleComponent data={{title:`Bids Pending`}}/>
        {!isWidget&&(
            <Suspense fallback="loading"><Header props={this.props} /></Suspense>)}
          <div className="contactBox">
            <div className="container">
              <div className="row no-break-print">
              {dynamicColumnsBuilt&&<div className="col-md-12">
                  <div className="table-responsive reportTableSection" ref={(el) => (this.componentRef = el)}>
                    {this.createCustomButtonGroup()}
                    <CustomTable
                        columns={dynamicColumns}
                        list={list}
                        preferences={listPref}
                        className="bid-pending-table"
                        customTableTitle={(!isWidget?"Bids Pending Report":'')}
                        onSavePreferences={SetBidPendingReportPrefAction}
                        isLoading={this.state.fetchRequest}
                        canShowRowSelection={false}
                        componentRef={this.componentRef}
                        canShowHeaderItems={!isWidget}
                        pageSizeArray={isWidget?[5]:[50,100,200]}
                    />
                  </div>
                </div>}
              </div>
            </div>
          </div>
          {this.state.noteModal && (
            <NotePopUp
              isOpen={this.state.noteModal}
              onClick={this.onNoteModalClose}
              noteInfo={this.state.noteData}
            />
          )}
          {!isWidget&&(
          <Footer />)}
        </div>
      </div>
    );
  }
}


function mapStateToProps(state) {
  return {
    listData: state.reports.bidPendingRecapList,
    listPref: state.reports.bidPendingRecapListPref,
    projectRoles: state.projectRoleType,
    projectFields: state.projectFields,
  };
}

export default (
  withRouter(connect(mapStateToProps, {
    GetBidPendingReportAction,
    GetBidPendingReportPrefAction,
    SetBidPendingReportPrefAction,
    getAllProjectRoleAction,
    getAllProjectFieldsAction,
  })(BidPendingReport))
);
