import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getCompanyEditInfo } from '../../../modules/companies';
import useCountryCodes from '../../../shared/hooks/use-country-codes';
import { formatPhone, formatPhoneForCall } from '../../../utils/formatters';
import styles from './style.module.css';

type ViewCompanyDetailInfoProps = {
  company: any;
};

export const ViewCompanyDetailInfo = ({ company = {} }: ViewCompanyDetailInfoProps) => {
  const { objectId: companyId = '', updatedAt } = company;

  const countries = useCountryCodes();
  const dispatch: any = useDispatch();

  const [companyData, setCompanyData] = useState<any>({});

  useEffect(() => {
    if (companyId) {
      (async () => {
        const companyData = await dispatch((getCompanyEditInfo as any)({ companyId })).unwrap();

        setCompanyData(companyData);
      })();
    }
  }, [companyId, updatedAt]);

  const getPhonesMarkup = (phones) =>
    phones.map((phone, index) => {
      const phoneNumber = formatPhone(phone, countries, true);
      const phoneNumberForCall = formatPhoneForCall(phone);
      const { type } = phone;

      return (
        <tr key={index}>
          <td>
            <a href={`tel:${phoneNumberForCall}`}>{phoneNumber}</a>
          </td>
          <td>{type && `(${type})`}</td>
        </tr>
      );
    });

  const getEmailsMarkup = (emails) =>
    emails.map(({ email, label }, index) => {
      return (
        <tr key={index}>
          <td>
            <a href={`mailto:${email}`}>{email}</a>
          </td>
          <td>{label && `(${label})`}</td>
        </tr>
      );
    });

  const getAddressesMarkup = (addresses) =>
    addresses.map((addr, index) => {
      const {
        address1 = '',
        address2 = '',
        country = '',
        state = '',
        city = '',
        zipCode = '',
        label = '',
      } = addr;
      const addressRow = [address1, address2].filter((addr) => addr).join(', ');
      const countryRow = [city, state, country, zipCode].filter((addr) => addr).join(', ');
      const resultRow = [addressRow, countryRow].filter((addr) => addr).join(' <br /> ');

      return (
        <tr key={index}>
          <td dangerouslySetInnerHTML={{ __html: resultRow }}></td>
          <td>{label && `(${label})`}</td>
        </tr>
      );
    });

  const getWebsiteMarkup = (companyWebsite) => {
    const url = companyWebsite?.startsWith('http') ? companyWebsite : 'http://' + companyWebsite;

    return (
      <tr key={9999}>
        <td>
          <a href={url} target='_blank' rel='noreferrer'>
            {companyWebsite.trim()}
          </a>
        </td>
        <td></td>
      </tr>
    );
  };

  const primaryFirstSort = (a, b) => b.isPrimary - a.isPrimary;

  const renderCompanyData = () => {
    const { phoneArr = [], emailArr = [], addressArr = [], companyWebsite } = companyData;
    const phoneArrSorted = [...phoneArr.filter((ph) => ph.phone)].sort(primaryFirstSort);
    const emailArrSorted = [...emailArr.filter((em) => em.email)].sort(primaryFirstSort);
    const addressArrSorted = [
      ...addressArr.filter(
        (addr) => addr.address1 || addr.address2 || addr.country || addr.state || addr.city
      ),
    ].sort(primaryFirstSort);

    if (!phoneArrSorted.length && !emailArrSorted.length && !addressArrSorted.length && !companyWebsite) {
      return null;
    }

    return (
      <table className={styles.affiliationTable}>
        <tbody>
          {phoneArrSorted.length ? (
            <tr>
              <td>Phone number</td>
              <td>
                <table>
                  <tbody>{getPhonesMarkup(phoneArrSorted)}</tbody>
                </table>
              </td>
            </tr>
          ) : null}
          {emailArrSorted.length ? (
            <tr>
              <td>Email</td>
              <td>
                <table>
                  <tbody>{getEmailsMarkup(emailArrSorted)}</tbody>
                </table>
              </td>
            </tr>
          ) : null}
          {addressArrSorted.length ? (
            <tr>
              <td>Address</td>
              <td>
                <table>
                  <tbody>{getAddressesMarkup(addressArrSorted)}</tbody>
                </table>
              </td>
            </tr>
          ) : null}
          {companyWebsite?.length > 0 ? (
            <tr>
              <td>Website</td>
              <td>
                <table>
                  <tbody>{getWebsiteMarkup(companyWebsite)}</tbody>
                </table>
              </td>
            </tr>
          ) : null}
        </tbody>
      </table>
    );
  };

  return (
    <div className={styles.wrapper} data-testid='viewCompanyDetailInfo'>
      {renderCompanyData()}
    </div>
  );
};
