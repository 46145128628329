import { UserRolesType } from '@collections';
import { CppModule, JobStatusCode, JobStatusCodeForm, ModuleType } from '@module/settings';
import apiClient from '../../utils/api-client';

export const addUserSettingsRequest = (modelName, settings) =>
  apiClient.post('/settings/addUserSettings', { modelName, settings });

export const updateUserSettingsRequest = (modelName, settings) =>
  apiClient.post('/settings/updateUserSettings', { modelName, settings });

export const addAdminSettingsRequest = (modelName, settings) =>
  apiClient.post('/settings/addAdminSettings', { modelName, settings });

export const updateAdminSettingsRequest = (modelName, settings) =>
  apiClient.post('/settings/updateAdminSettings', { modelName, settings });

export const getUserSettingsRequest = (modelName, settingId) =>
  apiClient.post('/settings/getUserSettings', { modelName, settingId });

export const getAdminSettingsRequest = (modelName) =>
  apiClient.post('/settings/getAdminSettings', { modelName });

export const enableUserSettingRequest = (modelName, settingId) =>
  apiClient.post('/settings/enableUserSetting', {
    modelName,
    settingId,
  });

export const disableUserSettingRequest = (modelName, settingId) =>
  apiClient.post('/settings/disableUserSetting', {
    modelName,
    settingId,
  });

export const resetUserSettingRequest = (modelName, settingId) =>
  apiClient.post('/settings/resetUserSetting', {
    modelName,
    settingId,
  });

export const syncQuickbooksItemsRequest = () => apiClient.post('/settings/syncQuickbooksItems');

export const enableAdminSettingRequest = (modelName, settingId) =>
  apiClient.post('/settings/enableAdminSetting', {
    modelName,
    settingId,
  });

export const disableAdminSettingRequest = (modelName, settingId) =>
  apiClient.post('/settings/disableAdminSetting', {
    modelName,
    settingId,
  });

export const getPermissionMatrixColumnsRequest = (moduleType: ModuleType) =>
  apiClient
    .post<CppModule[]>('/settings/getPermissionMatrixColumns', {
      moduleType,
    })
    .then((r) => r.data);

export const getPermissionMatrixRowsRequest = (isSuperAdmin = false) =>
  apiClient
    .post<UserRolesType[]>(
      isSuperAdmin ? '/settings/getAdminPermissionMatrixRows' : '/settings/getPermissionMatrixRows',
      {}
    )
    .then((r) => r.data);

export const getPermissionMatrixRequest = (moduleType: ModuleType, isSuperAdmin = false) =>
  apiClient
    .post<any>(
      isSuperAdmin ? '/settings/getAdminPermissionMatrix' : '/settings/getPermissionMatrix',
      { moduleType }
    )
    .then((r) => r.data);

export const setPermissionValueRequest = (
  roleId: string,
  moduleId: string,
  value: 'YES' | 'NO' | 'ASSIGN',
  isSuperAdmin = false
) =>
  apiClient.post(
    isSuperAdmin ? '/settings/setAdminPermissionValue' : '/settings/setPermissionValue',
    {
      roleId,
      moduleId,
      value,
    }
  );

export const resetDefaultPermissionsRequest = (roleId: string) =>
  apiClient.post('/settings/resetDefaultPermissions', { roleId });

export const importJobStatusCodesRequest = (accountId?: string) =>
  apiClient.post('/settings/importJobStatusCodes', { accountId });

export const getJobStatusCodesSettingsRequest = (params?: {
  accountId?: string;
  jobStatusCodesCode?: string[];
  includeDisabled?: boolean;
}) => apiClient.post<JobStatusCode[]>('/settings/getJobStatusCodes', params).then((r) => r.data);

export const getJobStatusCodeFormRequest = ({ statusCodeId }: { statusCodeId: string }) =>
  apiClient.post<any>('/settings/getJobStatusCodeForm', { statusCodeId }).then((r) => r.data);

export const updateJobStatusCodeRequest = (data: JobStatusCodeForm) =>
  apiClient.post<any>('/settings/updateJobStatusCode', data).then((r) => r.data);

export const disableJobStatusCodeRequest = ({ statusCodeId }: { statusCodeId: string }) =>
  apiClient.post<any>('/settings/disableJobStatusCode', { statusCodeId }).then((r) => r.data);

export const enableJobStatusCodeRequest = ({ statusCodeId }: { statusCodeId: string }) =>
  apiClient.post<any>('/settings/enableJobStatusCode', { statusCodeId }).then((r) => r.data);

export const getProjectRolesRequest = () =>
  apiClient.post<any>('/settings/getProjectRoles').then((r) => r.data);

export const getFormTemplatesRequest = () =>
  apiClient.post<any>('/settings/getFormTemplates').then((r) => r.data);

export const getReasonCodesRequest = ({ type }: { type: string }) =>
  apiClient.post<any>('/settings/getReasonCodesByType',{type}).then((r) => r.data);

export const getProjectFieldsSettingsRequest = () =>
  apiClient.post<any>('/settings/getProjectFieldsSettings').then((r) => r.data);

export const getProjectFieldFormRequest = ({ fieldId }: { fieldId: string }) =>
  apiClient.post<any>('/settings/getProjectFieldForm', { fieldId }).then((r) => r.data);

export const getContactFieldsSettingsRequest = () =>
  apiClient.post<any>('/settings/getContactFieldsSettings').then((r) => r.data);

export const getContactFieldFormRequest = ({ fieldId }: { fieldId: string }) =>
  apiClient.post<any>('/settings/getContactFieldForm', { fieldId }).then((r) => r.data);

export const getCustomSelectOptionsRequest = ({ listName }: { listName: string }) =>
  apiClient.post<any>(`/settings/getCustomSelectOptions/${listName}`).then((r) => r.data);

export const resetCustomFieldRequest = ({ fieldId }: { fieldId: string }) =>
  apiClient.post<any>('/settings/resetCustomField', { fieldId }).then((r) => r.data);

export const disableCustomFieldRequest = ({ fieldId }: { fieldId: string }) =>
  apiClient.post<any>('/settings/disableCustomField', { fieldId }).then((r) => r.data);

export const enableCustomFieldRequest = ({ fieldId }: { fieldId: string }) =>
  apiClient.post<any>('/settings/enableCustomField', { fieldId }).then((r) => r.data);

export const createCustomFieldRequest = (fieldData: any) =>
  apiClient.post<any>('/settings/createCustomField', fieldData).then((r) => r.data);

export const editCustomFieldRequest = (fieldData: any) =>
  apiClient.post<any>('/settings/editCustomField', fieldData).then((r) => r.data);

export const reorderProjectCustomFieldsRequest = ({ itemId, section, index }) =>
  apiClient.post<any>('/settings/reorderProjectCustomFields', { itemId, section, index });

export const reorderContactCustomFieldsRequest = ({ itemId, index }) =>
  apiClient.post<any>('/settings/reorderContactCustomFields', { itemId, index });

export const getReportSettingsRequest = () =>
  apiClient.post<any>('/settings/getReportSettings').then((r) => r.data);

export const getEnabledReportsRequest = () =>
  apiClient.post<any>('/settings/getEnabledReports').then((r) => r.data);

export const addReportRequest = (report) => apiClient.post<any>(`/settings/addReport`, report);

export const updateReportRequest = (report) =>
  apiClient.post<any>(`/settings/updateReport`, report);

export const resetReportRequest = (report) =>
  apiClient.post<any>(`/settings/resetReport`, report);

export const getReportFormRequest = (reportId) =>
  apiClient.post<any>('/settings/getReportForm', { reportId }).then((r) => r.data);

export const getReportTableColumnsRequest = (reportId, isWidget) =>
  apiClient.post<any>('/settings/getReportTableColumns', { reportId, isWidget }).then((r) => r.data);

export const getReportDisplayingDataRequest = (reportId, isWidget) =>
  apiClient.post<any>('/settings/getReportDisplayingData', { reportId, isWidget }).then((r) => r.data);

export const getReportFieldsOptionsRequest = (reportType) =>
  apiClient.post<any>('/settings/getReportFieldsOptions',{reportType}).then((r) => r.data);

export const reorderReportSettingsRequest = ({ itemId, section, index }) =>
  apiClient.post<any>('/settings/reorderReportSettings', { itemId, section, index });

export const disableReportRequest = ({ reportId }) =>
  apiClient.post<any>('/settings/disableReport', { reportId });

export const enableReportRequest = ({ reportId }) =>
  apiClient.post<any>('/settings/enableReport', { reportId });
