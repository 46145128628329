import React, { Suspense } from 'react';

import { Link } from 'react-router-dom';
import Header from '../../Layout/Header.js';
import Footer from '../../Layout/Footer.js';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { appConstants } from '../../../_constants';
import {
  GetWarrantyPeriodReportAction,
  GetWarrantyPeriodReportPrefAction,
  SetWarrantyPeriodReportPrefAction,
} from '../../../actions/reports';
import ReactLoading from 'react-loading';
import NotePopUp from '../../Common/NotePopUp';
import { formatNote } from '../../../utils/note-formatters';
import { CustomTable, multiSelectFilter, customSortByKey } from '../../Common/CustomTable';
import { Tooltip } from 'react-tooltip';
import { SelectColumnFilter } from '../../Common/CustomTableComponents/SelectColumnFilter';
import { DateColumnFilter } from '../../Common/CustomTableComponents/DateColumnFilter';
import TitleComponent from '../../Common/TitleComponent';
import { matchDataForTable } from '../../../utils/match-for-table.jsx';
import { getAllProjectRoleAction } from '../../../actions/projectRole';
import {
  formatExternalContactData,
  formatInternalContactData,
} from '../../../utils/contact-formatters';
import {
  formatDate,
} from '../../../utils/date-formatters';
import { getAllProjectFieldsAction } from '../../../actions/projectFields';
import { isAllowed, moduleConstants } from '../../../_constants';
import { buildDynamicColumns, onCurrency, onDate } from '../../../utils/column-formatters';

var sortingOrder = '';

class WarrantyStatusReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {},
      accountId: localStorage.getItem('accountId'),
      userId: localStorage.getItem('userId'),
      list: [],
      fetchRequest: true,
      comLogo: '',
      totalCount: 0,
      currentPage: 1,
      sizePerPage: appConstants.TableSizePerPageList[2].value,
      searchText: '',
      noteModal: false,
      noteData: '',
      sortType: 'DESC',
      sortKey: '',
      contactData: {},
      projectRoleTypes: [],
      allProjectFieldTypes: [],
      dynamicColumns: [],
    };

    this.getModalOpen = this.getModalOpen.bind(this);
    this.onNoteModalClose = this.onNoteModalClose.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps?.projectRoles?.allProjectRoleTypes) {
      this.setState({
        projectRoleTypes: nextProps.projectRoles.allProjectRoleTypes,
      });
    }

    if (nextProps?.projectFields?.allProjectFieldTypes?.length > 0) {
      this.setState({
        allProjectFieldTypes: nextProps.projectFields.allProjectFieldTypes,
      });
    }

    if (nextProps.listData && nextProps.listData.success && nextProps.listData.success === 1) {
      let fieldsToMatch = {
        projectAssignments: { lookupLocation: 'contactData' },
      };

      let matchedDataForTable = matchDataForTable(
        fieldsToMatch,
        nextProps.listData.data,
        nextProps.listData.contactData,
        this.props
      );

      this.setState({
        totalCount: nextProps.listData.count,
        list: matchedDataForTable,
        fetchRequest: false,
        contactData: nextProps.listData.contactData,
      });
    }

    if (nextProps.listData && nextProps.listData.data && nextProps.listData.data.length == 0) {
      let _this = this;
      setTimeout(function () {
        _this.setState({ fetchRequest: false });
      }, 2000);
    }
  }

  componentDidMount() {
    this.setState({ sizePerPage: appConstants.TableSizePerPageList[2].value });
    let data = {};
    data['accountId'] = this.state.accountId;
    data['moduleName'] = appConstants.reportModuleName['25'];
    data['page'] = 0;
    data['displayLimit'] = this.state.sizePerPage;
    data['sortKey'] = this.state.sortKey;
    data['sortType'] = this.state.sortType;
    data['search'] = this.state.searchText;
    this.props.GetWarrantyPeriodReportAction(data);

    if (
      localStorage.getItem('companyLogo') !== 'undefined' &&
      localStorage.getItem('companyLogo') !== null
    ) {
      this.setState({
        comLogo: localStorage.getItem('companyLogo'),
      });
    } else {
      let constImage = appConstants.CONTENT_CPOST_LOGO_URL;
      this.setState({ comLogo: constImage });
    }
    this.props.GetWarrantyPeriodReportPrefAction();

    this.props.getAllProjectRoleAction({
      className: 'projectRoleTypes',
      accountId: this.state.accountId,
    });

    this.props.getAllProjectFieldsAction({
      accountId: this.state.accountId,
      minimumStage: 'post-construction',
    });
  }

  buildColumnForRole(filteredRoles, roleCode, hidden = false) {
    const foundRole = filteredRoles.find((role) => role.code == roleCode);
    if (!foundRole) return;
    const isInternal = foundRole && foundRole.type === 'internal';

    if (foundRole && isInternal) {
      return (
        foundRole && {
          Header: foundRole?.shortName ?? roleCode,
          LongHeader: foundRole?.name,
          accessor: 'projectAssignmentsLabel' + roleCode,
          sortType: customSortByKey,
          sortKey: foundRole?.name ?? roleCode,
          Filter: SelectColumnFilter,
          filter: multiSelectFilter,
          width: 60,
          hidden: hidden,
          Cell: (props) => (
            <>
              {this.formatContactDataInternal(
                'projectAssignmentsMatched' + roleCode,
                props.row.original
              )}
            </>
          ),
        }
      );
    } else {
      return (
        foundRole && {
          Header: foundRole?.shortName ?? roleCode,
          LongHeader: foundRole?.name,
          accessor: 'projectAssignmentsLabel' + roleCode,
          sortType: customSortByKey,
          sortKey: foundRole?.name ?? roleCode,
          Filter: SelectColumnFilter,
          filter: multiSelectFilter,
          width: 60,
          hidden: hidden,
          Cell: (props) => (
            <>
              {this.formatContactDataExternal(
                'projectAssignmentsMatched' + roleCode,
                props.row.original
              )}
            </>
          ),
        }
      );
    }
  }

  formatContactDataInternal(cell, row) {
    if (row[cell] && row[cell].length > 0) {
      return formatInternalContactData(row[cell], this.state.contactData);
    } else {
      return <span>-</span>;
    }
  }

  formatContactDataExternal(cell, row) {
    if (row[cell] && row[cell].length > 0) {
      return formatExternalContactData(row[cell], this.state.contactData, false);
    } else {
      return <span>-</span>;
    }
  }

  onJobStatus(cell, row, enumObject, rowIndex) {
    let data = row && row.jobStatusCodesCode ? row.jobStatusCodesCode : '-';
    return data;
  }

  redirectToProject(cell, enumObject, row, rowIndex) {
    if (cell === 'jobNumFormatted' && row) {
      return (
        <div>
          <Link
            to={{
              pathname: `/project-details/` + row.objectId,
              state: { row },
            }}
          >
            {row.jobNumFormatted ? row.jobNumFormatted : '-'}
          </Link>
        </div>
      );
    }

    if (cell === 'jobName' && row) {
      return (
        <div>
          <strong>
            <Link
              to={{
                pathname: `/project-details/` + row.objectId,
                state: { row },
              }}
            >
              {row.jobName ? row.jobName : '-'}
            </Link>
          </strong>
        </div>
      );
    }

    if (cell === 'altJobNum' && row) {
      return (
        <div>
          <strong>
            <Link
              to={{
                pathname: `/project-details/` + row.objectId,
                state: { row },
              }}
            >
              {row.altJobNum ? row.altJobNum : '-'}
            </Link>
          </strong>
        </div>
      );
    }
  }



  onNoteDetail(cell, row, enumObject, rowIndex) {
    const notes = formatNote(row?.jobNotes);
    const maxLength = 400;
    const truncatedMessage =
    notes.length > maxLength ? `${notes.slice(0, maxLength)}...` : notes;

    return notes ? (
      <a
        className='anchorNote '
        data-type={'reminder'}
        onClick={(e) => {
          this.getModalOpen(e, row);
        }}
      >
        <div dangerouslySetInnerHTML={{ __html: truncatedMessage }} />
      </a>
    ) : (
      '-'
    );
  }

  onNoteModalClose() {
    this.setState({ noteModal: false });
  }

  getModalOpen(e, row) {
    let noteText = row && row.jobNotes ? row.jobNotes : '-';
    let lastNoteId = row && row.lastNoteId ? row.lastNoteId : null;
    this.setState({noteModal: true, noteData: { objectId: lastNoteId, description: noteText, typeId: row.objectId, typeRef: 'PROJECT' } });
  }

  onMonth(cell, row, enumObject, rowIndex) {
    return (
      <div>
        {row && row.warrPeriod ? row.warrPeriod : row.warrantyPeriod ? row.warrantyPeriod : '-'}
      </div>
    );
  }

  createCustomButtonGroup = (props) => {
    return (
      <div className='pdfLogoBox'>
        <div className='reportHead'>
          <div className='logo'>
            <img src={this.state.comLogo} alt='Upload Logo' />
          </div>
          <h2>Warranty Status Report</h2>
        </div>
      </div>
    );
  };

  _setTableOption() {
    if (this.state.fetchRequest) {
      return (
        <ReactLoading
          className='table-loader'
          type={appConstants.LOADER_TYPE}
          color={appConstants.LOADER_COLOR}
          height={appConstants.LOADER_HEIGHT}
          width={appConstants.LOADER_WIDTH}
        />
      );
    } else {
      return 'No data found..';
    }
  }

  onPageChange(page, sizePerPage) {
    this.setState({ fetchRequest: true });
    let data = {};
    data['accountId'] = this.state.accountId;
    data['moduleName'] = appConstants.reportModuleName['25'];
    // if (this.state.searchText === "") {
    //     data['page'] = (page === -1) ? 0 : page - 1;
    //     data['displayLimit'] = sizePerPage;
    // } else {
    data['page'] = page === -1 ? 0 : page - 1;
    data['displayLimit'] = sizePerPage;
    data['search'] = this.state.searchText;
    //}
    data['sortKey'] = this.state.sortKey;
    data['sortType'] = this.state.sortType;
    this.setState({ sizePerPage: sizePerPage });
    this.setState({ currentPage: page });
    this.props.GetWarrantyPeriodReportAction(data);
  }

  onSearchChange(text, sizePerPage) {
    this.setState({ fetchRequest: true, list: [] });
    text = text ? text.trim() : '';
    this.setState({ searchText: text });
    if (text !== '') {
      let data = {};
      data['accountId'] = this.state.accountId;
      data['moduleName'] = appConstants.reportModuleName['25'];
      data['page'] = 0;
      data['search'] = text;
      data['displayLimit'] = this.state.sizePerPage;
      data['sortKey'] = this.state.sortKey;
      data['sortType'] = this.state.sortType;
      this.setState({ sizePerPage: 50 });
      this.props.GetWarrantyPeriodReportAction(data);
    } else {
      this.componentDidMount();
    }
  }

  getWarrantyReport() {
    let data = {};
    let { searchText } = this.state;
    data['search'] = searchText;
    data['accountId'] = this.state.accountId;
    data['moduleName'] = appConstants.reportModuleName['25'];
    data['page'] = this.state.currentPage - 1;
    data['displayLimit'] = this.state.sizePerPage;
    data['sortKey'] = this.state.sortKey ? this.state.sortKey : '';
    data['sortType'] = this.state.sortType ? this.state.sortType : '';
    this.props.GetWarrantyPeriodReportAction(data);
  }

  buildReportColumns() {
    const hasContractValPermissions = isAllowed(moduleConstants.VIEWCONTRACTVAL, 'anyAssigned');
    const hasProfitValPermissions = isAllowed(moduleConstants.VIEWPROFIT, 'anyAssigned');

    const {
      allProjectFieldTypes
    } = this.state;

    const dynamicFieldsLoaded = allProjectFieldTypes?.length > 0;

    const defaultFieldCodes = [];

    const customFieldColumns = dynamicFieldsLoaded ? buildDynamicColumns(allProjectFieldTypes.filter(field => !defaultFieldCodes.includes(field.code))) : [];

    const defaultFields = dynamicFieldsLoaded ? allProjectFieldTypes.filter(field => defaultFieldCodes.includes(field.code)) : [];


    const isLeadType = false;

    const rolesLoaded = this.state.projectRoleTypes?.length > 0;

    const defaultRolesCodes = ['EST', 'CL', 'PM'];

    const dynamicColumnsLoaded = dynamicFieldsLoaded && rolesLoaded;

    let dynamicColumns = [];
    if (dynamicColumnsLoaded) {
      const enabledRoles = this.state.projectRoleTypes.filter(
        (role) => !defaultRolesCodes.includes(role.code) && (!isLeadType || role.isShowOnLeads)
      );
      const defaultRoles = this.state.projectRoleTypes.filter(
        (role) => defaultRolesCodes.includes(role.code) && (!isLeadType || role.isShowOnLeads)
      );

      const defaultColumns = [
        {
          Header: 'Job No.',
          accessor: 'jobNumFormatted',
          sortType: 'basic',
          width: 80,
          Cell: (props) => (
            <>
              <span data-tooltip-content={props.value} data-tooltip-id={props.value}>
                {this.redirectToProject(props.column.id, props.value, props.row.original)}
              </span>
              <Tooltip id={props.value} delayShow={1000} place='bottom' />
            </>
          ),
        },
        {
          Header: 'Alt Job#',
          accessor: 'altJobNum',
          width: 60,
          Cell: (props) => this.redirectToProject(props.column.id, props.value, props.row.original),
        },
        {
          Header: 'Job Name',
          accessor: 'jobName',
          width: 200,
          Cell: (props) => this.redirectToProject(props.column.id, props.value, props.row.original),
        },
        {
          Header: 'Delivery Date',
          accessor: (row) =>
            row?.deliveredDate?.iso
              ? new Date(row.deliveredDate.iso)
              : new Date(formatDate('1800-03-03T12:47:40.000Z')),
          id: 'deliveredDate',
          width: 130,
          Cell: (props) => <>{onDate(props.column.id, props.row.original, props.value)}</>,
          filter: DateColumnFilter,
          allowDateRangeFilter: true,
          sortType: 'datetime',
        },
        hasContractValPermissions && {
          Header: 'Contract Amt',
          accessor: 'currentContractAmount',
          width: 110,
          Cell: (props) => (
            <>{onCurrency(props.column.id, props.row.original, props.value)}</>
          ),
        },
        this.buildColumnForRole(defaultRoles, 'EST'),
        this.buildColumnForRole(defaultRoles, 'PM'),
        {
          Header: 'Status',
          accessor: 'jobStatusCodesName',
          width: 60,
          Cell: (props) => <>{this.onJobStatus(props.column.id, props.row.original, props.value)}</>,
          Filter: SelectColumnFilter,
          filter: 'exact',
        },
        {
          Header: 'Warranty Period',
          accessor: 'warrPeriod',
          width: 100,
          Cell: (props) => <>{this.onMonth(props.column.id, props.row.original, props.value)}</>,
        },
        {
          Header: 'Warranty Exp.',
          accessor: (row) =>
            row?.warrExpiration?.iso
              ? new Date(row.warrExpiration.iso)
              : new Date(formatDate('1800-03-03T12:47:40.000Z')),
          id: 'warrExpiration',
          width: 100,
          Cell: (props) => <>{onDate(props.column.id, props.row.original, props.value)}</>,
          filter: DateColumnFilter,
          allowDateRangeFilter: true,
          sortType: 'datetime',
        },
        {
          Header: 'Lien Exp.',
          accessor: (row) =>
            row?.lienExpiration?.iso
              ? new Date(row.lienExpiration.iso)
              : new Date(formatDate('1800-03-03T12:47:40.000Z')),
          id: 'lienExpiration',
          width: 100,
          Cell: (props) => <>{onDate(props.column.id, props.row.original, props.value)}</>,
          filter: DateColumnFilter,
          allowDateRangeFilter: true,
          sortType: 'datetime',
          hidden: true,
        },
        {
          Header: 'Follow Up',
          accessor: (row) =>
            row?.followUpDate?.iso
              ? new Date(row.followUpDate.iso)
              : new Date(formatDate('1800-03-03T12:47:40.000Z')),
          id: 'followUpDate',
          width: 100,
          Cell: (props) => <>{onDate(props.column.id, props.row.original, props.value)}</>,
          filter: DateColumnFilter,
          allowDateRangeFilter: true,
          sortType: 'datetime',
          hidden: true,
        },
        {
          Header: 'Start Date',
          accessor: (row) =>
            row?.startDate?.iso
              ? new Date(row.startDate.iso)
              : new Date(formatDate('1800-03-03T12:47:40.000Z')),
          id: 'startDate',
          width: 100,
          Cell: (props) => <>{onDate(props.column.id, props.row.original, props.value)}</>,
          filter: DateColumnFilter,
          allowDateRangeFilter: true,
          sortType: 'datetime',
          hidden: true,
        },
        {
          Header: 'End Date',
          accessor: (row) =>
            row?.endDate?.iso
              ? new Date(row.endDate.iso)
              : new Date(formatDate('1800-03-03T12:47:40.000Z')),
          id: 'endDate',
          width: 100,
          Cell: (props) => <>{onDate(props.column.id, props.row.original, props.value)}</>,
          filter: DateColumnFilter,
          allowDateRangeFilter: true,
          sortType: 'datetime',
          hidden: true,
        },
        {
          Header: 'Notes',
          accessor: 'jobNotes',
          hidden: true,
          width: 120,
          Cell: (props) => (
            <>
              <span
                data-tooltip-html={props.value}
                data-tooltip-id={props.column.id + ':' + props.row.id}
              >
                {this.onNoteDetail(props.column.id, props.row.original, props.value)}
              </span>
              <Tooltip
                id={props.column.id + ':' + props.row.id}
                delayShow={1000}
                style={{ color: '#fff !important' }}
                place='bottom'
              />
            </>
          ),
        },
      ];

      let columns = [];
      if (enabledRoles) {
        const extraColumns = enabledRoles.map((role) =>
          this.buildColumnForRole([role], role.code, true)
        );

        if (columns.length == 0) {
          columns = defaultColumns.filter((cols) => cols?.accessor);
        }

        for (let i in extraColumns) {
          const curColumnsAccessors = columns.map((cols) => cols.accessor);
          if (!curColumnsAccessors.includes(extraColumns[i].accessor)) {
            columns = columns.concat(extraColumns[i]);
          }
        }

        for (let i in customFieldColumns) {
          const curColumnsAccessors = columns.map((cols) => cols.accessor);
          if (!curColumnsAccessors.includes(customFieldColumns[i].accessor)) {
            columns = columns.concat(customFieldColumns[i]);
          }
        }
      } else {
        columns = defaultColumns.filter((cols) => cols?.accessor);
      }

      this.setState({ dynamicColumns: columns });
    }
  }

  render() {
   
    const {
      list, allProjectFieldTypes, dynamicColumns
    } = this.state;
    const { listPref, SetWarrantyPeriodReportPrefAction } = this.props;

    const dynamicFieldsLoaded = allProjectFieldTypes?.length > 0;

    const rolesLoaded = this.state.projectRoleTypes?.length > 0;

    const dynamicColumnsLoaded = dynamicFieldsLoaded && rolesLoaded;

    
    const dynamicColumnsBuilt = dynamicColumnsLoaded && dynamicColumns.length > 0;

    if(dynamicColumnsLoaded && !dynamicColumnsBuilt){
      this.buildReportColumns();
    }


    return (
      <div>
        <div className='ContactListReport'>
          <TitleComponent data={{ title: `Warranty Status Report` }} />
          <div>
            <Suspense fallback='loading'>
              <Header props={this.props} />
            </Suspense>
          </div>
          <div className='contactBox'>
            <div className='container'>
              <div className='row no-break-print'>
                {dynamicColumnsBuilt && (
                  <div className='col-md-12'>
                    <div
                      className='table-responsive reportTableSection'
                      ref={(el) => (this.componentRef = el)}
                    >
                      {this.createCustomButtonGroup()}
                      <CustomTable
                        columns={dynamicColumns}
                        list={list}
                        preferences={listPref}
                        className='bid-due-table'
                        customTableTitle='Warranty Status Report'
                        onChangeRowSelection={this.onChangeRowSelection}
                        onSavePreferences={SetWarrantyPeriodReportPrefAction}
                        isLoading={this.state.fetchRequest}
                        canShowRowSelection={false}
                        componentRef={this.componentRef}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <NotePopUp
            isOpen={this.state.noteModal}
            onClick={this.onNoteModalClose}
            noteInfo={this.state.noteData}
          />
          <Footer />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    listData: state.reports.warrantyStatusList,
    listPref: state.reports.warrantyStatusListPref,
    projectRoles: state.projectRoleType,
    projectFields: state.projectFields,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    GetWarrantyPeriodReportAction,
    GetWarrantyPeriodReportPrefAction,
    SetWarrantyPeriodReportPrefAction,
    getAllProjectRoleAction,
    getAllProjectFieldsAction
  })(WarrantyStatusReport)
);
