import React from 'react';
import { useRef } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { uploadCsvContacts } from '../../modules/contacts';
import { getImportingContacts } from '../../modules/contacts/selectors';
import { FileDropZone } from '../../shared/file-upload';

const UploadCsvContactsModal = ({ open = false, onClose = () => {} }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const fileRef = useRef(null);

    const { loading, error } = useSelector(getImportingContacts);

    const submit = async () => {
        const formData = new FormData();
        formData.append('contactsCsv', fileRef.current.files[0]);

        await dispatch(uploadCsvContacts(formData)).unwrap();

        history.push({
            pathname: '/import-contacts-view',
            state: { contactsCsv: fileRef.current.files[0] },
        });
    };

    const onDrop = (files) => {
        fileRef.current.files = files;
    };

    return (
        <Modal backdrop='static' keyboard={false} isOpen={open}>
            <ModalHeader>Import Contacts</ModalHeader>
            <FileDropZone onFileDrop={onDrop}>
                <ModalBody>
                    <div className={'alert alert-danger ' + (!error ? 'd-none' : '')}>
                        <strong>Error While uploading! </strong> Contact List.
                    </div>
                    <input
                        className='csv-input'
                        type='file'
                        name='files'
                        id='files'
                        accept='text/csv'
                        ref={fileRef}
                    />
                </ModalBody>
            </FileDropZone>
            <ModalFooter>
                <Button className='btn btn-primary' onClick={onClose}>
                    Cancel
                </Button>
                <Button disabled={loading} className='btn btn-primary' onClick={submit}>
                    {loading ? 'Loading...' : 'Upload'}
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default UploadCsvContactsModal;
