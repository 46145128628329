
import React, { Suspense } from 'react';
import { Nav, Row, Tab } from 'react-bootstrap';
import { connect } from 'react-redux';
import renderHTML from 'html-react-parser';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { getAssignedContactListAction } from '../../actions/contact';
import { getAllJobStatusAction } from '../../actions/jobStatusCode';
import { getAllProjectAction, updatedEventAction } from '../../actions/project';
import { AddLeadModal } from '../../modals/add-lead-modal';
import { AddProjectModal } from '../../modals/add-project-modal';
import {
  formatContactDataHTMLDropdown,
  formatUserDataHTMLDropdown,
} from '../../utils/contact-formatters';
import { isAllowed, moduleConstants } from '../../_constants';
import {useAuth} from '../Auth/Auth';
import ContactRedirect from '../Common/contactRedirect.component';
import { InputFieldSubmit } from '../Common/InputFieldSubmit';
import TitleComponent from '../Common/TitleComponent';
import Footer from '../Layout/Footer';
import Header from '../Layout/Header';
import LeadLists from './projectComponent/LeadLists';
import ProjectList from './projectComponent/ProjectList';


const contactRedirect = new ContactRedirect();

var optionAssignedContactList = [];
var optionAssignedAllUserList = [];
let buttonActive = false;

class ProjectLeadList extends React.Component {
  constructor(props) {
    super(props);
    const isProjectsOpen = props.match.params.viewMode === 'projects';

    this.state = {
      userId: localStorage.getItem('userId'),
      accountId: localStorage.getItem('accountId'),
      comName: localStorage.getItem('companyName'),
      row: [],
      key: isProjectsOpen ? 'Projects' : 'Leads',
      openPopUpState: '',
      list: [],
      leadList: [],
      searchSelectBox: {
        jobStatusCodes: { value: 'All', label: 'All' },
        accountExec: { value: 'All', label: 'All' },
        client: { value: 'All', label: 'All' },
      },
      isAddLeadModalOpen: false,
      leadModel: false,
      filterStatus: false,
      isAddProjectModalOpen: false,
      projectListStatus: isProjectsOpen,
      leadListStatus: !isProjectsOpen,
      submitLead: false,
      createSuccess: true,

      optionAssignedContactList: [],
      optionAssignedAllUserList: [],
      userInfo: JSON.parse(localStorage.getItem('userInfo')),
    };
    // this.getProjectList = this.getProjectList.bind(this);
    // this.addLeadPage = this.addLeadPage.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleAddLeadModalToggle = this.handleAddLeadModalToggle.bind(this);
    this.importProject = this.importProject.bind(this);
    //this.onPageChange = this.onPageChange.bind(this);
    //this.onSizePerPageList = this.onSizePerPageList.bind(this);
  }

  componentDidMount() {
    this.props.getAllJobStatusAction({
      className: 'jobStatusCodes',
      accountId: this.state.accountId,
    });
    this.props.getAssignedContactListAction({
      accountId: this.state.accountId,
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { location } = this.props;
    if (nextProps.buttonActive || nextProps.buttonActive === false) {
      buttonActive = nextProps.buttonActive;
      this.setState({
        buttonActive: nextProps.buttonActive,
      });
    }

    let list = [];
    if (nextProps.allProjects && nextProps.allProjects.length >= 0) {
      list = nextProps.allProjects.filter((project) => {
        return project;
      });
      this.setState({
        totalCount: nextProps.allProjectsCount,
        fetchRequest: nextProps.fetchRequest,
      });
    }
    if (nextProps.createProjReq) {
      this.setState({
        createSuccess: nextProps.createProjReq,
      });
    }

    if (nextProps.allProjectsCount === 0) {
      this.setState({ fetchRequest: true });
    }

    if (this.state.createSuccess && location && location.state === false) {
      if (nextProps.createSuccess) {
        this.setState({
          isAddLeadModalOpen: false,
          isAddProjectModalOpen: false,
          createSuccess: false,
        });
      }
    }
    if (this.state.createSuccess && location && location.state === true) {
      if (nextProps.createSuccess) {
        this.setState({
          isAddLeadModalOpen: this.state.isAddLeadModalOpen,
          isAddProjectModalOpen: this.state.isAddProjectModalOpen,
          createSuccess: false,
          //key: (1-1*this.state.leadModel),
        });
      }
    }

    this.setState({ list: list });
    this.setState({ allJobStatusCodes: nextProps.allJobStatusCodes });
    this.setState({ allOccupancyType: nextProps.allOccupancyType });
    this.setState({ allContactRole: nextProps.allContactRole });

    // *** Assigned contact list
    if (typeof nextProps.assignedContact === 'object' && nextProps.assignedContact.data) {
      optionAssignedAllUserList = [];
      optionAssignedContactList = [];
      if (
        nextProps.assignedContact.data &&
        nextProps.assignedContact.data.employees &&
        nextProps.assignedContact.data.employees.length > 0
      ) {
        nextProps.assignedContact.data.employees.map((v) => {
          if (v && v.objectId) {
            let cData = formatUserDataHTMLDropdown(this.state.comName, v);
            optionAssignedAllUserList.push({
              value: v.objectId,
              label: renderHTML(cData),
            });
          }
          return true;
        });
      }

      if (
        nextProps.assignedContact.data &&
        nextProps.assignedContact.data.externalContacts &&
        nextProps.assignedContact.data.externalContacts.length > 0
      ) {
        nextProps.assignedContact.data.externalContacts.map((v) => {
          if (v && v.objectId) {
            let cData = formatContactDataHTMLDropdown(v);
            optionAssignedContactList.push({
              value: v.objectId,
              label: renderHTML(cData),
            });
            return true;
          }
          return true;
        });
      }
      this.setState({ optionAssignedAllUserList, optionAssignedContactList });
    }
  }

  /*    addLeadPage(val) {

        this.setState({ leadModel:true});
    }
*/

  shouldComponentUpdate(prevProps, nextProps) {
    //let showLeads=(prevProps.location.state)&&(prevProps.location.state.type)&&(prevProps.location.state.type==='leads');
    let { viewMode } = prevProps.match.params;

    let showLeads =
      prevProps.location.pathname === '/lead-list' ||
      prevProps.location.pathname === '/projects-list/leads';
    if (viewMode) {
      showLeads = viewMode === 'leads';
    }

    const isLeads = this.state.leadListStatus;

    if (prevProps.location.state && prevProps.location.state.rand !== this.state.openPopUpState) {
      if (
        prevProps.location.state.openPopUp === 'LEAD' &&
        (prevProps.history.action === 'PUSH' || prevProps.history.action === 'REPLACE')
      ) {
        showLeads = true;
        this.handleSelect('Leads');
        this.setState({
          isAddLeadModalOpen: true,
          leadListStatus: true,
          projectListStatus: false,
          isAddProjectModalOpen: false,
        });

        //this.handleAddLeadModel();
      } else if (
        prevProps.location.state.openPopUp === 'PROJECT' &&
        (prevProps.history.action === 'PUSH' || prevProps.history.action === 'REPLACE')
      ) {
        this.setState({
          isAddLeadModalOpen: false,
          leadListStatus: false,
          projectListStatus: true,
          isAddProjectModalOpen: true,
        });
      }
      this.setState({ openPopUpState: prevProps.location.state.rand });
    } else if (showLeads && isLeads === false) {
      this.handleSelect(0);
    } else if (!showLeads && (isLeads || isLeads === undefined)) {
      this.handleSelect(1);
    }
    return true;
  }

  handleChangeSearchState(name, value) {
    let searchSelectBox = this.state.searchSelectBox;
    if (value == null) {
      value = { label: 'All', value: 'All' };
    }
    searchSelectBox[name] = value;
    this.setState({
      searchSelectBox: searchSelectBox,
    });

    if (
      this.state.searchSelectBox.jobStatusCodes.label === 'Lead' ||
      this.state.searchSelectBox.jobStatusCodes.label === 'Not Bid (closed)'
    )
      this.handleSelect(0);
    else if (this.state.searchSelectBox.jobStatusCodes.label !== 'All') this.handleSelect(1);
  }

  handleAddLeadModalToggle = () => {
    this.setState({
      key: 'Leads',
      isAddLeadModalOpen: !this.state.isAddLeadModalOpen,
      projectListStatus: false,
    });
  };

  handleAddProjectModalToggle = () => {
    this.setState((prevState) => ({
      isAddProjectModalOpen: !prevState.isAddProjectModalOpen,
      leadListStatus: false,
      key: 'Projects',
    }));
  };

  handleAddProjectModalSubmit = ({ _id }) => {
    this.props.history.push(`/project-details/${_id}`);
  };

  handleAddProjectAndAddNextModalSubmit = () => {
    this.handleSelect('Projects', 'next');
  };

  handleAddLeadModalSubmit = ({ _id }) => {
    this.props.history.push(`/lead-details/${_id}`);
  };

  handleAddLeadAndAddNextModalSubmit = () => {
    this.handleSelect('Leads', 'next');
  };

  importProject() {
    this.props.history.push({
      pathname: '/import-projects',
      state: { redirectOn: '/projects-list' },
    });
  }

  handleSelect(key, ins) {
    // console.log("key", key === 1);

    if (this.state.key !== key && key === 'Leads') {
      this.setState({
        key: key,
        leadListStatus: true,
        projectListStatus: false,
      });

      if (ins) {
        this.handleChangeSearchState('jobStatusCodes', { value: 'All', label: 'All' });
      }

      this.props.history.push({
        pathname: '/projects-list/leads',
        state: {
          type: 'leads',
        },
      });
    } else if (this.state.key !== key && key === 'Projects') {
      this.setState({
        key: key,
        projectListStatus: true,
        leadListStatus: false,
      });

      if (ins) {
        this.handleChangeSearchState('jobStatusCodes', { value: 'All', label: 'All' });
      }

      this.props.history.push({
        pathname: '/projects-list/projects',
        state: {
          type: 'projects',
        },
      });
    }

    if (ins === 'next') {
      this.setState({
        filterStatus: !this.state.filterStatus,
      });

      this.props.updatedEventAction(this.state.searchSelectBox, this.state.filterStatus);
    }
  }

  render() {
    let searchOptionTemplateJobStatusCode = [];
    const { allJobStatusCodes } = this.state;
    if (allJobStatusCodes) {
      searchOptionTemplateJobStatusCode.push({ value: 'All', label: 'All' });
      allJobStatusCodes.map((v) => {
        if (v.objectId !== '' && v.jobStatusCodesName !== '') {
          searchOptionTemplateJobStatusCode.push({
            value: v.objectId,
            label: v.jobStatusCodesName,
          });
        }
        return true;
      });
    }

    let isLeads = this.state.leadListStatus;

    const addProjectBtn = [
      {
        title: 'Add Project',
        loading: 'Loading...',
        loadingActive: buttonActive,
        onClickFun: this.handleAddProjectModalToggle,
        'data-type': 'SAVE',
        className: 'btn addContactBtn',
      },
    ];
    const addLeadBtn = [
      {
        title: 'Add Lead',
        loading: 'Loading...',
        loadingActive: buttonActive,
        onClickFun: this.handleAddLeadModalToggle,
        'data-type': 'SAVE',
        className: 'btn addButton',
      },
    ];

    return (
      <div className='ContactListReport'>
        {!this.state.leadListStatus && <TitleComponent data={{ title: 'All Projects' }} />}
        {this.state.leadListStatus && <TitleComponent data={{ title: 'All Leads' }} />}

        <Suspense fallback='loading'>
          <Header props={this.props} />
        </Suspense>
        <div className='contactBox'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-12'>
                {!this.state.leadListStatus && <h1 className='heading1'>All Projects List</h1>}
                {this.state.leadListStatus && <h1 className='heading1'>All Leads List</h1>}
              </div>
            </div>
            <div className='row searchbar no-print'>
              <div className='col-md-3'>
                <label htmlFor='jobStatusCodes'>Status</label>
                <Select
                  name='jobStatusCodes'
                  components={makeAnimated()}
                  isDisabled={searchOptionTemplateJobStatusCode.length==0}
                  value={this.state.searchSelectBox.jobStatusCodes}
                  onChange={this.handleChangeSearchState.bind(this, 'jobStatusCodes')}
                  options={searchOptionTemplateJobStatusCode}
                  isClearable={this.state.searchSelectBox.jobStatusCodes.value !== 'All'}
                />
              </div>
              <div className='col-md-3'>
                <label htmlFor='accountExec'>Employees</label>
                <Select
                  name='accountExec'
                  components={makeAnimated()}
                  isDisabled={this.state.optionAssignedContactList.length==0}
                  value={this.state.searchSelectBox.accountExec}
                  onChange={this.handleChangeSearchState.bind(this, 'accountExec')}
                  options={this.state.optionAssignedAllUserList}
                  isClearable={this.state.searchSelectBox.accountExec.value !== 'All'}
                />
              </div>
              <div className='col-md-3'>
                <label htmlFor='client'>External Contacts</label>
                <Select
                  name='Client'
                  isDisabled={this.state.optionAssignedContactList.length==0}
                  components={makeAnimated()}
                  value={this.state.searchSelectBox.client}
                  onChange={this.handleChangeSearchState.bind(this, 'client')}
                  options={this.state.optionAssignedContactList}
                  isClearable={this.state.searchSelectBox.client.value !== 'All'}
                />
              </div>
              <div className='col-md-3'>
                <div className=''>
                  <label>&nbsp;</label>
                  <br />
                  <input
                    type='button'
                    onClick={(e) => {
                      this.handleSelect(this.state.key, 'next');
                    }}
                    value='Filter'
                    className='btn btn-primary'
                  />
                </div>
              </div>
            </div>
            <div className=''></div>
            <div className='projectListTable'>
              <div className='row'>
                <div className='col-md-12'>
                  <Tab.Container
                    defaultActiveKey='Projects'
                    onSelect={this.handleSelect}
                    activeKey={this.state.key}
                    id='projects-list-tab'
                  >
                    <Row>
                      <Nav variant='tabs'>
                        <Nav.Item>
                          <Nav.Link eventKey='Leads'>Leads</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey='Projects'>Projects</Nav.Link>
                        </Nav.Item>
                        <Nav.Item className='ms-auto'>
                          <div className='p-2'>
                            {isAllowed(moduleConstants.IMPORTPROJECTS) && (
                              <button className='btn' onClick={this.importProject}>
                                Import
                              </button>
                            )}
                          </div>
                        </Nav.Item>
                        <Nav.Item className=''>
                          <div className='p-2'>
                            {isLeads && isAllowed(moduleConstants.EL) && (
                              /*<button className="btn addButton" onClick={() =>this.handleAddLeadModel('POST')}>Add Lead</button>*/
                              <InputFieldSubmit actions={addLeadBtn} />
                            )}
                            {!isLeads && isAllowed(moduleConstants.EP) && (
                              <InputFieldSubmit actions={addProjectBtn} />
                            )}
                          </div>
                        </Nav.Item>
                      </Nav>
                    </Row>
                    <Row>
                      <Tab.Content>
                        <Tab.Pane eventKey='Leads'>
                          <div className='sortable-table'>
                            {/*Leads List Table Component*/}
                            {this.state.leadListStatus && (
                              <LeadLists
                                props={this.props}
                                useAuth={useAuth}
                                contactRedirect={contactRedirect}
                                onClickFun={this.handleSelect}
                                leadListStatus={this.state.leadListStatus}
                              />
                            )}
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey='Projects'>
                          {/*Projects List Table Component*/}

                          {this.state.projectListStatus && (
                            <ProjectList
                              props={this.props}
                              contactRedirect={contactRedirect}
                              onClickFun={this.handleSelect}
                              useAuth={useAuth}
                              projectListStatus={this.state.projectListStatus}
                              searchSelectBox={this.state.searchSelectBox}
                            />
                          )}
                        </Tab.Pane>
                      </Tab.Content>
                    </Row>
                  </Tab.Container>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />

        {/*Add Project Modal Component*/}

        {!this.state.leadListStatus && this.state.isAddProjectModalOpen && (
          <AddProjectModal
            open={this.state.isAddProjectModalOpen}
            onClose={this.handleAddProjectModalToggle}
            onSubmit={this.handleAddProjectModalSubmit}
            onSubmitAndAdd={this.handleAddProjectAndAddNextModalSubmit}
          />
        )}

        {/*Add Lead Modal Component*/}
        {this.state.leadListStatus && this.state.isAddLeadModalOpen && (
          <AddLeadModal
            open={this.state.isAddLeadModalOpen}
            onClose={this.handleAddLeadModalToggle}
            onSubmit={this.handleAddLeadModalSubmit}
            onSubmitAndAdd={this.handleAddLeadAndAddNextModalSubmit}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    buttonActive: state.project.buttonActive,
    isStatus: state.project.isStatus,
    allProjectsCount: state.project.allProjectsCount,
    allJobStatusCodes: state.jobStatusCode.allJobStatus,
    fetchRequest: state.project.fetchRequest,
    createSuccess: state.project.createSuccess,
    createProjReq: state.project.createProjReq,
    message: state.project.message,
    fetchSuccess: state.project.fetchSuccess,
    accountExec: state.contact.response,
    allOccupancyType: state.occupancyType.allOccupancyType,
    lastJobData: state.project.lastJobData,
    allContactRole: state.contactRole.allContactRole,
    assignedContact: state.contact.assignedContact,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    getAllProjectAction,
    updatedEventAction,
    getAllJobStatusAction,
    getAssignedContactListAction,
  })(ProjectLeadList)
);
