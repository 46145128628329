import apiClient from '../utils/api-client';
import { postRequest } from './helper';

export const createContact_deprecated = (data) => postRequest(`functions/createUserContact`, data);
export const resendInvite = (data) => postRequest(`functions/userEmailVerifyResendMail`, data);
export const cancelInvite = (data) => postRequest(`functions/userEmailVerifyCancel`, data);
export const updateContact = (data) => postRequest(`functions/editContact`, data);
export const getAllContacts = (data) => apiClient.post('/parse/functions/viewContacts', data);
export const deleteContact = (data) => postRequest(`functions/deleteContacts`, data);
export const moveContactToEmp = (data) => postRequest(`functions/makeEmployee`, data);
export const getContactDetails = (data) => postRequest(`functions/getContactCompanyDetails`, data);
export const AddContactCompany = (data) => postRequest(`functions/editCompanyContact`, data);
export const AddContactAvatar = (data) => postRequest(`functions/updateUserImage`, data);
export const AssignedContactList = (data) => postRequest(`functions/viewProjectContacts`, data);
