import { useForm, FormProvider } from 'react-hook-form';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import FormInput from '../../../shared/form-controls/form-input/form-input';
import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../shared/hooks/use-app-dispatch';
import { useAppSelector } from '../../../shared/hooks/use-app-selector';
import {
  addUserSettings,
  disableUserSetting,
  enableUserSetting,
  resetUserSetting,
  updateUserSettings,
} from '../../../modules/settings';
import { useSubmit } from '../../../shared/hooks/use-submit';
import FormCheckbox from '../../../shared/form-controls/form-checkbox';
import FormSelect from '../../../shared/form-controls/form-select';
import FormMultiselect from '../../../shared/form-controls/form-multiselect';
import { projectSectionsForRoles, projectStages as projectStageOptions } from '../../../shared/custom-fields-controls/constants';

const projectRoleTypesOptions = [
  { value: 'internal', label: 'Internal' },
  { value: 'external', label: 'External' },
];

export const EditSettingModal = ({
  open = false,
  onClose = () => {},
  onSubmit = () => {},
  settingId,
}) => {
  const modelName = 'projectRoleTypes';

  const isUpdate = !!settingId;
  const dispatch = useAppDispatch();

  const form = useForm({
    defaultValues: {
      name: '',
      code: '',
      definition: '',
      type: '',
      projectSection: '',
      projectStages: ['all'],
      isMultiAssignable: false,
      hideInitially: false,
      isLinkToBids: false,
      isVenderTypeRole: false,
      isClientTypeRole: false,
      isShowOnLeads: false,
      required: false,
    },
  });

  const [roleType] = form.watch(['type']);

  // TODO: should be replaced with dispatch
  const setting = useAppSelector((state) => {
    const settings = state.rtk.settings.getUserSettings.data;

    const setting = settings.find((s) => s._id === settingId);

    return setting;
  });

  useEffect(() => {
    if (!open) return;

    if (!setting || !isUpdate) {
      form.reset({
        name: '',
        code: '',
        definition: '',
        type: '',
        shortName: '',
        projectSection: '',
        projectStages: ['all'],
        isMultiAssignable: false,
        isLinkToBids: false,
        hideInitially: false,
        isVenderTypeRole: false,
        isClientTypeRole: false,
        isShowOnLeads: false,
        required: false,
      }, { keepDefaultValues: true });

      return;
    }

    form.reset({
      name: setting.name,
      code: setting.code,
      definition: setting.definition,
      shortName: setting.shortName,
      type: setting.type,
      projectSection: setting.projectSection,
      projectStages: setting.projectStages,
      isMultiAssignable: setting.isMultiAssignable,
      isLinkToBids: setting.isLinkToBids,
      hideInitially: setting.hideInitially,
      isVenderTypeRole: setting.isVenderTypeRole,
      isClientTypeRole: setting.isClientTypeRole,
      isShowOnLeads: setting.isShowOnLeads,
      required: setting.required,
    }, { keepDefaultValues: true });
  }, [setting, isUpdate, open]);

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = form.handleSubmit(async (data) => {
    if (isUpdate) {
      await dispatch(updateUserSettings([modelName, { ...data, _id: settingId }])).unwrap();
    } else {
      await dispatch(addUserSettings([modelName, { ...data, _id: settingId }])).unwrap();
    }

    onSubmit();
    handleClose();
  });

  const [handleToggle, toggleLoading] = useSubmit(async () => {
    if (setting.trash) {
      await dispatch(enableUserSetting([modelName, settingId])).unwrap();
    } else {
      await dispatch(disableUserSetting([modelName, settingId])).unwrap();
    }
    onSubmit();
  }, [setting, setting]);

  const [handleReset, resetLoading] = useSubmit(async () => {
    await dispatch(resetUserSetting([modelName, settingId])).unwrap();

    onSubmit();
  }, [settingId]);

  const [fieldName, projectStages] = form.watch(['name','projectStages']);
  const autoCodeName = fieldName.toUpperCase().replace(/[^A-Z0-9]/g, '');

  const [projectSectionOptions, setProjectSectionOptions] = useState([]);

  useEffect(() => {
    if(roleType === 'internal') {
      form.setValue('projectSection', 'internal-roles');
      setProjectSectionOptions(projectSectionsForRoles.filter((section) => section.value !== 'external-roles'));
     
    }else if(roleType === 'external'){
      form.setValue('projectSection', 'external-roles');
      setProjectSectionOptions(projectSectionsForRoles.filter((section) => section.value !== 'internal-roles'));
     
    }
  }, [roleType, open]);

  useEffect(() => {

    if(projectStages){
      if(projectStages.includes('all')||projectStages.includes('lead')){
        form.setValue('isShowOnLeads', true);
      }else{
        form.setValue('isShowOnLeads', false);
      }
  
    }
   
  },[projectStages])


  return (
    <Modal isOpen={open} toggle={onClose} size='lg'>
      <ModalHeader toggle={onClose}>{isUpdate ? 'Edit' : 'Add'} Project Role</ModalHeader>
      <ModalBody>
        <FormProvider {...form}>
          <div className='container'>
            <div className='row'>
              <div className='col-6'>
                <FormInput label='Name' name='name' required />
              </div>

              <div className='col-6'>
                <FormInput
                  label='Code'
                  name='code'
                  required
                  disabled={isUpdate}
                  initialValue={autoCodeName}
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-md-6'>
                <FormInput
                  label='Short Name (Report Headings)'
                  required
                  name='shortName'
                  initialValue={fieldName}
                />
              </div>

              <div className='col-md-6'>
              <FormSelect
                required
                disabled={isUpdate}
                label='Role Type'
                options={projectRoleTypesOptions}
                name='type'
              />
              </div>
              <div className='col-md-6'>
                <FormCheckbox
                  name='isMultiAssignable'
                  label='All multiple assignments to this role'
                  className='mb-0 d-flex flex-row-reverse justify-content-end'
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-12'>
                <FormInput label='Definition' name='definition' textArea />
              </div>
            </div>
            
            <label className='form-label mt-2'>Project Options</label>
            <div className='row'>

            <div className='row'>
              <div className='col-6'>
                <FormMultiselect name='projectStages' required label='Show in Stages' options={projectStageOptions} />
              </div>

              <div className='col-6'>
                <FormSelect name='projectSection'  required disabled label='Section' options={projectSectionOptions} />
              </div>
            </div>
            </div>
            <div className='row'>

            <div className='row mb-2'>

            <div className='col-md-6'>
                <FormCheckbox
                  name='hideInitially'
                  label='Always show in "View More"'
                  className='mb-0 d-flex flex-row-reverse justify-content-end'
                />
              </div>

              <div className='col-md-6'>
                <FormCheckbox
                  name='required'
                  label='Require role on projects'
                  className='mb-0 d-flex flex-row-reverse justify-content-end'
                />
              </div>
             
              <div className='col-md-6'>
                <FormCheckbox
                  name='isLinkToBids'
                  label='Show track/role on bids'
                  className='mb-0 d-flex flex-row-reverse justify-content-end'
                />
              </div>
            </div>
            </div>

          
            {roleType === 'external' && (
              <>
              <label className='form-label mt-2'>Synchronization Options</label>
              <div className='row mb-2'>
                <div className='col-md-6'>
                  <FormCheckbox
                    name='isVenderTypeRole'
                    label='Vender-Type Role'
                    className='mb-0 d-flex flex-row-reverse justify-content-end'
                  />
                </div>
                <div className='col-md-6'>
                  <FormCheckbox
                    name='isClientTypeRole'
                    label='Client-Type Role'
                    className='mb-0 d-flex flex-row-reverse justify-content-end'
                  />
                </div>
              </div>
              </>
            )}

          </div>
        </FormProvider>
      </ModalBody>
      <ModalFooter>
        {isUpdate && !!setting.isLocalCopy && (
          <button className='btn btn-primary me-auto' onClick={handleReset} disabled={resetLoading}>
            Reset to default
          </button>
        )}

        <button className='btn btn-primary' onClick={onClose}>
          Cancel
        </button>
        {isUpdate && (
          <button className='btn btn-primary' onClick={handleToggle} disabled={toggleLoading}>
            {setting.trash ? 'Enable' : 'Disable'}
          </button>
        )}
        <button
          className='btn btn-primary'
          onClick={handleSubmit}
          disabled={form.formState.isSubmitting}
        >
          Save
        </button>
      </ModalFooter>
    </Modal>
  );
};
