import { LayoutProps } from './layout-props';

export const DefaultLayout = ({
  label,
  input,
  error,
  className = '',
  required = false,
  name,
  inputText,
  prefix,
}: LayoutProps) => {
  return (
    <div className={`form-group ${className}`}>
      {!!label && (
        <label htmlFor={name}>
          {label} {required && <span className='requiredField'>*</span>}
        </label>
      )}
      <div className='d-flex gap-2 align-items-center'>
        {prefix && <div className='mb-2'>{prefix}</div>}
        {input}
        {inputText && <div className='mb-2'>{inputText}</div>}
      </div>

      {!!error && <span className='error'>{error}</span>}
    </div>
  );
};
