import * as actionTypes from '../actions/actionTypes';

const initState = {
  task: {},
  tasks: [],
  message: '',
  isfetched: false,
  isfetchedError: false,
};

export default (state = initState, action = {}) => {
  switch (action.type) {
    case actionTypes.CREATE_PROJECT_TASKS_REQUEST:
      return {
        ...state,
        task: {},
        message: '',
      };
    case actionTypes.CREATE_PROJECT_TASKS_SUCCESS:
      return { tasks: state.tasks.concat(action.payload.response.result.data) };

    case actionTypes.CREATE_PROJECT_TASKS_ERROR:
      return {
        ...state,
        message: '',
      };

    case actionTypes.GET_ALL_PROJECT_TASKS_REQUEST:
      return {
        ...state,
        task: {},
        message: '',
      };

    case actionTypes.GET_ALL_PROJECT_TASKS_SUCCESS:
      return {
        ...state,
        tasks: action.payload.response.result.data.data.map((item) => {
          return item.taskData;
        }),
        message: '',
      };

    case actionTypes.GET_ALL_PROJECT_TASKS_ERROR:
      return {
        ...state,
        message: '',
      };

    case actionTypes.UPDATE_PROJECT_TASKS_REQUEST:
      return {
        ...state,
        task: {},
        message: '',
      };
    case actionTypes.UPDATE_PROJECT_TASKS_SUCCESS:
      return {
        ...state,
        tasks: action.payload.response.result.contractTypes,
        message: '',
      };

    case actionTypes.UPDATE_PROJECT_TASKS_ERROR:
      return {
        ...state,
        message: '',
      };

    case actionTypes.DELETE_PROJECT_TASKS_REQUEST:
      return {
        ...state,
        task: {},
        message: '',
      };
    case actionTypes.DELETE_PROJECT_TASKS_SUCCESS:
      return {
        ...state,
        tasks: state.tasks,
        message: '',
      };

    case actionTypes.DELETE_PROJECT_TASKS_ERROR:
      return {
        ...state,
        message: '',
      };

    default:
      return state;
  }
};
