import { usePageTitle } from '../../../layouts/title-context/index.js';
import SettingsPage from './index.js';

const AdminSettingsPageWrapper = ({
  singularTitle,
  tableTitle = `${singularTitle}s`,
  pageTitle = `Administration - Default ${tableTitle}`,
  modelName,
}) => {
  usePageTitle(pageTitle);

  return (
    <SettingsPage
      singularTitle={singularTitle}
      isSuperAdmin
      modelName={modelName}
      tableTitle={tableTitle}
    />
  );
};

export default AdminSettingsPageWrapper;
