import _ from 'lodash';
import { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { getCompany, getAccountAffiliation } from '../../../modules/companies';
import { getContactAffiliations } from '../../../modules/contacts';
import { processAffiliations } from '../../../forms/affiliation-form/use-affiliation-form';
import { formatPhone, formatPhoneForCall } from '../../../utils/formatters';
import useCountryCodes from '../../../shared/hooks/use-country-codes';
import { ContactCustomFields } from '../../../pages/contact-detail-page/custom-fields/contact-custom-fields';
import { getContactCustomFieldsValuesForm, getEmployeeInvitationStatus } from '../../../modules/contacts';
import { isAllowed, moduleConstants } from '../../../_constants';
import styles from './style.module.css';

type ViewContactDetailInfoProps = {
  contact: any;
  onOpenEditModal: (section: string) => void;
};

export const ViewContactDetailInfo = ({
  contact = {},
  onOpenEditModal,
}: ViewContactDetailInfoProps) => {

  const { objectId: contactId = '', isEmployee = false, updatedAt = '', comments='' } = contact;

  const countries = useCountryCodes();
  const dispatch: any = useDispatch();

  const [customFields, setCustomFields] = useState<Array<any>>([]);
  const [allAffiliations, setAllAffiliations] = useState<any>({});
  const [formerAffiliationsVisible, setFormerAffiliationsVisible] = useState<boolean>(false);
  const [invitationStatus, setInvitationStatus] = useState<any>({});
  

  useEffect(() => {
    if (contactId) {
      (async () => {
        const accountAffiliation = await dispatch(getAccountAffiliation()).unwrap();
        const fetchedAffiliations = await dispatch(getContactAffiliations({ contactId })).unwrap();

        let { affiliations = [], formerAffiliations = [] } = processAffiliations(fetchedAffiliations, accountAffiliation, isEmployee, !!contactId);

        // If information (address or phone) is not present for contact for a specific affiliation, show primary details from company profile.
        affiliations = await processAffiliationsWithoutData(_.cloneDeep(affiliations));
        formerAffiliations = await processAffiliationsWithoutData(_.cloneDeep(formerAffiliations));

        setAllAffiliations({ affiliations, formerAffiliations });
      })();

      dispatch(getEmployeeInvitationStatus({ contactId }))
        .unwrap()
        .then((data) => setInvitationStatus({ ...data }));
    }
  }, [contactId, updatedAt, isEmployee]);

  useEffect(() => {
    dispatch(getContactCustomFieldsValuesForm())
      .unwrap()
      .then((data) => {
        setCustomFields(data);
      });
  }, [contactId, updatedAt]);

  const processAffiliationsWithoutData = async (affs) => {
    const affiliationsWithoutData = affs
      .filter((aff) => !aff.selfAffiliated)
      .filter(({ phoneArr = [], emailArr = [], addressArr = [] }) => phoneArr.length === 0 || emailArr.length === 0 || addressArr.length === 0);

    for (let i = 0; i < affiliationsWithoutData.length; i++) {
      const aff = affiliationsWithoutData[i];
      const { companyId } = aff;

      if (!companyId) {
        continue;
      }

      const companyInfo = await dispatch((getCompany as any)({ companyId })).unwrap();

      if (!aff.phoneArr.length) {
        aff.phoneArr = companyInfo.phoneArr.map((ph) => ({...ph, isReplaced: true}));
      }

      if (!aff.emailArr.length) {
        aff.emailArr = companyInfo.emailArr.map((em) => ({...em, isReplaced: true}));
      }

      if (!aff.addressArr.length) {
        aff.addressArr = companyInfo.addressArr.map((addr) => ({...addr, isReplaced: true}));
      }
    }

    return affs;
  };

  const getPhonesMarkup = (phones) =>
    phones.map((phone, index) => {
      const phoneNumber = formatPhone(phone, countries, true);
      const phoneNumberForCall = formatPhoneForCall(phone);
      const { type, isReplaced } = phone;

      return (
        <tr key={index}>
          <td className={isReplaced ? 'fst-italic' : ''}><a href={`tel:${phoneNumberForCall}`}>{phoneNumber}</a></td>
          <td className={isReplaced ? 'fst-italic' : ''}>{type && `(${type})`}</td>
        </tr>
      );
    });

  const getEmailsMarkup = useCallback(
    (emails, isAccountAffiliation = false) => {
      const { email, status } = invitationStatus;
      const emailsClone = [...emails];

      if (isAccountAffiliation && email && status !== 'activated') {
        emailsClone.unshift({ email });
      }

      return emailsClone.map(({ email, label, isReplaced }, index) => {
        return (
          <tr key={index}>
            <td className={isReplaced ? 'fst-italic' : ''}>
              <a href={`mailto:${email}`}>{email}</a>
            </td>
            <td className={isReplaced ? 'fst-italic' : ''}>{label && `(${label})`}</td>
          </tr>
        );
      })
    },
    [invitationStatus]
  );

  const getAddressesMarkup = (addresses) =>
    addresses.map((addr, index) => {
      const { address1 = '', address2 = '', country = '', state = '', city = '', zipCode = '', label = '', isReplaced } = addr;
      const addressRow = [address1, address2].filter((addr) => addr).join(', ');
      const countryRow = [city, state, country, zipCode].filter((addr) => addr).join(', ');
      const resultRow = [addressRow, countryRow].filter((addr) => addr).join(' <br /> ');

      return (
        <tr key={index}>
          <td className={isReplaced ? 'fst-italic' : ''} dangerouslySetInnerHTML={{ __html: resultRow }}></td>
          <td className={isReplaced ? 'fst-italic' : ''}>{label && `(${label})`}</td>
        </tr>
      )
    });

  const primaryFirstSort = (a, b) => b.isPrimary - a.isPrimary;

  const renderAffiliations = (affs, isFormer = false) =>
    affs.map((aff, index) => {
      const {
        phoneArr = [],
        emailArr = [],
        addressArr = [],
        selfAffiliated,
        companyName = 'Unset Company Name',
        companyId,
        isAccountAffiliation = false,
      } = aff;
      const phoneArrSorted = [...phoneArr.filter((ph) => ph.phone)].sort(primaryFirstSort);
      const emailArrSorted = [...emailArr.filter((em) => em.email)].sort(primaryFirstSort);
      const addressArrSorted = [...addressArr.filter((addr) => addr.address1 || addr.address2 || addr.country || addr.state || addr.city)].sort(primaryFirstSort);
      const inactiveMarkup = <span className={styles.inactiveText+' ms-2'}>(Inactive)</span>;
      const titleMarkup = selfAffiliated
        ? 'Personal Information'
        : <a className={styles.contactBlockLink} href={`/company-details/${companyId}`}>{ companyName }</a>
      const minimapLocation = `contact-${selfAffiliated ? 'information' : index}`;


      return (
        <div className={styles.contactBlock} key={index}>
          <span className={styles.contactBlockTitle}>
            { titleMarkup } {isFormer && inactiveMarkup}
            {canEditDetails && (
              <a className={styles.contactBlockPencil} onClick={() => onOpenEditModal(minimapLocation)}>
                <i className='fa fa-pencil' aria-hidden='true'></i>
              </a>
            )}
          </span>
          <ContactCustomFields
            customFields={customFields}
            affiliation={aff}
            selfAffiliated={selfAffiliated}
            isAccountAffiliation={isAccountAffiliation}
          />
          <table className={styles.affiliationTable} key={index}>
            <tbody>
              {phoneArrSorted.length
                ? <tr>
                  <td>Phone number</td>
                  <td>
                    <table>
                      <tbody>{getPhonesMarkup(phoneArrSorted)}</tbody>
                    </table>
                  </td>
                </tr>
                : null}
              {emailArrSorted.length
                ? <tr>
                  <td>Email</td>
                  <td>
                    <table>
                      <tbody>{getEmailsMarkup(emailArrSorted, isAccountAffiliation)}</tbody>
                    </table>
                  </td>
                </tr>
                : null}
              {addressArrSorted.length
                ? <tr>
                  <td>Address</td>
                  <td>
                    <table>
                      <tbody>{getAddressesMarkup(addressArrSorted)}</tbody>
                    </table>
                  </td>
                </tr>
                : null}
            </tbody>
          </table>
        </div>
      );
    });

  const renderToggleFormerAffiliations = () =>
    <a href='#' onClick={onToggleFormerAffsClick} data-testid='viewContactDetailInfo-former-affs-toggler'>
      {formerAffiliationsVisible ? 'Hide' : 'View'} inactive company affiliations
    </a>;

  const onToggleFormerAffsClick = (e) => {
    e.preventDefault();
    e.target.blur();

    setFormerAffiliationsVisible(!formerAffiliationsVisible)
  };

  //const filterNotEmptyAffiliations = ({ phoneArr = [], emailArr = [], addressArr = [] }) => phoneArr.length || emailArr.length || addressArr.length;

  const affiliations = (allAffiliations.affiliations || []);//.filter(filterNotEmptyAffiliations);
  const formerAffiliations = (allAffiliations.formerAffiliations || []);//.filter(filterNotEmptyAffiliations);

  const canEditDetails = (!isEmployee && isAllowed(moduleConstants.EC)) || (isEmployee && isAllowed(moduleConstants.EE));

  const renderCommentsSection = () => {
    if (!comments) {
      return null;
    }

    return (
      <div className={styles.contactBlock}>
        <span className={styles.contactBlockTitle}>Comments
          {canEditDetails && (
              <a className={styles.contactBlockPencil} onClick={() => onOpenEditModal('contact-comments')}>
                <i className='fa fa-pencil' aria-hidden='true'></i>
              </a>
            )}</span>
        <div className={styles.commentsBlock}>
          {comments}
        </div>
      </div>
    );
  }

  return (
    <div className={styles.wrapper} data-testid='viewContactDetailInfo'>
      {!!affiliations.length && renderAffiliations(affiliations)}
      {!!formerAffiliations.length && renderToggleFormerAffiliations()}
      {!!formerAffiliations.length && formerAffiliationsVisible && renderAffiliations(formerAffiliations, true)}
      {renderCommentsSection()}
    </div>
  );
};
