import FormSelect from '../../form-controls/form-select';
import { CustomFieldsCommonProps } from '../constants';

type CustomSingleSelectProps = {
  options: any[];
  creatable: boolean;
} & CustomFieldsCommonProps;

export const CustomSingleSelect = ({ options, creatable, ...props }: CustomSingleSelectProps) => {
  return <FormSelect {...props} options={options} creatable={creatable} isClearable />;
};
