import React from 'react';
import { Link } from 'react-router-dom';
class CompanyNotFound extends React.Component {
  render() {
    return (
        <div>
            <div className="page-error text-center">
                <div className="container" style={{padding: '10%'}}>
                <div style={{textAlign: 'center'}}>
                    <div className="logo">
                    <a href="/home">
                        <img style={{width: '46%'}} src="/static/media/Cobilogo.png" alt="logo" />
                    </a>
                    </div>
                </div>
                <h1 style={{color: 'red'}}>Company Not Found</h1>
                    <Link className="" to={{ pathname: `/view-companies` }}>
                        {' '}
                        Return to previous page{' '}
                    </Link>
                </div>
            </div>
        </div>
    );
  }
}
export default (CompanyNotFound);
