import apiClient from '../../utils/api-client';

export const getUserInfoRequest = (id: string) => apiClient.post('/users/getUserInfo', { id });

export const updateUserInfoRequest = (data: any) =>
  apiClient.post('/users/updateUserInfo', { ...data });

export const verifyPhoneNumberRequest = (phone: string) =>
  apiClient.post('/users/verifyPhoneNumber', { phone });

export const confirmPhoneNumberRequest = (token: string) =>
  apiClient.post(`/users/confirmPhoneNumber/${token}`);

export const getVerifyInformationRequest = (token: string) =>
  apiClient.post(`/users/getVerifyInformation/${token}`);

export const cancelVerifyPhoneNumberRequest = (token: string) =>
  apiClient.post(`/users/cancelVerifyPhoneNumber/${token}`);

export const disablePhoneActivationRequest = () => apiClient.post('/users/disablePhoneActivation');

