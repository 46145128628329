import { useCallback, useState } from 'react';

export const useSubmit = (asyncCb: (...a: any) => Promise<any>, deps?: Array<any>) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = useCallback(async (e?: any) => {
    setLoading(true);
    try {
      await asyncCb(e);
    } catch (e) {
      console.log(e);
      setError(e as any);
    } finally {
      setLoading(false);
    }
  }, deps as any);

  return [handleSubmit, loading, error] as [typeof handleSubmit, typeof loading, typeof error];
};
