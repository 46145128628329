import { notification } from 'antd';

import { useCallback, useEffect, useState, useRef } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import {
  getTemporaryProjects,
  removeTemporaryProject,
  getProjectCustomFieldsValuesForm,
  createProject,
  getNextProjectNumber,
} from '../../../modules/projects';
import FormDatePicker from '../../../shared/form-controls/form-date-picker';
import FormInput from '../../../shared/form-controls/form-input';
import FormSelect from '../../../shared/form-controls/form-select';
import { useAppDispatch } from '../../../shared/hooks/use-app-dispatch';
import { useAppSelector } from '../../../shared/hooks/use-app-selector';
import { AddProjectModal } from '../../../modals/add-project-modal';
import { AddLeadModal } from '../../../modals/add-lead-modal';
import { ProjectCompanyTeam } from '../../../modals/add-project-modal/sections/project-company-team';
import { formatDateObjectOrNull } from '../../../utils/date-formatters';
import { HorizontalLayout } from '../../../shared/form-controls/control-layouts/horizontal-layout';
import RemoveTemporaryProjectModal from './remove-temporary-project-modal';
import { ProjectImportCustomFields } from './project-import-custom-fields';
import { selectAllJobStatusOptions } from '../../../selectors/jobStatusCode';
import { getProjectStageByCode } from '../../../shared/custom-fields-controls/constants';
import './../../../index.css';
import styles from '../styles.module.scss';

const ProjectView = ({ project, projectRoles, jobStatuses = [], autoAdd = false }) => {
  const dispatch = useAppDispatch();

  const leadStatuses = ['Lead', 'Not Pursued'];
  const displayRolesCount = 5;

  const [internalRolesDisplay, setInternalRolesDisplay] = useState([]);
  const [externalRolesDisplay, setExternalRolesDisplay] = useState([]);
  const [removeProjectModalOpen, setRemoveProjectModalOpen] = useState(false);
  const [isAddProjectModalOpen, setIsAddProjectModalOpen] = useState(false);
  const [isAddLeadModalOpen, setIsAddLeadModalOpen] = useState(false);
  const addProjectModalRef = useRef(null);
  const addLeadModalRef = useRef(null);
  const [customFields, setCustomFields] = useState([]);
  const [projectStage, setProjectStage] = useState('');
  

  const form = useForm({});
  const customFieldsForm = useForm({});
  const { control, reset, watch, handleSubmit } = form;

  const statusWatch = watch('jobStatusCodes');
  const startDate = watch('startDate');
  const endDate = watch('endDate');
  const jobName = watch('jobName');

  const allProjectJobStatusCodes = useAppSelector(selectAllJobStatusOptions);

  // defines project stage (e.g. 'bidding' or 'pre-construction') by jobStatusCodes
  useEffect(() => {
    if (statusWatch) {
      const jobStatusCodesCode =
        allProjectJobStatusCodes.find((st) => st.value === statusWatch)?.code || '';

      const projectStage = getProjectStageByCode(jobStatusCodesCode);
      setProjectStage(projectStage);
    }
  }, [statusWatch, allProjectJobStatusCodes]);

  // sets form data and gets custom fields
  useEffect(() => {
    if (project) {
      reset({
        ...project,
        startDate: formatDateObjectOrNull(project.startDate),
        endDate: formatDateObjectOrNull(project.endDate),
        bidDueDate: formatDateObjectOrNull(project.bidDueDate),
      });

      dispatch(getProjectCustomFieldsValuesForm())
        .unwrap()
        .then((data) => {
          setCustomFields([...data]);
        });
    }
  }, [project]);

  useEffect(() => {
    if (project?.customFields && customFields.length > 0) {
      customFieldsForm.reset(project.customFields);
    }
  }, [project, customFields]);

  // defines list of roles that should be displayed based on the 'jobStatusCodes'
  useEffect(() => {
    if (!statusWatch) {
      return;
    }

    const status = jobStatuses.find((statusData) => statusData.objectId === statusWatch)?.label;

    let internalRolesDisplay;
    let externalRolesDisplay;
    if (leadStatuses.includes(status)) {
      internalRolesDisplay = projectRoles
        .filter((role) => role.isShowOnLeads && role.type === 'internal')
        .slice(0, displayRolesCount);
      externalRolesDisplay = projectRoles
        .filter((role) => role.isShowOnLeads && role.type === 'external')
        .slice(0, displayRolesCount);
    } else {
      internalRolesDisplay = projectRoles
        .filter((role) => role.type === 'internal')
        .slice(0, displayRolesCount);
      externalRolesDisplay = projectRoles
        .filter((role) => role.type === 'external')
        .slice(0, displayRolesCount);
    }

    setInternalRolesDisplay([...internalRolesDisplay]);
    setExternalRolesDisplay([...externalRolesDisplay]);
  }, [statusWatch, jobStatuses]);

  const [submitting, setSubmitting] = useState(false);

  const onSubmit = useCallback(() => {
    setSubmitting(true);

    dispatch(removeTemporaryProject(project._id)).unwrap()
      .then(() => notification.success({ message: 'Project saved!' }))
      .finally(() => setSubmitting(false))
      .then(() => dispatch(getTemporaryProjects()));
  }, []);

  const onDirectSubmit = useCallback(() => {
    setSubmitting(true);

    const status = statusWatch
      ? jobStatuses.find((statusData) => statusData.objectId === statusWatch).objectId
      : project.jobStatusCodes;
    const data = { ...project, jobStatusCodes: status, createdFrom: 'IMPORT' };

    // use dispatch to get next project number
    dispatch(getNextProjectNumber()).then(({payload:numberResult}) => {
      if(data?.overrideJobNum){
        if(typeof(data?.overrideJobNum) === 'number'){
          // convert to string
          data.overrideJobNum = data.overrideJobNum.toString();
        }
        if(data?.overrideJobNum.includes('-')){
          const overrideJobNumSplit = data?.overrideJobNum.split('-');
          data.jobNum= overrideJobNumSplit[1];
          data.jobNumPrefix= overrideJobNumSplit[0];
        }else{
          data.jobNum= data.overrideJobNum;
          data.jobNumPrefix= numberResult.jobNumPrefix;
        }

        if(typeof(data.jobNum) === 'string'){
          data.jobNum = parseInt(data.jobNum);
        }
      }else{
        data.jobNum= numberResult.jobNum;
        data.jobNumPrefix = numberResult.jobNumPrefix;
      }
      dispatch(
        createProject({
          ...data,
          //accountId: data?.accountId?.objectId || data?.accountId || '',
          //userId: data?.userId?.objectId || data?.userId || '',
          //jobName: data?.jobName || '',
          jobStatusCodes: status || data?.jobStatusCodes || '',
        })
      )
        .unwrap()
        .then(dispatch(removeTemporaryProject(project._id)).unwrap()
        
        .then(() => notification.success({ message: 'Project saved!' }))
        //.finally(() => setSubmitting(false))
        .finally(() =>
          dispatch(getTemporaryProjects())
        ));
    });
    
  }, []);

  const onEditAndAddClick = () => {
    const status = jobStatuses.find((statusData) => statusData.objectId === statusWatch).label;
    const data = { ...project, jobStatusCodes: statusWatch, createdFrom: 'IMPORT' };

    if (leadStatuses.includes(status)) {
      // correction for Lead properties
      const leadRoleIds = projectRoles.filter((role) => role.isShowOnLeads).map((role) => role._id);
      const projectTeam = { ...data.projectTeam };

      data.estimateBidAmount = data.contractAmount;
      data.estimateGrossProfit = data.grossProfit;
      data.projectTeam = leadRoleIds.reduce(
        (acc, curr) => Object.assign(acc, { [curr]: projectTeam[curr] }),
        {}
      );

      addLeadModalRef.current.setProjectData(data);
      setIsAddLeadModalOpen(true);
    } else {
      addProjectModalRef.current.setProjectData(data);
      setIsAddProjectModalOpen(true);
    }
  };

  if (!project) {
    return null;
  }

  useEffect(() => {
    if(autoAdd && project && !isAddProjectModalOpen && !isAddLeadModalOpen && !submitting){
      onDirectSubmit();
    }
  }, [project, autoAdd]);

 

  return (
    <>
      <div className='row'>
        <FormProvider {...form}>
          <div className='col-md-12'>
            <div className='row'>
              <div className='col-md-12'>
                <div className='d-flex align-items-center justify-content-between'>
                  <h2>{jobName}</h2>
                  <div className='d-flex gap-2'>
                    <button
                      data-testid='projectViewRemoveBtn'
                      className='btn btn-primary'
                      onClick={() => setRemoveProjectModalOpen(true)}
                    >
                      Remove
                    </button>
                    <button
                      data-testid='projectViewEditBtn'
                      className='btn btn-primary'
                      onClick={onEditAndAddClick}
                    >
                      {submitting ? 'Saving' : 'Edit & Add'}
                    </button>
                    <button
                      className='btn btn-primary'
                      disabled={submitting}
                      onClick={handleSubmit(onDirectSubmit)}
                    >
                      {submitting ? 'Saving' : 'Add Project'}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className={`row ${styles.projectImportGrid}`}>
              <FormSelect
                required
                label='Project Status'
                control={control}
                name='jobStatusCodes'
                options={jobStatuses}
                Layout={HorizontalLayout}
              />
              <FormDatePicker
                label='Start Date'
                name='startDate'
                selectsStart
                startDate={startDate}
                endDate={endDate}
                control={control}
                Layout={HorizontalLayout}
                disabled
              />
              <FormDatePicker
                label='End Date'
                name='endDate'
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                control={control}
                Layout={HorizontalLayout}
                disabled
              />
              <FormInput
                label='Contract Amount'
                placeholder='Contract Amount ($)'
                control={control}
                name='contractAmount'
                type='number'
                Layout={HorizontalLayout}
                disabled
              />
              <FormInput
                label='Profit'
                placeholder='Profit ($)'
                control={control}
                name='grossProfit'
                type='number'
                Layout={HorizontalLayout}
                disabled
              />
              <ProjectCompanyTeam
                projectRoles={internalRolesDisplay}
                isInternal
                showHeader={false}
                disabled
              />
              <ProjectCompanyTeam
                projectRoles={externalRolesDisplay}
                isInternal={false}
                showHeader={false}
                disabled
              />
            </div>
            <div className={`row ${styles.projectImportGrid}`}>
              <FormProvider {...customFieldsForm}>
                <ProjectImportCustomFields fields={customFields} projectStage={projectStage} />
              </FormProvider>
            </div>
          </div>
        </FormProvider>
      </div>
      <RemoveTemporaryProjectModal
        project={project}
        open={removeProjectModalOpen}
        onClose={() => setRemoveProjectModalOpen(false)}
      />
      <AddProjectModal
        open={isAddProjectModalOpen}
        showSaveAndNext={false}
        onClose={() => setIsAddProjectModalOpen(false)}
        onSubmit={onSubmit}
        ref={addProjectModalRef}
        isImport
      />
      <AddLeadModal
        open={isAddLeadModalOpen}
        showSaveAndNext={false}
        onClose={() => setIsAddLeadModalOpen(false)}
        onSubmit={onSubmit}
        ref={addLeadModalRef}
        isImport
      />
    </>
  );
};

export default ProjectView;
