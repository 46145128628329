import { useMemo } from 'react';

export const useSchedulerResources = ({
  groupBy,
  isGroupShared,
  report,
  getProjectFields,
  getContactFields,
}: {
  groupBy: string;
  isGroupShared: boolean;
  report: any[];
  getProjectFields: (project) => any;
  getContactFields: (contact) => any;
}) => {
  const resources = useMemo(() => {
    if (!groupBy) {
      return report.map((project) => ({
        id: project._id,
        ...getProjectFields(project),
        isProject: true,
        disableBullets: true,
        children: [],
      }));
    }

    if (!isGroupShared) {
      return report.map((contact) => ({
        expanded: true,
        id: contact._id,
        ...getContactFields(contact),
        isContact: true,
        children: contact?.projects?.map((project) => ({
          id: `${contact._id}-${project._id}`,
          ...getProjectFields(project),
          isProject: true,
        })),
      }));
    }

    return report.map((project) => ({
      id: project._id,
      ...getProjectFields(project),
      isProject: true,
      expanded: true,
      children: project?.affiliations?.map((affiliation) => ({
        id: `${project._id}-${affiliation.contactAffiliationId}`,
        ...getContactFields(affiliation),
        isContact: true,
      })),
    }));
  }, [report, isGroupShared, groupBy]);

  return resources;
};
