import moment from 'moment-timezone';
import { appConstants } from '../_constants';

const cutoffDate = moment('1801-03-03T12:47:40.000Z');

export const formatDatePicker = (iso_date) => {
  let userTimeZone = getTimeZone();
  if (typeof iso_date === 'undefined') {
    return getCurrentTimeUTC().tz(userTimeZone).format(appConstants.DATEFORMATPICKER);
  } else if (iso_date && iso_date.__type === 'Date' && iso_date.iso) {
    iso_date = iso_date.iso;
  } 

  const momentDate = moment(iso_date).tz(userTimeZone);
  if(momentDate.isBefore(cutoffDate)||!momentDate.isValid()){
    return '-';
  }
  
  return moment(iso_date).tz(userTimeZone).format(appConstants.DATEFORMATPICKER);

};

export const formatDatePickerTime = (iso_date) => {
  let userTimeZone = getTimeZone();
  if (typeof iso_date === 'undefined') {
    return getCurrentTimeUTC().tz(userTimeZone).format(appConstants.DATEFORMATPICKERTIME);
  } else if (iso_date && iso_date.__type === 'Date' && iso_date.iso) {
    iso_date = iso_date.iso;
  } 

  const momentDate = moment(iso_date).tz(userTimeZone);
  if(momentDate.isBefore(cutoffDate)||!momentDate.isValid()){
    return '-';
  }
  
  return moment(iso_date).tz(userTimeZone).format(appConstants.DATEFORMATPICKERTIME);

};

/*Without Time*/
export const formatDate = (iso_date) => {
  let userTimeZone = getTimeZone();
  if (typeof iso_date === 'undefined') {
    return getCurrentTimeUTC().tz(userTimeZone).format(appConstants.DATEFORMATNOTTIME);
  } else if (iso_date && iso_date.__type === 'Date' && iso_date.iso) {
    iso_date = iso_date.iso;
  } 

  const momentDate = moment(iso_date).tz(userTimeZone);
  if(momentDate.isBefore(cutoffDate)||!momentDate.isValid()){
    return '-';
  }
  
  return moment(iso_date).tz(userTimeZone).format(appConstants.DATEFORMATNOTTIME);

};

export const dateFormatMMDDYYYY = (iso_date) => {
  let userTimeZone = getTimeZone();
  if (typeof iso_date === 'undefined') {
    return getCurrentTimeUTC().tz(userTimeZone).format(appConstants.DATEFORMATCONTACT);
  }
  return moment(iso_date).tz(userTimeZone).format(appConstants.DATEFORMATCONTACT);
};

export const dateFormatMMDDYYYYtime = (iso_date) => {
  let userTimeZone = getTimeZone();
  if (typeof iso_date === 'undefined') {
    return getCurrentTimeUTC().tz(userTimeZone).format(appConstants.DATEFORMATCONTACTTIME);
  }
  return moment(iso_date).tz(userTimeZone).format(appConstants.DATEFORMATCONTACTTIME);
};


export const formatDateWithoutTimeUTC = (iso_date) => {
  return moment(iso_date).utc().format();
};

export const startDateFormatWithoutTimeUTCF = (iso_date) => {
  return moment(iso_date).utc().startOf('day').format();
};

export const endDateFormatWithoutTimeUTCF = (iso_date) => {
  return moment(iso_date).utc().endOf('day').format();
};

/*With Time*/
export const formatDateWithTime = (iso_date) => {
  let userTimeZone = getTimeZone();
  if (typeof iso_date === 'undefined')
    return getCurrentTimeUTC().tz(userTimeZone).format(appConstants.DATEFORMATWITHTIME);
  else if (iso_date && iso_date.__type === 'Date' && iso_date.iso) {
    let iso_date_str = iso_date.iso;
    return moment(iso_date_str).tz(userTimeZone).format(appConstants.DATEFORMATWITHTIME);
  } else {
    return moment(iso_date).tz(userTimeZone).format(appConstants.DATEFORMATWITHTIME);
  }
};

export const formatDateUTC = (iso_date) => {
  let userTimeZone = getTimeZone();
  if (iso_date === '' || typeof iso_date === 'undefined') {
    let date = getCurrentTimeUTC().tz(userTimeZone);
    return date.utc().format();
  }
  let date = moment(iso_date);
  return date.utc().format();
};

export const formatDateObjectWithTime = (iso_date) => {
  let userTimeZone = getTimeZone();
  if (iso_date) {
    return moment(iso_date).tz(userTimeZone);
  } else {
    return getCurrentTimeUTC().tz(userTimeZone);
  }
};

export const formatDateMMDDYY = (iso_date) => {
  let userTimeZone = getTimeZone();
  if (typeof iso_date === 'undefined') {
    return getCurrentTimeUTC().tz(userTimeZone).format('M/D/YY');
  } else if (iso_date && iso_date.__type === 'Date' && iso_date.iso) {
    let iso_date_str = iso_date.iso;
    return moment(iso_date_str).tz(userTimeZone).format('M/D/YY');
  } else {
    return moment(iso_date).tz(userTimeZone).format('M/D/YY');
  }
};

export const formatDateForCompare = (iso_date) => {
  let userTimeZone = getTimeZone();

  if (typeof iso_date === 'undefined') {
    return getCurrentTimeUTC().format('YYYY-MM-dd');
  }
  return moment(iso_date).format('YYYY-MM-dd');
};

export const RSR_ComparisonDate = (iso_date) => {
  let userTimeZone = getTimeZone();

  if (typeof iso_date === 'undefined') {
    return getCurrentTimeUTC().tz(userTimeZone).format('YYYY-MM-dd');
  }
  return moment(iso_date).tz(userTimeZone).format('YYYY-MM-dd');
};

function getTimeZone() {
  //let userInfo = JSON.parse(localStorage.getItem('accountInfo'));
  /*let userTimeZone = '';
    if (
      userInfo &&
      userInfo.companyTimeZone &&
      userInfo.companyTimeZone.value &&
      userInfo.companyTimeZone.value.name
    ) {
      userTimeZone = userInfo.companyTimeZone.value.name;
    } else {
      */
  let userTimeZone = moment.tz.guess();
  //}
  return userTimeZone;
}

export const getCurrentTimeZone = () => {
  return getTimeZone();
};

export const formatDateIsValid = (iso_date) => {
  let userTimeZone = getTimeZone();
  return moment(iso_date).tz(userTimeZone).isValid();
};

export const formatDateObject = (iso_date) => {
  let userTimeZone = getTimeZone();
  if (iso_date === '' || typeof iso_date === 'undefined') {
    return getCurrentTimeUTC().tz(userTimeZone);
  }
  return moment(iso_date).tz(userTimeZone);
};

export const formatDateObjectOrNull = (iso_date) => {
  if(iso_date&&iso_date?.iso){
    iso_date=iso_date.iso;
  }
  if (!iso_date) {
    return null;
  }
  
  let userTimeZone = getTimeZone();
  
  return moment(iso_date).tz(userTimeZone);
};

function getCurrentTimeUTC() {
  let curTime = new Date().toUTCString();
  let curTimeMoment = moment(curTime);
  let tz_local = moment.tz.guess();

  return curTimeMoment;
}

export const formatWithOutDateObject = (iso_date) => {
  let userTimeZone = getTimeZone();
  if (iso_date === '' || typeof iso_date === 'undefined') {
    return '';
  }
  return moment(iso_date).tz(userTimeZone);
};

export const formatDateAdd = (day, type, startDate) => {
  const userTimeZone = getTimeZone();
  const date = !startDate ? getCurrentTimeUTC() : moment(startDate);

  return date.add(day, type).tz(userTimeZone);
};

export const formatDateDuration = (iso_date, type) => {
  return moment.duration(iso_date ? iso_date : 0, type);
};

export const fromNow = (iso_date) => {
  let userTimeZone = getTimeZone();
  return moment(iso_date).tz(userTimeZone).fromNow();
};

export const formatBidDueDate = (iso_date, pattern = appConstants.DATETIMEZONEFORMAT) => {
  if (iso_date && iso_date.iso) {
    iso_date = iso_date.iso;
  }
  let userTimeZone = getTimeZone();
  return moment(iso_date).tz(userTimeZone).format(pattern);
};

export const formatDuration = (iso_date_start, iso_date_end) => {
  if (iso_date_start?.iso) {
    iso_date_start = iso_date_start.iso;
  }
  if (iso_date_end?.iso) {
    iso_date_end = iso_date_end.iso;
  }

  const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
  const diffDays = Math.ceil((new Date(iso_date_end).getTime() - new Date(iso_date_start).getTime()) / oneDay);
  return diffDays;

};

export const teamActivityTimeFormat = (iso_date) => {
  let userTimeZone = getTimeZone();
  return moment(iso_date).tz(userTimeZone).format(appConstants.TEAMACTIVITYDATEFORMAT);
};

export const yearPickerValue = (optionYears=[]) => {
  var year = new Date().getFullYear();
  year = parseInt(year, 10);
  var yearStart = 1998;
  var yearEnd = year + 2;
  for (var i = yearEnd; i >= yearStart; i--) {
    optionYears.push({ value: i, label: i });
  }
  return optionYears;
};

export const addInDate = (iso_date, value) => {
  let userTimeZone = getTimeZone();
  if (typeof iso_date === 'undefined') {
    return getCurrentTimeUTC().tz(userTimeZone).format(appConstants.DATEFORMATNOTTIME);
  }
  if (iso_date) {
    let date_val = '';
    let newIsoDate = moment(iso_date);
    var duration = moment.duration(value ? value : 0, 'd');
    date_val = newIsoDate.add(duration);
    return date_val.format(appConstants.DATEFORMATNOTTIME);
  }
};

export const onWarrantyExpireDate = (iso_date, value) => {
  let userTimeZone = getTimeZone();
  if (typeof iso_date === 'undefined') {
    return getCurrentTimeUTC().tz(userTimeZone).format(appConstants.DATEFORMATNOTTIME);
  }
  let newIsoDate = moment(iso_date);

  if (iso_date) {
    let date_val = '';
    var duration = moment.duration(value ? value : 0, 'month');
    date_val = newIsoDate.add(duration);
    return moment(date_val).format(appConstants.DATEFORMATNOTTIME);
  }
};

// Delivered date validation function
export const dateValidation = (startDate, endDate, DeliveredDate) => {
  let startD = startDate;
  let endD = endDate;
  let deliveredD = DeliveredDate;
  let dateError = '';
  if (DeliveredDate && new Date(startD).getTime() >= new Date(deliveredD).getTime()) {
    dateError = 'start';
  } else if (new Date(endD).getTime() < new Date(startD).getTime()) {
    dateError = 'end';
  } else {
    dateError = '';
  }
  return dateError;
};

// Delivered date validation function
export const getDifferenceBetweenDates = (startDate, endDate) => {
  if (endDate && endDate.iso) {
    endDate = endDate.iso;
  }

  let userTimeZone = getTimeZone();
  startDate = startDate
    ? startDate
    : getCurrentTimeUTC().tz(userTimeZone).format(appConstants.DATEFORMATNOTTIME);
  endDate = moment(endDate).tz(userTimeZone);
  var duration = moment.duration(endDate.diff(startDate));
  var hours = duration.asHours();
  return hours >= 0.1 ? true : false;
};

export const formatDateYear = () => {
  let userTimeZone = getTimeZone();
  return getCurrentTimeUTC().tz(userTimeZone).format(appConstants.YEARFORMAT);
};

export const formatEndDateObject = (iso_date) => {
  let userTimeZone = getTimeZone();
  if (typeof iso_date === 'undefined') {
    return getCurrentTimeUTC().add(1, 'months').tz(userTimeZone);
  }
  return moment(iso_date).add(1, 'months').tz(userTimeZone);
};
