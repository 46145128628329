import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getContacts } from '../../../modules/contacts';
import { formatPhone, formatPhoneForCall } from '../../../utils/formatters';
import useCountryCodes from '../../../shared/hooks/use-country-codes';
import AddContactModal from '../../../modals/add-contact-modal/add-contact-modal';
import styles from './style.module.css';

type ViewCompanyContactsProps = {
  companyId: string;
  companyAffiliations: Array<any>;
  onAfterAddContact?: () => Promise<unknown>;
};

export const ViewCompanyContacts = ({
  companyId,
  companyAffiliations,
  onAfterAddContact = () => Promise.resolve(),
}: ViewCompanyContactsProps) => {

  const countries = useCountryCodes();

  const [formerContactsVisible, setFormerContactsVisible] = useState<boolean>(false);
  const [addContactModalOpened, setAddContactModalOpened] = useState<boolean>(false);
  const [contacts, setContacts] = useState<{ activeContacts: Array<any>, formerContacts: Array<any> }>({ activeContacts: [], formerContacts: [] });

  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      if (companyId && companyAffiliations.length) {

        const contactIds = companyAffiliations.filter((aff) => aff.contactId && aff.companyId === companyId).map((aff) => aff.contactId)

        if (contactIds.length) {
          const contacts = await dispatch((getContacts as any)({ contactIds })).unwrap();
          combineData(companyAffiliations, contacts);
        }else{
          setContacts({ activeContacts: [], formerContacts: [] });
        }

      } else {
        setContacts({ activeContacts: [], formerContacts: [] });
      }
    })()
  }, [companyId, companyAffiliations]);

  const combineData = (affiliations, contacts) => {
    const activeContacts: Array<any> = [];
    const formerContacts: Array<any> = [];

    affiliations.forEach((affiliation) => {
      const { active, contactId, phoneArr: affPhoneArr, emailArr: affEmailArr, title } = affiliation;
      const contact = contacts.find((c) => c._id === contactId) || {};

      if (contact._id) {
        const { phoneArr: contactPhoneArr, email: contactEmail } = contact;

        let isPhoneReplaced = false;
        let phoneArr = [...(affPhoneArr || []).filter((ph) => ph.phone)].sort(primaryFirstSort);
        if (!phoneArr.length) {
          phoneArr = [...(contactPhoneArr || []).filter((ph) => ph.phone)].sort(primaryFirstSort);
          isPhoneReplaced = true;
        }

        let isEmailReplaced = false;
        let emailArr = [...(affEmailArr || []).filter((em) => em.email)].sort(primaryFirstSort);
        if (!emailArr.length) {
          emailArr = contactEmail ? [{ email: contactEmail }] : [];
          isEmailReplaced = true;
        }

        const resultObj = {
          ...contact,
          title,
          active,
          phoneArr,
          emailArr,
          isPhoneReplaced,
          isEmailReplaced,
        };

        active ? activeContacts.push(resultObj) : formerContacts.push(resultObj);
      }
    });

    activeContacts.sort(contactsSort);
    formerContacts.sort(contactsSort);

    setContacts({ activeContacts, formerContacts });
  };

  const contactsSort = (contactA, contactB) => {
    const fullNameA = buildFullName(contactA).toLocaleLowerCase();
    const fullNameB = buildFullName(contactB).toLocaleLowerCase();

    if (fullNameA < fullNameB) {
      return -1;
    }
    if (fullNameA > fullNameB) {
      return 1;
    }

    return 0;
  };

  const buildFullName = ({ firstName = '', middleName = '', lastName = '' }) => {
    return middleName
      ? `${firstName} ${middleName[0].toUpperCase()} ${lastName}`.trim()
      : `${firstName || ''} ${lastName || ''}`.trim();
  };

  const primaryFirstSort = (a, b) => b.isPrimary - a.isPrimary;

  const renderContacts = (contacts) =>
    <table className={styles.infoTable}>
      <thead>
        <td>Name</td>
        <td>Title</td>
        <td>Phone</td>
        <td>Email</td>
      </thead>
      <tbody>
        {contacts.map((contact, index) => {
          const { phoneArr, emailArr, title = '', objectId, _id, isPhoneReplaced, isEmailReplaced } = contact;

          const phoneNumberValue = phoneArr.length ? formatPhone(phoneArr[0], countries, true) : '';
          const phoneNumberAttr = phoneArr.length ? formatPhoneForCall(phoneArr[0]) : '';
          const email = emailArr.length ? emailArr[0].email : null;
          const id = objectId || _id;

          return (
            <tr key={index}>
              <td className={styles.name}><a href={`/contact-details/${id}`}>{buildFullName(contact)}</a></td>
              <td className={styles.ellipsis}>{title ? title : null}</td>
              <td className={styles.ellipsis}>
                {phoneNumberValue
                  ? <a className={isPhoneReplaced ? 'fst-italic' : ''} href={`tel:${phoneNumberAttr}`}>{phoneNumberValue}</a>
                  : null}
              </td>
              <td className={styles.ellipsis}>
                {email
                  ? <a className={isEmailReplaced ? 'fst-italic' : ''} href={`mailto:${email}`}>{email}</a>
                  : null}
              </td>
            </tr>
          )
        })}
      </tbody>
    </table>;

  const renderToggleFormerContacts = () =>
    <a href='#' onClick={onToggleFormerContactsClick} data-testid='viewCompanyContacts-toggler'>
      {formerContactsVisible ? 'Hide' : 'View'} inactive company contacts
    </a>;

  const onToggleFormerContactsClick = (e) => {
    e.preventDefault();
    e.target.blur();

    setFormerContactsVisible(!formerContactsVisible)
  };

  const { activeContacts, formerContacts } = contacts;

  return (
    <div className={styles.wrapper} data-testid='viewCompanyContacts'>
      {
        <>
          <div className={styles.header}>
            <span className={styles.title}>Contacts</span>
            <ul>
              <li><i className='fa fa-plus' onClick={() => setAddContactModalOpened(true)}></i></li>
            </ul>
          </div>
          {!!activeContacts.length && renderContacts(activeContacts)}
          {!activeContacts.length && 'No contacts associated with company'}
        </>
      }
      {!!formerContacts.length && renderToggleFormerContacts()}
      {!!formerContacts.length && formerContactsVisible && renderContacts(formerContacts)}

      <AddContactModal
        onSubmit={onAfterAddContact}
        isAddToCompany={true}
        open={addContactModalOpened}
        contact={{ defaultAccess: false }}
        preAffiliations={[companyId]}
        isQuickAdd={true}
        onClose={() => setAddContactModalOpened(!addContactModalOpened)}
      />
    </div>
  );
};
