
import React from 'react';
import { TextEditorField } from '../../Common/TextEditorField';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  addTermsConditionAction,
  getTermsConditionAction
} from '../../../actions/Admin/Account.actions.js';
import { appConstants } from '../../../_constants';
import AdminSidebar from '../../Admin/Inc/AdminSideBar.js';
import TitleComponent from '../../Common/TitleComponent';
import loaderImage from '../../static/images/loading_spinner.gif';
import { callAlertMessage } from '../../../components/Auth/Auth';

// eslint-disable-next-line no-undef
let Validator = require('validatorjs');
let styleData = { color: 'red' };
let formArr = {};
let rules = {
  description: 'required',
};
let mess = {
  required: 'This field is required',
  alpha: 'This field may only contain letters',
  numeric: 'This field must be a number',
};

let validation = [];
validation = new Validator([], rules, mess);
validation.passes();
validation.fails();

class AdminTermsCondition extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contactRole: [],
      sessionToken: localStorage.getItem('sessionToken'),
      userId: localStorage.getItem('userId'),

      //companyName: localStorage.getItem('companyName'),
      formData: {},
      formArr: [],
      description: '',
      showProcessing: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.getTermCondition = this.getTermCondition.bind(this);
  }

  componentDidMount() {
    this.getTermCondition();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps && nextProps.isTermCondition && nextProps.isTermCondition.data) {
      this.setState({
        description: nextProps.isTermCondition.data.description,
        formData: nextProps.isTermCondition.data,
      });
    }

    if (
      nextProps.isAddTermConditionSuccess !== this.props.isAddTermConditionSuccess &&
      nextProps.isAddTermCondition &&
      nextProps.isAddTermCondition.success === 1
    ) {
      callAlertMessage('success', nextProps.message);
      this.getTermCondition();
      this.setState({ showProcessing: false, formArr: [] });
    }

    if (
      nextProps.isAddTermConditionSuccess !== this.props.isAddTermConditionSuccess &&
      nextProps.isAddTermCondition &&
      nextProps.isAddTermCondition.success === 0
    ) {
      callAlertMessage('error', nextProps.message);
      this.getTermCondition();
      this.setState({ showProcessing: false });
    }
  }

  getTermCondition() {
    let data = {};
    this.props.getTermsConditionAction(data);
  }

  handleChange(name, value) {
    let field = this.state.formData;
    field[name] = value;
    this.setState({
      formData: field,
      [name]: value,
    });
  }

  handleAddEditSubmit(e) {
    e.preventDefault();

    let formData = this.state.formData;

    formData['status'] = 'true';
    formData['trash'] = false;

    if (this.allValidate(false)) {
      this.props.addTermsConditionAction(formData);
      this.setState({ showProcessing: true });
    }
  }

  allValidate(check) {
    if (!check) {
      formArr = [];
      Object.keys(rules).forEach(function (key) {
        formArr[key] = 'TT';
      });
      this.setState({
        formArr,
      });
    }
    if (validation.passes()) {
      return 1;
    }
  }

  render() {
    const { formData, description, formArr } = this.state;

    validation = new Validator(formData, rules, appConstants.RULESMESSAGE);
    validation.passes();
    validation.fails();

    return (
      <div>
        <TitleComponent data={{ title: `Administration - Edit Terms & Conditions` }} />
        <div
          className='noteProcessing'
          style={{ display: this.state.showProcessing ? 'block' : 'none' }}
        >
          <img src={loaderImage} alt='Loading' />
        </div>
        <div className='headingbox'>
          <h2 className='heading2'>Modify Terms and Conditions</h2>
        </div>
        <div className='row'>
          <div className='col-md-12 sortable-table'>
            {/*  <div className={'alert alert-danger '+(this.state.errorUploading? 'd-none' : '')}>
                                            <strong>Error While uploading! </strong> Contact List.
                                        </div>
                                        <div className={'alert alert-success '+(this.state.successUploading?'d-none' : '')}>
                                           <strong>Contacts List !</strong> Uploaded successfully in system.
                                        </div>
                                        <input className="csv-input" type="file" name="files" id="files"   ref={(ref) => this.fileUpload = ref} />
                                        <Button className="btn btn-primary" onClick={ (e) => this.uploadContactCSV(e)}>Upload</Button>{' '}
                                        <Button className="btn btn-primary" onClick={this.cancelUpload}>Cancel</Button> */}

            <div className='form-group'>
             <TextEditorField
                title='Description'
                name='description'
                value={description}
                onChange={(e) => this.handleChange('description', e)}
              />
              <span style={styleData} className='error'>
                {formArr.description && validation.errors.first('description')}
              </span>
            </div>
          </div>
          <div className='col-md-12'>
            <button
              className='btn btn-primary'
              onClick={(e) => {
                this.handleAddEditSubmit(e);
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isTermCondition: state.account.isTermCondition,
    isTermConditionSuccess: state.account.isTermConditionSuccess,
    isTermConditionError: state.account.isTermConditionError,

    isAddTermCondition: state.account.isAddTermCondition,
    isAddTermConditionSuccess: state.account.isAddTermConditionSuccess,
    isAddTermConditionError: state.account.isAddTermConditionError,

    message: state.account.message,
    success: state.account.success,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    getTermsConditionAction,
    addTermsConditionAction,
  })(AdminTermsCondition)
);
