import { useForm, FormProvider } from 'react-hook-form';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import FormInput from '../../../shared/form-controls/form-input/form-input';
import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../shared/hooks/use-app-dispatch';
import {
  addUserSettings,
  disableUserSetting,
  enableUserSetting,
  getUserSettings,
  resetUserSetting,
  updateUserSettings,
} from '../../../modules/settings';
import { useSubmit } from '../../../shared/hooks/use-submit';
import FormCheckbox from '../../../shared/form-controls/form-checkbox';
import FormSelect from '../../../shared/form-controls/form-select';

const lineItemTypesOptions = [
  { label: 'Inventory', value: 'Inventory' },
  { label: 'Service', value: 'Service' },
  { label: 'Non-Inventory', value: 'Non-Inventory' },
  { label: 'Group', value: 'Group' },
  //{ label: 'Other', value: 'other' },
];

export const EditSettingModal = ({
  open = false,
  onClose = () => {},
  onSubmit = (data) => {},
  settingId,
  jobScopes = [],
  initialValues = {},
}) => {
  const modelName = 'lineItemTypes';

  const isUpdate = !!settingId;
  const dispatch = useAppDispatch();

  const form = useForm({
    defaultValues: {
      name: initialValues.name || '',
      code: '',
      definition: '',
      type: 'Service',
      shortName: '',
      taxable: false,
      unitCost: 0,
      unitPrice: 0,
      unitMeasure: '',
      sku: '',
      defaultQuantity: 1,
      billable: true,
      qbItemId: '',
      qbCategory: '',
      jobScopeId: initialValues.jobScopeId || null,
    },
  });

  const [itemType] = form.watch(['type']);

  const [settingData, setSettingData] = useState({});

  useEffect(() => {
    if (!open) {
      form.reset();
      setSettingData({});
      return;
    }

    if (!settingData && !isUpdate) {
      form.reset(
        {
          name: initialValues.name || '',
          code: '',
          definition: '',
          type: 'Service',
          shortName: '',
          taxable: false,
          unitCost: 0,
          unitPrice: 0,
          unitMeasure: '',
          sku: '',
          defaultQuantity: 1,
          purchaseDescription: '',
          billable: true,
          qbItemId: '',
          qbCategory: '',
          jobScopeId: initialValues.jobScopeId || null,

          /*projectSection: '',
        projectStages: ['all'],
        isMultiAssignable: false,
        isLinkToBids: false,
        hideInitially: false,
        isVenderTypeRole: false,
        isClientTypeRole: false,
        isShowOnLeads: false,
        required: false,*/
        },
        { keepDefaultValues: true }
      );

      return;
    }

    if (settingId?.length>0&&settingId !== settingData._id) {
      dispatch(getUserSettings([modelName, settingId])).then(({payload:data}) => {
        setSettingData(data);
        form.reset(
          {
            name: data.name,
            code: data.code,
            definition: data.definition,
            shortName: data.shortName,
            type: data.type,
            taxable: data.taxable,
            unitCost: data.unitCost,
            unitPrice: data.unitPrice,
            unitMeasure: data.unitMeasure,
            sku: data.sku,
            defaultQuantity: data.defaultQuantity,
            purchaseDescription: data.purchaseDescription,
            billable: data.billable,
            qbItemId: data.qbItemId,
            qbCategory: data.qbCategory,
            jobScopeId: data.jobScopeId,
            //projectSection: settingData.projectSection,
            //projectStages: settingData.projectStages,
            //isMultiAssignable: settingData.isMultiAssignable,
            //isLinkToBids: settingData.isLinkToBids,
            //hideInitially: settingData.hideInitially,
            //isVenderTypeRole: settingData.isVenderTypeRole,
            //isClientTypeRole: settingData.isClientTypeRole,
            //isShowOnLeads: settingData.isShowOnLeads,
            //required: settingData.required,
          },
          { keepDefaultValues: true }
        );
      });
    }
  }, [isUpdate, open, settingId]);

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = form.handleSubmit(async (data) => {
    if (isUpdate) {
      await dispatch(updateUserSettings([modelName, { ...data, _id: settingId }])).unwrap();
    } else {
      await dispatch(addUserSettings([modelName, { ...data, _id: settingId }])).unwrap();
    }

    onSubmit(data);
    handleClose();
  });

  const [handleToggle, toggleLoading] = useSubmit(async () => {
    if (settingData?.trash) {
      await dispatch(enableUserSetting([modelName, settingId])).unwrap();
    } else {
      await dispatch(disableUserSetting([modelName, settingId])).unwrap();
    }
    onSubmit();
  }, [settingData]);

  const [handleReset, resetLoading] = useSubmit(async () => {
    await dispatch(resetUserSetting([modelName, settingId])).unwrap();

    onSubmit();
  }, [settingId]);

  const [fieldName] = form.watch(['name']);

  const [shortName] = form.watch(['shortName']);

  useEffect(() => {
    // set short name and item code if not update
    if (!isUpdate && fieldName !== shortName) {
      const autoCodeName =
        fieldName.toUpperCase().replace(/[^A-Z0-9]/g, '') +
        ':' +
        Math.random().toString(36).substr(2, 9);
      form.setValue('shortName', fieldName);
      form.setValue('code', autoCodeName);
    }
  }, [fieldName]);
  //const [projectSectionOptions, setProjectSectionOptions] = useState([]);

  /*useEffect(() => {
    if (itemType === 'inventory') {
      form.setValue('projectSection', 'internal-roles');
      setProjectSectionOptions(
        projectSectionsForRoles.filter((section) => section.value !== 'external-roles')
      );
    } else if (itemType === 'external') {
      form.setValue('projectSection', 'external-roles');
      setProjectSectionOptions(
        projectSectionsForRoles.filter((section) => section.value !== 'internal-roles')
      );
    }
  }, [itemType, open]);*/

  return (
    <Modal isOpen={open} toggle={onClose} size='lg'>
      <ModalHeader toggle={onClose}>{isUpdate ? 'Edit' : 'Add'} Line item</ModalHeader>
      <ModalBody>
        <FormProvider {...form}>
          <div className='container'>
            <div className='row'>
              <div className='col-6'>
                <FormInput label='Item name' name='name' required />
              </div>

              <div className='col-md-6'>
                <FormSelect
                  required
                  disabled={isUpdate}
                  label='Item type'
                  options={lineItemTypesOptions}
                  name='type'
                />
              </div>

              {/*<div className='col-6'>
                <FormInput
                  label='Item Code'
                  name='code'
                  required
                  disabled={isUpdate}
                  initialValue={autoCodeName}
                />
              </div>*/}
            </div>

            <div className=''>
              {/*<div className='col-md-6'>

                <FormInput
                  label='Short Name (Report Headings)'
                  name='shortName'
                  initialValue={fieldName}
                />
              </div>*/}

              {/*<div className='col-md-6'>
                <FormCheckbox
                  name='isMultiAssignable'
                  label='All multiple assignments to this role'
                  className='mb-0 d-flex flex-row-reverse justify-content-end'
                />
  </div>*/}
            </div>

            <div className='row'>
              <div className='col-12'>
                <FormInput label='Item description' name='definition' textArea />
              </div>
            </div>

            {/*}
            <div className='row'>
              <div className='col-12'>
                <FormInput label='Purchase description' name='purchaseDescription' textArea />
              </div>
            </div>
              */}
            <label className='form-label mt-4'>Item default values</label>
            <div className='ms-2'>
              

              <div className='row'>
                <div className='col-md-4'>
                  <FormInput label='Unit cost (your cost)' name='unitCost' type='number' />
                </div>

                <div className='col-md-4'>
                  <FormInput label='Unit price' name='unitPrice' type='number' />
                </div>
                <div className='col-md-3 mt-4'>
                  <FormCheckbox
                    name='taxable'
                    label='Taxable'
                    className='mb-0 d-flex flex-row-reverse justify-content-end'
                  />
                </div>

                <div className='col-md-4'>
                  <FormInput label='Default quantity' name='defaultQuantity' type='number' />
                </div>
                <div className='col-md-4'>
                  <FormInput label='Unit of measure' name='unitMeasure' />
                </div>
                
                <div className='col-md-4'>
                  <FormInput label='SKU' name='sku' />
                </div>
                
              </div>

              <div className='row mt-2'>
                <div className='col-md-6'>
                  <FormSelect
                    label='Associated job scope'
                    name='jobScopeId'
                    options={[
                      { value: '', label: 'Select job scope' },
                      ...jobScopes.map((scope) => ({
                        value: scope._id,
                        label: scope.jobScopeName,
                      })),
                    ]}
                  />
                </div>
              </div>

              <div className='row'>
                
                {/*<div className='col-md-3'>
                  <FormCheckbox
                    name='billable'
                    label='Billable'
                    className='mb-0 d-flex flex-row-reverse justify-content-end'
                  />
                </div>*/}
              </div>
            </div>
            {/*
            <label className='form-label mt-4'>Quickbooks settings</label>
            <div className='ms-2'>
              <div className='row'>
                <div className='col-md-6'>
                  <FormInput label='QuickBooks Item ID' name='qbItemId' disabled />
                </div>
                <div className='col-md-6'>
                  <FormInput label='QuickBooks Category' name='qbCategory' disabled />
                </div>
              </div>
            </div>*/}

            {
              /*}
            <div className='row'>
              <div className='col-6'>
                <FormMultiselect name='projectStages' required label='Show in Stages' options={projectStageOptions} />
              </div>

              <div className='col-6'>
                <FormSelect name='projectSection'  required disabled label='Section' options={projectSectionOptions} />
              </div>
            </div>
            </div>

           
            <div className='row'>

            <div className='row mb-2'>

            <div className='col-md-6'>
                <FormCheckbox
                  name='hideInitially'
                  label='Always show in "View More"'
                  className='mb-0 d-flex flex-row-reverse justify-content-end'
                />
              </div>

              <div className='col-md-6'>
                <FormCheckbox
                  name='required'
                  label='Require role on projects'
                  className='mb-0 d-flex flex-row-reverse justify-content-end'
                />
              </div>
             
              <div className='col-md-6'>
                <FormCheckbox
                  name='isLinkToBids'
                  label='Show track/role on bids'
                  className='mb-0 d-flex flex-row-reverse justify-content-end'
                />
              </div>
            </div>
            </div>

          
            {itemType === 'external' && (
              <>
              <label className='form-label mt-2'>Synchronization Options</label>
              <div className='row mb-2'>
                <div className='col-md-6'>
                  <FormCheckbox
                    name='isVenderTypeRole'
                    label='Vender-Type Role'
                    className='mb-0 d-flex flex-row-reverse justify-content-end'
                  />
                </div>
                <div className='col-md-6'>
                  <FormCheckbox
                    name='isClientTypeRole'
                    label='Client-Type Role'
                    className='mb-0 d-flex flex-row-reverse justify-content-end'
                  />
                </div>
              </div>
              </>
            )}
                   */ <></>
            }
          </div>
        </FormProvider>
      </ModalBody>
      <ModalFooter>
        {isUpdate && !!settingData?.isLocalCopy && (
          <button className='btn btn-primary me-auto' onClick={handleReset} disabled={resetLoading}>
            Reset to default
          </button>
        )}

        <button className='btn btn-primary' onClick={onClose}>
          Cancel
        </button>
        {isUpdate && (
          <button className='btn btn-primary' onClick={handleToggle} disabled={toggleLoading}>
            {settingData.trash ? 'Enable' : 'Disable'}
          </button>
        )}
        <button
          className='btn btn-primary'
          onClick={handleSubmit}
          disabled={form.formState.isSubmitting}
        >
          Save
        </button>
      </ModalFooter>
    </Modal>
  );
};
