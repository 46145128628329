import { formatPhoneForCall } from '../../../utils/formatters';

export const formatAffiliation = (o: any, allowLink = false) => {
  const contactId = o.contactId;
  const companyName = o.companyId ? o?.companyName || 'N/A' : '';

  const contactName = o.contactId
    ? `${o?.contactFullName || ''}`.trim() || 'N/A'
    : o.__isNew__
    ? `${o.label}`
    : '';

  const email = o.primaryEmail?.email || '';

  const phone = formatPhoneForCall(o.primaryPhone || {}) || '';

  const contactUrl = o.contactId?.length>0 ? 'contact-details/' + o.contactId :'';
  const companyUrl = o.companyId?.length>0 ? 'company-detail/' + o.companyId :'';

  return (
    <div className='d-flex flex-column'>
      <div className='d-flex gap-2'>
        {companyName && (
          <small className='text-truncate' onClick={() => allowLink && window.open(companyUrl, '_blank')}>
            <span className='fa fa-building fa-outline me-1' />
            {companyName}
          </small>
        )}
        {contactName && (
          <small className='text-truncate' onClick={() => allowLink && window.open(contactUrl, '_blank')}>
            <span className='fa fa-user fa-outline me-1 ' />
            {contactName}
          </small>
        )}
        {!companyName && contactId && (
          <small className='text-truncate'>
            <i>(Personal)</i>
          </small>
        )}
      </div>

      <div className='d-flex gap-2'>
        {email && (
          <small className='text-truncate'>
            <span className='fa fa-envelope fa-outline me-1 ' />
            {email}
          </small>
        )}
        {phone && (
          <small className='text-truncate'>
            <span className='fa fa-phone fa-outline me-1' />
            {phone}
          </small>
        )}
      </div>
    </div>
  );
};
