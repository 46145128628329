import axios from 'axios';
import { appConstants } from '../_constants';

export const request = (path, data, method) => {
  return axios({
    method: method,
    url: `${appConstants.WEB_SERVICE_URL}/${path}`,
    headers: {
      crossDomain: true,
      'X-Parse-Application-Id': appConstants.PARSE_APPLICATION_ID,
      'X-Parse-REST-API-Key': appConstants.PARSE_REST_API_KEY,
      authorization: 'Key@123',
    },
    data: data,
  });
};

export const requestWithoutParse = (path, data, method) => {
  return axios({
    method: method,
    url: `${appConstants.WEB_SERVICE_URL_WITHOUT_PARSE}/${path}`,
    headers: {
      crossDomain: true,
      'X-Parse-Application-Id': appConstants.PARSE_APPLICATION_ID,
      'X-Parse-REST-API-Key': appConstants.PARSE_REST_API_KEY,
      authorization: 'Key@123',
    },
    data: data,
  });
};

export const requestWithoutParseData = (path, data, method) => {
  return axios({
    method: method,
    url: `${appConstants.WEB_SERVICE_URL_WITHOUT_PARSE}/${path}`,
    headers: {
      crossDomain: true,
      'X-Parse-Application-Id': appConstants.PARSE_APPLICATION_ID,
      'X-Parse-REST-API-Key': appConstants.PARSE_REST_API_KEY,
    },
    data: data,
  });
};

export const postRequestWithoutParseData = (path, data) =>
  requestWithoutParseData(path, data, 'POST');
export const postRequestWithoutSession = (path, data) => request(path, data, 'POST');
export const postRequestWithoutParse = (path, data) => requestWithoutParse(path, data, 'POST');
