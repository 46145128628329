import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { callAlertMessage } from '../../components/Auth/Auth';
import { buildDefaultActionResult } from '../../utils/redux';
import { createUpdateBidEstimateRequest, viewAllBidEstimatesRequest, getCurrentBidEstimateRequest } from './api';

export const createUpdateBidEstimateAsync = createAsyncThunk(
  'bids/createUpdateBidEstimateAsync',
  async (data) => {
    const {
      data: { result },
    } = await createUpdateBidEstimateRequest(data);

    callAlertMessage(result.success === 1 ? 'success' : 'error', result.msg);

    return result.data ?? {};
  }
);

export const viewAllBidEstimatesAsync = createAsyncThunk(
  'bids/viewAllBidEstimatesAsync',
  async (projectId) => {
    const {
      data: { result },
    } = await viewAllBidEstimatesRequest(projectId);

    if (result.success === 0) {
      callAlertMessage('error', result.msg);
    }

    return result.data ?? [];
  }
);

export const getCurrentBidEstimateAsync = createAsyncThunk(
  'bids/getCurrentBidEstimateAsync',
  async (data) => {
    const {
      data: { result },
    } = await getCurrentBidEstimateRequest(data);

    if (result.success === 0) {
      callAlertMessage('error', result.msg);
    }

    return result.data ?? [];
  }
);

export const bidsSlice = createSlice({
  name: 'bids',
  initialState: {
    createUpdateBidEstimate: {
      data: {},
      loading: false,
      error: null,
    },
    viewAllBidEstimates: {
      data: [],
      loading: false,
      error: null,
    },
    getCurrentBidEstimate: {
      data: [],
      loading: false,
      error: null,
    },
  },
  extraReducers: (builder) => {
    buildDefaultActionResult('createUpdateBidEstimate', createUpdateBidEstimateAsync, builder);

    buildDefaultActionResult('viewAllBidEstimates', viewAllBidEstimatesAsync, builder);

    buildDefaultActionResult('getCurrentBidEstimate', getCurrentBidEstimateAsync, builder);
  },
});

export default bidsSlice.reducer;
