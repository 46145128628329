import React, { useState, useEffect, useCallback } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { getAcronymCode } from '../../../../utils/contact-formatters';
import { formatBidDueDate, formatDate } from '../../../../utils/date-formatters';
import { getAllProjectRoleAction } from '../../../../actions/projectRole';
import { Link, withRouter } from 'react-router-dom';
import { useDispatch, useSelector, connect } from 'react-redux';
import CustomTable from '../../../Common/CustomTable';

const MissingDataWidgetTable = (props) => {
  const [accountId] = useState(localStorage.getItem('accountId'));
  const [list, setList] = useState(props.componentData?.missingData || []);
  const [missingType, setMissingType] = useState(props.componentData?.type || 0);
  const [modal, setModal] = useState(false);
  const [showProcessing, setShowProcessing] = useState(false);
  const [projectRoleTypes, setProjectRoleTypes] = useState([]);
  const dispatch = useDispatch();
  const [selectedDateName, setSelectedDateName] = useState('');

  const projectRoles = useSelector((state) => state.projectRoleType);

  const toggle = useCallback(() => {
    setModal(!modal);
    if (modal) {
      props.onClickClose();
    }
  }, [modal, props]);

  useEffect(() => {
    toggle();
    dispatch(
      getAllProjectRoleAction({
        className: 'projectRoleTypes',
        accountId,
      })
    );
  }, [accountId]);

  useEffect(() => {
    setShowProcessing(false);
    if (projectRoles?.allProjectRoleTypes) {
      setProjectRoleTypes(projectRoles.allProjectRoleTypes);
    }
  }, [projectRoles]);

  useEffect(() => {
    let selectedDate = '';
    if (missingType === '1' || missingType === '2') {
      selectedDate = 'Bid Date';
    } else if (missingType === '3' || missingType === '4') {
      selectedDate = 'Start Date';
    } else if (missingType === '7') {
      selectedDate = 'Start Date/End Date';
    } else if (missingType === '8') {
      selectedDate = 'End Date';
    }

    setSelectedDateName(selectedDate);

  }, [missingType]);

  useEffect(() => {
    setList(props.componentData?.missingData || []);
  }, [props.componentData?.missingData]);

  const onGetDate = (cell, row) => {
    if ((missingType === '1' || missingType === '2') && row?.bidDue) {
      return formatBidDueDate(row.bidDue) || '-';
    } else if ((missingType === '3' || missingType === '4') && row?.startDate) {
      return formatDate(row.startDate.iso) || '-';
    } else if (missingType === '7' && (row?.startDate || row?.endDate)) {
      return `${formatDate(row.startDate.iso)}<br/>${formatDate(row.endDate.iso)}` || '-';
    } else if (missingType === '8' && row?.endDate) {
      return formatDate(row.endDate.iso) || '-';
    }
    return '-';
  };

  const showAccountExecStatus = (cell, row) => {
    const accountExecArr = row?.projectAssignments.filter(project => project.projectRoleCode === 'AX');
    return renderProjectAssignments(accountExecArr);
  };

  const showEstimatorStatus = (cell, row) => {
    const estimatorArr = row?.projectAssignments.filter(project => project.projectRoleCode === 'EST');
    return renderProjectAssignments(estimatorArr);
  };

  const showClientStatus = (cell, row) => {
    const clientArr = row?.projectAssignments.filter(project => project.projectRoleCode === 'CL');
    return renderProjectAssignments(clientArr);
  };

  const renderProjectAssignments = (assignments) => {
    if (assignments && assignments.length > 0) {
      return (
        <div>
          {assignments.map((item, i) => {
            const comma = i !== 0 ? ', ' : '';
            return (
              <Link key={i} to={{ pathname: item.isCompany ? `/company-details/${item.companyId}` : `/contact-details/${item.contactId}` }}>
                {comma}{getAcronymCode(item.isCompany ? item.displayNameWithCompany : item.displayName)}
              </Link>
            );
          })}
        </div>
      );
    }
    return <span>-</span>;
  };

  const redirectToProject = (cell, row) => {
    const path = row ? `/project-details/${row.objectId}` : '';
    return (
      <Link to={{ pathname: path, state: { row } }}>
        {cell === 'jobNum' ? (row?.jobNumFormatted??'') || '-' : (row?.jobName??'') || '-'}
      </Link>
    );
  };


  const rolesLoaded = projectRoleTypes.length > 0;

  const axNameShort = (rolesLoaded && projectRoleTypes.find(role => role.code === 'AX')?.shortName) || 'Acc. Exec';
  const estNameShort = (rolesLoaded && projectRoleTypes.find(role => role.code === 'EST')?.shortName) || 'Estimator';
  const clientNameShort = (rolesLoaded && projectRoleTypes.find(role => role.code === 'CL')?.shortName) || 'Client';

  const columns = [
    { id: 'Date', Header: selectedDateName, accessor: 'eventDate', Cell: (props) => <>{onGetDate(props.column.id, props.row.original)}</> },
    { id: 'Job Num.', Header: 'Job Num.', accessor: 'jobNumFormatted', Cell: (props) => <>{redirectToProject('jobNum', props.row.original)}</> },
    { id: 'Job Name', Header: 'Job Name', accessor: 'jobName', Cell: (props) => <>{redirectToProject('jobName', props.row.original)}</> },
    { id: axNameShort, Header: axNameShort, accessor: 'projectAssignments', Cell: (props) => <>{showAccountExecStatus(props.column.id, props.row.original)}</> },
    { id: estNameShort, Header: estNameShort, accessor: 'projectAssignments', Cell: (props) => <>{showEstimatorStatus(props.column.id, props.row.original)}</> },
    { id: clientNameShort, Header: clientNameShort, accessor: 'projectAssignments', Cell: (props) => <>{showClientStatus(props.column.id, props.row.original)}</> },
  ];

  return (
    <div className='modalBoxDataPopup'>
      <Modal
        backdrop='static'
        keyboard
        isOpen={modal}
        toggle={toggle}
        className={props.className}
      >
        <ModalHeader toggle={toggle}>Check for Missing Data</ModalHeader>
        <ModalBody>
          <div className='container'>
            <div className='row'>
              <div className='col-md-12'>
                <div className='table-responsive reportTableSection'>
                <CustomTable
                    columns={columns.filter(Boolean)}
                    list={list}
                    inlineEdit={false}
                    canShowExport={false}
                    canShowPagination={true}
                    canShowGlobalFilter={false}
                    canShowColumnSettings={false}
                    canShowDateFilter={false}
                    canShowRowSelection={false}
                    useControlledState={(state) => ({ ...state })}
                  />
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button className='btn btn-primary' onClick={toggle}>
            Cancel
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default withRouter(connect(null, { getAllProjectRoleAction })(MissingDataWidgetTable));
