export const selectImportProjectsParse = (state) => state.rtk.projects.importProjectsParse;

export const selectImportProjectsLoad = (state) => state.rtk.projects.importProjectsLoad;

export const selectGetTemporaryProjects = (state) => state.rtk.projects.getTemporaryProjects;

export const selectJobStatusesDropdown = (state) => state.rtk.projects.jobStatusesDropdown;

export const selectBuildingTypesDropdown = (state) => state.rtk.projects.buildingTypesDropdown;

export const selectJobScopesDropdown = (state) => state.rtk.projects.jobScopesDropdown;

export const selectOccupancyTypesDropdown = (state) => state.rtk.projects.occupancyTypesDropdown;

export const selectNegotiatingMethodsDropdown = (state) =>
  state.rtk.projects.negotiatingMethodsDropdown;

export const selectSaveProject = (state) => state.rtk.projects.saveProject;

export const selectGetLastJobNumber = (state) => state.rtk.projects.getLastJobNumber;

export const selectRemoveTemporaryProject = (state) => state.rtk.projects.removeTemporaryProject;

export const selectValidateProjectName = (state) => state.rtk.projects.validateProjectName;

export const selectGetNextProjectNumber = (state) => state.rtk.projects.getNextProjectNumber;

export const selectCurrentProject = (state) => state.projectData??{};
