import joi from 'joi';
import { joiAnyString } from '../../utils/validation';

export const saveCompanySchema = joi.object({
  companyId: joiAnyString().label('Company Id'),
  companyName: joi.string().required().trim().label('Company Name'),
  companyWebsite: joiAnyString().label('Company Website'),
  taxId: joiAnyString().label('Tax Id'),
  dba: joiAnyString().label('DBA/Display Name'),
  companyTags: joi.array().label('companyTags'),
  emailArr: joi
    .array()
    .items(
      joi.object({
        _id: joi.allow(),
        email: joi.string().email({ tlds: false }).trim().allow('', null).empty('').label('Email'),
        isPrimary: joi.boolean().required().failover(false),
        label: joiAnyString().label('Label'),
      })
    )
    // filter elements with no empty email
    .custom((value) => {
      const filtered = value.filter((item) => item.email);

      if (!filtered.length) return [];

      if (!filtered.find((item: any) => item.isPrimary)) {
        filtered[0].isPrimary = true;
      }

      return filtered;
    }),
  addressArr: joi
    .array()
    .items(
      joi.object({
        _id: joi.allow(),
        address1: joiAnyString().label('Address 1'),
        address2: joiAnyString().label('Address 2'),
        city: joiAnyString().label('City'),
        state: joiAnyString().label('State'),
        zipCode: joiAnyString().label('Zip Code'),
        country: joiAnyString().label('Country'),
        isPrimary: joi.boolean().required().failover(false),
        label: joiAnyString().label('Label'),
      })
    )
    // filter elements with no empty address
    .custom((value) => {
      const filtered = value.filter(
        (item) =>
          item.address1 || item.address2 || item.city || item.state || item.zipCode || item.country
      );


      if (!filtered.length) return [];

      if (!filtered.find((item: any) => item.isPrimary)) {
        filtered[0].isPrimary = true;
      }

      return filtered;
    }),
  phoneArr: joi
    .array()
    .items(
      joi.object({
        _id: joi.allow(),
        phone: joiAnyString().label('Phone Number'),
        type: joiAnyString().label('Phone Type'),
        ext: joiAnyString().label('Country Code'),
        extPhone: joiAnyString().label('Extension Phone Number'),
        isPrimary: joi.boolean().required().failover(false),
        country: joiAnyString().label('Country'),
        fullPhone: joiAnyString().label('Full Phone'),
        phoneClean: joiAnyString().label('Cleaned Phone Number'),
      })
    )
    // filter elements with no empty phone
    .custom((value) => {
      const filtered = value.filter((item) => item.phone || item.extPhone);

      if (!filtered.length) return [];

      if (!filtered.find((item: any) => item.isPrimary)) {
        filtered[0].isPrimary = true;
      }


      return filtered;
    }),
});

