import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { appConstants } from '../../../_constants';
import { forgotPasswordAction } from '../../../actions/Admin/user.actions.js';
import TitleComponent from  "../../Common/TitleComponent";
import { callAlertMessage } from '../../../components/Auth/Auth';


// eslint-disable-next-line no-undef
let Validator = require('validatorjs');
let styleData = { color: 'red' };
let formArr = {};
let rules = {
  email: 'required|email',
};
let mess = {
  required: 'This field is required',
  alpha: 'This field may only contain letters',
  numeric: 'This field must be a number',
  email: 'This field must be a valid email address.',
};
let validation = [];
validation = new Validator([], rules, appConstants.RULESMESSAGE);

validation.passes();
validation.fails();

class ForgotPasswordPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      sessionToken: localStorage.getItem('sessionToken'),
      userId: localStorage.getItem('userId'),
      formArr: [],
      formData: {},
    };
    this.handleChange = this.handleChange.bind(this);
  }

  UNSAFE_componentWillMount() {
    require('./login.css');
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { useAuth, isForgotPwdSuccess } = this.props;
    if (
      nextProps.isForgotPwdSuccess !== isForgotPwdSuccess &&
      nextProps.isForgotPwd.success === 1
    ) {
      callAlertMessage('success', nextProps.message);
      this.setState({ formData: { email: '' }, formArr: [] });
    }

    if (
      nextProps.isForgotPwdSuccess !== isForgotPwdSuccess &&
      nextProps.isForgotPwd.success === 0
    ) {
      callAlertMessage('error', nextProps.message);
    }
  }

  handleChange = (e) => {
    e.preventDefault();
    let field = this.state.formData;
    field[e.target.name] = e.target.value;
    this.setState({ formData: field });
  };

  handleSubmit(e) {
    e.preventDefault();
    let { formData } = this.state;
    const urlData = window.location.href.split('/');
    let url = '';

    if (urlData) {
      url = urlData[0] + urlData[1] + '//' + urlData[2] + '/admin/set-new-password';
    }

    if (this.allValidate(false)) {
      formData['url'] = url;
      this.props.forgotPasswordAction(formData);
    }
  }

  allValidate(check) {
    if (!check) {
      formArr = [];
      Object.keys(rules).forEach(function (key) {
        formArr[key] = 'TT';
      });
      this.setState({
        formArr,
      });
    }
    if (validation.passes()) {
      return 1;
    }
  }

  render() {
    const { formData } = this.state;

    validation = new Validator(this.state.formData, rules, mess);
    validation.passes();
    validation.fails();

    return (
      <div className="container">
        <TitleComponent data={{title:`Administration Reset Password`}}/>
        <h1 className="form-heading">login Form</h1>
        <div className="login-form">
          <div className="main-div">
            <div className="panel">
              <h2>Forgot Password</h2>
            </div>
            <form name="form" onSubmit={this.handleSubmit}>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  name="email"
                  id="email"
                  placeholder="Email*"
                  value={formData.email}
                  onChange={(e) => {
                    this.handleChange(e);
                  }}
                />
                <span style={styleData} className="error">
                  {this.state.formArr.email && validation.errors.first('email')}
                </span>
              </div>
              <button
                type="button"
                className="btn btn-primary"
                onClick={(e) => this.handleSubmit(e)}
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isForgotPwd: state.user.isForgotPwd,
    isForgotPwdSuccess: state.user.isForgotPwdSuccess,
    isForgotPwdError: state.user.isForgotPwdError,

    success: state.user.success,
    message: state.user.message,
  };
}

export default withRouter(connect(mapStateToProps, { forgotPasswordAction })(ForgotPasswordPage));
