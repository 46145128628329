import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import AdminSidebar from '../../components/Admin/Inc/AdminSideBar.js';

const handleAuthentication = () => {
  let user_info = JSON.parse(localStorage.getItem('userInfo'));

  return (
    localStorage.getItem('baseUserRole') === 'SUPERADMIN' &&
    user_info &&
    (user_info.username === 'superadmin@commandtech.io' ||
      user_info.username === 'superadmin+staging@commandtech.io')
  );
};

const AdminLayout = ({ children }) => {
  const history = useHistory();
  useEffect(() => {
    if (handleAuthentication()) return;

    history.replace('/admin/access-denied');
  }, []);

  return (
    <div className='wrapper'>
      <div className='d-flex'>
      <AdminSidebar />
      <div>{/* <img src={loaderImage} alt="Loading" /> */}</div>
      <div id='content-SuperAdmin' className='tab-content' role='tablist'>
        <div id='pane-1' className='pane' role='tabpanel' aria-labelledby='tab-1'>
          <div id='collapse-1' className='' role='tabpanel' aria-labelledby='heading-1'>
            <div className='cardBody'>
              <div className='contain-back'>{children}</div>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
  );
};

export default AdminLayout;
