import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import renderHTML from 'html-react-parser';
import { withRouter } from 'react-router-dom';
import { getAllProjectRoleAction } from '../../../actions/projectRole';
import { formatExternalTeamContact } from '../../../utils/contact-formatters';
import loaderImage1 from '../../static/images/M_loading.gif';
import { moduleConstants, isAllowed } from '../../../_constants';

function ProjectTeam(props) {
  const { userId } = localStorage.getItem('userId');
  const { accountId } = localStorage.getItem('accountId');

  useEffect(() => {
    props.getAllProjectRoleAction({
      className: 'projectRoleTypes',
      accountId: accountId,
      includeDisabled: true,
    });
  }, []);

  const [projectData, setProjectData] = useState({});
  const [allProjectRoleTypes, setAllProjectRoleTypes] = useState([]);
  const [isHideFields, setIsHideFields] = useState(false);

  useEffect(() => {
    if (props.projectData?.objectId) {
      const projectData = props.projectData;
      setProjectData(projectData);
    }
  }, [props.projectData]);

  useEffect(() => {
    setIsHideFields(props.isHideFields);
  }, [props.isHideFields]);

  useEffect(() => {
    if (props.allProjectRoleTypes?.length > 0) {
      const allProjectRoleTypes = props.allProjectRoleTypes ?? [];
      setAllProjectRoleTypes(allProjectRoleTypes);
    } else {
      setAllProjectRoleTypes([]);
    }
  }, [props.allProjectRoleTypes]);

  const { isLead, assignedProjectToPeopleList } = props;

  const teamInfoFetchReq = props.fetchRequest;

  const enabledExternalProjectRoles = allProjectRoleTypes
    ?.filter((role) => !role.isSystemMaster && !role.trash && role.type === 'external')
    ?.filter((role) => (isLead ? role.isShowOnLeads : true));

  const allExternalProjectRoles =
    allProjectRoleTypes?.filter((role) => !role.isSystemMaster && role.type === 'external') ?? [];

  const projectAssignments = assignedProjectToPeopleList ?? [];

  const enabledProjRoles = enabledExternalProjectRoles || [];
  const enabledProjectRoleIds = enabledProjRoles?.map((assignment) => assignment.objectId);
  const presentProjectRoleIds = projectAssignments?.map((assignment) => assignment.projectRoleId);
  const enabledAndPresentProjectRoleIds = [
    ...new Set(presentProjectRoleIds.concat(enabledProjectRoleIds)),
  ];

  const displayProjectRoles =
    allExternalProjectRoles?.filter((role) =>
      enabledAndPresentProjectRoleIds.includes(role.objectId)
    ) ?? [];

  const isCreator = projectData?.createdBy?.objectId === userId;
  const canEditDetails =
    isAllowed(
      moduleConstants.EDITPROJDETAILS,
      isCreator ? 'anyAssigned' : 'assigned',
      projectData?.userAssignData
    ) &&
    isAllowed(
      moduleConstants.ASSIGNEXTERNAL,
      isCreator ? 'anyAssigned' : 'assigned',
      projectData?.userAssignData
    );

  return (
    <div>
      <div className='pDetail'>
        <h6>External Project Team</h6>
        {teamInfoFetchReq && (
          <img className='ajax-loader' src={loaderImage1} width='200' height='200' />
        )}
        {!teamInfoFetchReq && (
          <div className='pDetailTableBox'>
            {displayProjectRoles?.map((role) => {

              const roleAssignments = projectAssignments.filter(
                (item) =>
                  item.projectRoleId === role.objectId ||
                  (role.name.toLowerCase() === item.userRole.toLowerCase() && role.type !== 'internal')
              );

              if(roleAssignments.length === 0 && isHideFields) return null;

              let numRolesDisplayed = 0;
              return (
                <div key={role.objectId} className='row'>
                  <div className='col-lg-3 col-md-4 col-sm-5 col-xs-6 mb-1' >

                    {role.name}:
                  </div>
                  <div className='col-lg-9 col-md-8 col-sm-7 col-xs-6  mb-1'>
                    {roleAssignments?.length > 0 &&
                      roleAssignments.map((item) => {
                       
                          numRolesDisplayed = numRolesDisplayed + 1;
                          return renderHTML(formatExternalTeamContact(item) + '<br/>');
                        
                      })}
                    {numRolesDisplayed === 0 && <>-</>}
                  </div>
                </div>
              );
            })}
          </div>
        )}
        {canEditDetails && (
          <a className='editBtn' onClick={() => props.onOpenEditModal('external-team')}>
            <i className='fa fa-pencil' aria-hidden='true'></i>
          </a>
        )}
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    projectData: state.project.projectData,
    assignedProjectToPeopleList: state.assignProjectToPeople.assignedProjectToPeopleList,
    fetchRequest: state.project.fetchRequest,
    allProjectRoleTypes: state.projectRoleType.allProjectRoleTypes,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    getAllProjectRoleAction,
  })(ProjectTeam)
);
