import { memo, useCallback, useState } from 'react';
import { CustomFieldControl } from '../../shared/custom-fields-controls/form-controls/custom-field-control';
import { HorizontalLayout } from '../../shared/form-controls/control-layouts/horizontal-layout';

export const ContactCustomAdditionalFields = memo(({ fields = [], index, name }: any) => {
  const showMoreLabel = 'Add more additional information';

  const [showMore, setShowMore] = useState<boolean>(false);

  const shouldBeHidden = useCallback(
    (field: any) => {
      const { hideInitially, required } = field;

      if (required) return false;

      return hideInitially;
    },
    [fields]
  );

  const hiddenFields = fields.filter(shouldBeHidden);
  const notHiddenFields = fields.filter((field: any) => !shouldBeHidden(field));

  const shouldShowMore = hiddenFields.length > 0;

  const renderList = useCallback(
    (fields: any[] = []) =>
      fields.map((field: any) => {
        const { _id, required } = field;
        const CustomizedLayout = (props: any) => (
          <HorizontalLayout {...props} labelWidth={3} controlWidth={8} />
        );

        return (
          <CustomFieldControl
            Layout={CustomizedLayout}
            key={`${name}.${index}.${_id}`}
            required={required}
            fieldDefinition={field}
            prefix={`${name}.${index}`}
          />
        );
      }),
    [index]
  );

  return (
    <>
      {renderList(notHiddenFields)}
      {shouldShowMore && !showMore && <a onClick={() => setShowMore((s) => !s)}>{showMoreLabel}</a>}
      {showMore && renderList(hiddenFields)}
    </>
  );
});
