import { routerReducer } from 'react-router-redux';
import company from './company';
import user from './user';
import contact from './contact';
import contactList from './contactList';
import contractType from './contractType';
import registerCompany from './registerCompany';
import occupancyType from './occupancyType';
import buildingType from './buildingType';
import jobScope from './jobScope';
import reasonCode from './reasonCode';
import jobStatusCode from './jobStatusCode';
import negotiatingMethod from './negotiatingMethod';
import companyTypes from './companyTypes';
import sidebar from './sidebar';
import projectTasks from './projectTasks';
import project from './project';
import profile from './profile';
import contactRole from './contactRole';
import projectStatus from './projectStatus';
import projectRoleType from './projectRole';
import multipleContact from './multipleContact';
import message from './message';
import changeOrder from './changeOrder';
import moduleType from './moduleType';
import assignModule from './assignModule';
import assignProjectToPeople from './assignProjectToPeople';
import cppRole from './cppRole';
import reports from './reports';
import home from './home';
import accountSettings from './accountSettings';
import search from './search';
import customFields from './customFields';
import help from './help';
import account from './account';
import dashboard from './widget';
import notificationPreferences from './notificationPreferences';
import projectFields from './projectFields';
import contactFields from './contactFields';

const reducer = {
  router: routerReducer,
  company,
  user,
  contact,
  contactList,
  contractType,
  registerCompany,
  occupancyType,
  buildingType,
  jobScope,
  reasonCode,
  jobStatusCode,
  negotiatingMethod,
  projectRoleType,
  projectFields,
  contactFields,
  companyTypes,
  sidebar,
  projectTasks,
  project,
  profile,
  contactRole,
  projectStatus,
  multipleContact,
  message,
  changeOrder,
  moduleType,
  assignModule,
  assignProjectToPeople,
  cppRole,
  reports,
  home,
  accountSettings,
  search,
  customFields,
  help,
  account,
  dashboard,
  notificationPreferences,
};

export default reducer;
