export const getRoleName = (roles: Array<any>, roleId: string) => {
  if (!roles || !roleId) {
    return 'None';
  }

  return roles?.find((r) => r._id === roleId)?.name || 'No name';
};

export const getAssignmentName = (data: any) => {
  const { contactId, companyId, isAccountAffiliation, isInternal, contactFirstName, contactLastName, companyName } = data;
  const contactName = `${contactFirstName || ''} ${contactLastName || ''}`.trim() || 'N/A';

  if (companyId && contactId && !isAccountAffiliation && !isInternal) {
    return `${companyName} - ${contactName}`;
  }

  if (contactId) {
    return contactName;
  }

  if (companyId) {
    return companyName;
  }

  return '';
};

export const getDragMessage = (data: any, isValid: boolean) => {
  const fullName = getAssignmentName(data);
  const isUnassigned = data.id === 'unassigned';

  if (isValid) {
    return isUnassigned ? 'Unassigning' : `Assigning to ${fullName}`;
  }

  return isUnassigned ? 'Already Unassigned' : `Already assigned to ${fullName}`;
};

export const onBeforeEventDropFinalize = (data: any) => {
  const { context, source } = data;
  const { newResource, resourceRecord } = context as any;
  const store = source.resourceStore;
  const draggedRecord = store.findRecord('id', resourceRecord.id);

  // determine where user dragged event onto
  const isGroupRoot = !!newResource.parent?.isRoot;
  const targetRecord = isGroupRoot // if user dragged event onto group header
    ? newResource.children[0] // drop as first child
    : store.findRecord('id', newResource.id); // drop before user's taraget

  if (draggedRecord && targetRecord) {
    store.move(draggedRecord, targetRecord); // move resource
  }else if(draggedRecord&&isGroupRoot&&!targetRecord){
    newResource.appendChild(draggedRecord);
  }

  context.valid = false; // but do not allow to move event itself
};
