
import React, { Suspense, useEffect, useMemo, useState } from 'react';
import ReactLoading from 'react-loading';

import { withTranslation } from 'react-i18next';
import { connect, useSelector } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import {Tooltip} from 'react-tooltip';
//import { deleteContactAction, getAllContactAction } from '../../actions/contact';
import '../../index.css';
import AddContactModal from '../../modals/add-contact-modal/add-contact-modal';
import { viewContacts } from '../../modules/contacts';
import { getViewContacts } from '../../modules/contacts/selectors';
import store from '../../modules/store';
import RemotePagination from '../../shared/data-grid/remote-pagination/remote-pagination.jsx';
import SearchComponent from '../../shared/data-grid/search-component';
import useBasicRowSelection from '../../shared/data-grid/use-basic-row-selection/use-basic-row-selection.jsx';
import usePagination from '../../shared/data-grid/use-pagination';
import useSorting, { headerWithSort } from '../../shared/data-grid/use-sorting/use-sorting.jsx';
import FormattedPhoneArr from '../../shared/formatted-phone/formatted-phone-arr';
import { appConstants, isAllowed, moduleConstants, reportAccess } from '../../_constants';
import {useAuth, UpperCaseName} from '../Auth/Auth';
import CustomTable from '../Common/CustomTable';
import TitleComponent from '../Common/TitleComponent';
import Footer from '../Layout/Footer.js';
import Header from '../Layout/Header.js';
import FormattedPhone from '../../shared/formatted-phone/formatted-phone';



const ContactsTable = ({ onContactSelect = () => { }, trigger }) => {
  const { data: { total, contacts }, loading } = useSelector(getViewContacts);

  const pagination = usePagination({ total });

  const { limit, offset, setPage } = pagination;

  const rowSelection = useBasicRowSelection({ limit, data: contacts, keyField: '_id' });

  const { getSelectedKeys } = rowSelection;

  const sortingState = useSorting('firstName', 'asc');

  const { sortingField, sortingOrder } = sortingState;

  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    if (getSelectedKeys.length > 0)
      onContactSelect(getSelectedKeys());
  }, [getSelectedKeys]);

  useEffect(() => {
    store.dispatch(viewContacts({ limit, offset, sortingField, sortingOrder, searchValue }));
  }, [limit, offset, trigger, sortingField, sortingOrder, searchValue]);

  useEffect(() => {
    setPage(1);
  }, [searchValue, limit, trigger]);

  const ellipsis = {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  }

  const cellItemView = (data, type) => {
    const { email = '', phoneArr = [], emailArr = [], companyName = '' } = data;

    const emailValue = emailArr.length > 0
      ? emailArr[0].email
      : email;

    const hasPhone = !!(phoneArr?.length > 0);
    const hasEmail = !!emailValue;
    const hasCompanyName = !!companyName;

    const isInfoVisible = hasPhone || hasEmail || hasCompanyName;

    if (!isInfoVisible) return;

    switch (type) {
      case 'Phone':
        return hasPhone ? <FormattedPhone phoneObject={phoneArr[0]}></FormattedPhone> : <br />;
      case 'Email':
        return hasEmail ? <a href={'mailto:' + emailValue}>{emailValue}</a> : <br />;
      default:
        return hasCompanyName ? `${companyName}` : `(personal)`;
    }
  }

  const cellView = (data, type) => {
    const { affiliations = [] } = data;

    return (
      <div className='display-flex'>

        <div style={ellipsis}>{cellItemView(data, type)}</div>

        {affiliations.map((item) =>
          <div key={item._id} style={ellipsis}>{cellItemView(item, type)}</div>
        )}

      </div>
    )
  }

  const columns = useMemo(
    () => [
      {
        Header: headerWithSort('Name', 'firstName'),
        accessor: 'firstName',
        Cell: ({ row }) => (
          <Link to={{ pathname: `/contact-details/` + row.original._id }}>
            {row.original.firstName} {row.original.lastName}
          </Link>
        ),
        width: 128,
        disableSortBy: true,
      },
      {
        Header: 'Company',
        accessor: 'affiliations',
        width: 128,
        disableSortBy: true,
        Cell: ({ row }) => cellView(row.original, 'Company'),
      },
      {
        Header: 'Email',
        accessor: 'email',
        width: 128,
        disableSortBy: true,
        Cell: ({ row }) => cellView(row.original, 'Email'),
      },
      {
        Header: 'Phone',
        accessor: 'phoneArr',
        width: 128,
        disableSortBy: true,
        Cell: ({ row }) => cellView(row.original, 'Phone'),
      },
    ],
    [sortingState]
  );

  return (
    <>
      <SearchComponent onSearchSubmit={setSearchValue} />
      <CustomTable
        columns={columns}
        list={contacts}
        inlineEdit={false}
        canShowExport={false}
        canShowPagination={false}
        canShowGlobalFilter={false}
        canShowColumnSettings={false}
        canShowDateFilter={false}
        canShowRowSelection={false}
        isLoading={loading}
        useControlledState={(state) => ({ ...state, ...rowSelection, ...sortingState })}
      />
      <RemotePagination {...pagination} />
    </>
  );
};

// TODO: component needs refactoring
class ContactsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      phone: '',
      type: '',
      ext: '',
      customFields: [{ label: '', value: '', key: '' }],
      sessionToken: localStorage.getItem('sessionToken'),
      accountId: localStorage.getItem('accountId'),
      userId: localStorage.getItem('userId'),
      registeredCompanyName: localStorage.getItem('companyName'),
      openPopUpState: '',
      list: [],
      venderName: {},
      formData: { isEmployee: false, defaultAccess: false },
      //modal: false,
      companyModal: false,
      errorUploading: true,
      successUploading: true,
      alert: {},
      formArr: [],
      optionTemplateProjectStateList: [],
      companyData: {},
      disabledSelect: true,
      companyArr: {},
      selectBox: {},
      contact: {},
      userType: [],
      allCppRole: [],
      EmployeeType: [],
      phoneArr: [],
      phoneArr1: [],
      fetchRequest: true,
      optionTitle: [],
      optionNumberCode: [],
      venderArr: [],
      successRedirect: false,
      isContactCreated: false,
      companySubmitted: false,
      isfetched: false,
      contactModal: false,
      totalCount: 0,
      currentPage: 1,
      sizePerPage: appConstants.TableSizePerPageList[2].value,
      searchText: '',
      selectedRows: [],
      selectedContacts: [],
      trigger: false,

      addContactModalOpened: false,
    };

    this.importAddContact = this.importAddContact.bind(this);
    this.onEmail = this.onEmail.bind(this);
    this.onChangeRowSelection = this.onChangeRowSelection.bind(this);
  }

  UNSAFE_componentWillMount() {
    let optionTemplateProjectStateList = [];
    appConstants.STATE.map((v) => {
      if (v.objectId !== '' && v.username !== '') {
        optionTemplateProjectStateList.push({
          value: v.abbreviation,
          label: v.abbreviation,
        });
      }
      this.setState({ optionTemplateProjectStateList });
      return true;
    });

    let optionTitle = [];
    appConstants.NumberTypeOption.map((v) => {
      if (v.objectId !== '' && v.username !== '') {
        optionTitle.push({ value: v.name, label: v.name });
      }
      this.setState({ optionTitle });
      return true;
    });

    let optionNumberCode = [];
    appConstants.CountryCodeOption.map((v) => {
      if (v.objectId !== '' && v.username !== '') {
        optionNumberCode.push({ value: v.name, label: v.name });
      }
      this.setState({ optionNumberCode });
      return true;
    });
  }

  componentDidMount() {
    if (this.props?.location?.state?.search && this.props?.location?.state?.type === 'CONTACT') {
      this.setState({ searchText: this.props.location.state.search });
      this.list(this.props.location.state.search);
    } else {
      this.list();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps?.location?.state?.search) {
      if (
        nextProps.location.state.search !== this.props.location.state.search &&
        nextProps.location.state.type === 'CONTACT'
      ) {
        this.setState({ searchText: nextProps.location.state.search });
        this.list(nextProps.location.state.search);
      }
    }

    let listData = [];
    /*if (nextProps?.allContact?.length > 0) {
      let i = 0;
      nextProps.allContact.map((v) => {
        if (v.contactsData) {
          listData.push(v.contactsData);
        }
        if (v.contactsData && v.contactsData.isEmployee === true) {
          listData[i].venderData = this.state.registeredCompanyName;
        }
        i++;
        return true;
      });

      this.setState({
        list: listData,
        fetchRequest: false,
        totalCount: nextProps.count,
      });
    } else {
      this.setState({ list: [], totalCount: 0 });
    }

    if (nextProps.allContact && nextProps.allContact.length == 0) {
      let _this = this;
      setTimeout(function () {
        _this.setState({ fetchRequest: false });
      }, 2000);
    }*/

    let _this = this;

    if (
      nextProps.success === 1 &&
      nextProps.isContactCreated !== this.props.isContactCreated &&
      nextProps.isContactCreated === true
    ) {
      _this.setState({
        isContactCreated: true,
        fetchRequest: true,
        contactModal: !_this.state.contactModal,
      });
      _this.list();
    }

    if (
      nextProps.isContactDeleted !== this.state.isContactCreated &&
      nextProps.isContactDeleted === true
    ) {
      _this.setState({
        isContactCreated: true,
        fetchRequest: true,
      });
    }
  }

  onChangeRowSelection = (ids) => {
    this.setState({ selectedRows: ids });
  };

  shouldComponentUpdate(prevProps, nextProps) {
    if (prevProps.location.state && prevProps.location.state.rand !== this.state.openPopUpState) {
      if (
        prevProps.location.state.openPopUp === 'CONTACT' &&
        (prevProps.history.action === 'PUSH' || prevProps.history.action === 'REPLACE')
      ) {
        this.importAddContact('POST');
      }
      this.setState({ openPopUpState: prevProps.location.state.rand });

      return true;
    }

    return true;
  }

  list = (searchData) => {
    this.setState({ sizePerPage: appConstants.TableSizePerPageList[2].value });
    let data = {};
    data['accountId'] = this.state.accountId;
    data['page'] = 0;
    data['displayLimit'] = 9999999999;
    data['search'] = searchData ? searchData : this.state.searchText;
  };

  importAddContact = (val) => {
    if (val === 'importContact') {
      this.props.history.push({
        pathname: '/import-contacts',
        state: { redirectOn: '/view-contacts' },
      });
    }
    this.setState({
      addContactModalOpened: true,
    });
  };

  handleDeleteButtonClick = async () => {
    var retVal = window.confirm('Do you really want to delete the selected record?');
    if (retVal === true) {
      let data = {};
      const tifOptions = this.state.selectedContacts.map((key) => {
        return { objectId: key };
      });
      data['accountId'] = this.state.accountId;
      data['dataArr'] = tifOptions;
      await this.props.deleteContactAction(data);

      this.setState({
        trigger: !this.state.trigger,
        successRedirect: true,
        isContactCreated: false,
        formArr: [],
      });
    }
  };

  cellButton(cell, row) {
    return (
      <Link to={{ pathname: `/contact-details/` + row.objectId, state: { row } }}>
        {row.firstName} {row.lastName}
      </Link>
    );
  }

  companyName(cell, row) {
    if (row.isEmployee === true && row.venderData) {
      return <div>{row.venderData !== '' && row.venderData ? row.venderData : '---'} </div>;
    }

    if (row.isEmployee === false) {
      let multipleComp = '';
      if (row.venderArr && row.venderArr.length > 0) {
        row.venderArr.map((item) => {
          if (multipleComp !== '') {
            multipleComp = multipleComp + ', ';
          }
          multipleComp = multipleComp + item.label;
          return true;
        });
        return <div>{multipleComp}</div>;
      } else {
        return <div>{'---'}</div>;
      }
    }
  }

  onEmail(cell, row, enumObject, rowIndex) {
    if (row) {
      return <a href={'mailto:' + row.email}>{row.email ? row.email : '-'}</a>;
    } else {
      return <div>-</div>;
    }
  }

  onContactPhone(cell, row, enumObject, rowIndex) {
    let phn = '';
    if (row && row.phoneArr && row.phoneArr.length > 0) {
      row.phoneArr.map((item) => {
        if (phn !== '') {
          phn = phn + '<br/>';
        }
        phn = phn + (item.ext ? '(' + item.ext + ')' : '') + ' ' + item.phone;
        return true;
      });
      return <div dangerouslySetInnerHTML={{ __html: phn }} />;
    } else {
      return <span>-</span>;
    }
  }

  _setTableOption() {
    if (this.state.fetchRequest) {
      return (
        <ReactLoading
          className='table-loader'
          type={appConstants.LOADER_TYPE}
          color={appConstants.LOADER_COLOR}
          height={appConstants.LOADER_HEIGHT}
          width={appConstants.LOADER_WIDTH}
        />
      );
    } else {
      return 'No data found..';
    }
  }

  onPageChange = (page, sizePerPage) => {
    this.setState({ fetchRequest: true });
    let data = {};
    data['accountId'] = this.state.accountId;
    if (this.state.searchText === '') {
      data['page'] = page === -1 ? 0 : page - 1;
      data['displayLimit'] = sizePerPage;
    } else {
      data['page'] = page === -1 ? 0 : page - 1;
      data['displayLimit'] = sizePerPage;
      data['search'] = this.state.searchText;
    }

    this.setState({ sizePerPage: sizePerPage });
    this.setState({ currentPage: page });
  };

  onSearchChange = (text, sizePerPage) => {
    text = text ? UpperCaseName(text.trim()) : '';
    this.setState({ fetchRequest: true });
    let data = {};
    data['accountId'] = this.state.accountId;
    data['page'] = 0;
    data['displayLimit'] = this.state.sizePerPage;
    if (text !== '') {
      // if (text.length >= 3) {
      data['search'] = text;
      this.setState({ sizePerPage: 50 });
      //}
    }

    this.setState({ searchText: text });
  };

  handleAddContactModalSubmit = (affiliations) => {
    affiliations?.[0]?.contactId &&
      this.props.history.push(`/contact-details/${affiliations[0].contactId}`);
  };

  render() {
    let t = this.props.t;
    return (
      <>
        <div className='ContactListReport'>
          <TitleComponent data={{ title: 'Contacts' }} />
          <Suspense fallback='loading'>
            <Header props={this.props} />
          </Suspense>
          <div className='contactBox'>
            <div className='container'>
              <div className='row'>
                <div>
                  <div className='container'>
                    <div id='content' className='tab-content' role='tablist'>
                      <div id='pane-1' className='pane' role='tabpanel' aria-labelledby='tab-1'>
                        <div
                          id='collapse-1'
                          className=''
                          role='tabpanel'
                          aria-labelledby='heading-1'
                        >
                          <div className='cardBody'>
                            <div className='d-flex'>
                              <div className='p-2 '>
                                <h2 className='heading2'>Contacts</h2>
                              </div>
                              <div className='ms-auto'></div>

                              <div className='p-2 no-print'>
                                <button
                                  className='btn btn-primary'
                                  style={{
                                    // temporary hidden
                                    cursor: 'pointer', padding: '5px', display: 'none'
                                  }}
                                  disabled={this.state.selectedContacts?.length < 1}
                                  onClick={() => this.handleDeleteButtonClick()}
                                >
                                  <i className='fa fa-trash' aria-hidden='true'></i>&nbsp;Delete
                                </button>
                              </div>
                              {isAllowed(moduleConstants.IMPORTCONTACTS) && (
                                <div className='p-2'>
                                  <button
                                    onClick={() => this.importAddContact('importContact')}
                                    className='btn btn-primary addContactBtn'
                                  >
                                    Import
                                  </button>
                                </div>)}

                              {isAllowed(moduleConstants.EC) && (
                                <>
                                  <div className='p-2'>
                                    <button
                                      className='btn btn-primary addContactBtn'
                                      onClick={() =>
                                        this.setState({
                                          addContactModalOpened: true,
                                        })
                                      }
                                    >
                                      Add Contact
                                    </button>
                                  </div>
                                </>
                              )}
                            

                            { (
                              <div className='p-2 detailMenu'>
                                <li className='x'>
                                  <a
                                    className='dropdown-toggle btn'
                                    data-bs-toggle='dropdown'
                                    href='#'
                                    role='button'
                                    aria-expanded='false'
                                  >
                                    <i className='fa fa-ellipsis-v' aria-hidden='true'></i>
                                  </a>
                                  <ul className='dropdown-menu dropdown-menu-end'>
                                    {isAllowed(reportAccess.CLP, 'anyAssigned') && (
                                      <li className='dropdown-item nav-item'>
                                        <Link
                                          className=''
                                          to={{
                                            pathname: `/contact-list-report`,
                                            state: { ...this.props.rowData },
                                          }}
                                          data-tooltip-content={t('MENU1.SUBMENUS.SUBMENU3.Tooltip')}
                                          data-tooltip-id='MENU1.SUBMENUS.SUBMENU3.Tooltip'
                                        >
                                          {t('Contact List Report')}
                                        </Link>
                                        <Tooltip
                                          id='MENU1.SUBMENUS.SUBMENU3.Tooltip'
                                          place='right'
                                          delayShow={1000}
                                        />
                                      </li>
                                    )}
                                  </ul>
                                </li></div>)}
                                </div>
                            <div className='col-md-12 sortable-table'>
                              <ContactsTable
                                trigger={this.state.trigger}
                                onContactSelect={(selectedContacts) =>
                                  this.setState({ selectedContacts })
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />

          {/*add contact popup*/}

          <AddContactModal
            open={this.state.addContactModalOpened}
            contact={{ defaultAccess: false }}
            onSubmit={this.handleAddContactModalSubmit}
            onClose={() =>
              this.setState({
                addContactModalOpened: false,
              })
            }
          />
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    //allContact: state.contactList.allContacts,
    count: state.contact && state.contact.count ? state.contact.count : [],
    contact: state.contact,
    companies: state.company.response,
    companySubmitted: state.company.submitted,
    success: state.contact.success,
    message: state.contact.message,
    isContactCreated: state.contact.isContactCreated,
    isContactDeleted: state.contact.isContactDeleted,
    state: state,
  };
}

export default withTranslation()(withRouter(
  connect(mapStateToProps, {
    //getAllContactAction,
    //deleteContactAction,
  })(ContactsList))
);
