import * as actionTypes from './actionTypes';
import * as API from '../api/message';

import { callAlertMessage } from '../components/Auth/Auth';

export const createNoteRequest = () => ({
  type: actionTypes.CREATE_NOTE_REQUEST,
});

export const createNoteSuccess = (response) => ({
  type: actionTypes.CREATE_NOTE_SUCCESS,
  payload: {
    response,
  },
});

export const createNoteError = (error) => ({
  type: actionTypes.CREATE_NOTE_ERROR,
  payload: {
    error,
  },
});

export const createNoteAction = (data, props) => {
  return (dispatch) => {
    dispatch(createNoteRequest());
    return API.createNote(data)
      .then((response) => {
        let data = response.data.result;
        let message = data.success
          ? data.data.isDraft
            ? 'Note Successfully Saved!'
            : 'Note Successfully Saved!'
          : data.msg;
          callAlertMessage(data.success === 1 ? 'success' : 'error', message);
        dispatch(createNoteSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          createNoteError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};


export const pinNoteRequest = () => ({
  type: actionTypes.PIN_NOTE_REQUEST,
});

export const pinNoteSuccess = (response) => ({
  type: actionTypes.PIN_NOTE_SUCCESS,
  payload: {
    response,
  },
});

export const pinNoteError = (error) => ({
  type: actionTypes.PIN_NOTE_ERROR,
  payload: {
    error,
  },
});


export const pinNoteAction = async (data) => {
  await API.pinNote(data);
};


export const getAllNotesRequest = () => ({
  type: actionTypes.GET_ALL_NOTES_REQUEST,
});

export const getAllNotesSuccess = (response) => ({
  type: actionTypes.GET_ALL_NOTES_SUCCESS,
  payload: {
    response,
  },
});

export const getAllNotesError = (error) => ({
  type: actionTypes.GET_ALL_NOTES_ERROR,
  payload: {
    error,
  },
});

export const getAllNotesAction = (data) => {
  return (dispatch) => {
    dispatch(getAllNotesRequest());
    return API.getAllNotes(data)
      .then((response) => {
        dispatch(getAllNotesSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          getAllNotesError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

export const deleteNotesRequest = () => ({
  type: actionTypes.DELETE_NOTES_REQUEST,
});

export const deleteNotesSuccess = (response) => ({
  type: actionTypes.DELETE_NOTES_SUCCESS,
  payload: {
    response,
  },
});

export const deleteNotesError = (error) => ({
  type: actionTypes.DELETE_NOTES_ERROR,
  payload: {
    error,
  },
});

export const deleteNotesAction = (data) => {
  return (dispatch) => {
    dispatch(deleteNotesRequest());
    return API.deleteNotes(data)
      .then((response) => {
        dispatch(deleteNotesSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          deleteNotesError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

export const createEmailRequest = () => ({
  type: actionTypes.CREATE_EMAIL_REQUEST,
});

export const createEmailSuccess = (response) => ({
  type: actionTypes.CREATE_EMAIL_SUCCESS,
  payload: {
    response,
  },
});

export const createEmailError = (error) => ({
  type: actionTypes.CREATE_EMAIL_ERROR,
  payload: {
    error,
  },
});

export const createEmailAction = (data, props) => {
  let tempProps = props;
  return (dispatch) => {
    dispatch(createEmailRequest());
    return API.createEmail(data)
      .then((response) => {
        let data = response.data.result;
        let message = data.success
          ? data.data.isDraft
            ? 'Email Successfully Saved!'
            : 'Email Successfully Sent!'
          : data.msg;
        callAlertMessage(data.success === 1 ? 'success' : 'error', message);
        dispatch(createEmailSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          createEmailError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

export const getAllEmailRequest = () => ({
  type: actionTypes.GET_ALL_EMAIL_REQUEST,
});

export const getAllEmailSuccess = (response) => ({
  type: actionTypes.GET_ALL_EMAIL_SUCCESS,
  payload: {
    response,
  },
});

export const getAllEmailError = (error) => ({
  type: actionTypes.GET_ALL_EMAIL_ERROR,
  payload: {
    error,
  },
});

export const getAllEmailAction = (data) => {
  return (dispatch) => {
    dispatch(getAllEmailRequest());
    return API.getAllEmail(data)
      .then((response) => {
        dispatch(getAllEmailSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          getAllEmailError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

export const deleteEmailRequest = () => ({
  type: actionTypes.DELETE_EMAIL_REQUEST,
});

export const deleteEmailSuccess = (response) => ({
  type: actionTypes.DELETE_EMAIL_SUCCESS,
  payload: {
    response,
  },
});

export const deleteEmailError = (error) => ({
  type: actionTypes.DELETE_EMAIL_ERROR,
  payload: {
    error,
  },
});

export const deleteEmailAction = (data) => {
  return (dispatch) => {
    dispatch(deleteEmailRequest());
    return API.deleteEmail(data)
      .then((response) => {
        dispatch(deleteEmailSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          deleteEmailError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

export const createScheduleRequest = () => ({
  type: actionTypes.CREATE_SCHEDULE_REQUEST,
});

export const createScheduleSuccess = (response) => ({
  type: actionTypes.CREATE_SCHEDULE_SUCCESS,
  payload: {
    response,
  },
});

export const createScheduleError = (error) => ({
  type: actionTypes.CREATE_SCHEDULE_ERROR,
  payload: {
    error,
  },
});

export const createScheduleAction = (data, props) => {

  return (dispatch) => {
    dispatch(createScheduleRequest());
    return API.createSchedule(data)
      .then((response) => {
        let data = response.data.result;
        let message = data.success
          ? data.data?.isDraft
            ? 'Schedule Successfully Sent!'
            : 'Schedule Successfully Sent!'
          : data.msg;
        callAlertMessage(data.success === 1 ? 'success' : 'error', message);
        dispatch(createScheduleSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          createScheduleError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

export const getAllScheduleRequest = () => ({
  type: actionTypes.GET_ALL_SCHEDULE_REQUEST,
});

export const getAllScheduleSuccess = (response) => ({
  type: actionTypes.GET_ALL_SCHEDULE_SUCCESS,
  payload: {
    response,
  },
});

export const getAllScheduleError = (error) => ({
  type: actionTypes.GET_ALL_SCHEDULE_ERROR,
  payload: {
    error,
  },
});

export const getAllScheduleAction = (data) => {
  return (dispatch) => {
    dispatch(getAllScheduleRequest());
    return API.getAllSchedule(data)
      .then((response) => {
        dispatch(getAllScheduleSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          getAllScheduleError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

export const getTimeLineRequest = () => ({
  type: actionTypes.GET_TIME_LINE_REQUEST,
});

export const getTimeLineSuccess = (response) => ({
  type: actionTypes.GET_TIME_LINE_SUCCESS,
  payload: {
    response,
  },
});

export const getTimeLineError = (error) => ({
  type: actionTypes.GET_TIME_LINE_ERROR,
  payload: {
    error,
  },
});

export const getTimeLineAction = (data) => {
  return (dispatch) => {
    dispatch(getTimeLineRequest());
    return API.getTimeLine(data)
      .then((response) => {
        dispatch(getTimeLineSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          getTimeLineError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

export const cancelScheduleAction = (data, props) => {
  let tempProps = props;
  return (dispatch) => {
    dispatch({ type: actionTypes.CANCEL_SCHEDULE_REQUEST });
    return API.cancelSchedule(data)
      .then((response) => {
        //debugger;
        callAlertMessage(
          response.data.result.success === 1 ? 'success' : 'error',
          response.data.result.msg
        );
        dispatch({
          type: actionTypes.CANCEL_SCHEDULE_SUCCESS,
          payload: { response },
        });
      })
      .catch((error) => {
        //debugger;
        dispatch(
          createScheduleError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};
