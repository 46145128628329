import React, { Suspense } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Header from '../../Layout/Header.js';
import Footer from '../../Layout/Footer.js';
import { connect } from 'react-redux';
import { appConstants } from '../../../_constants';
import {
  GetFollowUpProjectReportAction,
  GetFollowUpProjectReportPrefAction,
  SetFollowUpProjectReportPrefAction,
} from '../../../actions/reports';
import ReactLoading from 'react-loading';
import NotePopUp from '../../Common/NotePopUp';
import renderHTML from 'html-react-parser';
import { CustomTable, multiSelectFilter, customSortByKey } from '../../Common/CustomTable';
import { Tooltip } from 'react-tooltip';
import { SelectColumnFilter } from '../../Common/CustomTableComponents/SelectColumnFilter';
import { DateColumnFilter } from '../../Common/CustomTableComponents/DateColumnFilter';
import TitleComponent from '../../Common/TitleComponent';
import { matchDataForTable } from '../../../utils/match-for-table.jsx';
import { getAllProjectRoleAction } from '../../../actions/projectRole';
import {
  formatExternalContactData,
  formatInternalContactData,
} from '../../../utils/contact-formatters';
import {
  formatDate,
} from '../../../utils/date-formatters';
import { formatNote } from '../../../utils/note-formatters';
import { getAllProjectFieldsAction } from '../../../actions/projectFields';
import { isAllowed, moduleConstants } from '../../../_constants';
import {buildColumnForField, buildDynamicColumns, onCurrency, onDate} from '../../../utils/column-formatters';

var sortingOrder = '';
class FollowUpProjectReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {},
      accountId: localStorage.getItem('accountId'),
      userId: localStorage.getItem('userId'),
      list: [],
      selectBox: {},
      fetchRequest: true,
      comLogo: '',
      totalCount: 0,
      currentPage: 1,
      sizePerPage: appConstants.TableSizePerPageList[2].value,
      searchText: '',
      noteModal: false,
      noteData: '',
      sortType: 'DESC',
      sortKey: '',
      contactData: {},
      projectRoleTypes: [],
      allProjectFieldTypes: [],
      dynamicColumns: [],
    };

    this.onPageChange = this.onPageChange.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.getModalOpen = this.getModalOpen.bind(this);
    this.onNoteModalClose = this.onNoteModalClose.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps?.projectRoles?.allProjectRoleTypes) {
      this.setState({
        projectRoleTypes: nextProps.projectRoles.allProjectRoleTypes,
      });
    }

    if (nextProps?.projectFields?.allProjectFieldTypes?.length > 0) {
      this.setState({
        allProjectFieldTypes: nextProps.projectFields.allProjectFieldTypes,
      });
    }

    if (nextProps.listData && nextProps.listData.success && nextProps.listData.success === 1) {
      let fieldsToMatch = {
        projectAssignments: { lookupLocation: 'contactData' },
        scope: {
          lookupLocation: 'jobScope',
          collectionName: 'allJobScope',
          labelField: 'jobScopeName',
        },
      };
      let matchedDataForTable = matchDataForTable(
        fieldsToMatch,
        nextProps.listData.data,
        nextProps.listData.contactData,
        this.props
      );

      this.setState({
        totalCount: nextProps.listData.count,
        list: matchedDataForTable,
        fetchRequest: false,
        contactData: nextProps.listData.contactData,
      });
    }

    if (nextProps.listData && nextProps.listData.data && nextProps.listData.data.length == 0) {
      let _this = this;
      setTimeout(function () {
        _this.setState({ fetchRequest: false });
      }, 2000);
    }
  }

  componentDidMount() {
    this.setState({ sizePerPage: appConstants.TableSizePerPageList[2].value });
    let data = {};
    data['accountId'] = this.state.accountId;
    data['moduleName'] = appConstants.reportModuleName['26'];
    data['page'] = 0;
    data['displayLimit'] = this.state.sizePerPage;
    data['search'] = this.state.searchText;
    data['sortKey'] = this.state.sortKey;
    data['sortType'] = this.state.sortType;
    this.props.GetFollowUpProjectReportAction(data);

    this.props.getAllProjectRoleAction({
      className: 'projectRoleTypes',
      accountId: this.state.accountId,
    });

    this.props.getAllProjectFieldsAction({
      accountId: this.state.accountId,
      minimumStage: 'post-construction',
    });

    sortingOrder = '';

    if (
      localStorage.getItem('companyLogo') !== 'undefined' &&
      localStorage.getItem('companyLogo') !== null
    ) {
      this.setState({
        comLogo: localStorage.getItem('companyLogo'),
      });
    } else {
      let constImage = appConstants.CONTENT_CPOST_LOGO_URL;
      this.setState({ comLogo: constImage });
    }
    this.props.GetFollowUpProjectReportPrefAction();
  }

  redirectToProject(cell, enumObject, row, rowIndex) {
    if (cell === 'jobNumFormatted' && row) {
      return (
        <div>
          <Link
            to={{
              pathname: `/project-details/` + row.objectId,
              state: { row },
            }}
          >
            {row.jobNumFormatted ? row.jobNumFormatted : '-'}
          </Link>
        </div>
      );
    }

    if (cell === 'jobName' && row) {
      return (
        <div>
          <Link
            to={{
              pathname: `/project-details/` + row.objectId,
              state: { row },
            }}
          >
            {row.jobName ? row.jobName : '-'}
          </Link>
        </div>
      );
    }

    if (cell === 'altJobNum' && row) {
      return (
        <div>
          <Link
            to={{
              pathname: `/project-details/` + row.objectId,
              state: { row },
            }}
          >
            {row.altJobNum ? row.altJobNum : '-'}
          </Link>
        </div>
      );
    }
  }



  buildColumnForRole(filteredRoles, roleCode, hidden = false) {
    const foundRole = filteredRoles.find((role) => role.code == roleCode);
    if (!foundRole) return;
    const isInternal = foundRole && foundRole.type === 'internal';

    if (foundRole && isInternal) {
      return (
        foundRole && {
          Header: foundRole?.shortName ?? roleCode,
          LongHeader: foundRole?.name,
          accessor: 'projectAssignmentsLabel' + roleCode,
          sortType: customSortByKey,
          sortKey: foundRole?.name ?? roleCode,
          Filter: SelectColumnFilter,
          filter: multiSelectFilter,
          width: 60,
          hidden: hidden,
          Cell: (props) => (
            <>
              {this.formatContactDataInternal(
                'projectAssignmentsMatched' + roleCode,
                props.row.original
              )}
            </>
          ),
        }
      );
    } else {
      return (
        foundRole && {
          Header: foundRole?.shortName ?? roleCode,
          LongHeader: foundRole?.name,
          accessor: 'projectAssignmentsLabel' + roleCode,
          sortType: customSortByKey,
          sortKey: foundRole?.name ?? roleCode,
          Filter: SelectColumnFilter,
          filter: multiSelectFilter,
          width: 60,
          hidden: hidden,
          Cell: (props) => (
            <>
              {this.formatContactDataExternal(
                'projectAssignmentsMatched' + roleCode,
                props.row.original
              )}
            </>
          ),
        }
      );
    }
  }

  formatContactDataInternal(cell, row) {
    if (row[cell] && row[cell].length > 0) {
      return formatInternalContactData(row[cell], this.state.contactData);
    } else {
      return <span>-</span>;
    }
  }

  formatContactDataExternal(cell, row) {
    if (row[cell] && row[cell].length > 0) {
      return formatExternalContactData(row[cell], this.state.contactData, false);
    } else {
      return <span>-</span>;
    }
  }

  onJobStatus(cell, row, enumObject, rowIndex) {
    let data = row && row.jobStatusCodesCode ? row.jobStatusCodesCode : '-';
    return data;
  }

  onNoteDetail(cell, row, enumObject, rowIndex) {
    //return row && row.followUpNote ? row.followUpNote : '-';
    const notes = formatNote(row?.followUpNote);

    return notes ? (
      <a
        className='anchorNote '
        data-type={'reminder'}
        onClick={(e) => {
          this.getModalOpen(e, row);
        }}
      >
        {renderHTML(notes)}
      </a>
    ) : (
      '-'
    );
  }
  

  onNoteModalClose() {
    this.setState({ noteModal: false });
  }

  getModalOpen(e, row) {
    let noteText = row && row.followUpNote ? row.followUpNote : '-';
    let followUpNoteId = row && row.followUpNoteId ? row.followUpNoteId : null;
    this.setState({ noteModal: true, noteData: {objectId:followUpNoteId,description: noteText,typeId: row.objectId, typeRef: 'PROJECT'} });
  }

  createCustomButtonGroup = (props) => {
    let _this = this;

    return (
      <div className='pdfLogoBox'>
        <div className='reportHead'>
          <div className='logo'>
            <img src={this.state.comLogo} alt='Upload Logo' />
          </div>
          <h2>Project Follow-up Report</h2>
        </div>
      </div>
    );
  };

  _setTableOption() {
    if (this.state.fetchRequest) {
      return (
        <ReactLoading
          className='table-loader'
          type={appConstants.LOADER_TYPE}
          color={appConstants.LOADER_COLOR}
          height={appConstants.LOADER_HEIGHT}
          width={appConstants.LOADER_WIDTH}
        />
      );
    } else {
      return 'No data found..';
    }
  }

  onPageChange(page, sizePerPage) {
    this.setState({ fetchRequest: true });
    let data = {};
    data['accountId'] = this.state.accountId;
    data['moduleName'] = appConstants.reportModuleName['26'];
    // if (this.state.searchText === "") {
    //     data['page'] = (page === -1) ? 0 : page - 1;
    //     data['displayLimit'] = sizePerPage;
    // } else {
    data['page'] = page === -1 ? 0 : page - 1;
    data['displayLimit'] = sizePerPage;
    data['search'] = this.state.searchText;
    //}
    data['sortKey'] = this.state.sortKey;
    data['sortType'] = this.state.sortType;

    this.setState({ sizePerPage: sizePerPage });
    this.setState({ currentPage: page });
    this.props.GetFollowUpProjectReportAction(data);
  }

  onSearchChange(text, sizePerPage) {
    this.setState({ fetchRequest: true, list: [] });
    text = text ? text.trim() : '';
    if (text !== '') {
      let data = {};
      data['accountId'] = this.state.accountId;
      data['moduleName'] = appConstants.reportModuleName['26'];
      data['page'] = 0;
      data['search'] = text;
      data['displayLimit'] = this.state.sizePerPage;
      data['search'] = this.state.searchText;
      data['sortKey'] = this.state.sortKey;
      data['sortType'] = this.state.sortType;
      this.setState({ sizePerPage: 50 });
      this.setState({ searchText: text });
      this.props.GetFollowUpProjectReportAction(data);
    } else {
      this.componentDidMount();
      this.setState({ searchText: text });
    }
  }

  buildReportColumns() {
    const hasContractValPermissions = isAllowed(moduleConstants.VIEWCONTRACTVAL, 'anyAssigned');
    
    const { list, allProjectFieldTypes } = this.state;

    const dynamicFieldsLoaded = allProjectFieldTypes?.length > 0;

    const defaultFieldCodes = [];

    const customFieldColumns = dynamicFieldsLoaded
      ? buildDynamicColumns(
          allProjectFieldTypes.filter((field) => !defaultFieldCodes.includes(field.code))
        )
      : [];

    const defaultFields = dynamicFieldsLoaded
      ? allProjectFieldTypes.filter((field) => defaultFieldCodes.includes(field.code))
      : [];

    const isLeadType = false;

    const rolesLoaded = this.state.projectRoleTypes?.length > 0;

    const defaultRolesCodes = ['EST', 'PM'];

    const dynamicColumnsLoaded = dynamicFieldsLoaded && rolesLoaded;

    const enabledRoles = this.state.projectRoleTypes.filter(
      (role) => !defaultRolesCodes.includes(role.code) && (!isLeadType || role.isShowOnLeads)
    );
    const defaultRoles = this.state.projectRoleTypes.filter(
      (role) => defaultRolesCodes.includes(role.code) && (!isLeadType || role.isShowOnLeads)
    );

    const defaultColumns = [
      {
        Header: 'Job Num.',
        accessor: 'jobNumFormatted',
        sortType: 'basic',
        width: 80,
        Cell: (props) => (
          <>
            <span data-tooltip-content={props.value} data-tooltip-id={props.value}>
              {this.redirectToProject(props.column.id, props.value, props.row.original)}
            </span>
            <Tooltip id={props.value} delayShow={1000} place='bottom' />
          </>
        ),
      },
      {
        Header: 'Alt Job#',
        accessor: 'altJobNum',
        width: 60,
        Cell: (props) => this.redirectToProject(props.column.id, props.value, props.row.original),
      },
      {
        Header: 'Job Name',
        accessor: 'jobName',
        width: 255,
        Cell: (props) => this.redirectToProject(props.column.id, props.value, props.row.original),
      },
      {
        Header: 'Date Delivered',
        id: 'deliveredDate',
        accessor: (row) =>
          row?.deliveredDate?.iso
            ? new Date(row.deliveredDate.iso)
            : new Date(formatDate('1800-03-03T12:47:40.000Z')),
        width: 170,
        Cell: (props) => <>{onDate(props.column.id, props.row.original, props.value)}</>,
        filter: DateColumnFilter,
        allowDateRangeFilter: true,
        sortType: 'datetime',
      },
      {
        Header: 'Closeout Date',
        id: 'closeoutDate',
        accessor: (row) =>
          row?.closeoutDate?.iso
            ? new Date(row.closeoutDate.iso)
            : new Date(formatDate('1800-03-03T12:47:40.000Z')),
        width: 80,
        Cell: (props) => <>{onDate(props.column.id, props.row.original, props.value)}</>,
        filter: DateColumnFilter,
        allowDateRangeFilter: true,
        sortType: 'datetime',
      },
      {
        Header: 'End Date',
        accessor: (row) =>
          row?.endDate?.iso
            ? new Date(row.endDate.iso)
            : new Date(formatDate('1800-03-03T12:47:40.000Z')),
        width: 170,
        id: 'endDate',
        Cell: (props) => <>{onDate(props.column.id, props.row.original, props.value)}</>,
        filter: DateColumnFilter,
        hidden: true,
        allowDateRangeFilter: true,
        sortType: 'datetime',
      },
      hasContractValPermissions&&{
        Header: 'Final Contract',
        accessor: 'currentContractAmount',
        width: 120,
        Cell: (props) => (
          <>{onCurrency(props.column.id, props.row.original, props.value)}</>
        ),
      },
      this.buildColumnForRole(defaultRoles, 'EST'),
      this.buildColumnForRole(defaultRoles, 'PM'),
      {
        Header: 'Status',
        accessor: 'jobStatusCodesName',
        width: 60,
        Cell: (props) => <>{this.onJobStatus(props.column.id, props.row.original, props.value)}</>,
        Filter: SelectColumnFilter,
        filter: 'exact',
      },
      {
        Header: 'Follow-up Done',
        id: 'followUpDone',
        accessor: (row) =>
          row?.followUpDone?.iso
            ? new Date(row.followUpDone.iso)
            : new Date(formatDate('1800-03-03T12:47:40.000Z')),
        width: 100,
        Cell: (props) => <>{onDate(props.column.id, props.row.original, props.value)}</>,
        filter: DateColumnFilter,
        allowDateRangeFilter: true,
        sortType: 'datetime',
      },
      {
        Header: 'Follow-up note',
        accessor: 'followUpNote',
        width: 140,
        Cell: (props) => (
          <>
            <span data-tooltip-html={props.value} data-tooltip-id={props.value}>
              {this.onNoteDetail(props.column.id, props.row.original, props.value)}
            </span>
            <Tooltip id={props.value} delayShow={1000} place='bottom' />
          </>
        ),
      },
    ];

    let columns = [];
    if (enabledRoles) {
      const extraColumns = enabledRoles.map((role) =>
        this.buildColumnForRole([role], role.code, true)
      );

      if (columns.length == 0) {
        columns = defaultColumns.filter((cols) => cols?.accessor);
      }

      for (let i in extraColumns) {
        const curColumnsAccessors = columns.map((cols) => cols.accessor);
        if (!curColumnsAccessors.includes(extraColumns[i].accessor)) {
          columns = columns.concat(extraColumns[i]);
        }
      }

      for (let i in customFieldColumns) {
        const curColumnsAccessors = columns.map((cols) => cols.accessor);
        if (!curColumnsAccessors.includes(customFieldColumns[i].accessor)) {
          columns = columns.concat(customFieldColumns[i]);
        }
      }
    } else {
      columns = defaultColumns.filter((cols) => cols?.accessor);
    }

    
    this.setState({ dynamicColumns: columns });

  }

  
  render() {
  
    const {
      list, allProjectFieldTypes, dynamicColumns
    } = this.state;


    const dynamicFieldsLoaded = allProjectFieldTypes?.length > 0;

    const rolesLoaded = this.state.projectRoleTypes?.length > 0;

    const dynamicColumnsLoaded = dynamicFieldsLoaded && rolesLoaded;

    
    const dynamicColumnsBuilt = dynamicColumnsLoaded && dynamicColumns.length > 0;

    if(dynamicColumnsLoaded && !dynamicColumnsBuilt){
      this.buildReportColumns();
    }
    
    
    const { listPref, SetFollowUpProjectReportPrefAction } = this.props;

    return (
      <div>
        <div className='ContactListReport'>
          <TitleComponent data={{ title: `Follow-up Project Report` }} />
          <div>
            <Suspense fallback='loading'>
              <Header props={this.props} />
            </Suspense>
          </div>
          <div className='contactBox'>
            <div className='container'>
              <div className='row no-break-print'>
                {dynamicColumnsBuilt && (
                  <div className='col-md-12'>
                    <div
                      className='table-responsive reportTableSection'
                      ref={(el) => (this.componentRef = el)}
                    >
                      {this.createCustomButtonGroup()}
                      <CustomTable
                        columns={dynamicColumns}
                        list={list}
                        preferences={listPref}
                        className='bid-due-table'
                        customTableTitle='Project Follow-up Report'
                        onChangeRowSelection={this.onChangeRowSelection}
                        onSavePreferences={SetFollowUpProjectReportPrefAction}
                        isLoading={this.state.fetchRequest}
                        canShowRowSelection={false}
                        componentRef={this.componentRef}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {this.state.noteModal && (
            <NotePopUp
              isOpen={this.state.noteModal}
              onClick={this.onNoteModalClose}
              noteInfo={this.state.noteData}
            />
          )}
          <Footer />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    listData: state.reports.projectFollowUpList,
    listPref: state.reports.projectFollowUpListPref,
    projectRoles: state.projectRoleType,
    projectFields: state.projectFields,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    GetFollowUpProjectReportAction,
    GetFollowUpProjectReportPrefAction,
    SetFollowUpProjectReportPrefAction,
    getAllProjectRoleAction,
    getAllProjectFieldsAction
  })(FollowUpProjectReport)
);
