import * as actionTypes from '../../actions/Admin/actionTypes';
const initState = {
  response: [],
  message: '',
  success: '',

  isNotificationTypeList: [],
  isNotificationTypeListSuccess: false,

  isNotificationTypeAdd: false,
  isNotificationTypeAddSuccess: false,
  isNotificationTypeAddError: false,

  isNotificationTypeDisable: false,
  isNotificationTypeDisableSuccess: false,
  isNotificationTypeDisableError: false,
};

export default (state = initState, action = {}) => {
  switch (action.type) {
    //Add/edit widgets
    case actionTypes.CREATE_NOTIFICATION_TYPES_REQUEST:
      return {
        ...state,
        isNotificationTypeAdd: false,
        isNotificationTypeAddSuccess: false,
        isNotificationTypeAddError: false,
        message: '',
        success: '',
      };
    case actionTypes.CREATE_NOTIFICATION_TYPES_SUCCESS:
      return {
        ...state,
        isNotificationTypeAdd: action.payload.response.result,
        isNotificationTypeAddSuccess: true,
        isNotificationTypeAddError: false,
        success: action.payload.response.result.success,
        message: action.payload.response.result.msg
          ? action.payload.response.result.msg
          : 'Record Added successfully',
      };
    case actionTypes.CREATE_NOTIFICATION_TYPES_ERROR:
      return {
        ...state,
        isNotificationTypeAdd: false,
        isNotificationTypeAddSuccess: false,
        isNotificationTypeAddError: true,
        message: '',
        success: '',
      };

    //get all widget list
    case actionTypes.GET_ALL_NOTIFICATION_TYPES_REQUEST:
      return {
        ...state,
        isNotificationTypeList: [],
        isNotificationTypeListSuccess: false,
        message: '',
        success: '',
      };
    case actionTypes.GET_ALL_NOTIFICATION_TYPES_SUCCESS:
      return {
        ...state,
        isNotificationTypeList: action.payload.response.result,
        isNotificationTypeListSuccess: true,
        message: action.payload.response.result.success
          ? 'Record Added successfully'
          : action.payload.response.result.msg,
        success: action.payload.response.result.success,
      };
    case actionTypes.GET_ALL_NOTIFICATION_TYPES_ERROR:
      return {
        ...state,
        isNotificationTypeList: [],
        isNotificationTypeListSuccess: false,
        success: action.payload.error.success,
        message: action.payload.error.msg,
      };

    //Disable widget list
    case actionTypes.DISABLE_NOTIFICATION_TYPES_REQUEST:
      return {
        ...state,
        message: '',
        success: '',
        isNotificationTypeDisable: false,
        isNotificationTypeDisableSuccess: false,
        isNotificationTypeDisableError: false,
      };
    case actionTypes.DISABLE_NOTIFICATION_TYPES_SUCCESS:
      return {
        ...state,
        isNotificationTypeDisable: action.payload.response.result,
        isNotificationTypeDisableSuccess: true,
        isNotificationTypeDisableError: false,
        message: action.payload.response.result.success
          ? 'Record Disabled successfully'
          : action.payload.response.result.msg,
        success: action.payload.response.result.success,
      };
    case actionTypes.DISABLE_NOTIFICATION_TYPES_ERROR:
      return {
        ...state,
        isNotificationTypeDisable: false,
        isNotificationTypeDisableSuccess: false,
        isNotificationTypeDisableError: true,
        success: action.payload.error.success,
        message: action.payload.error.msg,
      };

    default:
      return state;
  }
};
