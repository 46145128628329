import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import FormInput from '../../../shared/form-controls/form-input';
import { HorizontalLayout } from '../../../shared/form-controls/control-layouts/horizontal-layout';
import { moduleConstants, isAllowed } from '../../../_constants';
import { TermsAndConditionsModal } from '../../../modals/terms-and-conditions-modal';
import styles from '../style.module.css';

type WelcomePageUserStepProps = {
  companyName: string;
  isAdmin: boolean;
};

const WelcomePageUserStep = ({
  companyName,
  isAdmin,
}: WelcomePageUserStepProps) => {

  const [isTermsAndCondVisible, setIsTermsAndCondVisible] = useState<boolean>(false);

  const form = useFormContext();

  const welcomeTitleMessage = `Welcome to ${companyName}!`;
  const adminWelcomeMessage = `Thanks for activating your Command Post account!`;
  const userWelcomeMessage = `Thanks for activating your account on Command Post for ${companyName}!`;
  const welcomeEndMessage = `First, we'd like to confirm a few details to get you started.`;

  const isAllowedManageEmployees = isAllowed(moduleConstants.EE);
  const isFieldPlainClass = isAllowedManageEmployees ? '' : styles.plainText;

  return (
    <>
      <div>
        <div className="row">
          <div>
            <h2 data-testid='welcomePage-message-title'>{welcomeTitleMessage}</h2>
            <p data-testid='welcomePage-message-text'>{isAdmin ? adminWelcomeMessage : userWelcomeMessage} <br /> {welcomeEndMessage}</p>
          </div>
        </div>
        <br />
        <div className='form-group row'>
          <label className='col-md-4 col-form-label font-weight-bold'>Your Name<span className="requiredField">*</span></label>
          <div className={`row col-md-8 ${styles.noPadRight}`}>
            <FormInput
              className={`col-md-4 ${isFieldPlainClass}`}
              disabled={!isAllowedManageEmployees}
              required
              name='firstName'
              placeholder='First Name'
              control={form.control}
            />
            <FormInput
              className={`col-md-4 ${isFieldPlainClass}`}
              disabled={!isAllowedManageEmployees}
              required
              name='middleName'
              placeholder='Middle Name'
              control={form.control}
            />
            <FormInput
              className={`col-md-4 ${isFieldPlainClass}`}
              disabled={!isAllowedManageEmployees}
              required
              name='lastName'
              placeholder='Last Name'
              control={form.control}
            />
          </div>
        </div>
        <FormInput
          className={isFieldPlainClass}
          disabled={!isAllowedManageEmployees}
          label='Your Title:'
          name='contactTitle'
          placeholder='Job Title'
          control={form.control}
          Layout={HorizontalLayout}
        />
        <FormInput
          className={styles.plainText}
          disabled
          label='Your Login:'
          required
          name='email'
          placeholder='@website.com'
          control={form.control}
          Layout={HorizontalLayout}
        />
        <FormInput
          className={styles.plainText}
          disabled
          label='Account Type:'
          name='cppRoleName'
          placeholder='Role'
          control={form.control}
          Layout={HorizontalLayout}
        />
        <br />
        <div className='form-group row'>
          <div className='col-md-4'></div>
          <div className='col-md-8 d-flex'>
            <input
              type='checkbox'
              placeholder='Terms And Conditions'
              id='isAgreedToTermsAndCond'
              {...form.register('isAgreedToTermsAndCond')}
            />
            <label htmlFor='isAgreedToTermsAndCond'>&nbsp;I understand and consent to the <a href='#' onClick={() => setIsTermsAndCondVisible(true)}>Command Post terms and conditions</a></label>
          </div>
        </div>
      </div>
      <TermsAndConditionsModal open={isTermsAndCondVisible} onClose={() => setIsTermsAndCondVisible(false)} />
    </>
  );
};

export default WelcomePageUserStep;
