import { ClickAwayListener } from '@material-ui/core';
import { useState } from 'react';
import { SwatchesPicker } from 'react-color';
import { ColorPickerButton } from './color-picker-button';

export const ColorPicker = ({
  name,
  value = { r: 0, g: 0, b: 0, a: 1 },
  onChange: onChangeConfirmed = () => void 0,
  'aria-label': ariaLabel,
}: any) => {
  const [open, setOpen] = useState(false);

  const onChange = (v) => {
    onChangeConfirmed(v.rgb);
    setOpen(false);
  };

  return (
    <>
      <ClickAwayListener onClickAway={() => setOpen(false)}>
        <div>
          <ColorPickerButton
            onClick={() => setOpen((o) => !o)}
            value={value}
            id={name}
            aria-label={ariaLabel}
          />

          {open && (
            <div style={{ position: 'absolute', zIndex: 1000 }}>
              <SwatchesPicker color={value} onChangeComplete={onChange} />
            </div>
          )}
        </div>
      </ClickAwayListener>
    </>
  );
};
