import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { TextEditorField } from '../Common/TextEditorField';
import { InputFieldSubmit } from '../Common/InputFieldSubmit';
import Creatable from 'react-select/creatable';
//import Select from 'react-select';
import { getAllUserListAction } from '../../actions/user';
import { createNoteAction, getAllNotesAction, deleteNotesAction } from '../../actions/message';
import { Userpilot } from 'userpilot';
import { FileDropZone } from '../../shared/file-upload';
import { Confirm } from '../../shared/confirmation-dialog';
import _ from 'lodash';
import axios from 'axios';
import { appConstants } from '../../_constants';
import ReactLoading from 'react-loading';
import {useAuth} from '../../components/Auth/Auth';
import { fileUploadOnS3 } from '../../utils/s3-attachment-handler'
import { Modal,ModalBody,ModalHeader, ModalFooter } from 'reactstrap';



window.Buffer = window.Buffer || require('buffer').Buffer;


let Validator = require('validatorjs');
let validation = [];
let mess = {
  required: 'This field is required',
  alpha: 'This field may only contain letters',
  numeric: 'This field must be a number',
};
let rules = {
  noteTitle: 'required',
  noteMessage: 'required',
  //noteCategory: 'required',
  //noteLabel: 'required',
};
validation = new Validator([], rules, mess);
validation.passes();
validation.fails();

const NoteEditor = (props) => {
  const [accountId] = useState(localStorage.getItem('accountId'));
  const [userId] = useState(localStorage.getItem('userId'));
  const [formData, setFormData] = useState({});
  const [userList, setUserList] = useState([]);
  const [, setFilesCount] = useState(0);
  const [userCommentError, setUserCommentError] = useState('');
  const [isSave, setIsSave] = useState(false);
  const [buttonActive, setButtonActive] = useState(true);
  const [, setFilesUploaded] = useState(true);
  const [filesUploading, setFilesUploading] = useState(false);
  const [showEditNote, setShowEditNote] = useState(false);
  const [noteAction, setNoteAction] = useState('NEW');
  const [objectId, setObjectId] = useState(null);
  const [followUpNote, setFollowUpNote] = useState(false);
  const [asModal, setAsModal] = useState(false);
  //const [attachmentIndex, setAttachmentIndex] = useState(-1);
  const [typeId, setTypeId] = useState(null);
  const [typeRef, setTypeRef] = useState(null);
  const [noteDataLoaded, setNoteDataLoaded] = useState(false);
  const [tagList, setTagList] = useState([]);
  const [initialTags, setInitialTags] = useState([]);
  const [initialFiles, setInitialFiles] = useState([]);
  const [noteTitle, setNoteTitle] = useState('');

  const [showToolbar, setShowToolbar] = useState(true);

  const errorStyle = { color: 'red' };
  const attachmentLimit = 25000000000;

  useEffect(() => {
    let contData = {};
    contData['accountId'] = accountId;
    contData['userId'] = userId;
    contData['page'] = 0;
    contData['displayLimit'] = 999999999;
    props.getAllUserListAction(contData);
  }, []);

  useEffect(() => {
    if(props.initialTags?.length > 0){
      setInitialTags(props.initialTags);
    }
  }, [props.initialTags]);

  useEffect(() => {
    if(props.asModal){
      setAsModal(props.asModal);
    }
  }, [props.asModal]);


  useEffect(() => {
    if(props.initialFiles?.length > 0){
      setInitialFiles(props.initialFiles);
    }
  }, [props.initialFiles]);

  const removeFileConfirm = (attachmentData, attachmentIndex, confirm) => async () => {
    const confirmed =
      (await confirm?.({
        title: 'Remove attachment',
        question: 'Are you sure you want to remove ' + attachmentData.name + '?',
      })) ?? true;

    if (!confirmed) {
      return;
    }

    deleteFile(attachmentIndex);
  };

  useEffect(() => {
    if (props.showEditNote && props.showEditNote !== showEditNote) {
      setShowEditNote(true);
    } else if (!props.showEditNote && showEditNote) {
      setShowEditNote(false);
      resetFields();
    }

    setFollowUpNote(props.followUpNote ? true : false);

    const typeIdChanged = props.typeId && props.typeId !== typeId;
    const noteActionChanged = props.noteAction && props.noteAction !== noteAction;
    const objectIdChanged =
      props.objectId && props.objectId?.length > 0 ? props.objectId !== objectId : !objectId;

    if (typeIdChanged) {
      setTypeId(props.typeId);
      setTypeRef(props.typeRef);
    }

    if (noteActionChanged) {
      setNoteAction(props.noteAction);
    }

    if (objectIdChanged) {
      setObjectId(props.objectId?.length > 0 ? props.objectId : null);
    }

  
    const isSaveAction = props.isSave && !isSave;
    setIsSave(isSaveAction);

    if (props.allUserList?.length > 0 && userList.length == 0) {
      let userList = [];
      for (let i in props.allUserList) {
        if (props.allUserList[i].emailCheckStatus === true) {
          const userData = props.allUserList[i];
          userList.push({
            id: userData.userId,
            value: userData.firstName + ' ' + userData.lastName + ' (' + userData.email + ')',
          });
        }
      }
      setUserList(userList);
    }
  }, [props]);

  useEffect(() => {
    if (props.typeId && props.typeId !== typeId) {
      setTypeId(props.typeId);
      setTypeRef(props.typeRef);
    }
  }, [props.typeId,props.typeRef]);

  useEffect(() => {
    const hasObjectId = objectId && objectId?.length > 0;
    if ((typeId || noteAction !== 'NEW' || objectId) && hasObjectId) {
      resetFields();
      getNoteData({ objectId: objectId, typeRef: typeRef, typeId: typeId });
    } else if( noteAction === 'NEW' && !hasObjectId && typeId) {
      resetFields();
      getNoteData({ objectId: null, typeRef: typeRef, typeId: typeId, onlyTags: true });
    }
  }, [typeId, typeRef, noteAction, objectId]);

  const onFileChange = (e) => {
    let files = e.target.files;
    let field = formData;

    if (typeof field.tempUploadNote === 'undefined') {
      field.tempUploadNote = [];
    }
    for (let i = 0; i < files.length; i++) {
      field.tempUploadNote.push(files[i]);
    }

    setFormData({ ...formData, tempUploadNote: field.tempUploadNote });
    setFilesCount(field.tempUploadNote.length);
    setFilesUploaded(false);
  };

  const onToolbarToggle = () => {
    setShowToolbar(!showToolbar);
  };

  const deleteFile = (fileIndex) => {
    //e.preventDefault();
    let localFormData = { ...formData };
    let tempUploadNote = localFormData.tempUploadNote ?? [];
    let noteAttachments = localFormData.noteAttachments ?? [];

    if (typeof tempUploadNote[fileIndex] != 'undefined') {
      localFormData.tempUploadNote = _.cloneDeep(tempUploadNote.filter(Boolean));
      localFormData.noteAttachments = _.cloneDeep(noteAttachments.filter(Boolean));

      delete localFormData.tempUploadNote[fileIndex];
      delete localFormData.noteAttachments[fileIndex];

      localFormData.tempUploadNote = localFormData.tempUploadNote.filter(Boolean);
      localFormData.noteAttachments = localFormData.noteAttachments.filter(Boolean);
    }

    setFormData(localFormData);
    setFilesCount(localFormData.tempUploadNote.length);
  };

  const onDrop = (files) => {
    let field = formData;
    const fileType = 'tempUploadNote';

    if (typeof field[fileType] === 'undefined') {
      field[fileType] = [];
    }
    for (let i = 0; i < files.length; i++) {
      field[fileType].push(files[i]);
    }

    setFormData({ ...formData, [fileType]: field[fileType] });
    setFilesCount(field[fileType].length);
    setFilesUploaded(false);
  };

  const saveNote = async () => {
    setButtonActive(false);
    if (!(formData.noteMessage?.length > 0)) {
      setUserCommentError('This field is required');
      setButtonActive(true);
    } else {
      let uploadSuccess = true;
      if (formData?.tempUploadNote?.length || 0) {
        uploadSuccess = await uploadFiles();
      }

      let messageData = {};

      if (uploadSuccess) {
        messageData['objectId'] = formData.objectId;
        messageData['accountId'] = accountId;
        messageData['userId'] = userId;
        messageData['receiverId'] = userId;
        messageData['typeId'] = typeId;
        messageData['typeRef'] = typeRef;
        //messageData['sourceName'] = this.props.state.title;

        messageData['title'] = noteTitle;
        messageData['category'] = formData.noteCategory;
        messageData['tags'] = formData.noteTags;
        messageData['description'] = formData.noteMessage;
        messageData['replyTo'] = formData?.replyTo ?? null;

        let parser = new DOMParser();
        const doc = parser.parseFromString(formData.noteMessage, 'text/html');
        const links = doc.getElementsByClassName('mention');
        messageData['mentions'] = [];
        for (let x = 0; x < links.length; x++) {
          let dataset = links[x].dataset;
          messageData['mentions'].push({
            id: dataset.id,
            denotationChar: dataset.denotationChar,
            value: dataset.value,
          });
        }
        messageData['followUpNote'] = followUpNote;
        messageData['isRead'] = 0;
        messageData['status'] = 'Enabled';
        messageData['trash'] = false;
        messageData['attachments'] = formData['noteAttachments'];

        Userpilot.track('Added Note');
        await props.createNoteAction(messageData, useAuth);

        resetFields();
        getAllNotes();
        showHideEditNote();
      }
      setButtonActive(true);
    }
  };

  const uploadFiles = async () => {

    let currentForm = formData;
    setFilesUploading(true);
    let { attachments, objectId, uploadSuccess } = await fileUploadOnS3(formData?.tempUploadNote, formData['objectId'], typeId, typeRef, attachmentLimit);

    if (!uploadSuccess) {
      return false;
    }

    currentForm['noteAttachments'] = attachments;
    currentForm['objectId'] = objectId;


    setFormData({ formData: currentForm });

    setFilesCount(attachments.length);
    setFilesUploading(false);
    setFilesUploaded(true);

    return true;
  };

  const getAllNotes = async () => {
    let contData = {};
    contData['accountId'] = accountId;
    contData['userId'] = userId;
    contData['typeId'] = typeId;
    contData['typeRef'] = typeRef;
    contData['page'] = 0;
    contData['displayLimit'] = 999999999;
    await props.getAllNotesAction(contData);
  };

  const getNoteData = async ({ objectId, typeRef, typeId, onlyTags }) => {
    const hasObjectId = objectId && objectId?.length > 0;

    if(!accountId || !typeId || !typeRef) return;


    setNoteDataLoaded(false);

    const { data } = await axios.post(
      'parse/functions/getNotes',
      {
        userId:userId,
        accountId:accountId,
      },
      {
        baseURL: appConstants.WEB_SERVICE_URL_WITHOUT_PARSE,
        headers: {
          'X-Parse-Application-Id': appConstants.PARSE_APPLICATION_ID,
          'X-Parse-REST-API-Key': appConstants.PARSE_REST_API_KEY,
          'X-Parse-Session-Token': localStorage.getItem('sessionToken'),
          authorization: localStorage.getItem('JWTTOKEN'),
        },
        params: {
          accountId: accountId,
          typeId: typeId,
          typeRef: typeRef,
          objectId: objectId,
          onlyTags: onlyTags||!hasObjectId,
        },
      }
    );

    setTagList(data?.result?.tags ?? []);
    setNoteDataLoaded(true);

    if (data?.result?.data?.length > 0) {
      if (noteAction === 'REPLY') {
        editReplyData(data.result.data[0], objectId);
      } else if (noteAction === 'EDIT') {
        editNoteData(data.result.data[0]);
      }
    }

    if(initialFiles?.length > 0){
      onDrop(initialFiles);
    }

    if(initialTags?.length > 0){
      setFormData({...formData, noteTags: initialTags.map((tag) => ({ value: tag, label: tag }))});
    }
  };

  const cancelDelete = (noteData, confirm) => async () => {
    const confirmed =
      (await confirm?.({
        title: 'Delete note',
        question: 'Are you sure you want to Delete this note?',
      })) ?? true;

    if (!confirmed) {
      return;
    }

    let formData = {};
    formData['objectId'] = noteData.objectId;
    formData['typeRef'] = noteData.typeRef;
    formData['typeId'] = noteData.typeId;

    await props.deleteNotesAction(formData);

    resetFields();
  };

  const editReplyData = (noteData, noteObjectId) => {
    let editFormData = { ...formData };
    editFormData.objectId = null;
    setNoteTitle('Re:' + noteData.title);
    editFormData.noteCategory = noteData.category;
    editFormData.noteTags = [];
    editFormData.noteMessage = '';
    editFormData.replyTo = noteObjectId;
    editFormData.noteAttachments = [];
    editFormData.tempUploadNote = [];

    setFormData(editFormData);
  };

  const editNoteData = (noteData, isCopy = false) => {
    let editFormData = { ...formData };
    editFormData.objectId = noteData.objectId;
    setNoteTitle(noteData.title);
    editFormData.noteCategory = noteData.category;
    editFormData.noteTags = noteData.tags;
    editFormData.noteMessage = noteData.description;
    editFormData.noteAttachments = noteData.attachments;
    editFormData.tempUploadNote = noteData.attachments.map((attachment) => ({
      name: attachment.fileName,
      type: attachment.fileType,
      lastModifiedDate: attachment.fileModified,
      size: attachment.fileSize,
      fileKey: attachment.fileKey,
      alreadyUploaded: true,
    }));

    setFormData(editFormData);
  };

  const resetFields = () => {
    /*Reset form data */
    let newFormData = { ...formData };
    Object.keys(newFormData).map((key, index) => {
      if (typeof newFormData[key] === 'object') newFormData[key] = [];
      else newFormData[key] = '';
      return true;
    });

    newFormData.noteCategory = { label: 'None', value: 'None' };
    newFormData.replyTo = null;

    setFormData(newFormData);
    setUserCommentError('');
  };


  const handleChangeSelect = (name, value) => {
    if (!isSave) {
      let newFormData = { ...formData };
      newFormData[name] = value;
      setFormData(newFormData);
    }
  };

  const handleChangeNotes = (name, value) => {
    if (!isSave) {
      let userCommentError = '';
      if (value && value === '<p><br></p>') {
        userCommentError = 'This field is required.';
        value = '';
      }
      let newFormData = { ...formData };
      newFormData[name] = value;
      setFormData(newFormData);
      setUserCommentError(userCommentError);
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    if (!isSave) {
      let newFormData = { ...formData };
      newFormData[e.target.name] = e.target.value;
      setFormData(newFormData);
    }
  };

  const showHideEditNote = () => {
    setShowEditNote(false);
    setObjectId(null);
    resetFields();
    props.closeWindowCallback();
  };

  const components = {
    DropdownIndicator: null,
  };

  let filesListNote = '';
  if (formData.tempUploadNote !== undefined && formData.tempUploadNote.length > 0) {
    filesListNote = formData.tempUploadNote.map((v, index) => (
      <Confirm
        render={(confirm) => (
          <div className='filesList'>
            <label className={v.alreadyUploaded?'':'time-left'}>{v.name}</label>
            <span onClick={removeFileConfirm(v, index, confirm)}><i className="fa fa-remove"></i></span>
          </div>
        )}
      />
    ));
  }

  const noteSaveBtn = [
    {
      title: 'Save Note',
      loading: 'Loading...',
      loadingActive: !buttonActive,
      onClickFun: (e) => saveNote(e, false),
      'data-type': 'SAVE',
      className: 'btn btn-primary custom-button',
    },
  ];

  const handleChangeNoteTitle = (e) => {
    e.preventDefault();
    if (!isSave) {
      setNoteTitle(e.target.value);
    }
  };

  const noteBody = () => {
    return !noteDataLoaded ? (
      <ReactLoading
        className='table-loader'
        type={appConstants.LOADER_TYPE}
        color={appConstants.LOADER_COLOR}
        height={appConstants.LOADER_HEIGHT}
        width={appConstants.LOADER_WIDTH}
      />
    ) : (
      <div>
        <FileDropZone
          onFileDrop={(e) => {
            onDrop(e);
          }}
        >
          <div className='row'>
            <div className='col-md-12'>
              <div className='form-group'>
                <label htmlFor='endDT'>Title</label>
                <input
                  type='text'
                  className='form-control'
                  name='noteTitle'
                  id='noteTitle'
                  placeholder='Add a title'
                  value={noteTitle}
                  onChange={(e) => {
                    handleChangeNoteTitle(e);
                  }}
                />
                {false && (
                  <span className='error' style={errorStyle}>
                    {noteTitle && validation.errors.first('noteTitle')}
                  </span>
                )}
              </div>
            </div>
          </div>


          {userList.length > 0 && (
            <TextEditorField
              fieldData={{
                onChange: handleChangeNotes.bind(this, 'noteMessage'),
                value: formData.noteMessage,
                name: 'noteMessage',
                errorMsg: formData.noteMessage?.length > 0 ? '' : userCommentError,
                mentionList: userList,
              }}
              toolbarEnabled={showToolbar}
            />
          )}

          <div className='row'>
            <div className='col-md-12'>
              <div className='form-group'>
                <div className='filesBox'>
                  {filesListNote.length > 0 && (
                    <div>
                      <div className=' row'>
                        <label className=''>Attachments</label>
                      </div>
                      <div className='ms-2 filesList row'>
                        {filesListNote}
                      </div>
                    </div>
                  )}

                </div>
              </div>
            </div>
          </div>

          <div className='row'>
            <div className='col-md-6 mt-4'>
              <div id='upload_button'>
                <label>
                  <input
                    type='file'
                    name='userImage'
                    multiple
                    className='field full'
                    label='Upload'
                    accept=''
                    title='Attach Files'
                    onChange={(e) => {
                      onFileChange(e);
                    }}
                  />
                  <span>
                    Add attachment <i className='fa fa-paperclip' aria-hidden='true'></i>
                  </span>
                </label>
              </div>
              {false && (<div id='show_hide_toolbar_button'>
                <label className='upload-button'>
                  <div
                    name='toggle'
                    label='toggleToolbar'
                    onClick={() => {
                      onToolbarToggle();
                    }}
                    title={showToolbar ? "Hide formatting" : "Show formatting"}
                  >
                    <i className={showToolbar ? 'fa fa-font' : 'fa fa-paragraph'} aria-hidden='true'></i>
                  </div>
                </label>
              </div>)}
            </div>
            {/*false && (<div className='col-md-6'>
              <div className='form-group'>
                <label htmlFor='endDT'>Category</label>
                <Select
                  className='basic-single'
                  classNamePrefix='select'
                  defaultValue={{ value: 'None', label: 'None' }}
                  isDisabled={false}
                  isLoading={false}
                  isClearable={false}
                  isRtl={false}
                  isSearchable={true}
                  name='Category'
                  options={[{ value: 'None', label: 'None' }]}
                  onChange={(e) => {
                    handleChangeSelect('noteCategory', e);
                  }}
                />
                <span className='error' style={errorStyle}>
                  {formData?.noteCategory && validation.errors.first('noteCategory')}
                </span>
              </div>
            </div>)*/}
            <div className='col-md-6 ms-auto'>
              <div className='form-group'>
                <label htmlFor='endDT'>Tags</label>
                <Creatable
                  isMulti={true}
                  components={components}
                  placeholder={`Tag your notes`}
                  onChange={(e) => {
                    handleChangeSelect('noteTags', e);
                  }}
                  value={formData.noteTags}
                  options={tagList.map((tag) => ({ value: tag, label: tag }))}
                />
                <span className='error' style={errorStyle}>
                  {formData?.noteTags && validation.errors.first('noteTags')}
                </span>
              </div>
            </div>
          </div>
        </FileDropZone>
      </div>
    )
  }

  const noteHeaderText = () => {
    if (noteAction == 'REPLY') {
      return 'Reply to Note';
    } else if (noteAction === 'EDIT') {
      return 'Edit Note';
    } else if (followUpNote){
      return 'Add Follow-up Note';
    
    } else {
      return 'Add Note';
    }
  }

  const noteFooter =() => {
    return (
      <div className='row mb-2 mt-3'>
            <div className='d-flex addProjectPopupBottomBtnBox'>
              <button
                className='btn btn-primary custom-button'
                onClick={showHideEditNote.bind(this)}
              >
                Close
              </button>
              <div className='ms-auto'>
                <InputFieldSubmit actions={noteSaveBtn} disabled={filesUploading} />
              </div>
            </div>
          </div>
    );
  }

  return (
    <div>
      {
        showEditNote?(!asModal?
        <div className='no-print'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='pageinfo'>
                <h2>
                  {noteHeaderText()}
                </h2>
              </div>
            </div>
          </div>
          {noteBody()}
          {noteFooter() }
        </div>
        : <Modal isOpen={showEditNote} onHide={showHideEditNote} size='lg'>
          <ModalHeader closeButton>
              {noteHeaderText()}
          </ModalHeader>
          <ModalBody>
          <div className='container'>
            {noteBody()}
            </div>
          </ModalBody>
          <ModalFooter>
            {noteFooter()}
          </ModalFooter>
        </Modal>
        ):''
      }
    </div>
  );
};

const mapStateToProps = (state) => {
  const { message } = state;
  return {
    isSave: message.isSaveM,
    success: message.success,
    buttonActive: message.buttonActive,
    allUserList: state.user.allUserList,
  };
};
export default withRouter(
  connect(mapStateToProps, {
    getAllNotesAction,
    deleteNotesAction,
    createNoteAction,
    getAllUserListAction,
  })(NoteEditor)
);
