import { ClickAwayListener } from '@material-ui/core';
import React, { forwardRef, useEffect, useMemo, useState } from 'react';
import styles from './SearchPlaceAutocomplete.module.css';

const SearchPlaceAutocomplete = forwardRef(function SearchPlaceAutocomplete(
  { name, placeholder, value, onChange, onSelect, disabled },
  ref
) {
  const [open, setOpen] = useState(false);
  const [predictions, setPredictions] = useState([]);

  const places = useMemo(() => new window.google.maps.places.AutocompleteService(), []);
  const geoCoder = useMemo(() => new window.google.maps.Geocoder(), []);

  useEffect(() => {
    if (value === '') {
      setPredictions([]);

      return;
    }

    places.getPlacePredictions({ input: value }, (results) => {
      if (results) {
        setPredictions(results);
      }
    });
  }, [places, value]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSelect = (placeId) => () => {
    geoCoder.geocode({ placeId }, (results) => {
      if (results) {
        onSelect(results);

        setOpen(false);
      }
    });
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div className={styles.wrapper}>
        <input
          ref={ref}
          type='text'
          name={name}
          id={name}
          className='form-control'
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onFocus={handleOpen}
          disabled={disabled}
          autoComplete="off"
        />

        {open && (
          <ul className={styles.list}>
            {predictions.map(({ place_id, description }) => (
              <li key={place_id} className={styles.item} onClick={handleSelect(place_id)}>
                {description}
              </li>
            ))}
          </ul>
        )}
      </div>
    </ClickAwayListener>
  );
});

export default SearchPlaceAutocomplete;
