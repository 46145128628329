import { useState, forwardRef, useImperativeHandle } from 'react';
import { useFormContext } from 'react-hook-form';
import FormDurationPicker from '../../../../shared/form-controls/form-duration-picker/form-duration-picker';
import FormDatePicker from '../../../../shared/form-controls/form-date-picker/form-date-picker';
import { HorizontalLayout } from '../../../../shared/form-controls/control-layouts/horizontal-layout';
import { formatDateObjectOrNull } from '../../../../utils/date-formatters';
import { convertIntervalToPreciseDuration } from '../../../../utils/import-utils';
import { isCurrentOrPrevStage } from '../../../../shared/custom-fields-controls/constants';
import styles from '../../bid-modal.module.scss';

type ProjectSchedulerProps = {
  isLocked: boolean;
  projectStage?: string;
  labelPrefix?: string;
  accountSettings?: any;
};

export const ProjectScheduler = forwardRef(({
  isLocked,
  projectStage = '',
  labelPrefix = 'Contract',
  accountSettings = {},
}: ProjectSchedulerProps, ref) => {
  const form = useFormContext();

  const watchStartDate = form.watch('startDate');
  const watchEndDate = form.watch('endDate');
  const watchBidDue = form.watch('bidDue');

  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  useImperativeHandle(ref, () => ({ setIsExpanded }), []);

  const dateFormat = 'MM/DD/YYYY';

  const bidDueFormatted = formatDateObjectOrNull(watchBidDue);
  const startDateFormatted = formatDateObjectOrNull(watchStartDate);
  const endDateFormatted = formatDateObjectOrNull(watchEndDate);

  const startDateRangeProps = {
    ...(bidDueFormatted && { minDate: bidDueFormatted }),
    ...(startDateFormatted && { startDate: startDateFormatted }),
    ...(endDateFormatted && { endDate: endDateFormatted }),
  };

  const endDateRangeProps = {
    ...(startDateFormatted && { startDate: startDateFormatted }),
    ...(endDateFormatted && { endDate: endDateFormatted }),
    ...(bidDueFormatted && { minDate: bidDueFormatted.clone()}),
    ...(startDateFormatted && { minDate: startDateFormatted.clone() }),
    ...(startDateFormatted && { maxDate: startDateFormatted.clone().add(120, 'months') }),
  };

  const { requireConstructionDatesOn } = accountSettings;
  const requireConstructionDates = isCurrentOrPrevStage(projectStage, requireConstructionDatesOn);

  const getCollapsedSchedule = () => {
    let startDateFormatted = '<Unknown>';
    let endDateFormatted = '<Unknown>';
    let durationText = '';

    if (watchStartDate && watchEndDate) {
      const { years, months, days } = convertIntervalToPreciseDuration(watchStartDate, watchEndDate);
      let durationArray = [{label: 'y', value: years}, {label: 'm', value: months}, {label: 'd', value: days}];
      const firstPeriodWithDurationIndex = durationArray.findIndex((el) => el.value > 0);

      if (firstPeriodWithDurationIndex !== -1) {
        durationArray = durationArray.slice(firstPeriodWithDurationIndex);
        durationText = `(${durationArray.map(({ label, value }) => `${value}${label}`).join(' ')})`;
      }

      startDateFormatted = watchStartDate.format(dateFormat);
      endDateFormatted = watchEndDate.format(dateFormat);
    }

    return `${startDateFormatted} - ${endDateFormatted} ${durationText}`;
  };

  const getExpandedSchedule = () => {
    return (
      <>
        <FormDatePicker
          required={requireConstructionDates}
          label= {`${labelPrefix} Start Date:`}
          name='startDate'
          selectsStart
          disabled={isLocked}
          control={form.control}
          Layout={HorizontalLayout}
          {...startDateRangeProps}
        />
        <FormDurationPicker
          label='Duration:'
          name='projectDuration'
          disabled={isLocked}
          startDateFieldName='startDate'
          endDateFieldName='endDate'
          Layout={HorizontalLayout}
        />
        <FormDatePicker
          required={requireConstructionDates}
          label= {`${labelPrefix} End Date:`}
          name='endDate'
          selectsEnd
          disabled={isLocked}
          control={form.control}
          Layout={HorizontalLayout}
          {...endDateRangeProps}
        />
      </>
    );
  };

  return (
    <div data-testId='bidManagementModalProjectScheduler'>
      <hr />
      <div className='form-group row my-4'>
        <div className={`col-md-4 ${isExpanded ? 'fw-bold' : ''}`}>Construction schedule:</div>
        <div className='col-md-6'>{!isExpanded && getCollapsedSchedule()}</div>
        <div className={`col-md-2 ${styles.chevron}`}>
          <i
            data-testId='bidSchedulerToggler'
            onClick={() => setIsExpanded(!isExpanded)}
            className={`fa-solid fa-chevron-${isExpanded ? 'down' : 'right'}`}
            data-title={isExpanded ? 'Collanse' : 'Expand'}
          ></i>
        </div>
      </div>
      {isExpanded && getExpandedSchedule()}
    </div>
  );
});
