import { routerReducer } from 'react-router-redux';
import { combineReducers } from 'redux';
import accounts from '../../modules/accounts';
import help from './../help';
import account from './account.reducer';
import admin from './admin.reducer';
import buildingType from './buildingType.reducer';
import contactRole from './contactRole.reducer';
import home from './homePage.reducer';
import jobScope from './jobScope.reducer';
import jobStatus from './jobStatus.reducer';
import negotiatingMethod from './negotiatingMethod.reducer';
import notificationTypes from './notificationTypes.reducer';
import occupancyType from './occupancyType.reducer';
import plans from './plans.reducer';
import reasonCode from './reasonCode.reducer';
import userRoleModule from './UserRole.reducer';
import siteModules from './moduleDetails.reducer';
import user from './users.reducer';
import widgets from './widgets.reducer';

import settings from '../../modules/settings';

const reducer = combineReducers({
  router: routerReducer,
  home,
  user,
  admin,
  contactRole,
  account,
  occupancyType,
  negotiatingMethod,
  jobScope,
  jobStatus,
  reasonCode,
  buildingType,
  plans,
  userRoleModule,
  help,
  widgets,
  notificationTypes,
  siteModules,
  rtk: combineReducers({
    settings,
    accounts
  })
});

export default reducer;
