import React, {Suspense} from 'react';

import { Link, withRouter } from 'react-router-dom';
import Header from '../../Layout/Header.js';
import Footer from '../../Layout/Footer.js';
import { connect } from 'react-redux';
import { appConstants } from '../../../_constants';
import {
  GetLostJobReportAction,
  SetLostJobPrefAction,
  GetLostJobPrefAction
} from '../../../actions/reports';
import ReactLoading from 'react-loading';
import {Tooltip} from 'react-tooltip';
import { getAllProjectRoleAction } from '../../../actions/projectRole';
import loaderImage from '../../static/images/loading_spinner.gif';
import NotePopUp from '../../Common/NotePopUp';
import renderHTML from 'html-react-parser';
import TitleComponent from  "../../Common/TitleComponent";
import {CustomTable, multiSelectFilter, customSortByKey} from '../../Common/CustomTable';
import {DateColumnFilter} from '../../Common/CustomTableComponents/DateColumnFilter';
import {SelectColumnFilter} from '../../Common/CustomTableComponents/SelectColumnFilter';
import {
  formatExternalContactData,
  formatInternalContactData,
} from '../../../utils/contact-formatters';
import {
  matchDataForTable
} from '../../../utils/match-for-table.jsx';

import { formatDateObject, startDateFormatWithoutTimeUTCF, endDateFormatWithoutTimeUTCF } from '../../../utils/date-formatters';
import { formatNote } from '../../../utils/note-formatters';
import { getAllProjectFieldsAction } from '../../../actions/projectFields';
import { isAllowed, moduleConstants } from '../../../_constants';
import {buildColumnForField, buildDynamicColumns, onCurrency, onDate} from '../../../utils/column-formatters';



var sortingOrder = '';
class LostJobsReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accountId: localStorage.getItem('accountId'),
      userId: localStorage.getItem('userId'),
      list: [],
      fetchRequest: true,
      comLogo: '',
      totalCount: 0,
      currentPage: 1,
      sizePerPage: appConstants.TableSizePerPageList[2].value,
      searchText: '',
      modal: false,
      selectDateBox: {
        startDate: formatDateObject().subtract(1, 'years'),
        endDate: formatDateObject(),
      },
      sortType: 'DESC',
      sortKey: '',
      noteModal: false,
      contactData: {},
      dynamicColumns: [],
      allProjectFieldTypes: [],
      projectRoleTypes: [],
    };

    this.onPageChange = this.onPageChange.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.toggle = this.toggle.bind(this);
    this.onSortChange = this.onSortChange.bind(this);
    this.getModalOpen = this.getModalOpen.bind(this);
    this.onNoteModalClose = this.onNoteModalClose.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.listData &&
      nextProps.listData.success &&
      nextProps.listData.success === 1 
    ) {

      let fieldsToMatch = {
          projectAssignments: {lookupLocation: 'contactData'},
          'scope':{'lookupLocation':'jobScope','collectionName':'allJobScope','labelField':'jobScopeName'},
          'occupancyType':{'lookupLocation':'occupancyType','collectionName':'allOccupancyType','labelField':'occupancyTypeName'}
      };
      let matchedDataForTable=matchDataForTable(fieldsToMatch,nextProps.listData.data,nextProps.listData.contactData,this.props);
            

      this.setState({
        totalCount: nextProps.listData.count,
        list: matchedDataForTable,
        fetchRequest: false,
        contactData: nextProps.listData.contactData,
      });
    }

    if (nextProps?.projectRoles?.allProjectRoleTypes) {
      this.setState({
        projectRoleTypes: nextProps.projectRoles.allProjectRoleTypes,
      });
    }

    if (nextProps?.projectFields?.allProjectFieldTypes?.length > 0) {
      this.setState({
        allProjectFieldTypes: nextProps.projectFields.allProjectFieldTypes,
      });
    }
   
    if (nextProps.listData && nextProps.listData.data && nextProps.listData.data.length == 0) {
      this.setState({ fetchRequest: false });
    }
  }

  
  
  buildColumnForRole(filteredRoles, roleCode, hidden = false) {
    const foundRole = filteredRoles.find((role) => role.code == roleCode);
    if(!foundRole)
      return
    const isInternal = foundRole&& foundRole.type === 'internal';

    if (foundRole && isInternal) {
      return (foundRole &&
      {
        Header: foundRole?.shortName ?? roleCode,
          LongHeader: foundRole?.name,
        accessor: 'projectAssignmentsLabel' + roleCode,
        sortType: customSortByKey,
        sortKey: foundRole?.name ?? roleCode,
        Filter: SelectColumnFilter,
        filter: multiSelectFilter,
        width: 60,
        hidden: hidden,
        Cell: (props) => <>{this.formatContactDataInternal('projectAssignmentsMatched' + roleCode, props.row.original)}</>,
      })
    } else {
      return (foundRole &&
      {
        Header: foundRole?.shortName ?? roleCode,
          LongHeader: foundRole?.name,
        accessor: 'projectAssignmentsLabel' + roleCode,
        sortType: customSortByKey,
        sortKey: foundRole?.name ?? roleCode,
        Filter: SelectColumnFilter,
        filter: multiSelectFilter,
        width: 60,
        hidden: hidden,
        Cell: (props) => <>{this.formatContactDataExternal('projectAssignmentsMatched' + roleCode, props.row.original)}</>,
      })
    }
  }

  formatContactDataInternal(cell, row) {
    if (row[cell] && row[cell].length > 0) {
      return formatInternalContactData(row[cell], this.state.contactData);
    } else {
      return <span>-</span>;
    }
  }

  formatContactDataExternal(cell, row) {
    if (row[cell] && row[cell].length > 0) {
      return formatExternalContactData(row[cell], this.state.contactData,false);
    } else {
      return <span>-</span>;
    }
  }

  componentDidMount() {
    sortingOrder = '';

    
    this.props.getAllProjectRoleAction({
      className: 'projectRoleTypes',
      accountId: this.state.accountId,
    });

    this.props.getAllProjectFieldsAction({
      accountId: this.state.accountId,
      minimumStage: 'bidding',
    });

    if (
      localStorage.getItem('companyLogo') !== 'undefined' &&
      localStorage.getItem('companyLogo') !== null
    ) {
      this.setState({
        comLogo: localStorage.getItem('companyLogo'),
      });
    } else {
      let constImage = appConstants.CONTENT_CPOST_LOGO_URL;
      this.setState({ comLogo: constImage });
    }

    let _this = this;
    setTimeout(function () {
      _this.setState({ fetchRequest: false });
    }, 2000);
    this.filteredData();
    this.props.GetLostJobPrefAction();
  }

  UNSAFE_componentWillMount() {
    this.toggle();
  }


  toggle(val) {
    this.setState({ modal: !this.state.modal });
  }

  filteredData() {
    this.setState({ sizePerPage: appConstants.TableSizePerPageList[2].value });
    let data = {};
    data['accountId'] = this.state.accountId;
    data['moduleName'] = appConstants.reportModuleName['11'];
    data['startDate'] = startDateFormatWithoutTimeUTCF(new Date(1900, 0, 1));
    data['endDate'] = endDateFormatWithoutTimeUTCF(
      new Date()
    );
    data['page'] = 0;
    data['displayLimit'] = 99999999;
    data['search'] = this.state.searchText;
    data['sortKey'] = this.state.sortKey;
    data['sortType'] = this.state.sortType;

    this.setState({ fetchRequest: true });
    this.toggle();
    this.props.GetLostJobReportAction(data);
  }

  handleDatePicker = (name, date) => {
    let field = this.state.selectDateBox;
    field[name] = date;
    if (name === 'startDate') {
      let dateObj = '';
      if (date === null) {
        dateObj = formatDateObject().add(1, 'day');
        field['endDate'] = dateObj;
      } else {
        dateObj = formatDateObject(date).add(1, 'Day');
        if (field['startDate'] > field['endDate']) {
          field['endDate'] = dateObj;
        }
      }
    }
    this.setState({
      selectDateBox: field,
    });
  };

  redirectToProject(cell, enumObject, row, rowIndex) {
    if (cell === 'jobNumFormatted' && row) {
      return (
        <div>
          <Link to={{pathname: `/project-details/` + row.objectId, state: {row}}}>
            {row.jobNumFormatted ? row.jobNumFormatted : '-'}
          </Link>
        </div>
      );
    }

    if (cell === 'jobName' && row) {
      return (
        <div>
          <Link to={{pathname: `/project-details/` + row.objectId, state: {row}}}>
            {row.jobName ? row.jobName : '-'}
          </Link>
        </div>
      );
    }

    if (cell === 'altJobNum' && row) {
      return (
        <div>
          <Link to={{pathname: `/project-details/` + row.objectId, state: {row}}}>
            {row.altJobNum ? row.altJobNum : '-'}
          </Link>
        </div>
      );
    }
  }


  

  showProjectStatus(cell, row, enumObject, rowIndex) {
    return row && row.buildingType && row.buildingType.buildingTypeCode
      ? row.buildingType.buildingTypeCode
      : '-';
  }

  onNoteDetail(cell, row, enumObject, rowIndex) {
    // return (<div>{(row && row.jobNotes)?row.jobNotes:'-'}</div>)
    const notes = 
    formatNote(row?.jobNotes);
    return notes ? (
      <a
        className="anchorNote "
        data-type={'reminder'}
        onClick={(e) => {
          this.getModalOpen(e, row);
        }}
      >
        {renderHTML(notes)}
      </a>
    ) : (
      '-'
    );
  }

  createCustomButtonGroup = () => {
    return (
      <div className="pdfLogoBox">
        <div className="reportHead">
          <div className="logo">
            <img src={this.state.comLogo} alt="Upload Logo" />
          </div>
          <h2>Lost Jobs Report</h2>
        </div>
        
      </div>
    );
  };

  onNoteModalClose() {
    this.setState({ noteModal: false });
  }

  getModalOpen(e, row) {
    let noteText = row && row.jobNotes ? row.jobNotes : '-';
    let lastNoteId = row && row.lastNoteId ? row.lastNoteId : null;
    this.setState({ noteModal: true, noteData: {objectId:lastNoteId,description: noteText,typeId: row.objectId, typeRef: 'PROJECT'} });
  }

  _setTableOption() {
    if (this.state.fetchRequest) {
      return (
        <ReactLoading
          className="table-loader"
          type={appConstants.LOADER_TYPE}
          color={appConstants.LOADER_COLOR}
          height={appConstants.LOADER_HEIGHT}
          width={appConstants.LOADER_WIDTH}
        />
      );
    } else {
      return 'No data found..';
    }
  }

  onPageChange(page, sizePerPage) {
    this.setState({ fetchRequest: true });
    let data = {};
    data['accountId'] = this.state.accountId;
    data['moduleName'] = appConstants.reportModuleName['11'];
    // if (this.state.searchText === "") {
    //     data['page'] = (page === -1) ? 0 : page - 1;
    //     data['displayLimit'] = sizePerPage;
    // } else {
    data['page'] = page === -1 ? 0 : page - 1;
    data['displayLimit'] = sizePerPage;
    data['search'] = this.state.searchText;
    //}
    data['sortKey'] = this.state.sortKey;
    data['sortType'] = this.state.sortType;
    data['startDate'] = startDateFormatWithoutTimeUTCF(
      this.state.selectDateBox.startDate
    );
    data['endDate'] = endDateFormatWithoutTimeUTCF(
      this.state.selectDateBox.endDate
    );

    this.setState({ sizePerPage: sizePerPage });
    this.setState({ currentPage: page });
    this.props.GetLostJobReportAction(data);
  }

  onSearchChange(text, sizePerPage) {
    this.setState({ fetchRequest: true, list: [] });
    let data = {};
    data['accountId'] = this.state.accountId;
    data['moduleName'] = appConstants.reportModuleName['11'];
    data['page'] = 0;
    data['displayLimit'] = this.state.sizePerPage;
    data['startDate'] = startDateFormatWithoutTimeUTCF(
      this.state.selectDateBox.startDate
    );
    data['endDate'] = endDateFormatWithoutTimeUTCF(
      this.state.selectDateBox.endDate
    );
    if (text !== '') {
      data['search'] = text;
      this.setState({ sizePerPage: 50 });
    }

    data['sortKey'] = this.state.sortKey;
    data['sortType'] = this.state.sortType;

    this.setState({ searchText: text });
    this.props.GetLostJobReportAction(data);
  }

  onSortChange(sortKey, sortType) {
    if (this.state && this.state.list && this.state.list.length > 0) {
      this.setState({ fetchRequest: true, list: [] });
      let data = {};
      data['sortKey'] = sortKey;
      if (sortKey === 'jobNumFormatted') {
        data['sortKey'] = 'jobNum';
      }
      if (sortType === 'asc') {
        data['sortType'] = 'ASC';
        sortingOrder = '-asc';
        this.setState({ sortType: 'ASC' });
      } else {
        data['sortType'] = 'DESC';
        sortingOrder = '-desc';
        this.setState({ sortType: 'DESC' });
      }
      data['search'] = this.state.searchText;
      data['accountId'] = this.state.accountId;
      data['moduleName'] = appConstants.reportModuleName['11'];
      data['displayLimit'] = this.state.sizePerPage;
      data['startDate'] = startDateFormatWithoutTimeUTCF(
        this.state.selectDateBox.startDate
      );
      data['endDate'] = endDateFormatWithoutTimeUTCF(
        this.state.selectDateBox.endDate
      );
      data['page'] = this.state.currentPage - 1;
      data['displayLimit'] = this.state.sizePerPage;
      this.props.GetLostJobReportAction(data);
    }
  }

  renderLink = (data) => {
    const dataId = data?.column?.id;
    const rowData = data?.row?.original;
    if(!rowData[dataId]) {
      return "-";
    }
    return <Link to={`/project-details/${rowData.objectId}`}>{rowData[dataId]}</Link>
  }



  

  buildReportColumns() {
    const hasContractValPermissions = isAllowed(moduleConstants.VIEWCONTRACTVAL, 'anyAssigned');
    //const hasProfitValPermissions = isAllowed(moduleConstants.VIEWPROFIT, 'anyAssigned');

    const { list, allProjectFieldTypes } = this.state;
   
  
    const dynamicFieldsLoaded = allProjectFieldTypes?.length > 0;

    const defaultFieldCodes = ['BLDGTYPE'];

    const customFieldColumns = dynamicFieldsLoaded
      ? buildDynamicColumns(
          allProjectFieldTypes.filter((field) => !defaultFieldCodes.includes(field.code))
        )
      : [];

    const defaultFields = dynamicFieldsLoaded
      ? allProjectFieldTypes.filter((field) => defaultFieldCodes.includes(field.code))
      : [];
    
    const isLeadType = false;

    const rolesLoaded = this.state.projectRoleTypes?.length > 0;

    const defaultRolesCodes = ['EST'];

    const dynamicColumnsLoaded = dynamicFieldsLoaded && rolesLoaded;

    
    const enabledRoles = this.state.projectRoleTypes.filter(
      (role) => !defaultRolesCodes.includes(role.code) && (!isLeadType || role.isShowOnLeads)
    );
    const defaultRoles = this.state.projectRoleTypes.filter((role) =>
      defaultRolesCodes.includes(role.code) && (!isLeadType || role.isShowOnLeads)
    );

    const defaultColumns = [
      {
        Header: "Job No.",
        accessor: "jobNumFormatted",
        width: 90,
        Cell: (props) => this.renderLink(props),
      },
      {
        Header: "Alt Job #",
        accessor: "altJobNum",
        width: 100,
        Cell: (props) => this.renderLink(props),
      },
      {
        Header: "Job Name",
        accessor: "jobName",
        width: 240,
        Cell: (props) => this.renderLink(props),
      },

      {
        Header: "Lost Date",
        accessor: row => row?.lostDate.iso ? new Date(row.lostDate.iso) : null,
        filter: DateColumnFilter,
        id:"lostDate",
        allowDateRangeFilter: true,
        sortType: "datetime",
        width: 100,
        Cell: (props) => onDate(props.column.id, props.row.original, props.value),
      },
      hasContractValPermissions&&{
        Header: "Contract Amt.",
        accessor: "currentContractAmount",
        width: 110,
        Cell: (props) => onCurrency(props.column.id, props.row.original, props.value),
      },

      this.buildColumnForRole(defaultRoles,'EST'),
      buildColumnForField(defaultFields, 'BLDGTYPE', false),
      {
        Header: 'Notes',
        accessor: 'jobNotes',
        hidden: true,
        width: 120,
        Cell: (props) => (
          <>
            <span
              data-tooltip-html={props.value}
              data-tooltip-id={props.column.id + ':' + props.row.id}
            >
              {this.onNoteDetail(props.column.id, props.row.original, props.value)}
            </span>
            <Tooltip
              id={props.column.id + ':' + props.row.id}
              delayShow={1000}
              style={{ color: '#fff !important' }}
              place='bottom'
            />
          </>
        ),
      },
    ];

    
    let columns = [];
    if (enabledRoles) {
      const extraColumns = enabledRoles.map((role) => (
        this.buildColumnForRole([role], role.code,true)));

      if (columns.length == 0) {
        columns = defaultColumns.filter(cols=>cols?.accessor);
      }

      for (let i in extraColumns) {
        const curColumnsAccessors = columns.map((cols) => cols.accessor);
        if (!curColumnsAccessors.includes(extraColumns[i].accessor)) {
          columns = columns.concat(extraColumns[i]);
        }
      }

      for (let i in customFieldColumns) {
        const curColumnsAccessors = columns.map((cols) => cols.accessor);
        if (!curColumnsAccessors.includes(customFieldColumns[i].accessor)) {
          columns = columns.concat(customFieldColumns[i]);
        }
      }
    } else {
      columns = defaultColumns.filter(cols=>cols?.accessor);
    }
    this.setState({ dynamicColumns: columns });

  }

  
  render() {
  
    const {
      list, allProjectFieldTypes, dynamicColumns
    } = this.state;


    const dynamicFieldsLoaded = allProjectFieldTypes?.length > 0;

    const rolesLoaded = this.state.projectRoleTypes?.length > 0;

    const dynamicColumnsLoaded = dynamicFieldsLoaded && rolesLoaded;

    
    const dynamicColumnsBuilt = dynamicColumnsLoaded && dynamicColumns.length > 0;

    if(dynamicColumnsLoaded && !dynamicColumnsBuilt){
      this.buildReportColumns();
    }
    

    const { listPref, SetLostJobPrefAction } = this.props;



    return (
      <div>
        <div className="ContactListReport">
        <TitleComponent data={{title:`Lost Jobs Report`}}/>
          <div>
            <Suspense fallback="loading"><Header props={this.props} /></Suspense>
          </div>
          <div
            className="noteProcessing"
            style={{ display: this.state.showProcessing ? 'block' : 'none' }}
          >
            <img src={loaderImage} alt="Loading" />
          </div>
          <div className="contactBox">
            <div className="container">
              <div className="row no-break-print">
              {dynamicColumnsBuilt&&(<div className="col-md-12">
                  <div className="table-responsive reportTableSection" ref={(el) => (this.componentRef = el)}>
                    {this.createCustomButtonGroup()}
                    <CustomTable
                      columns={dynamicColumns}
                      list={list}
                      preferences={listPref}
                      className="lost-job-table"
                      customTableTitle="Lost Jobs Report"
                      onSavePreferences={SetLostJobPrefAction}
                      isLoading={this.state.fetchRequest}
                      canShowRowSelection={false}
                      componentRef={this.componentRef}
                    />
                  </div>
                </div>)}
              </div>
            </div>
          </div>
          {this.state.noteModal && (
            <NotePopUp
              isOpen={this.state.noteModal}
              onClick={this.onNoteModalClose}
              noteInfo={this.state.noteData}
            />
          )}
          <Footer />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    listData: state.reports.lostJobsList,
    listPref: state.reports.lostJobsListPref,
    projectRoles: state.projectRoleType,
    projectFields: state.projectFields,
    //count: state.reports.reportList
  };
}

export default (
  withRouter(connect(mapStateToProps, {
    GetLostJobReportAction,
    SetLostJobPrefAction,
    GetLostJobPrefAction,
    getAllProjectRoleAction,
    getAllProjectFieldsAction,
  })(LostJobsReport))
);
