import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getMissingDataAction } from '../../../../actions/home';
import MissingDataWidgetTable from './MissingDataWidgetTable';
import { useAuth } from '../../../Auth/Auth';

const CommonMissingData = (props) => {
  const [accountId] = useState(localStorage.getItem('accountId'));
  const [userId] = useState(localStorage.getItem('userId'));
  const [list, setList] = useState([]);
  const [type, setType] = useState(0);
  const [modal, setModal] = useState(false);
  const [getDateTypeDataCalled, setGetDateTypeDataCalled] = useState(false);

  const dispatch = useDispatch();
  const isMissingData = useSelector((state) => state.home.isMissingData);

  useEffect(() => {
    if (isMissingData && isMissingData.data && getDateTypeDataCalled) {
      setList(isMissingData.data);
      setModal(true);
      setGetDateTypeDataCalled(false);
    }
  }, [isMissingData, getDateTypeDataCalled]);

  const listData = (type) => {
    const data = {
      accountId,
      userId,
      type: Number(type),
    };
    dispatch(getMissingDataAction(data));
  };

  const getDateTypeData = (e) => {
    setGetDateTypeDataCalled(true);
    setList([]);
    listData(e.target.dataset.type);
    setType(e.target.dataset.type);
  };

  const onClickClose = () => {
    setModal(false);
  };

  return (
    <div>
      <div className="col-md-12">
        <div className="row">
          <div className="col-md-6">
            <p>
              <a className="anchor" data-type={2} onClick={getDateTypeData}>
                Bids due with bid date in the past
              </a>
            </p>
            <p>
              <a className="anchor" data-type={3} onClick={getDateTypeData}>
                Bids pending with start dates in the past
              </a>
            </p>
            <p>
              <a className="anchor" data-type={4} onClick={getDateTypeData}>
                Bids pending with start dates within 2 weeks
              </a>
            </p>
          </div>
          <div className="col-md-6">
            <p>
              <a className="anchor" data-type={8} onClick={getDateTypeData}>
                Active jobs with completion dates in the past
              </a>
            </p>
          </div>
        </div>
      </div>
      {modal && (
        <MissingDataWidgetTable
          props={props}
          onClickClose={onClickClose}
          componentData={{
            missingData: list,
            type: type,
          }}
        />
      )}
    </div>
  );
};

export default withRouter(CommonMissingData);