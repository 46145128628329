import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { appConstants } from '../../../_constants';
import { connect } from 'react-redux';
import { GetProfitProjectionReportActionForecast } from '../../../actions/reports';
import ReactLoading from 'react-loading';
import { formatMoneyParens } from '../../../utils/number-formatters';
import { linearGradientDef } from '@nivo/core'

import { ResponsiveBar } from '@nivo/bar';

import { line, curveMonotoneX } from 'd3-shape';

const RealizedScatterCircle = ({ bars, yScale }) => {
  return (
    <>
      {bars.map((bar) => (
        // Render the circle SVG in chart using Bars co-ordinates.
        <circle
          key={`point-${bar.data.data.x}`}
          // Scale x-cordinate of the circle to the center of bar
          cx={bar.x + bar.width / 2}
          // Scale y-cordinate of the circle to top of the bar
          cy={yScale(bar.data.data.totalNetRealized + 0.2)}
          r={3}
          fill='white'
          stroke='white'
          style={{ pointerEvents: 'none' }}
        />
      ))}
    </>
  );
};

const ForecastScatterCircle = ({ bars, yScale }) => {
  return (
    <>
      {bars.map((bar) => (
        // Render the circle SVG in chart using Bars co-ordinates.
        <circle
          key={`point-${bar.data.data.x}`}
          // Scale x-cordinate of the circle to the center of bar
          cx={bar.x + bar.width / 2}
          // Scale y-cordinate of the circle to top of the bar
          cy={yScale(bar.data.data.totalNetPotential + 0.2)}
          r={3}
          fill='black'
          stroke='black'
          style={{ pointerEvents: 'none' }}
        />
      ))}
    </>
  );
};

const RealizedLine = ({ bars, yScale }) => {
  const lineColor = '#888888';

  bars = bars.filter((bar) => bar.data.id == 'netContractRealized');

  const lineGenerator = line()
    .x((bar) => bar.x + bar.width / 2)
    .y((bar) => yScale(bar.data.data.totalNetRealized))
    .curve(curveMonotoneX);

  //.curve(curveCatmullRom.alpha(0.5));

  return (
    <path
      d={lineGenerator(bars)}
      fill='none'
      stroke={lineColor}
      style={{ pointerEvents: 'none', strokeWidth: '2' }}
    />
  );
};

const ForecastLine = ({ bars, yScale }) => {
  const lineColor = '#bd7a0f';

  bars = bars.filter((bar) => bar.data.id == 'netContractRealized');

  const lineGenerator = line()
    .x((bar) => bar.x + bar.width / 2)
    .y((bar) => yScale(bar.data.data.totalNetPotential))
    .curve(curveMonotoneX);

  //.curve(curveCatmullRom.alpha(0.5));

  return (
    <path
      d={lineGenerator(bars)}
      fill='none'
      stroke={lineColor}
      style={{ pointerEvents: 'none', strokeWidth: '2' }}
    />
  );
};

function NetProfitForecastChartWidget(props) {
  const [accountId] = useState(localStorage.getItem('accountId'));
  const [projectList, setProjectList] = useState([]); // grouped list returned from profit projection report
  const [monthlyExArr, setMonthlyExArr] = useState([]); // monthly overhead array

  const [fetchRequest, setFetchRequest] = useState(false);
  const [showProcess, setShowProcess] = useState(true);
  const [dataFinancialFor, setDataFinancialFor] = useState([]);
  const [shouldCalculateData, setShouldCalculateData] = useState(false);

  const [timePeriodBinArray, setTimePeriodBinArray] = useState([]);
  const [netContractRealized, setNetContractRealized] = useState({});
  const [netContractPotential, setNetContractPotential] = useState({});
  const [overheadRealized, setOverHeadRealized] = useState({});
  const [overheadPotential, setOverHeadPotential] = useState({});

  useEffect(() => {
    props.GetProfitProjectionReportActionForecast({
      analyzeBy: 'jobStatusCodes',
      timePeriodType: 'next6months',
      projectStatus: 'all',
      accountId: accountId,
      filter: 'nextYear',
      skipContacts: true,
    });
  }, [accountId]);

  useEffect(() => {
    if (props.pprListData && props.pprListData.success === 1) {
      setProjectList(props.pprListData.data);
      setMonthlyExArr(props.pprListData.monthlyOverheadFixedArr);
      setFetchRequest(false);
      setShouldCalculateData(true);

      if (props.pprListData.data.length === 0) {
        setShowProcess(false);
        setFetchRequest(false);
      }
    }
  }, [props.pprListData]);

  useEffect(() => {
    if (shouldCalculateData) {
      dataFilter();
    }
  }, [shouldCalculateData]);

  const useNivo = true;

  const dataFilter = () => {
    let finalSum = [];
    let finalSumPotential = [];
    let timePeriodBinArray = [];
    let netContractRealized = {};
    let netContractPotential = {};
    let overheadRealized = {};
    let overheadPotential = {};
    let overheadFixed = {};

    let processedIds = [];

    let groupedDataArray = projectList;

    if (groupedDataArray.length > 0) {
      for (let x in groupedDataArray) {
        let groupProjects = groupedDataArray[x].projectData;
        for (let y in groupProjects) {
          const curProject = groupProjects[y];

          const useForGlobal = !processedIds.includes(curProject.objectId);
          if (useForGlobal) {
            // skip if already processed for total
            processedIds.push(curProject.objectId);
          } else {
            continue;
          }
          const projectInMonth = curProject.monthsArr;
          for (let m = 0; m < projectInMonth.length; m++) {
            const curMonthIdx = projectInMonth[m].name;

            if (!finalSum[m]) {
              finalSum.push({ monthName: curMonthIdx, value: 0 });
              finalSumPotential.push({ monthName: curMonthIdx, value: 0 });
              timePeriodBinArray.push(curMonthIdx);
              netContractRealized[curMonthIdx] = 0;
              netContractPotential[curMonthIdx] = 0;
              overheadRealized[curMonthIdx] = 0;
              overheadPotential[curMonthIdx] = 0;
            }

            if (!overheadFixed[curMonthIdx]) {
              overheadFixed[curMonthIdx] = 0;
            }

            if (projectInMonth[m]?.monthlyOverheadFixed) {
              overheadFixed[curMonthIdx] = projectInMonth[m].monthlyOverheadFixed;
            }

            if (curProject.isRealized) {
              finalSum[m].value += Math.round(projectInMonth[m].profit);

              netContractRealized[curMonthIdx] += Math.round(projectInMonth[m].profit);

              overheadRealized[curMonthIdx] =
                parseInt(overheadRealized[curMonthIdx], 10) +
                (projectInMonth[m].contractAmount * projectInMonth[m].monthlyOverheadVariable) /
                  100;
            } else {
              finalSumPotential[m].value += Math.round(projectInMonth[m].profit);

              netContractPotential[curMonthIdx] += Math.round(projectInMonth[m].profit);

              overheadPotential[curMonthIdx] =
                parseInt(overheadPotential[curMonthIdx], 10) +
                (projectInMonth[m].contractAmount * projectInMonth[m].monthlyOverheadVariable) /
                  100;
            }
          }
        }
      }

      let monthFinancialFor = [];
      let dataFinancialFor = [[['Month', 'Net Profit', 'Net Profit']]];
      for (let i = 0; i < timePeriodBinArray.length; i++) {
        monthFinancialFor.push(timePeriodBinArray[i]);
      }
      if (finalSum.length > 0) {
        let d = null;
        for (d in monthFinancialFor) {
          const curMonthIdx = monthFinancialFor[d];
          var windex = monthlyExArr.findIndex((p) => p.monthYear === curMonthIdx);
          var mIndex = finalSum.findIndex((p) => p.monthName === curMonthIdx);
          if (windex > -1 && mIndex > -1) {
            let graphData = [];
            let overheadCurrentValue =
              overheadRealized[curMonthIdx] === undefined || isNaN(overheadRealized[curMonthIdx])
                ? 0
                : overheadRealized[curMonthIdx];
            let monthlyOverheadFixed =
              monthlyExArr[windex].monthlyOverheadFixed === undefined ||
              isNaN(monthlyExArr[windex].monthlyOverheadFixed)
                ? 0
                : monthlyExArr[windex].monthlyOverheadFixed;

            let netAfterOverhead = Number(
              (finalSum[mIndex].value - (overheadCurrentValue + monthlyOverheadFixed)).toFixed(2)
            );
            netAfterOverhead = Math.round(netAfterOverhead);
            graphData.push(curMonthIdx);
            if (netAfterOverhead > 0) {
              graphData.push(netAfterOverhead);
              graphData.push(0);
            } else {
              graphData.push(0);
              graphData.push(netAfterOverhead);
            }
            dataFinancialFor.push(graphData);
          }
        }
      }

      const potentialProbability = 1;
      const mapDataForChart = timePeriodBinArray.map((item, index) => {

        const netProfitRealized = netContractRealized[item] - overheadRealized[item] - overheadFixed[item];
        return {
          periodBin: item,
          binWidth: 1,
          overheadRealized: -1 * overheadRealized[item],
          overheadFixed: -1 * overheadFixed[item],
          overheadPotential: -1 * overheadPotential[item],
          netContractRealized: netContractRealized[item],
          netContractPotential: netContractPotential[item],
          totalNetRealized:
            netContractRealized[item] - overheadRealized[item] - overheadFixed[item],
          totalNetPotential:
            netContractRealized[item] - overheadRealized[item] - overheadFixed[item] + potentialProbability * (netContractPotential[item] - overheadPotential[item]),
          overheadRealizedLabel: 'Fixed + Var Overhead',
          overheadFixedLabel: 'Fixed Overhead',
          overheadPotentialLabel: 'Potential Overhead',
          netContractRealizedLabel: 'Gross Profit',
          netContractPotentialLabel: 'Potential + Gross Profit',
          totalNetRealizedPositive: netProfitRealized>=0?netProfitRealized:0,
          totalNetRealizedNegative: netProfitRealized<0?netProfitRealized:0,
        };
      });

      setDataFinancialFor(useNivo ? mapDataForChart : dataFinancialFor);
      setShouldCalculateData(false);
      setTimePeriodBinArray(timePeriodBinArray);
      setNetContractRealized(netContractRealized);
      setNetContractPotential(netContractPotential);
      setOverHeadPotential(overheadPotential);
      setOverHeadRealized(overheadRealized);
      setShowProcess(false);
    }
  };

  return (
    <div className='row' style={{ minHeight: 300, fill: 'white' }}>
      <div className='col-md-12'>
        {dataFinancialFor.length > 1 &&
         
            <ResponsiveBar
              data={dataFinancialFor}
              indexBy='periodBin'
              colors={['#397E49', '#CE5449']}
              keys={[
                'totalNetRealizedPositive',
                'totalNetRealizedNegative',
              ]}
              /*  'Net Contract',
                'Net Contract (Potential)',
                'Fixed Overhead',
                'Var. Overhead',
                'Potential Var. Overhead',*/

              //label={(d) => `${String(d.data[(d.id+'Label')])}: ${d.value}`}

              tooltip={({ id, value, color, data }) => {

                const sectionLabel = String(data[id + 'Label']);
                const isPotential = id.includes('Potential');
                const idWithoutPotential = id.replace('Potential', 'Realized');
                const isOverhead = id.includes('overhead');

                const netValue = isPotential?data['totalNetPotential']:data['totalNetRealized']

                if(isPotential){
                  value= value + data[idWithoutPotential];
                }
                if(isOverhead && !(id==='overheadFixed')){
                  value = value + data['overheadFixed'];
                }
                return (<div
                  style={{
                    padding: 8,
                    color: 'white',
                    background: '#222222',
                  }}
                >
                  <span>{isPotential?'Potential Net Profit: ':'Net Profit: '}</span>
                  <div
                  style={{
                    color:netValue>0?'lightGreen':'#f68f7d',
                  }}>
                  <strong>{formatMoneyParens(netValue)}</strong>
                  </div>
                </div>)}
              }
              defs={[
                // using helpers
                // will inherit colors from current element
                linearGradientDef('gradientA', [
                    { offset: 0, color: 'inherit', opacity: 0.1 },
                    { offset: 100, color: 'inherit' },
                ]),
                linearGradientDef('gradientB', [
                  
                  { offset: 0, color: 'inherit'},
                  { offset: 100, color: 'inherit', opacity: 0.1  }
              ])
               
            ]}
              enableLabel={false}
              padding={0.3}
              innerPadding={0}
              axisTop={null}
              /*axisRight={{
                orient: 'right',
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: '',
                legendOffset: 0,
              }}*/
              axisBottom={{
                orient: 'bottom',
                tickSize: 1,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'Month',
                legendOffset: 36,
                legendPosition: 'middle',
                format: (v) => timePeriodBinArray[v - 0.5],
              }}
              axisLeft={{
                format: (v) => formatMoneyParens(v),
                legend: 'Net Profit',

                legendOffset: -70,
                legendPosition: 'middle',
              }}
              fill={[
                // match using object query
                { match: { id: 'netContractPotential' }, id: 'gradientA' },
                { match: { id: 'overheadPotential' }, id: 'gradientB' },
              ]}
              margin={{ top: 5, right: 40, bottom: 40, left: 80 }}
              //colors={{ scheme: 'spectral' }}
              borderWidth={0}
              borderColor={{
                from: 'color',
                modifiers: [['darker', 0.2]],
              }}
              layers={['grid', 'axes', 'bars' ]}//,ForecastScatterCircle,ForecastLine]}
              
              offset='diverging'
              valueFormat=' >($,'
              
              /*legends={[
                {
                  anchor: 'bottom',
                  direction: 'row',
                  justify: false,
                  translateX: 0,
                  translateY: 80,
                  itemsSpacing: 0,
                  itemWidth: 140,
                  itemHeight: 18,
                  itemTextColor: '#999',
                  itemDirection: 'right-to-left',
                  itemOpacity: 1,
                  symbolSize: 18,
                  symbolShape: 'square',
                  effects: [
                    {
                      on: 'hover',
                      style: {
                        itemTextColor: '#000',
                      },
                    },
                  ],
                },
              ]}*/
            />
            }
      </div>
      {showProcess && (
        <ReactLoading
          className='table-loader'
          type={appConstants.LOADER_TYPE}
          color={appConstants.LOADER_COLOR}
          height={appConstants.LOADER_HEIGHT}
          width={appConstants.LOADER_WIDTH}
        />
      )}

      {fetchRequest && (
        <div className='msgBox1 msgBoxEF'>
          <span>No data available!</span>
        </div>
      )}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    pprListData:
      state.reports?.profitProjectListForecast?.result,
  };
}

export default withRouter(
  connect(mapStateToProps, { GetProfitProjectionReportActionForecast })(
    NetProfitForecastChartWidget
  )
);
