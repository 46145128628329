import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { selectNavLinkAction } from '../../actions/sidebar.js';

function SidebarPreferences() {
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const location = useLocation();
  const dispatch = useDispatch();

  const handleClick = (event, index) => {
    dispatch(selectNavLinkAction(index));
  };

  return (
    <ul className='sidebarlist masterSection'>
      <li className='nav-item' onClick={(e) => handleClick(e, 2)}>
        <span
          className={
            location.pathname === '/preferences/account'
              ? 'nav-link menu-title active'
              : 'nav-link menu-title'
          }
        >
          <Link to='/preferences/account'>Account </Link>
        </span>
      </li>
      <li className='nav-item active' onClick={(e) => handleClick(e, 1)}>
        <span
          className={
            location.pathname === '/preferences/change-password'
              ? 'nav-link menu-title active'
              : 'nav-link menu-title'
          }
        >
          <Link to='/preferences/change-password'>Password</Link>
        </span>
      </li>
      
      <li className='nav-item' onClick={(e) => handleClick(e, 2)}>
        <span
          className={
            location.pathname === '/preferences/dashboard'
              ? 'nav-link menu-title active'
              : 'nav-link menu-title'
          }
        >
          <Link to='/preferences/dashboard'>Dashboard </Link>
        </span>
      </li>
      <li className='nav-item' onClick={(e) => handleClick(e, 2)}>
        <span
          className={
            location.pathname === '/preferences/notifications'
              ? 'nav-link menu-title active'
              : 'nav-link menu-title'
          }
        >
          <Link to='/preferences/notifications'>Notifications </Link>
        </span>
      </li>
    </ul>
  );
}

export default SidebarPreferences;
