import { createContext, useContext, useEffect, useMemo, useState } from 'react';

const MatchingContactsContext = createContext<any>({
  pushValidationData: () => void 0,
});

type MatchingContactsProviderProps = {
  children: React.ReactNode;
  onGroupedValidationDataChange?: (data: any[]) => void;
};

export const MatchingContactsProvider = ({
  children,
  onGroupedValidationDataChange = () => void 0,
}: MatchingContactsProviderProps) => {
  const [validationData, setValidationData] = useState<any[]>([]);

  const pushValidationData = (args: { affiliationIndex: number; type: string; data: any[] }) => {
    setValidationData((prev) => {
      const newArr = [...prev];

      const element = { ...(newArr[args.affiliationIndex] || {}) };

      element[args.type] = args.data;

      newArr[args.affiliationIndex] = element;

      return newArr;
    });
  };

  const groupedValidationData = useMemo(() => {
    const result = [] as any[];

    validationData.forEach((data) => {
      const { email, phone } = data || { email: [], phone: [] };

      phone.forEach((phoneData) => {
        if (!phoneData) return;

        const phoneValue = phoneData[0].phone;

        const foundValue = result.find(
          (item) =>
            item.value.phoneClean === phoneValue.phoneClean &&
            (!phoneValue.extPhone
              ? [null, '', undefined].includes(item.value.extPhone)
              : item.value.extPhone === phoneValue.extPhone)
        );

        if (foundValue) return;

        result.push({
          type: 'phone',
          value: phoneValue,
          data: phoneData,
        });
      });

      email.forEach((emailData) => {
        if (!emailData) return;

        const emailValue = emailData[0].email;

        const foundValue = result.find((item) => item.value.email === emailValue.email);

        if (foundValue) return;

        result.push({
          type: 'email',
          value: emailValue,
          data: emailData,
        });
      });
    });

    return result;
  }, [validationData]);

  useEffect(() => {
    onGroupedValidationDataChange(groupedValidationData);
  }, [groupedValidationData]);

  return (
    <MatchingContactsContext.Provider
      value={{
        pushValidationData,
        groupedValidationData,
      }}
    >
      {children}
    </MatchingContactsContext.Provider>
  );
};

export const useMatchingContactsContext = () => {
  const context = useContext(MatchingContactsContext);
  if (!context) {
    throw new Error('useMatchingContactsContext must be used within a MatchingContactsProvider');
  }
  return context as any;
};
