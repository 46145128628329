import * as actionTypes from './actionTypes';
import * as API from '../api/user';
//get all user details

import { notification } from 'antd';
import {useAuth} from '../components/Auth/Auth';

export const GetSignInUserRequest = () => ({
  type: actionTypes.GET_SIGNIN_USER_REQUEST,
});

export const GetSignInUserSuccess = (response) => ({
  type: actionTypes.GET_SIGNIN_USER_SUCCESS,
  payload: {
    response,
  },
});

export const GetSignInUserError = (error) => ({
  type: actionTypes.GET_SIGNIN_USER_ERROR,
  payload: {
    error,
  },
});

export const GetAllUserListRequest = () => ({
  type: actionTypes.GET_ALL_USER_LIST_REQUEST,
});

export const GetAllUserListSuccess = (response) => ({
  type: actionTypes.GET_ALL_USER_LIST_SUCCESS,
  payload: {
    response,
  },
});

export const GetAllUserListError = (error) => ({
  type: actionTypes.GET_ALL_USER_LIST_ERROR,
  payload: {
    error,
  },
});

export const getAllUserListAction = (data) => {
  return (dispatch) => {
    dispatch(GetAllUserListRequest());
    return API.getAllUserList(data)
      .then((response) => {
        dispatch(GetAllUserListSuccess(response));
      })
      .catch((error) => {
        dispatch(
          GetAllUserListError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};


export const GetSignInUserAction = (data) => {
  return (dispatch) => {
    dispatch(GetSignInUserRequest());
    const FormData = { userId: data };
    return API.getSignInUser(FormData)
      .then((response) => {
        const tempUser = {
          userEmail: response.data.result.data.username.toLowerCase(),
          username: response.data.result.data.username.toLowerCase(),
          password: response.data.result.data.username.toLowerCase(),
        };
        dispatch(GetSignInUserSuccess(tempUser));
      })
      .catch((error) => {
        dispatch(
          GetSignInUserError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};


export const EmailVerificationAction = (data) => {
  return (dispatch) => {
    dispatch(GetEmailVerifyRequest());
    const FormData = { token: data };
    return API.emailVerification(FormData)
      .then((response) => {
        dispatch(GetEmailVerifySuccess(response.data.result));
      })
      .catch((error) => {
        dispatch(GetEmailVerifyError(error.response));
        
      });
  };
};

export const GetEmailVerifyRequest = () => ({
  type: actionTypes.EMAIL_VERIFY_REQUEST,
});

export const GetEmailVerifySuccess = (result) => ({
  type: actionTypes.EMAIL_VERIFY_SUCCESS,
  payload: {
    
      userEmail: result?.data?.userEmail.toLowerCase(),
        username: result?.data?.username.toLowerCase(),
        msg: result.msg,
        ticketURL: result?.ticketURL,
        verifiedResult: result?.verifiedResult
  },
});


export const GetEmailVerifyError = (error) => ({

  type: actionTypes.EMAIL_VERIFY_ERROR,
  payload: {
    error,msg: error?.data?.result?.msg, verifiedResult:false 
  },
});

export const SignInUserRequest = () => ({
  type: actionTypes.SIGNIN_USER_REQUEST,
});

export const SignInUserSuccess = (response) => ({
  type: actionTypes.SIGNIN_USER_SUCCESS,
  payload: {
    response,
  },
});

export const SignInUserError = (error) => ({
  type: actionTypes.SIGNIN_USER_ERROR,
  payload: {
    error,
  },
});

export const  SignInUserAction = (data) => {
  const user = {
    username: data.username,
    userEmail: data.email,
    
    authId: data.authId,
    aud: data.aud,

    auth0token: data.auth0token,
    loginPlatform: 'web',
  };
   return (dispatch) => {
    dispatch(SignInUserRequest());

    return API.signInUser(user)
      .then((response) => {
        if (response && response.data) {
          if (response.data.result.success === 1) {
            const result = response.data.result;
            result.data.roleName = result.roleName;
            localStorage.setItem('sessionToken', result.data.sessionToken);
            localStorage.setItem('userId', result.data.objectId);
            localStorage.setItem('userContactAffiliationId', result.data.contactAffiliationId);
            localStorage.setItem('userContactId', result.data.contactId);
            localStorage.setItem('accountId', result.data.accountId.objectId);
            localStorage.setItem('baseUserRole', result.data.cppRoleId);
            localStorage.setItem('JWTTOKEN', result.token);
            localStorage.setItem(
              'modulePermissions',
              JSON.stringify(result.roleMatrix.modulePermissions)
            );
            localStorage.setItem('accountInfo', JSON.stringify(result.accountData));

            localStorage.removeItem('companyLogo');
            if(result.accountData.imageUrl && result.accountData.imageUrl !== undefined)
              localStorage.setItem('companyLogo',result.accountData.imageUrl);

            localStorage.setItem('companyName', result.accountData.companyName);
            localStorage.setItem('userInfo', JSON.stringify(result.data));
            localStorage.setItem('onboardingStatus', result.data.onboardingStatus);
            localStorage.setItem('loginType', result.data.cppRoleId);
            localStorage.setItem('contactId', result.data.contactId);
            
            dispatch(SignInUserSuccess(result));
          } else {
            dispatch(SignInUserError(response.data.result));
          }
        }
      })
      .catch((error) => {
        if (error.response) {
          alert(error.response.data.error);
          const { logout } = useAuth();
          logout();
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('Error', error.message);
        }
      });
  };
};

export const SignUpUserRequest = () => ({
  type: actionTypes.SIGNUP_USER_REQUEST,
});

export const SignUpUserSuccess = (response) => ({
  type: actionTypes.SIGNUP_USER_SUCCESS,
  payload: {
    response,
  },
});

export const SignUpUserError = (error) => ({
  type: actionTypes.SIGNUP_USER_ERROR,
  payload: {
    error,
  },
});

export const SignUpUserAction = (data) => {
  //debugger;
  console.log('data', data);
  return (dispatch) => {
    dispatch(SignUpUserRequest());
    const user = {
      accountId: data.accountId,
      invitedById: '',
      invitationSource: '',
      authId: data.objectId,
      userType: '',
      socialSecurityNumber: '',
      username: data.email,
      password: data.email,
      firstName: data.firstName,
      title: '',
      titleCode: '',
      userAddress: data.userAddress,
      userCity: data.userCity,
      userState: data.userState,
      userZip: data.userZip,
      userStatus: 'Employee',
      homePhone: data.phoneNumber,
      workPhone: data.phoneNumber,
      workExtension: '',
      pager: '',
      notes: '',
      mobile: data.phoneNumber,
      nextelId: '',
      status: '',
      trash: false,
      avatar: '',
    };

    return API.signUpUser(user)
      .then((response) => {
        // do something with the response
        if (response && response.data) {
          if (response.data.result.success === 1) {
            localStorage.setItem('sessionToken', response.data.result.data.sessionToken);
            localStorage.setItem('userId', response.data.result.data.objectId);
            localStorage.setItem('accountId', response.data.result.data.accountId.objectId);
            localStorage.setItem('baseUserRole', response.data.result.data.cppRoleId);
            localStorage.setItem('JWTTOKEN', response.data.result.token);
            localStorage.setItem(
              'modulePermissions',
              JSON.stringify(response.data.result.roleMatrix.modulePermissions)
            );
            localStorage.setItem('accountInfo', JSON.stringify(response.data.result.accountData));
            localStorage.setItem('companyName', response.data.result.accountData.companyName);
            localStorage.setItem('onboardingStatus', response.data.result.data.onboardingStatus);
            localStorage.setItem('userInfo', JSON.stringify(response.data.result.data));
            this.updateContactStatus(this.state.tempUser.objectId);
            dispatch(SignInUserSuccess(response.data.result));
          } else {
            dispatch(SignInUserError(response.data.result));
          }
        }
      })
      .catch((error) => {
        if (error.response) {
          alert(error.response.data.error);
          const { logout } = useAuth();
          logout();
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('Error', error.message);
        }
      });
  };
};

export const UpdateContactStatusRequest = () => ({
  type: actionTypes.UPDATE_CONTACT_STATUS_REQUEST,
});

export const UpdateContactStatusSuccess = (response) => ({
  type: actionTypes.UPDATE_CONTACT_STATUS_SUCCESS,
  payload: {
    response,
  },
});

export const UpdateContactStatusError = (error) => ({
  type: actionTypes.UPDATE_CONTACT_STATUS_ERROR,
  payload: {
    error,
  },
});

export const UpdateContactStatusAction = (objectId) => {
  return (dispatch) => {
    dispatch(UpdateContactStatusRequest());
    const data = { userStatus: 'Employee' };

    return API.updateContactStatus(objectId, data)
      .then((response) => {
        dispatch(UpdateContactStatusSuccess(response.data));
      })
      .catch((error) => {
        if (error.response) {
          alert(error.response.data.error);
          const { logout } = useAuth();
          logout();
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('Error', error.message);
        }
      });
  };
};

export const getUserEmailByIdRequest = () => ({
  type: actionTypes.GET_USER_EMAIL_BY_ID_REQUEST,
});

export const getUserEmailByIdSuccess = (response) => ({
  type: actionTypes.GET_USER_EMAIL_BY_ID_SUCCESS,
  payload: {
    response,
  },
});

export const getUserEmailByIdError = (error) => ({
  type: actionTypes.GET_USER_EMAIL_BY_ID_ERROR,
  payload: {
    error,
  },
});

export const getUserEmailByIdAction = (data) => {
  return (dispatch) => {
    dispatch(getUserEmailByIdRequest());
    return API.getUserEmailById(data)
      .then((response) => {
        dispatch(getUserEmailByIdSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          getUserEmailByIdError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};


export const getUserCppRoleRequest = () => ({
  type: actionTypes.GET_USER_CPOST_ROLE_REQUEST,
});

export const getUserCppRoleSuccess = (response) => ({
  type: actionTypes.GET_USER_CPOST_ROLE_SUCCESS,
  payload: {
    response,
  },
});

export const getUserCppRoleError = (error) => ({
  type: actionTypes.GET_USER_CPOST_ROLE_ERROR,
  payload: {
    error,
  },
});

export const getUserCppRoleAction = (userId) => {
  return (dispatch) => {
    dispatch(getUserCppRoleRequest());
    return API.getUserCppRole({
      roleId: localStorage.getItem('baseUserRole'),
      accountId: localStorage.getItem('accountId'),
    })
      .then((response) => {
        localStorage.setItem(
          'modulePermissions',
          JSON.stringify(response.data.result.data.modulePermissions)
        );
        localStorage.setItem('accountInfo', JSON.stringify(response.data.result.accountData));
        dispatch(SignInUserSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          getUserCppRoleError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};


//Change Password
export const ChangePasswordRequest = () => ({
  type: actionTypes.CHANGE_USER_PASSWORD_REQUEST,
});

export const ChangePasswordSuccess = (response) => ({
  type: actionTypes.CHANGE_USER_PASSWORD_SUCCESS,
  payload: {
    response,
  },
});

export const ChangePasswordError = (error) => ({
  type: actionTypes.CHANGE_USER_PASSWORD_ERROR,
  payload: {
    error,
  },
});

export const ChangeUserPasswordAction = (data) => {
  return (dispatch) => {
    dispatch(ChangePasswordRequest());
    return API.changeUserPassword(data)
      .then((response) => {
        dispatch(ChangePasswordSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          ChangePasswordError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

//Reset Password
export const ResetPasswordRequest = () => ({
  type: actionTypes.RESET_USER_PASSWORD_REQUEST,
});

export const ResetPasswordSuccess = (response) => ({
  type: actionTypes.RESET_USER_PASSWORD_SUCCESS,
  payload: {
    response,
  },
});

export const ResetPasswordError = (error) => ({
  type: actionTypes.RESET_USER_PASSWORD_ERROR,
  payload: {
    error,
  },
});

export const ResetUserPasswordAction = (data) => {
  return (dispatch) => {
    dispatch(ResetPasswordRequest());
    return API.resetUserPassword(data)
      .then((response) => {
        notification.success({message: 'Password Reset Request Sent Successfully'});
        dispatch(ResetPasswordSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          ResetPasswordError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};
