import * as actionTypes from '../actions/actionTypes';

const initState = {
  widgetPreferences: [],
  message: '',
  isfetched: false,
  isfetchedError: false,
};

export default (state = initState, action = {}) => {
  switch (action.type) {
   
    case actionTypes.GET_ALL_WIDGET_PREFERENCES_REQUEST:
      return {
        ...state,
        widgetPreferences: [],
        message: '',
        isfetched: false,
        isfetchedError: false
      };

    case actionTypes.GET_ALL_WIDGET_PREFERENCES_SUCCESS:
      return {
        ...state,
        widgetPreferences: action.payload.response.result.data,
        message: '',
        isfetched: true,
      };

    case actionTypes.GET_ALL_WIDGET_PREFERENCES_ERROR:
      return {
        ...state,
        message: '',
        isfetched: true,
        isfetchedError: true,
      };

    case actionTypes.UPDATE_WIDGET_PREFERENCE_REQUEST:
      return {
        ...state,
        widgetPreferences: [],
        message: '',
      };
    case actionTypes.UPDATE_WIDGET_PREFERENCE_SUCCESS:
      return {
        ...state,
        widgetPreferences: action.payload.response.result.widgetPreferences,
        message: '',
      };

    case actionTypes.UPDATE_WIDGET_PREFERENCE_ERROR:
      return {
        ...state,
        message: '',
      };

      case actionTypes.SET_WIDGET_ORDER_REQUEST:
      return {
        ...state,
        //widgetPreferences: [],
        message: '',
      };
    case actionTypes.SET_WIDGET_ORDER_SUCCESS:
      return {
        ...state,
        //widgetPreferences: action.payload.response.result.widgetPreferences,
        message: '',
      };

    case actionTypes.SET_WIDGET_ORDER_ERROR:
      return {
        ...state,
        message: '',
      };


    default:
      return state;
  }
};
