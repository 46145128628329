import * as actionTypes from './../Admin/actionTypes.js';
import * as API from '../../api/Admin/admin';

//get all user details
export const getExistingContactRequest = () => ({
  type: actionTypes.GET_EXISTING_CONTACT_REQUEST,
});

export const getExistingContactSuccess = (response) => ({
  type: actionTypes.GET_EXISTING_CONTACT_SUCCESS,
  payload: {
    response,
  },
});

export const getExistingContactError = (error) => ({
  type: actionTypes.GET_EXISTING_CONTACT_ERROR,
  payload: {
    error,
  },
});

//get all user details
export const getAllContactRequest = () => ({
  type: actionTypes.GET_ALL_CONTACT_REQUEST,
});

export const getAllContactSuccess = (response) => ({
  type: actionTypes.GET_ALL_CONTACT_SUCCESS,
  payload: {
    response,
  },
});

export const getAllContactError = (error) => ({
  type: actionTypes.GET_ALL_CONTACT_ERROR,
  payload: {
    error,
  },
});

export const getAllAdminContactAction = (data) => {
  return (dispatch) => {
    dispatch(getAllContactRequest());
    return API.getAllAdminContacts(data)
      .then((response) => {
        dispatch(getAllContactSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          getAllContactError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

//get all user details
export const createRequest = () => ({
  type: actionTypes.CREATE_CONTACT_REQUEST,
});

export const createSuccess = (response) => ({
  type: actionTypes.CREATE_CONTACT_SUCCESS,
  payload: {
    response,
  },
});

export const createError = (error) => ({
  type: actionTypes.CREATE_CONTACT_ERROR,
  payload: {
    error,
  },
});

export const createContactAction = (data) => {
  return (dispatch) => {
    dispatch(createRequest());
    const FormData = data;
    return API.createContact_deprecated(FormData)
      .then((response) => {
        console.log(response);
        dispatch(createSuccess(response.data));
      })
      .catch((error) => {
        dispatch(createError(error.response.data.error.msg));
      });
  };
};

//updates user details
export const updateContactRequest = () => ({
  type: actionTypes.UPDATE_CONTACT_REQUEST,
});

export const updateContactSuccess = (response) => ({
  type: actionTypes.UPDATE_CONTACT_SUCCESS,
  payload: {
    response,
  },
});

export const updateContactError = (error) => ({
  type: actionTypes.UPDATE_CONTACT_ERROR,
  payload: {
    error,
  },
});

export const updateContactAction = (data) => {
  console.log('Api called contact');
  return (dispatch) => {
    dispatch(updateContactRequest());
    const FormData = data;
    return API.updateContact(FormData)
      .then((response) => {
        dispatch(updateContactSuccess(response.data));
      })
      .catch((error) => {
        dispatch(updateContactError(error.response.data.error.msg));
      });
  };
};

//Delete user details
export const deleteContactRequest = () => ({
  type: actionTypes.DELETE_CONTACT_REQUEST,
});

export const deleteContactSuccess = (response) => ({
  type: actionTypes.DELETE_CONTACT_SUCCESS,
  payload: {
    response,
  },
});

export const deleteContactError = (error) => ({
  type: actionTypes.DELETE_CONTACT_ERROR,
  payload: {
    error,
  },
});

export const deleteContactAction = (data) => {
  return (dispatch) => {
    dispatch(deleteContactRequest());
    const FormData = data;
    return API.deleteContact(FormData)
      .then((response) => {
        dispatch(deleteContactSuccess(response.data));
      })
      .catch((error) => {
        dispatch(deleteContactError(error.response.data.error.msg));
      });
  };
};

//dashboard
export const getAdminDashboardRequest = () => ({
  type: actionTypes.ADMIN_DASHBOARD_REQUEST,
});

export const getAdminDashboardSuccess = (response) => ({
  type: actionTypes.ADMIN_DASHBOARD_SUCCESS,
  payload: {
    response,
  },
});

export const getAdminDashboardError = (error) => ({
  type: actionTypes.ADMIN_DASHBOARD_ERROR,
  payload: {
    error,
  },
});

export const getAdminDashboardAction = (data) => {
  return (dispatch) => {
    dispatch(getAdminDashboardRequest());
    return API.getDashboard(data)
      .then((response) => {
        dispatch(getAdminDashboardSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          getAdminDashboardError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};
