import { useFormContext } from 'react-hook-form';
import { useEffect } from 'react';
import { HorizontalLayout } from '../../../shared/form-controls/control-layouts/horizontal-layout';
import { formatDateAdd } from '../../../utils/date-formatters';
import FormInput from '../../../shared/form-controls/form-input';
import FormDatePicker from '../../../shared/form-controls/form-date-picker/form-date-picker';
import { useAppSelector } from '../../../shared/hooks/use-app-selector';
import { selectAllLeadJobStatusOptions } from '../../../selectors/jobStatusCode';
import { ProjectCompanyTeam } from '../../add-project-modal/sections/project-company-team';
import FormSelect from '../../../shared/form-controls/form-select/form-select';
import { useDebounce } from '../../../shared/hooks/use-debounce';
import { useAppDispatch } from '../../../shared/hooks/use-app-dispatch';
import {
  validateProjectName,
} from '../../../modules/projects';

type LeadInformationProps = {
  projectRoles?: any[];
  projectId?: string;
  isUpdate?: boolean;
  isAssigned?: boolean;
};

export const LeadInformation = ({ projectRoles = [], projectId, isUpdate = false, isAssigned = false }: LeadInformationProps) => {


  const dispatch = useAppDispatch();

  const form = useFormContext();
  const watchJobStatusCodes = form.watch('jobStatusCodes');

  const allProjectJobStatusCodes = useAppSelector(selectAllLeadJobStatusOptions);

  useEffect(() => {
    if (watchJobStatusCodes) {
      const jobStatusCodesCode = allProjectJobStatusCodes.find((st) => st.value === watchJobStatusCodes)?.code || '';
      form.setValue('jobStatusCodesCode', jobStatusCodesCode);
    }
  }, [watchJobStatusCodes]);

  const jobName = form.watch('jobName', '');

  useDebounce(async () => {
    if (!jobName) return;

    const data = await dispatch(validateProjectName({ jobName, projectId })).unwrap();

    const { valid, message } = data as any;

    if (valid) form.clearErrors('jobName');
    else form.setError('jobName', { message });
  }, [jobName]);
  

  return (
    <>
      <b>Lead Information</b>
      <hr />
      <FormSelect
        label='Project Status'
        options={allProjectJobStatusCodes}
        name='jobStatusCodes'
        Layout={HorizontalLayout}
        disabled={true}
      />
      <FormInput
        label='Job Name'
        required
        name='jobName'
        control={form.control}
        Layout={HorizontalLayout}
      />
      {/*<FormInput
        label='Lead Source'
        required
        name='leadSource'
        control={form.control}
        Layout={HorizontalLayout}
      />
      <FormInput
        label='Lead Cohort'
        name='leadCohort'
        control={form.control}
        Layout={HorizontalLayout}
  />*/}
      <FormDatePicker
        label='Lead Date'
        name='leadDate'
        control={form.control}
        minDate={formatDateAdd(-12, 'months')}
        maxDate={formatDateAdd(1, 'months')}
        Layout={HorizontalLayout}
      />

      <ProjectCompanyTeam projectRoles={projectRoles} isInternal showHeader={false} isLead={true} isUpdate={isUpdate} isAssigned={isAssigned} />
      
      {!isUpdate&&(<div>
      <FormInput
        label='Notes'
        control={form.control}
        name='jobNotes'
        textArea
        Layout={HorizontalLayout}
        placeholder='Lead Notes'
      /></div>)}
    </>
  );
};
