import { LayoutProps } from './layout-props';

export const HorizontalLayout = ({
  label,
  input,
  error,
  className = '',
  required = false,
  name,
  inputText,
  labelWidth = 4,
  inline = false,
  controlWidth = 8,
}: LayoutProps) => {
  return (
    <div className={`${inline?'':'form-group row '}${className}`}>
      {labelWidth>0&&
      <label htmlFor={name} className={`col-md-${labelWidth} col-form-label font-weight-bold`}>
        {label} {required && <span className='requiredField'>*</span>}
      </label>}
      <div className={`col-md-${controlWidth}`}>
        <div className='d-flex align-items-center gap-2'>
          {input}
          {inputText && <span className='form-input-text'>{inputText}</span>}
        </div>
        <span className='error'>{error}</span>
      </div>
    </div>
  );
};
