import * as actionTypes from './actionTypes.js';
import * as API from '../../api/Admin/widgets.js';

//get all user details
export const getAllWidgetsRequest = () => ({
  type: actionTypes.GET_ALL_WIDGETS_REQUEST,
});

export const getAllWidgetsSuccess = (response) => ({
  type: actionTypes.GET_ALL_WIDGETS_SUCCESS,
  payload: {
    response,
  },
});

export const getAllWidgetsError = (error) => ({
  type: actionTypes.GET_ALL_WIDGETS_ERROR,
  payload: {
    error,
  },
});

export const getAllWidgetsAction = (data) => {
  return (dispatch) => {
    dispatch(getAllWidgetsRequest());
    return API.getAllWidgets(data)
      .then((response) => {
        dispatch(getAllWidgetsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          getAllWidgetsError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

//get all user details
export const createRequest = () => ({
  type: actionTypes.CREATE_WIDGETS_REQUEST,
});

export const createSuccess = (response) => ({
  type: actionTypes.CREATE_WIDGETS_SUCCESS,
  payload: {
    response,
  },
});

export const createError = (error) => ({
  type: actionTypes.CREATE_WIDGETS_ERROR,
  payload: {
    error,
  },
});

export const createWidgetsAction = (data) => {
  return (dispatch) => {
    dispatch(createRequest());
    return API.createWidgets(data)
      .then((response) => {
        console.log(response);
        dispatch(createSuccess(response.data));
      })
      .catch((error) => {
        dispatch(createError(error.response.data.msg));
      });
  };
};
//Disable user details
export const disableWidgetsRequest = () => ({
  type: actionTypes.DISABLE_WIDGETS_REQUEST,
});

export const disableWidgetsSuccess = (response) => ({
  type: actionTypes.DISABLE_WIDGETS_SUCCESS,
  payload: {
    response,
  },
});
export const disableWidgetsError = (error) => ({
  type: actionTypes.DISABLE_WIDGETS_ERROR,
  payload: {
    error,
  },
});

export const disableWidgetsAction = (data) => {
  return (dispatch) => {
    dispatch(disableWidgetsRequest());
    return API.disableWidgets(data)
      .then((response) => {
        dispatch(disableWidgetsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(disableWidgetsError(error.response.data.error.msg));
      });
  };
};
