import { useEffect, useState } from 'react';
import { isAllowed } from '../../_constants';

type WithPermissionsProps = {
  children: React.ReactNode;
  required?: any[];
};

/**
 *
 * Permissions hook
 * TODO: need to implement after permission system will be integrated
 */
export const useWithPermissions = (props: { required: any[] } = { required: [] }) => {
  const [allowed, setAllowed] = useState(false);

  if (props) {
    // placeholder, please delete once you're using the props variable
   
    (() => void 0)();
  }

  useEffect(() => {
    // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
    setAllowed(props?.required? isAllowed(props.required[0])!:false);
  }, []);

  return allowed;
};

export const WithPermissions = ({ children, required = [] }: WithPermissionsProps) => {
  const allowed = useWithPermissions({ required });

  if (!allowed) return null;

  return children;
};
