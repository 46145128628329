import styles from '../field-actions.module.scss';

type WarningProps = {
  data?: any;
  onClick?: (data?: any) => void;
};

export const Warning = ({
  data = null,
  onClick = () => void 0,
}: WarningProps) => {
  if (!data) {
    return null;
  }

  return (
    <i
      className={`fa-solid fa-circle-exclamation text-danger ${styles.actionButton}`}
      role='button'
      onClick={() => onClick(data)}
    />
  );
};
