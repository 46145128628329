import _ from 'lodash';
import { components } from 'react-select';
import renderHTML from 'html-react-parser';
import {
  formatProjectOption,
  formatContactOption,
  formatCompanyOption,
  formatCompanyContactOption,
} from '../../utils/contact-formatters';
import styles from './dialog-section.module.scss';

/* PROJECT */
const createProjectOption = (affiliation: any, assignData: Array<any> = []) => {
  const assignedUsers = assignData.filter(
    (assign) => assign.contactAffiliationId === affiliation._id
  );
  const roleName = assignedUsers.map((assign) => assign.userRoleName).join(', ');
  const updatedAffiliation = { ...affiliation, roleName };

  return {
    label: renderHTML(formatProjectOption(updatedAffiliation)),
    value: affiliation.primaryEmail.email,
    objectId: affiliation.contactId,
    companyId: affiliation.companyId,
  };
};

const filterAssignedRoles = (
  type: string,
  assignData: Array<any>,
  projectRoles: Array<any>,
  affiliations: Array<any>
) => {
  // find roles that are external
  const typeRolesIds = projectRoles.filter((role) => role.type === type).map((role) => role._id);

  // find affiliation ids that assigned as type roles
  const typeAssignedUserIds = assignData
    .filter((assign) => typeRolesIds.includes(assign.projectRoleId))
    .map((assign) => assign.contactAffiliationId);

  // find affiliation with that ids
  const typeAssignedUsersGroup = affiliations
    .filter((affiliation) => typeAssignedUserIds.includes(affiliation._id))
    .map((affiliation) => createProjectOption(affiliation, assignData));

  return typeAssignedUsersGroup;
};

export const getProjectOptions = (contacts: Array<any>, assignData = [], projectRoles = []) => {
  const options: Array<any> = [];

  const internalGroup = filterAssignedRoles('internal', assignData, projectRoles, contacts);

  if (internalGroup.length) {
    options.push(createGroup('Internal Team', [...internalGroup]));
  }

  const internalGroupIds = internalGroup.map((opt) => opt.objectId); // if user in internal and external - show it only as INTERNAL OPTION
  const externalGroup = filterAssignedRoles('external', assignData, projectRoles, contacts).filter(
    (opt) => !internalGroupIds.includes(opt.objectId)
  );

  if (externalGroup.length) {
    options.push(createGroup('External Team', [...externalGroup]));
  }

  return options;
};
/* PROJECT */

/* COMPANY */
export const getCompanyOptions = (contacts: Array<any>, assignments: Array<any>, company: any) => {
  const options: Array<any> = [];

  if (!_.isEmpty(company) && company.companyEmail) {
    const companyOption = {
      label: renderHTML(formatCompanyOption(company)),
      value: company.companyEmail,
      objectId: company.objectId,
      companyId: company.objectId,
    };

    options.push(createGroup('This Company', [companyOption]));
  }

  const thisCompanyGroup = contacts.map((contact) =>
    createCompanyContactOption(contact, assignments)
  );

  if (thisCompanyGroup.length) {
    options.push(createGroup('Company Employees', [...thisCompanyGroup]));
  }

  return options;
};

const createCompanyContactOption = (contact: any, assignments: Array<any> = []) => {
  const affiliation = assignments?.find((assignment) => assignment.contactId === contact._id);

  if (affiliation) {
    contact.title = affiliation.title;
    contact.email = (affiliation.emailArr || []).find((em: any) => em.isPrimary)?.email || '';
  }

  return {
    label: renderHTML(formatCompanyContactOption(contact)),
    value: contact.email,
    objectId: contact._id,
  };
};
/* COMPANY */

/* CONTACT */
export const getContactOptions = (contact: any) => {
  const options: Array<any> = [];

  if (!_.isEmpty(contact)) {
    const contactOption = {
      label: renderHTML(formatContactOption(contact)),
      value: contact.email,
      objectId: contact.objectId,
    };

    options.push(createGroup('This Contact', [contactOption]));
  }

  return options;
};
/* CONTACT */

export const createGroup = (groupName: string, options: Array<any>) => {
  return {
    label: <div>{groupName}</div>,
    options: options,
  };
};

export const GroupHeading = ({ ...props }) => {
  return <components.GroupHeading {...props} className={styles.groupHeader} />;
};

export const formatGroupLabel = (data: any) => (
  <div className={styles.group}>
    <span>{data.label}</span>
    <span className={styles.groupBadge}>{data.options.length}</span>
  </div>
);

export const MenuList = (props: any) => {
  return (
    <components.MenuList {...props}>
      {props.children}
      <div>
        <div onClick={props.onClick} className={styles.groupHeader}>
          <div className={styles.group}>
            <span>
              <div>Select other contacts...</div>
            </span>
          </div>
        </div>
      </div>
    </components.MenuList>
  );
};
