export const inputTypeOptions = [
  { label: 'Divider', value: 'DIVIDER' },
  { label: 'Text input', value: 'TEXT_INPUT' },
  { label: 'Number input', value: 'NUMBER_INPUT' },
  { label: 'Date input', value: 'DATE_INPUT' },
  { label: 'Single select', value: 'SINGLE_SELECT' },
  { label: 'Multi select', value: 'MULTI_SELECT' },
  { label: 'Checkbox', value: 'CHECKBOX' },
  { label: 'Radio', value: 'RADIO' },
  { label: 'Rating', value: 'RATE' },
];

// lead, bidding, active, post-construction, all
export const projectStages = [
  { label: 'Lead (L)', value: 'lead' },
  { label: 'Bidding (B,BP)', value: 'bidding' },
  { label: 'Pre-construction (P1,P2...)', value: 'pre-construction' },
  { label: 'Active Construction (SNS,A)', value: 'active' },
  { label: 'Post-construction (D,W,C)', value: 'post-construction' },
  { label: 'All', value: 'all' },
  { label: 'Service (SVC)', value: 'service' },
];

// general, deal, marketing, additional
export const projectSections = [
  { label: 'Project Information', value: 'general', order: 0, section: 'project-information' },
  { label: 'Location & Description', value: 'location', order: 1, section: 'location-and-description' },
  { label: 'Deal Information', value: 'deal', order: 2, section: 'deal-information' },
  { label: 'Marketing Information', value: 'marketing', order: 3, section: 'marketing-information' },
  { label: 'Additional Information', value: 'additional', order: 4, section: 'additional-information' },
];

// general, deal, marketing, additional
export const projectSectionsForRoles = [
  { label: 'Project Information', value: 'general', order: 0, section: 'project-information' },
  { label: 'Location & Description', value: 'location', order: 1, section: 'location-and-description' },
  { label: 'Deal Information', value: 'deal', order: 2, section: 'deal-information' },
  { label: 'Marketing Information', value: 'marketing', order: 3, section: 'marketing-information' },
  { label: 'Additional Information', value: 'additional', order: 4, section: 'additional-information' },
  { label: 'Internal Contacts', value: 'internal-roles', order: 5, section: 'internal-roles' },
  { label: 'External Contacts', value: 'external-roles', order: 6, section: 'external-roles' },
];

// none, logDate, bidDue, startDate, deliveredDate, endDate, warrantyExpirationDate
export const projectDates = [
  { label: 'None', value: 'none' },
  { label: 'Today', value: 'today' },
  { label: 'Next Week', value: 'nextWeek' },
  { label: 'Next Month', value: 'nextMonth' },
  { label: 'Next Year', value: 'nextYear' },
  { label: 'Log Date', value: 'logDate' },
  { label: 'Bid Due', value: 'bidDue' },
  { label: 'Start Date', value: 'startDate' },
  { label: 'Delivered Date', value: 'deliveredDate' },
  { label: 'End Date', value: 'endDate' },
  { label: 'Warranty Expiration Date', value: 'warrantyExpirationDate' },
];

// none, today, nextWeek, nextMonth, nextYear
export const contactDates = [
  { label: 'None', value: 'none' },
  { label: 'Today', value: 'today' },
  { label: 'Next Week', value: 'nextWeek' },
  { label: 'Next Month', value: 'nextMonth' },
  { label: 'Next Year', value: 'nextYear' },
];

// company, contact, affiliation, employee, all
export const contactStages = [
  { label: 'Company', value: 'company' },
  { label: 'Contact', value: 'contact' },
  { label: 'Affiliation (Contact @ Company)', value: 'affiliation' },
  { label: 'Employee', value: 'employee' },
];

// company, contact, affiliation, employee
export const contactSections = [
  { label: 'Company', value: 'company' },
  { label: 'Contact', value: 'contact' },
  { label: 'Affiliation (Contact @ Company)', value: 'affiliation' },
  { label: 'Employee', value: 'employee' },
];

export const aggregationOptions = [
  { label: 'None', value: 'none' },
  { label: 'Average', value: 'average' },
  { label: 'Min', value: 'min' },
  { label: 'Max', value: 'max' },
  { label: 'Sum', value: 'sum' },
  { label: 'Count', value: 'count' },
]

export type CustomFieldsCommonProps = {
  name: string;
  label: string;
  control: any;
  Layout: any;
  disabled?: boolean;
};

export const getProjectStageByCode = (jobStatusCodesCode: string) => {
  const leadStage = ['L', 'NP'];
  const biddingStage = ['B', 'BP', 'NC', 'R', 'XC', 'OH'];
  const preConstructionStage = ['P1', 'P2', 'P3', 'OHP', 'PC', 'P1P', 'P2P', 'P3P'];
  const activeStage = ['A', 'SUSP', 'SNS'];
  const postConstructionStage = ['D', 'W', 'C', 'XT'];
  const serviceStage = ['SVC', 'SVC-C','SVC-D'];

  if (leadStage.includes(jobStatusCodesCode)) return 'lead';
  if (biddingStage.includes(jobStatusCodesCode)) return 'bidding';
  if (preConstructionStage.includes(jobStatusCodesCode)) return 'pre-construction';
  if (activeStage.includes(jobStatusCodesCode)) return 'active';
  if (postConstructionStage.includes(jobStatusCodesCode)) return 'post-construction';
  if (serviceStage.includes(jobStatusCodesCode)) return 'service';
  return '';
};

export const isCurrentOrPrevStage = (projectStage: string, targetStage: string) => {
  if (!targetStage) return false;

  if(projectStage === 'service'){
    return targetStage === 'service';
  }

  const stagesOrder = getProjectStagesOrder();
  const projectStageIndex = stagesOrder.indexOf(projectStage);
  const targetStageIndex = stagesOrder.indexOf(targetStage);

  return targetStageIndex <= projectStageIndex;
};

export const getProjectStagesOrder = () =>
  projectStages.filter(({ value }) => value !== 'all').map(({ value }) => value);
