import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { useCallback, useMemo, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import useCountryCodes from '../../../../shared/hooks/use-country-codes';
import { formatPhone } from '../../../../utils/formatters';
import styles from '../field-actions.module.scss';

type FieldActionsProps = {
  name: string;
  type: string;
  data: any;
  onEditCompany?: () => void;
};

const Copy = ({ name = '', type = '', data = {}, onEditCompany }: FieldActionsProps) => {
  const countries = useCountryCodes();
  const [isDisabled, setIsDisabled] = useState<boolean>(true);

  const { control, trigger } = useFormContext<any>();
  const { append } = useFieldArray({ control, name });

  const listItemClass = 'dropdown-item d-flex align-items-center justify-content-between';

  const buildEmailMenu = useCallback((emails: Array<any>) => {
    const emailsFiltered = emails.filter((email) => email.email);

    setIsDisabled(emailsFiltered.length === 0);

    return emailsFiltered.map((data) => {
      const { _id, email, label } = data;

      return (
        <li key={_id} onClick={(e) => onItemPush(e, data)}>
          <span className={listItemClass} role='button'>
            {email}
            {label && <span className='ms-2 badge rounded-pill bg-light text-dark'>{label}</span>}
          </span>
        </li>
      );
    });
  }, []);

  const buildPhoneMenu = useCallback(
    (phones: Array<any>) => {
      const phonesFiltered = phones.filter((phone) => phone.phone);

      setIsDisabled(phonesFiltered.length === 0);

      return phonesFiltered.map((data: any) => {
        const { _id, type } = data;

        return (
          <li key={_id} onClick={(e) => onItemPush(e, data)}>
           <span className={listItemClass} role='button'>
              {formatPhone(data, countries, true)}
              {type && <span className='ms-2 badge rounded-pill bg-light text-dark'>{type}</span>}
            </span>
          </li>
        );
      });
    },
    [countries]
  );

  const buildAddressMenu = useCallback((addresses: Array<any>) => {
    const addressesFiltered = addresses.filter(
      ({ address1, address2, city, state, country, zipCode }) =>
        address1 || address2 || city || state || country || zipCode
    );

    setIsDisabled(addressesFiltered.length === 0);

    return addressesFiltered.map((data: any) => {
      const { address1, address2, country, state, city, zipCode, _id, label } = data;
      const addressText = [address1, address2, city, state, country, zipCode]
        .filter((addr) => addr)
        .join(', ');

      return (
        <li key={_id} onClick={(e) => onItemPush(e, data)}>
          <span className={listItemClass} role='button' title={addressText}>
            {_.truncate(addressText, { length: 60 })}
            {label && <span className='ms-2 badge rounded-pill bg-light text-dark'>{label}</span>}
          </span>
        </li>
      );
    });
  }, []);

  const menuItems = useMemo(() => {
    if (!_.isEmpty(data)) {
      const { emailArr, phoneArr, addressArr } = data;

      switch (type) {
        case 'email':
          return buildEmailMenu(emailArr);
        case 'phone':
          return buildPhoneMenu(phoneArr);
        case 'address':
          return buildAddressMenu(addressArr);
      }
    }

    return null;
  }, [data]);

  const editCompanyMenuItem = useMemo(() => {
    const { isAccountAffiliation } = data;

    if (isAccountAffiliation) return null;

    return (
      <>
        <li>
          <hr className='dropdown-divider' />
        </li>
        <li onClick={onEditCompany}>
          <span className='dropdown-item' role='button'>
            Edit Company
          </span>
        </li>
      </>
    );
  }, [data]);

  const onItemPush = useCallback(
    async (e: any, data: any) => {
      e && e.preventDefault();
      const ok = await trigger(name);
      const pushData = { ...data, isPrimary: false, _id: uuidv4() };

      ok && append(pushData);
    },
    [type]
  );

  if (_.isEmpty(data)) {
    return null;
  }

  return (
    <div className={`${styles.addButton} dropdown ${isDisabled ? styles.disabled : ''}`}>
      <div className='dropdown-toggle' data-bs-toggle='dropdown' role='button'>
        Copy company {type}
      </div>
      <ul className='dropdown-menu'>
        {menuItems}
        {editCompanyMenuItem}
      </ul>
    </div>
  );
};

export default Copy;
