type ProjectCustomSectionProps = {
  customFields: any;
  sectionName: string;
};

export const ProjectCustomSection = ({
  customFields,
  sectionName,
}: ProjectCustomSectionProps) => {
    
  return (
    <>
      <b>{sectionName}</b>
      <hr />
      {customFields}
    </>
  );
};
