import React from 'react';
import bannerImage from '../static/images/Cobilogo.png';
import { renderToStaticMarkup } from 'react-dom/server';
import {useAuth} from '../Auth/Auth';

class Header2 extends React.Component {
  constructor(props) {
    super(props);
    let localActiveLanguage = window.localStorage.getItem('activeLanguage');
    this.props.initialize({
      languages: [
        { name: 'English', code: 'en' },
        { name: 'French', code: 'fr' },
        /* { name: 'Spanish', code: 'es' },      */
      ],
      options: {
        renderToStaticMarkup,
        renderInnerHtml: true,
        defaultLanguage:
          localActiveLanguage !== '' && localActiveLanguage !== null ? localActiveLanguage : 'en',
      },
    });
    this.state = {
      searchTxt: '',
      isUsingRedux: localActiveLanguage,
      sessionToken: localStorage.getItem('sessionToken'),
    };
    
  }

  

  login() {
    const { login } = useAuth();
    login(false);
  }

  logout() {
    const { logout } = useAuth();
          logout();
  }

  componentDidUpdate(prevProps, prevState) {
    
  }
  searchData = (e) => {
    e.preventDefault();
    let searchTxt = this.state.searchTxt;
    //  console.log(this.props.props.history);
    this.props.props.history.push({
      pathname: '/search',
      state: {
        //objectId:this.props.location.state.row.objectId,
        search: searchTxt,
      },
    });
  };
  handleChange = (e) => {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  render() {
    //console.log(this.props.props);
    return (
      <header className="registerheader">
        <div className="container">
          <div className="row">
            <div className="col-sm-6">
              <div className="logo">
                <img src={bannerImage} alt="Logo" />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="right">
                <ul>
                  <li>
                    <a className="btn" onClick={this.login.bind(this)}>
                      Sign In
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default (Header2);
