import { formatBidDueDate, formatDate } from './date-formatters';
import { Tooltip } from 'react-tooltip';
import { customSortByKey, multiSelectFilter } from '../components/Common/CustomTable';
import { DateColumnFilter } from '../components/Common/CustomTableComponents/DateColumnFilter';
import { SelectColumnFilter } from '../components/Common/CustomTableComponents/SelectColumnFilter';
import { formatMoney } from './number-formatters';
import {
  formatPhoneArrayHTML,
  formatAddressText,
  formatAddressHTML,
} from '../utils/contact-formatters';
import { formatPhone } from '../utils/formatters';
import moment from 'moment';


export const onBoolean = (cell, row, value) => {
  if (value) {
    return <span>No</span>;
  } else {
    return <span>Yes</span>;
  }
};

export const onText = (cell, row, value) => {
  if (value) {
    return <span>{value}</span>;
  } else {
    return <span>-</span>;
  }
};

export const onEmail=(cell, row, enumObject, rowIndex)=>{
  let emailHtml = '';
  let isExport = false;
  if (row.original) {
    row = row.original;
    isExport = true;
  }
  if (row && row.emailArr && row.emailArr.length > 0) {
    if (!isExport) {
      row.emailArr.map((item) => {
        if (emailHtml !== '') {
          emailHtml = emailHtml + '<br/>';
        }
        emailHtml = emailHtml + "<a href=mailto:'" + item.email + '>' + item.email + '</a>';
        return true;
      });
      return <div dangerouslySetInnerHTML={{ __html: emailHtml }} />;
    } else {
      return row.emailArr.map((email) => email.email).join(', ');
    }
  } else {
    return <span>-</span>;
  }
}

const isValidISODate = (inputDate) => {
  const dateParsed = Date.parse(inputDate);
  const isoDate = new Date(dateParsed).toISOString();
  return isoDate === inputDate;
}

export const onDate = (cell, row, value) => {
  if (row[cell] && typeof row[cell] === 'object' && !value) {
    value = row[cell];
  }
  if(value && typeof value === 'object'){
    if (value?.iso) {
      return <span>{formatDate(value.iso)}</span> ?? <span>-</span>;
    } else {
      return <span>{formatDate(value)}</span>  ?? <span>-</span>;
    }
  } else if(typeof value === 'string') {
    // check if value is a datetime string 
    if(isValidISODate(value)){
      return <span>{formatDate(value) ?? '-'}</span>;
    }else{
      return <span>-</span>;
    }
  }
};

export const onDateTime = (cell, row, value) => {
  if (row[cell] && typeof row[cell] === 'object') {
    if (row[cell]?.iso) {
      return formatBidDueDate(row[cell].iso) ?? '-';
    } else {
      return formatBidDueDate(row[cell]) ?? '-';
    }
  } else {
    return <span>-</span>;
  }
};

export const onAddress = (cell, row, enumObject, rowIndex) => {
    if (row.original) {
      row = row.original;
      if (row && row.addressArr) {
        let addressArr = row.addressArr.map((v) => formatAddressText(v));
        return addressArr?.join('\n');
      }
    }
    if (row && row.addressArr) {
      let addressArr = row.addressArr.map((v) => formatAddressHTML(v));

      return addressArr;
    } else {
      return <span>-</span>;
    }
  }

export const onURL= (cell, row, enumObject, rowIndex) =>{
  const companyWebsite = row[cell];
  const url =
    companyWebsite?.length > 1
      ? companyWebsite?.startsWith('http')
        ? companyWebsite
        : 'http://' + companyWebsite
      : '';

  if (url.length > 0) {
    return (
    
          <a href={url} target='_blank' rel='noreferrer'>
            <span>{companyWebsite.trim()}</span>
          </a>
       
    );
  } else {
    return '-';
  }
}

export const onPhone= (cell, row, enumObject, rowIndex) =>{
  if (row.original) {
    row = row.original;
    if (row && row.phoneArr && row.phoneArr.length > 0) {
      return row.phoneArr.map((phone) => formatPhone(phone, [], true)).join(', ');
    } else {
      return <span>-</span>;
    }
  }
  if (row && row.phoneArr && row.phoneArr.length > 0) {
    return <div dangerouslySetInnerHTML={{ __html: formatPhoneArrayHTML(row.phoneArr) }} />;
  } else {
    return <span>-</span>;
  }
};

export const onCurrency = (cell, row, value) => {
  let data = value ? formatMoney(Math.round(value)) : '-';

  return <div className='linkText textRight'>{data}</div>;
};

export const onDateDynamic = (accessor, dateField, valueDefinition) => {
  if (dateField) {
    if (valueDefinition?.allowTime) {
      return <span>{formatBidDueDate(dateField)}</span>;
    } else {
      return <span>{formatDate(dateField)}</span>;
    }
  } else {
    return <span>-</span>;
  }
};

export const onNumericDynamic = (accessor, row, numericField, valueDefinition) => {
  if (numericField) {
    return <span>{numericField}</span>;
  } else {
    return <span>-</span>;
  }
};

export const onTextDynamic = (columnId, row, accessor, valueDefinition) => {
  const textField = row.original?.dynamicFieldData?.[accessor];
  if (textField) {
    const hasLabel = textField?.label?.length >= 0;
    let textValue = hasLabel ? textField.value ?? '-' : textField ?? '-';
    const embeddedValue = textValue?.value ?? textValue;
    if (embeddedValue) {
      textValue = embeddedValue;
    }
    const isArray = Array.isArray(textValue);
    if (isArray) {
      textValue = textValue.filter(Boolean).join(',');
    }

    if(typeof textValue === 'object'){
        if(Object.keys(textValue).length > 0){
          textValue = 'ERROR';
        }else{
          textValue = '-';
        }
      }

    if (valueDefinition?.isUrl) {
      return <span>{textValue}</span>;
    } else if (hasLabel) {
      const tooltipId = row.Id + columnId;
      return (
        <>
          <span data-tooltip-content={textField?.label} data-tooltip-id={tooltipId}>
            {textValue ?? '-'}
          </span>
          <Tooltip id={tooltipId} delayShow={200} place='bottom' />
        </>
      );
    } else {
      return <span>{textValue ?? '-'}</span>;
    }
  }else{
    return <span>-</span>;
  }
};

export const onBooleanDynamic = (accessor, booleanField, valueDefinition) => {
  if (booleanField) {
    if (booleanField === true) {
      return <span>Yes</span>;
    } else {
      return <span>No</span>;
    }
  } else {
    return <span>-</span>;
  }
};

export const onRatingDynamic = (accessor, rating, valueDefinition) => {
  if (rating) {
    return <span>{rating}</span>;
  } else {
    return <span>-</span>;
  }
};

export const buildDynamicColumns = (projectFieldTypes, hidden = true) => {
  const columns = projectFieldTypes?.map((field) => {
    switch (field?.controlType) {
      case 'DATE_INPUT':
        return buildDateColumn(field, hidden);
      case 'RATE':
        return buildRatingColumn(field, hidden);
      case 'SINGLE_SELECT':
        return buildSelectColumn(field, hidden);
      case 'NUMBER_INPUT':
        return buildNumericColumn(field, hidden);
      case 'TEXT_INPUT':
        return buildTextColumn(field, hidden);
      case 'RADIO':
        return buildSelectColumn(field, hidden);
      case 'CHECKBOX':
        return buildBooleanColumn(field, hidden);
      case 'MULTI_SELECT':
        return buildSelectColumn(field, hidden);
    }
  });
  return columns;
};

const configureAccessor = (row, objectId) => {
  if(!row?.dynamicFieldData) return null;

  if(!(row.dynamicFieldData[objectId] || row.dynamicFieldData[objectId] === '')) return null;
  
  return new Date(row.dynamicFieldData[objectId]);
};

export const buildDateColumn = (field, hidden = true) => {
  return {
    Header: field?.shortName,
    LongHeader: field?.name,
    valueDefinition: field?.valueDefinition,
    accessor: (row) => configureAccessor(row, field.objectId),
    filter: DateColumnFilter,
    allowDateRangeFilter: true,
    hidden: hidden,
    sortType: customSortByKey,
    id: field.objectId,
    width: 100,
    Cell: (props) => onDateDynamic(props.column.id, props.value, props.column.valueDefinition),
  };
};

export const buildRatingColumn = (field, hidden = true) => {
  return {
    Header: field?.shortName,
    LongHeader: field?.name,
    accessor: field?.objectId ? 'dynamicFieldData.' + field?.objectId : null,
    valueDefinition: field?.valueDefinition,
    sortType: customSortByKey,
    Filter: SelectColumnFilter,
    hidden: hidden,
    width: 100,
    Cell: (props) => onRatingDynamic(props.column.id, props.value, props.column.valueDefinition),
  };
};

export const buildBooleanColumn = (field, hidden = true) => {
  return {
    Header: field?.shortName,
    LongHeader: field?.name,
    accessor: field?.objectId ? 'dynamicFieldData.' + field?.objectId : null,
    valueDefinition: field?.valueDefinition,
    Filter: SelectColumnFilter,
    hidden: hidden,
    sortType: customSortByKey,
    width: 100,
    Cell: (props) => onBooleanDynamic(props.column.id, props.value, props.column.valueDefinition),
  };
};

export const buildNumericColumn = (field, hidden = true) => {
  return {
    Header: field?.shortName,
    LongHeader: field?.name,
    accessor: field?.objectId ? 'dynamicFieldData.' + field?.objectId : null,
    valueDefinition: field?.valueDefinition,
    hidden: hidden,
    sortType: customSortByKey,
    width: 100,
    Cell: (props) =>
      onNumericDynamic(props.column.id, props.row, props.value, props.column.valueDefinition),
  };
};

export const buildSelectColumn = (field, hidden = true) => {
  const useLabelAccessor =
    field?.valueDefinition?.type === 'array' || field?.valueDefinition?.dataSrc;
  const accessor = field?.objectId
    ? 'dynamicFieldData.' + field?.objectId + (useLabelAccessor ? '.label' : '')
    : null;

  return {
    Header: field?.shortName,
    LongHeader: field?.name,
    accessor: accessor,
    accessorObjId: field?.objectId,
    valueDefinition: field?.valueDefinition,
    Filter: SelectColumnFilter,
    hidden: hidden,
    sortType: customSortByKey,
    width: 100,
    Cell: (props) =>
      onTextDynamic(
        props.column.id,
        props.row,
        props.column.accessorObjId,
        props.column.valueDefinition
      ),
  };
};

export const buildTextColumn = (field, hidden = true) => {
  const useLabelAccessor =
    field?.valueDefinition?.type === 'array' || field?.valueDefinition?.dataSrc;
  const accessor = field?.objectId
    ? 'dynamicFieldData.' + field?.objectId + (useLabelAccessor ? '.label' : '')
    : null;
  return {
    Header: field?.shortName,
    LongHeader: field?.name,
    accessor: accessor,
    accessorObjId: field?.objectId,
    valueDefinition: field?.valueDefinition,
    hidden: hidden,
    sortType: customSortByKey,
    width: 100,
    Cell: (props) =>
      onTextDynamic(
        props.column.id,
        props.row,
        props.column.accessorObjId,
        props.column.valueDefinition
      ),
  };
};

export const buildColumnForField = (filteredFields, fieldCode, hidden = false) => {
  const foundField = filteredFields.find((field) => field.code == fieldCode);
  if (!foundField) return;

  return buildDynamicColumns([foundField], hidden)['0'];
};
