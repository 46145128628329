import { useRef } from 'react';
import styles from './style.module.css';

type Duration = {
  years: number;
  months: number;
  days: number;
};

type DurationPickerProps = {
  onValueChange: (duration: Duration) => void;
  name?: string;
  duration: Duration;
  maxYearsDuration?: number;
  disabled?: boolean;
};

const DurationPicker = ({
  onValueChange,
  name = 'duration',
  duration = { years: 0, days: 0, months: 0 },
  maxYearsDuration = 4,
  disabled = false,
}: DurationPickerProps) => {

  const yearsInputRef = useRef<HTMLInputElement>(null);
  const monthsInputRef = useRef<HTMLInputElement>(null);
  const daysInputRef = useRef<HTMLInputElement>(null);
  const durationInputRef = useRef<HTMLInputElement>(null);

  const { years, months, days } = duration;

  const onInput = () => {
    if (!yearsInputRef.current || !monthsInputRef.current || !daysInputRef.current) {
      return;
    }

    const years = Math.abs(+yearsInputRef.current.value);
    const months = Math.abs(+monthsInputRef.current.value);
    const days = Math.abs(+daysInputRef.current.value);

    onValueChange({years, months, days});
  };

  return (
    <div className={`${styles.container}`} data-testid="durationPicker">
      <div className={styles.inputContainer}>
        <input className='form-control' ref={yearsInputRef} value={years} disabled={disabled} min='0' max={maxYearsDuration} id='durationpicker-year' placeholder='0' type='number' onInput={onInput} data-testid="durationPicker-input-year" />
        <span className='durationpicker-label'>y</span>
      </div>
      <div className={styles.inputContainer}>
        <input className='form-control' ref={monthsInputRef} value={months} disabled={disabled} min='0' id='durationpicker-month' placeholder='0' type='number' onInput={onInput} data-testid="durationPicker-input-month" />
        <span className='durationpicker-label'>m</span>
      </div>
      <div className={styles.inputContainer}>
        <input className='form-control' ref={daysInputRef} value={days} disabled={disabled} min='0' id='durationpicker-day' placeholder='0' type='number' onInput={onInput} data-testid="durationPicker-input-day" />
        <span className='durationpicker-label'>d</span>
      </div>
      <input type='hidden' id={name} name={name} value={`${years}y ${months}m ${days}d`} ref={durationInputRef} data-testid="durationPicker-input-duration" />
    </div>
  );
};

export default DurationPicker;
