//import { Collapse } from 'antd';
import { useCallback } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { DotsButton } from '../../shared/buttons/dots-button';
import { useConfirm } from '../../shared/confirmation-dialog';
import { DisabledFieldsProvider, useDisabledFieldsContext } from '../../shared/disabled-fields';
import { Dropdown } from '../../shared/dropdown';
import { useWithPermissions } from '../../shared/with-permissions';
import { AffiliationForm } from './affiliation-form';
import { moduleConstants } from '../../_constants';
import { useSelector } from 'react-redux';
import { selectGetEmployeeInvitationStatus } from '../../modules/contacts/selectors';

type AffiliationsSectionProps = {
  name?: 'affiliations' | 'formerAffiliations';
  isUpdate?: boolean;
  isPersonalInfoVisible?: boolean;
  contactId?: string | undefined;
  isPersonalSlot?: boolean;
  isFormerAffiliationSlot?: boolean;
  CustomFields?: any;
};

export const AffiliationsSection = ({
  name = 'affiliations',
  isUpdate = false,
  contactId,
  isPersonalSlot = false,
  isFormerAffiliationSlot = false,
  CustomFields,
}: AffiliationsSectionProps) => {
  const { watch, control, getValues, setValue } = useFormContext();
  const confirm = useConfirm();

  const disabled = useDisabledFieldsContext();

  const affiliations = watch(name);

  const affiliationArray = useFieldArray({
    name,
    control,
  });


  const { data: invitation } = useSelector(selectGetEmployeeInvitationStatus);

  const getHeaderActions = useCallback((affiliation, index) => {
    const result: any[] = [];

    if (name === 'formerAffiliations') {
      result.push({
        key: '1',
        label: 'Re-activate Affiliation',
        onClick: () => {
          setValue('affiliations', [
            ...getValues('affiliations'),
            { ...affiliation, active: true },
          ]);

          affiliationArray.remove(index);
        },
      });

      return result;
    }

    if (!affiliation._id) {
      result.push({
        key: '1',
        label: 'Delete Affiliation',
        onClick: async () => {
          const ok = await confirm({
            title: 'Confirm your action',
            question: 'Are you sure you want to remove this affiliation?',
          });

          if (!ok) return;

          affiliationArray.remove(index);
        },
      });
    } else {
      result.push({
        key: '1',
        label: 'Deactivate Affiliation',
        onClick: async () => {
          const ok = await confirm({
            title: 'Confirm your action',
            question: 'Are you sure you want to deactivate this affiliation?',
          });

          if (!ok) return;

          setValue('formerAffiliations', [
            ...getValues('formerAffiliations'),
            { ...affiliation, active: false, isPrimary: false },
          ]);

          affiliationArray.remove(index);
        },
      });
    }

    result.push({
      key: '2',
      label: 'Set as primary affiliation',
      onClick: () => {
        const currentAffiliations = getValues('affiliations');
        const firstMovableAffiliationIndex = currentAffiliations.findIndex((el: any) => isItemMovable(el));
        const targetAffiliationIndex = currentAffiliations.findIndex((aff) => aff.companyId === affiliation.companyId);

        if (firstMovableAffiliationIndex !== -1 && targetAffiliationIndex !== -1) {
          affiliationArray.fields.forEach((aff: any) => aff.isPrimary = false );

          const target = currentAffiliations[targetAffiliationIndex];
          (target as any).isPrimary = true;
          
          affiliationArray.update(targetAffiliationIndex, target);
          affiliationArray.swap(firstMovableAffiliationIndex, targetAffiliationIndex);
        }
      },
    });

    return result;
  }, []);

  const isEmployee = invitation?.isEmployee;
  const isUser =(invitation?.status === 'accepted' ||  invitation?.status === 'invited') && isEmployee && !(invitation?.disabled);
  const isAdmin = isUser && isEmployee && invitation?.isAdmin;
  const editEmployeeAllowed = useWithPermissions({required:[moduleConstants.EE]});
  const editUserAllowed = useWithPermissions({required:[moduleConstants.EU]});
  const editAdminAllowed = useWithPermissions({required:[moduleConstants.EA]});

  const editAllowed = (((!isUser||!isUpdate) && editEmployeeAllowed) || (!isAdmin && editUserAllowed) || editAdminAllowed);


  const isItemMovable = useCallback(
    (item: any) => !(item.selfAffiliated || (item.isAccountAffiliation && isUpdate) || item.companyDeleted),
    []
  );

  const getLabel = (item) => {
    return (
      item.selfAffiliated 
        ? 'Personal' 
        : `${item.companyName}${item.companyDeleted ? ' (deleted)' : ''}`
    );
  }

  if (isPersonalSlot) {
    return (
      <>
        {affiliations.filter((item) => item.selfAffiliated).map((item, index) => {
          return(
            <DisabledFieldsProvider
              disabled={(item.isAccountAffiliation && !editAllowed) || disabled} key={index}
            >
              <AffiliationForm index={index} name={name} isUpdate={isUpdate} contactId={contactId}/>
            </DisabledFieldsProvider>
          );
        })}
      </>
    )
  }
  
  return (
    <>
      {affiliations.map((item, index) => {
        if (item.companyId && !item.selfAffiliated) {
          return(
            <div id={`contact-${index}`} data-minimap-area-label={getLabel(item)} key={item.companyId}>
              <div className='form-group row'>
                <hr style={{boxSizing: 'border-box'}} />
                <div className="col-md-3 col-3">Company</div>
                <div className="col-md-8 mb-3 col-8">{getLabel(item)}</div>
                {isItemMovable(item) ? (
                  <div className="col-md-1 ps-0 col-1">
                    <Dropdown items={getHeaderActions(item, index)}>
                      <DotsButton size={22} />
                    </Dropdown>
                  </div>
                ) : null}
              </div>
              <DisabledFieldsProvider
                disabled={(item.isAccountAffiliation && !editAllowed) || disabled}
              >
                <AffiliationForm
                  index={index}
                  name={name}
                  isUpdate={isUpdate}
                  contactId={contactId}
                  isFormerAffiliationSlot={isFormerAffiliationSlot}
                  CustomFields={CustomFields}
                />
              </DisabledFieldsProvider>
            </div>
          );
        }

        return null;
      })}
    </>
  );
};
