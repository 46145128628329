import { createSelector } from '@reduxjs/toolkit';

export const selectAllReasonCode = (state) => state.reasonCode.allReasonCode;

export const selectAllReasonCodeByStatusCode = (state) => state.reasonCode.allReasonCodeByStatusCode;

export const selectAllReasonCodeByStatusCodeOptions = createSelector(
  selectAllReasonCodeByStatusCode,
  (response) =>
    response?.data.map(({ reasonName, objectId }) => ({
      label: reasonName,
      value: objectId,
    }))
);

export const selectChangeOrderReasons = createSelector(
  selectAllReasonCode,
  (allReasonCode) =>
    allReasonCode.ChangeOrder?.filter(({ reasonName, reasonCode }) => reasonName || reasonCode) ??
    []
);

export const selectChangeOrderReasonOptions = createSelector(
  selectChangeOrderReasons,
  (changeOrderReasons) =>
    changeOrderReasons.map(({ reasonName, objectId }) => ({
      label: reasonName,
      value: objectId,
    }))
);

export const selectBidEstimateReasons = createSelector(
  selectAllReasonCode,
  (allReasonCode) =>
    allReasonCode.Bid?.filter(({ reasonName, reasonCode }) => reasonName || reasonCode) ??
    []
);

export const selectBidEstimateReasonOptions = createSelector(
  selectBidEstimateReasons,
  (bidEstimateReasons) =>
    bidEstimateReasons?.map(({ reasonName, objectId, jobStatusCodesCode, trash, isSystemMaster }) => ({
      label: reasonName,
      value: objectId,
      jobStatusCodesCode: jobStatusCodesCode,
      trash,
      isSystemMaster,
    }))
);

