export const defaultSettingsColumns = [
  {
    Header: 'Name',
    accessor: 'name',
    width: 290,
    searchable: true,
  },
  {
    Header: 'Code',
    accessor: 'code',
    width: 120,
    searchable: true,
  },
  {
    Header: 'Definition',
    accessor: 'definition',
    width: 300,
    searchable: true,
    Cell: ({ value }: any) => {
      return <div className='text-truncate'>{value}</div>;
    },
  },
];
