import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useAppDispatch } from '../../shared/hooks/use-app-dispatch';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { findContactAffiliations } from '../../modules/contacts';
import { FormSelectPaging } from '../add-project-modal/utils/form-select-paging';
import { HorizontalLayout } from '../../shared/form-controls/control-layouts/horizontal-layout';

type AffiliationLookupModalProps = {
  isOpen: boolean;
  toggle: () => void;
  onAfterSubmit: (data: any) => Promise<void>;
};

export const AffiliationLookupModal = ({
  isOpen = false,
  toggle,
  onAfterSubmit,
}: AffiliationLookupModalProps) => {
  const count = 20;

  const form = useForm();
  const dispatch = useAppDispatch();

  const [initialOptions, setInitialOptions] = useState<Array<any>>([]);

  useEffect(() => {
    if (isOpen) {
      if (initialOptions.length === 0) {
        dispatch(
          findContactAffiliations({
            criteria: '',
            page: 1,
            count,
            isAccount: false,
            includeDeleted: false,
            requiredFields: ['primaryEmail.email'],
          })
        )
          .unwrap()
          .then((data) => setInitialOptions(data));
      }
    } else {
      form.reset();
    }
  }, [isOpen, initialOptions]);

  const onSubmit = () => {
    const result = form.getValues('contacts');
    toggle();
    onAfterSubmit && onAfterSubmit(result);
  };

  const CustomizedLayout = (props: any) => (
    <HorizontalLayout {...props} labelWidth={0} controlWidth={12} />
  );

  return (
    <Modal backdrop='static' isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Search Contacts</ModalHeader>
      <ModalBody>
        <FormProvider {...form}>
          <FormSelectPaging
            count={count}
            initialOptions={initialOptions}
            isAccount={false}
            name='contacts'
            role={{ isMultiAssignable: true, _id: '1' }}
            disabled={false}
            creatable={false}
            useObjectValue={true}
            requiredFields={['primaryEmail.email']}
            Layout={CustomizedLayout}
          />
        </FormProvider>
      </ModalBody>
      <ModalFooter>
        <button type='button' className='btn btn-primary' onClick={toggle}>
          Cancel
        </button>
        <button type='button' className='btn btn-primary ms-auto' onClick={onSubmit}>
          Submit
        </button>
      </ModalFooter>
    </Modal>
  );
};
