import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import {
  addAdminSettings,
  addUserSettings,
  disableAdminSetting,
  disableUserSetting,
  enableAdminSetting,
  enableUserSetting,
  getPermissionMatrixRows,
  resetDefaultPermissions,
  resetUserSetting,
  updateAdminSettings,
  updateUserSettings,
} from '../../../modules/settings';
import {
  selectDisableAdminSetting,
  selectDisableUserSetting,
  selectEnableAdminSetting,
  selectEnableUserSetting,
  selectResetDefaultPermissions,
  selectResetUserSetting,
} from '../../../modules/settings/selectors';
import { useConfirm } from '../../../shared/confirmation-dialog';
import { HorizontalLayout } from '../../../shared/form-controls/control-layouts/horizontal-layout';
import FormCheckbox from '../../../shared/form-controls/form-checkbox/form-checkbox';
import FormInput from '../../../shared/form-controls/form-input';
import FormSelect from '../../../shared/form-controls/form-select';
import { isAllowed, moduleConstants } from '../../../_constants';

const useDefaultPermissionsOptions = ({ modelName, isSuperAdmin, isUpdate }) => {
  const isUserRoles = modelName === 'cppRoles';
  const dispatch = useDispatch();
  const [options, setOptions] = useState([]);
  useEffect(() => {
    if (isUserRoles && !isSuperAdmin && !isUpdate)
      dispatch(getPermissionMatrixRows({ isSuperAdmin: true }))
        .unwrap()
        .then((adminRoles) =>
          setOptions(adminRoles.map((role) => ({ value: role._id, label: role.roleName })))
        );
  }, []);

  return options;
};

const projectRoleOptions = [
  { value: 'internal', label: 'Internal' },
  { value: 'external', label: 'External' },
];

const AddNewItemModal = ({
  singularTitle = 'Item',
  title = `Add ${singularTitle}`,
  open = false,
  onClose: onCloseProp = () => {},
  modelName,
  afterSubmit = () => {},
  isUpdate = false,
  isSuperAdmin = false,
  updateTitle = `Update ${singularTitle}`,
  initialValue,
}) => {
  const dispatch = useDispatch();

  const confirm = useConfirm();

  const [loading, setLoading] = useState(false);

  const { _id, originalRoleCode, roleCode } = initialValue || {};

  const { control, handleSubmit, reset, formState, watch } = useForm();
  const { loading: enabling } = useSelector(selectEnableUserSetting(_id));
  const { loading: disabling } = useSelector(selectDisableUserSetting(_id));
  const { loading: adminEnabling } = useSelector(selectEnableAdminSetting(_id));
  const { loading: adminDisabling } = useSelector(selectDisableAdminSetting(_id));
  const { loading: resetPermissionsLoading } = useSelector(selectResetDefaultPermissions(_id));

  const { loading: resetLoading } = useSelector(selectResetUserSetting(_id));

  const loadingDisableEnable = enabling || disabling || adminEnabling || adminDisabling;

  const defaultOptions = { isShowOnLeads: true };
  const roleType = watch('type');

  const adminRoleCode = 'ADMIN';
  const isUserRoles = modelName === 'cppRoles';
  const isProjectRoles = modelName === 'projectRoleTypes';

  const canIManageAdmins = isAllowed(moduleConstants.EA);
  const isAdminRole = isUserRoles && roleCode === adminRoleCode;
  const isInheritedAdminRole = isUserRoles && roleCode !== adminRoleCode && originalRoleCode === adminRoleCode;
  const isNotEditableAdmin = isAdminRole || (isInheritedAdminRole && !canIManageAdmins);
  const showToggleRoleButton = isUpdate && !isNotEditableAdmin;

  useEffect(() => {
    if (open) {
      let defaultValue = { ...defaultOptions };

      if (initialValue && isUpdate) {
        initialValue.isShowOnLeads = Object.prototype.hasOwnProperty.call(initialValue, 'isShowOnLeads') ? initialValue.isShowOnLeads : false;
        defaultValue = { ...defaultValue, ...initialValue };
      }

      reset({ ...defaultValue });
    }
  }, [initialValue, open, isUpdate]);

  const onClose = () => {
    onCloseProp();
    reset({
      name: '',
      code: '',
      definition: '',
      _id: null,
    });
  };

  const submit = useCallback(
    ([modelName, values]) => {
      if (isSuperAdmin)
        return isUpdate
          ? updateAdminSettings([modelName, values])
          : addAdminSettings([modelName, values]);

      return isUpdate
        ? updateUserSettings([modelName, values])
        : addUserSettings([modelName, values]);
    },
    [isSuperAdmin, isUpdate]
  );

  const onSubmit = async (values) => {
    setLoading(true);
    try {
      await dispatch(submit([modelName, values])).unwrap();
      onClose();
      afterSubmit();
    } finally {
      setLoading(false);
    }
  };

  const onDisableEnableClick = () => {
    if (!initialValue) return;

    if (initialValue.trash)
      return dispatch(
        isSuperAdmin
          ? enableAdminSetting([modelName, initialValue._id])
          : enableUserSetting([modelName, initialValue._id])
      );

    dispatch(
      isSuperAdmin
        ? disableAdminSetting([modelName, initialValue._id])
        : disableUserSetting([modelName, initialValue._id])
    );
  };

  const onResetClick = () => {
    if (!initialValue) return;

    dispatch(resetUserSetting([modelName, initialValue._id]));
  };

  const adminRoles = useDefaultPermissionsOptions({ modelName, isSuperAdmin, isUpdate });

  const onResetPermissionsClick = async () => {
    if (!initialValue || !isUserRoles) return;

    const confirmed = await confirm({
      title: 'Reset default permissions',
      question: 'Are you sure you want to restore all permissions for this role?',
    });

    if (!confirmed) return;

    dispatch(resetDefaultPermissions({ roleId: initialValue._id }));
  };

  const RestoreDefaultPermissionsButton = useCallback(
    () => (
      <>
        {isUserRoles && isUpdate && !isSuperAdmin && initialValue?.isLocalCopy && !isNotEditableAdmin && (
          <button
            className='btn btn-primary mt-3'
            disabled={resetPermissionsLoading}
            onClick={onResetPermissionsClick}
          >
            Reset default permissions
          </button>
        )}
      </>
    ),
    [modelName, isUpdate, isSuperAdmin, initialValue, isAdminRole, isNotEditableAdmin]
  );

  const HorizontalLayoutCustom = useCallback(
    (props) => <HorizontalLayout {...props} labelWidth={8} controlWidth={4} />,
    []
  );

  const [fieldName] = watch(['name']);
  const autoCodeName = fieldName?.toUpperCase().replace(/[^A-Z0-9]/g, '');

  return (
    <Modal backdrop='static' isOpen={open} toggle={onClose}>
      <ModalHeader toggle={onClose}>{isUpdate ? updateTitle : title}</ModalHeader>
      <ModalBody>
        <div className='container'>
          <div className='row'>
            <div className='col-md-6'>
              <FormInput control={control} label='Name' required name='name' disabled={isNotEditableAdmin} />
            </div>

            <div className='col-md-6'>
              <FormInput control={control} label='Code' required name='code' disabled={isUpdate && (isProjectRoles || isNotEditableAdmin)} initialValue={autoCodeName}/>
            </div>
          </div>

          {isProjectRoles && (
            <div className='row'>
              <div className='col-md-6'>
                <FormInput control={control} label='Short Name (Report Headings)' required name='shortName' initialValue={fieldName}/>
              </div>
              <div className='col-md-6 row align-items-center'>
                  <div className='col-md-6'>
                    <FormCheckbox
                      name='isMultiAssignable'
                      control={control}
                      label='Is Multi Assignable'
                      className='mb-0'
                      Layout={HorizontalLayoutCustom}
                    />
                  </div>
                  <div className='col-md-6'>
                    <FormCheckbox
                      name='isShowOnLeads'
                      control={control}
                      label='Show on Leads'
                      className='mb-0'
                      Layout={HorizontalLayoutCustom}
                    />
                  </div>
                {
                  roleType === 'external' &&
                  <>
                    <div className='col-md-6'>
                      <FormCheckbox
                        name='isVenderTypeRole'
                        control={control}
                        label='Vender-Type Role'
                        className='mb-0'
                        Layout={HorizontalLayoutCustom}
                      />
                    </div>
                    <div className='col-md-6'>
                      <FormCheckbox
                        name='isClientTypeRole'
                        control={control}
                        label='Client-Type Role'
                        className='mb-0'
                        Layout={HorizontalLayoutCustom}
                      />
                    </div>
                  </>
                }
              </div>
            </div>
          )}

          <div className='row'>
            <div className='col-md-12'>
              <FormInput control={control} label='Definition' name='definition' />
            </div>
          </div>

          <RestoreDefaultPermissionsButton />
          {isUserRoles && !isUpdate && !isSuperAdmin && (
            <FormSelect
              isClearable
              label='Inherit role permissions from'
              options={adminRoles}
              control={control}
              name='defaultRoleId'
            />
          )}
          {isProjectRoles && (
            <FormSelect
              required
              disabled={isUpdate}
              label='Type'
              options={projectRoleOptions}
              control={control}
              name='type'
            />
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <div className='d-flex justify-content-start w-100 gap-2'>
          {isUpdate && initialValue?.isLocalCopy && !isNotEditableAdmin && (
            <button
              className='btn btn-primary mr-auto'
              disabled={resetLoading}
              onClick={onResetClick}
            >
              Reset to default
            </button>
          )}
          <button className='ms-auto btn btn-primary' onClick={onClose}>
            Cancel
          </button>
          {showToggleRoleButton && (
            <button
              disabled={loadingDisableEnable}
              className='btn btn-primary'
              onClick={onDisableEnableClick}
            >
              {initialValue?.trash ? 'Enable' : 'Disable'}
            </button>
          )}

          <button
            disabled={loading || !formState.isDirty}
            className='btn btn-primary'
            onClick={handleSubmit(onSubmit)}
          >
            Save
          </button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default AddNewItemModal;
