import { notification } from 'antd';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { buildDefaultActionResult } from '../../utils/redux';
import {
  disableWidgetRequest,
  enableWidgetRequest,
  resetWidgetsOrderRequest,
  reorderWidgetRequest,
} from './api';

export const disableWidget = createAsyncThunk(
  'widgets/disableWidget',
  async ({ id }: { id: string }) => {
    await disableWidgetRequest({ id });

    notification.success({ message: 'Widget successfully disabled!' });
  }
);

export const enableWidget = createAsyncThunk(
  'widgets/enableWidget',
  async ({ id }: { id: string }) => {
    await enableWidgetRequest({ id });

    notification.success({ message: 'Widget successfully enabled!' });
  }
);

export const resetWidgetsOrder = createAsyncThunk(
  'users/resetWidgetsOrder',
  async () => {
    await resetWidgetsOrderRequest();

    notification.success({ message: 'Order successfully reset!' });
  }
);

export const reorderWidget = createAsyncThunk(
  'users/resetWidgetsOrder',
  async ({ id, index }: { id: string, index: number }) => {
    await reorderWidgetRequest({ id, index });

    notification.success({ message: 'Widget successfully reordered!' });
  }
);

const widgetsSlice = createSlice({
  name: 'widgets',
  reducers: {},
  initialState: {},
  extraReducers: (builder) => {
    buildDefaultActionResult('disableWidget', disableWidget, builder);
    buildDefaultActionResult('enableWidget', enableWidget, builder);
    buildDefaultActionResult('resetWidgetsOrder', resetWidgetsOrder, builder);
    buildDefaultActionResult('reorderWidget', reorderWidget, builder);
  },
});

export default widgetsSlice.reducer;
