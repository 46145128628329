import { useFormContext } from 'react-hook-form';
import { TextInputSection } from './text-input';
import { NumberInputSection } from './number-input';
import { SingleSelectSection } from './single-select';
import { MultiSelectSection } from './multiselect-input';
import { useCallback, useEffect } from 'react';
import { CheckboxInputSection } from './checkbox-input';
import { RadioInputSection } from './radio-input';
import { RateInputSection } from './rate-input';
import { DateInputSection } from './date-input';
import { inputTypeOptions } from '../constants';
import FormSelect from '../../form-controls/form-select';
import { DividerSection } from './divider-input';
type ControlTypeDefinitionSectionProps = {
  isUpdate?: boolean;
};

export const ControlTypeDefinitionSection = ({
  isUpdate = false,
}: ControlTypeDefinitionSectionProps) => {
  const form = useFormContext();

  const controlType = form.watch('controlType');

  useEffect(() => {
    if (!form.formState.dirtyFields.controlType) return;

    const resetValueDefinition = (newValues) => {
      const prevValues = form.getValues();

      form.reset({
        ...prevValues,
        valueDefinition: {
          ...newValues,
        },
      });
    };

    switch (controlType) {
      case 'DIVIDER':
        resetValueDefinition({
          type: 'divider',
          defaultValue: '',
        });
        break;
      case 'TEXT_INPUT':
        resetValueDefinition({
          type: 'string',
          defaultValue: '',
          isUrl: false,
          defaultValueUrl: '',
          isTextArea: false,
        });
        break;
      case 'NUMBER_INPUT':
        resetValueDefinition({
          type: 'number',
          aggregation: 'average',
          defaultValue: '',
          allowDecimal: false,
          allowNegative: false,
          showCommas: true,
          prefix: '',
          postfix: '',
        });
        break;
      case 'SINGLE_SELECT':
        resetValueDefinition({
          type: 'string',
          hasDataSrc: false,
          creatable: false,
          options: [],
          defaultValue: '',
        });
        break;
      case 'MULTI_SELECT':
        resetValueDefinition({
          type: 'array',
          hasDataSrc: false,
          creatable: false,
          options: [],
          defaultValue: [],
        });
        break;
      case 'CHECKBOX':
        resetValueDefinition({
          type: 'boolean',
          defaultValue: false,
        });
        break;
      case 'RADIO':
        resetValueDefinition({
          type: 'string',
          options: [],
          defaultValue: '',
        });
        break;
      case 'RATE':
        resetValueDefinition({
          type: 'number',
          defaultValue: 0,
          aggregation: 'average',
          allowHalf: false,
          count: 5,
          character: '',
          icon: 'star-filled',
          color: { r: 255, g: 235, b: 59, a: 1 },
        });
        break;
      case 'DATE_INPUT':
        resetValueDefinition({
          type: 'date',
          allowTime: false,
          showOnCalendar: false,
          greaterThanEqual: 'none',
          lessThanEqual: 'none',
        });
        break;
      default:
        resetValueDefinition({
          type: 'string',
          defaultValue: '',
          isUrl: false,
          defaultValueUrl: '',
          isTextArea: false,

        });
        break;
    }
  }, [controlType]);

  const Section = useCallback(() => {
    switch (controlType) {
      case 'TEXT_INPUT':
        return <TextInputSection />;
      case 'NUMBER_INPUT':
        return <NumberInputSection />;
      case 'SINGLE_SELECT':
        return <SingleSelectSection />;
      case 'MULTI_SELECT':
        return <MultiSelectSection />;
      case 'CHECKBOX':
        return <CheckboxInputSection />;
      case 'RADIO':
        return <RadioInputSection />;
      case 'RATE':
        return <RateInputSection />;
      case 'DATE_INPUT':
        return <DateInputSection />;
      case 'DIVIDER':
        return <DividerSection />;
      default:
        return null;
    }
  }, [controlType]);

  return (
    <div>
      <div className='row'>
        <div className='col-6'>
          <FormSelect
            name='controlType'
            required
            label='Input Type'
            options={inputTypeOptions}
            disabled={isUpdate}
          />
        </div>
      </div>

      <div className='pb-5 pt-1'>
        <Section />
      </div>
    </div>
  );
};
