import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { notification } from 'antd';
import { convertParseResponse, handleUpload, setLoadResultReducer } from '../../utils/import-utils';
import { buildDefaultActionResult } from '../../utils/redux';
import {
  companiesDropdownRequest,
  createCompanyRequest,
  deleteCompaniesRequest,
  deleteCompanyRequest,
  deleteCompanyWithSingleContactsRequest,
  getAccountAffiliationRequest,
  getCompanyRequest,
  getCompanyContactAffiliationsRequest,
  getCompanyAffiliationsRequest,
  getCompanyEditInfoRequest,
  getSingleAffiliatedContactsRequest,
  importCompaniesLoadRequest,
  importCompaniesParseRequest,
  removeCompanyProfilePictureRequest,
  saveCompanyContactAffiliationRequest,
  saveCompanyProfilePictureRequest,
  saveCompanyRequest,
  updateCompanyRequest,
  validateCompanyNameRequest,
  getTagsRequest,
  getTagNamesRequest,
  getCompanyProjectsRequest,
  listCompaniesRequest,
  getCompanyCustomFieldsValuesFormRequest,
} from './api';

export const importCompaniesParse = createAsyncThunk(
  'companies/importCompaniesParse',
  async (formData) => {
    const response = await importCompaniesParseRequest(formData);

    return convertParseResponse(response);
  }
);

export const loadCompanies = createAsyncThunk(
  'companies/loadCompanies',
  async ([rows, selected], api) => {
    const [selectedResult, data] = await handleUpload(
      [
        'companyName',
        'companyWebsite',
        'companyEmail',
        'phoneType',
        'countryCode',
        'phoneNumber',
        'companyAddress1',
        'companyAddress2',
        'city',
        'state',
        'zipCode',
        'country',
        'taxId',
        'dba',
        'originId',
        'description',
      ],
      rows,
      selected,
      importCompaniesLoadRequest
    );

    api.dispatch(setLoadResult([selectedResult, rows]));

    return data;
  }
);



export const createCompany = createAsyncThunk('contacts/createCompany', async (data, api) => {
  const result = await createCompanyRequest(data);

  await api
    .dispatch(
      saveCompanyContactAffiliation({
        companyId: result._id,
        phoneArr: data.phoneArr.map((e, i) => ({ ...e, isPrimary: i === 0 })),
        addressArr: [
          {
            address1: data.companyAddress1,
            address2: data.companyAddress2,
            city: data.city,
            country: data.country,
            state: data.state,
            zipCode: data.zipCode,
            isPrimary: true,
            label: '',
          },
        ],
        emailArr: [
          {
            email: data.companyEmail,
            label: '',
            isPrimary: true,
          },
        ],
      })
    )
    .unwrap();

  return result;
});

export const updateCompany = createAsyncThunk('contacts/updateCompany', async (data, api) => {
  const result = await updateCompanyRequest(data);

  await api
    .dispatch(
      saveCompanyContactAffiliation({
        companyId: result._id,
        phoneArr: data.phoneArr,
        addressArr: [
          {
            address1: data.companyAddress1,
            address2: data.companyAddress2,
            city: data.city,
            country: data.country,
            state: data.state,
            zipCode: data.zipCode,
            isPrimary: true,
            label: '',
          },
        ],
        emailArr: [
          {
            email: data.companyEmail,
            label: '',
            isPrimary: true,
          },
        ],
      })
    )
    .unwrap();

  return result;
});

export const saveCompany = createAsyncThunk('companies/saveCompany', async (data, api) => {
  const result = await saveCompanyRequest(data);

  return result;
});

export const deleteCompanies = createAsyncThunk('companies/deleteCompanies', async ({ ids }) => {
  return deleteCompaniesRequest(ids);
});

export const saveCompanyContactAffiliation = createAsyncThunk(
  'companies/saveCompanyContactAffiliation',
  async ({ companyId, phoneArr, addressArr, emailArr, accountId }) => {
    return saveCompanyContactAffiliationRequest({
      accountId,
      companyId,
      phoneArr,
      addressArr,
      emailArr,
    });
  }
);

export const getCompanyContactAffiliations = createAsyncThunk(
  'companies/getCompanyContactAffiliations',
  async () => {
    return getCompanyContactAffiliationsRequest().then((d) =>
      d.map((item) => ({
        companyId: item.companyId,
        phoneArr: item.phoneArr,
        addressArr: item.addressArr,
        emailArr: item.emailArr,
        companyName: item.companyName,
      }))
    );
  }
);

export const getCompanyAffiliations = createAsyncThunk(
  'companies/getCompanyAffiliations',
  async ({ companyId }) => {
    const data = await getCompanyAffiliationsRequest({ companyId });

    const result = data?.map((item) => ({
      ...item,
    }));

    return result;
  }
);

export const getAccountAffiliation = createAsyncThunk(
  'companies/getAccountAffiliation',
  async () => {
    const affiliation = await getAccountAffiliationRequest();

    return {
      companyId: affiliation.companyId,
      phoneArr: affiliation.phoneArr,
      addressArr: affiliation.addressArr,
      emailArr: affiliation.emailArr,
      companyName: affiliation.companyName,
      isAccountAffiliation: true,
    };
  }
);

export const saveCompanyProfilePicture = createAsyncThunk(
  'companies/saveCompanyProfilePicture',
  async ({ companyId, file }) => {
    await saveCompanyProfilePictureRequest({ companyId, file });
  }
);

export const removeCompanyProfilePicture = createAsyncThunk(
  'companies/removeCompanyProfilePicture',
  async (companyId) => {
    await removeCompanyProfilePictureRequest(companyId);
  }
);

export const validateCompanyName = createAsyncThunk(
  'companies/validateCompanyName',
  async ({ companyName, companyId }) => {
    return validateCompanyNameRequest({ companyName, companyId });
  }
);

export const getCompanyEditInfo = createAsyncThunk(
  'companies/getCompanyEditInfo',
  async ({ companyId }) => {
    return getCompanyEditInfoRequest({ companyId });
  }
);

export const getCompany = createAsyncThunk(
  'companies/getCompany',
  async ({ companyId }) => {
    const res = await getCompanyRequest({ companyId });

    return res;
  }
);

export const getTags = createAsyncThunk(
  'companies/getTags',
  async ({ type }) => getTagsRequest({ type })
);

export const getTagNames = createAsyncThunk(
  'companies/getTagNames',
  async (
    { tagIds }
  ) => getTagNamesRequest({ tagIds })
);

export const getCompanyProjects = createAsyncThunk(
  'companies/getCompanyProjects',
  async ({ companyId }) => getCompanyProjectsRequest({ companyId })
);

export const deleteCompany = createAsyncThunk(
  'companies/deleteCompany',

  async (
    /** @type {{ companyId: string, deleteSingleContacts?: boolean }} */ {
      companyId,
      deleteSingleContacts = false,
    }
  ) => {
    if (deleteSingleContacts) {
      await deleteCompanyWithSingleContactsRequest({ companyId });
    } else {
      await deleteCompanyRequest({ companyId });
    }

    notification.success({ message: 'Company deleted successfully' });
  }
);

export const getSingleAffiliatedContacts = createAsyncThunk(
  'companies/getSingleAffiliatedContacts',
  async (/** @type {{ companyId: string }} */ { companyId }) => {
    return getSingleAffiliatedContactsRequest({ companyId });
  }
);

export const listCompanies = createAsyncThunk('companies/listCompanies', 
  async ({ limit, offset, sortingField, sortingOrder, searchValue }) => {
    const { data, count} = await listCompaniesRequest({
      displayLimit:limit,
      page:offset/limit,
      sortingField,
      sortingOrder,
      search:searchValue,
    });
    return { data, total:count};
});


export const getCompanyCustomFieldsValuesForm = createAsyncThunk(
  'projects/getCompanyCustomFieldsValuesForm',
  async (/** @type {any} */ data) => {
    const result = await getCompanyCustomFieldsValuesFormRequest({
      ...data,
    });

    return result;
  }
);

const companiesSlice = createSlice({
  name: 'companies',
  initialState: {
    importingCompanies: {
      data: [],
      loading: false,
      error: null,
    },
    loadingCompanies: {
      data: [],
      loading: false,
      error: null,
    },
    createCompany: {
      loading: false,
      error: null,
    },
    updateCompany: {
      data: [],
      loading: false,
      error: null,
    },
    deleteCompanies: {
      data: null,
      loading: false,
      error: null,
    },
    getCompanyContactAffiliations: {
      data: [],
      loading: false,
      error: null,
    },
    getCompanyAffiliations: {
      data: [],
      loading: false,
      error: null,
    },
    getAccountAffiliation: {
      data: null,
      loading: false,
      error: null,
    },
    saveCompanyProfilePicture: {
      data: null,
      loading: false,
      error: null,
    },
    saveCompany: {
      data: null,
      loading: false,
      error: null,
    },
    validateCompanyName: {
      data: null,
      loading: false,
      error: null,
    },
    listCompanies: {
      data: { total: 0, companies: [] },
      loading: false,
      error: null,
    },
    getCompany: {
      data: null,
      loading: false,
      error: null,
    },
    getCompanyEditInfo: {
      data: null,
      loading: false,
      error: null,
    },
    deleteCompany: {
      data: null,
      loading: false,
      error: null,
    },
    getSingleAffiliatedContacts: {
      data: {
        total: 0,
      },
      loading: false,
      error: null,
    },
  },
  reducers: {
    setLoadResult: setLoadResultReducer('importingCompanies'),
    clearGetCompanyEditInfo: (state) => {
      state.getCompanyEditInfo.data = null;
    },
  },
  extraReducers: (builder) => {
    buildDefaultActionResult('importingCompanies', importCompaniesParse, builder);
    buildDefaultActionResult('loadingCompanies', loadCompanies, builder);
    buildDefaultActionResult('createCompany', createCompany, builder);
    buildDefaultActionResult('updateCompany', updateCompany, builder);
    buildDefaultActionResult('deleteCompanies', deleteCompanies, builder);
    buildDefaultActionResult(
      'getCompanyContactAffiliations',
      getCompanyContactAffiliations,
      builder
    );
    buildDefaultActionResult('listCompanies', listCompanies, builder);
    buildDefaultActionResult('getCompanyAffiliations', getCompanyAffiliations, builder);
    buildDefaultActionResult('getAccountAffiliation', getAccountAffiliation, builder);
    buildDefaultActionResult('saveCompanyProfilePicture', saveCompanyProfilePicture, builder);
    buildDefaultActionResult('validateCompanyName', validateCompanyName, builder);
    buildDefaultActionResult('saveCompany', saveCompany, builder);
    buildDefaultActionResult('getCompanyEditInfo', getCompanyEditInfo, builder);
    buildDefaultActionResult('getCompany', getCompany, builder);
    buildDefaultActionResult('deleteCompany', deleteCompany, builder);
    buildDefaultActionResult('getSingleAffiliatedContacts', getSingleAffiliatedContacts, builder);
  },
});

export default companiesSlice.reducer;

export const { setLoadResult, clearGetCompanyEditInfo } = companiesSlice.actions;
