import React from 'react';
import { Link } from 'react-router-dom';
import {
  formatExternalContactData,
  formatInternalContactData,
} from '../../utils/contact-formatters';

class ContactRedirect extends React.Component {
  constructor(props) {
    super(props);
    this.state = { authResult: '', messageLogout: false };
    this.showClient = this.showClient.bind(this);
    this.showAccountExecStatus = this.showAccountExecStatus.bind(this);
  }
  sortAsc = (data, name) => {
    if (data && data.length === 0) {
      data = Object.keys(data);
    }
    if (data && data.length) {
      data.sort(function (a, b) {
        var aName = a[name].toLowerCase();
        var bName = b[name].toLowerCase();
        if (aName < bName) {
          return -1;
        }
        if (aName > bName) {
          return 1;
        }
        return 0;
      });
    }
    return data;
  };

  /*Show Account Executive Data Data on Table*/
  showAccountExecStatus(cell, row, enumObject, rowIndex) {
    if (row.accountExecArr && row.accountExecArr.length > 0) {
      let accountExecArr = this.sortAsc(row.accountExecArr, 'displayName');
      return (
        <div>{formatExternalContactData(accountExecArr.map(cl=>cl?.objectId),accountExecArr,false,false)}</div>
      );
    } else {
      return <div>-</div>;
    }
  }
  /*Show Client Data on Table*/
  showClient(cell, row, enumObject, rowIndex) {
    if (row && row.clientDataArr && row.clientDataArr.length > 0) {
      let clientDataArr = this.sortAsc(row.clientDataArr, 'displayNameWithCompany');
      return (
        <div>{formatExternalContactData(clientDataArr.map(cl=>cl?.objectId),clientDataArr,false)}</div>
      );
    } else {
      return <div>-</div>;
    }
  }

  render() {
    return '';
  }
}

export default ContactRedirect;
