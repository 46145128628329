type ProjectAdditionalInformationProps = {
  customFields: any;
};

export const ProjectAdditionalInformation = ({
  customFields,
}: ProjectAdditionalInformationProps) => {
  return (
    <>
      <b>Additional information</b>
      <hr />
      {customFields}
    </>
  );
};
