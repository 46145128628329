import moment from 'moment';

/**
 *
 * @param {import('axios').AxiosResponse} response
 */
export const convertParseResponse = (response) => {
  const {
    data: { jsonRows, errors },
  } = response;

  return jsonRows.map((d, i) => {
    const rowErrors = errors[i];

    const _isAvailable = Object.keys(rowErrors).length === 0;

    return { ...d, _loadResult: null, _rowIndex: i, _isAvailable, _rowErrors: rowErrors };
  });
};

export const handleUpload = async (fieldNames, rows, selected, uploadCb) => {
    const selectedEntries = Object.entries(selected).filter(([_, isSelected]) => isSelected);

    const indexes = selectedEntries.map(([key, value]) => key);

    const payload = indexes.map((index) =>
      fieldNames.reduce((prev, curr) => Object.assign(prev, { [curr]: rows[index][curr] }), {})
    );

    const res = await uploadCb(payload);

    const selectedResult = selectedEntries.reduce(
      (prev, [rowIndex], index) =>
        Object.assign(prev, {
          [rowIndex]: res.data.result[index],
        }),
      {}
    );

    return [selectedResult, res.data];
};

export const setLoadResultReducer = (name) => (state, action) => {
  const [selectedResult, rows] = action.payload;

  state[name].data = rows.map((row, index) => {
    if (!selectedResult[index]) return { ...row };

    return {
      ...row,
      _loadResult: selectedResult[index],
      _isAvailable: !!selectedResult[index].error,
    };
  });
};

export const stringifyTimezone = (zone, offset) => {
  const ensure2Digits = (num) => (num > 9 ? `${num}` : `0${num}`);

  return `(${offset}${zone.offset < 0 ? '-' : '+'}${ensure2Digits(
    Math.floor(Math.abs(zone.offset))
  )}:${ensure2Digits(Math.abs((zone.offset % 1) * 60))}) ${zone.label}`;
};

export const convertIntervalToDuration = (start, end) => {
  const newStart = moment(start);
  const newEnd = moment(end);

  const years = Math.abs(newEnd.diff(newStart, 'year')) || 0;
  newEnd.subtract(years, 'years');

  const months = Math.abs(newEnd.diff(newStart, 'months')) || 0;
  newEnd.subtract(months, 'months');

  const days = Math.abs(newEnd.diff(newStart, 'days')) || 0;
  newEnd.subtract(days, 'days');

  return { years, months, days };
};

const buildValueObject = (yDiff = 0, mDiff = 0, dDiff = 0) => {
  return {
      'years' : yDiff,
      'months' : mDiff,
      'days' : dDiff,
  }
};

export const convertIntervalToPreciseDuration = (start, end) => {
  if (!start || !end) {
    return buildValueObject(0, 0, 0);
  }

  const startDate = moment(start);
  const endDate = moment(end);

  if (startDate.isSame(endDate)) {
      return buildValueObject(0, 0, 0);
  }

  let yDiff = endDate.year() - startDate.year();
  let mDiff = endDate.month() - startDate.month();
  let dDiff = endDate.date() - startDate.date();
  let hourDiff = endDate.hour() - startDate.hour();

  if (hourDiff < 0) {
    hourDiff = 24 + hourDiff;
    dDiff--;
  }

  if (dDiff < 0) {
    const daysInLastFullMonth = moment(endDate.year() + '-' + (endDate.month() + 1), 'YYYY-MM').subtract(1, 'M').daysInMonth();
      if (daysInLastFullMonth < startDate.date()) { // 31/01 -> 2/03
          dDiff = daysInLastFullMonth + dDiff + (startDate.date() - daysInLastFullMonth);
      } else {
          dDiff = daysInLastFullMonth + dDiff;
      }
      mDiff--;
  }

  if (mDiff < 0) {
      mDiff = 12 + mDiff;
      yDiff--;
  }

  return buildValueObject(yDiff, mDiff, dDiff);
};
