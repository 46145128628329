import { useFormContext } from 'react-hook-form';
import FormInput from '../../../shared/form-controls/form-input';
import { HorizontalLayout } from '../../../shared/form-controls/control-layouts/horizontal-layout';

type WelcomePageAdminStepProps = {
  imageUrl?: string;
};

const WelcomePageAdminStep = ({
  imageUrl = '',
}: WelcomePageAdminStepProps) => {

  const form = useFormContext();
  const file = form.watch('file');

  return (
    <>
      <h2 data-testid='welcomePage-title-setup-company'><strong>Thank you for creating your Command Post account!</strong></h2>
      <div className='form-group' data-testid='welcomePage-message-setup-company'>
        <p>Before getting started, we ask that you review your company's name, add company logo, and establish your starting job number.</p>
      </div>
      <strong>
      <FormInput
        label='Company Name:'
        name='companyName'
        placeholder='Company Name'
        control={form.control}
        required
        Layout={HorizontalLayout}
        labelWidth={2}
      />
      </strong>

      <div className='row'>
        <div className='col-md-2' >
          <strong>
            <label htmlFor='imageUrl'>Company Logo:</label>
          </strong>
        </div>
        {imageUrl
          ? <div className='col-sm-6 col-md-4' >
            <img src={imageUrl} style={{ height: '50px' }} alt='' data-testid='welcomePage-company-logo' />
          </div>
          : <div className='col-md-4'>
            <p>You may optionally upload a logo to personalize Command Post. This logo may be changed anytime at configurations&gt;company settings.</p>
          </div>}

        <div className='col-md-4'>
          <div className='fileBox'>
            <input
              type='file'
              id='imageUrl'
              className='field full'
              placeholder='Company Logo'
              alt='Upload'
              title='Upload'
              accept=''
              {...form.register('file')}
            />
          </div>
          {form.formState.errors.file && (
            <span className='error'>{String(form.formState.errors.file.message)??''}</span>
          )}
        </div>
        <br />
        {file && file[0] && <small className='image-name'>Submit to change logo to: {file[0].name}</small>}
      </div>
      <div className='form-group col-md-12' data-testid='welcomePage-message-jobNumber'>
        <p>
          Job numbering preferences: Please specify an initial job number. Command Post will automatically assign job numbers to new "bidding" projects. Job numbers can have up to a 4 digit prefix (e.g. the current year) and a sequential job number.
        </p>
      </div>
      <div className='row'>
        <div className='col-md-2'>
          <strong><label htmlFor='initialJobNumberPre'>Next Job Number<span className='requiredField'>*</span>:</label></strong>
        </div>
        <div className='col-md-8'>
          <div className='row'>
            <div className='col-md-3'>
              <FormInput
                name='initialJobNumberPre'
                control={form.control}
                required
                placeholder='PROJ'
              />
            </div>
            <div className='col-md-3'>
              <FormInput
                name='initialJobNumber'
                control={form.control}
                required
                placeholder='Initial Job Number'
              />
            </div>
          </div>
        </div>
      </div>
      <div className='form-group col-md-12' data-testid='welcomePage-message-alternate-jobNumber'>
        <p>
          Additionally, you can enable an optional "alternate job number" field in the event that you want to have more than one job number e.g. your client job number or purchase order number. Check the box below to allow additional job numbers.
        </p>
      </div>
      <div className='row'>
        <div className='col-md-2'>
          <strong><span>Enable alternate job numbers:</span>
          </strong>
        </div>
        <div className='d-flex col-md-8'>
          <input id='alternateJobNumber' type='checkbox' {...form.register('alternateJobNumber')} placeholder='Alternate Job Number' />
        </div>
      </div>
    </>
  );
};

export default WelcomePageAdminStep;
