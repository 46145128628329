import * as actionTypes from '../actions/actionTypes';

const initState = {
  projectStatus: {},
  projectStatusUpdate: {},
  allProjectStatus: [],
  message: '',
  isfetched: false,
  isfetchedError: false,
  buttonActive: false,
};

export default (state = initState, action = {}) => {
  switch (action.type) {
    case actionTypes.UPDATE_PROJECT_STATUS_REQUEST:
      return {
        ...state,
        buttonActive: true,
        projectStatusUpdate: {},
        message: '',
        successStatus: null,
      };
    case actionTypes.UPDATE_PROJECT_STATUS_SUCCESS:
      return {
        ...state,
        buttonActive: false,
        projectStatusUpdate: action.payload.response.result.data,
        message: 'Project Status Updated Successfully',
        successStatus: !!action.payload.response.result.success,
      };

      case actionTypes.UPDATE_PROJECT_STATUS_ERROR:
        return {
          ...state,
          buttonActive: false,
          message: action.payload.error.msg, //'Something Went Wrong in Update Status',
          successStatus: false,
          projectStatusUpdate: {},
        };
      case actionTypes.GET_ALL_PROJECT_STATUS_REQUEST:
        return {
          ...state,
          projectStatus: {},
          message: '',
        };
  
      case actionTypes.GET_ALL_PROJECT_STATUS_SUCCESS:
        return {
          ...state,
          allProjectStatus: action.payload.response.result.data.data,
          message: '',
        };
  
      case actionTypes.GET_ALL_PROJECT_STATUS_ERROR:
        return {
          ...state,
          buttonActive: false,
          allProjectStatus: action.payload.response.result.data.data,
          message: '',
        };


    default:
      return state;
  }
};
