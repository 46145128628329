import FormMultiselect from '../../form-controls/form-multiselect';
import { CustomFieldsCommonProps } from '../constants';

type CustomMultiSelectProps = {
  options: any[];
  creatable: boolean;
} & CustomFieldsCommonProps;

export const CustomMultiSelect = ({ options, creatable, ...props }: CustomMultiSelectProps) => {
  return <FormMultiselect {...props} options={options} creatable={creatable} />;
};
