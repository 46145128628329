import { useEffect, useState } from 'react';
import { getNotificationActivity } from '../../modules/contacts';
import { selectGetNotificationActivity } from '../../modules/contacts/selectors';
import { useAppDispatch } from '../../shared/hooks/use-app-dispatch';
import { useAppSelector } from '../../shared/hooks/use-app-selector';
import { CustomTable } from '../../components/Common/CustomTable';
import { useNotificationsPage } from './column-helper';

import TitleComponent from '../../components/Common/TitleComponent';
import { userInteraction, isAllowed } from '../../_constants';
import { Suspense } from 'react';

import Header from '../../components/Layout/Header.js';
import Footer from '../../components/Layout/Footer.js';

import usePagination from '../../shared/data-grid/use-pagination';
import RemotePagination from '../../shared/data-grid/remote-pagination';

export const NotificationsActivity = () => {
  const dispatch = useAppDispatch();

  const [notificationActivityData, setNotificationActivityData] = useState([]);
  const [total, setTotal] = useState(0);

  const pagination = usePagination({ total });

  const { page, limit } = pagination;
  const { loading } = useAppSelector(selectGetNotificationActivity);
  const [columns] = useNotificationsPage();

  useEffect(() => {
    dispatch(getNotificationActivity({ count: limit, page }))
      .unwrap()
      .then((data) => {
        setNotificationActivityData(data.notificationActivity);
        setTotal(data.total);
      });
  }, [page, limit]);

  return (
    <div className='ContactListReport'>
      <Suspense fallback='loading'>
        <TitleComponent data={{ title: `Notifications` }} />
        <Header />
        <div className='row contactBox ms-2 me-2 row'>
          {isAllowed(userInteraction.TA, 'anyAssigned') && (
            <div className='col'>
              <div className='container'>
                <h3>Notifications</h3>
                <CustomTable
                  columns={columns}
                  list={notificationActivityData}
                  inlineEdit={false}
                  canShowExport={false}
                  canShowPagination={false}
                  canShowGlobalFilter={false}
                  canShowColumnSettings={false}
                  canShowDateFilter={false}
                  canShowRowSelection={false}
                  isLoading={loading}
                />
                <RemotePagination {...pagination} />
              </div>
            </div>
          )}
        </div>
        <Footer />
      </Suspense>
    </div>
  );
};

export default NotificationsActivity;
