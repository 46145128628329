import React from 'react';
import { formatPhone } from '../../utils/formatters';
import useCountryCodes from '../hooks/use-country-codes';

const FormattedPhone = ({ phoneObject }) => {
  const countries = useCountryCodes();
  return (
    <span>
      {formatPhone(phoneObject, countries)}
      {phoneObject.extPhone && <span> ext. {phoneObject.extPhone}</span>}
    </span>
  );
};

export default FormattedPhone;
