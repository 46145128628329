import _ from 'lodash';
import { notification } from 'antd';
import { useEffect, useMemo, useState, Suspense } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { getUserInfo, updateUserInfo, verifyPhoneNumber, disablePhoneActivation } from '../../../modules/users'; // verifyPhoneNumber
import {
  getContactAffiliations,
  saveContactAffiliations,
  updateContactPicture,
  removeContactPicture,
} from '../../../modules/contacts';
import { userRolesDropdown } from '../../../modules/contacts';
import { HorizontalLayout } from '../../../shared/form-controls/control-layouts/horizontal-layout';
import FormInput from '../../../shared/form-controls/form-input/form-input';
import FormSelect from '../../../shared/form-controls/form-select/form-select';
import { useAppDispatch } from '../../../shared/hooks/use-app-dispatch';
import { useSubmit } from '../../../shared/hooks/use-submit';
import FormAvatar from '../../../shared/form-controls/form-avatar';
import PhoneArray from '../../../shared/phone-array/phone-array';
import EmailArray from '../../../shared/form-controls/email-array/email-array';
import AddressArray from '../../../shared/form-controls/address-array/address-array';
import { stringifyTimezone } from '../../../utils/import-utils';
import { appConstants, timezones } from '../../../_constants';
import TitleComponent from '../../../components/Common/TitleComponent';
import SidebarPreferences from '../../../components/Layout/SidebarPreferences';
import Header from '../../../components/Layout/Header';
import { PhoneElement } from '../../../shared/phone-array';

const defaultValues = {
  firstName: '',
  middleName: '',
  lastName: '',
  userEmail: '',
  userRole: '',
  title: '',
  userImage: null,
  pictureFile: null,
  contactId: '',
  userTimeZone: appConstants.DEFAULT_TIMEZONE,
  emailArr: [],
  phoneArr: [],
  addressArr: [],
  userPhone: { phone: '', ext: '', type: '', extPhone: '', fullPhone: '', phoneClean: ''},
  userPhoneActivation: false,
};

export const UserInfoPage = (props) => {
  const dispatch = useAppDispatch();

  const form = useForm({ defaultValues });
  const { control } = form;

  const [userInfo, setUserInfo] = useState<any>({});
  const [affiliations, setAffiliations] = useState<Array<any>>([]);
  const [roles, setRoles] = useState<Array<any>>([]);

  const localUserInfo = JSON.parse(localStorage.getItem('userInfo') || '{}');
  const { objectId: id, contactId } = localUserInfo;

  useEffect(() => {
    dispatch(getUserInfo(id))
      .unwrap()
      .then((data) => setUserInfo(data));

    dispatch(getContactAffiliations({ contactId }))
      .unwrap()
      .then((data) => setAffiliations(data));

    dispatch(userRolesDropdown())
      .unwrap()
      .then((data) => setRoles(data));
  }, []);

  useEffect(() => {
    if (_.isEmpty(userInfo)) {
      return;
    }

    let emailArr = [];
    let phoneArr = [];
    let addressArr = [];

    const accountAffiliation = affiliations.find((aff) => aff.isAccountAffiliation);
    if (accountAffiliation) {
      emailArr = _.cloneDeep(accountAffiliation.emailArr);
      phoneArr = _.cloneDeep(accountAffiliation.phoneArr);
      addressArr = _.cloneDeep(accountAffiliation.addressArr);
    }

    const userRole = roles.find((role) => role._id === userInfo.cppRoleId)?.label || '';

    form.reset({
      firstName: userInfo.firstName,
      middleName: userInfo.middleName,
      lastName: userInfo.lastName,
      userEmail: userInfo.userEmail,
      userRole,
      title: userInfo.title,
      contactId: userInfo.contactId,
      userImage: userInfo.userImage,
      userTimeZone: userInfo.userTimeZone,
      userPhone: userInfo.userPhone,
      userPhoneActivation: userInfo.userPhoneActivation,
      emailArr,
      phoneArr,
      addressArr,
    });
  }, [userInfo, affiliations, roles]);

  const timezoneOptions = useMemo(
    () =>
      timezones.map((val) => ({
        value: val,
        label: `${stringifyTimezone(val, 'GMT')}- ( ${val.name} )`,
      })),
    []
  );

  const [onSubmit, submitting] = useSubmit(
    form.handleSubmit(async (values) => {
      // update user info
      const { firstName, middleName, lastName, title, userTimeZone, userPhone } = values;
      const data = { _id: id, firstName, middleName, lastName, title, userTimeZone, userPhone };
      await dispatch(updateUserInfo({ data }));

      // update user/contact image
      const { pictureFile, contactId } = values;
      pictureFile
        ? await dispatch(updateContactPicture({ pictureFile, contactId }))
        : await dispatch(removeContactPicture(contactId));

      // update account affiliation
      const { emailArr, phoneArr, addressArr } = values;
      const affiliationsClone = _.cloneDeep(affiliations);
      const activeAffiliations = affiliationsClone.filter((aff) => aff.active);
      const formerAffiliations = affiliationsClone.filter((aff) => !aff.active);
      const accountAffiliation = activeAffiliations.find((aff) => aff.isAccountAffiliation);

      if (accountAffiliation) {
        accountAffiliation.emailArr = [...emailArr];
        accountAffiliation.phoneArr = [...phoneArr];
        accountAffiliation.addressArr = [...addressArr];

        const data = { affiliations: activeAffiliations, formerAffiliations, contactId };

        await dispatch(saveContactAffiliations({ ...data }));
      }

      notification.success({ message: 'User saved!' });
    }),
    [affiliations]
  );

  const [handlePhoneNumberVerify, phoneNumberVerifyLoading] = useSubmit(async () => {
    const { userPhone } = form.getValues() as any;

    await dispatch(verifyPhoneNumber({ phone: userPhone }));
  });

  const CustomizedLayout = (props: any) => (
    <HorizontalLayout {...props} labelWidth={3} controlWidth={8} />
  );

  const userPhoneActivation = form.watch('userPhoneActivation');

  const disablePhoneActivationDialog =  async () => {

    confirm('Are you sure you want to disconnect this phone from this account??') &&
      await dispatch(disablePhoneActivation()).unwrap().then(() => {
        form.setValue('userPhoneActivation', false);
        notification.success({ message: 'Phone disconnected!' });
      });
  }

  return (
    <div className='ContactListReport'>
      <TitleComponent data={{ title: `My Profile` }} />
      <Suspense fallback='loading'>
        <Header props={props} />
      </Suspense>

      <div className='contactBox'>
        <div className='container'>
          <div className='row'>
            <div className='col-sm-2'>
              <div className='heading2'>Preferences</div>

              <div className='sidebarBox'>
                <SidebarPreferences {...props} />
              </div>
            </div>
            <div className='col-sm-10'>
              <div className='rightTableBox cardBody'>
                <div className='formBox'>
                  <FormProvider {...form}>
                    <div className='flex row'>
                      <h2 className='heading2'>User Account Preferences</h2>
                    </div>

                    <div className='col-lg-9 col-12 d-flex flex-column gap-3'>
                      <h3 className='infoHeading'>Account Info</h3>
                      <FormInput
                        name='userEmail'
                        label='Login Email'
                        control={control}
                        Layout={CustomizedLayout}
                        disabled
                      />

                   <PhoneElement
                        name='userPhone'
                        hideExt
                        hideType
                        disabled={userPhoneActivation}
                        label='Mobile Phone'
                        actions={
                          !userPhoneActivation ? (
                            <button
                              className='btn btn-primary'
                              disabled={phoneNumberVerifyLoading}
                              onClick={handlePhoneNumberVerify}
                            >
                              Verify
                            </button>
                          ) : (
                            <>
                              <span className='ms-2 text-success' style={{display:'inline-flex'}} onClick={disablePhoneActivationDialog}>
                                <div className='fa fa-check-circle'/><div className='mt-0'>&nbsp;Verified</div>
                              </span>
                            </>
                          )
                        }
                      />

                      <FormInput
                        name='userRole'
                        label='User Type'
                        control={control}
                        Layout={CustomizedLayout}
                        disabled
                      />

                      <div className='row d-flex align-items-center'>
                        <div className='col-3'>Profile Picture</div>
                        <div className='col-3'>
                          <FormAvatar
                            name='userImage'
                            control={control}
                            onFileSelect={(file) => form.setValue('pictureFile', file)}
                            onFileRemove={() => {
                              form.setValue('userImage', null);
                              form.setValue('pictureFile', null);
                            }}
                          />
                        </div>
                      </div>

                      <h3 className='infoHeading'>User Profile</h3>

                      <FormInput
                        required
                        name='firstName'
                        label='First Name'
                        control={control}
                        Layout={CustomizedLayout}
                      />
                      <FormInput
                        name='middleName'
                        label='Middle Name'
                        control={control}
                        Layout={CustomizedLayout}
                      />
                      <FormInput
                        required
                        name='lastName'
                        label='Last Name'
                        control={control}
                        Layout={CustomizedLayout}
                      />
                      <FormInput
                        name='title'
                        label='Title'
                        control={control}
                        Layout={CustomizedLayout}
                      />

                      <h3 className='infoHeading'> Work Contact Info</h3>

                      <EmailArray name='emailArr' />
                      <PhoneArray name='phoneArr' />
                      <AddressArray name='addressArr' />

                      <h3 className='infoHeading mt-2'>Default Localization Settings</h3>

                      <FormSelect
                        name='userTimeZone'
                        required
                        options={timezoneOptions}
                        label='Preferred Time Zone'
                        control={control}
                        Layout={CustomizedLayout}
                        useObjectValue
                      />

                      <div className='row d-flex'>
                        <div className='mt-2 col-md-2 offset-md-9 justify-content-end d-flex'>
                          <button
                            className='btn btn-primary'
                            onClick={onSubmit}
                            disabled={submitting}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </FormProvider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserInfoPage;
