import joi from 'joi';
import _ from 'lodash';
import moment from 'moment';
import { isCurrentOrPrevStage, getProjectStageByCode } from '../shared/custom-fields-controls/constants';

export const joiAnyString = () => joi.string().trim().allow('').empty('').failover('');

export const joiEmail = () =>
  joi.object({
    label: joiAnyString().label('Label'),
    email: joi
      .string()
      .email({ tlds: { allow: false } })
      .required()
      .label('Email'),
    isPrimary: joi.boolean(),
  });

export const joiAddress = () =>
  joi.object({
    label: joiAnyString().label('Label'),
    address1: joi.string().allow('', null).empty('').label('Street'),
    address2: joiAnyString().label('Apt/Suite'),
    city: joiAnyString().label('City'),
    state: joiAnyString().label('State'),
    zipCode: joiAnyString().label('Zip Code'),
    country: joiAnyString().label('Country'),
    isPrimary: joi.boolean().allow(),
  });

export const joiPhone = () =>
  joi.object({
    isPrimary: joi.boolean().allow(),
    phone: joi.string().optional().allow('').empty('').label('Phone Number'),
    type: joi
      .string()
      .optional()
      .valid('Work', 'Mobile', 'Phone', 'Home', 'Fax', 'Other')
      .allow('')
      .empty('')
      .default('Mobile')
      .label('Phone Type'),
    ext: joi.string().optional().allow('').empty('').default('1').label('Country Code'),
    extPhone: joi
      .string()
      .optional()
      .allow('')
      .empty('')
      .default('')
      .label('Extension Phone Number'),
    country: joi.string().optional().allow('').empty('').default('').label('Country'),
    fullPhone: joi.string().optional().allow('').empty('').default('').label('Full Phone'),
    phoneClean: joi.string().optional().allow('').empty('').default('').label('Cleaned Phone Number'),
  });

// export const joiMoment = (props?: any) =>
//   joi.any().custom((value, helper) => {
//     const { required } = props || {};

//     if (!value) {
//       if (required) {
//         return helper.message({
//           custom: '{#label} is required',
//         });
//       }

//       return null;
//     }

//     if (!moment.isMoment(value)) {
//       return helper.message({
//         custom: 'Invalid date',
//       });
//     }

//     return value.toDate();
//   });

export const joiProjectTeam = () => {
  return joi.any().custom((value, helper) => {
    if (value) {
      const { state = {}, prefs = {} } = helper;
      const { path = [], ancestors = [] } = state;
      const name = path[0];
      const data = ancestors[0];
      const context = prefs.context;

      if (name && !_.isEmpty(data) && !_.isEmpty(context)) {
        const { projectRoles } = context;
        const reqRoles = (projectRoles || []).filter((role: any) => role.required);
        const reqRolesCount = reqRoles.length;

        if (reqRolesCount > 0) {
          if (!value) {
            const reqRoleName = reqRoles.map((role: any) => role.name).join(', ');
            const singularMessage = `Role' ${reqRoleName}' is required`;
            const multipleMessage = `Roles' ${reqRoleName}' are required`;

            return helper.message({
              custom: reqRolesCount === 1 ? singularMessage : multipleMessage,
            });
          }

          for (let i = 0; i < reqRoles.length; i++) {
            const reqRole = reqRoles[i];

            if (!value[reqRole._id] || value[reqRole._id]?.length === 0) {
              return helper.message({
                custom: `Role' ${reqRole.name}' is required`,
              });
            }
          }
        }
      }
    }

    return value;
  });
}

// check if value is a date or a moment and return a date
export const joiMoment = (props?: any) => {
  const { required } = props || {};

  return joi.any().custom((value, helper) => {
    if (!value) {

      if (required || isProjectDateRequired(helper)) {
        return helper.message({
          custom: '{#label} is required',
        });
      }

      return null;
    }

    if (!moment.isMoment(value) && !moment(value).isValid()) {
      return helper.message({
        custom: 'Invalid date',
      });
    }

    return moment(value).toISOString();
  });
}

const isProjectDateRequired = (helper: any) => {
  const { state = {}, prefs = {} } = helper;
  const { path = [], ancestors = [] } = state;
  const name = path[0];
  const data = ancestors[0];
  const context = prefs.context;

  if (name && !_.isEmpty(data) && !_.isEmpty(context)) {
    const accountSettings = context.accountSettings;
    const jobStatusCodesCode = data.jobStatusCodesCode;

    const { requireNTP, requireConstructionDatesOn } = accountSettings;
    const projectStage = getProjectStageByCode(jobStatusCodesCode);
    const requireConstructionDates = isCurrentOrPrevStage(projectStage, requireConstructionDatesOn);
    const shouldRequireNTP = requireNTP && isCurrentOrPrevStage(projectStage, 'active');

    switch (name) {
      case 'startDate':
      case 'endDate':
        return requireConstructionDates;
      case 'ntpDate':
        return shouldRequireNTP;
    }
  }

  return false;
}
