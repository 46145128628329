import * as actionTypes from '../../actions/Admin/actionTypes';

const initState = {
  moduleData: [],
  message: '',
  isfetched: false,
  isUpdateStatus: false,
  success: null,
  isCreated: false,
  isDeleted: false,
};

export default (state = initState, action = {}) => {
  switch (action.type) {
    case actionTypes.GET_MODULES_REQUEST:
      return {
        ...state,
        moduleData: [],
        message: '',
        isfetched: false,
        isCreated: false,
      };
    case actionTypes.GET_MODULES_SUCCESS:
      return {
        ...state,
        moduleData: action.payload.response.data,
        message: 'Module details imported',
        isfetched: true,
        isCreated: true,
      };

    case actionTypes.GET_MODULES_ERROR:
      return {
        ...state,
        message: '',
        isfetched: false,
        isCreated: false,
      };
      
      case actionTypes.EDIT_MODULES_REQUEST:
       return {
        ...state,
        moduleData: [],
        message: '',
        isfetched: false,
        isCreated: false,
      };
    case actionTypes.EDIT_MODULES_SUCCESS:
      return {
        ...state,
        moduleData: action.payload.response.result.data,
        isCreated: true,
        isfetched: true,
        success: action.payload.response.result.success,
        message: action.payload.response.result.success
          ? 'Record updated successfully'
          : action.payload.response.result.msg,
      };

    case actionTypes.EDIT_MODULES_ERROR:
      return {
        ...state,
        moduleData: [],
        isfetched: false,
        isCreated: false,
        success: action.payload.response.result.success,
        message: action.payload.response.result.msg,
      };

    // //**** create help *****
    // case actionTypes.ADD_HELP_REQUEST:
    //   return {
    //     ...state,
    //     helpData: [],
    //     message: '',
    //     isfetched: false,
    //     isCreated: false,
    //   };
    // case actionTypes.ADD_HELP_SUCCESS:
    //   return {
    //     ...state,
    //     helpData: action.payload.response.result.data,
    //     isCreated: true,
    //     isfetched: true,
    //     success: action.payload.response.result.success,
    //     message: action.payload.response.result.success
    //       ? 'Record added successfully'
    //       : action.payload.response.result.msg,
    //   };

    // case actionTypes.ADD_HELP_ERROR:
    //   return {
    //     ...state,
    //     helpData: [],
    //     isfetched: false,
    //     isCreated: false,
    //     success: action.payload.response.result.success,
    //     message: action.payload.response.result.msg,
    //   };

    // //**** create help by file upload *****
    // case actionTypes.ADD_HELP_BY_FILE_UPLOAD_REQUEST:
    //   return {
    //     ...state,
    //     helpData: [],
    //     message: '',
    //     isfetched: false,
    //     isCreated: false,
    //   };
    // case actionTypes.ADD_HELP_BY_FILE_UPLOAD_SUCCESS:
    //   return {
    //     ...state,
    //     helpData: action.payload.response.result.data,
    //     isCreated: true,
    //     isfetched: true,
    //     success: action.payload.response.result.success,
    //     message: action.payload.response.result.success
    //       ? 'Record uploaded successfully'
    //       : action.payload.response.result.msg,
    //   };

    // case actionTypes.ADD_HELP_BY_FILE_UPLOAD_ERROR:
    //   return {
    //     ...state,
    //     helpData: [],
    //     isfetched: false,
    //     isCreated: false,
    //     success: action.payload.response.result.success,
    //     message: action.payload.response.result.msg,
    //   };

    // //**** update help *****
    // 

    // //**** update help *****
    // case actionTypes.DELETE_HELP_REQUEST:
    //   return {
    //     ...state,
    //     helpData: [],
    //     message: '',
    //     isCreated: false,
    //   };
    // case actionTypes.DELETE_HELP_SUCCESS:
    //   return {
    //     ...state,
    //     helpData: action.payload.response.result.data,
    //     isCreated: true,
    //     success: action.payload.response.result.success,
    //     message: action.payload.response.result.success
    //       ? 'Record deleted successfully'
    //       : action.payload.response.result.msg,
    //   };

    // case actionTypes.DELETE_HELP_ERROR:
    //   return {
    //     ...state,
    //     helpData: [],
    //     isCreated: false,
    //     success: action.payload.response.result.success,
    //     message: action.payload.response.result.msg,
    //   };

    // case actionTypes.GET_TERMS_CONDITION_SUCCESS:
    //   return {
    //     ...state,
    //     helpData: action.payload.response.result.data,
    //     message: 'GET TERMS CONDITION SUCCESS',
    //     isfetched: true,
    //     isCreated: true,
    //   };

    default:
      return state;
  }
};
