import { JobStatusCodeForm, ModuleType } from '@module/settings';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { notification } from 'antd';
import { buildDefaultActionResult, buildDefaultActionResultForList } from '../../utils/redux';
import {
  addAdminSettingsRequest,
  addUserSettingsRequest,
  disableAdminSettingRequest,
  disableJobStatusCodeRequest,
  disableUserSettingRequest,
  enableAdminSettingRequest,
  enableJobStatusCodeRequest,
  enableUserSettingRequest,
  getAdminSettingsRequest,
  getCustomSelectOptionsRequest,
  createCustomFieldRequest,
  editCustomFieldRequest,
  disableCustomFieldRequest,
  enableCustomFieldRequest,
  resetCustomFieldRequest,
  getJobStatusCodeFormRequest,
  getJobStatusCodesSettingsRequest,
  getPermissionMatrixColumnsRequest,
  getPermissionMatrixRequest,
  getPermissionMatrixRowsRequest,
  getProjectFieldFormRequest,
  getProjectFieldsSettingsRequest,
  getContactFieldFormRequest,
  getContactFieldsSettingsRequest,
  getProjectRolesRequest,
  getFormTemplatesRequest,
  getUserSettingsRequest,
  importJobStatusCodesRequest,
  resetDefaultPermissionsRequest,
  resetUserSettingRequest,
  setPermissionValueRequest,
  updateAdminSettingsRequest,
  updateJobStatusCodeRequest,
  updateUserSettingsRequest,
  reorderProjectCustomFieldsRequest,
  reorderContactCustomFieldsRequest,
  getReportSettingsRequest,
  addReportRequest,
  updateReportRequest,
  resetReportRequest,
  getReportFormRequest,
  getReportTableColumnsRequest,
  getReportDisplayingDataRequest,
  getReportFieldsOptionsRequest,
  reorderReportSettingsRequest,
  disableReportRequest,
  enableReportRequest,
  getEnabledReportsRequest,
  getReasonCodesRequest,
  syncQuickbooksItemsRequest,
} from './api';

export const addUserSettings = createAsyncThunk(
  'settings/addUserSettings',
  ([modelName, settings]: any) =>
    addUserSettingsRequest(modelName, settings).then(() =>
      notification.success({ message: 'Saved!' })
    )
);

export const addAdminSettings = createAsyncThunk(
  'settings/addAdminSettings',
  async ([modelName, settings]: any, api) => {
    await addAdminSettingsRequest(modelName, settings);

    await api.dispatch(getAdminSettings([modelName]));

    notification.success({ message: 'Saved!', key: '2' });
  }
);

export const syncLineItems = createAsyncThunk('settings/syncQuickbooksItems', async () => {
  await notification.success({ message: 'Syncing line items...' });

  await syncQuickbooksItemsRequest();

  await notification.success({ message: 'Line items synced!' });
})

export const updateUserSettings = createAsyncThunk(
  'settings/updateUserSettings',
  ([modelName, settings]: any) =>
    updateUserSettingsRequest(modelName, settings)
      // TODO: delete after all tables will be updated
      // .then(() => api.dispatch(getUserSettings([modelName])))
      .then(() => notification.success({ message: 'Saved!' }))
);

export const updateAdminSettings = createAsyncThunk(
  'settings/updateAdminSettings',
  ([modelName, settings]: any, api) =>
    updateAdminSettingsRequest(modelName, settings)
      .then(() => api.dispatch(getAdminSettings([modelName])))
      .then(() => notification.success({ message: 'Saved!' }))
);

export const getAdminSettings = createAsyncThunk('settings/getAdminSettings', ([modelName]: any) =>
  getAdminSettingsRequest(modelName).then(({ data }) => data)
);

export const getUserSettings = createAsyncThunk(
  'settings/getUserSettings',
  ([modelName, settingId]: [modelName: string, settingId?:string]) => getUserSettingsRequest(modelName,settingId).then(({ data }) => data)
);

export const enableUserSetting = createAsyncThunk(
  'settings/enableUserSetting',
  async ([modelName, settingId]: any) => {
    await enableUserSettingRequest(modelName, settingId).then(({ data }) => data);

    // await api.dispatch(getUserSettings([modelName]));

    notification.success({ message: 'Saved!' });
  }
);

export const disableUserSetting = createAsyncThunk(
  'setting/disableUserSetting',
  async ([modelName, settingId]: any) => {
    await disableUserSettingRequest(modelName, settingId);

    // await api.dispatch(getUserSettings([modelName]));

    notification.success({ message: 'Saved!' });
  }
);

export const resetUserSetting = createAsyncThunk(
  'setting/resetUserSetting',
  async ([modelName, settingId]: any) => {
    await resetUserSettingRequest(modelName, settingId);

    // await api.dispatch(getUserSettings([modelName]));

    notification.success({ message: 'Saved!' });
  }
);

export const enableAdminSetting = createAsyncThunk(
  'settings/enableAdminSetting',
  async ([modelName, settingId]: any, api) => {
    await enableAdminSettingRequest(modelName, settingId);

    await api.dispatch(getAdminSettings([modelName]));

    notification.success({ message: 'Saved!' });
  }
);

export const disableAdminSetting = createAsyncThunk(
  'setting/disableAdminSetting',
  async ([modelName, settingId]: any, api) => {
    await disableAdminSettingRequest(modelName, settingId);

    await api.dispatch(getAdminSettings([modelName]));

    notification.success({ message: 'Saved!' });
  }
);

export const getPermissionMatrixColumns = createAsyncThunk(
  'settings/getPermissionMatrixColumns',
  async ({ moduleType }: { moduleType: ModuleType }) => {
    const modules = await getPermissionMatrixColumnsRequest(moduleType);

    return modules;
  }
);

export const getPermissionMatrixRows = createAsyncThunk(
  'settings/getPermissionMatrixRows',
  async ({ isSuperAdmin }: { isSuperAdmin: boolean }) => {
    const roles = await getPermissionMatrixRowsRequest(isSuperAdmin);

    return roles;
  }
);

export const getPermissionMatrix = createAsyncThunk(
  'settings/getPermissionMatrix',
  async ({ moduleType, isSuperAdmin }: { moduleType: ModuleType; isSuperAdmin: boolean }) => {
    const matrix = await getPermissionMatrixRequest(moduleType, isSuperAdmin);

    return matrix;
  }
);

export const setPermissionValue = createAsyncThunk(
  'settings/setPermissionValue',
  async ({ roleId, moduleId, value, moduleType, isSuperAdmin }: any, api) => {
    await setPermissionValueRequest(roleId, moduleId, value, isSuperAdmin);

    await api.dispatch(getPermissionMatrix({ moduleType, isSuperAdmin }));
  }
);

export const resetDefaultPermissions = createAsyncThunk(
  'settings/resetDefaultPermissions',
  async ({ roleId }: { roleId: string }) => {
    await resetDefaultPermissionsRequest(roleId);

    notification.success({ message: 'Permissions successfully restored' });
  }
);

export const importJobStatusCodes = createAsyncThunk(
  'settings/importJobStatusCodes',
  async ({ accountId }: { accountId?: string }) => {
    await importJobStatusCodesRequest(accountId);

    notification.success({ message: 'Job statuses imported successfully' });
  }
);

export const getJobStatusCodesSettings = createAsyncThunk(
  'settings/getJobStatusCodes',
  async (
    arg?:
      | { accountId?: string; jobStatusCodesCode?: string[]; includeDisabled?: boolean }
      | undefined
  ) => {
    const data = await getJobStatusCodesSettingsRequest(arg);

    return data;
  }
);

export const getJobStatusCodeForm = createAsyncThunk(
  'settings/getJobStatusCodeForm',
  async (arg: { statusCodeId: string }) => {
    const data = await getJobStatusCodeFormRequest({ statusCodeId: arg.statusCodeId });

    return data;
  }
);

export const enableJobStatusCode = createAsyncThunk(
  'settings/enableJobStatusCode',
  async (arg: { statusCodeId: string }) => {
    await enableJobStatusCodeRequest({ statusCodeId: arg.statusCodeId });

    notification.success({ message: 'Job status code enabled' });
  }
);

export const disableJobStatusCode = createAsyncThunk(
  'settings/disableJobStatusCode',
  async (arg: { statusCodeId: string }) => {
    await disableJobStatusCodeRequest({ statusCodeId: arg.statusCodeId });

    notification.success({ message: 'Job status code disabled' });
  }
);

export const updateJobStatusCode = createAsyncThunk(
  'settings/updateJobStatusCode',
  async (arg: JobStatusCodeForm & { statusCodeId: string }) => {
    await updateJobStatusCodeRequest(arg);

    notification.success({ message: 'Job status code updated' });
  }
);

export const getReasonCodesByType = createAsyncThunk('settings/getReasonCodesByType', async (type: string) => {
  const data = await getReasonCodesRequest({type});

  return data;
});

export const getProjectRoles = createAsyncThunk('settings/getProjectRoles', async () => {
  const data = await getProjectRolesRequest();

  return data;
});

export const getFormTemplates = createAsyncThunk('settings/getFormTemplates', async () => {
  const data = await getFormTemplatesRequest();

  return data;
});

export const getProjectFieldsSettings = createAsyncThunk(
  'settings/getProjectFieldsSettings',
  async () => {
    const data = await getProjectFieldsSettingsRequest();

    return data;
  }
);

export const getProjectFieldForm = createAsyncThunk(
  'settings/getProjectFieldForm',
  async ({ fieldId }: { fieldId: string }) => {
    const data = await getProjectFieldFormRequest({ fieldId });

    return data;
  }
);

export const getContactFieldsSettings = createAsyncThunk(
  'settings/getContactFieldsSettings',
  async () => {
    const data = await getContactFieldsSettingsRequest();

    return data;
  }
);

export const getContactFieldForm = createAsyncThunk(
  'settings/getContactFieldForm',
  async ({ fieldId }: { fieldId: string }) => {
    const data = await getContactFieldFormRequest({ fieldId });

    return data;
  }
);

export const getCustomSelectOptions = createAsyncThunk(
  'settings/getCustomSelectOptions',
  async ({ listName }: { listName: string }) => {
    const data = await getCustomSelectOptionsRequest({ listName });

    return data;
  }
);

export const disableCustomField = createAsyncThunk(
  'settings/disableCustomField',
  async ({ fieldId }: { fieldId: string }) => {
    const data = await disableCustomFieldRequest({ fieldId });

    notification.success({ message: 'Custom field successfully disabled!' });

    return data;
  }
);

export const resetCustomField = createAsyncThunk(
  'settings/resetCustomField',
  async ({ fieldId }: { fieldId: string }) => {
    const data = await resetCustomFieldRequest({ fieldId });

    notification.success({ message: 'Custom field successfully reset!' });

    return data;
  }
);

export const enableCustomField = createAsyncThunk(
  'settings/enableCustomField',
  async ({ fieldId }: { fieldId: string }) => {
    const data = await enableCustomFieldRequest({ fieldId });

    notification.success({ message: 'Custom field successfully enabled!' });

    return data;
  }
);

export const createCustomField = createAsyncThunk(
  'settings/createCustomField',
  async ({ fieldData }: { fieldData: any }) => {
    const data = await createCustomFieldRequest(fieldData);

    notification.success({ message: 'Custom field successfully created!' });

    return data;
  }
);

export const editCustomField = createAsyncThunk(
  'settings/editCustomField',
  async ({ fieldData }: { fieldData: any }) => {
    const data = await editCustomFieldRequest(fieldData);

    notification.success({ message: 'Custom field successfully edited!' });

    return data;
  }
);

export const reorderProjectCustomFields = createAsyncThunk(
  'settings/reorderProjectCustomFields',
  async ({ itemId, section, index }: { itemId: string; section: string; index: number }) => {
    await reorderProjectCustomFieldsRequest({ itemId, section, index });

    notification.success({ message: 'Custom field successfully reordered!' });
  }
);

export const reorderContactCustomFields = createAsyncThunk(
  'settings/reorderContactCustomFields',
  async ({ itemId, index }: { itemId: string; index: number }) => {
    await reorderContactCustomFieldsRequest({ itemId, index });

    notification.success({ message: 'Custom field successfully reordered!' });
  }
);

export const getReportSettings = createAsyncThunk('settings/getReportSettings', async () => {
  const settings = await getReportSettingsRequest();

  return settings;
});

export const getEnabledReports = createAsyncThunk('settings/getEnabledReports', async () => {
  const reports = await getEnabledReportsRequest();

  return reports;
});

export const getReportPermissionsModules = createAsyncThunk(
  'settings/getReportPermissionsModules',
  async () => {
    const modules = await getPermissionMatrixColumnsRequest('report-access');

    return modules;
  }
);

export const addReport = createAsyncThunk(
  'settings/addReport',
  async ({ reportData }: { reportData: any }) => {
    await addReportRequest(reportData);

    notification.success({ message: 'Report successfully created!' });
  }
);

export const updateReport = createAsyncThunk(
  'settings/updateReport',
  async ({ reportData }: { reportData: any }) => {
    await updateReportRequest(reportData);

    notification.success({ message: 'Report successfully updated!' });
  }
);

export const resetReport = createAsyncThunk(
  'settings/resetReport',
  async ({ reportData }: { reportData: any }) => {
    await resetReportRequest(reportData);

    notification.success({ message: 'Report successfully reset!' });
  }
);

export const getReportForm = createAsyncThunk('settings/getReport', async ({ reportId }: any) => {
  const report = await getReportFormRequest(reportId);

  return report;
});

export const getReportTableColumns = createAsyncThunk(
  'settings/getReportTableColumns',
  async ({ reportId, isWidget }: any) => {
    const columns = await getReportTableColumnsRequest(reportId, isWidget);

    return columns;
  }
);

export const getReportDisplayingData = createAsyncThunk(
  'settings/getReportDisplayingData',
  async ({ reportId, isWidget }: any) => {
    const data = await getReportDisplayingDataRequest(reportId, isWidget);

    return data;
  }
);

export const getReportFieldsOptions = createAsyncThunk(
  'settings/getReportFieldsOptions',
  async (reportType:string) => {
    const options = await getReportFieldsOptionsRequest(reportType);

    return options;
  }
);

export const reorderReportSettings = createAsyncThunk(
  'settings/reorderReportSettings',
  async ({ itemId, section, index }: any) => {
    await reorderReportSettingsRequest({ itemId, section, index });

    notification.success({ message: 'Report setting successfully reordered!' });
  }
);

export const disableReport = createAsyncThunk(
  'settings/disableReport',
  async ({ reportId }: any) => {
    await disableReportRequest({ reportId });

    notification.success({ message: 'Report successfully disabled!' });
  }
);

export const enableReport = createAsyncThunk('settings/enableReport', async ({ reportId }: any) => {
  await enableReportRequest({ reportId });

  notification.success({ message: 'Report successfully enabled!' });
});

const settingsSlice = createSlice({
  name: 'settings',
  reducers: {},
  initialState: {
    addUserSettings: {
      loading: false,
      data: null,
      error: null,
    },
    updateUserSettings: {
      loading: false,
      data: null,
      error: null,
    },
    addAdminSettings: {
      loading: false,
      data: null,
      error: null,
    },
    updateAdminSettings: {
      loading: false,
      data: null,
      error: null,
    },
    getUserSettings: {
      loading: false,
      data: [],
      error: null,
    },
    getAdminSettings: {
      loading: false,
      data: [],
      error: null,
    },
    enableUserSetting: {},
    disableUserSetting: {},
    resetUserSetting: {},
    enableAdminSetting: {},
    disableAdminSetting: {},
    resetDefaultPermissions: {},
    getPermissionMatrixColumns: {
      loading: false,
      data: [],
      error: null,
    },
    getPermissionMatrixRows: {
      loading: false,
      data: [],
      error: null,
    },
    getPermissionMatrix: {
      loading: false,
      data: {},
      error: null,
    },
    setPermissionValue: {
      loading: false,
      data: null,
      error: null,
    },
    importJobStatusCodes: {
      loading: false,
      data: null,
      error: null,
    },
    getJobStatusCodesSettings: {
      loading: false,
      data: [],
      error: null,
    },
    getProjectRoles: {
      loading: false,
      data: [],
      error: null,
    },
    getFormTemplates: {
      loading: false,
      data: [],
      error: null,
    },
    getReasonCodesByType: {
      loading: false,
      data: [],
      error: null,
    },
    getProjectFieldsSettings: {
      loading: false,
      data: [],
      error: null,
    },
    getCustomSelectOptions: {
      loading: false,
      data: [],
      error: null,
    },
    getContactFieldsSettings: {
      loading: false,
      data: [],
      error: null,
    },
  },
  extraReducers: (builder) => {
    buildDefaultActionResult('addUserSettings', addUserSettings, builder);
    buildDefaultActionResult('updateUserSettings', updateUserSettings, builder);
    buildDefaultActionResult('addAdminSettings', addAdminSettings, builder);
    buildDefaultActionResult('updateAdminSettings', updateAdminSettings, builder);
    buildDefaultActionResult('getAdminSettings', getAdminSettings, builder);
    buildDefaultActionResult('getUserSettings', getUserSettings, builder);
    buildDefaultActionResult('getPermissionMatrixColumns', getPermissionMatrixColumns, builder);
    buildDefaultActionResult('getPermissionMatrix', getPermissionMatrix, builder);
    buildDefaultActionResult('getPermissionMatrixRows', getPermissionMatrixRows, builder);
    buildDefaultActionResult('setPermissionValue', setPermissionValue, builder);
    buildDefaultActionResult('importJobStatusCodes', importJobStatusCodes, builder);
    buildDefaultActionResult('getJobStatusCodesSettings', getJobStatusCodesSettings, builder);
    buildDefaultActionResult('getProjectRoles', getProjectRoles, builder);
    buildDefaultActionResult('getFormTemplates', getFormTemplates, builder);
    buildDefaultActionResult('getReasonCodesByType', getReasonCodesByType, builder);
    buildDefaultActionResult('getProjectFieldsSettings', getProjectFieldsSettings, builder);
    buildDefaultActionResult('getContactFieldsSettings', getContactFieldsSettings, builder);
    buildDefaultActionResult('getCustomSelectOptions', getCustomSelectOptions, builder);
    buildDefaultActionResultForList(
      'enableUserSetting',
      enableUserSetting,
      builder,
      ((a) => a[1]) as any
    );
    buildDefaultActionResultForList(
      'disableUserSetting',
      disableUserSetting,
      builder,
      ((a) => a[1]) as any
    );
    buildDefaultActionResultForList(
      'resetUserSetting',
      resetUserSetting,
      builder,
      ((a) => a[1]) as any
    );
    buildDefaultActionResultForList(
      'enableAdminSetting',
      enableAdminSetting,
      builder,
      ((a) => a[1]) as any
    );
    buildDefaultActionResultForList(
      'disableAdminSetting',
      disableAdminSetting,
      builder,
      ((a) => a[1]) as any
    );
    buildDefaultActionResultForList(
      'resetDefaultPermissions',
      resetDefaultPermissions,
      builder,
      ({ roleId }) => roleId
    );
  },
});

export default settingsSlice.reducer;
