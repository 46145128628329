import apiClient from '../../utils/api-client';

export const createCompanyRequest = (data) =>
  apiClient.post('/companies/createCompany', data).then((r) => r.data);

export const updateCompanyRequest = (data) =>
  apiClient.post('/companies/updateCompany', data).then((r) => r.data);

export const saveCompanyRequest = (data) => {
  const { companyId, companyName, companyWebsite, taxId, emailArr, phoneArr, addressArr, dba, companyTags, customFields } =
    data;

  if (companyId) {
    return updateCompanyRequest({
      companyId,
      companyName,
      companyWebsite,
      taxId,
      emailArr,
      phoneArr,
      addressArr,
      dba,
      companyTags,
      customFields,
    });
  }

  return createCompanyRequest({
    companyName,
    companyWebsite,
    taxId,
    emailArr,
    phoneArr,
    addressArr,
    dba,
    companyTags,
    customFields,
  });
};

export const importCompaniesParseRequest = (formData) =>
  apiClient.post('/companies/importCompanies/parse', formData);

export const importCompaniesLoadRequest = (data) => {
  const newData = data.map((item) => {
    const { countryCode, phoneNumber, phoneType, ...rest } = item;

    const phoneArr =
      countryCode || phoneNumber || phoneType
        ? [
            {
              ext: countryCode,
              phone: phoneNumber,
              type: phoneType,
              isPrimary: true,
            },
          ]
        : [];
    return { ...rest, phoneArr };
  });

  return apiClient.post('/companies/importCompanies/load', {
    data: newData,
  });
};

export const companiesDropdownRequest = () =>
  apiClient.post('/parse/functions/companyDropDown').then(({ data }) => data.result.data);

export const listCompaniesRequest = (params) =>
  apiClient.post('/parse/functions/listCompanies',params).then(({ data }) => data.result);

export const deleteCompaniesRequest = (ids = []) =>
  apiClient.post('/companies/deleteCompanies', { ids });

export const saveCompanyContactAffiliationRequest = ({
  accountId,
  companyId,
  phoneArr,
  emailArr,
  addressArr,
}) =>
  apiClient.post('/companies/saveCompanyContactAffiliation', {
    accountId,
    companyId,
    phoneArr,
    emailArr,
    addressArr,
  });

export const getCompanyContactAffiliationsRequest = () =>
  apiClient.post('/companies/getCompanyContactAffiliations').then((r) => r.data);

export const getCompanyAffiliationsRequest = ({ companyId }) =>
  apiClient.post('/companies/getCompanyAffiliations', { companyId }).then((r) => r.data);

export const getAccountAffiliationRequest = () =>
  apiClient.post('/companies/getAccountAffiliation').then((r) => r.data);

export const saveCompanyProfilePictureRequest = ({ file, companyId }) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('companyId', companyId);
  formData.append('accountId', localStorage.getItem('accountId'));

  return apiClient.post('/companies/saveCompanyProfilePicture', formData);
};

export const removeCompanyProfilePictureRequest = (companyId) =>
  apiClient.post('/companies/removeCompanyProfilePicture', { companyId });

export const validateCompanyNameRequest = ({ companyName, companyId }) =>
  apiClient.post('/companies/validateCompanyName', { companyName, companyId }).then((r) => r.data);

export const getCompanyEditInfoRequest = ({ companyId }) =>
  apiClient.post('/companies/getCompanyEditInfo', { companyId }).then((r) => r.data);

export const getCompanyRequest = ({ companyId }) =>
  apiClient.post('/companies/getCompanyEditInfo', { companyId }).then((r) => r.data);

export const deleteCompanyRequest = ({ companyId }) =>
  apiClient.post('/companies/deleteCompany', { companyId }).then((r) => r.data);

export const getSingleAffiliatedContactsRequest = ({ companyId }) =>
  apiClient.post('/companies/getSingleAffiliatedContacts', { companyId }).then((r) => r.data);

export const deleteCompanyWithSingleContactsRequest = ({ companyId }) =>
  apiClient
    .post('/companies/deleteCompanyWithSingleContacts', { companyId })
    .then((r) => r.data);

export const getTagsRequest = ({ type }) =>
  apiClient.post('/companies/getTags', { type }).then((r) => r.data);

export const getTagNamesRequest = ({ tagIds }) =>
  apiClient.post('/companies/getTagNames', { tagIds }).then((r) => r.data);

export const getCompanyProjectsRequest = ({ companyId }) =>
  apiClient.post('/companies/getCompanyProjects', { companyId }).then((r) => r.data);

  export const getCompanyCustomFieldsValuesFormRequest = ({ companyId }) => {
    return apiClient
      .post('/companies/getCompanyCustomFieldsValuesForm', { companyId })
      .then(({ data }) => data);
  };
