import { useCallback } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useConfirm } from '../../../confirmation-dialog';
import { useDisabledFieldsContext } from '../../../disabled-fields';
import styles from '../field-actions.module.scss';

type DeleteProps = {
  name: string;
  index: number;
  isDisabled?: boolean;
  itemType?: string
  requireConfirmation?: boolean;
};

const Delete = ({
  name = '',
  index = 0,
  isDisabled = false,
  itemType = 'item',
  requireConfirmation = true,
}: DeleteProps) => {

  const confirm = useConfirm();
  const forceDisabled = useDisabledFieldsContext();

  const { control, watch } = useFormContext<any>();
  const { remove } = useFieldArray({ control, name });
  const fieldArr = watch(name, []);

  const field = fieldArr[index];
  const isActionDisabled = forceDisabled || isDisabled;

  const confirmTitle = 'Remove '+ itemType;
  const confirmQuestion = 'Are you sure you want to delete this '+ itemType +'?';

  const onItemDelete = useCallback(async (index) => {
    const ok = !requireConfirmation || await confirm({ title: confirmTitle, question: confirmQuestion });

    ok && remove(index);
  }, []);

  if (!field || isActionDisabled) {
    return null;
  }

  const className = `fa-solid fa-circle-xmark ${styles.actionButton} ${styles.deleteButton}`;

  return (
    <i data-testid='Delete' role='button' className={className} onClick={() => onItemDelete(index)} data-title='Delete'></i>
  );
};

export default Delete;
