import _ from 'lodash';
import { useCallback, useState } from 'react';
import { useAppDispatch } from '../../shared/hooks/use-app-dispatch';
import { useAppSelector } from '../../shared/hooks/use-app-selector';
import { useHistory } from 'react-router-dom';
import { importProjectsLoad } from '../../modules/projects';
import {
  selectImportProjectsLoad,
  selectImportProjectsParse,
} from '../../modules/projects/selectors';
import { useRowSelection } from '../../shared/data-grid';
import DataGridMatrix from '../../shared/data-grid/data-grid-matrix/data-grid-matrix';
import { useImportProjectsColumns } from './use-import-projects-columns';
import { uploadDescriptor, errorDescriptor } from '../../shared/data-grid/import-components';
import { columnDescriptor } from '../../shared/data-grid/use-row-selection/use-row-selection';
import { notification } from 'antd';

const ImportProjectsView = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { data } = useAppSelector(selectImportProjectsParse);
  const [done, setDone] = useState(false);
  const { loading: submitting } = useAppSelector(selectImportProjectsLoad);

  const [fieldsData, getCellFormatter] = useImportProjectsColumns();

  const columnsData = [...fieldsData.filter(field=>!(field.hidden)).map((field) => ({
    Header: field.label,
    accessor: field.name,
    Cell: getCellFormatter(field),
  }))];

  const selectionState = useRowSelection({ data });
  const { getSelectedSomething, selected, setSelected } = selectionState;
  const isSomethingSelected = getSelectedSomething();

  const columns = [ errorDescriptor, columnDescriptor, uploadDescriptor, ...columnsData];

  const onUploadClick = useCallback(() => {
    const formattedRows = data.map((row) => {
      const newRow = { projectTeam: {}, customFields: {} };

      // move projectTeam related fields into projectTeam object. process other fields
      Object.keys(row).forEach((key) => {
        const fieldInfo = fieldsData.find((field) => field.name === key);
        if (fieldInfo) {
          const fieldValue = row[key];

          if (fieldInfo.isCustom) {
            newRow.customFields[key] = fieldValue;
          } else {
            switch (fieldInfo.type) {
              case 'id':
                newRow[key] = fieldValue?._id || '';
                break;
              case 'array':
                newRow[key] = Array.isArray(fieldValue) ? fieldValue.map((el) => el._id) : fieldValue;
                break;
              case 'affiliation':
                newRow.projectTeam[key] = Array.isArray(fieldValue) ? fieldValue.map((el) => el._id) : fieldValue;
                break;
              default:
               newRow[key] = fieldValue;
            }
          }
        }
      });

      return { ...newRow };
    });

    dispatch(importProjectsLoad([data, formattedRows, selected]))
      .unwrap()
      .then(() => setSelected({}))
      .then(() => {
        notification.success({ message: 'Uploaded!' });
        setDone(true);
      })
      .catch(() => notification.error({ message: 'Error!' }));
  }, [data, selected]);

  return (
    <div className='container'>
      <div className='d-flex justify-content-between py-3 gap-2'>
        <div className='d-flex gap-3'>
          <button
            onClick={() => history.push('/import-projects')}
            className='btn btn-primary mr-auto'
          >
            Back
          </button>
          <span className='mr-auto align-self-center'>
            <b>Import Projects</b>
          </span>
        </div>
        <div className='d-flex gap-3 align-items-center'>
          <span>Total records: {data?.length || '0'}</span>
          <button
            disabled={!isSomethingSelected || submitting}
            onClick={onUploadClick}
            className='btn btn-primary'
          >
            {submitting ? 'Loading' : 'Upload'}
          </button>
          <button
            className='btn btn-primary'
            disabled={!done}
            onClick={() => history.push(`/edit-pending-project-uploads/${localStorage.getItem('accountId')}`)}
          >
            Continue
          </button>
        </div>
      </div>

      <DataGridMatrix
        useControlledState={(state) => ({
          ...state,
          ...selectionState,
        })}
        columns={columns}
        data={data}
      />
    </div>
  );
};

export default ImportProjectsView;
