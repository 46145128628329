import React, {Suspense} from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { appConstants } from '../../../_constants';
import {
  createReasonCodeAction,
  getAllReasonCodeAction,
  updateReasonCodeAction,
  deleteReasonCodeAction,
} from '../../../actions/reasonCode';
import CustomTable from "../../Common/CustomTable";
import { getAllJobStatusAction } from '../../../actions/jobStatusCode';
import './../../../index.css';
import ReactLoading from 'react-loading';
import { callAlertMessage, UpperCaseName } from '../../../components/Auth/Auth';

// eslint-disable-next-line no-undef
let Validator = require('validatorjs');
let styleData = { color: 'red' };
let formArr = {};
let rules = {
  jobStatusCodes: 'required',
  reasonName: 'required',
};
let mess = {
  required: 'This field is required',
  alpha: 'This field may only contain letters',
  numeric: 'This field must be a number',
};
let validation = [];
validation = new Validator([], rules, mess);
validation.passes();
validation.fails();

class ReasonCodes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hidden: true, // htmlFor use objectid hide in table
      sessionToken: localStorage.getItem('sessionToken'),
      userId: localStorage.getItem('userId'),
      accountId: localStorage.getItem('accountId'),
      list: [],
      formArr: [],
      modal: false,
      saveType: 'POST',
      formData: {},
      selectBox: {},
      StatusChangeList: [],
      changeOrderList: [],
      ChangeDateList: [],
      optionTypes: [],
      defaultValue: [],
      reasonModalName: '',
      jobStatusCodeList: [],
      fetchRequest: false,
      isCreated: false,
      successRedirect: false,
      reasonType: '',
      definition: '',
      typeReasonModalName: '',
      masterTypeArr: [],
      selectedRows: {
        statusCodeChanges: [],
        changeOrderReasons: [],
        dateChangeReasons: [],
      },
      showProcessing: false,
    };
    this.toggle = this.toggle.bind(this);
    this.handleSelection = this.handleSelection.bind(this);
  }


  componentDidMount() {
    this.getReasonCodeList();
    this.getJobStatusList();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    var masterTypeArr = this.state.masterTypeArr;
    if (nextProps.allReasonCode) {
      this.setState({ fetchRequest: true });
    }

    if (nextProps.allReasonCode) {
      this.setState({
        StatusChangeList: nextProps.allReasonCode?.StatusChange?.map((p) => ({ ...p, disabled: p.isSystemMaster === true})),
        changeOrderList: nextProps.allReasonCode?.ChangeOrder?.map((p) => ({ ...p, disabled: p.isSystemMaster === true})),
        ChangeDateList: nextProps.allReasonCode?.DateChange?.map((p) => ({ ...p, disabled: p.isSystemMaster === true})),
        fetchRequest: true
      });


      if (nextProps.allReasonCode.StatusChange && nextProps.allReasonCode.StatusChange.length > 0) {
        nextProps.allReasonCode.StatusChange.map((v, i) => {
          if (v && v.isSystemMaster === true && localStorage.getItem('loginType') !== 'SUPERADMIN') {
            masterTypeArr.push(v.objectId);
          }
          return true;
        });
        this.setState({
          masterTypeArr: masterTypeArr,
        });
      }

      if (nextProps.allReasonCode.ChangeOrder && nextProps.allReasonCode.ChangeOrder.length > 0) {
        nextProps.allReasonCode.ChangeOrder.map((v, i) => {
          if (v && v.isSystemMaster === true && localStorage.getItem('loginType') !== 'SUPERADMIN') {
            masterTypeArr.push(v.objectId);
          }
          return true;
        });
        this.setState({
          masterTypeArr: masterTypeArr,
        });
      }

      if (nextProps.allReasonCode.DateChange && nextProps.allReasonCode.DateChange.length > 0) {
        nextProps.allReasonCode.DateChange.map((v, i) => {
          if (v && v.isSystemMaster === true) {
            masterTypeArr.push(v.objectId);
          }
          return true;
        });
        this.setState({
          masterTypeArr: masterTypeArr,
        });
      }
    }

    let jobStatusCodeList = [];
    if (nextProps.allJobStatus && nextProps.allJobStatus.length > 0) {
      nextProps.allJobStatus.map((v) => {
        if (v.objectId !== '') {
          jobStatusCodeList.push({
            value: v.objectId,
            label: (v.jobStatusCodesName+' ('+v.jobStatusCodesCode+')'),
          });
        }
        this.setState({ jobStatusCodeList });
        return true;
      });
    }

    if (nextProps.isCreated !== this.state.isCreated && nextProps.isCreated === true) {
      this.setState({
        isCreated: true,
      });
      let _this = this;
      if (nextProps.success === 1 && this.state.successRedirect && nextProps.message !== '') {
        callAlertMessage('success', nextProps.message);
        if (_this.state.modal) {
          _this.setState({ modal: false });
        }
        this.getReasonCodeList();
      }
      if (nextProps.success === 0 && nextProps.message !== '') {
        callAlertMessage('error', nextProps.message);
        if (_this.state.modal) _this.setState({ modal: true });
      }
    }

    if (this.state.showProcessing === true) {
      this.setState({ showProcessing: false });
    }
  }

  getReasonCodeList() {
    let data = {};
    data['className'] = 'reasonCodes';
    data['accountId'] = this.state.accountId;
    this.props.getAllReasonCodeAction(data);
  }

  getJobStatusList() {
    let data = {};
    data['className'] = 'jobStatusCodes';
    data['accountId'] = this.state.accountId;
    this.props.getAllJobStatusAction(data);
  }

  toggle(val) {
    let reasonCode = '';
    if (val !== 'PUT') {
      this.setState({ formData: {}, selectBox: {} });
      if (val === 'StatusChange') {
        reasonCode = 'StatusChange';
        this.setState({ reasonType: reasonCode, reasonModalName: val });
      }
      if (val === 'ChangeOrder') {
        reasonCode = 'ChangeOrder';
        this.setState({ reasonType: reasonCode, reasonModalName: val });
      }
      if (val === 'ChangeDate') {
        reasonCode = 'DateChange';
        this.setState({ reasonType: reasonCode, reasonModalName: val });
      }
    } else {
      this.setState({ saveType: 'PUT' });
    }
    this.setState({ modal: !this.state.modal, formArr: [] });
  }

  addReasonCode(event) {
    event.preventDefault();
    let formData = this.state.formData;
    formData['className'] = 'reasonCodes';
    formData['userId'] = this.state.userId;
    formData['accountId'] = this.state.accountId;
    if (this.state.reasonType) formData['reasonType'] = this.state.reasonType;
    formData['jobStatusCodes'] =
      this.state.selectBox.jobStatusCodes && this.state.selectBox.jobStatusCodes.value
        ? this.state.selectBox.jobStatusCodes.value
        : '';
    formData['definition'] = this.state.formData.definition;
   
    formData['isSystemMaster'] = false;
   
    let _this = this;
    if (this.state.saveType === 'POST') {
      this.props.createReasonCodeAction(formData);
    } else {
      if (this.state.reasonType === 'edit StatusChange') {
        _this.state.formData.reasonType = 'StatusChange';
      }
      if (this.state.reasonType === 'edit ChangeOrder') {
        _this.state.formData.reasonType = 'ChangeOrder';
      }
      if (this.state.reasonType === 'edit DateChange') {
        _this.state.formData.reasonType = 'DateChange';
      }
      this.props.updateReasonCodeAction(formData);
    }
    this.setState({ showProcessing: true });
    this.setState({ successRedirect: true, isCreated: false });
    // }
  }

  allValidate(check) {
    if (!check) {
      formArr = [];
      Object.keys(rules).forEach(function (key) {
        formArr[key] = 'TT';
        console.log(formArr[key]);
      });
      this.setState({
        formArr,
      });
    }
    if (validation.passes()) {
      return 1;
    }
  }

  handleChange = (e) => {
    e.preventDefault();
    let formData = this.state.formData;
    formData[e.target.name] = e.target.value;

    if (e.target.name === 'reasonName') {
      formData[e.target.name] = UpperCaseName(e.target.value);
    }

    this.setState({ formData });
  };

  handleChangeStatus = (status, name) => {
    let formData = this.state.formData;
    let selectBox = this.state.selectBox;
    formData['jobStatusCodes'] = status.value;
    selectBox['jobStatusCodes'] = status;
    this.setState({
      formData: formData,
      selectBox: selectBox,
    });
  };

  handleSelection = (items, type) => {
    this.setState(prevState => {
      return {
        ...prevState,
        selectedRows: {
          ...prevState.selectedRows,
          [type]: items,
        }
      };
    })
  }

  handleDeleteButtonClick = (type) => {
    const { selectedRows, StatusChangeList, changeOrderList, ChangeDateList } = this.state;
    let data = {};
    let listData = [];
    if(type === 'statusCodeChanges') {
      listData = StatusChangeList
    } else if(type === 'changeOrderReasons') {
      listData = changeOrderList;
    } else {
      listData = ChangeDateList;
    }
    const selection = selectedRows[type];
    const keysToDelete = listData.filter((f, index) => selection.includes(index.toString())).map((k) => ({
      objectId: k.objectId
    }));
    data['className'] = 'reasonCodes';
    data['dataArr'] = keysToDelete;
    const confirm = window.confirm("Are you sure to delete?");
    if(confirm) {this.props.deleteReasonCodeAction(data);
      this.setState({ successRedirect: true, isCreated: false });
    } else {
      console.log({ confirm });
    }
  };

  selectedRow1 = (row) => {
    this.setState({
      formData: row,
    });

    if (row.reasonType === 'StatusChange') {
      var selectBox = this.state.selectBox;
      selectBox.jobStatusCodes = {
        value: row.jobStatusCodes,
        label: row.jobStatusCodesName + ' (' + row.jobStatusCodesCode +')',
      };
      
      this.setState({
        formData: row,
        reasonType: 'edit StatusChange',
        selectBox: selectBox,
      });
    }

    if (row.reasonType === 'ChangeOrder') {
      this.setState({ formData: row, reasonType: 'edit ChangeOrder' });
    }

    if (row.reasonType === 'DateChange') {
      this.setState({ formData: row, reasonType: 'edit DateChange' });
    }


    if (row && row.isSystemMaster === false) {
      this.toggle('PUT'); // Select row to open edit modal
    } else {
      return false;
    }

    //this.toggle("PUT");
  };

  _setTableOption() {
    if (this.state.fetchRequest) {
      return 'No data found..';
    } else {
      return (
        <ReactLoading
          className="table-loader"
          type={appConstants.LOADER_TYPE}
          color={appConstants.LOADER_COLOR}
          height={appConstants.LOADER_HEIGHT}
          width={appConstants.LOADER_WIDTH}
        />
      );
    }
  }

  render() {
    var classTitle = '';
    var errorMsgStatus;
    var errorMsgReason;
    if (this.state.reasonType === 'StatusChange') {
      classTitle = 'Add status change reason codes';
      errorMsgStatus = (
        <span style={styleData}>
          {this.state.formArr.reasonName && validation.errors.first('reasonName')}
        </span>
      );
      errorMsgReason = (
        <span style={styleData}>
          {this.state.formArr.jobStatusCodes && validation.errors.first('jobStatusCodes')}
        </span>
      );
    }
    if (this.state.reasonType === 'edit StatusChange' && this.state.formData.objectId) {
      classTitle = 'Edit Status Code Changes';
      errorMsgStatus = (
        <span style={styleData}>
          {this.state.formArr.reasonName && validation.errors.first('reasonName')}
        </span>
      );
      errorMsgReason = (
        <span style={styleData}>
          {this.state.formArr.jobStatusCodes && validation.errors.first('jobStatusCodes')}
        </span>
      );
    }

    if (this.state.reasonType === 'ChangeOrder') {
      classTitle = 'Add Change Order Reasons';
      errorMsgReason = (
        <span style={styleData}>
          {this.state.formArr.jobStatusCodes && validation.errors.first('jobStatusCodes')}
        </span>
      );
    }
    if (this.state.reasonType === 'edit ChangeOrder' && this.state.formData.objectId) {
      classTitle = 'Edit Change Order Reasons';
      errorMsgReason = (
        <span style={styleData}>
          {this.state.formArr.jobStatusCodes && validation.errors.first('jobStatusCodes')}
        </span>
      );
    }

    if (this.state.reasonType === 'DateChange') {
      classTitle = 'Add Date Change Reasons';
      errorMsgReason = (
        <span style={styleData}>
          {this.state.formArr.jobStatusCodes && validation.errors.first('jobStatusCodes')}
        </span>
      );
    }
    if (this.state.reasonType === 'edit DateChange' && this.state.formData.objectId) {
      classTitle = 'Edit Date Change Reasons';
      errorMsgReason = (
        <span style={styleData}>
          {this.state.formArr.jobStatusCodes && validation.errors.first('jobStatusCodes')}
        </span>
      );
    }

    validation = new Validator(this.state.formData, rules, mess);
    validation.passes();
    validation.fails();

    const selectRowProp = {
      mode: 'checkbox',
      unselectable: this.state.masterTypeArr,
    };

    //show option like delete, pagination, number of data, search
    let tableOption = appConstants.TableOption;
    tableOption['afterDeleteRow'] = this.handleDeleteButtonClick;
    tableOption['paginationShowsTotal'] = this.renderShowsTotal;
    tableOption['onRowClick'] = this.selectedRow1;
    tableOption['noDataText'] = this._setTableOption();

    const statusCodeColumns = [
      {
        Header: "Changed To",
        accessor: "jobStatusCodesName",
        width: 100
      },
      {
        Header: "Reason",
        accessor: "reasonName",
        width: 200
      },
      {
        Header: "Definition",
        accessor: "definition",
        width: 300
      },
    ];
    const changeOrderReasonColumns = [
      {
        Header: "Reasons",
        accessor: "reasonName",
        width: 200
      },
      {
        Header: "Definition",
        accessor: "definition",
        width: 300
      },
    ];
    const dataChangeReasonColumns = [
      {
        Header: "Reasons",
        accessor: "reasonName",
        width: 200
      },
      {
        Header: "Definition",
        accessor: "definition",
        width: 300
      },
    ];

    const isUpdate = this.state.formData.objectId? true: false;
    const isStatusReason = this.state.reasonType === 'StatusChange';
    const isEditStatusReason = this.state.reasonType === 'edit StatusChange';
    const isChangeOrderReason = this.state.reasonType === 'ChangeOrder';
    const isEditChangeOrderReason = this.state.reasonType === 'edit ChangeOrder';
    //const isDateChangeReason = this.state.reasonType === 'DateChange';

    return (
      <>
        <div className="headingbox">
          <h2 className="heading2">Reason Codes</h2>
        </div>
        <div className="row">
          <div className="col-md-12">
            <h3 className="infoHeadingRC">Status Code Changes</h3>
          </div>
          <div className="col-md-12">
            <button className="btn btnRight" onClick={() => this.toggle('StatusChange')}>
              Add
            </button>
            <button className="btn btn-primary" style={{cursor: 'pointer', padding: '5px'}} disabled={this.state.selectedRows?.['statusCodeChanges']?.length < 1} onClick={() => this.handleDeleteButtonClick('statusCodeChanges')}>
              <i className="fa fa-trash" aria-hidden="true"></i>&nbsp;Delete
            </button>
            <CustomTable
                columns={statusCodeColumns}
                list={this.state.StatusChangeList}
                isLoading={!this.state.fetchRequest}
                canShowColumnSettings={false}
                canShowDateFilter={false}
                canShowExport={false}
                canShowPagination={false}
                onSavePreferences={()=>{}}
                canShowRowSelection={true}
                onRowClicked={(cell, row, e) => this.selectedRow1(row.original)}
                onChangeRowSelection={(items) => this.handleSelection(items, 'statusCodeChanges')}
            />
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-md-12">
            <h3 className="infoHeadingRC">Change Order Reasons</h3>
          </div>
          <div className="col-md-12">
            <button
              className="btn btn-primary addButton"
              onClick={() => this.toggle('ChangeOrder')}
            >
              Add
            </button>
            <button className="btn btn-primary" style={{cursor: 'pointer', padding: '5px'}} disabled={this.state.selectedRows?.['changeOrderReasons']?.length < 1} onClick={() => this.handleDeleteButtonClick('changeOrderReasons')}>
              <i className="fa fa-trash" aria-hidden="true"></i>&nbsp;Delete
            </button>
            <CustomTable
                columns={changeOrderReasonColumns}
                list={this.state.changeOrderList}
                canShowColumnSettings={false}
                canShowDateFilter={false}
                canShowExport={false}
                canShowPagination={false}
                onSavePreferences={()=>{}}
                canShowRowSelection={true}
                onRowClicked={(cell, row, e) => this.selectedRow1(row.original)}
                onChangeRowSelection={(items) => this.handleSelection(items, 'changeOrderReasons')}
            />
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-md-12">
            <h3 className="infoHeadingRC">Date Change Reasons</h3>
          </div>
          <div className="col-md-12">
            <button
              className="btn btnRight"
              onClick={() => this.toggle('ChangeDate')}
            >
              Add
            </button>
            <button className="btn btn-primary" style={{cursor: 'pointer', padding: '5px'}} disabled={this.state.selectedRows?.['dateChangeReasons']?.length < 1} onClick={() => this.handleDeleteButtonClick('dateChangeReasons')}>
              <i className="fa fa-trash" aria-hidden="true"></i>&nbsp;Delete
            </button>
            <CustomTable
                columns={dataChangeReasonColumns}
                list={this.state.ChangeDateList}
                canShowColumnSettings={false}
                canShowDateFilter={false}
                canShowExport={false}
                canShowPagination={false}
                onSavePreferences={()=>{}}
                canShowRowSelection={true}
                onRowClicked={(cell, row, e) => this.selectedRow1(row.original)}
                onChangeRowSelection={(items) => this.handleSelection(items, 'dateChangeReasons')}
            />
          </div>
        </div>

        <Modal
          backdrop="static"
          keyboard={true}
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={this.props.className}
          size='lg'
        >
          <ModalHeader toggle={this.toggle}>{classTitle}</ModalHeader>
          <ModalBody>
            <div className="container">
              {!isUpdate&&(isStatusReason ? (
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="reasonCode">Code Changed To</label>
                      <Select
                        name="reasonType"
                        components={makeAnimated()}
                        value={this.state.selectBox.jobStatusCodes}
                        onChange={this.handleChangeStatus}
                        options={this.state.jobStatusCodeList}
                      />
                      {errorMsgStatus}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="reasonName">Reason</label>
                      <input
                        type="text"
                        className="form-control"
                        name="reasonName"
                        id="reasonName"
                        placeholder="Reason"
                        value={this.state.formData.reasonName}
                        onChange={(e) => {
                          this.handleChange(e);
                        }}
                      />
                      {errorMsgReason}
                    </div>
                  </div>
                </div>
              ) : isChangeOrderReason ? (
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="reasonName">Reason</label>
                      <input
                        type="text"
                        className="form-control"
                        name="reasonName"
                        id="reasonName"
                        placeholder="Reason"
                        value={this.state.formData.reasonName}
                        onChange={(e) => {
                          this.handleChange(e);
                        }}
                      />
                      {errorMsgReason}
                    </div>
                  </div>
                </div>
              ) : 
                (
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label htmlFor="reasonName">Reason</label>
                        <input
                          type="text"
                          className="form-control"
                          name="reasonName"
                          id="reasonName"
                          placeholder="Reason"
                          value={this.state.formData.reasonName}
                          onChange={(e) => {
                            this.handleChange(e);
                          }}
                        />
                        {errorMsgReason}
                      </div>
                    </div>
                  </div>
                
                ))}

              {isUpdate&&(isEditStatusReason ? (
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="reasonCode">Code Changed To</label>
                      <Select
                        name="reasonType"
                        components={makeAnimated()}
                        value={this.state.selectBox.jobStatusCodes}
                        onChange={this.handleChangeStatus}
                        options={this.state.jobStatusCodeList}
                      />
                      {errorMsgStatus}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="reasonName">Reason</label>
                      <input
                        type="text"
                        className="form-control"
                        name="reasonName"
                        id="reasonName"
                        placeholder="Reason"
                        value={this.state.formData.reasonName}
                        onChange={(e) => {
                          this.handleChange(e);
                        }}
                      />
                      {errorMsgReason}
                    </div>
                  </div>
                </div>
              ) : isEditChangeOrderReason  ? (
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="reasonName">Reason</label>
                      <input
                        type="text"
                        className="form-control"
                        name="reasonName"
                        id="reasonName"
                        placeholder="Reason"
                        value={this.state.formData.reasonName}
                        onChange={(e) => {
                          this.handleChange(e);
                        }}
                      />
                      {errorMsgReason}
                    </div>
                  </div>
                </div>
              ) : 
                 ( // date change reason
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label htmlFor="reasonName">Reason</label>
                        <input
                          type="text"
                          className="form-control"
                          name="reasonName"
                          id="reasonName"
                          placeholder="Reason"
                          value={this.state.formData.reasonName}
                          onChange={(e) => {
                            this.handleChange(e);
                          }}
                        />
                        {errorMsgReason}
                      </div>
                    </div>
                  </div>
                )
              )}
             
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="reasonName">Definition</label>
                      <input
                        type="text"
                        className="form-control"
                        name="definition"
                        id="definition"
                        placeholder="Definition"
                        value={this.state.formData.definition}
                        onChange={(e) => {
                          this.handleChange(e);
                        }}
                      />
                    </div>
                  </div>
                </div>
              
            
            </div>
          </ModalBody>
          <ModalFooter>
            <button className="btn btn-primary" onClick={this.toggle}>
              Cancel
            </button>
            <button className="btn btn-primary" onClick={(e) => this.addReasonCode(e)}>
              Submit
            </button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    allReasonCode: state.reasonCode.allReasonCode,
    allJobStatus: state.jobStatusCode.allJobStatus,
    isCreated: state.reasonCode.isCreated,
    message: state.reasonCode.message,
    success: state.reasonCode.success,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    createReasonCodeAction,
    getAllReasonCodeAction,
    updateReasonCodeAction,
    deleteReasonCodeAction,
    getAllJobStatusAction,
  })(ReasonCodes)
);
