import * as actionTypes from '../actions/actionTypes';
const initState = {
  contact: {},
  message: '',
  isfetched: false,
  isfetchedError: false,
  onboarding: false,
  isOnboarding: false,
  isOnboardingError: false,
};

export default (state = initState, action = {}) => {
  switch (action.type) {
    case actionTypes.CREATE_MULTIPLE_CONTACT_REQUEST:
      return {
        ...state,
        response: [],
        message: '',
        isStatus: false,
      };
    case actionTypes.CREATE_MULTIPLE_CONTACT_SUCCESS:
      return {
        ...state,
        response: action.payload.response.data.result,
        message: '',
        isStatus: true,
        alert: { message: action.payload.response, type: 'alert-success' },
      };
    case actionTypes.CREATE_MULTIPLE_CONTACT_ERROR:
      return {
        ...state,
        response: [],
        message: action.payload.error,
        isStatus: false,
        alert: { message: action.payload.error, type: 'alert-danger' },
      };

    case actionTypes.IMPORT_MULTIPLE_COMPANY_REQUEST:
      return {
        ...state,
        response: [],
        message: '',
        isStatus: false,
      };
    case actionTypes.IMPORT_MULTIPLE_COMPANY_SUCCESS:
      return {
        ...state,
        response: action.payload.response.data.result,
        success: action.payload.response.data.result.success,
        //message: (action.payload.response.result.success)?"Record  successfully":action.payload.response.result.msg,
        isStatus: true,
      };
    case actionTypes.IMPORT_MULTIPLE_COMPANY_ERROR:
      return {
        ...state,
        response: [],
        message: action.payload.error,
        isStatus: false,
      };

    /*---------------Upload Project Sheet Data--------------------*/
    case actionTypes.UPLOAD_SHEET_DATA_REQUEST:
      return {
        ...state,
        response: [],
        message: '',
        isStatus: false,
      };
    case actionTypes.UPLOAD_SHEET_DATA_SUCCESS:
      return {
        ...state,
        response: action.payload.response.data.result,
        success: action.payload.response.data.result.success,
        message: action.payload.response.data.result.success
          ? 'Record  successfully'
          : action.payload.response.result.msg,
        isStatus: true,
      };
    case actionTypes.UPLOAD_SHEET_DATA_ERROR:
      return {
        ...state,
        response: [],
        message: action.payload.error,
        isStatus: false,
      };

    /*-------------------------Get All Project CppTemps-----------------------*/
    case actionTypes.IMPORT_MULTIPLE_CONTACT_REQUEST:
      return {
        ...state,
        response: [],
        message: '',
        isStatus: false,
      };
    case actionTypes.IMPORT_MULTIPLE_CONTACT_SUCCESS:
      return {
        ...state,
        response: action.payload.response.result,
        //success: action.payload.response.result.success,
        //message: (action.payload.response.result.success)?"Record  successfully":action.payload.response.result.msg,
        isStatus: true,
      };
    case actionTypes.IMPORT_MULTIPLE_CONTACT_ERROR:
      return {
        ...state,
        response: [],
        message: action.payload.error,
        isStatus: false,
      };

    /*---------------Update Status Code-----------Onboarding-------------*/
    case actionTypes.UPDATE_ONBOARDING_STATUS_REQUEST:
      return {
        ...state,
        response: [],
        message: '',
        isStatus: false,
      };

    case actionTypes.UPDATE_ONBOARDING_STATUS_SUCCESS:
      return {
        ...state,
        response: action.payload.response.result,
        success: action.payload.response.result.success,
        message: action.payload.response.result.success
          ? 'Record  successfully'
          : action.payload.response.result.msg,
        isStatus: true,
      };
    case actionTypes.UPDATE_ONBOARDING_STATUS_ERROR:
      return {
        ...state,
        response: [],
        message: action.payload.error,
        isStatus: false,
      };

    /*------------Import Contact Social Media Action----------------------------------*/
    case actionTypes.IMPORT_CONTACT_SOCIAL_MEDIA_REQUEST:
      return {
        ...state,
        response: [],
        message: '',
        isStatus: false,
      };
    case actionTypes.IMPORT_CONTACT_SOCIAL_MEDIA_SUCCESS:
      return {
        ...state,
        response: action.payload.response.result,
        success: action.payload.response.result.success,
        isStatus: true,
      };
    case actionTypes.IMPORT_CONTACT_SOCIAL_MEDIA_ERROR:
      return {
        ...state,
        response: [],
        message: action.payload.error,
        isStatus: false,
      };

    case actionTypes.GET_CONTACT_SOCIAL_MEDIA_REQUEST:
      return {
        ...state,
        reducerType: 'REQUEST',
        apiName: 'LIST',
        response: [],
        message: '',
        success: '',
      };
    case actionTypes.GET_CONTACT_SOCIAL_MEDIA_SUCCESS:
      return {
        ...state,
        reducerType: 'SUCCESS',
        apiName: 'LIST',
        response: action.payload.response.result,
        message: action.payload.response.result.success,
        success: action.payload.response.result.msg,
      };
    case actionTypes.GET_CONTACT_SOCIAL_MEDIA_ERROR:
      return {
        ...state,
        reducerType: 'ERROR',
        apiName: 'LIST',
        response: [],
        message: '',
        success: '',
      };

    case actionTypes.MERGE_CONTACT_SOCIAL_MEDIA_REQUEST:
      return {
        ...state,
        reducerType: 'REQUEST',
        apiName: 'MERGE',
        response: [],
        message: '',
        success: '',
      };
    case actionTypes.MERGE_CONTACT_SOCIAL_MEDIA_SUCCESS:
      return {
        ...state,
        reducerType: 'SUCCESS',
        apiName: 'MERGE',
        response: action.payload.response.result,
        message: action.payload.response.result.success,
        success: action.payload.response.result.msg,
      };
    case actionTypes.MERGE_CONTACT_SOCIAL_MEDIA_ERROR:
      return {
        ...state,
        reducerType: 'ERROR',
        apiName: 'MERGE',
        response: [],
        message: '',
        success: '',
      };
    case actionTypes.EDIT_CONTACT_SOCIAL_MEDIA_REQUEST:
      return {
        ...state,
        reducerType: 'REQUEST',
        response: [],
        message: '',
        success: '',
      };
    case actionTypes.EDIT_CONTACT_SOCIAL_MEDIA_SUCCESS:
      return {
        ...state,
        reducerType: 'SUCCESS',
        editResponse: action.payload.response.result,
        message: action.payload.response.result.success,
        success: action.payload.response.result.msg,
      };
    case actionTypes.EDIT_CONTACT_SOCIAL_MEDIA_ERROR:
      return {
        ...state,
        reducerType: 'ERROR',
        response: [],
        message: '',
        success: '',
      };
    case actionTypes.DELETE_CONTACT_SOCIAL_MEDIA_REQUEST:
      return {
        ...state,
        reducerType: 'REQUEST',
        response: [],
        message: '',
        success: '',
      };
    case actionTypes.DELETE_CONTACT_SOCIAL_MEDIA_SUCCESS:
      return {
        ...state,
        reducerType: 'SUCCESS',
        delResponse: action.payload.response.result,
        message: action.payload.response.result.success,
        success: action.payload.response.result.msg,
      };
    case actionTypes.DELETE_CONTACT_SOCIAL_MEDIA_ERROR:
      return {
        ...state,
        reducerType: 'ERROR',
        response: [],
        message: '',
        success: '',
      };
    case actionTypes.OUTLOOK_CONTACT_SOCIAL_MEDIA_REQUEST:
      return {
        ...state,
        reducerType: 'REQUEST',
        response: [],
        message: '',
        success: '',
      };
    case actionTypes.OUTLOOK_CONTACT_SOCIAL_MEDIA_SUCCESS:
      return {
        ...state,
        reducerType: 'SUCCESS',
        outlookResponse: action.payload.response.result,
        message: action.payload.response.result.success,
        success: action.payload.response.result.msg,
      };
    case actionTypes.OUTLOOK_CONTACT_SOCIAL_MEDIA_ERROR:
      return {
        ...state,
        reducerType: 'ERROR',
        response: [],
        message: '',
        success: '',
      };
    case actionTypes.OUTLOOK_CONTACT_DATA_SOCIAL_MEDIA_REQUEST:
      return {
        ...state,
        reducerType: 'REQUEST',
        response: [],
        message: '',
        success: '',
      };
    case actionTypes.OUTLOOK_CONTACT_DATA_SOCIAL_MEDIA_SUCCESS:
      return {
        ...state,
        reducerType: 'SUCCESS',
        outlookResponse: action.payload.response.result,
        message: action.payload.response.result.success,
        success: action.payload.response.result.msg,
      };
    case actionTypes.OUTLOOK_CONTACT_DATA_SOCIAL_MEDIA_ERROR:
      return {
        ...state,
        reducerType: 'ERROR',
        response: [],
        message: '',
        success: '',
      };

    case actionTypes.SKIP_ONBOARDING_REQUEST:
      return {
        ...state,
        onboarding: [],
        isOnboarding: false,
        isOnboardingError: false,
        message: '',
        success: '',
      };
    case actionTypes.SKIP_ONBOARDING_SUCCESS:
      return {
        ...state,
        onboarding: action.payload.response.result,
        isOnboarding: true,
        isOnboardingError: false,
        message: action.payload.response.result.success,
        success: action.payload.response.result.msg,
      };
    case actionTypes.SKIP_ONBOARDING_ERROR:
      return {
        ...state,
        onboarding: [],
        isOnboarding: false,
        isOnboardingError: true,
        message: action.payload.response.result.success,
        success: action.payload.response.result.msg,
      };
    default:
      return state;
  }
};
