import _ from 'lodash';
import Papa from 'papaparse';
import { notification } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useState, useCallback, useRef, useEffect } from 'react';
import { loadContacts, uploadCsvContacts, convertRowToContact } from '../../modules/contacts';
import { getImportingContacts } from '../../modules/contacts/selectors';
import useRowSelection from '../../shared/data-grid/use-row-selection';
import DataGridMatrix from '../../shared/data-grid/data-grid-matrix/data-grid-matrix';
import { SearchCompanyModal } from '../../modals/search-company-modal/search-company-modal';
import AddContactModal from '../../modals/add-contact-modal/add-contact-modal';
import { formatDateObjectOrNull } from '../../utils/date-formatters.js';
import { columns } from './columnHelper';
import styles from './styles.module.scss';

const ImportContactsView = () => {
  const { data: jsonRows } = useSelector(getImportingContacts);
  const fileParseConfig = {
    delimiter: '', // auto-detect
    newline: '', // auto-detect
    header: true,
    skipEmptyLines: true,
  };

  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const addContactModal = useRef(null);
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  const [dataFile, setDataFile] = useState(null);
  const [contactRows, setContactRows] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSearchCompanyModalOpen, setSearchCompanyModalOpen] = useState(false);
  const [currentRowIndex, setCurrentRowIndex] = useState(null);

  useEffect(() => {
    if (location.state?.contactsCsv) {
      setDataFile(location.state.contactsCsv);
    }
  }, [location]);

  useEffect(() => {
    if (!jsonRows?.length) {
      history.push('/import-contacts');
    }

    const newContactRows = _.cloneDeep(jsonRows);
    // on update do not drop _loadResult (_rowErrors and _isAvailable) if it was set
    contactRows.forEach((row, index) => {
      if (row._loadResult) {
        newContactRows[index]._loadResult = row._loadResult;
        newContactRows[index]._isAvailable = row._isAvailable;
        newContactRows[index]._rowErrors = row._rowErrors;
      }
    });

    setLoading(false);
    setContactRows(newContactRows);
  }, [jsonRows]);

  const selectionState = useRowSelection({ data: contactRows });
  const { selected, getSelectedSomething, setSelected } = selectionState;
  const isSomethingSelected = getSelectedSomething();

  const onContinueClick = () => history.push('/view-contacts');
  const onBackClick = () => history.goBack();
  const getOriginalCompanyName = () => {
    let companyName = '<No Company Selected>';
    if (currentRowIndex !== null) {
      const error = contactRows[currentRowIndex]._rowErrors.companyName;
      if (error && error.data) {
        companyName = error.data;
      }
    }
    return companyName;
  };

  const onUploadClick = async () => {
    try {
      setLoading(true);
      const { successCount, total } = await dispatch(
        loadContacts([contactRows, selected])
      ).unwrap();

      notification.success({
        message: `Uploaded ${successCount} / ${total} contacts`,
      });

      setSelected({});
    } catch (e) {
    } finally {
      setDone(true);
      setLoading(false);
    }
  };

  const handleEditRow = async (row) => {
    const selectedRowIndex = row.index;
    const selectedRowData = { ...contactRows[selectedRowIndex] };

    if (selectedRowData._rowErrors?.companyName) {
      setSearchCompanyModalOpen(true);
    } else if (row.original._isAvailable) {
      setIsModalOpen(true);

      const { contact, affiliations } = await dispatch(
        convertRowToContact(selectedRowData)
      ).unwrap();
      contact.dateOfBirthday = formatDateObjectOrNull(contact.dateOfBirthday);
      addContactModal.current.setContact(contact);
      addContactModal.current.setAffiliations(affiliations);
    }

    setCurrentRowIndex(selectedRowIndex);
  };

  const closeModal = useCallback(() => {
    setCurrentRowIndex(null);
    setIsModalOpen(false);
  }, []);

  const onMatchCompany = (company) => {
    if (company) {
      revalidateRows(company);
    }
  };

  const onAfterAddContact = () => {
    const newContactRows = _.cloneDeep(contactRows);

    // show row as uploaded
    newContactRows[currentRowIndex]._loadResult = { error: null };
    newContactRows[currentRowIndex]._isAvailable = false;

    setDone(true);
    setContactRows(newContactRows);
  };

  const revalidateRows = (company) => {
    Papa.parse(dataFile, {
      ...fileParseConfig,
      complete: async (results) => {
        const changedRowIndex = contactRows[currentRowIndex]._rowErrors.companyName.rowIndex;
        const rowData = results.data[changedRowIndex];
        if (rowData) {
          rowData['Company Name'] = company.companyName;

          const formData = new FormData();
          const csv = Papa.unparse({ fields: results.meta.fields, data: results.data });
          const csvData = new Blob([csv], { type: 'text/csv' });
          formData.append('contactsCsv', csvData);

          setLoading(true);
          setDataFile(csvData);

          await dispatch(uploadCsvContacts(formData));
        }
      },
    });
  };

  return (
    <div className='ms-2 me-2'>
      <div className='d-flex justify-content-between py-3 gap-2'>
        <div className='d-flex gap-3'>
          <button className='btn btn-primary mr-auto' onClick={onBackClick}>
            Back
          </button>
          <span className='mr-auto align-self-center'>
            <b>Import Contacts</b>&emsp;&emsp; Total records: {contactRows?.length || '0'}
          </span>
        </div>
        <div className='d-flex gap-3 align-items-center'>
          <button
            className='btn btn-primary'
            disabled={!isSomethingSelected || loading}
            onClick={onUploadClick}
          >
            {loading ? 'Loading ...' : 'Upload'}
          </button>
          <button className='btn btn-primary' disabled={!done} onClick={onContinueClick}>
            Continue
          </button>
        </div>
      </div>

      {currentRowIndex !== null && (
        <SearchCompanyModal
          open={isSearchCompanyModalOpen}
          onClose={() => setSearchCompanyModalOpen(false)}
          onConfirm={onMatchCompany}
          onGetOriginalCompanyName={getOriginalCompanyName}
        />
      )}

      <AddContactModal
        onSubmit={onAfterAddContact}
        isAddToCompany={false}
        open={isModalOpen}
        contact={{}}
        preAffiliations={[]}
        isQuickAdd={true}
        isImport={true}
        onClose={closeModal}
        ref={addContactModal}
      />

      <DataGridMatrix
        useControlledState={(state) => ({
          ...state,
          ...selectionState,
        })}
        columns={columns}
        data={contactRows}
        onRowClick={handleEditRow}
      />

      {loading && (
        <div className={styles.spinnerWrapper}>
          <div className='spinner-border text-primary' role='status'>
            <span className='sr-only'>Loading...</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default ImportContactsView;
