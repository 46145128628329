import { useContext } from 'react';
import TitleComponent from '../../components/Common/TitleComponent';
import Footer from '../../components/Layout/Footer';
import Header from '../../components/Layout/Header';
import { TitleContext } from '../title-context';

const MainLayout = ({ children, menuSection, showFooter=true }) => {
  const { title } = useContext(TitleContext);

  return (
    <div className='ContactListReport'>
      <TitleComponent data={{ title }} />
      <Header menuSection={menuSection}/>
      <div className='contactBox' style={{display:'contents'}}>{children}</div>
      {showFooter && (<Footer/>)}
    </div>
  );
};

export default MainLayout;
