import * as actionTypes from '../../actions/Admin/actionTypes';

const initState = {
  negotiatingMethod: {},
  allNegotiatingMethod: [],
  message: '',
  fetchRequest: false,
  isCreated: false,
  success: null,
};

export default (state = initState, action = {}) => {
  switch (action.type) {
    case actionTypes.CREATE_NEGOTIATING_METHOD_REQUEST:
      return {
        ...state,
        negotiatingMethod: {},
        message: '',
      };
    case actionTypes.CREATE_NEGOTIATING_METHOD_SUCCESS:
      return {
        allNegotiatingMethod: state.allNegotiatingMethod.concat(
          action.payload.response.result.data
        ),
        isCreated: true,
        success: action.payload.response.result.success,
        message: action.payload.response.result.success
          ? 'Record Added successfully'
          : action.payload.response.result.msg,
      };

    case actionTypes.CREATE_NEGOTIATING_METHOD_ERROR:
      return {
        ...state,
        isCreated: false,
        success: action.payload.response.result.success,
        message: action.payload.response.result.msg,
      };

    case actionTypes.GET_ALL_NEGOTIATING_METHOD_REQUEST:
      return {
        ...state,
        negotiatingMethod: {},
        message: '',
        fetchRequest: true,
      };

    case actionTypes.GET_ALL_NEGOTIATING_METHOD_SUCCESS:
      return {
        ...state,
        allNegotiatingMethod: action.payload.response.result.data.data,
        message: '',
        fetchRequest: false,
      };

    case actionTypes.GET_ALL_NEGOTIATING_METHOD_ERROR:
      return {
        ...state,
        message: '',
        fetchRequest: false,
      };

    case actionTypes.UPDATE_NEGOTIATING_METHOD_REQUEST:
      return {
        ...state,
        negotiatingMethod: {},
        message: '',
      };
    case actionTypes.UPDATE_NEGOTIATING_METHOD_SUCCESS:
      return {
        ...state,
        allNegotiatingMethod: action.payload.response.result.contractTypes,
        isCreated: true,
        success: action.payload.response.result.success,
        message: action.payload.response.result.success
          ? 'Record Updated successfully'
          : action.payload.response.result.msg,
      };

    case actionTypes.UPDATE_NEGOTIATING_METHOD_ERROR:
      return {
        ...state,
        isCreated: false,
        success: action.payload.response.result.success,
        message: action.payload.response.result.msg,
      };

    case actionTypes.DELETE_NEGOTIATING_METHOD_REQUEST:
      return {
        ...state,
        negotiatingMethod: {},
        message: '',
      };
    case actionTypes.DELETE_NEGOTIATING_METHOD_SUCCESS:
      return {
        ...state,
        allNegotiatingMethod: state.allNegotiatingMethod,
        isCreated: true,
        success: action.payload.response.result.success,
        message: action.payload.response.result.success
          ? 'Record Deleted successfully'
          : action.payload.response.result.msg,
      };

    case actionTypes.DELETE_NEGOTIATING_METHOD_ERROR:
      return {
        ...state,
        isCreated: false,
        success: action.payload.response.result.success,
        message: action.payload.response.result.msg,
      };

    default:
      return state;
  }
};
