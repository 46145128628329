import React ,{Suspense} from 'react';
import { withRouter } from 'react-router-dom';
import { renderToStaticMarkup } from 'react-dom/server';
import {useAuth} from '../Auth/Auth';
import axios from 'axios';
import { appConstants } from '../../_constants';
import { notification } from 'antd';

import { searchAction } from '../../actions/search';
import { connect } from 'react-redux';
import bannerImage from '../static/images/cpostLogo.png';
import Avatar from 'react-avatar';
import { withTranslation } from 'react-i18next';

class HeaderOnboarding extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTxt: '',
      sessionToken: localStorage.getItem('sessionToken'),
      messageLogout: false,
      searchData: {},
      SearchList: false,
      Searching: true,
      ShowSearchedData: false,
      companyNameInfo: localStorage.getItem('companyName'),
      formData: {},
      accountId: localStorage.getItem('accountId'),
      recentSearchData: {},
      comLogo: {},
      userInfo: JSON.parse(localStorage.getItem('userInfo')),
      onboardingStatus: Number(localStorage.getItem('onboardingStatus')),
    };
  }

componentDidMount(){
  if (
    localStorage.getItem('companyLogo') !== '' ||
    localStorage.getItem('companyLogo') !== null ||
    localStorage.getItem('companyLogo') === undefined
  )
    this.setState({ comLogo: localStorage.getItem('companyLogo') });

}

  logout() {
    const { logout } = useAuth();
          logout();
  }


  

  render() {
    
    const { isAuthenticated, logout} = useAuth();

    const t = this.props.t;
    
    //const userInfo = this.state.userInfo;
    const isAdmin = false;//userInfo?.cppRoleId === 'cppAdmin';

    return (
      <header className="onboarding-header">
        {!isAuthenticated() && logout()}
        <Suspense fallback="loading">
        <div className="container">
          <div className="row">
            <div className="col-sm-6">
              <div className="logo">
                <a>
                    {localStorage.getItem('companyLogo') !== null &&false ? (
                      <img
                        className=''
                        style={{ maxHeight: '50px', width:'150px'}}
                        src={this.state.comLogo}
                        alt='Upload Logo'
                      />
                    ) : !isAdmin ? (
                      <div className=''>
                        <Avatar
                          size='40'
                          colors={['#808B96', '#808B96', '#808B96']}
                          name={this.state.companyNameInfo}
                        />
                      </div>
                    ) :(<div><img src={bannerImage} style={{ maxHeight: '50px' }} alt="Default Logo" /></div>)}
                </a>
                
              </div>
            </div>
             {/*<div className="col-md-4 text-center">
              
             <div className="clearfix">
                <p className="on-brd-paragraph">
                  1. Companies&nbsp;&gt;&nbsp;2. Contacts&nbsp; &gt;&nbsp; 3. Projects &nbsp;&gt;&nbsp;4. Get
                  Insight
                </p>
                  </div>
            </div>
            */}
            <div className="col-sm-6 ms-auto justify-content-end ">
              {this.state.sessionToken && (
                <ul className="clearfix topnav topnav-link">
                  <li>
                    <a
                      className="btn btn-primary float-end"
                      id="qsLogoutBtn"
                      onClick={this.logout.bind(this)}
                    >
                      {t('Log Out')}
                    </a>
                  </li>
                </ul>
              )}
            </div>
          </div>
        </div>
        </Suspense>
      </header>
    );
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default withTranslation()(withRouter(connect(mapStateToProps, { searchAction })(HeaderOnboarding)));
