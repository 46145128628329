import { postRequest } from '../helper';

export const getExistingContact = (data) => postRequest('functions/getAdminUserList', data);
export const createContact_deprecated = (data) => postRequest(`functions/createUserContact`, data);
export const updateContact = (data) => postRequest(`functions/editContact`, data);
export const getAllAdminContacts = (data) => postRequest(`functions/getAdminUserList`, data);
export const deleteContact = (data) => postRequest(`functions/deleteContacts`, data);
export const moveContactToEmp = (data) => postRequest(`functions/makeEmployee`, data);
export const getContactDetails = (data) => postRequest(`functions/getContactCompanyDetails`, data);
export const AddContactCompany = (data) => postRequest(`functions/editCompanyContact`, data);

//dashboard api
export const getDashboard = (data) => postRequest(`functions/adminDashboard`, data);
