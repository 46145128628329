// CustomValueEditor.tsx
import { format, parse } from 'date-fns';
import { ValueEditor, ValueEditorProps, FieldSelector } from 'react-querybuilder';
import Select from 'react-select';
import { Checkbox } from 'antd';
import { DefaultLayout } from '../../../shared/form-controls/control-layouts/default-layout';
import styles from '../../../shared/form-controls/form-checkbox/style.module.scss';
import _ from 'lodash';

const dateFormat = 'yyyy-MM-dd';

const dateOptions = [
  { label: 'Today', value: format(new Date(), dateFormat) },
  { label: 'Now', value: format(new Date(), dateFormat) },
  {
    label: 'Yesterday',
    value: format(new Date(new Date().setDate(new Date().getDate() - 1)), dateFormat),
  },
  {
    label: 'Last 7 Days',
    value: format(new Date(new Date().setDate(new Date().getDate() - 7)), dateFormat),
  },
  {
    label: 'Last 30 Days',
    value: format(new Date(new Date().setDate(new Date().getDate() - 30)), dateFormat),
  },
  {
    label: 'Last 90 Days',
    value: format(new Date(new Date().setDate(new Date().getDate() - 90)), dateFormat),
  },
  {
    label: 'Last 365 Days',
    value: format(new Date(new Date().setDate(new Date().getDate() - 365)), dateFormat),
  },
  {
    label: 'Tomorrow',
    value: format(new Date(new Date().setDate(new Date().getDate() + 1)), dateFormat),
  },
  {
    label: 'Next 7 Days',
    value: format(new Date(new Date().setDate(new Date().getDate() + 7)), dateFormat),
  },
  {
    label: 'Next 30 Days',
    value: format(new Date(new Date().setDate(new Date().getDate() + 30)), dateFormat),
  },
  {
    label: 'Next 90 Days',
    value: format(new Date(new Date().setDate(new Date().getDate() + 90)), dateFormat),
  },
  {
    label: 'Next 365 Days',
    value: format(new Date(new Date().setDate(new Date().getDate() + 365)), dateFormat),
  },
  {
    label: 'This Week',
    value: format(
      new Date(new Date().setDate(new Date().getDate() - new Date().getDay() + 1)),
      dateFormat
    ),
  },
  { label: 'This Month', value: format(new Date(new Date().setDate(1)), dateFormat) },
  { label: 'This Year', value: format(new Date(new Date().setMonth(0)), dateFormat) },
  {
    label: 'Next Week',
    value: format(
      new Date(new Date().setDate(new Date().getDate() - new Date().getDay() + 8)),
      dateFormat
    ),
  },
  {
    label: 'Next Month',
    value: format(new Date(new Date().setMonth(new Date().getMonth() + 1)), dateFormat),
  },
  { label: 'Next Year', value: format(new Date(new Date().setMonth(0)), dateFormat) },
  {
    label: 'Last Week',
    value: format(
      new Date(new Date().setDate(new Date().getDate() - new Date().getDay() - 6)),
      dateFormat
    ),
  },
  {
    label: 'Last Month',
    value: format(new Date(new Date().setMonth(new Date().getMonth() - 1)), dateFormat),
  },
  { label: 'Last Year', value: format(new Date(new Date().setMonth(0)), dateFormat) },
  {
    label: 'This Quarter',
    value: format(
      new Date(new Date().setMonth(new Date().getMonth() - (new Date().getMonth() % 3))),
      dateFormat
    ),
  },
  {
    label: 'Next Quarter',
    value: format(
      new Date(new Date().setMonth(new Date().getMonth() - (new Date().getMonth() % 3) + 3)),
      dateFormat
    ),
  },
  {
    label: 'Last Quarter',
    value: format(
      new Date(new Date().setMonth(new Date().getMonth() - (new Date().getMonth() % 3) - 6)),
      dateFormat
    ),
  },
];

export const CustomValueEditor = (props) => {
  if (props.fieldData.datatype === 'array') {
    return (
      <div className='rule-value-custom'>
        <Select
          options={
            props.fieldData?.options?.map((option) => ({
              label: option.label,
              value: option.value,
            })) ?? [
              { label: 'A', value: 'A' },
              { label: 'B', value: 'B' },
              { label: 'C', value: 'C' },
            ]
          }
          value={props.value ?? null}
          onChange={(selectedOption) =>
            props.handleOnChange(selectedOption ? selectedOption : null)
          }
          isClearable
          isMulti={true}
        />
      </div>
    );
  } else if (props.fieldData.inputType === 'select') {
    return (
      <div className='rule-value-custom'>
        <Select
          options={
            props.fieldData?.options?.map((option) => ({
              label: option.label,
              value: option.value,
            })) ?? [
              { label: 'A', value: 'A' },
              { label: 'B', value: 'B' },
              { label: 'C', value: 'C' },
            ]
          }
          value={props.value ?? null}
          onChange={(selectedOption) =>
            props.handleOnChange(selectedOption ? selectedOption : null)
          }
          isClearable
          isMulti={true}
        />
      </div>
    );
  } else if (props.fieldData.inputType === 'dateSelect') {
    return (
      <div className='rule-value-custom'>
        <Select
          options={dateOptions.map((option) => ({ label: option.label, value: option.label }))}
          value={props.value ? { label: props.value, value: props.value } : null}
          onChange={(selectedOption) =>
            props.handleOnChange(selectedOption ? selectedOption.value : null)
          }
        />
      </div>
    );
  } else if (props.fieldData.valueEditorType === 'checkbox') {
    return (
      <div className='rule-value-custom rule-value-custom-checkbox'>
        <DefaultLayout
          className={`d-inline-flex ${styles.styledFormCheckbox} mb-0`}
          required={false}
          input={
            <Checkbox
              checked={!!props.value}
              id={props.fieldData.name}
              name={props.fieldData.name}
              onChange={(e) => props.handleOnChange(e.target.checked)}
            />
          }
        />
      </div>
    );
  }

  return <ValueEditor {...props} />;
};

export const CustomFieldSelector = (props) => {
  // sort alphabetically
  props.options.sort((a, b) => a.label.localeCompare(b.label));

  return (
    <div className='rule-field-selector-custom'>
      <Select
        //className={props.className}
        options={props.options.map((option) => ({ label: option.label, value: option.name }))}
        value={
          props.value
            ? {
                label: props.options.find((option) => option.name === props.value).label,
                value: props.value,
              }
            : null
        }
        onChange={(selectedOption) => {
          props.handleOnChange(selectedOption ? selectedOption.value : null);
        }}
      />
    </div>
  );
};

export const CustomOperatorSelector = (props) => {
  const inputType = props.fieldData?.inputType;

  const options = _.cloneDeep(props.options);

  if (inputType === 'dateSelect') {
    // rename operators
    options.forEach((operator) => {
      if (operator.name === '=') {
        operator.label = 'within / on';
      } else if (operator.name === '!=') {
        operator.label = 'not within';
      } else if (operator.name === '>') {
        operator.label = 'after';
      } else if (operator.name === '>=') {
        operator.label = 'after and including';
      } else if (operator.name === '<') {
        operator.label = 'before';
      } else if (operator.name === '<=') {
        operator.label = 'before and including';
      } else if (operator.name === 'null') {
        operator.label = 'is missing';
      } else if (operator.name === 'notNull') {
        operator.label = 'is not missing';
      }
    });
  } else if (inputType === 'number') {
    // rename operators
    options.forEach((operator) => {
      if (operator.name === 'between') {
        operator.label = 'between';
      } else if (operator.name === 'notBetween') {
        operator.label = 'not between';
      } else if (operator.name === 'null') {
        operator.label = 'is missing';
      } else if (operator.name === 'notNull') {
        operator.label = 'is not missing';
      } else {
        operator.label = operator.name;
      }
    });
  } else if(inputType === 'string'){
    // rename operators
    options.forEach((operator) => {
      if (operator.name === '=') {
        operator.label = 'is';
      } else if (operator.name === '!=') {
        operator.label = 'is not';
      } else if (operator.name === 'null') {
        operator.label = 'is missing';
      } else if (operator.name === 'notNull') {
        operator.label = 'is not missing';
      } else {
        operator.label = operator.name;
      }
    });
  }
  
  else {
    // rename operators
    options.forEach((operator) => {
      if (operator.name === '=') {
        operator.label = 'is';
      } else if (operator.name === '!=') {
        operator.label = 'is not';
      } else if (operator.name === 'null') {
        operator.label = 'is missing';
      } else if (operator.name === 'notNull') {
        operator.label = 'is not missing';
      } else {
        operator.label = operator.name;
      }
    });
  }

  return (
    <div className='rule-operator-selector-custom'>
      <Select
        options={options.map((operator) => ({ label: operator.label, value: operator.name }))}
        value={
          props.value
            ? {
                label: options.find((operator) => operator.name === props.value).label,
                value: props.value,
              }
            : null
        }
        onChange={(selectedOption) =>
          props.handleOnChange(selectedOption ? selectedOption.value : null)
        }
      />
    </div>
  );
};
