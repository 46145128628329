import { useEffect, useState } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { isAllowed, moduleConstants } from '../../_constants';

import TimeLineTab from '../Layout/TimeLineTab.component';
import NotesTab from '../Layout/NotesTab.component.jsx';
import EmailTab from '../Layout/EmailTab.component.jsx';
import ScheduleTab from '../Layout/ScheduleTab.component';
import FilesTabComponent from './FilesTab.component';

type DetailsRightSectionProps = {
  typeRef: string;
  typeId: string;
  data?: any;
};

const DetailsRightSection = ({
  typeRef,
  typeId,
  data = {},
}: DetailsRightSectionProps) => {
  const timelineTab = 'TIMELINE';
  const scheduleTab = 'SCHEDULE';
  const notesTab = 'NOTES';
  const emailTab = 'EMAIL';
  const filesTab = 'FILES';

  const history = useHistory();
  const location = useLocation();

  const [activeTab, setActiveTab] = useState<string>(timelineTab);

  useEffect(() => {
    const pathArr = location.pathname.split('/').filter(Boolean);
    const selectedTab = (pathArr[2] || '').toLocaleUpperCase();

    const tabs = [timelineTab, scheduleTab, notesTab, emailTab, filesTab];
    const toggleToTab = tabs.includes(selectedTab) ? selectedTab : timelineTab;

    onSelect(toggleToTab, true);
  },[]);

  useEffect(() => {
    const pathArr = location.pathname.split('/').filter(Boolean);
    const selectedTab = (pathArr[2] || '').toLocaleUpperCase();

    const tabs = [timelineTab, scheduleTab, notesTab, emailTab, filesTab];
    const toggleToTab = tabs.includes(selectedTab) ? selectedTab : timelineTab;

    onSelect(toggleToTab, true);
  }, [location.pathname]);


  const onSelect = (key: string, byDefault = false) => {
    if(activeTab === key) return;
    setActiveTab(key || timelineTab);

    const pathArr = location.pathname.split('/').filter(Boolean); 
    pathArr[2] = key.toLowerCase();
    const newPathName = byDefault?pathArr.join('/'):pathArr.slice(0,3).join('/');
    const historyData = { pathname: '/'+newPathName, state: true };
    byDefault ? history.replace(historyData) : history.push(historyData);
  };

  const checkPermissions = (moduleName) =>
    ['LEAD', 'PROJECT'].includes(typeRef)
      ? isAllowed(moduleName, 'assigned', data.userAssignData)
      : isAllowed(moduleName, 'anyAssigned');

  const canAddNotes = checkPermissions(moduleConstants.EN);
  const canScheduleMeeting = checkPermissions(moduleConstants.SM);
  const canFollowUp = ['D','C','W'].includes(data?.jobStatusCodesCode??'');

  // TODO: Rewrite this logic after child components refactoring
  /*const childProps = {
    useAuth,
    props,
    state: componentData,
    pathname: location.pathname,
  };*/


  const [minimalProps, setMinimalProps] = useState({
    location: location,
    typeRef: typeRef,
    typeId: typeId,
    canFollowUp,
    updatedAt: data?.updatedAt,
    data: { ...data },
  });

  useEffect(() => {
    setMinimalProps({
      location: location,
      typeRef: typeRef,
      typeId: typeId,
      canFollowUp,
      updatedAt: data?.updatedAt,
      data: { ...data },
    });
  }, [location, typeRef, typeId, canFollowUp, data]);


  return (
    <div className='projectTabs'>
      <Tabs
        defaultActiveKey={timelineTab}
        activeKey={activeTab}
        onSelect={(key) => onSelect(key as string)}
        id='controlled-tab-right-section'
      >
        <Tab eventKey={timelineTab} title='Timeline'>
          {activeTab === timelineTab && <TimeLineTab {...minimalProps} />}
        </Tab>
        {canAddNotes && (
          <Tab eventKey={notesTab} title='Notes'>
            {activeTab === notesTab && <NotesTab {...minimalProps} />}
          </Tab>
        )}
        <Tab eventKey={emailTab} title='Email'>
          {activeTab === emailTab && <EmailTab {...minimalProps} />}
        </Tab>
        {canScheduleMeeting && (
          <Tab eventKey={scheduleTab} title='Events'>
            {activeTab === scheduleTab && <ScheduleTab {...minimalProps} />}
          </Tab>
        )}
         <Tab eventKey={filesTab} title='Files'>
          {activeTab === filesTab && <FilesTabComponent {...minimalProps} />}
        </Tab>
      </Tabs>
    </div>
  );
};

export default DetailsRightSection;
