import * as actionTypes from './actionTypes';
import * as API from '../api/registerCompany';

export const registerCompanyRequest = () => ({
  type: actionTypes.REGISTER_COMPANY_REQUEST,
});

export const registerCompanySuccess = (response) => ({
  type: actionTypes.REGISTER_COMPANY_SUCCESS,
  payload: {
    response,
  },
});

export const registerCompanyError = (error) => ({
  type: actionTypes.REGISTER_COMPANY_ERROR,
  payload: {
    error,
  },
});

export const registerCompanyAction = (data) => {
  return (dispatch) => {
    dispatch(registerCompanyRequest());
    const FormData = data;
    return API.registerCompany(FormData)
      .then((response) => {
        dispatch(registerCompanySuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          registerCompanyError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

export const getSubscriptionPlanRequest = () => ({
  type: actionTypes.GET_SUBSCRIPTION_PLAN_REQUEST,
});

export const getSubscriptionPlanSuccess = (response) => ({
  type: actionTypes.GET_SUBSCRIPTION_PLAN_SUCCESS,
  payload: {
    response,
  },
});

export const getSubscriptionPlanError = (error) => ({
  type: actionTypes.GET_SUBSCRIPTION_PLAN_ERROR,
  payload: {
    error,
  },
});

export const getSubscriptionPlanAction = (data) => {
  return (dispatch) => {
    dispatch(getSubscriptionPlanRequest());
    return API.getSubscriptionPlan()
      .then((response) => {
        dispatch(getSubscriptionPlanSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          getSubscriptionPlanError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

export const getDemoAccountRequest = () => ({
  type: actionTypes.GET_DEMO_ACCOUNT_REQUEST,
});

export const getDemoAccountSuccess = (response) => ({
  type: actionTypes.GET_DEMO_ACCOUNT_SUCCESS,
  payload: {
    response,
  },
});

export const getDemoAccountError = (error) => ({
  type: actionTypes.GET_DEMO_ACCOUNT_ERROR,
  payload: {
    error,
  },
});

export const getDemoAccountAction = (data) => {
  return (dispatch) => {
    dispatch(getDemoAccountRequest());
    return API.getDemoAccount(data)
      .then((response) => {
        dispatch(getDemoAccountSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          getDemoAccountError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};
