import { useCallback } from 'react';
import FormCheckbox from '../../form-controls/form-checkbox';
import FormInput from '../../form-controls/form-input';
import { useFormContext } from 'react-hook-form';
import styles from './styles.module.scss';
import FormSelect from '../../form-controls/form-select';
import { aggregationOptions } from '../constants';
import { CurrencyInput } from '../../../shared/form-controls/form-input/currency-input';

export const NumberInputSection = () => {
  const form = useFormContext();

  const allowDecimal = form.watch('valueDefinition.allowDecimal');
  const allowNegative = form.watch('valueDefinition.allowNegative');
  const showCommas = form.watch('valueDefinition.showCommas');
  const prefix = form.watch('valueDefinition.prefix');
  const postfix = form.watch('valueDefinition.postfix');
  const defaultValue = form.watch('valueDefinition.defaultValue');

  const onAllowDecimalTouch = useCallback((e) => {
    if (!e.target.checked) {
      const parsedDefaultValue = parseFloat(defaultValue);
      if(parsedDefaultValue<0){
        form.setValue('valueDefinition.defaultValue', '');
      }

    }
  }, []);

  const onAllowNegativeTouch = useCallback((e) => {
    if (!e.target.checked) {
      const parsedDefaultValue = Math.round(parseFloat(defaultValue));

      form.setValue('valueDefinition.defaultValue', parsedDefaultValue.toString());
    }
  }, []);

  const onShowCommasTouch = useCallback((e) => {
    if (!e.target.checked) {
    //  form.setValue('valueDefinition.defaultValue', '');
    }
  }, []);

  const onKeyDown = useCallback(
    (e: any) => {
      const value = e.target.value;
      const key = e.key;

      if (key === 'Enter') return;
      if (key === 'Backspace') return;
      if (key === 'Delete') return;
      if (key === 'ArrowLeft') return;
      if (key === 'ArrowRight') return;
      if (key === 'ArrowUp') return;
      if (key === 'ArrowDown') return;
      if (key === 'Tab') return;
      if (e.ctrlKey) return;
      if (key === 'Escape') return;

      // get caret position
      const start = e.target.selectionStart;
      const end = e.target.selectionEnd;

      const futureValue = value.slice(0, start) + key + value.slice(end);

      if (futureValue === '-' && allowNegative) return;

      if (key === '.' && !allowDecimal) {
        e.preventDefault();
        return false;
      }

      if (isNaN(Number(futureValue))) {
        e.preventDefault();
        return false;
      }
    },
    [allowDecimal, allowNegative]
  );

  return (
    <div>
      <div className='row'>
        <div className='col-12'>
          <label className='form-label'>Number Input Options</label>
        </div>

        <div className='col-12'>
          <div className='row'>
            <div className='col-6'>
              <FormCheckbox
                className='flex-row-reverse mb-1'
                name='valueDefinition.allowDecimal'
                label='Allow decimals'
                onChange={onAllowDecimalTouch}
              />
            </div>

            <div className='col-6'>
              <FormCheckbox
                className='flex-row-reverse mb-1'
                name='valueDefinition.allowNegative'
                label='Allow negatives'
                onChange={onAllowNegativeTouch}
              />
            </div>
            <div className='col-6'>
              <FormCheckbox
                className='flex-row-reverse mb-1'
                name='valueDefinition.showCommas'
                label='Show commas'
                onChange={onShowCommasTouch}
              />
            </div>

            <div className="col-12">
              <FormSelect options={aggregationOptions} label='Summary Operator' name='valueDefinition.aggregation' />
            </div>

            <div className='col-4'>
              <FormInput name='valueDefinition.prefix' label='Prefix' />
            </div>

            <div className='col-4'>
              <FormInput name='valueDefinition.postfix' label='Postfix' />
            </div>
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='col-6'>
          {showCommas ? ( <FormInput
            type='number'
            name='valueDefinition.defaultValue'
            label='Default Value'
            inputClassName={`form-control ${showCommas?styles.numberInput:styles.inputTextRight}`}
            onKeyDown={onKeyDown}
            CustomInput={showCommas ? CurrencyInput : undefined}
            inputText={postfix}
            prefix={prefix}
          />):( <FormInput
            type='number'
            name='valueDefinition.defaultValue'
            label='Default Value'
            inputClassName={`form-control ${showCommas?styles.numberInput:styles.inputTextRight}`}
            onKeyDown={onKeyDown}
            //CustomInput={showCommas ? CurrencyInput : undefined}
            inputText={postfix}
            prefix={prefix}
          />)}
         
        </div>
      </div>
    </div>
  );
};
