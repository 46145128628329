import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import loaderImage from '../../../components/static/images/loading_i.gif';
import { usePageTitle } from '../../../layouts/title-context';
import VariableHistoryModal from '../../../modals/variable-history-modal';
import { getAccountProjectSettings, updateAccountProjectSettings } from '../../../modules/accounts';
import { generateProjectNumber, validateProjectNumber } from '../../../modules/projects';
import { selectIsFetching } from '../../../selectors/accountSettings';
import FormInput from '../../../shared/form-controls/form-input/form-input';
import { IntegerInput } from '../../../shared/form-controls/form-input/integer-input';
import FormSelect from '../../../shared/form-controls/form-select/form-select';
import FormCheckbox from '../../../shared/form-controls/form-checkbox/form-checkbox';
import { useDebounce } from '../../../shared/hooks/use-debounce';

export const ProjectProfitSettings = ({ pageTitle }) => {
  usePageTitle(pageTitle);

  const form = useForm({
    defaultValues: {
      monthlyOverheadFixed: 0,
      monthlyOverheadVariable: 0,
      profitForecastChanged: 0,
      warrantyPeriod: 12,
      lienPeriod: 30,
      followUpPeriod: 90,
      initialJobNumberPre: '',
      initialJobNumber: 1,
      alternateJobNumber: false,
      sequentialJobNumber: true,
      requireNTP: false,
      enableEstimatingScheduling: false,
      requireConstructionDatesOn: '',
      restrictContractAdjustments: true,
      requireReasonCodes: true,
    },
  });

  // TODO: redesign async validation logic
  // const validateJobNumPre = async (_, allValues) => {
  //   const { valid, message } = await dispatch(
  //     validateProjectNumber({
  //       projectNumber: {
  //         jobNumPrefix: allValues.initialJobNumberPre,
  //         jobNum: allValues.initialJobNumber,
  //       },
  //     })
  //   ).unwrap();

  //   if (!valid) {
  //     return message || 'Job number already exists';
  //   }

  //   return true;
  // }

  // form.register('initialJobNumberPre', {
  //   validate: validateJobNumPre,
  // });

  const initialJobNumberPre = form.watch('initialJobNumberPre');
  const initialJobNumber = form.watch('initialJobNumber');

  const [isJobNumberValid, setIsJobNumberValid] = useState(false);

  useDebounce(async () => {
    setIsJobNumberValid(false);
    const { valid } = await dispatch(
      validateProjectNumber({
        projectNumber: {
          jobNumPrefix: initialJobNumberPre,
          jobNum: initialJobNumber,
        },
      })
    ).unwrap();

    if (!valid) {
      form.setError('initialJobNumberPre', {
        message: 'Job number already exists',
      });
      setIsJobNumberValid(false);
    } else {
      form.clearErrors('initialJobNumberPre');
      setIsJobNumberValid(true);
    }
  }, [initialJobNumberPre, initialJobNumber]);

  const [isVariableHistoryModalOpen, setIsVariableHistoryModalOpen] = useState(false);

  const dispatch = useDispatch();
  const isFetching = useSelector(selectIsFetching);

  useEffect(() => {
    dispatch(getAccountProjectSettings())
      .unwrap()
      .then((data) => {
        form.reset({
          monthlyOverheadFixed: data.monthlyOverheadFixed,
          monthlyOverheadVariable: data.monthlyOverheadVariable,
          profitForecastChanged: data.profitForecastChanged,
          warrantyPeriod: data.warrantyPeriod,
          lienPeriod: data.lienPeriod,
          followUpPeriod: data.followUpPeriod,
          initialJobNumberPre: data.initialJobNumberPre,
          initialJobNumber: data.initialJobNumber,
          alternateJobNumber: data.alternateJobNumber,
          sequentialJobNumber: data.sequentialJobNumber,
          requireNTP: data.requireNTP,
          enableEstimatingScheduling: data.enableEstimatingScheduling,
          requireConstructionDatesOn: data.requireConstructionDatesOn,
          restrictContractAdjustments: !!data.restrictContractAdjustments,
          requireReasonCodes: !!data.requireReasonCodes,
        });
      });
  }, []);

  const onSubmit = form.handleSubmit(async (data) => {
    if (!isJobNumberValid) {
      form.setError('initialJobNumberPre', {
        message: 'Job number already exists',
      });
      return;
    }

    await dispatch(updateAccountProjectSettings(data)).unwrap();

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  });

  const handleVariableHistoryModalToggle = () => {
    setIsVariableHistoryModalOpen(
      (prevIsVariableHistoryModalOpen) => !prevIsVariableHistoryModalOpen
    );
  };

  const onGenerateJobNumberClick = async () => {
    const { jobNumPrefix, jobNum } = await dispatch(
      generateProjectNumber({ options: { prefix: initialJobNumberPre, startWith: 1 } })
    ).unwrap();

    form.setValue('initialJobNumber', jobNum);
    form.setValue('initialJobNumberPre', jobNumPrefix);
  };

  const monthOptions = useMemo(
    () => Array.from({ length: 12 }, (_, i) => ({ value: i + 1, label: `${i + 1}` })),
    []
  );

  const constructionOptions = useMemo(
    () => [
      { value: 'lead', label: 'Leads' },
      { value: 'bidding', label: 'Bidding' },
      { value: 'pre-construction', label: 'Pre-construction' },
      { value: 'active', label: 'Active' },
    ],
    []
  );

  return (
    <div className='position-relative h-100'>
      {isFetching && (
        <div className='loading_bg'>
          <img className='ajax-loader' src={loaderImage} alt='' width='100' height='100' />
        </div>
      )}

      <form className='formBox'>
        <div className='flex row mt-2'>
          <h2 className='heading2 col-8'>Project Settings</h2>
        </div>
        <div className='flex row mt-1'>
          <p className='col-8 logonote'>
            <strong>NOTE :</strong> After entering or editing any information on this page, please
            scroll to the bottom of the page and click the SUBMIT button to submit the changes.
          </p>
        </div>

        {/*<h3 className='infoHeading'>Estimated Monthly Overhead</h3>
        <div className='fieldBoxRow section2'>
          <label htmlFor='monthlyOverheadFixed'>
            Fixed Overhead / Month<span className='requiredField'>*</span>
          </label>
          <div className='d-inline-block'>
            <input
              type='text'
              name='monthlyOverheadFixed'
              id='monthlyOverheadFixed'
              className='field full'
              placeholder='Current Estimated Fixed Overhead / Month'
              disabled
              {...form.register('monthlyOverheadFixed')}
            />
            <small>$</small>
            <span onClick={handleVariableHistoryModalToggle}>
              <i className='fa fa-history' aria-hidden='true'></i>
              &nbsp;Edit/View History
            </span>
          </div>
          <span className='error'>
            {form.formState.errors.monthlyOverheadFixed &&
              form.formState.errors.monthlyOverheadFixed.message}
          </span>
        </div>
        <div className='fieldBoxRow section2'>
          <label htmlFor='monthlyOverheadVariable'>
           Variable Overhead / Month<span className='requiredField'>*</span>
          </label>
          <div className='d-inline-block'>
            <input
              type='text'
              name='monthlyOverheadVariable'
              id='monthlyOverheadVariable'
              className='field full'
              placeholder='Current Estimated Variable Overhead / Month'
              disabled
              {...form.register('monthlyOverheadVariable')}
            />
            <small>% of revenue</small>
            <span onClick={handleVariableHistoryModalToggle}>
              <i className='fa fa-history' aria-hidden='true'></i>
              &nbsp;Edit/View History
            </span>
          </div>
          <span className='error'>
            {form.formState.errors.monthlyOverheadVariable &&
              form.formState.errors.monthlyOverheadVariable.message}
          </span>
            </div>*/}
        <h3 className='infoHeading'>Project Notification Settings</h3>
        <div className='fieldBoxRow section2'>
          <label htmlFor='profitForecastChanged'>
            Profit Variance Notification
            <span className='requiredField'>*</span>
          </label>
          <div className='d-inline-block'>
            <input
              type='number'
              name='profitForecastChanged'
              id='profitForecastChanged'
              className='field full text-end'
              placeholder='Profit Forecast Changed'
              {...form.register('profitForecastChanged')}
            />
            <small>%</small>
          </div>
          <span className='error'>
            {form.formState.errors.profitForecastChanged &&
              form.formState.errors.profitForecastChanged.message}
          </span>
        </div>

        <h3 className='infoHeading'>Default Project Periods</h3>
        <div className='fieldBoxRow section2'>
          <label htmlFor='warrantyPeriod'>
            Default Warranty Period
            <span className='requiredField'>*</span>
          </label>
          <div className='d-inline-block'>
          <input
              type='number'
              name='warrantyPeriod'
              id='warrantyPeriod'
              className='field full  text-end'
              placeholder='Warranty Period'
              {...form.register('warrantyPeriod')}
            />
            <small>Months</small>
          </div>
          <span className='error'>
            {form.formState.errors.warrantyPeriod && form.formState.errors.warrantyPeriod.message}
          </span>
        </div>
        <div className='fieldBoxRow section2'>
          <label htmlFor='lienPeriod'>
            Default Lien Period
            <span className='requiredField'>*</span>
          </label>
          <div className='d-inline-block'>
            <input
              type='number'
              name='lienPeriod'
              id='lienPeriod'
              className='field full  text-end'
              placeholder='Lien Period'
              {...form.register('lienPeriod')}
            />
            <small>Days</small>
          </div>
          <span className='error'>
            {form.formState.errors.lienPeriod && form.formState.errors.lienPeriod.message}
          </span>
        </div>
        <div className='fieldBoxRow section2'>
          <label htmlFor='followUpPeriod'>
            Follow Up Period
            <span className='requiredField'>*</span>
          </label>
          <div className='d-inline-block'>
            <input
              type='number'
              name='followUpPeriod'
              id='followUpPeriod'
              className='field full  text-end'
              placeholder='Follow Up Period'
              {...form.register('followUpPeriod')}
            />
            <small>Days</small>
          </div>
          <span className='error'>
            {form.formState.errors.followUpPeriod && form.formState.errors.followUpPeriod.message}
          </span>
        </div>

        <h3 className='infoHeading'>Job Numbering Settings</h3>
        <div className='fieldBoxRow section2'>
          <label htmlFor='initialJobNumberPre'>
            Next Job Number
            <span className='requiredField'>*</span>
          </label>
          <div className='d-inline-block'>
            <div className='d-inline-flex gap-3'>
              <FormInput
                name='initialJobNumberPre'
                control={form.control}
                placeholder='Job Number prefix'
              />

              <FormInput
                name='initialJobNumber'
                type='number'
                control={form.control}
                placeholder='Initial Job Number'
                CustomInput={(props) => <IntegerInput digits={4} strictDigits {...props} />}
              />
            </div>
            <small></small>
            <span onClick={onGenerateJobNumberClick}>
              <i className='fa fa-history' aria-hidden='true'></i>
              &nbsp;Generate Available Project Number
            </span>
          </div>
          {/* <span className='error'>
            {form.formState.errors.initialJobNumberPre?.message}
          </span> */}
        </div>
        <div className='fieldBoxRow section2'>
          <FormCheckbox
            className='gap-0'
            name='alternateJobNumber'
            label='Use Alternate Job Number'
            control={form.control}
          />
        </div>
        <div className='fieldBoxRow section2'>
          <FormCheckbox
            className='gap-0'
            name='sequentialJobNumber'
            label='Require Sequential Job Numbers'
            control={form.control}
          />
        </div>
        <h3 className='infoHeading'>Project Schedule Settings</h3>
        <div className='fieldBoxRow section2'>
          <FormCheckbox
            className='gap-0'
            name='requireNTP'
            label='Require Notice-To-Proceed for Active Jobs'
            control={form.control}
          />
        </div>
        <div className='fieldBoxRow section2'>
          <label className='pe-1' htmlFor='requireConstructionDatesOn'>
            Enable Estimating Scheduling
          </label>
          <div className='d-inline-block'>
            <FormCheckbox
              className='gap-0'
              name='enableEstimatingScheduling'
              control={form.control}
            />
          </div>
        </div>
        <div className='fieldBoxRow section2 d-flex'>
          <label htmlFor='requireConstructionDatesOn'>
            Require Construction Start/End on
          </label>
          <div className='d-inline-block'>
            <FormSelect
              name='requireConstructionDatesOn'
              inputClassName='select full'
              placeholder='Require Construction Start/End on'
              options={constructionOptions}
              control={form.control}
              Layout={({ input }) => <>{input}</>}
            />
            <small>Stage</small>
          </div>
          <span className='error'>
            {form.formState.errors.requireConstructionDatesOn && form.formState.errors.requireConstructionDatesOn.message}
          </span>
        </div>
        <h3 className='infoHeading mt-3'>Project Finance Settings</h3>
        <div className='fieldBoxRow section2'>
          <FormCheckbox
            className='gap-0'
            name='restrictContractAdjustments'
            label='Restrict Contract Value adjustments to Change Orders'
            control={form.control}
          />
        </div>
        <h3 className='infoHeading mt-3'>Other Settings</h3>
        <div className='fieldBoxRow section2'>
          <FormCheckbox
            className='gap-0'
            name='requireReasonCodes'
            label='Require reason codes (when available) for project status transitions'
            control={form.control}
          />
        </div>
        <div className='col-9 d-flex flex-column gap-3'>
        <div className='row'>
          <button
            className='btn btn-primary align-self-end ms-auto mt-2 col-sm-2'
            onClick={onSubmit}
            disabled={form.formState.isSubmitting || !isJobNumberValid}
          >
            Submit
          </button>
        </div>
        </div>
      </form>

      <VariableHistoryModal
        isOpen={isVariableHistoryModalOpen}
        toggle={handleVariableHistoryModalToggle}
      />
    </div>
  );
};
