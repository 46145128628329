import React, { useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
//import xlsx from 'node-xlsx';
import * as XLSX from 'xlsx';

const ExportExcelComponent = (props) => {
  const [modal, setModal] = useState(false);
  const [download, setDownload] = useState(false);

  const toggle = () => {
    setModal(!modal);
    setDownload(false);
  };

  const excelLoadData = () => {
    setDownload(true);
    toggle();
    props.onClick();
  };

  const exportToCSV = (columns, data, fileName) => {
    setDownload(true);

    const header = columns?.map((col) => col.title ?? col);

    const wb = XLSX.utils.book_new();
    let newData = header ? [header] : [];
    newData = newData ? newData.concat(data) : data;
    const ws = XLSX.utils.json_to_sheet(newData, { skipHeader: true });
    XLSX.utils.book_append_sheet(wb, ws, fileName);
    XLSX.writeFile(wb, `${fileName}.xlsx`);
  };

  const { data, reportName, className } = props;

  let exportColumns;
  let exportData;

  if (data && data[0]) {
    if (data[0]?.columns) {
      exportColumns = data[0].columns;
      exportData = data[0].data;
    } else {
      exportData = data;
      exportColumns = null;
    }
  }

  return (
    <span className="DownloadButton">
      <button id="btn" className="btn rightalignmentBtn" onClick={excelLoadData}>
        {!modal ? (
          <div>
            <i className="fa fa-download" aria-hidden="true" /> Excel Export
          </div>
        ) : (
          'Process...'
        )}
      </button>
      {data && download === false && (
        <Modal
          size={'sm'}
          backdrop="static"
          keyboard={true}
          isOpen={modal}
          toggle={toggle}
          className={className}
        >
          <ModalHeader toggle={toggle}>Download File</ModalHeader>
          <ModalBody className="d-flex justify-content-center">
            <Button
              color="secondary"
              style={{ width: '240px' }}
              onClick={() => {
                exportToCSV(exportColumns, exportData, reportName);
              }}
            >
              Download Data
            </Button>
          </ModalBody>
        </Modal>
      )}
    </span>
  );
};

export default withRouter(ExportExcelComponent);
