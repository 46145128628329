import * as actionTypes from './actionTypes';
import * as API from '../api/projectRole';


export const getAllProjectRoleRequest = () => ({
  type: actionTypes.GET_ALL_PROJECT_ROLE_REQUEST,
});

export const getAllProjectRoleSuccess = (response) => ({
  type: actionTypes.GET_ALL_PROJECT_ROLE_SUCCESS,
  payload: {
    response,
  },
});

export const getAllProjectRoleError = (error) => ({
  type: actionTypes.GET_ALL_PROJECT_ROLE_ERROR,
  payload: {
    error,
  },
});

export const getAllProjectRoleAction = (data) => {
  return (dispatch) => {
    dispatch(getAllProjectRoleRequest());
    return API.getAllProjectRole(data)
      .then((response) => {
        dispatch(getAllProjectRoleSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          getAllProjectRoleError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

