import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getDashboardAction } from '../../../actions/Admin/HomePage.actions.js';
import { getAdminDashboardAction } from '../../../actions/Admin/Admin.actions.js';
import AdminSidebar from '../../Admin/Inc/AdminSideBar';
import TitleComponent from "../../Common/TitleComponent";

const AdminHomePage = ({ isDashboard, isDashboardSuccess, getAdminDashboardAction }) => {
  const [dashboardData, setDashboardData] = useState({});
  const [userId] = useState(localStorage.getItem('userId'));

  useEffect(() => {
    getAdminDashboardAction({ adminId: userId });
  }, []);

  useEffect(() => {
    if (isDashboardSuccess) {
      setDashboardData(isDashboard);
    }
  }, [isDashboardSuccess]);

  return (
    <div>
      <TitleComponent data={{ title: `Administration Dashboard` }} />
      <div className='d-flex'>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-4 col-sm-4 bg-light border">
              <div className="dash">
                <i className="fa fa-building"></i>
                <p>Accounts</p>
                <h6>{dashboardData.adminCompanyCount}</h6>
              </div>
              <div className="dash-text">
                <Link className="nav-link" to="/admin/accounts">
                  List
                </Link>
              </div>
            </div>

            <div className="col-md-4 col-sm-4 bg-light border">
              <div className="dash">
                <i className="fa fa-product-hunt"></i>
                <p>Projects</p>
                <h6>{dashboardData.projectCount}</h6>
              </div>
              <div className="dash-text">
                <Link className="nav-link" to="/admin/projects">
                  List
                </Link>
              </div>
            </div>

            <div className="col-md-4 col-sm-4 bg-light border">
              <div className="dash">
                <i className="fa fa-user"></i>
                <p>Users</p>
                <h6>{dashboardData.userCount}</h6>
              </div>
              <div className="dash-text">
                <Link className="nav-link" to="/admin/users">
                  List
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isDashboard: state.admin.isDashboard,
  isDashboardSuccess: state.admin.isDashboardSuccess,
  isDashboardError: state.admin.isDashboardError,
  success: state.admin.success,
  message: state.admin.message,
});

export default withRouter(
  connect(mapStateToProps, { getDashboardAction, getAdminDashboardAction })(AdminHomePage)
);
