import PhoneInput from '../phone-input';

import styles from '../form-controls/field-actions/field-actions.module.scss';
import React from 'react';

type PhoneElementProps = {
  name: string;
  hideExt?: boolean;
  hideType?: boolean;
  actions?: React.ReactNode;
  disabled?: boolean;
  label?: string;
};

export const PhoneElement = ({
  name,
  hideExt,
  hideType,
  actions,
  disabled,
  label = 'Phone number',
}: PhoneElementProps) => {
  const dataRowClassName = `d-flex gap-0 row align-items-baseline ${styles.rowWrapper} ${styles.selectWrapper}`;
  const controlsWrapperClassName = `col-md-1 ${styles.rowControlsWrapper}`;

  return (
    <div data-testid='PhoneElement' className={styles.wrapper}>
      <div className={dataRowClassName}>
        <span className='col-md-2 pe-0'>{label}</span>
        <div className={`${controlsWrapperClassName} justify-content-end d-none d-sm-flex`}></div>
        <PhoneInput name={name} hideExt={hideExt} hideType={hideType} disabled={disabled} />
        <div className={`${controlsWrapperClassName} justify-content-start col`}>{actions}</div>
      </div>
    </div>
  );
};
