import { createSelector } from '@reduxjs/toolkit';

const selectAllNotificationTypesFromState = (state) => state.notificationPreferences;

export const selectAllNotificationTypes = createSelector(selectAllNotificationTypesFromState, (notificationTypes) =>
notificationTypes/*
    .filter((val)=>{
       if(val.enabled)
       return true;
      else
      return false;})*/
);
