import { useState, useEffect, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { getTimeLineAction } from '../../actions/message';
import { t } from 'i18next';
import { Tooltip } from 'react-tooltip';
import { FileLinkModal } from '../Layout/FileLinkModal';
import {
  ChonkyActions,
  defineFileAction,
  FileBrowser,
  FileContextMenu,
  FileHelper,
  FileList,
  FileNavbar,
  FileToolbar,
  setChonkyDefaults,
} from 'chonky';
import { v4 as uuidv4 } from 'uuid';
import { ChonkyIconFA } from 'chonky-icon-fontawesome';
import { LightBoxComponent } from './LightBox.component';
import NoteEditor from '../../components/Dialogs/note-editor-modal';

setChonkyDefaults({ iconComponent: ChonkyIconFA });




const FilesTab = (props) => {
  const [timelineData, setTimeLineData] = useState([]);
  const [typeId, setTypeId] = useState([]);
  const [typeRef, setTypeRef] = useState([]);
  const [fileMap, setFileMap] = useState([]);
  const [numFiles, setNumFiles] = useState(0);
  const [currentFolderId, setCurrentFolderId] = useState(null);

  const [lightBoxOpen, setLightBoxOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [attachmentIndex, setAttachmentIndex] = useState(-1);
  const [attachmentItems, setAttachmentItems] = useState([]);

  const [noteTags, setNoteTags] = useState([]);

  const [showEditNoteComponent, setShowEditNoteComponent] = useState(false);
  const [noteObjectId, setNoteObjectId] = useState(null);
  const [noteAction, setNoteAction] = useState(null);
  const [noteFiles, setNoteFiles] = useState([]);
  const [noteTagsSelected, setNoteTagsSelected] = useState([]);

  const closeWindowCallback = () => {
    setShowEditNoteComponent(false);
    setNoteObjectId('');
    setNoteAction('NEW');
    getTimelineData();
  };

  const addNoteOnDrop = () => {
    setShowEditNoteComponent(true);
    setNoteAction('NEW');
  };

  const [thisURL, setThisURL] = useState('');

  const pathnameMap = new Map()
    .set('CONTACT', '/contact-details/')
    .set('LEAD', '/lead-details/')
    .set('PROJECT', '/project-details/')
    .set('COMPANY', '/company-details/')
    .set('USER', '/contact-details/');

  const typeMap = new Map()
    .set('cppEmails', '/email/')
    .set('cppNotes', '/notes/')
    .set('cppSchedule', '/schedule/');


  const showHideLightBox = (item) => {
    if (!currentFolderId) {
      return;
    }

    if (!item) {
      setSelectedItem({});
      setLightBoxOpen(false);
      return;
    }

    const currentFolderFiles = (fileMap[currentFolderId]?.childrenIds ?? []).filter(
      (fileId) => !fileMap[fileId].isDir
    );

    const index = currentFolderFiles.findIndex((fileId) => fileId == item.id);

    const folderAttachments = currentFolderFiles.map((fileId) => {
      let matchedTimelineData = timelineData.find(
        (entry) => fileMap[fileId].originId == entry.objectId
      );
      let matchedAttachments = matchedTimelineData?.attachments ?? [];
      let matchedLinks = matchedTimelineData?.fileLinks ?? [];
      let allAttachments = matchedAttachments.concat(matchedLinks);
      let matchedAttachment = allAttachments[fileMap[fileId].index];


      if (matchedTimelineData.classType === 'cppEmails') {
        return {
          ...matchedAttachment,
          index: index,
          description:
            'Draft Email\n' +
            'to: ' +
            matchedTimelineData.emailTo?.map((to) => to.value).join(', ') +
            '\n' +
            'sub: ' +
            matchedTimelineData.emailSub +
            '\n' +
            matchedTimelineData.emailBody?.replace(/(<([^>]+)>)/gi, ''),
        };
      } else if (matchedTimelineData.classType === 'cppNotes') {
        return {
          ...matchedAttachment,
          index: index,
          description:
            matchedTimelineData.title +
            '\n' +
            matchedTimelineData.description?.replace(/(<([^>]+)>)/gi, ''),
        };
      } else {
        return {};
      }
    });

    setAttachmentItems(folderAttachments);
    setAttachmentIndex(index);
    setLightBoxOpen(!lightBoxOpen);
  };

  useEffect(() => {
    if (selectedItem?.fileType === 'link') {
      toggleFileLink(selectedItem.url, selectedItem.name);
      setSelectedItem({});

    } else {
      showHideLightBox(selectedItem);
    }

  }, [selectedItem]);

  const [linkUrl, setLinkUrl] = useState('');
  const [linkName, setLinkName] = useState('');

  const toggleFileLink = (url, name) => {
    if (!url) {
      setLinkUrl('');
      setLinkName('');
      return;
    }
    setLinkUrl(url);
    setLinkName(name);
  }


  useEffect(() => {
    if (!props.isSave && props.timelineData?.length) {
      setTimeLineData(props.timelineData);
    }
  }, [props.isSave, props.timelineData, props.allMessages, props.allEmails, props.allSchedule]);

  useEffect(() => {
    if (props.typeId?.length > 0) {
      setTypeId(props.typeId);
      setTypeRef(props.typeRef);
      setCurrentFolderId(props.typeId);
    }
  }, [props.typeId, props.typeRef]);

  useEffect(() => {
    if (typeId.length > 0) {
      getTimelineData();
      setCurrentFolderId(typeId);
      setThisURL(pathnameMap.get(typeRef) + typeId);
    }
  }, [typeId, typeRef]);

  const getTimelineData = () => {
    if (typeId.length > 0) {
      const data = {
        typeId,
        typeRef,
        page: 0,
        displayLimit: 99999999,
      };
      props.getTimeLineAction(data);
    }
  };

  const useFolderChain = (currentFolderId) => {
    return useMemo(() => {
      const currentFolder = fileMap[currentFolderId];
      const folderChain = [currentFolder];
      let parentId = currentFolder?.parentId;
      while (parentId) {
        const parentFile = fileMap[parentId];
        if (parentFile) {
          folderChain.unshift(parentFile);
          parentId = parentFile.parentId;
        } else {
          parentId = null;
        }
      }
      return folderChain;
    }, [currentFolderId, fileMap]);
  };

  const childProps = {
    props: props,
    state: timelineData,
    pathname: location.pathname,
  };


  useEffect(() => {
    const newFileMap = extractFileMapFromTimeline(timelineData);

    if (typeId?.length > 0) {
      if (newFileMap[typeId]?.childrenIds.length > 0) {
        setFileMap(newFileMap);
        setCurrentFolderId(typeId);
      }
    }
  }, [timelineData.length]);

  const go_to_item = defineFileAction({
    id: 'go_to_item',
    sortKeySelector: (file) => (file ? file.size : undefined),
    button: {
      name: 'View origin',
      group: 'Actions',
      contextMenu: true,
    },
  });

  useEffect(() => {
    const parentElement = document.getElementsByClassName('chonky-fileListWrapper');
    //  const parentElement = a[0].parentElement.parentElement;
    //  parentElement.setAttribute('id', id);

    parentElement[0].addEventListener('drop', (e) => {
      setNoteFiles(e.dataTransfer.files);
      addNoteOnDrop();
    });
  }, [currentFolderId]);

  const useFileActionHandler = ({ setCurrentFolderId, setSelectedItem }) => {
    return useCallback(
      (data, file) => {
        if (data.id === 'upload_files') {

        }
        else if (data.id === 'change_selection') {
          const { selectedFiles } = data.state;
          const UrlNames = selectedFiles.map((file) =>
            [file.fileType, file.name, file.url].join(':')
          );
          const fileToOpen = selectedFiles[0];
          const id = fileToOpen?.id;
          if (!id) {
            return;
          }
          const a = document.querySelectorAll('[data-chonky-file-id="' + id + '"]');
          const parentElement = a[0].parentElement.parentElement;
          parentElement.setAttribute('id', id);
          parentElement.addEventListener('dragstart', (e) => {
            for (let i = 0; i < selectedFiles.length; i++) {
              e.dataTransfer.setData('DownloadURL', UrlNames[i]);
            }
            e.dataTransfer.dropEffect = 'copy';
          });
          parentElement.addEventListener(
            'dragover',
            (e) => {
              e.preventDefault();
            },
            false
          );
          parentElement.addEventListener('dragend', (e) => {
            e.preventDefault();
          });
          parentElement.addEventListener('drop', (e) => {

            // pass files to note here
            //e.dataTransfer.setData(fileToOpen.fileType, fileToOpen.name);

            setNoteFiles(e.dataTransfer.files);

            addNoteOnDrop();

            //const fileName = e.dataTransfer.getData('DownloadURL', UrlNames[0]);
            //     console.log(`Dropped file: ${fileName}`);

          });
        } else if (data.id === ChonkyActions.OpenFiles.id) {
          const { targetFile, files } = data.payload;
          const fileToOpen = targetFile ?? files[0];
          if (fileToOpen && FileHelper.isDirectory(fileToOpen)) {
            setCurrentFolderId(fileToOpen.id);
            if (fileToOpen.parentId === 'cppNotes' && fileToOpen.id !== 'AllNotes') {
              setNoteTagsSelected([fileToOpen.id]);
            } else {
              setNoteTagsSelected([]);
            }

            return;
          } else if (fileToOpen) {
            setSelectedItem(fileToOpen);
          }
        } else if (data.id === ChonkyActions.DownloadFiles.id) {
          const { selectedFiles } = data.state;
          for (let i = 0; i < selectedFiles.length; i++) {
            const fileToOpen = selectedFiles[i];
            saveFile(fileToOpen);
          }
        } else if (data.id === go_to_item.id) {
          const { selectedFiles } = data.state;

          const fileToOpen = selectedFiles[0];

          if (fileToOpen) {
            window.open(fileToOpen.localUrl);
          }
        }
      },
      [setCurrentFolderId]
    );
  };

  const saveFile = async ({ fileName, url }) => {
    const a = document.createElement('a');
    a.download = fileName;
    a.href = url;
    a.target = '_blank';
    a.addEventListener('click', (e) => {
      setTimeout(() => a.remove(), 30 * 1000);
    });
    a.click();
  };

  const extractFileMapFromTimeline = (timelineData) => {
    const newFileMap = {};
    const fileIds = [];
    timelineData.forEach((item) => {
      if (item.attachments && item.attachments?.length > 0) {
        item.attachments.forEach((attachment, index) => {
          const objectId = attachment.objectId ?? uuidv4();
          newFileMap[objectId] = {
            url: attachment.url,
            name: attachment.fileName,
            size: attachment.fileSize ?? 0,
            fileType: attachment.fileType,
            modDate: attachment.modDate,
            thumbnailUrl: attachment?.fileType.includes('image') ? attachment.thumbnailUrl : null,
            isDir: false,
            isHidden: false,
            id: objectId,
            index: index,
            originType: item.classType,
            originId: item.objectId,
            localUrl:
              pathnameMap.get(item.typeRef) +
              item.typeId +
              typeMap.get(item.classType) + 'view/' +
              item.objectId,
          };
          if (!item.tags||item.tags.length === 0) {
            fileIds.push(objectId);
          }
          //fileIds.push(objectId);
        });
      }
      if (item.fileLinks && item.fileLinks?.length > 0) {
        item.fileLinks.forEach((fileLink, index) => {
          const objectId = fileLink.itemId ?? uuidv4();
          newFileMap[objectId] = {
            url: fileLink.fileLink,
            name: fileLink.fileName,
            size: 0,
            fileType: 'link',
            modDate: item.updatedAt,
            //thumbnailUrl: attachment?.fileType.includes('image') ? attachment.thumbnailUrl : null,
            isDir: false,
            isHidden: false,
            id: objectId,
            index: item.attachments.length + index,
            originType: item.classType,
            originId: item.objectId,
            localUrl:
              pathnameMap.get(item.typeRef) +
              item.typeId +
              typeMap.get(item.classType) + 'view/' +
              item.objectId,
          };
          if (item.tags.length === 0) {
            fileIds.push(objectId);
          }
          //fileIds.push(objectId);
        });
      }
    });

    setNumFiles(fileIds.length);



    const allNotes = timelineData.filter((item) => item.classType === 'cppNotes').map((item) => item.tags);
    const allEmails = timelineData.filter((item) => item.classType === 'cppEmails' && item.isDraft !== 1);

    const noteTags = [...new Set(allNotes.flat().map((item) => item?.label ?? ''))].filter(Boolean);


    setNoteTags(noteTags);

    const noteFieldIds = [];

    for (let i = 0; i < noteTags.length; i++) {
      const tag = noteTags[i];

      const itemsWithTag = timelineData.filter((item) => item?.tags?.length > 0 && item.tags.map((item) => item.label).includes(tag)).map((item) => item.objectId);

      const fileMapKeys = Object.keys(newFileMap);
      let childrenObjects = [];
      let modDate = null;

      for (let j = 0; j < fileMapKeys?.length; j++) {
        const key = fileMapKeys[j];
        if (itemsWithTag.includes(newFileMap[key].originId)) {
          childrenObjects.push(newFileMap[key].id);
          if (!modDate || newFileMap[key].modDate > modDate) {
            modDate = newFileMap[key].modDate;
          }
        }
      }

      if (childrenObjects.length > 0) {
        newFileMap[tag] = {
          id: tag,
          name: tag,
          childrenIds: childrenObjects,//;childrenObjects.map((item) => item.id),
          isDir: true,
          parentId: typeId,//'cppNotes',
          modDate: modDate,
        };

        fileIds.push(tag);
      }
    };

    const itemsWithoutTag = timelineData.filter((item) => item?.tags?.length === 0).map((item) => item.objectId);
    for (let i = 0; i < itemsWithoutTag.length; i++) {
      const item = itemsWithoutTag[i];
      const fileMapKeys = Object.keys(newFileMap);
      let childrenObjects = [];
      let modDate = null;
    }


    newFileMap['AllNotes'] = {
      id: 'AllNotes',
      name: 'All',
      childrenIds: fileIds.filter((fileId) => newFileMap[fileId].originType === 'cppNotes'),
      isDir: true,
      parentId: 'cppNotes',
    };

    noteFieldIds.push('AllNotes');


    // notes folder
    newFileMap['cppNotes'] = {
      id: 'cppNotes',
      name: 'Notes',
      childrenIds: noteFieldIds,//fileIds.filter((fileId) => newFileMap[fileId].originType === 'cppNotes'),
      isDir: true,
      parentId: typeId,
    };

    if (noteFieldIds?.length > 0) {
      //fileIds.push('cppNotes');
    }

    const emailFieldIds = [];

    for (let i = 0; i < allEmails.length; i++) {
      const emailSubject = allEmails[i].emailSub;
      const emailId = allEmails[i].objectId;

      const fileMapKeys = Object.keys(newFileMap);
      let childrenObjects = [];
      let modDate = null;

      for (let j = 0; j < fileMapKeys?.length; j++) {
        const key = fileMapKeys[j];
        if (emailId.includes(newFileMap[key].originId)) {
          childrenObjects.push(newFileMap[key].id);
          if (!modDate || newFileMap[key].modDate > modDate) {
            modDate = newFileMap[key].modDate;
          }
        }
      }

      if (childrenObjects.length > 0) {
        newFileMap[emailSubject] = {
          id: emailSubject,
          name: emailSubject,
          childrenIds: childrenObjects,//;childrenObjects.map((item) => item.id),
          isDir: true,
          parentId: 'cppEmails',
          modDate: modDate,
        };

        emailFieldIds.push(emailSubject);
      }
    };

    if (emailFieldIds?.length > 0) {
      newFileMap['AllEmail'] = {
        id: 'AllEmail',
        name: 'All',
        childrenIds: fileIds.filter((fileId) => newFileMap[fileId].originType === 'cppEmail'),
        isDir: true,
        parentId: 'cppEmails',
      };


      emailFieldIds.push('AllEmail');


      // notes folder
      newFileMap['cppEmails'] = {
        id: 'cppEmails',
        name: 'Emails',
        childrenIds: emailFieldIds,//fileIds.filter((fileId) => newFileMap[fileId].originType === 'cppNotes'),
        isDir: true,
        parentId: typeId,
      };

      if (emailFieldIds?.length > 0) {
        fileIds.push('cppEmails');
      }

    }

    // emails folder

    // root folder
    newFileMap[typeId] = {
      id: typeId,
      name: typeRef,
      childrenIds: fileIds,
      isDir: true,
      parentId: null,
    };

    return newFileMap;
  };

  const useFiles = (currentFolderId) => {
    return useMemo(() => {
      const currentFolder = fileMap[currentFolderId];
      const files = currentFolder?.childrenIds
        ? currentFolder.childrenIds.map((fileId) => fileMap[fileId] ?? null)
        : [];
      return files;
    }, [currentFolderId, fileMap]);
  };

  const files = useFiles(currentFolderId);
  const folderChain = useFolderChain(currentFolderId);
  const handleFileAction = useFileActionHandler({ setCurrentFolderId, setSelectedItem });

  const fileBrowserOptions = {
    fileActions: [ChonkyActions.DownloadFiles, go_to_item],
  };

  return (
    <>
      <div className='container'>
        <br />
        <div className='row'>
          <div className='col-md-12'>
            <div className='pageinfo d-flex'>
              <h2> All attachments </h2>
              <div className='detailMenu ms-auto'>
                <li className='dropdown px-2'>
                  <a
                    className='dropdown-toggle rightSectionOverflowMenu'
                    data-bs-toggle='dropdown'
                    href='#'
                    role='button'
                    aria-hidden='true'
                    aria-expanded='false'
                  >
                    <button className='btn btn-primary'>Add</button>
                  </a>
                  <ul className='dropdown-menu dropdown-menu-end ml-auto'>
                    {true && (
                      <li className='dropdown-item'>
                        <Link
                          data-tooltip-content={t('Add a new note to this item')}
                          data-tooltip-id='AddNote'
                          to={{
                            pathname: thisURL + '/notes/add',
                            state: { objectId: typeId, showEditNote: true },
                          }}
                        >
                          Add Note
                        </Link>
                        <Tooltip id='AddNote' place='right' delayShow={1000} />
                      </li>
                    )}
                    <li className='dropdown-item'>
                      <Link
                        data-tooltip-content={t('Send a new email related to this item')}
                        data-tooltip-id='SendEmail'
                        to={{
                          pathname: thisURL + '/email/new',
                          state: { objectId: typeId, showEditEmail: true },
                        }}
                        {...childProps}
                      >
                        Send Email
                      </Link>
                      <Tooltip id='Send Email' place='right' delayShow={1000} />
                    </li>
                    {true && (
                      <li className='dropdown-item'>
                        <Link
                          to={{
                            pathname: thisURL + '/schedule/add',
                            state: { objectId: typeId, showEditMeeting: true },
                          }}
                          data-tooltip-content={t('Add a meeting or an event')}
                          data-tooltip-id='AddMeeting'
                          {...childProps}
                        >
                          Add Event
                        </Link>
                        <Tooltip id='AddMeeting' place='right' delayShow={1000} />
                      </li>
                    )}
                  </ul>
                </li>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        {showEditNoteComponent ? (
          <NoteEditor showEditNote={showEditNoteComponent} asModal={true} typeId={typeId} typeRef={typeRef} objectId={noteObjectId} noteAction={noteAction} initialFiles={noteFiles} initialTags={noteTagsSelected} closeWindowCallback={closeWindowCallback}></NoteEditor>) : null}
        {true ? (
          <>
            <FileBrowser
              onFileAction={handleFileAction}
              folderChain={folderChain}
              files={files}
              //disableDragAndDrop={true}
              //thumbnailGenerator={(file) => file?.thumbnailUrl ? commonFile : null}
              {...fileBrowserOptions}
            >
              <FileNavbar />
              <FileToolbar />
              <FileList />
              <FileContextMenu />
            </FileBrowser>
            <LightBoxComponent
              attachments={attachmentItems ?? []}
              shouldOpen={lightBoxOpen}
              closeCallback={showHideLightBox}
              initialIndex={attachmentIndex}
            />
          </>
        ) : (
          'No files found'
        )}
        <FileLinkModal url={linkUrl} name={linkName} closeCallback={toggleFileLink}></FileLinkModal>
      </div>
    </>
  );
};


function mapStateToProps(state) {
  let { message } = state;
  let { timelineData } = message;
  return {
    timelineData: timelineData?.length ? timelineData : [],
  };
}

export default withRouter(
  connect(mapStateToProps, {
    getTimeLineAction,
  })(FilesTab)
);
