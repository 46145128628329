import { useState } from 'react';
import { useAppDispatch } from '../../shared/hooks/use-app-dispatch';
import { useConfirm } from '../../shared/confirmation-dialog';
import { FileDropZone } from '../../shared/file-upload';
import { notification } from 'antd';
import { importProjectsParse } from '../../modules/projects/index.js';
import { getTemporaryProjects } from '../../modules/projects';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import history from '../../routes/history.js';

type ImportProjectModalProps = {
  open: boolean;
  onClose: () => void;
};

export const ImportProjectModal = ({ open = false, onClose }: ImportProjectModalProps) => {
  const confirm = useConfirm();
  const dispatch = useAppDispatch();

  const [fileUpload, setFileUpload] = useState<any>({});

  const uploadContactCSV = async () => {
    const formData = new FormData();

    if (!fileUpload.files.length) {
      notification.error({ message: 'Please select a file!' });
      return;
    }

    formData.append('projectsCsv', fileUpload.files[0]);

    await dispatch((importProjectsParse as any)(formData)).unwrap();

    history.push('/import-projects-view');
  };

  const onUploadClick = async () => {
    const result = await dispatch(getTemporaryProjects()).unwrap();

    if (result.length > 0) {
      const ok = await confirm({
        title: 'Confirm your action',
        question:
          'You have some pending projects, if you Upload this action will override them. Are you sure?',
      });

      if (!ok) {
        return;
      }
    }

    uploadContactCSV();
  };

  const onDrop = (files: any) => {
    fileUpload.files = files;
  };

  return (
    <Modal backdrop='static' isOpen={open} toggle={onClose}>
      <ModalHeader toggle={onClose}>Import Projects</ModalHeader>
      <FileDropZone onFileDrop={onDrop}>
        <ModalBody>
          <input
            className='csv-input'
            type='file'
            name='files'
            id='files'
            accept='text/csv'
            ref={(ref) => setFileUpload(ref)}
          />
        </ModalBody>
      </FileDropZone>
      <ModalFooter>
        <button type='button' className='btn btn-primary' onClick={onClose}>
          Cancel
        </button>
        <button type='button' className='btn btn-primary' onClick={onUploadClick}>
          Upload
        </button>
      </ModalFooter>
    </Modal>
  );
};
