import React, {Suspense} from 'react';

import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { Button } from 'react-bootstrap';
import * as Papa from 'papaparse';
import Header from '../Layout/Header.js';
import Footer from '../Layout/Footer.js';
import { appConstants, moduleConstants, isAllowed } from '../../_constants';
import { connect } from 'react-redux';
import {
  GetWipProjectListReportAction,
  UpdateWipProjectListReportAction,
} from '../../actions/reports';
import { withRouter, Link } from 'react-router-dom';
import { getAllJobStatusAction } from '../../actions/jobStatusCode';
import { notification } from 'antd';
import { CSVLink } from 'react-csv';

import ReactLoading from 'react-loading';
import TitleComponent from  "../Common/TitleComponent";

import { UpperCaseName } from '../../components/Auth/Auth';
import {
  getAcronymCode,
} from '../../utils/contact-formatters';
import { formatDateWithTime,formatDateObject,formatDate } from '../../utils/date-formatters';
import { FileDropZone } from '../../shared/file-upload';
import { formatMoney } from '../../utils/number-formatters';
import CustomTable from '../Common/CustomTable';
import { onCurrency, onDate, onText } from '../../utils/column-formatters';


const allowedExtension = 'text/csv';
var getFileName = 'exportWip.csv';
var csvHeaders = [];
var csvData = [];

class WipViewList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hidden: true,
      sessionToken: localStorage.getItem('sessionToken'),
      userId: localStorage.getItem('userId'),
      accountId: localStorage.getItem('accountId'),
      row: [],
      list: [],
      allProjectStatus: [],
      selectBox: {
        isBondRqd: false,
        isRef: false,
      },
      jobStatusCodes: [],
      formData: {},
      allJobStatusCodes: [],
      fetchRequest: false,
      leadFormData: {
        cuserId: localStorage.getItem('userId'),
        accountId: localStorage.getItem('accountId'),
      },
      projectFormData: {
        userId: localStorage.getItem('userId'),
        accountId: localStorage.getItem('accountId'),
      },
      submitLead: false,
      createSuccess: false,
      currentPage: 1,
      sizePerPage: appConstants.TableSizePerPageList[2].value,
      totalCount: 0,
    };
    this.toggle = this.toggle.bind(this);
    this.getProjectList = this.getProjectList.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.onSizePerPageList = this.onSizePerPageList.bind(this);
  }

  UNSAFE_componentWillMount() {
    let data = {
      accountId: this.state.accountId,
      page: 0,
      displayLimit: appConstants.TableSizePerPageList[2].value,
      sortKey: 'createdAt',
      statusArr: [
        'Active',
        'In Warranty',
        'Completed (Portfolio)',
        'Delivered',
        'Phase 1 (DB)',
        'Phase 2 (DB)',
        'Phase 3 (DB)',
      ],
      sortType: 'ASC',
      search: 'adad',
      lang: 'EN',
    };
    this.props.GetWipProjectListReportAction(data);
    this.props.getAllJobStatusAction({
      className: 'jobStatusCodes',
      accountId: this.state.accountId,
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.allProjectStatus && nextProps.allProjectStatus.length > 0) {
      this.setState({ allJobStatusCodes: nextProps.allProjectStatus });
    }
    if (nextProps.allProjects && nextProps.allProjects.success === 1) {
      this.setState({
        list: nextProps.allProjects.data,
        fetchRequest: true,
        totalCount: nextProps.allProjects.count,
      });
    }

    if (nextProps.wipUpdate?.error) {
      this.toggle();

      if (nextProps.wipUpdate.error.length === 0) {
        notification.success({
          message: nextProps.wipUpdate.msg,
          duration: 3,
        });
      } else {
        nextProps.wipUpdate.error.forEach(error => {
          notification.error({
            message: error,
            duration: 3,
          });
        });
      }

      this.getProjectList();
    }
  }

  getProjectList(key, clickSubmit = '') {
    const { jobStatusCodes, accountId, userId } = this.state;
    // console.log("jobStatusCodes", jobStatusCodes);
    this.setState({
      list: [],
      sizePerPage: appConstants.TableSizePerPageList[2].value,
    });
    this.setState({ fetchRequest: false });
    this.setState({ currentPage: 1 });
    let allProject = {};
    allProject['accountId'] = accountId;
    allProject['displayLimit'] = appConstants.TableSizePerPageList[2].value;
    allProject['userId'] = userId;
    allProject['jobStatusCodesName'] = jobStatusCodes;
    allProject['jobStatusCodes'] = jobStatusCodes;
    if (jobStatusCodes.length < 1) {
      this.UNSAFE_componentWillMount();
    } else {
      let jobStatusCodesList = [];
      if (jobStatusCodes.length > 0) {
        jobStatusCodes.map((v) => {
          jobStatusCodesList.push(v.label);
          return true;
        });
      }
      allProject['statusArr'] = jobStatusCodesList;
      this.props.GetWipProjectListReportAction(allProject);
    }
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
      successUploading: true,
      errorUploading: true,
      uploading: false,
    });
  }

  onDrop = (files) => {
    this.fileUpload.files = files;
  };

  handleChangeSearchState(name, value) {
    let jobStatusCodes = this.state.jobStatusCodes;
    jobStatusCodes = value;
    this.setState({
      jobStatusCodes: jobStatusCodes,
    });
  }

  uploadContactCSV = () => {
    if (!this.fileUpload.files.length)
      return notification.error({ message: 'Please select a file!' });

    const file = this.fileUpload.files[0];

    if (file.type !== allowedExtension)
      return notification.error({ message: 'Failed to parse file!' });

    let sheetData = [];
    let _this = this;
    this.setState({
      counter: -1,
      uploading: true,
    });
    if (file) {
      Papa.parse(file, {
        download: true,
        header: false,
        quoteChar: '"',
        escapeChar: '"',
        delimiter: ",",
        step: this.addContact,
        complete: function (results) {
          const dbHeader = ['objectId', 'jobNum', 'jobName', 'contractAmount', 'grossProfit'];
          // last element is empty string
          results?.data?.splice(-1);
          _this.importCompany(results?.data || [], dbHeader);
        },
      });
    }
  };

  importCompany(data, headers) {
    let dataArr = [];
    let hError;
    for (var i = 0; i < headers.length; i++) {
      if (headers[i] === data[0][i]) {
        hError = false;
      } else {
        hError = true;
        break;
      }
    }

    if (hError !== true) {
      // 0 element is csv headers
      for (var x = 1; x < data.length; x++) {
        let obj = {};
        for (var z = 0; z < headers.length; z++) {
          if (headers[z] === 'grossProfit' || headers[z] === 'contractAmount') {
            obj[headers[z]] = parseInt(data[x][z], 10);
          } else if (headers[z] === 'jobName') {
            obj[headers[z]] = UpperCaseName(data[x][z]);
          } else {
            obj[headers[z]] = data[x][z];
          }
        }
        dataArr.push(obj);
      }
      const sendData = {
        adminId: this.state.userId,
        accountId: this.state.accountId,
        dataArr,
      };
      this.props.UpdateWipProjectListReportAction(sendData, this.props);
    } else {
      this.toggle();
    }
  }

  showProjectStatus(cell, row, enumObject, rowIndex) {
    return <p>{row.projectStatus}</p>;
  }

  showBidDate(cell, row, enumObject, rowIndex) {
    if (
      row.projectStatus === 'Active' ||
      row.projectStatus === 'Delivered' ||
      row.projectStatus === 'Warranty' ||
      row.projectStatus === 'Completed' ||
      row.projectStatus === 'On-Hold' ||
      row.projectStatus === 'Bidding'
    ) {
      return <p>{row.bidDue && formatDate(row.bidDue.iso)}</p>;
    } else {
      return <p>{row.startDate && formatDate(row.startDate.iso)}</p>;
    }
  }

  showAccountExecStatus(cell, row, enumObject, rowIndex) {
    let accountExec = '';
    if (
      row &&
      row.teamData &&
      row.teamData['account executive'] &&
      row.teamData['account executive'].length > 0
    ) {
      return (
        <div>
          {row.teamData['account executive'].map((item, i) => {
            let comma;
            if (i != 0) {
              comma = ', ';
            } else {
              comma = '';
            }
            return (
              <Link to={{ pathname: `/contact-details/` + item.userId }}>
                {comma}
                {getAcronymCode(item.userFullName)}{' '}
              </Link>
            );
          })}
        </div>
      );
    } else {
      return <span>-</span>;
    }
  }
  
  contractAmount(cell, row, enumObject, rowIndex) {
    return cell?.currentContractAmount ? formatMoney(cell.currentContractAmount) : '';
  }

  grossProfit(cell, row, enumObject, rowIndex) {
    return cell?.currentGrossProfit ? formatMoney(cell.currentGrossProfit) : '';
  }

  /*  showAccountExecStatus(cell, row, enumObject, rowIndex) {
          let accountExec = ''
          if (row.accountExecArr && row.accountExecArr.length > 0) {
              row.accountExecArr.map(item => {
                  if (accountExec !== "") {
                      accountExec = accountExec + ", ";
                  }
                  accountExec = (accountExec + item.userFullName);
                  return true;
              });
              return (<p>{accountExec}</p>)
          }
      }*/

  _setTableOption() {
    if (this.state.fetchRequest) {
      return 'No data found..';
    } else {
      return (
        <ReactLoading
          className="table-loader"
          type={appConstants.LOADER_TYPE}
          color={appConstants.LOADER_COLOR}
          height={appConstants.LOADER_HEIGHT}
          width={appConstants.LOADER_WIDTH}
        />
      );
    }
  }

  showAmountProject(cell, row, enumObject, rowIndex) {
    if (row.originalContractAmount && row.originalContractAmount !== '') {
      return <p>{row.originalContractAmount && this.formatMoney(row.originalContractAmount)}</p>;
    }
  }

  DataCsvList() {
    let projectList = this.state.list;
    var finalArr = [];
    let dbHeader = ['objectId', 'jobNum', 'jobName', 'contractAmount', 'grossProfit'];
    var s = null;
    var a = null;
    for (s in projectList) {
      var dataArr = [];
      for (a in dbHeader) {
        var t = {};
        t = projectList[s][dbHeader[a]];
        if (dbHeader[a] === 'jobNum') {
          t = projectList[s]['jobNumFormatted'];
        }
        if (dbHeader[a] === 'contractAmount') {
          t = projectList[s]['currentContractAmount'];
        }
        if (dbHeader[a] === 'grossProfit') {
          t = projectList[s]['currentGrossProfit'];
        }
        dataArr.push(t);
      }
      finalArr.push(dataArr);
    }
    // let headerField=["Project Id","Job Number","Job Name","Contract Amount","Gross Profit"];
    csvHeaders = dbHeader;
    csvData = finalArr;
    console.log('finalArr', finalArr);
  }

  onPageChange(page, sizePerPage) {
    let data = {};
    if (this.state.jobStatusCodes.length > 0) {
      const { jobStatusCodes } = this.state;
      data['statusArr'] = [];
      data['jobStatusCodesName'] = jobStatusCodes;
      data['sortKey'] = 'createdAt';
      data['jobStatusCodes'] = jobStatusCodes;
      for (var i in jobStatusCodes) {
        data['statusArr'].push(jobStatusCodes[i].label);
      }
    } else {
      data['sortKey'] = 'createdAt';
      data['statusArr'] = [
        'Active',
        'In Warranty',
        'Completed (Portfolio)',
        'Delivered',
        'Phase 1 (DB)',
        'Phase 2 (DB)',
        'Phase 3 (DB)',
      ];
    }
    if (this.state.searchText === '') {
      data['accountId'] = this.state.accountId;
      data['page'] = page === -1 ? 0 : page - 1;
      data['displayLimit'] = sizePerPage;
    } else {
      data['accountId'] = this.state.accountId;
      data['page'] = page === -1 ? 0 : page - 1;
      data['displayLimit'] = sizePerPage;
      data['search'] = this.state.searchText;
    }

    this.setState({ sizePerPage: sizePerPage });
    this.setState({ currentPage: page });
    this.props.GetWipProjectListReportAction(data);
  }

  

  onSizePerPageList(sizePerPage) {}

  jobNumTable(cell, row, enumObject, rowIndex) {
    return (
      <div>
        <Link to={{ pathname: `/project-details/` + row.objectId }}>{row.jobNumFormatted}</Link>
      </div>
    );
  }

  jobNameTable(cell, row, enumObject, rowIndex) {
    return (
      <div>
        <Link to={{ pathname: `/project-details/` + row.objectId }}>{row.jobName}</Link>
      </div>
    );
  }



  render() {
    const columns = [
     // { id: 'Obj Id', Header: 'Obj Id', accessor: 'objectId', searchable: false, hidden: true },
      { id: 'Job Number', Header: 'Job Number', accessor: 'jobNumFormatted', Cell: ({ column, row, value }) => <>{this.jobNumTable(column.id, row.original, value)}</> },
      { id: 'Name', Header: 'Name', accessor: 'jobName', Cell: ({ column, row, value }) => <>{this.jobNameTable(column.id, row.original, value)}</> },
      { id: 'Status', Header: 'Status', accessor: 'jobStatusCodesName', Cell: ({ column, row, value }) => <>{onText(column.id, row.original, value)}</> },
      { id: 'Bid/Start Date', Header: 'Bid/Start Date', accessor: 'startDate', Cell: ({ column, row, value }) => <>{onDate(column.id, row.original, value)}</> },
      { id: 'Job End', Header: 'Job End', accessor: 'endDate', Cell: ({ column, row, value }) => <>{onDate(column.id, row.original, value)}</> },
      { id: 'Contract', Header: 'Contract', accessor: 'currentContractAmount', Cell: ({ column, row, value }) => <>{onCurrency(column.id, row.original, value)}</> },
      { id: 'Gross Margin', Header: 'Gross Margin', accessor: 'currentGrossProfit', Cell: ({ column, row, value }) => <>{onCurrency(column.id, row.original, value)}</> },
      { id: 'Comment', Header: 'Comment', accessor: 'comment' },
    ];

    let searchOptionTemplateJobStatusCode = [];
    if (this.state.allJobStatusCodes.length > 0) {
      this.state.allJobStatusCodes.map((v) => {
        if (v.objectId !== '' && v.jobStatusCodesCode !== '') {
          if (
            v.jobStatusCodesCode === 'A' ||
            v.jobStatusCodesCode === 'P1' ||
            v.jobStatusCodesCode === 'P2' ||
            v.jobStatusCodesCode === 'P3' ||
            v.jobStatusCodesCode === 'D' ||
            v.jobStatusCodesCode === 'W' ||
            v.jobStatusCodesCode === 'C'
          ) {
            searchOptionTemplateJobStatusCode.push({
              value: v.objectId,
              label: v.jobStatusCodesName,
            });
          }
        }
        return true;
      });
    }

    this.DataCsvList();
    let tableOption = {
      sizePerPageList: appConstants.TableSizePerPageList,
      sizePerPage: this.state.sizePerPage,
      onPageChange: this.onPageChange,
      page: this.state.currentPage,
      onSizePerPageList: this.onSizePerPageList,
      noDataText: this._setTableOption(),
      //afterDeleteRow:this.handleDeleteButtonClick,
      prePage: 'Prev', // Previous page button text
      nextPage: 'Next', // Next page button text
      firstPage: 'First', // First page button text
      lastPage: 'Last', // Last page button text,
      paginationPosition: 'bottom', // default is bottom, top and both is all available
      onSearchChange: this.onSearchChange,
      alwaysShowAllBtns: true, // Always show next and previous buttondefault is bottom, top and both is all available
    };

    return (
      <div className="ContactListReport">
        <TitleComponent data={{title:`WIP Upload`}}/>
        <Suspense fallback="loading"><Header props={this.props} /></Suspense>
        <div className="contactBox">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h1 className="heading1">WIP Project List </h1>
              </div>
            </div>
            <div className="row searchbar">
              <div className="col-md-3">
                <label htmlFor="jobStatusCodes">Status</label>
                <Select
                  name="jobStatusCodes"
                  closeMenuOnSelect={false}
                  isMulti
                  components={makeAnimated()}
                  value={this.state.jobStatusCodes}
                  onChange={this.handleChangeSearchState.bind(this, 'jobStatusCodes')}
                  options={searchOptionTemplateJobStatusCode}
                />
              </div>
              <div className="col-md-2">
                <div className="projectSearchBarBox">
                  <input
                    type="button"
                    onClick={(e) => {
                      this.getProjectList(this.state.key, 'submitSearch');
                    }}
                    value="Search"
                    className="btn btn-primary"
                  />
                </div>
              </div>
              <div className="col-md-3">&nbsp;</div>
              <div className="col-md-2 right-div">
                <CSVLink headers={csvHeaders} data={csvData} filename={getFileName} className="btn">
                  Export to CSV ⬇
                </CSVLink>
              </div>
              {isAllowed(moduleConstants.EW) && (
                <div className="col-md-2 right-div">
                  <Button className="btn" onClick={this.toggle}>
                    Import CSV <span>⬆️</span>
                  </Button>
                </div>
              )}
            </div>
            <div className="projectListTable">
              <div className="row">
                <div className="col-md-12">
                  <div className="sortable-table">
                  <CustomTable
                    columns={columns.filter(Boolean)}
                    list={this.state.list}
                    inlineEdit={false}
                    canShowExport={false}
                    canShowPagination={true}
                    canShowGlobalFilter={false}
                    canShowColumnSettings={false}
                    canShowDateFilter={false}
                    canShowRowSelection={false}
                    useControlledState={(state) => ({ ...state })}
                  />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
        <Modal
          backdrop="static"
          keyboard={true}
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggle}>Upload Projects</ModalHeader>
          <FileDropZone onFileDrop={this.onDrop}>
            <ModalBody>
              <div className={'alert alert-danger ' + (this.state.errorUploading ? 'd-none' : '')}>
                <strong>Error While uploading! </strong> Contact List.
              </div>
              <div className={'alert alert-success ' + (this.state.successUploading ? 'd-none' : '')}>
                <strong>Contacts List !</strong> Uploaded successfully in system.
              </div>
              <input
                className="csv-input"
                type="file"
                name="files"
                id="files"
                accept={allowedExtension}
                onChange={this.onInputChange}
                ref={(ref) => (this.fileUpload = ref)}
              />
            </ModalBody>
          </FileDropZone>
          <ModalFooter>
            <Button className="btn btn-primary" onClick={this.toggle}>
              Cancel
            </Button>{' '}
            <Button disabled={this.state.uploading} className="btn btn-primary" onClick={(e) => this.uploadContactCSV(e)}>
              {this.state.uploading ? 'Uploading...' : 'Upload'}
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    allProjects: state.reports && state.reports.reportList && state.reports.reportList.result,
    allProjectStatus: state.jobStatusCode.allJobStatus,
    createSuccess: state.project.createSuccess,
    message:
      state.reports &&
      state.reports.reportList &&
      state.reports.reportList.result &&
      state.reports.reportList.result.msg,
    fetchSuccess: state.project.fetchSuccess,
    wipUpdate: state.reports.reportList && state.reports.reportList.result,
  };
}

export default (
  withRouter(
    connect(mapStateToProps, {
      GetWipProjectListReportAction,
      UpdateWipProjectListReportAction,
      getAllJobStatusAction,
    })(WipViewList)
  )
);
