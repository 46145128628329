export const toContactAddress = (googlePlace) => {
  const place = googlePlace?.[0];

  if (!place) return;

  const { address_components } = place;

  const payload = {
    streetNumber: undefined,
    route: undefined,
    city: undefined,
    state: undefined,
    zipCode: undefined,
    country: undefined,
    placeId: place.place_id,
    lat: place.geometry.location.lat(),
    lng: place.geometry.location.lng(),
  } as {
    streetNumber?: string;
    route?: string;
    city?: string;
    state?: string;
    zipCode?: string;
    country?: string;
    placeId?: string;
    lat: number;
    lng: number;
  };

  const long = 'long_name';
  const short = 'short_name';

  const map = new Map([
    ['street_number', [long, 'streetNumber']],
    ['route', [long, 'route']],
    ['locality', [long, 'city']],
    ['administrative_area_level_1', [short, 'state']],
    ['postal_code', [long, 'zipCode']],
    ['country', [long, 'country']],
  ]);

  address_components.forEach((address) => {
    address.types.forEach((type) => {
      if (!map.has(type)) return;

      const [length, field] = map.get(type) as any;
      payload[field] = address[length] ?? '';
      map.delete(type);
    });
  });

  return payload;
};

export const convertAddressToFormValue = (streetNumber, route) => {
  let address = '';
  if (streetNumber && route) {
    address = `${streetNumber} ${route}`;
  } else if (route && !streetNumber) {
    address = `${route}`;
  }
  return address;
};
