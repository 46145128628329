import { postRequest } from './helper';

export const createNote = (data) => postRequest(`functions/createNotes`, data);
export const pinNote = (data) => postRequest(`functions/pinNote`, data);
export const getAllNotes = (data) => postRequest('functions/getNotes', data);
export const deleteNotes = (data) => postRequest('functions/deleteNotes', data);

export const createEmail = (data) => postRequest(`functions/sendSectionEmail`, data);
export const getAllEmail = (data) => postRequest('functions/getSectionEmail', data);
export const deleteEmail = (data) => postRequest('functions/deleteDraftEmail', data);

export const createSchedule = (data) => postRequest(`functions/createCpostSchedule`, data);
export const getAllSchedule = (data) => postRequest('functions/getCpostSchedule', data);
export const getTimeLine = (data) => postRequest('functions/timelineTabs', data);
export const cancelSchedule = (data) => postRequest('functions/cancelCpostSchedule', data);
