import { useEffect } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import avatarImage from '../../components/static/images/avatar3.png';
import loaderImage1 from '../../components/static/images/loading_i.gif';
import { getNotificationActivity } from '../../modules/contacts';
import { selectGetNotificationActivity } from '../../modules/contacts/selectors';
import { useAppDispatch } from '../../shared/hooks/use-app-dispatch';
import { useAppSelector } from '../../shared/hooks/use-app-selector';
import { fromNow, teamActivityTimeFormat } from '../../utils/date-formatters';

import { Link } from 'react-router-dom';

export const TeamActivityWidget = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getNotificationActivity({ count: 10, page: 1 }));
  }, []);

  const {
    data: { notificationActivity: notificationActivityData },
    loading,
  } = useAppSelector(selectGetNotificationActivity);

  const notificationActivity = (notificationActivityData as any[]).map((data, index) => {
    const activityDate = teamActivityTimeFormat(data._created_at);
    const str = data.actMessage;
    const projectId = data.projectObjectId;
    const companyId = data.companyId;
    const contactId = data.contactId;
    
    const res = str.replace('SYSTEM_DATE', activityDate);
    return (
      <li key={index}>
        <Link to={{ pathname: `/contact-details/` + data.user.contactId }}>
          <img
            className='dashAvatar'
            src={data.user && data.user.userImage ? data.user.userImage : avatarImage}
            alt='Avatar'
          />
        </Link>
        <span>
          {projectId ? (
                <strong>
                  {' '}
                  <Link to={{ pathname: `/project-details/` + projectId }}>{data.title}</Link>
                </strong>
              ) : 
              contactId ? (
                <strong>
                  {' '}
                  <Link to={{ pathname: `/contact-details/` + contactId }}>{data.title}</Link>
                </strong>
              ) : 
              companyId ? (
                <strong>
                  {' '}
                  <Link to={{ pathname: `/company-details/` + companyId }}>{data.title}</Link>
                </strong>
              ) : 
              (
                <strong>{data.title}</strong>
              )}
        </span>
        <span>{res}</span>
        <div className='activityTime'>
          <i className='fa fa-clock-o' aria-hidden='true'></i> {fromNow(data._created_at)}
        </div>
      </li>
    );
  });

  return (
    <div className='col-lg-6'>
      <div className='welcomeDescription'>
        <h3>Team Activity</h3>
        {!notificationActivityData?.length && !loading && (
          <div className='msgBox1'>
            <span>No data available!</span>
          </div>
        )}
        {loading ? (
          <div id='loaderBox' className='msgBox'>
            <img src={loaderImage1} width='100' height='100' />
          </div>
        ) : (
          <div className='dashInner 200'>
            <Scrollbars style={{ height: '100%' }}>
              <ul className='clearfix teamActivityList'>{notificationActivity}</ul>
            </Scrollbars>
          </div>
        )}
      </div>
    </div>
  );
};
