import Joi from 'joi';
import { Moment } from 'moment';
import { joiMoment } from '../../utils/validation';
import { currencyToNumber } from '../../utils/number-formatters';

export type AddLeadModalSchema = {
  jobName: string;
  leadSource: string;
  leadCohort: string;
  leadDate: Moment | null;
  accountExec: string[];
  marketingDescription: string;
  bidDue: Moment | null;
  startDate: Moment | null;
  endDate: Moment | null;
  jobAddress: string;
  jobAddress2: string;
  jobCity: string;
  jobState: string;
  jobZip: string;
  lat: number;
  lng: number;
  jobCountry: string;
  apNum: string;
  sqFeet: number | null;
  estimateBidAmount: string;
  estimateGrossProfit: string;
  client: string[];
  buildingOwner: string[];
  tenant: string[];
  broker: string[];
  architect: string[];
  negotiationMethod: string | null;
  scope: string[];
  buildingType: string | null;
  occupancyType: string | null;
  jobNotes: string;
  accountId: string | null;
  createdBy: string | null;
  userId: string | null;
  jobStatusCodes: string | null;
  jobStatusCodesCode: string | null;
  moduleName: string | null;
  isBondRqd: boolean;
  isRef: boolean;
  objectId: string | null;
  duration: string | null;
  projectTeam: { [key: string]: string[] }
  createdFrom: string;
  timezone: string;
};

export const addLeadModalSchema = Joi.object({
  jobName: Joi.string().trim().empty('').required().label('Job Name'),
  leadSource: Joi.string().empty('').allow('').label('Lead Source'),
  leadCohort: Joi.string().empty('').allow('').label('Lead Cohort'),
  leadDate: joiMoment().label('Lead Date'),
  // accountExec: Joi.array().items(Joi.string()).label('Account Exec'),
  marketingDescription: Joi.string().allow('').label('Marketing Description'),
  bidDue: joiMoment().allow(null).label('Bid Date'),
  startDate: joiMoment().label('Const. Start Date'),
  endDate: joiMoment().label('Const. End Date'),
  jobAddress: Joi.string().allow('').label('Address 1'),
  jobAddress2: Joi.string().allow('').label('Address 2'),
  jobCity: Joi.string().allow('').label('City'),
  jobState: Joi.string().allow('').label('State'),
  jobZip: Joi.string().allow('').label('Zip Code'),
  lat: Joi.number(),
  lng: Joi.number(),
  jobCountry: Joi.string().allow('').label('Country'),
  apNum: Joi.string().allow('').label('Assessor Parcel Number'),
  sqFeet: Joi.number().allow('',null).label('Approx. Sq. Feet'),
  estimateBidAmount: Joi.string().empty('').label('Approx. Bid Amount'),
  estimateGrossProfit: Joi.string()
    .allow('')
    .custom((value, helper) => {
      const estimateGrossProfitNumber = currencyToNumber(value);
      const estimateBidAmountNumber = currencyToNumber(helper.state.ancestors[0].estimateBidAmount);

      if (estimateGrossProfitNumber > estimateBidAmountNumber) {
        return helper.message({
          custom: '{#label} should be less than "Approx. Bid Amount"',
        });
      }

      return value;
    })
    .label('Approx. Gross Profit'),
  negotiationMethod: Joi.string().allow(null,'').label('Negotiating Method'),
  scope: Joi.array().items(Joi.string()).label('Job Scope'),
  buildingType: Joi.string().allow(null,'').label('Building Type'),
  occupancyType: Joi.string().allow(null,'').label('Occupancy Type'),
  jobNotes: Joi.string().allow('').label('Notes'),
  cuserId: Joi.string().allow('').label('Current User Id'),
  accountId: Joi.string().allow('').label('Account Id'),
  createdBy: Joi.string().allow('').label('Created By'),
  userId: Joi.string().allow('').label('User Id'),
  jobStatusCodes: Joi.string().label('Job Status Code'),
  jobStatusCodesCode: Joi.string().allow('').label('Job Status Short Code'),
  moduleName: Joi.string().allow('').label('Module Name'),
  isBondRqd: Joi.boolean().label('Bond Required'),
  isRef: Joi.boolean().label('Show as a reference'),
  objectId: Joi.string().trim().empty('').label('Object Id'),
  duration: Joi.string().trim().empty('').label('Duration'),
  projectTeam: Joi.allow().label('Project Team'),
  createdFrom: Joi.string().allow(null, '').failover('WEB').label('Created From'),
  timezone: Joi.string().allow(null, '').label('Time Zone'),
});
