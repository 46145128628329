import _ from 'lodash';
import moment from 'moment';
import { useCallback, useMemo } from 'react';
import { dateFormatMMDDYYYY } from '../../../../utils/date-formatters';
import { formatMoney } from '../../../../utils/number-formatters';
import coStyles from '../../summary-financial-modal.module.scss';

type FinancialProps = {
  projectData: any;
  changeOrders: any;
  projectWIPs: any;
  onOpenCOModal: any;
};

export const Financial = ({
  projectData = {},
  changeOrders = [],
  projectWIPs = [],
  onOpenCOModal,
}: FinancialProps) => {
  const { originalContractAmount, originalGrossProfit, startDate } = projectData;

  // WIPs don't have numbers or indexes to name them thus adding it
  const updatedWIPs = _.cloneDeep(projectWIPs).map((wip: any, index: number) => {
    wip.index = index;
    return wip;
  });
  // COs date fields returned as objects thus get only iso date value
  const updatedCOs = _.cloneDeep(changeOrders)
    .filter((co: any) => !co.isPCO)
    .map((co: any) => {
      co.effectiveDate = co.effectiveDate?.iso ?? co.changeOrderDate?.iso;
      return co;
    });

  const projectWIPsAndCOs = [...updatedCOs, ...updatedWIPs];
  projectWIPsAndCOs.sort((a: any, b: any) => {
    const aEffectiveDate = moment(a.effectiveDate);
    const bEffectiveDate = moment(b.effectiveDate);

    return aEffectiveDate.diff(bEffectiveDate) || (a.coNumber - b.coNumber);
  });

  const getCORow = useCallback((data: any, currValues: any) => {
    const {
      objectId,
      coNumber,
      comment,
      coCostAmount,
      profitDollars,
      coTotalAmount,
      effectiveDate,
    } = data;

    const { cost, profit, contract } = currValues;

    const newCost = cost + coCostAmount;
    const newProfit = profit + profitDollars;
    const newContract = contract + coTotalAmount;

    currValues.cost = newCost;
    currValues.profit = newProfit;
    currValues.contract = newContract;

    return (
      <tr key={objectId} role='button' onClick={() => onOpenCOModal(objectId)}>
        <td>{dateFormatMMDDYYYY(effectiveDate)}</td>
        <td>CO {coNumber}</td>
        <td>{comment}</td>
        <td>{formatMoney(newCost)}</td>
        <td>{formatMoney(newProfit)}</td>
        <td>{formatMoney(newContract)}</td>
      </tr>
    );
  }, []);

  const getWIPRow = useCallback((data: any, currValues: any) => {
    const {
      _id,
      index,
      comment,
      currentCost,
      currentGrossProfit,
      currentContractAmount,
      effectiveDate,
    } = data;

    currValues.cost = currentCost;
    currValues.profit = currentGrossProfit;
    currValues.contract = currentContractAmount;

    return (
      <tr key={_id}>
        <td>{dateFormatMMDDYYYY(effectiveDate)}</td>
        <td>WIP {index}</td>
        <td>{comment}</td>
        <td>{formatMoney(currentCost)}</td>
        <td>{formatMoney(currentGrossProfit)}</td>
        <td>{formatMoney(currentContractAmount)}</td>
      </tr>
    );
  }, []);

  const rows = useMemo(
    () => {
      const currValues = {
        cost: originalContractAmount - originalGrossProfit,
        profit: originalGrossProfit,
        contract: originalContractAmount,
      };

      return projectWIPsAndCOs.map((data) => {
        const isWIP = data.updateType === 'UpdateWIP';

        return isWIP ? getWIPRow(data, currValues) : getCORow(data, currValues);
      })
    },
    [projectWIPsAndCOs]
  );

  return (
    <table className={`${coStyles.coTable} ${coStyles.clickable}`}>
      <thead>
        <tr>
          <th>Date</th>
          <th>Name/#</th>
          <th>Description</th>
          <th>Cost</th>
          <th>Profit</th>
          <th>Contract</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{dateFormatMMDDYYYY(startDate)}</td>
          <td>Original Contract</td>
          <td></td>
          <td>{formatMoney(originalContractAmount - originalGrossProfit)}</td>
          <td>{formatMoney(originalGrossProfit)}</td>
          <td>{formatMoney(originalContractAmount)}</td>
        </tr>
        {rows}
      </tbody>
    </table>
  );
};
