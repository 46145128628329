import { useMemo } from 'react';
import { getProjectStageByCode } from '../../../shared/custom-fields-controls/constants';
import { ProjectCustomFieldsForSectionView } from './project-custom-fields-view';

type ProjectCustomFieldsViewProps = {
  projectData: any;
  customFields: any[];
  section: string;
  title: string;
  isHideFields: boolean;
  onEditRef?: any;
};

export const ProjectCustomFieldsView = ({
  projectData = {},
  customFields = [],
  section,
  title,
  isHideFields=false,
  onEditRef,
}: ProjectCustomFieldsViewProps) => {

  const { customFields: customFieldsValues, jobStatusCodesCode } = projectData;



  const projectStage = useMemo(
    () => getProjectStageByCode(jobStatusCodesCode),
    [jobStatusCodesCode]
  );


  const sectionFields = useMemo(
    () => customFields.filter((field: any) => field.section === section),
    [customFields, section]
  );

  const sectionViewMemo = useMemo(() => {
    return (
      <ProjectCustomFieldsForSectionView
        fields={sectionFields}
        allFields={customFields}
        values={customFieldsValues}
        projectStage={projectStage}
        isHideFields={isHideFields}
        onEditRef={onEditRef}
      />
    );
  }, [sectionFields, customFieldsValues, projectStage, isHideFields]);

  return (
    <div className='row'>
      {title && (
        <div className='col-12'>
          <strong>{title}</strong>
        </div>
      )}
      {sectionViewMemo}
    </div>
  );
};
