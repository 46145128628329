export const reportSections = [
  { label: 'Leads', value: 'leads' },
  { label: 'Bidding', value: 'bidding' },
  { label: 'Bids Pending', value: 'bidsPending' },
  { label: 'Active', value: 'active' },
  { label: 'Delivered', value: 'delivered' },
  { label: 'Warranty', value: 'warranty' },
  { label: 'Completed', value: 'completed' },
];

export const reportTypes = [
  { label: 'Projects', value: 'project' },
  { label: 'Bids', value: 'bid' },
  { label: 'Contacts', value: 'contact' },
  { label: 'Companies', value: 'company' },
];

export const predefinedReportFields = [
  {
    value: 'jobName',
    label: 'Job Name',
  },
  {
    value: 'jobNumber',
    label: 'Job Number',
  },
  {
    value: 'bidStartDate',
    label: 'Estimating Start',
  },
  {
    value: 'bidDate',
    label: 'Bid Date',
  },
  {
    value: 'jobStatusCodes',
    label: 'Job Status',
    canShowCode: true,
  },
  {
    value: 'siteAddress',
    label: 'Site Address',
  },
  {
    value: 'bidSubmittedDate',
    label: 'Bid Submitted Date',
  },
  {
    value: 'startDate',
    label: 'Start Date',
  },
  {
    value: 'endDate',
    label: 'End Date',
  },
  {
    value: 'ntpDate',
    label: 'NTP Received Date',
  },
  {
    value: 'deliveredDate',
    label: 'Delivered Date',
  },
  {
    value: 'followUpDate',
    label: 'Follow Up Date',
  },
  {
    value: 'grossProfit',
    label: 'Gross Profit',
  },
  {
    value: 'warrantyPeriod',
    label: 'Warranty Period',
  },
  {
    value: 'lienPeriod',
    label: 'Lien Period',
  },
  {
    value: 'jobScope',
    label: 'Job Scope',
    canShowCode: true,
  },
  {
    value: 'bondRequired',
    label: 'Bond Required',
  },
  {
    value: 'leadSource',
    label: 'Lead Source',
  },
  {
    value: 'leadCohort',
    label: 'Lead Cohort',
  },
  {
    value: 'leadDate',
    label: 'Lead Date',
  },
];
