import * as actionTypes from './../Admin/actionTypes.js';
import * as API from '../../api/Admin/login';
import history from '../../routes/history';

export const getDashboardRequest = () => ({
  type: actionTypes.GET_DASHBOARD_REQUEST,
});

export const getDashboardSuccess = (response) => ({
  type: actionTypes.GET_DASHBOARD_SUCCESS,
  payload: {
    response,
  },
});

export const getDashboardError = (error) => ({
  type: actionTypes.GET_DASHBOARD_ERROR,
  payload: {
    error,
  },
});

export const getDashboardAction = (data) => {
  return (dispatch) => {
    dispatch(getDashboardRequest());
    return API.signInAdmin(data)
      .then((response) => {
        if (response && response.data) {
          if (response.data.result.success === 1) {
            localStorage.setItem('sessionToken', response.data.result.data.sessionToken);
            localStorage.setItem('userId', response.data.result.data.objectId);
            localStorage.setItem('accountId', response.data.result.data.accountId.objectId);
            localStorage.setItem('baseUserRole', response.data.result.data.cppRoleId);
            localStorage.setItem('JWTTOKEN', response.data.result.token);
            localStorage.setItem(
              'modulePermissions',
              JSON.stringify(response.data.result.roleMatrix.modulePermissions)
            );
            localStorage.setItem('accountInfo', JSON.stringify(response.data.result.accountData));
            localStorage.setItem('userInfo', JSON.stringify(response.data.result.data));
            history.replace('/admin/home');
            dispatch(getDashboardSuccess(response.data.result));
          } else {
            dispatch(getDashboardError(response.data.result));
          }
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data.error);
          console.log(this.props);
          //this.props.user.logout();
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('Error', error.message);
        }
        console.log(error.config);
      });
  };
};
