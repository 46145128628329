import { useEffect, useState, useCallback } from 'react';
import { useAppDispatch } from '../../shared/hooks/use-app-dispatch';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { getUpdateProjectForm, getProjectWIPUpdates } from '../../modules/projects';
import { viewChangeOrdersAsync } from '../../modules/orders';
import { getAllReasonCodeAction } from '../../actions/reasonCode';
import { ChangeOrderModal } from '../change-order-modal';
import { Header } from './sections/header';
import { Status } from './sections/status';
import { Financial } from './sections/financial';
import { ChangeOrders } from './sections/change-orders';

type SummaryFinancialProps = {
  projectId: string;
  isOpen: boolean;
  toggle: () => void;
};

export const SummaryFinancial = ({
  projectId = '',
  isOpen = false,
  toggle,
}: SummaryFinancialProps) => {
  const dispatch = useAppDispatch();

  const [dialogCOId, setDialogCOId] = useState<string>('');
  const [isAddCOOpened, setAddCOOpened] = useState<boolean>(false);
  const [projectData, setProjectData] = useState<any>({});
  const [changeOrders, setChangeOrders] = useState<Array<any>>([]);
  const [projectWIPs, setProjectWIPs] = useState<Array<any>>([]);

  useEffect(() => {
    if (isOpen && projectId) {
      dispatch(getAllReasonCodeAction({ className: 'reasonCodes' }));
      getData();
    }
  }, [isOpen, projectId]);

  const getData = useCallback(() => {
    dispatch(getUpdateProjectForm({ projectId }))
      .unwrap()
      .then((data: any) => setProjectData({ ...data, objectId: projectId }));

    dispatch((viewChangeOrdersAsync as any)(projectId))
      .unwrap()
      .then((data: Array<any>) => setChangeOrders([...data]));

    dispatch(getProjectWIPUpdates({ projectId }))
      .unwrap()
      .then((data: any) => setProjectWIPs([...data]));
  }, [projectId]);

  const onRefresh = useCallback(async () => {
    getData();
  }, [projectId]);

  const onOpenCOModal = useCallback((id: string) => {
    setDialogCOId(id);
    setAddCOOpened(true);
  }, []);

  return (
    <>
      <Modal backdrop='static' isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>Edit bid information</ModalHeader>
        <ModalBody>
          <div className='container d-flex flex-column gap-4'>
            <Header
              projectData={projectData}
              onOpenCOModal={onOpenCOModal}
              onAfterSubmit={onRefresh}
            />
            <Status projectData={projectData} changeOrders={changeOrders} />
            <Financial
              projectData={projectData}
              changeOrders={changeOrders}
              projectWIPs={projectWIPs}
              onOpenCOModal={onOpenCOModal}
            />
            <ChangeOrders changeOrders={changeOrders} onOpenCOModal={onOpenCOModal} isPotential />
            <ChangeOrders
              changeOrders={changeOrders}
              onOpenCOModal={onOpenCOModal}
              isPotential={false}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <button className='ms-auto btn btn-primary' onClick={toggle}>
            Close
          </button>
        </ModalFooter>
      </Modal>

      <ChangeOrderModal
        isOpen={isAddCOOpened}
        toggle={() => setAddCOOpened(false)}
        onSubmit={onRefresh}
        project={projectData}
        coId={dialogCOId}
      />
    </>
  );
};
