import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getCompanyProjects } from '../../../modules/companies';
import { formatDate } from '../../../utils/date-formatters';
import styles from './style.module.css';
import loaderImage from '../../static/images/loading_i.gif';
import { isAllowed, reportAccess } from '../../../_constants';

type ViewCompanyProjectsProps = {
    companyId: string;
    capacity?: number;
};

export const ViewCompanyProjects = ({ companyId, capacity = 10 }: ViewCompanyProjectsProps) => {

  const dispatch = useDispatch();
  const [projects, setProjects] = useState<Array<any>>([]);
  const [isShowAllVisible, setIsShowAllVisible] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const isShowAll = projects.length > capacity && isShowAllVisible && isAllowed(reportAccess.CJL, 'anyAssigned');

  useEffect(() => {
    if (companyId) {
        (async () => {
            setIsLoading(true);

            const projects = await dispatch((getCompanyProjects as any)({ companyId: companyId })).unwrap();

            setIsLoading(false);
            setProjects(projects);
            projects.length > capacity && setIsShowAllVisible(true);
        })();
    }
  }, [companyId]);

  const projectsArrayToRender = isShowAllVisible ? projects.slice(0, capacity) : projects;

  const renderProjects = (projects) =>
    <table className={styles.infoTable}>
        <thead>
            <td>Name</td>
            <td>Role</td>
            <td>Status</td>
            <td>Last updated</td>
        </thead>

        <tbody>
            {projects.map((data, index) => {
                   const projectRolesName = data.projectRolesName.join(', ');
                
                return (
                    <tr key={index}>
                        <td>
                        <a className={styles.ellipsis} href={`/project-details/${data.projectId}`} title={data.projectName}>
                                <span className='address-det' >{data.projectName}</span>
                            </a>
                        </td>
                        <td className={styles.ellipsis} title={projectRolesName}>
                            {projectRolesName}
                        </td>
                        <td className={styles.ellipsis}>
                            {data.jobStatusCodesName}
                        </td>
                        <td className={styles.ellipsis}>
                            {formatDate(data.lastUpdated)}
                        </td>
                    </tr>
                );
            })}
        </tbody>
    </table>;

  return (
    <div className={styles.wrapper} data-testid='viewCompanyProjects'>
        {isLoading &&
            <div className='loading_bg'>
                <img className='ajax-loader position-relative' src={loaderImage} width='100' height='100' />
            </div>}

        <div className={styles.header}>
            <span className={styles.title}>Projects</span>
        </div>
        {!!projects.length && renderProjects(projectsArrayToRender)}
        {!projects.length && 'No projects found'}

        {isShowAll &&
            <a data-testid='viewCompanyProjects-toggler' onClick={() => setIsShowAllVisible(false)}>Show all</a>}
    </div>
  );
};
