import FormCheckbox from '../../form-controls/form-checkbox';

export const CheckboxInputSection = () => {
  return (
    <div>
      <div className='row'>
        <div className='col-12'>
          <label className='form-label'>Checkbox Options</label>
        </div>
      </div>

      <div className='row'>
        <div className='col-6'>
          <FormCheckbox name='valueDefinition.defaultValue' label='Default Value' />
        </div>
      </div>
    </div>
  );
};
