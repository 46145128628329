type ProjectDealInformationProps = {
  customFields?: any;
};

export const ProjectDealInformation = ({
  customFields,
}: ProjectDealInformationProps) => {
   return (
    <>
      <b>Deal information</b>
      <hr />
      {customFields}
    </>
  );
};
