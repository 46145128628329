import * as actionTypes from './actionTypes.js';
import * as API from '../../api/Admin/notificationTypes.js';

//get all user details
export const getAllNotificationTypesRequest = () => ({
  type: actionTypes.GET_ALL_NOTIFICATION_TYPES_REQUEST,
});

export const getAllNotificationTypesSuccess = (response) => ({
  type: actionTypes.GET_ALL_NOTIFICATION_TYPES_SUCCESS,
  payload: {
    response,
  },
});

export const getAllNotificationTypesError = (error) => ({
  type: actionTypes.GET_ALL_NOTIFICATION_TYPES_ERROR,
  payload: {
    error,
  },
});

export const getAllNotificationTypesAction = (data) => {
  return (dispatch) => {
    dispatch(getAllNotificationTypesRequest());
    return API.getAllNotificationTypes(data)
      .then((response) => {
        dispatch(getAllNotificationTypesSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          getAllNotificationTypesError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

//get all user details
export const createRequest = () => ({
  type: actionTypes.CREATE_NOTIFICATION_TYPES_REQUEST,
});

export const createSuccess = (response) => ({
  type: actionTypes.CREATE_NOTIFICATION_TYPES_SUCCESS,
  payload: {
    response,
  },
});

export const createError = (error) => ({
  type: actionTypes.CREATE_NOTIFICATION_TYPES_ERROR,
  payload: {
    error,
  },
});

export const createNotificationTypeAction = (data) => {
  return (dispatch) => {
    dispatch(createRequest());
    return API.createNotificationTypes(data)
      .then((response) => {
        console.log(response);
        dispatch(createSuccess(response.data));
      })
      .catch((error) => {
        dispatch(createError(error.response.data.msg));
      });
  };
};
//Disable user details
export const disableNotificationTypesRequest = () => ({
  type: actionTypes.DISABLE_NOTIFICATION_TYPES_REQUEST,
});

export const disableNotificationTypesSuccess = (response) => ({
  type: actionTypes.DISABLE_NOTIFICATION_TYPES_SUCCESS,
  payload: {
    response,
  },
});
export const disableNotificationTypesError = (error) => ({
  type: actionTypes.DISABLE_NOTIFICATION_TYPES_ERROR,
  payload: {
    error,
  },
});

export const disableNotificationTypesAction = (data) => {
  return (dispatch) => {
    dispatch(disableNotificationTypesRequest());
    return API.disableNotificationTypes(data)
      .then((response) => {
        dispatch(disableNotificationTypesSuccess(response.data));
      })
      .catch((error) => {
        dispatch(disableNotificationTypesError(error.response.data.error.msg));
      });
  };
};
