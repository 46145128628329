import { notification } from 'antd';
import axios from 'axios';
import { appConstants } from '../_constants';

const apiClient = axios.create({
  baseURL: appConstants.WEB_SERVICE_URL_WITHOUT_PARSE,
});

// TODO: add an error handling

apiClient.interceptors.request.use(
  (config) => {
    config.headers.authorization = localStorage.getItem('JWTTOKEN');
    config.headers['X-Parse-Application-Id'] = process.env.REACT_APP_PARSE_APPLICATION_ID;
    config.headers['X-Parse-REST-API-Key'] = process.env.REACT_APP_PARSE_REST_API_KEY;
    config.headers['X-Parse-Session-Token'] = localStorage.getItem('sessionToken');

    if (!config.data) {
      config.data = {};
    }

    if (config.data instanceof FormData) {
      config.headers['Content-Type'] = 'multipart/form-data';
      config.headers['boundary'] = '-??-';
      config.headers['accountId'] = localStorage.getItem('accountId');
      config.headers['userId'] = localStorage.getItem('userId');
    }

    if (!config.data.accountId) config.data.accountId = localStorage.getItem('accountId');
    if (!config.data.userId) config.data.userId = localStorage.getItem('userId');

    return config;
  },
  (error) => Promise.reject(error)
);

apiClient.interceptors.response.use(
  (axiosResponse) => axiosResponse,
  (axiosError) => {
    const data = axiosError?.response?.data;

    if (data?.isApiError) {
      notification.error({ message: `${data?.message || 'Error!'}` });
    }

    return Promise.reject({ ...data });
  }
);

export default apiClient;
