import { Checkbox, Space } from 'antd';
import { Controller, useFormContext } from 'react-hook-form';

type IncludeProjectsControlProps = {
  options?: any[];
};

export const IncludeProjectsControl = ({ options = [] }: IncludeProjectsControlProps) => {
  const form = useFormContext();

  return (
    <div className='d-flex flex-column'>
      <span className='h6'>Projects to include:</span>

      <Controller
        name='jobStatusOption'
        control={form.control}
        render={({ field }) => (
          <Checkbox.Group value={field.value} onChange={field.onChange}>
            <Space direction='vertical' className='ms-3'>
              {options.map((option: any) => (
                <Checkbox
                  key={option.value}
                  value={option.value}
                  disabled={option.defaultChecked}
                  defaultChecked={option.defaultChecked}
                >
                  {option.label}
                </Checkbox>
              ))}
            </Space>
          </Checkbox.Group>
        )}
      />
    </div>
  );
};
