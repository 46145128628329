import moment from 'moment-timezone';
import { ReactElement, useEffect, useRef, useState, forwardRef } from 'react';
import DatePicker from 'react-datepicker';
import { Control, Controller, useFormContext } from 'react-hook-form';
import { InputMask } from '@react-input/mask';
import { ErrorBoundary } from '../../error-boundary';
import { DefaultLayout } from '../control-layouts/default-layout';
import { LayoutProps } from '../control-layouts/layout-props';
import { formatDate, formatDateWithTime } from '../../../utils/date-formatters'

type FormDatePickerProps = {
  control?: Control<any>;
  label: string;
  startDate?: Date | null | moment.Moment | undefined;
  endDate?: Date | null | moment.Moment | undefined;
  Layout?: (props: LayoutProps) => ReactElement;
  labelWidth?: number;
  controlWidth?: number;
  inputText?: string;
  children?: any;
  name: string;
  required?: boolean;
  disabled?: boolean;
  placeholderText?: string;
  selectsStart?: boolean;
  selectsEnd?: boolean;
  minDate?: Date | null | moment.Moment | undefined;
  maxDate?: Date | null | moment.Moment | undefined;
  showYearDropdown?: boolean;
  showMonthDropdown?: boolean;
  showTimeSelect?: boolean;
  dateFormat?: string;
  timeFormat?: string;
  timeCaption?: string;
  timeIntervals?: number;
  todayButton?: string;
  //customInput?: ReactElement;
  allowSameDay?: boolean;
};

const FormDatePicker = ({
  name,
  control,
  label,
  required,
  disabled,
  placeholderText = label,
  selectsStart,
  selectsEnd,
  startDate,
  endDate,
  minDate,
  maxDate,
  showYearDropdown,
  showMonthDropdown,
  showTimeSelect,
  dateFormat = 'MM/dd/yyyy',
  timeFormat = 'h:mm a',
  timeCaption = 'time',
  timeIntervals = 15,
  todayButton = 'Today',
  allowSameDay,
  inputText,
  labelWidth = 4,
  controlWidth = 8,
  children,
  Layout = DefaultLayout,
}: FormDatePickerProps) => {
  const formContext = useFormContext();
  const userTimeZone = moment.tz.guess();
  const ref = useRef<any>(null);

  const getDateFormat = () => {
    // Replace 'YYYY' with 'yyyy' to avoid the conflict with 'MM'
    const adjustedDateFormat = dateFormat.replace(/YYYY/g, 'yyyy').replace(/DD/g, 'dd');
    if (showTimeSelect && !adjustedDateFormat.includes(':')) {
      return `${adjustedDateFormat} ${timeFormat}`;
    }
    return adjustedDateFormat;
  };

  const [isCleared, setIsCleared] = useState(false);

  interface CustomInputProps {
    value?: string;
    onClick?: () => void;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    className?: string;
    onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  }

  const CustomInput = forwardRef<HTMLInputElement, CustomInputProps>(
    ({ value, onClick, onKeyDown,onChange, className }, inputRef) => (
      <InputMask
        className={className}
        mask={showTimeSelect ? "mm/dd/yyyy hh:mm _M" : "mm/dd/yyyy"}
        replacement={{
          _: /[AaPpM]/,  // AM/PM indicator
          m: /\d/,  // Minute
          d: /\d/,  // Day
          y: /\d/,  // Year
          ...(showTimeSelect ? { 
            h: /\d/,  // Hour
          } : {})
        }}
        showMask={!isCleared}
        separate
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            onClose();
            setIsCleared(false);
          }
         onKeyDown && onKeyDown(e);
        }}
        value={value}
        onClick={onClick}
        onChange={(e) => {
          
          e.preventDefault();
          onChange && onChange(e);
        }}

        ref={inputRef}
        disabled={disabled}
      />
    )
  );
  
  const watchDate = formContext.watch(name);

  const [tzLabel, setTzLabel] = useState<string>('');

  useEffect(
    () => {
      if(!watchDate || !showTimeSelect) 
        setTzLabel('');
      else
       setTzLabel((moment(watchDate)).zoneAbbr());
    },
    [watchDate, userTimeZone]
  );

  inputText = inputText ?? tzLabel


 
  function momentToDateOrUndefined(date: any) {
    if (!date) {
      return undefined;
    }
    if (date instanceof Date && !isNaN(date.getTime())) {
      return date;
    } else {
      return date ? moment(date).toDate() : undefined;
    }

  }

  const [startDateInternal, setStartDateInternal] = useState<Date | undefined>(momentToDateOrUndefined(startDate));
  const [endDateInternal, setEndDateInternal] = useState<Date | undefined>(momentToDateOrUndefined(endDate) );
  const [minDateInternal, setMinDateInternal] = useState<Date | undefined>(momentToDateOrUndefined(minDate));
  const [maxDateInternal, setMaxDateInternal] = useState<Date | undefined>(momentToDateOrUndefined(maxDate));

  useEffect(() => {
    if (startDate) {
      setStartDateInternal(momentToDateOrUndefined(startDate));
    }
  }, [startDate]);

  useEffect(() => {
    if (endDate) {
      setEndDateInternal(momentToDateOrUndefined(endDate));
    }
  }, [endDate]);

  useEffect(() => {
    if (minDate) {
      setMinDateInternal(momentToDateOrUndefined(minDate));
    }
  }, [minDate]);

  useEffect(() => {
    if (maxDate) {
      setMaxDateInternal(momentToDateOrUndefined(maxDate));
    }
  }, [maxDate]);



  if (!control && !formContext) {
    throw new Error('FormDatePicker must be used within a FormContext or have a control prop');
  }

  const onChange = (date: Date | null, field: any) => {
    if (date && !isOutOfBounds(date)) {
      field.onChange(moment(date).tz(userTimeZone));
      setIsCleared(false);
    } else {
      field.onChange(null);
      setIsCleared(true);
    }
  };

  const validateSelected = (field: any) => {
    const date = momentToDateOrUndefined(field.value);

    if (date) {
      if (!isOutOfBounds(date)) {
        return date;
      }

      if (minDateInternal && date < minDateInternal) {
        field.onChange(minDate);
        return minDateInternal;
      }

      if (maxDateInternal && date > maxDateInternal) {
        field.onChange(maxDateInternal);
        return maxDateInternal;
      }
    }

    return null;
  }

  const isOutOfBounds = (date: Date) => {
    return (minDateInternal && date<minDateInternal || (maxDateInternal && date>maxDateInternal));
  };

  const onClose = () => ref.current?.setOpen(false);

  const Children = children;

  const getTextValue = (field: any) => {
    const value = validateSelected(field);

    if (value) {
      return showTimeSelect ? formatDateWithTime(value) : formatDate(value);
    }
  }

  return (
    <Controller
      rules={{ required: required && `${label} is required` }}
      name={name}
      control={control || formContext.control}
      render={({ field, fieldState }) => {
        useEffect(() => {
          if (field.value === null || field.value === undefined) {
            setIsCleared(true);
          } else {
            setIsCleared(false);
          }
        }, [field.value]);

        return (
          <Layout
            label={label}
            required={required}
            error={fieldState.error?.message}
            labelWidth={labelWidth}
            controlWidth={controlWidth}
            inputText={inputText}
            textValue={getTextValue(field)}
            input={
              <ErrorBoundary>
                <DatePicker
                  ref={ref}
                  className='form-control'
                  id={field.name}
                  name={field.name}
                  selectsEnd={selectsEnd}
                  selectsStart={selectsStart}
                  selected={validateSelected(field)}
                  startDate={startDateInternal}
                  endDate={endDateInternal}
                  onChange={(date) => onChange(date, field)}
                  customInput={<CustomInput />}
                  allowSameDay={allowSameDay}
                  dateFormat={getDateFormat()}
                  placeholderText={placeholderText}
                  minDate={minDateInternal}
                  maxDate={maxDateInternal}
                  showYearDropdown={showYearDropdown}
                  showMonthDropdown={showMonthDropdown}
                  showTimeSelect={showTimeSelect}
                  timeFormat={timeFormat}
                  timeCaption={timeCaption}
                  timeIntervals={timeIntervals}
                  dropdownMode='select'
                  todayButton={todayButton}
                  autoComplete="off"
                  disabled={disabled}
                  isClearable={!required&&!disabled}
                  popperPlacement={showTimeSelect ? 'bottom-start' : 'bottom'}
                >
                  {children ? <Children name={field.name} onClose={onClose} /> : null}
                </DatePicker>
              </ErrorBoundary>
            }
          />
        );
      }}
    />
  );
};

export default FormDatePicker;
