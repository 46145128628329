import FormSelect from '../../form-controls/form-select/form-select';
import { useFormContext } from 'react-hook-form';
import FormCheckbox from '../../form-controls/form-checkbox';
import FormInput from '../../form-controls/form-input';
import { FormColorPicker } from '../../form-controls/form-color-picker/form-color-picker';
import { HeartFilledIcon } from '../../svg/heart-filled-icon';
import { StarFilledIcon } from '../../svg/star-filled-icon';
import { useCallback } from 'react';
import { FormRate } from '../../form-controls/form-rate';
import { aggregationOptions } from '../constants';

const countOptions = [
  {
    value: 3,
    label: '3',
  },
  {
    value: 4,
    label: '4',
  },
  {
    value: 5,
    label: '5',
  },
  {
    value: 6,
    label: '6',
  },
  {
    value: 7,
    label: '7',
  },
  {
    value: 8,
    label: '8',
  },
  {
    value: 9,
    label: '9',
  },
  {
    value: 10,
    label: '10',
  },
  {
    value: 11,
    label: '11',
  },
];

const iconOptions = [
  {
    value: 'star-filled',
    label: (
      <>
        <StarFilledIcon width={24} /> Star
      </>
    ),
    component: ({ color }) => <StarFilledIcon color={color} />,
  },
  {
    value: 'heart-filled',
    label: (
      <>
        <HeartFilledIcon width={24} /> Heart
      </>
    ),
    component: ({ color }) => <HeartFilledIcon color={color} />,
  },
  {
    value: 'character',
    label: 'Custom',
    component: ({ color }) => <HeartFilledIcon color={color} />,
  },
];

export const RateInputSection = () => {
  const form = useFormContext();

  const count = form.watch('valueDefinition.count');
  const allowHalf = form.watch('valueDefinition.allowHalf');
  const char = form.watch('valueDefinition.character');
  const color = form.watch('valueDefinition.color');
  const icon = form.watch('valueDefinition.icon');

  const getIconComponent = (icon: string) => {
    const iconOption = iconOptions.find((o) => o.value === icon);

    if (!iconOption) return () => null;

    return iconOption.component;
  };

  const getRateCharacter = useCallback(() => {
    switch (icon) {
      case 'star-filled':
        return getIconComponent(icon)({
          color: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`,
        });
      case 'heart-filled':
        return getIconComponent(icon)({
          color: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`,
        });
      case 'character':
        return (
          <span style={{ color: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})` }}>
            <strong>{char?.length > 0 ? char : '\u2605'}</strong>
          </span>
        );
      default:
        return null;
    }
  }, [icon, char, color]);

  return (
    <div>
      <div className='row'>
        <div className='col-12'>
          <label className='form-label'>Rating Options</label>
          <div className='row'>
            <div className='col-6'>
              <FormSelect
                options={aggregationOptions}
                label='Summary Operator'
                name='valueDefinition.aggregation'
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-4'>
              <FormSelect label='Scale' name='valueDefinition.count' options={countOptions} />
            </div>
            <div className='col-4'>
              <FormSelect name='valueDefinition.icon' label='Icon' options={iconOptions} />
            </div>

            {icon === 'character' && (
              <div className='col-4'>
                <FormInput name='valueDefinition.character' label='Character' />
              </div>
            )}
          </div>

          <div className='row'>
            <div className='col-2 mt-2'>
              <FormColorPicker
                name='valueDefinition.color'
                label='Color'
                className='flex-row-reverse justify-content-end'
              />
            </div>
            <div className='col-4'>
              <FormCheckbox
                className='flex-row-reverse mb-2 mt-2'
                name='valueDefinition.allowHalf'
                label='Allow "Half-Star" Ratings'
              />
            </div>
          </div>

          <div className='row'>
            <div className='col-12'>
              <FormRate
                name='valueDefinition.defaultValue'
                label='Default Value'
                character={getRateCharacter()}
                allowHalf={allowHalf}
                count={count}
              />
            </div>
          </div>
        </div>
      </div>

      <div className='col-6'></div>
    </div>
  );
};
