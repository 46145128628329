import * as actionTypes from './actionTypes';
import * as API from '../api/cppRole';

export const createCppRoleRequest = () => ({
  type: actionTypes.CREATE_CPOST_ROLE_REQUEST,
});

export const createCppRoleSuccess = (response) => ({
  type: actionTypes.CREATE_CPOST_ROLE_SUCCESS,
  payload: {
    response,
  },
});

export const createCppRoleError = (error) => ({
  type: actionTypes.CREATE_CPOST_ROLE_ERROR,
  payload: {
    error,
  },
});

export const createCppRoleAction = (data) => {
  return (dispatch) => {
    dispatch(createCppRoleRequest());
    return API.createCppRole(data)
      .then((response) => {
        dispatch(createCppRoleSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          createCppRoleError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

export const getAllCppRoleRequest = () => ({
  type: actionTypes.GET_ALL_CPOST_ROLE_REQUEST,
});

export const getAllCppRoleSuccess = (response) => ({
  type: actionTypes.GET_ALL_CPOST_ROLE_SUCCESS,
  payload: {
    response,
  },
});

export const getAllCppRoleError = (error) => ({
  type: actionTypes.GET_ALL_CPOST_ROLE_ERROR,
  payload: {
    error,
  },
});

export const getAllCppRoleAction = (data) => {
  return (dispatch) => {
    dispatch(getAllCppRoleRequest());
    return API.getAllCppRole(data)
      .then((response) => {
        dispatch(getAllCppRoleSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          getAllCppRoleError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

export const updateCppRoleRequest = () => ({
  type: actionTypes.UPDATE_CPOST_ROLE_REQUEST,
});

export const updateCppRoleSuccess = (response) => ({
  type: actionTypes.UPDATE_CPOST_ROLE_SUCCESS,
  payload: {
    response,
  },
});

export const updateCppRoleError = (error) => ({
  type: actionTypes.UPDATE_CPOST_ROLE_ERROR,
  payload: {
    error,
  },
});

export const updateCppRoleAction = (data) => {
  return (dispatch) => {
    dispatch(updateCppRoleRequest());
    return API.updateCppRole(data)
      .then((response) => {
        dispatch(updateCppRoleSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          updateCppRoleError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

export const permissionStatusRequest = () => ({
  type: actionTypes.GET_PERMISSION_STATUS_REQUEST,
});

export const permissionStatusSuccess = (response) => ({
  type: actionTypes.GET_PERMISSION_STATUS_SUCCESS,
  payload: {
    response,
  },
});

export const permissionStatusError = (error) => ({
  type: actionTypes.GET_PERMISSION_STATUS_ERROR,
  payload: {
    error,
  },
});

export const permissionStatusAction = (data) => {
  return (dispatch) => {
    dispatch(permissionStatusRequest());
    return API.checkPermissionStatus(data)
      .then((response) => {
        dispatch(permissionStatusSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          permissionStatusError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

// export const deleteCppRoleRequest = () => ({
//     type: actionTypes.DELETE_CPOST_ROLE_REQUEST
// })

// export const deleteCppRoleSuccess = (response) => ({
//     type: actionTypes.DELETE_CPOST_ROLE_SUCCESS,
//     payload: {
//         response,
//     }
// })

// export const deleteCppRoleError = (error) => ({
//     type: actionTypes.DELETE_CPOST_ROLE_ERROR,
//     payload: {
//         error
//     }
// })

// export const deleteCppRoleAction = (data) => {
//     return dispatch => {
//         dispatch(deleteCppRoleRequest())
//         return API.deleteCppRole(data)
//             .then(response => {
//                 dispatch(deleteCppRoleSuccess(response.data))
//             })
//             .catch(error => {
//                 dispatch(deleteCppRoleError(error.response !== undefined ? error.response.data.error : "Internet Connection Error"))
//             })
//     }
// }
