import { useMemo } from 'react';

const useCountryCodes = () => {
  const codes = useMemo(
    () => [
      {
        name: 'United States',
        code: '1',
        iso: 'US',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/us.svg',
        mask: '(000) 000-0000',
        fullMask: '0 (000) 000-0000',
      },
      {
        name: 'Afghanistan',
        code: '93',
        iso: 'AF',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/af.svg',
        mask: '00-000-0000',
        fullMask: '00 00-000-0000',
      },
      {
        name: 'Aland Islands',
        code: '358',
        iso: 'AX',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/ax.svg',
        mask: '(000) 000-00-00',
        fullMask: '000 (000) 000-00-00',
      },
      {
        name: 'Albania',
        code: '355',
        iso: 'AL',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/al.svg',
        mask: '(000) 000-000',
        fullMask: '000 (000) 000-000',
      },
      {
        name: 'Algeria',
        code: '213',
        iso: 'DZ',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/dz.svg',
        mask: '00-000-0000',
        fullMask: '000 00-000-0000',
      },
      {
        name: 'American Samoa',
        code: '1684',
        iso: 'AS',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/as.svg',
        mask: '000-0000',
        fullMask: '0(000) 000-0000',
      },
      {
        name: 'Andorra',
        code: '376',
        iso: 'AD',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/ad.svg',
        mask: '000-000',
        fullMask: '000 000-000',
      },
      {
        name: 'Angola',
        code: '244',
        iso: 'AO',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/ao.svg',
        mask: '(000) 000-000',
        fullMask: '000 (000) 000-000',
      },
      {
        name: 'Anguilla',
        code: '1264',
        iso: 'AI',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/ai.svg',
        mask: '000-0000',
        fullMask: '0(000) 000-0000',
      },
      {
        name: 'Antarctica',
        code: '672',
        iso: 'AQ',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/aq.svg',
        mask: '100-000',
        fullMask: '000 100-000',
      },
      {
        name: 'Antigua and Barbuda',
        code: '1268',
        iso: 'AG',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/ag.svg',
        mask: '000-0000',
        fullMask: '0(000) 000-0000',
      },
      {
        name: 'Argentina',
        code: '54',
        iso: 'AR',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/ar.svg',
        mask: '(000) 000-0000',
        fullMask: '00 (000) 000-0000',
      },
      {
        name: 'Armenia',
        code: '374',
        iso: 'AM',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/am.svg',
        mask: '00-000-000',
        fullMask: '000 00-000-000',
      },
      {
        name: 'Aruba',
        code: '297',
        iso: 'AW',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/aw.svg',
        mask: '000-0000',
        fullMask: '000 000-0000',
      },
      {
        name: 'Ascension Island',
        code: '247',
        iso: 'AC',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/sh.svg',
        mask: '0000',
        fullMask: '000 0000',
      },
      {
        name: 'Australia',
        code: '61',
        iso: 'AU',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/au.svg',
        mask: '0-0000-0000',
        fullMask: '00 0-0000-0000',
      },
      {
        name: 'Austria',
        code: '43',
        iso: 'AT',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/at.svg',
        mask: '(000) 000-0000',
        fullMask: '00 (000) 000-0000',
      },
      {
        name: 'Azerbaijan',
        code: '994',
        iso: 'AZ',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/az.svg',
        mask: '00-000-00-00',
        fullMask: '000 00-000-00-00',
      },
      {
        name: 'Bahamas',
        code: '1242',
        iso: 'BS',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/bs.svg',
        mask: '000-0000',
        fullMask: '0(000) 000-0000',
      },
      {
        name: 'Bahrain',
        code: '973',
        iso: 'BH',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/bh.svg',
        mask: '0000-0000',
        fullMask: '000 0000-0000',
      },
      {
        name: 'Bangladesh',
        code: '880',
        iso: 'BD',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/bd.svg',
        mask: '1000-000000',
        fullMask: '000 1000-000000',
      },
      {
        name: 'Barbados',
        code: '1246',
        iso: 'BB',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/bb.svg',
        mask: '000-0000',
        fullMask: '0(000) 000-0000',
      },
      {
        name: 'Belarus',
        code: '375',
        iso: 'BY',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/by.svg',
        mask: '(00) 000-00-00',
        fullMask: '000 (00) 000-00-00',
      },
      {
        name: 'Belgium',
        code: '32',
        iso: 'BE',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/be.svg',
        mask: '(000) 000-000',
        fullMask: '00 (000) 000-000',
      },
      {
        name: 'Belize',
        code: '501',
        iso: 'BZ',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/bz.svg',
        mask: '000-0000',
        fullMask: '000 000-0000',
      },
      {
        name: 'Benin',
        code: '229',
        iso: 'BJ',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/bj.svg',
        mask: '00-00-0000',
        fullMask: '000 00-00-0000',
      },
      {
        name: 'Bermuda',
        code: '1441',
        iso: 'BM',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/bm.svg',
        mask: '000-0000',
        fullMask: '0(000) 000-0000',
      },
      {
        name: 'Bhutan',
        code: '975',
        iso: 'BT',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/bt.svg',
        fullMask: '000 000000000000',
        mask: '000000000000',
      },
      {
        name: 'Bolivia',
        code: '591',
        iso: 'BO',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/bo.svg',
        mask: '0-000-0000',
        fullMask: '000 0-000-0000',
      },
      {
        name: 'Bosnia and Herzegovina',
        code: '387',
        iso: 'BA',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/ba.svg',
        mask: '00-00000',
        fullMask: '000 00-00000',
      },
      {
        name: 'Botswana',
        code: '267',
        iso: 'BW',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/bw.svg',
        mask: '00-000-000',
        fullMask: '000 00-000-000',
      },
      {
        name: 'Brazil',
        code: '55',
        iso: 'BR',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/br.svg',
        mask: '(00) 00000-0000',
        fullMask: '00 (00) 00000-0000',
      },
      {
        name: 'British Indian Ocean Territory',
        code: '246',
        iso: 'IO',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/io.svg',
        mask: '000-0000',
        fullMask: '000 000-0000',
      },
      {
        name: 'Brunei Darussalam',
        code: '673',
        iso: 'BN',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/bn.svg',
        mask: '000-0000',
        fullMask: '000 000-0000',
      },
      {
        name: 'Bulgaria',
        code: '359',
        iso: 'BG',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/bg.svg',
        mask: '(000) 000-000',
        fullMask: '000 (000) 000-000',
      },
      {
        name: 'Burkina Faso',
        code: '226',
        iso: 'BF',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/bf.svg',
        mask: '00-00-0000',
        fullMask: '000 00-00-0000',
      },
      {
        name: 'Burundi',
        code: '257',
        iso: 'BI',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/bi.svg',
        mask: '00-00-0000',
        fullMask: '000 00-00-0000',
      },
      {
        name: 'Cambodia',
        code: '855',
        iso: 'KH',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/kh.svg',
        mask: '00-000-000',
        fullMask: '000 00-000-000',
      },
      {
        name: 'Cameroon',
        code: '237',
        iso: 'CM',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/cm.svg',
        mask: '0000-0000',
        fullMask: '000 0000-0000',
      },
      {
        name: 'Canada',
        code: '1',
        iso: 'CA',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/ca.svg',
        mask: '(000) 000-0000',
        fullMask: '0 (000) 000-0000',
      },
      {
        name: 'Cape Verde',
        code: '238',
        iso: 'CV',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/cv.svg',
        mask: '(000) 00-00',
        fullMask: '000 (000) 00-00',
      },
      {
        name: 'Cayman Islands',
        code: '1345',
        iso: 'KY',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/ky.svg',
        mask: '000-0000',
        fullMask: '0(000) 000-0000',
      },
      {
        name: 'Central African Republic',
        code: '236',
        iso: 'CF',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/cf.svg',
        mask: '00-00-0000',
        fullMask: '000 00-00-0000',
      },
      {
        name: 'Chad',
        code: '235',
        iso: 'TD',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/td.svg',
        mask: '00-00-00-00',
        fullMask: '000 00-00-00-00',
      },
      {
        name: 'Chile',
        code: '56',
        iso: 'CL',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/cl.svg',
        mask: '0-0000-0000',
        fullMask: '00 0-0000-0000',
      },
      {
        name: 'China',
        code: '86',
        iso: 'CN',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/cn.svg',
        fullMask: '00 000000000000',
        mask: '000000000000',
      },
      {
        name: 'Christmas Island',
        code: '61',
        iso: 'CX',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/cx.svg',
        mask: '0-0000-0000',
        fullMask: '00 0-0000-0000',
      },
      {
        name: 'Cocos (Keeling) Islands',
        code: '61',
        iso: 'CC',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/cc.svg',
        mask: '0-0000-0000',
        fullMask: '00 0-0000-0000',
      },
      {
        name: 'Colombia',
        code: '57',
        iso: 'CO',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/co.svg',
        mask: '(000) 000-0000',
        fullMask: '00 (000) 000-0000',
      },
      {
        name: 'Comoros',
        code: '269',
        iso: 'KM',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/km.svg',
        mask: '00-00000',
        fullMask: '000 00-00000',
      },
      {
        name: 'Congo',
        code: '242',
        iso: 'CG',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/cg.svg',
        mask: '00-00000',
        fullMask: '000 00-00000',
      },
      {
        name: 'Cook Islands',
        code: '682',
        iso: 'CK',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/ck.svg',
        mask: '00-000',
        fullMask: '000 00-000',
      },
      {
        name: 'Costa Rica',
        code: '506',
        iso: 'CR',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/cr.svg',
        mask: '0000-0000',
        fullMask: '000 0000-0000',
      },
      {
        name: 'Croatia',
        code: '385',
        iso: 'HR',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/hr.svg',
        mask: '00-000-000',
        fullMask: '000 00-000-000',
      },
      {
        name: 'Cuba',
        code: '53',
        iso: 'CU',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/cu.svg',
        mask: '0-000-0000',
        fullMask: '00 0-000-0000',
      },
      {
        name: 'Cyprus',
        code: '357',
        iso: 'CY',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/cy.svg',
        mask: '00-000-000',
        fullMask: '000 00-000-000',
      },
      {
        name: 'Czech Republic',
        code: '420',
        iso: 'CZ',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/cz.svg',
        mask: '(000) 000-000',
        fullMask: '000 (000) 000-000',
      },
      {
        name: 'Democratic Republic of the Congo',
        code: '243',
        iso: 'CD',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/cd.svg',
        mask: '(000) 000-000',
        fullMask: '000 (000) 000-000',
      },
      {
        name: 'Denmark',
        code: '45',
        iso: 'DK',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/dk.svg',
        mask: '00-00-00-00',
        fullMask: '00 00-00-00-00',
      },
      {
        name: 'Djibouti',
        code: '253',
        iso: 'DJ',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/dj.svg',
        mask: '00-00-00-00',
        fullMask: '000 00-00-00-00',
      },
      {
        name: 'Dominica',
        code: '1767',
        iso: 'DM',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/dm.svg',
        mask: '000-0000',
        fullMask: '0(000) 000-0000',
      },
      {
        name: 'Dominican Republic',
        code: '1',
        iso: 'DO',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/do.svg',
        mask: '(000) 000-0000',
        fullMask: '0 (000) 000-0000',
      },
      {
        name: 'Ecuador',
        code: '593',
        iso: 'EC',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/ec.svg',
        fullMask: '000 000000000000',
        mask: '000000000000',
      },
      {
        name: 'Egypt',
        code: '20',
        iso: 'EG',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/eg.svg',
        mask: '(000) 000-0000',
        fullMask: '00 (000) 000-0000',
      },
      {
        name: 'El Salvador',
        code: '503',
        iso: 'SV',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/sv.svg',
        mask: '00-00-0000',
        fullMask: '000 00-00-0000',
      },
      {
        name: 'Equatorial Guinea',
        code: '240',
        iso: 'GQ',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/gq.svg',
        mask: '00-000-0000',
        fullMask: '000 00-000-0000',
      },
      {
        name: 'Eritrea',
        code: '291',
        iso: 'ER',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/er.svg',
        mask: '0-000-000',
        fullMask: '000 0-000-000',
      },
      {
        name: 'Estonia',
        code: '372',
        iso: 'EE',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/ee.svg',
        mask: '0000-0000',
        fullMask: '000 0000-0000',
      },
      {
        name: 'Eswatini',
        code: '268',
        iso: 'SZ',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/sz.svg',
        mask: '00-00-0000',
        fullMask: '000 00-00-0000',
      },
      {
        name: 'Ethiopia',
        code: '251',
        iso: 'ET',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/et.svg',
        mask: '00-000-0000',
        fullMask: '000 00-000-0000',
      },
      {
        name: 'Falkland Islands (Malvinas)',
        code: '500',
        iso: 'FK',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/fk.svg',
        mask: '00000',
        fullMask: '000 00000',
      },
      {
        name: 'Faroe Islands',
        code: '298',
        iso: 'FO',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/fo.svg',
        mask: '000-000',
        fullMask: '000 000-000',
      },
      {
        name: 'Fiji',
        code: '679',
        iso: 'FJ',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/fj.svg',
        mask: '00-00000',
        fullMask: '000 00-00000',
      },
      {
        name: 'Finland',
        code: '358',
        iso: 'FI',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/fi.svg',
        mask: '(000) 000-00-00',
        fullMask: '000 (000) 000-00-00',
      },
      {
        name: 'France',
        code: '33',
        iso: 'FR',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/fr.svg',
        mask: '(000) 000-000',
        fullMask: '00 (000) 000-000',
      },
      {
        name: 'French Guiana',
        code: '594',
        iso: 'GF',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/gf.svg',
        mask: '00000-0000',
        fullMask: '000 00000-0000',
      },
      {
        name: 'French Polynesia',
        code: '689',
        iso: 'PF',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/pf.svg',
        mask: '00-00-00',
        fullMask: '000 00-00-00',
      },
      {
        name: 'Gabon',
        code: '241',
        iso: 'GA',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/ga.svg',
        mask: '0-00-00-00',
        fullMask: '000 0-00-00-00',
      },
      {
        name: 'Gambia',
        code: '220',
        iso: 'GM',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/gm.svg',
        mask: '(000) 00-00',
        fullMask: '000 (000) 00-00',
      },
      {
        name: 'Georgia',
        code: '995',
        iso: 'GE',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/ge.svg',
        mask: '(000) 000-000',
        fullMask: '000 (000) 000-000',
      },
      {
        name: 'Germany',
        code: '49',
        iso: 'DE',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/de.svg',
        fullMask: '00 000000000000',
        mask: '000000000000',
      },
      {
        name: 'Ghana',
        code: '233',
        iso: 'GH',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/gh.svg',
        mask: '(000) 000-000',
        fullMask: '000 (000) 000-000',
      },
      {
        name: 'Gibraltar',
        code: '350',
        iso: 'GI',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/gi.svg',
        mask: '000-00000',
        fullMask: '000 000-00000',
      },
      {
        name: 'Greece',
        code: '30',
        iso: 'GR',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/gr.svg',
        mask: '(000) 000-0000',
        fullMask: '00 (000) 000-0000',
      },
      {
        name: 'Greenland',
        code: '299',
        iso: 'GL',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/gl.svg',
        mask: '00-00-00',
        fullMask: '000 00-00-00',
      },
      {
        name: 'Grenada',
        code: '1473',
        iso: 'GD',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/gd.svg',
        mask: '000-0000',
        fullMask: '0(000) 000-0000',
      },
      {
        name: 'Guadeloupe',
        code: '590',
        iso: 'GP',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/gp.svg',
        mask: '(000) 000-000',
        fullMask: '000 (000) 000-000',
      },
      {
        name: 'Guam',
        code: '1671',
        iso: 'GU',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/gu.svg',
        mask: '000-0000',
        fullMask: '0(000) 000-0000',
      },
      {
        name: 'Guatemala',
        code: '502',
        iso: 'GT',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/gt.svg',
        mask: '0-000-0000',
        fullMask: '000 0-000-0000',
      },
      {
        name: 'Guernsey',
        code: '44',
        iso: 'GG',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/gg.svg',
        mask: '(0000) 000000',
        fullMask: '00 (0000) 000000',
      },
      {
        name: 'Guinea',
        code: '224',
        iso: 'GN',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/gn.svg',
        mask: '00-000-000',
        fullMask: '000 00-000-000',
      },
      {
        name: 'Guinea-Bissau',
        code: '245',
        iso: 'GW',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/gw.svg',
        mask: '0-000000',
        fullMask: '000 0-000000',
      },
      {
        name: 'Guyana',
        code: '592',
        iso: 'GY',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/gy.svg',
        mask: '000-0000',
        fullMask: '000 000-0000',
      },
      {
        name: 'Haiti',
        code: '509',
        iso: 'HT',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/ht.svg',
        mask: '00-00-0000',
        fullMask: '000 00-00-0000',
      },
      {
        name: 'Holy See (Vatican City State)',
        code: '39',
        iso: 'VA',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/va.svg',
        mask: '06 69800000',
        fullMask: '00 06 69800000',
      },
      {
        name: 'Honduras',
        code: '504',
        iso: 'HN',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/hn.svg',
        mask: '0000-0000',
        fullMask: '000 0000-0000',
      },
      {
        name: 'Hong Kong',
        code: '852',
        iso: 'HK',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/hk.svg',
        mask: '0000-0000',
        fullMask: '000 0000-0000',
      },
      {
        name: 'Hungary',
        code: '36',
        iso: 'HU',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/hu.svg',
        mask: '(000) 000-000',
        fullMask: '00 (000) 000-000',
      },
      {
        name: 'Iceland',
        code: '354',
        iso: 'IS',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/is.svg',
        mask: '000-0000',
        fullMask: '000 000-0000',
      },
      {
        name: 'India',
        code: '91',
        iso: 'IN',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/in.svg',
        mask: '(0000) 000-000',
        fullMask: '00 (0000) 000-000',
      },
      {
        name: 'Indonesia',
        code: '62',
        iso: 'ID',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/id.svg',
        fullMask: '00 000000000000',
        mask: '000000000000',
      },
      {
        name: 'Iran',
        code: '98',
        iso: 'IR',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/ir.svg',
        mask: '(000) 000-0000',
        fullMask: '00 (000) 000-0000',
      },
      {
        name: 'Iraq',
        code: '924',
        iso: 'IQ',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/iq.svg',
        mask: '(000) 000-0000',
        fullMask: '000 (000) 000-0000',
      },
      {
        name: 'Ireland',
        code: '353',
        iso: 'IE',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/ie.svg',
        mask: '(000) 000-000',
        fullMask: '000 (000) 000-000',
      },
      {
        name: 'Isle of Man',
        code: '44',
        iso: 'IM',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/im.svg',
        mask: '(0000) 000000',
        fullMask: '00 (0000) 000000',
      },
      {
        name: 'Israel',
        code: '972',
        iso: 'IL',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/il.svg',
        fullMask: '000 000000000000',
        mask: '000000000000',
      },
      {
        name: 'Italy',
        code: '39',
        iso: 'IT',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/it.svg',
        mask: '(000) 0000-000',
        fullMask: '00 (000) 0000-000',
      },
      {
        name: "Ivory Coast / Cote d'Ivoire",
        code: '225',
        iso: 'CI',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/ci.svg',
        mask: '00-000-000',
        fullMask: '000 00-000-000',
      },
      {
        name: 'Jamaica',
        code: '1876',
        iso: 'JM',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/jm.svg',
        mask: '000-0000',
        fullMask: '0(000) 000-0000',
      },
      {
        name: 'Japan',
        code: '81',
        iso: 'JP',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/jp.svg',
        fullMask: '00 000000000000',
        mask: '000000000000',
      },
      {
        name: 'Jersey',
        code: '44',
        iso: 'JE',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/je.svg',
        mask: '(0000) 0000-000000',
        fullMask: '00 (0000) 0000-000000',
      },
      {
        name: 'Jordan',
        code: '962',
        iso: 'JO',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/jo.svg',
        mask: '0-0000-0000',
        fullMask: '000 0-0000-0000',
      },
      {
        name: 'Kazakhstan',
        code: '77',
        iso: 'KZ',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/kz.svg',
        fullMask: '00 000000000000',
        mask: '000000000000',
      },
      {
        name: 'Kenya',
        code: '254',
        iso: 'KE',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/ke.svg',
        mask: '000-000000',
        fullMask: '000 000-000000',
      },
      {
        name: 'Kiribati',
        code: '686',
        iso: 'KI',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/ki.svg',
        mask: '00-000',
        fullMask: '000 00-000',
      },
      {
        name: "Korea, Democratic People's Republic of Korea",
        code: '850',
        iso: 'KP',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/kp.svg',
        fullMask: '000 000000000000',
        mask: '000000000000',
      },
      {
        name: 'Korea, Republic of South Korea',
        code: '82',
        iso: 'KR',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/kr.svg',
        mask: '00-000-0000',
        fullMask: '00 00-000-0000',
      },
      {
        name: 'Kosovo',
        code: '383',
        iso: 'XK',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/xk.svg',
        fullMask: '000 000000000000',
        mask: '000000000000',
      },
      {
        name: 'Kuwait',
        code: '965',
        iso: 'KW',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/kw.svg',
        mask: '0000-0000',
        fullMask: '000 0000-0000',
      },
      {
        name: 'Kyrgyzstan',
        code: '996',
        iso: 'KG',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/kg.svg',
        mask: '(000) 000-000',
        fullMask: '000 (000) 000-000',
      },
      {
        name: 'Laos',
        code: '856',
        iso: 'LA',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/la.svg',
        fullMask: '000 000000000000',
        mask: '000000000000',
      },
      {
        name: 'Latvia',
        code: '371',
        iso: 'LV',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/lv.svg',
        mask: '00-000-000',
        fullMask: '000 00-000-000',
      },
      {
        name: 'Lebanon',
        code: '961',
        iso: 'LB',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/lb.svg',
        fullMask: '000 000000000000',
        mask: '000000000000',
      },
      {
        name: 'Lesotho',
        code: '266',
        iso: 'LS',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/ls.svg',
        mask: '0-000-0000',
        fullMask: '000 0-000-0000',
      },
      {
        name: 'Liberia',
        code: '231',
        iso: 'LR',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/lr.svg',
        mask: '00-000-000',
        fullMask: '000 00-000-000',
      },
      {
        name: 'Libya',
        code: '218',
        iso: 'LY',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/ly.svg',
        fullMask: '000 000000000000',
        mask: '000000000000',
      },
      {
        name: 'Liechtenstein',
        code: '423',
        iso: 'LI',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/li.svg',
        mask: '(000) 000-0000',
        fullMask: '000 (000) 000-0000',
      },
      {
        name: 'Lithuania',
        code: '370',
        iso: 'LT',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/lt.svg',
        mask: '(000) 00-000',
        fullMask: '000 (000) 00-000',
      },
      {
        name: 'Luxembourg',
        code: '352',
        iso: 'LU',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/lu.svg',
        mask: '(000) 000-000',
        fullMask: '000 (000) 000-000',
      },
      {
        name: 'Macau',
        code: '853',
        iso: 'MO',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/mo.svg',
        mask: '0000-0000',
        fullMask: '000 0000-0000',
      },
      {
        name: 'Madagascar',
        code: '261',
        iso: 'MG',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/mg.svg',
        mask: '00-00-00000',
        fullMask: '000 00-00-00000',
      },
      {
        name: 'Malawi',
        code: '265',
        iso: 'MW',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/mw.svg',
        fullMask: '000 000000000000',
        mask: '000000000000',
      },
      {
        name: 'Malaysia',
        code: '60',
        iso: 'MY',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/my.svg',
        fullMask: '00 000000000000',
        mask: '000000000000',
      },
      {
        name: 'Maldives',
        code: '960',
        iso: 'MV',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/mv.svg',
        mask: '000-0000',
        fullMask: '000 000-0000',
      },
      {
        name: 'Mali',
        code: '223',
        iso: 'ML',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/ml.svg',
        mask: '00-00-0000',
        fullMask: '000 00-00-0000',
      },
      {
        name: 'Malta',
        code: '356',
        iso: 'MT',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/mt.svg',
        mask: '0000-0000',
        fullMask: '000 0000-0000',
      },
      {
        name: 'Marshall Islands',
        code: '692',
        iso: 'MH',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/mh.svg',
        mask: '000-0000',
        fullMask: '000 000-0000',
      },
      {
        name: 'Martinique',
        code: '596',
        iso: 'MQ',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/mq.svg',
        mask: '(000) 00-00-00',
        fullMask: '000 (000) 00-00-00',
      },
      {
        name: 'Mauritania',
        code: '222',
        iso: 'MR',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/mr.svg',
        mask: '00-00-0000',
        fullMask: '000 00-00-0000',
      },
      {
        name: 'Mauritius',
        code: '230',
        iso: 'MU',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/mu.svg',
        mask: '000-0000',
        fullMask: '000 000-0000',
      },
      {
        name: 'Mayotte',
        code: '262',
        iso: 'YT',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/yt.svg',
        mask: '00000-0000',
        fullMask: '000 00000-0000',
      },
      {
        name: 'Mexico',
        code: '52',
        iso: 'MX',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/mx.svg',
        fullMask: '00 000000000000',
        mask: '000000000000',
      },
      {
        name: 'Micronesia, Federated States of Micronesia',
        code: '691',
        iso: 'FM',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/fm.svg',
        mask: '000-0000',
        fullMask: '000 000-0000',
      },
      {
        name: 'Moldova',
        code: '373',
        iso: 'MD',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/md.svg',
        mask: '0000-0000',
        fullMask: '000 0000-0000',
      },
      {
        name: 'Monaco',
        code: '377',
        iso: 'MC',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/mc.svg',
        fullMask: '000 000000000000',
        mask: '000000000000',
      },
      {
        name: 'Mongolia',
        code: '976',
        iso: 'MN',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/mn.svg',
        mask: '00-00-0000',
        fullMask: '000 00-00-0000',
      },
      {
        name: 'Montenegro',
        code: '382',
        iso: 'ME',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/me.svg',
        mask: '00-000-000',
        fullMask: '000 00-000-000',
      },
      {
        name: 'Montserrat',
        code: '1664',
        iso: 'MS',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/ms.svg',
        mask: '000-0000',
        fullMask: '0(000) 000-0000',
      },
      {
        name: 'Morocco',
        code: '212',
        iso: 'MA',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/ma.svg',
        mask: '00-0000-000',
        fullMask: '000 00-0000-000',
      },
      {
        name: 'Mozambique',
        code: '258',
        iso: 'MZ',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/mz.svg',
        mask: '00-000-000',
        fullMask: '000 00-000-000',
      },
      {
        name: 'Myanmar',
        code: '95',
        iso: 'MM',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/mm.svg',
        fullMask: '00 000000000000',
        mask: '000000000000',
      },
      {
        name: 'Namibia',
        code: '224',
        iso: 'NA',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/na.svg',
        mask: '00-000-0000',
        fullMask: '000 00-000-0000',
      },
      {
        name: 'Nauru',
        code: '674',
        iso: 'NR',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/nr.svg',
        mask: '000-0000',
        fullMask: '000 000-0000',
      },
      {
        name: 'Nepal',
        code: '977',
        iso: 'NP',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/np.svg',
        mask: '00-000-000',
        fullMask: '000 00-000-000',
      },
      {
        name: 'Netherlands',
        code: '31',
        iso: 'NL',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/nl.svg',
        mask: '00-000-0000',
        fullMask: '00 00-000-0000',
      },
      {
        name: 'Netherlands Antilles',
        code: '599',
        iso: 'AN',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/an.svg',
        fullMask: '000 000000000000',
        mask: '000000000000',
      },
      {
        name: 'New Caledonia',
        code: '687',
        iso: 'NC',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/nc.svg',
        mask: '00-0000',
        fullMask: '000 00-0000',
      },
      {
        name: 'New Zealand',
        code: '24',
        iso: 'NZ',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/nz.svg',
        fullMask: '00 000000000000',
        mask: '000000000000',
      },
      {
        name: 'Nicaragua',
        code: '505',
        iso: 'NI',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/ni.svg',
        mask: '0000-0000',
        fullMask: '000 0000-0000',
      },
      {
        name: 'Niger',
        code: '227',
        iso: 'NE',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/ne.svg',
        mask: '00-00-0000',
        fullMask: '000 00-00-0000',
      },
      {
        name: 'Nigeria',
        code: '234',
        iso: 'NG',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/ng.svg',
        fullMask: '000 000000000000',
        mask: '000000000000',
      },
      {
        name: 'Niue',
        code: '683',
        iso: 'NU',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/nu.svg',
        mask: '0000',
        fullMask: '000 0000',
      },
      {
        name: 'Norfolk Island',
        code: '672',
        iso: 'NF',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/nf.svg',
        mask: '300-000',
        fullMask: '000 300-000',
      },
      {
        name: 'North Macedonia',
        code: '389',
        iso: 'MK',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/mk.svg',
        mask: '00-000-000',
        fullMask: '000 00-000-000',
      },
      {
        name: 'Northern Mariana Islands',
        code: '1670',
        iso: 'MP',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/mp.svg',
        mask: '000-0000',
        fullMask: '0(000) 000-0000',
      },
      {
        name: 'Norway',
        code: '47',
        iso: 'NO',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/no.svg',
        mask: '(000) 00-000',
        fullMask: '00 (000) 00-000',
      },
      {
        name: 'Oman',
        code: '968',
        iso: 'OM',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/om.svg',
        mask: '00-000-000',
        fullMask: '000 00-000-000',
      },
      {
        name: 'Pakistan',
        code: '92',
        iso: 'PK',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/pk.svg',
        mask: '(000) 000-0000',
        fullMask: '00 (000) 000-0000',
      },
      {
        name: 'Palau',
        code: '680',
        iso: 'PW',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/pw.svg',
        mask: '000-0000',
        fullMask: '000 000-0000',
      },
      {
        name: 'Palestine',
        code: '970',
        iso: 'PS',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/ps.svg',
        mask: '00-000-0000',
        fullMask: '000 00-000-0000',
      },
      {
        name: 'Panama',
        code: '507',
        iso: 'PA',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/pa.svg',
        mask: '000-0000',
        fullMask: '000 000-0000',
      },
      {
        name: 'Papua New Guinea',
        code: '675',
        iso: 'PG',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/pg.svg',
        mask: '(000) 00-000',
        fullMask: '000 (000) 00-000',
      },
      {
        name: 'Paraguay',
        code: '595',
        iso: 'PY',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/py.svg',
        mask: '(000) 000-000',
        fullMask: '000 (000) 000-000',
      },
      {
        name: 'Peru',
        code: '51',
        iso: 'PE',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/pe.svg',
        mask: '(000) 000-000',
        fullMask: '00 (000) 000-000',
      },
      {
        name: 'Philippines',
        code: '63',
        iso: 'PH',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/ph.svg',
        mask: '(000) 000-0000',
        fullMask: '00 (000) 000-0000',
      },
      {
        name: 'Pitcairn',
        code: '870',
        iso: 'PN',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/pn.svg',
        mask: '000-000-000',
        fullMask: '000 000-000-000',
      },
      {
        name: 'Poland',
        code: '48',
        iso: 'PL',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/pl.svg',
        mask: '(000) 000-000',
        fullMask: '00 (000) 000-000',
      },
      {
        name: 'Portugal',
        code: '351',
        iso: 'PT',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/pt.svg',
        mask: '00-000-0000',
        fullMask: '000 00-000-0000',
      },
      {
        name: 'Puerto Rico',
        code: '1',
        iso: 'PR',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/pr.svg',
        fullMask: '0 000000000000',
        mask: '000000000000',
      },
      {
        name: 'Qatar',
        code: '974',
        iso: 'QA',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/qa.svg',
        mask: '0000-0000',
        fullMask: '000 0000-0000',
      },
      {
        name: 'Reunion',
        code: '262',
        iso: 'RE',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/re.svg',
        mask: '00000-0000',
        fullMask: '000 00000-0000',
      },
      {
        name: 'Romania',
        code: '40',
        iso: 'RO',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/ro.svg',
        mask: '00-000-0000',
        fullMask: '00 00-000-0000',
      },
      {
        name: 'Russia',
        code: '7',
        iso: 'RU',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/ru.svg',
        mask: '(000) 000-00-00',
        fullMask: '0 (000) 000-00-00',
      },
      {
        name: 'Rwanda',
        code: '250',
        iso: 'RW',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/rw.svg',
        mask: '(000) 000-000',
        fullMask: '000 (000) 000-000',
      },
      {
        name: 'Saint Barthelemy',
        code: '590',
        iso: 'BL',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/bl.svg',
        mask: '000-00-00-00',
        fullMask: '000 000-00-00-00',
      },
      {
        name: 'Saint Helena, Ascension and Tristan Da Cunha',
        code: '290',
        iso: 'SH',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/sh.svg',
        mask: '0000',
        fullMask: '000 0000',
      },
      {
        name: 'Saint Kitts and Nevis',
        code: '1869',
        iso: 'KN',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/kn.svg',
        mask: '000-0000',
        fullMask: '0(000) 000-0000',
      },
      {
        name: 'Saint Lucia',
        code: '1758',
        iso: 'LC',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/lc.svg',
        mask: '000-0000',
        fullMask: '0(000) 000-0000',
      },
      {
        name: 'Saint Martin',
        code: '590',
        iso: 'MF',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/mf.svg',
        mask: '(000) 000-000',
        fullMask: '000 (000) 000-000',
      },
      {
        name: 'Saint Pierre and Miquelon',
        code: '508',
        iso: 'PM',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/pm.svg',
        mask: '00-0000',
        fullMask: '000 00-0000',
      },
      {
        name: 'Saint Vincent and the Grenadines',
        code: '1784',
        iso: 'VC',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/vc.svg',
        mask: '000-0000',
        fullMask: '0(000) 000-0000',
      },
      {
        name: 'Samoa',
        code: '685',
        iso: 'WS',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/ws.svg',
        mask: '00-0000',
        fullMask: '000 00-0000',
      },
      {
        name: 'San Marino',
        code: '378',
        iso: 'SM',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/sm.svg',
        mask: '0000-000000',
        fullMask: '000 0000-000000',
      },
      {
        name: 'Sao Tome and Principe',
        code: '239',
        iso: 'ST',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/st.svg',
        mask: '00-00000',
        fullMask: '000 00-00000',
      },
      {
        name: 'Saudi Arabia',
        code: '966',
        iso: 'SA',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/sa.svg',
        fullMask: '000 000000000000',
        mask: '000000000000',
      },
      {
        name: 'Senegal',
        code: '221',
        iso: 'SN',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/sn.svg',
        mask: '00-000-0000',
        fullMask: '000 00-000-0000',
      },
      {
        name: 'Serbia',
        code: '381',
        iso: 'RS',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/rs.svg',
        mask: '00-000-0000',
        fullMask: '000 00-000-0000',
      },
      {
        name: 'Seychelles',
        code: '248',
        iso: 'SC',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/sc.svg',
        mask: '0-000-000',
        fullMask: '000 0-000-000',
      },
      {
        name: 'Sierra Leone',
        code: '232',
        iso: 'SL',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/sl.svg',
        mask: '00-000000',
        fullMask: '000 00-000000',
      },
      {
        name: 'Singapore',
        code: '65',
        iso: 'SG',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/sg.svg',
        mask: '0000-0000',
        fullMask: '00 0000-0000',
      },
      {
        name: 'Sint Maarten',
        code: '1721',
        iso: 'SX',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/sx.svg',
        mask: '000-0000',
        fullMask: '0(000) 000-0000',
      },
      {
        name: 'Slovakia',
        code: '421',
        iso: 'SK',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/sk.svg',
        mask: '(000) 000-000',
        fullMask: '000 (000) 000-000',
      },
      {
        name: 'Slovenia',
        code: '386',
        iso: 'SI',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/si.svg',
        mask: '00-000-000',
        fullMask: '000 00-000-000',
      },
      {
        name: 'Solomon Islands',
        code: '677',
        iso: 'SB',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/sb.svg',
        fullMask: '000 000000000000',
        mask: '000000000000',
      },
      {
        name: 'Somalia',
        code: '252',
        iso: 'SO',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/so.svg',
        fullMask: '000 000000000000',
        mask: '000000000000',
      },
      {
        name: 'South Africa',
        code: '27',
        iso: 'ZA',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/za.svg',
        mask: '00-000-0000',
        fullMask: '00 00-000-0000',
      },
      {
        name: 'South Georgia and the South Sandwich Islands',
        code: '500',
        iso: 'GS',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/gs.svg',
        mask: '00000',
        fullMask: '000 00000',
      },
      {
        name: 'South Sudan',
        code: '211',
        iso: 'SS',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/ss.svg',
        mask: '00-000-0000',
        fullMask: '000 00-000-0000',
      },
      {
        name: 'Spain',
        code: '34',
        iso: 'ES',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/es.svg',
        mask: '(000) 000-000',
        fullMask: '00 (000) 000-000',
      },
      {
        name: 'Sri Lanka',
        code: '94',
        iso: 'LK',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/lk.svg',
        mask: '00-000-0000',
        fullMask: '00 00-000-0000',
      },
      {
        name: 'Sudan',
        code: '249',
        iso: 'SD',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/sd.svg',
        mask: '00-000-0000',
        fullMask: '000 00-000-0000',
      },
      {
        name: 'Suriname',
        code: '597',
        iso: 'SR',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/sr.svg',
        mask: '000-0000',
        fullMask: '000 000-0000',
      },
      {
        name: 'Svalbard and Jan Mayen',
        code: '47',
        iso: 'SJ',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/sj.svg',
        mask: '(000) 00-000',
        fullMask: '00 (000) 00-000',
      },
      {
        name: 'Sweden',
        code: '46',
        iso: 'SE',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/se.svg',
        mask: '00-000-0000',
        fullMask: '00 00-000-0000',
      },
      {
        name: 'Switzerland',
        code: '41',
        iso: 'CH',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/ch.svg',
        mask: '00-000-0000',
        fullMask: '00 00-000-0000',
      },
      {
        name: 'Syrian Arab Republic',
        code: '963',
        iso: 'SY',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/sy.svg',
        mask: '00-0000-000',
        fullMask: '000 00-0000-000',
      },
      {
        name: 'Taiwan',
        code: '886',
        iso: 'TW',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/tw.svg',
        mask: '000000000000',
        fullMask: '000 000000000000',
      },
      {
        name: 'Tajikistan',
        code: '992',
        iso: 'TJ',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/tj.svg',
        mask: '00-000-0000',
        fullMask: '000 00-000-0000',
      },
      {
        name: 'Tanzania, United Republic of Tanzania',
        code: '255',
        iso: 'TZ',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/tz.svg',
        mask: '00-000-0000',
        fullMask: '000 00-000-0000',
      },
      {
        name: 'Thailand',
        code: '66',
        iso: 'TH',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/th.svg',
        fullMask: '00 000000000000',
        mask: '000000000000',
      },
      {
        name: 'Timor-Leste',
        code: '670',
        iso: 'TL',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/tl.svg',
        fullMask: '000 000000000000',
        mask: '000000000000',
      },
      {
        name: 'Togo',
        code: '228',
        iso: 'TG',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/tg.svg',
        mask: '00-000-000',
        fullMask: '000 00-000-000',
      },
      {
        name: 'Tokelau',
        code: '690',
        iso: 'TK',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/tk.svg',
        mask: '0000',
        fullMask: '000 0000',
      },
      {
        name: 'Tonga',
        code: '676',
        iso: 'TO',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/to.svg',
        mask: '00000',
        fullMask: '000 00000',
      },
      {
        name: 'Trinidad and Tobago',
        code: '1868',
        iso: 'TT',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/tt.svg',
        mask: '000-0000',
        fullMask: '0(000) 000-0000',
      },
      {
        name: 'Tunisia',
        code: '216',
        iso: 'TN',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/tn.svg',
        mask: '00-000-000',
        fullMask: '000 00-000-000',
      },
      {
        name: 'Turkey',
        code: '90',
        iso: 'TR',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/tr.svg',
        mask: '(000) 000-0000',
        fullMask: '00 (000) 000-0000',
      },
      {
        name: 'Turkmenistan',
        code: '993',
        iso: 'TM',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/tm.svg',
        mask: '0-000-0000',
        fullMask: '000 0-000-0000',
      },
      {
        name: 'Turks and Caicos Islands',
        code: '1249',
        iso: 'TC',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/tc.svg',
        mask: '000-000',
        fullMask: '0(000) 000-000',
      },
      {
        name: 'Tuvalu',
        code: '688',
        iso: 'TV',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/tv.svg',
        fullMask: '000 000000000000',
        mask: '000000000000',
      },
      {
        name: 'Uganda',
        code: '256',
        iso: 'UG',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/ug.svg',
        mask: '(000) 000-000',
        fullMask: '000 (000) 000-000',
      },
      {
        name: 'Ukraine',
        code: '380',
        iso: 'UA',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/ua.svg',
        mask: '(00) 000-00-00',
        fullMask: '000 (00) 000-00-00',
      },
      {
        name: 'United Arab Emirates',
        code: '971',
        iso: 'AE',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/ae.svg',
        fullMask: '000 000000000000',
        mask: '000000000000',
      },
      {
        name: 'United Kingdom',
        code: '44',
        iso: 'GB',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/gb.svg',
        mask: '00-0000-0000',
        fullMask: '00 00-0000-0000',
      },
      
      {
        name: 'Uruguay',
        code: '598',
        iso: 'UY',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/uy.svg',
        mask: '0-000-00-00',
        fullMask: '000 0-000-00-00',
      },
      {
        name: 'Uzbekistan',
        code: '998',
        iso: 'UZ',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/uz.svg',
        mask: '00-000-0000',
        fullMask: '000 00-000-0000',
      },
      {
        name: 'Vanuatu',
        code: '678',
        iso: 'VU',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/vu.svg',
        fullMask: '000 000000000000',
        mask: '000000000000',
      },
      {
        name: 'Venezuela, Bolivarian Republic of Venezuela',
        code: '58',
        iso: 'VE',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/ve.svg',
        mask: '(000) 000-0000',
        fullMask: '00 (000) 000-0000',
      },
      {
        name: 'Vietnam',
        code: '84',
        iso: 'VN',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/vn.svg',
        fullMask: '00 000000000000',
        mask: '000000000000',
      },
      {
        name: 'Virgin Islands, British',
        code: '1284',
        iso: 'VG',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/vg.svg',
        mask: '000-0000',
        fullMask: '0(000) 000-0000',
      },
      {
        name: 'Virgin Islands, U.S.',
        code: '1340',
        iso: 'VI',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/vi.svg',
        mask: '000-0000',
        fullMask: '0(000) 000-0000',
      },
      {
        name: 'Wallis and Futuna',
        code: '681',
        iso: 'WF',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/wf.svg',
        mask: '00-0000',
        fullMask: '000 00-0000',
      },
      {
        name: 'Yemen',
        code: '967',
        iso: 'YE',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/ye.svg',
        fullMask: '000 000000000000',
        mask: '000000000000',
      },
      {
        name: 'Zambia',
        code: '260',
        iso: 'ZM',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/zm.svg',
        mask: '00-000-0000',
        fullMask: '000 00-000-0000',
      },
      {
        name: 'Zimbabwe',
        code: '263',
        iso: 'ZW',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/zw.svg',
        mask: '0-000000',
        fullMask: '000 0-000000',
      },
    ],
    []
  );

  return codes.map((c, i) => ({ ...c, value: i, label: c.name,code: c.code }));
};

export default useCountryCodes;
