import { PropsWithChildren } from 'react';
import SidebarPreferences from '../../components/Layout/SidebarPreferences';

export const PreferencesLayout = ({ children }: PropsWithChildren<unknown>) => {
  return (
    <div className='contactBox'>
      <div className='container'>
        <div className='row'>
          <div className='col-sm-2'>
            <div className='heading2'>Preferences</div>

            <div className='sidebarBox'>
              <SidebarPreferences />
            </div>
          </div>
          <div className='col-sm-10'>
            <div className='container-md'>{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
