import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { TextEditorField } from '../Common/TextEditorField';
import { InputFieldSubmit } from '../Common/InputFieldSubmit';
import Creatable from 'react-select/creatable';
import { useAppDispatch } from '../../shared/hooks/use-app-dispatch';
import { getAllUserListAction } from '../../actions/user';
import { formatContactDataHTMLDropdown } from '../../utils/contact-formatters';
import { createEmailAction, getAllEmailAction, deleteEmailAction } from '../../actions/message';
import { Userpilot } from 'userpilot';
import { FileDropZone } from '../../shared/file-upload';
import { Confirm } from '../../shared/confirmation-dialog';
import { formatDateWithTime } from '../../utils/date-formatters';
import { getProjectRoles } from '../../modules/settings';
import renderHTML from 'html-react-parser';
import _ from 'lodash';
import axios from 'axios';
import { appConstants } from '../../_constants';
import ReactLoading from 'react-loading';
import { useAuth } from '../../components/Auth/Auth';
import { fileUploadOnS3 } from '../../utils/s3-attachment-handler';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import { findContactAffiliations, getContacts } from '../../modules/contacts';
import { getCompanyAffiliations } from '../../modules/companies';
import { AffiliationLookupModal } from '../../modals/affiliation-lookup-modal';
import {
  createGroup,
  GroupHeading,
  MenuList,
  formatGroupLabel,
  getContactOptions,
  getCompanyOptions,
  getProjectOptions,
} from './email-editor-helper';

window.Buffer = window.Buffer || require('buffer').Buffer;

let Validator = require('validatorjs');
let emailValidation = [];
let mess = {
  required: 'This field is required',
  alpha: 'This field may only contain letters',
  numeric: 'This field must be a number',
};
let rules = {
  emailTo: 'required',
  emailSub: 'required',
  //emailTo: 'required',
  //emailLabel: 'required',
};
emailValidation = new Validator([], rules, mess);
emailValidation.passes();
emailValidation.fails();

const EmailEditor = (props) => {
  const dispatch = useAppDispatch();

  const [accountId] = useState(localStorage.getItem('accountId'));
  const [userId] = useState(localStorage.getItem('userId'));
  const [comName] = useState(localStorage.getItem('companyName'));
  const [formData, setFormData] = useState({});
  const [userList, setUserList] = useState([]);
  const [contactListData, setContactListData] = useState([]);
  const [, setFilesCount] = useState(0);
  const [userCommentError, setUserCommentError] = useState('');
  const [isSave, setIsSave] = useState(false);
  const [buttonActive, setButtonActive] = useState(true);
  const [, setFilesUploaded] = useState(true);
  const [filesUploading, setFilesUploading] = useState(false);
  const [showEditEmail, setShowEditEmail] = useState(false);
  const [emailAction, setEmailAction] = useState('NEW');
  const [objectId, setObjectId] = useState(null);
  const [asModal, setAsModal] = useState(false);
  //const [attachmentIndex, setAttachmentIndex] = useState(-1);
  const [typeId, setTypeId] = useState(null);
  const [typeRef, setTypeRef] = useState(null);
  const [emailSub, setEmailSub] = useState('');
  const [emailCC, setEmailCC] = useState([]);
  const [emailTo, setEmailTo] = useState(null);
  const [emailDataLoaded, setEmailDataLoaded] = useState(false);
  const [projectRoles, setProjectRoles] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [assignments, setAssignments] = useState([]);
  const [initialTags, setInitialTags] = useState([]);
  const [initialFiles, setInitialFiles] = useState([]);
  const [isAffiliationSearchOpen, setIsAffiliationSearchOpen] = useState(false);
  const [targetEmailType, setTargetEmailType] = useState('');

  const [showToolbar, setShowToolbar] = useState(true);

  const errorStyle = { color: 'red' };
  const attachmentLimit = 20000000;

  const getProjectContacts = () => {
    const assignData = props.data?.userAssignData || [];

    if (Array.isArray(assignData) && assignData.length > 0) {
      setAssignments(assignData);

      const assignedIds = assignData.map((assign) => assign.contactAffiliationId);

      dispatch(
        findContactAffiliations({
          criteria: `${assignedIds.join(' ')}`,
          page: 1,
          count: assignedIds.length,
          isAccount: false,
          includeDeleted: true,
          fields: ['_id'],
          requiredFields: ['primaryEmail.email'],
        })
      )
        .unwrap()
        .then((data) => setContacts(data));
    }
  };

  const getCompanyContacts = () => {
    const { objectId: companyId } = props.data;

    if (companyId) {
      dispatch(getCompanyAffiliations({ companyId }))
      .unwrap()
      .then((affiliations) => {
        setAssignments(affiliations);

        const contactIds = affiliations
          .filter((aff) => aff.contactId && aff.active)
          .map((aff) => aff.contactId);

        if (contactIds.length) {
          dispatch(getContacts({ contactIds }))
            .unwrap()
            .then((contacts) => setContacts(contacts));
        }
      });
    }
  };

  useEffect(() => {
    props.getAllUserListAction({ accountId, userId, page: 0, displayLimit: 999999999 });

    dispatch(getProjectRoles()).unwrap().then((data) => setProjectRoles(data));
  }, []);

  useEffect(() => {
    switch (typeRef) {
      case 'LEAD':
      case 'PROJECT':
        getProjectContacts();
        break;
      case 'COMPANY':
        getCompanyContacts();
        break;
    }
  }, [typeRef]);

  useEffect(() => {
    if (contacts.length > 0 || typeRef === 'CONTACT') {
      let options = [];

      switch (typeRef) {
        case 'LEAD':
        case 'PROJECT':
          options = getProjectOptions(contacts, assignments, projectRoles);
          break;
        case 'COMPANY':
          options = getCompanyOptions(contacts, assignments, props.data);
          break;
        case 'CONTACT':
          options = getContactOptions(props.data);
      }

      tryAddCurrentUserOption(options);
      setContactListData(options);
    }
  }, [contacts, assignments, props.data, projectRoles]);

  const tryAddCurrentUserOption = (data) => {
    const userInfoObject = JSON.parse(localStorage.getItem('userInfo'));
    const userContactId = userInfoObject.contactId;
    let currentUserOption = data.find((c) => c.objectId === userContactId);

    if (!currentUserOption) {
      // attempt to safely read userInfo JSON and set its data as an predefined option for CC field
      // as well as existing option to select even if it was removed accidentally by user
      try {
        if (!userInfoObject.email) {
          userInfoObject.email = userInfoObject.userEmail;
        }

        userInfoObject.companyName = comName;

        const cData = formatContactDataHTMLDropdown(userInfoObject);
        currentUserOption = {
          label: renderHTML(cData),
          value: userInfoObject.email,
          objectId: userInfoObject.contactId,
        };

        data.push(createGroup('Current User', [currentUserOption]));
      } catch (e) {}
    }

    if (currentUserOption) {
      setEmailCC([currentUserOption]);
    }
  };

  useEffect(() => {
    if (props.initialTags?.length > 0) {
      setInitialTags(props.initialTags);
    }
  }, [props.initialTags]);

  useEffect(() => {
    if (props.asModal) {
      setAsModal(props.asModal);
    }
  }, [props.asModal]);

  useEffect(() => {
    if (props.initialFiles?.length > 0) {
      setInitialFiles(props.initialFiles);
    }
  }, [props.initialFiles]);

  const removeFileConfirm = (attachmentData, attachmentIndex, confirm) => async () => {
    const confirmed =
      (await confirm?.({
        title: 'Remove attachment',
        question: 'Are you sure you want to remove ' + attachmentData.name + '?',
      })) ?? true;

    if (!confirmed) {
      return;
    }

    deleteFile(attachmentIndex);
  };

  useEffect(() => {
    if (props.showEditEmail && props.showEditEmail !== showEditEmail) {
      setShowEditEmail(true);
    } else if (!props.showEditEmail && showEditEmail) {
      setShowEditEmail(false);
      resetFields();
    }

    const typeIdChanged = props.typeId && props.typeId !== typeId;
    const emailActionChanged = props.emailAction && props.emailAction !== emailAction;
    const objectIdChanged =
      props.objectId && props.objectId?.length > 0 ? props.objectId !== objectId : !objectId;

    if (typeIdChanged) {
      setTypeId(props.typeId);
      setTypeRef(props.typeRef);
    }

    if (emailActionChanged) {
      setEmailAction(props.emailAction);
    }

    if (objectIdChanged) {
      setObjectId(props.objectId?.length > 0 ? props.objectId : null);
    }

    const isSaveAction = props.isSave && !isSave;
    setIsSave(isSaveAction);

    if (props.allUserList?.length > 0 && userList.length == 0) {
      let userList = [];
      for (let i in props.allUserList) {
        if (props.allUserList[i].emailCheckStatus === true) {
          const userData = props.allUserList[i];
          userList.push({
            id: userData.userId,
            value: userData.firstName + ' ' + userData.lastName + ' (' + userData.email + ')',
          });
        }
      }
      setUserList(userList);
    }
  }, [props]);

  useEffect(() => {
    if (props.typeId && props.typeId !== typeId) {
      setTypeId(props.typeId);
      setTypeRef(props.typeRef);
    }
  }, [props.typeId, props.typeRef]);

  useEffect(() => {
    const hasObjectId = objectId && objectId?.length > 0;
    if ((typeId || emailAction !== 'new' || objectId) && hasObjectId) {
      resetFields();
      getEmailData({ objectId: objectId, typeRef: typeRef, typeId: typeId });
    } else if (emailAction === 'new' && !hasObjectId && typeId) {
      resetFields();
      setEmailDataLoaded(true);
      //getEmailData({ objectId: null, typeRef: typeRef, typeId: typeId});
    }
  }, [typeId, typeRef, emailAction, objectId]);

  const onFileChange = (e) => {
    let files = e.target.files;
    let field = formData;

    if (typeof field.tempUploadEmail === 'undefined') {
      field.tempUploadEmail = [];
    }
    for (let i = 0; i < files.length; i++) {
      field.tempUploadEmail.push(files[i]);
    }

    setFormData({ ...formData, tempUploadEmail: field.tempUploadEmail });
    setFilesCount(field.tempUploadEmail.length);
    setFilesUploaded(false);
  };

  const onToolbarToggle = () => {
    setShowToolbar(!showToolbar);
  };

  const deleteFile = (fileIndex) => {
    //e.preventDefault();
    let localFormData = { ...formData };
    let tempUploadEmail = localFormData.tempUploadEmail ?? [];
    let emailAttachments = localFormData.emailAttachments ?? [];

    if (typeof tempUploadEmail[fileIndex] != 'undefined') {
      localFormData.tempUploadEmail = _.cloneDeep(tempUploadEmail.filter(Boolean));
      localFormData.emailAttachments = _.cloneDeep(emailAttachments.filter(Boolean));

      delete localFormData.tempUploadEmail[fileIndex];
      delete localFormData.emailAttachments[fileIndex];

      localFormData.tempUploadEmail = localFormData.tempUploadEmail.filter(Boolean);
      localFormData.emailAttachments = localFormData.emailAttachments.filter(Boolean);
    }

    setFormData(localFormData);
    setFilesCount(localFormData.tempUploadEmail.length);
  };

  const onDrop = (files) => {
    let field = formData;
    const fileType = 'tempUploadEmail';

    if (typeof field[fileType] === 'undefined') {
      field[fileType] = [];
    }
    for (let i = 0; i < files.length; i++) {
      field[fileType].push(files[i]);
    }

    setFormData({ ...formData, [fileType]: field[fileType] });
    setFilesCount(field[fileType].length);
    setFilesUploaded(false);
  };

  const saveEmail = async (e, saveType) => {
    setButtonActive(false);
    if (!emailTo) {
      setEmailTo([]);
      //triggers error
    }
    

    if (!(formData.emailMessage?.length > 0)) {
      setUserCommentError('This field is required');
      setButtonActive(true);
    }

    if (
      !(formData.emailMessage?.length > 0) ||
      !emailTo ||
      emailTo.length == 0 ||
      emailSub.length == 0
    ) {
      setButtonActive(true);
      return;
    }

    let uploadSuccess = true;
    if (formData?.tempUploadEmail?.length || 0) {
      uploadSuccess = await uploadFiles();
    }

    let messageData = {};

    if (uploadSuccess) {
      messageData['objectId'] = formData.objectId;
      messageData['accountId'] = accountId;
      messageData['userId'] = userId;
      messageData['receiverId'] = userId;
      messageData['typeId'] = typeId;
      messageData['typeRef'] = typeRef;
      //messageData['sourceName'] = this.props.state.title;

      messageData['emailSub'] = emailSub;
      messageData['emailTo'] = emailTo;
      messageData['emailCC'] = emailCC;
      messageData['tags'] = formData.emailTags;
      messageData['emailBody'] = formData.emailMessage;
      messageData['copyOf'] = formData?.copyOf ?? null;

      let parser = new DOMParser();
      const doc = parser.parseFromString(formData.emailMessage, 'text/html');
      const links = doc.getElementsByClassName('mention');
      messageData['mentions'] = [];
      for (let x = 0; x < links.length; x++) {
        let dataset = links[x].dataset;
        messageData['mentions'].push({
          id: dataset.id,
          denotationChar: dataset.denotationChar,
          value: dataset.value,
        });
      }

      const isDraft = saveType === 'DRAFT';

      messageData['isDraft'] = isDraft;
      messageData['isRead'] = 0;
      messageData['status'] = 'Enabled';
      messageData['trash'] = false;
      messageData['attachments'] = formData['emailAttachments'];

      if (isDraft) Userpilot.track('Save Draft Email');
      else {
        Userpilot.track('Sent Email');
      }
      await props.createEmailAction(messageData, useAuth);

      resetFields();
      getAllEmails();
      showHideEditEmail();

      setButtonActive(true);
    }
  };

  const uploadFiles = async () => {
    let currentForm = formData;
    setFilesUploading(true);
    let { attachments, objectId, uploadSuccess } = await fileUploadOnS3(
      formData?.tempUploadEmail,
      formData['objectId'],
      typeId,
      typeRef,
      attachmentLimit,
      'Email'
    );

    if (!uploadSuccess) {
      return false;
    }

    currentForm['emailAttachments'] = attachments;
    currentForm['objectId'] = objectId;

    setFormData(currentForm);

    setFilesCount(attachments.length);
    setFilesUploading(false);
    setFilesUploaded(true);

    return true;
  };

  const getAllEmails = async () => {
    let contData = {};
    contData['accountId'] = accountId;
    contData['userId'] = userId;
    contData['typeId'] = typeId;
    contData['typeRef'] = typeRef;
    contData['page'] = 0;
    contData['displayLimit'] = 999999999;
    await props.getAllEmailAction(contData);
  };

  const getEmailData = async ({ objectId, typeRef, typeId }) => {
    const hasObjectId = objectId && objectId?.length > 0;
    if (!accountId || !typeId || !typeRef || !hasObjectId) return;

    setEmailDataLoaded(false);

    const { data } = await axios.post(
      'parse/functions/getSectionEmail',
      {
        userId: userId,
        accountId: accountId,
      },
      {
        baseURL: appConstants.WEB_SERVICE_URL_WITHOUT_PARSE,
        headers: {
          'X-Parse-Application-Id': appConstants.PARSE_APPLICATION_ID,
          'X-Parse-REST-API-Key': appConstants.PARSE_REST_API_KEY,
          'X-Parse-Session-Token': localStorage.getItem('sessionToken'),
          authorization: localStorage.getItem('JWTTOKEN'),
        },
        params: {
          accountId: accountId,
          typeId: typeId,
          typeRef: typeRef,
          objectId: objectId,
        },
      }
    );

    setEmailDataLoaded(true);

    if (data?.result?.data?.length > 0) {
      if (emailAction === 'COPY') {
        editCopyData(data.result.data[0], objectId);
      } else if (emailAction === 'FORWARD') {
        editForwardData(data.result.data[0], objectId);
      } else if (emailAction === 'REPLY') {
        replyEmailData(data.result.data[0], objectId);
      } else if (emailAction === 'EDIT') {
        editEmailData(data.result.data[0]);
      }
    }

    if (initialFiles?.length > 0) {
      onDrop(initialFiles);
    }

    if (initialTags?.length > 0) {
      setFormData({
        ...formData,
        emailTags: initialTags.map((tag) => ({ value: tag, label: tag })),
      });
    }
  };

  const cancelDelete = (emailData, confirm) => async () => {
    const confirmed =
      (await confirm?.({
        title: 'Delete email',
        question: 'Are you sure you want to Delete this draft?',
      })) ?? true;

    if (!confirmed) {
      return;
    }

    let formData = {};
    formData['objectId'] = emailData.objectId;
    formData['typeRef'] = emailData.typeRef;
    formData['typeId'] = emailData.typeId;

    await props.deleteEmailAction(formData);

    resetFields();
  };

  const editCopyData = (emailData, emailObjectId) => {
    let editFormData = { ...formData };
    editFormData.objectId = null;
    editFormData.emailSub = emailData.emailSub ?? '';
    editFormData.emailTo = emailData.emailTo;
    editFormData.emailCC = emailData.emailCC;
    editFormData.emailMessage = emailData.emailBody;
    editFormData.emailAttachments = emailData.attachments;
    editFormData.tempUploadEmail = emailData.attachments.map((attachment) => ({
      name: attachment.fileName,
      type: attachment.fileType,
      lastModifiedDate: attachment.fileModified,
      size: attachment.fileSize,
      fileKey: attachment.fileKey,
      alreadyUploaded: true,
      copiedFromOtherEmail: true,
    }));
    editFormData.copyOf = emailObjectId;

    setFormData(editFormData);
    setEmailSub(emailData.emailSub ?? '');
    handleChangeEmailSelection('emailTo', emailData.emailTo);
    handleChangeEmailSelection('emailCC', emailData.emailCC);
  };

  const editForwardData = (emailData, emailObjectId) => {
    let editFormData = { ...formData };
    editFormData.objectId = null;
    editFormData.emailTo = [];
    editFormData.emailCC = [];
    let forwardMessage = 'Forwarded message:\n\n' + emailData.emailBody;
    editFormData.emailMessage = forwardMessage;
    editFormData.emailAttachments = emailData.attachments;
    editFormData.tempUploadEmail = emailData.attachments.map((attachment) => ({
      name: attachment.fileName,
      type: attachment.fileType,
      lastModifiedDate: attachment.fileModified,
      size: attachment.fileSize,
      fileKey: attachment.fileKey,
      alreadyUploaded: true,
      copiedFromOtherEmail: true,
    }));
    editFormData.copyOf = emailObjectId;

    setFormData(editFormData);
    setEmailSub('FW: ' + (emailData.emailSub ?? ''));
    handleChangeEmailSelection('emailTo', []);
    handleChangeEmailSelection('emailCC', []);
  };

  const replyEmailData = (emailData, emailObjectId) => {
    let editFormData = { ...formData };
    editFormData.objectId = null;
    editFormData.emailSub = 'RE: ' + (emailData.emailSub ?? '');
    editFormData.emailTo = [emailData.emailFrom];
    editFormData.emailCC = [];
    let replyMessage =
      `\n\nOn ${formatDateWithTime(emailData.createdAt)}, ${emailData.emailFrom.label} wrote:\n\n` +
      emailData.emailBody;
    editFormData.emailMessage = replyMessage;
    editFormData.emailAttachments = [];
    editFormData.tempUploadEmail = [];
    editFormData.copyOf = emailObjectId;

    setFormData(editFormData);
    setEmailSub('RE: ' + (emailData.emailSub ?? ''));
    handleChangeEmailSelection('emailTo', [emailData.emailFrom]);
    handleChangeEmailSelection('emailCC', emailData.emailCC);
  };

  const editEmailData = (emailData, isCopy = false) => {
    let editFormData = { ...formData };
    editFormData.objectId = emailData.objectId;
    editFormData.emailSub = emailData.emailSub ?? '';
    editFormData.emailTo = emailData.emailTo;
    editFormData.emailCC = emailData.emailCC;
    editFormData.emailMessage = emailData.emailBody;
    editFormData.emailAttachments = emailData.attachments;
    editFormData.tempUploadEmail = emailData.attachments.map((attachment) => ({
      name: attachment.fileName,
      type: attachment.fileType,
      lastModifiedDate: attachment.fileModified,
      size: attachment.fileSize,
      fileKey: attachment.fileKey,
      alreadyUploaded: true,
    }));

    setFormData(editFormData);
    setEmailSub(emailData.emailSub ?? '');
    handleChangeEmailSelection('emailTo', emailData.emailTo);
    handleChangeEmailSelection('emailCC', emailData.emailCC);
  };

  const resetFields = () => {
    /*Reset form data */
    let newFormData = { ...formData };
    Object.keys(newFormData).map((key, index) => {
      if (typeof newFormData[key] === 'object') newFormData[key] = [];
      else newFormData[key] = '';
      return true;
    });

    setEmailTo([]);
    setEmailCC([]);
    setEmailSub('');
    newFormData.emailMessage = null;
    newFormData.copyOf = null;

    setFormData(newFormData);
    tryAddCurrentUserOption(contactListData);
    setUserCommentError('');
  };

  const handleChangeEmailBody = (name, value) => {
    if (!isSave) {
      let userCommentError = '';
      if (value && value === '<p><br></p>') {
        userCommentError = 'This field is required.';
        value = '';
      }
      let newFormData = { ...formData };
      newFormData[name] = value;
      setFormData(newFormData);
      setUserCommentError(userCommentError);
    }
  };

  const handleChangeSubject = (e) => {
    e.preventDefault();
    if (!isSave) {
      setEmailSub(e.target.value);
    }
  };

  const showHideEditEmail = () => {
    setShowEditEmail(false);
    setObjectId(null);
    resetFields();
    props.closeWindowCallback();
  };

  let filesListEmail = '';
  if (formData.tempUploadEmail !== undefined && formData.tempUploadEmail.length > 0) {
    filesListEmail = formData.tempUploadEmail.map((v, index) => (
      <Confirm
        render={(confirm) => (
          <div className='filesList'>
            <label className={v.alreadyUploaded ? '' : 'time-left'}>{v.name}</label>
            <span onClick={removeFileConfirm(v, index, confirm)}>
              <i className='fa fa-remove'></i>
            </span>
          </div>
        )}
      />
    ));
  }

  const emailSaveBtn = [
    {
      title: 'Save Draft',
      loading: 'Loading...',
      loadingActive: !buttonActive,
      onClick: (e) => saveEmail(e, 'DRAFT'),
      'data-type': 'SAVE',
      className: 'btn btn-primary custom-button',
    },
    {
      title: 'Send',
      loading: 'Loading...',
      loadingActive: !buttonActive,
      onClick: (e) => saveEmail(e, 'SEND'),
      'data-type': 'SAVE',
      className: 'btn btn-primary custom-button',
    },
  ];

  const validateEmail = (email) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const handleChangeEmailSelection = (name, value = []) => {
    const newValues = value
      .filter((data) => validateEmail(data.value))
      .map((data) => ({
        label: data.objectId ? renderHTML([data.label[0], data.value].join('<br/>')) : data.label,
        value: data.value,
        objectId: data.objectId ? data.objectId : '',
      }));

    const method = (name ?? targetEmailType) === 'emailCC' ? setEmailCC : setEmailTo;
    method(newValues);
  };

  const onGroupHeadingClick = (props) => {
    const options = props?.data?.options || [];
    const existingEmails = targetEmailType === 'emailCC' ? emailCC : emailTo;
    const newValues = [...(existingEmails || []), ...options];
    handleChangeEmailSelection(targetEmailType, newValues);
  };

  const onAfterSubmit = (options) => {
    const optionsWithEmail = options
      .filter((option) => option.primaryEmail)
      .map((option) => {
        const email = option.primaryEmail.email;
        const updatedOption = { ...option, email };

        return {
          label: renderHTML(formatContactDataHTMLDropdown(updatedOption)),
          value: email,
          objectId: option._id,
        };
    });

    const existingEmails = targetEmailType === 'emailCC' ? emailCC : emailTo;
    const newValues = [...(existingEmails || []), ...optionsWithEmail];
    handleChangeEmailSelection(targetEmailType, newValues);
  };

  const MenuListCustom = (props) => (
    <MenuList {...props} onClick={() => setIsAffiliationSearchOpen(true)} />
  );

  const GroupHeadingCustom = (props) => (
    <GroupHeading {...props} onClick={() => onGroupHeadingClick(props)} />
  );

  const emailBody = () => {
    return !emailDataLoaded ? (
      <ReactLoading
        className='table-loader'
        type={appConstants.LOADER_TYPE}
        color={appConstants.LOADER_COLOR}
        height={appConstants.LOADER_HEIGHT}
        width={appConstants.LOADER_WIDTH}
      />
    ) : (
      <div>
        <FileDropZone
          onFileDrop={(e) => {
            onDrop(e);
          }}
        >
          <div className='row'>
            <div className='col-md-12'>
              <div className='form-group'>
                <label htmlFor='startDT'> To </label>
                <Creatable
                  name='emailTo'
                  formatCreateLabel={(userInput) => `Email '${userInput}'`}
                  value={emailTo}
                  onChange={handleChangeEmailSelection.bind(this, 'emailTo')}
                  options={contactListData}
                  isMulti
                  components={{ GroupHeading: GroupHeadingCustom, MenuList: MenuListCustom }}
                  formatGroupLabel={formatGroupLabel}
                  createOptionPosition={'first'}
                  onMenuOpen={() => setTargetEmailType('emailTo')}
                />
                <span style={errorStyle}>
                  {emailTo?.length == 0 && emailValidation.errors.first('emailTo')}
                </span>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-12'>
              <div className='form-group'>
                <label htmlFor='emailCC'>CC</label>
                <Creatable
                  name='emailCC'
                  formatCreateLabel={(userInput) => `Email '${userInput}'`}
                  value={emailCC}
                  onChange={handleChangeEmailSelection.bind(this, 'emailCC')}
                  options={contactListData}
                  isMulti
                  components={{ GroupHeading: GroupHeadingCustom, MenuList: MenuListCustom }}
                  formatGroupLabel={formatGroupLabel}
                  createOptionPosition={'first'}
                  onMenuOpen={() => setTargetEmailType('emailCC')}
                />
                <span style={errorStyle}>
                  {formData.emailCC && emailValidation.errors.first('emailCC')}
                </span>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-12'>
              <div className='form-group'>
                <label htmlFor='endDT'>Subject</label>
                <input
                  type='text'
                  className='form-control'
                  name='emailSub'
                  id='emailSub'
                  value={emailSub}
                  onChange={(e) => {
                    handleChangeSubject(e);
                  }}
                />
                <span style={errorStyle}>
                  {emailSub?.length == 0 && emailValidation.errors.first('emailSub')}
                </span>
              </div>
            </div>
          </div>

          {userList.length > 0 && (
            <TextEditorField
              fieldData={{
                onChange: handleChangeEmailBody.bind(this, 'emailMessage'),
                value: formData.emailMessage,
                name: 'emailMessage',
                errorMsg: formData.emailMessage?.length > 0 ? '' : userCommentError,
                mentionList: userList,
              }}
              toolbarEnabled={showToolbar}
            />
          )}

          <div className='row'>
            <div className='col-md-12'>
              <div className='form-group'>
                <div className='filesBox'>
                  {filesListEmail.length > 0 && (
                    <div>
                      <div className=' row'>
                        <label className=''>Attachments</label>
                      </div>
                      <div className='ms-2 filesList row'>{filesListEmail}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className='row'>
            <div className='col-md-6 mt-4'>
              <div id='upload_button'>
                <label>
                  <input
                    type='file'
                    name='userImage'
                    multiple
                    className='field full'
                    label='Upload'
                    accept=''
                    title='Attach Files'
                    onChange={(e) => {
                      onFileChange(e);
                    }}
                  />
                  <span>
                    Add attachment <i className='fa fa-paperclip' aria-hidden='true'></i>
                  </span>
                </label>
              </div>
              {false && (
                <div id='show_hide_toolbar_button'>
                  <label className='upload-button'>
                    <div
                      name='toggle'
                      label='toggleToolbar'
                      onClick={() => {
                        onToolbarToggle();
                      }}
                      title={showToolbar ? 'Hide formatting' : 'Show formatting'}
                    >
                      <i
                        className={showToolbar ? 'fa fa-font' : 'fa fa-paragraph'}
                        aria-hidden='true'
                      ></i>
                    </div>
                  </label>
                </div>
              )}
            </div>
          </div>
        </FileDropZone>
      </div>
    );
  };

  const emailHeaderText = () => {
    switch (emailAction) {
      case 'FORWARD':
        return 'Forward Email';
      case 'REPLY':
        return 'Reply to Email';
      case 'EDIT':
        return 'Edit Draft';
      default:
        return 'New Email';
    }
  };

  const emailFooter = () => {
    return (
      <div className='row mb-2 mt-3'>
        <div className='d-flex addProjectPopupBottomBtnBox'>
          <button className='btn btn-primary custom-button' onClick={showHideEditEmail.bind(this)}>
            Close
          </button>
          <div className='ms-auto'>
            <InputFieldSubmit actions={emailSaveBtn} disabled={filesUploading} />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className='no-print'>
      {showEditEmail ? (
        !asModal ? (
          <div>
            <div className='row'>
              <div className='col-md-12'>
                <div className='pageinfo'>
                  <h2>{emailHeaderText()}</h2>
                </div>
              </div>
            </div>
            {emailBody()}
            {emailFooter()}
          </div>
        ) : (
          <Modal isOpen={showEditEmail} onHide={showHideEditEmail} size='lg'>
            <ModalHeader closeButton>{emailHeaderText()}</ModalHeader>
            <ModalBody>
              <div className='container'>{emailBody()}</div>
            </ModalBody>
            <ModalFooter>{emailFooter()}</ModalFooter>
          </Modal>
        )
      ) : (
        ''
      )}

      <AffiliationLookupModal
        isOpen={isAffiliationSearchOpen}
        toggle={() => setIsAffiliationSearchOpen(false)}
        onAfterSubmit={onAfterSubmit}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  const { message } = state;

  return {
    isSave: message.isSaveM,
    buttonActive: message.buttonActive,
    allUserList: state.user.allUserList,
  };
};
export default withRouter(
  connect(mapStateToProps, {
    getAllEmailAction,
    deleteEmailAction,
    createEmailAction,
    getAllUserListAction,
  })(EmailEditor)
);
