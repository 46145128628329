import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { CustomTable } from '../../Common/CustomTable';
import { SelectColumnFilter } from '../../Common/CustomTableComponents/SelectColumnFilter';
import { DateColumnFilter } from '../../Common/CustomTableComponents/DateColumnFilter';
import { formatDateObjectOrNull } from '../../../utils/date-formatters.js';
import { appConstants } from '../../../_constants';
import TitleComponent from '../../Common/TitleComponent';
import loaderImage from '../../static/images/loading_spinner.gif';

const ProjectList = () => {
    const [userId, setUserId] = useState(localStorage.getItem('userId'));
    const [list, setList] = useState([]);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(99999);
    const [total, setTotal] = useState(0);
    const [showProcessing, setShowProcessing] = useState(false);

    useEffect(() => {
      setShowProcessing(true);
        getDataList();
        getDataListCount();
       
    }, [page, limit]);

    const getDataList = async () => {
        const { data } = await axios.post(
            'admin/adminProjectsData',
            {
                adminId: userId,
            },
            {
                baseURL: appConstants.WEB_SERVICE_URL_WITHOUT_PARSE,
                headers: {
                    'X-Parse-Application-Id': appConstants.PARSE_APPLICATION_ID,
                    'X-Parse-REST-API-Key': appConstants.PARSE_REST_API_KEY,
                    'X-Parse-Session-Token': localStorage.getItem('sessionToken'),
                    authorization: localStorage.getItem('JWTTOKEN'),
                },
                params: {
                    limit: limit,
                    offset: (page - 1) * limit,
                },
            }
        );

        setList(data);
        setShowProcessing(false);
    };

    const getDataListCount = async () => {
        const { data } = await axios.post(
            'admin/adminProjectsTotalCount',
            {
                adminId: userId,
            },
            {
                baseURL: appConstants.WEB_SERVICE_URL_WITHOUT_PARSE,
                headers: {
                    'X-Parse-Application-Id': appConstants.PARSE_APPLICATION_ID,
                    'X-Parse-REST-API-Key': appConstants.PARSE_REST_API_KEY,
                    'X-Parse-Session-Token': localStorage.getItem('sessionToken'),
                    authorization: localStorage.getItem('JWTTOKEN'),
                },
            }
        );

        setTotal(data.total);
    };

    const onPageChange = (_, page) => {
        setPage(page);
    };

    const onLimitChange = (e) => {
        setLimit(e.target.value);
        setPage(1);
    };

    const formatDateCell = (cell, row, accessor) => {
        if (row && row[accessor]) {
            return <div>{row[accessor] ?? ''}</div>;
        } else {
            return <span>-</span>;
        }
    };

    const formatBooleanCell = (cell, row, accessor) => {
        if (row && row[accessor]) {
            return <div>{row[accessor] == true ? 'true' : 'false'}</div>;
        } else {
            return <span>-</span>;
        }
    };

    const columns = [
        {
            Header: 'Project Id',
            accessor: '_id',
            width: 60,
            hidden: true,
        },
        {
            Header: 'Job Number',
            accessor: 'jobNumFormatted',
            width: 30,
        },
        {
            Header: 'Job Name',
            accessor: 'jobName',
            width: 70,
        },
        {
            Header: 'Status',
            accessor: 'projectStatus',
            Filter: SelectColumnFilter,
            width: 50,
        },
        {
            Header: 'Account',
            Filter: SelectColumnFilter,
            accessor: 'cs.companyName',
            width: 80,
        },
        {
            Header: 'Account Id',
            accessor: 'cs._id',
            Filter: SelectColumnFilter,
            width: 40,
            hidden: true,
        },
        {
            Header: 'Created',
            id: 'createdAt',
            accessor: (row) =>
                row?.createdAt ? new Date(row.createdAt) : formatDateObjectOrNull(row.lastLogin),
            width: 60,
            filter: DateColumnFilter,
            allowDateRangeFilter: true,
            Cell: (props) => formatDateCell(props.column.id, props.row.original, 'createdAt'),
        },
        {
            Header: 'Updated',
            id: 'updatedAt',
            accessor: (row) =>
                row?.updatedAt ? new Date(row.updatedAt) : formatDateObjectOrNull(row.updatedAt),
            width: 60,
            filter: DateColumnFilter,
            allowDateRangeFilter: true,
            Cell: (props) => formatDateCell(props.column.id, props.row.original, 'updatedAt'),
        },
    ];

    return (
        <div>
            <TitleComponent data={{ title: `Administration - All Projects` }} />
            <div className='noteProcessing' style={{ display: showProcessing ? 'block' : 'none' }}>
                <img src={loaderImage} alt='Loading' />
            </div>

            <div className='row'>
                <div className='col-md-12 sortable-table'>
                    <CustomTable
                        columns={columns}
                        list={list}
                        //preferences={this.props.listDataPref}
                        className='project-list-table'
                        customTableTitle={'Project List'}
                        canShowDateFilter={true}
                        //onChangeRowSelection={this.onChangeRowSelection}
                        //onSavePreferences={this.props.SetAdminProjectReportPrefAction}
                        isLoading={showProcessing}
                        canShowHeaderItems={true}
                        canShowRowSelection={false}
                        pageSizeArray={[50, 100, 200]}
                        //componentRef={this.componentRef}
                    />
                </div>
            </div>
        </div>
    );
};

export default ProjectList;
