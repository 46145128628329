import { Checkbox } from 'antd';
import { Control, Controller, useFormContext } from 'react-hook-form';
import { DefaultLayout } from '../control-layouts/default-layout';
import { LayoutProps } from '../control-layouts/layout-props';
import styles from './style.module.scss';

type FormCheckboxProps = {
  name: string;
  control?: Control<any>;
  className?: string;
  label?: string;
  disabled?: boolean;
  required?: boolean;
  onChange?: (e: any) => void;
  Layout?: (props: LayoutProps) => JSX.Element;
};

const FormCheckbox = ({
  name,
  control,
  disabled = false,
  required = false,
  className = '',
  label = '',
  onChange,
  Layout = (props) => <DefaultLayout {...props} className={`${props.className} d-inline-flex`} />,
}: FormCheckboxProps) => {
  const formContext = useFormContext();

  if (!control && !formContext) {
    throw new Error('FormCheckbox must be used within a FormContext or have a control prop');
  }

  return (
    <Controller
      rules={{ required: required && `${label} is required` }}
      name={name}
      control={control || formContext.control}
      render={({ field, fieldState }) => {
        return (
          <Layout
            className={`${className} ${styles.styledFormCheckbox}`}
            name={field.name}
            label={label}
            required={required}
            input={
              <Checkbox
                disabled={disabled}
                id={field.name}
                checked={field.value}
                name={field.name}
                onChange={(e) => {
                  onChange?.(e);
                  field.onChange(e.target.checked);
                }}
              />
            }
            error={fieldState.error?.message}
          />
        );
      }}
    />
  );
};

export default FormCheckbox;
