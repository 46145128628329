import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../../shared/hooks/use-app-dispatch';
import { findContactAffiliations } from '../../../../modules/contacts';
import { getUserSettings } from '../../../../modules/settings';
import { formatPhoneForCall } from '../../../../utils/formatters';

type HeaderProps = {
  projectData: any;
  projectBidData?: any;
};

export const Header = ({ projectData = {}, projectBidData = {} }: HeaderProps) => {
  const {
    jobName,
    jobAddress,
    jobAddress2,
    jobCity,
    jobState,
    jobZip,
    jobNumFormatted,
    altJobNum,
    projectTeam = {},
  } = projectData;

  const [clients, setClients] = useState<Array<any>>([]);
  const [clientRoleId, setClientRoleId] = useState<string>('');

  const { clientArr: bidClientArr } = projectBidData;
  const projectTeamClientArr = projectTeam[clientRoleId];

  const dispatch = useAppDispatch();

  // get client role id
  useEffect(() => {
    dispatch(getUserSettings(['projectRoleTypes']))
      .unwrap()
      .then((roles) => {
        const clRoleId = roles.find((role: any) => role.code === 'CL')?._id || '';
        setClientRoleId(clRoleId);
      });
  }, []);

  useEffect(() => {
    (async () => {
      // get clients from bid or from project team
      const clientAffsIds = bidClientArr || projectTeamClientArr || [];

      // if clients were not showed yet - get their data
      if (clientAffsIds.length > 0 && clients.length === 0) {
        const clientsData = await dispatch(
          findContactAffiliations({
            criteria: `${clientAffsIds.join(' ')}`,
            page: 1,
            count: clientAffsIds.length,
            isAccount: null,
            includeDeleted: true,
            fields: ['_id'],
          })
        ).unwrap();

        setClients([...clientsData]);
      }
    })();
  }, [bidClientArr, projectTeamClientArr, clients]);

  const getClients = () => {
    return (
      <ul>
        {clients.map(
          ({ _id, companyName, contactFullName = '', contactId, primaryEmail, primaryPhone }) => {
            const contactName = contactId ? contactFullName : '';
            const clientName = [companyName, contactName].filter((text) => text).join(' ');
            const email = primaryEmail?.email || '';

            const phone = formatPhoneForCall(primaryPhone || {}) || '';

            return (
              <li key={_id} className='mb-1'>
                {clientName?.length > 0 && (
                  <>
                    {clientName}
                    <br />
                  </>
                )}
                {email?.length > 0 && (
                  <>
                    {email}
                    <br />
                  </>
                )}
                {phone?.length > 0 && (
                  <>
                    {phone}
                    <br />
                  </>
                )}
              </li>
            );
          }
        )}
      </ul>
    );
  };

  const getJobInfo = () => {
    const jobCityStateZip = [jobCity, jobState, jobZip].filter((part) => part).join(', ');

    return (
      <ul className='mb-2'>
        <li>{jobName}</li>
        <li>{jobAddress}</li>
        <li>{jobAddress2}</li>
        <li>{jobCityStateZip}</li>
      </ul>
    );
  };

  const getJobNumbers = () => (
    <ul>
      <li>{jobNumFormatted}</li>
      <li>{altJobNum}</li>
    </ul>
  );

  const bidCount = projectBidData.bidCount || 0;
  const bidRev = projectBidData.bidRev || 0;

  const getBidNumber = () => (
    <ul>
      <li>V{bidCount}.{bidRev}</li>
    </ul>
  );


  return (
    <div className='row' data-testId='bidManagementModalHeader'>
      <div className='col-md-4 pe-2'>
        <div className='grayInfoBox'>
          <span className='fw-bold'>Client(s):</span>
          {getClients()}
        </div>
      </div>
      <div className='col-md-4 pe-2'>
        <div className='grayInfoBox'>
          <span className='fw-bold'>Job Info:</span>
          {getJobInfo()}
        </div>
      </div>
      <div className='col-md-4'>
        <div className='grayInfoBox'>
          <span className='fw-bold'>Job Number:</span>
          {getJobNumbers()}
          <span className='fw-bold'>Bid Version:</span>
          {getBidNumber()}
         
        </div>
      </div>
    </div>
  );
};
