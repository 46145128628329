import moment from 'moment-timezone';
import { Moment } from 'moment';
import { useState, forwardRef, useImperativeHandle, useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import FormDurationPicker from '../../../../shared/form-controls/form-duration-picker/form-duration-picker';
import FormDatePicker from '../../../../shared/form-controls/form-date-picker/form-date-picker';
import { HorizontalLayout } from '../../../../shared/form-controls/control-layouts/horizontal-layout';
import {
  formatDateObjectOrNull,
  formatDateObject,
  formatBidDueDate,
  //formatDateAdd,
} from '../../../../utils/date-formatters';
import { isAllowed, moduleConstants } from '../../../../_constants';
import styles from '../../bid-modal.module.scss';
import { notification } from 'antd';

type BidSchedulerProps = {
  isPending: boolean;
  isLocked: boolean;
  accountSettings?: any;
  initiallyExpanded?: boolean;
};

export const BidScheduler = forwardRef(({
  isPending,
  isLocked,
  initiallyExpanded = false,
  accountSettings = {},
}: BidSchedulerProps, ref) => {
  const form = useFormContext();

  const watchBidStartDate = form.watch('bidStart');
  const watchBidDueDate = form.watch('bidDue');
  const watchBidSubmittedDate = form.watch('bidSubmittedDate');

  const dateTimeFormat = 'MM/DD/yyyy hh:mm a';
  const dateTimeFormatPicker = 'MM/dd/yyyy hh:mm a';
  const hasBidDatePermissions = isAllowed(moduleConstants.EBD);
  const isDisabled = !hasBidDatePermissions || isLocked;
  const { enableEstimatingScheduling } = accountSettings;

  const [isExpanded, setIsExpanded] = useState<boolean>(initiallyExpanded);
  useImperativeHandle(ref, () => ({ setIsExpanded }), []);

  useEffect(() => {
    if (!initiallyExpanded) {
      // keep Bidding schedule expanded if Due Date is empty
      // or if estimating schedule is disabled
      const shouldBeExpanded = !watchBidDueDate || !enableEstimatingScheduling;

      shouldBeExpanded && setIsExpanded(true);
    }
  }, [initiallyExpanded]);

  const getCollapsedSchedule = () => {
    if (watchBidSubmittedDate && isPending) {
      const timezone = watchBidSubmittedDate.zoneAbbr();
      return `Submitted: ${formatBidDueDate(watchBidSubmittedDate, dateTimeFormat)} ${timezone}`;
    }

    if (watchBidDueDate) {
      const timezone = watchBidDueDate.zoneAbbr();
      return `Due: ${formatBidDueDate(watchBidDueDate, dateTimeFormat)} ${timezone}`;
    }

    return null;
  };

  const startDateFormatted = formatDateObjectOrNull(watchBidStartDate);
  const bidDueDateFormatted = formatDateObjectOrNull(watchBidDueDate);

  const startDateRangeProps = {
    ...(startDateFormatted && { startDate: startDateFormatted }),
    ...(bidDueDateFormatted && { endDate: bidDueDateFormatted, maxDate: bidDueDateFormatted }),
  };

  const bidDueDateRangeProps = {
    ...(bidDueDateFormatted && { endDate: bidDueDateFormatted }),
    ...(startDateFormatted && { startDate: startDateFormatted, minDate: startDateFormatted }),
    //...{ maxDate: formatDateAdd(24, 'months', startDateFormatted) },
  };

  // bidSubmitted max date should be week from today
  // or at least week from startDate if case if startDate in future
  //const bidSubmittedStart = startDateFormatted?.isBefore(new Date()) ? '' : startDateFormatted;
  const bidSubmittedDateRangeProps = {
    ...(startDateFormatted && { minDate: startDateFormatted }),
    //...{ maxDate: formatDateAdd(1, 'week', bidSubmittedStart) },
  };

  const defaultTimezone = moment.tz(moment.tz.guess());

  // returns available date range by field name
  const getRangeByFieldName = useCallback(
    (fieldName) => {
      let rangeSource: any = {};

      switch (fieldName) {
        case 'bidStart':
          rangeSource = startDateRangeProps;
          break;
        case 'bidDue':
          rangeSource = bidDueDateRangeProps;
          break;
        case 'bidSubmittedDate':
          rangeSource = bidSubmittedDateRangeProps;
      }

      const { minDate = null, maxDate = null } = rangeSource;

      return { minDate, maxDate };
    },
    [startDateRangeProps, bidSubmittedDateRangeProps, bidDueDateRangeProps]
  );

  // sets date for field by name
  const setFieldDate = (newDate: Moment, name: string, onClose: any) => {
    const { minDate, maxDate } = getRangeByFieldName(name);

    // if selected date is out of range - show warning that selection is incorrect
    if (newDate.isBefore(minDate, 'day') || newDate.isAfter(maxDate, 'day')) {
      notification.warning({ message: 'Selected date if out of range' });
      return;
    }

    form.setValue(name, newDate);
    onClose();
  };

  const onSetTodayClick = (name, onClose) => setFieldDate(formatDateObject(), name, onClose);
  const onSetBidDueClick = (name, onClose) => setFieldDate(form.getValues('bidDue'), name, onClose);

  const customButtons = useCallback(
    ({ onClose, name }) => {
      const isTodayOnly = name === 'bidDue';
      return (
        <div className='d-flex react-datepicker__today-button'>
          <div
            className={`w-${isTodayOnly ? '100' : '50'}`}
            onClick={() => onSetTodayClick(name, onClose)}
          >
            Today
          </div>
          {!isTodayOnly && (
            <div
              className='w-50 border-start border-3'
              onClick={() => onSetBidDueClick(name, onClose)}
            >
              Bid Due Date
            </div>
          )}
        </div>
      );
    },
    [startDateRangeProps, bidSubmittedDateRangeProps, bidDueDateRangeProps]
  );

  const getExpandedSchedule = () => {
    return (
      <>
        {enableEstimatingScheduling && <FormDatePicker
          label='Estimating Start:'
          name='bidStart'
          selectsStart
          disabled={isDisabled}
          todayButton=''
          control={form.control}
          Layout={HorizontalLayout}
          {...startDateRangeProps}
        >
         {customButtons}
        </FormDatePicker>}
        {watchBidStartDate && (
          <FormDurationPicker
            label='Bid Duration:'
            name='bidDuration'
            startDateFieldName='bidStart'
            endDateFieldName='bidDue'
            updateEndOnStartChange={false}
            disabled
            Layout={HorizontalLayout}
          />
        )}
        <FormDatePicker
          required
          allowSameDay
          todayButton=''
          label='Bid Due Date:'
          name='bidDue'
          dateFormat={dateTimeFormatPicker}
          showTimeSelect
          selectsEnd
          disabled={isDisabled}
          showMonthDropdown
          control={form.control}
          Layout={HorizontalLayout}
          inputText={(watchBidDueDate || defaultTimezone).zoneAbbr()}
          {...bidDueDateRangeProps}
        >
          {customButtons}
        </FormDatePicker>
        {isPending && (
          <FormDatePicker
            required
            allowSameDay
            todayButton=''
            label='Bid Submitted'
            name='bidSubmittedDate'
            dateFormat={dateTimeFormatPicker}
            showTimeSelect
            showMonthDropdown
            disabled={isDisabled}
            control={form.control}
            Layout={HorizontalLayout}
            inputText={(watchBidSubmittedDate || defaultTimezone).zoneAbbr()}
            {...bidSubmittedDateRangeProps}
          >
            {customButtons}
          </FormDatePicker>
        )}
      </>
    );
  };

  return (
    <div data-testId='bidManagementModalBidScheduler'>
      <div className='form-group row my-4'>
        <div className={`col-md-4 ${isExpanded ? 'fw-bold' : ''}`}>Bidding Schedule:</div>
        <div className='col-md-6'>{!isExpanded && getCollapsedSchedule()}</div>
        <div className={`col-md-2 ${styles.chevron}`}>
          <i
            data-testId='bidSchedulerToggler'
            onClick={() => setIsExpanded(!isExpanded)}
            className={`fa-solid fa-chevron-${isExpanded ? 'down' : 'right'}`}
            data-title={isExpanded ? 'Collapse' : 'Expand'}
          ></i>
        </div>
      </div>
      {isExpanded && getExpandedSchedule()}
    </div>
  );
});
