import { useFormContext } from 'react-hook-form';
import { HorizontalLayout } from '../../../shared/form-controls/control-layouts/horizontal-layout';
import { isAllowed, moduleConstants } from '../../../_constants';
import { isCurrentOrPrevStage } from '../../../shared/custom-fields-controls/constants';
import FormDatePicker from '../../../shared/form-controls/form-date-picker/form-date-picker';
import FormDurationPicker from '../../../shared/form-controls/form-duration-picker/form-duration-picker';
import { formatDateObjectOrNull } from '../../../utils/date-formatters';
import FormInput from '../../../shared/form-controls/form-input';
import { CurrencyInput } from '../../../shared/form-controls/form-input/currency-input';

const dateTimeFormat = 'MM/dd/yyyy hh:mm a';

type LeadProjectDatesProps = {
  isAssigned?: boolean;
  isUpdate?: boolean;
  accountSettings?: any;
};

export const LeadProjectDates = ({
  isAssigned = false,
  isUpdate = false,
  accountSettings = {},
}:LeadProjectDatesProps) => {

  const form = useFormContext();

  const watchStartDate = form.watch('startDate');
  const watchEndDate = form.watch('endDate');
  const bidDue = form.watch('bidDue');

  const bidDueFormatted = formatDateObjectOrNull(bidDue);
  const startDateFormatted = formatDateObjectOrNull(watchStartDate);
  const endDateFormatted = formatDateObjectOrNull(watchEndDate);

  const startDateRangeProps = {
    ...bidDueFormatted && { minDate: bidDueFormatted },
    ...startDateFormatted && { startDate: startDateFormatted },
    ...endDateFormatted && { endDate: endDateFormatted },
  };

  const endDateRangeProps = {
    ...startDateFormatted && { startDate: startDateFormatted },
    ...endDateFormatted && { endDate: endDateFormatted },
    ...bidDueFormatted && { minDate: bidDueFormatted.clone().add(1, 'day') },
    ...startDateFormatted && { minDate: startDateFormatted.clone().add(1, 'day') },
    ...startDateFormatted && { maxDate: startDateFormatted.clone().add(48, 'months') },
  };

  const assignedUserCheck = (!isUpdate || isAssigned) ? 'anyAssigned' : null;
  const requireConstructionDates = isCurrentOrPrevStage('lead', accountSettings.requireConstructionDatesOn);

  return (
    <>
      <b>Dates & Dollars</b>
      <hr />
      {isAllowed(moduleConstants.EBD, assignedUserCheck) && (
        <FormDatePicker
          label='Bid Date'
          name='bidDue'
          control={form.control}
          showTimeSelect
          dateFormat={dateTimeFormat}
          Layout={HorizontalLayout}
        />
      )}
      {(
        <FormDatePicker
          required={requireConstructionDates}
          label='Const. Start Date'
          name='startDate'
          selectsStart
          control={form.control}
          Layout={HorizontalLayout}
          {...startDateRangeProps}
        />
      )}
      <FormDurationPicker
        label='Duration'
        name='duration'
        startDateFieldName='startDate'
        endDateFieldName='endDate'
        Layout={HorizontalLayout}
      />
      {(
        <FormDatePicker
          required={requireConstructionDates}
          label='Const. End Date'
          name='endDate'
          selectsEnd
          control={form.control}
          Layout={HorizontalLayout}
          {...endDateRangeProps}
        />
      )}
      {isAllowed(moduleConstants.VIEWCONTRACTVAL, assignedUserCheck) &&(<FormInput
        label='Approx. Bid Amount'
        control={form.control}
        name='estimateBidAmount'
        Layout={HorizontalLayout}
        CustomInput={CurrencyInput}
      />)}
      {isAllowed(moduleConstants.VIEWPROFIT, assignedUserCheck) && isAllowed(moduleConstants.EBAGP, assignedUserCheck) && (
        <FormInput
          label='Approx. Gross Profit'
          control={form.control}
          name='estimateGrossProfit'
          Layout={HorizontalLayout}
          CustomInput={CurrencyInput}
        />
      )}
    </>
  );
};
