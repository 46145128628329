import { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { usePageTitle } from '../../layouts/title-context';
import { Scheduler, useSchedulerResources } from '../../shared/scheduler';
import { ResourceScheduleFilterModal } from '../../modals/resource-schedule-filter-modal';
import { affiliationLinkFormatter } from '../../utils/formatters';
import { formatDate } from '../../utils/date-formatters';
import { getActiveScheduleReport } from '../../modules/reports';
import { useAppDispatch } from '../../shared/hooks/use-app-dispatch';
import { selectGetActiveScheduleReport } from '../../modules/reports/selectors';
import { useAppSelector } from '../../shared/hooks/use-app-selector';
import { affiliationsRenderer } from '../../shared/scheduler/affiliations-renderer';
import { uniqueId, isEmpty } from 'lodash';
import moment from 'moment';
import { AddProjectModal } from '../../modals/add-project-modal';
import { BidModal } from '../../modals/bid-modal/bid-modal';
import ProjectStatusModal from '../../components/Common/ProjectStatusModal.jsx';
import { ProjectStatusModalProps } from '../../components/Common/ProjectStatusModalProps.d';
import { getExistingProjectAction } from '../../actions/project';
import { getProjectRoles } from '../../modules/settings';
import { getDragMessage, getRoleName, onBeforeEventDropFinalize } from '../../utils/report-helper';
import { ReassignmentModal } from '../../modals/reassignment-modal';
import { reassignProjects } from '../../modules/projects';

export const ActiveScheduleReportPage = () => {
  usePageTitle('Resource Schedule');

  const [projectRoles, setProjectRoles] = useState<any>([]);
  const dispatch = useAppDispatch();

  const { data: report, loading: reportLoading } = useAppSelector(selectGetActiveScheduleReport);

  const [formDefaultValues, setFormDefaultValues] = useState<any>({
    showBy: 'days',
    startDateOption: 'today',
    groupByOption: 'projectManager',
    jobStatusOption: ['awarded'],
    startDate: moment().startOf('day'),
    groupBy: null,
    isGroupShared: false,
  });

  const [open, setOpen] = useState(false);
  const [filters, setFilters] = useState<any>({});
  const [statusModal, setStatusModal] = useState(false);
  const [editProjectId, setEditProjectId] = useState<string>('');
  const [statusUpdateType, setStatusModalUpdateType] = useState('');
  const [schedulerFilters, setSchedulerFilters] = useState<any>({});
  const [reassignmentChanges, setReassignmentChanges] = useState<Array<any>>([]);
  const [isReassignModalOpen, setIsReassignModalOpen] = useState<boolean>(false);
  const [isAddProjectModalOpen, setIsAddProjectModalOpen] = useState<boolean>(false);
  const [isBidManagementModalOpen, setIsBidManagementModalOpen] = useState<boolean>(false);

  const schedulerRef = useRef<{ setIsReadOnly: (isReadOnly: boolean) => void }>({
    setIsReadOnly: (isReadOnly: boolean) => isReadOnly,
  });

  const addProjectModalRef = useRef<{ getProjectData: () => void }>({
    getProjectData: () => undefined,
  });

  const afterStatusUpdate = () => {
    getData();
  };

  const toggleStatusUpdate = (updateType) => {
    if (!statusModal) {
      setStatusModalUpdateType(updateType);
      setStatusModal(!statusModal);
    } else {
      setStatusModalUpdateType('');
      setStatusModal(false);
    }
  };

  const getData = useCallback(() => {
    if (!isEmpty(filters)) {
      dispatch(getActiveScheduleReport(filters)).unwrap();
    }
  }, [filters]);

  useEffect(() => {
    const filtersStorage = localStorage.getItem('activeScheduleReportFilters');

    if (!filtersStorage) {
      setOpen(true);
      return;
    }

    const settings = JSON.parse(filtersStorage);

    const { dataFilters, schedulerSettings, formSettings } = settings;

    setFilters(dataFilters);
    setSchedulerFilters(schedulerSettings);
    setFormDefaultValues({ ...formSettings, startDate: moment(formSettings.startDate) });
    dispatch(getProjectRoles())
      .unwrap()
      .then((roles) => setProjectRoles(roles));
  }, []);

  useEffect(() => {
    getData();
  }, [filters]);

  const getProjectFields = (project) => ({
    jobName: project.jobName,
    statusName: project.statusName,
    startDate: project.startDate?.length > 0 ? moment.utc(project.startDate).toDate() : '',
    projectId: project._id,
    endDate: project.endDate?.length > 0 ? moment.utc(project.endDate).toDate() : '',
    jobNumber: project.jobNumber,
    altJobNum: project.altJobNum,
    statusCode: project.statusCode,
    jobAddress: project.jobAddress,
    jobAddress2: project.jobAddress2,
    jobCity: project.jobCity,
    jobState: project.jobState,
    jobZip: project.jobZip,
    jobCountry: project.jobCountry,
    lat: project.lat,
    lon: project.lon,
    timezone: project.timezone,
    projectManagers: project.projectManagers,
    superintendents: project.superintendents,
    clients: project.clients,
    deliveredDate:
      project.deliveredDate?.length > 0 ? moment.utc(project.deliveredDate).toDate() : '',
    closeoutDate: project.closeoutDate?.length > 0 ? moment.utc(project.closeoutDate).toDate() : '',
    baselineStartDate:
      project.baselineStartDate?.length > 0 ? moment.utc(project.baselineStartDate).toDate() : '',
    currentBaselineEndDate:
      project.currentBaselineEndDate?.length > 0
        ? moment.utc(project.currentBaselineEndDate).toDate()
        : '',
    warrExpiration: project.warrExpiration,
    lienExpiration: project.lienExpiration,
  });

  const getContactFields = (contact) => ({
    contactAffiliationId: contact._id,
    contactFirstName: contact.contactFirstName,
    contactLastName: contact.contactLastName,
    companyName: contact.companyName,
    contactId: contact.contactId,
    companyId: contact.companyId,
    isAccountAffiliation: contact.isAccountAffiliation,
    isInternal: contact.isInternal,
    eventColor: contact.calendarColor,
    imageUrl: contact.userImage,
  });

  const resources = useSchedulerResources({
    groupBy: schedulerFilters.groupBy,
    isGroupShared: schedulerFilters.isGroupShared,
    report,
    getProjectFields,
    getContactFields,
  });

  const modalConfig = useMemo(
    () => ({
      statusOptions: [
        {
          value: 'active',
          label: 'Active (A)',
        },
        {
          value: 'delivered',
          label: 'Delivered (D)',
        },
        {
          value: 'preconstruction',
          label: 'Preconstruction / Not Started (P1,P2,P3,SNS)',
        },
        {
          value: 'bidsPending',
          label: 'Bids Pending (BP)',
        },
        {
          value: 'suspended',
          label: 'Include Suspended / On Hold (SUSP,OH)',
        },
        {
          value: 'closedOut',
          label: 'Closed Out (W,C)',
        },
      ],
      getStatusCodesFromOptions: (options: any[]) => {
        const codes = [] as string[];

        if (options.includes('active')) {
          codes.push('A');
        }

        if (options.includes('delivered')) {
          codes.push('D');
        }

        if (options.includes('preconstruction')) {
          codes.push('P1', 'P2', 'P3', 'P1P', 'P2P', 'P3P','SNS');
        }

        if (options.includes('bidsPending')) {
          codes.push('BP');
        }

        if (options.includes('suspended')) {
          codes.push('SUSP');

          if (options.includes('preconstruction')) {
            codes.push('OH', 'OHP', 'PC');
          }

          if (options.includes('bidsPending')) {
            codes.push('OHP');
          }
        }

        if (options.includes('closedOut')) {
          codes.push('W', 'C');
        }

        return codes;
      },
      groupByOptions: [
        {
          value: 'none',
          label: 'None',
        },
        {
          value: 'projectManager',
          label: 'Project Manager',
          code: 'PM',
        },
        {
          value: 'superintendent',
          label: 'Superintendent',
          code: 'SI',
        },
        {
          value: 'client',
          label: 'Client',
          code: 'CL',
        },
      ],
    }),
    []
  );

  const dateRenderer = (recordData) => (recordData.value ? formatDate(recordData.value) : '');

  const resourceStore = useMemo(
    () => ({
      fields: [
        'jobName',
        'statusName',
        'startDate',
        'endDate',
        'jobNumber',
        'statusCode',
        'altJobNum',
        'jobAddress',
        'jobAddress2',
        'jobCity',
        'jobState',
        'jobZip',
        'jobCountry',
        'lat',
        'lon',
        'timezone',
        'projectManagers',
        'superintendents',
        'clients',
        'deliveredDate',
        'closeoutDate',
        'warrExpiration',
        'lienExpiration',
      ],
    }),
    []
  );

  const columns = useMemo(
    () => [
      {
        type: 'tree',
        text: 'Project Name',
        field: 'jobName',
        minWidth: 300,
        align: 'left',
        htmlEncode: false,
        autoWidth: true,
        searchable: true,
        filterable: {
          filterField: {
            placeholder: 'Filter Project Name',
          },
        },
        renderer: (recordData) => {
          const { record } = recordData;

          const isContact = record.data.isContact;
          if (isContact) {
            const { contactAffiliationId } = record.data;

            if (contactAffiliationId === 'unassigned') {
              return '<span>Unassigned</span>';
            }

            return affiliationLinkFormatter(record.data);
          }

          const slug = recordData.record.data.projectId;

          return (
            <a
              href={`/project-details/${slug}`}
              target='_blank'
              rel='noreferrer'
              data-disable-bullets={recordData.record.data.disableBullets || false}
            >
              {recordData.record.data.jobName}
            </a>
          );
        },
      },
      {
        text: 'Start',
        field: 'startDate',
        align: 'center',
        type: 'date',
        filterable: {
          filterField: {
            type: 'date',
          },
        },
        //filterable: false,
        renderer: dateRenderer,
      },
      {
        text: 'End',
        field: 'endDate',
        align: 'center',
        type: 'date',
        renderer: dateRenderer,
        filterable: {
          filterField: {
            type: 'date',
          },
        },
        hidden: false,
      },
      {
        text: 'Delivered',
        field: 'deliveredDate',
        align: 'center',
        type: 'date',
        renderer: dateRenderer,
        filterable: {
          filterField: {
            type: 'date',
          },
        },
        hidden: false,
      },
      {
        text: 'Contract Start',
        field: 'baselineStartDate',
        type: 'date',
        filterable: {
          filterField: {
            type: 'date',
          },
        },
        align: 'center',
        hidden: true,
        renderer: dateRenderer,
      },
      {
        text: 'Contract End',
        field: 'currentBaselineEndDate',
        type: 'date',
        filterable: {
          filterField: {
            type: 'date',
          },
        },
        align: 'center',
        hidden: true,
        renderer: dateRenderer,
      },
      {
        text: 'Status',
        field: 'statusCode',
        align: 'center',
        autoWidth: false,
        hidden: false,
        width: 40,
        filterable: {
          filterField: {
            type: 'combo',
            multiSelect: true,
            operator: '=',
            inlinePicker: true,
            placeholder: 'Select Status',
          },
          filterFn: (record) =>
            !(record.value?.length > 0) ||
            record.value.includes(record.record.data[record.property]),
        },
      },
      {
        text: 'Status Name',
        field: 'statusName',
        align: 'center',
        autoWidth: true,
        hidden: true,
        filterable: {
          filterField: {
            type: 'combo',
            multiSelect: true,
            operator: '=',
            inlinePicker: true,
            placeholder: 'Select Status',
          },
          filterFn: (record) =>
            !(record.value?.length > 0) ||
            record.value.includes(record.record.data[record.property]),
        },
      },
      {
        text: 'Job Number',
        field: 'jobNumber',
        align: 'center',
        hidden: true,
      },
      {
        text: 'Alternate Job Number',
        field: 'altJobNum',
        align: 'center',
        hidden: true,
      },
      {
        text: 'Closeout',
        field: 'closeoutDate',
        type: 'date',
        filterable: {
          filterField: {
            type: 'date',
          },
        },
        align: 'center',
        hidden: true,
        renderer: dateRenderer,
      },
      {
        text: 'Address',
        field: 'jobAddress',
        align: 'center',
        hidden: true,
        renderer: (recordData) => {
          const { record } = recordData;
          const { originalData } = record;

          let jobAddressString =
            (originalData.jobAddress ? originalData.jobAddress : '') +
            (originalData.jobAddress ? ', ' : '') +
            (originalData.jobAddress2 ? originalData.jobAddress2 : '') +
            (originalData.jobAddress2 ? ', ' : '');
          jobAddressString +=
            (originalData.jobCity ? originalData.jobCity : '') +
            (originalData.jobCity ? ', ' : '') +
            (originalData.jobState ? originalData.jobState : '') +
            (originalData.jobState ? ', ' : '') +
            (originalData.jobZip ? originalData.jobZip : '');

          return (
            <div className='d-flex flex-column px-1'>
              <span>{jobAddressString}</span>
            </div>
          );
        },
      },
      {
        text: 'Warr Expr',
        field: 'warrExpiration',
        type: 'date',
        filterable: {
          filterField: {
            type: 'date',
          },
        },
        align: 'center',
        hidden: true,
        renderer: dateRenderer,
      },
      {
        text: 'Lien Expr',
        field: 'lienExpiration',
        type: 'date',
        filterable: {
          filterField: {
            type: 'date',
          },
        },
        align: 'center',
        hidden: true,
        renderer: dateRenderer,
      },
      {
        text: 'Project Manager',
        field: 'projectManagers',
        type: 'widget',
        autoWidth: true,
        minWidth: 150,
        hidden: true,
        // render as jsx list of links
        renderer: affiliationsRenderer('projectManagers'),
      },
      {
        text: 'Superintendent',
        field: 'superintendents',
        type: 'widget',
        hidden: true,
        autoWidth: true,
        minWidth: 150,
        renderer: affiliationsRenderer('superintendents'),
      },
      {
        text: 'Client',
        field: 'clients',
        type: 'widget',
        hidden: true,
        autoWidth: true,
        minWidth: 150,
        renderer: affiliationsRenderer('clients'),
      },
    ],
    []
  );

  const events = useMemo(() => {
    const result: any[] = [];

    const getResourcePrefix = (contact, project) => {
      if (schedulerFilters.isGroupShared || !schedulerFilters.groupBy) {
        return `${project._id}`;
      }

      return `${contact._id}-${project._id}`;
    };

    const pushEvent = (contact, project) => {
      const {
        statusColor,
        startDate,
        endDate,
        baselineStartDate,
        currentBaselineEndDate,
        deliveredDate,
        statusName,
        prevStatusName,
        activeStatusColor,
        deliveredStatusColor,
        closeoutDate,
        activeStatusName,
        deliveredStatusName,
        statusCode,
      } = project;

      const isActivePlus = ['A', 'SNS', 'D', 'W', 'C', 'SUSP'].includes(statusCode);
      const isDelivered = ['D', 'W', 'C'].includes(statusCode);

      const isClosed = ['XT','W','C'].includes(statusCode);

      const { r, g, b, a } = statusColor || { r: 0, g: 0, b: 0, a: 1 };
      const { r: ar, g: ag, b: ab, a: aa } = activeStatusColor || { r: 0, g: 1, b: 0, a: 1 };
      const { r: dr, g: dg, b: db, a: da } = deliveredStatusColor || { r: 0, g: 0, b: 1, a: 1 };

      const today = new Date().toISOString();

      const shouldHaveBeenDelivered = isActivePlus && endDate <= today && !isDelivered;

      const resourcePrefix = getResourcePrefix(contact, project);

      const prevStatusNameFormatted = prevStatusName ? ` (${prevStatusName})` : '';

      if (startDate >= baselineStartDate && isActivePlus) {
        result.push({
          id: uniqueId(`${resourcePrefix}-${project.statusCode}-`),
          resourceId: `${resourcePrefix}`,
          //name: `Contract Start`,

          startDate: baselineStartDate,
          endDate: baselineStartDate,
          eventColor: `rgba(${0}, ${0}, ${0}, ${1})`,
        });
        if (startDate > baselineStartDate) {
          result.push({
            id: uniqueId(`${resourcePrefix}-${project.statusCode}-BaselineStart`),
            resourceId: `${resourcePrefix}`,
            name: ``,
            startDate: baselineStartDate,
            endDate: startDate,
            eventColor: `rgba(${ar}, ${ag}, ${ab}, ${aa})`,
            eventStyle: 'dashed',
          });
        }
      }

      if (!isDelivered) {
        if (currentBaselineEndDate >= endDate || !isActivePlus) {
          result.push({
            id: uniqueId(`${resourcePrefix}-${project.statusCode}-Construction`),
            resourceId: `${resourcePrefix}`,
            name: `${statusName}${prevStatusNameFormatted}`,
            startDate: startDate,
            endDate: endDate,
            eventColor: `rgba(${r}, ${g}, ${b}, ${a})`,
          });

          // don't show contract stuff if not active
          if (isActivePlus && !shouldHaveBeenDelivered) {
            if (currentBaselineEndDate > endDate) {
              result.push({
                id: uniqueId(`${resourcePrefix}-${project.statusCode}-BaselineEnd`),
                resourceId: `${resourcePrefix}`,
                name: ``,
                startDate: endDate,
                endDate: currentBaselineEndDate,
                eventColor: `rgba(${ar}, ${ag}, ${ab}, ${aa})`,
                eventStyle: 'dashed',
              });
            }
            result.push({
              id: uniqueId(`${resourcePrefix}-${project.statusCode}-`),
              resourceId: `${resourcePrefix}`,
              name: `Contract End`,
              startDate: currentBaselineEndDate,
              endDate: currentBaselineEndDate,
              eventColor: `rgba(${0}, ${0}, ${0}, ${1})`,
            });
          } else if (shouldHaveBeenDelivered) {
            if (currentBaselineEndDate > today) {
              result.push({
                id: uniqueId(`${resourcePrefix}-${project.statusCode}-`),
                resourceId: `${resourcePrefix}`,
                name: `Contract End`,
                startDate: currentBaselineEndDate,
                endDate: currentBaselineEndDate,
                eventColor: `rgba(${0}, ${0}, ${0}, ${1})`,
              });
            } else {
              if (currentBaselineEndDate <= today && !isDelivered) {
                result.push({
                  id: uniqueId(`${resourcePrefix}-${project.statusCode}-ContractEnd`),
                  resourceId: `${resourcePrefix}`,
                  name: ``,
                  startDate: currentBaselineEndDate,
                  endDate: currentBaselineEndDate,
                  eventColor: `rgba(${255}, ${0}, ${0}, ${1})`,
                });
              } else {
                result.push({
                  id: uniqueId(`${resourcePrefix}-${project.statusCode}-ContractEnd`),
                  resourceId: `${resourcePrefix}`,
                  name: ``,
                  startDate: currentBaselineEndDate,
                  endDate: currentBaselineEndDate,
                  eventColor: `rgba(${0}, ${0}, ${0}, ${1})`,
                });
              }
            }
          }
        } else if (
          currentBaselineEndDate < endDate &&
          currentBaselineEndDate > startDate &&
          isActivePlus
        ) {
          // expected to be over due
          result.push({
            id: uniqueId(`${resourcePrefix}-${project.statusCode}-Construction`),
            resourceId: `${resourcePrefix}`,
            name: `${statusName}${prevStatusNameFormatted}`,
            startDate: startDate,
            endDate: currentBaselineEndDate,
            eventColor: `rgba(${r}, ${g}, ${b}, ${a})`,
          });
          result.push({
            id: uniqueId(`${resourcePrefix}-${project.statusCode}-BaselineEnd`),
            resourceId: `${resourcePrefix}`,
            name: ``,
            startDate: currentBaselineEndDate,
            endDate: currentBaselineEndDate,
            eventColor: `rgba(${255}, ${0}, ${0}, ${1})`,
          });
          result.push({
            id: uniqueId(`${resourcePrefix}-${project.statusCode}-PastContract`),
            resourceId: `${resourcePrefix}`,
            name: `Anticipated late`,
            startDate: currentBaselineEndDate,
            endDate: endDate,
            eventStyle: 'hollow',
            eventColor: `rgba(${ar}, ${ag}, ${ab}, ${1})`,
          });
        } else {
          result.push({
            id: uniqueId(`${resourcePrefix}-${project.statusCode}-Construction`),
            resourceId: `${resourcePrefix}`,
            name: `${statusName}${prevStatusNameFormatted}`,
            startDate: startDate,
            endDate: endDate,
            eventColor: `rgba(${r}, ${g}, ${b}, ${a})`,
          });
        }
      }

      if (isDelivered) {
        // early
        if (currentBaselineEndDate >= deliveredDate) {
          result.push({
            id: uniqueId(`${resourcePrefix}-${project.statusCode}-Construction`),
            resourceId: `${resourcePrefix}`,
            name: `${activeStatusName}`,
            startDate: startDate,
            endDate: deliveredDate,
            eventColor: `rgba(${ar}, ${ag}, ${ab}, ${aa})`,
          });
          /*result.push({
            id: uniqueId(`${resourcePrefix}-${project.statusCode}-Delivered Date`),
            resourceId: `${resourcePrefix}`,
            name: ``,
            startDate: deliveredDate,
            endDate: deliveredDate,
            eventColor: `rgba(${dr}, ${dg}, ${db}, ${da})`,
          });*/
          /*
          result.push({
            id: uniqueId(`${resourcePrefix}-${project.statusCode}-BaselineEnd`),
            resourceId: `${resourcePrefix}`,
            name: `Delivered`,
            startDate: deliveredDate,
            endDate: currentBaselineEndDate,
            eventColor: `rgba(${dr}, ${dg}, ${db}, ${da})`,
            //eventStyle: 'dashed',
          });*/
        } else if (deliveredDate > currentBaselineEndDate) {
          result.push({
            id: uniqueId(`${resourcePrefix}-${project.statusCode}-Construction`),
            resourceId: `${resourcePrefix}`,
            name: `${activeStatusName}`,
            startDate: startDate,
            endDate: currentBaselineEndDate,
            eventColor: `rgba(${ar}, ${ag}, ${ab}, ${aa})`,
          });
          result.push({
            id: uniqueId(`${resourcePrefix}-${project.statusCode}-ContractEnd`),
            resourceId: `${resourcePrefix}`,
            name: ``,
            startDate: currentBaselineEndDate,
            endDate: currentBaselineEndDate,
            eventColor: `rgba(${255}, ${0}, ${0}, ${1})`,
          });

          result.push({
            id: uniqueId(`${resourcePrefix}-${project.statusCode}-Overdue period`),
            resourceId: `${resourcePrefix}`,
            name: `Late`,
            startDate: currentBaselineEndDate,
            endDate: deliveredDate,
            eventColor: `rgba(${ar}, ${ag}, ${ab}, ${1})`,
          });
        } else {
          // on time
          /*
          result.push({
            id: uniqueId(`${resourcePrefix}-${project.statusCode}-DeliveredDate`),
            resourceId: `${resourcePrefix}`,
            name: `${deliveredStatusName}`,
            startDate: deliveredDate,
            endDate: deliveredDate,
            eventColor: `rgba(${dr}, ${dg}, ${db}, ${da})`,
          });*/
        }

        // }

        const showCloseoutEndPeriod = closeoutDate && closeoutDate > deliveredDate;

        if (closeoutDate) {
          if (closeoutDate < currentBaselineEndDate) {
              result.push({
                id: uniqueId(`${resourcePrefix}-${project.statusCode}-BaselineEnd`),
                resourceId: `${resourcePrefix}`,
                name: ``,
                startDate: closeoutDate,
                endDate: currentBaselineEndDate,
                eventColor: `rgba(${dr}, ${dg}, ${db}, ${da})`,
                eventStyle: 'dashed',
              });
            result.push({
              id: uniqueId(`${resourcePrefix}-${project.statusCode}-ContractEnd`),
              resourceId: `${resourcePrefix}`,
              name: `Contract End`,
              startDate: currentBaselineEndDate,
              endDate: currentBaselineEndDate,
              eventColor: `rgba(${0}, ${0}, ${0}, ${1})`,
            });
          } else {
            result.push({
              id: uniqueId(`${resourcePrefix}-${project.statusCode}-ContractEnd`),
              resourceId: `${resourcePrefix}`,
              name: ``,
              startDate: currentBaselineEndDate,
              endDate: currentBaselineEndDate,
              eventColor: `rgba(${0}, ${0}, ${0}, ${1})`,
            });
          }
          /*
          result.push({
            id: uniqueId(`${resourcePrefix}-${project.statusCode}-`),
            resourceId: `${resourcePrefix}`,
            name:
              !showCloseoutEndPeriod && !(deliveredDate == closeoutDate)
                ? `${deliveredStatusName}`
                : '',
            startDate: deliveredDate,
            endDate: deliveredDate,
            eventColor: `rgba(${dr * 0.75}, ${dg * 0.75}, ${db * 0.75}, ${1})`,
          });*/

          if (showCloseoutEndPeriod && deliveredDate < closeoutDate) {
            result.push({
              id: uniqueId(`${resourcePrefix}-${project.statusCode}-DeliveredPeriod`),
              resourceId: `${resourcePrefix}`,
              name: `${deliveredStatusName}`,
              startDate: deliveredDate,
              endDate: closeoutDate,
              eventColor: `rgba(${dr}, ${dg}, ${db}, ${da})`,
            });
          }

          /*
          result.push({
            id: uniqueId(`${resourcePrefix}-${project.statusCode}-CloseoutDate`),
            resourceId: `${resourcePrefix}`,
            name: `Closed out`,
            startDate: closeoutDate,
            endDate: closeoutDate,
            eventColor: `rgba(${dr * 0.75}, ${dg * 0.75}, ${db * 0.75}, ${1})`,
          });*/
        } else if (deliveredDate) {
          if (currentBaselineEndDate > today) {
            result.push({
              id: uniqueId(`${resourcePrefix}-${project.statusCode}-BaselineEnd`),
              resourceId: `${resourcePrefix}`,
              name: ``,
              startDate: today,
              endDate: currentBaselineEndDate,
              eventColor: `rgba(${dr}, ${dg}, ${db}, ${da})`,
              eventStyle: 'dashed',
            });
            result.push({
              id: uniqueId(`${resourcePrefix}-${project.statusCode}-ContractEnd`),
              resourceId: `${resourcePrefix}`,
              name: `Contract End`,
              startDate: currentBaselineEndDate,
              endDate: currentBaselineEndDate,
              eventColor: `rgba(${0}, ${0}, ${0}, ${1})`,
            });
          } else {
            result.push({
              id: uniqueId(`${resourcePrefix}-${project.statusCode}-ContractEnd`),
              resourceId: `${resourcePrefix}`,
              name: ``,
              startDate: currentBaselineEndDate,
              endDate: currentBaselineEndDate,
              eventColor: `rgba(${0}, ${0}, ${0}, ${1})`,
            });
          }
        }
      }

      if (endDate <= today && !isDelivered) {
        result.push({
          id: uniqueId(`${resourcePrefix}-${project.statusCode}-NotDelivered`),
          resourceId: `${resourcePrefix}`,
          name: ``,
          startDate: endDate,
          endDate: today,
          eventStyle: 'dashed',
          eventColor: `rgba(255, 0, 0, 255)`,
        });
      }

      if (isDelivered && deliveredDate <= today && !closeoutDate && !isClosed) {
        result.push({
          id: uniqueId(`${resourcePrefix}-${project.statusCode}-NotClosedOut`),
          resourceId: `${resourcePrefix}`,
          name: `Delivered (Not closed out)`,
          startDate: deliveredDate,
          endDate: today,
          eventStyle: 'hollow',
          eventColor: `rgba(${dr}, ${dg}, ${db}, ${da})`,
        });
      }
    };

    if (!schedulerFilters.isGroupShared && schedulerFilters.groupBy) {
      report?.forEach((contact) => {
        contact.projects?.forEach((project) => pushEvent(contact, project));
      });
    } else {
      report?.forEach((project) => {
        pushEvent(null, project);
      });
    }

    return result;
  }, [report, schedulerFilters.isGroupShared, schedulerFilters.groupBy]);

  const onFiltersApplied = async (settings) => {
    const { dataFilters, schedulerSettings } = settings;

    setFilters(dataFilters);
    setSchedulerFilters(schedulerSettings);
    await dispatch(getActiveScheduleReport(dataFilters)).unwrap();

    setFormDefaultValues(settings.formSettings);
    localStorage.setItem('activeScheduleReportFilters', JSON.stringify(settings));
  };

  const visibleColumns = useMemo(() => {
    const storedColumns = localStorage.getItem('activeScheduleReportColumns');

    if (storedColumns) {
      return JSON.parse(storedColumns);
    }

    return columns.filter((column) => !column.hidden).map((column) => column.field);
  }, [columns]);

  const handleColumnHide = (columnName) => {
    const preferences = localStorage.getItem('activeScheduleReportColumns');

    if (preferences) {
      const parsedPreferences = JSON.parse(preferences);

      const visibleColumns = parsedPreferences.filter((column) => column !== columnName);

      localStorage.setItem('activeScheduleReportColumns', JSON.stringify(visibleColumns));
    } else {
      localStorage.setItem(
        'activeScheduleReportColumns',
        JSON.stringify(visibleColumns.filter((column) => column !== columnName))
      );
    }
  };

  const handleColumnShow = (columnName) => {
    const preferences = localStorage.getItem('activeScheduleReportColumns');

    if (preferences) {
      const parsedPreferences = JSON.parse(preferences);

      const visibleColumns = [...parsedPreferences, columnName];

      localStorage.setItem('activeScheduleReportColumns', JSON.stringify(visibleColumns));
    } else {
      localStorage.setItem(
        'activeScheduleReportColumns',
        JSON.stringify([...visibleColumns, columnName])
      );
    }
  };

  const columnNames = useMemo(() => {
    return [
      {
        field: 'clients',
        text: projectRoles?.find((role) => role.code === 'CL')?.name || 'Client',
      },
      {
        field: 'projectManagers',
        text: projectRoles?.find((role) => role.code === 'PM')?.name || 'Project Manager',
      },
      {
        field: 'superintendents',
        text: projectRoles?.find((role) => role.code === 'SI')?.name || 'Superintendent',
      },
    ];
  }, [projectRoles]);

  const ProjectStatusModalComponent: React.ComponentType<ProjectStatusModalProps> =
    ProjectStatusModal;

  const onEditSubmit = useCallback(async () => {
    getData();
  }, [getData]);

  const onOpenBidModalClick = useCallback((projectId: string) => {
    if (projectId) {
      setEditProjectId(projectId);
      setIsBidManagementModalOpen(true);
    }
  }, []);

  const onEditProjectButtonClick = useCallback((projectId: string) => {
    if (projectId) {
      setEditProjectId(projectId);
      setIsAddProjectModalOpen(true);
    }
  }, []);

  const onBidManagementModalSubmit = useCallback(() => {
    addProjectModalRef.current.getProjectData();
    getData();
  }, []);

  const onUpdateWIP = useCallback(async (projectId: string) => {
    if (projectId) {
      await dispatch(getExistingProjectAction({ projectId }));
      setEditProjectId(projectId);
      toggleStatusUpdate('UpdateWIP');
    }
  }, []);

  const onCloseout = useCallback(async (projectId: string) => {
    if (projectId) {
      await dispatch(getExistingProjectAction({ projectId }));
      setEditProjectId(projectId);
      toggleStatusUpdate('Closeout');
    }
  }, []);

  const onMarkDelivered = useCallback(async (projectId: string) => {
    if (projectId) {
      await dispatch(getExistingProjectAction({ projectId }));
      setEditProjectId(projectId);
      toggleStatusUpdate('Delivered');
    }
  }, []);

  const onSuspend = useCallback(async (projectId: string) => {
    if (projectId) {
      await dispatch(getExistingProjectAction({ projectId }));
      setEditProjectId(projectId);
      toggleStatusUpdate('Suspended');
    }
  }, []);

  const onUnsuspend = useCallback(async (projectId: string) => {
    if (projectId) {
      await dispatch(getExistingProjectAction({ projectId }));
      setEditProjectId(projectId);
      toggleStatusUpdate('Unsuspend');
    }
  }, []);

  const onProceedToActive = useCallback(async (projectId: string) => {
    if (projectId) {
      await dispatch(getExistingProjectAction({ projectId }));
      setEditProjectId(projectId);
      toggleStatusUpdate('ProceedToActive');
    }
  }, []);

  const getProjectId = useCallback(
    (event: any) =>
      event.eventRecord?.data?.resourceId.includes('-')
        ? event.eventRecord?.data?.resourceId?.split('-')?.[1]
        : event.eventRecord?.data?.resourceId,
    []
  );

  const getProjectStatusCode = useCallback(
    (eventRecord: any) =>
      eventRecord?.data?.id.includes('-') ? eventRecord?.data?.id?.split('-')?.[2] : '',
    []
  );

  const eventMenuFeature = {
    items: {
      copyEvent: false,
      cutEvent: false,
      deleteEvent: false,
      unassignEvent: false,
    },
    processItems({ eventRecord, items }) {
      const statusCode = getProjectStatusCode(eventRecord);

      items.editProject = {
        text: 'Edit Project',
        icon: 'fa fa-pencil',
        onItem: (event: any) => onEditProjectButtonClick(getProjectId(event)),
      };
      if (statusCode === 'D') {
        items.closeoutProject = {
          text: 'Closeout Project',
          icon: 'fa-solid fa-file-lines',
          onItem: (event: any) => onCloseout(getProjectId(event)),
        };
      }
      if (statusCode === 'A' || statusCode === 'SNS') {
        if (statusCode === 'SNS') {
          items.proceedToActive = {
            text: 'Proceed to Active',
            icon: 'fa-solid fa-file-lines',
            onItem: (event: any) => onProceedToActive(getProjectId(event)),
          };
        }

        items.updateProgress = {
          text: 'Update Progress',
          icon: 'fa-solid fa-file-lines',
          onItem: (event: any) => onUpdateWIP(getProjectId(event)),
        };
        if (statusCode === 'A') {
          items.markDelivered = {
            text: 'Mark Delivered',
            icon: 'fa-solid fa-file-lines',
            onItem: (event: any) => onMarkDelivered(getProjectId(event)),
          };
        }
      }
      if (statusCode === 'A' || statusCode === 'SNS') {
        items.suspendProject = {
          text: 'Suspend Project',
          icon: 'fa-solid fa-file-lines',
          onItem: (event: any) => onSuspend(getProjectId(event)),
        };
      } else if (statusCode === 'SUSP') {
        items.unSuspendProject = {
          text: 'Unsuspend Project',
          icon: 'fa-solid fa-file-lines',
          onItem: (event: any) => onUnsuspend(getProjectId(event)),
        };
      }
    },
  };

  const eventDragFeature = useMemo(
    () => ({
      constrainDragToTimeSlot: true,
      validatorFn({ eventRecords, newResource }) {
        const sourceParentId = eventRecords[0]?.resource.parentId;
        const targetParentId = newResource.parentId;
        const targetIsRoot = targetParentId === null;
        const targetResource = targetIsRoot ? newResource : newResource.parent;

        const valid = targetIsRoot
          ? newResource.id !== sourceParentId
          : sourceParentId !== targetParentId;
        const message = getDragMessage(targetResource.data, valid);

        return { valid, message };
      },
    }),
    []
  );

  const onSaveReassign = useCallback(
    async (changes: any) => {
      setReassignmentChanges(changes);
      setIsReassignModalOpen(true);
    },
    [schedulerFilters.groupBy, resources]
  );

  const onAfterSaveReassign = useCallback(
    async (data: any) => {
      schedulerRef.current.setIsReadOnly(true);
      await dispatch(reassignProjects({ roleId: schedulerFilters.groupBy, data })).unwrap();
      getData(); // refresh data from server
    },
    [schedulerFilters.groupBy]
  );

  const roleName = useMemo(
    () => getRoleName(projectRoles, schedulerFilters.groupBy),
    [projectRoles, schedulerFilters.groupBy]
  );

  return (
    <>
      <ResourceScheduleFilterModal
        open={open}
        onClose={() => setOpen(false)}
        config={modalConfig}
        onFiltersApplied={onFiltersApplied}
        defaultValues={formDefaultValues}
        title='Resource Schedule Preferences'
      />
      <AddProjectModal
        open={isAddProjectModalOpen}
        showSaveAndNext={false}
        onClose={() => setIsAddProjectModalOpen(false)}
        projectId={editProjectId}
        onSubmit={onEditSubmit}
        onManageBid={() => onOpenBidModalClick(editProjectId)}
        ref={addProjectModalRef}
      />
      <BidModal
        isOpen={isBidManagementModalOpen}
        toggle={() => setIsBidManagementModalOpen(false)}
        toggleStatusUpdate={(updateType) => toggleStatusUpdate(updateType)}
        onSubmit={onBidManagementModalSubmit}
        projectId={editProjectId}
        showSaveSubmit={!isAddProjectModalOpen}
      />
      <ProjectStatusModalComponent
        projectId={editProjectId}
        modalStatusUpdate={statusModal}
        initialUpdateType={statusUpdateType}
        toggle={toggleStatusUpdate}
        onAfterUpdate={afterStatusUpdate}
      />
      <ReassignmentModal
        changes={reassignmentChanges}
        resources={resources}
        onAfterSave={onAfterSaveReassign}
        roleName={roleName}
        isOpen={isReassignModalOpen}
        toggle={() => setIsReassignModalOpen(false)}
      />

      <Scheduler
        title='Resource Schedule'
        loading={reportLoading}
        startDate={schedulerFilters.startDate}
        endDate={schedulerFilters.endDate}
        eventMenuFeature={eventMenuFeature}
        eventDragFeature={eventDragFeature}
        viewPreset={schedulerFilters.viewPreset}
        events={events}
        resources={resources}
        columns={columns}
        resourceStore={resourceStore}
        onApplyClick={() => setOpen(true)}
        groupBy={schedulerFilters.groupBy}
        isGroupShared={schedulerFilters.isGroupShared}
        visibleColumns={visibleColumns}
        onColumnHide={handleColumnHide}
        onColumnShow={handleColumnShow}
        onSaveReassign={onSaveReassign}
        onBeforeEventDropFinalize={onBeforeEventDropFinalize}
        columnNames={columnNames}
        ref={schedulerRef}
      />
    </>
  );
};
