import * as actionTypes from '../actions/actionTypes';

const initState = {
  moduleType: {},
  allModuleType: [],
  message: '',
  isfetched: false,
  isfetchedError: false,
};

export default (state = initState, action = {}) => {
  switch (action.type) {
    case actionTypes.CREATE_MODULE_TYPE_REQUEST:
      return {
        ...state,
        moduleType: {},
        message: '',
      };
    case actionTypes.CREATE_MODULE_TYPE_SUCCESS:
      return {
        allModuleType: state.allModuleType.concat(action.payload.response.result.data),
      };

    case actionTypes.CREATE_MODULE_TYPE_ERROR:
      return {
        ...state,
        message: '',
      };

    case actionTypes.GET_ALL_MODULE_TYPE_REQUEST:
      return {
        ...state,
        moduleType: {},
        message: '',
      };

    case actionTypes.GET_ALL_MODULE_TYPE_SUCCESS:
      return {
        ...state,
        allModuleType: action.payload.response.result.data,
        message: '',
      };

    case actionTypes.GET_ALL_MODULE_TYPE_ERROR:
      return {
        ...state,
        message: '',
      };

    case actionTypes.UPDATE_MODULE_TYPE_REQUEST:
      return {
        ...state,
        moduleType: {},
        message: '',
      };
    case actionTypes.UPDATE_MODULE_TYPE_SUCCESS:
      return {
        ...state,
        allModuleType: action.payload.response.result.moduleType,
        message: '',
      };

    case actionTypes.UPDATE_MODULE_TYPE_ERROR:
      return {
        ...state,
        message: '',
      };

    default:
      return state;
  }
};
