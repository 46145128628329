import React, {Suspense} from 'react';
import Header2 from '../Layout/Header2.js';
import Footer from '../Layout/Footer.js';

import { connect } from 'react-redux';
import { getSubscriptionPlanAction } from '../../actions/registerCompany';
import { Link } from 'react-router-dom';
import './planPages.css';
import TitleComponent from  "../Common/TitleComponent";

class PlanPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      plan: 'subscription',
      showProcessing: false,
      planArr: [],
    };
  }

  componentDidMount() {
    this.props.getSubscriptionPlanAction(this.props);
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.isGetSubscription !== this.props.isGetSubscription) {
      this.setState({ planArr: nextProps.cmpResponse });
    }
  }

  render() {
    return (
      <div className="registerBox">
        <TitleComponent data={{title:`Subscription Options`}}/>
        <Suspense fallback="loading"><Header2 props={this.props} /></Suspense>
        <div className="registerSection">
          <div className="container">
            <div className="row pricing py-5">
              {this.state.planArr &&
                this.state.planArr.map(function (item) {
                  return (
                    <div className="col-lg-4">
                      <div className="card mb-5 mb-lg-0">
                        <div className="card-body">
                          <h5 className="card-title text-muted text-uppercase text-center">
                            {item.planName}
                          </h5>
                          <h6 className="card-price text-center">
                            ${item.planCost}
                            <span className="period">/{item.planLimitType}</span>
                          </h6>
                          <hr />
                          <ul className="fa-ul">
                            <li>
                              <span className="fa-li">
                                <i className="fa fa-check"></i>
                              </span>
                              Single User
                            </li>

                            <li>
                              <span className="fa-li">
                                <i className="fa fa-check"></i>
                              </span>
                              Unlimited Public Projects
                            </li>
                            <li>
                              <span className="fa-li">
                                <i className="fa fa-check"></i>
                              </span>
                              Community Access
                            </li>
                            <li className="text-muted">
                              <span className="fa-li">
                                <i className="fa fa-times"></i>
                              </span>
                              Unlimited Private Projects
                            </li>
                            <li className="text-muted">
                              <span className="fa-li">
                                <i className="fa fa-times"></i>
                              </span>
                              Dedicated Phone Support
                            </li>
                            <li className="text-muted">
                              <span className="fa-li">
                                <i className="fa fa-times"></i>
                              </span>
                              Free Subdomain
                            </li>
                            <li className="text-muted">
                              <span className="fa-li">
                                <i className="fa fa-times"></i>
                              </span>
                              Monthly Status Reports
                            </li>
                          </ul>

                          <Link
                            to={{
                              pathname: '/company-registration',
                              state: { subscriptionPlan: item },
                            }}
                            className="btn btn-block btn-primary text-uppercase"
                          >
                            Select
                          </Link>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>

            {/*<div className="row"><div className="panel panel-default credit-card-box">
                <div className="panel-heading display-table" >
                    <div className="row display-tr" >
                        <h3 className="panel-title display-td" >Payment Details</h3>
                        <div className="display-td" >
                            <img className="img-responsive pull-right" src="http://i76.imgup.net/accepted_c22e0.png" alt="card"/>
                        </div>
                    </div>
                </div>
                <div className="panel-body">
                    <form  id="payment-form" method="POST">
                        <div className="row">
                            <div className="col-xs-12">
                                <div className="form-group">
                                    <label htmlFor="cardNumber">CARD NUMBER</label>
                                    <div className="input-group">
                                        <input 
                                            type="tel"
                                            className="form-control"
                                            name="cardNumber"
                                            placeholder="Valid Card Number"
                                            autocomplete="cc-number"
                                            required autofocus 
                                        />
                                        <span className="input-group-addon"><i className="fa fa-credit-card"></i></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xs-7 col-md-7">
                                <div className="form-group">
                                    <label htmlFor="cardExpiry"><span className="hidden-xs">EXPIRATION</span><span className="visible-xs-inline">EXP</span> DATE</label>
                                    <input 
                                        type="tel" 
                                        className="form-control" 
                                        name="cardExpiry"
                                        placeholder="MM / YY"
                                        autocomplete="cc-exp"
                                        required 
                                    />
                                </div>
                            </div>
                            <div className="col-xs-5 col-md-5 pull-right">
                                <div className="form-group">
                                    <label htmlFor="cardCVC">CV CODE</label>
                                    <input 
                                        type="tel" 
                                        className="form-control"
                                        name="cardCVC"
                                        placeholder="CVC"
                                        autocomplete="cc-csc"
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xs-12">
                                <div className="form-group">
                                    <label htmlFor="couponCode">COUPON CODE</label>
                                    <input type="text" className="form-control" name="couponCode" />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xs-12">
                                <button className="subscribe btn btn-success btn-lg btn-block" type="button">Start Subscription</button>
                            </div>
                        </div>
                        <div className="row" style={{"display":"none"}}>
                            <div className="col-xs-12">
                                <p className="payment-errors"></p>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
          </div>*/}
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    cmpResponse: state.registerCompany.response,
    isGetSubscription: state.registerCompany.isGetSubscription,
    isGetSubscriptionError: state.registerCompany.isGetSubscriptionError,
  };
}

export default (connect(mapStateToProps, { getSubscriptionPlanAction })(PlanPage));
