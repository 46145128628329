import { MenuItem, TextField } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import React from 'react';

const RemotePagination = ({
  limit,
  onLimitChange,
  pagesCount,
  onPageChange,
  page,
  countOptions = [15, 30, 50, 100, 200],
}) => {
  return (
    <>
      <div className='d-flex justify-content-between align-items-center'>
        <div className='d-flex align-items-center no-print gap-3'>
          <span className='text-nowrap'>Rows per page</span>
          <select className='form-select form-select-sm' value={limit} onChange={onLimitChange}>
            {countOptions.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>

        <div className='ms-auto'>
          <Pagination
            page={page}
            onChange={onPageChange}
            count={pagesCount}
            variant='outlined'
            shape='rounded'
          />
        </div>
      </div>
    </>
  );
};

export default RemotePagination;
