import { createSelector } from '@reduxjs/toolkit';

const selectAllWidgets = (state) => state.dashboard;

export const selectAllWidgetsOptions = createSelector(selectAllWidgets, (dashboard) =>
dashboard/*
    .filter((val)=>{
       if(val.enabled)
       return true;
      else
      return false;})*/
);

export const selectActiveWidgets = createSelector(selectAllWidgets, (allWidgets) =>
allWidgets
    /*.filter(
      (val) =>
        val.enabled==true
    )*/
    /*.map((val) => ({
      label: val.jobStatusCodesName,
      value: val.objectId,
      code: val.jobStatusCodesCode,
    })*/
);
