import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import moment from 'moment';
import DatePickerWrapper from '../../shared/datepicker-wrapper/datepicker-wrapper';
import { appConstants } from '../../_constants';

const DateRangeSelector = ({ onChange, initialMode, initialValue, initialStart, initialEnd }) => {
  const [mode, setMode] = useState(initialMode || 'priorDays');
  const [value, setValue] = useState(initialValue || '365');
  const [startDate, setStartDate] = useState(initialStart);
  const [endDate, setEndDate] = useState(initialEnd);

  useEffect(() => {
    updateDateRange();
  }, [mode, value, startDate, endDate]);

  const updateDateRange = () => {
    let start, end;
    const today = moment();

    switch (mode) {
      case 'priorDays':
        start = today.clone().subtract(parseInt(value), 'days');
        end = today;
        break;
      case 'year':
        start = moment(value, 'YYYY').startOf('year');
        end = moment(value, 'YYYY').endOf('year');
        break;
      case 'quarter':
        const [year, quarter] = value.split('-');
        start = moment(year).quarter(parseInt(quarter)).startOf('quarter');
        end = moment(year).quarter(parseInt(quarter)).endOf('quarter');
        break;
      case 'month':
        const [monthYear, monthNum] = value.split('-');
        start = moment(`${monthYear}-${monthNum}-01`, 'YYYY-MM-DD').startOf('month');
        end = moment(`${monthYear}-${monthNum}-01`, 'YYYY-MM-DD').endOf('month');
        break;
      case 'custom':
        start = moment(startDate);
        end = moment(endDate);
        break;
    }

    if (start && end) {
      onChange({ startDate: start.toDate(), endDate: end.toDate(), mode, value });
    }
  };

  const handleModeChange = (selectedOption) => {
    const newMode = selectedOption.value;
    let newValue;

    switch (newMode) {
      case 'priorDays':
        newValue = '365'; // Default to last 365 days
        break;
      case 'year':
        newValue = moment().format('YYYY'); // Current year
        break;
      case 'quarter':
        newValue = `${moment().format('YYYY')}-${moment().quarter()}`; // Current quarter
        break;
      case 'month':
        newValue = moment().format('YYYY-MM'); // Current month
        break;
      case 'custom':
        setStartDate(moment().subtract(1, 'month').toDate()); // Default to last month
        setEndDate(moment().toDate());
        break;
    }

    setMode(newMode);
    if (newValue) setValue(newValue);
  };

  const modeOptions = [
    { value: 'priorDays', label: 'Last X days' },
    { value: 'year', label: 'Year' },
    { value: 'quarter', label: 'Quarter' },
    { value: 'month', label: 'Month' },
    { value: 'custom', label: 'Custom' }
  ];

  const renderSecondDropdown = () => {
    const currentYear = moment().year();

    switch (mode) {
      case 'priorDays':
        const priorDaysOptions = [
          { value: '7', label: '7 days' },
          { value: '30', label: '30 days' },
          { value: '90', label: '90 days' },
          { value: '180', label: '180 days' },
          { value: '365', label: '365 days' }
        ];
        return (
          <Select 
            value={priorDaysOptions.find(option => option.value === value)}
            onChange={(selectedOption) => setValue(selectedOption.value)}
            options={priorDaysOptions}
          />
        );
      case 'year':
        const yearOptions = Array.from({ length: 10 }, (_, i) => ({
          value: (currentYear - i).toString(),
          label: (currentYear - i).toString()
        }));
        return (
          <Select 
            value={yearOptions.find(option => option.value === value)}
            onChange={(selectedOption) => setValue(selectedOption.value)}
            options={yearOptions}
          />
        );
      case 'quarter':
        const quarterOptions = Array.from({ length: 40 }, (_, i) => {
          const year = currentYear - Math.floor(i / 4);
          const quarter = 4 - (i % 4);
          return {
            value: `${year}-${quarter}`,
            label: `${year} Q${quarter}`
          };
        });
        return (
          <Select 
            value={quarterOptions.find(option => option.value === value)}
            onChange={(selectedOption) => setValue(selectedOption.value)}
            options={quarterOptions}
          />
        );
      case 'month':
        const monthOptions = Array.from({ length: 120 }, (_, i) => {
          const date = moment().subtract(i, 'months');
          return {
            value: `${date.format('YYYY')}-${date.format('MM')}`,
            label: date.format('MMMM YYYY')
          };
        });
        return (
          <Select 
            value={monthOptions.find(option => option.value === value)}
            onChange={(selectedOption) => setValue(selectedOption.value)}
            options={monthOptions}
          />
        );
      case 'custom':
        return (
          <div className="custom-date-range">
            <div className="date-picker-wrapper">
              <label>Start Date:</label>
              <DatePickerWrapper
                className="form-control"
                closeOnSelect
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                dateFormat={appConstants.DATEFORMATNOTTIME}
                required
                placeholderText="Select start date"
                selectsStart
                startDate={startDate}
                endDate={endDate}
                todayButton="Today"
                showMonthDropdown
              />
            </div>
            <div className="date-picker-wrapper">
              <label>End Date:</label>
              <DatePickerWrapper
                className="form-control"
                closeOnSelect
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                dateFormat={appConstants.DATEFORMATNOTTIME}
                required
                placeholderText="Select end date"
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                todayButton="Today"
                showMonthDropdown
              />
            </div>
          </div>
        );
    }
  };

  return (
    <div className="date-range-selector">
      <div className="mode-selector">
        <label>Select Range Type:</label>
        <Select 
          value={modeOptions.find(option => option.value === mode)}
          onChange={handleModeChange}
          options={modeOptions}
        />
      </div>
      
      <div className="value-selector">
        {mode === 'custom' ? null : (
        <label>{mode === 'custom' ? 'Select Dates:' : mode ==='priorDays'?  ('Select number of days:')
        :  ('Select ' + mode.charAt(0).toUpperCase() + mode.slice(1) + ':')}</label>)}
        {renderSecondDropdown()}
      </div>
    </div>
  );
};

export default DateRangeSelector;