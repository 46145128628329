import * as actionTypes from '../actions/actionTypes';

const initState = {
  isActive: 1,
};

export default (state = initState, action = {}) => {
  switch (action.type) {
    case actionTypes.SELECT_NAV_LINK_ACTION:
      return {
        ...state,
        isActive: action.payload.response,
      };

    default:
      return state;
  }
};
