export const getImportingCompanies = (state) => state.rtk.companies.importingCompanies;

export const getCreateContact = (state) => state.rtk.companies.createContact;

export const getListCompanies = (state) => state.rtk.companies.listCompanies;

export const getListCompaniesCount = (state) => state.rtk.companies.viewCompaniesCount;

export const selectCompaniesDropdown = (state) => state.rtk.companies.companiesDropdown;

export const selectCreateCompany = (state) => state.rtk.companies.createCompany;

export const selectUpdateCompany = (state) => state.rtk.companies.updateCompany;

export const selectDeleteCompanies = (state) => state.rtk.companies.deleteCompanies;

export const selectGetCompany = (state) => state.rtk.companies.getCompany;

export const selectGetCompanyContactAffiliations = (state) =>
  state.rtk.companies.getCompanyContactAffiliations;

export const selectGetCompanyAffiliations = (state) => state.rtk.companies.getCompanyAffiliations?.data??[];

export const selectGetAccountAffiliation = (state) => state.rtk.companies.getAccountAffiliation;

export const selectSaveCompany = (state) => state.rtk.companies.saveCompany;

export const selectGetCompanyEditInfo = (state) => state.rtk.companies.getCompanyEditInfo;

export const selectGetSingleAffiliatedContacts = (state) =>
  state.rtk.companies.getSingleAffiliatedContacts;
