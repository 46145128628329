import React from 'react';
import {Helmet} from "react-helmet-async";

const TitleComponent = (props) => {
    const {data} = props; 
    return( 
        <Helmet>
            <title>{(data.title)?`${data.title} - CommandPost`:'CommandPost App'}</title>
        </Helmet>
    )   
};
export default TitleComponent;