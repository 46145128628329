import { Suspense, useState } from 'react';
import { Link } from 'react-router-dom';
import TitleComponent from '../../components/Common/TitleComponent';
import { default as Footer } from '../../components/Layout/Footer.js';
import { default as Header } from '../../components/Layout/Header.js';
import { CSVLink } from 'react-csv';
import csv_icon_upload from '../../components/static/images/csv_icon_upload.png';
import csv_icon_download from '../../components/static/images/csv_icon_download.png';
import fill_in_icon from '../../components/static/images/fill_in_icon.png';
import history from '../../routes/history.js';
import { isAllowed, moduleConstants } from '../../_constants';
import { ImportProjectModal } from '../../modals/import-project-modal';
import { useAppSelector } from '../../shared/hooks/use-app-selector';
import { selectImportProjectsParse } from '../../modules/projects/selectors.js';
import { useImportProjectsColumns } from '../import-projects-view/use-import-projects-columns';
import styles from './styles.module.scss';

export const ImportProjectPage = (props) => {
  if (!isAllowed(moduleConstants.IMPORTPROJECTS)) {
    history.push({ pathname: '/' });
  }

  const { loading } = useAppSelector(selectImportProjectsParse);

  const [isImportProjectModalOpen, setIsImportProjectModalOpen] = useState(false);

  const getLoadingPopup = () => {
    return loading ? (
      <div className={styles.loader}>
        <div>Please wait, uploading your data...</div>
      </div>
    ) : null;
  };

  const [fieldsData] = useImportProjectsColumns();
  const csvHeaders = [...fieldsData.map(({ label }) => label)];

  return (
    <Suspense fallback='loading'>
      <div className={styles.wrapper}>
        {getLoadingPopup()}
        <TitleComponent data={{ title: `Import Projects` }} />
        <Header props={props} />
        <div className='container'>
          <div className='px-2'>
            <h2>Import Projects</h2>
            <p>
              Importing your projects is the fastest way to get all of your projects into
              <br></br>CommandPost so you can have complete insight into your business and ongoing
              operations.
            </p>
          </div>
          <div className='row col-md-12 px-2'>
            <h4>IMPORT PROJECT LIST</h4>
            <div className={`row ${styles.blocks}`}>
              <div className='col-md-3'>
                <CSVLink headers={csvHeaders} data={[]} filename={'addProject.csv'}>
                  <img src={csv_icon_download} alt='download-csv' />
                  Download the Project List template
                </CSVLink>
              </div>
              <div className='col-md-3'>
                <img src={fill_in_icon} />
                <span>Then add your project information into the sheet </span>
              </div>
              <div className='col-md-3'>
                <a role='button' className='' onClick={() => setIsImportProjectModalOpen(true)}>
                  <img src={csv_icon_upload} />
                  <span className='d-flex'>Upload Projects to Command Post</span>
                </a>
              </div>
              <div className='col-md-3'>
                <img src={fill_in_icon} />
                <Link to={{ pathname: `/edit-pending-project-uploads/` }}>Edit Pending Imported Projects</Link>
              </div>
            </div>
            {false&&(<>
            <div className='px-3'>
              <h4>IMPORT WORK-IN-PROGRESS (WIP) REPORT</h4>
              Use the WIP import export tool to fill in status details about your projects
              <br />
              <Link className={styles.wiplink} to={{ pathname: `/wip-view/` }}>
                Download/Upload Work In Progress (WIP) Report
              </Link>
            </div>
            </>)}
          </div>
        </div>
        <Footer />

        <ImportProjectModal
          open={isImportProjectModalOpen}
          onClose={() => setIsImportProjectModalOpen(!isImportProjectModalOpen)}
        />
      </div>
    </Suspense>
  );
};
