import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import bannerImage from '../static/images/cpostLogo.png';
import Footer from '../Layout/Footer.js';
import { getTermsConditionAction } from '../../actions/account';
import TitleComponent from  "../Common/TitleComponent";
import TermsAndConditionsPage from '../../pages/terms-and-conditions-page'

class TermAndConditions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      content: '',
    };
  }

  componentDidMount() {
    this.props.getTermsConditionAction();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps && nextProps.description) {
      this.setState({ description: nextProps.description });
    }
  }

  goTo(route) {
    this.props.history.replace(`/${route}`);
  }
 

  render() {

    return (
      <div>
        <TitleComponent data={{title:`Terms & Conditions`}}/>
        <div className="wrapperBox">
          <header className="frontLoginHeader">
            <div className="container">
              <div className="row">
                <div className="col-sm-6">
                  <div className="logo">
                    <a href="/home">
                      <img src={bannerImage} alt="Logo" />
                    </a>
                    <span className="tagline">Business Intelligence Software</span>
                  </div>
                </div>
                <div className="col-sm-6"></div>
              </div>
            </div>
          </header>
          <div className="homeContainer">
            <TermsAndConditionsPage />
          </div>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <div className="fixfooter"><Footer /></div>
          
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    description: state.account?.termsResponse?.description,
  };
}

export default withRouter(
  connect(mapStateToProps, { getTermsConditionAction })(TermAndConditions)
);
