import * as actionTypes from './../Admin/actionTypes.js';
import * as API from '../../api/Admin/account';

//get all user details
export const getAllAccountRequest = () => ({
  type: actionTypes.GET_ACCOUNT_LIST_REQUEST,
});

export const getAllAccountSuccess = (response) => ({
  type: actionTypes.GET_ACCOUNT_LIST_SUCCESS,
  payload: {
    response,
  },
});

export const getAllAccountError = (error) => ({
  type: actionTypes.GET_ACCOUNT_LIST_ERROR,
  payload: {
    error,
  },
});

export const getAllAccountAction = (data) => {
  return (dispatch) => {
    dispatch(getAllAccountRequest());
    return API.getAllAccount(data)
      .then((response) => {
        dispatch(getAllAccountSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          getAllAccountError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

//ADD Company

export const registerCompanyRequest = () => ({
  type: actionTypes.REGISTER_COMPANY_ACCOUNT_REQUEST,
});

export const registerCompanySuccess = (response) => ({
  type: actionTypes.REGISTER_COMPANY_ACCOUNT_SUCCESS,
  payload: {
    response,
  },
});

export const registerCompanyError = (error) => ({
  type: actionTypes.REGISTER_COMPANY_ACCOUNT_ERROR,
  payload: {
    error,
  },
});

export const registerCompanyAccountAction = (data) => {
  return (dispatch) => {
    dispatch(registerCompanyRequest());
    const FormData = data;
    return API.registerAdminCompany(FormData)
      .then((response) => {
        dispatch(registerCompanySuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          registerCompanyError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

//Account edit

//Account Active and Deactivated
export const AccountActiveDeactivatedRequest = () => ({
  type: actionTypes.ADMIN_ACCOUNT_STATUS_LIST_REQUEST,
});

export const AccountActiveDeactivatedSuccess = (response) => ({
  type: actionTypes.ADMIN_ACCOUNT_STATUS_LIST_SUCCESS,
  payload: {
    response,
  },
});

export const AccountActiveDeactivatedError = (error) => ({
  type: actionTypes.ADMIN_ACCOUNT_STATUS_LIST_ERROR,
  payload: {
    error,
  },
});

export const allAccountActiveDeactivatedAction = (data) => {
  return (dispatch) => {
    dispatch(AccountActiveDeactivatedRequest());
    return API.AllAccountActiveDeactivated(data)
      .then((response) => {
        dispatch(AccountActiveDeactivatedSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          AccountActiveDeactivatedError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

//Terms AND CONDITION
export const AddTermsConditionRequest = () => ({
  type: actionTypes.ADD_TERMS_CONDITION_REQUEST,
});

export const AddTermsConditionSuccess = (response) => ({
  type: actionTypes.ADD_TERMS_CONDITION_SUCCESS,
  payload: {
    response,
  },
});

export const AddTermsConditionError = (error) => ({
  type: actionTypes.ADD_TERMS_CONDITION_ERROR,
  payload: {
    error,
  },
});

export const addTermsConditionAction = (data) => {
  return (dispatch) => {
    dispatch(AddTermsConditionRequest());
    if (data && data.objectId) {
      return API.editTermsCondition(data)
        .then((response) => {
          dispatch(AddTermsConditionSuccess(response.data));
        })
        .catch((error) => {
          dispatch(
            AddTermsConditionError(
              error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
            )
          );
        });
    } else {
      return API.addTermsCondition(data)
        .then((response) => {
          dispatch(AddTermsConditionSuccess(response.data));
        })
        .catch((error) => {
          dispatch(
            AddTermsConditionError(
              error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
            )
          );
        });
    }
  };
};

//Terms AND CONDITION
export const GetTermsConditionRequest = () => ({
  type: actionTypes.GET_TERMS_CONDITION_REQUEST,
});

export const GetTermsConditionSuccess = (response) => ({
  type: actionTypes.GET_TERMS_CONDITION_SUCCESS,
  payload: {
    response,
  },
});

export const GetTermsConditionError = (error) => ({
  type: actionTypes.GET_TERMS_CONDITION_ERROR,
  payload: {
    error,
  },
});

export const getTermsConditionAction = (data) => {
  return (dispatch) => {
    dispatch(GetTermsConditionRequest());
    return API.getTermsCondition(data)
      .then((response) => {
        dispatch(GetTermsConditionSuccess(response.data?.result));
      })
      .catch((error) => {
        dispatch(
          GetTermsConditionError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

//Reset Password
export const ResetPasswordRequest = () => ({
  type: actionTypes.RESET_PASSWORD_REQUEST,
});

export const ResetPasswordSuccess = (response) => ({
  type: actionTypes.RESET_PASSWORD_SUCCESS,
  payload: {
    response,
  },
});

export const ResetPasswordError = (error) => ({
  type: actionTypes.RESET_PASSWORD_ERROR,
  payload: {
    error,
  },
});

export const ResetPasswordAction = (data) => {
  return (dispatch) => {
    dispatch(ResetPasswordRequest());
    return API.resetPassword(data)
      .then((response) => {
        dispatch(ResetPasswordSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          ResetPasswordError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};
