import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { selectGetAccountProjectSettings } from '../../../modules/accounts/selectors';
import { validateProjectName, validateProjectNumber, getAllJobNumbers } from '../../../modules/projects';
import { selectGetNextProjectNumber } from '../../../modules/projects/selectors';
import { selectGeneralInfo } from '../../../selectors/accountSettings';
import { selectAllJobStatusOptions } from '../../../selectors/jobStatusCode';
import { selectLastJobData } from '../../../selectors/project';
import { HorizontalLayout } from '../../../shared/form-controls/control-layouts/horizontal-layout';
import FormInput from '../../../shared/form-controls/form-input';
import FormSelect from '../../../shared/form-controls/form-select/form-select';
import { useAppDispatch } from '../../../shared/hooks/use-app-dispatch';
import { useAppSelector } from '../../../shared/hooks/use-app-selector';
import { useDebounce } from '../../../shared/hooks/use-debounce';
import FormCheckbox from '../../../shared/form-controls/form-checkbox';
import {
  getUpdateProjectForm,
} from '../../../modules/projects';

type ProjectInformationProps = {
  projectId?: string;
  customFields?: any;
  projectRoles?: any[];
  //hasHidden?: boolean;
  onShowHiddenCustomFields?: () => void;
};

export const ProjectInformation = ({
  projectId,
  projectRoles,
  customFields, //hasHidden = false,
  // onShowHiddenCustomFields,
}: ProjectInformationProps) => {
  const isUpdate = !!projectId;

  const dispatch = useAppDispatch();
  const form = useFormContext();
  const watchJobStatusCodes = form.watch('jobStatusCodes');
  const watchRelatedJobNum = form.watch('relatedJobNum');

  const { loading: nextProjectNumberLoading } = useAppSelector(selectGetNextProjectNumber);

  const allProjectJobStatusCodes = useAppSelector(selectAllJobStatusOptions);
  
  const projectJobStatusCode = isUpdate
    ? [...allProjectJobStatusCodes]
    : allProjectJobStatusCodes.filter((opt: any) => !['OH', 'OHP', 'SUSP'].includes(opt.code));
    

  useEffect(() => {
    if (watchJobStatusCodes) {
      const jobStatusCodesCode =
        projectJobStatusCode.find((st) => st.value === watchJobStatusCodes)?.code || '';
      form.setValue('jobStatusCodesCode', jobStatusCodesCode);
    }
  }, [watchJobStatusCodes]);

  

  const jobNumPrefix = form.watch('jobNumPrefix', '');
  const jobNum = form.watch('jobNum', '');
  const jobStatusCodesCode = form.watch('jobStatusCodesCode', '');


  const setRelatedProjectFields = async (relatedJobNum) =>{
    if (relatedJobNum) {
      const relatedJobData = await dispatch(getUpdateProjectForm({ jobNumFormatted:relatedJobNum })).unwrap();

      //form.setValue('relatedJobId', relatedJobData?.relatedJobId);
      form.setValue('jobAddress', relatedJobData?.jobAddress);
      form.setValue('jobAddress2', relatedJobData?.jobAddress2);
      form.setValue('jobCity', relatedJobData?.jobCity);
      form.setValue('jobState', relatedJobData?.jobState);
      form.setValue('jobZip', relatedJobData?.jobZip);
      form.setValue('jobCountry', relatedJobData?.jobCountry);
      form.setValue('lat', relatedJobData?.lat);
      form.setValue('lng', relatedJobData?.lng);

      const clientRole = projectRoles?.find((role) => role?.code === 'CL');
      const clients = relatedJobData?.projectTeam[clientRole._id] ?? [];

      form.setValue(`projectTeam.${clientRole._id}`, clients);


      //const fieldFormatted = [{ value: watchRelatedJobNum?.value, label: watchRelatedJobNum?.value }];
      //setAllJobExistingJobNumbers(fieldFormatted) ;
    }
  }

  const { data: defaultProjectSettings } = useAppSelector(selectGetAccountProjectSettings);

  const jobNumberDisabled = defaultProjectSettings?.sequentialJobNumber ?? true;

  const accountInfo = useAppSelector(selectGeneralInfo);
  const lastJobData = useAppSelector(selectLastJobData);


  const jobName = form.watch('jobName', '');

  useDebounce(async () => {
    if (!jobName || isUpdate) return;

    const data = await dispatch(validateProjectName({ jobName, projectId })).unwrap();

    const { valid, message } = data as any;

    if (valid) form.clearErrors('jobName');
    else form.setError('jobName', { message });
  }, [jobName]);

  

  const isActivePlusStage = ['A', 'SNS', 'SUSP', 'D', 'W', 'C','XT'].includes(jobStatusCodesCode);
  
  const isServiceStage = ['SVC', 'SVC-C','SVC-D'].includes(jobStatusCodesCode);

  
  useDebounce(async () => {
    if (!jobNumPrefix || !jobNum || (isUpdate && !isUpdateOnJobWithoutJobNumber)) return;

    if(isServiceStage){
      return;
    }

    form.setError('jobNum', { type: 'manual', message: 'Validating Job Number...' });

    const data = await dispatch(
      validateProjectNumber({
        projectId,
        projectNumber: {
          jobNumPrefix,
          jobNum,
        },
      })
    ).unwrap();

    const { valid, message } = data as any;

    if (valid) form.clearErrors('jobNum');
    else form.setError('jobNum', { message });
  }, [jobNumPrefix, jobNum]);



  useEffect(() => {
    if (watchRelatedJobNum && !isUpdate && isServiceStage) {
      const relatedJobNum = watchRelatedJobNum ?? '';
      form.setValue('isWarrantyService', !!(watchRelatedJobNum.length>0));
      if (relatedJobNum && !isUpdate) {
        setRelatedProjectFields(relatedJobNum);
       // const fieldFormatted = [{ value: watchRelatedJobNum?.value, label: watchRelatedJobNum?.value }];
       // setAllJobExistingJobNumbers(fieldFormatted) ;
      }
      // fetch related job data
    }else{
      form.setValue('isWarrantyService', false);
    }
  }, [watchRelatedJobNum, isServiceStage]);

  const isUpdateOnJobWithoutJobNumber =
    isUpdate && (!jobNum || form.getFieldState('jobNum').invalid);

  if (isUpdateOnJobWithoutJobNumber && jobNumberDisabled && !jobNumPrefix && !isServiceStage) {
    form.setValue('jobNumPrefix', lastJobData.initialJobNumberPre);
  }

  const [allJobExistingJobNumbers, setAllJobExistingJobNumbers] = useState<any[]>([]);

  useEffect(() => {
    if (allJobExistingJobNumbers.length ==0 && isServiceStage && !isUpdate) {
      dispatch(getAllJobNumbers()).then((res) => {
        const fieldFormatted = res.payload?.map((job) => ({ value: job.jobNumFormatted, label: job.jobNumFormatted+((job.jobName?.length>0?(' '+job.jobName+''):'') )}));
        setAllJobExistingJobNumbers(fieldFormatted) ;
      });
    }else if (allJobExistingJobNumbers.length ==0 && isServiceStage && isUpdate) {
      const fieldFormatted = [{ value: watchRelatedJobNum, label: watchRelatedJobNum }];
      setAllJobExistingJobNumbers(fieldFormatted) ;
      form.setValue('relatedJobNum', watchRelatedJobNum);
    }
  }, [isServiceStage]);
  

  return (
    <>
      <b>Project information</b>
      <hr />
      <FormSelect
        required
        label='Project Status'
        options={projectJobStatusCode}
        name='jobStatusCodes'
        Layout={HorizontalLayout}
        disabled={isUpdate}
      />
      <FormInput type='hidden' name='jobStatusCodesCode' />
      <FormInput
        label={isServiceStage ? 'Service Name' : 'Job Name'}
        required
        name='jobName'
        Layout={HorizontalLayout}
      />
      {isServiceStage && (allJobExistingJobNumbers?.length>0) && (
        <FormSelect
          label='Related Project'
          options={allJobExistingJobNumbers}
          name='relatedJobNum'
          Layout={HorizontalLayout}
          disabled={isUpdate}
          isClearable={!isUpdate}
          required={isServiceStage}
        />
      )}

      
      {!isServiceStage && (
        <div className='row d-flex align-items-center'>
          <div className='col-md-4 pb-1'>
            <div className='form-group'>
              <label htmlFor='jobNumPrefix'>Job Number</label>
            </div>
          </div>
          <div className='col-md-8'>
            <div className='form-group'>
              {(!isUpdate || isUpdateOnJobWithoutJobNumber) && (
                <label>
                  Last Job Number was{' '}
                  {lastJobData.initialJobNumberPre ? lastJobData.initialJobNumberPre + '-' : '#'}
                  {lastJobData.previousCountNumber}
                </label>
              )}
              <div className='row'>
                <div className='col-6'>
                  <input
                    type='text'
                    id='jobNumPrefix'
                    className='form-control'
                    placeholder='Prefix'
                    maxLength={4}
                    disabled={
                      jobNumberDisabled ||
                      (!isUpdateOnJobWithoutJobNumber && (nextProjectNumberLoading || isUpdate))
                    }
                    {...form.register('jobNumPrefix')}
                  />
                </div>
                <div className='col-6'>
                  <input
                    type='text'
                    id='jobNum'
                    className='form-control'
                    placeholder='Job Number'
                    maxLength={4}
                    disabled={
                      !isUpdateOnJobWithoutJobNumber &&
                      (jobNumberDisabled || nextProjectNumberLoading || isUpdate)
                    }
                    {...form.register('jobNum')}
                  />
                </div>
              </div>

              {/* error text for jobNum */}

              {form.formState.errors.jobNum && (
                <span className='error'>{String(form.formState.errors.jobNum.message)}</span>
              )}
            </div>
          </div>
        </div>
      )}

      {accountInfo.alternateJobNumber && !isServiceStage && (
        <FormInput label='Alternate Job Number' name='altJobNum' Layout={HorizontalLayout} />
      )}

      {accountInfo.alternateJobNumber && isServiceStage && (
        <FormInput
          label='Alternate Service Number'
          name='altServiceNum'
          Layout={HorizontalLayout}
          control={form.control}
        />
      )}

      {isActivePlusStage && (
        <FormCheckbox
          Layout={HorizontalLayout}
          control={form.control}
          name='isRef'
          label='Show as a reference'
        />
      )}

      {!isUpdate && (
        <FormInput
          label='Job Notes'
          name='jobNotes'
          Layout={HorizontalLayout}
          textArea
          disabled={isUpdate}
        />
      )}

      {isUpdate && (
        <FormInput
          label='Update comment'
          name='updateComment'
          Layout={HorizontalLayout}
          textArea
        />
      )}

      {customFields}
    </>
  );
};
