import { PropsWithChildren } from 'react';
import SidebarSettings from '../../components/Layout/SidebarSettings';

const SettingsLayout = ({ children }: PropsWithChildren<unknown>) => {
  return (
    <div className='contactBox h-auto'>
      <div className='container'>
        <div className='row'>
          <div className='col-sm-2'>
            <div className='sidebarBox'>
              <SidebarSettings />
            </div>
          </div>
          <div className='col-sm-10'>
            <div className='rightTableBox'>
              <div className='cardBody'>{children}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsLayout;
