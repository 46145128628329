import { countryCodes } from './countryCodes';

let url = window.location.protocol + '//' + window.location.hostname + '/';
if (window.location.hostname === 'localhost') {
  url = 'http://' + window.location.hostname + ':3000/';
}

//Production uses .env.production, Staging uses env.staging definitions
export const AUTH_CONFIG = {
  domain: process.env.REACT_APP_AUTH0_COMMANDPOST_DOMAIN,
  customDomain: process.env.REACT_APP_AUTH0_COMMANDPOST_CUSTOM_DOMAIN
    ? process.env.REACT_APP_AUTH0_COMMANDPOST_CUSTOM_DOMAIN
    : process.env.REACT_APP_AUTH0_COMMANDPOST_DOMAIN,
  clientId: process.env.REACT_APP_AUTH0_COMMANDPOST_CLIENTID,
  callbackUrl: url + 'callback',
  logoutUrl(params = '') {
    return url + params;
  },
};


export const appConstants = {
  //Production uses .env.production, Staging uses env.staging definitions
  // use localhost:5000 as URL for local server host
  WEB_SERVICE_URL_WITHOUT_PARSE: process.env.REACT_APP_DOMAIN_COMMANDPOST,
  WEB_SERVICE_URL: process.env.REACT_APP_DOMAIN_COMMANDPOST + '/parse',

  AUTO_IMPORT_ENABLED: (process.env.REACT_APP_AUTO_IMPORT_ENABLED+'') === 'true',

  PARSE_APPLICATION_ID: process.env.REACT_APP_PARSE_APPLICATION_ID,
  PARSE_REST_API_KEY: process.env.REACT_APP_PARSE_REST_API_KEY,

  /* AWS CREDENTIALS */
  AWS_BUCKET_NAME: process.env.REACT_APP_AWS_BUCKET_NAME,
  AWS_DIR_NAME: process.env.REACT_APP_AWS_DIR_NAME,
  AWS_REGION: process.env.REACT_APP_AWS_REGION,
  AWS_ACCESS_KEY: process.env.REACT_APP_AWS_ACCESS_KEY,
  AWS_SECRET_KEY: process.env.REACT_APP_AWS_SECRET_KEY,
  /* AWS CREDENTIALS
   */
  /*Google*/
  GOOGLE_MAPS_API_KEY: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,

  /*PDF print*/
  PDF_PRINT_URL: process.env.REACT_APP_PDF_GENERATOR_URL,

  /* Flag for production */
  IS_PRODUCTION: (process.env.REACT_APP_IS_PRODUCTION === 'true' || process.env.REACT_APP_IS_PRODUCTION === '1'),

  HEADER_LANGUAGE: 'en',
  RULESMESSAGE: {
    required: 'This field is required!',
    alpha: 'First name should not contain a space or number!',
    email: 'Please enter a valid Email address!',
  },

  CONTENT_CPOST_PROFILE_DIR:
    'https://' +
    process.env.REACT_APP_AWS_BUCKET_NAME +
    '.s3.' +
    process.env.REACT_APP_AWS_REGION +
    '.amazonaws.com/' +
    process.env.REACT_APP_AWS_DIR_NAME +
    '/',
  CONTENT_CPOST_LOGO_URL:
    'https://' +
    process.env.REACT_APP_AWS_BUCKET_NAME +
    '.s3.' +
    process.env.REACT_APP_AWS_REGION +
    '.amazonaws.com/' +
    process.env.REACT_APP_AWS_DIR_NAME +
    '/cpplogo.png',
  //"https://ccp-s3-web-static.s3.us-east-2.amazonaws.com/profile-photo/cpostLogo.png",

  LOADER_TYPE: 'bars',
  LOADER_COLOR: 'rgb(42, 129, 182)',
  LOADER_HEIGHT: 50,
  LOADER_WIDTH: 50,
  PAGE_LOADER_TYPE: 'spokes',
  PAGE_LOADER_COLOR: 'rgb(42, 129, 182)',
  PAGE_LOADER_HEIGHT: 50,
  PAGE_LOADER_WIDTH: 130,
  DATETIMEFORMAT: 'MMM DD,  YYYY, hh:mm a z',
  DATETIMEZONEFORMAT: 'MMM DD, YYYY, hh:mm a z',
  DATETIMEFORMATYEAR: 'YYYY',
  TEAMACTIVITYDATEFORMAT: 'dddd MMM DD YYYY h:mm:ss a z',
  YEARFORMAT: 'YYYY',
  // DATEFORMAT: "MMM DD, YYYY",
  DATEFORMAT: 'MM/DD/YYYY',
  DATEFORMATPICKER: 'MM/dd/YYYY',
  DATEFORMATNOTTIME: 'MMM DD, YYYY',
  DATEFORMATWITHTIME: 'MMM DD, YYYY h:mm a z',
  DATEFORMATPICKERTIME: 'MM/dd/YYYY hh:mm a',
  DATEFORMATCONTACT: 'MM/DD/YYYY',
  DATEFORMATCONTACTTIME: 'MM/DD/YYYY h:mm a',
  WARRANTYPERIOD: [
    { label: '1', value: '1' },
    { label: '2', value: '2' },
    { label: '3', value: '3' },
    { label: '4', value: '4' },
    { label: '5', value: '5' },
    { label: '6', value: '6' },
    { label: '7', value: '7' },
    { label: '8', value: '8' },
    { label: '9', value: '9' },
    { label: '10', value: '10' },
    { label: '11', value: '11' },
    { label: '12', value: '12' },
  ],
  STATE: [
    { name: 'Alabama', abbreviation: 'AL' },
    { name: 'Alaska', abbreviation: 'AK' },
    { name: 'American Samoa', abbreviation: 'AS' },
    { name: 'Arizona', abbreviation: 'AZ' },
    { name: 'Arkansas', abbreviation: 'AR' },
    { name: 'California', abbreviation: 'CA' },
    { name: 'Colorado', abbreviation: 'CO' },
    { name: 'Connecticut', abbreviation: 'CT' },
    { name: 'Delaware', abbreviation: 'DE' },
    { name: 'District Of Columbia', abbreviation: 'DC' },
    { name: 'Federated States Of Micronesia', abbreviation: 'FM' },
    { name: 'Florida', abbreviation: 'FL' },
    { name: 'Georgia', abbreviation: 'GA' },
    { name: 'Guam', abbreviation: 'GU' },
    { name: 'Hawaii', abbreviation: 'HI' },
    { name: 'Idaho', abbreviation: 'ID' },
    { name: 'Illinois', abbreviation: 'IL' },
    { name: 'Indiana', abbreviation: 'IN' },
    { name: 'Iowa', abbreviation: 'IA' },
    { name: 'Kansas', abbreviation: 'KS' },
    { name: 'Kentucky', abbreviation: 'KY' },
    { name: 'Louisiana', abbreviation: 'LA' },
    { name: 'Maine', abbreviation: 'ME' },
    { name: 'Marshall Islands', abbreviation: 'MH' },
    { name: 'Maryland', abbreviation: 'MD' },
    { name: 'Massachusetts', abbreviation: 'MA' },
    { name: 'Michigan', abbreviation: 'MI' },
    { name: 'Minnesota', abbreviation: 'MN' },
    { name: 'Mississippi', abbreviation: 'MS' },
    { name: 'Missouri', abbreviation: 'MO' },
    { name: 'Montana', abbreviation: 'MT' },
    { name: 'Nebraska', abbreviation: 'NE' },
    { name: 'Nevada', abbreviation: 'NV' },
    { name: 'New Hampshire', abbreviation: 'NH' },
    { name: 'New Jersey', abbreviation: 'NJ' },
    { name: 'New Mexico', abbreviation: 'NM' },
    { name: 'New York', abbreviation: 'NY' },
    { name: 'North Carolina', abbreviation: 'NC' },
    { name: 'North Dakota', abbreviation: 'ND' },
    { name: 'Northern Mariana Islands', abbreviation: 'MP' },
    { name: 'Ohio', abbreviation: 'OH' },
    { name: 'Oklahoma', abbreviation: 'OK' },
    { name: 'Oregon', abbreviation: 'OR' },
    { name: 'Palau', abbreviation: 'PW' },
    { name: 'Pennsylvania', abbreviation: 'PA' },
    { name: 'Puerto Rico', abbreviation: 'PR' },
    { name: 'Rhode Island', abbreviation: 'RI' },
    { name: 'South Carolina', abbreviation: 'SC' },
    { name: 'South Dakota', abbreviation: 'SD' },
    { name: 'Tennessee', abbreviation: 'TN' },
    { name: 'Texas', abbreviation: 'TX' },
    { name: 'Utah', abbreviation: 'UT' },
    { name: 'Vermont', abbreviation: 'VT' },
    { name: 'Virgin Islands', abbreviation: 'VI' },
    { name: 'Virginia', abbreviation: 'VA' },
    { name: 'Washington', abbreviation: 'WA' },
    { name: 'West Virginia', abbreviation: 'WV' },
    { name: 'Wisconsin', abbreviation: 'WI' },
    { name: 'Wyoming', abbreviation: 'WY' },
  ],
  StatusOption: [{ name: 'Enabled' }, { name: 'Disabled' }],
  TaskStatusOption: [{ name: 'Pending' }, { name: 'Done' }],
  GenderOption: [{ name: 'Male' }, { name: 'Female' }],
  TitleOption: [{ name: 'Dr' }, { name: 'Mr' }, { name: 'Mrs' }, { name: 'Prof' }],
  TableOption: {
    page: 1, // which page you want to show as default
    sizePerPage: 10, // which size per page you want to locate as default
    pageStartIndex: 1, // where to start counting the pages
    paginationSize: 3, // the pagination bar size.
    prePage: 'Prev', // Previous page button text
    nextPage: 'Next', // Next page button text
    firstPage: 'First', // First page button text
    lastPage: 'Last', // Last page button text
    paginationShowsTotal: true, // Accept bool or function
    paginationPosition: 'bottom', // default is bottom, top and both is all available
    alwaysShowAllBtns: true, // Always show next and previous buttondefault is bottom, top and both is all available
  },
  TableSizePerPageList: [
    { text: '50', value: 50 },
    { text: '100', value: 100 },
    { text: '200', value: 200 },
  ],
  NumberTypeOption: [
    { name: 'Work' },
    { name: 'Home' },
    { name: 'Phone' },
    { name: 'Mobile' },
    { name: 'Fax' },
    { name: 'Other' },
  ],
  CountryCodeOption: countryCodes,
  RoleModuleOption: [
    { label: 'NO', value: 'NO' },
    { label: 'YES', value: 'YES' },
    { label: 'ASSIGNED', value: 'ASSIGN' },
  ],
  fileuploadexcl: [
    { label: 'Contact', value: 'Contact' },
    { label: 'Company', value: 'Company' },
    { label: 'Project', value: 'Project' },
  ],
  ModuleNamesPage: [
    { label: 'Contacts (Employee)', value: 'USER' },
    { label: 'External Contacts ', value: 'CONTACT' },
    { label: 'Company', value: 'COMPANY' },
    { label: 'Leads and Projects', value: 'PROJECT' },
  ],
  OptionFieldType: [
    { label: 'Text', value: 'TEXT' },
    { label: 'Link/URL', value: 'URL' },
    { label: 'Checkbox', value: 'CHECKBOX' },
    { label: 'Date', value: 'DATE' },
    { label: 'Single Select Dropdown', value: 'Dropdown' },
    { label: 'Multi Select Dropdown', value: 'MultiSelectDropDown' },
    { label: 'Single Select Editable Dropdown', value: 'EditableDropdown' },
    { label: 'Multi Select Editable Dropdown', value: 'MultiSelectEditableDropDown' },
  ],
  OptionHelpType: [
    { name: 'Overview', value: 'Overview' },
    { name: 'CCP', value: 'CCP' },
    { name: 'Masters', value: 'Masters' },
    { name: 'People', value: 'People' },
    { name: 'Projects', value: 'Project' },
    { name: 'Leads', value: 'Lead' },
    { name: 'Reports', value: 'Reports' },
    { name: 'FAQs', value: 'FAQs' },
  ],
  OptionModuleType: [
    { name: 'USER-INTERACTION', value: 'USER-INTERACTION' },
    { name: 'STATUS-CHANGE', value: 'STATUS-CHANGE' },
    { name: 'ADMIN', value: 'ADMIN' },
    { name: 'REPORT-ACCESS', value: 'REPORT-ACCESS' },
    { name: 'Alerts', value: 'Alerts' },
    { name: 'Actions', value: 'Actions' },
  ],
  OptionModuleDefaultPermission: [
    { name: 'YES', value: 'YES' },
    { name: 'NO', value: 'NO' },
    { name: 'ASSIGN', value: 'ASSIGN' },
  ],
  optionLanguageType: [
    { label: 'English', value: 'EN' },
    { label: 'French', value: 'FR' },
  ],
  languages: [
    { name: 'English', code: 'en' },
    { name: 'French', code: 'fr' },
  ],
  OptionDays: [
    { name: 'M', value: 'M' },
    { name: 'T', value: 'T' },
    { name: 'W', value: 'W' },
    { name: 'Th', value: 'Th' },
    { name: 'F', value: 'F' },
    { name: 'S', value: 'S' },
    { name: 'Su', value: 'Su' },
  ],
  OptionDataCheckStatus: [
    { name: 'Bidding jobs past the Bid Date ', value: 'bidding_passed_biddate' },
    { name: 'Bid Pending jobs past the Start Date', value: 'bidpending_passed_startdate' },
    { name: 'Active jobs past the job End Date', value: 'active_passed_enddate' },
    {
      name: 'Jobs in Complete status after the expiration date',
      value: 'warranty_passed_warrantydate',
    },
  ],
  OptionBidRecapAStatus: [
    { name: 'Estimator', value: 'EST' },
    { name: 'Account Executive', value: 'AX' },
    { name: 'Project Manager', value: 'PM' },
    { name: 'Superintendent', value: 'SI' },
    { name: 'Client', value: 'CL' },
    { name: 'Tenant', value: 'TN' },
    { name: 'Broker', value: 'REB' },
    { name: 'Architect', value: 'AR' },
    { name: 'Agent', value: 'AG' },
    { name: 'Lender', value: 'LN' },
    { name: 'Building Type', value: 'buildingType' },
    { name: 'Occupancy Type', value: 'occupancyType' },
    { name: 'Negotiated Method ', value: 'negotiatingMethod' },
    { name: 'Scope of Construction', value: 'scopeArr' },
  ],
  OptionProfitSaleJobs: [
    { name: 'Bid Date', value: 'bidDue' },
    { name: 'Job Start Date', value: 'startDate' },
    { name: 'Job End Date', value: 'endDate' },
    { name: 'Active, PreCon and Delivered', value: 'activeAndDelivered' },
  ],
  OptionProfitSaleMonths: [
    { name: 'Jan', value: '01' },
    { name: 'Feb', value: '02' },
    { name: 'Mar', value: '03' },
    { name: 'Apr', value: '04' },
    { name: 'May', value: '05' },
    { name: 'Jun', value: '06' },
    { name: 'Jul', value: '07' },
    { name: 'Aug', value: '08' },
    { name: 'Sep', value: '09' },
    { name: 'Oct', value: '10' },
    { name: 'Nov', value: '11' },
    { name: 'Dec', value: '12' },
  ],
  OptionProfitSaleQuarter: [
    { name: '1st', value: '1' },
    { name: '2nd', value: '2' },
    { name: '3rd', value: '3' },
    { name: '4th', value: '4' },
  ],
  OptionProjectStatus: [
    { name: 'Bid Pending Response', value: 'BP' },
    { name: 'Phase 1 (Design-bid)', value: 'P1' },
    { name: 'Phase 2 (Design-bid)', value: 'P2' },
    { name: 'Phase 3 (Design-bid)', value: 'P3' },
    { name: 'Active', value: 'A' },
    { name: 'Delivered', value: 'D' },
    { name: 'PreCon', value: 'PC' },
  ],
  OptionAddressFields: [
    { label: 'Firm Name', value: 'companyName' },
    // {"name": "Email", "value":"companyEmail"},
    // {"name": "PhoneNumber", "value":"phoneNumber"},
    { label: 'Address', value: 'companyAddress1' },
    { label: 'Address2', value: 'companyAddress2' },
    { label: 'City Data', value: 'city' },
    { label: 'State', value: 'state' },
    { label: 'Zip Code', value: 'zipCode' },
  ],
  OptionAddressOperator: [
    { label: 'Equals', value: 'equals' },
    { label: 'Contains', value: 'contains' },
    { label: 'Starts With', value: 'startsWith' },
    { label: 'Greater than', value: 'greaterThan' },
    { label: 'Less than', value: 'lessThan' },
  ],
  OptionCustomOperator1: [
    { label: 'Equals', value: 'equals' },
    { label: 'Greater than', value: 'greaterThan' },
    { label: 'Less than', value: 'lessThan' },
  ],
  OptionCustomOperator2: [
    { label: 'Greater than', value: 'greaterThan' },
    { label: 'Less than', value: 'lessThan' },
  ],
  OptionCustomOperatorAdd: [
    { label: 'Equals', value: 'equals' },
    { label: 'Contains', value: 'contains' },
    { label: 'Starts With', value: 'startsWith' },
  ],
  OptionCustomFields: [
    { label: 'Job Num', value: 'jobNumFormatted' },
    { label: 'Alt JobNum', value: 'altJobNum' },
    { label: 'Job Name', value: 'jobName' },
    { label: 'Job Address', value: 'jobAddress' },
    { label: 'Job Address2', value: 'jobAddress2' },
    { label: 'Job City', value: 'jobCity' },
    { label: 'Status', value: 'jobStatusCodesName' },
    { label: 'Cur Contr', value: 'currentContractAmount' },
    { label: 'SqFeet', value: 'sqFeet' },
    // {"name": "Comment", "value":"comment"},
    { label: 'Job Start', value: 'startDate' },
    { label: 'Job End', value: 'endDate' },
  ],
  OptionDateFilter: [
    { label: 'This Year', value: 'thisYear' },
    { label: 'This Quarter', value: 'thisQuarter' },
    { label: 'This Month', value: 'thisMonth' },
    { label: 'Last Quarter', value: 'lastQuarter' },
    { label: 'Last Month', value: 'lastMonth' },
    { label: 'Last Year', value: 'lastYear' },
    { label: 'Next Quarter', value: 'nextQuarter' },
    { label: 'Next Month', value: 'nextMonth' },
    { label: 'Next Year', value: 'nextYear' },
    { label: 'Custom', value: 'custom' },
  ],
  /*optionDateFilterPPr*/
  optionDateFilterPPr: [
    { label: 'Last Quarter', value: 'lastQuarter' },
    { label: 'Last Year', value: 'lastYear' },
    { label: 'This Quarter', value: 'thisQuarter' },
    { label: 'This Year', value: 'thisYear' },
    { label: 'Next Quarter', value: 'nextQuarter' },
    { label: 'Next Year', value: 'nextYear' },
    { label: 'Next 12 Months', value: 'next12months' },
    { label: 'Custom Year', value: 'custom' },
  ],
  OptionSearchBy: [
    { label: 'Project Manager', value: 'projectManager' },
    { label: 'General Superintendent', value: 'projectSupervisor' },
    { label: 'Client', value: 'client' },
  ],
  monthArray: [
    { value: 'Jan', label: 'Jan' },
    { value: 'Feb', label: 'Feb' },
    { value: 'Mar', label: 'Mar' },
    { value: 'Apr', label: 'Apr' },
    { value: 'May', label: 'May' },
    { value: 'June', label: 'June' },
    { value: 'July', label: 'July' },
    { value: 'Aug', label: 'Aug' },
    { value: 'Sept', label: 'Sept' },
    { value: 'Oct', label: 'Oct' },
    { value: 'Nov', label: 'Nov' },
    { value: 'Dec', label: 'Dec' },
  ],
  monthNumArray: [
    { value: 1, label: 'Jan' },
    { value: 2, label: 'Feb' },
    { value: 3, label: 'Mar' },
    { value: 4, label: 'Apr' },
    { value: 5, label: 'May' },
    { value: 6, label: 'June' },
    { value: 7, label: 'July' },
    { value: 8, label: 'Aug' },
    { value: 9, label: 'Sept' },
    { value: 10, label: 'Oct' },
    { value: 11, label: 'Nov' },
    { value: 12, label: 'Dec' },
  ],
  reportList: {
    'Address Label Report': { name: 'Address Label Report', link: 'address-label-report' },
    'Lead Summary Report': { name: 'Lead Summary Report', link: 'project-lead-sheet' },
    'Contact List Report': { name: 'Contact List Report', link: 'contact-list-report' },
    'Lead Source Report': { name: 'Lead Source Report', link: 'lead-source-report' },
    'Client List Report': { name: 'Client List Report', link: 'client-list-report' },
    'Companies (List View)': { name: 'Companies (List View)', link: 'company-list-view-report' },
    'Company Settings Screen': { name: 'Company Settings Screen', link: 'commandpost-settings' },
    'Customized Project List Report': {
      name: 'Customized Project List Report',
      link: 'customized-project-list-report',
    },
    'Bids Due & Bids Pending Report': {
      name: 'Bids Due & Bids Pending Report',
      link: 'bids-due-report',
    },
    'No Bid Report': { name: 'No Bid Report', link: 'no-bid-report' },
    'Bid Result Recap Report': { name: 'Bid Result Recap Report', link: 'bid-result-recap-report' },
    'Lost Jobs Report': { name: 'Lost Jobs Report', link: 'lost-jobs-report' },
    'Active Projects Report': { name: 'Active Projects Report', link: 'active-project-report' },
    'Resource Schedule Report': {
      name: 'Resource Schedule Report',
      link: 'resource-schedule-report',
    },'Project Timeline Report': {
      name: 'Project Timeline Report',
      link: 'project-timeline-report',
    },
    'Resource Schedule Report Classic': {
      name: 'Resource Schedule Report Classic',
      link: 'resource-schedule-report-classic',
    },
    'Master Constructions Schedule Report': {
      name: 'Master Constructions Schedule Report',
      link: 'master-construction-report',
    },
    'Contact Job List': { name: 'Contact Job List', link: 'contact-job-list-report' },
    'Preliminary Info Report': {
      name: 'Preliminary Info Report',
      link: 'project-prelim-info',
    },
    'Project Info Hot Sheet': { name: 'Project Info Hot Sheet', link: 'project-info-hot-sheet' },
    'Timesheet Form': { name: 'Timesheet Form', link: 'timesheet-report' },
    'Project History Report': { name: 'Project History Report', link: 'project-history-report' },
    'Profit Projection Report': {
      name: 'Profit Projection Report',
      link: 'profit-projection-report',
    },
    'Profit & Sales Analysis Report': {
      name: 'Profit & Sales Analysis Report',
      link: 'profit-sales-report',
    },
    'Profit Per Week Analysis Report': {
      name: 'Profit Per Week Analysis Report',
      link: 'profit-per-week',
    },
    'Delivered Project Report': {
      name: 'Delivered Project Report',
      link: 'delivered-project-report',
    },
    'Lien Period Report': { name: 'Lien Period Report', link: 'lien-period-report' },
    'Warranty Status Report': { name: 'Warranty Status Report', link: 'warranty-status-report' },
    'Project Follow-up Report': {
      name: 'Project Follow-up Report',
      link: 'follow-up-project-report',
    },
    'Client References Report': {
      name: 'Client References Report',
      link: 'client-references-report',
    },
    'Data Check Report': { name: 'Data Check Report', link: 'data-check-report' },
    'Employee Roster': { name: 'Employee Roster', link: 'employee-roster-report' },
    'Master Project Log Report': {
      name: 'Master Project Log Report',
      link: 'master-project-report',
    },
  },
  ActiveProjectSortIndex: 20,
  reportModuleName: {
    1: 'Address Label Report',
    2: 'Lead Summary Report',
    3: 'Contact List Report',
    4: 'Lead Source Report',
    CLR: 'Client List Report',
    6: 'Companies (List View)',
    7: 'Customized Project List Report',
    8: 'Bids Due Report & Bids Pending Report',
    9: 'No Bid Report',
    10: 'Bid Result Recap Report',
    11: 'Lost Jobs Report',
    12: 'Active Projects Report',
    13: 'Resource Schedule Report',
    14: 'Master Constructions Schedule Report',
    15: 'Contact Job List',
    16: 'Preliminary Info Report',
    17: 'Profit Info Hot Sheet',
    18: 'Timesheet Form',
    19: 'Project History Report',
    20: 'Profit Projection Report',
    21: 'Profit & Sales Analysis Report',
    22: 'Profit Per Week Analysis Report',
    23: 'Delivered Project Report',
    24: 'Lien Period Report',
    25: 'Warranty Status Report',
    26: 'Project Follow-up Report',
    27: 'Client References Report',
    28: 'Data Check Report',
    29: 'Employee Roster Report',
    30: 'Master Project Log Report',
  },
  errorMsg: {
    zipCodeMsg: 'The value is not a valid zip code!',
    urlMsg: 'Please enter a valid URL!',
    assignCompanyMsg: 'Please select a company!',
    deleteConfirmationMsg: 'Are you sure you want to delete it?',
    contactAmtValidationMsg: 'Profit should be less than the contract amount!',
    Suspended24ErrMsg:
      'You can not change the status of a project (Suspended) more than once in a day!',
    Suspended3TimeErrMsg:
      'You can not change the status of a project (Suspended) more than 3 times!',
    deliveredStartDateMsg: 'Delivery date should be greater than start date!',
    deliveredEndDateMsg: 'Delivery date should be less than or equal to the end date!',
    noResultFound: 'No data found!',
    permissionChange: 'Permissions have been changed on you account, Please wait a moment',
  },
  jobStatusCode: {
    L: 'L',
    NP: 'NP',
    NC: 'NC',
    B: 'B',
    BP: 'BP',
    P1: 'P1',
    P2: 'P2',
    P3: 'P3',
    XC: 'XC',
    R: 'R',
    A: 'A',
    SUSP: 'SUSP',
    D: 'D',
    W: 'W',
    C: 'C',
    OH: 'OH',
  },
  roleList: {
    role1: 'VP Operations',
    role2: 'businessOwnerArr.userFullName',
    role3: 'accountExecName',
    role4: 'estimatorArr.userFullName',
    role5: 'projectSupervisorArr.userFullName',
    role6: 'Admin Assistant',
    role7: 'Finance',
    role8: 'foremanArr.userFullName',
    role9: 'projectManagerArr',
    role10: 'HR',
    role11: 'BD - Sales',
    role13: 'Property Owner',
    role17: 'managingAgent.userFullName',
    role18: 'lenderArr.userFullName',

    foremanCode: 'PE',
    foremanArr: 'foremanArr',
    foremanName: 'foremanName',

    accountExecCode: 'AX',
    accountExecArr: 'accountExecArr',
    accountExecName: 'accountExecName',

    estimatorCode: 'EST',
    estimatorArr: 'estimatorArr',
    estimatorName: 'estimatorName',
    estimatorArrMatched: 'estimatorArrMatched',
    estimatorArrLabel: 'estimatorArrLabel',

    projectManagerCode: 'PM',
    projectManagerArr: 'projectManagerArr',
    projectManagerArrMatched: 'projectManagerArrMatched',
    projectManagerArrLabel: 'projectManagerArrLabel',
    projectManagerName: 'projectManagerName',
    projectManagerNameLabel: 'projectManagerNameLabel',

    projectSupervisorName: 'projectSupervisorName',
    projectSupervisorCode: 'SI',

    tenantCode: 'TN',
    tenantName: 'tenantName',
    tenantArr: 'tenantArr',

    agentCode: 'MA',
    agentName: 'agentName',
    agentArr: 'agentArr',

    lenderCode: 'LN',
    lenderArr: 'lenderArr',
    lenderName: 'lenderName',

    clientCode: 'CL',
    clientArr: 'clientArr',
    clientName: 'clientName',

    buildingOwnerCode: 'PO',
    buildingOwnerArr: 'buildingOwnerArr',
    buildingOwnerName: 'buildingOwnerName',

    brokerCode: 'REB',
    brokerArr: 'brokerArr',
    brokerName: 'brokerName',

    architectCode: 'AR',
    architectArr: 'architectArr',
    architectName: 'architectName',

    billToCode: 'BT',

    negotiatingMethod: 'negotiatingMethod',
    jobAdd: 'jobAddress',
    jobName: 'jobName',
    jobNum: 'jobNum',
    jobNumFormatted: 'jobNumFormatted',
    altJobNum: 'altJobNum',
    jobCode: 'jobStatusCodesCode',
    jobCodeName: 'jobStatusCodesName',
    sqFeet: 'sqFeet',
    currAmount: 'currentContractAmount',
    projectStatus: 'projectStatus',
    startDate: 'startDate',
    endDate: 'endDate',
    orgAmount: 'originalContractAmount',
  },
  accountExecLabelName: 'Account Exec',
  accountExecLabelNameShort: 'Acc. Exec.',
  estimatorLabelNameShort: 'Proj. Est.',

  projectPerMonth: [
    { value: '500', label: '0-500' },
    { value: '501', label: 'Above 500' },
    { value: '1000', label: 'Above 1000' },
    { value: '5000', label: 'Above 5000' },
    { value: '10000', label: 'Above 10000' },
  ],

  multiSelectList: [
    { value: 'all', label: 'All' },
    { value: 'contacts', label: 'Contacts / Employees' },
    { value: 'companies', label: 'Companies' },
    { value: 'projects', label: 'Leads / Projects' },
    { value: 'report', label: 'Reports' },
  ],

  numberOfEmployees: [
    { value: '500', label: '0-500' },
    { value: '1000', label: 'Above 1000' },
    { value: '5000', label: 'Above 5000' },
    { value: '10000', label: 'Above 10000' },
  ],
  planList: [
    { label: 'day', value: 'day' },
    { label: 'months', value: 'month' },
    { label: 'year', value: 'year' },
  ],
  constName: {
    lead: 'Lead',
    notBid: 'Not Bid (closed)',
  },
  phoneOptions: ['Work', 'Mobile', 'Home', 'Fax', 'Other'],
  addressOptions: ['Work', 'Home', 'Other'],
  emailOptions: ['Work', 'Personal', 'Other'],
  defaultFieldValues: {
    phone: { ext: '1', type: 'Work', phone: '', country: 'US', fullPhone: '1', extPhone: '', phoneClean: '' },
    email: { label: '', email: '' },
    address: { address1: '', address2: '', city: '', country: '', state: '', zipCode: '', label: '' },
  },
};
