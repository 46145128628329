import { dateFormatMMDDYYYY } from '../../../utils/date-formatters';
import Joi from 'joi';
import styles from './style.module.scss';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getTagNames } from '../../../modules/companies';

type ViewCompanyHeaderProps = {
  company: any;
};

export const ViewCompanyHeader = ({ company = {}}: ViewCompanyHeaderProps) => {

  const { companyName, dba, createdAt, pictureUrl, companyTags } = company;
  const { error: pictureError } = Joi.string().uri().required().validate(pictureUrl);
  const name = companyName;
  const hasDba = dba?.length>0 && dba !== companyName;
  const [companyTagNames, setCompanyTagNames] = useState<any>([]);

  const dispatch = useDispatch();

  useEffect(() => {
    if(companyTags) {
      (async () => {
        const tagNames = await dispatch((getTagNames as any)({ tagIds: companyTags })).unwrap();
  
        setCompanyTagNames(tagNames);
      })();
    } else {
      setCompanyTagNames([]);
    }
  }, [company]);

  const tagList = (companyTagNames = []) => {
    let stringTags = companyTagNames.map((tagName) => tagName).join(', ');

    if (stringTags.length > 70) {
      stringTags = stringTags.substring(0, 70) + "...";
    }

    return stringTags;
  }

  return (
    <div className={styles.wrapper} data-testid='viewCompanyHeader'>
      {pictureError
        ? <i className='fa-solid fa-2x fa-briefcase prof_img' data-testid='viewCompanyHeader-logo-placeholder'></i>
        : <img src={pictureUrl} alt='Company logo' title={name} data-testid='viewCompanyHeader-logo' />}
      <div className='d-flex flex-column p-1'>
        <span className={`${styles.title} mb-1`} data-testid='viewCompanyHeader-name'>{name}</span>
        {hasDba && (
          <span className={`${styles.subtitle} mb-1`} data-testid="viewCompanyHeader-dba">
            DBA: {dba}
          </span>
        )}
        <div>
          <small>
            <span data-testid='viewCompanyHeader-created' className={styles.createdOn}>Created On {dateFormatMMDDYYYY(createdAt)}</span>
            <div className={styles.tagWrapper}>
              {tagList(companyTagNames)}
            </div>
          </small>
        </div>
      </div>
    </div>
  );
};
