import React, { useState, useEffect } from 'react';


export const InputFieldSubmit = ({actions, disabled}) => {
    const [isLoading, setIsLoading] = useState([]);
  
   /* const simulateNetworkRequest = () => {
      return new Promise((resolve) => setTimeout(resolve, 2000));
    };*/
  
    useEffect(() => {
      const initialLoadingState = actions.map((action) => action.loadingActive);
      setIsLoading(initialLoadingState.every((item) => item === false));
    }, [actions]);
  
    const handleClick = (e) => {
      const { key } = e.target.dataset;
      const updatedLoadingState = [...isLoading];
      updatedLoadingState[key] = true;
      setIsLoading(updatedLoadingState);
      if(actions[key].onClickFun)
        actions[key].onClickFun(e);
  
      /* Uncomment this section if you want to simulate a network request
      simulateNetworkRequest().then(() => {
        updatedLoadingState[key] = false;
        setIsLoading(updatedLoadingState);
      });
      */
    };
  
    useEffect(() => {
      const updatedLoadingState = [...isLoading];
  
      actions.forEach((key, index) => {
        if (updatedLoadingState[index] === true && !key.loadingActive) {
          updatedLoadingState[index] = false;
        }
      });
  
      setIsLoading(updatedLoadingState);
    }, [actions]);
  
    return (
      <span className='addProjectPopupBottomBtnBox'>
        {actions.map((key, index) => (
          <button
            disabled={disabled||isLoading[index] && key.loadingActive}
            onClick={!isLoading[index] || !key.loadingActive ? handleClick : null}
            key={index}
            id={index}
            data-key={index}
            {...key}
          >
            {isLoading[index] && key.loadingActive ? key.loading : key.title}
          </button>
        ))}
      </span>
    );
  };
  