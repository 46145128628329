
import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDisabledFieldsContext } from '../../disabled-fields';
import { convertAddressToFormValue, toContactAddress } from '../../../utils/address';
import { appConstants } from '../../../_constants';
import FormInput from '../form-input';
import FormSearchPlaceAutocomplete from '../form-search-place-autocomplete';
import FormSelect from '../form-select';
import Star from '../field-actions/star/star';
import Add from '../field-actions/add/add';
import Copy from '../field-actions/copy/copy';
import Delete from '../field-actions/delete/delete';
import styles from '../field-actions/field-actions.module.scss';
//import { v4 as uuidv4 } from 'uuid';

type AddressArrayProps = {
  name: string;
  copyData?: any;
  onEditCompany?: () => void;
};

const AddressArray = ({
  name = 'addressArr',
  copyData = {},
  onEditCompany = () => void 0,
}: AddressArrayProps) => {

  const { control, setValue, getValues, watch } = useFormContext<any>();
  const forceDisabled = useDisabledFieldsContext();
  const dataArr = watch(name, []);

  useEffect(() => {
    /*if (dataArr.length === 0) {
      setValue(name, [{ ...appConstants.defaultFieldValues.address, _id: uuidv4() }]);
    }*/
  }, [dataArr.length]);

  const labelOptions = useMemo(
    () => appConstants.addressOptions.map((value) => ({ value, label: value })),
    []
  );

  const handleSearchSelection = (index: number) => (res: any) => {
    const payload = toContactAddress(res);
    const prefix = `${name}.${index}`;

    const address1 = convertAddressToFormValue(payload?.streetNumber, payload?.route);
    setValue(prefix, {
      ...getValues(prefix),
      address1,
      state: payload?.state,
      city: payload?.city,
      zipCode: payload?.zipCode,
      country: payload?.country,
    });
  };

  const controlsWrapperClassName = `col-md-1 ${styles.rowControlsWrapper}`;
  const dataRowClassName = `d-flex gap-0 row align-items-baseline ${styles.rowWrapper}`;
  const hasData = dataArr?.length > 0;

  return (
    <div data-testid='AddressArray' className={styles.wrapper}>
      {Array.isArray(dataArr) && dataArr.map((field, index) => (
        <div className={dataRowClassName} data-testid={`AddressItem-${index}`} key={field._id}>
          <span className='col-md-2 '>{index==0 &&('Address')}</span>
          <div className={`${controlsWrapperClassName} justify-content-end`}>
            <Star name={name} index={index} />
          </div>
          <div className='col-md-8 col-11'>
            <div className='row'>
              <div className='col-md-12'>
                <FormSearchPlaceAutocomplete
                  control={control}
                  label='Street'
                  name={`${name}.${index}.address1`}
                  onSelect={handleSearchSelection(index)}
                />
              </div>
              <div className='col-md-12'>
                <FormInput
                  label='Apt/Suite'
                  name={`${name}.${index}.address2`}
                  control={control}
                />
              </div>
              <div className='col-sm-12'>
                <FormInput label='City' name={`${name}.${index}.city`} control={control} />
              </div>
              <div className='col-sm-6 pe-sm-1'>
                <FormInput label='State' name={`${name}.${index}.state`} control={control} />
              </div>
              <div className='col-sm-6 ps-sm-1'>
                <FormInput label='Zip Code' name={`${name}.${index}.zipCode`} control={control} />
              </div>
              <div className='col-sm-12'>
                <FormInput label='Country' name={`${name}.${index}.country`} control={control} />
              </div>
              <div className={`col-sm-12 ${styles.selectWrapper}`}>
                <FormSelect
                  isClearable
                  options={labelOptions}
                  name={`${name}.${index}.label`}
                  creatable
                  control={control}
                  label='Label'
                />
              </div>
              {index<dataArr.length-1 &&(<div className='mb-4'/>)}
            </div>
          </div>
          {forceDisabled || <div className={`${controlsWrapperClassName} justify-content-start col-1`}>
            <Delete name={name} index={index} />
          </div>}
        </div>
      ))}
      
      <div className={dataRowClassName}>
         {!hasData && (<span className='col-md-2 d-flex gap-2'>Address</span>)}
        <div className={`col-md-8 offset-md-${hasData ? '3' : '1'} d-flex gap-2`}>
          <Copy name={name} type='address' data={copyData} onEditCompany={onEditCompany} />
          <Add name={name} type='address' />
        </div>
      </div>
    </div>
  );
};

export default AddressArray;
