
import { createAsyncThunk } from '@reduxjs/toolkit';
import * as userAPI from '../../api/Admin/user.js';
//import { RootState } from '../../modules/store.js';


export const getTeamActivity = createAsyncThunk(
    'contacts/getTeamActivity',
    async (arg: { count: number; page: number }) => {
      const { page, count } = arg;
      const res = await userAPI.getTeamActivityRequest({ page, count });
  
      return res;
    }
  );
  

  //export const selectGetTeamActivity = (state: RootState) => state.rtk.contacts.getTeamActivity;