import { useCallback, useState } from 'react';
import FormCheckbox from '../../form-controls/form-checkbox';
import FormInput from '../../form-controls/form-input';
import { useFormContext } from 'react-hook-form';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const EnterUrlModal = ({ open, onClose }: any) => {
  return (
    <Modal isOpen={open} toggle={onClose}>
      <ModalHeader toggle={onClose}>Enter URL</ModalHeader>
      <ModalBody>
        <FormInput name='valueDefinition.defaultValueUrl' label='Default Value URL' />
      </ModalBody>
      <ModalFooter>
        <button className='btn btn-primary' onClick={onClose}>
          OK
        </button>
      </ModalFooter>
    </Modal>
  );
};

export const TextInputSection = () => {
  const form = useFormContext();

  const isUrl = form.watch('valueDefinition.isUrl');
  const isTextArea = form.watch('valueDefinition.isTextArea');

  const [urlModalOpen, setUrlModalOpen] = useState(false);

  // clear default value url when isUrl is false
  const onIsUrlChange = useCallback((e) => {
    if (!e.target.checked) {
      form.setValue('valueDefinition.defaultValueUrl', '');
    }
  }, []);

  const onTextAreaChange = useCallback((e) => {
    if (!e.target.checked) {
      form.setValue('valueDefinition.maxRows', '6');
      form.setValue('valueDefinition.minRows', '2');
    }
  }, []);

  return (
    <div>
      <div className='row'>
        <div className='col-12'>
          <label className='form-label'>Text Input Options</label>
        </div>

        <div className='col-6'>
          <FormCheckbox
            className='flex-row-reverse mb-1'
            name='valueDefinition.isUrl'
            label='Is URL'
            onChange={onIsUrlChange}
          />
        </div>
        <div className='col-6'>
          <FormCheckbox
            className='flex-row-reverse mb-1'
            name='valueDefinition.isTextArea'
            label='Is Text Area'
            onChange={onTextAreaChange}
          />
        </div>
      </div>

      <div className='row mt-2'>
        <div className='col-6'>
          <FormInput
            name='valueDefinition.defaultValue'
            label='Default Value'
            inputText={
              isUrl ? (
                <span role='button' onClick={() => setUrlModalOpen(true)}>
                  <i className='fa fa-link'></i>
                </span>
              ) : null
            }
          />
          <EnterUrlModal open={urlModalOpen} onClose={() => setUrlModalOpen(false)} />
        </div>

        <div className='col-6'>
          <FormInput name='valueDefinition.placeholder' label='Placeholder' />
        </div>
      </div>

      {isTextArea && (
        <div className='row mt-2'>
          <div className='col-6'>
            <FormInput name='valueDefinition.minRows' label='Default Lines'  type='number'/>
          </div>

          <div className='col-6'>
            <FormInput name='valueDefinition.maxRows' label='Max Lines'  type='number'/>
          </div>
        </div>
      )}


    </div>
  );
};
