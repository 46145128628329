import * as actionTypes from '../../actions/Admin/actionTypes';
const initState = {
  isfetched: false,
  isfetchedError: false,
  success: '',
  message: '',

  isUserModule: [],
  isUserModuleSuccess: false,
  isUserModuleError: false,

  isUserPermission: false,
  isCppRole: false,

  isEditUserPermission: false,
  isEditUserPermissionSuccess: false,
  isEditUserPermissionError: false,
};

export default (state = initState, action = {}) => {
  switch (action.type) {
    case actionTypes.GET_USER_ROLE_MODULE_REQUEST:
      return {
        ...state,
        isUserModuleSuccess: false,
        isUserModuleError: false,
        message: '',
        success: '',
      };

    case actionTypes.GET_USER_ROLE_MODULE_SUCCESS:
      return {
        ...state,
        isUserModule: action.payload.response.result.data,
        isUserModuleSuccess: true,
        isUserModuleError: false,
        message: '',
        success: '',
      };

    case actionTypes.GET_USER_ROLE_MODULE_ERROR:
      return {
        ...state,
        isUserModuleSuccess: false,
        isUserModuleError: true,
        message: '',
        success: '',
      };

    // get user permission
    case actionTypes.GET_USER_PERMISSION_REQUEST:
      return {
        ...state,
        isUserPermission: false,
        message: '',
        success: '',
      };
    case actionTypes.GET_USER_PERMISSION_SUCCESS:
      return {
        ...state,
        message: action.payload.response.result.msg,
        success: action.payload.response.result.success,
        isUserPermission: action.payload.response.result.data
          ? action.payload.response.result.data
          : {},
      };

    case actionTypes.GET_USER_PERMISSION_ERROR:
      return {
        ...state,
        isUserPermission: false,
        message: '',
        success: '',
      };

    // get ccp role
    case actionTypes.GET_CPOST_ROLE_REQUEST:
      return {
        ...state,
        isCppRole: false,
        message: '',
        success: '',
      };

    case actionTypes.GET_CPOST_ROLE_SUCCESS:
      return {
        ...state,
        isCppRole: action.payload.response.result.data,
        message: '',
        success: '',
      };

    case actionTypes.GET_CPOST_ROLE_ERROR:
      return {
        ...state,
        message: '',
        success: '',
        isCppRole: false,
      };

    // Edit user permission

    case actionTypes.EDIT_CCP_ROLE_REQUEST:
      return {
        ...state,
        buttonActive: true,
        isEditUserPermission: false,
        message: '',
        accountRoleMatrixStatus: false,
      };
    case actionTypes.EDIT_CCP_ROLE_SUCCESS:
      return {
        ...state,
        buttonActive: false,
        isEditUserPermission: action.payload.response.result.data
          ? action.payload.response.result.data
          : [],
        accountRoleMatrixStatus: true,
        success: action.payload.response.result.success,
        message: action.payload.response.result.success
          ? 'Record updated successfully'
          : action.payload.response.result.msg,
      };

    case actionTypes.EDIT_CCP_ROLE_ERROR:
      return {
        ...state,
        buttonActive: false,
        isEditUserPermission: false,
        accountRoleMatrixStatus: false,
        // success: action.payload.response.result.success,
        // message: action.payload.response.result.msg
        success: action.payload.error.success,
        message: action.payload.error.msg,
      };

    default:
      return state;
  }
};
