import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import FormCheckbox from '../../form-controls/form-checkbox';
import FormSelect from '../../form-controls/form-select';
import FormMultiselect from '../../form-controls/form-multiselect';
import { projectDates, contactDates } from '../constants';
import { useAppDispatch } from '../../../shared/hooks/use-app-dispatch';
import { getUserSettings } from '../../../modules/settings';

export const DateInputSection = () => {
  const dispatch = useAppDispatch();
  const { watch, setValue } = useFormContext();
  const page = watch('page');
  const showOnCalendar = watch('valueDefinition.showOnCalendar');

  const [projectRoles, setProjectRoles] = useState<Array<any>>([]);

  const [isProjectDate, setIsProjectDate] = useState(false);

  useEffect(() => {
    if (page === 'projects') {
      dispatch(getUserSettings(['projectRoleTypes']))
        .unwrap()
        .then((data: Array<any>) => {
          const roles = data.map(({ _id, name }) => ({ roleId: _id, label: name }));
          setProjectRoles(roles);
        });
      setIsProjectDate(true);
    }else{
      setIsProjectDate(false);
    }
  }, [page]);

  useEffect(() => {
    // if used unchecks showOnCalendar - clear assigned roles
    if (!showOnCalendar) {
      setValue('valueDefinition.assignedRoles', []);
    }
  }, [showOnCalendar]);

  const options = useMemo(() => {
    switch (page) {
      case 'projects':
        return [...projectDates];
      case 'contacts':
        return [...contactDates];
      default:
        return [];
    }
  }, [page]);

  const getRoleLabel = useCallback((option: any) => {
    const foundRole = projectRoles.find((role) => role.roleId === option.roleId);
    return foundRole ? foundRole.label : option.roleId;
  }, [projectRoles]);

  return (
    <div>
      <div className='row'>
        <div className='col-12'>
          <label className='form-label'>Date Input Options</label>
        </div>
      </div>

      <div className='row'>
        <div className='col-6'>
          <div className='row'>
            <div className='col-12'>
              <FormCheckbox
                className='flex-row-reverse mb-1'
                name='valueDefinition.allowTime'
                label='Allow time'
              />

              <div className='col-12'>
                <FormCheckbox
                  className='flex-row-reverse mb-1'
                  name='valueDefinition.showOnCalendar'
                  label='Show on calendar'
                />
              </div>

              {showOnCalendar && isProjectDate && (
                <div className='col-12'>
                  <FormMultiselect
                    label='Assigned Roles'
                    name='valueDefinition.assignedRoles'
                    options={projectRoles}
                    getOptionLabel={getRoleLabel}
                    getOptionValue={(role: any) => role.roleId}
                    useObjectValue
                  />
                </div>
              )}

              <div className='col-12'>
                <FormSelect
                  label='Greater than / equal'
                  name='valueDefinition.greaterThanEqual'
                  options={options}
                />
              </div>

              <div className='col-12'>
                <FormSelect
                  label='Less than / equal'
                  name='valueDefinition.lessThanEqual'
                  options={options}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
