import styled from 'styled-components';

export const styles = {
    paginationButtons: {
        padding: 5,
        textAlign: 'center',
        textDecoration: 'none',
        display: 'inline-block',
        fontSize: 16,
        cursor: 'pointer',
        backgroundColor: 'white',
        color: 'gray',
        border: 'none',
        borderRadius: 8
    },
    disabledButton: {
        cursor: 'auto',
        color: 'lightgray'
    },
    dateInfoText: {
        textAlign: 'center',
        marginBottom: 16,
    },
    dateFilters: {
        padding: 16,
        borderRadius: 8,
        border: '1px solid #ccc',
        backgroundColor: '#f7f7f7',
        maxWidth: 360,
        margin: '16px 0',
    },
    filterLabel: {
        maxWidth: 190,
        display: 'block',
        margin: '0 auto 15px',
        textAlign: 'center',
    },
    dateFieldGroup: {
        display: 'flex',
        alignItems: 'center',
        gap: 16,
    },
    dateFieldLabel: {
        flexShrink: 0,
    },
    dropdownFilter: {
        backgroundColor: 'lightgray',
        borderRadius: 6,
        width: '100%',
        color: 'black',
        cursor: 'pointer',
    },
    dateDropdown: {
        cursor: 'pointer',
        outline: 'none',
        borderRadius: 6,
        width: '100%',
        marginBottom: 16,
        border: '2px solid #000000',
        color: '#000000',
        backgroundColor: '#fff',
        padding: '6px',
        boxSizing: 'border-box',
},
    dateDropdownDisabled: {
        border: '2px solid transparent',
        background: 'lightgrey',
        cursor: 'pointer',
        outline: 'none',
        borderRadius: 6,
        color: 'grey',
        width: '100%',
        marginBottom: 16,
        padding: '6px',
    },
    datepickerWrapper: {
        display: 'none'
    },
    clearButton: {
        margin: '10px 0',
        display: 'flex',
        justifyContent: 'flex-end'
    }
}

export const DatePickerWrapperStyle = styled.div`
  .react-datepicker-wrapper {
    width: inherit;
  }

  .react-datepicker-wrapper input {
    width: inherit;
    border-radius: 6px;
    cursor: pointer;
    padding: 5px;
    margin-right: 5px;
    border: 2px solid #000000;
    color: #000000;
    background-color: #fff;
  }
  
  .react-datepicker-wrapper input:disabled {
    background: lightgrey;
    border: none;
    color: grey;
  }
`

export const TableStyles = styled.div`
  padding: 1rem 0;

  table {
    border-spacing: 0;
    border: 1px solid rgba(0, 0, 0, 0.2);
    margin-top: 15px;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.2rem;
      border-right: 1px solid rgba(0, 0, 0, 0.2);
      font-size: 12px;

      :last-child {
        border-right: 0;
      }

      .resizer {
        display: inline-block;
        width: 10px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(50%);
        z-index: 1;
        touch-action: none;

        &.isResizing {
        }
      }
    }

    tr:not(.highlightedRow):nth-child(even) {
      background-color: #f2f2f2;
    }

    tr.highlightedRow {
      background-color: #ffeeee;
    }

    tr:hover {
      background-color: #ddd;
    }

    th {
      background-color: rgb(245,245,245);
    }

    td:empty::before {
      content: '\\00a0';
      visibility: hidden
    }
    
    tr {
      display: flex;
    }

    .sort-icon-wrapper {
     
      padding: 8px;
    }

    .sort-icon-wrapper:hover,
    .sort-icon-wrapper:active,
    .sort-icon-wrapper:focus,
    .sort-icon-wrapper:active:focus {
      background-color: #CCCCCC;
      box-shadow: 0 0 0 0 #fff;
      border-radius: 5px;
    }

    .groupedHeaderCell{
      background-color: #e3e3e3;
    }

    .groupedColumnCell{
      padding: 0px;
    }

    .expander{
      padding-right: 10px;
    }

    /* Style for highlighted row */
    .highlightedRow {
     background-color: yellow; /* Highlighted row background color */
   }
   
   /* Style for text in highlighted row */
   .highlightedRow .text {
     color: var(--text-color); /* Use the defined text color */
   }
   
   /* Style for links in highlighted row */
   .highlightedRow a {
     color: var(--text-color); /* Use the defined text color */
     text-decoration: none; /* Remove underline */
   }
   
   /* Hover effect for links in highlighted row */
   .highlightedRow a:hover {
     text-decoration: underline; /* Add underline on hover */
   }
  }
`