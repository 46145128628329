import { notification } from 'antd';
import axios from 'axios';
import { appConstants } from '../_constants';

export const fileUploadOnS3 = async (files, objectId, typeId, typeRef, attachmentLimit = 25000000, uploadType = 'Notes') => {
  files = files?.filter(Boolean) ?? [];

  let uploadFailed = false;

  if (!files || files.length === 0) {
    return { attachments: [], objectId: objectId, uploadSuccess: true };
  } else {
    let totalFileSize = 0;
    let attachments = [];

    for (let i = 0; i < files.length; i++) {
      totalFileSize += files[i]?.size ?? 0;
    }

    if (totalFileSize > attachmentLimit) {
      notification.error({
        message: `Total file upload exceeds ${attachmentLimit / 1024 / 1024}mb, please remove some attachments`,
        duration: 5,
      });
      uploadFailed = true;
    } else {
      for (let i = 0; i < files.length; i++) {
        let file = files[i];

        const uploadedKeys = attachments.map((attachment) => attachment.fileKey);

        if (file.alreadyUploaded) {
          if (uploadedKeys.includes(file.fileKey)) {
            notification.error({
              message: `File ${file.name} already uploaded!`,
              duration: 2,
            });
            uploadFailed = true;
            continue;
          }

          attachments.push(setAWSurlDirect(file, i));
          continue;
        }

        let fileName = files[i].name.trim();

        let uploadData;


        // Use presigned URL method for other types (including FormTemplate)
        try {
          const presignedUrlResponse = await axios.post(
            'parse/functions/createPresignedAttachmentURL',
            {
              userId: localStorage.getItem('userId'),
              accountId: localStorage.getItem('accountId'),
              typeId,
              typeRef,
              fileName,
              uploadType,
              objectId,
            },
            {
              baseURL: appConstants.WEB_SERVICE_URL_WITHOUT_PARSE,
              headers: {
                'X-Parse-Application-Id': appConstants.PARSE_APPLICATION_ID,
                'X-Parse-REST-API-Key': appConstants.PARSE_REST_API_KEY,
                'X-Parse-Session-Token': localStorage.getItem('sessionToken'),
                'authorization': localStorage.getItem('JWTTOKEN'),
              },
            }
          );

          if (presignedUrlResponse.data.success !== appConstants.successCODE && presignedUrlResponse.data || !(presignedUrlResponse.data?.result?.presignedURL)) {
            if (presignedUrlResponse.data.msg) {
              throw new Error(presignedUrlResponse.data.msg);
            }
            else {
              throw new Error('Error getting presigned URL');
            }
          }

          const { presignedURL, bucket, fileKey, objectId: newObjectId } = presignedUrlResponse.data.result;

          // Upload file to S3 using presigned URL
          await axios.put(presignedURL, file, {
            headers: {
              'Content-Type': file.type,
            },
          });

          uploadData = { url: presignedURL, fileKey, objectId: newObjectId };
        } catch (error) {
          console.error('Error uploading file:', error);
          uploadData = { url: null };
        }


        if (!uploadData.url) {
          notification.error({
            message: `Upload of ${fileName} failed!`,
            duration: 2,
          });
          uploadFailed = true;
        } else {
          if (uploadedKeys.includes(uploadData.fileKey)) {
            notification.error({
              message: `File ${file.name} already uploaded!`,
              duration: 2,
            });
            uploadFailed = true;
            continue;
          }

          attachments.push(setAWSurl(file, uploadData, i));
          if (!objectId || objectId === '' || objectId?.length === 0) {
            objectId = uploadData.objectId;
          }
        }
      }
    }
    return { attachments: attachments, objectId: objectId, uploadSuccess: !uploadFailed };
  }
};

const setAWSurl = (file, uploadData, index) => {
  return {
    fileKey: uploadData.fileKey,
    fileName: file.name,
    fileType: file.type,
    fileSize: file.size,
    fileModified: file.lastModifiedDate,
  };
};

const setAWSurlDirect = (file, index) => {
  return {
    fileKey: file.fileKey,
    fileName: file.name,
    fileType: file.type,
    fileSize: file.size,
    fileModified: file.lastModifiedDate,
  };
};