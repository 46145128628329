import { useEffect, useState } from 'react';
import { notification } from 'antd';

import axios from 'axios';
import Avatar from 'react-avatar';
import { clearLoggedInUser } from '../../api/helper.js';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Userpilot } from 'userpilot';
import MainMenu from './MainMenu.component.jsx';
import history from '../../routes/history';
import {
  appConstants,
  isAllowed,
  moduleConstants,
  reportAccess,
  userInteraction,
} from '../../_constants';
import {useAuth} from '../Auth/Auth';
import { ClickAwayListener } from '@material-ui/core';
import { useAppDispatch } from '../../shared/hooks/use-app-dispatch';
import { getNotificationActivity } from '../../modules/contacts/index';
import moment from 'moment';

Userpilot.initialize(process.env.REACT_APP_USER_PILOT_KEY);

// TODO: it's better to move whole Header to layout folder and split it into smaller components
const NotificationBell = () => {
  const [open, setOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);

  const showClassName = open ? 'show' : '';

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!open) return;

    dispatch(
      getNotificationActivity({
        count: 5,
        page: 1,
      })
    )
      .unwrap()
      .then((data) => {
        setNotifications(data.notificationActivity);
      });
  }, [open]);

  const activityList = notifications.map((notification) => {
    const projectId = notification.projectObjectId;
    const contactId = notification.contactId;
    const companyId = notification.companyId;
    const title = notification.title;
    const date = notification._created_at;

    const ago = moment(date).fromNow();

    return (
      <li className='nav-item dropdown-item' key={notification._id}>
        <div className='row flex-nowrap'>
          <div className='col-8 text-truncate'>
            <span style={{ fontSize: '12px' }}>
              {projectId ? (
                <Link
                  className=''
                  to={{
                    pathname: `/project-details/${projectId}`,
                  }}
                >
                  {title}
                </Link>
              ) : contactId ? (
                <Link
                  className=''
                  to={{
                    pathname: `/contact-details/${contactId}`,
                  }}
                >
                  {title}
                </Link>
              ) : companyId ? (
                <Link
                  className=''
                  to={{
                    pathname: `/company-details/${companyId}`,
                  }}
                >
                  {title}
                </Link>
              ) : (
                <span>{title}</span>
              )}
            </span>
          </div>

          <div className='col'>
            <span style={{ fontSize: '10px' }}>
              <i className='fa fa-clock-o' aria-hidden='true' /> {ago}
            </span>
          </div>
        </div>
      </li>
    );
  });

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div>
        <a
          className={`nav-link ${showClassName}`}
          role='button'
          aria-expanded={open}
          onClick={() => setOpen((o) => !o)}
        >
          <button className='btn  header-button header-button-icon'>
            <b>
              <i className='fa-solid fa-lg fa-bell'></i>
            </b>
          </button>
        </a>
        <ul className={`dropdown-menu dropdown-menu-end ${showClassName}`} data-bs-popper='static'>
          {activityList}
          <li>
            <hr className='dropdown-divider' />
          </li>
          <li className='nav-item dropdown-item'>
            <Link
              className=''
              to={{
                pathname: `/notifications`,
              }}
            >
              <small> View All </small>
            </Link>
          </li>
        </ul>
      </div>
    </ClickAwayListener>
  );
};

function Header(props) {
  const [sessionToken] = useState(localStorage.getItem('sessionToken'));
  const [messageLogout, setMessageLogout] = useState(false);
  const [companyNameInfo] = useState(localStorage.getItem('companyName'));
  const [userInfo] = useState(JSON.parse(localStorage.getItem('userInfo')));
  const [comLogo, setComLogo] = useState({});

  const { t } = useTranslation();

  useEffect(() => {
    isAuthenticatedSession();
  }, []);

  useEffect(() => {
    if (localStorage.getItem('accountInfo') !== 'undefined') {
      let companyNameInfo = JSON.parse(localStorage.getItem('accountInfo'));
      if (
        localStorage.getItem('companyLogo') === '' ||
        localStorage.getItem('companyLogo') === null ||
        localStorage.getItem('companyLogo') === undefined
      ) {
        if (companyNameInfo && companyNameInfo.imageUrl) {
          localStorage.setItem('companyLogo', companyNameInfo.imageUrl);
          setComLogo(companyNameInfo.imageUrl);
        }
      }
    }

    if (
      localStorage.getItem('companyLogo') !== '' ||
      localStorage.getItem('companyLogo') !== null ||
      localStorage.getItem('companyLogo') === undefined
    )
      setComLogo(localStorage.getItem('companyLogo'));

    return () => {
      // Cleanup code here (if needed)
    };
  }, []);

  const isAuthenticatedSession = () => {
    axios({
      method: 'POST',
      url: appConstants.WEB_SERVICE_URL + '/functions/getUserInfo',
      headers: {
        'X-Parse-Application-Id': appConstants.PARSE_APPLICATION_ID,
        'X-Parse-REST-API-Key': appConstants.PARSE_REST_API_KEY,
        'X-Parse-Session-Token': localStorage.getItem('sessionToken'),
        authorization: localStorage.getItem('JWTTOKEN'),
      },
      data: {
        userId: localStorage.getItem('userId'),
        accountId: localStorage.getItem('accountId'),
      },
    }).catch(function (error) {
      if (error.response) {
        if (error.response.status === 400 && error.response.data.code === 209) {
          if (!messageLogout) {
            setMessageLogout(true);
            notification.error({
              message:
                'Your account was recently logged into from a new browser or device. Was this you?',
              duration: 5,
            });
            //logout();
            clearLoggedInUser();
            history.replace('/');
          }
        } else {
          if (error.code === 'ERR_NETWORK') {
            notification.error({
              message: error.message + ' \r\nCommandPost Server may not be available',
              duration: 5,
            });
          } else {
            notification.error({
              message: error.message,
              duration: 5,
            });
          }
        }
      } else {
        notification.error({
          message: 'Unknown error!',
          duration: 5,
        });
      }
    });
  };

  

  Userpilot.reload();

  const canAddContact = isAllowed(moduleConstants.EC);
  const canAddEmployee = isAllowed(moduleConstants.EE);
  const canAddLead = isAllowed(moduleConstants.EL);
  const canAddProject = isAllowed(moduleConstants.EP);
  const { logout, isAuthenticated } = useAuth();

  return (
    <header>
      {!isAuthenticated() && logout(false)}
      <nav className='navbar  navbar-absolute px-0 navbar-transparent no-print'>
        <div className='container-fluid'>
          <div className='navbar-wrapper justify-content-start'>
            <a
              className='navbar-brand '
              style={{ justifyContent: 'center', alignItems: 'center' }}
              href='/home'
            >
              {localStorage.getItem('companyLogo') !== null ? (
                <img className='' style={{ maxHeight: '50px' }} src={comLogo} alt='Upload Logo' />
              ) : (
                <div className=''>
                  <Avatar
                    size='40'
                    colors={['#808B96', '#808B96', '#808B96']}
                    name={companyNameInfo}
                  />
                </div>
              )}
            </a>
          </div>
          {sessionToken && (
            <div className='navbar-nav nav-pills justify-content-end'>
              <ul className='clearfix nav'>
                <li className='nav-item menu nav-link px-0' aria-expanded='false'>
                  <button
                    className='btn impact-header-button '
                    data-bs-toggle='dropdown'
                    href='#'
                    role='button'
                    aria-expanded='false'
                  >
                    <div className='d-sm-none'>
                      <b>
                        <i className='fa fa-lg fa-plus' aria-hidden='true'></i>
                      </b>
                    </div>
                    <div className='d-none d-sm-block'>
                      <b>{t('Add New')}</b>
                    </div>
                  </button>

                  <ul className='dropdown-menu '>
                    {canAddContact && (
                      <li className='nav-item dropdown-item'>
                        <Link
                          className=''
                          to={{
                            pathname: `/view-contacts`,
                            state: {
                              openPopUp: 'CONTACT',
                              rand: Math.random(),
                            },
                          }}
                        >
                          {t('Add Contact')}
                        </Link>
                      </li>
                    )}

                    {canAddContact && (
                      <li className='nav-item dropdown-item'>
                        <Link
                          className=''
                          to={{
                            pathname: `/view-companies`,
                            state: {
                              openPopUp: 'COMPANY',
                              rand: Math.random(),
                            },
                          }}
                        >
                          {t('Add Company')}
                        </Link>
                      </li>
                    )}
                    {canAddEmployee && (
                      <li className='nav-item dropdown-item'>
                        <Link
                          className=''
                          to={{
                            pathname: `/view-employees`,
                            state: {
                              openAddContactModal: true,
                              isEmployee: true,
                              rand: Math.random(),
                            },
                          }}
                        >
                          {t('Add Employee')}
                        </Link>
                      </li>
                    )}
                    <div className='dropdown-divider' />
                    {canAddLead && (
                      <li className='nav-item dropdown-item'>
                        <Link
                          className=''
                          to={{
                            pathname: `/projects-list/leads`,
                            state: {
                              key: 0,
                              openPopUp: 'LEAD',
                              rand: Math.random(),
                            },
                          }}
                        >
                          {t('Add Lead')}
                        </Link>
                      </li>
                    )}
                    {canAddProject && (
                      <li className='nav-item  dropdown-item'>
                        <Link
                          className=''
                          to={{
                            pathname: `/projects-list`,
                            state: {
                              key: 1,
                              openPopUp: 'PROJECT',
                              rand: Math.random(),
                            },
                          }}
                        >
                          {t('Add Project')}
                        </Link>
                      </li>
                    )}
                  </ul>
                </li>
                <li className='nav-item menu px-0 ' aria-expanded='false'>
                  <a
                    className='nav-link '
                    data-bs-toggle='dropdown'
                    href='#'
                    role='button'
                    aria-expanded='false'
                  >
                    <button className='btn  header-button '>{t('Contacts')}</button>
                  </a>
                  <ul className='dropdown-menu '>
                    <li className='nav-item dropdown-item'>
                      <Link
                        className=''
                        to={{
                          pathname: `/view-contacts`,
                          state: { ...props.rowData },
                        }}
                      >
                        {t('People')}
                      </Link>
                    </li>
                    <li className='nav-item dropdown-item'>
                      <Link
                        className=''
                        to={{
                          pathname: `/view-companies`,
                          state: { ...props.rowData },
                        }}
                      >
                        {t('Companies')}
                      </Link>
                    </li>
                    <li className='nav-item  dropdown-item'>
                      <Link
                        className=''
                        to={{
                          pathname: `/view-employees`,
                          state: { ...props.rowData },
                        }}
                      >
                        {t('Employees')}
                      </Link>
                    </li>
                  </ul>
                </li>

                {isAllowed(reportAccess.PROJECTLIST, 'anyAssigned') && (

                <li className='nav-item nav-link menu px-0'>
                  <Link
                    className='btn  header-button'
                    to={{
                      pathname: `/projects-list/projects`,
                      state: { ...props.rowData },
                    }}
                  >
                    {t('Projects')}
                  </Link>
                </li>

                  )}

                {isAllowed(userInteraction.CCC, 'anyAssigned') && (
                  <li className='nav-item menu px-0  nav-link'>
                    <Link
                      className='btn  header-button'
                      to={{
                        pathname: `/calendar`,
                        state: { ...props.rowData },
                      }}
                    >
                      {t('Calendar')}
                    </Link>
                  </li>
                )}
                <li className='nav-item menu btn-rotate px-0 '>
                  <NotificationBell />
                </li>

                <li className='nav-item dropdown  px-0'>
                  <a
                    className='nav-link '
                    data-bs-toggle='dropdown'
                    role='button'
                    aria-expanded='false'
                  >
                    <div className='d-sm-none'>
                      <button className='btn  header-button header-button-icon '>
                        <b>
                          <i className='fa-solid fa-lg fa-user-alt'></i>
                        </b>
                      </button>
                    </div>
                    <div className='d-none d-sm-block'>
                      {userInfo?.userImage ? (
                        <Avatar
                          round={true}
                          size='45'
                          alt={`${userInfo.firstName} ${userInfo.lastName}`}
                          src={userInfo.userImage}
                        >
                          {`${userInfo.firstName.charAt(0)}${userInfo.lastName.charAt(0)}`}
                        </Avatar>
                      ) : (
                        <>
                          {' '}
                          <button className='btn  header-button '>
                            {userInfo.firstName}&nbsp; {userInfo.lastName}
                            &nbsp;
                          </button>
                        </>
                      )}
                    </div>
                  </a>
                  <ul className='dropdown-menu dropdown-menu-end ml-auto'>
                    <li className='nav-item dropdown-item'>
                      <Link
                        className='nav-item '
                        to={{
                          pathname: `/contact-details/` + userInfo.contactId,
                          state: { ...props.rowData },
                        }}
                      >
                        <i className='fas fa-user-alt'></i> &nbsp;My Profile
                      </Link>
                    </li>
                    
                      <li className='nav-item dropdown-item'>
                        <Link
                          className=''
                          to={{
                            pathname: `/preferences`,
                            state: { ...props.rowData },
                          }}
                        >
                          <i className='fas fa-cog'></i> &nbsp;Preferences
                        </Link>
                      </li>
                    {/*
                        <li className='nav-item dropdown-item'>
                          <Link
                            className=''
                            to={{
                              pathname: `/help-view`,
                              state: { ...props.rowData },
                            }}
                          >
                            <i className='fas fa-question-circle'></i> &nbsp;Help
                          </Link>
                          </li>*/}
                    {isAllowed(reportAccess.ACCOUNTSETTINGS) && (
                      <>
                        <li>
                          <hr className='dropdown-divider' />
                        </li>
                        <li className='nav-item dropdown-item'>
                          <Link
                            className=''
                            to={{
                              pathname: '/settings/company-info',
                              state: { ...props.rowData },
                            }}
                          >
                            Settings
                          </Link>
                        </li>
                      </>
                    )}
                    <li>
                      <hr className='dropdown-divider' />
                    </li>
                    <li className='nav-item dropdown-item '>
                      <a id='qsLogoutBtn ' onClick={logout.bind(this)}>
                        <i className='fas fa-door-open' />
                        &nbsp;{t('Log Out')}
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          )}
        </div>
      </nav>

      <MainMenu props={props} />
    </header>
  );
}

export default Header;
