import { v4 as uuidv4 } from 'uuid';
import { useCallback } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { appConstants } from '../../../../_constants';
import styles from '../field-actions.module.scss';

type FieldActionsProps = {
  name: string;
  type: string;
};

const Add = ({ name = '', type = '' }: FieldActionsProps) => {
  const { control, trigger } = useFormContext<any>();
  const { append } = useFieldArray({ control, name });

  const onItemPush = useCallback(async (e?: any) => {
    e && e.preventDefault();
    const ok = await trigger(name);

    ok && append({ ...appConstants.defaultFieldValues[type], _id: uuidv4() });
  }, []);

  return (
    <div data-testid='Add' role='button' className={styles.addButton} onClick={onItemPush}>
      + Add {type}
    </div>
  );
};

export default Add;
