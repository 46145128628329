import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import moment from 'moment';
import React from 'react';
import ReactDOM from 'react-dom';

moment.updateLocale(moment.locale(), { invalidDate: '<Unknown>' });

import './i18n';
import './index.css';
import { makeMainRoutes } from './routes/routes';
import { makeMainRoutesAdmin } from './routes/routesAdmin';

// fix for ResizeObserver loop limit exceeded
window.addEventListener('error', (e) => {
  if (
    e.message === 'ResizeObserver loop limit exceeded' ||
    e.message === 'ResizeObserver loop completed with undelivered notifications.'
  ) {
    const resizeObserverErrDiv = document.getElementById('webpack-dev-server-client-overlay-div');
    const resizeObserverErr = document.getElementById('webpack-dev-server-client-overlay');
    if (resizeObserverErr) {
      resizeObserverErr.setAttribute('style', 'display: none');
    }
    if (resizeObserverErrDiv) {
      resizeObserverErrDiv.setAttribute('style', 'display: none');
    }
  }
});

ReactDOM.render(
  <React.StrictMode>
    {window.location.pathname.includes('/admin/') ? makeMainRoutesAdmin() : makeMainRoutes()}
  </React.StrictMode>,
  document.getElementById('root')
);
