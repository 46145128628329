import * as actionTypes from '../../actions/Admin/actionTypes';
const initState = {
  response: [],
  message: '',
  success: '',

  widgetList: [],
  isWidgetListSuccess: false,

  isWidgetAdd: false,
  isWidgetAddSuccess: false,
  isWidgetAddError: false,

  isWidgetDisable: false,
  isWidgetDisableSuccess: false,
  isWidgetDisableError: false,
};

export default (state = initState, action = {}) => {
  switch (action.type) {
    //Add/edit widgets
    case actionTypes.CREATE_WIDGETS_REQUEST:
      return {
        ...state,
        isWidgetAdd: false,
        isWidgetAddSuccess: false,
        isWidgetAddError: false,
        message: '',
        success: '',
      };
    case actionTypes.CREATE_WIDGETS_SUCCESS:
      return {
        ...state,
        isWidgetAdd: action.payload.response.result,
        isWidgetAddSuccess: true,
        isWidgetAddError: false,
        success: action.payload.response.result.success,
        message: action.payload.response.result.msg
          ? action.payload.response.result.msg
          : 'Record Added successfully',
      };
    case actionTypes.CREATE_WIDGETS_ERROR:
      return {
        ...state,
        isWidgetAdd: false,
        isWidgetAddSuccess: false,
        isWidgetAddError: true,
        message: '',
        success: '',
      };

    //get all widget list
    case actionTypes.GET_ALL_WIDGETS_REQUEST:
      return {
        ...state,
        widgetList: [],
        isWidgetListSuccess: false,
        message: '',
        success: '',
      };
    case actionTypes.GET_ALL_WIDGETS_SUCCESS:
      return {
        ...state,
        widgetList: action.payload.response.result.data,
        isWidgetListSuccess: true,
        message: action.payload.response.result.success
          ? 'Record Added successfully'
          : action.payload.response.result.msg,
        success: action.payload.response.result.success,
      };
    case actionTypes.GET_ALL_WIDGETS_ERROR:
      return {
        ...state,
        widgetList: [],
        isWidgetListSuccess: false,
        success: action.payload.error.success,
        message: action.payload.error.msg,
      };

    //Disable widget list
    case actionTypes.DISABLE_WIDGETS_REQUEST:
      return {
        ...state,
        message: '',
        success: '',
        isWidgetDisable: false,
        isWidgetDisableSuccess: false,
        isWidgetDisableError: false,
      };
    case actionTypes.DISABLE_WIDGETS_SUCCESS:
      return {
        ...state,
        isWidgetDisable: action.payload.response.result,
        isWidgetDisableSuccess: true,
        isWidgetDisableError: false,
        message: action.payload.response.result.success
          ? 'Record Disabled successfully'
          : action.payload.response.result.msg,
        success: action.payload.response.result.success,
      };
    case actionTypes.DISABLE_WIDGETS_ERROR:
      return {
        ...state,
        isWidgetDisable: false,
        isWidgetDisableSuccess: false,
        isWidgetDisableError: true,
        success: action.payload.error.success,
        message: action.payload.error.msg,
      };

    default:
      return state;
  }
};
