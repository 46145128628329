import styled from '@emotion/styled';
import { ClickAwayListener } from '@material-ui/core';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FixedSizeList as List, } from 'react-window';
import './autocomplete-input.css';

const SearchDropdownWrapper = styled.div(() => ({
  position: 'relative',

  '& .search-menu': {
    position: 'absolute',
    zIndex: 200,
    backgroundColor: '#fff',
    width: '100%',
    boxShadow:
      '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
  },

  '& .search-prediction': {
    '&:hover': {
      background: '#00000022',
      cursor: 'pointer',
    },
  },
} as any));

type SoloAutocompleteInputProps = {
  options?: Array<any>;
  selected?: any;
  onSelect: (value: any) => unknown;
  getLabel?: (option: any) => string;
  getValue?: (option: any) => string;
  title: string;
  disabledValues?: Array<any>;
  getNotFoundNode?: (input: string) => React.ReactNode;
  getExtraOption?: (input: string) => any;
};

const SoloAutocompleteInput = ({
  options = [],
  selected,
  onSelect,
  getLabel = (option) => option.label,
  getValue = (option) => option.value,
  title,
  disabledValues = [],
  getNotFoundNode = () => 'Company not found',
  getExtraOption,
}: SoloAutocompleteInputProps) => {
  const [inputValue, setInputValue] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [open, setOpen] = useState(false);
  const [shouldPlaceTop, setShouldPlaceTop] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);
  const menuHeight = 200;

  const onMenuOpen = () => {
    if (inputRef?.current?.closest('.container')) {
      try {
        const spaceBelow =
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          inputRef.current.closest('.container')!.getBoundingClientRect().bottom -
          inputRef.current.getBoundingClientRect().bottom;

        setShouldPlaceTop(spaceBelow < menuHeight);
      } catch (e) {
        // We want application to not crash, but don't care about the message
      }
    }
  };

  useEffect(() => {
    if (open) {
      onMenuOpen();
      inputRef.current?.focus();
      setInputValue('');
    } else if (selected) setInputValue(getLabel(selected));
  }, [open]);

  useEffect(() => {
    setSearchValue(inputValue);
  }, [inputValue]);

  const onSearch = useCallback((e) => {
    setInputValue(e.target.value);
  }, []);

  const displayData = useMemo(() => {
    const result = options?.filter((option) =>
      getLabel(option)?.toLowerCase().includes(searchValue?.toLowerCase())
    );

    if (searchValue)
      result.push({
        __extra__: true,
      });

    return result;
  }, [searchValue, options]);

  const onSelectClick = (selectedValue, event) => {
    event.stopPropagation();
    onSelect(selectedValue);
    setOpen(false);
  };

  useEffect(() => {
    if (selected) setInputValue(getLabel(selected));
  }, [selected]);

  const getDisabledValue = useCallback(
    (target) => {
      const found = disabledValues.find((current) => getValue(current) === getValue(target));

      return !!found;
    },
    [disabledValues]
  );

  const styleMenu = shouldPlaceTop ? {
    transform: 'translateY(-130%)'
  } : {};

  return (
    <div className='form-group row'>
      <div className='col-md-3 col-form-label font-weight-bold'>{title}</div>
      <ClickAwayListener onClickAway={() => setOpen(false)}>
        <div className='col-md-8'>
          <div
            className='d-flex align-items-center gap-3'
            onClick={() => setOpen((prev) => !prev)}
          >
            <div className='w-100 form-control' style={{ position: 'relative' }}>
              <div className='autocomplete--company-box'>
                <input
                  data-testid='solo-autocomplete-input'
                  ref={inputRef}
                  placeholder='Search ...'
                  type='text'
                  style={{ border: 0, outline: 'none !important', width: '100%' }}
                  onChange={onSearch}
                  value={inputValue}
                />
              </div>
            </div>
          </div>

          {open && (
            <SearchDropdownWrapper>
              <div className='search-menu' style={styleMenu}>
                {!displayData.length && (
                  <div className='py-3 px-3'>{getNotFoundNode(inputValue)}</div>
                )}
                <List
                  height={menuHeight}
                  itemData={displayData}
                  itemCount={displayData.length}
                  itemSize={40}
                  width={'100%'}
                >
                  {({ data, index, style }) => {
                    const row = data[index];

                    const isDisabled = getDisabledValue(row);
                    const isExtra = row?.__extra__;

                    if (isExtra) {
                      const extraOption = getExtraOption?.call(0, inputValue);

                      return (
                        <div
                          className={`search-prediction d-flex align-items-center px-2 gap-2`}
                          key={'__extra__'}
                          style={{ ...style }}
                          onClick={extraOption?.onClick}
                        >
                          {extraOption.label}
                        </div>
                      );
                    }

                    return (
                      <div
                        className={`search-prediction d-flex align-items-center px-2 gap-2 ${
                          isDisabled && 'text-muted'
                        }`}
                        key={getValue(row)}
                        style={{ ...style }}
                        onClick={(event) => !isDisabled && onSelectClick(row, event)}
                      >
                        {getLabel(row)}
                      </div>
                    );
                  }}
                </List>
              </div>
            </SearchDropdownWrapper>
          )}
        </div>
      </ClickAwayListener>
    </div>
  );
};

export default SoloAutocompleteInput;
