import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAdminSettings, getUserSettings } from '../../modules/settings';
import { selectGetAdminSettings, selectGetUserSettings } from '../../modules/settings/selectors';

const useSettingsData = ({ modelName, isSuperAdmin = false }) => {
  const dispatch = useDispatch();

  const getSettings = useCallback(
    () => dispatch(isSuperAdmin ? getAdminSettings([modelName]) : getUserSettings([modelName])),
    [isSuperAdmin, modelName]
  );

  useEffect(() => {
    if (!modelName) return;
    getSettings();
  }, [modelName, isSuperAdmin]);

  const { loading: adminLoading, data: adminRows } = useSelector(selectGetAdminSettings);
  const { loading: userLoading, data: userRows } = useSelector(selectGetUserSettings);

  const rows = useMemo(
    () => (isSuperAdmin ? adminRows : userRows),
    [isSuperAdmin, adminRows, userRows]
  );
  const loading = useMemo(
    () => (isSuperAdmin ? adminLoading : userLoading),
    [isSuperAdmin, adminLoading, userLoading]
  );

  return { rows, loading, getSettings };
};

export default useSettingsData;
