import * as actionTypes from './../Admin/actionTypes.js';
import * as API from '../../api/Admin/login';
import { useAuth } from '../../components/Auth/Auth';

export const loginRequest = () => ({
  type: actionTypes.ADMIN_LOGIN_REQUEST,
});

export const loginSuccess = (response) => ({
  type: actionTypes.ADMIN_LOGIN_SUCCESS,
  payload: {
    response,
  },
});

export const loginError = (error) => ({
  type: actionTypes.ADMIN_LOGIN_ERROR,
  payload: {
    error,
  },
});

export const loginAction = (data) => {
  return (dispatch) => {
    dispatch(loginRequest());
    return API.signInAdmin(data)
      .then((response) => {
        if (response && response.data) {
          if (response.data.result.success === 1) {
            localStorage.setItem('sessionToken', response.data.result.data.sessionToken);
            localStorage.setItem('userId', response.data.result.data.objectId);
            localStorage.setItem('accountId', response.data.result.data.accountId.objectId);
            localStorage.setItem('baseUserRole', response.data.result.data.loginType);
            localStorage.setItem('JWTTOKEN', response.data.result.token);
            localStorage.setItem(
              'modulePermissions',
              JSON.stringify(response.data.result.roleMatrix.modulePermissions)
            );
            localStorage.setItem('accountInfo', JSON.stringify(response.data.result.accountData));
            localStorage.setItem('userInfo', JSON.stringify(response.data.result.data));
            dispatch(loginSuccess(response.data));
          } else {
            dispatch(loginError(response.data));
          }
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data.error);
          //console.log(this.props);
          const { logout } = useAuth();
          logout();
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('Error', error.message);
        }
      });
  };
};

//forgot password
export const forgotPasswordRequest = () => ({
  type: actionTypes.ADMIN_FORGOT_LOGIN_REQUEST,
});

export const forgotPasswordSuccess = (response) => ({
  type: actionTypes.ADMIN_FORGOT_LOGIN_SUCCESS,
  payload: {
    response,
  },
});

export const forgotPasswordError = (error) => ({
  type: actionTypes.ADMIN_FORGOT_LOGIN_ERROR,
  payload: {
    error,
  },
});

export const forgotPasswordAction = (data) => {
  return (dispatch) => {
    console.log('data>>>>', data);
    dispatch(forgotPasswordRequest());
    return API.forgotPassword(data)
      .then((response) => {
        dispatch(forgotPasswordSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          forgotPasswordError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

// export  const forgotPasswordAction = (data) => {
//     return dispatch => {
//         dispatch(forgotPasswordRequest())
//         return API.forgotPassword(data)
//             .then(response => {
//                 if(response && response.data ){
//                     if(response.data.result.success===1){
//                         dispatch(forgotPasswordSuccess(response.data.result))
//                     }else{
//                         dispatch(forgotPasswordError(response.data.result));
//                     }
//                   }
//             })
//             .catch(error => {
//                 const { logout } = useAuth();
          //logout();
//             })
//     }
//
// }

//set new Password
export const setNewPasswordRequest = () => ({
  type: actionTypes.ADMIN_SET_NEW_PASSWORD_REQUEST,
});

export const setNewPasswordSuccess = (response) => ({
  type: actionTypes.ADMIN_SET_NEW_PASSWORD_SUCCESS,
  payload: {
    response,
  },
});

export const setNewPasswordError = (error) => ({
  type: actionTypes.ADMIN_SET_NEW_PASSWORD_ERROR,
  payload: {
    error,
  },
});

export const setNewPasswordAction = (data) => {
  return (dispatch) => {
    dispatch(setNewPasswordRequest());
    return API.setNewPassword(data)
      .then((response) => {
        dispatch(setNewPasswordSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          setNewPasswordError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};
