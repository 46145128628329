import { useCallback, useEffect, useState } from 'react';
import { SettingsCodesLayoutNext } from '../../../layouts/settings-codes-layout/settings-codes-layout-next';
import { usePageTitle } from '../../../layouts/title-context';
import { disableUserSetting, enableUserSetting, getUserSettings } from '../../../modules/settings';
import { defaultSettingsColumns } from '../../../shared/settings/default-settings-columns';
import { useAppDispatch } from '../../../shared/hooks/use-app-dispatch';
import { useConfirm } from '../../../shared/confirmation-dialog';
import { EditSettingModal } from './edit-setting-modal';
import { useSubmit } from '../../../shared/hooks/use-submit';

export const ContactRolesPage = () => {
  usePageTitle('Contact Roles Settings');

  const dispatch = useAppDispatch();
  const confirm = useConfirm();
  const [open, setOpen] = useState(false);
  const [selectedSettingId, setSelectedSettingId] = useState<string | null>(null);
  const [data, setData] = useState<any[]>([]);

  const [refreshTable, refreshing] = useSubmit(
    () =>
      dispatch(getUserSettings(['contactRoles']))
        .unwrap()
        .then((data) => {
          setData(data);
        }),
    []
  );

  useEffect(() => {
    refreshTable();
  }, []);

  const onToggle = useCallback(async (trash: boolean, rowValue: any) => {
    if (trash) {
      const ok = await confirm({
        title: 'Disable Contact Role',
        question: 'Are you sure you want to disable this record?',
      });

      if (!ok) return;

      await dispatch(disableUserSetting(['contactRoles', rowValue._id])).unwrap();
    } else {
      await dispatch(enableUserSetting(['contactRoles', rowValue._id])).unwrap();
    }

    refreshTable();
  }, []);

  const onRowClick = (row: any) => {
    setSelectedSettingId(row._id);
    setOpen(true);
  };

  const onAddNewItemClick = () => {
    setSelectedSettingId(null);
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const onSubmit = () => {
    refreshTable();
  };

  return (
    <>
      <SettingsCodesLayoutNext
        tableTitle='Contact Roles'
        columns={defaultSettingsColumns}
        rows={data}
        loading={refreshing}
        onToggle={onToggle}
        onRowClick={onRowClick}
        onAddNewItemClick={onAddNewItemClick}
      />

      <EditSettingModal
        open={open}
        onClose={onClose}
        settingId={selectedSettingId}
        onSubmit={onSubmit}
      />
    </>
  );
};
