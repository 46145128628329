import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { NoteView } from '../Layout/RightSectionRender'
import NoteEditor from '../Dialogs/note-editor-modal';
import { LightBoxComponent } from '../Layout/LightBox.component';


const NotePopUp = ({ noteInfo, isOpen, onClick, modalClassName, modalTitle }) => {
  const [noteData, setNoteData] = useState(noteInfo);
  const [modal, setModal] = useState(false);

  const [lightBoxOpen, setLightBoxOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [attachmentIndex, setAttachmentIndex] = useState(-1);
  const [attachmentItems, setAttachmentItems] = useState([]);
  const [title, setTitle] = useState(modalTitle);

  useEffect(() => {
    setNoteData(noteInfo);
  }, [noteInfo]);


  const formatNoteAttachments = (selectedNote) => {
    return selectedNote?.attachments?.map((attachment) => ({
      ...attachment,
      description:
        selectedNote.title + '\n' + selectedNote.description?.replace(/(<([^>]+)>)/gi, ''),
    }));
  };

  useEffect(() => {
    if (!selectedItem) {
      return;
    }

    setLightBoxOpen(!lightBoxOpen);
    setAttachmentIndex(attachmentIndex);

    setAttachmentItems(formatNoteAttachments(selectedItem));

  }, [selectedItem]);


  useEffect(() => {
    if (!modal && isOpen){
      toggle();
    }
  }, [isOpen]);

  const toggle = (val) => {
    if (modal) onClick();
    setModal(!modal);
  };

  const showHideLightBox = (item, index) => {
    setSelectedItem(item);
    setAttachmentIndex(index);
  };

  return (
    noteData.typeRef && ( noteData.objectId ? (
    <>
      <Modal
        backdrop="static"
        keyboard={true}
        isOpen={modal}
        toggle={toggle}
        className={modalClassName}
        size={'lg'}
      >
        <ModalHeader toggle={toggle}>{title??'Note Details'}</ModalHeader>
        <ModalBody>
          {noteInfo?.objectId ?(
          <div className="noteContainer container">
            <NoteView noteId={noteData.objectId} typeId={noteData.typeId} typeRef={noteData.typeRef} showHideLightBox={showHideLightBox}></NoteView>
          </div>):noteInfo?.length>0 && noteInfo}
         
   
        </ModalBody>
        <ModalFooter>
          <button className="btn btn-primary" onClick={toggle}>
            Close
          </button>
        </ModalFooter>
      </Modal>
      <LightBoxComponent
        attachments={attachmentItems ?? []}
        shouldOpen={lightBoxOpen}
        initialIndex={attachmentIndex}
      />
    </>
    ): <NoteEditor showEditNote={modal} closeWindowCallback={toggle} 
    asModal={true} typeId={noteData.typeId} typeRef={noteData.typeRef} />
  ));
};

export default NotePopUp;
