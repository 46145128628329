import { useCallback, useEffect, useMemo, useState } from 'react';
import { SettingsCodesLayoutNext } from '../../../layouts/settings-codes-layout/settings-codes-layout-next';
import { usePageTitle } from '../../../layouts/title-context';
import { disableUserSetting, enableUserSetting, getUserSettings, syncLineItems } from '../../../modules/settings';
import { useAppDispatch } from '../../../shared/hooks/use-app-dispatch';
import { useConfirm } from '../../../shared/confirmation-dialog';
import { EditSettingModal } from './edit-setting-modal';
import { useSubmit } from '../../../shared/hooks/use-submit';

export const LineItemsPage = () => {
  usePageTitle('Line Item Settings');
  const modelName = 'lineItemTypes';

  const dispatch = useAppDispatch();
  const confirm = useConfirm();
  const [open, setOpen] = useState(false);
  const [selectedSettingId, setSelectedSettingId] = useState<string | null>(null);
  const [data, setData] = useState<any[]>([]);
  const [jobScopes, setJobScopes] = useState<any[]>([]);

  const fetchJobScopes = useCallback(async () => {
    try {
      const response = await dispatch(getUserSettings(['jobScopes'])).unwrap();
      setJobScopes(response);
    } catch (error) {
      console.error('Failed to fetch job scopes:', error);
    }
  }, [dispatch]);

  const [refreshTable, refreshing] = useSubmit(
    () =>
      dispatch(getUserSettings([modelName]))
        .unwrap()
        .then((data) => {
          setData(data);
        }),
    []
  );

  useEffect(() => {
    refreshTable();
    fetchJobScopes();
  }, []);


  const getItemTypeLabel = (type) => {
    switch (type) {
      case 'Inventory':
        return 'Inventory';
      case 'Service':
        return 'Service';
      case 'Non-Inventory':
          return 'Non-Inventory';
      case 'Group':
        return 'Bundle';
      default:
        return type;
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Type',
        accessor: 'type',
        width: 100,
        Cell: ({ value }) => getItemTypeLabel(value),
      },
      {
        Header: 'Name',
        accessor: 'name',
        width: 250,
        searchable: true,
      },
      {
        Header: 'Description',
        accessor: 'definition',
        width: 350,
        searchable: true,
      },
    ],
    [jobScopes]
  );

  const onToggle = useCallback(async (trash: boolean, rowValue: any) => {
    if (trash) {
      const ok = await confirm({
        title: 'Disable Line Item',
        question: 'Are you sure you want to disable this record?',
      });

      if (!ok) return;

      await dispatch(disableUserSetting([modelName, rowValue._id])).unwrap();
    } else {
      await dispatch(enableUserSetting([modelName, rowValue._id])).unwrap();
    }

    refreshTable();
  }, []);

  const onRowClick = (row: any) => {
    setSelectedSettingId(row._id);
    setOpen(true);
  };

  const onAddNewItemClick = () => {
    setSelectedSettingId(null);
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const onSubmit = () => {
    refreshTable();
  };

  const syncButton = {
    label: 'Sync QB Items',
    onClick: () => {
       dispatch(syncLineItems()).then(() => {
         refreshTable();
       });
    },
  };

  return (
    <>
      <SettingsCodesLayoutNext
        tableTitle='Line Items'
        columns={columns}
        rows={data}
        loading={refreshing}
        onToggle={onToggle}
        onRowClick={onRowClick}
        onAddNewItemClick={onAddNewItemClick}
        additionalButtons={[syncButton]}
      />

      <EditSettingModal
        open={open}
        onClose={onClose}
        settingId={selectedSettingId}
        onSubmit={onSubmit}
        jobScopes={jobScopes}
      />
    </>
  );
};
