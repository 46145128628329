import _ from 'lodash';
import { useState, useEffect, useRef } from 'react';
import { useAppDispatch } from '../../../shared/hooks/use-app-dispatch';
import { findContactAffiliations } from '../../../modules/contacts';
import { formatPhoneForCall } from '../../../utils/formatters';
import { useFormContext } from 'react-hook-form';
import { useDebounce } from '../../../shared/hooks/use-debounce';
import { HorizontalLayout } from '../../../shared/form-controls/control-layouts/horizontal-layout';
import { formatAffiliation } from '../utils/format-affiliation';
import FormMultiSelect from '../../../shared/form-controls/form-multiselect/form-multiselect';
import AddContactModal from '../../add-contact-modal/add-contact-modal';
import { isAllowed, moduleConstants } from '../../../_constants';
import { notification } from 'antd';

type FormSelectPagingProps = {
  role: any;
  name: string;
  count: number;
  isAccount: boolean;
  initialOptions: Array<any>;
  disabled?: boolean;
  formatAsTextWhenLocked?: boolean;
  creatable?: boolean;
  required?: boolean;
  useObjectValue?: boolean;
  requiredFields?: Array<any>;
  Layout?: any;
};

type stateRefType = {
  isEndOfSearch: boolean;
  searchCriteria: string;
  page: number;
  options: Array<any>;
  initialOptions: Array<any>;
  initialAndSelectedOptions: Array<any>;
  field: any;
};

export const FormSelectPaging = ({
  role = {},
  name = '',
  count = 20,
  isAccount = true,
  initialOptions = [],
  disabled = false,
  formatAsTextWhenLocked = false,
  creatable = true,
  required = false,
  useObjectValue = false,
  requiredFields = [],
  Layout = HorizontalLayout,
}: FormSelectPagingProps) => {
  const isAllowedAddContacts = isAllowed(moduleConstants.EC);
  const isAllowedAddEmployees = isAllowed(moduleConstants.EE);

  const form = useFormContext();
  const dispatch = useAppDispatch();

  const fieldWatch = form.watch(name);

  const [isContactModalOpen, setIsContactModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [contactName, setContactName] = useState({ firstName: '', lastName: '' });
  const [companyId, setCompanyId] = useState('');

  const [searchCriteria, setSearchCriteria] = useState<string>('');
  const [options, setOptions] = useState<Array<any>>([]);
  const stateRef = useRef<stateRefType>({ isEndOfSearch: false, searchCriteria: '', page: 1, options, initialOptions, initialAndSelectedOptions: [], field: null });

  // if this field value has been changed
  // but there are value(s) that does not have corresponding option(s)
  // get it (them) from server and update options
  useEffect(() => {
    // if no initial options yet - skip
    if (options.length === 0 || useObjectValue) return;

    const currentOptionsIds = options.map((op) => op._id);
    const missingOptionsIds = _.difference(fieldWatch, currentOptionsIds);

    if (missingOptionsIds.length > 0) {
      (async () => {
        const missingOptions = await dispatch(
          findContactAffiliations({
            criteria: `${missingOptionsIds.join(' ')}`,
            page: 1,
            count: missingOptionsIds.length,
            isAccount,
            includeDeleted: true,
            fields: ['_id'],
            requiredFields,
          })
        ).unwrap();

        // if there are options that were set but were not found on the server - remove them
        if (missingOptions.length < missingOptionsIds.length) {
          const notFoundOptions = missingOptions.map((op) => op._id);
          const notFoundOptionsIds = _.difference(missingOptionsIds, notFoundOptions);

          const newFieldValue = fieldWatch.filter((id) => !notFoundOptionsIds.includes(id));
          form.setValue(name, newFieldValue);
        }

        onAfterSelection([...missingOptions]);
      })();
    }
  }, [fieldWatch, options]);

  useEffect(() => {
    if (initialOptions.length > 0) {
      stateRef.current.initialAndSelectedOptions = [...initialOptions];
      stateRef.current.initialOptions = [...initialOptions];
      updateOptions([...initialOptions]);
    }
  }, [initialOptions.length]);

  useDebounce(async () => {
    if (searchCriteria !== '') {
      let newOptions = await dispatch(
        findContactAffiliations({
          criteria: searchCriteria,
          page: 1,
          count,
          isAccount,
          includeDeleted: false,
          requiredFields,
        })
      ).unwrap();

      newOptions = _.uniqBy([...newOptions, ...stateRef.current.initialAndSelectedOptions], '_id');
      updateOptions([...newOptions]);
    }
  }, [searchCriteria]);

  const updateOptions = (newOptions: Array<any>) => {
    stateRef.current.options = newOptions;
    setOptions(newOptions); // causes re-render
    setIsLoading(false);
  };

  const onAfterSelection = (selections: Array<any>) => {
    const newOptions = _.uniqBy([...stateRef.current.initialOptions, ...selections], '_id');

    // to be able to set initalOptions+selection when user closes menu
    stateRef.current.initialAndSelectedOptions = [...newOptions];
    updateOptions([...newOptions]);
  };

  // executes when user is typing in search field. requests options. triggers onInputChange and onMenuClose
  const onInputChange = (criteria: string, action: any) => {
    stateRef.current.page = 1;
    stateRef.current.isEndOfSearch = false;
    stateRef.current.searchCriteria = criteria;

    if (action.action === 'input-change') {
      if (criteria === '') {
        updateOptions([...stateRef.current.initialAndSelectedOptions]);
      } else {
        setIsLoading(true);
      }
    } else {
      // for case when user was typing search criteria but closed menu without selecting any option
      updateOptions([...stateRef.current.initialAndSelectedOptions]);
    }

    setSearchCriteria(criteria);
  };

  // executes when user scrolls to the bottom of the options menu
  const onMenuScrollToBottom = async () => {
    const { isEndOfSearch, page, searchCriteria, options } = stateRef.current;

    if (isEndOfSearch) {
      return;
    }

    setIsLoading(true);
    const nextPage = page + 1;
    const newOptions = await dispatch(
      findContactAffiliations({
        criteria: searchCriteria,
        page: nextPage,
        count,
        isAccount,
        includeDeleted: false,
        requiredFields,
      })
    ).unwrap();

    stateRef.current.page = nextPage;
    if (newOptions.length > 0) {
      const uniqueOptions = _.uniqBy([...options, ...newOptions], '_id');
      updateOptions(uniqueOptions);
    } else {
      stateRef.current.isEndOfSearch = true;
      setIsLoading(false);
    }
  };

  // executes when user is typing in search field. filters options
  const filterOption = (candidate: any, criteria: string) => {
    criteria = criteria.replace(/"/g, '');

    if (criteria === '' || candidate.data.__isNew__) {
      return true;
    }

    criteria = criteria?.toLocaleLowerCase();
    const criteriaParts = criteria?.split(' ');

    const { contactFullName = '', companyName = '', primaryPhone = {}, primaryEmail = {} } = candidate.data;

    // do not search by company if it is accountAffiliation because each option has that value

    const result = criteriaParts.map((criteriaPart) => {
      const isCompanyNameFits = (isAccount ? false : companyName?.toLocaleLowerCase().includes(criteriaPart)) ?? false;
      const isFullNameFits = contactFullName?.toLocaleLowerCase().includes(criteriaPart) ?? false;
      const isPhoneNumberFits = formatPhoneForCall(primaryPhone)?.includes(criteriaPart) ?? false;
      const isEmailFits = primaryEmail.email?.toLocaleLowerCase().includes(criteriaPart) ?? false;

      return isFullNameFits || isCompanyNameFits || isPhoneNumberFits || isEmailFits;
    })

    return result.every(v => v === true)
  };

  const onCreateOption = (input: string, field: any) => {
    if (isAccount && !isAllowedAddEmployees) {
      notification.error({ message: 'Insufficient permissions for adding employees' });
      return;
    }

    if (!isAccount && !isAllowedAddContacts) {
      notification.error({ message: 'Insufficient permissions for adding contacts' });
      return;
    }

    const nameArray = input.trim().split(' ');
    const firstName = nameArray[0];
    nameArray.shift();
    const lastName = nameArray.join(' ');
    const currentCompanyId = initialOptions[0]?.companyId || '';
    const companyId = isAccount ? currentCompanyId : '';

    setCompanyId(companyId);
    setContactName({ firstName, lastName });
    setIsContactModalOpen(true);

    stateRef.current.field = field;
  };

  const onAfterAddContact = async (savedAffiliations) => {
    if (!savedAffiliations || savedAffiliations.length === 0) {
      return;
    }

    // add new options as selected
    let newAffiliation: any = null;
    if (isAccount) {
      newAffiliation = savedAffiliations.find((affiliation) => affiliation.isAccountAffiliation && affiliation.companyId === companyId);

      if (!newAffiliation) {
        notification.error({ message: 'Internal role cannot be set to non-employee contact' });
        return;
      }
    } else {
      const personalAff = savedAffiliations.find((aff) => aff.selfAffiliated);
      const accountAff = savedAffiliations.find((aff) => aff.isAccountAffiliation);
      const primaryAff = savedAffiliations.find((aff) => !aff.selfAffiliated && !aff.isAccountAffiliation);

      newAffiliation = { ...personalAff };

      if (accountAff && !primaryAff) {
        newAffiliation = { ...accountAff };
      }

      if (!accountAff && primaryAff) {
        newAffiliation = { ...primaryAff };
      }
    }

    if (newAffiliation && stateRef.current.field) {
      // search for created affiliation and set it as selected option
      const newAffiliations = await dispatch(
        findContactAffiliations({
          criteria: newAffiliation._id,
          page: 1,
          count: 1,
          isAccount,
          includeDeleted: false,
          fields: ['_id'],
          requiredFields,
        })
      ).unwrap();

      if (Array.isArray(newAffiliations) && newAffiliations[0]) {
        const newOption = newAffiliations[0];

        // to be able to set initalOptions+selection when user closes menu
        const newOptions = [...stateRef.current.options, newOption];
        stateRef.current.initialAndSelectedOptions = [...newOptions];
        updateOptions([...newOptions]);

        // calculate value depending on single- or multi- select control is
        let newValue = [newOption._id];
        if (role.isMultiAssignable && Array.isArray(stateRef.current.field.value)) {
          newValue = [...stateRef.current.field.value, ...newValue]
        }

        stateRef.current.field.onChange(newValue);
      }
    }
  };

  const formatCreateLabel = (label: string) => isAccount?`Add '${label}' as new employee`: `Add '${label}' as new contact`;
  const isOptionDisabled = (option: any) => !option.active && !option.__isNew__;

  return (
    <>
      <FormMultiSelect
        required={required}
        disabled={disabled}
        key={role._id}
        label={role.name}
        control={form.control}
        options={options}
        onMenuScrollToBottom={onMenuScrollToBottom}
        isSolo={!role.isMultiAssignable}
        name={name}
        creatable={creatable}
        Layout={Layout}
        getExtraValue={() => null}
        getOptionValue={(o) => o._id}
        getOptionLabel={formatAffiliation}
        isOptionDisabled={isOptionDisabled}
        onInputChange={onInputChange}
        filterOption={filterOption}
        onAfterSelection={onAfterSelection}
        onCreateOptionCustom={onCreateOption}
        useCustomCreateOption={true}
        formatCreateLabel={formatCreateLabel}
        isLoading={isLoading}
        useObjectValue={useObjectValue}
        formatAsTextWhenLocked={formatAsTextWhenLocked}
      />
      {isContactModalOpen && <AddContactModal
        onSubmit={onAfterAddContact}
        isAddToCompany={!isAccount}
        isQuickAdd={true}
        open={isContactModalOpen}
        preAffiliations={[companyId]}
        contact={{ defaultAccess: false, ...contactName }}
        onClose={() => setIsContactModalOpen(!isContactModalOpen)}
      />}
    </>
  );
};
