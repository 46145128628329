import { useState } from 'react';

const usePagination = ({ total }) => {
  const [limit, setLimit] = useState(localStorage.getItem('defaultPagination')?parseInt(localStorage.getItem('defaultPagination')):15);
  const [page, setPage] = useState(1);

  const onLimitChange = (e) => {setLimit(e.target.value); localStorage.setItem('defaultPagination',e.target.value);};

  const pagesCount = Math.ceil(total / limit);

  const onPageChange = (_, newPage) =>  setPage(newPage);

  const offset = (page - 1) * limit;

  return { limit, onLimitChange, pagesCount, onPageChange, page, offset, setPage, setLimit };
};

export default usePagination;
