import * as actionTypes from '../../actions/Admin/actionTypes.js';

const initState = {
  response: [],
  message: '',
  isfetched: false,
  isUpdateStatus: false,
  success: null,
};

export default (state = initState, action = {}) => {
  switch (action.type) {
    case actionTypes.GET_DASHBOARD_REQUEST:
      return {
        ...state,
        response: [],
        message: '',
        isfetched: false,
        isUpdateStatus: false,
      };
    case actionTypes.GET_DASHBOARD_SUCCESS:
      return {
        ...state,
        response: action.payload.response.result.data,
        success: action.payload.response.result.success,
        message: action.payload.response.result.success
          ? 'Record updated successfully'
          : action.payload.response.result.msg,
        isfetched: true,
        isUpdateStatus: true,
      };

    case actionTypes.GET_DASHBOARD_ERROR:
      return {
        ...state,
        isfetched: false,
        isUpdateStatus: false,
        success: action.payload.error.success,
        message: action.payload.error.msg,
      };

    default:
      return state;
  }
};
