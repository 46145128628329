import React, { useState, useEffect, Suspense, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import { appConstants } from '../../_constants';
import '../../index.css';
import { searchAction } from '../../actions/search';
import { useDispatch, useSelector } from 'react-redux';

import { matchDataForTable } from '../../utils/match-for-table';
import { Tooltip } from 'react-tooltip';
import {
  formatExternalContactData,
  formatInternalContactData,
} from '../../utils/contact-formatters';

import { getAllProjectRoleAction } from '../../actions/projectRole';
import { getAllJobStatusAction } from '../../actions/jobStatusCode';
import TitleComponent from '../Common/TitleComponent';
import { customSortByKey, CustomTable, multiSelectFilter } from '../Common/CustomTable';
import { DateColumnFilter } from '../Common/CustomTableComponents/DateColumnFilter';
import { SelectColumnFilter } from '../Common/CustomTableComponents/SelectColumnFilter';
import { formatDate } from '../../utils/date-formatters';
import { formatAddressText } from '../../utils/contact-formatters.jsx';

import { getAllProjectFieldsAction } from '../../actions/projectFields';
import { getAllContactFieldsAction } from '../../actions/contactFields';
import {
  buildColumnForField,
  buildDynamicColumns,
  onCurrency,
  onDate,
  onDateTime,
  onText,
  onURL,
  onPhone,
  onAddress,
  onEmail,
} from '../../utils/column-formatters';
import { sortAsc, recentSearch } from '../../components/Auth/Auth';

function SearchList({searchText, searchType}) {
  const dispatch = useDispatch();
  const searchData = useSelector((state) => state.search.searchData);
  const projectRoles = useSelector((state) => state.projectRoleType.allProjectRoleTypes);
  const projectFields = useSelector((state) => state.projectFields.allProjectFieldTypes);
  const contactFields = useSelector((state) => state.contactFields.allContactFieldTypes);
  const [projectArrLocal,setProjectArrLocal] = useState([]);
  const allProjectStatus = useSelector((state) => state.jobStatusCode.allJobStatus);

  const [accountId, setAccountId] = useState(localStorage.getItem('accountId'));
  const [searchList, setSearchList] = useState([]);
  const [sizePerPage, setSizePerPage] = useState(appConstants.TableSizePerPageList[2].value);
  const [currentSearchText, setCurrentSearchText] = useState(searchText ?? '');
  const [currentSearchType, setCurrentSearchType] = useState(searchType ?? 'PROJECT');
  const [projectContactsData, setProjectContactsData] = useState([]);
  const [contactArr, setContactArr] = useState([]);
  const [companyArr, setCompanyArr] = useState([]);
  const [projectRoleTypes, setProjectRoleTypes] = useState([]);
  const [dynamicColumns, setDynamicColumns] = useState([]);
  const [allProjectFieldTypes, setAllProjectFieldTypes] = useState([]);
  const [allContactFieldTypes, setAllContactFieldTypes] = useState([]);
  const [fetchRequest, setFetchRequest] = useState(true);

  const componentRef = useRef(null);

  


  useEffect(() => {
    setFetchRequest(true);    if (searchText?.length > 0) {
      setCurrentSearchText(searchText);
      setCurrentSearchType(searchType);
      recentSearch(searchText);
      getSearchResult(searchType, searchText);
    }

    dispatch(getAllProjectRoleAction({ className: 'projectRoleTypes', accountId }));
    dispatch(getAllJobStatusAction({ className: 'jobStatusCodes', accountId }));
    dispatch(getAllProjectFieldsAction({ accountId, minimumStage: 'post-construction' }));
    dispatch(getAllContactFieldsAction({ accountId }));
  }, [searchText, searchType, dispatch]);

  useEffect(() => {
    if(searchData?.query === searchText){
      setProjectRoleTypes(projectRoles);
      setAllProjectFieldTypes(projectFields);
      setAllContactFieldTypes(contactFields);

      let fieldsToMatch = {
        projectAssignments: { lookupLocation: 'contactData' },
      };

      let matchedDataForTable = matchDataForTable(
        fieldsToMatch,
        searchData.projectData,
        searchData.projectContactsData,
        {}
      );

      setProjectArrLocal(matchedDataForTable);
      setProjectContactsData(searchData.projectContactsData);
      setContactArr(searchData.contactsData);
      setCompanyArr(searchData.companyData);
      setFetchRequest(false);
    }
   
  }, [projectRoles, projectFields, contactFields, searchData]);

  useEffect(() => {
    const dynamicFieldsLoaded = allProjectFieldTypes?.length > 0;
    const rolesLoaded = projectRoleTypes?.length > 0;
    const dynamicColumnsLoaded = dynamicFieldsLoaded && rolesLoaded;

    if (dynamicColumnsLoaded) {
      buildReportColumns();
    }
  }, [allProjectFieldTypes, projectRoleTypes, currentSearchType]);

  const getSearchResult = (searchType, searchText) => {
    setFetchRequest(true);
    let data = {
      accountId: localStorage.getItem('accountId'),
      userId: localStorage.getItem('userId'),
      keyword: searchText,
      type: [searchType],
    };
    dispatch(searchAction(data));
  };

  const onContactName = (cell, row) => {
    return <Link to={{ pathname: `/contact-details/${row.contactId}` }}>{row.displayName ?? '-'}</Link>;
  };

  const onCompanyName = (cell, row) => {
    return row?.companyId ? (
      <Link to={{ pathname: `/company-details/${row.companyId}` }}>
        {row[cell] ? row[cell] : '-'}
      </Link>
    ) : (
      <div>(personal)</div>
    );
  };

  const redirectToProject = (cell,value, row) => {
   
      return (
        <div>
          <Link to={{ pathname: `/project-details/${row.objectId}`, state: { row } }}>
            {row[cell] ? row[cell] : '-'}
          </Link>
        </div>
      );
    
  };

  const buildColumnForRole = (filteredRoles, roleCode, hidden = false) => {
    const foundRole = filteredRoles.find((role) => role.code === roleCode);
    if (!foundRole) return;
    const isInternal = foundRole.type === 'internal';

    return (
      foundRole && {
        Header: foundRole.shortName ?? roleCode,
        LongHeader: foundRole.name,
        accessor: 'projectAssignmentsLabel' + roleCode,
        sortType: customSortByKey,
        sortKey: foundRole.name ?? roleCode,
        Filter: SelectColumnFilter,
        filter: multiSelectFilter,
        width: 60,
        hidden: hidden,
        roleCode: roleCode,
        Cell: (props) => (
          <>
            {isInternal
              ? formatInternalContactData(props.row.original?.projectAssignments.filter(x=>x.projectRoleCode===props.column.roleCode), projectContactsData)
              : formatExternalContactData(props.row.original?.projectAssignments.filter(x=>x.projectRoleCode ===props.column.roleCode), projectContactsData, false)}
          </>
        ),
      }
    );
  };

  const onVenderName = (cell, row) => {
    if (row && row.venderArr && row.venderArr.length > 0) {
      let venderArr = sortAsc(row.venderArr, 'label');

      return (
        <div>
          {venderArr.map((item, i) => {
            let comma = i !== 0 ? ', ' : '';
            return item.venderId ? (
              <Link key={i} to={{ pathname: `/company-details/${item.venderId}` }}>
                {comma}
                {item.label}
              </Link>
            ) : (
              <div key={i}>(Personal)</div>
            );
          })}
        </div>
      );
    } else {
      return <span>-</span>;
    }
  };

  const projectSearch = currentSearchType === 'PROJECT';
  const companySearch = currentSearchType === 'COMPANY';
  const contactSearch = currentSearchType === 'CONTACT';

  const buildReportColumns = () => {


    const customProjectFieldColumns = buildDynamicColumns(
      allProjectFieldTypes.filter((field) => !['SCOPE'].includes(field.code))
    );

    const customContactFieldColumns = buildDynamicColumns(
      allContactFieldTypes.filter((field) => ![] .includes(field.code) && field.stages.includes('contact'))
    );

    const customCompanyFieldColumns = buildDynamicColumns(
      allContactFieldTypes.filter((field) => ![] .includes(field.code) && field.stages.includes('company'))
    );

    const enabledRoles = projectRoleTypes.filter((role) => !['AX', 'CL'].includes(role.code));
    const defaultRoles = projectRoleTypes.filter((role) => ['AX', 'CL'].includes(role.code));

    const defaultProjectColumns = [
      {
        Header: 'Job No.',
        accessor: 'jobNumFormatted',
        sortType: 'basic',
        width: 80,
        Cell: (props) => (
          <>
            <span data-tooltip-content={props.value} data-tooltip-id={props.value}>
              {redirectToProject(props.column.id, props.value, props.row.original)}
            </span>
            <Tooltip id={props.value} delayShow={1000} place='bottom' />
          </>
        ),
      },
      {
        Header: 'Alt Job#',
        accessor: 'altJobNum',
        width: 80,
        hidden: true,
        Cell: (props) => redirectToProject(props.column.id, props.value, props.row.original),
      },
      {
        Header: 'Job Name',
        accessor: 'jobName',
        width: 120,
        Cell: (props) => redirectToProject(props.column.id, props.value, props.row.original),
      },
      {
        Header: 'Start Date',
        accessor: (row) =>
          row?.startDate?.iso
            ? new Date(row.startDate.iso)
            : new Date(formatDate('1800-03-03T12:47:40.000Z')),
        id: 'startDate',
        width: 90,
        Cell: (props) => <>{onDate(props.column.id, props.row.original, props.value)}</>,
        filter: DateColumnFilter,
        allowDateRangeFilter: true,
        sortType: 'datetime',
      },
      {
        Header: 'End Date',
        accessor: (row) =>
          row?.endDate?.iso
            ? new Date(row.endDate.iso)
            : new Date(formatDate('1800-03-03T12:47:40.000Z')),
        id: 'endDate',
        width: 90,
        Cell: (props) => <>{onDate(props.column.id, props.row.original, props.value)}</>,
        filter: DateColumnFilter,
        allowDateRangeFilter: true,
        sortType: 'datetime',
      },
      {
        Header: 'Bid Due Date',
        accessor: (row) =>
          row?.bidDue?.iso
            ? new Date(row.bidDue.iso)
            : new Date(formatDate('1800-03-03T12:47:40.000Z')),
        id: 'bidDue',
        hidden: true,
        width: 170,
        Cell: (props) => <>{onDateTime(props.column.id, props.row.original, props.value)}</>,
        filter: DateColumnFilter,
        allowDateRangeFilter: true,
        sortType: 'datetime',
      },
      {
        Header: 'Est. Contract',
        accessor: (row) => (row?.currentContractAmount),
        id: 'currentContractAmount',
        width: 100,
        Cell: (props) => <>{onCurrency(props.column.id, props.row.original, props.value)}</>,
      },
      buildColumnForRole(defaultRoles, 'AX'),
      buildColumnForRole(defaultRoles, 'CL'),
      buildColumnForField(allProjectFieldTypes, 'SCOPE', false),
    {
        Header: 'Status',
        accessor: 'jobStatusCodesName',
        width: 60,
        Filter: SelectColumnFilter,
        Cell: (props) => <>{onText(props.column.id, props.row.original, props.value)}</>,
      },
    ];

    const contactColumns = [
      {
        Header: 'Contact Person',
        accessor: 'displayName',
        id: 'displayName',
        Filter: SelectColumnFilter,
        width: 120,
        Cell: (props) => onContactName(props.column.id, props.row.original, props.value),
      },
      {
        Header: 'Firm Name',
        accessor: 'companyName',
        id: 'companyName',
        Filter: SelectColumnFilter,
        width: 120,
        Cell: (props) => onVenderName(props.column.id, props.row.original, props.value),
      },
      {
        Header: 'Title',
        accessor: 'title',
        width: 60,
        Cell: (props) => onText(props.column.id, props.row.original, props.value),
      },
      {
        Header: 'Department',
        accessor: 'department',
        width: 60,
        Cell: (props) => onText(props.column.id, props.row.original, props.value),
      },
      {
        Header: 'Contact Phone (Ext)',
        accessor: 'phoneArr',
        width: 60,
        Cell: (props) => onPhone(props.column.id, props.row.original, props.value),
        getCellExportValue: (row, col) => onPhone(col, row),
      },
      {
        Header: 'Email',
        accessor: 'emailArr',
        width: 80,
        Cell: (props) => onEmail(props.column.id, props.row.original, props.value),
        getCellExportValue: (row, col) => onEmail(col, row),
      },
      {
        Header: 'Address',
        accessor: (row) => row.addressArr.map((v) => formatAddressText(v)),
        width: 130,
        Cell: (props) => onAddress(props.column.id, props.row.original, props.value),
        getCellExportValue: (row, col) => onAddress(col, row),
      },
    ];

    const companyColumns = [
      {
        Header: 'Company Name',
        accessor: 'companyName',
        width: 130,
        Cell: (props) => onCompanyName(props.column.id, props.row.original, props.value),
      },
      {
        Header: 'DBA',
        accessor: 'dba',
        width: 130,
        hidden: true,
        Cell: (props) => onCompanyName(props.column.id, props.row.original, props.value),
      },
      {
        Header: 'Website',
        accessor: 'companyWebsite',
        width: 80,
        hidden: true,
        Cell: (props) => onURL(props.column.id, props.row.original, props.value),
      },
      {
        Header: 'Email',
        accessor: 'emailArr',
        exportField: 'email',
        width: 130,
        Cell: (props) => onEmail(props.column.id, props.row.original, props.value),
      },
      {
        Header: 'Phone Number',
        accessor: 'phoneArr',
        exportField: 'phone',
        width: 130,
        Cell: (props) => onPhone(props.column.id, props.row.original, props.value),
      },
      {
        Header: 'Address',
        accessor: 'addressArr',
        width: 130,
        exportField: 'address1',
        Cell: (props) => onAddress(props.column.id, props.row.original, props.value),
      },
    ];

    let columns = [];
    if (projectSearch) {
      const extraRoleColumns = enabledRoles.map((role) => buildColumnForRole([role], role.code, true));
      columns = [...defaultProjectColumns,...extraRoleColumns, ...customProjectFieldColumns].filter(Boolean);
    } else if (contactSearch) {
      columns = [...contactColumns, ...customContactFieldColumns];
    } else if (companySearch) {
      columns = [...companyColumns, ...customCompanyFieldColumns];
    }

    setDynamicColumns(columns);
  };

  const createCustomButtonGroup = () => {
    return (
      <div className='pdfLogoBox'>
        <div className='reportHead'>
          <div className='logo'>
            <img alt='Upload Logo' />
          </div>
          <h2>Global Search</h2>
        </div>
      </div>
    );
  };
  

  const dynamicColumnsBuilt = dynamicColumns.length > 0;

  return (
    <div className='ContactListReport'>
      <TitleComponent data={{ title: `Search - ${currentSearchText}` }} />
      <Suspense fallback='loading'>
        <Header />
      </Suspense>
      <div className=''>
          {projectSearch && (
            <div className='ms-2 me-2'>
              <div className='row'>
                <div className='col-md-12'>
                  <div className='reportHead'></div>
                </div>
                <div className='col-md-12'>
                  <div className='analyzedBy'>
                    <strong>Search results for: {currentSearchText}</strong>{' '}
                  </div>
                </div>
              </div>

              {dynamicColumnsBuilt && (
                <div className='col-md-12'>
                  <div
                    className='table-responsive reportTableSection'
                    ref={componentRef}
                  >
                    {createCustomButtonGroup()}
                    <CustomTable
                      columns={dynamicColumns}
                      list={projectArrLocal}
                      //preferences={listPref}
                      className='bid-due-table'
                      customTableTitle={'Project Search'}
                      //onSavePreferences={SetBidDueReportPrefAction}
                      isLoading={fetchRequest}
                      canShowRowSelection={false}
                      componentRef={componentRef}
                      canShowHeaderItems={true}
                      pageSizeArray={[50, 100, 200]}
                    />
                  </div>
                </div>
              )}
            </div>
          )}

          {contactSearch && (
            <div className='container'>
              <div className='analyzedBy'>
                <strong>Search results for: {currentSearchText}</strong>{' '}
              </div>
              <div className='row'>
                {dynamicColumnsBuilt && (
                  <div className='col-md-12'>
                    <div
                      className='table-responsive reportTableSection'
                      ref={componentRef}
                    >
                      {createCustomButtonGroup()}
                      <CustomTable
                        columns={dynamicColumns}
                        list={contactArr}
                        //preferences={listPref}
                        className='contact-list-table'
                        customTableTitle='Contact Search'
                        //onSavePreferences={SetContactListInfoReportPrefAction}
                        isLoading={fetchRequest}
                        canShowRowSelection={false}
                        componentRef={componentRef}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}

          {companySearch && (
            <div className='container'>
              <div className='row'>
                <div className='col-md-12'>
                  <div className='analyzedBy'>
                    <strong>Search results for: {currentSearchText}</strong>{' '}
                  </div>
                </div>
              </div>

              <div className=''>
                <div className='row'>
                  {dynamicColumnsBuilt && (
                    <div className='col-md-12'>
                      <div
                        className='table-responsive reportTableSection'
                        ref={componentRef}
                      >
                        {createCustomButtonGroup()}
                        <CustomTable
                          columns={dynamicColumns}
                          list={companyArr}
                          //preferences={listPref}
                          className='contact-list-table'
                          customTableTitle='Company Search'
                          //onSavePreferences={SetContactListInfoReportPrefAction}
                          isLoading={fetchRequest}
                          canShowRowSelection={false}
                          componentRef={componentRef}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      <Footer />
    </div>
  );
}

export default SearchList;



    ////////

