import * as actionTypes from './actionTypes';
import * as API from '../api/jobScope';

export const createJobScopeRequest = () => ({
  type: actionTypes.CREATE_JOB_SCOPE_REQUEST,
});

export const createJobScopeSuccess = (response) => ({
  type: actionTypes.CREATE_JOB_SCOPE_SUCCESS,
  payload: {
    response,
  },
});

export const createJobScopeError = (error) => ({
  type: actionTypes.CREATE_JOB_SCOPE_ERROR,
  payload: {
    error,
  },
});

export const createJobScopeAction = (data) => {
  return (dispatch) => {
    dispatch(createJobScopeRequest());
    return API.createJobScope(data)
      .then((response) => {
        dispatch(createJobScopeSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          createJobScopeError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

export const getAllJobScopeRequest = () => ({
  type: actionTypes.GET_ALL_JOB_SCOPE_REQUEST,
});

export const getAllJobScopeSuccess = (response) => ({
  type: actionTypes.GET_ALL_JOB_SCOPE_SUCCESS,
  payload: {
    response,
  },
});

export const getAllJobScopeError = (error) => ({
  type: actionTypes.GET_ALL_JOB_SCOPE_ERROR,
  payload: {
    error,
  },
});

export const getAllJobScopeAction = (data) => {
  return (dispatch) => {
    dispatch(getAllJobScopeRequest());
    return API.getAllJobScope(data)
      .then((response) => {
        dispatch(getAllJobScopeSuccess(response.data));
      })
      .catch((error) => {
        dispatch(getAllJobScopeError('Internet Connection Error'));
      });
  };
};

export const updateJobScopeRequest = () => ({
  type: actionTypes.UPDATE_JOB_SCOPE_REQUEST,
});

export const updateJobScopeSuccess = (response) => ({
  type: actionTypes.UPDATE_JOB_SCOPE_SUCCESS,
  payload: {
    response,
  },
});

export const updateJobScopeError = (error) => ({
  type: actionTypes.UPDATE_JOB_SCOPE_ERROR,
  payload: {
    error,
  },
});

export const updateJobScopeAction = (data) => {
  return (dispatch) => {
    dispatch(updateJobScopeRequest());
    return API.updateJobScope(data)
      .then((response) => {
        dispatch(updateJobScopeSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          updateJobScopeError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

export const deleteJobScopeRequest = () => ({
  type: actionTypes.DELETE_JOB_SCOPE_REQUEST,
});

export const deleteJobScopeSuccess = (response) => ({
  type: actionTypes.DELETE_JOB_SCOPE_SUCCESS,
  payload: {
    response,
  },
});

export const deleteJobScopeError = (error) => ({
  type: actionTypes.DELETE_JOB_SCOPE_ERROR,
  payload: {
    error,
  },
});

export const deleteJobScopeAction = (data) => {
  return (dispatch) => {
    dispatch(deleteJobScopeRequest());
    return API.deleteJobScope(data)
      .then((response) => {
        dispatch(deleteJobScopeSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          deleteJobScopeError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};
