import * as actionTypes from './actionTypes';
import * as API from '../api/Admin/jobStatus';

export const createJobStatusRequest = () => ({
  type: actionTypes.CREATE_JOB_STATUS_REQUEST,
});

export const createJobStatusSuccess = (response) => ({
  type: actionTypes.CREATE_JOB_STATUS_SUCCESS,
  payload: {
    response,
  },
});

export const createJobStatusError = (error) => ({
  type: actionTypes.CREATE_JOB_STATUS_ERROR,
  payload: {
    error,
  },
});

export const createJobStatusAction = (data) => {
  return (dispatch) => {
    dispatch(createJobStatusRequest());
    return API.createJobStatus(data)
      .then((response) => {
        dispatch(createJobStatusSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          createJobStatusError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

export const listImportableJobStatusRequest = () => ({
  type: actionTypes.LIST_IMPORTABLE_JOB_STATUS_REQUEST
})


export const listImportableJobStatusSuccess = (response) => ({
  type: actionTypes.LIST_IMPORTABLE_JOB_STATUS_SUCCESS,
  payload: {
      response,
  }
})

export const listImportableJobStatusError = (error) => ({
  type: actionTypes.LIST_IMPORTABLE_JOB_STATUS_ERROR,
  payload: {
      error
  }
})

export const listImportableJobStatusAction = (data) => {
  return dispatch => {
      dispatch(listImportableJobStatusRequest())
      return API.listImportableJobStatus(data)
          .then(response => {
              dispatch(listImportableJobStatusSuccess(response.data))

          })
          .catch(error => {
              dispatch(listImportableJobStatusError(error.response !== undefined ? error.response.data.error : "Internet Connection Error"))
          })
  }
}

export const copyJobStatusRequest = () => ({
  type: actionTypes.COPY_JOB_STATUS_REQUEST
})


export const copyJobStatusSuccess = (response) => ({
  type: actionTypes.COPY_JOB_STATUS_SUCCESS,
  payload: {
      response,
  }
})

export const copyJobStatusError = (error) => ({
  type: actionTypes.COPY_JOB_STATUS_ERROR,
  payload: {
      error
  }
})

export const copyJobStatusAction = (data) => {
  return dispatch => {
      dispatch(copyJobStatusRequest())
      return API.copyJobStatusCode(data)
          .then(response => {
              dispatch(copyJobStatusSuccess(response.data))

          })
          .catch(error => {
              dispatch(copyJobStatusError(error.response !== undefined ? error.response.data.error : "Internet Connection Error"))
          })
  }
}



export const getAllJobStatusRequest = () => ({
  type: actionTypes.GET_ALL_JOB_STATUS_REQUEST,
});

export const getAllJobStatusSuccess = (response) => ({
  type: actionTypes.GET_ALL_JOB_STATUS_SUCCESS,
  payload: {
    response,
  },
});

export const getAllJobStatusError = (error) => ({
  type: actionTypes.GET_ALL_JOB_STATUS_ERROR,
  payload: {
    error,
  },
});

export const getAllJobStatusAction = (data) => {
  return (dispatch) => {
    dispatch(getAllJobStatusRequest());
    return API.getAllJobStatus(data)
      .then((response) => {
        dispatch(getAllJobStatusSuccess(response.data));
      })
      .catch((error) => {
        dispatch(getAllJobStatusError('Internet Connection Error'));
      });
  };
};

export const updateJobStatusRequest = () => ({
  type: actionTypes.UPDATE_JOB_STATUS_REQUEST,
});

export const updateJobStatusSuccess = (response) => ({
  type: actionTypes.UPDATE_JOB_STATUS_SUCCESS,
  payload: {
    response,
  },
});

export const updateJobStatusError = (error) => ({
  type: actionTypes.UPDATE_JOB_STATUS_ERROR,
  payload: {
    error,
  },
});

export const updateJobStatusAction = (data) => {
  return (dispatch) => {
    dispatch(updateJobStatusRequest());
    return API.updateJobStatus(data)
      .then((response) => {
        dispatch(updateJobStatusSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          updateJobStatusError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

export const deleteJobStatusRequest = () => ({
  type: actionTypes.DELETE_JOB_STATUS_REQUEST,
});

export const deleteJobStatusSuccess = (response) => ({
  type: actionTypes.DELETE_JOB_STATUS_SUCCESS,
  payload: {
    response,
  },
});

export const deleteJobStatusError = (error) => ({
  type: actionTypes.DELETE_JOB_STATUS_ERROR,
  payload: {
    error,
  },
});

export const deleteJobStatusAction = (data) => {
  return (dispatch) => {
    dispatch(deleteJobStatusRequest());
    return API.deleteJobStatus(data)
      .then((response) => {
        dispatch(deleteJobStatusSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          deleteJobStatusError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

export const getCurrentJobStatusCodeAction = (data) => (dispatch, getState) => {
  return getState().jobStatusCode.allJobStatus.find((val) => val.objectId === data)
    ?.jobStatusCodesCode;
};
