import React from 'react';
import  { styles } from "../../../styles"

export const SelectColumnFilter = ({ column: { filterValue, setFilter, preFilteredRows, id } }) => {
  const options = React.useMemo(() => {
    let optionsArr = [];
    preFilteredRows.forEach((row) => {
      const rowVals=row.values[id];
      const isArray = Array.isArray(rowVals);
      if(isArray){
        for (let x in rowVals){
          optionsArr.push(rowVals[x]?.Label??rowVals[x]);
        }
      }else if(rowVals) {
        optionsArr.push(String(rowVals));
      }
    });
    optionsArr.sort();
    return [... new Set(optionsArr.filter(Boolean))];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      style={styles.dropdownFilter}
      className="no-print"
    >
      <option value="">All</option>
      {options.filter(option=>option?.length>0).map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
};
