import { useMemo } from 'react';
import { formatMoney } from '../../../utils/number-formatters';
import { formatDate } from '../../../utils/date-formatters';
import styles from '../change-order-intermediate-modal.module.scss';

type PotentialChangeOrderTableProps = {
  changeOrders: Array<any>;
  isExpanded?: boolean;
  onOpenCOModal: (id: string) => void,
};

export const PotentialChangeOrderTable = ({
  changeOrders = [],
  isExpanded = false,
  onOpenCOModal = () => void 0,
}: PotentialChangeOrderTableProps) => {
  changeOrders.sort((coA, coB) => coA.coNumber - coB.coNumber);

  const totalCOAmount = changeOrders.reduce((acc, co) => acc + co.coTotal, 0);
  const totalCOProfit = changeOrders.reduce((acc, co) => acc + co.profitDollars, 0);
  const totalCOImpact = changeOrders.reduce((acc, co) => acc + co.scheduleImpact, 0);

  const coRows = useMemo(
    () =>
      changeOrders.map((co) => {
        const {
          objectId,
          coNumber,
          //coCostAmount,
          profitDollars,
          scheduleImpact,
          effectiveDate,
          comment,
          reasonName,
          coTotal
        } = co;

        return (
          <tr role='button' key={coNumber} onClick={() => onOpenCOModal(objectId)}>
            <td>PCO {coNumber}</td>
            <td className='text-end'>{formatMoney(coTotal)}</td>
            <td className='text-end'>{formatMoney(profitDollars)}</td>
            <td className='text-end'>{scheduleImpact}</td>
            {isExpanded && (
              <>
                <td>{formatDate(effectiveDate)}</td>
                <td>{comment}</td>
                <td>{reasonName}</td>
              </>
            )}
          </tr>
        );
      }),
    [changeOrders, isExpanded]
  );

  if (changeOrders.length === 0) {
    return null;
  }

  return (
    <div className='mt-2'>
      <b>Potential Change Orders</b>
      <table className={`${styles.coTable} ${styles.clickable}`}>
        <thead>
          <tr>
            <th></th>
            <th>Amount</th>
            <th>Gross profit</th>
            <th>Duration (days)</th>
            {isExpanded && (
              <>
                <th>Date</th>
                <th>Description</th>
                <th>Reason</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {coRows}
          <tr className={styles.thickBorder}>
            <td>Total potential change orders</td>
            <td className='text-end'>{formatMoney(totalCOAmount)}</td>
            <td className='text-end'>{formatMoney(totalCOProfit)}</td>
            <td className='text-end'>{totalCOImpact}</td>
            {isExpanded && (
              <>
                <td></td>
                <td></td>
                <td></td>
              </>
            )}
          </tr>
        </tbody>
      </table>
    </div>
  );
};
