import {updatePermissions} from '../components/Auth/Auth';

export const isAllowed = (moduleCode, assignedUserCheck, assignedUser) => {
  if (!moduleCode) {
    return false;
  }

  let accessArr = JSON.parse(localStorage.getItem('modulePermissions'));
  if (!accessArr || accessArr.length == 0 || !(accessArr?.['0']?.moduleCode)) {
    console.log('no permissions detected!');
    updatePermissions();
    return false;
  }

  if (accessArr && accessArr.length > 0) {
    const checkStatusKey = accessArr.find((accessModule) => accessModule.moduleCode == moduleCode);

    const userContactId = localStorage.getItem('contactId');

    if (checkStatusKey) {
      if (checkStatusKey.status === 'YES') return true;
      else if (checkStatusKey.status === 'NO') return false;
      else if (checkStatusKey.status === 'ASSIGN') {
        // If user is assigned and in assignedUser (contactIds) array then return true
        // use this if the permission may not depend on a particular project assignment (or is checked on backend)
        if (assignedUserCheck === 'anyAssigned') {
          return true;
        } else if (assignedUserCheck === 'assigned') {
          if (assignedUser && assignedUser.length > 0) {
            if (assignedUser.findIndex((x) => x.contactId === userContactId) !== -1) {
              return true;
            } else {
              return false;
            }
          } else {
            return false;
          }
        }
      } else {
        console.log('Permissions for ' + moduleCode + ' ' + checkStatusKey?.status + ' not valid!');
        return false;
      }
    } // module not found, default to true for custom statuses etc
    else {
      //TODO: implement dynamic permissions based on existing statuses instead of outdated ones

      if (moduleCode != undefined) {
        console.log('Permissions for ' + moduleCode + ' not found!');
        return false;
      } else return false;
    }
  } else {
    return false;
  }
};

export const moduleConstants = {
  L: 'P-STATUS-LEAD',
  B: 'P-STATUS-BIDDING',
  BP: 'P-STATUS-BIDPENDING',
  A: 'P-STATUS-ACTIVE',
  D: 'P-STATUS-DELIVERED',
  W: 'P-STATUS-WARRANTY',
  C: 'P-STATUS-COMPLETED',
  P1: 'P-STATUS-P1',
  P2: 'P-STATUS-P2',
  P3: 'P-STATUS-P3',
  OH: 'P-STATUS-ONHOLD',
  NC: 'P-STATUS-NOTBID',
  SUSP: 'P-STATUS-SUSPENDED',
  OVERRIDE: 'P-STATUS-OVERRIDE',
  EL: 'P-PROJECT-ADDNEWLEAD',
  EP: 'P-PROJECT-ADDNEW',
  EC: 'P-MANAGE-CONTACTS',
  EE: 'P-MANAGE-EMPLOYEES',
  EU: 'P-MANAGE-USERS',
  BILL: 'P-ACCOUNT-BILLING',
  EA: 'P-MANAGE-ADMINS',

  PERM: 'P-MANAGE-PERMISSIONS',
  ASSIGNEXTERNAL: 'P-PROJECT-ASSIGN-EXTERNALROLES',
  ASSIGNEST: 'P-PROJECT-ASSIGN-ESTIMATOR',
  ASSIGNPM: 'P-PROJECT-ASSIGN-PROJMANAGER',
  ASSIGNPS: 'P-PROJECT-ASSIGN-SUPERVISOR',
  ASSIGNPE: 'P-PROJECT-ASSIGN-PROJENG',
  ASSIGNINTERNAL: 'P-PROJECT-ASSIGN-INTERNAL',
  EBD: 'P-PROJECT-EDIT-BIDDATE',
  EBAGP: 'P-PROJECT-EDIT-BIDAMOUNT',
  CO: 'P-PROJECT-EDIT-CHANGEORDER',
  EPI: 'Enter Prelim Info (General Contractor)',
  ECD: 'P-PROJECT-EDIT-STARTENDDATES',
  MD: 'P-PROJECT-EDIT-DESCRIPTION',
  EW: 'P-PROJECT-EDIT-WIP',
  VIEWCONTRACTVAL: 'P-PROJECT-VIEW-CONTRACT-VAL',
  MANAGECONTRACT: 'P-PROJECT-MANAGE-CONTRACT',
  VIEWPROFIT: 'P-PROJECT-VIEW-PROFIT',
  EDITPROJDETAILS: 'P-PROJECT-EDIT-DETAILS',
  EN: 'P-NOTES-ADD',
  SM: 'P-SCHEDULE-ADD',
  NUWAOTOAPATE: 'P-NOTIFY-PROJECTASSIGNMENT',
  NLE: 'P-NOTIFY-NEWLEAD',
  NSUSP: 'P-NOTIFY-SUSPENDED',
  NBP_LSU_JNA: 'P-NOTIFY-NEWBIDDINGPROJECT',
  BDW: 'P-NOTIFY-BIDEDUEIN24H',
  BDM: 'P-NOTIFY-BIDMISSED',
  NPB: 'P-NOTIFY-NEWPENDINGBID',
  PPOH: 'P-NOTIFY-PROJECTONHOLD',
  LP: 'P-NOTIFY-JOBLOST',
  AWP: 'P-NOTIFY-PROJECTAWARDED',
  PDC_MTXOD: 'P-NOTIFY-PROJECTDATECHANGED',
  PPC_MTXOLRP: 'P-NOTIFY-PROFITCHANGE',
  PD: 'P-NOTIFY-PROJECTDELIVERED',
  IMPORTCONTACTS: 'P-IMPORT-CONTACTS',
  IMPORTPROJECTS: 'P-IMPORT-PROJECTS',
  VIEWPROJECT: 'P-PROJECT-VIEW-PAGE',
  
};
export const reportAccess = {
  ACCOUNTSETTINGS: 'P-ACCOUNT-SETTINGS',
  ALR: 'P-REPORT-ADDRESSLABEL',
  // LS: "Lead Sheet",
  LS: 'P-REPORT-LEADSUMMARY',
  CLP: 'P-REPORT-CONTACTLIST',
  LSR: 'P-REPORT-LEADSOURCE',
  //CONLV: "Contacts (List View)",
  CONLV: 'P-REPORT-CLIENTLIST',
  COMLV: 'P-REPORT-COMPANYLIST',
  CPLR: 'P-REPORT-CUSTOMLIST',
  BDR: 'P-REPORT-BIDDINGRECAP',
  NBR: 'P-REPORT-NOBID',
  BRR: 'P-REPORT-BIDRESULTRECAP',
  LJR: 'P-REPORT-LOSTJOBS',
  APR: 'P-REPORT-ACTIVEPROJECTS',
  RSR: 'P-REPORT-RESOURCESCHEDULE',
  MCSR: 'P-REPORT-MASTERCONSTRUCTIONSCHEDULE',
  CJL: 'P-REPORT-CONTACTJOBLIST',
  PPIR: 'P-REPORT-PRELIMINFO',
  PIHS: 'P-REPORT-PROJECTINFOHOTSHEET',
  TSF: 'P-REPORT-TIMESHEET',
  PHR: 'P-REPORT-PROJECTHISTORY',
  PPR: 'P-REPORT-PROFITPROJECTION',
  P_SAR: 'P-REPORT-PROFITSALESANALYSIS',
  PPWAR: 'P-REPORT-PROFITPERWEEK',
  DPR: 'P-REPORT-DELIVEREDPROJECTS',
  LPR: 'P-REPORT-LIENPERIOD',
  WLR: 'P-REPORT-WARRANTYLIST',
  FUR: 'P-REPORT-FOLLOWUP',
  CRR: 'P-REPORT-CLIENTREFERENCES',
  DCR: 'P-REPORT-DATACHECK',
  ER: 'P-REPORT-EMPLOYEEROSTER',
  MPLV: 'P-REPORT-MASTERPROJECTLOG',
  PROJECTLIST: 'P-REPORT-PROJECTLIST',
};
export const userInteraction = {
  FF: 'P-WIDGET-FINANCIALFORECAST',
  PPW: 'P-WIDGET-PROFITPROJECTION',
  PROFITSALESW: 'P-WIDGET-PROFITSALES',
  BG: 'P-WIDGET-BIGGESTGAINERS',
  BL: 'P-WIDGET-BIGGESTLOSERS',
  TA: 'P-WIDGET-TEAMACTIVITY',
  RD: 'P-WIDGET-REMINDERS',
  RABD: 'P-WIDGET-RECENTAWARDS',
  CMD: 'P-WIDGET-MISSINGDATA',
  LPN: 'P-WIDGET-LIENPERIOD',
  NLD: 'P-WIDGET-NEWLEADS',
  NBDD: 'P-WIDGET-BIDSDUE',
  BDMD: 'P-WIDGET-MISSEDBIDS',
  NPBS: 'P-WIDGET-PENDINGBIDS',
  CCC: 'P-REPORT-CALENDAR',
};
