import { useCallback, useMemo, useRef, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { useDisabledFieldsContext } from '../../disabled-fields';
import { DefaultLayout } from '../control-layouts/default-layout';
import { LayoutProps } from '../control-layouts/layout-props';

export type FormSelectProps = {
  name: string;
  control?: any;
  label: string;
  options: any[];
  required?: boolean;
  placeholder?: string;
  isClearable?: boolean;
  disabled?: boolean;
  getOptionLabel?: (o: any, props?:any) => string,
  getOptionValue?: (o: any) => string;
  creatable?: boolean;
  formatOptionLabel?: (o: any, props?:any) => any,
  formatCreateLabel?: (o: any) => string;
  getNewOptionData?: (o: any, l: string) => any;
  getExtraValue?: (i: string) => any;
  isOptionDisabled?: (o: any) => any;
  Layout?: (props: LayoutProps) => JSX.Element;
  useObjectValue?: boolean;
  inputText?: string;
  inputClassName?: string;
  labelWidth?: number;
  controlWidth?: number;
  className?: string;
  onChange?: (data: any) => void;
  onInputChange?: (data: any) => void;
};

const FormSelect = ({
  name,
  control,
  label,
  options: initOptions = [],
  required = false,
  placeholder = label,
  isClearable = false,
  disabled = false,
  formatOptionLabel = (o) => o.label,
  getOptionLabel = (o) => o.label,
  getOptionValue = (o) => o.value,
  creatable = false,
  formatCreateLabel = (v) => `Add "${v}"`,
  getNewOptionData = (v, l) => ({ value: v, label: l }),
  getExtraValue = (input) => ({ value: input, label: input }),
  isOptionDisabled = (o) => o.disabled,
  Layout = DefaultLayout,
  useObjectValue = false,
  inputText,
  inputClassName,
  labelWidth = 4,
  controlWidth = 8,
  className,
  onChange,
  onInputChange,
}: FormSelectProps) => {
  const elementRef = useRef<any>();
  const [shouldPlaceTop, setShouldPlaceTop] = useState(false);

  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const maxMenuHeight = 300; // this is default value but setting it strictly to be able to compare
  const optionHeight = 75; // approx option height

  const forceDisabled = useDisabledFieldsContext();

  const formContext = useFormContext();

  if (!control && !formContext) {
    throw new Error('FormSelect must be used within a FormContext or have a control prop');
  }

  const [extraOptions, setExtraOptions] = useState<any[]>([]);

  // if not creatable, return only initOptions
  const options = useMemo(
    () => [...initOptions, ...(creatable ? extraOptions : [])],
    [initOptions, extraOptions, creatable]
  );

  const handleInsert = (input, onChange) => {
    const newOption = getExtraValue(input);

    setExtraOptions((old) => [...old, newOption]);

    onChange(input);
  };

  const getCurrentValue = useCallback(
    (input) => {
      if (!input) return null;

      const foundedOption = options.find((o) => getOptionValue(o) === input);

      if (!creatable || foundedOption) return foundedOption;

      const newOption = getExtraValue(input);

      setExtraOptions((old) => {
        if (old.find((o) => getOptionValue(o) === input)) return old;

        return [...old, newOption];
      });

      return newOption;
    },
    [creatable, options]
  );

  const SelectRenderer = useMemo(() => (creatable ? CreatableSelect : Select), [creatable]);

  const onChangeLocal = useCallback(
    (data, onChangeField) => {
      if (!data) {
        if(onChange) onChange(null);

        return onChangeField(null);
      }

      if (onChange) onChange(data);

      return onChangeField(useObjectValue ? data : getOptionValue(data));
    },
    [useObjectValue]
  );

  const onMenuOpen = () => {
    if (elementRef.current) {
      try {
        // get ref to select DOM element
        const controlRef =
          elementRef.current.select.controlRef ?? elementRef.current.select.select.controlRef;
        // compare distance between container of scroll element and select element to bottom of the screen
        const spaceBelow =
          controlRef.closest('.container').getBoundingClientRect().bottom -
          controlRef.getBoundingClientRect().bottom;

        let approxMenuHeight = (elementRef.current.props.options.length || 1) * optionHeight;

        if (approxMenuHeight > maxMenuHeight) {
          approxMenuHeight = maxMenuHeight;
        }

        setShouldPlaceTop(spaceBelow < approxMenuHeight);

        setMenuIsOpen(true);
      } catch (e) {
        // We want application to not crush, but don't care about the message
      }
    }
  };

  const getTextValue = (value: any) => {
    const selectedOption = useObjectValue ? value : getCurrentValue(value);
    return selectedOption?.label;
  }

  return (
    <Controller
      rules={{ required: required && `${label} is required` }}
      name={name}
      control={control || formContext.control}
      render={({ field, fieldState }) => {
        return (
          <Layout
            className={className}
            name={field.name}
            label={label}
            required={required}
            inputText={inputText}
            textValue={getTextValue(field.value)}
            input={
              <SelectRenderer
                ref={elementRef}
                className={`form-react-select ${inputClassName}`}
                classNamePrefix={`${inputClassName}`}
                placeholder={placeholder}
                isDisabled={disabled || forceDisabled}
                menuPlacement={shouldPlaceTop ? 'top' : 'bottom'}
                inputId={field.name}
                name={field.name}
                options={options}
                value={useObjectValue ? field.value : getCurrentValue(field.value)}
                onChange={(data) => onChangeLocal(data, field.onChange)}
                getOptionValue={getOptionValue}
                getOptionLabel={(option) => getOptionLabel(option, menuIsOpen)}
                isOptionDisabled={isOptionDisabled}
                isClearable={isClearable}
                formatCreateLabel={formatCreateLabel}
                formatOptionLabel={(option) => formatOptionLabel(option, menuIsOpen)}
                getNewOptionData={getNewOptionData}
                onCreateOption={(i) => handleInsert(i, field.onChange)}
                onMenuOpen={onMenuOpen}
                onMenuClose={() => setMenuIsOpen(false)}
                appendTo={document.body}
                menuPortalTarget={document.body}
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                onInputChange={onInputChange}
              />
            }
            error={fieldState.error?.message}
            labelWidth={labelWidth}
            controlWidth={controlWidth}
          />
        );
      }}
    />
  );
};

export default FormSelect;
