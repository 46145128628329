import { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import bannerImage from '../components/static/images/cpostLogo.png';
import Footer from '../components/Layout/Footer.js';
import loaderImage from '../components/static/images/loading_spinner.gif';
import TitleComponent from "../components/Common/TitleComponent";
import { useAuth } from '../components/Auth/Auth';

function App(props) {
  const [showProcessing, setShowProcessing] = useState(false);

  const { isAuthenticated,login } = useAuth();

  const goTo = (route) => {
    props.history.replace(`/${route}`);
  }


  useEffect(() => {
    if (!isAuthenticated()) {
      login();
    }
  }, [isAuthenticated]);

  return (
    <div>
      {!isAuthenticated() && (
        <div className="wrapperBox">
          <TitleComponent data={{ title: `Your Key to Success` }} />
          <header className="frontLoginHeader">
            <div
              className="noteProcessing"
              style={{
                display: showProcessing ? 'block' : 'none',
              }}
            >
              <img src={loaderImage} alt="Loading" />
            </div>
            <div className="container">
              <div className="row">
                <div className="col-sm-6">
                  <div className="logo">
                    <a href="/home">
                      <img alt="logo" src={bannerImage} />
                    </a>
                    <span className="tagline"></span>
                  </div>
                </div>
                <div className="col-sm-6">
                  <form action="#">
                    <div className="signBtn">
                      <a
                        href=""
                        className="btn cancel-btn"
                        onClick={login}
                      >
                        Sign In
                      </a>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </header>
          <Footer />
        </div>
      )}
    </div>
  );
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(App);
