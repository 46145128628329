import * as actionTypes from '../actions/actionTypes';

const initState = {
  changeOrder: {},
  allProjectActivities: [],
  message: '',
  isfetched: false,
  isfetchedError: false,
  fetchRequest: false,
  modalStatusUpdate: true,
};

export default (state = initState, action = {}) => {
  switch (action.type) {
    case actionTypes.GET_PROJECT_HISTORY_QUICK_REQUEST:
      return {
        ...state,
        changeOrder: {},
        modalStatusUpdate: true,
        message: '',
      };

    case actionTypes.GET_PROJECT_HISTORY_QUICK_SUCCESS:
      return {
        ...state,
        modalStatusUpdate: true,
        allProjectActivities: action.payload.response.result.data,
        message: '',
      };

    case actionTypes.GET_PROJECT_HISTORY_QUICK_ERROR:
      return {
        ...state,
        modalStatusUpdate: true,
        message: '',
      };

    case actionTypes.UPDATE_CHANGE_ORDER_REQUEST:
      return {
        ...state,
        buttonActive: true,
        changeOrder: {},
        message: '',
        successStatus: false,
      };
    case actionTypes.UPDATE_CHANGE_ORDER_SUCCESS:
      return {
        ...state,
        buttonActive: false,
        changeOrder: action.payload.response.result.data,
        message: 'Change Order Updated Successfully',
        successStatus: true,
      };

    case actionTypes.UPDATE_CHANGE_ORDER_ERROR:
      return {
        ...state,
        buttonActive: false,
        message: action.payload.error.msg, //'Something Went Wrong in Update Status',
        successStatus: false,
        changeOrder: {},
      };

    case actionTypes.CREATE_CHANGE_ORDER_STATUS_REQUEST:
      return {
        ...state,
        buttonActive: true,
        changeOrderStatus: {},
        message: '',
        successStatus: false,
      };
    case actionTypes.CREATE_CHANGE_ORDER_STATUS_SUCCESS:
      return {
        ...state,
        buttonActive: false,
        changeOrderStatus: action.payload.response.result.data,
        message: 'Change Order Created Successfully',
        successStatus: true,
      };

    case actionTypes.CREATE_CHANGE_ORDER_STATUS_ERROR:
      return {
        ...state,
        buttonActive: false,
        message: action.payload.error.msg,
        successStatus: false,
      };
    case actionTypes.HIDE_CHANGE_ORDER_REQUEST:
      return {
        ...state,
        message: action.payload,
        changeOrderStatus: true,
        modalRequestStatus: false,
      };
    case actionTypes.OPEN_CHANGE_ORDER_REQUEST:
      return {
        ...state,
        message: action.payload,
        changeOrderStatus: false,
        successStatus: false,
        modalRequestStatus: true,
      };

    // case actionTypes.DELETE_CHANGE_ORDER_REQUEST:
    //   return {
    //     ...state,
    //     changeOrder: {},
    //     message: ''
    //   }
    // case actionTypes.DELETE_CHANGE_ORDER_SUCCESS:
    //   return {
    //     ...state,
    //     allProjectActivities: state.allProjectActivities,
    //     message: ''
    //   }

    // case actionTypes.DELETE_CHANGE_ORDER_ERROR:
    //   return {
    //     ...state,
    //     message: ''
    //   }

    default:
      return state;
  }
};
