import { UpdateAccountData } from '@module/accounts';
import joi from 'joi';
import { joiAnyString } from '../../utils/validation';

export const updateAccountSchema = joi.object<UpdateAccountData>({
  _id: joiAnyString(),
  adminEmail: joi
    .string()
    .email({ tlds: { allow: false } })
    .required(),
  adminUserId: joiAnyString(),
  adminContactId: joiAnyString(),
  adminPhoneArr: joi.array().optional(),
  phoneArr: joi.array().optional(),
  city: joiAnyString(),

  companyEmail: joi.string().trim().optional().allow('').allow(null).empty(''), // not used
  
  adminFirstName: joi.string().required().trim(),
  adminLastName: joi.string().required().trim(),
  accountName: joi.string().required().trim(),
  website: joiAnyString(),
  route: joiAnyString(),
  lat: joi.number().optional(),
  lng: joi.number().optional(),
  taxId: joiAnyString(),
  chargebeeId: joiAnyString(),
  address1: joi.string().required().trim(),
  address2: joiAnyString(),
  state: joiAnyString(),
  zipCode: joiAnyString(),
  country: joiAnyString(),
  maxUsers: joi.number().optional(),
});
