
export const LeadMisc = () => {

  return (
    <>
      <b>Additional Information</b>
      <hr />
    </>
  );
};
