import { v4 as uuidv4 } from 'uuid';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDisabledFieldsContext } from '../../disabled-fields';
import { appConstants } from '../../../_constants';
import FormInput from '../form-input';
import FormSelect from '../form-select';
import Star from '../field-actions/star/star';
import Add from '../field-actions/add/add';
import Copy from '../field-actions/copy/copy';
import Delete from '../field-actions/delete/delete';
import styles from '../field-actions/field-actions.module.scss';
import _ from 'lodash';
import { Warning } from '../field-actions/warning';

type EmailArrayProps = {
  name?: string;
  disableSetPrimary?: boolean;
  disableRadio?: boolean;
  getDisabledValue?: (option: any) => boolean;
  onEditCompany?: () => void;
  copyData?: any;
  onValidate?: (value: any) => Promise<any>;
  onWarningClick?: (data: any, field: any) => void;
  onValidationDataChange?: (data: any[], fieldData: any[]) => void;
};

const EmailArray = ({
  name = 'emailArr',
  disableRadio = false,
  disableSetPrimary = false,
  getDisabledValue = () => false,
  onEditCompany = () => void 0,
  copyData = {},
  onValidate = () => Promise.resolve(),
  onWarningClick = () => void 0,
  onValidationDataChange = () => void 0,
}: EmailArrayProps) => {
  const { control, watch, setValue } = useFormContext();
  const forceDisabled = useDisabledFieldsContext();
  const dataArr = watch(name, []);

  const [validationDataArr, setValidationDataArr] = useState<any[]>([]);

  const validate = useCallback(
    _.debounce(async (value: any, index: number) => {
      const data = await onValidate(value);

      setValidationDataArr((prev) => {
        const newArr = [...prev];
        newArr[index] = data;
        return newArr;
      });
    }, 500),
    []
  );

  useEffect(() => {
    onValidationDataChange(validationDataArr, dataArr);
  }, [validationDataArr]);

  useEffect(() => {
    if (dataArr.length === 0) {
      setValue(name, [{ ...appConstants.defaultFieldValues.email, _id: uuidv4() }]);
    }
  }, [dataArr.length]);

  const labelOptions = useMemo(
    () => appConstants.emailOptions.map((value) => ({ value, label: value })),
    []
  );

  const controlsWrapperClassName = `col-md-1 ${styles.rowControlsWrapper}`;
  const dataRowClassName = `d-flex gap-0 row align-items-center ${styles.rowWrapper}`;

  return (
    <div data-testid='EmailArray' className={styles.wrapper}>
      {dataArr.map((field: any, index) => {
        const isDisabled = getDisabledValue(field);
        isDisabled && (disableSetPrimary = true);

        return (
          <div className={dataRowClassName} data-testid={`EmailItem-${index}`} key={field._id}>
            <span className='col-md-2'>{index === 0 ? 'Email' : ''}</span>
            <div className={`${controlsWrapperClassName} justify-content-end d-none d-sm-flex`}>
              {!disableSetPrimary && <Star name={name} index={index} isDisabled={disableRadio} />}
            </div>
            <FormInput
              className={`col-md-6 ${styles.inputWrapper} pe-1 col-7`}
              control={control}
              label='Email'
              disabled={isDisabled}
              placeholder='Email'
              name={`${name}.${index}.email`}
              onChange={(v) => validate(v, index)}
            />
            <div className={`col-md-2 ${styles.selectWrapper} ps-1 col-4`}>
              <FormSelect
                options={labelOptions}
                name={`${name}.${index}.label`}
                creatable
                control={control}
                label='Label'
              />
            </div>
            {forceDisabled || (
              <div className={`${controlsWrapperClassName} justify-content-start col-1`}>
                <Delete name={name} index={index} isDisabled={isDisabled} />

                <Warning
                  data={validationDataArr[index]}
                  onClick={(data) => onWarningClick(data, field)}
                />
              </div>
            )}
          </div>
        );
      })}
      <div className={dataRowClassName}>
        <div className='col-md-8 offset-md-3 d-flex gap-2'>
          <Copy name={name} type='email' data={copyData} onEditCompany={onEditCompany} />
          <Add name={name} type='email' />
        </div>
      </div>
    </div>
  );
};

export default EmailArray;
