import React, { useState, useEffect, useMemo } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, Input } from 'reactstrap';
import { DragDropContext, Droppable, Draggable, DropResult } from '@hello-pangea/dnd';

interface Field {
  label: string;
  value: string;
}

interface ManageFieldsModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (fields: Field[]) => void;
  options: Field[];
  selectedFields: string[];
}

export const ManageFieldsModal: React.FC<ManageFieldsModalProps> = ({
  open,
  onClose,
  onSubmit,
  options = [],
  selectedFields = [],
}) => {
  const [availableFields, setAvailableFields] = useState<Field[]>([]);
  const [selectedFieldsState, setSelectedFieldsState] = useState<Field[]>([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    if (open) {
      const selected = options.filter(field => selectedFields.includes(field.value));
      const available = options.filter(field => !selectedFields.includes(field.value));
      
      const sortedAvailable = available.sort((a, b) => a.label.localeCompare(b.label));
      
      setSelectedFieldsState(selected);
      setAvailableFields(sortedAvailable);
    }
  }, [open, options, selectedFields]);

  const filteredAvailableFields = useMemo(() => {
    return availableFields.filter(field => 
      field.label.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [availableFields, searchTerm]);

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    const sourceList = source.droppableId === 'available' ? filteredAvailableFields : selectedFieldsState;
    const destList = destination.droppableId === 'available' ? filteredAvailableFields : selectedFieldsState;

    const [reorderedItem] = sourceList.splice(source.index, 1);
    destList.splice(destination.index, 0, reorderedItem);

    if (source.droppableId !== destination.droppableId) {
      if (source.droppableId === 'available') {
        setAvailableFields(availableFields.filter(f => f.value !== reorderedItem.value));
        setSelectedFieldsState([...selectedFieldsState]);
      } else {
        setSelectedFieldsState(selectedFieldsState.filter(f => f.value !== reorderedItem.value));
        const newAvailableFields = [...availableFields, reorderedItem].sort((a, b) => 
          a.label.localeCompare(b.label)
        );
        setAvailableFields(newAvailableFields);
      }
    } else {
      if (source.droppableId === 'available') {
        // No need to update state for reordering within available fields
      } else {
        setSelectedFieldsState([...destList]);
      }
    }
  };

  const handleAdd = (field: Field) => {
    
    setAvailableFields(availableFields.filter(f => f.value !== field.value));
    setSelectedFieldsState([...selectedFieldsState, field]);
    setSearchTerm(''); // Clear the search term after adding
  };

  const handleRemove = (field: Field) => {
    setSelectedFieldsState(selectedFieldsState.filter(f => f.value !== field.value));
    const newAvailableFields = [...availableFields, field].sort((a, b) => 
      a.label.localeCompare(b.label)
    );
    setAvailableFields(newAvailableFields);
  };

  const handleSubmit = () => {
    onSubmit(selectedFieldsState);
    onClose();
  };

  const handleSearchKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && filteredAvailableFields.length > 0) {
      handleAdd(filteredAvailableFields[0]);
    }
  };

  return (
    <Modal isOpen={open} toggle={onClose} size="lg">
      <ModalHeader toggle={onClose}>Choose Columns to Display in Your Report</ModalHeader>
      <ModalBody>
        <div className="row mt-0 ms-4 me-4">Select the columns you want to display within your report. Drag and drop to reorder or use the buttons to move fields.</div>
        <DragDropContext onDragEnd={onDragEnd}>
          <div className="row mt-0">
            <div className="col-6 ps-4 pe-4">
              <h5 className='mb-2'>Available fields ({filteredAvailableFields.length})</h5>
              <Input
                type="text"
                placeholder="Search fields..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onKeyDown={handleSearchKeyDown}
                className="mb-2"
              />
              <Droppable droppableId="available">
                {(provided) => (
                  <div 
                    {...provided.droppableProps} 
                    ref={provided.innerRef}
                    className="list-group" 
                    style={{ maxHeight: '400px', overflowY: 'auto', minHeight: '400px' }}
                  >
                    {filteredAvailableFields.map((field, index) => (
                      <Draggable key={field.value} draggableId={field.value} index={index}>
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
                          >
                            {field.label}
                            <span 
                              className="badge bg-primary rounded-pill" 
                              onClick={() => handleAdd(field)}
                              style={{ cursor: 'pointer' }}
                            >
                              <span className='fa fa-plus' />
                            </span>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
            <div className="col-6 ps-4 pe-4">
              <h5 className='mb-2'>Selected fields ({selectedFieldsState.length})</h5>
              <Droppable droppableId="selected">
                {(provided) => (
                  <div 
                    {...provided.droppableProps} 
                    ref={provided.innerRef}
                    className="list-group" 
                    style={{ maxHeight: '1000px', overflowY: 'auto', minHeight: '400px' }}
                  >
                    {selectedFieldsState.map((field, index) => (
                      <Draggable key={field.value} draggableId={field.value} index={index}>
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
                          >
                            <span className='fa fa-bars me-2' />
                            <span className='text-start me-auto'>{field.label}</span>
                            <span
                            className='fa fa-remove'
                            onClick={() => handleRemove(field)}
                            style={{ cursor: 'pointer' }}
                          />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
          </div>
        </DragDropContext>
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-secondary" onClick={onClose}>
          Cancel
        </button>
        <button className="btn btn-primary" onClick={handleSubmit}>
          Save
        </button>
      </ModalFooter>
    </Modal>
  );
};