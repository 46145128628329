import { useMemo } from 'react';
import { formatMoney } from '../../../utils/number-formatters';
import { formatDate, formatDateObject } from '../../../utils/date-formatters';
import styles from '../change-order-intermediate-modal.module.scss';

type ChangeOrderModalProps = {
  project: any;
  changeOrders: Array<any>;
  isExpanded?: boolean;
  onOpenCOModal: (id: string) => void;
};

export const ChangeOrderTable = ({
  project = {},
  changeOrders = [],
  isExpanded = false,
  onOpenCOModal = () => void 0,
}: ChangeOrderModalProps) => {
  const { originalContractAmount, originalGrossProfit, baselineStartDate, baselineEndDate } = project;
  const daysInProject = formatDateObject(baselineEndDate.iso).diff(formatDateObject(baselineStartDate.iso), 'days');

  changeOrders.sort((coA, coB) => coA.coNumber - coB.coNumber);

  const totalCOAmount = changeOrders.reduce((acc, co) => acc + co.coTotalAmount, 0);
  const totalCOProfit = changeOrders.reduce((acc, co) => acc + co.profitDollars, 0);
  const totalCOImpact = changeOrders.reduce((acc, co) => acc + (co.scheduleImpact??0), 0);

  const coRows = useMemo(
    () =>
      changeOrders.map((co) => {
        const {
          objectId,
          coNumber,
          coTotalAmount,
          profitDollars,
          scheduleImpact,
          effectiveDate,
          comment,
          reasonName,
        } = co;

        return (
          <tr role='button' key={coNumber} onClick={() => onOpenCOModal(objectId)}>
            <td>CO {coNumber}</td>
            <td className='text-end'>{formatMoney(coTotalAmount)}</td>
            <td className='text-end'>{formatMoney(profitDollars)}</td>
            <td className='text-end'>{scheduleImpact??'-'}</td>
            {isExpanded && (
              <>
                <td>{effectiveDate?formatDate(effectiveDate):'-'}</td>
                <td>{comment}</td>
                <td>{reasonName}</td>
              </>
            )}
          </tr>
        );
      }),
    [changeOrders, isExpanded]
  );

  return (
    <div>
      <table className={`${styles.coTable} ${styles.clickable}`}>
        <thead>
          <tr>
            <th></th>
            <th>Amount</th>
            <th>Gross profit</th>
            <th>Duration (days)</th>
            {isExpanded && (
              <>
                <th>Date approved</th>
                <th>Description</th>
                <th>Reason</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Original Contract</td>
            <td className='text-end'>{formatMoney(originalContractAmount)}</td>
            <td className='text-end'>{formatMoney(originalGrossProfit)}</td>
            <td className='text-end'>{daysInProject}</td>
            {isExpanded && (
              <>
                <td>{formatDate(baselineStartDate)}</td>
                <td></td>
                <td></td>
              </>
            )}
          </tr>
          {coRows}
          <tr className={styles.thickBorder}>
            <td>Total change orders</td>
            <td className='text-end'>{formatMoney(totalCOAmount)}</td>
            <td className='text-end'>{formatMoney(totalCOProfit)}</td>
            <td className='text-end'>{totalCOImpact}</td>
            {isExpanded && (
              <>
                <td></td>
                <td></td>
                <td></td>
              </>
            )}
          </tr>
          <tr className={styles.veryThickBorder}>
            <td>Current Contract</td>
            <td className='text-end'>{formatMoney(originalContractAmount + totalCOAmount)}</td>
            <td className='text-end'>{formatMoney(originalGrossProfit + totalCOProfit)}</td>
            <td className='text-end'>{daysInProject + totalCOImpact}</td>
            {isExpanded && (
              <>
                <td></td>
                <td></td>
                <td></td>
              </>
            )}
          </tr>
        </tbody>
      </table>
    </div>
  );
};
