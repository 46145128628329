import React, { useEffect, useRef, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { addContactCompanyAction, getContactDetailAction } from '../../actions/contact';
import { useAuth } from '../Auth/Auth';
import ContactToolbar from '../../components/Contact/ContactToolbar.component';
import TitleComponent from '../Common/TitleComponent';
import DetailsRightSection from '../Layout/DetailsRightSection';
import Footer from '../Layout/Footer';
import Header from '../Layout/Header';
import ContactNotFound from './contact-not-found';
import { ViewContactDetailInfo } from './view-contact-detail-info';
import { ViewContactProjects } from './view-contact-projects';
import moment from 'moment';
import styles from './view-contact-detail-info/style.module.css';

const ViewContactDetails = ({ objectId, getContactDetailAction, allContacts }) => {
  const [userId] = useState(localStorage.getItem('userId'));
  const [accountId] = useState(localStorage.getItem('accountId'));
  const [contactId, setContactId] = useState('');
  const [contactData, setContactData] = useState({});
  const [isContactFound, setIsContactFound] = useState(false);
  const [fetchRequest, setFetchRequest] = useState(true);

  const componentToPrint = useRef(null);
  const contactToolbarRef = useRef(null);

  
  const fetchContactData = useCallback(async () => {
    if (objectId?.length>0) {
      setContactId(objectId);
      getContactInfo(objectId);
    }
  });



  useEffect(() => {
    if (allContacts?.isContactFound) {
      setIsContactFound(true);
      setContactData(allContacts.contactsData );
      setFetchRequest(false);
    }
  },[allContacts])

  useEffect(() => {
    fetchContactData();
  }, [objectId]);

  useEffect(() => {
    if (!isContactFound && !fetchRequest) {
      return (<ContactNotFound />);
    }
  }, [isContactFound, fetchRequest]);

  const getContactInfo = (contactId) => {
    let data = {};
    data['accountId'] = accountId;
    data['userId'] = userId;
    data['type'] = 'contacts';
    if (contactId) {
      data['id'] = contactId;
      getContactDetailAction(data);
      setContactId(contactId);
      setFetchRequest(true);
    }
    
  };

  const onOpenEditModal = (section) => {
    contactToolbarRef.current.onEditContactClick(section);
  };

  let contactInfo = contactData;
  contactInfo.createdAt = moment(contactInfo.createdAt).format('YYYY-MM-DD');
  const [ updatedAt, setUpdatedAt ] = useState(contactInfo.updatedAt);

  if (!isContactFound && !fetchRequest) {
    return (<ContactNotFound />);
  }

  return (
    <div className='ContactListReport'>
      <TitleComponent
        data={{ title: `Contact Details - ${contactInfo.firstName} ${contactInfo.lastName}` }}
      />
      <Header props={{}} />
      <div className='addCompany' ref={(el) => (componentToPrint.current = el)}>
        <div className='container'>
          {contactId?.length>0&&<>
          <div className='row mb-0'>
            <ContactToolbar
              contactData={contactInfo}
              componentToPrint={componentToPrint}
              fetchContactData={fetchContactData}
              ref={contactToolbarRef}
            />
          </div>
          <div className='row mt-0'>
            <div className='col-md-6 col-lg-7 col-xl-7'>
              <div className='contactDetailSidebar d-flex flex-column mt-2'>
                <ViewContactDetailInfo contact={contactInfo} onOpenEditModal={onOpenEditModal} />
                <ViewContactProjects contactId={contactId} />
              </div>
            </div>
            <div className='col-md-6 col-lg-5 col-xl-5 mt-2'>
              {(contactId?.length>0) && (
                <DetailsRightSection
                  typeRef={'CONTACT'}
                  typeId={contactId}
                  data={contactInfo}
                />
              )}
            </div>
          </div>
          </>}
        </div>
        
      </div>
      <Footer />
    </div>
  );
};

function mapStateToProps(state) {
  return {
    allContacts: state.contact.response,
    success: state.contact.success,
    contactCompanySubmission: state.contact.contactCompanySubmission,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    getContactDetailAction,
    addContactCompanyAction,
  })(ViewContactDetails)
);
