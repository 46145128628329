import * as actionTypes from './actionTypes';
import * as API from '../api/company';

//get all user details
export const getAllCompanyRequest = () => ({
  type: actionTypes.GET_ALL_COMPANY_REQUEST,
});

export const getAllCompanySuccess = (response) => ({
  type: actionTypes.GET_ALL_COMPANY_SUCCESS,
  payload: {
    response,
  },
});

export const getAllCompanyError = (error) => ({
  type: actionTypes.GET_ALL_COMPANY_ERROR,
  payload: {
    error,
  },
});

export const getCompanyListAction = (data) => {
  return (dispatch) => {
    dispatch(getAllCompanyRequest());
    return API.getAllCompany(data)
      .then((response) => {
        dispatch(getAllCompanySuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          getAllCompanyError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

export const createCompanyRequest = () => ({
  type: actionTypes.CREATE_COMPANY_REQUEST,
});

export const createCompanySuccess = (response) => ({
  type: actionTypes.CREATE_COMPANY_SUCCESS,
  payload: {
    response,
  },
});

export const createCompanyError = (error) => ({
  type: actionTypes.CREATE_COMPANY_ERROR,
  payload: {
    error,
  },
});

export const createCompanyAction = (data) => {
  return (dispatch) => {
    dispatch(createCompanyRequest());
    return API.createCompany(data)
      .then((response) => {
        dispatch(createCompanySuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          createCompanyError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

export const updateCompanyRequest = () => ({
  type: actionTypes.CREATE_COMPANY_REQUEST,
});

export const updateCompanySuccess = (response) => ({
  type: actionTypes.CREATE_COMPANY_SUCCESS,
  payload: {
    response,
  },
});

export const updateCompanyError = (error) => ({
  type: actionTypes.CREATE_COMPANY_ERROR,
  payload: {
    error,
  },
});

export const updateCompanyAction = (data) => {
  return (dispatch) => {
    dispatch(updateCompanyRequest());
    return API.updateCompany(data)
      .then((response) => {
        dispatch(updateCompanySuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          updateCompanyError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

export const getAllCompanyUserRequest = () => ({
  type: actionTypes.GET_ALL_COMPANY_USER_REQUEST,
});

export const getAllCompanyUserSuccess = (response) => ({
  type: actionTypes.GET_ALL_COMPANY_USER_SUCCESS,
  payload: {
    response,
  },
});

export const getAllCompanyUserError = (error) => ({
  type: actionTypes.GET_ALL_COMPANY_USER_ERROR,
  payload: {
    error,
  },
});

export const getCompanyUserListAction = (data) => {
  return (dispatch) => {
    dispatch(getAllCompanyUserRequest());
    return API.getAllCompanyUser(data)
      .then((response) => {
        dispatch(getAllCompanyUserSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          getAllCompanyUserError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

export const deleteCompanyRequest = () => ({
  type: actionTypes.DELETE_COMPANY_REQUEST,
});

export const deleteCompanySuccess = (response) => ({
  type: actionTypes.DELETE_COMPANY_SUCCESS,
  payload: {
    response,
  },
});

export const deleteCompanyError = (error) => ({
  type: actionTypes.DELETE_COMPANY_ERROR,
  payload: {
    error,
  },
});

export const deleteCompanyAction = (data) => {
  return (dispatch) => {
    dispatch(deleteCompanyRequest());
    return API.deleteCompany(data)
      .then((response) => {
        dispatch(deleteCompanySuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          deleteCompanyError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

//company DD list

export const getCompanyDDRequest = () => ({
  type: actionTypes.GET_COMPANY_DD_REQUEST,
});

export const getCompanyDDSuccess = (response) => ({
  type: actionTypes.GET_COMPANY_DD_SUCCESS,
  payload: {
    response,
  },
});

export const getCompanyDDError = (error) => ({
  type: actionTypes.GET_COMPANY_DD_ERROR,
  payload: {
    error,
  },
});

export const getCompanyDDAction = (data) => {
  return (dispatch) => {
    dispatch(getCompanyDDRequest());
    return API.getCompanyDD(data)
      .then((response) => {
        dispatch(getCompanyDDSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          getCompanyDDError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};
