import { useEffect, useCallback, useState } from 'react';
import { useDispatch, } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { SearchAffiliationModal } from '../search-affiliation-modal/search-affiliation-modal';
import { getCompanyContactAffiliations, getAccountAffiliation } from '../../modules/companies';

type SearchCompanyModalProps = {
  open: boolean;
  onClose?: (...args: any) => unknown;
  onConfirm?: (...args: any) => unknown;
  onGetOriginalCompanyName: (...args: any) => string;
};

type DialogDefaultValues = {
  affiliations: Array<any>,
  formerAffiliations: Array<any>,
  affiliationsFetched: Array<any>,
}

export const SearchCompanyModal = ({
  open = false,
  onClose = () => void 0,
  onConfirm = () => void 0,
  onGetOriginalCompanyName,
}: SearchCompanyModalProps) => {
  const defaultValues: DialogDefaultValues = {
    affiliations: [],
    formerAffiliations: [],
    affiliationsFetched: [],
  };

  const dispatch: any = useDispatch();
  const [originalCompanyName, setOriginalCompanyName] = useState<string>('');
  const form = useForm({ defaultValues });
  const { watch, setValue } = form;
  const companyAffiliation = watch('affiliations') || [];

  useEffect(() => {
    if (open) {
      dispatch(getAccountAffiliation());
      dispatch(getCompanyContactAffiliations());
      setOriginalCompanyName(onGetOriginalCompanyName());
    }
  }, [open]);

  const onCloseClick = () => {
    setValue('affiliations', []);
    onClose();
  };

  const onConfirmClick = useCallback(() => {
    onConfirm(companyAffiliation[0]);
    onCloseClick();
  }, [companyAffiliation]);

  return (
    <Modal isOpen={open} toggle={onClose} size='lg'>
      <ModalHeader toggle={onClose}>Match Company</ModalHeader>
      <ModalBody>
        <FormProvider {...form}>
          <div className='d-flex pb-2 gap-2' data-testid='searchCompanyModal-originalCompany'>
            Original Company: <b>{originalCompanyName}</b>
          </div>
          {!!companyAffiliation.length && (
            <div className='d-flex pb-2 gap-2' data-testid='searchCompanyModal-selectedCompany'>
              Selected Company: <b>{companyAffiliation[0].companyName}</b>
            </div>
          )}
          <SearchAffiliationModal
            onAffiliationPush={(company) => setValue('affiliations', [company])}
            addCompanyText={'Select Another Company'}
          />
        </FormProvider>
      </ModalBody>
      <ModalFooter>
        <button className='btn btn-primary' onClick={onCloseClick}>
          Cancel
        </button>
        <button className='btn btn-primary' onClick={onConfirmClick}>
          Confirm
        </button>
      </ModalFooter>
    </Modal>
  );
};
