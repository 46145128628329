import { memo, useCallback } from 'react';
import { CustomFieldControl } from '../../../shared/custom-fields-controls/form-controls/custom-field-control';

export const ProjectImportCustomFields = memo(({ fields = [], projectStage }: any) => {
  const belongToCurrentStage = useCallback(
    ({ stages }: { stages: Array<string> }) =>
      stages.includes('all') || stages.includes(projectStage),
    [projectStage]
  );

  const shouldBeRequired = useCallback(
    (field: any) => {
      const { stages, required } = field;

      const belongsToCurrentStage = stages.includes('all') || stages.includes(projectStage);

      return belongsToCurrentStage && required;
    },
    [projectStage]
  );

  const currStageFields = fields.filter(belongToCurrentStage);

  const renderList = useCallback(
    (fields: any[] = []) =>
      fields.map((field: any) => {
        const { _id } = field;

        return (
          <CustomFieldControl
            key={_id}
            required={shouldBeRequired(field)}
            fieldDefinition={field}
            disabled={true}
          />
        );
      }),
    [shouldBeRequired]
  );

  return <>{renderList(currStageFields)}</>;
});
