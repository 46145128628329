import React, { Suspense } from 'react';
import HeaderOnboarding from '../Layout/HeaderOnboarding.js';
import Footer from '../Layout/Footer.js';
import './../../index.css';
import TitleComponent from "../Common/TitleComponent.js";
import WelcomePage from '../../pages/welcome-page/welcome-page';

class OnboardHome extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      onboardingStatus: Number(localStorage.getItem('onboardingStatus')),
    };
  }

  render() {
    return (
      <Suspense fallback="loading">
        <div className="ContactListReport">
          <TitleComponent data={{ title: `Account Onboarding` }} />
          {this.state.onboardingStatus >= 0 && <HeaderOnboarding props={this.props} />}
            <div className="container">
              <WelcomePage />
            </div>
          <Footer />
        </div>
      </Suspense>
    );
  }
};

export default OnboardHome;
