import axios from 'axios';
import React from 'react';
import { appConstants } from '../_constants';
import { notification, Button } from 'antd';
import history from '../routes/history';

var CancelToken = axios.CancelToken;
let wj_cancel;

const close = () => {
  history.push('/logout');
};

export const request = (path, data, method) => {
  if (localStorage.getItem('sessionToken') !== undefined) {
    data['userId'] = localStorage.getItem('userId');
    var options = {
      method: method,
      url: `${appConstants.WEB_SERVICE_URL}/${path}`,
      headers: {
        crossDomain: true,
        'X-Parse-Application-Id': appConstants.PARSE_APPLICATION_ID,
        'X-Parse-REST-API-Key': appConstants.PARSE_REST_API_KEY,
        'X-Parse-Session-Token': localStorage.getItem('sessionToken'),
        authorization: localStorage.getItem('JWTTOKEN'),
      },
      data: data,
      cancelToken: new CancelToken(function executor(c) {
        // An executor function receives a cancel function as a parameter
        wj_cancel = c;
      }),
    };
    let res = axios(options);
    return res
      .then(async function (res1) {
        let role = localStorage.getItem('baseUserRole');
        if (role !== 'SUPERADMIN') {
          requestUpdateLoginPermission(res1, path);
          // return res1;
        }
        return res1;
      })
      .catch((error) => {
        res = error.response;
        return res;
      });
  }
};

export const requestForNode = (path, data, method) => {
  if (localStorage.getItem('sessionToken') !== undefined) {
    wj_cancel && wj_cancel();
    data['userId'] = localStorage.getItem('userId');
    return axios({
      method: method,
      url: `${appConstants.WEB_SERVICE_URL_WITHOUT_PARSE}/${path}`,
      headers: {
        crossDomain: true,
        'X-Parse-Application-Id': appConstants.PARSE_APPLICATION_ID,
        'X-Parse-REST-API-Key': appConstants.PARSE_REST_API_KEY,
        'X-Parse-Session-Token': localStorage.getItem('sessionToken'),
        authorization: localStorage.getItem('JWTTOKEN'),
      },
      data: data,
      cancelToken: new CancelToken(function executor(c) {
        // An executor function receives a cancel function as a parameter
        wj_cancel = c;
        // console.log("wj_cancel", wj_cancel);
      }),
    });
  }
};

export const requestForSearch = (path, data, method) => {
  if (localStorage.getItem('sessionToken') !== undefined) {
    wj_cancel && wj_cancel();
    data['userId'] = localStorage.getItem('userId');
    return axios({
      method: method,
      url: `${appConstants.WEB_SERVICE_URL}/${path}`,
      headers: {
        crossDomain: true,
        'X-Parse-Application-Id': appConstants.PARSE_APPLICATION_ID,
        'X-Parse-REST-API-Key': appConstants.PARSE_REST_API_KEY,
        'X-Parse-Session-Token': localStorage.getItem('sessionToken'),
        authorization: localStorage.getItem('JWTTOKEN'),
      },
      data: data,
      cancelToken: new CancelToken(function executor(c) {
        // An executor function receives a cancel function as a parameter
        wj_cancel = c;
        // console.log("wj_cancel", wj_cancel);
      }),
    });
  }
};

export const requestWithoutParseData = (path, data, method) => {
  if (localStorage.getItem('sessionToken') !== undefined) {
    data['userId'] = localStorage.getItem('userId');
    return axios({
      method: method,
      url: `${appConstants.WEB_SERVICE_URL_WITHOUT_PARSE}/${path}`,
      headers: {
        crossDomain: true,
        'X-Parse-Application-Id': appConstants.PARSE_APPLICATION_ID,
        'X-Parse-REST-API-Key': appConstants.PARSE_REST_API_KEY,
        'X-Parse-Session-Token': localStorage.getItem('sessionToken'),
        authorization: localStorage.getItem('JWTTOKEN'),
      },
      data: data,
      cancelToken: new CancelToken(function executor(c) {
        // An executor function receives a cancel function as a parameter
        wj_cancel = c;
        // console.log("wj_cancel", wj_cancel);
      }),
    });
  }
};

export const clearLoggedInUser=()=>{
  localStorage.removeItem('access_token');
  localStorage.removeItem('id_token');
  localStorage.removeItem('expires_at');
  localStorage.removeItem('userinfo');
  localStorage.removeItem('cppRoles');
  localStorage.removeItem('loginType')
  localStorage.removeItem('modulePermissions');
  localStorage.removeItem('companyLogo');
  localStorage.removeItem('accountInfo');
  
  localStorage.removeItem('accountId');
  localStorage.removeItem('contactId');

  localStorage.removeItem('JWTTOKEN');

  localStorage.removeItem('userId');
  localStorage.removeItem('userInfo');

  localStorage.removeItem('searchArr');
  localStorage.removeItem('companyName');

  localStorage.removeItem('sessionToken');

  localStorage.removeItem('cppRoleId');
  localStorage.removeItem('baseUserRole');
  
  //localStorage.clear();
}

export const requestUpdateLoginPermission = (res1, path) => {
  var self = this;
  if (
    res1 &&
    res1.data &&
    res1.data.result &&
    (res1.data.result.code === 402 || path === 'functions/setAccountRoleMatrix')
  ) {
    wj_cancel && wj_cancel();
    if (res1.data.result.code === 402) {
      let matrixData =
        res1.data.result && res1.data.result.data && res1.data.result.data.modulePermissions;
      localStorage.setItem('modulePermissions', JSON.stringify(matrixData));
    }
    let paramData = {
      accountId: localStorage.getItem('accountId'),
      userId: localStorage.getItem('userId'),
    };
    axios({
      method: 'post',
      url: `${appConstants.WEB_SERVICE_URL}/functions/updateLoginPermission`,
      headers: {
        crossDomain: true,
        'X-Parse-Application-Id': appConstants.PARSE_APPLICATION_ID,
        'X-Parse-REST-API-Key': appConstants.PARSE_REST_API_KEY,
        'X-Parse-Session-Token': localStorage.getItem('sessionToken'),
        authorization: localStorage.getItem('JWTTOKEN'),
      },
      data: paramData,
      cancelToken: new CancelToken(function executor(c) {
        // An executor function receives a cancel function as a parameter
        wj_cancel = c;
      }),
    })
      .then((res) => {
        if (path !== 'functions/setAccountRoleMatrix') {
          notification.success({
            message: appConstants.errorMsg.permissionChange,
            duration: 3,
          });
          window.location.reload();
        }
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.data.code === 209 && error.response.status === 400) {
            if (self.state.messageLogout === false) {
              self.state.messageLogout = true;
              notification.error({
                message:
                  'Your account was recently logged into from a new browser or device. Was this you?',
                duration: 5,
              });
              //self.logout();
              clearLoggedInUser();
              
              history.replace('/');
            }
          }
        }
      });
  }
};

export const requestWithOutCancelToken = (path, data, method) => {
  if (localStorage.getItem('sessionToken') !== undefined) {
    data['userId'] = localStorage.getItem('userId');
    var options = {
      method: method,
      url: `${appConstants.WEB_SERVICE_URL}/${path}`,
      headers: {
        crossDomain: true,
        'X-Parse-Application-Id': appConstants.PARSE_APPLICATION_ID,
        'X-Parse-REST-API-Key': appConstants.PARSE_REST_API_KEY,
        'X-Parse-Session-Token': localStorage.getItem('sessionToken'),
        authorization: localStorage.getItem('JWTTOKEN'),
      },
      data: data,
    };
    let res = axios(options);
    return res
      .then(async function (res1) {
        let role = localStorage.getItem('baseUserRole');
        if (role !== 'SUPERADMIN') {
          requestUpdateLoginPermission(res1, path);
          // return res1;
        }
        return res1;
      })
      .catch((error) => {
        res = error.response;
        console.log(error);
        return res;
      });
  }
};

export const postRequest = (path, data) => request(path, data, 'POST');
export const postRequestWithOutCancelToken = (path, data) =>
  requestWithOutCancelToken(path, data, 'POST');
export const postRequestNode = (path, data) => requestForNode(path, data, 'POST');
export const postRequestForSearch = (path, data) => requestForSearch(path, data, 'POST');
export const postRequestWithoutParseData = (path, data) =>
  requestWithoutParseData(path, data, 'POST');
export const putRequest = (path, data) => request(path, data, 'PUT');
