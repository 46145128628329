import { Suspense, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ResourceScheduleFilterModal } from '../../../modals/resource-schedule-filter-modal';
import { selectGetResourceSchedule } from '../../../modules/reports/selectors';
import TitleComponent from '../../Common/TitleComponent';
import { ResourceScheduleModal } from '../../Dialogs';
import Footer from '../../Layout/Footer';
import Header from '../../Layout/Header';
import loaderImage from '../../static/images/loading_i.gif';
import ResourceScheduler from './resource-scheduler';

function ProjectTimelineReport(props) {
  const [isResourceScheduleModalOpen, setIsResourceScheduleModalOpen] = useState(false);

  const { data: resourceSchedule, loading: isGetResourceScheduleLoading } =
    useSelector(selectGetResourceSchedule);

  // useEffect(() => {
  //   setIsResourceScheduleModalOpen(true);
  // }, []);

  const handleResourceScheduleModalToggle = () => {
    setIsResourceScheduleModalOpen(
      (prevIsResourceScheduleModalOpen) => !prevIsResourceScheduleModalOpen
    );
  };

  const comLogo = localStorage.getItem('companyLogo');

  const createCustomButtonGroup = () => {
    return (
      <div className='pdfLogoBox'>
        <div className='reportHead'>
          <div className='row'>
            <div className='col-4'>
              <div className='logo'>
                <img src={comLogo} alt='Upload Logo' />
              </div>
            </div>
            <div className='col-6'>
              <div className='row'>
                <h2 className='my-0'>Project Timeline Report</h2>
              </div>
              <div>
                <div className='row'>
                  <div className='col-6'>
                    <strong>Analyzed By:</strong> {resourceSchedule.analyzeByStatus}
                  </div>
                </div>
                <div className='row'>
                  <div className='col-6'>
                    <strong>Date: </strong>{' '}
                    {resourceSchedule.formatDate
                      ? `${resourceSchedule.formatDate.startDate} to ${resourceSchedule.formatDate.endDate}`
                      : ''}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className='d-flex flex-column'>
        <TitleComponent data={{ title: 'Project Timeline Report' }} />

        <Suspense fallback='loading'>
          <Header props={props} />
        </Suspense>

        <div className='contactBox flex-fill'>
          <div className='container-fluid position-relative h-100'>
            <div className='row'>
              {isGetResourceScheduleLoading && (
                <div className='loading_bg'>
                  <img className='ajax-loader' src={loaderImage} alt='' width='100' height='100' />
                </div>
              )}
            </div>

            {createCustomButtonGroup()}

            <div className='d-flex flex-column gap-2 h-100'>
              <div className='row gap-2 no-print'>
                <div className='col-md-6'>
                  <h2 className='my-0'>Project Timeline Report</h2>
                  <div>
                    <strong>Analyzed By:</strong> {resourceSchedule.analyzeByStatus}
                  </div>
                  <div>
                    <strong>Date: </strong>
                    {resourceSchedule.formatDate
                      ? `${resourceSchedule.formatDate.startDate} to ${resourceSchedule.formatDate.endDate}`
                      : ''}
                  </div>
                </div>
                <div className='col-md'>
                  <button
                    className='btn d-md-block ml-md-auto'
                    onClick={handleResourceScheduleModalToggle}
                  >
                    <i className='fa fa-filter'></i> Apply Filters
                  </button>

                </div>
              </div>

              <ResourceScheduler />
            </div>
          </div>
        </div>
      </div>

      <ResourceScheduleModal
        isOpen={isResourceScheduleModalOpen}
        toggle={handleResourceScheduleModalToggle}
      />
    </>
  );
}

export default ProjectTimelineReport;
