import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useAppDispatch } from '../../../shared/hooks/use-app-dispatch';
import { useConfirm } from '../../../shared/confirmation-dialog';
import { useCallback, useEffect, useState } from 'react';
import {
  getCustomSelectOptions,
  getProjectFieldForm,
  editCustomField,
  createCustomField,
  disableCustomField,
  enableCustomField,
  resetCustomField
} from '../../../modules/settings';
import { useForm, FormProvider } from 'react-hook-form';
import FormInput from '../../../shared/form-controls/form-input';
import FormSelect from '../../../shared/form-controls/form-select';
import FormCheckbox from '../../../shared/form-controls/form-checkbox';
import FormMultiselect from '../../../shared/form-controls/form-multiselect';
import { ControlTypeDefinitionSection } from '../../../shared/custom-fields-controls';
import { projectSections, projectStages } from '../../../shared/custom-fields-controls/constants';

type EditProjectFieldModalProps = {
  open?: boolean;
  onClose?: () => void;
  onAfterSubmit?: () => void;
  selectedFieldId?: string | null;
};

export const EditProjectFieldModal = ({
  open = false,
  onClose = () => void 0,
  onAfterSubmit = () => void 0,
  selectedFieldId = null,
}: EditProjectFieldModalProps) => {
  const confirm = useConfirm();
  const dispatch = useAppDispatch();

  const defaultValues = {
    code: '',
    name: '',
    definition: '',
    shortName: '',
    page: 'projects',
    stages: ['all'],
    section: '',
    required: false,
    references: null,
    hideInitially: false,
    controlType: 'TEXT_INPUT',
    valueDefinition: {
      type: 'string',
      defaultValue: '',
      isUrl: false,
      defaultValueUrl: '',
    },
  };

  const isUpdate = !!selectedFieldId;

  const [hasOriginalId, setHasOriginalId] = useState(false);

  useEffect(() => {
    if (!open) return;

    if (selectedFieldId) {
      dispatch(getProjectFieldForm({ fieldId: selectedFieldId }))
        .unwrap()
        .then((data) => {
          form.reset(data);
          setHasOriginalId(data?.originalId?.length>0);
          
        });
    } else {
      form.reset({ ...defaultValues });
    }
  }, [selectedFieldId, open]);

  const form = useForm<any>({
    defaultValues,
  });

  const dataSrc = form.watch('valueDefinition.dataSrc');
  const trash = form.watch('trash');
  const references = form.watch('references');

  useEffect(() => {
    if (dataSrc) {
      dispatch(getCustomSelectOptions({ listName: dataSrc }));
    }
  }, [dataSrc]);

  const onDisableField = useCallback(async () => {
    if (selectedFieldId) {
      const ok = await confirm({
        title: 'Disabling Custom Field',
        question: 'Are you sure you want to disable this custom field?',
      });

      if (!ok) return;

      dispatch(disableCustomField({ fieldId: selectedFieldId }));
      onAfterSubmit();
      onClose();
    }
  }, [selectedFieldId]);

  const onEnableField = useCallback(async () => {
    if (selectedFieldId) {
      dispatch(enableCustomField({ fieldId: selectedFieldId }));
      onAfterSubmit();
      onClose();
    }
  }, [selectedFieldId]);

  const ToggleFieldButton = useCallback(() => {
    const label = trash ? 'Enable' : 'Disable';
    const onClick = trash ? onEnableField : onDisableField;

    return (
      <button className='btn btn-primary' onClick={onClick}>
        {label}
      </button>
    );
  }, [trash, selectedFieldId]);

  const onReset = () => {
    if (selectedFieldId) {
      dispatch(resetCustomField({ fieldId: selectedFieldId }))
        .unwrap()
        .then((data) => {
          form.reset(data);
          
        });
    }
  };

  const onSubmit = form.handleSubmit(async (values) => {
    const action = selectedFieldId ? editCustomField : createCustomField;

    await dispatch(action({ fieldData: { ...values, page: 'projects' } })).unwrap();

    onFormClose();
    onAfterSubmit();
  });

  const modalTitle = isUpdate ? 'Edit Project Field' : 'Add Project Field';

  const onFormClose = () => {
    form.reset({ ...defaultValues });
    onClose();
  };

  const [fieldName] = form.watch(['name']);
  const controlType = form.watch('controlType');

  const isDivider = controlType === 'DIVIDER';

  const autoCodeName = fieldName.toUpperCase().replace(/[^A-Z0-9]/g, '');

  return (
    <Modal isOpen={open} size='lg'>
      <ModalHeader toggle={onFormClose}>{modalTitle}</ModalHeader>
      <ModalBody>
        <div className='container'>
          <FormProvider {...form}>
            <div className='row'>
              <div className='col-12'>
                <FormInput name='name' required label='Name' />
              </div>
            </div>

            <div className='row'>
              <div className='col-7'>
                <FormInput name='shortName' required label='Short Name (Report Headings)' initialValue={fieldName}/>
              </div>

              <div className='col-5'>
                <FormInput name='code' required label='Code' disabled={isUpdate} initialValue={autoCodeName}/>
              </div>
            </div>

            <div className='row'>
              <div className='col-12'>
                <FormInput name='definition' textArea rows={5} label='Definition' />
              </div>
            </div>

            <div className='row'>
              <div className='col-12'>
                <hr />
              </div>
            </div>

            <div className='row'>
              <div className='col-6'>
                <FormMultiselect name='stages' required label='Show in Stages' options={projectStages} />
              </div>

              <div className='col-6'>
                <FormSelect name='section' required label='Section' options={projectSections} />
              </div>
            </div>
            
            {!isDivider && (
            <div className='row'>

              <div className='col'>
                <div className='d-flex gap-4'>
                  <FormCheckbox name='required' label='Required' className='mt-4' />

                  <FormCheckbox name='hideInitially' label='Always show in "View More"' className='mt-4' />
                </div>
              </div>
            </div>)}

            <div className='row'>
              <div className='col-12'>
                <hr />
              </div>
            </div>

            <ControlTypeDefinitionSection isUpdate={isUpdate} />

            <div className='row'>
              {references && (
                <div className='col-12'>
                  <i className='fa fa-outline fa-info-circle me-2'></i>
                  <span>Linked to a specific field</span>
                </div>
              )}
            </div>
          </FormProvider>
        </div>
      </ModalBody>
      <ModalFooter>
        { hasOriginalId &&(
        <button className='btn btn-primary' onClick={onReset}>
          Reset to Default
        </button>)}        <button className='btn btn-primary' color='secondary' onClick={onFormClose}>
          Cancel
        </button>
        {selectedFieldId && <ToggleFieldButton />}
        <button className='btn btn-primary' onClick={onSubmit}>
          Save
        </button>
      </ModalFooter>
    </Modal>
  );
};
