import { CreateAccountData, UpdateAccountData, UpdateAccountProjectSettingsData, UpdateAccountSettingsData } from '@module/accounts';
import apiClient from '../../utils/api-client';

export const getAllAccountsRequest = () =>
  apiClient.post('/parse/functions/adminAccountsData').then(({ data }) => data?.result?.data);

export const createAccountRequest = (data: CreateAccountData) =>
  apiClient.post('/accounts/createAccount', data).then((r) => r.data);

export const updateAccountRequest = (data: UpdateAccountData) =>
  apiClient.post('/accounts/updateAccount', data).then((r) => r.data);

export const spreadAccountAffiliationBetweenContactsRequest = ({
  accountId,
}: {
  accountId: string;
}) =>
  apiClient
    .post('/accounts/spreadAccountAffiliationBetweenContacts', { accountId })
    .then((r) => r.data);

export const getSubscriptionDetails = ({
  accountId,
}: {
  accountId: string;
}) =>
  apiClient.post('/parse/functions/getSubscriptionDetails', { accountId }).then((r) => r.data);

export const getAccountEditInfoRequest = () =>
  apiClient.post('/accounts/getAccountEditInfo').then((r) => r.data);

export const updateAccountSettingsRequest = (data: UpdateAccountSettingsData) =>
  apiClient.post('/accounts/updateAccountSettings', data).then((r) => r.data);

export const updateAccountLogoRequest = (data: { accountId?: string; companyLogo: File }) => {
  const formData = new FormData();
  formData.append('accountId', data.accountId || localStorage.getItem('accountId') as string);
  formData.append('companyLogo', data.companyLogo);

  return apiClient.post('/accounts/updateAccountLogo', formData).then((r) => r.data);
}

export const removeAccountLogoRequest = (data: { accountId?: string }) =>
  apiClient.post('/accounts/removeAccountLogo', data).then((r) => r.data);

export const getAccountProjectSettingsRequest = (data: { accountId?: string }) => {
  return apiClient.post('/accounts/getAccountProjectSettings', data).then((r) => r.data as UpdateAccountProjectSettingsData);
}

export const updateAccountProjectSettingsRequest = (data: { accountId?: string; } & UpdateAccountProjectSettingsData) =>
  apiClient.post('/accounts/updateAccountProjectSettings', data).then((r) => r.data);
