import * as actionTypes from './actionTypes';
import * as API from '../api/changeOrder';
import { callAlertMessage } from '../components/Auth/Auth';

export const getProjectHistoryQuickRequest = () => ({
  type: actionTypes.GET_PROJECT_HISTORY_QUICK_REQUEST,
});

export const getProjectHistoryQuickSuccess = (response) => ({
  type: actionTypes.GET_PROJECT_HISTORY_QUICK_SUCCESS,
  payload: {
    response,
  },
});

export const getProjectHistoryQuickError = (error) => ({
  type: actionTypes.GET_PROJECT_HISTORY_QUICK_ERROR,
  payload: {
    error,
  },
});

export const getProjectHistoryQuickAction = (data) => {
  return (dispatch) => {
    dispatch(getProjectHistoryQuickRequest());
    return API.getProjectHistoryQuick(data)
      .then((response) => {
        dispatch(getProjectHistoryQuickSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          getProjectHistoryQuickError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

export const updateChangeOrderRequest = () => ({
  type: actionTypes.UPDATE_CHANGE_ORDER_REQUEST,
});

export const updateChangeOrderSuccess = (response) => ({
  type: actionTypes.UPDATE_CHANGE_ORDER_SUCCESS,
  payload: {
    response,
  },
});

export const updateChangeOrderError = (error) => ({
  type: actionTypes.UPDATE_CHANGE_ORDER_ERROR,
  payload: {
    error,
  },
});

export const updateChangeOrderAction = (data, props) => {
  return (dispatch) => {
    dispatch(updateChangeOrderRequest());
    return API.updateChangeOrder(data)
      .then((response) => {
        if (response.data && response.data.result) {
          callAlertMessage(
            response.data.result.success === 1 ? 'success' : 'error',
            response.data.result.msg
          );
          dispatch(updateChangeOrderSuccess(response.data));
        } else {
          dispatch(updateChangeOrderError(response.data.result));
        }
      })
      .catch((error) => {
        dispatch(
          updateChangeOrderError(
            error.response !== undefined
              ? error.response.data.error
              : { msg: 'Internet Connection Error' }
          )
        );
      });
  };
};

export const createChangeOrderStatusRequest = () => ({
  type: actionTypes.CREATE_CHANGE_ORDER_STATUS_REQUEST,
});

export const createChangeOrderStatusSuccess = (response) => ({
  type: actionTypes.CREATE_CHANGE_ORDER_STATUS_SUCCESS,
  payload: {
    response,
  },
});

export const createChangeOrderStatusError = (error) => ({
  type: actionTypes.CREATE_CHANGE_ORDER_STATUS_ERROR,
  payload: {
    error,
  },
});

export const createChangeOrderStatusAction = (data) => {
  return (dispatch) => {
    dispatch(createChangeOrderStatusRequest());
    return API.createChangeOrderStatus(data)
      .then((response) => {
        if (response.data.result && response.data.result.success === 1) {
          dispatch(createChangeOrderStatusSuccess(response.data));
        } else {
          dispatch(createChangeOrderStatusError(response.data.result));
        }
      })
      .catch((error) => {
        dispatch(
          createChangeOrderStatusError(
            error.response !== undefined
              ? error.response.data.error
              : { msg: 'Internet Connection Error' }
          )
        );
      });
  };
};

export const hideChangeOrder = () => ({
  type: actionTypes.HIDE_CHANGE_ORDER_REQUEST,
  payload: {},
});

export const showChangeOrder = () => ({
  type: actionTypes.OPEN_CHANGE_ORDER_REQUEST,
  payload: {},
});

// export const deleteChangeOrderRequest = () => ({
//     type: actionTypes.DELETE_CHANGE_ORDER_REQUEST
// })

// export const deleteChangeOrderSuccess = (response) => ({
//     type: actionTypes.DELETE_CHANGE_ORDER_SUCCESS,
//     payload: {
//         response,
//     }
// })

// export const deleteChangeOrderError = (error) => ({
//     type: actionTypes.DELETE_CHANGE_ORDER_ERROR,
//     payload: {
//         error
//     }
// })

// export const deleteChangeOrderAction = (data) => {
//     return dispatch => {
//         dispatch(deleteChangeOrderRequest())
//         return API.deleteChangeOrder(data)
//             .then(response => {
//                 dispatch(deleteChangeOrderSuccess(response.data))
//             })
//             .catch(error => {
//                 dispatch(deleteChangeOrderError(error.response !== undefined ? error.response.data.error : "Internet Connection Error"))
//             })
//     }
// }
