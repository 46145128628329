
export const formatNote = (note_string) => {

  if(!note_string||note_string?.length==0)
    return '';
  const strippedNote = note_string?.replace(/<img[^>"']*((("[^"]*")|('[^']*'))[^"'>]*)*>/gi,"");
  if(!strippedNote||strippedNote?.length==0)
    return '';

  const strippedNoteLength=strippedNote?.length?strippedNote.length:0;
  
  if(strippedNoteLength > 25){
    return strippedNote.substring(0, 25)+'...';
  }else{
    return strippedNote;
  }
    
};
