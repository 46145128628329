import * as actionTypes from '../actions/actionTypes';

const initState = {
  signinUser: {},
  message: '',
  isCreated: false,
  isCreatedError: false,
  isSignInError: false,
  isSignUpSuccess: false,
  allUserList: [],
  fetchRequest: false,
  isChangePwd: false,
  isChangePwdSuccess: false,
  isChangePwdError: false,
  isResetPwd: false,
  isResetPwdSuccess: false,
  isResetPwdError: false,
  isVerifyUser: false,
  isVerifyUserSuccess: false,
  isVerifyUserError: false,
};

export default (state = initState, action = {}) => {
  switch (action.type) {
    case actionTypes.SIGNIN_USER_REQUEST:
      return {
        ...state,
        message: '',
        isCreated: false,
        isCreatedError: false,
      };
    case actionTypes.SIGNIN_USER_SUCCESS:
      return {
        signinUser: action.payload.response,
        isCreated: true,
        isSignUpSuccess: true,
        isCreatedError: false,
        success: action.payload.response.success,
        message: action.payload.response.success
          ? 'User signed in successfully'
          : action.payload.response.msg,
      };
    case actionTypes.SIGNIN_USER_ERROR:
      return {
        ...state,
        isSignInError: true,
        success: action.payload.error.success,
        message: action.payload.error.msg,
      };
    case actionTypes.SIGNUP_USER_REQUEST:
      return {
        ...state,
        message: '',
        isCreated: false,
        isCreatedError: false,
      };
    case actionTypes.SIGNUP_USER_SUCCESS:
      return {
        tempUser: action.payload.response,
        isCreated: true,
        isSignUpSuccess: true,
        isCreatedError: false,
        success: action.payload.response.success,
        message: action.payload.response.success
          ? 'User signed in successfully'
          : action.payload.response.msg,
      };

    case actionTypes.SIGNUP_USER_ERROR:
      return {
        ...state,
        isCreated: false,
        isCreatedError: true,
        success: action.payload.error.success,
        message: action.payload.error.msg,
      };

    case actionTypes.GET_SIGNIN_USER_REQUEST:
      return {
        ...state,
        message: '',
        isCreated: false,
        isCreatedError: false,
      };
    case actionTypes.GET_SIGNIN_USER_SUCCESS:
      return {
        ...state,
        tempUser: action.payload.response,
        message: 'get signed user successfully',
        isCreated: true,
        isCreatedError: false,
      };

    case actionTypes.GET_SIGNIN_USER_ERROR:
      return {
        ...state,
        message: action.payload.response,
        isCreated: false,
        isCreatedError: true,
      };
      case actionTypes.GET_USER_EMAIL_BY_ID_REQUEST:
        return {
          ...state,
          response: [],
          loginEmail:[],
          // message: '',
          alert: {},
          isStatus: false,
          isfetched: false,
        };
      case actionTypes.GET_USER_EMAIL_BY_ID_SUCCESS:
        return {
          ...state,
          response: action.payload.response.result.data,
          loginEmail: action.payload.response.result.data,
          isVerified: action.payload.response.result.isVerified,
          //  response: action.payload.response.result,
        
          // message: '',
          isStatus: true,
          isfetched: true,
          alert: {},
        };
      case actionTypes.GET_USER_EMAIL_BY_ID_ERROR:
        return {
          ...state,
          response: [],
          loginEmail: 'error',
          message: action.payload.error,
          isStatus: false,
          isfetched: true,
          alert: { message: action.payload.error, type: 'alert-danger' },
        }; 
    case actionTypes.UPDATE_CONTACT_STATUS_REQUEST:
      return {
        ...state,
        message: '',
        isCreated: false,
        isCreatedError: false,
      };
    case actionTypes.UPDATE_CONTACT_STATUS_SUCCESS:
      return {
        message: 'Contact status update successfully',
      };

    case actionTypes.UPDATE_CONTACT_STATUS_ERROR:
      return {
        ...state,
        message: action.payload.response,
        isCreated: false,
        isCreatedError: true,
      };
    case actionTypes.GET_USER_CPOST_ROLE_REQUEST:
      return {
        ...state,
        cppRole: {},
        message: '',
      };
    case actionTypes.GET_USER_CPOST_ROLE_SUCCESS:
      return {
        ...state,
        allCppRole: action.payload.response.result.data,
        message: '',
      };
    case actionTypes.GET_USER_CPOST_ROLE_ERROR:
      return {
        ...state,
        message: '',
      };

    case actionTypes.GET_ALL_USER_LIST_REQUEST:
      return {
        ...state,
        allUserList: [],
        message: '',
        fetchRequest: true,
      };
    case actionTypes.GET_ALL_USER_LIST_SUCCESS:
      return {
        ...state,
        allUserList: action.payload.response.data.result.data,
        message: '',
        fetchRequest: false,
      };
    case actionTypes.GET_ALL_USER_LIST_ERROR:
      return {
        ...state,
        message: '',
        fetchRequest: false,
      };
      case actionTypes.EMAIL_VERIFY_REQUEST:
        return {
          ...state,
          emailData: [],
          message: [],
          ticketURL: null,
          emailVerifyStatus:  [],
          isEmailVerify: true,
          isEmailVerifyError: false,
          isEmailVerifySuccess: false,
        
        };
    case actionTypes.EMAIL_VERIFY_SUCCESS:
      return {
        ...state,
        emailData: action.payload.userEmail,
        message: action.payload.msg,
        ticketURL: action.payload.ticketURL,
        emailVerifyStatus:  action.payload?.verifiedResult,
        isEmailVerify: false,
          isEmailVerifyError: false,
          isEmailVerifySuccess: true,
      };
    case actionTypes.EMAIL_VERIFY_ERROR:
      return {
        ...state,
        emailData: [],
        ticketURL: null,
        message: 'Email could not be verified..',
        emailVerifyStatus: false,
        isEmailVerify: false,
          isEmailVerifyError: true,
          isEmailVerifySuccess: false,
      };

      case actionTypes.CHANGE_USER_PASSWORD_REQUEST:
      return {
        ...state,
        message: '',
        success: '',
        isChangePwd: false,
        isChangePwdSuccess: false,
        isChangePwdError: false,
      };
    case actionTypes.CHANGE_USER_PASSWORD_SUCCESS:
      return {
        ...state,
        isChangePwd: action.payload.response.result,
        isChangePwdSuccess: true,
        isChangePwdError: false,
        success: action.payload.response.result.success,
        message: action.payload.response.result.msg,
      };

    case actionTypes.CHANGE_USER_PASSWORD_ERROR:
      return {
        ...state,
        isResetPwd: false,
        isResetPwdSuccess: false,
        isResetPwdError: true,
        success: action.payload.error.success,
        message: action.payload.error.msg,
      };


      case actionTypes.RESET_USER_PASSWORD_REQUEST:
      return {
        ...state,
        message: '',
        success: '',
        isResetPwd: false,
        isResetPwdSuccess: false,
        isResetPwdError: false,
      };
    case actionTypes.RESET_USER_PASSWORD_SUCCESS:
      return {
        ...state,
        isResetPwd: action.payload.response.result,
        isResetPwdSuccess: true,
        isResetPwdError: false,
        success: action.payload.response.result.success,
        message: action.payload.response.result.msg,
      };

    case actionTypes.RESET_USER_PASSWORD_ERROR:
      return {
        ...state,
        isResetPwd: false,
        isResetPwdSuccess: false,
        isResetPwdError: true,
        success: action.payload.error.success,
        message: action.payload.error.msg,
      };

    default:
      return state;
  }
};
