import * as actionTypes from '../actions/actionTypes';

const initState = {
  jobStatus: {},
  allJobStatus: [],
  message: '',
  isCreated: false,
  success: null,
  fetchRequest: false,
  
  allJobStatusList:[],
  copyJobStatusList:[],

  isAddStatusError: false,
  isEditStatusError: false,

  isCreatedCopy:false
};

export default (state = initState, action = {}) => {
  switch (action.type) {
    case actionTypes.LOAD_JOB_STATUS_REQUEST:
      return {
        ...state,
        allJobStatusList: {},
        message: ''
      }
    case actionTypes.LOAD_JOB_STATUS_SUCCESS:
      return {
        allJobStatusList: action.payload.response.result.data,
        isCreated:true,
        success: action.payload.response.result.success,
        message: (action.payload.response.result.success)?action.payload.response.result.msg:"",
      }      

    case actionTypes.LOAD_JOB_STATUS_ERROR:
      return {
        ...state,
        isCreated:false,
        success: action.payload.response.result.success,
        message: action.payload.response.result.msg,
      }
    case actionTypes.COPY_JOB_STATUS_REQUEST:
      return {
        ...state,
        copyJobStatusList: {},
        message: ''
      }
    case actionTypes.COPY_JOB_STATUS_SUCCESS:
      return {
        copyJobStatusList: action.payload.response.result.data,
        isCreated:true,
        isCreatedCopy:true,
        success: action.payload.response.result.success,
        message: action.payload.response.result.msg,
      }      

    case actionTypes.COPY_JOB_STATUS_ERROR:
      return {
        ...state,
        isCreated:false,
        success: action.payload.response.result.success,
        message: action.payload.response.result.msg,
      }
    case actionTypes.CREATE_JOB_STATUS_REQUEST:
      return {
        ...state,
        jobStatus: {},
        message: '',
        isAddStatusError: false,
      };
    case actionTypes.CREATE_JOB_STATUS_SUCCESS:
      return {
        allJobStatus: state.allJobStatus.concat(action.payload.response.result.data),
        isCreated: true,
        isAddStatusError: false,
        success: action.payload.response.result.success,
        message: action.payload.response.result.success
          ? 'Record Added successfully'
          : action.payload.response.result.msg,
      };

    case actionTypes.CREATE_JOB_STATUS_ERROR:
      return {
        ...state,
        isCreated: false,
        success: action.payload.response.result.success,
        message: action.payload.response.result.msg,
        isAddStatusError: true,
      };

    case actionTypes.GET_ALL_JOB_STATUS_REQUEST:
      return {
        ...state,
        jobStatus: {},
        message: '',
        fetchRequest: true,
      };

    case actionTypes.GET_ALL_JOB_STATUS_SUCCESS:
      return {
        ...state,
        allJobStatus: action.payload.response.result.data.data.filter(item => (item['isSystemMaster']===false&&item['trash']===false)),
        allUsedJobStatus: action.payload.response.result.data.data,
        allBiddingJobStatus: action.payload.response.result.data.data.filter(item => (item['isSystemMaster']===false&&item['trash']===false&&item['onDueDateReport']===true)),
        allBidPendingJobStatus: action.payload.response.result.data.data.filter(item => (item['isSystemMaster']===false&&item['trash']===false&&
          (item['isPending']===true||item['jobStatusCodesCode']==='BP'||item['jobStatusCodesCode']==='P1P'||item['jobStatusCodesCode']==='P2P'||item['jobStatusCodesCode']==='P3P'))),
        message: '',
        fetchRequest: false,
      };

    case actionTypes.GET_ALL_JOB_STATUS_ERROR:
      return {
        ...state,
        message: '',
        fetchRequest: false,
      };

      case actionTypes.LIST_IMPORTABLE_JOB_STATUS_REQUEST:
        return {
          ...state,
          importableJobStatusList: {},
          message: '',
          fetchRequest: true,
        };
  
      case actionTypes.LIST_IMPORTABLE_JOB_STATUS_SUCCESS:
        return {
          ...state,
          importableJobStatusList: action.payload.response.result.data,
          message: '',
          fetchRequest: false,
        };
  
      case actionTypes.LIST_IMPORTABLE_JOB_STATUS_ERROR:
        return {
          ...state,
          message: '',
          fetchRequest: false,
        };

    case actionTypes.UPDATE_JOB_STATUS_REQUEST:
      return {
        ...state,
        jobStatus: {},
        message: '',
        isEditStatusError: false,
      };
    case actionTypes.UPDATE_JOB_STATUS_SUCCESS:
      return {
        ...state,
        allJobStatus: action.payload.response.result.contractTypes,
        isCreated: true,
        isEditStatusError: false,
        success: action.payload.response.result.success,
        message: action.payload.response.result.success
          ? 'Record Updated successfully'
          : action.payload.response.result.msg,
      };

    case actionTypes.UPDATE_JOB_STATUS_ERROR:
      return {
        ...state,
        isCreated: false,
        success: action.payload.response.result.success,
        message: action.payload.response.result.msg,
        isEditStatusError: true,
      };

    case actionTypes.DELETE_JOB_STATUS_REQUEST:
      return {
        ...state,
        jobStatus: {},
        message: '',
      };
    case actionTypes.DELETE_JOB_STATUS_SUCCESS:
      return {
        ...state,
        allJobStatus: state.allJobStatus,
        isCreated: true,
        success: action.payload.response.result.success,
        message: action.payload.response.result.success
          ? 'Record Deleted successfully'
          : action.payload.response.result.msg,
      };

    case actionTypes.DELETE_JOB_STATUS_ERROR:
      return {
        ...state,
        isCreated: false,
        success: action.payload.response.result.success,
        message: action.payload.response.result.msg,
      };

    default:
      return state;
  }
};
