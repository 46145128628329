import { postRequest, postRequestWithoutParseData } from './helper';

export const createMultipleCompany = (data) => postRequest(`functions/importCompanies`, data);
export const createMultipleContact = (data) => postRequest(`functions/importContacts`, data);
export const createMultipleProject = (data) => postRequest(`functions/importProject`, data);
export const getSheetProject = (data) => postRequest(`functions/importSheetProject`, data);
export const updateOnboardingStatus = (data) =>
  postRequest(`functions/onboardingStatusUpdate`, data);
export const importContactSocialMedia = (data) => postRequest(`functions/importContactsTemp`, data);
export const getContactSocialMedia = (data) => postRequest(`functions/getImportContactsTemp`, data);
export const editContactSocialMedia = (data) => postRequest(`functions/editContactsTemp`, data);
export const delContactSocialMedia = (data) => postRequest(`functions/deleteContactsTemp`, data);
export const mergeContactSocialMedia = (data) => postRequest(`functions/mergeContactsTemp`, data);
export const outlookContactSocialMedia = (data) => postRequest(`functions/getOutlookUrl`, data);
export const getOutlookContactData = (data) => postRequest(`functions/getOutlookData`, data);
export const skipOnboarding = (data) => postRequestWithoutParseData(`skipUserOnboarding`, data);
