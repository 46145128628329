import * as actionTypes from '../actions/actionTypes';
const initState = {
  contact: {},
  message: '',
  alert: {},
  isfetched: false,
  isfetchedError: false,
  contactSubmission: false,
  isStatus: false,
  isContactCreated: false,
  isContactUpdated: false,
  isContactError: false,
  isContactDeleted: false,
  isVenderCreated: false,
  success: null,
  imageUploadStatus: false,
  imageData: false,
  assignedContact: [],
  assignedContactStatus: false,

  isUpdateContact: false,
  isUpdateContactError: false,

  isContactList: false,
  resendInvite: {
    isLoading: false,
    isSuccess: false,
    successCode: null,
    error: null,
    message: null
  },
  cancelInvite: {
    isLoading: false,
    isSuccess: false,
    successCode: null,
    error: null,
    message: null
  }
};

export default (state = initState, action = {}) => {
  switch (action.type) {
    case actionTypes.GET_EXISTING_CONTACT_REQUEST:
      return {
        ...state,
        contact: {},
        alert: {},
        message: '',
        isfetched: false,
        isfetchedError: false,
        isStatus: false,
      };
    case actionTypes.GET_EXISTING_CONTACT_SUCCESS:
      return {
        ...state,
        contact: action.payload.response.results,
        message: 'Contact Already Existing',
        alert: {},
        isfetched: true,
        isfetchedError: false,
        isStatus: false,
      };

    case actionTypes.GET_EXISTING_CONTACT_ERROR:
      return {
        ...state,
        message: '',
        isfetched: false,
        alert: {},
        isfetchedError: true,
        isStatus: false,
      };
    case actionTypes.RESEND_INVITE_REQUEST:
      return {
        ...state,
        resendInvite: {
          ...state.resendInvite,
          isLoading: true,
          isSuccess: false,
          successCode: null,
          error: null,
          message: null
       }
      };
    case actionTypes.RESEND_INVITE_SUCCESS:
      return {
        ...state,
        resendInvite: {
          ...state.resendInvite,
          isLoading: false,
          isSuccess: true,
          successCode: action.payload.response.result.success,
          error: null,
          message: action.payload.response.result.msg
        }
      };
    case actionTypes.RESEND_INVITE_ERROR:
      return {
        ...state,
        resendInvite: {
          ...state.resendInvite,
          isLoading: false,
          isSuccess: false,
          successCode: 0,
          error: action.payload.error,
          message: action.payload.response.result.msg
        }
      };
    case actionTypes.CANCEL_INVITE_REQUEST:
      return {
        ...state,
        cancelInvite: {
          ...state.cancelInvite,
          isLoading: true,
          isSuccess: false,
          successCode: null,
          error: null,
          message: null
        }
      };
    case actionTypes.CANCEL_INVITE_SUCCESS:
      return {
        ...state,
        cancelInvite: {
          ...state.cancelInvite,
          isLoading: false,
          isSuccess: true,
          successCode: action.payload.response.result.success,
          error: null,
          message: action.payload.response.result.msg
        }
      };
    case actionTypes.CANCEL_INVITE_ERROR:
      return {
        ...state,
        cancelInvite: {
          ...state.cancelInvite,
          isLoading: false,
          isSuccess: false,
          successCode: 0,
          error: action.payload.error,
          message: action.payload.response.result.msg
        }
      };
    case actionTypes.CREATE_CONTACT_REQUEST:
      return {
        ...state,
        buttonActive: true,
        response: [],
        message: '',
        alert: {},
        isStatus: false,
        isContactCreated: false,
      };
    case actionTypes.CREATE_CONTACT_SUCCESS:
      return {
        ...state,
        buttonActive: false,
        addContactData: action.payload.response.result.data,
        isStatus: true,
        isfetched: false,
        isContactCreated: true,
        success: action.payload.response.result.success,
        message: action.payload.response.result.success
          ? 'Record Added successfully'
          : action.payload.response.result.msg,
      };
    case actionTypes.CREATE_CONTACT_ERROR:
      return {
        ...state,
        buttonActive: false,
        response: [],
        isStatus: false,
        alert: { message: action.payload.error, type: 'alert-danger' },
        isContactCreated: false,
        message: action.payload.response.result.msg,
        success: action.payload.response.result.success,
      };
  
    case actionTypes.UPDATE_CONTACT_REQUEST:
      return {
        ...state,
        buttonActive: true,
        response: [],
        message: '',
        isContactCreated: false,
        isUpdateContact: false,
        isUpdateContactError: false,
      };
    case actionTypes.UPDATE_CONTACT_SUCCESS:
      return {
        ...state,
        buttonActive: false,
        response: action.payload.response.result,
        isContactCreated: true,
        success: action.payload.response.result.success,
        message: action.payload.response.result.success
          ? 'Record updated successfully'
          : action.payload.response.result.msg,
        isUpdateContact: true,
        isUpdateContactError: false,
      };
    case actionTypes.UPDATE_CONTACT_ERROR:
      return {
        ...state,
        buttonActive: false,
        response: [],
        isStatus: false,
        isContactCreated: false,
        message: action.payload.response.result.msg,
        success: action.payload.response.result.success,
        isUpdateContact: false,
        isUpdateContactError: true,
      };

    case actionTypes.DELETE_CONTACT_REQUEST:
      return {
        ...state,
        response: [],
        message: '',
        alert: {},
        isStatus: false,
      };
    case actionTypes.DELETE_CONTACT_SUCCESS:
      return {
        ...state,
        response: action.payload.response.result,
        isStatus: true,
        alert: { message: action.payload.response, type: 'alert-success' },
        isContactDeleted: true,
        message: action.payload.response.result.success
          ? 'Record Deleted successfully'
          : action.payload.response.result.msg,
        success: action.payload.response.result.success,
      };
    case actionTypes.DELETE_CONTACT_ERROR:
      return {
        ...state,
        response: [],
        isStatus: false,
        message: action.payload.response.result.msg,
        success: action.payload.response.result.success,
      };
    case actionTypes.MOVE_CONTACT_TO_EMP_REQUEST:
      return {
        ...state,
        response: [],
        message: '',
        alert: {},
        isStatus: false,
      };
    case actionTypes.MOVE_CONTACT_TO_EMP_SUCCESS:
      return {
        ...state,
        response: action.payload.response.results,
        message: '',
        isStatus: true,
      };
    case actionTypes.MOVE_CONTACT_TO_EMP_ERROR:
      return {
        ...state,
        response: [],
        message: action.payload.error,
        isStatus: false,
      };

    case actionTypes.ADD_CONTACT_COMPANY_REQUEST:
      return {
        ...state,
        response: [],
        contactCompanySubmission: false,
      };
    case actionTypes.ADD_CONTACT_COMPANY_SUCCESS:
      return {
        ...state,
        response: action.payload.response.result,
        success: action.payload.response.result.success,
        message: action.payload.response.result.success
          ? 'Record Updated Successfully'
          : action.payload.response.result.msg,
        contactCompanySubmission: true,
      };
    case actionTypes.ADD_CONTACT_COMPANY_ERROR:
      return {
        ...state,
        response: [],
        success: action.payload.response.result.success,
        message: action.payload.response.result.msg,
        contactCompanySubmission: false,
      };

    case actionTypes.SET_CONTACT_AVATAR_REQUEST:
      return {
        ...state,
        imageData: [],
        message: '',
        imageUploadStatus: false,
      };
    case actionTypes.SET_CONTACT_AVATAR_SUCCESS:
      return {
        ...state,
        imageData: action.payload.response.result.msg,
        success: action.payload.response.result.success,
        message: action.payload.response.result.success
          ? 'Record Updated successfully'
          : action.payload.response.result.msg,
        imageUploadStatus: true,
      };
    case actionTypes.SET_CONTACT_AVATAR_ERROR:
      return {
        ...state,
        imageData: [],
        message: '',
        imageUploadStatus: false,
      };

    case actionTypes.GET_ASSIGNED_CONTACT_REQUEST:
      return {
        ...state,
        assignedContact: [],
        message: '',
        assignedContactStatus: false,
      };
    case actionTypes.GET_ASSIGNED_CONTACT_SUCCESS:
      return {
        ...state,
        assignedContact: action.payload.response.result,
        success: action.payload.response.result.success,
        message: action.payload.response.result.success
          ? 'Record listed successfully'
          : action.payload.response.result.msg,
        assignedContactStatus: true,
      };
    case actionTypes.GET_ASSIGNED_CONTACT_ERROR:
      return {
        ...state,
        assignedContact: [],
        message: '',
        assignedContactStatus: false,
      };
      case actionTypes.GET_CONTACT_DETAILS_REQUEST:
      return {
        ...state,
        response: [],
        isContactList: false,
        wasUpdated: false,
        // message: '',
        alert: {},
        isStatus: false,
        isfetched: false,
      };
    case actionTypes.GET_CONTACT_DETAILS_SUCCESS:
      return {
        ...state,
        response: action.payload.response.result.data,
        count: action.payload.response.result.count,
        isContactList: action.payload.response.result,
        isStatus: true,
        isfetched: true,
        alert: {},
      };
    case actionTypes.GET_CONTACT_DETAILS_ERROR:
      return {
        ...state,
        response: [],
        message: action.payload.error,
        isStatus: false,
        isfetched: false,
        isContactList: false,
        alert: { message: action.payload.error, type: 'alert-danger' },
      };

    default:
      return state;
  }
};
