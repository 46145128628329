import apiClient from '../../utils/api-client';

export const createUpdateBidEstimateRequest = (data) =>
  apiClient.post('parse/functions/createUpdateBidEstimate', data);

export const viewAllBidEstimatesRequest = (projectId) =>
  apiClient.post('parse/functions/viewAllBidEstimates', { projectId });

  export const getCurrentBidEstimateRequest = (data) =>
  apiClient.post('parse/functions/getCurrentBidEstimate', data);
