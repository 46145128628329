import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { notification } from 'antd';
import { convertParseResponse, handleUpload, setLoadResultReducer } from '../../utils/import-utils';
import { buildDefaultActionResult } from '../../utils/redux';
import {
  createProjectRequest,
  generateProjectNumberRequest,
  getBuildingTypesRequest,
  getJobScopesRequest,
  getJobStatusesRequest,
  getLastJobNumberRequest,
  getNegotiatingMethodsRequest,
  getNextProjectNumberRequest,
  getOccupancyTypesRequest,
  getTemporaryProjectsRequest,
  getUpdateProjectFormRequest,
  getProjectOrigContractRequest,
  updateProjectOrigContractRequest,
  getProjectWIPUpdatesRequest,
  getSheetProjectRequest,
  importProjectsLoadRequest,
  importProjectsParseRequest,
  promoteLeadToProjectRequest,
  removeTemporaryProjectRequest,
  saveProjectRequest,
  updateProjectRequest,
  getProjectBidRequest,
  createUpdateProjectBidRequest,
  validateProjectNameRequest,
  validateProjectNumberRequest,
  getProjectCustomFieldsValuesFormRequest,
  getAllJobNumbersRequest,
  reassignProjectsRequest,
} from './api';

const getEntryNameByCode = (code) => (code === 'L' ? 'Lead' : 'Project');


export const importProjectsParse = createAsyncThunk(
  'projects/importProjectsParse',
  async (formData, api) => {
    const response = await importProjectsParseRequest(formData);

    api.dispatch(setSessionId());

    return convertParseResponse(response);
  }
);

export const getTemporaryProjects = createAsyncThunk('projects/getTemporaryProjects', async () => {
  return getTemporaryProjectsRequest();
});

export const importProjectsLoad = createAsyncThunk(
  'projects/importProjectsLoad',
  async ([data, rows, selected], api) => {
    const selectedIds = Object.keys(selected);
    let uploadFields = [];
    selectedIds.forEach((key) => {
      if (selected[key]) {
        uploadFields=uploadFields.concat(Object.keys(rows[key]));
      }
    });
    uploadFields = [...new Set(uploadFields)];
    const sessionId = api.getState().rtk.projects.importProjectsParse_sessionId;
    const handleUploadCb = (data) => importProjectsLoadRequest(data, sessionId);

    const [selectedResult, result] = await handleUpload(
      uploadFields,
      rows,
      selected,
      handleUploadCb
    );

    api.dispatch(setLoadResult([selectedResult, data]));

    return result;
  }
);

export const jobStatusesDropdown = createAsyncThunk('projects/jobStatusesDropdown', () =>
  getJobStatusesRequest().then((rows) =>
    rows
      .filter((obj) => !obj.trash && !obj.isSystemMaster)
      .map((row) => ({
        ...row,
        value: row.objectId,
        label: row.jobStatusCodesName,
      }))
  )
);

export const buildingTypesDropdown = createAsyncThunk('projects/buildingTypesDropdown', () =>
  getBuildingTypesRequest().then((rows) =>
    rows.map((row) => ({
      ...row,
      value: row.objectId,
      label: row.buildingTypeName,
    }))
  )
);

export const jobScopesDropdown = createAsyncThunk('projects/jobScopesDropdown', () =>
  getJobScopesRequest().then((rows) =>
    rows.map((row) => ({
      ...row,
      value: row.objectId,
      label: row.jobScopeName,
    }))
  )
);

export const occupancyTypesDropdown = createAsyncThunk('projects/occupancyTypesDropdown', () =>
  getOccupancyTypesRequest().then((rows) =>
    rows.map((row) => ({
      ...row,
      value: row.objectId,
      label: row.occupancyTypeName,
    }))
  )
);

export const negotiatingMethodsDropdown = createAsyncThunk(
  'projects/negotiatingMethodsDropdown',
  () =>
    getNegotiatingMethodsRequest().then((rows) =>
      rows.map((row) => ({
        ...row,
        value: row.objectId,
        label: row.negotiatingMethodName,
      }))
    )
);

export const saveProject = createAsyncThunk('projects/saveProject', (data) =>
  saveProjectRequest(data)
);

export const getLastJobNumber = createAsyncThunk('projects/getLastJobNumberRequest', (data) =>
  getLastJobNumberRequest(data)
);

export const removeTemporaryProject = createAsyncThunk(
  'projects/removeTemporaryProject',
  (projectId) => removeTemporaryProjectRequest(projectId)
);

export const validateProjectName = createAsyncThunk(
  'projects/validateProjectName',
  async (/** @type */ { jobName, projectId }) => {
    const result = await validateProjectNameRequest({ jobName, projectId });

    return result;
  }
);

export const getAllJobNumbers = createAsyncThunk(
  'projects/getAllJobNumbers',
  async (/** @type */ ) => {
    const result = await getAllJobNumbersRequest();

    return result;
  }
);

export const validateProjectNumber = createAsyncThunk(
  'projects/validateProjectNumber',
  async (/** @type */ { projectNumber, projectId }) => {
    const result = await validateProjectNumberRequest({ projectNumber, projectId });

    return result;
  }
);

export const createProject = createAsyncThunk(
  'projects/createProject',
  async (/** @type {any} */ data) => {
    const { isSaveAndAddNext, ...dataForCreateProject } = data;
    const result = await createProjectRequest({
      ...dataForCreateProject,
    });

    const entryName = getEntryNameByCode(result.jobStatusCodesCode);
    const entryNameLC = entryName.toLowerCase();
    const link = isSaveAndAddNext ? (
      <a href={`/${entryNameLC}-details/${result._id}`}>Go to {entryNameLC}</a>
    ) : (
      ''
    );

    notification.success({
      message: (
        <div>
          {entryName} Created &ensp;{link}
        </div>
      ),
      duration: isSaveAndAddNext ? 10 : undefined,
    });

    return result;
  }
);

export const updateProject = createAsyncThunk(
  'projects/updateProject',
  async (/** @type {any} */ data) => {
    const result = await updateProjectRequest({
      ...data,
    });

    const entryName = getEntryNameByCode(result.jobStatusCodesCode);

    notification.success({
      message: `${entryName} Updated`,
    });

    return result;
  }
);

export const getProjectBid = createAsyncThunk(
  'projects/getProjectBid',
  async (/** @type {any} */ data) => {
    const result = await getProjectBidRequest({
      ...data,
    });

    return result;
  }
);

export const createUpdateProjectBid = createAsyncThunk(
  'projects/createUpdateProjectBid',
  async (/** @type {any} */ data) => {
    const result = await createUpdateProjectBidRequest({
      ...data,
    });

    const isNew = !data.bidInternalId;

    if (result._id) {
      notification.success({
        message: `Bid ${isNew ? 'created' : 'updated'}`,
      });
    } else {
      notification.error({
        message: `Bid ${isNew ? 'create' : 'update'} failed`,
      });
    }

    return result;
  }
);

export const generateProjectNumber = createAsyncThunk(
  'projects/generateProjectNumber',
  async (options) => {
    const result = await generateProjectNumberRequest(options);

    return result;
  }
);

export const getNextProjectNumber = createAsyncThunk('projects/getNextProjectNumber', async () => {
  const result = await getNextProjectNumberRequest();

  return result;
});

export const promoteLeadToProject = createAsyncThunk(
  'projects/promoteLeadToProject',
  async (/** @type {any} */ data) => {
    const result = await promoteLeadToProjectRequest({
      ...data,
    });

    notification.success({
      message: 'Successfully Promoted',
    });

    return result;
  }
);

export const getUpdateProjectForm = createAsyncThunk(
  'projects/getUpdateProjectForm',
  async (/** @type {any} */ data) => {
    const result = await getUpdateProjectFormRequest({
      ...data,
    });

    return result;
  }
);

export const getProjectOrigContract = createAsyncThunk(
  'projects/getProjectOrigContract',
  async (/** @type {any} */ data) => {
    const result = await getProjectOrigContractRequest({
      ...data,
    });

    return result;
  }
);

export const updateProjectOrigContract = createAsyncThunk(
  'projects/updateProjectOrigContract',
  async (/** @type {any} */ data) => {
    const result = await updateProjectOrigContractRequest({
      ...data,
    });

    notification.success({
      message: (
        <div>
          Original Contract Updated &ensp;
        </div>
      ),
      duration: 10,
    });

    return result;
  }
);

export const getProjectWIPUpdates = createAsyncThunk(
  'projects/getProjectWIPUpdates',
  async (/** @type {any} */ data) => {
    const result = await getProjectWIPUpdatesRequest({
      ...data,
    });

    return result;
  }
);

export const getSheetProject = createAsyncThunk(
  '/parse/functions/importSheetProject',
  async (/** @type {any} */ data) => {
    const result = await getSheetProjectRequest({
      ...data,
    });

    return result;
  }
);

export const getProjectCustomFieldsValuesForm = createAsyncThunk(
  'projects/getProjectCustomFieldsValuesForm',
  async (/** @type {any} */ data) => {
    const result = await getProjectCustomFieldsValuesFormRequest({
      ...data,
    });

    return result;
  }
);

export const reassignProjects = createAsyncThunk(
  'projects/reassignProjects',
  async (/** @type {any} */ data) => {
    const result = await reassignProjectsRequest({
      ...data,
    });

    return result;
  }
);

const projectsSlice = createSlice({
  name: 'projects',
  initialState: {
    importProjectsParse_sessionId: Date.now(),
    importProjectsParse: {
      data: [],
      loading: false,
      error: null,
    },
    importProjectsLoad: {
      data: [],
      loading: false,
      error: null,
    },
    getTemporaryProjects: {
      data: [],
      loading: false,
      error: null,
    },
    jobStatusesDropdown: {
      data: [],
      loading: false,
      error: null,
    },
    buildingTypesDropdown: {
      data: [],
      loading: false,
      error: null,
    },
    jobScopesDropdown: {
      data: [],
      loading: false,
      error: null,
    },
    occupancyTypesDropdown: {
      data: [],
      loading: false,
      error: null,
    },
    negotiatingMethodsDropdown: {
      data: [],
      loading: false,
      error: null,
    },
    saveProject: {
      data: null,
      loading: false,
      error: null,
    },
    getLastJobNumber: {
      data: null,
      loading: false,
      error: null,
    },
    removeTemporaryProject: {
      data: null,
      loading: false,
      error: null,
    },
    validateProjectName: {
      data: { valid: true, message: '' },
      loading: false,
      error: null,
    },
    validateProjectNumber: {
      data: { valid: true, message: '' },
      loading: false,
      error: null,
    },
    createProject: {
      data: null,
      loading: false,
      error: null,
    },
    getProjectBid: {
      data: null,
      loading: false,
      error: null,
    },
    createUpdateProjectBid: {
      data: null,
      loading: false,
      error: null,
    },
    generateProjectNumber: {
      data: {
        jobNumPrefix: '',
        jobNum: 0,
      },
      loading: false,
      error: null,
    },
    getNextProjectNumber: {
      data: {
        jobNumPrefix: '',
        jobNum: 0,
      },
      loading: false,
      error: null,
    },
    promoteLeadToProject: {
      data: null,
      loading: false,
      error: null,
    },
  },
  reducers: {
    setLoadResult: setLoadResultReducer('importProjectsParse'),
    setSessionId: (state) => {
      state.importProjectsParse_sessionId = Date.now();
    },
  },
  extraReducers: (builder) => {
    buildDefaultActionResult('importProjectsParse', importProjectsParse, builder);
    buildDefaultActionResult('importProjectsLoad', importProjectsLoad, builder);
    buildDefaultActionResult('getTemporaryProjects', getTemporaryProjects, builder);
    buildDefaultActionResult('jobStatusesDropdown', jobStatusesDropdown, builder);
    buildDefaultActionResult('buildingTypesDropdown', buildingTypesDropdown, builder);
    buildDefaultActionResult('jobScopesDropdown', jobScopesDropdown, builder);
    buildDefaultActionResult('occupancyTypesDropdown', occupancyTypesDropdown, builder);
    buildDefaultActionResult('negotiatingMethodsDropdown', negotiatingMethodsDropdown, builder);
    buildDefaultActionResult('saveProject', saveProject, builder);
    buildDefaultActionResult('getLastJobNumber', getLastJobNumber, builder);
    buildDefaultActionResult('removeTemporaryProject', removeTemporaryProject, builder);
    buildDefaultActionResult('validateProjectName', validateProjectName, builder);
    buildDefaultActionResult('validateProjectNumber', validateProjectNumber, builder);
    buildDefaultActionResult('createProject', createProject, builder);
    buildDefaultActionResult('getProjectBid', getProjectBid, builder);
    buildDefaultActionResult('createUpdateProjectBid', createUpdateProjectBid, builder);
    buildDefaultActionResult('generateProjectNumber', generateProjectNumber, builder);
    buildDefaultActionResult('getNextProjectNumber', getNextProjectNumber, builder);
    buildDefaultActionResult('promoteLeadToProject', promoteLeadToProject, builder);
  },
});

export default projectsSlice.reducer;

export const { setLoadResult, setSessionId } = projectsSlice.actions;
