import { useCallback } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useDisabledFieldsContext } from '../../../disabled-fields';
import styles from '../field-actions.module.scss';

type StarProps = {
  name: string;
  index: number;
  isDisabled?: boolean;
};

const Star = ({
  name = '',
  index = 0,
  isDisabled = false,
}: StarProps) => {

  const forceDisabled = useDisabledFieldsContext();

  const { control, watch, trigger, setValue } = useFormContext<any>();
  const { move } = useFieldArray({ control, name });
  const fieldArr = watch(name, []);

  const field = fieldArr[index];
  const isActionDisabled = forceDisabled || isDisabled;

  const onRadioChange = useCallback(async (_: any, index: number) => {
    const ok = await trigger(`${name}.${index}`, { shouldFocus: true });

    if (ok) {
      fieldArr.forEach((_, i) => setValue(`${name}.${i}.isPrimary`, i === index));
      move(index, 0);
    }
  }, [fieldArr]);

  if (!field || fieldArr.length === 1) {
    return null;
  }

  const className = `fa-star fa${field.isPrimary ? 's' : 'r'}
    text-primary
    ${styles.actionButton}
    ${styles.starButton}
    ${field.isPrimary ? styles.active : styles.inactive}
    ${isActionDisabled ? styles.disabled : ''}`;

  return (
    <i data-testid='MarkAsPrimary' role='button' className={className} onClick={(e) => onRadioChange(e, index)} data-title='Mark As Primary'></i>
  );
};

export default Star;
