
import { useState, useEffect, useRef } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import ReactDOM from 'react-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { searchAction } from '../../actions/search.js';
import loaderImage from '../static/images/loading_s.gif';


const GlobalSearchBox = (props) => {
  const [searchTxt, setSearchTxt] = useState('');
  const [searchData, setSearchData] = useState({});
  const [GlobalSearchListOpen, setGlobalSearchListOpen] = useState(false);
  const [Searching, setSearching] = useState(true);
  const [dataLength, setDataLength] = useState(false);
  const [ShowSearchedData, setShowSearchedData] = useState(false);
  const [recentSearchData, setRecentSearchData] = useState([]);
  const [searchWithTypes, setSearchWithTypes] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);

  let searchTimeout;


  const dispatch = useDispatch();
  const { searchData: reduxSearchData, isSearchDataError } = useSelector((state) => ({
    searchData: state.search.searchData,
    isSearchDataError: state.search.isSearchDataError,
  }));

  useEffect(() => {
    setSearchData({});
    setSearching(false);
    setDataLength(true);
  }, []);

  useEffect(() => {
    if (
      reduxSearchData !== searchData &&
      (
        reduxSearchData?.companyData?.length > 0 ||
        reduxSearchData?.contactsData?.length > 0 ||
        reduxSearchData?.projectData?.length > 0 ||
        reduxSearchData?.reportData?.length > 0 ||
        reduxSearchData?.usersData?.length > 0
      )
    ) {
      setSearchData(reduxSearchData);
      setSearching(false);
      setDataLength(true);
    }

    if (
      reduxSearchData !== searchData &&
      (
        reduxSearchData.companyData?.length === 0 ||
        reduxSearchData.contactsData?.length === 0 ||
        reduxSearchData.projectData?.length === 0 ||
        reduxSearchData.reportData?.length === 0 ||
        reduxSearchData.usersData?.length === 0
      )
    ) {
      setSearching(false);
      setDataLength(false);
    }

    if (isSearchDataError) {
      setSearching(false);
      setDataLength(false);
    }
  }, [reduxSearchData, searchData, isSearchDataError]);

  const clickRecentSearch = (search) => {
    clearTimeout(searchTimeout);
    searchTimeout = setTimeout(() => {
      document.getElementById('searchTxt').value = search;
      setSearchTxt(search);
      const data = {
        accountId: localStorage.getItem('accountId'),
        userId: localStorage.getItem('userId'),
        keyword: search,
        inSearchLimit: 5,
        type: selectedTypes.length > 0 ? selectedTypes : ['all'],
      };
      dispatch(searchAction(data));
      if (search !== '') {
        setGlobalSearchListOpen(true);
        setShowSearchedData(false);
      } else {
        setGlobalSearchListOpen(false);
        setShowSearchedData(true);
      }
    }, 1000);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      const domNode = ReactDOM.findDOMNode(this);
      if (!domNode || !domNode.contains(event.target)) {
        setGlobalSearchListOpen(false);
        setShowSearchedData(false);
      }
    };

    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const showSearchHistory = () => {
    setShowSearchedData(true);
    setGlobalSearchListOpen(false);
  };

  const handleChange = (e) => {
    e.preventDefault();
    const searchTxt = e.target.value;

    if (searchTxt === '') {
      setGlobalSearchListOpen(false);
      setShowSearchedData(false);
    }

    setSearchTxt(searchTxt);
  };

  const onSearch = (e, value) => {
    e.preventDefault();

    setSearching(true);
    setSearchData([]);

    if (e.key === 'Enter' && e.keyCode === 13) {
      const data = {
        accountId: localStorage.getItem('accountId'),
        userId: localStorage.getItem('userId'),
        keyword: value,
        type: selectedTypes.length > 0 ? selectedTypes : ['all'],
      };
      dispatch(searchAction(data));
      if (value !== '') {
        setGlobalSearchListOpen(true);
        setShowSearchedData(false);
      } else {
        setGlobalSearchListOpen(false);
        setShowSearchedData(true);
      }
    } else {
      setGlobalSearchListOpen(false);
      setShowSearchedData(false);
    }
  };

  const onClickData = () => {
    setGlobalSearchListOpen(false);
    setShowSearchedData(false);
  };

  const renderContactData = (data) => {
    const contactData = data ?? [];
    const uniqueContactIds = [...new Set(contactData.map((contact) => contact.contactId))];
    return (
      <>{uniqueContactIds.map((contactId) => {
        const contacts = contactData.filter((contact) => contact.contactId === contactId);
        const fullName = contacts[0].displayName;
        const companyNames = contacts.filter(contact=>contact.companyId).map((contact) => contact.companyName);
        const emails = [... new Set(contacts.map((contact) => contact.emailArr.filter(Boolean).map(email=>email.email)).flat())];
        return (
          <li key={contactId}>
            <Link to={`/contact-details/${contactId}`} onClick={onClickData}>
              {fullName} {companyNames.length > 0 ? `- (${companyNames.join(', ')})` : ''}
              <br/><t/>
              
            </Link>
          </li>
        );
      })}</>
    );
  };

  const renderProjectData = (data) => {
    const projectData = data;
    return (
      <>{projectData.map((project) => (
        <li key={project.id}>
          <Link to={`/project-details/${project.objectId}`} onClick={onClickData}>
            {project.jobNumFormatted && project.jobNumFormatted !== 'null' && project.jobNumFormatted !=='N/A' ? (project.jobNumFormatted + ' ') : '(Lead) '}
            {project.jobName}
          </Link>
        </li>
      ))}</>
    );
  };

  const renderCompanyData = (data) => {
    const companyData = data;
    return (
      <>{companyData.map((company) => (
        <li key={company.id}>
          <Link to={`/company-details/${company.companyId}`} onClick={onClickData}>
            {company.displayName}
          </Link>
        </li>
      ))}</>
    );
  };

  const renderReportData = (data) => {
    const reportData = data;
    return (
      <li key={reportData.id}>
        <Link to={`/reports/${reportData.id}`} onClick={onClickData}>
          {reportData.name}
        </Link>
      </li>
    );
  }


  return (
    <div className='searchB'>
      <div className='ms-auto'>
        <input
          type='text'
          className='fieldHS'
          name='searchTxt'
          id='searchTxt'
          //  value={searchTxt} onChange={(e) => this.onSearch(e)}  placeholder="Search..." /> */}
          value={searchTxt}
          onKeyUp={(e) => onSearch(e, searchTxt)}
          onChange={(e) => handleChange(e)}
          placeholder='Search CommandPost...'
        />
      </div>

      {/*<button type="button" className="btn btn-danger search-btn" ><i className="fa fa-search"></i></button> */}
      <div
        className='search_list'
        style={{
          display: GlobalSearchListOpen ? 'block' : 'none',
        }}
      >
        {Searching ? (
          <img
            className='header-ajax-loader ajax-loader'
            src={loaderImage}
            width='100'
            height='100'
          />
        ) : (
          ''
        )}

        {!Searching &&
          !dataLength &&
          !searchData.projectData &&
          !searchData.contactsData &&
          !searchData.companyData &&
          !searchData.reportData && (
            <p className='no-data-found'>No results found!</p>
          )}
        <Scrollbars style={{ height: 400 }}>
          <ul className='search_list_data'>
            {/*<li  style={{display: Searching ? 'block' : 'none' }}><a>Searching...</a></li>*/}

            {searchData?.projectData?.length > 0 && (
              <li>
                <span>Projects</span>
                <ul className='search_dt_type'>{renderProjectData(searchData.projectData)}</ul>
              </li>
            )}

            {searchData?.contactsData?.length > 0 && (
              <li>
                <span>Contacts</span>
                <ul className='search_dt_type'>{renderContactData(searchData.contactsData)}</ul>
              </li>
            )}

            {searchData?.companyData?.length > 0 && (
              <li>
                <span>Companies</span>
                <ul className='search_dt_type'>{renderCompanyData(searchData.companyData)}</ul>
              </li>
            )}

            {searchData?.reportData?.length > 0 && (
              <li>
                <span>Reports</span>
                <ul className='search_dt_type'>{renderReportData(searchData.reportData)}</ul>
              </li>
            )}
          </ul>
        </Scrollbars>
      </div>
      <div
        className='search_list'
        style={{
          display: ShowSearchedData ? 'block' : 'none',
        }}
      >
        <Scrollbars style={{ height: 200 }}>
          <ul className='search_list_data'>
            <li className='msg'>
              Search for projects by name or number, contacts by name, email or job,
              companies, reports and help.
            </li>
            <li></li>
            <li></li>
            {recentSearchData.length === 0 ? <li className='recentsearch'>No Recent Searches</li> :
              <>
                <li className='recentsearch'>Recent Searches:</li>
                <li>{recentSearchData}</li></>}
          </ul>
        </Scrollbars>
      </div>
    </div>

  );
}
export default withRouter(GlobalSearchBox);
