import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { buildDefaultActionResult } from '../../utils/redux';
import { getEventByIdRequest } from './api';
import { importEventsParseRequest } from './api';
import { convertParseResponse} from '../../utils/import-utils';

export const getEventById = createAsyncThunk(
  'schedule/getEventById',
  async ({ id }: { id: string }) => {
    const result = await getEventByIdRequest({ id });

    return result;
  }
);

export const importEventsParse = createAsyncThunk(
  'companies/importEventsParse',
  async (formData) => {
    const response = await importEventsParseRequest(formData);

    return convertParseResponse(response);
  }
);


const scheduleSlice = createSlice({
  name: 'schedule',
  reducers: {},
  initialState: {},
  extraReducers: (builder) => {
    buildDefaultActionResult('getEventById', getEventById, builder);
  },
});

export default scheduleSlice.reducer;
