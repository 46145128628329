import { memo, useCallback } from 'react';
import { CustomFieldControl } from '../../shared/custom-fields-controls/form-controls/custom-field-control';
import { HorizontalLayout } from '../../shared/form-controls/control-layouts/horizontal-layout';

export const ContactCustomFields = memo(({ fields = [], index, name }: any) => {
  const renderList = useCallback(
    (fields: any[] = []) =>
      fields.map((field: any) => {
        const { _id, required } = field;
        const CustomizedLayout = (props: any) => (
          <HorizontalLayout {...props} labelWidth={3} controlWidth={8} />
        );

        return (
          <CustomFieldControl
            Layout={CustomizedLayout}
            key={`${name}.${index}.${_id}`}
            required={required}
            fieldDefinition={field}
            prefix={`${name}.${index}`}
          />
        );
      }),
    [index]
  );

  return <>{renderList(fields)}</>;
});
