import * as actionTypes from '../actions/actionTypes';

const initState = {
  response: [],
  message: '',
  isfetched: false,
  isUpdateStatus: false,
  success: null,
};

export default (state = initState, action = {}) => {
  switch (action.type) {
    case actionTypes.UPDATE_ACCOUNT_REQUEST:
      return {
        ...state,
        buttonActive: true,
        response: [],
        message: '',
        isfetched: false,
        isUpdateStatus: false,
      };
    case actionTypes.UPDATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        buttonActive: false,
        response: action.payload.response.result.data,
        success: action.payload.response.result.success,
        message: action.payload.response.result.success
          ? 'Record updated successfully'
          : action.payload.response.result.msg,
        isfetched: true,
        isUpdateStatus: true,
      };

    case actionTypes.UPDATE_ACCOUNT_ERROR:
      return {
        ...state,
        buttonActive: false,
        isfetched: false,
        isUpdateStatus: false,
        success: action.payload.response.result.success,
        message: action.payload.response.result.msg,
      };

    case actionTypes.GET_TERMS_CONDITION_REQUEST:
      return {
        ...state,
        contact: {},
        alert: {},
        message: '',
        isfetched: false,
        isfetchedError: false,
        isStatus: false,
      };
    case actionTypes.GET_TERMS_CONDITION_SUCCESS:
      return {
        ...state,
        termsResponse: action.payload.response.result.data,
        message: 'Terms found',
        alert: {},
        isfetched: true,
        isfetchedError: false,
        isStatus: false,
      };

    case actionTypes.GET_TERMS_CONDITION_ERROR:
      return {
        ...state,
        message: '',
        isfetched: false,
        alert: {},
        isfetchedError: true,
        isStatus: false,
      };

    default:
      return state;
  }
};
