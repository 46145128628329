import { Checkbox } from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import { useCallback } from 'react';

export const columnDescriptor = {
  Header: ({ state }) => {
    return (
      <input
        type='checkbox'
        checked={state.getSelectedAll() && state.getSelectedSomething()}
        onChange={state.onSelectAllClick}
      />
    );
  },
  accessor: '_isAvailable',
  width: 20,
  Cell: ({ row, state }) => {
    if (!row.values._isAvailable) return null;

    return (
      <input
        type='checkbox'
        checked={!!state.selected[row.index]}
        onChange={(e) => {
          e.persist();
          state.setSelected((prev) => ({ ...prev, [row.index]: e.target.checked }));
        }}
        onClick={(e) => e.stopPropagation()}
      />
    );
  },
};

/**
 * @param { { data: { _isAvailable?: boolean, _rowIndex: number }[] } } props
 */
const useRowSelection = ({ data = [] }) => {
  const [selected, setSelected] = useState({});

  const getAvailableIndexes = useCallback(
    () => data.filter((v) => v._isAvailable).map((r) => r._rowIndex),
    [data]
  );
  const getAvailableCount = useCallback(() => data.filter((v) => v._isAvailable).length, [data]);
  const getSelectedAll = useCallback(
    () => Object.values(selected).filter((s) => s).length === getAvailableCount(),
    [selected, data]
  );
  const getSelectedSomething = useCallback(
    () => Object.values(selected).filter((s) => s).length > 0,
    [selected, data]
  );

  const onSelectAllClick = useCallback(() => {
    const isAllSelected = getSelectedAll();

    if (isAllSelected) return setSelected({});

    setSelected(
      getAvailableIndexes().reduce((prev, curr) => Object.assign(prev, { [curr]: true }), {})
    );
  }, [getSelectedAll, data]);

  return {
    selected,
    setSelected,
    getAvailableIndexes,
    getAvailableCount,
    getSelectedAll,
    onSelectAllClick,
    getSelectedSomething,
  };
};

export default useRowSelection;
