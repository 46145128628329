import * as actionTypes from './actionTypes';
import * as API from '../api/profile';

//get all user details
export const getExistingProfileRequest = () => ({
  type: actionTypes.GET_EXISTING_PROFILE_REQUEST,
});

export const getExistingProfileSuccess = (response) => ({
  type: actionTypes.GET_EXISTING_PROFILE_SUCCESS,
  payload: {
    response,
  },
});

export const getExistingProfileError = (error) => ({
  type: actionTypes.GET_EXISTING_PROFILE_ERROR,
  payload: {
    error,
  },
});

export const getExistingProfileAction = (data) => {
  return (dispatch) => {
    dispatch(getExistingProfileRequest());
    return API.getExistingProfile(data)
      .then((response) => {
        if (response.data.result.success === 1) {
          localStorage.setItem('userInfo', JSON.stringify(response.data.result.data));
        }
        dispatch(getExistingProfileSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          getExistingProfileError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

//get Edit user details
export const updateProfileRequest = () => ({
  type: actionTypes.UPDATE_PROFILE_REQUEST,
});

export const updateProfileSuccess = (response) => ({
  type: actionTypes.UPDATE_PROFILE_SUCCESS,
  payload: {
    response,
  },
});

export const updateProfileError = (error) => ({
  type: actionTypes.UPDATE_PROFILE_ERROR,
  payload: {
    error,
  },
});

export const updateProfileAction = (data) => {
  return (dispatch) => {
    dispatch(updateProfileRequest());
    return API.editProfile(data)
      .then((response) => {
        dispatch(updateProfileSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          updateProfileError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};
