import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import type { RuleGroupType } from 'react-querybuilder';
import ReportQueryBuilderModal from './report-query-modal';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import { Checkbox } from 'antd';

import './styles.scss';
import _ from 'lodash';
//import { QueryBuilderBootstrap } from '@react-querybuilder/bootstrap';

interface HighlightRulesModalProps {
  open: boolean;
  highlightRules: HighlightRuleGroup[];
  fields: any[];
  setHighlightRules: (value: HighlightRuleGroup[]) => void;
  onClose: () => void;
}

type HighlightRuleGroup = {
  highlightColor: { r: number; g: number; b: number; a: number };
  highlightTextColor?: { r: number; g: number; b: number; a: number };
  enabled: boolean;
  rules: RuleGroupType;
  name: string;
};

const HighlightRulesModal: React.FC<HighlightRulesModalProps> = ({
  open,
  onClose,
  fields,
  setHighlightRules,
  highlightRules,
}) => {
  const [queryBuilderModalOpen, setQueryBuilderModalOpen] = useState(false);
  const [currentRule, setCurrentRule] = useState<HighlightRuleGroup | undefined>(undefined);
  const [currentRuleIndex, setCurrentRuleIndex] = useState<number | undefined>(undefined);

  const [currentHighlightRules, setCurrentHighlightRules] =
    useState<HighlightRuleGroup[]>(highlightRules);

  useEffect(() => {
    setCurrentHighlightRules(highlightRules);
  }, [highlightRules, open]);

  const onSave = () => {
    setHighlightRules(currentHighlightRules);
    onClose();
  };

  const handleQueryBuilderModalToggle = () => {
    setQueryBuilderModalOpen(!queryBuilderModalOpen);
  };

  const onDragEnd = useCallback(
    (result: any) => {
      if (!result.destination) return;

      const items = _.cloneDeep(currentHighlightRules);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);

      setCurrentHighlightRules(items);
    },
    [currentHighlightRules]
  );

  const selectedFieldsList = useMemo(() => {
    const list = currentHighlightRules.map((rule: any, index) => {
      return (
        <Draggable key={rule.rules.id} draggableId={rule.rules.id} index={index}>
          {(provided: any) => (
            <div
              className='row'
              key={rule.field}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              ref={provided.innerRef}
            >
              <div className='col-1'>
                <span className='d-flex align-items-center justify-content-center mt-1'>
                  <i className='fa fa-bars' aria-hidden='true' role='button' />
                </span>
              </div>
              <div className='col'>
                <Checkbox
                  id={'checkbox'}
                  checked={rule.enabled}
                  name={'Enabled'}
                  onChange={(e) => {
                    const newRules = [...currentHighlightRules];
                    newRules[index].enabled = e.target.checked;
                    setCurrentHighlightRules(newRules);
                  }}
                />
              </div>

              <span className='col'>{rule.name}</span>
              <div className='col'>
                <span
                  style={{
                    width: '20px',
                    backgroundColor: `rgba(${rule.highlightColor.r}, ${rule.highlightColor.g}, ${rule.highlightColor.b}, ${rule.highlightColor.a})`,
                  }}
                >
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </div>
              <div className='col'>
                <button
                  className='btn btn-link'
                  onClick={() => {
                    setCurrentRuleIndex(index);
                    setCurrentRule(currentHighlightRules[index]);
                    setQueryBuilderModalOpen(true);
                  }}
                >
                  Edit
                </button>
              </div>
            </div>
          )}
        </Draggable>
      );
    });

    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId='droppable'>
          {(provided: any) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {list}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }, [currentHighlightRules, onDragEnd]);

  return (
    <Modal isOpen={open} toggle={onClose} backdrop='static' size='lg'>
      <ModalHeader>Highlight Rules</ModalHeader>
      <ModalBody>
        <div className='highlightTable'>
          <div className='row'>
            <div className='col-1'></div>
            <div className='col'>
              <b>Enabled</b>
            </div>
            <div className='col'>
              <b>Name</b>
            </div>
            <div className='col'>
              <b>Color</b>
            </div>
            <div className='col'></div>
          </div>
          {currentHighlightRules?.length > 0 && selectedFieldsList}
        </div>

        <div className='d-flex justify-content-end mt-2'></div>

        <ReportQueryBuilderModal
          open={queryBuilderModalOpen}
          fields={fields}
          deleteHighlightRule={() => {
            const newRules = [...currentHighlightRules];
            newRules.splice(currentRuleIndex as number, 1);
            setCurrentHighlightRules(newRules);
            setQueryBuilderModalOpen(false);
          }}
          filterRules={currentRule?.rules as unknown as RuleGroupType}
          onClose={handleQueryBuilderModalToggle}
          isHighlightRule={true}
          highlightRuleName={currentRule?.name}
          highlightRuleColor={currentRule?.highlightColor}
          highlightRuleTextColor={currentRule?.highlightTextColor}
          setHighlightRule={(newHighlightRule) => {
            const newRules = [...currentHighlightRules];
            if (!newRules[currentRuleIndex as number]) {
              newRules.push(newHighlightRule);
            } else {
              newRules[currentRuleIndex as number] = newHighlightRule;
            }
            setCurrentHighlightRules(newRules);
          }}
        />
      </ModalBody>
      <ModalFooter>
        <button className='btn btn-secondary alignLeft' onClick={onClose}>
          Close
        </button>
        <button
          className='btn btn-primary ms-end'
          onClick={() => {
            const newRule = {
              highlightColor: { r: 255, g: 255, b: 255, a: 0 },
              highlightTextColor: { r: 0, g: 0, b: 0, a: 1 },
              enabled: true,
              rules: { combinator: 'and', rules: [] },
              name: 'New Rule',
            };
            setCurrentRuleIndex(currentHighlightRules.length);
            setCurrentRule(newRule);

            setQueryBuilderModalOpen(true);
          }}
        >
          Add New Rule
        </button>
        <button className='btn btn-secondary alignLeft' onClick={onSave}>
          Save
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default HighlightRulesModal;
