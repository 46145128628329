import React, { useState, useEffect } from 'react';
import 'quill/dist/quill.snow.css';
import { useQuill } from 'react-quilljs';

import {Mention, MentionBlot} from "quill-mention";


export const TextEditorField = ({ fieldData, title, toolbarEnabled = true, autoFocus=true, value, onChange, name }) => {

  // if fieldData is not passed, create it from value, onChange and name
  if(!fieldData && onChange){
    fieldData = {
      value: value,
      onChange: onChange,
      name: name
    }
  }

  const [text, setText] = useState(fieldData?.value??'');
  const [textEntered, setTextEntered] = useState(false);
  const [modulesLoaded, setModulesLoaded] = useState(false);
  const [mentionModule, setMentionModule] = useState({
    allowedChars: /^[A-Za-z\s]*$/,
    mentionDenotationChars: ['@', '#'],
    listValues: [],
    isolateCharacter: true,
    source: function (searchTerm, renderList, mentionChar) {
      let values;

      if (mentionChar === '@') {
        values = this.options.listValues;
      } else {
        values = this.options.listValues;
      }

      if (searchTerm?.length === 0) {
        const matches = [];
        const maxMatches = 10;
        for (let i = 0; i < values.length; i++) {
          matches.push(values[i]);
          if (matches.length > maxMatches) {
            break;
          }
        }

        renderList(matches, searchTerm);
      } else {
        const matches = [];
        const maxMatches = 10;
        const searchTermLower = searchTerm.toLowerCase();

        if (values) {
          for (let i = 0; i < values.length; i++) {
            if (values[i].value && ~values[i].value.toLowerCase().indexOf(searchTermLower)) {
              matches.push(values[i]);
              if (matches.length > maxMatches) {
                break;
              }
            }
          }
          renderList(matches, searchTerm);
        }
      }
    },
  });

  useEffect(() => {
    if(toolbarEnabled){
      showToolbar();
    }else{
      hideToolbar();
    }
  },[toolbarEnabled]);

  const [quillModules, setQuillModules] = useState({
    toolbar: [
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote',],
      [ { 'color': [] }, { 'background': [] }, { list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
      ['link'],
      ['clean'],
    ],
  });

  function showToolbar() {
    var toolbar = document.querySelector('.ql-toolbar');
    if(toolbar)
      toolbar.style.display = 'block';
  }
  
  function hideToolbar() {
    var toolbar = document.querySelector('.ql-toolbar');
    if(toolbar)
      toolbar.style.display = 'none';
  }

  useEffect(() => {
    if (mentionModule?.listValues?.length > 0) {
      setQuillModules((prevModule) => ({
        ...prevModule,
        mention: mentionModule,
      }));
      setModulesLoaded(true);
    }
  }, [mentionModule]);

  useEffect(() => {
    if(!(fieldData?.mentionList)){
      setModulesLoaded(true);
    }
  },[]);

  useEffect(() => {
    if (fieldData?.mentionList?.length > 0) {
      setMentionModule((prevModule) => ({
        ...prevModule,
        listValues: fieldData.mentionList,
      }));
    }
  }, [fieldData?.mentionList]);

  const quillFormats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'color',
    'background',
    'list',
    'indent',
    'link',
    'mention',
  ];

  const styleData = { color: 'red' };

  const theme = 'snow';

  const { quill, quillRef, Quill } = useQuill({
    theme: theme,
    modules: quillModules,
    formats: quillFormats,
  });

  if(Quill && !quill){
    Quill.register('modules/mention', Mention);
    Quill.register('formats/mention', MentionBlot);
    Quill.import('formats/link').PROTOCOL_WHITELIST = ['http', 'https', 'mailto', 'tel', 'smb', 'file', 'ftp'];

  }

  const [focused, setFocused] = useState(false);

  React.useEffect(() => {
    if (quill) {

      if(!focused && autoFocus){
        quill.focus();
        setFocused(true);
      }
      quill.on('text-change', (delta, oldDelta, source) => {
        //console.log('Text change!');
        //console.log(quill.getText()); // Get text only
        //console.log(quill.getContents()); // Get delta contents
        //console.log(quill.root.innerHTML); // Get innerHTML using quill
        //console.log(quillRef.current.firstChild.innerHTML); // Get innerHTML using quillRef
        //setText(quill.root.innerHTML);

        setTextEntered(true);

        if(onChange){
          const e = {
            target: {
              name: fieldData.name,
              value: quill.root.innerHTML
            },
            preventDefault: () => {}
          }
          onChange(e);
        }else{
          fieldData.onChange(quill.root.innerHTML);
        }
        
        
      });
    }
  }, [quill]);

  useEffect(() => {
    if(!textEntered && fieldData?.value?.length>0){
      setText(fieldData?.value??'');
    }
  },[fieldData?.value]);

  useEffect(() => {
    if (quill && text?.length>0 && !textEntered) {
      quill.root.innerHTML = text;
    }
  }, [text,quill]);

  return (
    <div className='row'>
      {modulesLoaded && (
        <div className='col-md-12'>
          <div className='form-group'>
            <label>{title}</label>
            <div ref={quillRef} />
            <span style={styleData}>{fieldData?.errorMsg}</span>
          </div>
        </div>
      )}
    </div>
  );
};
