import { useState, useEffect, useMemo } from 'react';


import {
  getNotificationPreferencesAction,
  setNotificationPreferencesAction,
} from '../../../actions/notificationPreferences.js';

import { selectAllNotificationTypes } from '../../../selectors/notificationPreferences';
import { EditableTable } from '../../../shared/editable-table/editable-table.jsx';
import { useForm, FormProvider } from 'react-hook-form';
import _, { set } from 'lodash';
import { useAppDispatch } from '../../../shared/hooks/use-app-dispatch';
import { useAppSelector } from '../../../shared/hooks/use-app-selector';
import FormCheckbox from '../../../shared/form-controls/form-checkbox/form-checkbox';
import formattedPhone from '../../../shared/formatted-phone/index.js';
import {getUserInfo} from '../../../modules/users';

function NotificationPreferences() {
  const dispatch = useAppDispatch();

  const { notificationPreferences, isFetched } = useAppSelector(selectAllNotificationTypes);

  const [userId] = useState(localStorage.getItem('userId'));
  const [accountId] = useState(localStorage.getItem('accountId'));

  const [userInfo, setUserInfo] = useState({});

  useEffect(() => {
    let params = { accountId: accountId, userId: userId };
    fetchUserInfo();
    dispatch(getNotificationPreferencesAction(params));
    
  }, [accountId, userId]);


  const fetchUserInfo = async () => {
    await dispatch(getUserInfo(userId)).unwrap().then(
      (response) => {
        setUserInfo(response);
      }
    )
  }

  const form = useForm();

  useEffect(() => {

    if(!(notificationPreferences?.length>0)) return;
    const values = notificationPreferences.reduce((acc, item) => {
      acc[item.objectId] = {};

      acc[item.objectId].emailEnabled = item.emailEnabled;
      acc[item.objectId].smsEnabled = item.smsEnabled;

      return acc;
    }, {});

    form.reset(values);
  }, [notificationPreferences.length]);

  const handleSubmit = form.handleSubmit(async (data) => {
    const result = _.cloneDeep(notificationPreferences);

    Object.entries(data).forEach(([key, value]) => {
      const id = key;

      const modifiedItem = result.find((item) => item.objectId === id);

      modifiedItem.emailEnabled = value.emailEnabled;
      modifiedItem.smsEnabled = value.smsEnabled;
    });

    await dispatch(
      setNotificationPreferencesAction({
        notificationTypes: result,
        userId: userId,
        accountId: accountId,
      })
    );
  });

  const columns = useMemo(
    () => [
      {
        Header: 'Notification Name',
        accessor: 'notificationName',
        width: 400,
      },
      {
        Header: 'Email Enabled',
        headerClassName: 'text-center',
        accessor: 'emailEnabled',
        Cell: ({ row }) => {
          return (
            <FormCheckbox
              className='mt-2 ms-auto me-auto'
              name={`${row.original.objectId}.emailEnabled`}
            />
          );
        },
      },
      {
        Header: 'SMS Enabled',
        headerClassName: 'text-center',
        accessor: 'smsEnabled',
        Cell: ({ row }) => {
          return (
            <FormCheckbox
              className='mt-2 ms-auto me-auto'
              name={`${row.original.objectId}.smsEnabled`}
            />
          );
        },
      },
    ],
    []
  );

  return (userInfo?.userEmail) ? (
    <>
      <div className='row'>
        <div className='formBox d-flex align-items-center justify-content-between'>
          <h2 className='heading2'>Notification Preferences</h2>

          <button className='btn btn-primary' type='primary' onClick={handleSubmit}>
            Save
          </button>
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <p>
            <b>NOTE:</b> Notifications will be sent via email <b>{userInfo.userEmail}</b>{' '}
            {userInfo.userPhone && userInfo.userPhoneActivation ? (
              <>
                via sms to <b>{userInfo?.userPhone.fullPhone}</b>
              </>
            ) : userInfo.userPhone?.fullPhone?.length>0 ? (
              <>
                via sms to <b>{userInfo?.userPhone.fullPhone}</b> <i>(pending verification)</i>
              </>
            ) : (
              ''
            )}
          </p>
          <p></p>
        </div>
      </div>
      <div className='row'>
        <FormProvider {...form}>
          <EditableTable columns={columns} loading={!isFetched} data={notificationPreferences} />
        </FormProvider>
      </div>
    </>): (
    <div className='row'>
      <div className='formBox d-flex align-items-center justify-content-between'>
        <h2 className='heading2'>Notification Preferences</h2>

        <button className='btn btn-primary' type='primary' onClick={handleSubmit}>
          Save
        </button>
      </div>
    </div>
  );
}

export default NotificationPreferences;
