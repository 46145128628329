import { memo, useCallback } from 'react';
import { CustomFieldControl } from '../../shared/custom-fields-controls/form-controls/custom-field-control';
import { HorizontalLayout } from '../../shared/form-controls/control-layouts/horizontal-layout';

export const CompanyCustomFields = memo(
  ({ fields = [] }: any) => {

    const renderList = useCallback(
      (fields: any[] = []) =>
        fields.map((field: any) => {
          const { _id, required } = field;
          const CustomizedLayout = (props: any) => (
            <HorizontalLayout {...props} labelWidth={3} controlWidth={8} />
          );

          return (
            <CustomFieldControl
              Layout={CustomizedLayout}
              key={_id}
              required={required}
              fieldDefinition={field}
            />
          );
        }),
      []
    );

    return <>{renderList(fields)}</>;
  }
);
