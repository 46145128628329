import { useMemo } from 'react';
import { useRef } from 'react';
import { useTable } from 'react-table';
import { VariableSizeGrid as Grid } from 'react-window';
import styled from '@emotion/styled';
import { useEffect } from 'react';
import { useState } from 'react';
import { getScrollbarWidth } from '../../../utils/get-scrollbar-width';

const MatrixCell = styled.div(()  => ({
  display: 'flex',
  alignItems: 'center',
  fontSize: 12,
  paddingLeft: 4,
  overflowX: 'visible',
  lineHeight: 1.2,
  borderLeft: '1px solid #00000033',
  borderBottom: '1px solid #00000033',

  '&:hover': {
    cursor: 'pointer'
  }
}));

const DataGridMatrix = ({
  columns,
  data,
  useControlledState,
  onRowClick = () => {}
}) => {
  const { getTableProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
    useControlledState,
  });

  const wrapperRef = useRef(null);
  const headerRef = useRef(null);

  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const [hoveredRowIndex, setHoveredRowIndex] = useState(null);
  const itemData = useMemo(
    () => ({
      hoveredRowIndex,
      setHoveredRowIndex
    }),
    [hoveredRowIndex]
  );

  useEffect(() => {
    if (wrapperRef.current) {
      const rect = wrapperRef.current.getBoundingClientRect();
      setHeight(window.innerHeight - rect.top);
      setWidth(rect.width);
    }
  }, [wrapperRef.current, data.length]);

  useEffect(() => {
    const cb = (event) => {
      if (wrapperRef.current) {
        const rect = wrapperRef.current.getBoundingClientRect();
        setHeight(event.target.innerHeight - rect.top);
        setWidth(rect.width);
      }
    };
    window.addEventListener('resize', cb);

    return () => window.removeEventListener('resize', cb);
  }, []);

  if (!data.length) return null;

  rows.forEach((row) => prepareRow(row));
  return (
    <div {...getTableProps()}>
      <div
        ref={headerRef}
        style={{
          width: `calc(100% - ${getScrollbarWidth()}px)`,
          overflow: 'hidden',
          borderBottom: '2px solid #00000022',
        }}
      >
        {headerGroups.map((headerGroup) => (
          <div
            className='d-flex align-items-center justify-content-start'
            {...headerGroup.getHeaderGroupProps()}
          >
            {headerGroup.headers.map((column) => {
              return (
                <div
                  {...column.getHeaderProps({
                    style: {
                      minWidth: column.width,
                      maxWidth: column.width,
                      display: 'flex',
                      alignContent: 'center',
                      fontSize: 12,
                      paddingLeft: 6,
                    },
                  })}
                >
                  {column.render('Header')}
                </div>
              );
            })}
          </div>
        ))}
      </div>

      {
        <div ref={wrapperRef}>
          <Grid
            itemData={itemData}
            columnCount={columns.length}
            columnWidth={(index) => columns[index]?.width || 150}
            height={height}
            rowCount={data.length}
            rowHeight={(index) => 50}
            width={width}
            onScroll={(props) => headerRef.current.scrollLeft = props.scrollLeft}
          >
            {({ columnIndex, rowIndex, style, data }) => {
              const cell = rows[rowIndex]?.cells[columnIndex];
              const { hoveredRowIndex, setHoveredRowIndex } = data;

              if (!cell) return null;

              const cellProps = cell.getCellProps({
                style: {
                  ...style,
                  minWidth: cell.column.width,
                  maxWidth: cell.column.width,
                  backgroundColor: hoveredRowIndex === rowIndex ? '#00000033' : 'transparent',
                },
              });

              return <MatrixCell
                onClick={() => onRowClick(cell.row)}
                onMouseEnter={() => setHoveredRowIndex(rowIndex)}
                onMouseLeave={() => setHoveredRowIndex(-1)}
                {...cellProps}
                >
                  {cell.render('Cell')}
                </MatrixCell>;
            }}
          </Grid>
        </div>
      }
    </div>
  );
};

export default DataGridMatrix;
