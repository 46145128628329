import { useEffect, useMemo, useState } from 'react';
import { SettingsCodesLayoutNext } from '../../../layouts/settings-codes-layout/settings-codes-layout-next';
import {
  disableReport,
  enableReport,
  getReportSettings,
  reorderReportSettings,
} from '../../../modules/settings';
import { useAppDispatch } from '../../../shared/hooks/use-app-dispatch';
import { EditReportSettingsModal } from './edit-report-settings-modal';
import { reportSections } from './constants';
import _ from 'lodash';
import { useConfirm } from '../../../shared/confirmation-dialog';
import { useNavigationContext } from '../../../shared/navigation-provider';

export const ReportSettingsPage = ({ initialReportId }: any) => {
  const dispatch = useAppDispatch();

  const confirm = useConfirm();

  const { refreshMainMenu } = useNavigationContext();

  const [settings, setSettings] = useState<any[]>([]);
  const [open, setOpen] = useState(false);
  const [selectedFieldId, setSelectedFieldId] = useState<string | null>(null);

  const getReportSectionLabel = (section: string) => {
    return reportSections.find((item) => item.value === section)?.label;
  };

  const refreshTable = async () => {
    const data = await dispatch(getReportSettings()).unwrap();
    setSettings(data);
    if(refreshMainMenu) {
      await refreshMainMenu();
    }
  };

  useEffect(() => {
    refreshTable();
  }, []);

  const [initialReportOpened, setInitialReportOpened] = useState(false);

  useEffect(() => {
    if (initialReportId && !initialReportOpened) {
      const report = settings.find((item) => item._id === initialReportId);
      if (report) {
        handleRowClick(report);
        setInitialReportOpened(true);
      }
    }
  }, [settings, initialReportId]);

  const columns = useMemo(() => {
    return [
      {
        Header: 'Section',
        accessor: 'section',
        width: 180,
        Cell: ({ value, row }: any) => {
          const label = getReportSectionLabel(value);
          if (row.original.isGroup === true) {
            return <b className='font-weight-bold'>{label}</b>;
          }

          return null;
        },
        searchable: true,
      },
      {
        Header: 'Report Title',
        accessor: 'name',
        width: 200,
        searchable: true,
      },
      {
        Header: 'Definition',
        accessor: 'definition',
        width: 300,
        Cell: ({ value }: any) => {
          return <div className='text-truncate'>{value}</div>;
        },
        searchable: true,
      },
    ];
  }, []);

  const groupedRows = useMemo(() => {
    const groups = reportSections.map((item) => item.value);

    return groups.map((group) => {
      const groupData = settings?.filter((item) => item.section === group);

      return {
        section: group,
        isGroup: true,
        subRows: groupData,
      };
    });
  }, [settings]);

  const handleAddNewItemClick = () => {
    setOpen(true);
    setSelectedFieldId(null);
  };

  const handleRowClick = (row: any) => {
    setOpen(true);
    setSelectedFieldId(row._id);
  };

  const handleDragEnd = async ({ item, destination }) => {
    await dispatch(
      reorderReportSettings({
        itemId: item._id,
        section: destination.droppableId,
        index: destination.index,
      })
    ).unwrap();

    await refreshTable();
  };

  const handleToggle = async (trash, report) => {
    if (trash) {
      const ok = await confirm({
        title: 'Disable Report',
        question: 'Are you sure you want to disable this report?',
      });

      if (!ok) {
        return;
      }

      await dispatch(disableReport({ reportId: report._id })).unwrap();
      await refreshTable();
    } else {
      await dispatch(enableReport({ reportId: report._id })).unwrap();
      await refreshTable();
    }
  };

  const getActiveItems = (rows: any[]) => {
    const newRows = _.cloneDeep(rows);

    newRows.forEach((row) => {
      row.subRows = row.subRows.filter((subRow) => !subRow.trash);
    });

    return newRows;
  };

  return (
    <>
      <SettingsCodesLayoutNext
        tableTitle='Report Settings'
        columns={columns}
        rows={groupedRows}
        loading={false}
        onRowClick={handleRowClick}
        onAddNewItemClick={handleAddNewItemClick}
        onDragEnd={handleDragEnd}
        getSubRows={(row) => row.subRows}
        onToggle={handleToggle}
        getActiveItems={getActiveItems}
      />

      <EditReportSettingsModal
        settingId={selectedFieldId}
        open={open}
        onClose={() => setOpen(false)}
        onSubmit={() => refreshTable()}
      />
    </>
  );
};
