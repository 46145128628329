import { useEffect, useState, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { getContactCustomFieldsValuesForm } from '../../modules/contacts';
import { ContactCustomFields } from '../../modals/add-contact-modal/contact-custom-fields';
import { ContactCustomAdditionalFields } from '../../modals/add-contact-modal/contact-custom-additional-fields';

export const useContactCustomFieldsForm = ({ open, affiliationFormWatch }): Array<any> => {
  const defaultValues = {
    affiliations: Array<any>(),
    formerAffiliations: Array<any>(),
    initialized: false,
  };

  const dispatch: any = useDispatch();
  const customFieldsForm = useForm({ defaultValues });

  const [customFields, setCustomFields] = useState<Array<any>>([]);
  const [customFieldsDefaultValues, setCustomFieldsDefaultValues] = useState({});

  const contactFieldsFilter = ({ stages }) => stages.includes('contact');
  const employeeFieldsFilter = ({ stages }) => stages.includes('employee');
  const affiliationFieldsFilter = ({ stages }) => stages.includes('affiliation');

  // Returns custom fields based on the incomming arguments
  const CustomFields = useCallback(
    (callback: any, name: string, index: number, isAdditional = false) => {
      const contactFieldsFiltered = customFields.filter(callback);

      if (contactFieldsFiltered.length === 0) {
        return null;
      }

      const values = customFieldsForm.getValues(name as any);
      const companyAffId = Array.isArray(values) ? values[index]?.companyId || '' : '';

      const props = {
        key: companyAffId,
        fields: contactFieldsFiltered,
        index,
        name,
      }

      const RenderComponent = isAdditional ? ContactCustomAdditionalFields : ContactCustomFields;

      return (
        <FormProvider {...customFieldsForm}>
          <RenderComponent {...props} />
        </FormProvider>
      );
    },
    [customFields, customFieldsDefaultValues]
  );

  // get custom fields and their default values
  useEffect(() => {
    if (open) {
      dispatch(getContactCustomFieldsValuesForm())
        .unwrap()
        .then((data: any) => {
          const defaultValues = data.reduce((acc, field) => {
            if (field.valueDefinition.isUrl) {
              acc[field._id] = {
                label: field.valueDefinition.defaultValue,
                url: field.valueDefinition.defaultValueUrl,
              };
              return acc;
            }

            acc[field._id] = field.valueDefinition.defaultValue;
            return acc;
          }, {});

          // change all undefined to null
          const newDefaultValues = Object.keys(defaultValues).reduce((acc, key) => {
            acc[key] = defaultValues[key] ?? null;
            return acc;
          }, {});

          setCustomFields([...data]);
          setCustomFieldsDefaultValues(newDefaultValues);
        });
    } else {
      customFieldsForm.reset(defaultValues);
    }
  }, [open]);

  // subscribe to changes in affiliation form
  useEffect(() => {
    const subscription = affiliationFormWatch(affFormWatchCb);
    return () => subscription.unsubscribe();
  }, [customFieldsDefaultValues, affiliationFormWatch]);

  const getDefaultValuesForAffiliation = useCallback(
    (aff: any) => {
      const selfAffFieldsIds = customFields.filter(contactFieldsFilter).map((field) => field._id);
      const employeeFieldsIds = customFields.filter(employeeFieldsFilter).map((field) => field._id);
      const affiliationFieldsIds = customFields
        .filter(affiliationFieldsFilter)
        .map((field) => field._id);
      const accountAffFieldIds = [...employeeFieldsIds, ...affiliationFieldsIds];

      return Object.keys(customFieldsDefaultValues).reduce((acc, key) => {
        let getFieldValue = false;

        if (aff.selfAffiliated) {
          getFieldValue = selfAffFieldsIds.includes(key);
        } else if (aff.isAccountAffiliation) {
          getFieldValue = accountAffFieldIds.includes(key);
        } else {
          getFieldValue = affiliationFieldsIds.includes(key);
        }

        if (getFieldValue) {
          acc[key] = customFieldsDefaultValues[key];
        }

        return acc;
      }, {});
    },
    [customFields, customFieldsDefaultValues]
  );

  const updateAffDefaultValues = (aff: any) => ({
    ...getDefaultValuesForAffiliation(aff),
    companyId: aff.companyId,
    selfAffiliated: aff.selfAffiliated,
    isAccountAffiliation: aff.isAccountAffiliation,
  });

  // if default values changed - update affiliation form
  // we need it because affiliation form initialized before this form
  useEffect(() => {
    const { affiliations, formerAffiliations, initialized } = customFieldsForm.getValues();

    const updatedAffValues = affiliations.map(updateAffDefaultValues);
    const updatedFormerAffValues = formerAffiliations.map(updateAffDefaultValues);

    customFieldsForm.reset({
      affiliations: updatedAffValues,
      formerAffiliations: updatedFormerAffValues,
      initialized,
    });
  }, [customFields, customFieldsDefaultValues]);

  const affFormWatchCb = useCallback(
    (values, { name = '' }) => {
      switch (name) {
        case 'affiliations':
        case 'formerAffiliations': {
          const customFieldsValues = {...customFieldsForm.getValues()};
          
          const data = values[name].map((aff: any) => {
            const { companyId, selfAffiliated = false, isAccountAffiliation = false } = aff;
            const data = { companyId, selfAffiliated, isAccountAffiliation };

            // get custom fields from response initially
            if (!customFieldsValues.initialized || aff.customFields) {
              return { ...data, ...(aff.customFields || getDefaultValuesForAffiliation(aff)) };
            }

            // selfAffiliation is always first
            if (selfAffiliated) {
              return { ...data, ...customFieldsValues.affiliations[0] };
            }

            // get modified custom fields for other affiliations
            if (companyId) {
              const affFilterCb = (cAff: any) => cAff?.companyId === companyId;
              // search for custom fields data in affiliations array
              let existingFields = customFieldsValues.affiliations.find(affFilterCb);
              if (!existingFields) {
                // search for custom fields data in formerAffiliations array
                existingFields = customFieldsValues.formerAffiliations.find(affFilterCb);
              }

              if (existingFields) {
                return { ...data, ...existingFields };
              }
            }
            // set fields to default
            return { ...data, ...getDefaultValuesForAffiliation(aff) };
          });

          customFieldsValues[name] = [...data];
          customFieldsForm.reset({...customFieldsValues});
          break;
        }
        case 'affiliationsFetched': {
          if (values.affiliationsFetched) {
            customFieldsForm.setValue('initialized', values.affiliationsFetched);
            break;
          }
        }
      }
    },
    [customFields, customFieldsDefaultValues, getDefaultValuesForAffiliation]
  );

  return [customFieldsForm, CustomFields];
};
