import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { IMaskInput as _imask } from 'react-imask';
import Select, { createFilter } from 'react-select';
import { appConstants } from '../../_constants';
import { useDisabledFieldsContext } from '../disabled-fields';
import FormSelect from '../form-controls/form-select';
import useCountryCodes from '../hooks/use-country-codes';
import style from './phone-input.module.css';
import { LayoutProps } from '../form-controls/control-layouts/layout-props';

const IMaskInput: any = _imask;

type PhoneInputProps = {
  hideExt?: boolean;
  hideType?: boolean;
  name: string;
  disabled?: boolean;
  Layout?: (props: LayoutProps) => JSX.Element;
  onChange?: (value: any) => void;

};

const PhoneInput = ({
  name,
  disabled = false,
  Layout = ({ input }) => <>{input}</>,
  hideExt = false,
  hideType = false,
  onChange = () => void 0,
}: PhoneInputProps) => {
  const forceDisabled = useDisabledFieldsContext();

  const phoneOptions = useMemo(
    () => appConstants.phoneOptions.map((value) => ({ value, label: value })),
    []
  );

  const countries = useCountryCodes();

  const [opts, setOpts] = useState({ mask: '000000000000' });

  const { setValue, watch, control } = useFormContext();

  const value = watch(name, { phone: '', ext: '', type: '', extPhone: '', fullPhone: '', phoneClean: '' });

  const getCountryValue = () => {
    if (value?.country) {
      return countries.find((c) => c.iso === value.country);
    }
    return countries.find(
      (c) => c.code === value?.ext && (c.code === '1' ? (c as any).iso === 'US' : true)
    );
  };

  const extPhoneMaskOptions = '0000000000';

  const extRef = useRef<any>(null);
  const phoneRef = useRef<any>(null);

  const [selectedCountry, setSelectedCountry] = useState(() =>
    countries.find((c) => c.iso === 'US')
  );

  useEffect(() => {
    if (selectedCountry) setOpts({ mask: selectedCountry.mask });
  }, [selectedCountry]);

  // this doesn't correctly concatenate ext or phone it only sets one or the other 
  // TODO fix this
  const onPhoneChange = useCallback(() => {
    const phone = phoneRef.current?.maskRef?._value;
    const phoneClean = phoneRef.current?.maskRef?._unmaskedValue.replace(/\D/g, '');
    const fullPhone = `+${value?.ext ?? '1'} ${phone}`;
    setValue(`${name}.phone`, phone);
    setValue(`${name}.fullPhone`, fullPhone);
    setValue(`${name}.phoneClean`, phoneClean);

    onChange({
      ...value,
      phone,
      fullPhone,
      phoneClean,
    })
  }, [value]);

  const onExtPhoneChange = () => {
    const extPhone = extRef.current?.maskRef?._unmaskedValue;

    setValue(`${name}.extPhone`, extPhone);

    onChange({
      ...value,
      extPhone,
    })
  };

  const onCountrySelect = useCallback((selectedCountry) => {
    setSelectedCountry(selectedCountry);

    const phone = phoneRef.current?.maskRef?._value;
    const phoneClean = phoneRef.current?.maskRef?._unmaskedValue.replace(/\D/g, '');
    const countryExt = selectedCountry.code;
    const fullPhone = `+${countryExt ?? '1'} ${phone}`;

    setValue(`${name}.phone`, phone);
    setValue(`${name}.fullPhone`, fullPhone);
    setValue(`${name}.phoneClean`, phoneClean);

    setValue(`${name}.ext`, selectedCountry.code);
    setValue(`${name}.country`, selectedCountry.iso);

    onChange({
      ...value,
      ext: selectedCountry.code,
      country: selectedCountry.iso,
      phone,
      fullPhone,
      phoneClean,
    })
  }, [value]);

  const filterConfig = {
    ignoreCase: true,
    ignoreAccents: true,
    trim: true,
    matchFrom: 'any',
    stringify: (option) => `+${option.data.code} ${option.data.name} ${option.data.iso} `,
  };

  const MemoLayout = useMemo(() => Layout, []);

  return (
    <MemoLayout
      input={
        <>
          <div className='col-xs-4 col-md-2 pe-1 col-2'>
            <label className='label' id='aria-label' htmlFor='phone-input'>
              Country
            </label>
            <Select
              styles={{
                menu: (baseStyles) => ({
                  ...baseStyles,
                  minWidth: 300,
                  border: '1px solid #00000022',
                }),
              }}
              isDisabled={disabled || forceDisabled}
              onChange={onCountrySelect}
              value={getCountryValue()}
              options={countries}
              controlShouldRenderValue
              components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
              filterOption={createFilter(filterConfig)}
              formatOptionLabel={(o) => {
                return (
                  <div className={style['menu-item--item-container']}>
                    <img
                      className={style['menu-item--flag']}
                      height={24}
                      width={24}
                      src={o.flag}
                      alt=''
                    />
                    <span className={style['menu-item--country-code']}>+{o.code} </span>
                    <b className={style['menu-item--country-name']}>{o.name}</b>
                  </div>
                );
              }}
            />
          </div>

          <div className='col-xs-7 col-md-3 px-1 col-5'>
            <label className='label' id='aria-label' htmlFor='phone-input'>
              Phone
            </label>
            <IMaskInput
              disabled={disabled || forceDisabled}
              mask={opts?.mask}
              value={value?.phone}
              onAccept={onPhoneChange}
              className='form-control'
              placeholder={selectedCountry?.mask || '000000000000'}
              ref={phoneRef}
            />
          </div>

          {!hideExt && (
            <div className='col-xs-6 col-md-1 px-1 col-4'>
              <label className='label' id='aria-label' htmlFor='phone-input'>
                Ext
              </label>
              <IMaskInput
                disabled={disabled || forceDisabled}
                mask={extPhoneMaskOptions}
                ref={extRef}
                value={value.extPhone}
                onAccept={onExtPhoneChange}
                placeholder='Ext.'
                className='form-control'
              />
            </div>
          )}
          {!hideType && (
            <>
              <div className='d-xs-inline d-md-none d-lg-none ms-auto col-7'></div>

              <div className='col-xs-6 col-md-2 ps-1 col-4'>
                <FormSelect
                  options={phoneOptions}
                  name={`${name}.type`}
                  creatable
                  control={control}
                  label='Type'
                />
              </div>
            </>
          )}
        </>
      }
    />
  );
};

export default PhoneInput;
