import { createContext, useContext } from 'react';

export const DisabledFieldsContext = createContext(false);

export const DisabledFieldsProvider = ({
  disabled = false,
  children,
}: {
  disabled?: boolean;
  children: React.ReactNode;
}) => {
  return (
    <DisabledFieldsContext.Provider value={disabled}>{children}</DisabledFieldsContext.Provider>
  );
};

export const useDisabledFieldsContext = () => {
  const context = useContext(DisabledFieldsContext);

  return !!context;
};
