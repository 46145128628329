import React from 'react';

import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { formatMoney } from '../../../utils/number-formatters';

var tableData = [];

class BiggestLosers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        whatToAnalyze: 'sales',
        jobStatus: false,
        dateType: 'dateType',
        analyzeBy: '',
        jobBasedOn: '',
      },
      accountId: localStorage.getItem('accountId'),
      userId: localStorage.getItem('userId'),
    };
  }

  biggestLosersTable() {
    tableData = [];
    const { losersData } = this.props.componentData;

    losersData.sort((a, b) => {
      return (
        b.originalGrossProfit -
        b.currentGrossProfit -
        (a.originalGrossProfit - a.currentGrossProfit)
      );
    });

    for (var x in losersData) {
      var tdJobName = (
        <td colSpan="2">
          <Link to={{ pathname: `/project-details/` + losersData[x].objectId }}>
            {losersData[x].jobName}
          </Link>
        </td>
      );
      var tdAmountDollar = (
        <td>
          {formatMoney(Math.round(losersData[x].originalGrossProfit)) +
            ' / ' +
            formatMoney(Math.round(losersData[x].currentGrossProfit))}
        </td>
      );
      var obj = (
        <tr>
          {tdJobName}
          {tdAmountDollar}
        </tr>
      );
      tableData.push(obj);
    }
  }

  render() {
    this.biggestLosersTable();
    return (
      <div className="">
        <div className="">
          {tableData.length > 0 && (
            <table>
              <thead>
                <tr>
                  <th colSpan="2">Job Name</th>
                  <th>
                    Gross Profit($)<span>Original/Current</span>
                  </th>
                </tr>
              </thead>
              <tbody>{tableData}</tbody>
            </table>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default (withRouter(connect(mapStateToProps)(BiggestLosers)));
