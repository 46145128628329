import { useCallback, useMemo, useState } from 'react';
import { EditableTable } from '../../shared/editable-table';
import styles from './styles.module.scss';
import _ from 'lodash';

export type SettingsCodesLayoutProps = {
  tableTitle: string;
  columns: any[];
  rows: any[];
  loading: boolean;
  getActiveItems?: (rows: any[]) => any[];
  onToggle?: (toggle: boolean, rowValue: any) => Promise<unknown>;
  onRowClick?: (row: any) => unknown;
  onAddNewItemClick?: () => unknown;
  groupBy?: string;
  onDragEnd?: any;
  groups?: any[];
  getSubRows?: (row: any) => any[];
  additionalButtons?: any[];
};

export const SettingsCodesLayoutNext = ({
  tableTitle,
  columns,
  rows,
  loading,
  getActiveItems = (rows: any[]) => rows.filter((row) => !row.trash),
  onToggle,
  onRowClick = () => void 0,
  onAddNewItemClick,
  groupBy = '',
  onDragEnd,
  getSubRows,
  additionalButtons = [],
}: SettingsCodesLayoutProps) => {
  const TOGGLE_FALSE_COLOR = '#b2102f';
  const TOGGLE_TRUE_COLOR = '#357a38';

  const activeItems = useMemo(() => {
    return getActiveItems(rows);
  }, [rows]);

  const [searchValue, setSearchValue] = useState('');

  const [tab, setTab] = useState('ActiveItems');

  const allItemsColumns = useMemo(() => {
    const result = [...columns];

    if (onToggle) {
      result.push({
        Header: '',
        accessor: 'trash',
        width: 30,
        Cell: ({ value, row }: any) => {
          const [loading, setLoading] = useState(false);

          if (row.original?.isGroup) return null;

          return (
            <i
              style={{
                fontSize: '20px',
                color: !value ? TOGGLE_FALSE_COLOR : TOGGLE_TRUE_COLOR,
              }}
              className={`fa fa-${value ? 'plus' : 'minus'}-circle ${loading ? 'text-muted' : ''}`}
              aria-hidden='true'
              onClick={async (e) => {
                e.persist();
                e.stopPropagation();

                if (loading) return;

                setLoading(true);

                onToggle(!value, row.original).finally(() => setLoading(false));
              }}
            />
          );
        },
      });
    }

    return result;
  }, [columns, onToggle]);

  const getRowClassName = useCallback((row: any) => {
    // eslint-disable-next-line react/prop-types
    if (row.original.trash) return styles.trashRow;

    return '';
  }, []);

  const Table = useMemo(() => {
    if (tab === 'AllItems') {
      return (
        <EditableTable
          columns={allItemsColumns}
          data={rows}
          isLoading={loading && !rows.length}
          onRowClicked={onRowClick}
          searchKeyword={searchValue}
          onDragEnd={onDragEnd}
          getSubRows={getSubRows}
          rowClassName={getRowClassName}
        />
      );
    }

    return (
      <EditableTable
        columns={columns}
        data={activeItems}
        isLoading={loading && !activeItems.length}
        onRowClicked={onRowClick}
        searchKeyword={searchValue}
        getSubRows={getSubRows}
      />
    );
  }, [groupBy, tab, allItemsColumns, rows, loading, activeItems, searchValue]);

  const handleSearch = useCallback(
    _.debounce((value: string) => {
      setSearchValue(value);
    }, 500),
    []
  );

  const AdditionalButtons = useMemo(() => {
    if (additionalButtons.length === 0) return null;

    return additionalButtons.map((button, index) => {
      const { onClick, label } = button;

      return <button key={index} className='btn btn-primary text-nowrap' onClick={onClick}>
        {label}
      </button>;
    })
  }, [additionalButtons]);

  return (
    <div data-testid='settings-codes-layout'>
      <div className='d-flex justify-content-between pt-2'>
        <div className='headingbox'>
          <span className='heading2'>{tableTitle}</span>
        </div>
        <div className='row'>
          <div className='col-md-12'>
            <div className='d-flex gap-2'>
              <input
                type='text'
                className='form-control'
                placeholder='Search...'
                onChange={(e) => handleSearch(e.target.value)}
              />

              <button
                className='btn btn-primary text-nowrap'
                onClick={() =>
                  setTab((oldTab) => (oldTab === 'ActiveItems' ? 'AllItems' : 'ActiveItems'))
                }
              >
                {tab === 'ActiveItems' ? 'Manage Items' : 'Show Active Only'}
              </button>

              {AdditionalButtons}

              {onAddNewItemClick && (
                <button className='btn btn-primary' onClick={onAddNewItemClick}>
                  Add
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      {Table}
    </div>
  );
};
