import _ from 'lodash';
import { joiResolver } from '@hookform/resolvers/joi';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { useSubmit } from '../../shared/hooks/use-submit';
import { useAppDispatch } from '../../shared/hooks/use-app-dispatch';
import { useAppSelector } from '../../shared/hooks/use-app-selector';
import { getExistingProfileAction, updateProfileAction } from '../../actions/profile';
import { getAccountSettingsAction, updateAccountSettingsAction } from '../../actions/accountSettings';
import { selectGeneralInfo } from '../../selectors/accountSettings';
import { selectGetContact, selectGetContactAffiliations } from '../../modules/contacts/selectors';
import { WelcomePageFieldsSchema, WelcomePageFieldsValidationSchema } from './welcome-page-fields.schema';
import { updateAccountLogo } from '../../modules/accounts';
import { getContact, getContactAffiliations, saveContactAffiliations, saveContact } from '../../modules/contacts';
import WelcomePageUserStep from './steps/user-step';
import WelcomePageAdminStep from './steps/admin-step';
import { Userpilot } from 'userpilot';

type WelcomePageProps = {
  onSubmit?: () => Promise<unknown>;
};

const WelcomePage = ({
  onSubmit = () => Promise.resolve(),
}: WelcomePageProps) => {

  const imageUrl = localStorage.getItem('companyLogo') || '';
  const companyName = localStorage.getItem('companyName') || '';
  const userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}');

  const { userEmail: email, objectId: userId, contactId, accountId: accountIdObj } = userInfo;
  const accountId = accountIdObj.objectId;

  const [onboardingStatus, setOnboardingStatus] = useState<number>(0);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [isLastStep, setIsLastStep] = useState<boolean>(false);

  const defaultValues = {
    firstName: '',
    middleName: '',
    lastName: '',
    contactTitle: '',
    email: '',
    cppRoleName: '',
    companyName,
    initialJobNumberPre: '',
    initialJobNumber: 1,
    alternateJobNumber: false,
    isAgreedToTermsAndCond: false,
    file: null,
  };

  const form = useForm<WelcomePageFieldsSchema>({
    defaultValues,
    resolver: joiResolver(WelcomePageFieldsValidationSchema),
  });

  const file = form.watch('file');
  const isAgreedToTermsAndCond = form.watch('isAgreedToTermsAndCond');

  const history = useHistory();
  const dispatch = useAppDispatch();
  const generalInfo = useAppSelector(selectGeneralInfo);

  const { data: contact } = useAppSelector(selectGetContact);
  const { data: affiliations = [] } = useAppSelector(selectGetContactAffiliations);

  useEffect(() => {
    dispatch(getAccountSettingsAction());
    dispatch(getContact({ contactId }));
    dispatch(getContactAffiliations({ contactId }));
  }, []);

  useEffect(() => {
    if (contact && generalInfo) {
      const { firstName, lastName, middleName, contactTitle } = contact;
      const isAdmin = (userId === generalInfo.adminUserId) || (!generalInfo.adminUserId && email === generalInfo.companyEmail);
      const cppRoleName = isAdmin ? 'Administrator' : userInfo.roleName;
      setIsAdmin(isAdmin);

      form.reset({
        firstName,
        middleName,
        lastName,
        contactTitle,
        email,
        cppRoleName,
        companyName,
        initialJobNumberPre: '',
        initialJobNumber: 1,
        alternateJobNumber: false,
      });
    }
  }, [contact, generalInfo]);

  useEffect(() => {
    const lastStepNumber = isAdmin ? 1 : 0;

    setIsLastStep(onboardingStatus === lastStepNumber);
  }, [onboardingStatus, isAdmin]);

  const applyAdminStepChanges = async () => {
    const { companyName, initialJobNumberPre, initialJobNumber, alternateJobNumber } = form.getValues();

    localStorage.setItem('companyName', companyName);

    if (file && file[0]) {
      const result = await dispatch(updateAccountLogo({ companyLogo: file[0] })).unwrap();

      localStorage.setItem('companyLogo', result);
    }

    await dispatch(
      updateAccountSettingsAction({
        ...generalInfo,
        companyName,
        imageUrl,
        initialJobNumberPre,
        initialJobNumber: +initialJobNumber,
        alternateJobNumber,
      })
    );
  };

  const applyLastStepChanges = async () => {
    const { firstName, middleName, lastName, contactTitle } = form.getValues();

    const userNewData = {
      ...userInfo,
      title: contactTitle,
      firstName,
      lastName,
      onboardingStatus: onboardingStatus + 1,
    };

    await dispatch(updateProfileAction(userNewData));

    const contactNewData = {
      ...(contact as any),
      accountId,
      contactTitle,
      userId,
      firstName,
      middleName,
      lastName,
    };

    await dispatch(saveContact({ contactData: contactNewData }));

    const affiliationsClone = _.cloneDeep(affiliations);
    const accountAffiliation = affiliationsClone.find((aff) => aff.isAccountAffiliation);
    if (accountAffiliation) {
      const existingEmail = accountAffiliation.emailArr.find((em) => em.email === email);

      if (!existingEmail) {
        const accountAffEmailsClone = [...accountAffiliation.emailArr];

        accountAffEmailsClone.forEach((aff) => aff.isPrimary = false);
        accountAffEmailsClone.unshift({ isPrimary: true, email });

        accountAffiliation.emailArr = accountAffEmailsClone;

        await dispatch(saveContactAffiliations({ affiliations: affiliationsClone, formerAffiliations: [], contactId }));
      }
    }

    // update data in userInfo localStorage
    await dispatch(getExistingProfileAction({ userId }));

    history.push('/');
  };

  const [onSubmitClick] = useSubmit(
    form.handleSubmit(async () => {
      if (onboardingStatus === 1) {
        await applyAdminStepChanges();
      }

      if (isLastStep) {
        if(isAdmin){
          Userpilot.track("Account Owner Onboarded");
        }else{
          Userpilot.track("User Onboarded");
        }
        
        await applyLastStepChanges();
      }

      

      await onSubmit();

      const nextOnboardingStatus = onboardingStatus + 1;
      setOnboardingStatus(nextOnboardingStatus);
    }), [onboardingStatus, isLastStep, imageUrl, file, contact, affiliations]
  );

  return (
    <div data-testid='welcomePage' className='ps-5 pb-4'>
      <FormProvider {...form}>
        <div className='col-md-11'>
          {
            onboardingStatus === 0
              ? <WelcomePageUserStep companyName={companyName} isAdmin={isAdmin} />
              : <WelcomePageAdminStep imageUrl={imageUrl} />
          }
          <br />
          <br />
          <div className='col-md-2 offset-md-10'>
            <button data-testid='welcomePage-button-submit' type='button' className='btn btn-primary w-100' disabled={!isAgreedToTermsAndCond} onClick={onSubmitClick}>
              {isLastStep ? 'Submit' : 'Continue'}
            </button>
          </div>
        </div>
      </FormProvider>
    </div>
  );
};

export default WelcomePage;
