import * as actionTypes from '../actions/actionTypes';

const initState = {
  assignModule: {},
  allAssignModule: [],
  allAccountRoleMatrix: [],
  message: '',
  accountRoleMatrixStatus: false,
};

export default (state = initState, action = {}) => {
  switch (action.type) {
    case actionTypes.CREATE_ASSIGN_MODULE_REQUEST:
      return {
        ...state,
        buttonActive: true,
        assignModule: {},
        message: '',
        accountRoleMatrixStatus: false,
      };
    case actionTypes.CREATE_ASSIGN_MODULE_SUCCESS:
      return {
        ...state,
        buttonActive: false,
        allAccountRoleMatrix: action.payload.response.result.data
          ? action.payload.response.result.data
          : [],
        accountRoleMatrixStatus: true,
        success: action.payload.response.result.success,
        message: action.payload.response.result.success
          ? 'Record Set successfully'
          : action.payload.response.result.msg,
      };

    case actionTypes.CREATE_ASSIGN_MODULE_ERROR:
      return {
        ...state,
        buttonActive: false,
        accountRoleMatrixStatus: true,
        success: action.payload.response.result && action.payload.response.result.success,
        message: action.payload.response.result.msg,
      };

    case actionTypes.GET_ALL_ASSIGN_MODULE_REQUEST:
      return {
        ...state,
        assignModule: {},
        message: '',
      };

    case actionTypes.GET_ALL_ASSIGN_MODULE_SUCCESS:
      return {
        ...state,
        allAssignModule: action.payload.response.result.data,
        message: '',
      };

    case actionTypes.GET_ALL_ASSIGN_MODULE_ERROR:
      return {
        ...state,
        message: '',
      };

    case actionTypes.UPDATE_ASSIGN_MODULE_REQUEST:
      return {
        ...state,
        buttonActive: true,
        assignModule: {},
        message: '',
      };
    case actionTypes.UPDATE_ASSIGN_MODULE_SUCCESS:
      return {
        ...state,
        buttonActive: false,
        allAssignModule: action.payload.response.result.assignModule,
        message: '',
      };

    case actionTypes.UPDATE_ASSIGN_MODULE_ERROR:
      return {
        ...state,
        buttonActive: false,
        message: '',
      };

    case actionTypes.GET_MODULE_PERMISSION_REQUEST:
      return {
        ...state,
        assignModule: {},
        message: '',
      };
    case actionTypes.GET_MODULE_PERMISSION_SUCCESS:
      return {
        ...state,
        allAssignModule: state.allAssignModule.concat(action.payload.response.result.data),
      };

    case actionTypes.GET_MODULE_PERMISSION_ERROR:
      return {
        ...state,
        message: '',
      };

    case actionTypes.GET_PERMISSIONS_BY_ROLE_REQUEST:
      return {
        ...state,
        assignModule: {},
        message: '',
      };
    case actionTypes.GET_PERMISSIONS_BY_ROLE_SUCCESS:
      return {
        ...state,
        message: action.payload.response.result.msg,
        allAssignModule: action.payload.response.result.data
          ? action.payload.response.result.data.permissions
          : [],
      };

    case actionTypes.GET_PERMISSIONS_BY_ROLE_ERROR:
      return {
        ...state,
        message: '',
      };

    case actionTypes.GET_COMPANY_ROLE_MATRIX_REQUEST:
      return {
        ...state,
        allAccountRoleMatrix: [],
        message: '',
      };
    case actionTypes.GET_COMPANY_ROLE_MATRIX_SUCCESS:
      return {
        ...state,
        message: action.payload.response.result.msg,
        allAccountRoleMatrix: action.payload.response.result.data
          ? action.payload.response.result.data[0]
          : [],
      };

    case actionTypes.GET_COMPANY_ROLE_MATRIX_ERROR:
      return {
        ...state,
        allAccountRoleMatrix: [],
        message: '',
      };

    default:
      return state;
  }
};
