import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { formatDateObject } from '../../../utils/date-formatters';

class ReminderModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accountId: localStorage.getItem('accountId'),
      userId: localStorage.getItem('userId'),
      list:
        this.props.componentData && this.props.componentData.remindersData
          ? this.props.componentData.remindersData
          : [],
      missingType:
        this.props.componentData && this.props.componentData.type
          ? this.props.componentData.type
          : '',
      modal: false,
      fetchRequest: false,
      showProcessing: false,
    };
    this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {
    this.toggle();
  }

  toggle(val) {
    if (this.state.modal) this.props.onClick();
    this.setState({ modal: !this.state.modal });
  }

  render() {
    let reminderList = this.state.list;
    return (
      <div>
        <Modal
          backdrop='static'
          keyboard
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggle}>Reminder</ModalHeader>
          <ModalBody>
            <div className='container'>
              <div className='row'>
                <div className='col-md-12'>
                  {reminderList.map((v) => {
                    return (
                      <div key={v.objectId} className='reminderBox'>
                        <p>
                          <i className='fa fa-bell' aria-hidden='true'></i>
                          <strong> {v.title ? v.title : ''}</strong>,{' '}
                          {v.description ? v.description : ''} at{' '}
                          {formatDateObject(v.startDate.iso).format('h:mm A')}
                          {formatDateObject(v.endDate.iso).format('h:mm A')} on location{' '}
                          {v.location}.
                        </p>
                        <p className='attNames'>
                          Attendees are -{' '}
                          {v.scheduleTo.map((to) => (
                            <strong key={to.objectId}>{to.value}</strong>
                          ))}
                        </p>
                      </div>
                    );
                  })}

                  {reminderList?.length == 0 && (
                    <div className='msgBox'>
                      <span>No data available!</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button className='btn btn-primary' onClick={this.toggle}>
              Cancel
            </button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default ReminderModal;
