import { useFormContext } from 'react-hook-form';
import { HorizontalLayout } from '../../../shared/form-controls/control-layouts/horizontal-layout';
import { convertAddressToFormValue, toContactAddress } from '../../../utils/address';
import FormInput from '../../../shared/form-controls/form-input';
import FormSearchPlaceAutocomplete from '../../../shared/form-controls/form-search-place-autocomplete';
import { isAllowed, moduleConstants } from '../../../_constants';

export const LeadJobAddress = () => {

  const form = useFormContext();

  const handlePlaceSelect = (place) => {
    const parsedPlace = toContactAddress(place);

    if (!parsedPlace) {
      return;
    }

    form.setValue(
      'jobAddress',
      convertAddressToFormValue(parsedPlace.streetNumber, parsedPlace.route)
    );
    form.setValue('jobCity', parsedPlace.city ?? '');
    form.setValue('jobState', parsedPlace.state ?? '');
    form.setValue('jobZip', parsedPlace.zipCode ?? '');
    form.setValue('jobCountry', parsedPlace.country ?? '');
    form.setValue('lat', parsedPlace.lat);
    form.setValue('lng', parsedPlace.lng);
  };

  return (
    <>
      <b>Location & Description</b>
      <hr />
      <FormSearchPlaceAutocomplete
        control={form.control}
        label='Address 1'
        name='jobAddress'
        onSelect={handlePlaceSelect}
        Layout={HorizontalLayout}
        placeholder='ex: 123 Main St'
      />
      <FormInput
        label='Address 2'
        control={form.control}
        name='jobAddress2'
        Layout={HorizontalLayout}
        placeholder='Apt/Suite/Floor (optional)'
      />
      <FormInput label='City' name='jobCity' Layout={HorizontalLayout} />
      <FormInput label='State' name='jobState' Layout={HorizontalLayout} />
      <FormInput label='Zip Code' name='jobZip' Layout={HorizontalLayout} />
      <FormInput name='lat' type='hidden' className='d-none' disabled Layout={HorizontalLayout} />
      <FormInput name='lng' type='hidden' className='d-none' disabled Layout={HorizontalLayout} />
      <FormInput label='Country' name='jobCountry' Layout={HorizontalLayout} />

      {isAllowed(moduleConstants.MD) && (
        <div className='mt-2'>
          <FormInput
            label='Marketing Description'
            control={form.control}
            name='marketingDescription'
            textArea
            Layout={HorizontalLayout}
          />
        </div>
      )}
    </>
  );
};
