import { createSelector } from '@reduxjs/toolkit';

const selectAllBuildingType = (state) => state.buildingType.allBuildingType;

export const selectOptionTemplateBuildingType = createSelector(
  selectAllBuildingType,
  (allBuildingType) => {
    return allBuildingType
      .filter(
        (val) => val.objectId !== '' && val.buildingTypeName !== '' && val.buildingTypeCode !== ''
      )
      .map((val) => ({
        label: `${val.buildingTypeName} (${val.buildingTypeCode})`,
        value: val.objectId,
      }));
  }
);
