import { useEffect } from "react";

const useEventListener = ({ref, handler, on}) => {
    useEffect(() => {
        if (ref.current) {
          ref.current.addEventListener(on, handler);
        }

        return () => {
          ref.current.removeEventListener(on, handler);
        }
      }, []);
}

export default useEventListener;