import { LayoutProps } from './layout-props';

export const ViewLayout = ({
  label,
  input,
  inputText,
  prefix,
  textValue,
  urlLabel,
  viewMode = 'text',
  renderMode = 'default',
}: LayoutProps) => {
  const notAvailable = '-';
  let content: any = textValue || notAvailable;

  switch (viewMode) {
    case 'component':
      content = input;
      break;
    case 'url':
      content = (typeof textValue === 'string' && textValue?.length>0)
        ? <a href={textValue} target='_blank' rel='noreferrer'>{urlLabel}</a>
        : notAvailable;
      break;
    case 'text':
      if (!textValue) {
        prefix = null;
        inputText = null;
      }
  }

  let markUp: any = null;

  switch (renderMode) {
    case 'table':
      markUp = (
        <tr>
          <td>{label}</td>
          <td>
            {prefix}
            {content}
            {inputText}
          </td>
        </tr>
      );
      break;
    case 'default':
      markUp = (
        <>
        <div className='col-lg-3 col-md-4 col-sm-5 col-xs-6 mb-1'>
          {label}:
          </div>
        <div className='col-lg-9 col-md-8 col-sm-7 col-xs-6 mb-1'>
            {prefix}
          {content}
          {inputText}
        </div>
        </>
      );
  }

  return markUp;
};
