import { Radio, Space } from 'antd';
import { Controller, useFormContext } from 'react-hook-form';

export const ShowByControl = () => {
  const form = useFormContext();

  const onChange = (e: any) => {
    if (form.getValues('startDateOption') === 'other') return;

    switch (e.target.value) {
      case 'hours':
        form.setValue('startDateOption', 'today');
        break;
      case 'days':
        form.setValue('startDateOption', 'today');
        break;
      case 'weeks':
        form.setValue('startDateOption', 'thisWeek');
        break;
      case 'months':
        form.setValue('startDateOption', 'thisMonth');
        break;
    }
  };

  return (
    <Controller
      name='showBy'
      control={form.control}
      render={({ field }) => (
        <div className='d-flex flex-column'>
          <span className='h6'>Show results by:</span>
          <Radio.Group
            className='ms-3'
            value={field.value}
            onChange={(e) => field.onChange(e.target.value)}
          >
            <Space direction='vertical'>
              <Radio value={'hours'} onChange={onChange}>
                Hours
              </Radio>
              <Radio value={'days'} onChange={onChange}>
                Days
              </Radio>
              <Radio value={'weeks'} onChange={onChange}>
                Weeks
              </Radio>
              <Radio value={'months'} onChange={onChange}>
                Months
              </Radio>
            </Space>
          </Radio.Group>
        </div>
      )}
    />
  );
};
