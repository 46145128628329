import * as actionTypes from '../actions/actionTypes';

const initState = {
  isRoleCreated: false,
  allCustomFields: [],
};

export default (state = initState, action = {}) => {
  switch (action.type) {
    case actionTypes.CREATE_CUSTOM_FIELD_REQUEST:
      return {
        ...state,
        allCustomFields: [],
        isRoleCreated: false,
      };
    case actionTypes.CREATE_CUSTOM_FIELD_SUCCESS:
      return {
        ...state,
        allCustomFields: action.payload.response.result.data,
        isRoleCreated: true,
      };

    case actionTypes.CREATE_CUSTOM_FIELD_ERROR:
      return {
        ...state,
        allCustomFields: [],
        isRoleCreated: false,
      };

    case actionTypes.GET_ALL_CUSTOM_FIELD_REQUEST:
      return {
        ...state,
        allCustomFields: [],
        isRoleCreated: false,
      };

    case actionTypes.GET_ALL_CUSTOM_FIELD_SUCCESS:
      return {
        ...state,
        allCustomFields: action.payload.response.result.data,
        isRoleCreated: false,
      };

    case actionTypes.GET_ALL_CUSTOM_FIELD_ERROR:
      return {
        ...state,
        allCustomFields: [],
        isRoleCreated: false,
      };

    case actionTypes.UPDATE_CUSTOM_FIELD_REQUEST:
      return {
        ...state,
        allCustomFields: [],
        isRoleCreated: false,
      };
    case actionTypes.UPDATE_CUSTOM_FIELD_SUCCESS:
      return {
        ...state,
        allCustomFields: action.payload.response.result.data,
        isRoleCreated: true,
      };
    case actionTypes.UPDATE_CUSTOM_FIELD_ERROR:
      return {
        ...state,
        isRoleCreated: false,
        allCustomFields: [],
      };

    case actionTypes.DELETE_CUSTOM_FIELD_REQUEST:
      return {
        ...state,
        allCustomFields: [],
        isRoleCreated: false,
      };
    case actionTypes.DELETE_CUSTOM_FIELD_SUCCESS:
      return {
        ...state,
        allCustomFields: [],
        isRoleCreated: true,
      };
    case actionTypes.DELETE_CUSTOM_FIELD_ERROR:
      return {
        ...state,
        isRoleCreated: false,
        allCustomFields: [],
      };

    case actionTypes.GET_CUSTOM_FIELD_REQUEST:
      return {
        ...state,
        allCustomFields: [],
        isRoleCreated: false,
      };

    case actionTypes.GET_CUSTOM_FIELD_SUCCESS:
      return {
        ...state,
        allCustomFields: action.payload.response.result.data.data,
        isRoleCreated: false,
      };

    case actionTypes.GET_CUSTOM_FIELD_ERROR:
      return {
        ...state,
        allCustomFields: [],
        isRoleCreated: false,
      };
    case actionTypes.SAVE_CUSTOM_FIELD_VALUE_REQUEST:
      return {
        ...state,
        allCustomFieldsValue: [],
        isRoleCreated: false,
      };
    case actionTypes.SAVE_CUSTOM_FIELD_VALUE_SUCCESS:
      return {
        ...state,
        isRoleCreated: true,
        allCustomFieldsValue: action.payload.response.result.data.data,
      };
    case actionTypes.SAVE_CUSTOM_FIELD_VALUE_ERROR:
      return {
        ...state,
        allCustomFieldsValue: [],
        isRoleCreated: false,
      };
    default:
      return state;
  }
};
