import _ from 'lodash';
import { useSelector } from 'react-redux';
import {
  selectDisableAdminSetting,
  selectDisableUserSetting,
  selectEnableAdminSetting,
  selectEnableUserSetting,
} from '../../modules/settings/selectors';

const setActionButton =
  (onActionPerformed, isSuperAdmin = false) =>
  ({ value, row }) => {
    const _id = row.original?._id;
    const modelName = row.original.modelName;

    const { loading: enabling } = useSelector(selectEnableUserSetting(_id));
    const { loading: disabling } = useSelector(selectDisableUserSetting(_id));
    const { loading: adminEnabling } = useSelector(selectEnableAdminSetting(_id));
    const { loading: adminDisabling } = useSelector(selectDisableAdminSetting(_id));

    const loading = enabling || disabling || adminEnabling || adminDisabling;

    const disabled = modelName === 'cppRoles' && row.original.assignedContactsCount !== 0;

    // disabled to enabling due to logic complexity
    // TODO: make decision what to do with that
    if (isSuperAdmin && value) return null;

    return (
      <i
        style={{
          fontSize: '20px',
          color: value ? '#357a38' : '#b2102f',
        }}
        className={`fa fa-${value ? 'plus' : 'minus'}-circle ${
          loading || disabled ? 'text-muted' : ''
        }`}
        aria-hidden='true'
        onClick={(e) => {
          e.persist();
          e.stopPropagation();

          if (loading || disabled) return;

          onActionPerformed(row.original);
        }}
      />
    );
  };

const settingsTableColumns = (modelName, onActionPerformed, isSuperAdmin = false) => {
  const result = [];

  if (modelName === 'projectRoleTypes') {
    result.push({
      Header: 'Type',
      accessor: 'type',
      width: 150,
      Cell: ({ value }) => {
        if (value === 'external') return 'External';
        if (value === 'internal') return 'Internal';
        return null;
      },
    });
  }

  result.push({
    Header: 'Name',
    accessor: 'name',
    searchable: true,
    width: 290,
  });

  result.push({
    Header: 'Code',
    accessor: 'code',
    searchable: true,
    width: 64,
  });

  result.push({
    Header: 'Definition',
    accessor: 'definition',
    width: 300,
    Cell: (props) =>
      _.truncate(props.value, {
        length: 50,
        omission: '...',
      }),
  });

  if (onActionPerformed) {
    result.push({
      Header: '',
      accessor: 'trash',
      width: 30,
      Cell: setActionButton(onActionPerformed, isSuperAdmin),
    });
  }

  return result;
};

export default settingsTableColumns;
