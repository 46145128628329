import FormCheckbox from '../../form-controls/form-checkbox';
import { CustomFieldsCommonProps } from '../constants';

type CustomCheckboxProps = {
  //
} & CustomFieldsCommonProps;

export const CustomCheckbox = ({ ...props }: CustomCheckboxProps) => {
  return <FormCheckbox {...props} />;
};
