import Joi from 'joi';
import { Moment } from 'moment';
import moment from 'moment';
import { joiAnyString, joiMoment } from '../../utils/validation';
import { currencyToNumber } from '../../utils/number-formatters';
// import { appConstants } from '../../_constants';

export type AddProjectModalSchema = {
  jobName: string;
  jobNumPrefix: string;
  relatedJobNum: string;
  relatedAltJobNum: string;
  jobNum: number;
  altJobNum: string;
  altServiceNum: string;
  jobStatusCodes: string | null;
  jobStatusCodesCode: string | null;
  marketingDescription: string;
  jobNotes: string;
  updateComment: string | null;
  isRef: boolean;
  startDate: Moment | null;
  endDate: Moment | null;
  bidDue: Moment | null;
  bidStart: Moment | null;
  bidSubmittedDate: Moment | null;
  ntpDate: Moment | null;
  deliveredDate: Moment | null;
  followUpDone: Moment | null;
  closeoutDate: Moment | null;
  contractAmount: string | null;
  grossProfit: string | null;
  sqFeet: number | null;
  accountExec: string[];
  projectManager: string[];
  projectSupervisor: string[];
  estimator: string[];
  foreman: string[];
  jobAddress: string;
  jobAddress2: string;
  jobCity: string;
  jobState: string;
  jobZip: string;
  jobCountry: string;
  apNum: string;
  lat: number;
  lng: number;
  buildingType: string | null;
  scope: string[];
  occupancyType: string | null;
  negotiationMethod: string | null;
  permitNo: string;
  isBondRqd: boolean;
  leadDate: Moment | null;
  planLoc: string;
  leadSource: string;
  leadCohort: string;
  billToContact: string[] | null;
  client: string[] | null;
  buildingOwner: string[] | null;
  lender: string[] | null;
  architect: string[];
  agent: string[];
  broker: string[];
  tenant: string[];
  warrPeriod: string | null;
  lienPeriod: number | null;
  duration: string | null;
  projectTeam: { [projectRole: string]: string };
  createdFrom: string;
  timezone: string;
  isWarrantyService : boolean;
};

export const addProjectModalSchema = Joi.object({
  jobName: Joi.string().trim().empty('').required().label('Job Name'),
  jobNumPrefix: Joi.string().allow('').label('Job Number Prefix'),
  altServiceNum: Joi.string().allow('', null).label('Alternate Service Number'),
  relatedJobNum: Joi.string().allow('', null).label('Related Job Number'),
  jobNum: Joi.number().integer().positive().required().label('Job Number'),
  altJobNum: Joi.string().allow('', null).label('Alternate Job Number'),
  relatedAltJobNum: Joi.string().allow('', null).label('Related Alternate Job Number'),
  jobStatusCodes: Joi.string().allow(null).empty('').empty(null).required().label('Project Status'),
  jobStatusCodesCode: Joi.string().allow('', null).label('Project Status Code'),
  marketingDescription: Joi.string().allow('').label('Marketing Description'),
  jobNotes: Joi.string().allow('').label('Job Notes'),
  updateComment: Joi.string().allow(null,'').label('Update Comment'),
  isRef: Joi.boolean().label('Show as a reference'),
  // if start date is set, then end date should be greater than or equal to start date
  startDate: joiMoment().label('Start Date').custom((value, helper) => {
    const endDate = helper.state.ancestors[0].endDate;
    if (endDate && value && moment(value).isAfter(endDate)) {
      return helper.message({
        custom: '{#label} should be less than "End Date"',
      });
    }

    return value
  }
),
  endDate: joiMoment().label('End Date'),
  bidDue: joiMoment().allow(null).label('Bid Due Date'),
  bidStart: joiMoment().allow(null).label('Estimating Start Date').custom((value, helper) => {
    const bidDue = helper.state.ancestors[0].bidDue;
    if (bidDue && value && moment(value).isAfter(bidDue)) {
      return helper.message({
        custom: '{#label} should be less than "Bid Due Date"',
      });
    }

    return value
  }),
  bidSubmittedDate: joiMoment().allow(null).label('Bid Submitted Date'),
  ntpDate: joiMoment().label('NTP Received Date'),
  deliveredDate: joiMoment().allow(null).label('Delivered Date'),
  followUpDone: joiMoment().allow(null).label('Follow Up Done'),
  closeoutDate: joiMoment().allow(null).label('Closeout Date'),
  contractAmount: Joi.string().allow(null, '').label('Contract Amount'),
  grossProfit: Joi.string()
    .allow(null, '')
    .label('Gross Profit')
    .custom((value, helper) => {
      const grossProfitNumber = currencyToNumber(value);
      const contractAmountNumber = currencyToNumber(helper.state.ancestors[0].contractAmount);

      if (grossProfitNumber > contractAmountNumber) {
        return helper.message({
          custom: '{#label} should be less than "Contract Amount"',
        });
      }

      return value;
    })
    .label('Profit'),
  sqFeet: Joi.number().allow(null, '').optional().label('Sq. Feet'),
  accountExec: Joi.array().items(Joi.string()).label('Account Executive'),
  projectManager: Joi.array().items(Joi.string()).label('Project Manager'),
  projectSupervisor: Joi.array().items(Joi.string()).label('Superintendent'),
  estimator: Joi.array().items(Joi.string()).label('Estimator'),
  foreman: Joi.array().items(Joi.string()).label('Project Engineer'),
  jobAddress: Joi.string().allow('').label('Job Street Address'),
  jobAddress2: Joi.string().allow('').label('Job Street Address 2'),
  jobCity: Joi.string().allow('').label('Job City'),
  jobState: Joi.string().allow('').label('Job State'),
  jobZip: Joi.string().allow('').label('Job Zip Code'),
  jobCountry: joiAnyString().label('Job Country'),
  apNum: joiAnyString().label('Assessor Parcel Number'),
  lat: Joi.number().allow(null, '').optional(),
  lng: Joi.number().allow(null, '').optional(),
  buildingType: Joi.string().allow(null).label('Building Type'),
  scope: Joi.array().items(Joi.string()).label('Job Scope'),
  occupancyType: Joi.string().allow(null).label('Occupancy Type'),
  negotiationMethod: Joi.string().allow(null).label('Negotiating Method'),
  permitNo: Joi.string().allow(null, '').label('Building Permit'),
  isBondRqd: Joi.boolean().label('Bond Required'),
  leadDate: joiMoment().label('Lead Date'),
  planLoc: joiAnyString().label('Plan Location'),
  leadSource: Joi.string().empty('').allow('').label('Lead Source'),
  leadCohort: joiAnyString().label('Lead Cohort'),
  billToContact: Joi.array()
    .items(Joi.string())
    .label('Bill To')
    .failover((v: any) => (v.billToContact ? [v.billToContact] : [])),
  client: Joi.array()
    .items(Joi.string())
    .label('Client')
    .failover((v: any) => (v.client ? [v.client] : [])),
  buildingOwner: Joi.array()
    .items(Joi.string())
    .label('Property Owner')
    .failover((v: any) => (v.buildingOwner ? [v.buildingOwner] : [])),
  lender: Joi.array()
    .items(Joi.string())
    .label('Lender')
    .failover((v: any) => (v.lender ? [v.lender] : [])),
  architect: Joi.array().items(Joi.string()).label('Architect'),
  agent: Joi.array().items(Joi.string()).label('Agent'),
  broker: Joi.array().items(Joi.string()).label('Broker'),
  tenant: Joi.array().items(Joi.string()).label('Tenant'),
  warrPeriod: Joi.number().integer().allow(null).label('Warranty Period'),
  lienPeriod: Joi.number().integer().allow(null).label('Lien Period'),
  duration: Joi.string().trim().empty('').label('Duration'),
  projectTeam: Joi.object().pattern(Joi.string(), Joi.array().items(Joi.string())),
  createdFrom: Joi.string().allow(null, '').failover('WEB').label('Created From'),
  timezone: Joi.string().allow(null, '').label('Time Zone'),
  isWarrantyService: Joi.boolean().label('Is Warranty Service'),
});
