import FormMultiselect from '../../form-controls/form-multiselect';
import { useFormContext } from 'react-hook-form';
import { FormRadio } from '../../form-controls/form-radio';

export const RadioInputSection = () => {
  const form = useFormContext();

  const options = form.watch('valueDefinition.options', []);

  return (
    <div>
      <div className='row'>
        <div className='col-12'>
          <label className='form-label'>Radio Options</label>
        </div>

        <div className='col-6'>
          <div className='row'>
            <div className='col-12'>
              <FormMultiselect
                label='Options'
                name='valueDefinition.options'
                creatable
                useObjectValue
                options={[]}
              />
            </div>
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='col-6'>
          <FormRadio name='valueDefinition.defaultValue' label='Default Value' options={options} />
        </div>
      </div>
    </div>
  );
};
