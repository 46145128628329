import { Checkbox, Radio, Space } from 'antd';
import { useEffect, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { selectGetProjectRoles } from '../../../modules/settings/selectors';
import FormSelect from '../../../shared/form-controls/form-select';
import { useAppSelector } from '../../../shared/hooks/use-app-selector';

type GroupByControlProps = {
  options: any[];
};

export const GroupByControl = ({ options = [] }: GroupByControlProps) => {
  const { data: projectRoles } = useAppSelector(selectGetProjectRoles);

  const radioRoles = useMemo(() => {
    return options.map((option) => {
      if (option.value === 'none')
        return (
          <Radio key={option.value} value={option.value}>
            None
          </Radio>
        );

      const role = projectRoles?.find((role: any) => role?.code === option?.code);

      if (!role) return null;

      return (
        <Radio key={option.value} value={option.value}>
          {role.name}
        </Radio>
      );
    });
  }, [projectRoles, options]);

  const form = useFormContext();

  const groupBy = form.watch('groupBy');

  const [showIsGroupShared, groupSharedName] = useMemo(() => {
    const role = projectRoles?.find((role: any) => role._id === groupBy);
    return [role?.isMultiAssignable || false, role?.name || 'N/A'];
  }, [groupBy, projectRoles]);

  // always set isGroupShared to false when changing groupBy
  useEffect(() => {
    // if groupBy or groupByOption dirty, set isGroupShared to false
    if (form.formState.dirtyFields.groupBy || form.formState.dirtyFields.groupByOption) {
      form.setValue('isGroupShared', false);
    }
  }, [groupBy]);

  const groupByOption = form.watch('groupByOption');
  // when groupByOption is changed, set groupBy to appropriate role
  useEffect(() => {

    const isNoDefaultGroupBySelected = ['other','none'].includes(groupByOption);
    if ((!form.formState.dirtyFields.groupByOption 
      &&
      (!form.getValues('groupBy')&&isNoDefaultGroupBySelected))
      || projectRoles?.length==0) return;

    if (groupByOption === 'other') {
      form.setValue('groupBy', null);
      return;
    }

    if (groupByOption === 'none') {
      form.setValue('groupBy', null);
      return;
    }

    const selectedOption = options.find((option) => option.value === groupByOption);

    const role = projectRoles?.find((role: any) => role?.code === selectedOption?.code);

    form.setValue('groupBy', role?._id);
  }, [groupByOption, options, projectRoles]);

  return (
    <div className='d-flex flex-column'>
      <span className='h6'>Group by:</span>

      <Controller
        name='groupByOption'
        control={form.control}
        render={({ field }) => (
          <Radio.Group className='ms-3' value={field.value} onChange={field.onChange}>
            <Space direction='vertical' className='w-100'>
              {radioRoles}

              <div className='d-flex align-items-center'>
                <Radio value='other'>
                  <span>Other</span>
                </Radio>
                {field.value === 'other' && (
                  <FormSelect
                    label=''
                    options={projectRoles}
                    className='w-100'
                    inputClassName='prevent-ant-rg-fs mb-0'
                    name='groupBy'
                    control={form.control}
                    getOptionValue={(o) => o._id}
                    formatOptionLabel={(o) => o.name}
                  />
                )}
              </div>
            </Space>
          </Radio.Group>
        )}
      />

      {showIsGroupShared && (
        <Controller
          name='isGroupShared'
          control={form.control}
          render={({ field }) => (
            <Checkbox className='mt-2 ms-3' checked={field.value} onChange={field.onChange}>
              Group shared <i>{groupSharedName}s</i>
            </Checkbox>
          )}
        />
      )}
    </div>
  );
};
