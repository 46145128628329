import * as actionTypes from './actionTypes';
import * as API from '../api/contactRole';

export const createContactRoleRequest = () => ({
  type: actionTypes.CREATE_CONTACT_ROLE_REQUEST,
});

export const createContactRoleSuccess = (response) => ({
  type: actionTypes.CREATE_CONTACT_ROLE_SUCCESS,
  payload: {
    response,
  },
});

export const createContactRoleError = (error) => ({
  type: actionTypes.CREATE_CONTACT_ROLE_ERROR,
  payload: {
    error,
  },
});

export const createContactRoleAction = (data) => {
  return (dispatch) => {
    dispatch(createContactRoleRequest());
    return API.createContactRole(data)
      .then((response) => {
        dispatch(createContactRoleSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          createContactRoleError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

export const getAllContactRoleRequest = () => ({
  type: actionTypes.GET_ALL_CONTACT_ROLE_REQUEST,
});

export const getAllContactRoleSuccess = (response) => ({
  type: actionTypes.GET_ALL_CONTACT_ROLE_SUCCESS,
  payload: {
    response,
  },
});

export const getAllContactRoleError = (error) => ({
  type: actionTypes.GET_ALL_CONTACT_ROLE_ERROR,
  payload: {
    error,
  },
});

export const getAllContactRoleAction = (data) => {
  return (dispatch) => {
    dispatch(getAllContactRoleRequest());
    return API.getAllContactRole(data)
      .then((response) => {
        dispatch(getAllContactRoleSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          getAllContactRoleError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

export const updateContactRoleRequest = () => ({
  type: actionTypes.UPDATE_CONTACT_ROLE_REQUEST,
});

export const updateContactRoleSuccess = (response) => ({
  type: actionTypes.UPDATE_CONTACT_ROLE_SUCCESS,
  payload: {
    response,
  },
});

export const updateContactRoleError = (error) => ({
  type: actionTypes.UPDATE_CONTACT_ROLE_ERROR,
  payload: {
    error,
  },
});

export const updateContactRoleAction = (data) => {
  return (dispatch) => {
    dispatch(updateContactRoleRequest());
    return API.updateContactRole(data)
      .then((response) => {
        dispatch(updateContactRoleSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          updateContactRoleError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

export const deleteContactRoleRequest = () => ({
  type: actionTypes.DELETE_CONTACT_ROLE_REQUEST,
});

export const deleteContactRoleSuccess = (response) => ({
  type: actionTypes.DELETE_CONTACT_ROLE_SUCCESS,
  payload: {
    response,
  },
});

export const deleteContactRoleError = (error) => ({
  type: actionTypes.DELETE_CONTACT_ROLE_ERROR,
  payload: {
    error,
  },
});

export const deleteContactRoleAction = (data) => {
  return (dispatch) => {
    dispatch(deleteContactRoleRequest());
    return API.deleteContactRole(data)
      .then((response) => {
        dispatch(deleteContactRoleSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          deleteContactRoleError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};
