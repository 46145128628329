
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { deleteCompany, getSingleAffiliatedContacts } from '../../../modules/companies';
import { selectGetSingleAffiliatedContacts } from '../../../modules/companies/selectors';
import { useAppDispatch } from '../../../shared/hooks/use-app-dispatch';
import { useSubmit } from '../../../shared/hooks/use-submit';

export type DeleteCompanyProps = {
  companyId: string;
  onSubmit?: (...args: any) => unknown;
  onClose?: () => unknown;
  open: boolean;
};

// Prompt user, 'X' contacts are linked only to this company, would you like to remove these affiliations
// or delete these contacts?
//
export const DeleteCompanyModal = ({ companyId, onSubmit, onClose, open }: DeleteCompanyProps) => {
  const dispatch = useAppDispatch();

  const { data } = useSelector(selectGetSingleAffiliatedContacts);

  useEffect(() => {
    dispatch(getSingleAffiliatedContacts({ companyId }));
  }, [open]);

  const [onDeleteCompany, companySubmitting] = useSubmit(async () => {
    await dispatch(deleteCompany({ companyId }));

    onClose?.();
    onSubmit?.();
  }, []);

  const [onDeleteContacts, contactsSubmitting] = useSubmit(async () => {
    await dispatch(deleteCompany({ companyId, deleteSingleContacts: true }));
    await dispatch(getSingleAffiliatedContacts({ companyId }));

    onClose?.();
    onSubmit?.();
  });

  return (
    <Modal isOpen={open}>
      <ModalHeader toggle={() => onClose?.()}>Delete Company</ModalHeader>
      <ModalBody>
        <div className='container'>
          <p>
            {data.total ? (
              <>
                <b>{data?.total}</b> contacts are linked only to this company, would you like to
                remove these affiliations or delete these contacts?
              </>
            ) : (
              <>Are you sure you want to delete this company?</>
            )}
          </p>
        </div>
      </ModalBody>
      <ModalFooter>
        <button className='btn btn-primary' onClick={() => onClose?.()}>
          Cancel
        </button>
        {data.total ? (
          <button
            className='btn btn-danger'
            disabled={contactsSubmitting}
            onClick={onDeleteContacts}
          >
            Delete Contacts
          </button>
        ) : null}

        <button disabled={companySubmitting} className='btn btn-danger' onClick={onDeleteCompany}>
          Delete Company
        </button>
      </ModalFooter>
    </Modal>
  );
};
