import { createSelector } from '@reduxjs/toolkit';

const selectAllJobScope = (state) => state.jobScope.allJobScope;

export const selectAllJobScopes = createSelector(
    selectAllJobScope,
    (allJobScope) =>
    allJobScope?.map(({ jobScopeName, objectId, isSystemMaster, trash, lineItemId, isBundle }) => ({
        label: jobScopeName,
        value: objectId,
        isSystemMaster: isSystemMaster,
        trash: trash,
        lineItemId: lineItemId,
        isBundle: isBundle,
      }))
  );

export const selectOptionTemplateJobScope = createSelector(selectAllJobScope, (allJobScope) => {
return allJobScope
    .filter((val) => val.objectId !== '' && val.jobScopeName !== '' && val.trash === false && val.isSystemMaster === false)
    .map((val) => ({
    label: val.jobScopeName,
    value: val.objectId,
    }));
});