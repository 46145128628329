import { createSelector } from '@reduxjs/toolkit';
import _ from 'lodash';

export const selectGetResourceScheduleFilters = (state) =>
  state.rtk.reports.getResourceScheduleFilters;

export const selectGetResourceSchedule = (state) => state.rtk.reports.getResourceSchedule;

const selectResourceScheduleFilterColors = createSelector(
  (state) => state.rtk.reports.getResourceScheduleFilters.data.jobStatusFilter,
  (filters) =>
    filters
      ? Object.values(filters)
          .flat()
          .reduce((colors, filter) => {
            colors[
              filter.jobStatusCodesName
            ] = `rgba(${filter.jobColorCode.r},${filter.jobColorCode.g},${filter.jobColorCode.b},${filter.jobColorCode.a})`;

            return colors;
          }, {})
      : null
);

export const selectResourceSchedulerData = createSelector(
  (state) => state.rtk.reports.getResourceSchedule.data.data,
  selectResourceScheduleFilterColors,
  (items, colors) => {
    const initData = { resources: [], events: [] };

    if (!items || !colors) {
      return initData;
    }

    return items.reduce((data, item) => {

      const clientData = item.clientData;
      const clientId = clientData.objectId ?? 'unassigned';
      

      const clientProjects = item.projectData.map((project) => {
        const projectPerClientId = `${clientId}-${project.objectId}`;

        const uniquePerRowProjectEvents = project.extraData?.filter(
          (event, eventIdx, events) =>
            eventIdx === 0 || event.jobStatusCodesName !== events[eventIdx - 1].jobStatusCodesName
        );

        if (uniquePerRowProjectEvents?.length > 1) {
          // add missing Bidding event for old cases when Lead-to-Bidding promotion was broken
          if (
            project.jobStatusCodesCode !== 'L' &&
            uniquePerRowProjectEvents[0].jobStatusCodesCode === 'L' &&
            uniquePerRowProjectEvents[1]?.jobStatusCodesCode !== 'B'
          ) {
            uniquePerRowProjectEvents.splice(1, 0, {
              objectId: `${projectPerClientId}-leadPromoted`,
              jobStatusCodesCode: 'B',
              eventDate: { iso: project.leadPromotedDate.iso },
            });
          }
        }

        const resourceEvents = uniquePerRowProjectEvents?.map((event, eventIdx, events) => ({
          id: `${projectPerClientId}-${event.objectId}`,
          resourceId: projectPerClientId,
          name: event.jobStatusCodesName,
          startDate: event.eventDate.iso,
          endDate: events[eventIdx + 1]?.eventDate.iso ?? project.endDate.iso,
          eventColor: colors[event.jobStatusCodesName],
        }));

        if (resourceEvents) {
          if (project.leadDate) {
            resourceEvents.push({
              id: `${projectPerClientId}-leadEventMarker`,
              resourceId: projectPerClientId,
              name: 'Lead',
              startDate: project.leadDate.iso,
              endDate: project.leadDate.iso,
            });
          }

          if (project.bidDue) {
            resourceEvents.push({
              id: `${projectPerClientId}-bidDueEventMarker`,
              resourceId: projectPerClientId,
              name: 'Bid due',
              startDate: project.bidDue.iso,
              endDate: project.bidDue.iso,
            });
          }

          if (project.deliveredDate) {
            resourceEvents.push({
              id: `${projectPerClientId}-deliveredEventMarker`,
              resourceId: projectPerClientId,
              name: 'Delivered',
              startDate: project.deliveredDate.iso,
              endDate: project.deliveredDate.iso,
            });
          }

          data.events.push(...resourceEvents);
        }

        return {
          id: projectPerClientId,
          projectId: project.objectId,
          jobNumber: project.jobNumFormatted,
          alternateJobNumber: project.altJobNum,
          jobName: project.jobName,
          status: project.jobStatusCodesName,
          statusCode: project.jobStatusCodesCode,
          startDate: project.startDate?.iso,
          deliveredDate: project.deliveredDate?.iso,
          endDate: project.endDate?.iso,
          bidDueDate: project.bidDue?.iso,
          leadDate: project.leadDate?.iso,
        };
      });

      const resource = {
        id: clientId,
        isCompany: clientData.isCompany,
        linkId: clientData.isCompany?clientData.companyId:clientData.contactId,
        jobName: clientData.displayNameWithCompany || 'Unassigned',
        unassigned: item.isUnassigned,
        children: clientProjects,
        expanded: true,
        imageUrl: clientData?.userImage,
        eventColor: clientData?.calendarColor
      };

      // prevent duplicated events ids
      data.events = data.events.map((e) => ({ ...e, id: _.uniqueId(`${e.id}-`) }));
      data.resources.push(resource);

      return data;
    }, initData);
  }
);

export const selectGetEstimatingScheduleReport = (state) =>
  state.rtk.reports.getEstimatingScheduleReport;

export const selectGetActiveScheduleReport = (state) => state.rtk.reports.getActiveScheduleReport;

export const selectProcessTemplateFile = (state) => state.rtk.reports.processTemplateFile;
