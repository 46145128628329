import { useFormContext } from 'react-hook-form';
import FormInput from '../../../../shared/form-controls/form-input';
import { HorizontalLayout } from '../../../../shared/form-controls/control-layouts/horizontal-layout';

type BottomFieldsProps = {
  isLocked: boolean;
  isActiveProject: boolean;
};

export const BottomFields = ({ isLocked = false, isActiveProject = false }: BottomFieldsProps) => {
  const { control } = useFormContext();

  return (
    <>
      <FormInput
        textArea
        label='Exclusions'
        control={control}
        name='exclusions'
        disabled={isLocked}
        Layout={HorizontalLayout}
      />
      {isActiveProject && !isLocked && (
        <FormInput
          textArea
          label='Update Comment'
          control={control}
          name='comment'
          disabled={isLocked}
          Layout={HorizontalLayout}
        />
      )}
    </>
  );
};
