import React, { Suspense, useEffect } from 'react';
import ReactLoading from 'react-loading';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, withRouter } from 'react-router-dom';
import { getExistingProjectAction } from '../../../actions/project';
import {
  GetCompanyJobListPrefAction,
  GetCompanyJobListReportAction,
  SetCompanyJobListPrefAction,
} from '../../../actions/reports';
import { Tooltip } from 'react-tooltip';

import { getCompany } from '../../../modules/companies';
import { selectGetCompany } from '../../../modules/companies/selectors';
import { formatDate } from '../../../utils/date-formatters';
import { appConstants } from '../../../_constants';
import { DateColumnFilter } from '../../Common/CustomTableComponents/DateColumnFilter';
import TitleComponent from '../../Common/TitleComponent';
import Footer from '../../Layout/Footer.js';
import Header from '../../Layout/Header.js';
import loaderImage from '../../static/images/loading_spinner.gif';
import {
  formatExternalContactData,
} from '../../../utils/contact-formatters';

import { CustomTable, multiSelectFilter } from '../../Common/CustomTable';
import { SelectColumnFilter } from '../../Common/CustomTableComponents/SelectColumnFilter';
import { getAllProjectFieldsAction } from '../../../actions/projectFields';
import { isAllowed, moduleConstants } from '../../../_constants';
import {buildColumnForField, buildDynamicColumns, onCurrency} from '../../../utils/column-formatters';


let companyNameInfo = '';

// TODO: needs to be refactored
class CompanyJobListReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accountId: localStorage.getItem('accountId'),
      userId: localStorage.getItem('userId'),
      list: [],
      fetchRequest: true,
      contactData: [],
      companyData: [],
      dynamicColumns: [],
      comLogo: '',
      companyId: '',
      totalCount: 0,
      currentPage: 1,
      sizePerPage: appConstants.TableSizePerPageList[2].value,
      searchText: '',
      projectData: {},
      sortType: 'DESC',
      sortKey: '',
      allProjectFieldTypes: [],
    };
    this.onProjectName = this.onProjectName.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.listData !== this.props.listData) {
      this.setState({ fetchRequest: true });
    }

    let listData = [];
    let companyData = [];
    let contactData = [];
    let i = 0;
    if (nextProps.listData && nextProps.listData.data && nextProps.listData.data.length > 0) {
      nextProps.listData.data.map((v) => {
        if (v.projectData && v.projectData.length > 0) {
          v.projectData.map((w) => {
            listData.push(w);
            listData[i].contactRoleName = v.contactRoleName;
            i++;
            return true;
          });
        }
        companyData = v.companyData;
        contactData = v.contactData;
        return true;
      });
      this.setState({
        list: listData,
        companyData: companyData,
        contactData: contactData,
        totalCount: nextProps.listData.count,
        fetchRequest: false,
      });
    } else {
      this.setState({ list: [], totalCount: 0 });
    }

    if (nextProps.listData && nextProps.listData.data && nextProps.listData.data.length == 0) {
      this.setState({ fetchRequest: false });
    }

    if (nextProps?.projectFields?.allProjectFieldTypes?.length > 0) {
      this.setState({
        allProjectFieldTypes: nextProps.projectFields.allProjectFieldTypes,
      });
    }
  }

  componentDidMount() {
    let data = {};
    data['accountId'] = this.state.accountId;

    if (localStorage.getItem('companyLogo') !== 'undefined')
      this.setState({
        comLogo: localStorage.getItem('companyLogo'),
      });

    let _this = this;
    setTimeout(function () {
      _this.setState({ fetchRequest: false });
    }, 2000);
    this.props.GetCompanyJobListPrefAction();

    this.props.getAllProjectFieldsAction({
      accountId: this.state.accountId,
      minimumStage: 'post-construction',
    });
  }

  redirectToProject(cell, row) {
    if (cell === 'jobNumFormatted' && row && row.objectId && row.jobStatusCodesCode === 'NC') {
      return (
        <span>
          <Link
            to={{
              pathname: `/project-details/` + row.objectId,
              state: { row },
            }}
          >
            {row.jobNumFormatted ? row.jobNumFormatted : '-'}
          </Link>
        </span>
      );
    }
    if (cell === 'jobName' && row) {
      return (
        <div>
          <Link
            to={{
              pathname: `/project-details/` + row.objectId,
              state: { row },
            }}
          >
            {row.jobName ? row.jobName : '-'}
          </Link>
        </div>
      );
    }

    if (cell === 'altJobNum' && row) {
      return (
        <div>
          <Link
            to={{
              pathname: `/project-details/` + row.objectId,
              state: { row },
            }}
          >
            {row.altJobNum ? row.altJobNum : '-'}
          </Link>
        </div>
      );
    } else {
      return '';
    }
  }

  onAltJob(cell, enumObject, row) {
    if (
      cell === 'altJobNum' &&
      row &&
      row.objectId &&
      (row.jobStatusCodesCode === 'L' || row.jobStatusCodesCode === 'NP')
    ) {
      return (
        <span>
          <Link to={{ pathname: `/lead-details/` + row.objectId, state: { row } }}>
            {row.altJobNum ? row.altJobNum : '-'}
          </Link>
        </span>
      );
    } else {
      return (
        <span>
          <Link
            to={{
              pathname: `/project-details/` + row.objectId,
              state: { row },
            }}
          >
            {row.altJobNum ? row.altJobNum : '-'}
          </Link>
        </span>
      );
    }
  }

  onJobNum(cell, enumObject, row) {
    if (
      cell === 'jobNumFormatted' &&
      row &&
      row.objectId &&
      (row.jobStatusCodesCode === 'L' || row.jobStatusCodesCode === 'NP')
    ) {
      return <span>-</span>;
    } else {
      return (
        <span>
          <Link
            to={{
              pathname: `/project-details/` + row.objectId,
              state: { row },
            }}
          >
            {row.jobNumFormatted ? row.jobNumFormatted : '-'}
          </Link>
        </span>
      );
    }
  }

  onProjectName(cell, row, enumObject, rowIndex) {
    if (
      row &&
      row.objectId &&
      (row.jobStatusCodesCode === 'L' || row.jobStatusCodesCode === 'NP')
    ) {
      return (
        <span>
          <strong>
            <Link to={{ pathname: `/lead-details/` + row.objectId, state: { row } }}>
              {row.jobName ? row.jobName : '-'}
            </Link>
          </strong>
        </span>
      );
    } else {
      return (
        <span>
          <strong>
            <Link
              to={{
                pathname: `/project-details/` + row.objectId,
                state: { row },
              }}
            >
              {row.jobName ? row.jobName : '-'}
            </Link>
          </strong>
        </span>
      );
    }
  }


  onRole(cell, row, enumObject) {
    let data = row && row.projectRole?.length > 0 ? row.projectRole.join(', ') : '-';
    return data;
  }

  onContacts(cell, row, enumObject) {
    let data =
      row && row.projectContacts
        ? formatExternalContactData(row.projectContacts, this.state.contactData, false, false)
        : '-';
    return data;
  }

  onJobStatus(cell, row, enumObject) {
    if (row && row.jobStatusCodesName) {
      let curStatusCode = row.jobStatusCodesCode;
      return curStatusCode;
    } else {
      return <span>-</span>;
    }
  }

  onProjectDate(cell, row, rowIndex) {
    if (row && typeof row.endDate === 'object') {
      return row.endDate && row.endDate.iso ? formatDate(row.endDate.iso) : '';
    }
  }

  createCustomButtonGroup = () => {
    if (this.state.comLogo === null) {
      let constImage = appConstants.CONTENT_CPOST_LOGO_URL;

      this.setState({
        comLogo: constImage,
      });
    }
    return (
      <div className='pdfLogoBox'>
        <div className='reportHead'>
          <div className='logo'>
            <img src={this.state.comLogo} alt='Upload Logo' />
          </div>
          <h2>Company Job List</h2>
        </div>
        <div className='searchbar'>
          <div className='col-md-12'>
            <div className='analyzedBy'>
              <strong>Jobs Related to This Company:</strong> {this.props.companyName}
            </div>
          </div>
        </div>
      </div>
    );
  };

  _setTableOption() {
    if (this.state.fetchRequest) {
      return (
        <ReactLoading
          className='table-loader'
          type={appConstants.LOADER_TYPE}
          color={appConstants.LOADER_COLOR}
          height={appConstants.LOADER_HEIGHT}
          width={appConstants.LOADER_WIDTH}
        />
      );
    } else {
      return 'No data found..';
    }
  }


  

  buildReportColumns() {
    const hasContractValPermissions = isAllowed(moduleConstants.VIEWCONTRACTVAL, 'anyAssigned');
    //const hasProfitValPermissions = isAllowed(moduleConstants.VIEWPROFIT, 'anyAssigned');
    
    const { list, allProjectFieldTypes } = this.state;

    const dynamicFieldsLoaded = allProjectFieldTypes?.length > 0;

    const defaultFieldCodes = ['OCCTYPE','SCOPE'];

    const customFieldColumns = dynamicFieldsLoaded
      ? buildDynamicColumns(
          allProjectFieldTypes.filter((field) => !defaultFieldCodes.includes(field.code))
        )
      : [];

    const defaultFields = dynamicFieldsLoaded
      ? allProjectFieldTypes.filter((field) => defaultFieldCodes.includes(field.code))
      : [];

    const defaultColumns = [
      {
        Header: 'Job No.',
        accessor: 'jobNumFormatted',
        sortType: 'basic',
        width: 70,
        Cell: (props) => this.onJobNum(props.column.id, props.value, props.row.original),
      },
      {
        Header: 'Alt Job#',
        accessor: 'altJobNum',
        width: 70,
        Cell: (props) => this.onAltJob(props.column.id, props.value, props.row.original),
      },
      {
        Header: 'Job Name',
        accessor: 'jobName',
        width: 140,
        Cell: (props) => this.onProjectName(props.column.id, props.row.original, props.value),
      },
      {
        Header: 'Completion Date',
        accessor: (row) =>
          row?.endDate?.iso
            ? new Date(row.endDate.iso)
            : new Date(formatDate('1800-03-03T12:47:40.000Z')),
        id: 'endDate',
        width: 120,
        Cell: (props) => this.onProjectDate(props.column.id, props.row.original, props.value),
        filter: DateColumnFilter,
        allowDateRangeFilter: true,
        sortType: 'datetime',
      },
      {
        Header: 'Role',
        accessor: 'projectRole',
        width: 110,
        Filter: SelectColumnFilter,
        filter: multiSelectFilter,
        Cell: (props) => this.onRole(props.column.id, props.row.original, props.value),
      },
      {
        Header: 'Contacts',
        accessor: 'projectContactsName',
        width: 110,
        Filter: SelectColumnFilter,
        filter: multiSelectFilter,
        Cell: (props) => this.onContacts(props.column.id, props.row.original, props.value),
      },
      buildColumnForField(defaultFields, 'SCOPE', false),
      buildColumnForField(defaultFields, 'OCCTYPE', false),
      {
        Header: 'Status',
        accessor: 'jobStatusCodesName',
        width: 40,
        Filter: SelectColumnFilter,
        filter: multiSelectFilter,
        Cell: (props) => this.onJobStatus(props.column.id, props.row.original, props.value),
      },
      hasContractValPermissions&&{
        Header: 'Contract Amt.',
        accessor: 'currentContractAmount',
        width: 110,
        Cell: (props) => onCurrency(props.column.id, props.row.original, props.value),
      },
    ];

    let columns = [];
    if (dynamicFieldsLoaded) {
      if (columns.length == 0) {
        columns = defaultColumns.filter((cols) => cols?.accessor);
      }

      for (let i in customFieldColumns) {
        const curColumnsAccessors = columns.map((cols) => cols.accessor);
        if (!curColumnsAccessors.includes(customFieldColumns[i].accessor)) {
          columns = columns.concat(customFieldColumns[i]);
        }
      }
    } else {
      columns = defaultColumns.filter((cols) => cols?.accessor);
    }
    this.setState({ dynamicColumns: columns });

  }

  
  render() {
  
    const {
      list, allProjectFieldTypes, dynamicColumns
    } = this.state;


    const dynamicFieldsLoaded = allProjectFieldTypes?.length > 0;

    //const rolesLoaded = this.state.projectRoleTypes?.length > 0;

    //const dynamicColumnsLoaded = dynamicFieldsLoaded && rolesLoaded;

    
    const dynamicColumnsBuilt = dynamicFieldsLoaded && dynamicColumns.length > 0;

    if(dynamicFieldsLoaded && !dynamicColumnsBuilt){
      this.buildReportColumns();
    }
    
    const { listPref, SetCompanyJobListPrefAction } = this.props;

    return (
      <div>
        {/* <QueryWrapper /> */}
        <div className='ContactListReport'>
          <TitleComponent data={{ title: `Company Job List` }} />
          <div>
            <Suspense fallback='loading'>
              <Header props={this.props} />
            </Suspense>
          </div>
          <div
            className='noteProcessing'
            style={{ display: this.state.showProcessing ? 'block' : 'none' }}
          >
            <img src={loaderImage} alt='Loading' />
          </div>
          <div className='contactBox'>
            <div className='container'>
              <div className='reportHead'>
                <h2>Company Job List</h2>
              </div>

              <div className='searchbar'>
                <div className='col-md-12'>
                  <div className='analyzedBy'>
                    <strong>Jobs Related to This Company:</strong> {this.props.companyName}
                  </div>
                </div>
              </div>

              {dynamicColumnsBuilt && (
                <div className='row'>
                  <div className='col-md-12'>
                    <div
                      className='table-responsive reportTableSection'
                      ref={(el) => (this.componentRef = el)}
                    >
                      {this.createCustomButtonGroup()}
                      <CustomTable
                        columns={dynamicColumns}
                        list={list}
                        preferences={listPref}
                        className='company-job-table'
                        customTableTitle=' '
                        onSavePreferences={(pref) => SetCompanyJobListPrefAction(pref)}
                        isLoading={this.state.fetchRequest}
                        canShowRowSelection={false}
                        componentRef={this.componentRef}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <Footer />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    listData: state.reports.companyJobList,
    listPref: state.reports.companyJobListPref,
    isReportDD: state.reports.isReportDD,
    isReportDDError: state.reports.isReportDDError,
    projectData: state.project.projectData,
    projectFields: state.projectFields,
  };
}

const WithRouter = withRouter(
  connect(mapStateToProps, {
    GetCompanyJobListReportAction,
    GetCompanyJobListPrefAction,
    SetCompanyJobListPrefAction,
    getExistingProjectAction,
    getAllProjectFieldsAction,
  })(CompanyJobListReport)
);

const QueryWrapper = (props) => {
  const location = useLocation();
  const dispatch = useDispatch();

  const companyId = new URLSearchParams(location.search).get('company');

  useEffect(() => {
    dispatch(getCompany({ companyId }));
  }, []);

  const { data: company } = useSelector(selectGetCompany);

  const companyName = `${company?.companyName}`;

  useEffect(() => {
    dispatch(
      GetCompanyJobListReportAction({
        accountId: localStorage.getItem('accountId'),
        moduleName: appConstants.reportModuleName['15'],
        companyId,
        page: 0,
        displayLimit: 99999999,
        sortKey: '',
        sortType: 'DESC',
        search: '',
      })
    );
  }, [companyId]);

  return <WithRouter {...props} companyId={companyId} companyName={companyName} />;
};

export default QueryWrapper;
