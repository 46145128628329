import * as actionTypes from './actionTypes';
import * as API from '../api/multipleContact';
import { callAlertMessage } from '../components/Auth/Auth';

/*-----------------uploads Sheet Company-------------------*/
export const importMultipleCompanyRequest = () => ({
  type: actionTypes.IMPORT_MULTIPLE_COMPANY_REQUEST,
});

export const importMultipleCompanySuccess = (response) => ({
  type: actionTypes.IMPORT_MULTIPLE_COMPANY_SUCCESS,
  payload: {
    response,
  },
});

export const importMultipleCompanyError = (error) => ({
  type: actionTypes.IMPORT_MULTIPLE_COMPANY_ERROR,
  payload: {
    error,
  },
});

export const createMultipleCompanyAction = (data, userUpdate, props) => {
  let propsTemp = props;
  return (dispatch) => {
    dispatch(importMultipleCompanyRequest());
    return API.createMultipleCompany(data)
      .then((response) => {
        callAlertMessage(
          response.data.result.success === 1 ? 'success' : 'error',
          response.data.result.msg
        );
        // callAlertMessage((response.data.result.success===1)?"success":'error',response.data.result.msg);
        dispatch(importMultipleCompanySuccess(response));
      })
      .catch((error) => {
        dispatch(
          importMultipleCompanyError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

/*----------------------upload Contact Sheet------------------------------------*/
export const createMultipleContactRequest = () => ({
  type: actionTypes.CREATE_MULTIPLE_CONTACT_REQUEST,
});

export const createMultipleContactSuccess = (response) => ({
  type: actionTypes.CREATE_MULTIPLE_CONTACT_SUCCESS,
  payload: {
    response,
  },
});

export const createMultipleContactError = (error) => ({
  type: actionTypes.CREATE_MULTIPLE_CONTACT_ERROR,
  payload: {
    error,
  },
});

export const createMultipleContactAction = (data, userUpdate, props) => {
  return (dispatch) => {
    dispatch(createMultipleContactRequest());
    return API.createMultipleContact(data)
      .then((response) => {
        dispatch(createMultipleContactSuccess(response));
      })
      .catch((error) => {
        dispatch(
          createMultipleContactError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

/*------------------------------Upload Project Sheet----------------------------*/
export const uploadMultipleDataSheetRequest = () => ({
  type: actionTypes.UPLOAD_SHEET_DATA_REQUEST,
});

export const uploadMultipleDataSheetSuccess = (response) => ({
  type: actionTypes.UPLOAD_SHEET_DATA_SUCCESS,
  payload: {
    response,
  },
});

export const uploadMultipleDataSheetError = (error) => ({
  type: actionTypes.UPLOAD_SHEET_DATA_ERROR,
  payload: {
    error,
  },
});

export const createMultipleProjectAction = (data, props) => {
  let propsTemp = props;
  return (dispatch) => {
    dispatch(uploadMultipleDataSheetRequest());
    return API.createMultipleProject(data)
      .then((response) => {
        callAlertMessage(
          response.data.result.success === 1 ? 'success' : 'error',
          response.data.result.msg
        );
        dispatch(uploadMultipleDataSheetSuccess(response));
      })
      .catch((error) => {
        dispatch(
          uploadMultipleDataSheetError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

/*----------Get All Project Of CppTempCompanies----------------*/

export const importMultipleContactRequest = () => ({
  type: actionTypes.IMPORT_MULTIPLE_CONTACT_REQUEST,
});

export const importMultipleContactSuccess = (response) => ({
  type: actionTypes.IMPORT_MULTIPLE_CONTACT_SUCCESS,
  payload: {
    response,
  },
});

export const importMultipleContactError = (error) => ({
  type: actionTypes.IMPORT_MULTIPLE_CONTACT_ERROR,
  payload: {
    error,
  },
});

export const getSheetProjectAction = (data, props) => {
  return (dispatch) => {
    dispatch(importMultipleContactRequest());
    return API.getSheetProject(data)
      .then((response) => {
        dispatch(importMultipleContactSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          importMultipleContactError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

/*------------Update status Code----------------------------------*/
export const updateOnboardingStatusRequest = () => ({
  type: actionTypes.UPDATE_ONBOARDING_STATUS_REQUEST,
});

export const updateOnboardingStatusSuccess = (response) => ({
  type: actionTypes.UPDATE_ONBOARDING_STATUS_SUCCESS,
  payload: {
    response,
  },
});

export const updateOnboardingStatusError = (error) => ({
  type: actionTypes.UPDATE_ONBOARDING_STATUS_ERROR,
  payload: {
    error,
  },
});

export const updateOnboardingUserStatus = (data, props) => {
  let propsTemp = props;
  return (dispatch) => {
    dispatch(updateOnboardingStatusRequest());
    return API.updateOnboardingStatus(data)
      .then((response) => {
        localStorage.setItem('onboardingStatus', response.data.result.data.onboardingStatus);
        if (response.data.result.data.onboardingStatus >=1) {
          propsTemp.history.push({
            pathname: '/',
          });
        }else{
          propsTemp.history.push({
            pathname: '/',
          });
        }
        dispatch(updateOnboardingStatusSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          updateOnboardingStatusError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

/*------------Import Contact Social Media Action----------------------------------*/
export const importContactSocialMediaRequest = () => ({
  type: actionTypes.IMPORT_CONTACT_SOCIAL_MEDIA_REQUEST,
});

export const importContactSocialMediaSuccess = (response) => ({
  type: actionTypes.IMPORT_CONTACT_SOCIAL_MEDIA_SUCCESS,
  payload: {
    response,
  },
});

export const importContactSocialMediaError = (error) => ({
  type: actionTypes.IMPORT_CONTACT_SOCIAL_MEDIA_ERROR,
  payload: {
    error,
  },
});

export const importContactSocialMediaAction = (data, props) => {
  let propsTemp = props;
  return (dispatch) => {
    dispatch(importContactSocialMediaRequest());
    return API.importContactSocialMedia(data)
      .then((response) => {
        callAlertMessage(
          response.data.result.success === 1 ? 'success' : 'error',
          response.data.result.msg
        );
        if (response.data.result.success === 1) {
          propsTemp.history.push({
            pathname: '/social-contact-view',
          });
        }
        dispatch(importContactSocialMediaSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          importContactSocialMediaError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

/*------------Import Contact Social Media Action----------------------------------*/
export const getContactSocialMediaRequest = () => ({
  type: actionTypes.GET_CONTACT_SOCIAL_MEDIA_REQUEST,
});

export const getContactSocialMediaSuccess = (response) => ({
  type: actionTypes.GET_CONTACT_SOCIAL_MEDIA_SUCCESS,
  payload: {
    response,
  },
});

export const getContactSocialMediaError = (error) => ({
  type: actionTypes.GET_CONTACT_SOCIAL_MEDIA_ERROR,
  payload: {
    error,
  },
});

export const getContactSocialMediaAction = (data, props) => {
  return (dispatch) => {
    dispatch(getContactSocialMediaRequest());
    return API.getContactSocialMedia(data)
      .then((response) => {
        dispatch(getContactSocialMediaSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          getContactSocialMediaError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

/*------------Edit Contact Social Media Action----------------------------------*/
export const editContactSocialMediaRequest = () => ({
  type: actionTypes.EDIT_CONTACT_SOCIAL_MEDIA_REQUEST,
});

export const editContactSocialMediaSuccess = (response) => ({
  type: actionTypes.EDIT_CONTACT_SOCIAL_MEDIA_SUCCESS,
  payload: {
    response,
  },
});

export const editContactSocialMediaError = (error) => ({
  type: actionTypes.EDIT_CONTACT_SOCIAL_MEDIA_ERROR,
  payload: {
    error,
  },
});

export const editContactSocialMediaAction = (data, props) => {
  return (dispatch) => {
    dispatch(editContactSocialMediaRequest());
    return API.editContactSocialMedia(data)
      .then((response) => {
        dispatch(editContactSocialMediaSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          editContactSocialMediaError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

/*------------Delete Contact Social Media Action----------------------------------*/
export const deleteContactSocialMediaRequest = () => ({
  type: actionTypes.DELETE_CONTACT_SOCIAL_MEDIA_REQUEST,
});

export const deleteContactSocialMediaSuccess = (response) => ({
  type: actionTypes.DELETE_CONTACT_SOCIAL_MEDIA_SUCCESS,
  payload: {
    response,
  },
});

export const deleteContactSocialMediaError = (error) => ({
  type: actionTypes.DELETE_CONTACT_SOCIAL_MEDIA_ERROR,
  payload: {
    error,
  },
});

export const deleteContactSocialMediaAction = (data, props) => {
  return (dispatch) => {
    dispatch(deleteContactSocialMediaRequest());
    return API.delContactSocialMedia(data)
      .then((response) => {
        dispatch(deleteContactSocialMediaSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          deleteContactSocialMediaError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

/*------------get Outlook Api Contact Social Media Action----------------------------------*/
export const createContactOutlookSocialMediaRequest = () => ({
  type: actionTypes.OUTLOOK_CONTACT_SOCIAL_MEDIA_REQUEST,
});

export const createContactOutlookSocialMediaSuccess = (response) => ({
  type: actionTypes.OUTLOOK_CONTACT_SOCIAL_MEDIA_SUCCESS,

  payload: {
    response,
  },
});
export const createContactOutlookSocialMediaError = (error) => ({
  type: actionTypes.OUTLOOK_CONTACT_SOCIAL_MEDIA_ERROR,
  payload: {
    error,
  },
});

export const createContactSocialOutlookAction = (data, props) => {
  return (dispatch) => {
    dispatch(createContactOutlookSocialMediaRequest());
    return API.outlookContactSocialMedia(data)
      .then((response) => {
        dispatch(createContactOutlookSocialMediaSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          createContactOutlookSocialMediaError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

/*------------Outlook Data Contact Social Media Action----------------------------------*/
export const getDataContactOutlookSocialRequest = () => ({
  type: actionTypes.OUTLOOK_CONTACT_DATA_SOCIAL_MEDIA_REQUEST,
});

export const getDataContactOutlookSocialSuccess = (response) => ({
  type: actionTypes.OUTLOOK_CONTACT_DATA_SOCIAL_MEDIA_SUCCESS,

  payload: {
    response,
  },
});
export const getDataContactOutlookSocialError = (error) => ({
  type: actionTypes.OUTLOOK_CONTACT_DATA_SOCIAL_MEDIA_ERROR,
  payload: {
    error,
  },
});

export const getOutlookContactData = (data, props) => {
  return (dispatch) => {
    dispatch(getDataContactOutlookSocialRequest());
    return API.getOutlookContactData(data)
      .then((response) => {
        dispatch(getDataContactOutlookSocialSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          getDataContactOutlookSocialError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

/*------------Merge Contact Social Media Action----------------------------------*/
export const mergeContactSocialMediaRequest = () => ({
  type: actionTypes.MERGE_CONTACT_SOCIAL_MEDIA_REQUEST,
});

export const mergeContactSocialMediaSuccess = (response) => ({
  type: actionTypes.MERGE_CONTACT_SOCIAL_MEDIA_SUCCESS,
  payload: {
    response,
  },
});

export const mergeContactSocialMediaError = (error) => ({
  type: actionTypes.MERGE_CONTACT_SOCIAL_MEDIA_ERROR,
  payload: {
    error,
  },
});

export const mergeContactSocialMediaAction = (data, props) => {
  let propsTemp = props;
  return (dispatch) => {
    dispatch(mergeContactSocialMediaRequest());
    return API.mergeContactSocialMedia(data)
      .then((response) => {
        callAlertMessage(
          response.data.result.success === 1 ? 'success' : 'error',
          response.data.result.msg
        );
        dispatch(mergeContactSocialMediaSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          mergeContactSocialMediaError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

/*------------skip on boarding flow Action----------------------------------*/
export const skipOnboardingRequest = () => ({
  type: actionTypes.SKIP_ONBOARDING_REQUEST,
});

export const skipOnboardingSuccess = (response) => ({
  type: actionTypes.SKIP_ONBOARDING_SUCCESS,
  payload: {
    response,
  },
});

export const skipOnboardingError = (error) => ({
  type: actionTypes.SKIP_ONBOARDING_ERROR,
  payload: {
    error,
  },
});

export const skipOnboardingAction = (data) => {
  return (dispatch) => {
    dispatch(skipOnboardingRequest());
    return API.skipOnboarding(data)
      .then((response) => {
        dispatch(skipOnboardingSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          skipOnboardingError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};
