import { postRequest } from '../helper';
import apiClient from '../../utils/api-client';

export const createReasonCode = (data) => postRequest(`functions/addMaster`, data);

export const getAllReasonCode = (data) => apiClient.post('/parse/functions/viewMasters', data);

export const updateReasonCode = (data) => postRequest('functions/addMaster', data);

export const deleteReasonCode = (data) => postRequest('functions/deleteMasters', data);

export const getReasonCodesByStatusCode = (data) => postRequest('functions/getReasonCodes', data);
