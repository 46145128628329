import { joiResolver } from '@hookform/resolvers/joi';
import { UpdateAccountData, UpdateModalProps } from '@module/accounts';
import { useEffect } from 'react';
import { notification } from 'antd';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { createAccount, updateAccount } from '../../modules/accounts';
import { selectCreateAccount, selectUpdateAccount } from '../../modules/accounts/selectors';
import FormInput from '../../shared/form-controls/form-input';
import FormSearchPlaceAutocomplete from '../../shared/form-controls/form-search-place-autocomplete';
import PhoneArray from '../../shared/phone-array';
import { convertAddressToFormValue, toContactAddress } from '../../utils/address';
import { updateAccountSchema } from './update-account.schema';

import { BillingInfoPage } from '../../pages/settings/billing-info-page';

const defaultValues = {
  adminFirstName: '',
  adminLastName: '',
  adminEmail: '',
  adminUserId: '',
  adminContactId: '',
  adminPhoneArr: [],
  _id:'',
  companyEmail:'',
  accountName: '',
  website: '',
  route: '',
  lat: 0,
  lng: 0,
  taxId: '',
  maxUsers: -1,
  chargebeeId: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  zipCode: '',
  country: '',
  phoneArr: [],
};

const UpdateAccountModal = ({
  open = false,
  onClose = () => void 0,
  account = null,
}: UpdateModalProps) => {
  const isUpdate = !!account;
  const dispatch = useDispatch() as any;

  const form = useForm<UpdateAccountData>({
    defaultValues,
    resolver: joiResolver(updateAccountSchema),
  });

  const { control, setValue, handleSubmit, reset } = form;

  useEffect(() => {
    if (account) {
      return reset({ ...account });
    }

    reset(defaultValues);
  }, [account]);

  const { loading: submitting } = useSelector(selectCreateAccount);
  const { loading: updating } = useSelector(selectUpdateAccount);

  const loading = submitting || updating;

  const handleSearchSelection = (res) => {
    const payload: any = toContactAddress(res);
    const address1 = convertAddressToFormValue(payload.streetNumber, payload.route) as string;
    setValue('address1', address1);
    setValue('state', payload.state);
    setValue('city', payload.city);
    setValue('zipCode', payload.zipCode);
    setValue('country', payload.country);
    setValue('route', payload.route);
    setValue('lat', payload.lat);
    setValue('lng', payload.lng);
  };

  const handleClose = () => {
    reset(defaultValues);
    onClose();
  };

  const onSubmit = async (values: UpdateAccountData) => {

    const accountOk = await form.trigger();

      if (!(accountOk))
        return console.log(
          'Error in account construction',
          form.formState.errors,
        );

    await dispatch(
      isUpdate ? updateAccount({ data: values }) : createAccount({ data: values })
    ).unwrap();

    handleClose();
  };

  return (
    <FormProvider {...form}>
      <Modal backdrop='static' isOpen={open} toggle={handleClose} size='lg'>
        <ModalHeader toggle={handleClose}>
          {isUpdate ? 'Update Account' : 'Add Account'}
        </ModalHeader>
        <ModalBody>
          <div className='container'>
            <div className='row'>
              <div className='col-12'>
                <h6 className='mt-0'>
                  <b>Admin info</b>
                </h6>
              </div>
            </div>

            <div className='row'>
              <div className='col-6'>
                <FormInput label='First Name' required control={control} name='adminFirstName' />
              </div>
              <div className='col-6'>
                <FormInput label='Last Name' required control={control} name='adminLastName' />
              </div>

              <div className='col-12'>
                <FormInput
                  label='Email'
                  disabled={isUpdate}
                  required
                  control={control}
                  name='adminEmail'
                />
              </div>

              {isUpdate&&( <>
              <div className='col-6'>
                <FormInput
                  label='Admin User Id'
                  disabled={!isUpdate}
                  control={control}
                  name='adminUserId'
                />
              </div>

              <div className='col-6'>
                <FormInput
                  label='Admin Contact Id'
                  disabled={true}
                  control={control}
                  name='adminContactId'
                />
              </div>
             </>)}

              <div className='col-12'>
                <PhoneArray name='adminPhoneArr' />
              </div>
            </div>

            <div className='row'>
              <div className='col-12'>
                <div className='border-bottom' />
              </div>
            </div>

            <div className='row'>
              <div className='col-12'>
                <h6>
                  <b>Account info</b>
                </h6>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-6'>
                <FormInput name='accountName' label='Account Name' required control={control} />
              </div>
              {isUpdate&&( <>
              <div className='col-md-6'>
                <FormInput name='_id' label='Account Id' disabled control={control} />
              </div></>)}
              <div className='col-md-6'>
                <FormInput name='chargebeeId' label='ChargebeeID' control={control} />
              </div>
              <div className='col-md-6'>
                <FormInput name='maxUsers' label='Max Users' type='number' control={control}/>
              </div>
              <div className='col-md-6'>
                <FormInput name='taxId' label='Tax ID (EIN)' control={control} />
              </div>
              <div className='col-md-6'>
                <FormInput name='website' label='Website' control={control} />
              </div>
              <div className='col-6'>
                <FormInput
                  label='Company Email'
                  control={control}
                  name='companyEmail'
                />
              </div>
            </div>



            <PhoneArray name='phoneArr' />

            <div className='row'>
              <div className='col-md-4'>
                <FormSearchPlaceAutocomplete
                  control={control}
                  label='Street'
                  name='address1'
                  required
                  onSelect={handleSearchSelection}
                />
              </div>
              <div className='col-md-4'>
                <FormInput label='Apt/Suite' control={control} name='address2' />
              </div>
              <div className='col-md-4'>
                <FormInput label='City' name='city' control={control} />
              </div>
              <div className='col-md-4'>
                <FormInput label='State' name='state' control={control} />
              </div>
              <div className='col-md-4'>
                <FormInput label='Zip Code' name='zipCode' control={control} />
              </div>
              <div className='col-md-4'>
                <FormInput label='Country' name='country' control={control} />
              </div>
            </div>
            <BillingInfoPage accountId={account?._id??''} embeddedMode={true}/>
            
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color='secondary' onClick={handleClose}>
            Cancel
          </Button>
          <Button color='primary' onClick={handleSubmit(onSubmit, () =>
          /* console.log(
            'Error in account construction',
            form.formState.errors,
          )*/
           notification.error({ message: 'Validation error!' })
          )} disabled={loading}>
            Submit
          </Button>
        </ModalFooter>
      </Modal>
    </FormProvider>
  );
};

export default UpdateAccountModal;
