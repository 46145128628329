import moment from 'moment';
import { InputMask } from '@react-input/mask';
import { CustomFieldsCommonProps } from '../constants';
import { useCallback, useState, forwardRef, useImperativeHandle } from 'react';
import FormDatePicker from '../../form-controls/form-date-picker/form-date-picker';

type CustomDateInputProps = {
  allowTime: boolean;
  lessThanEqual: string;
  greaterThanEqual: string;
} & CustomFieldsCommonProps;

export const CustomDateInput = forwardRef(
  ({ allowTime = false, lessThanEqual, greaterThanEqual, ...props }: CustomDateInputProps, ref) => {
    const [fieldsData, setFieldsData] = useState<any>({});

    useImperativeHandle(ref, () => ({ onUpdate }), []);

    const onUpdate = (fieldsData: any) => setFieldsData(fieldsData);

    const getDate = useCallback(
      (option: string) => {
        if (!option) {
          return null;
        }

        switch (option) {
          case 'none':
            return null;
          case 'today':
            return moment();
          case 'nextWeek':
            return moment().add(1, 'week');
          case 'nextMonth':
            return moment().add(1, 'month');
          case 'nextYear':
            return moment().add(1, 'year');
          default: {
            const propDate = fieldsData[option];
            return (propDate?.isValid && propDate?.isValid()) ? propDate : null;
          }
        }
      },
      [fieldsData]
    );

    let minDate = getDate(greaterThanEqual);
    let maxDate = getDate(lessThanEqual);

    // if both dates are valid - make sure that minDate < maxDate
    if (minDate?.isValid() && maxDate?.isValid() && minDate.isAfter(maxDate)) {
      minDate = null;
      maxDate = null;
    }

    const customInput = <InputMask  mask={`11/11/1111 11:11 aa`} />;

    const dateRangeProps = {
      ...(minDate && { minDate }),
      ...(maxDate && { maxDate }),
      ...(allowTime && { dateFormat: 'MM/dd/YYYY hh:mm a', customInput }),
    };



    return (
      <FormDatePicker
        allowSameDay
        showTimeSelect={allowTime}
        showMonthDropdown
        {...props}
        {...dateRangeProps}
      />
    );
  }
);
