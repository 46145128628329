import React from 'react';
import { affiliationLinkFormatter } from '../../utils/formatters';

export const affiliationsRenderer = (fieldName) => (recordData) => {
  const { record } = recordData;

  const accountExecs = record.data[fieldName] || [];

  recordData.size.height = Math.max(accountExecs.length * 20, 40);

  return (
    <div className='d-flex flex-column px-1'>
      {accountExecs.map((accountExec) => (
        <React.Fragment key={accountExec._id}>
          {affiliationLinkFormatter(accountExec)}
        </React.Fragment>
      ))}
    </div>
  );
};
