import React, { useState, useEffect } from 'react';
import { appConstants } from '../../../_constants';
import ReactLoading from 'react-loading';
import { ResponsiveFunnel } from '@nivo/funnel'
import { useTheme, useMotionConfig } from '@nivo/core'
import { useSpring, animated } from 'react-spring'
import axios from 'axios';
import {formatMoney} from '../../../utils/number-formatters';

const PartLabel = ({ part }) => {
    const theme = useTheme()
    const { animate, config: motionConfig } = useMotionConfig()

    const animatedProps = useSpring({
        transform: `translate(${part.x}, ${part.y})`,
        color: part.labelColor,
        config: motionConfig,
        immediate: !animate,
        fontWeight: "bold"
    })

    return (
        <animated.g transform={animatedProps.transform}>
            <animated.text
                textAnchor="middle"
                dominantBaseline="central"
                style={{
                    ...theme.labels.text,
                    fill: animatedProps.color,
                    pointerEvents: 'none',
                }}>
                {part.data.label + ': ' + formatMoney(part.data.value)}
            </animated.text>
        </animated.g>
    )
}

function Labels(props) {
  
  return props.parts.map((part) => <PartLabel key={part.data.id} part={part} />)
}



const ProjectFunnelChartWidget = () => {
    const [accountId] = useState(localStorage.getItem('accountId'));
    const [userId] = useState(localStorage.getItem('userId'));
    const [funnelData, setFunnelData] = useState([]);
    const [fetchRequest, setFetchRequest] = useState(false);
    const [showProcess, setShowProcess] = useState(true);

    const requestData = {
        analyzeBy: 'PM',
        timePeriodType: 'next12months',
        projectStatus: 'realizedProject',
    };

    const getDataList = async () => {
      // TODO: setup axios and redux toolkit
      const { data } = await axios.post(
        'parse/functions/getProjectFunnelData',
        {
          userId:userId,
          accountId:accountId,
        },
        {
          baseURL: appConstants.WEB_SERVICE_URL_WITHOUT_PARSE,
          headers: {
            'X-Parse-Application-Id': appConstants.PARSE_APPLICATION_ID,
            'X-Parse-REST-API-Key': appConstants.PARSE_REST_API_KEY,
            'X-Parse-Session-Token': localStorage.getItem('sessionToken'),
            authorization: localStorage.getItem('JWTTOKEN'),
          },
          params: {
            period: 'last12months'
          },
        }
      );

      const receivedData = data?.result?.data.map((item) => {
        return {
          id: item.label,
          value: Number.parseInt(item.totalContract)??0,//Number.parseInt(item.totalProjects),
          label: item.label
        }
      })??[];

      setFunnelData(receivedData);
      setFetchRequest(false);
    };

    useEffect(() => {
        // You can perform data filtering or other actions here
        //dataFilter();
        setFetchRequest(true);
        getDataList();
        
    }, []);

    return (
        <div className="row" style={{ minHeight: 200, fill: 'white' }}>
            <div className="col-md-12">
                {funnelData.length>0 && (
                    <ResponsiveFunnel
                        data={funnelData}
                        layers={['separators', 'parts', Labels, 'annotations']}
                        margin={{ top: 30, right: 1, bottom: 20, left: 1 }}
                        valueFormat=">-.4s"
                        colors={{ scheme: 'spectral' }}
                        borderWidth={5}
                        labelColor={'white'}
                        beforeSeparatorLength={100}
                        beforeSeparatorOffset={20}
                        afterSeparatorLength={100}
                        afterSeparatorOffset={20}
                        currentPartSizeExtension={10}
                        currentBorderWidth={5}
                        motionConfig="none"
                    />
                )}
            </div>
            {fetchRequest && (
                <ReactLoading
                    className="table-loader"
                    type={appConstants.LOADER_TYPE}
                    color={appConstants.LOADER_COLOR}
                    height={appConstants.LOADER_HEIGHT}
                    width={appConstants.LOADER_WIDTH}
                />
            )}

            {(!fetchRequest && funnelData.length==0)&&(
                <div className="msgBox1 msgBoxEF">
                    <span>No data available!</span>
                </div>
            )}
        </div>
    );
}

export default ProjectFunnelChartWidget;
