import * as actionTypes from './actionTypes';
import * as API from '../api/help';

export const getHelpDetailsRequest = () => ({
  type: actionTypes.GET_HELP_REQUEST,
});

export const getHelpDetailsSuccess = (response) => ({
  type: actionTypes.GET_HELP_SUCCESS,
  payload: {
    response,
  },
});

export const getHelpDetailsError = (error) => ({
  type: actionTypes.GET_HELP_ERROR,
  payload: {
    error,
  },
});

export const getHelpDetailsAction = (data) => {
  return (dispatch) => {
    dispatch(getHelpDetailsRequest());
    return API.getHelpDetailData(data)
      .then((response) => {
        dispatch(getHelpDetailsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          getHelpDetailsError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

