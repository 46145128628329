import apiClient from '../../utils/api-client';

export const importProjectsParseRequest = (formData) => {
  formData.append('accountId', localStorage.getItem('accountId'));
  return apiClient.post('projects/importProjects/parse', formData);
};

export const importProjectsLoadRequest = (data, sessionId) =>
  apiClient.post('projects/importProjects/load', { data, sessionId });

export const getTemporaryProjectsRequest = () =>
  apiClient.post('projects/getTemporaryProjects').then(({ data }) => data);

export const getJobStatusesRequest = () =>
  apiClient
    .post('/parse/functions/viewMasters', { className: 'jobStatusCodes' })
    .then(({ data }) => data?.result?.data?.data);

export const getNegotiatingMethodsRequest = () =>
  apiClient
    .post('/parse/functions/viewMasters', { className: 'negotiatingMethods' })
    .then(({ data }) => data?.result?.data?.data);

export const getJobScopesRequest = () =>
  apiClient
    .post('/parse/functions/viewMasters', { className: 'jobScopes' })
    .then(({ data }) => data?.result?.data?.data);

export const getProjectTypesRequest = () =>
  apiClient
    .post('/parse/functions/viewMasters', { className: 'projectTypes' })
    .then(({ data }) => data?.result?.data?.data);

export const getOccupancyTypesRequest = () =>
  apiClient
    .post('/parse/functions/viewMasters', { className: 'occupancyTypes' })
    .then(({ data }) => data?.result?.data?.data);

export const getBuildingTypesRequest = () =>
  apiClient
    .post('/parse/functions/viewMasters', { className: 'buildingTypes' })
    .then(({ data }) => data?.result?.data?.data);

export const getLastJobNumberRequest = () =>
  apiClient.post('/parse/functions/getLastJobNum').then(({ data }) => data?.result);

export const saveProjectRequest = (data) => {
  const payload = {
    accountExec: data.accountExec,
    projectManager: data.projectManager,
    projectSupervisor: data.projectSupervisor,
    foreman: data.projectEngineer,
    estimator: data.estimator,
    alternateJobNum: data.altJobNum,
    altJobNum: data.altJobNum,
    architect: data.architect,
    agent: data.managingAgent,
    broker: data.broker,
    buildingOwner: data.buildOwner,
    billToArr: data.billTo,
    billToContact: data.billTo,
    client: data.client,
    lender: data.lender,
    tenant: data.tenant,
    scope: data.jobScope,
    occupancyType: data.occupancyType,
    negotiationMethod: data.negotiatingMethod,
    buildingType: data.buildingType,
    bidDue: data.bidDueDate,
    isBondRqd: false,
    isWarrantyService: data.isWarrantyService,
    contractAmount: data.contractAmount,
    country: '',
    createdBy: localStorage.getItem('userId'),
    endDate: data.endDate,
    followUpDone: data.followUpDone,
    grossProfit: data.grossProfit,
    jobAddress: data.jobAddress,
    jobAddress2: data.jobAddress2,
    jobNum: data.lastJobNum,
    jobNumPrefix: data.lastJobNumPre,
    jobCity: data.jobCity,
    jobName: data.jobName,
    jobNotes: data.jobNotes,
    jobState: data.jobState,
    jobStatusCodes: data.projectStatus,
    jobZip: data.jobZip,
    leadDate: data.leadDate,
    leadSource: data.leadSource,
    logDate: '',
    marketingDescription: data.marketingDescription,
    permitNo: '',
    phoneArr: '',
    planLoc: '',
    isRef: false,
    estimateBidAmount: data.contractAmount,
    estimateGrossProfit: 32,
    sqFeet: data.sqFeet,
    startDate: data.startDate,
    userId: localStorage.getItem('userId'),
    accountId: localStorage.getItem('accountId'),
    warrPeriod: 1,
    lienPeriod: 60,
  };
  return apiClient.post('/parse/functions/createProject', payload);
};

export const removeTemporaryProjectRequest = (projectId) =>
  apiClient.post('/projects/removeTemporaryProject', { projectId });

export const validateProjectNameRequest = ({ jobName, projectId }) =>
  apiClient.post('/projects/validateProjectName', { jobName, projectId }).then(({ data }) => data);

export const validateProjectNumberRequest = ({ projectNumber, projectId }) =>
  apiClient
    .post('/projects/validateProjectNumber', { projectNumber, projectId })
    .then(({ data }) => data);

export const getAllJobNumbersRequest = () =>
  apiClient
    .post('/projects/getAllJobNumbers')
    .then(({ data }) => data);

export const createProjectRequest = (data) => {
  return apiClient.post('/projects/createProject', data).then(({ data }) => data);
};

export const updateProjectRequest = (data) => {
  return apiClient.post('/projects/updateProject', data).then(({ data }) => data);
};

export const getProjectBidRequest = (data) => {
  return apiClient.post('/projects/getProjectBid', data).then(({ data }) => data);
};

export const createUpdateProjectBidRequest = (data) => {
  return apiClient.post('/projects/createUpdateProjectBid', data).then(({ data }) => data);
};

export const generateProjectNumberRequest = (data) => {
  return apiClient.post('/projects/generateProjectNumber', data).then(({ data }) => data);
};

export const getNextProjectNumberRequest = () => {
  return apiClient.post('/projects/getNextProjectNumber').then(({ data }) => data);
};

export const promoteLeadToProjectRequest = (data) => {
  return apiClient.post('/projects/promoteLeadToProject', data).then(({ data }) => data);
};

export const getUpdateProjectFormRequest = ({ projectId, jobNumFormatted }) => {
  return apiClient.post('/projects/getUpdateProjectForm', { projectId, jobNumFormatted }).then(({ data }) => data);
};

export const getSheetProjectRequest = ({ type }) => {
  return apiClient.post('/parse/functions/importSheetProject', { type }).then(({ data }) => data);
};

export const getProjectCustomFieldsValuesFormRequest = ({ projectId }) => {
  return apiClient
    .post('/projects/getProjectCustomFieldsValuesForm', { projectId })
    .then(({ data }) => data);
};

export const getProjectWIPUpdatesRequest = ({ projectId }) => {
  return apiClient.post('/projects/getProjectWIPUpdates', { projectId }).then(({ data }) => data);
};

export const getProjectOrigContractRequest = ({ projectId }) => {
  return apiClient.post('/projects/getProjectOrigContract', { projectId }).then(({ data }) => data);
};

export const updateProjectOrigContractRequest = (data) => {
  return apiClient.post('/projects/updateProjectOrigContract', data).then(({ data }) => data);
};

export const reassignProjectsRequest = (data) => {
  return apiClient.post('/projects/reassignProjects', data).then(({ data }) => data);
};
