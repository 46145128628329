import React from 'react';
import FormattedPhone from './formatted-phone';

const FormattedPhoneArr = ({ phoneArr = [] }) => {
  const filteredArr = phoneArr?.filter((phoneObj) => phoneObj.phone);
  const slicedArray = filteredArr?.slice(0, 2);

  return filteredArr?.length ? (
    <span>
      {slicedArray.map((phone, index) => (
        <span>
          <FormattedPhone phoneObject={phone}></FormattedPhone>
          {index === 1 && filteredArr.length > 2 ? <span> ...</span> : ''}
          <br></br>
        </span>
      ))}
    </span>
  ) : (
    <span>-</span>
  );
};

export default FormattedPhoneArr;
