import FormCheckbox from '../../form-controls/form-checkbox';
import { useAppSelector } from '../../hooks/use-app-selector';
import { selectGetCustomSelectOptions } from '../../../modules/settings/selectors';
import { useFormContext } from 'react-hook-form';
import FormSelect from '../../form-controls/form-select';
import FormMultiSelect from '../../form-controls/form-multiselect/form-multiselect';

export const SingleSelectSection = () => {
  const { data: selectOptions } = useAppSelector(selectGetCustomSelectOptions);
  const form = useFormContext();
  const hasDataSrc = form.watch('valueDefinition.hasDataSrc');

  const options = hasDataSrc ? selectOptions : form.watch('valueDefinition.options');

  const creatable = form.watch('valueDefinition.creatable');

  return (
    <div>
      <div className='row'>
        <div className='col-12'>
          <label className='form-label'>Single Select Options</label>
        </div>

        {!hasDataSrc && (
          <div className='col-6'>
            <div className='row'>
              <div className='col-12'>
                <FormCheckbox
                  className='flex-row-reverse mb-1'
                  name='valueDefinition.creatable'
                  label='Allow create new options'
                />
              </div>

              <div className='col-12'>
                <FormMultiSelect
                  label='Options'
                  name='valueDefinition.options'
                  creatable
                  useObjectValue
                  options={[]}
                />
              </div>
            </div>
          </div>
        )}
      </div>

      <div className='row'>
        <div className='col-6'>
          <FormSelect
            label='Default Value'
            name='valueDefinition.defaultValue'
            options={options}
            creatable={creatable}
            isClearable
          />
        </div>
      </div>
    </div>
  );
};
