import * as actionTypes from '../../actions/Admin/actionTypes';

const initState = {
  contact: {},
  alert: {},
  isfetched: false,
  isfetchedError: false,
  isStatus: false,

  isAddCompany: false,
  isAddCompanySuccess: false,
  isAddCompanyError: false,

  isEditCompany: false,
  isEditCompanySuccess: false,
  isEditCompanyError: false,

  isAccountStatus: false,
  isAccountStatusSuccess: false,
  isAccountStatusError: false,

  isTermCondition: false,
  isTermConditionSuccess: false,
  isTermConditionError: false,

  isAddTermCondition: false,
  isAddTermConditionSuccess: false,
  isAddTermConditionError: false,

  isResetPwd: false,
  isResetPwdSuccess: false,
  isResetPwdError: false,

  success: null,
  message: '',
};

export default (state = initState, action = {}) => {
  switch (action.type) {
    case actionTypes.GET_ACCOUNT_LIST_REQUEST:
      return {
        ...state,
        contact: {},
        alert: {},
        message: '',
        isfetched: false,
        isfetchedError: false,
        isStatus: false,
      };

    case actionTypes.GET_ACCOUNT_LIST_SUCCESS:
      return {
        ...state,
        allAccount: action.payload.response.result,
        message: 'Contact Already Existing',
        alert: {},
        isfetched: true,
        isfetchedError: false,
        isStatus: false,
      };

    case actionTypes.GET_ACCOUNT_LIST_ERROR:
      return {
        ...state,
        message: '',
        isfetched: false,
        alert: {},
        isfetchedError: true,
        isStatus: false,
      };

    //Add company
    case actionTypes.REGISTER_COMPANY_ACCOUNT_REQUEST:
      return {
        ...state,
        buttonActive: true,
        isAddCompany: false,
        message: '',
        isAddCompanySuccess: false,
        isAddCompanyError: false,
      };
    case actionTypes.REGISTER_COMPANY_ACCOUNT_SUCCESS:
      return {
        ...state,
        buttonActive: false,
        isAddCompany: action.payload.response.result,
        success: action.payload.response.result.success,
        isAddCompanySuccess: true,
        isAddCompanyError: false,
        message: action.payload.response.result.success
          ? 'Record Added successfully'
          : action.payload.response.result.msg,
      };
    case actionTypes.REGISTER_COMPANY_ACCOUNT_ERROR:
      return {
        ...state,
        buttonActive: false,
        isAddCompany: false,
        isAddCompanySuccess: false,
        isAddCompanyError: true,
        success: action.payload.error.success,
        message: action.payload.error.msg,
      };

    //Account update
    case actionTypes.UPDATE_ADMIN_ACCOUNT_REQUEST:
      return {
        ...state,
        buttonActive: true,
        isEditCompany: false,
        isEditCompanySuccess: false,
        isEditCompanyError: false,
        message: '',
        success: '',
      };
    case actionTypes.UPDATE_ADMIN_ACCOUNT_SUCCESS:
      return {
        ...state,
        buttonActive: false,
        isEditCompany: action.payload.response.result,
        isEditCompanySuccess: true,
        isEditCompanyError: false,
        success: action.payload.response.result.success,
        message: action.payload.response.result.success
          ? 'Record Updated successfully'
          : action.payload.response.result.msg,
      };
    case actionTypes.UPDATE_ADMIN_ACCOUNT_ERROR:
      return {
        ...state,
        buttonActive: false,
        isEditCompany: false,
        isEditCompanySuccess: false,
        isEditCompanyError: true,
        success: action.payload.error.success,
        message: action.payload.error.msg,
      };

    //Account Active And Deactivated
    case actionTypes.ADMIN_ACCOUNT_STATUS_LIST_REQUEST:
      return {
        ...state,
        isAccountStatus: false,
        isAccountStatusSuccess: false,
        isAccountStatusError: false,
        message: '',
        success: '',
      };
    case actionTypes.ADMIN_ACCOUNT_STATUS_LIST_SUCCESS:
      return {
        ...state,
        isAccountStatus: action.payload.response.result,
        isAccountStatusSuccess: true,
        isAccountStatusError: false,
        message: action.payload.response.result.msg,
        success: action.payload.response.result.success,
      };

    case actionTypes.ADMIN_ACCOUNT_STATUS_LIST_ERROR:
      return {
        ...state,
        isAccountStatus: false,
        isAccountStatusSuccess: false,
        isAccountStatusError: true,
        // message: action.payload.response.result.msg,
        // success: action.payload.response.result.success,
        success: action.payload.error.success,
        message: action.payload.error.msg,
      };

    //terms and condition
    case actionTypes.ADD_TERMS_CONDITION_REQUEST:
      return {
        ...state,
        message: '',
        success: '',
        isAddTermCondition: false,
        isAddTermConditionSuccess: false,
        isAddTermConditionError: false,
      };
    case actionTypes.ADD_TERMS_CONDITION_SUCCESS:
      return {
        ...state,
        isAddTermCondition: action.payload.response.result,
        isAddTermConditionSuccess: true,
        isAddTermConditionError: false,
        success: action.payload.response.result.success,
        message: action.payload.response.result.msg,
      };

    case actionTypes.ADD_TERMS_CONDITION_ERROR:
      return {
        ...state,
        isAddTermCondition: false,
        isAddTermConditionSuccess: false,
        isAddTermConditionError: true,
        success: action.payload.error.success,
        message: action.payload.error.msg,
      };

    //get terms and condition
    case actionTypes.GET_TERMS_CONDITION_REQUEST:
      return {
        ...state,
        message: '',
        success: '',
        isTermCondition: false,
        isTermConditionSuccess: false,
        isTermConditionError: false,
      };
    case actionTypes.GET_TERMS_CONDITION_SUCCESS:
      return {
        ...state,
        isTermCondition: action.payload.response.result,
        isTermConditionSuccess: true,
        isTermConditionError: false,
        success: action.payload.response.success,
        message: action.payload.response.msg,
      };

    case actionTypes.GET_TERMS_CONDITION_ERROR:
      return {
        ...state,
        isTermCondition: false,
        isTermConditionSuccess: false,
        isTermConditionError: true,
        success: action.payload.error.success,
        message: action.payload.error.msg,
      };

    //Reset Password
    case actionTypes.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        message: '',
        success: '',
        isResetPwd: false,
        isResetPwdSuccess: false,
        isResetPwdError: false,
      };
    case actionTypes.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        isResetPwd: action.payload.response.result,
        isResetPwdSuccess: true,
        isResetPwdError: false,
        success: action.payload.response.result.success,
        message: action.payload.response.result.msg,
      };

    case actionTypes.RESET_PASSWORD_ERROR:
      return {
        ...state,
        isResetPwd: false,
        isResetPwdSuccess: false,
        isResetPwdError: true,
        success: action.payload.error.success,
        message: action.payload.error.msg,
      };

    default:
      return state;
  }
};
