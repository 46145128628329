import { joiResolver } from '@hookform/resolvers/joi';
import { JobStatusCodeForm } from '@module/settings';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { getJobStatusCodeForm, updateJobStatusCode } from '../../../modules/settings';
import { ColorPickerButton } from '../../../shared/color-picker';
import { FormColorPicker } from '../../../shared/form-controls/form-color-picker/form-color-picker';
import FormInput from '../../../shared/form-controls/form-input/form-input';
import { useAppDispatch } from '../../../shared/hooks/use-app-dispatch';
import { editJobStatusCodeSchema } from './edit-job-status-code.schema';

type EditJobStatusCodeModalProps = {
  open?: boolean;
  onClose?: () => unknown;
  statusCodeId?: string | null;
  onSubmit?: () => unknown;
};

export const EditJobStatusCodeModal = ({
  open = false,
  onClose = () => void 0,
  statusCodeId = null,
  onSubmit: onSubmitted = () => void 0,
}: EditJobStatusCodeModalProps) => {
  const dispatch = useAppDispatch();

  const form = useForm<JobStatusCodeForm>({
    defaultValues: {
      jobColorCode: { r: 0, g: 0, b: 0, a: 1 },
      jobStatusCodesDefinition: '',
      jobStatusCodesCode: '',
      jobStatusCodesName: '',
    },
    resolver: joiResolver(editJobStatusCodeSchema),
  });

  const [defaultValues, setDefaultValues] = useState({
    jobColorCode: { r: 0, g: 0, b: 0, a: 1 },
    jobStatusCodesDefinition: '',
  });

  useEffect(() => {
    if (!statusCodeId || !open) return;

    dispatch(getJobStatusCodeForm({ statusCodeId }))
      .unwrap()
      .then((data) => {
        const { defaultValues, ...rest } = data;

        form.reset({
          jobColorCode: rest.jobColorCode,
          jobStatusCodesDefinition: rest.jobStatusCodesDefinition,
          jobStatusCodesCode: rest.jobStatusCodesCode,
          jobStatusCodesName: rest.jobStatusCodesName,
        });

        setDefaultValues({
          jobColorCode: defaultValues.jobColorCode,
          jobStatusCodesDefinition: defaultValues.jobStatusCodesDefinition,
        });
      });
  }, [statusCodeId, open]);

  const onSubmit = form.handleSubmit(async (values) => {
    await dispatch(
      updateJobStatusCode({ ...values, statusCodeId: statusCodeId as string })
    ).unwrap();

    onClose();
    onSubmitted();
  });

  return (
    <Modal isOpen={open} toggle={onClose} size='lg'>
      <ModalHeader toggle={onClose}>Edit Job Status Code</ModalHeader>
      <ModalBody>
        <FormProvider {...form}>
          <div className='container' data-testid='edit-job-status-code-modal'>
            <div className='row'>
              <div className='col-md-8'>
                <FormInput
                  name='jobStatusCodesName'
                  label='Name'
                  required
                  data-testid='jobStatusCodesName'
                />
              </div>

              <div className='col-md-4'>
                <FormInput
                  name='jobStatusCodesCode'
                  label='Code'
                  required
                  disabled
                  data-testid='jobStatusCodesCode'
                />
              </div>
            </div>

            <div className='row gx-0'>
              <div className='col'>
                <FormInput name='jobStatusCodesDefinition' label='Definition' textArea rows={5} />
              </div>

              <div className='col-1 d-flex align-items-center justify-content-center'>
                <i
                  onClick={() =>
                    form.setValue(
                      'jobStatusCodesDefinition',
                      defaultValues.jobStatusCodesDefinition
                    )
                  }
                  className='fa fa-arrow-left'
                  role='button'
                  aria-hidden='true'
                  data-testid='defaultDefinitionButton'
                ></i>
              </div>

              <div className='col'>
                <label htmlFor='defaultDefinition'>Default Definition</label>
                <textarea
                  id='defaultDefinition'
                  rows={5}
                  className='form-control'
                  disabled
                  value={defaultValues.jobStatusCodesDefinition}
                />
              </div>
            </div>

            <div className='row mt-2'>
              <div className='col'>
                <FormColorPicker
                  name='jobColorCode'
                  aria-label='Display color'
                  label='Display color'
                />
              </div>

              <div className='col-1 d-flex align-items-center justify-content-center'>
                <i
                  onClick={() => form.setValue('jobColorCode', defaultValues.jobColorCode)}
                  role='button'
                  className='fa fa-arrow-left'
                  aria-hidden='true'
                  data-testid='defaultColorButton'
                ></i>
              </div>

              <div className='col'>
                <div className='d-flex gap-3'>
                  <label>Default color</label>

                  <ColorPickerButton
                    value={defaultValues.jobColorCode}
                    id='defaultColor'
                    aria-label='Default Color'
                  />
                </div>
              </div>
            </div>
          </div>
        </FormProvider>
      </ModalBody>
      <ModalFooter>
        <button className='btn btn-primary' onClick={onClose}>
          Cancel
        </button>

        <button
          className='btn btn-primary'
          disabled={form.formState.isSubmitting}
          onClick={onSubmit}
        >
          Submit
        </button>
      </ModalFooter>
    </Modal>
  );
};
