import { memo, useCallback, useMemo } from 'react';
import { CustomFieldControl } from '../../../shared/custom-fields-controls/form-controls/custom-field-control';
import { ViewLayout } from '../../../shared/form-controls/control-layouts/view-layout';
import { FormProvider, useForm } from 'react-hook-form';
import { getProjectStagesOrder } from '../../../shared/custom-fields-controls/constants';

/* 
  TODO: This component implementation should be revised in order to get rid of FormProvider usage.
  As an option, separate displaying components for different types of fields that are not using FormProvider might be created
  or 'View Mode' that does not depend on the FormProvider should be create for existing fields
  This component will be calling new CustomFieldControlView that will be calling mentioned above components
*/

export const ProjectCustomFieldsForSectionView = memo(
  ({ fields = [], values = [], allFields = [], projectStage, isHideFields = false, onEditRef = null }: any) => {
    const customFieldsForm = useForm({ values });

    const stagesOrder = getProjectStagesOrder();
    const projectStageIndex = useMemo(() => stagesOrder.indexOf(projectStage), [projectStage]);

    const getEarliestStageIndex = (stages: Array<string>) => {
      const stagesFiltered = stages.filter((stage) => stage !== 'all');
      if (stagesFiltered.length === 0) return 0;

      const stagesIndexes = stagesFiltered.map((stage) => stagesOrder.indexOf(stage));
      return Math.min(...stagesIndexes);
    };

    const belongToCurrentOrNextStage = useCallback(
      ({ stages }: { stages: Array<string> }) => {
        if (projectStage === 'service') return stages.includes('service');

        if (stages.length === 1 && stages[0] === 'service') return false;

        if (stages.includes('all')) {
          return true;
        }

        // if project stage is 'earlier' than earliest field stage - do not show or require field
        const fieldEarliestStageIndex = getEarliestStageIndex(stages);
        return fieldEarliestStageIndex <= projectStageIndex;
      },
      [projectStageIndex]
    );

    const currStageFields = fields.filter(belongToCurrentOrNextStage);

    const renderList = useCallback(
      (fields: any[] = [], allFields: any[] = [], values: any, isHideFields) => {

        let lastDivider = null;
        return fields.map((field: any) => {
          const { _id, controlType, valueDefinition, required } = field;

          let viewMode = 'text';
          if (valueDefinition.isUrl) {
            viewMode = 'url';
          } else if (controlType === 'CHECKBOX' || controlType === 'RATE') {
            viewMode = 'component';
          }

          if (controlType === 'DIVIDER') {

            if (!lastDivider || (lastDivider as any)._id !== field._id) {
              lastDivider = field;

              if (lastDivider && (lastDivider as any).valueDefinition.restrictVisibility && (lastDivider as any)?.valueDefinition?.criteria) {
                const matchedField = allFields.find((f) => f.code === (lastDivider as any).valueDefinition.criteria);
                if (matchedField) {
                  (lastDivider as any).valueDefinition.criteriaId = matchedField._id;
                  const criteriaValue = (lastDivider as any).valueDefinition.criteriaValue;
                  (lastDivider as any).valueDefinition.criteriaValueId = criteriaValue.map((value: any) => value._id );
                }

              }
            }
          }

       if (lastDivider && (lastDivider as any).valueDefinition.restrictVisibility && (lastDivider as any)?.valueDefinition?.criteriaId) {
            const criteriaId = (lastDivider as any).valueDefinition.criteriaId;
            const curValue = values[criteriaId];
            const criteriaValueIds = (lastDivider as any).valueDefinition.criteriaValueId;

            if (Array.isArray(curValue)) {
              if (!criteriaValueIds.some((id: any) => curValue.includes(id))) {
                return;
              }
            } else if (curValue) {
              if (!criteriaValueIds.includes(curValue)) {
                return;
              }
            } else {
              return;
            }
          }

          const CustomizedLayout = (props: any) => <ViewLayout {...props} viewMode={viewMode} />;

          return (
            <CustomFieldControl
              Layout={CustomizedLayout}
              key={_id}
              required={required}
              disabled={true}
              fieldDefinition={field}
              isHideFields={isHideFields}
              onEditRef={onEditRef}
            />
          );
        });
      },
      []
    );

    return <FormProvider {...customFieldsForm}>{renderList(currStageFields, allFields, values, isHideFields)}</FormProvider>;
  }
);
