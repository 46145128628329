import moment from 'moment';
import { useEffect, useState } from 'react';

import { CustomDateInput } from '../../../shared/custom-fields-controls/form-controls/custom-date-input';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import {
  getResourceScheduleAsync,
  getResourceScheduleFiltersAsync,
} from '../../../modules/reports';
import { selectGetResourceScheduleFilters } from '../../../modules/reports/selectors';
import { useLocalStorage } from '../../../shared/hooks/use-local-storage';
import { appConstants } from '../../../_constants';
import { formatDateObject, formatDateAdd } from '../../../utils/date-formatters';
import { getProjectRoles } from '../../../modules/settings';
import CustomTable from '../../Common/CustomTable';

function ResourceScheduleModal({ isOpen = false, toggle = () => { } }) {


  useEffect(() => {
    dispatch(getProjectRoles())
      .unwrap()
      .then((data) => setProjectRoles(data));
  }, []);

  const [projectRoles, setProjectRoles] = useState([]);

  const [searchState, setSearchState] = useLocalStorage('ResourceScheduleModal.searchState', {
    analyzeBy: { label: 'Project Manager', value: 'PM' },
    yearStatus: { label: 'This Year', value: 'thisYear' },
    projectStatus: 'realized',
  });
  const [selectDateBox, setSelectDateBox] = useLocalStorage('ResourceScheduleModal.selectDateBox', {
    startDate: formatDateObject(),
    endDate: formatDateObject().add(1, 'day'),
  });
  const [selectedJobCodesLeadsBids, setSelectedJobCodesLeadsBids] = useLocalStorage(
    'ResourceScheduleModal.selectedJobCodesLeadsBids',
    []
  );
  const [selectedJobCodesPre, setSelectedJobCodesPre] = useLocalStorage(
    'ResourceScheduleModal.selectedJobCodesPre',
    []
  );
  const [selectedJobCodesCon, setSelectedJobCodesCon] = useLocalStorage(
    'ResourceScheduleModal.selectedJobCodesCon',
    []
  );
  const [selectedJobCodesOperations, setSelectedJobCodesOperations] = useLocalStorage(
    'ResourceScheduleModal.selectedJobCodesOperations',
    []
  );
  const [selectedJobCodeName, setSelectedJobCodeName] = useLocalStorage(
    'ResourceScheduleModal.selectedJobCodeName',
    []
  );

  const dispatch = useDispatch();

  const { data: resourceScheduleFilters, loading: isGetResourceScheduleFiltersLoading } =
    useSelector(selectGetResourceScheduleFilters);

  useEffect(() => {
    dispatch(getResourceScheduleFiltersAsync());
  }, [dispatch]);


  const optionDateFilter = [
    { label: 'Last Quarter', value: 'lastQuarter' },
    { label: 'Last Month', value: 'lastMonth' },
    { label: 'Last Year', value: 'lastYear' },
    { label: 'This Quarter', value: 'thisQuarter' },
    { label: 'This Month', value: 'thisMonth' },
    { label: 'This Year', value: 'thisYear' },
    { label: 'Next Quarter', value: 'nextQuarter' },
    { label: 'Next Month', value: 'nextMonth' },
    { label: 'Next Year', value: 'nextYear' },
    { label: 'Next 12 Months', value: 'next12months' },
    { label: 'Custom', value: 'custom' },
  ];


  const selectRowProp_LeadBid = {
    mode: 'checkbox',
    clickToSelect: true,
    selected: selectedJobCodesLeadsBids,
    onSelect: (row, isSelect, e, rowIndex) => {
      const newRowIndexes = resourceScheduleFilters.jobStatusFilter.LeadsAndBids.filter(
        (x, index) => row.includes(index.toString())
      );
        setSelectedJobCodesLeadsBids(() => [
          newRowIndexes.map((x, i) => x.jobStatusCodesCode),
        ]);
       
      }

  };

  const selectRowProp_Pre = {
    mode: 'checkbox',
    clickToSelect: true,
    selected: selectedJobCodesPre,
    onSelect: (row, isSelect, e, rowIndex) => {
      const newRowIndexes = resourceScheduleFilters.jobStatusFilter.PreConstruction.filter(
        (x, index) => row.includes(index.toString())
      );
        setSelectedJobCodesPre(() => [
          newRowIndexes.map((x, i) => x.jobStatusCodesCode),
        ]);
        
      }
  };

  const selectRowProp_Con = {
    mode: 'checkbox',
    clickToSelect: true,
    selected: selectedJobCodesCon,
    onSelect: (row, isSelect, e, rowIndex) => {
      const newRowIndexes = resourceScheduleFilters.jobStatusFilter.Construction.filter(
        (x, index) => row.includes(index.toString())
      );
      setSelectedJobCodesCon(() => [
          newRowIndexes.map((x, i) => x.jobStatusCodesCode),
        ]);
        
      }
  };

  const selectRowProp_Ops = {
    mode: 'checkbox',
    clickToSelect: true,
    selected: selectedJobCodesOperations,
    onSelect: (row, isSelect, e, rowIndex) => {
      const newRowIndexes = resourceScheduleFilters.jobStatusFilter.Operational.filter(
        (x, index) => row.includes(index.toString())
      );
      setSelectedJobCodesOperations(() => [
          newRowIndexes.map((x, i) => x.jobStatusCodesCode),
        ]);
        
      }
  };

  const handleChangeSearchState = (name) => (value) => {
    setSearchState((prevSearchState) => ({
      ...prevSearchState,
      [name]: value,
    }));
  };

  const handleDatePicker = (name) => (date) => {
    setSelectDateBox((prevSelectDateBox) => {
      const field = { ...prevSelectDateBox, [name]: date };

      if (name === 'startDate') {
        let dateObj = '';
        if (!date) {
          dateObj = formatDateObject().add(1, 'day');
          field.endDate = dateObj;
        } else {
          dateObj = formatDateObject(date).add(1, 'Day');
          if (field.startDate > field.endDate) {
            field.endDate = dateObj;
          }
        }
      }

      return field;
    });
  };

  const handleSearchData = () => {
    const requestData = {
      moduleName: appConstants.reportModuleName['13'],
      analyzeBy: searchState.analyzeBy.value,
      analyzeByStatus: searchState.analyzeBy.label,
      yearStatus: searchState.yearStatus.value,
      projectStatus: searchState.projectStatus,
      page: 0,
      displayLimit: 2000,
      jobCodeName: selectedJobCodeName,
      jobCode: [
        ...selectedJobCodesLeadsBids,
        ...selectedJobCodesPre,
        ...selectedJobCodesCon,
        ...selectedJobCodesOperations,
      ],
    };

    if (requestData.yearStatus === 'custom') {
      requestData.yearStatus = 'custom';
      requestData.startDate = moment(selectDateBox.startDate).format('YYYY-MM-DD');
      requestData.endDate = moment(selectDateBox.endDate).format('YYYY-MM-DD');
    }

    if (requestData.yearStatus === 'nextTwelveMonth') {
      requestData.yearStatus = 'custom';
      requestData.startDate = moment(selectDateBox.startDate).startOf('month').format('YYYY-MM-DD');

      const currentDate = moment(selectDateBox.startDate).endOf('month').format('YYYY-MM-DD');
      const futureMonth = moment(currentDate).add(11, 'M');
      const futureMonthEnd = moment(futureMonth).endOf('day');
      requestData.endDate = moment(futureMonthEnd).format('YYYY-MM-DD');
    }

    dispatch(getResourceScheduleAsync(requestData));

    toggle();
  };

  const columns = [
    {
      id: "jobStatusCodesCode",
      accessor: 'jobStatusCodesCode',
      Header: 'Code',
      width: '30',
    },
    {
      accessor: "jobStatusCodesName",
      Header: 'Name',
      width: '75',
    },
    {
      accessor: "jobStatusCodesDefinition",
      Header: 'Definition',
    },
    {
      accessor: "jobColorCode",
      Header: 'Color',
      width: '25',
      Cell: ({ row }) => {
        if (row.original.jobColorCode && typeof (row.original.jobColorCode) === 'object')
          return (
            <div
              className='color-box'
              style={{
                backgroundColor: `rgba(${row.original.jobColorCode.r},${row.original.jobColorCode.g},${row.original.jobColorCode.b},${row.original.jobColorCode.a})`,
                height: '18px',
                borderRadius: '10px',
              }}
            ></div>
          ); else {
          return null;

        }
      }
    },];

  const searchBy = [{ label: 'All Projects', value: 'notGrouped' },].concat(projectRoles.filter(role => !role.trash).map(role => ({ 'label': role.name, 'value': role.code })));

  return (
    <Modal backdrop='static' isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Select</ModalHeader>
      <ModalBody>
        <div className='container'>
          <div className='form-group'>
            <div className='row'>
              <div className='col-md-6'>
                <label>Group By</label>
                <Select
                  name='projectManager'
                  closeMenuOnSelect
                  components={makeAnimated()}
                  value={searchState.analyzeBy}
                  onChange={handleChangeSearchState('analyzeBy')}
                  options={searchBy}
                />
              </div>
              <div className='col-md-6'>
                <label>Period</label>
                <Select
                  name='status'
                  components={makeAnimated()}
                  value={searchState.yearStatus}
                  onChange={handleChangeSearchState('yearStatus')}
                  options={optionDateFilter}
                />
              </div>
            </div>
          </div>

          {searchState.yearStatus.value === 'custom' && (
            <div className='row'>
              <div className='col-md-6'>
                <div className='form-group'>
                  <label htmlFor='startDate'>Start Date</label>
                  <CustomDateInput
                    className='form-control'
                    selected={formatDateObject(selectDateBox.startDate)}
                    onChange={handleDatePicker('startDate')}
                    dateFormat={appConstants.DATEFORMATPICKER}
                    required
                    todayButton="Today"
                    showMonthDropdown
                    placeholderText='Click to select a date'
                  />
                </div>
              </div>
              <div className='col-md-6'>
                <div className='form-group'>
                  <label htmlFor='endDate'>End Date</label>
                  <CustomDateInput
                    className='form-control'
                    selected={formatDateObject(selectDateBox.endDate)}
                    onChange={handleDatePicker('endDate')}
                    dateFormat={appConstants.DATEFORMATPICKER}
                    required
                    placeholderText='Click to select a date'
                    selectsEnd
                    todayButton="Today"
                    showMonthDropdown
                    startDate={formatDateObject(selectDateBox.startDate)}
                    endDate={formatDateObject(selectDateBox.endDate)}
                    minDate={formatDateObject(selectDateBox.startDate).add(1, 'day')}
                    maxDate={formatDateAdd(5, 'months')}
                  />
                </div>
              </div>
            </div>
          )}

          {isGetResourceScheduleFiltersLoading && <div>Loading...</div>}

          {!isGetResourceScheduleFiltersLoading && resourceScheduleFilters.jobStatusFilter && (
            <div className='d-flex flex-column gap-2'>
              {resourceScheduleFilters.jobStatusFilter.LeadsAndBids && (
                <div>
                  <strong>Leads &#38; Bids</strong>
                  <CustomTable
                    columns={columns.filter(Boolean)}
                    list={resourceScheduleFilters.jobStatusFilter.LeadsAndBids}
                    inlineEdit={false}
                    canShowExport={false}
                    canShowPagination={true}
                    canShowGlobalFilter={false}
                    canShowColumnSettings={false}
                    canShowDateFilter={false}
                    canShowRowSelection={true}
                    onChangeRowSelection={selectRowProp_LeadBid.onSelect}
                    useControlledState={(selectRowProp_LeadBid) => ({ ...selectRowProp_LeadBid })}
                  />
                </div>
              )}
              {resourceScheduleFilters.jobStatusFilter.PreConstruction && (
                <div>
                  <strong>Pre-Construction</strong>
                  <CustomTable
                    columns={columns.filter(Boolean)}
                    list={resourceScheduleFilters.jobStatusFilter.PreConstruction}
                    inlineEdit={false}
                    canShowExport={false}
                    canShowPagination={true}
                    canShowGlobalFilter={false}
                    canShowColumnSettings={false}
                    canShowDateFilter={false}
                    canShowRowSelection={true}
                    onChangeRowSelection={selectRowProp_Pre.onSelect}
                    useControlledState={(selectRowProp_Pre) => ({ ...selectRowProp_Pre })}
                  />
                </div>
              )}
              {resourceScheduleFilters.jobStatusFilter.Construction && (
                <div>
                  <strong>Construction</strong>
                  <CustomTable
                    columns={columns.filter(Boolean)}
                    list={resourceScheduleFilters.jobStatusFilter.Construction}
                    inlineEdit={false}
                    canShowExport={false}
                    canShowPagination={true}
                    canShowGlobalFilter={false}
                    canShowColumnSettings={false}
                    canShowDateFilter={false}
                    canShowRowSelection={true}
                    onChangeRowSelection={selectRowProp_Con.onSelect}
                    useControlledState={(selectRowProp_Con) => ({ ...selectRowProp_Con })}
                  />
                </div>
              )}
              {resourceScheduleFilters.jobStatusFilter.Operational && (
                <div>
                  <strong>Operational</strong>
                  <CustomTable
                    columns={columns.filter(Boolean)}
                    list={resourceScheduleFilters.jobStatusFilter.Operational}
                    inlineEdit={false}
                    canShowExport={false}
                    canShowPagination={true}
                    canShowGlobalFilter={false}
                    canShowColumnSettings={false}
                    canShowDateFilter={false}
                    canShowRowSelection={true}
                    onChangeRowSelection={selectRowProp_Ops.onSelect}
                    useControlledState={(selectRowProp_Ops) => ({ ...selectRowProp_Ops })}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <button className='btn btn-primary' onClick={toggle}>
          Cancel
        </button>
        <button className='btn btn-primary' onClick={handleSearchData}>
          Next
        </button>
      </ModalFooter>
    </Modal>
  );
}

export default ResourceScheduleModal;
