import { notification } from 'antd';
import { useDispatch } from 'react-redux';
import { allAccountActiveDeactivatedAction } from '../../actions/Admin/Account.actions';
import { postRequest } from '../../api/helper';
import { getAllAccounts } from '../../modules/accounts';
import FormattedPhoneArr from '../../shared/formatted-phone/formatted-phone-arr';

import { SelectColumnFilter } from '../../components/Common/CustomTableComponents/SelectColumnFilter';

/*
  Moved from old functionality - need to refactor
*/

const userName = (_cell, row, _enumObject, _rowIndex) => {
  return <div>{row.firstName + ' ' + row.lastName}</div>;
};

// TODO: move it to redux
const VerifyEmail = async (data) => {
  let apiCall = await postRequest(`functions/resendAccountVerificationEmail`, data);
  let resultMessage = apiCall.data.result.msg;
  if (resultMessage === 'Success') {
    notification.success({
      message: 'Activation mail resent',
      duration: 4,
    });
  } else {
    notification.error({
      message: resultMessage,
      duration: 4,
    });
  }
};

const onContactPhone = (_cell, row, _rowIndex) => {
  if (row && row.phoneArr && row.phoneArr.length > 0) {
    return <FormattedPhoneArr phoneArr={row.phoneArr} />;
  } else {
    return <span>-</span>;
  }
};

const onUserCount = (_cell, row, _enumObject, _rowIndex) => {
  return <div>{row && row.userCount ? row.userCount - 1 : 0}</div>;
};

const accountType = (_cell, row, _enumObject, _rowIndex) => {
  return <div>{row.subscriptionPlanStatus === 'freeTrial' ? 'Free Trial' : ''}</div>;
};

const renderEmailActivation = ({ value, row }) => {
  if (value=='true') return <div>✓</div>;

  const onClick = async (e, _id) => {
    e.stopPropagation();
    e.preventDefault();

    await VerifyEmail({ accountId: _id });
  };

  return (
    <a onClick={(e) => onClick(e, row.original.objectId)}>
      <i className='fa fa-paper-plane' aria-hidden='true'></i>
    </a>
  );
};

const toggleActivationButton = (_cell, row, _enumObject, _rowIndex) => {
  const dispatch = useDispatch();

  const activeUser = async (event) => {
    event.stopPropagation();
    event.preventDefault();

    let newStatus = '';

    if (row.status === 'active') {
      newStatus = 'inactive';
    } else {
      newStatus = 'active';
    }

    await allAccountActiveDeactivatedAction({
      objectId: row.objectId || row._id,
      status: newStatus,
      type: 'account',
    })(dispatch);

    await dispatch(getAllAccounts());
  };

  return (
    <div className='action-act' onClick={(e) => activeUser(e)}>
      <a>{row.status === 'active' ? 'Enabled' : <span className='statusColor'>Disabled</span>}</a>
    </div>
  );
};

export const columns = [
    {
    Header: 'Account Id',
    accessor: 'objectId',
    width: 100,
    hidden: true
    //Cell: (props) => accountType(props.column.id, props.row.original, props.value),
  },
  {
    Header: 'Company Name',
    accessor: 'companyName',
    width: 230,
  },
  {
    Header: 'Name',
    accessor: 'userName',
    width: 150,
    Cell: (props) => userName(props.column.id, props.row.original, props.value),
  },
  {
    Header: 'Admin Email',
    accessor: 'accountEmail',
    width: 220,
  },
  {
    Header: 'Phone',
    accessor: 'phoneNumber',
    width: 140,
    Cell: (props) => onContactPhone(props.column.id, props.row.original, props.value),
  },
  {
    Header: 'Projects',
    accessor: 'cppProjectsCount',
    width: 80,
  },
  {
    Header: 'Contacts',
    accessor: 'contactsCount',
    width: 90,
  },
  {
    Header: 'Users',
    accessor: 'userCount',
    width: 80,
    Cell: (props) => onUserCount(props.column.id, props.row.original, props.value),
  },
  {
    Header: 'Companies',
    accessor: 'venderCount',
    width: 60,
  },
  {
    Header: 'Subscription',
    accessor: 'chargebeeStatus.subscriptionStatus',
    Filter: SelectColumnFilter,
    width: 100,
    //Cell: (props) => accountType(props.column.id, props.row.original, props.value),
  },
  {
    Header: 'ChargeBee ID',
    accessor: 'chargebeeId',
    width: 100,
    //Cell: (props) => accountType(props.column.id, props.row.original, props.value),
  },
  {
    Header: 'Max Users',
    accessor: 'maxUsers',
    width: 20,
  },
  {
    Header: 'Active',
    id: 'userEmailActivation',
          accessor: (row) =>
            row?.userEmailActivation?'true':'false',
    Filter: SelectColumnFilter,
    width: 60,
    Cell: renderEmailActivation,
  },
  {
    Header: 'Status',
    accessor: 'status',
    width: 70,
    Cell: (props) => toggleActivationButton(props.column.id, props.row.original, props.value),
  },
];
