import moment from 'moment';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import avatarImage from '../../components/static/images/avatar3.png';
import { teamActivityTimeFormat } from '../../utils/date-formatters';

export const useNotificationsPage = () => {
  const onGetUser = (data) => {
    if (data) {
      const { user = {} } = data;

      return (
        <Link to={{ pathname: `/contact-details/` + user.contactId }}>
          <img
            style={{ width: 25, display: 'block-inline', margin: '0 auto' }}
            className='dashAvatar rounded-circle'
            src={user && user.userImage ? user.userImage : avatarImage}
            alt='Avatar'
          />
           &nbsp;{[user.firstName,user.lastName].filter(Boolean).join(' ')}
        </Link>
       
      );
    }

    return null;
  };

  const onGetActivity = (data) => {
    if (data) {
      const { projectObjectId: projectId, companyId, contactId, title } = data;

      let pathname = '';

      if (projectId) {
        pathname = `/project-details/${projectId}`;
      } else if (contactId) {
        pathname = `/contact-details/${contactId}`;
      } else if (companyId) {
        pathname = `/company-details/${companyId}`;
      }

      if (pathname) {
        return <Link to={{ pathname }}>{title}</Link>;
      }

      return title;
    }

    return null;
  };

  const onGetMessage = (data, value) => {
    return value.replace('SYSTEM_DATE', teamActivityTimeFormat(data._created_at));
  };
  const onGetTime = (data) => moment(data._created_at).format('MM/DD/YYYY hh:mm a');

  const columns = useMemo(
    () => [
      {
        Header: 'User',
        accessor: 'user',
        width: 80,
        disableSortBy: true,
        Cell: (props) => onGetUser(props.row.original),
      },
      {
        Header: 'Activity',
        accessor: '_id',
        width: 250,
        disableSortBy: true,
        Cell: (props) => onGetActivity(props.row.original),
      },
      {
        Header: 'Message',
        accessor: 'actMessage',
        disableSortBy: true,
        width: 250,
        Cell: (props) => onGetMessage(props.row.original, props.value),
      },
      {
        Header: 'Time',
        accessor: '_created_at',
        width: 100,
        disableSortBy: true,
        Cell: (props) => onGetTime(props.row.original),
      },
    ],
    []
  );

  return [columns];
};
