import { Controller } from 'react-hook-form';
import { useDisabledFieldsContext } from '../../disabled-fields';
import SearchPlaceAutocomplete from '../../search-place-autocomplete';
import { DefaultLayout } from '../control-layouts/default-layout';

function FormSearchPlaceAutocomplete({
  control,
  name,
  label = '',
  placeholder = label,
  onSelect,
  required = false,
  disabled = false,
  Layout = DefaultLayout,
}) {
  const forceDisabled = useDisabledFieldsContext();

  return (
    <Controller
      rules={{ required: required && `${label} is required` }}
      control={control}
      name={name}
      render={({ field, fieldState }) => {
        return (
          <Layout
            name={field.name}
            label={<>{label} <i className='fa fa-search flip-horizontal' aria-hidden='true'></i></>}
            required={required}
            error={fieldState.error?.message}
            input={
              <SearchPlaceAutocomplete
                {...field}
                placeholder={placeholder}
                onSelect={onSelect}
                disabled={disabled || forceDisabled}
              />
            }
          />
        );
      }}
    />
  );
}

export default FormSearchPlaceAutocomplete;
