import { useState } from 'react';
import ProjectStatusModal from '../../../../components/Common/ProjectStatusModal.jsx';
import { ProjectStatusModalProps } from '../../../../components/Common/ProjectStatusModalProps.d';

type HeaderProps = {
  projectData: any;
  onOpenCOModal: any;
  onAfterSubmit?: any;
};

export const Header = ({ projectData = {}, onOpenCOModal, onAfterSubmit }: HeaderProps) => {
  const { jobName, jobNum, _id } = projectData;

  const [statusUpdateType, setStatusModalUpdateType] = useState('');
  const [isProjectStatusOpened, setIsProjectStatusOpened] = useState(false);

  const toggleStatusUpdate = (updateType: string) => {
    setStatusModalUpdateType(isProjectStatusOpened ? '' : updateType);
    setIsProjectStatusOpened(!isProjectStatusOpened);
  };

  const ProjectStatusModalComponent: React.ComponentType<ProjectStatusModalProps> =
    ProjectStatusModal;

  return (
    <div>
      <div className='d-flex justify-content-between align-items-center'>
        <div className='d-flex gap-2'>
          <span>
            <b>Job Name:</b> {jobName}
          </span>
          <span>
            <b>Job Number:</b> {jobNum}
          </span>
        </div>
        <ul className='d-flex justify-content-between gap-1'>
          <li>
            <button
              className='ms-auto btn btn-primary'
              onClick={() => toggleStatusUpdate('UpdateOriginalContract')}
            >
              Edit Contract
            </button>
          </li>
          <li>
            <button className='ms-auto btn btn-primary' onClick={() => onOpenCOModal()}>
              Add C/O
            </button>
          </li>
          <li>
            <button
              className='ms-auto btn btn-primary'
              onClick={() => toggleStatusUpdate('UpdateWIP')}
            >
              Enter WIP
            </button>
          </li>
        </ul>
      </div>

      <ProjectStatusModalComponent
        projectId={_id}
        modalStatusUpdate={isProjectStatusOpened}
        initialUpdateType={statusUpdateType}
        toggle={toggleStatusUpdate}
        onAfterUpdate={onAfterSubmit}
      />
    </div>
  );
};
