import { useState, useEffect } from 'react';
import { ContactCustomFieldsView } from './contact-custom-fields-view';
import styles from '../../../components/Company/view-company-detail-info/style.module.css';

type ContactCustomFieldsProps = {
  customFields: Array<any>;
  affiliation: any;
  selfAffiliated: boolean;
  isAccountAffiliation: boolean;
  defaultFields?: any;
};

export const ContactCustomFields = ({
  customFields = [],
  affiliation = {},
  selfAffiliated = false,
  isAccountAffiliation = false,
}: ContactCustomFieldsProps) => {

  const { customFields: customFieldsValues, _id } = affiliation;
  const [affCustomFields, setAffCustomFields] = useState<Array<any>>([]);

  useEffect(() => {
    const contactFields = customFields.filter(({ stages }) => stages.includes('contact'));
    const employeeFields = customFields.filter(({ stages }) => stages.includes('employee'));
    const affiliationFields = customFields.filter(({ stages }) => stages.includes('affiliation'));

    let affCustomFields = affiliationFields;
    if (selfAffiliated) {
      affCustomFields = [...contactFields];
    } else if (isAccountAffiliation) {
      affCustomFields = [...employeeFields];
    }

    setAffCustomFields(affCustomFields);
  }, [customFields, selfAffiliated, isAccountAffiliation]);

  return (
    <table className={`${styles.affiliationTable} ${styles.twoColumns}`}>
      <tbody>
        <ContactCustomFieldsView key={_id} fields={affCustomFields} values={customFieldsValues} />
      </tbody>
    </table>
  );
};
