export const matchDataForTable = (fieldsToMatch, list, contactData, passedProps) => {
  // This function should prepare any rows which have not been matched based on their object Ids

  /*let fieldsToMatch = {
    projectAssignments:  { lookupLocation: 'contactData', filterByCodes: ['EST','AX'] },
    estimatorArr: { lookupLocation: 'contactData' },
    accountExecArr: { lookupLocation: 'contactData' },
    occupancyType: {
      lookupLocation: 'occupancyType',
      collectionName: 'allOccupancyType',
      labelField: 'occupancyTypeName',
    },
    negotiatingMethod: {
      lookupLocation: 'negotiatingMethod',
      collectionName: 'allNegotiatingMethod',
      labelField: 'negotiatingMethodName',
    },
  };*/

  let fieldsToMatch_keys = Object.keys(fieldsToMatch);

  let outList = [];
  for (let x in list) {
    let curRow = list[x];
    for (let f in fieldsToMatch_keys) {
      let fieldName = fieldsToMatch_keys[f];
      let curObj = curRow[fieldsToMatch_keys[f]];

      if (fieldName === 'projectAssignments') {
        // Used for projectAssignToUser matching

        const fieldToMatch = fieldsToMatch.projectAssignments;

        const uFilterCodes = curObj ? [...new Set(curObj.map(roles => roles.projectRoleCode))] : [];
        const roleCodes = fieldToMatch.filterByCodes ?? uFilterCodes ?? [];

        for (let c in roleCodes) {
          const curRoleCode = roleCodes[c] ?? 'Unknown';

          const idFieldMatchArr = fieldName + 'Matched' + curRoleCode;
          const idFieldMatchArrLabel = fieldName + 'Label' + curRoleCode;

          if (fieldToMatch['lookupLocation'] == 'contactData' && contactData) {
            const filteredRoles = curObj.filter(roles => roles.projectRoleCode === curRoleCode);

            let contactMatchArr = []; // list of objectIds
            let contactMatchLabelArr = []; // list of identifiers
            for (let contactIdx in filteredRoles) {
              contactMatchArr[contactIdx] = {};
              const contactId = filteredRoles[contactIdx].contactId;
              const companyId = filteredRoles[contactIdx].companyId;
              const contactAffiliationId = filteredRoles[contactIdx].contactAffiliationId;

              contactMatchArr[contactIdx]['id'] = contactData[contactAffiliationId] ? contactAffiliationId : contactId;
              contactMatchArr[contactIdx]['contactId'] = contactId;
              contactMatchArr[contactIdx]['companyId'] = companyId;

              let matchedObject = contactData[contactAffiliationId] ?? contactData[contactId] ?? contactData[companyId];
              let labelName = '';
              if (matchedObject) {
                //  labelName = matchedObject.displayName??matchedObject.userFullName??'';
                const companyName = matchedObject.companyName ?? contactData[companyId]?.companyName;
                if (companyName) {
                  labelName = companyName;
                }
                const fullName = [matchedObject.firstName, matchedObject.lastName].filter(Boolean).join(' ') ?? [contactData[contactId]['firstName'], contactData[contactId]['lastName']].filter(Boolean).join(' ');
                if (fullName) {
                  if (companyName) {
                    labelName = labelName + ' - ';
                  }
                  labelName = labelName + fullName;
                }

              } else {
                labelName = 'Unmatched Contact';
              }
              labelName = labelName?.trim() ?? 'Unknown';

              contactMatchArr[contactIdx]['Label'] = labelName;
              contactMatchLabelArr[contactIdx] = labelName;
              contactMatchArr[contactIdx]['userFullName'] = labelName;
            }

            contactMatchArr.sort((a, b) => ((a.displayName?.toLowerCase().trim() > b.displayName?.toLowerCase().trim())) ? 1 : -1);
            contactMatchLabelArr.sort((a, b) => ((a?.toLowerCase().trim() > b?.toLowerCase().trim())) ? 1 : -1);


            curRow[idFieldMatchArr] = contactMatchArr;
            curRow[idFieldMatchArrLabel] = contactMatchLabelArr;
          }


        }

      } else if (fieldName === 'projectTeam') {
        // Used for projectAssignToUser matching

        const fieldToMatch = fieldsToMatch.projectTeam;

        const uFilterCodes = curObj ? Object.keys(curObj) : [];
        const roleCodes = fieldToMatch.filterByCodes ?? uFilterCodes ?? [];

        for (let c in roleCodes) {
          const curRoleCode = roleCodes[c] ?? 'Unknown';

          const idFieldMatchArr = fieldName + 'Matched' + curRoleCode;
          const idFieldMatchArrLabel = fieldName + 'Label' + curRoleCode;

          if (fieldToMatch['lookupLocation'] == 'contactData' && contactData && curObj && curObj[curRoleCode]?.length>0) {
            const filteredRoles = curObj[curRoleCode];

            let contactMatchArr = []; // list of objectIds
            let contactMatchLabelArr = []; // list of identifiers
            for (let contactIdx in filteredRoles) {
              contactMatchArr[contactIdx] = {};
              //const contactId = filteredRoles[contactIdx].contactId;
              //const companyId = filteredRoles[contactIdx].companyId;
              const contactAffiliationId = filteredRoles[contactIdx];

              contactMatchArr[contactIdx]['id'] = contactData[contactAffiliationId] ? contactAffiliationId : null;
              //contactMatchArr[contactIdx]['contactId'] = contactId;
              //contactMatchArr[contactIdx]['companyId'] = companyId;

              let matchedObject = contactData[contactAffiliationId];
              let labelName = '';
              if (matchedObject) {
                //  labelName = matchedObject.displayName??matchedObject.userFullName??'';
                const companyName = matchedObject.companyName ??'';
                if (companyName) {
                  labelName = companyName;
                }
                const fullName = [matchedObject.firstName, matchedObject.lastName].filter(Boolean).join(' ') ?? '';
                if (fullName) {
                  if (companyName) {
                    labelName = labelName + ' - ';
                  }
                  labelName = labelName + fullName;
                }

              } else {
                labelName = 'Unmatched Contact';
              }
              labelName = labelName?.trim() ?? 'Unknown';

              contactMatchArr[contactIdx]['Label'] = labelName;
              contactMatchLabelArr[contactIdx] = labelName;
              contactMatchArr[contactIdx]['userFullName'] = labelName;
            }

            contactMatchArr.sort((a, b) => ((a.displayName?.toLowerCase().trim() > b.displayName?.toLowerCase().trim())) ? 1 : -1);
            contactMatchLabelArr.sort((a, b) => ((a?.toLowerCase().trim() > b?.toLowerCase().trim())) ? 1 : -1);


            curRow[idFieldMatchArr] = contactMatchArr;
            curRow[idFieldMatchArrLabel] = contactMatchLabelArr;
          }


        }


      }

      else if (curObj?.__type == 'Object') {
        // object does not need to be matched, just use field provided
        let fieldToMatch = fieldsToMatch[fieldName];
        let idFieldMatch = fieldsToMatch_keys[f] + 'ID';
        let idFieldMatchLabel = fieldsToMatch_keys[f] + 'Label';

        let idToMatch = curObj.objectId;
        let labelToMatch = curObj[fieldToMatch['labelField']]

        curRow[idFieldMatch] = idToMatch;
        curRow[idFieldMatchLabel] = labelToMatch?.trim();;
      }
      else if (curObj?.__type == 'Pointer') {
        // object is a parse pointer, name itself does not need to be looked up
        //    (assumes name is className + label and id is className + ID)
        let curClassName = curObj.className;
        let fieldToMatch = fieldsToMatch[fieldName];
        let idFieldMatch = fieldsToMatch_keys[f] + 'ID';
        let idFieldMatchLabel = fieldsToMatch_keys[f] + 'Label';

        let idToMatch = curObj.objectId;

        let labelToMatch = '';

        let matchedObject = [];

        let matchedProp = [];
        if (fieldToMatch['lookupLocation']) {
          if (fieldToMatch['lookupLocation'] == 'contactData') {
            matchedObject = contactData && contactData[idToMatch];
            if (matchedObject) {
              labelToMatch = matchedObject.firstName + ' ' + matchedObject.lastName;
            }
            matchedProp = [];
          } else {
            matchedProp = passedProps[fieldToMatch['lookupLocation']];
          }
        }

        if (fieldToMatch['collectionName'] && matchedProp) {
          matchedProp = matchedProp[fieldToMatch['collectionName']];
        } else if (fieldToMatch['collectionName']) {
          matchedProp = [];
        }

        if (idToMatch && matchedProp && matchedProp.length > 0) {
          matchedObject = matchedProp.filter((x) => x.objectId === idToMatch);
          matchedObject = matchedObject[0];
        }

        if (matchedObject && fieldToMatch['labelField']) {
          if (matchedObject[fieldToMatch['labelField']]) {
            labelToMatch = matchedObject[fieldToMatch['labelField']];
          }
        }

        curRow[idFieldMatch] = idToMatch;
        curRow[idFieldMatchLabel] = labelToMatch;
      } else if (curObj && curObj.length > 0) { // matching by id, not embedded objectid or parse pointer
        let fieldToMatch = fieldsToMatch[fieldName];
        if (fieldToMatch['lookupLocation'] == 'contactData') {
          let idFieldMatchArr = fieldName + 'Matched';
          let idFieldMatchArrLabel = fieldName + 'Label';
          let contactMatchArr = [];
          let contactMatchLabelArr = [];
          for (let contactIdx in curObj) {
            contactMatchArr[contactIdx] = {};
            const curObjId = curObj[contactIdx].userId;
            contactMatchArr[contactIdx]['ID'] = curObjId;
            contactMatchArr[contactIdx]['userId'] = curObjId;
            let matchedObject = contactData && contactData[curObjId];
            let labelName = '';
            if (matchedObject) {
              labelName = matchedObject.firstName + ' ' + matchedObject.lastName;
            } else {
              labelName = curObj[contactIdx].userFullName;
            }
            labelName = labelName?.trim() ?? 'Unknown';

            contactMatchArr[contactIdx]['Label'] = labelName;
            contactMatchLabelArr[contactIdx] = labelName;
            contactMatchArr[contactIdx]['userFullName'] = labelName;
          }
          curRow[idFieldMatchArr] = contactMatchArr;
          curRow[idFieldMatchArrLabel] = contactMatchLabelArr.join(', ');
        } else {

          let idFieldMatchArr = fieldName + 'Matched';
          let idFieldMatchArrLabel = fieldName + 'Label';
          let fieldMatchArr = [];
          let fieldMatchLabelArr = [];
          let matchedObject;
          let labelToMatch;

          if (Array.isArray(curObj)) {
            for (let fieldIdx in curObj) {
              //fieldMatchArr[fieldIdx] = {};
              const curObjId = curObj[fieldIdx]?.objectId ?? curObj[fieldIdx];

              let matchedProp = passedProps[fieldToMatch['lookupLocation']];


              if (fieldToMatch['collectionName'] && matchedProp) {
                matchedProp = matchedProp[fieldToMatch['collectionName']];
              } else if (fieldToMatch['collectionName']) {
                matchedProp = [];
              }

              if (curObjId && matchedProp && matchedProp.length > 0) {
                matchedObject = matchedProp.find((x) => x.objectId === curObjId);
              }

              if (matchedObject && fieldToMatch['labelField']) {
                if (matchedObject[fieldToMatch['labelField']]) {
                  labelToMatch = matchedObject[fieldToMatch['labelField']];
                }
              }

              if (matchedObject) {
                fieldMatchArr.push(matchedObject);

                fieldMatchLabelArr.push(labelToMatch);
              }
            }

            fieldMatchArr.sort((a, b) => ((a.label?.toLowerCase().trim() > b.label?.toLowerCase().trim())) ? 1 : -1);
            fieldMatchLabelArr.sort((a, b) => ((a?.toLowerCase().trim() > b?.toLowerCase().trim())) ? 1 : -1);


            curRow[idFieldMatchArr] = fieldMatchArr;
            curRow[idFieldMatchArrLabel] = fieldMatchLabelArr;
          } else {


            let matchedProp = passedProps[fieldToMatch['lookupLocation']];


            if (fieldToMatch['collectionName'] && matchedProp) {
              matchedProp = matchedProp[fieldToMatch['collectionName']];
            } else if (fieldToMatch['collectionName']) {
              matchedProp = [];
            }

            if (curObj && matchedProp && matchedProp.length > 0) {
              matchedObject = matchedProp.find((x) => x.objectId === curObj);
            }

            if (matchedObject && fieldToMatch['labelField']) {
              if (matchedObject[fieldToMatch['labelField']]) {
                labelToMatch = matchedObject[fieldToMatch['labelField']];
              }
            }

            curRow[idFieldMatchArr] = curObj;
            curRow[idFieldMatchArrLabel] = labelToMatch;

          }


        }
      }
    }
    outList.push(curRow);
  }

  return outList;
};
