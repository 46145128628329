import { UpdateAccountData } from '@module/accounts';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import UpdateAccountModal from '../../modals/update-account-modal';
import { getAllAccounts } from '../../modules/accounts';
import { selectGetAllAccounts } from '../../modules/accounts/selectors';
import { columns } from './columns';

import CustomTable from '../../components/Common/CustomTable';

const AccountsListPage = () => {
  const dispatch = useDispatch();

  const [modalOpened, setModalOpened] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState<UpdateAccountData | null>(null);
  //const [search, setSearch] = useState('');

  const openModal = (account: any = null) => {
    setModalOpened(true);

    if (account == null) return setSelectedAccount(null);

    setSelectedAccount({
      _id: account._id || account.objectId,
      adminFirstName: account.firstName,
      adminLastName: account.lastName,
      adminEmail: account.accountEmail??account.companyEmail,
      adminUserId: account.adminUserId,
      adminContactId: account.adminContactId,
      
      adminPhoneArr: account.phoneArr || [],

      accountName: account.companyName,
      companyEmail: account.companyEmail,
      website: account.companyWebsite,
      route: account.companyRoute,
      lat: account.lat,
      lng: account.lng,
      taxId: account.taxId,
      maxUsers: account.maxUsers,
      chargebeeId: account.chargebeeId,
      address1: account.companyAddress1,
      address2: account.companyAddress2,
      city: account.city,
      state: account.state,
      zipCode: account.zipCode,
      country: account.country,
      phoneArr: account.accountPhoneArr || [],
    });
  };

  const closeModal = () => {
    setModalOpened(false);
  };

  const { data: accounts } = useSelector(selectGetAllAccounts);

  useEffect(() => {
    dispatch(getAllAccounts());
  }, []);

  return (
    <>
      <div className='d-flex align-items-center justify-content-between'>
        <h2 className='heading2'>Account List</h2>

        <div className='d-flex gap-3'>
          <button className='btn btn-primary' onClick={() => openModal()}>
            Add
          </button>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-12 sortable-table'></div>
        <div>
        <CustomTable
            columns={columns}
            list={accounts}
            //preferences={this.props.listDataPref}
            className='account-list-table'
            //customTableTitle={ 'Account List'}
            canShowDateFilter={true}
            onRowClicked={(row) => openModal(row.row.original)}
            canShowHeaderItems={true}
            canShowRowSelection={false}
            pageSizeArray={[50, 100, 200]}
            
          />
          {/*<SettingsTable
            searchKeyword={search}
            columns={columns}
            data={accounts}
            onRowClicked={(row) => openModal(row)}
        />*/}
        </div>
      </div>

      <UpdateAccountModal open={modalOpened} onClose={closeModal} account={selectedAccount} />
    </>
  );
};

export default AccountsListPage;
