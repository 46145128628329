import Joi from 'joi';
import { notification } from 'antd';
import { useEffect, useRef } from 'react';
import { Control, Controller, useFormContext } from 'react-hook-form';
import { useConfirm } from '../../confirmation-dialog';
import styles from './style.module.css';

type AvatarComponentProps = {
  picture?: string | null;
  onPictureSelect: (picture: string | null) => unknown;
  onFileSelect: (file: any) => unknown;
  onFileRemove: () => unknown;
  isRectangular?: boolean;
  width?: string;
};

export const AvatarComponent = ({
  picture = '',
  onPictureSelect,
  onFileSelect = () => void 0,
  onFileRemove = () => void 0,
  isRectangular = false,
  width,
}: AvatarComponentProps) => {
  const fileRef = useRef<any>();
  const confirm = useConfirm();

  const allowedTypes = ['image/jpeg', 'image/png'];
  const allowedSize = 10000000;

  const onChangeClick = () => {
    fileRef.current.click();
  };

  const onRemoveClick = async () => {
    const ok = await confirm({
      title: 'Confirm your action',
      question: 'Are you sure you want to remove the picture?',
    });

    if (!ok) return;

    onPictureSelect(null);
    onFileRemove();
  };

  const onFileChange = (e) => {
    const file = e.target.files[0];
    
    // to upload same picture multiple times
    fileRef.current.value = '';

    if (!allowedTypes.includes(file.type))
      return notification.error({ message: 'This file type is not allowed!' });
    if (file.size > allowedSize)
      return notification.error({ message: 'This image is too large - Please limit to <10mb' });

    onPictureSelect(URL.createObjectURL(file));
    onFileSelect(file);
  };

  useEffect(() => {
    fetch(picture as string, { cache: 'no-cache', mode: 'no-cors' });
  }, [picture]);

  const { error: pictureError } = Joi.string().uri().required().validate(picture);
  const imgClassName = isRectangular ? styles['rect-image'] : styles.image;
  const doesPictureExist = !pictureError && picture;

  return (
    <div className='flex row' data-testid='AvatarComponent'>
      <div className='col-12'>
        <div className='row'>
          <div className='col d-flex '>
            <div className='position-relative img-responsive' style={{ width }}>
              <div className='d-flex justify-content-center flex-sm-column align-items-center'>
                {!doesPictureExist
                  ? <div className={`${imgClassName} img-responsive ${styles['no-image']}`} style={{ width }}>
                      <i className="fa-regular fa-image"></i>
                    </div>
                  : <img src={picture} className={`${imgClassName} img-responsive`} style={{ width }} />
                }
                <i
                  onClick={onChangeClick}
                  role='button'
                  className={`fa fa-camera h4 ${picture ? styles.icon : styles['icon-no-image']} ${
                    isRectangular ? `${styles['rect-icon']}` : ''
                  }`}
                />
                {doesPictureExist && (
                  <a
                    className={styles['remove-btn']}
                    data-testid='RemoveButton'
                    onClick={onRemoveClick}
                  >
                    Remove Picture
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <input
        onChange={onFileChange}
        accept={allowedTypes.join(', ')}
        ref={fileRef}
        type='file'
        hidden
      />
    </div>
  );
};

type FormAvatarProps = {
  control: Control<any>;
  name: string;
  onFileSelect: (file: any) => unknown;
  onFileRemove: () => unknown;
};

const FormAvatar = ({ name, onFileSelect, onFileRemove }: FormAvatarProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <AvatarComponent
          picture={field.value}
          onPictureSelect={field.onChange}
          onFileSelect={onFileSelect}
          onFileRemove={onFileRemove}
          isRectangular={true}
          width='170px'
        />
      )}
    />
  );
};

export default FormAvatar;
