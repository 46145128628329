import * as actionTypes from './actionTypes';
import * as API from '../api/buildingType';

export const createBuildingTypeRequest = () => ({
  type: actionTypes.CREATE_BUILDING_TYPE_REQUEST,
});

export const createBuildingTypeSuccess = (response) => ({
  type: actionTypes.CREATE_BUILDING_TYPE_SUCCESS,
  payload: {
    response,
  },
});

export const createBuildingTypeError = (error) => ({
  type: actionTypes.CREATE_BUILDING_TYPE_ERROR,
  payload: {
    error,
  },
});

export const createBuildingTypeAction = (data) => {
  return (dispatch) => {
    dispatch(createBuildingTypeRequest());
    return API.createBuildingType(data)
      .then((response) => {
        dispatch(createBuildingTypeSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          createBuildingTypeError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

export const getAllBuildingTypeRequest = () => ({
  type: actionTypes.GET_ALL_BUILDING_TYPE_REQUEST,
});

export const getAllBuildingTypeSuccess = (response) => ({
  type: actionTypes.GET_ALL_BUILDING_TYPE_SUCCESS,
  payload: {
    response,
  },
});

export const getAllBuildingTypeError = (error) => ({
  type: actionTypes.GET_ALL_BUILDING_TYPE_ERROR,
  payload: {
    error,
  },
});

export const getAllBuildingTypeAction = (data) => {
  return (dispatch) => {
    dispatch(getAllBuildingTypeRequest());
    return API.getAllBuildingType(data)
      .then((response) => {
        dispatch(getAllBuildingTypeSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          getAllBuildingTypeError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

export const updateBuildingTypeRequest = () => ({
  type: actionTypes.UPDATE_BUILDING_TYPE_REQUEST,
});

export const updateBuildingTypeSuccess = (response) => ({
  type: actionTypes.UPDATE_BUILDING_TYPE_SUCCESS,
  payload: {
    response,
  },
});

export const updateBuildingTypeError = (error) => ({
  type: actionTypes.UPDATE_BUILDING_TYPE_ERROR,
  payload: {
    error,
  },
});

export const updateBuildingTypeAction = (data) => {
  return (dispatch) => {
    dispatch(updateBuildingTypeRequest());
    return API.updateBuildingType(data)
      .then((response) => {
        dispatch(updateBuildingTypeSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          updateBuildingTypeError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

export const deleteBuildingTypeRequest = () => ({
  type: actionTypes.DELETE_BUILDING_TYPE_REQUEST,
});

export const deleteBuildingTypeSuccess = (response) => ({
  type: actionTypes.DELETE_BUILDING_TYPE_SUCCESS,
  payload: {
    response,
  },
});

export const deleteBuildingTypeError = (error) => ({
  type: actionTypes.DELETE_BUILDING_TYPE_ERROR,
  payload: {
    error,
  },
});

export const deleteBuildingTypeAction = (data) => {
  return (dispatch) => {
    dispatch(deleteBuildingTypeRequest());
    return API.deleteBuildingType(data)
      .then((response) => {
        dispatch(deleteBuildingTypeSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          deleteBuildingTypeError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};
