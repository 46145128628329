import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { DisabledFieldsProvider } from '../../shared/disabled-fields';
import { AffiliationsSection } from '../../forms/affiliation-form/affiliations-section';

type FormerAffiliationsModalProps = {
  open: boolean;
  onClose: () => unknown;
  CustomFields: () => unknown;
};

export const FormerAffiliationsModal = ({ open, onClose, CustomFields }: FormerAffiliationsModalProps) => {
  const { watch } = useFormContext();

  const formerAffiliations = watch('formerAffiliations');

  useEffect(() => {
    if (!formerAffiliations.length) onClose();
  }, [formerAffiliations]);

  return (
    <Modal isOpen={open} toggle={onClose} size='lg'>
      <ModalHeader toggle={onClose}>Former Affiliations</ModalHeader>
      <ModalBody>
        <DisabledFieldsProvider disabled={true}>
          <AffiliationsSection
            isFormerAffiliationSlot
            name='formerAffiliations'
            CustomFields={CustomFields}
          />
        </DisabledFieldsProvider>
      </ModalBody>
      <ModalFooter>
        <div className='btn btn-primary' onClick={onClose}>
          Close
        </div>
      </ModalFooter>
    </Modal>
  );
};
