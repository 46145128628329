import * as actionTypes from '../actions/actionTypes';
const initState = {
  generalInfo: {},
  message: '',
  isFetching: false,
  isfetched: false,
  isfetchedError: false,
  generalSubmission: false,
  success: null,
  variableData: {},
  variableAddEdit: {},
};

export default (state = initState, action = {}) => {
  switch (action.type) {
    case actionTypes.GET_EXISTING_ACCOUNT_SETTINGS_REQUEST:
      return {
        ...state,
        generalInfo: {},
        message: '',
        isFetching: true,
        isfetched: false,
        isfetchedError: false,
      };
    case actionTypes.GET_EXISTING_ACCOUNT_SETTINGS_SUCCESS:
      return {
        ...state,
        generalInfo: action.payload.response.result.data,
        message: '',
        isFetching: false,
        isfetched: true,
        isfetchedError: false,
      };

    case actionTypes.GET_EXISTING_ACCOUNT_SETTINGS_ERROR:
      return {
        ...state,
        message: '',
        isFetching: false,
        isfetched: false,
        isfetchedError: true,
      };

    case actionTypes.UPDATE_ACCOUNT_SETTINGS_REQUEST:
      return {
        ...state,
        buttonActive: true,
        response: [],
        message: '',
        generalSubmission: false,
      };
    case actionTypes.UPDATE_ACCOUNT_SETTINGS_SUCCESS:
      return {
        ...state,
        buttonActive: false,
        response: action.payload.response.result,
        generalSubmission: true,
        success: action.payload.response.result.success,
        message: action.payload.response.result.success
          ? 'Record Updated successfully'
          : action.payload.response.result.msg,
      };
    case actionTypes.UPDATE_ACCOUNT_SETTINGS_ERROR:
      return {
        ...state,
        buttonActive: false,
        response: [],
        generalSubmission: false,
        success: action.payload.response.result.success,
        message: action.payload.response.result.msg,
      };

    case actionTypes.SET_IMAGE_MASTER_REQUEST:
      return {
        ...state,
        imageData: [],
        message: '',
        imageUploadStatus: false,
      };
    case actionTypes.SET_IMAGE_MASTER_SUCCESS:
      return {
        ...state,
        imageData: action.payload.response.result.data,
        success: action.payload.response.result.success,
        message: action.payload.response.result.success
          ? 'Record Updated successfully'
          : action.payload.response.result.msg,
        imageUploadStatus: true,
      };
    case actionTypes.SET_IMAGE_MASTER_ERROR:
      return {
        ...state,
        variableData: {},
        message: '',
        imageUploadStatus: false,
      };

    case actionTypes.GET_VARIABLE_MASTER_REQUEST:
      return {
        ...state,
        variableData: {},
        message: '',
        imageUploadStatus: false,
      };
    case actionTypes.GET_VARIABLE_MASTER_SUCCESS:
      return {
        ...state,
        variableData: action.payload.response.result,
        success: action.payload.response.result.success,
        message: action.payload.response.result.success
          ? 'Record Updated successfully'
          : action.payload.response.result.msg,
        imageUploadStatus: true,
      };
    case actionTypes.GET_VARIABLE_MASTER_ERROR:
      return {
        ...state,
        variableData: {},
        message: '',
        imageUploadStatus: false,
      };
    case actionTypes.AddEdit_VARIABLE_MASTER_REQUEST:
      return {
        ...state,
        variableAddEdit: {},
        message: '',
        imageUploadStatus: false,
      };
    case actionTypes.AddEdit_VARIABLE_MASTER_SUCCESS:
      return {
        ...state,
        variableAddEdit: action.payload.response.result,
        success: action.payload.response.result.success,
        message: action.payload.response.result.success
          ? 'Record Updated successfully'
          : action.payload.response.result.msg,
        imageUploadStatus: true,
      };
    case actionTypes.AddEdit_VARIABLE_MASTER_ERROR:
      return {
        ...state,
        variableAddEdit: {},
        message: '',
        imageUploadStatus: false,
      };

    case actionTypes.ADD_VARIABLE_DATA:
      return {
        ...state,
        variableData: {
          ...state.variableData,
          data: [action.payload, ...state.variableData.data],
        },
      };
    case actionTypes.UPDATE_VARIABLE_DATA_BY_INDEX:
      return {
        ...state,
        variableData: {
          ...state.variableData,
          data: [
            ...state.variableData.data.slice(0, action.index),
            { ...state.variableData.data[action.index], ...action.payload },
            ...state.variableData.data.slice(action.index + 1),
          ],
        },
      };

    default:
      return state;
  }
};
