import { useFormContext } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { selectGetEmployeeInvitationStatus } from '../../modules/contacts/selectors';
import AddressArray from '../../shared/form-controls/address-array';
import EmailArray from '../../shared/form-controls/email-array';
import PhoneArray from '../../shared/phone-array';
import { useCallback, useEffect, useState } from 'react';
import { getCompanyEditInfo } from '../../modules/companies';
import AddCompanyModal from '../../modals/add-company-modal/add-company-modal';
import { validateContactEmail, validateContactPhoneNumber } from '../../modules/contacts';
import { PhoneMatchingContactsModal } from './phone-matching-contacts-modal';
import { useMatchingContactsContext } from './matching-contacts-context';

export const AffiliationForm = ({
  index,
  name = 'affiliations',
  isUpdate,
  contactId,
  isFormerAffiliationSlot,
  CustomFields,
}: {
  index: number;
  name?: string;
  isUpdate?: boolean;
  contactId?: string;
  isFormerAffiliationSlot?: boolean;
  CustomFields?: any;
}) => {
  const dispatch: any = useDispatch();
  const formContext = useFormContext();

  const [companyData, setCompanyData] = useState<any>({});
  const [isCompanyModalOpened, setIsCompanyModalOpened] = useState<boolean>(false);
  const [isAllInputsVisible, setIsAllInputsVisible] = useState<boolean>(false);

  const { watch, getValues, setValue } = formContext;

  const item = `${name}.${index}`;

  const companyId = getValues(`${item}.companyId`);
  const contactRole = getValues(`${item}.contactRole`);
  const department = getValues(`${item}.department`);
  const employeeId = getValues(`${item}.employeeId`);
  const isIndependentContractor = getValues(`${item}.isIndependentContractor`);

  const isAnyInfo = contactRole || department || employeeId || isIndependentContractor;

  const isAddMoreInformationVisible = isAnyInfo || isAllInputsVisible;

  const isSelfAffiliated = watch(`${item}.selfAffiliated`);
  const isAccountAffiliation = watch(`${item}.isAccountAffiliation`);

  const { data: invitation } = useSelector(selectGetEmployeeInvitationStatus);

  const getCompanyData = useCallback(async () => {
    const data = await dispatch((getCompanyEditInfo as any)({ companyId })).unwrap();
    setCompanyData({ ...data, isAccountAffiliation });
  }, [companyId, isAccountAffiliation]);

  useEffect(() => {
    if (!isSelfAffiliated && companyId) {
      getCompanyData();
    }
  }, [isSelfAffiliated, companyId]);

  useEffect(() => {
    if (
      !isUpdate ||
      !isAccountAffiliation ||
      contactId !== invitation.contactId ||
      invitation.status !== 'invited'
    )
      return;

    const emailArr = getValues(`${item}.emailArr`);
    const hasPrimaryEmail = emailArr.findIndex((item) => item.isPrimary) > -1;
    const idx = emailArr.findIndex((item) => item.email === invitation?.email);
    emailArr.unshift(
      idx > -1
        ? emailArr.splice(idx, 1)[0]
        : { email: invitation?.email, isPrimary: !hasPrimaryEmail }
    );

    setValue(`${item}.emailArr`, emailArr);
  }, [isAccountAffiliation]);

  const getIsDisabled = (item) =>
    item.email &&
    contactId === invitation.contactId &&
    invitation?.email &&
    item.email === invitation?.email &&
    invitation?.status !== 'not-invited';

  const customFieldsFilter = (stage: string) => (field: any) =>
    field.stages.includes(stage) && (isAddMoreInformationVisible ? true : !field.hideInitially);

  const renderCustomFieldsByStage = useCallback(
    (stage: string) => CustomFields(customFieldsFilter(stage), name, index),
    [isAddMoreInformationVisible, CustomFields, index]
  );

  const onEditCompany = useCallback(() => {
    setIsCompanyModalOpened(true);
  }, [companyId]);

  const showAddMoreInformation =
    (isAccountAffiliation || !isSelfAffiliated) &&
    !isAddMoreInformationVisible &&
    !isFormerAffiliationSlot;

  const [matchingModalOpen, setMatchingModalOpen] = useState(false);
  const [matchingData, setMatchingData] = useState<any[]>([]);
  const [matchingField, setMatchingField] = useState<any>(null);
  const [matchingType, setMatchingType] = useState<any>(null);

  const handleValidatePhoneNumber = async (value: any) => {
    const data = await dispatch(
      validateContactPhoneNumber({ phoneValue: value, contactId })
    ).unwrap();

    if (data.length) return data;

    return null;
  };

  const handleValidateEmail = async (value: any) => {
    const data = await dispatch(validateContactEmail({ emailValue: value, contactId })).unwrap();

    if (data.length) return data;

    return null;
  };

  const handleWarningClick = (data: any, field: any, type: any) => {
    setMatchingData(data);
    setMatchingField(field);
    setMatchingType(type);
    setMatchingModalOpen(true);
  };

  const matchingContactsContext = useMatchingContactsContext();

  const handleValidationDataChange = useCallback(
    (data: any[], fieldData: any[], type: string) => {
      if (!isFormerAffiliationSlot) {
        matchingContactsContext.pushValidationData({
          affiliationIndex: index,
          type,
          data,
        });
      }
    },
    [index]
  );

  return (
    <>
      <div data-testid='contact-affiliation-form' className='d-flex flex-column gap-1'>
        {!isSelfAffiliated && !isAccountAffiliation && renderCustomFieldsByStage('affiliation')}
        {isAccountAffiliation && renderCustomFieldsByStage('employee')}

        <EmailArray
          getDisabledValue={getIsDisabled}
          name={`${item}.emailArr`}
          copyData={companyData}
          onEditCompany={onEditCompany}
          onValidate={handleValidateEmail}
          onWarningClick={(data, field) => handleWarningClick(data, field, 'email')}
          onValidationDataChange={(data, fieldData) =>
            handleValidationDataChange(data, fieldData, 'email')
          }
        />
        <PhoneArray
          name={`${item}.phoneArr`}
          copyData={companyData}
          onEditCompany={onEditCompany}
          onValidate={handleValidatePhoneNumber}
          onWarningClick={(data, field) => handleWarningClick(data, field, 'phone')}
          onValidationDataChange={(data, fieldData) =>
            handleValidationDataChange(data, fieldData, 'phone')
          }
        />

        <AddressArray
          name={`${item}.addressArr`}
          copyData={companyData}
          onEditCompany={onEditCompany}
        />

        {showAddMoreInformation && (
          <a onClick={() => setIsAllInputsVisible(true)}>Add more company information</a>
        )}
      </div>
      {isCompanyModalOpened && (
        <AddCompanyModal
          onClose={() => setIsCompanyModalOpened(false)}
          open={isCompanyModalOpened}
          onCompanyUpdate={getCompanyData}
          companyId={companyId}
        />
      )}
      <PhoneMatchingContactsModal
        open={matchingModalOpen}
        onClose={() => setMatchingModalOpen(false)}
        field={matchingField}
        data={matchingData}
        type={matchingType}
      />
    </>
  );
};
