import moment from 'moment';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import avatarImage from '../../components/static/images/avatar3.png';
import { teamActivityTimeFormat } from '../../utils/date-formatters';

export const useTeamActivityPage = () => {
  const onGetUser = (data) => {
    if (data) {
      const { user = {} } = data;

      return (
        <Link to={{ pathname: `/contact-details/` + user.contactId }}>
          <img
            style={{ width: 25, display: 'block-inline', margin: '0 auto' }}
            className='dashAvatar rounded-circle'
            src={user && user.userImage ? user.userImage : avatarImage}
            alt='Avatar'
          />
          &nbsp;{[user.firstName,user.lastName].filter(Boolean).join(' ')}
        </Link>
      );
    }

    return null;
  };

  const onGetActivity = (data, accessor) => {
    if (data) {
      const { recordType, recordId, subRecordId, subRecordType } = data;

      let pathname = '';

      const subRecordTypeMap = {
        bid: 'bids',
        changeOrder: 'change-orders',
        email: 'email',
        note: 'notes',
        meeting: 'schedule',
      };

      const subRecordTypePath = subRecordTypeMap[subRecordType]??null;


      if (recordType === 'project') {
        pathname = `/project-details/${recordId}`;
      } else if (recordType === 'contact') {
        pathname = `/contact-details/${recordId}`;
      } else if (recordType === 'company') {
        pathname = `/company-details/${recordId}`;
      }

      if(subRecordTypePath&&subRecordId?.length>0){
        pathname = pathname+`/${subRecordTypePath}/view/${subRecordId}`;
      }

      if (pathname) {
        return <Link to={{ pathname }}>{data[accessor]}</Link>;
      }

      return data[accessor];
    }

    return null;
  };

  const onGetMessage = (data, value) => {
    return value.replace('SYSTEM_DATE', teamActivityTimeFormat(data._created_at));
  };
  const onGetTime = (data) => moment(data._created_at).format('MM/DD/YYYY hh:mm a');

  const columns = useMemo(
    () => [
      {
        Header: 'User',
        accessor: 'user',
        width: 80,
        disableSortBy: true,
        Cell: (props) => onGetUser(props.row.original),
      },
      {
        Header: 'Activity',
        accessor: 'action',
        width: 100,
        disableSortBy: true,
        Cell: (props) => onGetActivity(props.row.original,props.column.id),
      },
      {
        Header: 'Parent Item',
        accessor: 'recordName',
        width: 250,
        disableSortBy: true,
        Cell: (props) => onGetActivity(props.row.original,props.column.id),
      },
      {
        Header: 'Message',
        accessor: 'description',
        disableSortBy: true,
        width: 250,
        Cell: (props) => onGetMessage(props.row.original, props.value),
      },
      {
        Header: 'Time',
        accessor: '_created_at',
        width: 100,
        disableSortBy: true,
        Cell: (props) => onGetTime(props.row.original),
      },
    ],
    []
  );

  return [columns];
};
