import * as actionTypes from '../actions/actionTypes';

const initState = {
  profile: {},
  allProfiles: [],
  message: '',
  isfetched: false,
  isUpdateStatus: false,
  success: null,
};

export default (state = initState, action = {}) => {
  switch (action.type) {
    case actionTypes.GET_EXISTING_PROFILE_REQUEST:
      return {
        ...state,
        profile: {},
        message: '',
        isfetched: false,
      };
    case actionTypes.GET_EXISTING_PROFILE_SUCCESS:
      return {
        ...state,
        profile: action.payload.response.result.data,
        message: 'Profile Already Existing',
        isfetched: true,
      };

    case actionTypes.GET_EXISTING_PROFILE_ERROR:
      return {
        ...state,
        message: '',
        isfetched: false,
      };

    case actionTypes.UPDATE_PROFILE_REQUEST:
      return {
        ...state,
        profile: {},
        message: '',
        isfetched: false,
      };
    case actionTypes.UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        profile: action.payload.response.result.data,
        isfetched: true,
        isUpdateStatus: true,
        success: action.payload.response.result.success,
        message: action.payload.response.result.success
          ? 'Record Updated successfully'
          : action.payload.response.result.msg,
      };

    case actionTypes.UPDATE_PROFILE_ERROR:
      return {
        ...state,
        isfetched: false,
        isUpdateStatus: false,
        success: action.payload.response.result.success,
        message: action.payload.response.result.msg,
      };

    default:
      return state;
  }
};
