import { useFormContext } from 'react-hook-form';
import styles from '../style.module.scss';
import { formatMoney } from '../../../../../utils/number-formatters';

type TotalProps = {
  showProfitAsPers: boolean;
  initialValues: { currentGrossProfit: number; currentContractAmount: number };
};

export const Total = ({ showProfitAsPers, initialValues }: TotalProps) => {
  const form = useFormContext();

  const estTotalCost = +form.watch('estTotalCost', 0);
  const estTotalProfit = +form.watch('estTotalProfit', 0);
  const estTotalContract = +form.watch('estTotalContract', 0);

  const emptyScope = estTotalProfit === 0 && estTotalCost === 0 && estTotalContract === 0;
  const { currentGrossProfit = 0, currentContractAmount = 0 } = initialValues;

  const totalProfit = emptyScope ? currentGrossProfit : estTotalProfit;
  const totalCost = emptyScope ? currentContractAmount - currentGrossProfit : estTotalCost;
  const totalCtr = emptyScope ? totalProfit + totalCost : estTotalContract;

  const costPers = totalCost === 0 ? 0 : totalCtr === 0 ? 100 : parseFloat(((totalCost * 100) / totalCtr).toFixed(1));
  const profitPers = totalCtr === 0 ? 0 : totalCtr === 0 ? 100 : parseFloat(((totalProfit * 100) / totalCtr).toFixed(1));

  return (
    <div data-testid='bidManagementModalTotal'>
      <hr />
      <div className={`row col-md-12 ${!showProfitAsPers ? 'fw-bold' : ''}`}>
        <div className='offset-md-2 col-md-2'>Totals ($):</div>
        <div className={`text-end ${styles.inputWidth}`}>{formatMoney(totalCost)}</div>
        <div className={`text-end ${styles.inputWidth}`}>{formatMoney(totalProfit)}</div>
        <div className={`text-end ${styles.inputWidth}`}>{formatMoney(totalCtr)}</div>
      </div>
      <div className={`row col-md-12 ${showProfitAsPers ? 'fw-bold' : ''}`}>
        <div className='offset-md-2 col-md-2'>Totals (%):</div>
        <div className={`text-end ${styles.inputWidth}`}>{formatMoney(costPers,1,'')}%</div>
        <div className={`text-end ${styles.inputWidth}`}>{formatMoney(profitPers,1,'')}%</div>
        <div className={`text-end ${styles.inputWidth}`}>100%</div>
      </div>
    </div>
  );
};
