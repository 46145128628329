import { useCallback } from 'react';
import FormInput from '../../form-controls/form-input';
import styles from './styles.module.scss';
import { CustomFieldsCommonProps } from '../constants';
import { CurrencyInput } from '../../../shared/form-controls/form-input/currency-input';

type CustomNumberInputProps = {
  allowDecimal: boolean;
  allowNegative: boolean;
  showCommas: boolean;
  prefix: string;
  postfix: string;
} & CustomFieldsCommonProps;

export const CustomNumberInput = ({
  allowDecimal,
  allowNegative,
  showCommas,
  prefix,
  postfix,
  ...props
}: CustomNumberInputProps) => {
  const isValidNumber = (value: string) => {
    const number = Number(value);

    if (isNaN(number)) {
      return false;
    }

    if (number > Number.MAX_SAFE_INTEGER || number < Number.MIN_SAFE_INTEGER) {
      return false;
    }

    return true;
  };

  const onKeyDown = useCallback(
    (e: any) => {
      const value = e.target.value;
      const key = e.key;

      if (key === 'Enter') return;
      if (key === 'Backspace') return;
      if (key === 'Delete') return;
      if (key === 'ArrowLeft') return;
      if (key === 'ArrowRight') return;
      if (key === 'ArrowUp') return;
      if (key === 'ArrowDown') return;
      if (key === 'Tab') return;
      if (e.ctrlKey) return;
      if (key === 'Escape') return;

      // get caret position
      const start = e.target.selectionStart;
      const end = e.target.selectionEnd;

      const futureValue = value.slice(0, start) + key + value.slice(end);

      if (futureValue === '-' && allowNegative) return;

      if (key === '.' && !allowDecimal) {
        e.preventDefault();
        return false;
      }

      const isValid = isValidNumber(futureValue);

      if (!isValid) {
        e.preventDefault();
        return false;
      }
    },
    [allowDecimal, allowNegative]
  );

  const onPaste = useCallback(
    (e: React.ClipboardEvent) => {
      const value = e.clipboardData.getData('text');

      const isValid = isValidNumber(value);

      if (!isValid) {
        e.preventDefault();
        return false;
      }
    },
    [allowDecimal, allowNegative]
  );

  return (
    <FormInput
      type='text'
      {...props}
      inputClassName={`form-control ${styles.numberInput}`}
      onKeyDown={onKeyDown}
      onPaste={onPaste}
      inputText={postfix}
      prefix={prefix}
      CustomInput={showCommas ? CurrencyInput : undefined}
    />
  );
};
