import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { formatDate } from '../../../utils/date-formatters';

function MiscellaneousInfo() {
  const projectData = useSelector((state) => state.project.projectData);

  const lastBidData = projectData?.lastBidData;

  const isServiceStatus = projectData?.jobStatusCodesCode === 'SVC' || projectData?.jobStatusCodesCode === 'SVC-C' || projectData?.jobStatusCodesCode === 'SVC-D';

  return (
    <div>
      <div className='pDetail'>
        <h6>Miscellaneous Information</h6>
        <div className='row'>
          <div className='col-lg-3 col-md-4 col-sm-5 col-xs-6 mb-1'>
            <>Log Date:</>
          </div>
          <div className='col'>
            {projectData?.logDateIso?.iso ? formatDate(projectData.logDateIso.iso) : '-'}
          </div>
        </div>
        {!isServiceStatus && (<>
        <div className='row'>
          <div className='col-lg-3 col-md-4 col-sm-5 col-xs-6 mb-1'>
            <>Lead Date:</>
          </div>
          <div className='col'>
            {projectData?.leadDate?.iso ? formatDate(projectData.leadDate.iso) : '-'}
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-3 col-md-4 col-sm-5 col-xs-6 mb-1'>
            <>Last Bid Date:</>
          </div>
          <div className='col'>
            {lastBidData?.bidDue?.iso ? formatDate(lastBidData?.bidDue.iso) : '-'}
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-3 col-md-4 col-sm-5 col-xs-6 mb-1'>
            <>Last Bid Outcome:</>
          </div>
          <div className='col'>
          {lastBidData?.Outcome ? lastBidData.Outcome : '-'}
          </div>
        </div></>)}
      </div>
    </div>
  );
}

export default withRouter(MiscellaneousInfo);
