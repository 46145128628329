import { HTMLAttributes } from 'react';
import styles from './color-picker.module.css';

type ColorPickerButtonProps = HTMLAttributes<HTMLDivElement> & {
  value: { r: number; g: number; b: number; a: number };
};

export const ColorPickerButton = (props: ColorPickerButtonProps) => (
  <div
    {...props}
    className={styles.colorButton}
    style={{
      backgroundColor: `rgba(${props.value?.r}, ${props.value?.g}, ${props.value?.b}, ${props.value?.a})`,
    }}
  />
);
