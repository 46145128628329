import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { callAlertMessage } from '../../components/Auth/Auth';
import { buildDefaultActionResult } from '../../utils/redux';
import { getAllCalendarRequest } from './api';

export const getAllCalendarAsync = createAsyncThunk<any, { startTime: number; endTime: number }>(
  'calendar/getAllCalendarAsync',
  async (data) => {
    const {
      data: { result },
    } = await getAllCalendarRequest(data);

    if (result.success === 0) {
      callAlertMessage('error', result.msg);
    }

    return result.data ?? {};
  }
);

export const calendarSlice = createSlice({
  name: 'calendar',
  initialState: {
    getAllCalendar: {
      data: {
        companyData: [] as Record<string, any>[],
        contactData: [] as Record<string, any>[],
        projectData: [] as Record<string, any>[],
        scheduleData: [] as Record<string, any>[],
      },
      loading: false,
      error: null,
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    buildDefaultActionResult('getAllCalendar', getAllCalendarAsync, builder);
  },
});

export default calendarSlice.reducer;
