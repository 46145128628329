import apiClient from '../utils/api-client';
import { postRequest } from './helper';

export const getAccountSettings = (data) =>
  apiClient.post('parse/functions/getAccountDetails', data);
export const updateAccountSettings = (data) =>
  apiClient.post('parse/functions/editAccountDetails', data);
export const setImagesMaster = (data) => postRequest('functions/imageMaster', data);
export const getOverheadSettingsHistory = (data) =>
  postRequest('functions/getOverheadSettingsHistory', data);
export const addEditAccountSettingsHistoryVar = (data) =>
  postRequest('functions/addEditAccountSettings', data);
