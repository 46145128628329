import { NavLink, useParams } from 'react-router-dom';
import { usePageTitle } from '../../layouts/title-context';
import { useSubmit } from '../../shared/hooks/use-submit';
import { useAppDispatch } from '../../shared/hooks/use-app-dispatch';
import { confirmPhoneNumber, getVerifyInformation, cancelVerifyPhoneNumber } from '../../modules/users';
import { useState, useEffect } from 'react';

export const VerifyPhonePage = () => {
  usePageTitle('Verify Phone Number');

  const dispatch = useAppDispatch();

  const { token } = useParams() as { token: string };

  const [message, setMessage] = useState<any>(null);

  const [userEmail, setUserEmail] = useState<string>('');
  const [userPhone, setUserPhone] = useState<string>('');
  const [userName, setUserName] = useState<string>('');

  useEffect(() => {

    if (token?.length > 0) {
      initializeConfirmDialog(token);
    }
  }, [token]);

  const initializeConfirmDialog = async (token) => {
    try {
      const { data } = await dispatch(getVerifyInformation({ token })).unwrap().then((data) => {
        return data;
      });

      setUserEmail(data.user.userEmail);
      setUserPhone(data.phone.fullPhone);
      setUserName(data.user.firstName + ' ' + data.user.lastName);


    } catch (error) {
      setMessage(
        <div className='text-danger'>
          Link is invalid or expired. Go to <NavLink to='/preferences/account'>preferences</NavLink>{' '}
          to request a new one.
        </div>
      );
    }
  }

  const [handleDecline] = useSubmit(async () => {
    try {
      await dispatch(cancelVerifyPhoneNumber({ token })).unwrap();

      setMessage(
        <div className='text-success'>
          Phone number declined successfully.
          <br />
          Please go to <NavLink to='/home'>Command Post</NavLink> to request an updated mobile number.
        </div>
      );
    } catch (error) {
      setMessage(
        <div className='text-danger'>
          Link is invalid or expired. Go to <NavLink to='/preferences/account'>preferences</NavLink>{' '}
          to request a new one.
        </div>
      );
    }
  });

  const [handleConfirm] = useSubmit(async () => {
    try {
      await dispatch(confirmPhoneNumber({ token })).unwrap();

      setMessage(
        <div className='text-success'>
          Phone number verified successfully.
          <br />
          Go to <NavLink to='/home'>Command Post</NavLink>.
        </div>
      );
    } catch (error) {
      setMessage(
        <div className='text-danger'>
          Link is invalid or expired. Go to <NavLink to='/preferences/account'>preferences</NavLink>{' '}
          to request a new one.
        </div>
      );
    }
  });

  return (
    <div className='container'>
      <div className='d-flex flex-column justify-content-center text-center align-items-center gap-3'>
        <h2>Verify your mobile phone for Command Post</h2>

        {userEmail && userPhone && userName && message === null && (
          <p>
            Welcome {userName}!
            <br />
            <br />
            Please confirm that you want to use the phone number ({userPhone}) for your Command Post account ({userEmail}).
            <br />
            <br />
            If you did not request to use your mobile number or the information or account is not yours, please ignore this message or decline the request.
          </p>
        )}

        {userEmail && userPhone && userName &&  message=== null && (
        <div className='flex-row'>
          <button className='btn btn-primary' onClick={handleConfirm}>
            Confirm
          </button>
          &nbsp;
          <button className='btn btn-primary' onClick={handleDecline}>
            Decline
          </button>

        </div>)}

        <p>{message}</p>
      </div>
    </div>
  );
};
