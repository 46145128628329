import Joi from 'joi';
import { joiAnyString } from '../../utils/validation';

export type WelcomePageFieldsSchema = {
  firstName: string;
  middleName: string;
  lastName: string;
  contactTitle: string;
  email: string;
  cppRoleName: string;
  companyName: string;
  initialJobNumberPre: string;
  initialJobNumber: number;
  alternateJobNumber: boolean;
  isAgreedToTermsAndCond: boolean;
  file: File | null;
};

export const WelcomePageFieldsValidationSchema = Joi.object({
  firstName: Joi.string().trim().empty('').required().label('First Name'),
  middleName: joiAnyString().label('Middle Name'),
  lastName: joiAnyString().label('Last Name'),
  contactTitle: joiAnyString().label('User Title'),
  email: joiAnyString().email({ tlds: false }).required().label('E-mail'),
  cppRoleName: joiAnyString().label('Role Name'),
  companyName: Joi.string().trim().empty('').required().label('Company Name'),
  initialJobNumberPre: Joi.string().trim().empty('').failover('').required().label('Initial Job Number Prefix'),
  initialJobNumber: Joi.number().integer().empty('').required().label('Initial Job Number'),
  alternateJobNumber: Joi.boolean().allow('').label('Alternate Job Number'),
  isAgreedToTermsAndCond: Joi.boolean().allow('').label('Agreed To Terms And Conditions'),
  file: Joi.object().custom((value, helper) => {
    if (value.length > 0) {
      const maxFileSizeInB = 10000000;
      const allowedFileTypes = ['image/jpeg', 'image/jpg', 'image/png'];
      const file = value[0];

      if (!allowedFileTypes.includes(file.type)) {
        return helper.message({ custom: `Allowed file types are: JPG/PNG` });
      }

      if (file.size >= maxFileSizeInB) {
        return helper.message({ custom: `File size must be less than ${maxFileSizeInB / 1000000} MB` });
      }
    }

    return true;
  }).optional().label('Company Logo'),
});
