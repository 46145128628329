import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../shared/hooks/use-app-dispatch';
import { useAppSelector } from '../../shared/hooks/use-app-selector';
import { getTermsConditionAction } from '../../actions/account';
import { selectTermsAndConditions } from '../../selectors/account';

const TermsAndConditionsPage = () => {

  const dispatch = useAppDispatch();
  const termsAndConditions = useAppSelector(selectTermsAndConditions);

  const [description, setDescription] = useState<string>('');

  useEffect(() => {
    dispatch(getTermsConditionAction());
  }, []);

  useEffect(() => {
    if (termsAndConditions) {
      setDescription(termsAndConditions.description);
    }
  }, [termsAndConditions]);

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="TermTitle">
            <h6 data-testid='termsAndConditionsPage-title'>TERMS AND CONDITIONS</h6>
          </div>
        </div>
      </div>
      <div className="row">
        <div data-testid='termsAndConditionsPage-content' className="col-md-12" dangerouslySetInnerHTML={{__html: description}}></div>
      </div>
    </div>
  );
};

export default TermsAndConditionsPage;
