import { UpdateAccountSettingsData } from '@module/accounts';
import { useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  getAccountEditInfo,
  removeAccountLogo,
  updateAccountLogo,
  updateAccountSettings,
} from '../../../modules/accounts';
import AddressArray from '../../../shared/form-controls/address-array/address-array';
import { HorizontalLayout } from '../../../shared/form-controls/control-layouts/horizontal-layout';
import EmailArray from '../../../shared/form-controls/email-array/email-array';
import FormInput from '../../../shared/form-controls/form-input/form-input';
import FormSelect from '../../../shared/form-controls/form-select/form-select';
import { useAppDispatch } from '../../../shared/hooks/use-app-dispatch';
import { useSubmit } from '../../../shared/hooks/use-submit';
import { MemoizedAvatar } from '../../../shared/memoized-avatar';
import PhoneArray from '../../../shared/phone-array/phone-array';
import { stringifyTimezone } from '../../../utils/import-utils';
import { appConstants, timezones } from '../../../_constants';

export const CompanyInfoPage = () => {
  const dispatch = useAppDispatch();

  const form = useForm({
    defaultValues: {
      companyName: '',
      companyTimeZone: appConstants.DEFAULT_TIMEZONE,
      companyLanguage: appConstants.DEFAULT_LANGUAGE,
      companyWebsite: '',
      dba: '',
      taxId: '',
      phoneArr: [],
      addressArr: [],
      emailArr: [],
    },
  });
  const { control } = form;

  useEffect(() => {
    dispatch(getAccountEditInfo())
      .unwrap()
      .then((data) => {
        form.reset({
          companyName: data.companyName,
          companyTimeZone: data.companyTimeZone,
          companyLanguage: data.companyLanguage,
          phoneArr: data.phoneArr,
          addressArr: data.addressArr,
          emailArr: data.emailArr,
          companyWebsite: data.companyWebsite,
          dba: data.dba,
          taxId: data.taxId,
        });
        setPictureUrl(data.imageUrl);
        pictureRef.current.url = data.companyLogo;
      });
  }, []);

  const timezoneOptions = useMemo(() => {
    return timezones.map((val) => ({
      value: val,
      label: `${stringifyTimezone(val, 'GMT')}- ( ${val.name} )`,
    }));
  }, []);

  const pictureRef = useRef({ file: null, url: null });
  const [pictureUrl, setPictureUrl] = useState('');

  // without image
  const [onSubmit, submitting] = useSubmit(
    form.handleSubmit(async (data: UpdateAccountSettingsData) => {
      const updateData = {
        companyName: data.companyName,
        companyTimeZone: data.companyTimeZone,
        companyLanguage: data.companyLanguage,
        phoneArr: data.phoneArr,
        addressArr: data.addressArr,
        emailArr: data.emailArr,
        companyWebsite: data.companyWebsite,
        dba: data.dba,
        taxId: data.taxId,
      };

      await dispatch(updateAccountSettings(updateData))
        .unwrap()
        .then((data) => {
          form.reset({
            companyName: data.companyName,
            companyTimeZone: data.companyTimeZone,
            companyLanguage: data.companyLanguage,
            phoneArr: data.phoneArr,
            addressArr: data.addressArr,
            emailArr: data.emailArr,
            companyWebsite: data.companyWebsite,
            dba: data.dba,
            taxId: data.taxId,
          });
        });

      // update picture

      if (pictureRef.current.file) {
        const result = await dispatch(
          updateAccountLogo({ companyLogo: pictureRef.current.file })
        ).unwrap();

        setPictureUrl(result);
        console.log('result', result);
        localStorage.setItem('companyLogo', result);
        window.location.reload();
      } else if (!pictureRef.current.url && pictureUrl) {
        const result = await dispatch(removeAccountLogo({})).unwrap();

        setPictureUrl(result);
        localStorage.removeItem('companyLogo');
        window.location.reload();
      }
    })
  );

  const CustomizedLayout = (props: any) => (
    <HorizontalLayout {...props} labelWidth={3} controlWidth={8} />
  );

  return (
    <div className='form-box mt-2'>
      <FormProvider {...form}>
        <div className='flex row'>
          <h2 className='heading2'>Company Profile</h2>
        </div>
        <div className='flex row'>
          <p className='col-8 logonote'>
            <strong>NOTE :</strong> After entering or editing any information on this page, please
            scroll to the bottom of the page and click the SUBMIT button to submit the changes.
          </p>
        </div>
        <div className='col-9 d-flex flex-column gap-3'>
          <h3 className='infoHeading'>Company Info</h3>

          <FormInput
            required
            name='companyName'
            label='Company Name'
            control={control}
            Layout={CustomizedLayout}
          />

          <FormInput name='dba' label='DBA' control={control} Layout={CustomizedLayout} />

          <FormInput name='taxId' label='Tax ID' control={control} Layout={CustomizedLayout} />

          <FormInput
            name='companyWebsite'
            label='Company Website'
            control={control}
            Layout={CustomizedLayout}
          />

          <div className='row d-flex align-items-center'>
            <div className='col-3'>Company Logo</div>
            <div className='col-3'>
              <MemoizedAvatar pictureRef={pictureRef} image={pictureUrl} />
            </div>
          </div>

          <h3 className='infoHeading'>Company Contact Info</h3>

          <EmailArray name='emailArr' />
          <PhoneArray name='phoneArr' />
          <AddressArray name='addressArr' />

          <h3 className='infoHeading mt-2'>Default Localization Settings</h3>

          <FormSelect
            name='companyTimeZone'
            required
            options={timezoneOptions}
            label='Preferred Time Zone'
            control={control}
            Layout={CustomizedLayout}
            useObjectValue
          />

          <FormSelect
            name='companyLanguage'
            required
            options={appConstants.optionLanguageType}
            label='Preferred Language'
            control={control}
            Layout={CustomizedLayout}
            useObjectValue
          />

          <div className='row d-flex'>
            <div className='mt-2 col-md-2 offset-md-9 justify-content-end d-flex'>
              <button className='btn btn-primary' onClick={onSubmit} disabled={submitting}>
                Submit
              </button>
            </div>
          </div>
        </div>
      </FormProvider>
    </div>
  );
};
