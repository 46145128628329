type DotsButtonProps = {
  onClick?: React.MouseEventHandler;
  size?: number;
};

export const DotsButton = ({ onClick = () => void 0, size = 36 }: DotsButtonProps) => {
  return (
    <div role='button' data-testid='DotsButton' onClick={onClick}>
      <svg focusable='false' viewBox='0 0 24 24' width={size}>
        <path
          fill='gray'
          d='M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z'
        />
      </svg>
    </div>
  );
};
