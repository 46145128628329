import * as actionTypes from './actionTypes';
import * as API from '../api/contact';

//get all user details
export const getAllContactRequest = () => ({
  type: actionTypes.GET_ALL_CONTACT_REQUEST,
});

export const getAllContactSuccess = (response) => ({
  type: actionTypes.GET_ALL_CONTACT_SUCCESS,
  payload: {
    response,
  },
});

export const getAllInternalContactSuccess = (response) => ({
  type: actionTypes.GET_ALL_INTERNAL_CONTACT_SUCCESS,
  payload: {
    response,
  },
});

export const getAllContactSuccessNotUpdated = (response) => ({
  type: actionTypes.GET_ALL_CONTACT_SUCCESS_NOT_UPDATED,
  payload: {
    response,
  },
});

export const getAllContactError = (error) => ({
  type: actionTypes.GET_ALL_CONTACT_ERROR,
  payload: {
    error,
  },
});

export const getAllContactAction = (data) => {
  return (dispatch, getState) => {
    dispatch(getAllContactRequest());
    return API.getAllContacts({ ...data, lastFetched: getState().contactList.lastFetched })
      .then((response) => {
        if(response.data.result.updateNeeded){
          if(response.data.result.contactListType === 'internal'){
            dispatch(getAllInternalContactSuccess(response.data));
          }else{
            dispatch(getAllContactSuccess(response.data));
          }
        
      }else{
        dispatch(getAllContactSuccessNotUpdated(response.data));
      }
      })
      .catch((error) => {
        dispatch(
          getAllContactError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};


//get all user details
export const createRequest = () => ({
  type: actionTypes.CREATE_CONTACT_REQUEST,
});

export const createSuccess = (response) => ({
  type: actionTypes.CREATE_CONTACT_SUCCESS,
  payload: {
    response,
  },
});

export const createError = (error) => ({
  type: actionTypes.CREATE_CONTACT_ERROR,
  payload: {
    error,
  },
});

export const createContactAction = (data) => {
  return (dispatch) => {
    dispatch(createRequest());
    const FormData = data;
    return API.createContact_deprecated(FormData)
      .then((response) => {
        dispatch(createSuccess(response.data));
      })
      .catch((error) => {
        dispatch(createError(error.response.error.msg));
      });
  };
};

export const resendInviteRequest = () => ({
  type: actionTypes.RESEND_INVITE_REQUEST,
});

export const resendInviteSuccess = (response) => ({
  type: actionTypes.RESEND_INVITE_SUCCESS,
  payload: {
    response,
  },
});

export const resendInviteError = (error) => ({
  type: actionTypes.RESEND_INVITE_ERROR,
  payload: {
    error,
  },
});

export const resentInviteAction = (data) => {
  return (dispatch) => {
    dispatch(resendInviteRequest());
    const FormData = data;
    return API.resendInvite(FormData)
        .then((response) => {
          dispatch(resendInviteSuccess(response.data));
        })
        .catch((error) => {
          dispatch(resendInviteError(error.response.error.msg));
        });
  };
};

export const cancelInviteRequest = () => ({
  type: actionTypes.CANCEL_INVITE_REQUEST,
});

export const cancelInviteSuccess = (response) => ({
  type: actionTypes.CANCEL_INVITE_SUCCESS,
  payload: {
    response,
  },
});

export const cancelInviteError = (error) => ({
  type: actionTypes.CANCEL_INVITE_ERROR,
  payload: {
    error,
  },
});

export const cancelInviteAction = (data) => {
  return (dispatch) => {
    dispatch(cancelInviteRequest());
    const FormData = data;
    return API.cancelInvite(FormData)
        .then((response) => {
          dispatch(cancelInviteSuccess(response.data));
        })
        .catch((error) => {
          dispatch(cancelInviteError(error.response.error.msg));
        });
  };
};

//updates user details
export const updateContactRequest = () => ({
  type: actionTypes.UPDATE_CONTACT_REQUEST,
});

export const updateContactSuccess = (response) => ({
  type: actionTypes.UPDATE_CONTACT_SUCCESS,
  payload: {
    response,
  },
});

export const updateContactError = (error) => ({
  type: actionTypes.UPDATE_CONTACT_ERROR,
  payload: {
    error,
  },
});

export const updateContactAction = (data) => {
  return (dispatch) => {
    dispatch(updateContactRequest());
    const FormData = data;
    return API.updateContact(FormData)
      .then((response) => {
        dispatch(updateContactSuccess(response.data));
      })
      .catch((error) => {
        dispatch(updateContactError(error.response.data.error.msg));
      });
  };
};

export const getContactDetailsActionBasic = (data) => {
  return (dispatch) => {
    dispatch(getAllContactRequest());
    return API.getContactDetails(data)
      .then((response) => {
         return response.data.result.data.contactsData;
      })
      .catch((error) => {
        dispatch(
          getAllContactError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
}

//Delete user details
export const deleteContactRequest = () => ({
  type: actionTypes.DELETE_CONTACT_REQUEST,
});

export const deleteContactSuccess = (response) => ({
  type: actionTypes.DELETE_CONTACT_SUCCESS,
  payload: {
    response,
  },
});

export const deleteContactError = (error) => ({
  type: actionTypes.DELETE_CONTACT_ERROR,
  payload: {
    error,
  },
});

export const deleteContactAction = (data) => {
  return (dispatch) => {
    dispatch(deleteContactRequest());
    const FormData = data;
    return API.deleteContact(FormData)
      .then((response) => {
        dispatch(deleteContactSuccess(response.data));
      })
      .catch((error) => {
        dispatch(deleteContactError(error.response.data.error.msg));
      });
  };
};

//get all user details
export const moveContactToEmpRequest = () => ({
  type: actionTypes.MOVE_CONTACT_TO_EMP_REQUEST,
});

export const moveContactToEmpSuccess = (response) => ({
  type: actionTypes.MOVE_CONTACT_TO_EMP_SUCCESS,
  payload: {
    response,
  },
});

export const moveContactToEmpError = (error) => ({
  type: actionTypes.MOVE_CONTACT_TO_EMP_ERROR,
  payload: {
    error,
  },
});

export const moveContactToEmpAction = (data) => {
  return (dispatch) => {
    dispatch(moveContactToEmpRequest());
    const FormData = data;
    return API.moveContactToEmp(FormData)
      .then((response) => {
        if (response.data.result.success)
          dispatch(moveContactToEmpSuccess(response.data.result.msg));
        else dispatch(moveContactToEmpError(response.data.result.msg));
      })
      .catch((error) => {
        dispatch(moveContactToEmpError(error.response.data.error.msg));
      });
  };
};


//get contact details

//get all user details
export const getContactDetailsRequest = () => ({
  type: actionTypes.GET_CONTACT_DETAILS_REQUEST,
});

export const getContactDetailsSuccess = (response) => ({
  type: actionTypes.GET_CONTACT_DETAILS_SUCCESS,
  payload: {
    response,
  },
});


export const getContactDetails = (error) => ({
  type: actionTypes.GET_CONTACT_DETAILS_ERROR,
  payload: {
    error,
  },
});


export const getContactDetailAction = (data) => {
  return (dispatch) => {
    dispatch(getAllContactRequest());
    return API.getContactDetails(data)
      .then((response) => {
        dispatch(getContactDetailsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          getAllContactError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};



//Assign multiple vender to a 
export const addContactCompanyRequest = () => ({
  type: actionTypes.ADD_CONTACT_COMPANY_REQUEST,
});

export const addContactCompanySuccess = (response) => ({
  type: actionTypes.ADD_CONTACT_COMPANY_SUCCESS,
  payload: {
    response,
  },
});

export const addContactCompanyError = (error) => ({
  type: actionTypes.ADD_CONTACT_COMPANY_ERROR,
  payload: {
    error,
  },
});

export const addContactCompanyAction = (data) => {
  return (dispatch) => {
    dispatch(addContactCompanyRequest());
    const FormData = data;
    return API.AddContactCompany(FormData)
      .then((response) => {
        dispatch(addContactCompanySuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          getAllContactError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

// upload contact avatar
export const setContactAvatarRequest = () => ({
  type: actionTypes.SET_CONTACT_AVATAR_REQUEST,
});

export const setContactAvatarSuccess = (response) => ({
  type: actionTypes.SET_CONTACT_AVATAR_SUCCESS,
  payload: {
    response,
  },
});

export const setContactAvatarError = (error) => ({
  type: actionTypes.SET_CONTACT_AVATAR_ERROR,
  payload: {
    error,
  },
});

export const setContactAvatarAction = (data) => {
  return (dispatch) => {
    dispatch(setContactAvatarRequest());
    return API.AddContactAvatar(data)
      .then((response) => {
        dispatch(setContactAvatarSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          setContactAvatarError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

// Assigned contact in project
export const getAssignedContactListRequest = () => ({
  type: actionTypes.GET_ASSIGNED_CONTACT_REQUEST,
});

export const getAssignedContactListSuccess = (response) => ({
  type: actionTypes.GET_ASSIGNED_CONTACT_SUCCESS,
  payload: {
    response,
  },
});

export const getAssignedContactListError = (error) => ({
  type: actionTypes.GET_ASSIGNED_CONTACT_ERROR,
  payload: {
    error,
  },
});

export const getAssignedContactListAction = (data) => {
  return (dispatch) => {
    dispatch(getAssignedContactListRequest());
    return API.AssignedContactList(data)
      .then((response) => {
        dispatch(getAssignedContactListSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          getAssignedContactListError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};
