import { CustomFieldsCommonProps } from '../constants';
import styles from './styles.module.scss';

type CustomDividerProps = {
  control: any;
  label: string;
  Layout: any;
  onEditRef?: any;
  
} & CustomFieldsCommonProps;

export const CustomDivider = ({ ...props }: CustomDividerProps) => {

  const onEditForm = !props.disabled;
  const onEditRef = props.onEditRef;

  const dividerLink = props.label.replaceAll(" ", "-").replace('&', 'and');

  return (
    <>
      <p className={`mt-2 mb-2 ${onEditForm?styles.projectFieldDividerEdit:styles.projectFieldDivider}`}>{props.label}{onEditRef && (
            <a className='editBtn2' onClick={() => onEditRef(dividerLink)}>
              <i className='fa fa-pencil' aria-hidden='true'></i>
            </a>
          )}</p>
      
    </>
  );
};
