export const selectProjectCreateSuccess = (state) => state.project.createSuccess;

export const selectIsProjectUpdating = (state) => state.project.isProjectUpdating;

export const selectIsProjectUpdated = (state) => state.project.isProjectUpdated;

export const selectProjectIsUpdateStatus = (state) => state.project.isUpdateStatus;

export const selectProjectEventDateMessage = (state) => state.project.message;

export const selectProjectEventDateSuccess = (state) => state.project.success;

export const selectProjectButtonActive = (state) => state.project.buttonActive;

export const selectProjectData = (state) => state.project.projectData;

export const selectLastJobData = (state) => state.project.lastJobData;
