import apiClient from '../../utils/api-client';

export const disableWidgetRequest = ({ id }) => apiClient.post('/widgets/disableWidget', { id });

export const enableWidgetRequest = ({ id }) => apiClient.post('/widgets/enableWidget', { id });

export const resetWidgetsOrderRequest = () => apiClient.post('/widgets/resetWidgetsOrder');

export const reorderWidgetRequest = ({ id, index }) =>
  apiClient.post('/widgets/reorderWidget', { id, index });
