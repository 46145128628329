export const LOGOUT = 'LOGOUT';

export const ADMIN_LOGIN_REQUEST = 'ADMIN_LOGIN_REQUEST';
export const ADMIN_LOGIN_SUCCESS = 'ADMIN_LOGIN_SUCCESS';
export const ADMIN_LOGIN_ERROR = 'ADMIN_LOGIN_ERROR';

export const SELECT_NAV_LINK_ACTION = 'SELECT_NAV_LINK_ACTION';

export const GET_ALL_COMPANY_REQUEST = 'GET_ALL_COMPANY_REQUEST';
export const GET_ALL_COMPANY_SUCCESS = 'GET_ALL_COMPANY_SUCCESS';
export const GET_ALL_COMPANY_ERROR = 'GET_ALL_COMPANY_ERROR';

export const CREATE_COMPANY_SUCCESS = 'CREATE_COMPANY_SUCCESS';
export const CREATE_COMPANY_REQUEST = 'CREATE_COMPANY_REQUEST';
export const CREATE_COMPANY_ERROR = 'CREATE_COMPANY_ERROR';

export const DELETE_COMPANY_REQUEST = 'DELETE_COMPANY_REQUEST';
export const DELETE_COMPANY_SUCCESS = 'DELETE_COMPANY_SUCCESS';
export const DELETE_COMPANY_ERROR = 'DELETE_COMPANY_ERROR';

export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const SIGNIN_USER_REQUEST = 'SIGNIN_USER_REQUEST';
export const SIGNIN_USER_ERROR = 'SIGNIN_USER_ERROR';

export const GET_SIGNIN_USER_SUCCESS = 'GET_SIGNIN_USER_SUCCESS';
export const GET_SIGNIN_USER_REQUEST = 'GET_SIGNIN_USER_REQUEST';
export const GET_SIGNIN_USER_ERROR = 'GET_SIGNIN_USER_ERROR';

export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const SIGNUP_USER_REQUEST = 'SIGNUP_USER_REQUEST';
export const SIGNUP_USER_ERROR = 'SIGNUP_USER_ERROR';

export const UPDATE_CONTACT_STATUS_REQUEST = 'UPDATE_CONTACT_STATUS_REQUEST';
export const UPDATE_CONTACT_STATUS_SUCCESS = 'UPDATE_CONTACT_STATUS_SUCCESS';
export const UPDATE_CONTACT_STATUS_ERROR = 'UPDATE_CONTACT_STATUS_ERROR';

export const CREATE_CONTRACT_TYPE_REQUEST = 'CREATE_CONTRACT_TYPE_REQUEST';
export const CREATE_CONTRACT_TYPE_SUCCESS = 'CREATE_CONTRACT_TYPE_SUCCESS';
export const CREATE_CONTRACT_TYPE_ERROR = 'CREATE_CONTRACT_TYPE_ERROR';

export const GET_ALL_CONTRACT_TYPE_REQUEST = 'GET_ALL_CONTRACT_TYPE_REQUEST';
export const GET_ALL_CONTRACT_TYPE_SUCCESS = 'GET_ALL_CONTRACT_TYPE_SUCCESS';
export const GET_ALL_CONTRACT_TYPE_ERROR = 'GET_ALL_CONTRACT_TYPE_ERROR';

export const UPDATE_CONTRACT_TYPE_REQUEST = 'UPDATE_CONTRACT_TYPE_REQUEST';
export const UPDATE_CONTRACT_TYPE_SUCCESS = 'UPDATE_CONTRACT_TYPE_SUCCESS';
export const UPDATE_CONTRACT_TYPE_ERROR = 'UPDATE_CONTRACT_TYPE_ERROR';

export const DELETE_CONTRACT_TYPE_REQUEST = 'DELETE_CONTRACT_TYPE_REQUEST';
export const DELETE_CONTRACT_TYPE_SUCCESS = 'DELETE_CONTRACT_TYPE_SUCCESS';
export const DELETE_CONTRACT_TYPE_ERROR = 'DELETE_CONTRACT_TYPE_ERROR';

export const CREATE_CONTACT_ROLE_REQUEST = 'CREATE_CONTACT_ROLE_REQUEST';
export const CREATE_CONTACT_ROLE_SUCCESS = 'CREATE_CONTACT_ROLE_SUCCESS';
export const CREATE_CONTACT_ROLE_ERROR = 'CREATE_CONTACT_ROLE_ERROR';

export const GET_ALL_CONTACT_ROLE_REQUEST = 'GET_ALL_CONTACT_ROLE_REQUEST';
export const GET_ALL_CONTACT_ROLE_SUCCESS = 'GET_ALL_CONTACT_ROLE_SUCCESS';
export const GET_ALL_CONTACT_ROLE_ERROR = 'GET_ALL_CONTACT_ROLE_ERROR';

export const UPDATE_CONTACT_ROLE_REQUEST = 'UPDATE_CONTACT_ROLE_REQUEST';
export const UPDATE_CONTACT_ROLE_SUCCESS = 'UPDATE_CONTACT_ROLE_SUCCESS';
export const UPDATE_CONTACT_ROLE_ERROR = 'UPDATE_CONTACT_ROLE_ERROR';

export const DELETE_CONTACT_ROLE_REQUEST = 'DELETE_CONTACT_ROLE_REQUEST';
export const DELETE_CONTACT_ROLE_SUCCESS = 'DELETE_CONTACT_ROLE_SUCCESS';
export const DELETE_CONTACT_ROLE_ERROR = 'DELETE_CONTACT_ROLE_ERROR';

export const REGISTER_COMPANY_REQUEST = 'REGISTER_COMPANY_REQUEST';
export const REGISTER_COMPANY_SUCCESS = 'REGISTER_COMPANY_SUCCESS';
export const REGISTER_COMPANY_ERROR = 'REGISTER_COMPANY_ERROR';

export const CREATE_OCCUPANCY_TYPE_REQUEST = 'CREATE_OCCUPANCY_TYPE_REQUEST';
export const CREATE_OCCUPANCY_TYPE_SUCCESS = 'CREATE_OCCUPANCY_TYPE_SUCCESS';
export const CREATE_OCCUPANCY_TYPE_ERROR = 'CREATE_OCCUPANCY_TYPE_ERROR';

export const UPDATE_OCCUPANCY_TYPE_REQUEST = 'UPDATE_OCCUPANCY_TYPE_REQUEST';
export const UPDATE_OCCUPANCY_TYPE_SUCCESS = 'UPDATE_OCCUPANCY_TYPE_SUCCESS';
export const UPDATE_OCCUPANCY_TYPE_ERROR = 'UPDATE_OCCUPANCY_TYPE_ERROR';

export const GET_ALL_OCCUPANCY_TYPE_REQUEST = 'GET_ALL_OCCUPANCY_TYPE_REQUEST';
export const GET_ALL_OCCUPANCY_TYPE_SUCCESS = 'GET_ALL_OCCUPANCY_TYPE_SUCCESS';
export const GET_ALL_OCCUPANCY_TYPE_ERROR = 'GET_ALL_OCCUPANCY_TYPE_ERROR';

export const DELETE_OCCUPANCY_TYPE_REQUEST = 'DELETE_OCCUPANCY_TYPE_REQUEST';
export const DELETE_OCCUPANCY_TYPE_SUCCESS = 'DELETE_OCCUPANCY_TYPE_SUCCESS';
export const DELETE_OCCUPANCY_TYPE_ERROR = 'DELETE_OCCUPANCY_TYPE_ERROR';

export const CREATE_BUILDING_TYPE_REQUEST = 'CREATE_BUILDING_TYPE_REQUEST';
export const CREATE_BUILDING_TYPE_SUCCESS = 'CREATE_BUILDING_TYPE_SUCCESS';
export const CREATE_BUILDING_TYPE_ERROR = 'CREATE_BUILDING_TYPE_ERROR';

export const UPDATE_BUILDING_TYPE_REQUEST = 'UPDATE_BUILDING_TYPE_REQUEST';
export const UPDATE_BUILDING_TYPE_SUCCESS = 'UPDATE_BUILDING_TYPE_SUCCESS';
export const UPDATE_BUILDING_TYPE_ERROR = 'UPDATE_BUILDING_TYPE_ERROR';

export const GET_ALL_BUILDING_TYPE_REQUEST = 'GET_ALL_BUILDING_TYPE_REQUEST';
export const GET_ALL_BUILDING_TYPE_SUCCESS = 'GET_ALL_BUILDING_TYPE_SUCCESS';
export const GET_ALL_BUILDING_TYPE_ERROR = 'GET_ALL_BUILDING_TYPE_ERROR';

export const DELETE_BUILDING_TYPE_REQUEST = 'DELETE_BUILDING_TYPE_REQUEST';
export const DELETE_BUILDING_TYPE_SUCCESS = 'DELETE_BUILDING_TYPE_SUCCESS';
export const DELETE_BUILDING_TYPE_ERROR = 'DELETE_BUILDING_TYPE_ERROR';

export const CREATE_JOB_SCOPE_REQUEST = 'CREATE_JOB_SCOPE_REQUEST';
export const CREATE_JOB_SCOPE_SUCCESS = 'CREATE_JOB_SCOPE_SUCCESS';
export const CREATE_JOB_SCOPE_ERROR = 'CREATE_JOB_SCOPE_ERROR';

export const UPDATE_JOB_SCOPE_REQUEST = 'UPDATE_JOB_SCOPE_REQUEST';
export const UPDATE_JOB_SCOPE_SUCCESS = 'UPDATE_JOB_SCOPE_SUCCESS';
export const UPDATE_JOB_SCOPE_ERROR = 'UPDATE_JOB_SCOPE_ERROR';

export const GET_ALL_JOB_SCOPE_REQUEST = 'GET_ALL_JOB_SCOPE_REQUEST';
export const GET_ALL_JOB_SCOPE_SUCCESS = 'GET_ALL_JOB_SCOPE_SUCCESS';
export const GET_ALL_JOB_SCOPE_ERROR = 'GET_ALL_JOB_SCOPE_ERROR';

export const DELETE_JOB_SCOPE_REQUEST = 'DELETE_JOB_SCOPE_REQUEST';
export const DELETE_JOB_SCOPE_SUCCESS = 'DELETE_JOB_SCOPE_SUCCESS';
export const DELETE_JOB_SCOPE_ERROR = 'DELETE_JOB_SCOPE_ERROR';

export const CREATE_REASON_CODE_REQUEST = 'CREATE_REASON_CODE_REQUEST';
export const CREATE_REASON_CODE_SUCCESS = 'CREATE_REASON_CODE_SUCCESS';
export const CREATE_REASON_CODE_ERROR = 'CREATE_REASON_CODE_ERROR';

export const UPDATE_REASON_CODE_REQUEST = 'UPDATE_REASON_CODE_REQUEST';
export const UPDATE_REASON_CODE_SUCCESS = 'UPDATE_REASON_CODE_SUCCESS';
export const UPDATE_REASON_CODE_ERROR = 'UPDATE_REASON_CODE_ERROR';

export const GET_ALL_REASON_CODE_REQUEST = 'GET_ALL_REASON_CODE_REQUEST';
export const GET_ALL_REASON_CODE_SUCCESS = 'GET_ALL_REASON_CODE_SUCCESS';
export const GET_ALL_REASON_CODE_ERROR = 'GET_ALL_REASON_CODE_ERROR';

export const DELETE_REASON_CODE_REQUEST = 'DELETE_REASON_CODE_REQUEST';
export const DELETE_REASON_CODE_SUCCESS = 'DELETE_REASON_CODE_SUCCESS';
export const DELETE_REASON_CODE_ERROR = 'DELETE_REASON_CODE_ERROR';

export const CREATE_NEGOTIATING_METHOD_REQUEST = 'CREATE_NEGOTIATING_METHOD_REQUEST';
export const CREATE_NEGOTIATING_METHOD_SUCCESS = 'CREATE_NEGOTIATING_METHOD_SUCCESS';
export const CREATE_NEGOTIATING_METHOD_ERROR = 'CREATE_NEGOTIATING_METHOD_ERROR';

export const UPDATE_NEGOTIATING_METHOD_REQUEST = 'UPDATE_NEGOTIATING_METHOD_REQUEST';
export const UPDATE_NEGOTIATING_METHOD_SUCCESS = 'UPDATE_NEGOTIATING_METHOD_SUCCESS';
export const UPDATE_NEGOTIATING_METHOD_ERROR = 'UPDATE_NEGOTIATING_METHOD_ERROR';

export const GET_ALL_NEGOTIATING_METHOD_REQUEST = 'GET_ALL_NEGOTIATING_METHOD_REQUEST';
export const GET_ALL_NEGOTIATING_METHOD_SUCCESS = 'GET_ALL_NEGOTIATING_METHOD_SUCCESS';
export const GET_ALL_NEGOTIATING_METHOD_ERROR = 'GET_ALL_NEGOTIATING_METHOD_ERROR';

export const DELETE_NEGOTIATING_METHOD_REQUEST = 'DELETE_NEGOTIATING_METHOD_REQUEST';
export const DELETE_NEGOTIATING_METHOD_SUCCESS = 'DELETE_NEGOTIATING_METHOD_SUCCESS';
export const DELETE_NEGOTIATING_METHOD_ERROR = 'DELETE_NEGOTIATING_METHOD_ERROR';

export const CREATE_COMPANY_TYPES_REQUEST = 'CREATE_COMPANY_TYPES_REQUEST';
export const CREATE_COMPANY_TYPES_SUCCESS = 'CREATE_COMPANY_TYPES_SUCCESS';
export const CREATE_COMPANY_TYPES_ERROR = 'CREATE_COMPANY_TYPES_ERROR';

export const UPDATE_COMPANY_TYPES_REQUEST = 'UPDATE_COMPANY_TYPES_REQUEST';
export const UPDATE_COMPANY_TYPES_SUCCESS = 'UPDATE_COMPANY_TYPES_SUCCESS';
export const UPDATE_COMPANY_TYPES_ERROR = 'UPDATE_COMPANY_TYPES_ERROR';

export const GET_ALL_COMPANY_TYPES_REQUEST = 'GET_ALL_COMPANY_TYPES_REQUEST';
export const GET_ALL_COMPANY_TYPES_SUCCESS = 'GET_ALL_COMPANY_TYPES_SUCCESS';
export const GET_ALL_COMPANY_TYPES_ERROR = 'GET_ALL_COMPANY_TYPES_ERROR';

export const DELETE_COMPANY_TYPES_REQUEST = 'DELETE_COMPANY_TYPES_REQUEST';
export const DELETE_COMPANY_TYPES_SUCCESS = 'DELETE_COMPANY_TYPES_SUCCESS';
export const DELETE_COMPANY_TYPES_ERROR = 'DELETE_COMPANY_TYPES_ERROR';

export const CREATE_OVER_HEADS_REQUEST = 'CREATE_OVER_HEADS_REQUEST';
export const CREATE_OVER_HEADS_SUCCESS = 'CREATE_OVER_HEADS_SUCCESS';
export const CREATE_OVER_HEADS_ERROR = 'CREATE_OVER_HEADS_ERROR';

export const UPDATE_OVER_HEADS_REQUEST = 'UPDATE_OVER_HEADS_REQUEST';
export const UPDATE_OVER_HEADS_SUCCESS = 'UPDATE_OVER_HEADS_SUCCESS';
export const UPDATE_OVER_HEADS_ERROR = 'UPDATE_OVER_HEADS_ERROR';

export const GET_ALL_OVER_HEADS_REQUEST = 'GET_ALL_OVER_HEADS_REQUEST';
export const GET_ALL_OVER_HEADS_SUCCESS = 'GET_ALL_OVER_HEADS_SUCCESS';
export const GET_ALL_OVER_HEADS_ERROR = 'GET_ALL_OVER_HEADS_ERROR';

export const DELETE_OVER_HEADS_REQUEST = 'DELETE_OVER_HEADS_REQUEST';
export const DELETE_OVER_HEADS_SUCCESS = 'DELETE_OVER_HEADS_SUCCESS';
export const DELETE_OVER_HEADS_ERROR = 'DELETE_OVER_HEADS_ERROR';

export const CREATE_JOB_STATUS_REQUEST = 'CREATE_JOB_STATUS_REQUEST';
export const CREATE_JOB_STATUS_SUCCESS = 'CREATE_JOB_STATUS_SUCCESS';
export const CREATE_JOB_STATUS_ERROR = 'CREATE_JOB_STATUS_ERROR';

export const UPDATE_JOB_STATUS_REQUEST = 'UPDATE_JOB_STATUS_REQUEST';
export const UPDATE_JOB_STATUS_SUCCESS = 'UPDATE_JOB_STATUS_SUCCESS';
export const UPDATE_JOB_STATUS_ERROR = 'UPDATE_JOB_STATUS_ERROR';

export const GET_ALL_JOB_STATUS_REQUEST = 'GET_ALL_JOB_STATUS_REQUEST';
export const GET_ALL_JOB_STATUS_SUCCESS = 'GET_ALL_JOB_STATUS_SUCCESS';
export const GET_ALL_JOB_STATUS_ERROR = 'GET_ALL_JOB_STATUS_ERROR';

export const DELETE_JOB_STATUS_REQUEST = 'DELETE_JOB_STATUS_REQUEST';
export const DELETE_JOB_STATUS_SUCCESS = 'DELETE_JOB_STATUS_SUCCESS';
export const DELETE_JOB_STATUS_ERROR = 'DELETE_JOB_STATUS_ERROR';

export const CREATE_USER_FIELDS_REQUEST = 'CREATE_USER_FIELDS_REQUEST';
export const CREATE_USER_FIELDS_SUCCESS = 'CREATE_USER_FIELDS_SUCCESS';
export const CREATE_USER_FIELDS_ERROR = 'CREATE_USER_FIELDS_ERROR';

export const UPDATE_USER_FIELDS_REQUEST = 'UPDATE_USER_FIELDS_REQUEST';
export const UPDATE_USER_FIELDS_SUCCESS = 'UPDATE_USER_FIELDS_SUCCESS';
export const UPDATE_USER_FIELDS_ERROR = 'UPDATE_USER_FIELDS_ERROR';

export const GET_ALL_USER_FIELDS_REQUEST = 'GET_ALL_USER_FIELDS_REQUEST';
export const GET_ALL_USER_FIELDS_SUCCESS = 'GET_ALL_USER_FIELDS_SUCCESS';
export const GET_ALL_USER_FIELDS_ERROR = 'GET_ALL_USER_FIELDS_ERROR';

export const DELETE_USER_FIELDS_REQUEST = 'DELETE_USER_FIELDS_REQUEST';
export const DELETE_USER_FIELDS_SUCCESS = 'DELETE_USER_FIELDS_SUCCESS';
export const DELETE_USER_FIELDS_ERROR = 'DELETE_USER_FIELDS_ERROR';

export const CREATE_STATUS_CODE_REQUEST = 'CREATE_STATUS_CODE_REQUEST';
export const CREATE_STATUS_CODE_SUCCESS = 'CREATE_STATUS_CODE_SUCCESS';
export const CREATE_STATUS_CODE_ERROR = 'CREATE_STATUS_CODE_ERROR';

export const UPDATE_STATUS_CODE_REQUEST = 'UPDATE_STATUS_CODE_REQUEST';
export const UPDATE_STATUS_CODE_SUCCESS = 'UPDATE_STATUS_CODE_SUCCESS';
export const UPDATE_STATUS_CODE_ERROR = 'UPDATE_STATUS_CODE_ERROR';

export const GET_ALL_STATUS_CODE_REQUEST = 'GET_ALL_STATUS_CODE_REQUEST';
export const GET_ALL_STATUS_CODE_SUCCESS = 'GET_ALL_STATUS_CODE_SUCCESS';
export const GET_ALL_STATUS_CODE_ERROR = 'GET_ALL_STATUS_CODE_ERROR';

export const DELETE_STATUS_CODE_REQUEST = 'DELETE_STATUS_CODE_REQUEST';
export const DELETE_STATUS_CODE_SUCCESS = 'DELETE_STATUS_CODE_SUCCESS';
export const DELETE_STATUS_CODE_ERROR = 'DELETE_STATUS_CODE_ERROR';

export const CREATE_JOB_TYPES_REQUEST = 'CREATE_JOB_TYPES_REQUEST';
export const CREATE_JOB_TYPES_SUCCESS = 'CREATE_JOB_TYPES_SUCCESS';
export const CREATE_JOB_TYPES_ERROR = 'CREATE_JOB_TYPES_ERROR';

export const UPDATE_JOB_TYPES_REQUEST = 'UPDATE_JOB_TYPES_REQUEST';
export const UPDATE_JOB_TYPES_SUCCESS = 'UPDATE_JOB_TYPES_SUCCESS';
export const UPDATE_JOB_TYPES_ERROR = 'UPDATE_JOB_TYPES_ERROR';

export const GET_ALL_JOB_TYPES_REQUEST = 'GET_ALL_JOB_TYPES_REQUEST';
export const GET_ALL_JOB_TYPES_SUCCESS = 'GET_ALL_JOB_TYPES_SUCCESS';
export const GET_ALL_JOB_TYPES_ERROR = 'GET_ALL_JOB_TYPES_ERROR';

export const DELETE_JOB_TYPES_REQUEST = 'DELETE_JOB_TYPES_REQUEST';
export const DELETE_JOB_TYPES_SUCCESS = 'DELETE_JOB_TYPES_SUCCESS';
export const DELETE_JOB_TYPES_ERROR = 'DELETE_JOB_TYPES_ERROR';

export const GET_EXISTING_CONTACT_REQUEST = 'GET_EXISTING_CONTACT_REQUEST';
export const GET_EXISTING_CONTACT_SUCCESS = 'GET_EXISTING_CONTACT_SUCCESS';
export const GET_EXISTING_CONTACT_ERROR = 'GET_EXISTING_CONTACT_ERROR';

export const GET_ALL_CONTACT_REQUEST = 'GET_ALL_CONTACT_REQUEST';
export const GET_ALL_CONTACT_SUCCESS = 'GET_ALL_CONTACT_SUCCESS';
export const GET_ALL_CONTACT_ERROR = 'GET_ALL_CONTACT_ERROR';

export const CREATE_CONTACT_REQUEST = 'CREATE_CONTACT_REQUEST';
export const CREATE_CONTACT_SUCCESS = 'CREATE_CONTACT_SUCCESS';
export const CREATE_CONTACT_ERROR = 'CREATE_CONTACT_ERROR';

export const CREATE_MULTIPLE_CONTACT_REQUEST = 'CREATE_MULTIPLE_CONTACT_REQUEST';
export const CREATE_MULTIPLE_CONTACT_SUCCESS = 'CREATE_MULTIPLE_CONTACT_SUCCESS';
export const CREATE_MULTIPLE_CONTACT_ERROR = 'CREATE_MULTIPLE_CONTACT_ERROR';

export const UPDATE_CONTACT_REQUEST = 'UPDATE_CONTACT_REQUEST';
export const UPDATE_CONTACT_SUCCESS = 'UPDATE_CONTACT_SUCCESS';
export const UPDATE_CONTACT_ERROR = 'UPDATE_CONTACT_ERROR';

export const DELETE_CONTACT_REQUEST = 'DELETE_CONTACT_REQUEST';
export const DELETE_CONTACT_SUCCESS = 'DELETE_CONTACT_SUCCESS';
export const DELETE_CONTACT_ERROR = 'DELETE_CONTACT_ERROR';

export const GET_EXISTING_PROJECT_REQUEST = 'GET_EXISTING_PROJECT_REQUEST';
export const GET_EXISTING_PROJECT_SUCCESS = 'GET_EXISTING_PROJECT_SUCCESS';
export const GET_EXISTING_PROJECT_ERROR = 'GET_EXISTING_PROJECT_ERROR';

export const GET_ALL_PROJECT_REQUEST = 'GET_ALL_PROJECT_REQUEST';
export const GET_ALL_PROJECT_SUCCESS = 'GET_ALL_PROJECT_SUCCESS';
export const GET_ALL_PROJECT_ERROR = 'GET_ALL_PROJECT_ERROR';

export const CREATE_PROJECT_REQUEST = 'CREATE_PROJECT_REQUEST';
export const CREATE_PROJECT_SUCCESS = 'CREATE_PROJECT_SUCCESS';
export const CREATE_PROJECT_ERROR = 'CREATE_PROJECT_ERROR';

export const UPDATE_PROJECT_REQUEST = 'UPDATE_PROJECT_REQUEST';
export const UPDATE_PROJECT_SUCCESS = 'UPDATE_PROJECT_SUCCESS';
export const UPDATE_PROJECT_ERROR = 'UPDATE_PROJECT_ERROR';

export const DELETE_PROJECT_REQUEST = 'DELETE_PROJECT_REQUEST';
export const DELETE_PROJECT_SUCCESS = 'DELETE_PROJECT_SUCCESS';
export const DELETE_PROJECT_ERROR = 'DELETE_PROJECT_ERROR';

export const GET_ALL_COMPANY_USER_REQUEST = 'GET_ALL_COMPANY_USER_REQUEST';
export const GET_ALL_COMPANY_USER_SUCCESS = 'GET_ALL_COMPANY_USER_SUCCESS';
export const GET_ALL_COMPANY_USER_ERROR = 'GET_ALL_COMPANY_USER_ERROR';

export const GET_ALL_PROJECT_TASKS_REQUEST = 'GET_ALL_PROJECT_TASKS_REQUEST';
export const GET_ALL_PROJECT_TASKS_SUCCESS = 'GET_ALL_PROJECT_TASKS_SUCCESS';
export const GET_ALL_PROJECT_TASKS_ERROR = 'GET_ALL_PROJECT_TASKS_ERROR';

export const CREATE_PROJECT_TASKS_REQUEST = 'CREATE_PROJECT_TASKS_REQUEST';
export const CREATE_PROJECT_TASKS_SUCCESS = 'CREATE_PROJECT_TASKS_SUCCESS';
export const CREATE_PROJECT_TASKS_ERROR = 'CREATE_PROJECT_TASKS_ERROR';

export const UPDATE_PROJECT_TASKS_REQUEST = 'UPDATE_PROJECT_TASKS_REQUEST';
export const UPDATE_PROJECT_TASKS_SUCCESS = 'UPDATE_PROJECT_TASKS_SUCCESS';
export const UPDATE_PROJECT_TASKS_ERROR = 'UPDATE_PROJECT_TASKS_ERROR';

export const DELETE_PROJECT_TASKS_REQUEST = 'DELETE_PROJECT_TASKS_REQUEST';
export const DELETE_PROJECT_TASKS_SUCCESS = 'DELETE_PROJECT_TASKS_SUCCESS';
export const DELETE_PROJECT_TASKS_ERROR = 'DELETE_PROJECT_TASKS_ERROR';

export const GET_ALL_PROJECT_LISTS_REQUEST = 'GET_ALL_PROJECT_LISTS_REQUEST';
export const GET_ALL_PROJECT_LISTS_SUCCESS = 'GET_ALL_PROJECT_LISTS_SUCCESS';
export const GET_ALL_PROJECT_LISTS_ERROR = 'GET_ALL_PROJECT_LISTS_ERROR';

export const GET_ALL_PROJECT_STATUS_REQUEST = 'GET_ALL_PROJECT_STATUS_REQUEST';
export const GET_ALL_PROJECT_STATUS_SUCCESS = 'GET_ALL_PROJECT_STATUS_SUCCESS';
export const GET_ALL_PROJECT_STATUS_ERROR = 'GET_ALL_PROJECT_STATUS_ERROR';

export const GET_ALL_ROLE_TYPE_REQUEST = 'GET_ALL_ROLE_TYPE_REQUEST';
export const GET_ALL_ROLE_TYPE_SUCCESS = 'GET_ALL_ROLE_TYPE_SUCCESS';
export const GET_ALL_ROLE_TYPE_ERROR = 'GET_ALL_ROLE_TYPE_ERROR';

export const GET_ALL_MODULE_TYPE_REQUEST = 'GET_ALL_MODULE_TYPE_REQUEST';
export const GET_ALL_MODULE_TYPE_SUCCESS = 'GET_ALL_MODULE_TYPE_SUCCESS';
export const GET_ALL_MODULE_TYPE_ERROR = 'GET_ALL_MODULE_TYPE_ERROR';

export const CREATE_MODULE_TYPE_REQUEST = 'CREATE_MODULE_TYPE_REQUEST';
export const CREATE_MODULE_TYPE_SUCCESS = 'CREATE_MODULE_TYPE_SUCCESS';
export const CREATE_MODULE_TYPE_ERROR = 'CREATE_MODULE_TYPE_ERROR';

export const UPDATE_MODULE_TYPE_REQUEST = 'UPDATE_MODULE_TYPE_REQUEST';
export const UPDATE_MODULE_TYPE_SUCCESS = 'UPDATE_MODULE_TYPE_SUCCESS';
export const UPDATE_MODULE_TYPE_ERROR = 'UPDATE_MODULE_TYPE_ERROR';

export const DELETE_MODULE_TYPE_REQUEST = 'DELETE_MODULE_TYPE_REQUEST';
export const DELETE_MODULE_TYPE_SUCCESS = 'DELETE_MODULE_TYPE_SUCCESS';
export const DELETE_MODULE_TYPE_ERROR = 'DELETE_MODULE_TYPE_ERROR';

export const GET_ALL_NOTES_REQUEST = 'GET_ALL_NOTES_REQUEST';
export const GET_ALL_NOTES_SUCCESS = 'GET_ALL_NOTES_SUCCESS';
export const GET_ALL_NOTES_ERROR = 'GET_ALL_NOTES_ERROR';

export const CREATE_NOTE_REQUEST = 'CREATE_NOTE_REQUEST';
export const CREATE_NOTE_SUCCESS = 'CREATE_NOTE_SUCCESS';
export const CREATE_NOTE_ERROR = 'CREATE_NOTE_ERROR';

export const DELETE_NOTES_REQUEST = 'DELETE_NOTES_REQUEST';
export const DELETE_NOTES_SUCCESS = 'DELETE_NOTES_SUCCESS';
export const DELETE_NOTES_ERROR = 'DELETE_NOTES_ERROR';

export const GET_ALL_ASSIGN_MODULE_REQUEST = 'GET_ALL_ASSIGN_MODULE_REQUEST';
export const GET_ALL_ASSIGN_MODULE_SUCCESS = 'GET_ALL_ASSIGN_MODULE_SUCCESS';
export const GET_ALL_ASSIGN_MODULE_ERROR = 'GET_ALL_ASSIGN_MODULE_ERROR';

export const CREATE_ASSIGN_MODULE_REQUEST = 'CREATE_ASSIGN_MODULE_REQUEST';
export const CREATE_ASSIGN_MODULE_SUCCESS = 'CREATE_ASSIGN_MODULE_SUCCESS';
export const CREATE_ASSIGN_MODULE_ERROR = 'CREATE_ASSIGN_MODULE_ERROR';

export const UPDATE_ASSIGN_MODULE_REQUEST = 'UPDATE_ASSIGN_MODULE_REQUEST';
export const UPDATE_ASSIGN_MODULE_SUCCESS = 'UPDATE_ASSIGN_MODULE_SUCCESS';
export const UPDATE_ASSIGN_MODULE_ERROR = 'UPDATE_ASSIGN_MODULE_ERROR';

export const DELETE_ASSIGN_MODULE_REQUEST = 'DELETE_ASSIGN_MODULE_REQUEST';
export const DELETE_ASSIGN_MODULE_SUCCESS = 'DELETE_ASSIGN_MODULE_SUCCESS';
export const DELETE_ASSIGN_MODULE_ERROR = 'DELETE_ASSIGN_MODULE_ERROR';

export const GET_PROJECT_HISTORY_QUICK_REQUEST = 'GET_PROJECT_HISTORY_QUICK_REQUEST';
export const GET_PROJECT_HISTORY_QUICK_SUCCESS = 'GET_PROJECT_HISTORY_QUICK_SUCCESS';
export const GET_PROJECT_HISTORY_QUICK_ERROR = 'GET_PROJECT_HISTORY_QUICK_ERROR';

export const CREATE_CHANGE_ORDER_REQUEST = 'CREATE_CHANGE_ORDER_REQUEST';
export const CREATE_CHANGE_ORDER_SUCCESS = 'CREATE_CHANGE_ORDER_SUCCESS';
export const CREATE_CHANGE_ORDER_ERROR = 'CREATE_CHANGE_ORDER_ERROR';

export const UPDATE_CHANGE_ORDER_REQUEST = 'UPDATE_CHANGE_ORDER_REQUEST';
export const UPDATE_CHANGE_ORDER_SUCCESS = 'UPDATE_CHANGE_ORDER_SUCCESS';
export const UPDATE_CHANGE_ORDER_ERROR = 'UPDATE_CHANGE_ORDER_ERROR';

export const GET_ALL_ASSIGN_PROJECT_TO_COMPANY_REQUEST =
  'GET_ALL_ASSIGN_PROJECT_TO_COMPANY_REQUEST';
export const GET_ALL_ASSIGN_PROJECT_TO_COMPANY_SUCCESS =
  'GET_ALL_ASSIGN_PROJECT_TO_COMPANY_SUCCESS';
export const GET_ALL_ASSIGN_PROJECT_TO_COMPANY_ERROR = 'GET_ALL_ASSIGN_PROJECT_TO_COMPANY_ERROR';

export const CREATE_ASSIGN_PROJECT_TO_COMPANY_REQUEST = 'CREATE_ASSIGN_PROJECT_TO_COMPANY_REQUEST';
export const CREATE_ASSIGN_PROJECT_TO_COMPANY_SUCCESS = 'CREATE_ASSIGN_PROJECT_TO_COMPANY_SUCCESS';
export const CREATE_ASSIGN_PROJECT_TO_COMPANY_ERROR = 'CREATE_ASSIGN_PROJECT_TO_COMPANY_ERROR';

export const DELETE_ASSIGN_PROJECT_TO_COMPANY_REQUEST = 'DELETE_ASSIGN_PROJECT_TO_COMPANY_REQUEST';
export const DELETE_ASSIGN_PROJECT_TO_COMPANY_SUCCESS = 'DELETE_ASSIGN_PROJECT_TO_COMPANY_SUCCESS';
export const DELETE_ASSIGN_PROJECT_TO_COMPANY_ERROR = 'DELETE_ASSIGN_PROJECT_TO_COMPANY_ERROR';

export const GET_ALL_ASSIGN_PROJECT_TO_PEOPLE_REQUEST = 'GET_ALL_ASSIGN_PROJECT_TO_PEOPLE_REQUEST';
export const GET_ALL_ASSIGN_PROJECT_TO_PEOPLE_SUCCESS = 'GET_ALL_ASSIGN_PROJECT_TO_PEOPLE_SUCCESS';
export const GET_ALL_ASSIGN_PROJECT_TO_PEOPLE_ERROR = 'GET_ALL_ASSIGN_PROJECT_TO_PEOPLE_ERROR';

export const CREATE_ASSIGN_PROJECT_TO_PEOPLE_REQUEST = 'CREATE_ASSIGN_PROJECT_TO_PEOPLE_REQUEST';
export const CREATE_ASSIGN_PROJECT_TO_PEOPLE_SUCCESS = 'CREATE_ASSIGN_PROJECT_TO_PEOPLE_SUCCESS';
export const CREATE_ASSIGN_PROJECT_TO_PEOPLE_ERROR = 'CREATE_ASSIGN_PROJECT_TO_PEOPLE_ERROR';

export const DELETE_ASSIGN_PROJECT_TO_PEOPLE_REQUEST = 'DELETE_ASSIGN_PROJECT_TO_PEOPLE_REQUEST';
export const DELETE_ASSIGN_PROJECT_TO_PEOPLE_SUCCESS = 'DELETE_ASSIGN_PROJECT_TO_PEOPLE_SUCCESS';
export const DELETE_ASSIGN_PROJECT_TO_PEOPLE_ERROR = 'DELETE_ASSIGN_PROJECT_TO_PEOPLE_ERROR';

export const DELETE_CHANGE_PROJECT_TO_PEOPLE_REQUEST = 'DELETE_CHANGE_PROJECT_TO_PEOPLE_REQUEST';
export const DELETE_CHANGE_PROJECT_TO_PEOPLE_SUCCESS = 'DELETE_CHANGE_PROJECT_TO_PEOPLE_SUCCESS';
export const DELETE_CHANGE_PROJECT_TO_PEOPLE_ERROR = 'DELETE_CHANGE_PROJECT_TO_PEOPLE_ERROR';

export const GET_MODULE_PERMISSION_REQUEST = 'GET_MODULE_PERMISSION_REQUEST';
export const GET_MODULE_PERMISSION_SUCCESS = 'GET_MODULE_PERMISSION_SUCCESS';
export const GET_MODULE_PERMISSION_ERROR = 'GET_MODULE_PERMISSION_ERROR';

export const MOVE_CONTACT_TO_EMP_REQUEST = 'MOVE_CONTACT_TO_EMP_REQUEST';
export const MOVE_CONTACT_TO_EMP_SUCCESS = 'MOVE_CONTACT_TO_EMP_SUCCESS';
export const MOVE_CONTACT_TO_EMP_ERROR = 'MOVE_CONTACT_TO_EMP_ERROR';

export const GET_ALL_CPOST_ROLE_REQUEST = 'GET_ALL_CPOST_ROLE_REQUEST';
export const GET_ALL_CPOST_ROLE_SUCCESS = 'GET_ALL_CPOST_ROLE_SUCCESS';
export const GET_ALL_CPOST_ROLE_ERROR = 'GET_ALL_CPOST_ROLE_ERROR';

export const GET_USER_CPOST_ROLE_REQUEST = 'GET_USER_CPOST_ROLE_REQUEST';
export const GET_USER_CPOST_ROLE_SUCCESS = 'GET_USER_CPOST_ROLE_SUCCESS';
export const GET_USER_CPOST_ROLE_ERROR = 'GET_USER_CPOST_ROLE_ERROR';

export const CREATE_CPOST_ROLE_REQUEST = 'CREATE_CPOST_ROLE_REQUEST';
export const CREATE_CPOST_ROLE_SUCCESS = 'CREATE_CPOST_ROLE_SUCCESS';
export const CREATE_CPOST_ROLE_ERROR = 'CREATE_CPOST_ROLE_ERROR';

export const UPDATE_CPOST_ROLE_REQUEST = 'UPDATE_CPOST_ROLE_REQUEST';
export const UPDATE_CPOST_ROLE_SUCCESS = 'UPDATE_CPOST_ROLE_SUCCESS';
export const UPDATE_CPOST_ROLE_ERROR = 'UPDATE_CPOST_ROLE_ERROR';

export const GET_PERMISSIONS_BY_ROLE_REQUEST = 'GET_PERMISSIONS_BY_ROLE_REQUEST';
export const GET_PERMISSIONS_BY_ROLE_SUCCESS = 'GET_PERMISSIONS_BY_ROLE_SUCCESS';
export const GET_PERMISSIONS_BY_ROLE_ERROR = 'GET_PERMISSIONS_BY_ROLE_ERROR';

export const GET_BID_DUE_REPORT_REQUEST = 'GET_BID_DUE_REPORT_REQUEST';
export const GET_BID_DUE_REPORT_SUCCESS = 'GET_BID_DUE_REPORT_SUCCESS';
export const GET_BID_DUE_REPORT_ERROR = 'GET_BID_DUE_REPORT_ERROR';

export const GET_LEAD_SOURCE_REPORT_REQUEST = 'GET_LEAD_SOURCE_REPORT_REQUEST';
export const GET_LEAD_SOURCE_REPORT_SUCCESS = 'GET_LEAD_SOURCE_REPORT_SUCCESS';
export const GET_LEAD_SOURCE_REPORT_ERROR = 'GET_LEAD_SOURCE_REPORT_ERROR';

export const GET_PROJECT_LEAD_REPORT_REQUEST = 'GET_PROJECT_LEAD_REPORT_REQUEST';
export const GET_PROJECT_LEAD_REPORT_SUCCESS = 'GET_PROJECT_LEAD_REPORT_SUCCESS';
export const GET_PROJECT_LEAD_REPORT_ERROR = 'GET_PROJECT_LEAD_REPORT_ERROR';

export const GET_PROFIT_PER_WEEK_REPORT_REQUEST = 'GET_PROFIT_PER_WEEK_REPORT_REQUEST';
export const GET_PROFIT_PER_WEEK__REPORT_SUCCESS = 'GET_PROFIT_PER_WEEK__REPORT_SUCCESS';
export const GET_PROFIT_PER_WEEK__REPORT_ERROR = 'GET_PROFIT_PER_WEEK__REPORT_ERROR';

export const GET_ALL_HOME_REQUEST = 'GET_ALL_HOME_REQUEST';
export const GET_ALL_HOME_SUCCESS = 'GET_ALL_HOME_SUCCESS';
export const GET_ALL_HOME_ERROR = 'GET_ALL_HOME_ERROR';

export const GET_ALL_USER_LIST_REQUEST = 'GET_ALL_USER_LIST_REQUEST';
export const GET_ALL_USER_LIST_SUCCESS = 'GET_ALL_USER_LIST_SUCCESS';
export const GET_ALL_USER_LIST_ERROR = 'GET_ALL_USER_LIST_ERROR';

export const GET_EXISTING_PROFILE_REQUEST = 'GET_EXISTING_PROFILE_REQUEST';
export const GET_EXISTING_PROFILE_SUCCESS = 'GET_EXISTING_PROFILE_SUCCESS';
export const GET_EXISTING_PROFILE_ERROR = 'GET_EXISTING_PROFILE_ERROR';

export const GET_EXISTING_ACCOUNT_SETTINGS_REQUEST = 'GET_EXISTING_ACCOUNT_SETTINGS_REQUEST';
export const GET_EXISTING_ACCOUNT_SETTINGS_SUCCESS = 'GET_EXISTING_ACCOUNT_SETTINGS_SUCCESS';
export const GET_EXISTING_ACCOUNT_SETTINGS_ERROR = 'GET_EXISTING_ACCOUNT_SETTINGS_ERROR';

export const UPDATE_ACCOUNT_SETTINGS_REQUEST = 'UPDATE_ACCOUNT_SETTINGS_REQUEST';
export const UPDATE_ACCOUNT_SETTINGS_SUCCESS = 'UPDATE_ACCOUNT_SETTINGS_SUCCESS';
export const UPDATE_ACCOUNT_SETTINGS_ERROR = 'UPDATE_ACCOUNT_SETTINGS_ERROR';

export const SEARCH_REQUEST = 'SEARCH_REQUEST';
export const SEARCH_SUCCESS = 'SEARCH_SUCCESS';
export const SEARCH_ERROR = 'SEARCH_ERROR';

export const GET_COMPANY_ROLE_MATRIX_REQUEST = 'GET_COMPANY_ROLE_MATRIX_REQUEST';
export const GET_COMPANY_ROLE_MATRIX_SUCCESS =
  'GET_COMPANY_ROLE_MATRIX_SUCCESS';
export const GET_COMPANY_ROLE_MATRIX_ERROR = 'GET_COMPANY_ROLE_MATRIX_ERROR';

export const CREATE_CHANGE_ORDER_STATUS_REQUEST = 'UPDATE_CHANGE_ORDER_STATUS_REQUEST';
export const CREATE_CHANGE_ORDER_STATUS_SUCCESS = 'UPDATE_CHANGE_ORDER_STATUS_SUCCESS';
export const CREATE_CHANGE_ORDER_STATUS_ERROR = 'UPDATE_CHANGE_ORDER_STATUS_ERROR';

export const ADD_CONTACT_COMPANY_REQUEST = 'ADD_CONTACT_COMPANY_REQUEST';
export const ADD_CONTACT_COMPANY_SUCCESS = 'ADD_CONTACT_COMPANY_SUCCESS';
export const ADD_CONTACT_COMPANY_ERROR = 'ADD_CONTACT_COMPANY_ERROR';

export const UPDATE_PROJECT_SECTION_REQUEST = 'UPDATE_PROJECT_SECTION_REQUEST';
export const UPDATE_PROJECT_SECTION_SUCCESS = 'UPDATE_PROJECT_SECTION_SUCCESS';
export const UPDATE_PROJECT_SECTION_ERROR = 'UPDATE_PROJECT_SECTION_ERROR';

export const UPDATE_PROFILE_REQUEST = 'UPDATE_PROFILE_REQUEST';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_ERROR = 'UPDATE_PROFILE_ERROR';

export const GET_ALL_EMAIL_REQUEST = 'GET_ALL_EMAIL_REQUEST';
export const GET_ALL_EMAIL_SUCCESS = 'GET_ALL_EMAIL_SUCCESS';
export const GET_ALL_EMAIL_ERROR = 'GET_ALL_EMAIL_ERROR';

export const CREATE_EMAIL_REQUEST = 'CREATE_EMAIL_REQUEST';
export const CREATE_EMAIL_SUCCESS = 'CREATE_EMAIL_SUCCESS';
export const CREATE_EMAIL_ERROR = 'CREATE_EMAIL_ERROR';

export const GET_ALL_SCHEDULE_REQUEST = 'GET_ALL_SCHEDULE_REQUEST';
export const GET_ALL_SCHEDULE_SUCCESS = 'GET_ALL_SCHEDULE_SUCCESS';
export const GET_ALL_SCHEDULE_ERROR = 'GET_ALL_SCHEDULE_ERROR';

export const CREATE_SCHEDULE_REQUEST = 'CREATE_SCHEDULE_REQUEST';
export const CREATE_SCHEDULE_SUCCESS = 'CREATE_SCHEDULE_SUCCESS';
export const CREATE_SCHEDULE_ERROR = 'CREATE_SCHEDULE_ERROR';

export const UPDATE_LEAD_SECTION_REQUEST = 'UPDATE_LEAD_SECTION_REQUEST';
export const UPDATE_LEAD_SECTION_SUCCESS = 'UPDATE_LEAD_SECTION_SUCCESS';
export const UPDATE_LEAD_SECTION_ERROR = 'UPDATE_LEAD_SECTION_ERROR';

export const GET_ALL_CUSTOM_FIELD_REQUEST = 'GET_ALL_CUSTOM_FIELD_REQUEST';
export const GET_ALL_CUSTOM_FIELD_SUCCESS = 'GET_ALL_CUSTOM_FIELD_SUCCESS';
export const GET_ALL_CUSTOM_FIELD_ERROR = 'GET_ALL_CUSTOM_FIELD_ERROR';

export const CREATE_CUSTOM_FIELD_REQUEST = 'CREATE_CUSTOM_FIELD_REQUEST';
export const CREATE_CUSTOM_FIELD_SUCCESS = 'CREATE_CUSTOM_FIELD_SUCCESS';
export const CREATE_CUSTOM_FIELD_ERROR = 'CREATE_CUSTOM_FIELD_ERROR';

export const UPDATE_CUSTOM_FIELD_REQUEST = 'UPDATE_CUSTOM_FIELD_REQUEST';
export const UPDATE_CUSTOM_FIELD_SUCCESS = 'UPDATE_CUSTOM_FIELD_SUCCESS';
export const UPDATE_CUSTOM_FIELD_ERROR = 'UPDATE_CUSTOM_FIELD_ERROR';

export const DELETE_CUSTOM_FIELD_REQUEST = 'DELETE_CUSTOM_FIELD_REQUEST';
export const DELETE_CUSTOM_FIELD_SUCCESS = 'DELETE_CUSTOM_FIELD_SUCCESS';
export const DELETE_CUSTOM_FIELD_ERROR = 'DELETE_CUSTOM_FIELD_ERROR';

export const GET_LAST_JOB_NUM_REQUEST = 'GET_LAST_JOB_NUM_REQUEST';
export const GET_LAST_JOB_NUM_SUCCESS = 'GET_LAST_JOB_NUM_SUCCESS';
export const GET_LAST_JOB_NUM_ERROR = 'GET_LAST_JOB_NUM_ERROR';

export const GET_BID_PENDING_REPORT_REQUEST = 'GET_BID_PENDING_REPORT_REQUEST';
export const GET_BID_PENDING_REPORT_SUCCESS = 'GET_BID_PENDING_REPORT_SUCCESS';
export const GET_BID_PENDING_REPORT_ERROR = 'GET_BID_PENDING_REPORT_ERROR';

export const GET_EMP_ROSTER_REPORT_REQUEST = 'GET_EMP_ROSTER_REPORT_REQUEST';
export const GET_EMP_ROSTER_REPORT_SUCCESS = 'GET_EMP_ROSTER_REPORT_SUCCESS';
export const GET_EMP_ROSTER_REPORT_ERROR = 'GET_EMP_ROSTER_REPORT_ERROR';

export const GET_ACTIVE_PROJECT_REPORT_REQUEST = 'GET_ACTIVE_PROJECT_REPORT_REQUEST';
export const GET_ACTIVE_PROJECT_REPORT_SUCCESS = 'GET_ACTIVE_PROJECT_REPORT_SUCCESS';
export const GET_ACTIVE_PROJECT_REPORT_ERROR = 'GET_ACTIVE_PROJECT_REPORT_ERROR';

export const GET_ADDRESS_LABEL_REPORT_REQUEST = 'GET_ADDRESS_LABEL_REPORT_REQUEST';
export const GET_ADDRESS_LABEL_REPORT_SUCCESS = 'GET_ADDRESS_LABEL_REPORT_SUCCESS';
export const GET_ADDRESS_LABEL_REPORT_ERROR = 'GET_ADDRESS_LABEL_REPORT_ERROR';

export const GET_TIME_LINE_REQUEST = 'GET_TIME_LINE_REQUEST';
export const GET_TIME_LINE_SUCCESS = 'GET_TIME_LINE_SUCCESS';
export const GET_TIME_LINE_ERROR = 'GET_TIME_LINE_ERROR';

export const GET_CONTACT_LIST_REPORT_REQUEST = 'GET_CONTACT_LIST_REPORT_REQUEST';
export const GET_CONTACT_LIST_REPORT_SUCCESS = 'GET_CONTACT_LIST_REPORT_SUCCESS';
export const GET_CONTACT_LIST_REPORT_ERROR = 'GET_CONTACT_LIST_REPORT_ERROR';

export const GET_CUSTOMIZE_PROJECT_LIST_REPORT_REQUEST =
  'GET_CUSTOMIZE_PROJECT_LIST_REPORT_REQUEST';
export const GET_CUSTOMIZE_PROJECT_LIST_REPORT_SUCCESS =
  'GET_CUSTOMIZE_PROJECT_LIST_REPORT_SUCCESS';
export const GET_CUSTOMIZE_PROJECT_LIST_REPORT_ERROR = 'GET_CUSTOMIZE_PROJECT_LIST_REPORT_ERROR';

export const DELETE_EMAIL_REQUEST = 'DELETE_EMAIL_REQUEST';
export const DELETE_EMAIL_SUCCESS = 'DELETE_EMAIL_SUCCESS';
export const DELETE_EMAIL_ERROR = 'DELETE_EMAIL_ERROR';

export const GET_CLIENT_LIST_REPORT_REQUEST = 'GET_CLIENT_LIST_REPORT_REQUEST';
export const GET_CLIENT_LIST_REPORT_SUCCESS = 'GET_CLIENT_LIST_REPORT_SUCCESS';
export const GET_CLIENT_LIST_REPORT_ERROR = 'GET_CLIENT_LIST_REPORT_ERROR';

export const GET_COMPANY_LIST_REPORT_REQUEST = 'GET_COMPANY_LIST_REPORT_REQUEST';
export const GET_COMPANY_LIST_REPORT_SUCCESS = 'GET_COMPANY_LIST_REPORT_SUCCESS';
export const GET_COMPANY_LIST_REPORT_ERROR = 'GET_COMPANY_LIST_REPORT_ERROR';

export const GET_CONTACT_JOB_LIST_REPORT_REQUEST = 'GET_CONTACT_JOB_LIST_REPORT_REQUEST';
export const GET_CONTACT_JOB_LIST_REPORT_SUCCESS = 'GET_CONTACT_JOB_LIST_REPORT_SUCCESS';
export const GET_CONTACT_JOB_LIST_REPORT_ERROR = 'GET_CONTACT_JOB_LIST_REPORT_ERROR';

export const GET_NO_BID_REPORT_REQUEST = 'GET_NO_BID_REPORT_REQUEST';
export const GET_NO_BID_REPORT_SUCCESS = 'GET_NO_BID_REPORT_SUCCESS';
export const GET_NO_BID_REPORT_ERROR = 'GET_NO_BID_REPORT_ERROR';

export const GET_ALL_REASON_CODE_BY_STATUS_CODE_REQUEST =
  'GET_ALL_REASON_CODE_BY_STATUS_CODE_REQUEST';
export const GET_ALL_REASON_CODE_BY_STATUS_CODE_SUCCESS =
  'GET_ALL_REASON_CODE_BY_STATUS_CODE_SUCCESS';
export const GET_ALL_REASON_CODE_BY_STATUS_CODE_ERROR = 'GET_ALL_REASON_CODE_BY_STATUS_CODE_ERROR';

export const GET_DELIVERED_REPORT_REQUEST = 'GET_DELIVERED_REPORT_REQUEST';
export const GET_DELIVERED_REPORT_SUCCESS = 'GET_DELIVERED_REPORT_SUCCESS';
export const GET_DELIVERED_REPORT_ERROR = 'GET_DELIVERED_REPORT_ERROR';

export const GET_LIEN_PERIOD_REPORT_REQUEST = 'GET_LIEN_PERIOD_REPORT_REQUEST';
export const GET_LIEN_PERIOD_REPORT_SUCCESS = 'GET_LIEN_PERIOD_REPORT_SUCCESS';
export const GET_LIEN_PERIOD_REPORT_ERROR = 'GET_LIEN_PERIOD_REPORT_ERROR';

export const GET_WARRANTY_STATUS_REPORT_REQUEST = 'GET_WARRANTY_STATUS_REPORT_REQUEST';
export const GET_WARRANTY_STATUS_REPORT_SUCCESS = 'GET_WARRANTY_STATUS_REPORT_SUCCESS';
export const GET_WARRANTY_STATUS_REPORT_ERROR = 'GET_WARRANTY_STATUS_REPORT_ERROR';

export const SET_IMAGE_MASTER_REQUEST = 'SET_IMAGE_MASTER_REQUEST';
export const SET_IMAGE_MASTER_SUCCESS = 'SET_IMAGE_MASTER_SUCCESS';
export const SET_IMAGE_MASTER_ERROR = 'SET_IMAGE_MASTER_ERROR';

export const GET_FOLLOW_UP_PROJECT_REPORT_REQUEST = 'GET_FOLLOW_UP_PROJECT_REPORT_REQUEST';
export const GET_FOLLOW_UP_PROJECT_REPORT_SUCCESS = 'GET_FOLLOW_UP_PROJECT_REPORT_SUCCESS';
export const GET_FOLLOW_UP_PROJECT_REPORT_ERROR = 'GET_FOLLOW_UP_PROJECT_REPORT_ERROR';

export const GET_LOST_JOB_REPORT_REQUEST = 'GET_LOST_JOB_REPORT_REQUEST';
export const GET_LOST_JOB_REPORT_SUCCESS = 'GET_LOST_JOB_REPORT_SUCCESS';
export const GET_LOST_JOB_REPORT_ERROR = 'GET_LOST_JOB_REPORT_ERROR';

export const GET_PROFIT_PROJECTION_REPORT_REQUEST = 'GET_PROFIT_PROJECTION_REPORT_REQUEST';
export const GET_PROFIT_PROJECTION_REPORT_SUCCESS = 'GET_PROFIT_PROJECTION_REPORT_SUCCESS';
export const GET_PROFIT_PROJECTION_REPORT_ERROR = 'GET_PROFIT_PROJECTION_REPORT_ERROR';

export const GET_CUSTOM_FIELD_REQUEST = 'GET_CUSTOM_FIELD_REQUEST';
export const GET_CUSTOM_FIELD_SUCCESS = 'GET_CUSTOM_FIELD_SUCCESS';
export const GET_CUSTOM_FIELD_ERROR = 'GET_CUSTOM_FIELD_ERROR';

export const IMPORT_MULTIPLE_CONTACT_REQUEST = 'IMPORT_MULTIPLE_CONTACT_REQUEST';
export const IMPORT_MULTIPLE_CONTACT_SUCCESS = 'IMPORT_MULTIPLE_CONTACT_SUCCESS';
export const IMPORT_MULTIPLE_CONTACT_ERROR = 'IMPORT_MULTIPLE_CONTACT_ERROR';

export const IMPORT_MULTIPLE_COMPANY_REQUEST = 'IMPORT_MULTIPLE_COMPANY_REQUEST';
export const IMPORT_MULTIPLE_COMPANY_SUCCESS = 'IMPORT_MULTIPLE_COMPANY_SUCCESS';
export const IMPORT_MULTIPLE_COMPANY_ERROR = 'IMPORT_MULTIPLE_COMPANY_ERROR';

export const UPLOAD_SHEET_DATA_REQUEST = 'UPLOAD_SHEET_DATA_REQUEST';
export const UPLOAD_SHEET_DATA_SUCCESS = 'UPLOAD_SHEET_DATA_SUCCESS';
export const UPLOAD_SHEET_DATA_ERROR = 'UPLOAD_SHEET_DATA_ERROR';

export const UPDATE_ONBOARDING_STATUS_REQUEST = 'UPDATE_ONBOARDING_STATUS_REQUEST';
export const UPDATE_ONBOARDING_STATUS_SUCCESS = 'UPDATE_ONBOARDING_STATUS_SUCCESS';
export const UPDATE_ONBOARDING_STATUS_ERROR = 'UPDATE_ONBOARDING_STATUS_ERROR';

export const SAVE_CUSTOM_FIELD_VALUE_REQUEST = 'SAVE_CUSTOM_FIELD_VALUE_REQUEST';
export const SAVE_CUSTOM_FIELD_VALUE_SUCCESS = 'SAVE_CUSTOM_FIELD_VALUE_SUCCESS';
export const SAVE_CUSTOM_FIELD_VALUE_ERROR = 'SAVE_CUSTOM_FIELD_VALUE_ERROR';

export const GET_HELP_REQUEST = 'GET_HELP_REQUEST';
export const GET_HELP_SUCCESS = 'GET_HELP_SUCCESS';
export const GET_HELP_ERROR = 'GET_HELP_ERROR';

export const GET_MODULES_REQUEST = 'GET_MODULES_REQUEST';
export const GET_MODULES_SUCCESS = 'GET_MODULES_SUCCESS';
export const GET_MODULES_ERROR = 'GET_MODULES_ERROR';

export const EDIT_MODULES_REQUEST = 'EDIT_MODULES_REQUEST';
export const EDIT_MODULES_SUCCESS = 'EDIT_MODULES_SUCCESS';
export const EDIT_MODULES_ERROR = 'EDIT_MODULES_ERROR';

export const DELETE_HELP_REQUEST = 'DELETE_HELP_REQUEST';
export const DELETE_HELP_SUCCESS = 'DELETE_HELP_SUCCESS';
export const DELETE_HELP_ERROR = 'DELETE_HELP_ERROR';

export const UPDATE_ACCOUNT_REQUEST = 'UPDATE_ACCOUNT_REQUEST';
export const UPDATE_ACCOUNT_SUCCESS = 'UPDATE_ACCOUNT_SUCCESS';
export const UPDATE_ACCOUNT_ERROR = 'UPDATE_ACCOUNT_ERROR';

export const ADD_HELP_REQUEST = 'ADD_HELP_REQUEST';
export const ADD_HELP_SUCCESS = 'ADD_HELP_SUCCESS';
export const ADD_HELP_ERROR = 'ADD_HELP_ERROR';

export const ADD_HELP_BY_FILE_UPLOAD_REQUEST = 'ADD_HELP_BY_FILE_UPLOAD_REQUEST';
export const ADD_HELP_BY_FILE_UPLOAD_SUCCESS = 'ADD_HELP_BY_FILE_UPLOAD_SUCCESS';
export const ADD_HELP_BY_FILE_UPLOAD_ERROR = 'ADD_HELP_BY_FILE_UPLOAD_ERROR';

export const UPDATE_HELP_REQUEST = 'UPDATE_HELP_REQUEST';
export const UPDATE_HELP_SUCCESS = 'UPDATE_HELP_SUCCESS';
export const UPDATE_HELP_ERROR = 'UPDATE_HELP_ERROR';

export const ADMIN_FORGOT_LOGIN_REQUEST = 'ADMIN_FORGOT_LOGIN_REQUEST';
export const ADMIN_FORGOT_LOGIN_SUCCESS = 'ADMIN_FORGOT_LOGIN_SUCCESS';
export const ADMIN_FORGOT_LOGIN_ERROR = 'ADMIN_FORGOT_LOGIN_ERROR';

export const GET_DASHBOARD_REQUEST = 'GET_DASHBOARD_REQUEST';
export const GET_DASHBOARD_SUCCESS = 'GET_DASHBOARD_SUCCESS';
export const GET_DASHBOARD_ERROR = 'GET_DASHBOARD_ERROR';

export const GET_ACCOUNT_LIST_REQUEST = 'GET_ACCOUNT_LIST_REQUEST';
export const GET_ACCOUNT_LIST_SUCCESS = 'GET_ACCOUNT_LIST_SUCCESS';
export const GET_ACCOUNT_LIST_ERROR = 'GET_ACCOUNT_LIST_ERROR';

export const ADMIN_ACCOUNT_STATUS_LIST_REQUEST = 'ADMIN_ACCOUNT_STATUS_LIST_REQUEST';
export const ADMIN_ACCOUNT_STATUS_LIST_SUCCESS = 'ADMIN_ACCOUNT_STATUS_LIST_SUCCESS';
export const ADMIN_ACCOUNT_STATUS_LIST_ERROR = 'ADMIN_ACCOUNT_STATUS_LIST_ERROR';

export const UPDATE_PLANS_REQUEST = 'UPDATE_PLANS_REQUEST';
export const UPDATE_PLANS_SUCCESS = 'UPDATE_PLANS_SUCCESS';
export const UPDATE_PLANS_ERROR = 'UPDATE_PLANS_ERROR';

export const DELETE_PLANS_REQUEST = 'DELETE_PLANS_REQUEST';
export const DELETE_PLANS_SUCCESS = 'DELETE_PLANS_SUCCESS';
export const DELETE_PLANS_ERROR = 'DELETE_PLANS_ERROR';

export const GET_ALL_PLANS_REQUEST = 'GET_ALL_PLANS_REQUEST';
export const GET_ALL_PLANS_SUCCESS = 'GET_ALL_PLANS_SUCCESS';
export const GET_ALL_PLANS_ERROR = 'GET_ALL_PLANS_ERROR';

export const CREATE_PLANS_REQUEST = 'CREATE_PLANS_REQUEST';
export const CREATE_PLANS_SUCCESS = 'CREATE_PLANS_SUCCESS';
export const CREATE_PLANS_ERROR = 'CREATE_PLANS_ERROR';

export const DISABLE_WIDGETS_REQUEST = 'DISABLE_WIDGETS_REQUEST';
export const DISABLE_WIDGETS_SUCCESS = 'DISABLE_WIDGETS_SUCCESS';
export const DISABLE_WIDGETS_ERROR = 'DISABLE_WIDGETS_ERROR';

export const GET_ALL_WIDGETS_REQUEST = 'GET_ALL_WIDGETS_REQUEST';
export const GET_ALL_WIDGETS_SUCCESS = 'GET_ALL_WIDGETS_SUCCESS';
export const GET_ALL_WIDGETS_ERROR = 'GET_ALL_WIDGETS_ERROR';

export const CREATE_WIDGETS_REQUEST = 'CREATE_WIDGETS_REQUEST';
export const CREATE_WIDGETS_SUCCESS = 'CREATE_WIDGETS_SUCCESS';
export const CREATE_WIDGETS_ERROR = 'CREATE_WIDGETS_ERROR';

export const DISABLE_NOTIFICATION_TYPES_REQUEST = 'DISABLE_NOTIFICATION_TYPES_REQUEST';
export const DISABLE_NOTIFICATION_TYPES_SUCCESS = 'DISABLE_NOTIFICATION_TYPES_SUCCESS';
export const DISABLE_NOTIFICATION_TYPES_ERROR = 'DISABLE_NOTIFICATION_TYPES_ERROR';

export const GET_ALL_NOTIFICATION_TYPES_REQUEST = 'GET_ALL_NOTIFICATION_TYPES_REQUEST';
export const GET_ALL_NOTIFICATION_TYPES_SUCCESS = 'GET_ALL_NOTIFICATION_TYPES_SUCCESS';
export const GET_ALL_NOTIFICATION_TYPES_ERROR = 'GET_ALL_NOTIFICATION_TYPES_ERROR';

export const CREATE_NOTIFICATION_TYPES_REQUEST = 'CREATE_NOTIFICATION_TYPES_REQUEST';
export const CREATE_NOTIFICATION_TYPES_SUCCESS = 'CREATE_NOTIFICATION_TYPES_SUCCESS';
export const CREATE_NOTIFICATION_TYPES_ERROR = 'CREATE_NOTIFICATION_TYPES_ERROR';

export const ADD_TERMS_CONDITION_REQUEST = 'ADD_TERMS_CONDITION_REQUEST';
export const ADD_TERMS_CONDITION_SUCCESS = 'ADD_TERMS_CONDITION_SUCCESS';
export const ADD_TERMS_CONDITION_ERROR = 'ADD_TERMS_CONDITION_ERROR';

export const GET_TERMS_CONDITION_REQUEST = 'GET_TERMS_CONDITION_REQUEST';
export const GET_TERMS_CONDITION_SUCCESS = 'GET_TERMS_CONDITION_SUCCESS';
export const GET_TERMS_CONDITION_ERROR = 'GET_TERMS_CONDITION_ERROR';

export const REGISTER_COMPANY_ACCOUNT_REQUEST = 'REGISTER_COMPANY_ACCOUNT_REQUEST';
export const REGISTER_COMPANY_ACCOUNT_SUCCESS = 'REGISTER_COMPANY_ACCOUNT_SUCCESS';
export const REGISTER_COMPANY_ACCOUNT_ERROR = 'REGISTER_COMPANY_ACCOUNT_ERROR';

export const UPDATE_ADMIN_ACCOUNT_REQUEST = 'UPDATE_ADMIN_ACCOUNT_REQUEST';
export const UPDATE_ADMIN_ACCOUNT_SUCCESS = 'UPDATE_ADMIN_ACCOUNT_SUCCESS';
export const UPDATE_ADMIN_ACCOUNT_ERROR = 'UPDATE_ADMIN_ACCOUNT_ERROR';

export const GET_USER_ROLE_MODULE_REQUEST = 'GET_USER_ROLE_MODULE_REQUEST';
export const GET_USER_ROLE_MODULE_SUCCESS = 'GET_USER_ROLE_MODULE_SUCCESS';
export const GET_USER_ROLE_MODULE_ERROR = 'GET_USER_ROLE_MODULE_ERROR';

export const GET_USER_PERMISSION_REQUEST = 'GET_USER_PERMISSION_REQUEST';
export const GET_USER_PERMISSION_SUCCESS = 'GET_USER_PERMISSION_SUCCESS';
export const GET_USER_PERMISSION_ERROR = 'GET_USER_PERMISSION_ERROR';

export const GET_CPOST_ROLE_REQUEST = 'GET_CPOST_ROLE_REQUEST';
export const GET_CPOST_ROLE_SUCCESS = 'GET_CPOST_ROLE_SUCCESS';
export const GET_CPOST_ROLE_ERROR = 'GET_CPOST_ROLE_ERROR';

export const EDIT_CCP_ROLE_REQUEST = 'EDIT_CCP_ROLE_REQUEST';
export const EDIT_CCP_ROLE_SUCCESS = 'EDIT_CCP_ROLE_SUCCESS';
export const EDIT_CCP_ROLE_ERROR = 'EDIT_CCP_ROLE_ERROR';

export const ADMIN_SET_NEW_PASSWORD_REQUEST = 'ADMIN_SET_NEW_PASSWORD_REQUEST';
export const ADMIN_SET_NEW_PASSWORD_SUCCESS = 'ADMIN_SET_NEW_PASSWORD_SUCCESS';
export const ADMIN_SET_NEW_PASSWORD_ERROR = 'ADMIN_SET_NEW_PASSWORD_ERROR';

export const ADMIN_DASHBOARD_REQUEST = 'ADMIN_DASHBOARD_REQUEST';
export const ADMIN_DASHBOARD_SUCCESS = 'ADMIN_DASHBOARD_SUCCESS';
export const ADMIN_DASHBOARD_ERROR = 'ADMIN_DASHBOARD_ERROR';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';

export const LOAD_JOB_STATUS_REQUEST = "LOAD_JOB_STATUS_REQUEST";
export const LOAD_JOB_STATUS_SUCCESS = "LOAD_JOB_STATUS_SUCCESS";
export const LOAD_JOB_STATUS_ERROR = "LOAD_JOB_STATUS_ERROR";

export const COPY_JOB_STATUS_REQUEST = "COPY_JOB_STATUS_REQUEST";
export const COPY_JOB_STATUS_SUCCESS = "COPY_JOB_STATUS_SUCCESS";
export const COPY_JOB_STATUS_ERROR = "COPY_JOB_STATUS_ERROR";
