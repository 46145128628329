import React, { Suspense } from 'react';

import { Link, withRouter } from 'react-router-dom';
import Header from '../../Layout/Header.js';
import Footer from '../../Layout/Footer.js';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { connect } from 'react-redux';
import { appConstants } from '../../../_constants';
import { customSortByKey, CustomTable, multiSelectFilter } from '../../Common/CustomTable';
import { matchDataForTable } from '../../../utils/match-for-table';
import {
  GetMasterProjectAction,
  GetMasterProjectPrefAction,
  SetMasterProjectPrefAction,
} from '../../../actions/reports';
import ReactLoading from 'react-loading';

import DatePickerWrapper  from '../../../shared/datepicker-wrapper/datepicker-wrapper';
import {Tooltip} from 'react-tooltip';
import NotePopUp from '../../Common/NotePopUp';
import renderHTML from 'html-react-parser';
import TitleComponent from '../../Common/TitleComponent';
import { DateColumnFilter } from '../../Common/CustomTableComponents/DateColumnFilter';
import { SelectColumnFilter } from '../../Common/CustomTableComponents/SelectColumnFilter';
import {
  formatExternalContactData,
  formatInternalContactData,
  formatAddressText
} from '../../../utils/contact-formatters';
import {
  formatDateAdd,
  formatDateObject,
  formatDate,
  startDateFormatWithoutTimeUTCF,
  endDateFormatWithoutTimeUTCF,
} from '../../../utils/date-formatters';
import { formatNote } from '../../../utils/note-formatters';
import { getAllProjectRoleAction } from '../../../actions/projectRole';
import { getAllProjectFieldsAction } from '../../../actions/projectFields';
import { isAllowed, moduleConstants } from '../../../_constants';
import {buildColumnForField, buildDynamicColumns, onCurrency, onDate, onBoolean} from '../../../utils/column-formatters';

var sortingOrder = '';
var masterDateStatus = '';

class MasterProjectLogReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {},
      accountId: localStorage.getItem('accountId'),
      userId: localStorage.getItem('userId'),
      list: [],
      modal: false,
      fetchRequest: false,
      projectRoleTypes: [],
      comLogo: '',
      currentPage: 1,
      totalCount: 0,
      sizePerPage: appConstants.TableSizePerPageList[2].value,
      searchText: '',
      selectDateBox: {
        startDate: formatDateObject().subtract(1, 'years'),
        endDate: formatDateObject(),
      },
      sortType: 'DESC',
      sortKey: '',
      noteModal: false,
      contactData: {},
      todayButton: 'Today',
      projectRoleTypes: [],
      allProjectFieldTypes: [],
      dynamicColumns: [],
    };

    this.onPageChange = this.onPageChange.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.toggle = this.toggle.bind(this);
    this.onProjectName = this.onProjectName.bind(this);
    this.onSortChange = this.onSortChange.bind(this);
    this.onAltJobNum = this.onAltJobNum.bind(this);
    this.getModalOpen = this.getModalOpen.bind(this);
    this.onNoteModalClose = this.onNoteModalClose.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps?.projectRoles?.allProjectRoleTypes) {
      this.setState({
        projectRoleTypes: nextProps.projectRoles.allProjectRoleTypes,
      });
    }


    if (nextProps?.projectFields?.allProjectFieldTypes?.length > 0) {
      this.setState({
        allProjectFieldTypes: nextProps.projectFields.allProjectFieldTypes,
      });
    }


    if (nextProps?.listData?.success === 1 && !this.state.dataForExcel && !this.state.dataForPdf) {
      const fieldsToMatch = {
        projectAssignments: { lookupLocation: 'contactData' },
      };

      let matchedDataForTable = matchDataForTable(
        fieldsToMatch,
        nextProps.listData.data,
        nextProps.listData.contactData,
        this.props
      );

      this.setState({
        totalCount: nextProps.listData.count,
        list: matchedDataForTable,
        fetchRequest: false,
        contactData: nextProps.listData.contactData,
      });
    }
    if (
      nextProps.listData &&
      nextProps.listData.success &&
      nextProps.listData.success === 1 &&
      (this.state.dataForExcel || this.state.dataForPdf)
    ) {
      this.setState({ fetchRequest: false, dataForExcel: false, dataForPdf: false });
      this.makeDataForExportExcel(nextProps.listData.data);
    }
    if (nextProps.listData && nextProps.listData.data && nextProps.listData.data.length === 0) {
      this.setState({ fetchRequest: false });
    }
  }


  componentDidMount() {
    if (
      localStorage.getItem('companyLogo') !== 'undefined' &&
      localStorage.getItem('companyLogo') !== null
    ) {
      this.setState({
        comLogo: localStorage.getItem('companyLogo'),
      });
    } else {
      let constImage = appConstants.CONTENT_CPOST_LOGO_URL;
      this.setState({ comLogo: constImage });
    }

    let _this = this;
    setTimeout(function () {
      _this.setState({ fetchRequest: false });
    }, 2000);

    sortingOrder = '';
    this.props.GetMasterProjectPrefAction();
  }

  UNSAFE_componentWillMount() {
    this.toggle();
    this.props.getAllProjectRoleAction({
      className: 'projectRoleTypes',
      accountId: this.state.accountId,
    });

    this.props.getAllProjectFieldsAction({
      accountId: this.state.accountId,
      minimumStage: 'all',
    });
  }

  toggle(val) {
    this.setState({ modal: !this.state.modal });
  }

  filteredData(e) {
    e.preventDefault();
    this.setState({ fetchRequest: true });
    this.setState({ sizePerPage: appConstants.TableSizePerPageList[2].value });
    let data = {};
    data['accountId'] = this.state.accountId;
    data['moduleName'] = appConstants.reportModuleName['30'];
    data['startDate'] = startDateFormatWithoutTimeUTCF(this.state.selectDateBox.startDate);
    data['endDate'] = endDateFormatWithoutTimeUTCF(this.state.selectDateBox.endDate);
    data['page'] = 0;
    data['displayLimit'] = 99999999;
    data['sortKey'] = this.state.sortKey;
    data['sortType'] = this.state.sortType;
    data['search'] = this.state.searchText;

    masterDateStatus =
      formatDate(this.state.selectDateBox.startDate) +
      ' to ' +
      formatDate(this.state.selectDateBox.endDate);

    this.toggle();
    this.props.GetMasterProjectAction(data);
  }

  handleDatePicker = (name, date) => {
    let field = this.state.selectDateBox;
    field[name] = date;

    // if(date===null){
    //   if(name==="endDate"){
    //      field["endDate"]  = formatDateObject(field['startDate']).add(1,"day");
    //   }else{
    //      field[name]  = formatDateObject();
    //   }
    // }

    if (name === 'startDate') {
      this.setState({
        todayButton: field['startDate'] > formatDateObject().add(-1, 'day') ? false : 'Today',
      });

      let dateObj = '';

      if (date === null) {
        dateObj = formatDateObject().add(1, 'day');
        field['endDate'] = dateObj;
      } else {
        dateObj = formatDateObject(date).add(1, 'Day');
        if (field['startDate'] >= field['endDate']) {
          field['endDate'] = dateObj;
        }
      }
    }

    this.setState({
      selectDateBox: field,
    });
  };

  redirectToProject(cell, row, enumObject, rowIndex) {
    if (
      row &&
      row.objectId &&
      (row.jobStatusCodesCode === 'L' || row.jobStatusCodesCode === 'NP')
    ) {
      return (
        <span>
          <Link to={{ pathname: `/lead-details/` + row.objectId, state: { row } }}>
            {row.jobNumFormatted ? row.jobNumFormatted : '-'}
          </Link>
        </span>
      );
    } else {
      return (
        <span>
          <Link to={{ pathname: `/project-details/` + row.objectId, state: { row } }}>
            {row.jobNumFormatted ? row.jobNumFormatted : '-'}
          </Link>
        </span>
      );
    }
  }

  onProjectName(cell, row, enumObject, rowIndex) {
    if (
      row &&
      row.objectId &&
      (row.jobStatusCodesCode === 'L' || row.jobStatusCodesCode === 'NP')
    ) {
      return (
        <span>
          <strong>
            <Link to={{ pathname: `/lead-details/` + row.objectId, state: { row } }}>
              {row.jobName ? row.jobName : '-'}
            </Link>
          </strong>
        </span>
      );
    } else {
      return (
        <span>
          <strong>
            <Link to={{ pathname: `/project-details/` + row.objectId, state: { row } }}>
              {row.jobName ? row.jobName : '-'}
            </Link>
          </strong>
        </span>
      );
    }
  }

  onAltJobNum(cell, row, enumObject, rowIndex) {
    if (
      row &&
      row.objectId &&
      (row.jobStatusCodesCode === 'L' || row.jobStatusCodesCode === 'NP')
    ) {
      return (
        <span>
          <strong>
            <Link to={{ pathname: `/lead-details/` + row.objectId, state: { row } }}>
              {row.altJobNum ? row.altJobNum : '-'}
            </Link>
          </strong>
        </span>
      );
    } else {
      return (
        <span>
          <strong>
            <Link to={{ pathname: `/project-details/` + row.objectId, state: { row } }}>
              {row.altJobNum ? row.altJobNum : '-'}
            </Link>
          </strong>
        </span>
      );
    }
  }


  onJobStatus(cell, row, enumObject, rowIndex) {
    return row && row.jobStatusCodesCode ? row.jobStatusCodesCode : '-';
  }


  onNoteDetail(cell, row, enumObject, rowIndex) {
    // return (<div>{(row && row.jobNotes)?row.jobNotes:'-'}</div>)
    const notes = formatNote(row?.jobNotes);
    return notes ? (
      <a
        className='anchorNote '
        data-type={'reminder'}
        onClick={(e) => {
          this.getModalOpen(e, row);
        }}
      >
        {renderHTML(notes)}
      </a>
    ) : (
      '-'
    );
  }

  onNoteModalClose() {
    this.setState({ noteModal: false });
  }

  getModalOpen(e, row) {
    let noteText = row && row.jobNotes ? row.jobNotes : '-';
    let lastNoteId = row && row.lastNoteId ? row.lastNoteId : null;
    this.setState({ noteModal: true, noteData: {objectId:lastNoteId,description: noteText,typeId: row.objectId, typeRef: 'PROJECT'} });
  }

  createCustomButtonGroup = (props) => {
    return (
      <div className='pdfLogoBox'>
        <div className='reportHead'>
          <div className='logo'>
            <img src={this.state.comLogo} alt='Upload Logo' />
          </div>
          <h2>Master Project Log Report</h2>
        </div>
        <div className='searchbar'>
          <div className='col-md-12'>
            <div>
              <strong>Date : </strong>
              {masterDateStatus}
            </div>
          </div>
        </div>
      </div>
    );
  };

  _setTableOption() {
    if (this.state.fetchRequest) {
      return (
        <ReactLoading
          className='table-loader'
          type={appConstants.LOADER_TYPE}
          color={appConstants.LOADER_COLOR}
          height={appConstants.LOADER_HEIGHT}
          width={appConstants.LOADER_WIDTH}
        />
      );
    } else {
      return 'No data found..';
    }
  }

  onPageChange(page, sizePerPage) {
    this.setState({ fetchRequest: true });
    let data = {};

    data['accountId'] = this.state.accountId;
    data['moduleName'] = appConstants.reportModuleName['30'];
    data['startDate'] = startDateFormatWithoutTimeUTCF(this.state.selectDateBox.startDate);
    data['endDate'] = endDateFormatWithoutTimeUTCF(this.state.selectDateBox.endDate);
    //
    // if (this.state.searchText === "") {
    //     data['page'] = (page === -1) ? 0 : page - 1;
    //     data['displayLimit'] = sizePerPage;
    // } else {
    data['page'] = page === -1 ? 0 : page - 1;
    data['displayLimit'] = sizePerPage;
    data['search'] = this.state.searchText;
    //}
    data['sortKey'] = this.state.sortKey;
    data['sortType'] = this.state.sortType;
    this.setState({ sizePerPage: sizePerPage });
    this.setState({ currentPage: page });

    this.props.GetMasterProjectAction(data);
  }

  onSearchChange(text, sizePerPage) {
    this.setState({ fetchRequest: true, list: [] });
    text = text ? text.trim() : '';
    let data = {};
    data['accountId'] = this.state.accountId;
    data['moduleName'] = appConstants.reportModuleName['30'];
    data['startDate'] = startDateFormatWithoutTimeUTCF(this.state.selectDateBox.startDate);
    data['endDate'] = endDateFormatWithoutTimeUTCF(this.state.selectDateBox.endDate);
    data['page'] = 0;
    data['displayLimit'] = this.state.sizePerPage;
    if (text !== '') {
      data['search'] = text;
      this.setState({ sizePerPage: 50 });
    }
    data['sortKey'] = this.state.sortKey;
    data['sortType'] = this.state.sortType;
    this.setState({ searchText: text });
    this.props.GetMasterProjectAction(data);
  }

  onSortChange(sortKey, sortType) {
    if (this.state && this.state.list && this.state.list.length > 0) {
      this.setState({ fetchRequest: true });
      let data = {};
      data['sortKey'] = sortKey;
      if (sortKey === 'jobNumFormatted') {
        data['sortKey'] = 'jobNumFormatted';
      }

      this.setState({ sortKey: data['sortKey'] });

      if (sortType === 'asc') {
        data['sortType'] = 'ASC';
        sortingOrder = '-asc';
        this.setState({ sortType: 'ASC' });
      } else {
        data['sortType'] = 'DESC';
        sortingOrder = '-desc';
        this.setState({ sortType: 'DESC' });
      }
      data['search'] = this.state.searchText;
      data['accountId'] = this.state.accountId;
      data['page'] = this.state.currentPage - 1;
      data['displayLimit'] = this.state.sizePerPage;
      data['moduleName'] = appConstants.reportModuleName['30'];
      data['startDate'] = startDateFormatWithoutTimeUTCF(this.state.selectDateBox.startDate);
      data['endDate'] = endDateFormatWithoutTimeUTCF(this.state.selectDateBox.endDate);
      this.props.GetMasterProjectAction(data);
    }
  }

  
  buildColumnForRole(filteredRoles, roleCode, hidden = false) {
    const foundRole = filteredRoles.find((role) => role.code == roleCode);
    if(!foundRole)
      return
    const isInternal = foundRole&& foundRole.type === 'internal';

    if (foundRole && isInternal) {
      return (foundRole &&
      {
        Header: foundRole?.shortName ?? roleCode,
          LongHeader: foundRole?.name,
        accessor: 'projectAssignmentsLabel' + roleCode,
        sortType: customSortByKey,
        sortKey: foundRole?.name ?? roleCode,
        Filter: SelectColumnFilter,
        filter: multiSelectFilter,
        width: 60,
        hidden: hidden,
        Cell: (props) => <>{this.formatContactDataInternal('projectAssignmentsMatched' + roleCode, props.row.original)}</>,
      })
    } else {
      return (foundRole &&
      {
        Header: foundRole?.shortName ?? roleCode,
          LongHeader: foundRole?.name,
        accessor: 'projectAssignmentsLabel' + roleCode,
        sortType: customSortByKey,
        sortKey: foundRole?.name ?? roleCode,
        Filter: SelectColumnFilter,
        filter: multiSelectFilter,
        width: 60,
        hidden: hidden,
        Cell: (props) => <>{this.formatContactDataExternal('projectAssignmentsMatched' + roleCode, props.row.original)}</>,
      })
    }
  }

  formatContactDataInternal(cell, row) {
    if (row[cell] && row[cell].length > 0) {
      return formatInternalContactData(row[cell], this.state.contactData);
    } else {
      return <span>-</span>;
    }
  }

  formatContactDataExternal(cell, row) {
    if (row[cell] && row[cell].length > 0) {
      return formatExternalContactData(row[cell], this.state.contactData,false);
    } else {
      return <span>-</span>;
    }
  }



  

  buildReportColumns() {
    const hasContractValPermissions = isAllowed(moduleConstants.VIEWCONTRACTVAL, 'anyAssigned');
    //const hasProfitValPermissions = isAllowed(moduleConstants.VIEWPROFIT, 'anyAssigned');

    const {
      list, allProjectFieldTypes
    } = this.state;

    const dynamicFieldsLoaded = allProjectFieldTypes?.length > 0;

    const defaultFieldCodes = ['BLDGTYPE'];

    const customFieldColumns = dynamicFieldsLoaded ? buildDynamicColumns(allProjectFieldTypes.filter(field => !defaultFieldCodes.includes(field.code))) : [];

    const defaultFields = dynamicFieldsLoaded ? allProjectFieldTypes.filter(field => defaultFieldCodes.includes(field.code)) : [];


    const isLeadType = false;

    const rolesLoaded = this.state.projectRoleTypes.length > 0;

    const defaultRolesCodes = ['EST'];

    
    const dynamicColumnsLoaded = dynamicFieldsLoaded && rolesLoaded;

    
    const enabledRoles = this.state.projectRoleTypes.filter(
      (role) => !defaultRolesCodes.includes(role.code) && (!isLeadType || role.isShowOnLeads)
    );
    const defaultRoles = this.state.projectRoleTypes.filter((role) =>
      defaultRolesCodes.includes(role.code)
    );

    const defaultColumns = [
      {
        Header: 'Job No. ',
        accessor: 'jobNumFormatted',
        width: 70,
        Cell: (props) => this.redirectToProject(props.column.id, props.row.original, props.value),
      },
      {
        Header: 'Alt Job# ',
        accessor: 'altJobNum',
        width: 80,
        Cell: (props) => this.onAltJobNum(props.column.id, props.row.original, props.value),
      },
      {
        Header: 'Job Name',
        accessor: 'jobName',
        width: 220,
        Cell: (props) => (
          <>{this.onProjectName(props.column.id, props.row.original, props.value)}</>
        ),
      },
      {
        Header: 'Log Date ',
        accessor: (row) =>
          row?.logDateIso?.iso
            ? new Date(row.logDateIso.iso)
            : new Date(formatDate('1800-03-03T12:47:40.000Z')),
        id: 'logDateIso',
        width: 95,
        Cell: (props) => onDate(props.column.id, props.row.original, props.value),
        filter: DateColumnFilter,
        allowDateRangeFilter: true,
        sortType: 'datetime',
      },
      {
        Header: 'Start Date',
        accessor: (row) =>
          row?.startDate?.iso
            ? new Date(row.startDate.iso)
            : new Date(formatDate('1800-03-03T12:47:40.000Z')),
        id: 'startDate',
        width: 95,
        Cell: (props) => (
          <>{onDate(props.column.id, props.row.original, props.value)}</>
        ),
        filter: DateColumnFilter,
        allowDateRangeFilter: true,
        sortType: 'datetime',
      },
      {
        Header: 'End Date',
        accessor: (row) =>
          row?.endDate?.iso
            ? new Date(row.endDate.iso)
            : new Date(formatDate('1800-03-03T12:47:40.000Z')),
        id: 'endDate',
        width: 95,
        Cell: (props) => (
          <>{onDate(props.column.id, props.row.original, props.value)}</>
        ),
        filter: DateColumnFilter,
        allowDateRangeFilter: true,
        sortType: 'datetime',
      },
      hasContractValPermissions&&{
        Header: 'Contract Amt.',
        accessor: 'currentContractAmount',
        width: 110,
        Cell: (props) => (
          <>{onCurrency(props.column.id, props.row.original, props.value)}</>
        ),
      },
      this.buildColumnForRole(defaultRoles,'EST'),
      buildColumnForField(defaultFields, 'BLDGTYPE', false),
      {
        Header: 'Status',
        accessor: 'jobStatusCodesName',
        width: 80,
        Filter: SelectColumnFilter,
        Cell: (props) => <>{this.onJobStatus(props.column.id, props.row.original, props.value)}</>,
      },
      {
        Header: 'Ref.',
        accessor: (row)=>(row?.isRef)?'Yes':'No',
        width: 40,
        Filter: SelectColumnFilter,
        Cell: (props) => <>{onBoolean(['isRef'], props.row.original, props.value)}</>,
      },
      {
        Header: 'Site Address',
        accessor: (row) => formatAddressText(row),
        sortType: 'basic',
        width: 100,
        Cell: (props) => (
          <>
            <Link
              target='_blank'
              rel='noopener noreferrer'
              to={{
                pathname: `https://maps.google.com/?q=` + formatAddressText(props.row.original),
              }}
              data-tooltip-content={props.value}
              data-tooltip-id={props.value}
            >
              {props.value}
            </Link>
          </>
        ),
      },
      {
        Header: 'Notes',
        accessor: 'jobNotes',
        hidden: true,
        width: 120,
        Cell: (props) => (
          <>
            <span
              data-tooltip-html={props.value}
              data-tooltip-id={props.column.id + ':' + props.row.id}
            >
              {this.onNoteDetail(props.column.id, props.row.original, props.value)}
            </span>
            <Tooltip
              id={props.column.id + ':' + props.row.id}
              delayShow={1000}
              style={{ color: '#fff !important' }}
              place='bottom'
            />
          </>
        ),
      },
    ];

    let columns = [];
    if (enabledRoles) {
      const extraColumns = enabledRoles.map((role) => (
        this.buildColumnForRole([role], role.code,true)));

      if (columns.length == 0) {
        columns = defaultColumns.filter(cols=>cols?.accessor);
      }

      for (let i in extraColumns) {
        const curColumnsAccessors = columns.map((cols) => cols.accessor);
        if (!curColumnsAccessors.includes(extraColumns[i].accessor)) {
          columns = columns.concat(extraColumns[i]);
        }
      }

      for (let i in customFieldColumns) {
        const curColumnsAccessors = columns.map((cols) => cols.accessor);
        if (!curColumnsAccessors.includes(customFieldColumns[i].accessor)) {
          columns = columns.concat(customFieldColumns[i]);
        }
      }

    } else {
      columns = defaultColumns.filter(cols=>cols?.accessor);
    }

    this.setState({ dynamicColumns: columns });

  }

  
  render() {
  
    const {
      list, allProjectFieldTypes, dynamicColumns
    } = this.state;


    const dynamicFieldsLoaded = allProjectFieldTypes?.length > 0;

    const rolesLoaded = this.state.projectRoleTypes?.length > 0;

    const dynamicColumnsLoaded = dynamicFieldsLoaded && rolesLoaded;

    
    const dynamicColumnsBuilt = dynamicColumnsLoaded && dynamicColumns.length > 0;

    if(dynamicColumnsLoaded && !dynamicColumnsBuilt){
      this.buildReportColumns();
    }
    
    
    const { listPref, SetMasterProjectPrefAction } = this.props;


    return (
      <div>
        <div className='ContactListReport'>
          <TitleComponent data={{ title: `Master Project Log` }} />
          <div>
            <Suspense fallback='loading'>
              <Header props={this.props} />
            </Suspense>
          </div>

          <div className='contactBox'>
            <div className='container'>
              <div className='reportHead no-print'>
                <h2>Master Project Log Report</h2>
                <button className='btn rightalignmentBtn' onClick={() => this.toggle('POST')}>
                  <i className='fa fa-filter'></i> Apply Filters
                </button>
              </div>
              <div>
                <div className='searchbar no-print'>
                  <div className='col-md-12'>
                    <div>
                      <strong>Date: </strong>
                      {masterDateStatus}
                    </div>
                  </div>
                </div>
                {dynamicColumnsBuilt && (
                  <div className='col-md-12'>
                    <div
                      className='table-responsive reportTableSection'
                      ref={(el) => (this.componentRef = el)}
                    >
                      {this.createCustomButtonGroup()}
                      <CustomTable
                        columns={dynamicColumns}
                        list={list}
                        preferences={listPref}
                        className='bid-due-table'
                        onChangeRowSelection={this.onChangeRowSelection}
                        onSavePreferences={(pref) => SetMasterProjectPrefAction(pref)}
                        isLoading={this.state.fetchRequest}
                        canShowRowSelection={false}
                        componentRef={this.componentRef}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {this.state.noteModal && (
            <NotePopUp
              isOpen={this.state.noteModal}
              onClick={this.onNoteModalClose}
              noteInfo={this.state.noteData}
            />
          )}
          <Footer />
        </div>

        <Modal
          backdrop='static'
          keyboard={true}
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggle}>Select Date</ModalHeader>
          <ModalBody>
            <div className='container'>
              <div className='row'>
                <div className='col-md-12'>
                  <span>
                    The report will contain all jobs whose log date falls between the start/end
                    dates selected.
                  </span>
                </div>
              </div>
              <br />
              <div className='row'>
                <div className='col-md-6'>
                  <div className='form-group'>
                    <label htmlFor='startDate'>Start Date</label>
                    <DatePickerWrapper
                      className='form-control'
                      selected={this.state.selectDateBox.startDate}
                      onChange={this.handleDatePicker.bind(this, 'startDate')}
                      dateFormat={appConstants.DATEFORMATPICKER}
                      required={true}
                      placeholderText='Click to select a date'
                      selectsStart
                      todayButton='Today'
                      showMonthDropdown
                    />
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='form-group'>
                    <label htmlFor='endDate'>End Date</label>
                    <DatePickerWrapper
                      className='form-control'
                      selected={this.state.selectDateBox.endDate}
                      onChange={this.handleDatePicker.bind(this, 'endDate')}
                      dateFormat={appConstants.DATEFORMATPICKER}
                      required={true}
                      placeholderText='Click to select a date'
                      selectsEnd
                      todayButton={this.state.todayButton}
                      showMonthDropdown
                      startDate={formatDateObject(this.state.selectDateBox.startDate)}
                      endDate={formatDateObject(this.state.selectDateBox.endDate)}
                      minDate={formatDateObject(this.state.selectDateBox.startDate).add(1, 'day')}
                      maxDate={formatDateAdd(5, 'months')}
                    />
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button className='btn btn-primary' onClick={this.toggle}>
              Cancel
            </button>
            <button className='btn btn-primary' onClick={(e) => this.filteredData(e)}>
              Next
            </button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    listData: state.reports.masterProjectLogsList,
    listPref: state.reports.masterProjectLogListPref,
    isReport: state.reports.isReport,
    isReportError: state.reports.isReportError,
    projectRoles: state.projectRoleType,
    projectFields: state.projectFields,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    GetMasterProjectAction,
    GetMasterProjectPrefAction,
    SetMasterProjectPrefAction,
    getAllProjectRoleAction,
    getAllProjectFieldsAction
  })(MasterProjectLogReport)
);
