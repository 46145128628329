export const configureAggregator = (column: any) => {
  const result = {
    Aggregated: () => null,
    aggregator: null,
  } as any;

  if (
    column.type === 'customField' &&
    (column.customFieldDefinition?.controlType === 'NUMBER_INPUT' ||
      column.customFieldDefinition?.controlType === 'RATE')
  ) {
    const aggregation = column.customFieldDefinition?.valueDefinition?.aggregation;

    switch (aggregation) {
      case 'none': {
        result.aggregate = undefined;
        result.Aggregated = () => null;
        break;
      }
      case 'sum': {
        result.aggregate = 'sum';
        result.Aggregated = ({ value }: any) => {
          return <div className='text-end'>{`Total: ${value}`}</div>;
        };
        break;
      }
      case 'average': {
        result.aggregate = 'average';
        result.Aggregated = ({ value }: any) => {
          return <div className='text-end'>{`Avg: ${Math.round(value * 100) / 100}`}</div>;
        };
        break;
      }
      case 'count': {
        result.aggregate = 'count';
        result.Aggregated = ({ value }: any) => {
          return <div className='text-end'>{`Count: ${value}`}</div>;
        };
        break;
      }
      case 'min': {
        result.aggregate = 'min';
        result.Aggregated = ({ value }: any) => {
          return <div className='text-end'>{`Min: ${value}`}</div>;
        };
        break;
      }
      case 'max': {
        result.aggregate = 'max';
        result.Aggregated = ({ value }: any) => {
          return <div className='text-end'>{`Max: ${value}`}</div>;
        };
        break;
      }
      default: {
        result.aggregate = undefined;
        result.Aggregated = () => null;
        break;
      }
    }
  }

  if (column.type === 'number') {
    if (column?.valueDefinition?.isCurrency) {
      result.aggregate = 'sum';
      result.Aggregated = ({ value }: any) => {
        return (
          <div className='text-end ms-auto'>{`Total: ${value.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: 0,
          })}`}</div>
        );
      };
    } else {
      result.aggregate = 'average';
      result.Aggregated = ({ value }: any) => {
        return <div className='text-end'>{`Avg: ${Math.round(value * 100) / 100}`}</div>;
      };
    }
  }

  return result;
};
