import { Dropdown as AntDropdown, MenuProps } from 'antd';
import { useRef } from 'react';

type DropdownProps = {
  items: MenuProps['items'];
  children: React.ReactNode;
};

export const Dropdown = ({ items = [], children }: DropdownProps) => {
  const containerRef = useRef(null) as unknown as React.MutableRefObject<HTMLDivElement>;

  return (
    <div ref={containerRef} data-testid='Dropdown' onClick={(e) => e.stopPropagation()}>
      <AntDropdown
        disabled={!items.length}
        trigger={['click']}
        getPopupContainer={() => containerRef.current}
        overlayStyle={{ minWidth: 240 }}
        placement='bottom'
        menu={{ items }}
        autoAdjustOverflow={true}
      >
        <div className='ant-dropdown-trigger'>{children}</div>
      </AntDropdown>
    </div>
  );
};
