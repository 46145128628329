import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { disableAdminSetting, disableUserSetting } from '../../../modules/settings';
import {
  selectDisableAdminSetting,
  selectDisableUserSetting, selectEnableAdminSetting, selectEnableUserSetting
} from '../../../modules/settings/selectors';

const ActionModal = ({
  isOpen = false,
  onClose = () => {},
  rowData = { name: '', code: '', definition: '', trash: false },
  modelName = '',
  afterSubmit = () => {},
  isSuperAdmin = false,
}) => {
  const dispatch = useDispatch();
  if (!rowData) return null;

  const { name, trash, _id } = rowData;

  const actionType = trash ? 'enable' : 'disable';

  const submit = () => {
    dispatch(
      isSuperAdmin ? disableAdminSetting([modelName, _id]) : disableUserSetting([modelName, _id])
    )
      .unwrap()
      .then(onClose)
      .then(afterSubmit);
  };

  const { loading: enabling } = useSelector(selectEnableUserSetting(_id));
  const { loading: disabling } = useSelector(selectDisableUserSetting(_id));
  const { loading: adminEnabling} = useSelector(selectEnableAdminSetting(_id));
  const { loading: adminDisabling } = useSelector(selectDisableAdminSetting(_id));

  const loading = enabling || disabling || adminEnabling || adminDisabling;

  return (
    <Modal backdrop='static' isOpen={isOpen} toggle={onClose}>
      <ModalHeader toggle={onClose}>Confirm your action</ModalHeader>
      <ModalBody>
        <div>
          <span>{`Are you sure to ${actionType} `}</span>
          <strong>{`${name ?? '<No Name>'}?`}</strong>
        </div>
      </ModalBody>
      <ModalFooter>
        <button onClick={onClose} className='btn btn-primary'>
          Cancel
        </button>
        <button onClick={submit} disabled={loading} className='btn btn-primary'>
          Confirm
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default ActionModal;
