import _ from 'lodash';
import renderHTML from 'html-react-parser';
import { useConfirm } from '../../shared/confirmation-dialog';
import { useState, useEffect, useMemo, useCallback } from 'react';
import {
  enableWidget,
  disableWidget,
  reorderWidget,
  resetWidgetsOrder,
} from '../../modules/widgets';
import { getAllWidgetPreferencesAction } from '../../actions/widget.js';
import { selectAllWidgetsOptions } from '../../selectors/widget.js';
import { useAppDispatch } from '../../shared/hooks/use-app-dispatch';
import { useAppSelector } from '../../shared/hooks/use-app-selector';
import { SettingsCodesLayoutNext } from '../../layouts/settings-codes-layout/settings-codes-layout-next';
import styles from './styles.module.scss';

export const DashboardPage = () => {
  const confirm = useConfirm();
  const dispatch = useAppDispatch();

  const { widgetPreferences } = useAppSelector(selectAllWidgetsOptions);

  const [userId] = useState(localStorage.getItem('userId'));
  const [accountId] = useState(localStorage.getItem('accountId'));

  useEffect(() => {
    refreshTable();
  }, [accountId, userId]);

  const onReset = useCallback(async () => {
    await dispatch(resetWidgetsOrder());
    refreshTable();
  }, []);

  const refreshTable = () => dispatch(getAllWidgetPreferencesAction({ accountId, userId }));

  const onDragEnd = useCallback(async (result) => {
    const { destination, item } = result;

    await dispatch(reorderWidget({ id: item.objectId, index: destination.index }));

    refreshTable();
  }, []);

  const onToggle = useCallback(async (trash, rowValue) => {
    const id = rowValue.objectId;

    if (trash) {
      const ok = await confirm({
        title: 'Disabling Widget',
        question: 'Are you sure you want to disable this widget?',
      });

      if (!ok) return;

      await dispatch(disableWidget({ id })).unwrap();
    } else {
      await dispatch(enableWidget({ id })).unwrap();
    }

    refreshTable();
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: 'Widget Name',
        accessor: 'widgetName',
        width: 400,
      },
      {
        Header: 'Description',
        accessor: 'widgetDescription',
        width: 400,
        Cell: ({ value }: { value: any }) => (
          <span className={`text-truncate ${styles.cell}`}>{renderHTML(value)}</span>
        ),
      },
    ],
    []
  );

  const additionalButtons = useMemo(() => [{ label: 'Reset', onClick: onReset }], []);

  const rows = useMemo(() => {
    return _.cloneDeep(widgetPreferences).map((widget: any) => {
      widget.trash = !widget.enabled;
      return widget;
    });
  }, [widgetPreferences]);

  return (
    <>
      <div className='row'>
        <SettingsCodesLayoutNext
          tableTitle='Dashboard Preferences'
          columns={columns}
          rows={rows}
          loading={false}
          onToggle={onToggle}
          onDragEnd={onDragEnd}
          additionalButtons={additionalButtons}
        />
      </div>
    </>
  );
};

export default DashboardPage;
