import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { buildDefaultActionResult } from '../../utils/redux';
import {
  confirmPhoneNumberRequest,
  getUserInfoRequest,
  updateUserInfoRequest,
  verifyPhoneNumberRequest,
  getVerifyInformationRequest,
  cancelVerifyPhoneNumberRequest,
  disablePhoneActivationRequest,
} from './api';
import { notification } from 'antd';

export const getUserInfo = createAsyncThunk('users/getUserInfo', async (id: string) => {
  const result = await getUserInfoRequest(id);

  return result.data;
});

export const disablePhoneActivation = createAsyncThunk('users/disablePhoneActivation', async () => {
  const result = await disablePhoneActivationRequest();

  return result;
});

export const updateUserInfo = createAsyncThunk('users/updateUserInfo', async ({ data }: any) => {
  const result = await updateUserInfoRequest(data);

  return result;
});

export const verifyPhoneNumber = createAsyncThunk(
  'users/verifyPhoneNumber',
  async ({ phone }: { phone: any }) => {
    const result = await verifyPhoneNumberRequest(phone);

    notification.success({
      message: 'Verification link sent',
    });

    return result;
  }
);

export const confirmPhoneNumber = createAsyncThunk(
  'users/confirmPhoneNumber',
  async ({ token }: { token: string }) => {
    const result = await confirmPhoneNumberRequest(token);

    return result;
  }
);

export const getVerifyInformation = createAsyncThunk(
  'users/getVerifyInformation',
  async ({ token }: { token: string }) => {
    const result = await getVerifyInformationRequest(token);

    return result;
  }
);

export const cancelVerifyPhoneNumber = createAsyncThunk(
  'users/cancelVerifyPhoneNumber',
  async ({ token }: { token: string }) => {
    const result = await cancelVerifyPhoneNumberRequest(token);

    return result;
  }
);

const settingsSlice = createSlice({
  name: 'users',
  reducers: {},
  initialState: {},
  extraReducers: (builder) => {
    buildDefaultActionResult('getUserInfo', getUserInfo, builder);
    buildDefaultActionResult('updateUserInfo', updateUserInfo, builder);
  },
});

export default settingsSlice.reducer;
