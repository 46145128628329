import apiClient from '../../utils/api-client';
import { postRequest } from './../helper';

export const createJobStatus = (data) => apiClient.post(`/parse/functions/addMaster`, data);

export const getAllJobStatus = (data) => apiClient.post('/parse/functions/viewMasters', data);

export const updateJobStatus = (data) => apiClient.post('/parse/functions/addMaster', data);

export const deleteJobStatus = (data) => postRequest('functions/deleteMasters', data);

export const listImportableJobStatus = (data) => postRequest('functions/listImportableJobStatus',data);

export const copyJobStatusCode = (data) => postRequest('functions/copyJobStatusCode',data); 