import React, { forwardRef, useMemo, useState } from 'react';
import { useRef } from 'react';
import { useTable } from 'react-table';
import { FixedSizeList as List } from 'react-window';
import { getScrollbarWidth } from '../../utils/get-scrollbar-width';
import useEventListener from '../hooks/use-event-listener';
import './data-grid-table.css';

/**
 *
 * @param {{columns: import('react-table').Column<any>[]; data: any[]}} props
 * @returns
 */
const DataGridTable = ({
  columns,
  data,
  useControlledState,
  height = 700,
  itemHeight = 40,
  editTable = false,
  openModal = () => void 0,
}) => {
  const { getTableProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
    useControlledState,
  });

  const outerElementType = useMemo(
    () =>
      forwardRef((props, _) => {
        const ref = useRef(null);

        useEventListener({
          ref,
          on: 'scroll',
          handler: (e) => {
            document.getElementById('sync-header').scrollLeft = e.target.scrollLeft;
          },
        });

        return <div ref={ref} {...props} />;
      }),
    []
  );

  if (!data.length) return null;

  return (
    <>
      <div {...getTableProps()}>
        <div
          id='sync-header'
          style={{
            width: `calc(100% - ${getScrollbarWidth()}px)`,
            overflow: 'hidden',
            borderBottom: '2px solid #00000022',
          }}
        >
          {headerGroups.map((headerGroup) => (
            <div
              className='d-flex align-items-center justify-content-start'
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map((column) => {
                return (
                  <div
                    {...column.getHeaderProps({
                      style: {
                        minWidth: column.width,
                        maxWidth: column.width,
                        display: 'flex',
                        alignContent: 'center',
                        fontSize: 12,
                        paddingLeft: 6,
                      },
                    })}
                  >
                    {column.render('Header')}
                  </div>
                );
              })}
            </div>
          ))}
        </div>

        {
          <List
            itemData={data}
            itemCount={data.length}
            itemSize={itemHeight}
            height={height}
            width={'100%'}
            outerElementType={outerElementType}
          >
            {({ data, index, style }) => {
              const row = rows[index];
              prepareRow(row);

              const rowProps = row.getRowProps({
                style: {
                  ...style,
                  borderBottom: '1px solid #00000022',
                  minWidth: '100%',
                  width: 'unset',
                },
              });

              return (
                <div
                  className={`d-flex align-items-center justify-content-start ${
                    editTable ? 'is-edit-table' : ''
                  }`}
                  {...rowProps}
                  onClick={
                    editTable
                      ? (e) => {
                          openModal(e, row);
                        }
                      : () => {}
                  }
                  title={editTable ? 'Edit contact' : ''}
                >
                  {row.cells.map((cell) => {
                    const cellProps = cell.getCellProps({
                      style: {
                        height: itemHeight,
                        minWidth: cell.column.width,
                        maxWidth: cell.column.width,
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: 12,
                        paddingLeft: 6,
                        overflowX: 'auto',
                        lineHeight: 1.2,
                      },
                    });

                    return (
                      <div {...cellProps} className={`${editTable ? 'is-edit-table-cell' : ''}`}>
                        {cell.render('Cell')}
                      </div>
                    );
                  })}
                </div>
              );
            }}
          </List>
        }
      </div>
    </>
  );
};

export default DataGridTable;
