import * as actionTypes from '../../actions/Admin/actionTypes.js';

const initState = {
  response: [],
  message: '',
  success: '',

  isfetched: false,
  isUpdateStatus: false,

  isForgotPwd: false,
  isForgotPwdSuccess: false,
  isForgotPwdError: false,

  isSetNewPwd: false,
  isSetNewPwdSuccess: false,
  isSetNewPwdError: false,

  isLogin: false,
  isLoginSuccess: false,
  isLoginError: false,
};

export default (state = initState, action = {}) => {
  switch (action.type) {
    case actionTypes.ADMIN_LOGIN_REQUEST:
      return {
        ...state,
        message: '',
        success: '',
        isLogin: false,
      };
    case actionTypes.ADMIN_LOGIN_SUCCESS:
      return {
        ...state,
        isLogin: action.payload.response.result,
        userData: action.payload.response.result.data,
        isLoginSuccess: true,
        success: action.payload.response.result.success,
        message: action.payload.response.result.success
          ? 'You are logged in successfully'
          : action.payload.response.result.msg,
      };

    case actionTypes.ADMIN_LOGIN_ERROR:
      return {
        ...state,
        isLogin: false,
        isLoginError: true,
        success: action.payload.error.result.success,
        message: action.payload.error.result.msg,
      };

    case actionTypes.ADMIN_FORGOT_LOGIN_REQUEST:
      return {
        ...state,
        message: '',
        success: '',
        isForgotPwd: false,
        isForgotPwdSuccess: false,
        isForgotPwdError: false,
      };
    case actionTypes.ADMIN_FORGOT_LOGIN_SUCCESS:
      return {
        ...state,
        isForgotPwd: action.payload.response.result,
        success: action.payload.response.result.success,
        message: action.payload.response.result.success
          ? 'Sent Email Id successfully'
          : action.payload.response.result.msg,
        isForgotPwdSuccess: true,
        isForgotPwdError: false,
      };
    case actionTypes.ADMIN_FORGOT_LOGIN_ERROR:
      return {
        ...state,
        isForgotPwd: false,
        isForgotPwdSuccess: false,
        isForgotPwdError: true,
        success: action.payload.error.success,
        message: action.payload.error.msg,
      };

    // set new password
    case actionTypes.ADMIN_SET_NEW_PASSWORD_REQUEST:
      return {
        ...state,
        message: '',
        success: '',
        isSetNewPwd: false,
        isSetNewPwdSuccess: false,
        isSetNewPwdError: false,
      };
    case actionTypes.ADMIN_SET_NEW_PASSWORD_SUCCESS:
      return {
        ...state,
        isSetNewPwd: action.payload.response.result,
        success: action.payload.response.result.success,
        message: action.payload.response.result.msg,
        isSetNewPwdSuccess: true,
        isSetNewPwdError: false,
      };
    case actionTypes.ADMIN_SET_NEW_PASSWORD_ERROR:
      return {
        ...state,
        isSetNewPwd: false,
        isSetNewPwdSuccess: false,
        isSetNewPwdError: true,
        success: action.payload.error.success,
        message: action.payload.error.msg,
      };

    default:
      return state;
  }
};
