import React, {Suspense} from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Header from '../Layout/Header.js';
import HeaderOnboarding from '../Layout/HeaderOnboarding.js';
import Footer from '../Layout/Footer.js';
import * as Papa from 'papaparse';
import { appConstants } from '../../_constants';
import { createMultipleCompanyAction, skipOnboardingAction } from '../../actions/multipleContact';
import { CSVLink } from 'react-csv';
import TitleComponent from '../Common/TitleComponent';
import csv_icon_upload from '../static/images/csv_icon_upload.png';
import csv_icon_download from '../static/images/csv_icon_download.png';
import fill_in_icon from '../static/images/fill_in_icon.png';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { importCompaniesParse } from '../../modules/companies';
import { getImportingCompanies } from '../../modules/companies/selectors';
import { FileDropZone } from '../../shared/file-upload';
import { UpperCaseName } from '../../components/Auth/Auth';

var csvHeaders = [
  'Company Name',
  'DBA Name',
  'Company Website',
  'Company Email',
  'Phone Type',
  'Country Code',
  'Phone Number',
  'Tax ID',
  'Company Address1',
  'Company Address2',
  'City',
  'State',
  'Zip Code',
  'Country'
];
var csvData = [];

const ImportCompaniesModal = ({ open, onClose }) => {
  const fileRef = useRef(null);

  const { error, loading } = useSelector(getImportingCompanies);

  const dispatch = useDispatch();
  const history = useHistory();

  const onUploadClick = async () => {
    const formData = new FormData();
    formData.append('companiesCsv', fileRef.current.files[0])
    await dispatch(importCompaniesParse(formData)).unwrap();

    history.push('/import-companies-view')
  }

  const onDrop = (files) => {
      fileRef.current.files = files;
  };

  return (
    <Modal backdrop='static' keyboard={true} isOpen={open} toggle={onClose}>
      <ModalHeader toggle={onClose}>Import Companies</ModalHeader>
      <FileDropZone onFileDrop={onDrop}>
        <ModalBody>
          <div className={'alert alert-danger ' + (!error ? 'd-none' : '')}>
            <strong>Error While uploading! </strong>
          </div>
          <input
            className='csv-input'
            type='file'
            name='files'
            id='files'
            ref={fileRef}
          />
        </ModalBody>
      </FileDropZone>

      <ModalFooter>
        <Button className='btn btn-primary' onClick={onClose}>
          Cancel
        </Button>{' '}
        <Button className='btn btn-primary' disabled={loading} onClick={onUploadClick}>
          {loading ? 'Loading ...' : 'Upload'}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

// looks awfully
class OnboardCompanyAdd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      modalError: false,
      data: {},
      formData: [],
      sessionToken: localStorage.getItem('sessionToken'),
      userId: localStorage.getItem('userId'),
      accountId: localStorage.getItem('accountId'),
      successUploading: true,
      errorUploading: true,
      counter: -1,
      multipleContact: [],
      toggleErrors: [],
      userInfo: JSON.parse(localStorage.getItem('userInfo')),
      onboardingStatus: parseInt(localStorage.getItem('onboardingStatus'), 10),
    };
    this.toggle = this.toggle.bind(this);
    this.toggleError = this.toggleError.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.isOnboarding !== this.props.isOnboarding) {
      this.props.history.push({
        pathname: '/import-contacts',
      });
    }
    if (nextProps.isOnboardingError !== this.props.isOnboardingError) {
      this.props.history.push({
        pathname: '/import-contacts',
      });
    }
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
      successUploading: true,
      errorUploading: true,
    });
  }

  toggleError() {
    this.setState({
      modalError: !this.state.modalError,
      successUploading: true,
      errorUploading: true,
    });
  }

  uploadCompaniesCSV = () => {
    let sheetData = [];
    let _this = this;
    this.setState({
      counter: -1,
    });
    const file = this.fileUpload.files[0];
    //console.log(file);
    if (file) {
      Papa.parse(file, {
        quotes: false, //or array of booleans
        quoteChar: '"',
        escapeChar: '"',
        delimiter: ',',
        header: false,
        newline: '\r\n',
        skipEmptyLines: 'greedy', //other option is 'greedy', meaning skip delimiters, quotes, and whitespace.
        columns: null, //or array of strings
        step: this.addContact,
        complete: function (results) {
          for (let i = 0; i < results.data.length; i++) {
            // console.log("results.data[i]",results.data[i][0]!=="")
            console.log('results.data[i]', results.data[i]);
            if (results.data[i].length > 2) {
              if (results.data[i] !== '') {
                sheetData.push(results.data[i]);
              }
            } else if (results.data[i][0] !== '') {
              let res = results.data[i][0].trim();
              sheetData.push(res);
            }
          }
          let headers = [
            'Company Name',
            'DBA Name',
            'Company Website',
            'Company Email',
            'Tax ID',
            'Phone Type',
            'Country Code',
            'Phone Number',
            'Company Address1',
            'Company Address2',
            'City',
            'State',
            'Zip Code',
            'Country',
          ];
          _this.importCompany(sheetData, headers);
        },
      });
    }
  };

  cancelButton() {
    if (this.props.location.state.redirectOn) {
      this.props.history.push({
        pathname: this.props.location.state.redirectOn,
        //state:{"row":row}
      });
    }
  }

  backToTarget = (path) => {
    this.props.history.push(path);
  };

  cancelButton() {
    if (this.props.location.state.redirectOn) {
      this.props.history.push({
        pathname: this.props.location.state.redirectOn,
        //state:{"row":row}
      });
    }
  }

  NextStep = (e) => {
    e.preventDefault();
    let data = {
      userId: this.state.userId,
      status: 2,
    };
    this.props.skipOnboardingAction(data);
  };

  importCompany(data, headers) {
    let dbHeaders = [
      'companyName',
      'companyWebsite',
      'companyEmail',
      'taxId',
      'phoneType',
      'phoneExtension',
      'phoneNumber',
      'companyAddress1',
      'companyAddress2',
      'city',
      'state',
      'zipCode',
      'country'
    ];
    let sendDataArr = [];
    let sendData = [];
    let hError;
    for (var a = 0; a < headers.length; a++) {
      if (headers[a] === data[0][a]) {
        hError = false;
      } else {
        hError = true;
        this.toggle();
        this.toggleError();
        return false;
      }
    }
    if (hError !== true) {
      for (var i = 0; i < data.length; i++) {
        let obj = {};
        obj.phoneArr = [];
        let phoneContactArr = {};
        for (var z = 0; z < headers.length; z++) {
          if (i !== 0) {
            if (headers[z] === 'Phone Type') {
              for (let y in appConstants.NumberTypeOption) {
                if (appConstants.NumberTypeOption[y].name === data[i][z]) {
                  phoneContactArr.type = appConstants.NumberTypeOption[y].name;
                }
              }
              if (!phoneContactArr.type) {
                phoneContactArr.type = 'Work';
              }
            }
            if (headers[z] === 'Country Code') {
              for (let v in appConstants.CountryCodeOption) {
                if (appConstants.CountryCodeOption[v].name === data[i][z]) {
                  phoneContactArr.ext = appConstants.CountryCodeOption[v].name;
                }
              }
              if (!phoneContactArr.ext) {
                phoneContactArr.ext = '+1';
              }
            }
            if (headers[z] === 'Phone Number') {
              phoneContactArr.phone = data[i][z];
              obj.phoneArr.push(phoneContactArr);
            }
            obj[dbHeaders[z]] = data[i][z];
            if (headers[z] === 'Company Name') {
              obj[dbHeaders[z]] = UpperCaseName(data[i][z]);
            }
          }
        }
        if (obj.companyName) {
          obj['indexid'] = i - 1;
          sendData.push(obj);
          if (sendData.length !== 0) {
            sendDataArr.push(sendData);
          }
        }
      }
    } else {
      this.toggle();
      this.toggleError();
    }
    if (hError !== true) {
      this.props.history.push({
        pathname: '/upload-data-view',
        state: {
          row: sendData,
          redirectOn:
            this.props.location && this.props.location.state && this.props.location.state.redirectOn
              ? this.props.location.state.redirectOn
              : '',
        },
      });
    }
  }

  render() {
    const { location } = this.props;
    return (
      <Suspense fallback="loading">
      <div className='ContactListReport'>
        <TitleComponent data={{ title: `Import Companies` }} />
        {(this.state.onboardingStatus > 1 ||true) && <Suspense fallback="loading"><Header props={this.props} /></Suspense>}
        {/*(this.state.onboardingStatus === 0 || this.state.onboardingStatus === 1) && (
          <HeaderOnboarding props={this.props} />
        )*/}
        <div className='container'>
          <div className='addContact onboarding-section'>
            <div className='contactBox'>
              <div className='row'>
                <div className='col-sm-12'>
                  {/*(this.state.onboardingStatus === 0 || this.state.onboardingStatus === 1) && (
                    <div className='onboardingSkip-bt col-sm-1'>
                      <Button onClick={(e) => this.NextStep(e)}> Skip</Button>
                    </div>
                  )*/}

                  {location && location.state && location.state.redirectOn && (
                    <div className='onboardingSkip-bt '>
                      <Button onClick={(e) => this.cancelButton(e)}> Cancel</Button>
                    </div>
                  )}
                </div>
                <div className='col-md-6'>
                  <div className='pageinfo'>
                    <h2>Import Companies</h2>
                    <p>
                      You can import your customers, prospects, and venders in bulk using a CSV
                      file. <br /> First, download the provided template and use it to fill in the
                      companies that you work with on a regular basis. You can also add companies
                      individually through the normal website or return to this page at any time to
                      continue the import process.
                    </p>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-md-4'>
                  <div className='Colbox onboarding-box'>
                    <p className='text-center'>
                      <h4>DOWNLOAD</h4>
                    </p>
                    <div className='textdesc'>
                      <p className='text-center'>
                        {' '}
                        <CSVLink
                          headers={csvHeaders}
                          data={csvData}
                          filename={'addCompany.csv'}
                          className=''
                        >
                          <img
                            src={csv_icon_download}
                            className='rounded mx-auto d-block'
                            alt='download-csv'
                            width='100'
                            height='120'
                          ></img>
                          <br></br>Download the provided template (CSV)
                        </CSVLink>
                        <br />
                        <br />
                        Use the preformatted table to help arrange your data
                      </p>
                    </div>
                  </div>
                </div>
                <div className='col-md-4'>
                  <div className='Colbox onboarding-box'>
                    <p className='text-center'>
                      <h4>FILL IN</h4>
                    </p>
                    <div className='textdesc'>
                      <p className='text-center'>
                        {' '}
                        <img
                          src={fill_in_icon}
                          className='rounded mx-auto d-block'
                          alt='fill-in-csv'
                          width='100'
                          height='120'
                        ></img>
                        <br></br>
                        <br />
                        <br />
                        Cut and paste your vender companies and their details
                      </p>
                    </div>
                  </div>
                </div>
                <div className='col-md-4'>
                  <div className='Colbox onboarding-box'>
                    <p className='text-center'>
                      <h4>UPLOAD</h4>
                      <div className='form-group'>
                        <span className='fileHyperLink' onClick={this.toggle}>
                          <img
                            src={csv_icon_upload}
                            className='rounded mx-auto d-block'
                            alt='upload-csv'
                            width='100'
                            height='120'
                          ></img>
                          <br></br>
                          Upload completed form
                        </span>
                        <br />
                        <br />
                        Next upload your company list to CommandPost
                      </div>
                    </p>
                    <div className='textdesc'>
                      <p className='text-center'> </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />

        <ImportCompaniesModal open={this.state.modal} onClose={this.toggle} />

        <Modal
          backdrop='static'
          keyboard={true}
          isOpen={this.state.modalError}
          toggle={this.toggleError}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggleError}>Error</ModalHeader>
          <ModalBody>
            <p>Please download the correct sample format file and upload it again</p>
          </ModalBody>

          <ModalFooter>
            <Button className='btn btn-primary' onClick={this.toggleError}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
      </Suspense>
    );
  }
}

function mapStateToProps(state) {
  return {
    allContractType: state.contractType.allContractType,
    onboarding: state.multipleContact.onboarding,
    isOnboarding: state.multipleContact.isOnboarding,
    isOnboardingError: state.multipleContact.isOnboardingError,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    createMultipleCompanyAction,
    skipOnboardingAction,
  })(OnboardCompanyAdd)
);
