import * as actionTypes from '../actions/actionTypes';

const initState = {
  helpData: [],
  message: '',
  isfetched: false,
  isUpdateStatus: false,
  success: null,
  isCreated: false,
  isDeleted: false,
};

export default (state = initState, action = {}) => {
  switch (action.type) {
    case actionTypes.GET_HELP_REQUEST:
      return {
        ...state,
        helpData: [],
        message: '',
        isfetched: false,
        isCreated: false,
      };
    case actionTypes.GET_HELP_SUCCESS:
      return {
        ...state,
        helpData: action.payload.response.result.data,
        message: 'Help already exists',
        isfetched: true,
        isCreated: true,
      };

    case actionTypes.GET_HELP_ERROR:
      return {
        ...state,
        message: '',
        isfetched: false,
        isCreated: false,
      };


    default:
      return state;
  }
};
