import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import Header from '../../Layout/Header.js';
import Footer from '../../Layout/Footer.js';
import { connect } from 'react-redux';
import { appConstants } from '../../../_constants';
import moment from 'moment';
import ReactToPrint from 'react-to-print';
import loaderImage from '../../static/images/loading_spinner.gif';
import bannerImage from '../../static/images/Cobilogo.png';
import ReactLoading from 'react-loading';

import TitleComponent from  "../../Common/TitleComponent";

import { ResourceScheduleModal } from '../../Dialogs';
import { selectGetResourceSchedule, selectGetResourceScheduleFilters } from '../../../modules/reports/selectors';
import { formatDateWithTime,formatDateObject,formatDate,formatDateForCompare,RSR_ComparisonDate } from '../../../utils/date-formatters';
import { formatExternalContactData } from '../../../utils/contact-formatters.jsx';


let dataArray = [];
var tableData = [];
var tableHeader = [];
var monthArray = [];

var count = 0;
var dateArr = [];
var daysArr = [];
var yearArr = [];
var annualArr = [];

class ResourceScheduleReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: localStorage.getItem('userId'),
      list: [],
      filterList:[],
      monthlyExArr: [],
      clientList: {},
      comLogo: '',
      expanding: [],
      currentPage: 1,
      sizePerPage: 10,
      isResourceScheduleModalOpen: false,
      quarterValue: [],
      monthsArr: [],
      customDate: false,
      searchText: '',
      customyears: '',
      selectYear: new Date().getFullYear(),
      customFilterType: '',
      Sdate: '',
      Edate: '',
      durationDate: {},
      hiddenDateBox: false,
    };

    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.dateRange = this.dateRange.bind(this);
    this.displayColorsData = this.displayColorsData.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    dataArray = [];
    tableData = [];

    if (nextProps.resourceList && nextProps.resourceList.formatDate) {
      this.setState({ durationDate: nextProps.resourceList.formatDate });
    }

    if(nextProps.filterList && nextProps.filterList.data) {      
      
      if(this.state.filterList&&this.state.filterList.length==0)
      {
        let nextFilter=nextProps.filterList.data.jobStatusFilter;

        if(nextFilter?.LeadsAndBids){
          this.setState({
            filterList: nextProps.filterList.data.jobStatusFilter,
          });
        }
      }

      
    }

    if (
      nextProps.resourceList &&
      nextProps.resourceList.data &&
      nextProps.resourceList.data.length > 0
    ) {
      dateArr = [];
      daysArr = [];
      yearArr = [];
      annualArr = [];
      this.setState({
        list: nextProps.resourceList.data,
        customFilterType: nextProps.resourceList.yearStatus,
        Sdate: nextProps.resourceList.startDate,
        Edate: nextProps.resourceList.endDate,
        totalCount: nextProps.count,
        quarterValue: nextProps.resourceList.quarter,
      });
    } else {
      this.setState({ list: [] });
      dataArray = [];
      tableData = [];
    }
  }

  UNSAFE_componentWillMount() {
    this.handleResourceScheduleModalToggle();

    if (
      localStorage.getItem('companyLogo') !== 'undefined' &&
      localStorage.getItem('companyLogo') !== null
    ) {
      this.setState({ comLogo: localStorage.getItem('companyLogo') });
    } else {
      let constImage = appConstants.CONTENT_CPOST_LOGO_URL;
      this.setState({ comLogo: constImage });
    }
  }

  handleResourceScheduleModalToggle = () => {
    this.setState((prevState) => ({
      isResourceScheduleModalOpen: !prevState.isResourceScheduleModalOpen,
    }));
  };

  handleChangeInput(e) {
    e.preventDefault();
    let selectYear = this.state.selectYear;
    selectYear = e.target.value;
    this.setState({ selectYear: selectYear });
  }


  


  dataFilter() {
    if (dataArray.length === 0) {
      tableData = [];
      tableHeader = [];
      monthArray = [];

      let months = yearArr;
      let weeks = dateArr;
      let days = daysArr;
      let years = annualArr;

      if (this.state.list.length > 0) {
        dataArray = [...this.state.list];
        dataArray.sort((a, b) => ((a.name===null)-(b.name===null)||a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
        var x = null;
        var y = null;
        var m = null;
        var q = null;
        var d = null;
        var a = null;

        for (x in dataArray) {
          var projects = dataArray[x].projectData;

          for (y in projects) {
            if (
              this.state.customFilterType === 'lastYear' ||
              this.state.customFilterType === 'thisYear' ||
              this.state.customFilterType === 'nextYear' ||
              this.state.customFilterType === 'next12months'
            ) {
              if (months && months.length > 0) {
                monthArray = [];
                var n1 = 0;
                if (n1 === 0) {
                  for (m in months) {
                    if (monthArray.indexOf(months[m].month) === -1) {
                      let obj = {
                        monthName: months[m].month,
                        year: months[m].year,
                        mNum: months[m].mNum,
                        fDate: months[m].fDate,
                        lDate: months[m].lDate,
                      };
                      monthArray.push(obj);
                    }
                  }
                  n1++;
                }
              }
            }

            if (
              this.state.customFilterType === 'lastQuarter' ||
              this.state.customFilterType === 'thisQuarter' ||
              this.state.customFilterType === 'nextQuarter'
            ) {
              if (weeks && weeks.length > 0) {
                monthArray = [];
                var n2 = 0;
                if (n2 === 0) {
                  for (q in weeks) {
                    if (monthArray.indexOf(weeks[q].startDate) === -1) {
                      monthArray.push(weeks[q].startDate);
                    }
                  }
                  n2++;
                }
              }
            }

            if (
              this.state.customFilterType === 'lastMonth' ||
              this.state.customFilterType === 'thisMonth' ||
              this.state.customFilterType === 'nextMonth'
            ) {
              if (days && days.length > 0) {
                monthArray = [];
                var n3 = 0;
                if (n3 === 0) {
                  for (d in days) {
                    if (monthArray.indexOf(days[d].day) === -1) {
                      monthArray.push(days[d].day);
                    }
                  }
                  n3++;
                }
              }
            }

            if (this.state.customFilterType === 'custom') {
              if (years && years.length > 1) {
                monthArray = [];
                var n4 = 0;
                if (n4 === 0) {
                  for (a in years) {
                    if (monthArray.indexOf(years[a].startYear) === -1) {
                      monthArray.push(years[a].startYear);
                    }
                  }
                  n4++;
                }
              }
            }
          }
        }

        var s = null;
        var l = null;
        var w = null;
        var d1 = null;
        tableHeader = [];
        for (s in monthArray) {
          var thead;
          thead = (
            <th>
              {monthArray[s] && monthArray[s].monthName ? monthArray[s].monthName : monthArray[s]}
            </th>
          );
          tableHeader.push(thead);
        }

        let currentYear = this.state.Sdate ? moment(this.state.Sdate.iso).format('YYYY') : '';

        tableData = [];
        var p = null;
        var t = null;
        for (let e in dataArray) {
          let row = dataArray[e].clientData;
          let Subtotals = row.objectId =='notAssigned' ?((
            <td>Jobs Assigned To: Unassigned</td>
          )): (
            <td>
              Jobs Assigned To: &nbsp;&nbsp;
              {formatExternalContactData([row],[row],false,true)}
            </td>
          );
          // var tdProjectManager = <td ><Link to={{ pathname: `/contact-details/`+row.objectId, state: {row} }}>{(dataArray[e].clientData && dataArray[e].clientData.firstName && dataArray[e].clientData.lastName)?
          //    (dataArray[e].clientData.firstName+' '+ dataArray[e].clientData.lastName):''}</Link></td>
          var sm = <tr className="border border2 subtotal">{Subtotals}</tr>;
          tableData.push(sm);

          for (s in dataArray[e].projectData) {
            const dpData = dataArray[e].projectData[s];
            var tdJobName;
            var tdJobStatus;
            var tdStartDate;
            var tdEndDate;
            var tdOriginalEndDate;

            let deliveredDate;
           
            let row = dpData;
          

            const projectStartDate = dpData?.startDate?.iso&&new Date(
              formatDate(dpData.startDate.iso)
            );
            const projectEndDate = dpData?.endDate?.iso&&new Date(
              formatDate(dpData.endDate.iso)
            );
            const projectStatusName = dpData.jobStatusCodesName;
            const projectStatusCode = dpData.jobStatusCodesCode;

            let curJobCode = projectStatusCode;

            let suspendedData = dpData.extraData;
            if (
              dpData?.deliveredDate?.iso
            )
              deliveredDate = new Date(
                formatDate(dpData.deliveredDate.iso)
              );

            tdJobName = (
              <td>
                <Link
                  to={{
                    pathname: `/project-details/` + row.objectId,
                    state: { row },
                  }}
                >
                  {dpData.jobName ? dpData.jobName : '-'}
                </Link>
              </td>
            );
            tdJobStatus = <td>{projectStatusName}</td>;
            tdStartDate = <td>{projectStartDate&&formatDate(projectStartDate)}</td>;
            tdEndDate = <td>{projectEndDate&&formatDate(projectEndDate)}</td>;

            if (deliveredDate)
              tdOriginalEndDate = <td>{deliveredDate&&formatDate(deliveredDate)}</td>;
            else tdOriginalEndDate = <td>-</td>;

            let suspendedDate = [];
            let start = null;
            let end = null;
            let code = null;
            let suspendedEndDate;

            for (p in suspendedData) { //Suspended dates are treated differently for some reason
              if (suspendedData[p].jobStatusCodesCode === 'SUSP') {
                suspendedDate.push({
                  startDate: suspendedData[p]['createdAt'],
                  endDate: suspendedData[parseInt(p) + 1]
                    ? suspendedData[parseInt(p) + 1]['createdAt']
                    : new Date(),
                });
              }
            }
            var jkhn = []; // An array?
            let afterProjectPercentage = 0;
            let beforeProjectPercentage = 0;
            let projectPercentage = 0;
            let endDatePercentage = 0;
            if (
              this.state.customFilterType === 'lastYear' ||
              this.state.customFilterType === 'thisYear' ||
              this.state.customFilterType === 'nextYear' ||
              this.state.customFilterType === 'next12months'
            ) {
              if (monthArray.length > 0) {
                for (l in monthArray) {
                  var monthFirstDay = monthArray[l].fDate;
                  var monthLastDay = monthArray[l].lDate;
                  let monthsDays = monthLastDay.getDate() - monthFirstDay.getDate() + 1;
                  var s;
                  if (
                    (projectStartDate < monthFirstDay && projectEndDate > monthFirstDay) ||
                    (projectStartDate < monthLastDay && projectEndDate > monthLastDay) ||
                    (projectStartDate > monthFirstDay && projectStartDate < monthLastDay) ||
                    (projectEndDate > monthFirstDay && projectEndDate < monthLastDay)
                  ) {
                    if (projectStartDate < monthFirstDay && projectEndDate > monthLastDay) {
                      projectPercentage = 100;
                      beforeProjectPercentage = 0;
                      afterProjectPercentage = 0;
                    } else if (
                      projectStartDate > monthFirstDay &&
                      projectEndDate > monthLastDay &&
                      projectStartDate < monthLastDay
                    ) {
                      let dayDiff = monthLastDay.valueOf() - projectStartDate.valueOf();
                      let dayValue = Math.round((dayDiff + 86400000) / 86400000);
                      projectPercentage = (100 * dayValue) / monthsDays;
                      beforeProjectPercentage = 100 - projectPercentage;
                      afterProjectPercentage = 0;
                    } else if (
                      projectStartDate < monthFirstDay &&
                      projectEndDate < monthLastDay &&
                      projectEndDate > monthFirstDay
                    ) {
                      projectPercentage = (100 * projectEndDate.getDate()) / monthsDays;
                      beforeProjectPercentage = 0;
                      afterProjectPercentage = 100 - projectPercentage;
                    } else if (projectStartDate > monthFirstDay && projectEndDate < monthLastDay) {
                      let dayDiff = projectEndDate.valueOf() - projectStartDate.valueOf();
                      let dayValue = Math.round((dayDiff + 86400000) / 86400000);
                      projectPercentage = (100 * dayValue) / monthsDays;
                      beforeProjectPercentage = (100 * projectStartDate.getDate()) / monthsDays;
                      afterProjectPercentage = 100 - (projectPercentage + beforeProjectPercentage);
                    }

                    let suspendedPercent = 0;
                    let beforeSuspendedPercent = 0;
                    let afterSuspendedPercent = 0;
                    let lastDatePercent = 0;
                    let startDatePercent = 0;

                    for (t in suspendedDate) {
                      let suspendStartDate = new Date(suspendedDate[t].startDate);
                      let suspendEndDate = new Date(suspendedDate[t].endDate);

                      if (suspendStartDate < monthFirstDay && suspendEndDate > monthLastDay) {
                        suspendedPercent = 100;
                        beforeSuspendedPercent = 0;
                        afterSuspendedPercent = 0;
                      } else if (
                        suspendStartDate > monthFirstDay &&
                        suspendEndDate > monthLastDay &&
                        suspendStartDate < monthLastDay
                      ) {
                        let dayDiff = monthLastDay.valueOf() - suspendStartDate.valueOf();
                        let dayValue = Math.round((dayDiff + 86400000) / 86400000);
                        suspendedPercent = (100 * dayValue) / monthsDays;
                        beforeSuspendedPercent = 100 - suspendedPercent;
                        afterSuspendedPercent = 0;
                      } else if (
                        suspendStartDate < monthFirstDay &&
                        suspendEndDate < monthLastDay &&
                        suspendEndDate > monthFirstDay
                      ) {
                        suspendedPercent = (100 * suspendEndDate.getDate()) / monthsDays;
                        beforeSuspendedPercent = 0;
                        afterSuspendedPercent = 100 - suspendedPercent;
                      } else if (
                        suspendStartDate > monthFirstDay &&
                        suspendEndDate < monthLastDay
                      ) {
                        let dayDiff = suspendEndDate.valueOf() - suspendStartDate.valueOf();
                        let dayValue = Math.round((dayDiff + 86400000) / 86400000);
                        suspendedPercent = (100 * dayValue) / monthsDays;
                        beforeSuspendedPercent = (100 * suspendStartDate.getDate()) / monthsDays;
                        afterSuspendedPercent = 100 - (suspendedPercent + beforeSuspendedPercent);
                      }
                    }

                    if (projectEndDate < monthLastDay) {
                      let dayDiff = monthLastDay.valueOf() - projectEndDate.valueOf();
                      let dayValue = Math.round((dayDiff + 86400000) / 86400000);
                      lastDatePercent = (100 * dayValue) / monthsDays;
                      afterSuspendedPercent -= lastDatePercent;
                    } else if (projectEndDate.getDate() === monthLastDay.getDate()) {
                      let dayDiff = monthLastDay.valueOf() - projectEndDate.valueOf();
                      let dayValue = Math.round(dayDiff / 86400000);
                      lastDatePercent = (100 * dayValue) / monthsDays;
                      projectPercentage = 100 - lastDatePercent;
                      beforeProjectPercentage = 0;
                    }

                    if (projectStartDate > monthFirstDay) {
                      let dayDiff = projectStartDate.valueOf() - monthFirstDay.valueOf();
                      let dayValue = Math.round((dayDiff + 86400000) / 86400000);
                      beforeSuspendedPercent -= (100 * dayValue) / monthsDays;
                      startDatePercent =
                        100 -
                        (suspendedPercent +
                          afterSuspendedPercent +
                          beforeSuspendedPercent +
                          lastDatePercent);
                    } else if (projectStartDate.getDate() === monthFirstDay.getDate()) {
                      let dayDiff = monthLastDay.getDate() - projectStartDate.getDate();
                      let dayValue = Math.round((dayDiff + 86400000) / 86400000);
                      lastDatePercent = (100 * dayDiff) / monthsDays;
                      projectPercentage = 100 - lastDatePercent;
                      projectPercentage = 100;
                    }

                    let biggerThenEnd = true;
                    let beforeEndPercent = 0;
                    let beforeEndStatus = true;
                    let beforeEndStyle = { maxWidth: '3px' };

                    if (deliveredDate !== undefined) {
                      if (
                        projectEndDate > deliveredDate &&
                        deliveredDate < monthLastDay &&
                        deliveredDate > monthFirstDay
                      ) {
                        let dayDiff = monthLastDay.valueOf() - deliveredDate.valueOf();
                        let dayValue = Math.round((dayDiff + 86400000) / 86400000);
                        beforeEndPercent = 100 - (100 * dayValue) / monthsDays;
                        beforeEndStyle['left'] = beforeEndPercent + '%';
                        beforeEndStatus = false;
                      } else if (projectEndDate < deliveredDate) {
                        biggerThenEnd = false;
                        if (
                          projectEndDate < deliveredDate &&
                          deliveredDate <= monthLastDay &&
                          deliveredDate >= monthFirstDay
                        ) {
                          let dayDiff = monthLastDay.valueOf() - deliveredDate.valueOf();
                          let dayValue = Math.round((dayDiff + 86400000) / 86400000);
                          beforeEndPercent = 100 - (100 * dayValue) / monthsDays;
                          beforeEndStyle['left'] = beforeEndPercent + '%';
                          beforeEndStatus = false;
                        } else {
                          biggerThenEnd = true;
                        }
                      } else if (
                        deliveredDate.valueOf() === projectEndDate.valueOf() &&
                        deliveredDate <= monthLastDay &&
                        deliveredDate >= monthFirstDay
                      ) {
                        let dayDiff = projectEndDate.valueOf() - monthFirstDay.valueOf();
                        let dayValue = Math.round((dayDiff + 86400000) / 86400000);
                        beforeEndPercent = (100 * dayValue) / monthsDays;
                        beforeEndStyle['left'] = beforeEndPercent + '%';
                        beforeEndStatus = false;
                      }
                    }

                    

                    if (suspendedPercent > 0) {
                      if (
                        curJobCode
                        // with Precon
                      ) {
                        s = (
                          <td className="colorDivsWrapr position-relative">
                            <div
                              className="endDateColor float_area"
                              hidden={beforeEndStatus}
                              style={beforeEndStyle}
                            ></div>
                            <div className="" style={{ maxWidth: startDatePercent + '%' }}></div>
                            <div className="activeColor" style={{'maxWidth':beforeSuspendedPercent+"%",backgroundColor:this.displayColorsData(curJobCode),height:'10px'}}></div>
                            <div
                              className="suspendedColor"
                              style={{ maxWidth: suspendedPercent + '%' }}
                            ></div>
                           <div className="activeColor" style={{'maxWidth':afterSuspendedPercent+"%",backgroundColor:this.displayColorsData(curJobCode),height:'10px'}}></div>
                          </td>
                        );
                      } else {
                        s = (
                          <td className="colorDivsWrapr position-relative">
                            <div
                              className="endDateColor float_area"
                              hidden={beforeEndStatus}
                              style={beforeEndStyle}
                            ></div>
                            <div
                              className=""
                              style={{
                                maxWidth: beforeProjectPercentage + '%',
                              }}
                            ></div>
                            <div className="bpColor" style={{'maxWidth':projectPercentage+"%",backgroundColor:this.displayColorsData(curJobCode),height:'10px'}}></div>
                            <div
                              className=""
                              style={{ maxWidth: afterProjectPercentage + '%' }}
                            ></div>
                          </td>
                        );
                      }
                    } else {
                      if (
                        curJobCode //without Precon
                      ) {
                        s = (
                          <td className="colorDivsWrapr position-relative">
                            <div
                              className="endDateColor float_area"
                              hidden={beforeEndStatus}
                              style={beforeEndStyle}
                            ></div>
                            <div
                              className=""
                              style={{
                                maxWidth: beforeProjectPercentage + '%',
                              }}
                            ></div>
                            <div className="activeColor" style={{'maxWidth':projectPercentage+"%",backgroundColor:this.displayColorsData(curJobCode),height:'10px'}}></div>

                          </td>
                        );
                      } else {
                        s = (
                          <td className="colorDivsWrapr position-relative">
                            <div
                              className="endDateColor float_area"
                              hidden={beforeEndStatus}
                              style={beforeEndStyle}
                            ></div>
                            <div
                              className=""
                              style={{
                                maxWidth: beforeProjectPercentage + '%',
                              }}
                            ></div>
                           <div className="bpColor" style={{'maxWidth':projectPercentage+"%",backgroundColor:this.displayColorsData([]),height:'10px'}}></div>

                          </td>
                        );
                      }
                    }
                  } else if (
                    (deliveredDate !== undefined &&
                      projectEndDate < deliveredDate &&
                      deliveredDate < monthLastDay &&
                      deliveredDate > monthFirstDay) ||
                    (projectEndDate < deliveredDate &&
                      deliveredDate > monthLastDay &&
                      deliveredDate < monthFirstDay)
                  ) {
                    if (
                      projectEndDate < deliveredDate &&
                      deliveredDate < monthLastDay &&
                      deliveredDate > monthFirstDay
                    ) {
                      let dayDiff = '';
                      if (dayDiff <= 9) {
                        dayDiff = 9;
                      } else {
                        dayDiff = monthLastDay.getDate() - deliveredDate.getDate();
                      }
                      projectPercentage = (100 * dayDiff) / monthsDays;
                      beforeProjectPercentage = 100 - projectPercentage;
                      afterProjectPercentage = 0;
                    } else if (
                      projectEndDate < monthFirstDay &&
                      deliveredDate > monthLastDay &&
                      deliveredDate < monthFirstDay
                    ) {
                      let dayDiff = monthFirstDay.getDate() - deliveredDate.getDate();
                      projectPercentage = (100 * dayDiff) / monthsDays;
                      beforeProjectPercentage = 0;
                      afterProjectPercentage = 100 - projectPercentage;
                    } else if (projectEndDate > monthFirstDay && deliveredDate < monthLastDay) {
                      let dayDiff = projectEndDate.getDate() - projectStartDate.getDate();
                      projectPercentage = (100 * dayDiff) / monthsDays;
                      beforeProjectPercentage = (100 * projectStartDate.getDate()) / monthsDays;
                      afterProjectPercentage = 100 - (projectPercentage + beforeProjectPercentage);
                    }
                    s = (
                      <td className="colorDivsWrapr">
                        <div className="" style={{ maxWidth: beforeProjectPercentage + '%' }}></div>
                        <div
                          className="endDateColor"
                          style={{ maxWidth: projectPercentage + '%' }}
                        ></div>
                        <div className="" style={{ maxWidth: afterProjectPercentage + '%' }}></div>
                      </td>
                    );
                  } else {
                    s = <td></td>;
                  }
                  var dataArrayE = dataArray[e][monthArray[l]];
                  jkhn.push(s);
                }
              }
            }

            if (
              this.state.customFilterType === 'lastQuarter' ||
              this.state.customFilterType === 'thisQuarter' ||
              this.state.customFilterType === 'nextQuarter'
            ) {
              if (monthArray.length > 0) {
                for (w in monthArray) {
                  var monthFirstDay = new Date(dateArr[w].SDate);
                  var monthLastDay = new Date(dateArr[w].endDate);

                  var s;

                  if (
                    (projectStartDate < monthFirstDay && projectEndDate > monthFirstDay) ||
                    (projectStartDate < monthLastDay && projectEndDate > monthLastDay) ||
                    (projectStartDate > monthFirstDay && projectStartDate < monthLastDay) ||
                    (projectEndDate > monthFirstDay && projectEndDate < monthLastDay)
                  ) {
                    let suspendedPercent = 0;
                    let beforeSuspendedPercent = 0;
                    let afterSuspendedPercent = 0;
                    let lastDatePercent = 0;
                    let startDatePercent = 0;

                    for (t in suspendedDate) {
                      let suspendStartDate = new Date(suspendedDate[t].startDate);
                      let suspendEndDate = new Date(suspendedDate[t].endDate);

                      if (suspendStartDate < monthFirstDay && suspendEndDate > monthLastDay) {
                        suspendedPercent = 100;
                        beforeSuspendedPercent = 0;
                        afterSuspendedPercent = 0;
                      } else if (
                        suspendStartDate > monthFirstDay &&
                        suspendEndDate > monthLastDay &&
                        suspendStartDate < monthLastDay
                      ) {
                        let dayDiff = monthLastDay.valueOf() - suspendStartDate.valueOf();
                        let dayValue = Math.round((dayDiff + 86400000) / 86400000);
                        suspendedPercent = (100 * dayValue) / 7;
                        beforeSuspendedPercent = 100 - suspendedPercent;
                        afterSuspendedPercent = 0;
                      } else if (
                        suspendStartDate < monthFirstDay &&
                        suspendEndDate < monthLastDay &&
                        suspendEndDate > monthFirstDay
                      ) {
                        let dayDiff = suspendEndDate.valueOf() - monthFirstDay.valueOf();
                        let dayValue = Math.round((dayDiff + 86400000) / 86400000);
                        suspendedPercent = (100 * dayValue) / 7;
                        beforeSuspendedPercent = 0;
                        afterSuspendedPercent = 100 - suspendedPercent;
                      } else if (
                        suspendStartDate > monthFirstDay &&
                        suspendEndDate < monthLastDay
                      ) {
                        let dayDiff = suspendEndDate.valueOf() - suspendStartDate.valueOf();
                        let startDayDiff = suspendStartDate.valueOf() - monthFirstDay.valueOf();
                        let dayValueD = Math.round((dayDiff + 86400000) / 86400000);
                        let dayValueS = Math.round((startDayDiff + 86400000) / 86400000);
                        suspendedPercent = (100 * dayValueD) / 7;
                        beforeSuspendedPercent = (100 * dayValueS) / 7;
                        afterSuspendedPercent = 100 - (suspendedPercent + beforeSuspendedPercent);
                      }
                    }

                    if (projectEndDate.valueOf() < monthLastDay.valueOf()) {
                      let dayDiff = monthLastDay.valueOf() - projectEndDate.valueOf();
                      let dayValue = Math.round((dayDiff + 86400000) / 86400000);
                      lastDatePercent = (100 * dayValue) / 7;
                      afterSuspendedPercent -= lastDatePercent;
                    } else if (projectEndDate.getDate() === monthLastDay.getDate()) {
                      let dayDiff = monthLastDay.valueOf() - projectEndDate.valueOf();
                      let dayValue = Math.round(dayDiff / 86400000);
                      lastDatePercent = (100 * dayValue) / 7;
                      projectPercentage = 100 - lastDatePercent;
                      beforeProjectPercentage = 0;
                    }
                    if (projectStartDate > monthFirstDay) {
                      let dayDiff = projectStartDate.valueOf() - monthFirstDay.valueOf();
                      let dayValue = Math.round((dayDiff + 86400000) / 86400000);
                      beforeSuspendedPercent -= (100 * dayValue) / 7;
                      startDatePercent =
                        100 -
                        (suspendedPercent +
                          afterSuspendedPercent +
                          beforeSuspendedPercent +
                          lastDatePercent);
                    } else if (projectStartDate.getDate() === monthFirstDay.getDate()) {
                      let dayDiff = monthLastDay.valueOf() - projectEndDate.valueOf();
                      let dayValue = Math.round((dayDiff + 86400000) / 86400000);
                      lastDatePercent = (100 * dayValue) / 7;
                      projectPercentage = 100 - lastDatePercent;
                    }

                    let biggerThenEnd = true;
                    let beforeEndPercent = 0;
                    let beforeEndStatus = true;
                    let beforeEndStyle = { maxWidth: '3px' };

                    if (deliveredDate !== undefined) {
                      if (
                        projectEndDate > deliveredDate &&
                        deliveredDate <= monthLastDay &&
                        deliveredDate >= monthFirstDay
                      ) {
                        // if(projectEndDate.valueOf() === deliveredDate.valueOf()){
                        //       beforeEndStatus = true;
                        //       console.log('********* 1',beforeEndPercent)
                        // }else{
                        let dayDiff = monthLastDay.valueOf() - deliveredDate.valueOf();
                        let dayValue = Math.round((dayDiff + 86400000) / 86400000);
                        beforeEndPercent = 100 - (100 * dayValue) / 7;
                        beforeEndStyle['left'] = beforeEndPercent + '%';
                        beforeEndStatus = false;
                        //}
                      } else if (projectEndDate < deliveredDate) {
                        biggerThenEnd = false;
                        if (
                          projectEndDate < deliveredDate &&
                          deliveredDate <= monthLastDay &&
                          deliveredDate >= monthFirstDay
                        ) {
                          let dayDiff = monthLastDay.valueOf() - deliveredDate.valueOf();
                          let dayValue = Math.round((dayDiff + 86400000) / 86400000);
                          beforeEndPercent = 100 - (100 * dayValue) / 7;
                          beforeEndStyle['left'] = beforeEndPercent + '%';
                          beforeEndStatus = false;
                        } else {
                          biggerThenEnd = true;
                        }
                      } else if (
                        deliveredDate.valueOf() === projectEndDate.valueOf() &&
                        deliveredDate <= monthLastDay &&
                        deliveredDate >= monthFirstDay
                      ) {
                        let dayDiff = projectEndDate.valueOf() - monthFirstDay.valueOf();
                        let dayValue = Math.round((dayDiff + 86400000) / 86400000);
                        beforeEndPercent = (100 * dayValue) / 7;
                        beforeEndStyle['left'] = beforeEndPercent + '%';
                        beforeEndStatus = false;
                      }
                    }

                    if (suspendedPercent > 0) {
                      if (
                        curJobCode //with precon
                      ) {
                        s = (
                          <td className="colorDivsWrapr position-relative">
                            <div
                              className="endDateColor float_area1 quarterSection"
                              hidden={beforeEndStatus}
                              style={beforeEndStyle}
                            ></div>
                            <div className="" style={{ maxWidth: startDatePercent + '%' }}></div>
                            <div className="activeColor" style={{'maxWidth':beforeSuspendedPercent+"%",backgroundColor:this.displayColorsData(curJobCode),height:'10px'}}></div>

                            <div
                              className="suspendedColor"
                              style={{ maxWidth: suspendedPercent + '%' }}
                            ></div>
                            <div className="activeColor" style={{'maxWidth':afterSuspendedPercent+"%",backgroundColor:this.displayColorsData(curJobCode),height:'10px'}}></div>

                          </td>
                        );
                      } else {
                        s = (
                          <td className="colorDivsWrapr position-relative">
                            <div
                              className="endDateColor float_area1 quarterSection"
                              hidden={beforeEndStatus}
                              style={beforeEndStyle}
                            ></div>
                            <div
                              className=""
                              style={{
                                maxWidth: beforeProjectPercentage + '%',
                              }}
                            ></div>
                           <div className="bpColor" style={{'maxWidth':projectPercentage+"%",backgroundColor:this.displayColorsData(curJobCode),height:'10px'}}></div>
 
                            <div
                              className=""
                              style={{ maxWidth: afterProjectPercentage + '%' }}
                            ></div>
                          </td>
                        );
                      }
                    } else {
                      if (
                        curJobCode //without precon
                      ) {
                        s = (
                          <td className="colorDivsWrapr position-relative">
                            <div
                              className="endDateColor float_area1 quarterSection"
                              hidden={beforeEndStatus}
                              style={beforeEndStyle}
                            ></div>
                            <div
                              className=""
                              style={{
                                maxWidth: beforeProjectPercentage + '%',
                              }}
                            ></div>
                            <div
                              className="activeColor"
                              style={{ maxWidth: projectPercentage + '%' }}
                            ></div>
                          </td>
                        );
                      } else {
                        s = (
                          <td className="colorDivsWrapr position-relative">
                            <div
                              className="endDateColor float_area1 quarterSection"
                              hidden={beforeEndStatus}
                              style={beforeEndStyle}
                            ></div>
                            <div
                              className=""
                              style={{
                                maxWidth: beforeProjectPercentage + '%',
                              }}
                            ></div>
                             <div className="bpColor" style={{'maxWidth':projectPercentage+"%",backgroundColor:this.displayColorsData(curJobCode),height:'10px'}}></div>

                          </td>
                        );
                      }
                    }
                  } else if (
                    deliveredDate !== undefined &&
                    ((projectEndDate < deliveredDate &&
                      deliveredDate < monthLastDay &&
                      deliveredDate > monthFirstDay) ||
                      (projectEndDate < deliveredDate &&
                        deliveredDate > monthLastDay &&
                        deliveredDate < monthFirstDay))
                  ) {
                    if (
                      projectEndDate < deliveredDate &&
                      deliveredDate <= monthLastDay &&
                      deliveredDate >= monthFirstDay
                    ) {
                      let dayDiff = monthLastDay.valueOf() - deliveredDate.valueOf();
                      let dayValue = Math.round((dayDiff + 86400000) / 86400000);
                      projectPercentage = (100 * dayValue) / 7;
                      afterProjectPercentage = 100 - projectPercentage;
                      beforeProjectPercentage = 0;
                    } else if (
                      projectEndDate < monthFirstDay &&
                      deliveredDate >= monthLastDay &&
                      deliveredDate <= monthFirstDay
                    ) {
                      let dayDiff = monthLastDay.getDate() - projectEndDate.getDate();
                      let dayValue = Math.round((dayDiff + 86400000) / 86400000);
                      projectPercentage = (100 * dayValue) / 7;
                      afterProjectPercentage = 100 - projectPercentage;
                      beforeProjectPercentage = 0;
                    } else if (projectEndDate > monthFirstDay && deliveredDate < monthLastDay) {
                      let dayDiff = deliveredDate.valueOf() - projectEndDate.valueOf();
                      let startDayDiff = projectEndDate.valueOf() - monthFirstDay.valueOf();
                      let dayValueD = Math.round((dayDiff + 86400000) / 86400000);
                      let dayValueS = Math.round((startDayDiff + 86400000) / 86400000);
                      projectPercentage = (100 * dayValueD) / 7;
                      beforeProjectPercentage = (100 * dayValueS) / 7;
                      afterProjectPercentage = 100 - (projectPercentage + beforeProjectPercentage);
                    }

                    s = (
                      <td className="colorDivsWrapr">
                        <div className="" style={{ maxWidth: beforeProjectPercentage + '%' }}></div>
                        <div
                          className="endDateColor"
                          style={{ maxWidth: projectPercentage + '%' }}
                        ></div>
                        <div className="" style={{ maxWidth: afterProjectPercentage + '%' }}></div>
                      </td>
                    );
                  } else {
                    s = <td></td>;
                  }
                  var dataArrayE = dataArray[e][monthArray[l]];
                  jkhn.push(s);
                }
              }
            }

            if (
              this.state.customFilterType === 'lastMonth' ||
              this.state.customFilterType === 'thisMonth' ||
              this.state.customFilterType === 'nextMonth'
            ) {
              for (d1 in monthArray) {
                var s;
                let projectSDate = formatDateForCompare(projectStartDate);
                let projectEDate = formatDateForCompare(projectEndDate);
                let estimateEDate = deliveredDate
                  ? formatDateForCompare(deliveredDate)
                  : '';
                let monthD = formatDateForCompare(daysArr[d1].startDate);
                let monthsDays = daysArr[d1].day;
                if (projectSDate <= monthD && projectEDate >= monthD) {
                  let suspendedPercent = 0;
                  let beforeSuspendedPercent = 0;
                  let afterSuspendedPercent = 0;

                  for (t in suspendedDate) {
                    let suspendStartDate = formatDateForCompare(
                      suspendedDate[t].startDate
                    );
                    let suspendEndDate = formatDateForCompare(
                      suspendedDate[t].endDate
                    );
                    if (suspendStartDate <= monthD && suspendEndDate >= monthD) {
                      suspendedPercent = 100;
                      beforeSuspendedPercent = 0;
                      afterSuspendedPercent = 0;
                    } else if (suspendStartDate >= monthD && suspendEndDate <= monthD) {
                      let dayDiff = suspendEndDate.getDate() - suspendStartDate.getDate();
                      suspendedPercent = (100 * dayDiff) / monthsDays;
                      beforeSuspendedPercent = (100 * suspendStartDate.getDate()) / monthsDays;
                      afterSuspendedPercent = 100 - (suspendedPercent + beforeSuspendedPercent);
                    }
                  }

                  let biggerThenEnd = true;
                  let beforeEndStatus = true;
                  if (estimateEDate !== undefined) {
                    if (
                      projectEDate >= estimateEDate &&
                      estimateEDate <= monthD &&
                      estimateEDate >= monthD
                    ) {
                      beforeEndStatus = false;
                    } else {
                      biggerThenEnd = false;
                    }
                  }

                  if (suspendedPercent > 0) {
                    if (
                      curJobCode//without precon
                    ) {
                      s = (
                        <td className="colorDivsWrapr">
                          <div className="endDateMColor" hidden={beforeEndStatus}></div>
                          <div className="activeColor" hidden={biggerThenEnd} style={{'maxWidth':beforeSuspendedPercent+"%",backgroundColor:this.displayColorsData(curJobCode),height:'10px'}}></div>

                          <div
                            className="suspendedColor"
                            hidden={biggerThenEnd}
                            style={{ maxWidth: suspendedPercent + '%' }}
                          ></div>
                          <div className="activeColor" hidden={biggerThenEnd} style={{'maxWidth':afterSuspendedPercent+"%",backgroundColor:this.displayColorsData(curJobCode),height:'10px'}}></div>
                                      
                        </td>
                      );
                    } else {
                      s = (
                        <td>
                          <div className="endDateMColor" hidden={beforeEndStatus}></div>
                          <div style={{backgroundColor:this.displayColorsData(curJobCode),height:'10px'}} hidden={biggerThenEnd} ></div>

                        </td>
                      );
                    }
                  } else {
                    if (
                      curJobCode //without precon
                    ) {
                      s = (
                        <td>
                          <div className="endDateMColor" hidden={beforeEndStatus}></div>
                          <div className="activeColor" hidden={biggerThenEnd}></div>
                        </td>
                      );
                    } else {
                      s = (
                        <td>
                          <div className="endDateMColor" hidden={beforeEndStatus}></div>
                          <div className="bpColor" hidden={biggerThenEnd}></div>
                        </td>
                      );
                    }
                  }
                } else if (
                  estimateEDate !== undefined &&
                  projectEDate <= estimateEDate &&
                  estimateEDate <= monthD &&
                  estimateEDate >= monthD
                ) {
                  s = (
                    <td>
                      <div className="endDateMColor"></div>
                    </td>
                  );
                } else {
                  s = <td></td>;
                }
                var dataArrayE = dataArray[e][monthArray[l]];
                jkhn.push(s);
              }
            }

            if (this.state.customFilterType === 'custom') {
              let projectPercent = 0;
              let beforeProjectPercent = 0;
              let afterProjectPercent = 0;

              for (d1 in monthArray) {
                var s;
                let projectSDate = moment(projectStartDate).format('YYYY');
                let projectEDate = moment(projectEndDate).format('YYYY');
                let estimateEDate = deliveredDate ? moment(deliveredDate).format('YYYY') : '';
                // let yearData = annualArr[d1].startYear;

                var yearData = monthArray[d1];
                var monthFirstDay = new Date(yearData, 0, 1);
                var monthLastDay = new Date(yearData, 12, 0);
                let diffM = monthLastDay.valueOf() - monthFirstDay.valueOf();
                let monthsDays = diffM / 86400000;

                if (projectSDate <= yearData && projectEDate >= yearData) {
                  //********************
                  // var timeDiff = Math.abs(projectStartDate.getTime() - projectEndDate.getTime());
                  // var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
                  //*******************

                  if (projectStartDate < monthFirstDay && projectEndDate > monthLastDay) {
                    projectPercentage = 100;
                    beforeProjectPercentage = 0;
                    afterProjectPercentage = 0;
                  } else if (
                    projectStartDate > monthFirstDay &&
                    projectEndDate > monthLastDay &&
                    projectStartDate < monthLastDay
                  ) {
                    // let dayDiff = monthLastDay.getDate() - projectStartDate.getDate();
                    let dayDiff = monthLastDay.valueOf() - projectStartDate.valueOf();
                    let dayValue = Math.round((dayDiff + 86400000) / 86400000);
                    projectPercentage = (100 * dayValue) / monthsDays;
                    beforeProjectPercentage = 100 - projectPercentage;
                    afterProjectPercentage = 0;
                  } else if (
                    projectStartDate < monthFirstDay &&
                    projectEndDate < monthLastDay &&
                    projectEndDate > monthFirstDay
                  ) {
                    //  projectPercentage = 100*projectEndDate.getDate()/monthsDays;
                    let dayDiff = projectEndDate.valueOf() - monthFirstDay.valueOf();
                    let dayValue = Math.round((dayDiff + 86400000) / 86400000);
                    projectPercentage = (100 * dayValue) / monthsDays;
                    beforeProjectPercentage = 0;
                    afterProjectPercentage = 100 - projectPercentage;
                  } else if (projectStartDate > monthFirstDay && projectEndDate < monthLastDay) {
                    let dayDiff = projectEndDate.valueOf() - projectStartDate.valueOf();
                    let dayValue = Math.round((dayDiff + 86400000) / 86400000);
                    projectPercentage = (100 * dayValue) / monthsDays;
                    let dateDiffStoE = projectStartDate.valueOf() - monthFirstDay.valueOf();
                    let dayDiffDate = Math.round((dateDiffStoE + 86400000) / 86400000);
                    beforeProjectPercentage = (100 * dayDiffDate) / monthsDays;
                    afterProjectPercentage = 100 - (projectPercentage + beforeProjectPercentage);
                  }

                  let suspendedPercent = 0;
                  let beforeSuspendedPercent = 0;
                  let afterSuspendedPercent = 0;
                  let lastDatePercent = 0;
                  let startDatePercent = 0;

                  for (t in suspendedDate) {
                    let suspendStartDate = new Date(suspendedDate[t].startDate);
                    let suspendEndDate = new Date(suspendedDate[t].endDate);

                    if (suspendStartDate < monthFirstDay && suspendEndDate > monthLastDay) {
                      suspendedPercent = 100;
                      beforeSuspendedPercent = 0;
                      afterSuspendedPercent = 0;
                    } else if (
                      suspendStartDate > monthFirstDay &&
                      suspendEndDate > monthLastDay &&
                      suspendStartDate < monthLastDay
                    ) {
                      let dayDiff = monthLastDay.valueOf() - suspendStartDate.valueOf();
                      let dayValue = Math.round((dayDiff + 86400000) / 86400000);
                      suspendedPercent = (100 * dayValue) / monthsDays;
                      beforeSuspendedPercent = 100 - suspendedPercent;
                      afterSuspendedPercent = 0;
                    } else if (
                      suspendStartDate < monthFirstDay &&
                      suspendEndDate < monthLastDay &&
                      suspendEndDate > monthFirstDay
                    ) {
                      let dayDiff = suspendEndDate.valueOf() - monthFirstDay.valueOf();
                      let dayValue = Math.round((dayDiff + 86400000) / 86400000);
                      suspendedPercent = (100 * dayValue) / monthsDays;
                      beforeSuspendedPercent = 0;
                      afterSuspendedPercent = 100 - suspendedPercent;
                    } else if (suspendStartDate > monthFirstDay && suspendEndDate < monthLastDay) {
                      let dayDiff = suspendEndDate.valueOf() - suspendStartDate.valueOf();
                      let dayValue = Math.round((dayDiff + 86400000) / 86400000);
                      suspendedPercent = (100 * dayDiff) / monthsDays;
                      let dayDiffStoE = suspendStartDate.valueOf() - monthFirstDay.valueOf();
                      let dayValueStoE = Math.round((dayDiffStoE + 86400000) / 86400000);
                      beforeSuspendedPercent = (100 * dayValueStoE) / monthsDays;
                      afterSuspendedPercent = 100 - (suspendedPercent + beforeSuspendedPercent);
                    }
                  }

                  if (projectEndDate < monthLastDay) {
                    let dayDiff = monthLastDay.valueOf() - projectEndDate.valueOf();
                    let dayValue = Math.round((dayDiff + 86400000) / 86400000);
                    lastDatePercent = (100 * dayValue) / monthsDays;
                    afterSuspendedPercent -= lastDatePercent;
                  }

                  if (projectStartDate > monthFirstDay) {
                    let dayDiff = projectStartDate.valueOf() - monthFirstDay.valueOf();
                    let dayValue = Math.round((dayDiff + 86400000) / 86400000);
                    beforeSuspendedPercent -= (100 * dayValue) / monthsDays;
                    startDatePercent =
                      100 -
                      (suspendedPercent +
                        afterSuspendedPercent +
                        beforeSuspendedPercent +
                        lastDatePercent);
                  }

                  let biggerThenEnd = true;
                  let beforeEndPercent = 0;
                  let beforeEndStatus = true;
                  let beforeEndStyle = { maxWidth: '3px' };

                  if (deliveredDate !== undefined) {
                    if (
                      projectEndDate > deliveredDate &&
                      deliveredDate < monthLastDay &&
                      deliveredDate > monthFirstDay
                    ) {
                      if (projectEndDate.getDate() === deliveredDate.getDate()) {
                        beforeEndStatus = true;
                      } else {
                        let dayDiff = monthLastDay.valueOf() - deliveredDate.valueOf();
                        let dayValue = Math.round((dayDiff + 86400000) / 86400000);
                        beforeEndPercent = 100 - (100 * dayValue) / monthsDays;
                        beforeEndStyle['left'] = beforeEndPercent + '%';
                        beforeEndStatus = false;
                      }
                    } else if (projectEndDate < deliveredDate) {
                      biggerThenEnd = false;
                      if (projectEndDate.getDate() === deliveredDate.getDate()) {
                        let dayDiff = monthLastDay.valueOf() - deliveredDate.valueOf();
                        let dayValue = Math.round((dayDiff + 86400000) / 86400000);
                        beforeEndPercent = 100 - (100 * dayValue) / monthsDays;
                        beforeEndStyle['left'] = beforeEndPercent + '%';
                        beforeEndStatus = false;
                      } else {
                        biggerThenEnd = true;
                      }
                    }
                  }

                  if (suspendedPercent > 0) {
                    if (
                      curJobCode //without precon
                    ) {
                      s = (
                        <td className="colorDivsWrapr position-relative">
                          <div
                            className="endDateColor float_area"
                            hidden={beforeEndStatus}
                            style={beforeEndStyle}
                          ></div>
                          <div className="" style={{ maxWidth: startDatePercent + '%' }}></div>
                          <div className="activeColor" style={{'maxWidth':beforeSuspendedPercent+"%",backgroundColor:this.displayColorsData(curJobCode),height:'10px'}}></div>

                          <div
                            className="suspendedColor"
                            style={{ maxWidth: suspendedPercent + '%' }}
                          ></div>
                           <div className="activeColor" style={{'maxWidth':afterSuspendedPercent+"%",backgroundColor:this.displayColorsData(curJobCode),height:'10px'}}></div>

                        </td>
                      );
                    } else {
                      s = (
                        <td className="colorDivsWrapr position-relative">
                          <div
                            className="endDateColor float_area"
                            hidden={beforeEndStatus}
                            style={beforeEndStyle}
                          ></div>
                          <div
                            className=""
                            style={{ maxWidth: beforeProjectPercentage + '%' }}
                          ></div>
                          <div className="bpColor" style={{'maxWidth':projectPercentage+"%",backgroundColor:this.displayColorsData(curJobCode),height:'10px'}}></div>

                          <div
                            className=""
                            style={{ maxWidth: afterProjectPercentage + '%' }}
                          ></div>
                        </td>
                      );
                    }
                  } else {
                    if (
                      curJobCode //without precon
                    ) {
                      s = (
                        <td className="colorDivsWrapr position-relative">
                          <div
                            className="endDateColor float_area"
                            hidden={beforeEndStatus}
                            style={beforeEndStyle}
                          ></div>
                          <div
                            className=""
                            style={{ maxWidth: beforeProjectPercentage + '%' }}
                          ></div>
                         <div className="activeColor" style={{'maxWidth':projectPercentage+"%",backgroundColor:this.displayColorsData(curJobCode),height:'10px'}}></div>
                                
                        </td>
                      );
                    } else {
                      s = (
                        <td className="colorDivsWrapr position-relative">
                          <div
                            className="endDateColor float_area"
                            hidden={beforeEndStatus}
                            style={beforeEndStyle}
                          ></div>
                          <div
                            className=""
                            style={{ maxWidth: beforeProjectPercentage + '%' }}
                          ></div>
                          <div className="bpColor" style={{'maxWidth':projectPercentage+"%",backgroundColor:this.displayColorsData(curJobCode),height:'10px'}}></div>

                        </td>
                      );
                    }
                  }
                } else if (
                  deliveredDate !== undefined &&
                  projectEDate <= yearData &&
                  estimateEDate >= yearData
                ) {
                  if (projectEndDate < monthFirstDay && deliveredDate > monthLastDay) {
                    projectPercentage = 100;
                    beforeProjectPercentage = 0;
                    afterProjectPercentage = 0;
                  } else if (
                    projectEndDate > monthFirstDay &&
                    deliveredDate > monthLastDay &&
                    projectEndDate < monthLastDay
                  ) {
                    let dayDiff = monthLastDay.valueOf() - projectEndDate.valueOf();
                    let dayValue = Math.round((dayDiff + 86400000) / 86400000);
                    projectPercentage = (100 * dayValue) / monthsDays;
                    beforeProjectPercentage = 100 - projectPercentage;
                    afterProjectPercentage = 0;
                  } else if (
                    projectEndDate < monthFirstDay &&
                    deliveredDate < monthLastDay &&
                    deliveredDate > monthFirstDay
                  ) {
                    //  projectPercentage = 100*deliveredDate.getDate()/monthsDays;
                    let dayDiff = deliveredDate.valueOf() - monthFirstDay.valueOf();
                    let dayValue = Math.round((dayDiff + 86400000) / 86400000);
                    projectPercentage = (100 * dayValue) / monthsDays;
                    beforeProjectPercentage = 0;
                    afterProjectPercentage = 100 - projectPercentage;
                  } else if (projectEndDate > monthFirstDay && deliveredDate < monthLastDay) {
                    let dayDiff = deliveredDate.valueOf() - projectEndDate.valueOf();
                    let dayValue = Math.round((dayDiff + 86400000) / 86400000);
                    projectPercentage = (100 * dayValue) / monthsDays;
                    //beforeProjectPercentage = 100*projectEndDate.getDate()/monthsDays;
                    let dateDiffStoE = projectEndDate.valueOf() - monthFirstDay.valueOf();
                    let dayDiffDate = Math.round((dateDiffStoE + 86400000) / 86400000);
                    beforeProjectPercentage = (100 * dayDiffDate) / monthsDays;
                    afterProjectPercentage = 100 - (projectPercentage + beforeProjectPercentage);
                  }

                  s = (
                    <td className="colorDivsWrapr">
                      <div className="" style={{ maxWidth: beforeProjectPercentage + '%' }}></div>
                      <div
                        className="endDateColor"
                        style={{ maxWidth: projectPercentage + '%' }}
                      ></div>
                      <div className="" style={{ maxWidth: afterProjectPercentage + '%' }}></div>
                    </td>
                  );
                } else {
                  s = <td></td>;
                }
                var dataArrayE = dataArray[e][monthArray[l]];
                jkhn.push(s);
              }
            }

            var pm = (
              <tr>
                {tdJobName}
                {tdJobStatus}
                {tdStartDate}
                {tdOriginalEndDate}
                {tdEndDate}
                {jkhn}
              </tr>
            );
            tableData.push(pm);
          }
        }

        var g = null;
        var k = null;
        var d = null;
        var finalSum = [];
        for (g in dataArray) {
          for (k in monthArray) {
            var t = null;
            if (finalSum.length == 0) {
              var objs = {};
              objs[monthArray[k]] = dataArray[g][monthArray[k]];
              finalSum.push(objs);
            } else {
              for (t in finalSum) {
                if (finalSum[t][monthArray[k]] == undefined) {
                  finalSum[t][monthArray[k]] = 0;
                }
                finalSum[t][monthArray[k]] =
                  finalSum[t][monthArray[k]] + dataArray[g][monthArray[k]];
              }
            }
          }
        }
      } else {
        dataArray = [];
        tableData = [];
      }
    }
  }

  

  displayColorsData(data){
    if(data)
    {

      let listOfFilter = ['LeadsAndBids','Construction','PreConstruction','Operational'];
      let percents;
      let row;
      listOfFilter.map((m,index)=>{
        percents = this.state.filterList[listOfFilter[index]].filter(item => item.jobStatusCodesCode===data);
        if(percents.length>0){
          row=percents[0];
        }
      })


      if(row && row.jobColorCode)
      {
        let colorRGB=('rgba'+'('+row.jobColorCode.r+','+ row.jobColorCode.g+','+ row.jobColorCode.b+','+ row.jobColorCode.a+')');
      
        return colorRGB;
      }
      else{
        return '';
      }
    }else{
      return 'rgba(255,105,180,255)'; //invalid color (or default color)
    }

  }

  dateRange(startDate, endDate) {
    var start = startDate.split('-');
    var end = endDate.split('-');
    var startYear = parseInt(start[0]);
    var endYear = parseInt(end[0]);
    var dates = [];

    for (var i = startYear; i <= endYear; i++) {
      var endMonth = i != endYear ? 11 : parseInt(end[1]) - 1;
      var startMon = i === startYear ? parseInt(start[1]) - 1 : 0;
      for (var j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
        var month = j + 1;
        var displayMonth = month < 10 ? month : month;
        dates.push({ year: i, Month: displayMonth });
      }
    }
    return dates;
  }

  downloadPdf = () => {
    return false;
  };

  render() {


    //**** created week ********
    if (
      this.state.customFilterType === 'lastQuarter' ||
      this.state.customFilterType === 'thisQuarter' ||
      this.state.customFilterType === 'nextQuarter'
    ) {
      var start1 = new Date(formatDate(this.state.Sdate.iso));
      var date1 = new Date(formatDate(this.state.Sdate.iso));
      let end1 = new Date(formatDate(this.state.Edate.iso));
      var sDate;
      var s1Date;
      var eDate;
      var j;

      for (var i = start1; i <= end1; i++) {
        let ds = new Date(i);

        if (ds.getDay() == 1 || (dateArr.length == 0 && !sDate)) {
          sDate = moment(ds.getTime()).format('DD-MMM');
          s1Date = new Date(ds.getTime());
          j = new Date(ds.setDate(ds.getDate() + 6));
          eDate = new Date(j.getTime());
        }

        if (sDate && eDate) {
          count += 1;
          dateArr.push({
            startDate: sDate,
            SDate: s1Date,
            endDate: eDate,
            count: count,
          });
          sDate = undefined;
          eDate = undefined;
        }

        if (start1 <= end1) {
          var t = date1.setDate(date1.getDate() + 7);
          i = new Date(t);
          sDate = moment(i.getTime()).format('DD-MMM');
          s1Date = new Date(i.getTime());

          if (j) {
            j = new Date(j.setDate(date1.getDate() + 6));
            eDate = new Date(j.getTime());
          }
        }
      }
    }

    //**** created Days ********
    if (
      this.state.customFilterType === 'lastMonth' ||
      this.state.customFilterType === 'thisMonth' ||
      this.state.customFilterType === 'nextMonth'
    ) {
      var start = new Date(formatDate(this.state.Sdate.iso));
      let end = new Date(formatDate(this.state.Edate.iso));
      var monthFirstDay = new Date(start.getFullYear(), start.getMonth(), start.getDate());
      var firstMonth = start.getMonth() + 1;
      var FirstYear = start.getFullYear();

      var monthlastDay = new Date(start.getFullYear(), parseInt(start.getMonth()) + 1, 0);
      let secLoop = true;
      if (start.getMonth() === end.getMonth()) {
        monthlastDay = new Date(end.getFullYear(), end.getMonth(), end.getDate());
        secLoop = false;
      }
      //first date
      let pagnbtn = [];
      for (let i = monthFirstDay.getDate(); i <= monthlastDay.getDate(); i++) {
        let obj = {};
        obj.day = i;
        obj.startDate = new Date(FirstYear + '-' + firstMonth + '-' + i);
        daysArr.push(obj);
      }

      //second Date
      var monthSecFirstDay = 1;
      var monthSecLastDay = new Date(end.getFullYear(), end.getMonth(), end.getDate());
      var secMonth = end.getMonth() + 1;
      var secYear = end.getFullYear();
      monthSecLastDay = monthSecLastDay.getDate();

      if (secLoop) {
        for (let i = monthSecFirstDay; i <= monthSecLastDay; i++) {
          let obj = {};
          obj.day = i;
          obj.startDate = new Date(secYear + '-' + secMonth + '-' + i);
          daysArr.push(obj);
        }
      }
    }

    //**** created months ********
    if (
      this.state.customFilterType === 'lastYear' ||
      this.state.customFilterType === 'thisYear' ||
      this.state.customFilterType === 'nextYear' ||
      this.state.customFilterType === 'next12months'
    ) {
      let d = new Date(this.state.Edate.iso);
      var startDate = RSR_ComparisonDate(this.state.Sdate.iso);
      var endDate = RSR_ComparisonDate(d.valueOf() - 86400000);

      let datesArr = this.dateRange(startDate, endDate);
      for (var i = 0; i < datesArr.length; i++) {
        let y = datesArr[i]['year'];
        let m = datesArr[i]['Month'] - 1;
        var monthFirstDay = new Date(y, m, 1);
        var monthLastDay = new Date(y, parseInt(m) + 1, 0);
        var mName = moment(monthFirstDay).format('MMM');
        yearArr.push({
          year: y,
          month: mName,
          mNum: m,
          fDate: monthFirstDay,
          lDate: monthLastDay,
        });
      }
    }

    //**** created year ********
    if (this.state.customFilterType === 'custom') {


      var tempSDate = new Date(formatDate(this.state.Sdate.iso));
      var tempEDate = new Date(formatDate(this.state.Edate.iso));
      var Sdate = tempSDate.getFullYear();
      var Edate = tempEDate.getFullYear();

      for (var i = Sdate; i <= Edate; i++) {
        annualArr.push({ startYear: i });
        if (i === Edate) {
          break;
        }
      }
    }

    this.dataFilter();

    let { multiDataSet } = this.state;

    return (
      <div>
        <div className="ContactListReport">
        <TitleComponent data={{title:`Resource Schedule Report`}}/>
          <div>
            <Header props={this.props} />
          </div>

          <div className="contactBox">
            <div className="container">
              <div className="reportHead">
                
                {/*<ResourceScheduleReportPdf reportName="Active_Projects_Report"  onClick={this.downloadPdf.bind(this)} data={multiDataSet} />*/}
                <ReactToPrint
                  trigger={() => (
                    <a className="btn rightalignmentBtn">
                      <i className="fa fa-print" aria-hidden="true"></i> Print PDF
                    </a>
                  )}
                  content={() => this.componentRef}
                />
                <button className="btn rightalignmentBtn" onClick={this.handleResourceScheduleModalToggle}>
                  <i className="fa fa-filter"></i> Apply Filters
                </button>
              </div>

              <div ref={(el) => (this.componentRef = el)}>
                <div className="row searchbar">
                  <div className="pdfLogoBox">
                    <div className="reportHead">
                      <div className="logo">
                        <img src={this.state.comLogo} alt="Upload Logo" />
                      </div>               
                    </div>
                  </div>
                  <h2>Resource Schedule Report</h2>
                  <div className="col-md-12">
                    <div className="analyzedBy">
                      <strong>Analyzed By:</strong> {this.props.resourceList.analyzeByStatus}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div>
                      <strong>Date: </strong>
                      {this.state.durationDate &&
                      this.state.durationDate.startDate &&
                      this.state.durationDate.endDate
                        ? formatDate(this.state.durationDate.startDate) +
                          ' to ' +
                          formatDate(this.state.durationDate.endDate)
                        : ''}
                    </div>
                  </div>
                </div>

              <div className="row">
                <div className="col-md-12">
                  <div className="profit-projection reportTableSection bigTable bigTable1 resourceScheduleReport">
                    <div className="table-responsive">
                      
                        <table width="100%" className="rsrTable">
                          <thead>
                            <tr>
                              <th>Project Name</th>
                              <th>Status</th>
                              <th>Start Date</th>
                              <th>Delivered Date</th>
                              <th>Current End Date</th>
                              {tableHeader}
                            </tr>
                          </thead>
                        {!this.props.isResourceListLoading &&
                          <tbody>{tableData}</tbody>
                        }
                        </table>
                      </div>
                      {this.props.isResourceListLoading && (
                        <ReactLoading
                          className="table-loader"
                          type={appConstants.LOADER_TYPE}
                          color={appConstants.LOADER_COLOR}
                          height={appConstants.LOADER_HEIGHT}
                          width={appConstants.LOADER_WIDTH}
                        />
                      )}
                      {!this.props.isResourceListLoading && this.state.list?.length === 0 && (
                        <div className="textCenter">No data found.</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>

        <ResourceScheduleModal
          isOpen={this.state.isResourceScheduleModalOpen}
          toggle={this.handleResourceScheduleModalToggle}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    filterList: selectGetResourceScheduleFilters(state),
    resourceList: selectGetResourceSchedule(state).data,
    isResourceListLoading: selectGetResourceSchedule(state).loading,
  };
}

export default (withRouter(connect(mapStateToProps)(ResourceScheduleReport)));
