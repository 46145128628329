import React from 'react';
import { Redirect, Route, Router } from 'react-router-dom';
import App from '../app/App';

import { Provider } from 'react-redux';
import { routerMiddleware } from 'react-router-redux';
import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { useAuthAdmin } from '../components/Admin/Auth/Auth';
import AdminHome from '../components/Admin/HomePage/HomePage.js';
import AdminLogin from '../components/Admin/LoginPage/LoginPage.js';
import adminReducer from '../reducers/Admin';
import history from '../routes/history';

import ForgotPassword from '../components/Admin/LoginPage/ForgotPasswordPage.js';

import ProjectList from '../components/Admin/AccountListPage/ProjectsListPage.js';
import UsersList from '../components/Admin/AccountListPage/UsersListPage.js';
import AccessDenied from '../components/Admin/Inc/access-denied.js';
import HelpList from '../components/Admin/Masters/helpList.js';
import ModuleOptions from '../components/Admin/Masters/moduleOptions.js';
import JobStatus from '../components/Admin/Masters/jobStatusList.js';
import ReasonCodes from '../components/Admin/Masters/reasonCodesList.js';
import PlansList from '../components/Admin/PlansListPage/PlansListPage.js';
import NotificationTypesList from '../components/Admin/NotificationTypes/NotificationTypes.js';
import WidgetsList from '../components/Admin/WidgetsPage/WidgetsPage.js';
import ResetPassword from '../components/Admin/ResetPassword/ResetPassword.js';
import TermsCondition from '../components/Admin/TermsCondition/TermsCondition.js';
import AdminSettingsPageWrapper from '../components/Masters/settingsPage/admin-settings-page-wrapper';
import AdminLayout from '../layouts/admin-layout';
import AccountsListPage from '../pages/accounts-list-page';
import PermissionsPage from '../pages/permissions-page';
import { ConfirmationDialogProvider } from '../shared/confirmation-dialog';
import UserActivityPage from '../components/Admin/AccountListPage/user-activity-page/user-activity-page';
import { ReportSettingsPage } from '../pages/settings/report-settings-page';
import { ProjectCustomFieldsPage } from '../pages/settings/project-custom-fields-page';

import { HelmetProvider } from 'react-helmet-async';

const historyMiddleware = routerMiddleware(history);
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const storeAdmin = createStore(
  adminReducer,
  composeEnhancers(applyMiddleware(historyMiddleware, thunk))
);

export const makeMainRoutesAdmin = () => {
  return (
    <Provider store={storeAdmin}>
      <ConfirmationDialogProvider>
        <HelmetProvider>
          <Router history={history}>
            <div className='routesBox'>
              <Route
                exact
                path='/'
                render={(props) => <App useAuthAdmin={useAuthAdmin} {...props} />}
              />
              {/* <Route exact path="/"  render={(props) => (useAuthAdmin().isAuthenticated(props) ? (<Redirect to="/home"/>) : (<App useAuthAdmin={useAuthAdmin} {...props} />))} />*/}
              <Route
                path='/admin/login'
                render={(props) => <AdminLogin useAuthAdmin={useAuthAdmin} {...props} />}
              />
              <Route
                path='/admin/logout'
                render={(props) => (useAuthAdmin().logout(props) ? <Redirect to='/home' /> : '')}
              />
              <Route
                path='/admin/forgot-password'
                render={(props) => <ForgotPassword useAuthAdmin={useAuthAdmin} {...props} />}
              />
              <Route
                path='/admin/access-denied'
                render={(props) => <AccessDenied useAuthAdmin={useAuthAdmin} {...props} />}
              />

              <Route
                path={[
                  '/admin/home',
                  '/admin/plans',
                  '/admin/widgets',
                  '/admin/reset-password',
                  '/admin/help-list',
                  '/admin/module-options',
                  '/admin/termsCondition',
                  '/admin/accounts',
                  '/admin/projects',
                  '/admin/users',
                  '/admin/user-activity',
                  '/admin/default-role-permissions',
                  '/admin/building-type',
                  '/admin/job-status',
                  '/admin/reason-codes',
                  '/admin/contact-role',
                  '/admin/occupancy-type',
                  '/admin/negotiation-method',
                  '/admin/notification-types',
                  '/admin/job-scope',
                  '/admin/user-types',
                  '/admin/project-role-types',
                  '/admin/report-settings',
                  '/admin/project-fields',
                  '/admin/status-actions'
                ]}
                render={(props) => {
                  if (!useAuthAdmin().handleAuthentication(props))
                    return <Redirect to='/admin/access-denied' />;
                  return (
                    <AdminLayout>
                      <Route
                        path='/admin/home'
                        render={(props) => <AdminHome useAuthAdmin={useAuthAdmin} {...props} />}
                      />
                      <Route
                        path='/admin/plans'
                        render={(props) => <PlansList useAuthAdmin={useAuthAdmin} {...props} />}
                      />
                      <Route
                        path='/admin/notification-types'
                        render={(props) => (
                          <NotificationTypesList useAuthAdmin={useAuthAdmin} {...props} />
                        )}
                      />
                      <Route
                        path='/admin/widgets'
                        render={(props) => <WidgetsList useAuthAdmin={useAuthAdmin} {...props} />}
                      />
                      <Route path='/admin/accounts' render={() => <AccountsListPage />} />
                      <Route
                        path='/admin/default-role-permissions'
                        render={(props) => <PermissionsPage isSuperAdmin />}
                      />

                      <Route
                        path='/admin/projects'
                        render={(props) => <ProjectList useAuthAdmin={useAuthAdmin} {...props} />}
                      />
                      <Route
                        path='/admin/users'
                        render={(props) => <UsersList useAuthAdmin={useAuthAdmin} {...props} />}
                      />
                      <Route
                        path='/admin/user-activity'
                        render={(props) => <UserActivityPage useAuthAdmin={useAuthAdmin} {...props} />}
                      />
                      <Route
                        path='/admin/report-settings'
                        render={(props) => <ReportSettingsPage useAuthAdmin={useAuthAdmin} {...props} />}
                      />

                      <Route
                        path='/admin/project-fields'
                        render={(props) => <ProjectCustomFieldsPage useAuthAdmin={useAuthAdmin} {...props} />}    />

                      <Route
                        path='/admin/reset-password'
                        render={(props) => <ResetPassword useAuthAdmin={useAuthAdmin} {...props} />}
                      />
                      <Route
                        path='/admin/help-list'
                        render={(props) => <HelpList useAuthAdmin={useAuthAdmin} {...props} />}
                      />
                      <Route
                        path='/admin/module-options'
                        render={(props) => <ModuleOptions useAuthAdmin={useAuthAdmin} {...props} />}
                      />
                      <Route
                        path='/admin/termsCondition'
                        render={(props) => (
                          <TermsCondition useAuthAdmin={useAuthAdmin} {...props} />
                        )}
                      />
                      <Route
                        path='/admin/building-type'
                        render={(props) => (
                          <AdminSettingsPageWrapper
                            singularTitle='Building Type'
                            tableTitle='Building types'
                            modelName='buildingTypes'
                          />
                        )}
                      />
                      <Route
                        path='/admin/job-status'
                        render={(props) => <JobStatus useAuthAdmin={useAuthAdmin} {...props} />}
                      />
                      <Route
                        path='/admin/reason-codes'
                        render={(props) => <ReasonCodes useAuthAdmin={useAuthAdmin} {...props} />}
                      />
                      <Route
                        path='/admin/contact-role'
                        render={(props) => (
                          <AdminSettingsPageWrapper
                            singularTitle='Contact Role'
                            tableTitle='Contact Roles'
                            modelName='contactRoles'
                          />
                        )}
                      />
                      <Route
                        path='/admin/occupancy-type'
                        render={(props) => (
                          <AdminSettingsPageWrapper
                            singularTitle='Occupancy Type'
                            tableTitle='Occupancy Types'
                            modelName='occupancyTypes'
                          />
                        )}
                      />
                      <Route
                        path='/admin/negotiation-method'
                        render={(props) => (
                          <AdminSettingsPageWrapper
                            singularTitle='Negotiating Method'
                            tableTitle='Negotiating Methods'
                            modelName='negotiatingMethods'
                          />
                        )}
                      />
                      <Route
                        path='/admin/job-scope'
                        render={(props) => (
                          <AdminSettingsPageWrapper
                            singularTitle='Job Scope'
                            tableTitle='Job Scopes'
                            modelName='jobScopes'
                          />
                        )}
                      />
                      <Route
                        path='/admin/user-types'
                        render={(props) => (
                          <AdminSettingsPageWrapper
                            singularTitle='User Type'
                            tableTitle='User Types'
                            modelName='cppRoles'
                          />
                        )}
                      />
                      <Route
                        path='/admin/project-role-types'
                        render={() => (
                          <AdminSettingsPageWrapper
                            singularTitle='Project Role'
                            modelName='projectRoleTypes'
                          />
                        )}
                      />
                    </AdminLayout>
                  );
                }}
              />
            </div>
          </Router>
        </HelmetProvider>
      </ConfirmationDialogProvider>
    </Provider>
  );
};
