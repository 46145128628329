import { Avatar } from '@material-ui/core';

import React, { Suspense } from 'react';
import SiteInfo from './ProjectDetailComponent/SiteInfo';
import { connect } from 'react-redux';
import renderHTML from 'html-react-parser';
import { Link, withRouter } from 'react-router-dom';
import { InputFieldSubmit } from '../Common/InputFieldSubmit';
import ReactToPrint from 'react-to-print';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { getAccountSettingsAction } from '../../actions/accountSettings';
import { getAllAssignProjectToPeopleAction } from '../../actions/assignProjectToPeople';
import { getAllJobScopeAction } from '../../actions/jobScope';
import { getAllJobStatusAction } from '../../actions/jobStatusCode';
import { updateLeadStatusAction } from '../../actions/project';

import { getExistingProjectAction } from '../../actions/project';
import { getAllProjectRoleAction } from '../../actions/projectRole';
import { AddLeadModal } from '../../modals/add-lead-modal';
import { PromoteToBiddingModal } from '../../modals/promote-to-bidding-modal';
import { selectGeneralInfo } from '../../selectors/accountSettings';
import ProjectTeam from './ProjectDetailComponent/ProjectTeam';
import InternalProjectTeam from './ProjectDetailComponent/InternalProjectTeam';
import { convertAddressToFormValue, toContactAddress } from '../../utils/address';
import { formatInternalTeamContact, getAcronymCode } from '../../utils/contact-formatters';
import { formatBidDueDate, formatDate } from '../../utils/date-formatters';
import { appConstants, isAllowed, moduleConstants, reportAccess } from '../../_constants';
import {useAuth, recentSearch} from '../Auth/Auth';
import {callAlertMessage} from '../Auth/Auth';
import TitleComponent from '../Common/TitleComponent';
import DetailsRightSection from '../Layout/DetailsRightSection';
import Footer from '../Layout/Footer';
import Header from '../Layout/Header';
import loaderImage from '../static/images/loading_i.gif';
import PrintThisLead from './print-this-lead';
import LeadNotFound from './lead-not-found';
import { AddProjectModal } from '../../modals/add-project-modal';
import { formatMoney } from '../../utils/number-formatters';


// eslint-disable-next-line no-undef
let Validator = require('validatorjs');
let rules = {
  // taskName: 'required',
  // startDT: 'required',
  // endDT: 'required',
  // status: 'required',
  // comment: 'required',
};
let mess = {
  required: 'This field is required',
  alpha: 'This field may only contain letters',
  numeric: 'This field must be a number',
};
let errorMsg = '';

let validation = [];
let formData = {};

validation = new Validator([], rules, mess);
validation.passes();
validation.fails();
let buttonActive = false;

class ViewLeadDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAddLeadModalOpen: false,
      sessionToken: localStorage.getItem('sessionToken'),
      userId: localStorage.getItem('userId'),
      lienPeriod: localStorage.getItem('lienPeriod') || 0,
      objectId: '',
      projectId: '',
      accountId: localStorage.getItem('accountId'),
      comName: localStorage.getItem('companyName'),
      modalStatusUpdate: false,
      accountData: {},
      allNegotiatingMethod: [],
      messageData: {},
      selectBox: {},
      assignedProjectToPeopleList: [],
      statusUpdateProject: {},
      isProject: false,
      dateReasonReq: false,
      statusReasonRequired: false,
      currentProjectStatus: {},
      allBuildingType: [],
      list: [],
      projectData: {},
      allProjectStatus: [],
      changeLeadToBiddingModal: false,
      buttonActive: true,
      changeLeadToNoBidModal: false,
      changeNoBidToLeadModal: false,
      changeLeadToNoBid: {},
      changeNoBidToLead: {},
      enabledExternalProjectRoles: [],
      accountExecRole: null,
      allProjectRoleTypes: [],
      accountExecutives: [],
      allProjectActivities: [],
      allJobScope: [],
      occupancyType: [],
      allContactRole: [],
      submitted: false,
      userComment: '',
      commentList: [],
      modal: false,
      modal2: false,
      addListState: true,
      bidDueTime: '',
      marketingDescription: '',
      originalCurrent: '',
      accountInfo: {},
      mapData: {
        center: [34.052235, -118.243683],
        zoom: 18,
      },
      marker: {},
      oneTimeLoad: true,
      imageUploadStatus: false,
      printSheet: false,
      dataType: {},
      hiddenValue: true,
      searchTxt: '',
      isProjectUpdated: false,
      isProjectNotFound: false,
    };

    this.handleAddLeadModalToggle = this.handleAddLeadModalToggle.bind(this);

    this.changeLeadToBidding = this.changeLeadToBidding.bind(this);
    this.changeLeadToNoBid = this.changeLeadToNoBid.bind(this);
  }

  handlePlaceSelect = (place) => {
    const parsedPlace = toContactAddress(place);
    const address1 = convertAddressToFormValue(parsedPlace.streetNumber, parsedPlace.route);

    this.setState((prevState) => ({
      projectData: {
        ...prevState.projectData,
        jobAddress: address1,
        jobCity: parsedPlace.city,
        jobState: parsedPlace.state,
        jobZip: parsedPlace.zipCode,
        jobCountry: parsedPlace.country,
        lat: parsedPlace.lat,
        lng: parsedPlace.lng,
      },
    }));
  };

  shouldComponentUpdate(prevProps, nextProps) {
    let { location } = prevProps;
    let { state } = location;
    if (state && state.row) {
      let { row } = state;
      if (row && row.objectId !== this.state.objectId) {
        if (this.props.location.state.search) {
          recentSearch(this.props.location.state.search);
        }
        this.setState({ objectId: row.objectId });
        return true;
      }
    }
    return true;
  }

  changeLeadToBidding() {
    this.setState({
      changeLeadToBiddingModal: !this.state.changeLeadToBiddingModal,
    });
  }

  changeLeadToNoBid(evt) {
    let changeLeadToNoBid = this.state.changeLeadToNoBid;
    changeLeadToNoBid['objectId'] = this.state.projectId;

    this.state.allProjectStatus.filter((projectStatus) => {
      if (projectStatus.jobStatusCodesCode === 'NP') {
        changeLeadToNoBid['jobStatusCodes'] = projectStatus.objectId;
      }
      return true;
    });

    this.setState({
      changeLeadToNoBidModal: !this.state.changeLeadToNoBidModal,
      changeLeadToNoBid,
    });
  }

  changeNoBidToLead = () => {
    const projectStatusId = this.state.allProjectStatus.find(
      ({ jobStatusCodesCode }) => jobStatusCodesCode === appConstants.jobStatusCode.L
    )?.objectId;

    const changeNoBidToLead = {
      objectId: this.state.projectId,
      jobStatusCodes: projectStatusId,
    };

    this.setState((prevState) => ({
      changeNoBidToLeadModal: !prevState.changeNoBidToLeadModal,
      changeNoBidToLead,
    }));
  };

  handleChangeLeadToNoBid = (e) => {
    let changeLeadToNoBid = this.state.changeLeadToNoBid;
    changeLeadToNoBid['accountId'] = this.state.accountId;
    changeLeadToNoBid['userId'] = this.state.userId;

    this.setState({
      changeLeadToNoBidModal: !this.state.changeLeadToNoBidModal,
    });
    this.props.updateLeadStatusAction(changeLeadToNoBid, this.props);
    this.setState({ isUpdateStatus: false });
  };

  handleChangeNoBidToLead = () => {
    const accountData = this.state.accountData;

    const changeNoBidToLead = {
      ...this.state.changeNoBidToLead,
      accountId: this.state.accountId,
      userId: this.state.userId,
    };

    this.setState((prevState) => ({ changeNoBidToLeadModal: !prevState.changeNoBidToLeadModal }));

    this.props.updateLeadStatusAction(changeNoBidToLead, this.props);
    this.setState({ isUpdateStatus: false });
  };

  getObjectId() {
    const { match } = this.props;
    let objectId = '';
    let { state } = this.props.location;
    if (state && state.row) {
      let { row } = state;
      objectId = row.objectId;
    }
    if (match.params && match.params.objectId) {
      objectId = match.params.objectId;
    }
    this.setState({ objectId, projectId: objectId });
    return objectId;
  }

  componentDidMount() {
    let objectId = this.getObjectId();
    let projectData = {};
    projectData['projectId'] = objectId;
    if (objectId) {
      this.props.getExistingProjectAction(projectData);
      this.props.getAllJobStatusAction({
        className: 'jobStatusCodes',
        accountId: this.state.accountId,
      });
      this.props.getAllJobScopeAction({
        className: 'jobScopes',
        accountId: this.state.accountId,
        includeDisabled: true,
      });
      this.props.getAllAssignProjectToPeopleAction({
        projectId: objectId,
        accountId: this.state.accountId,
      });
      this.props.getAllProjectRoleAction({
        className: 'projectRoleTypes',
        accountId: this.state.accountId,
        includeDisabled: true,
      });
      this.props.getAccountSettingsAction();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.projectData?.isProjectNotFound === true) {
      this.setState({
        isProjectNotFound: nextProps.projectData.isProjectNotFound,
      });
    }

    if (
      nextProps.projectData?.objectId &&
      nextProps.projectData.jobStatusCodesCode !== appConstants.jobStatusCode.L &&
      nextProps.projectData.jobStatusCodesCode !== appConstants.jobStatusCode.NP
    ) {
      nextProps.history.replace(
        nextProps.location.pathname.replace('lead-details', 'project-details')
      );
    }

    if (this.props.isProjectUpdated !== nextProps.isProjectUpdated && nextProps.isProjectUpdated) {
      this.props.getExistingProjectAction({ projectId: this.state.projectId });
      this.props.getAllAssignProjectToPeopleAction({
        projectId: this.state.projectId,
        accountId: this.state.accountId,
      });
    }

    if (this.props.isUpdateStatus !== nextProps.isUpdateStatus && nextProps.isUpdateStatus) {
      this.props.getExistingProjectAction({ projectId: this.state.projectId });
      this.props.getAllAssignProjectToPeopleAction({
        projectId: this.state.projectId,
        accountId: this.state.accountId,
      });
    }

    if (nextProps.accountInfo) {
      this.setState({ accountData: nextProps.accountInfo });
    }

    if (nextProps.projectData?.lat) {
      this.setState({ lat: nextProps.projectData?.lat });
      this.setState({ lng: nextProps.projectData?.lng });
    }

    if (this.state.maps && this.state.lng && this.state.lat) {
      this.renderMarkers();
    }

    this.setState({
      assignedProjectToPeopleList: nextProps.assignedProjectToPeopleList,
    });
    this.setState({
      allProjectStatus: nextProps.allProjectStatus.allJobStatus,
    });
    this.setState({ fetchRequest1: nextProps.fetchRequest });
    this.setState({ reasonCodes: nextProps.reasonCodes.allReasonCode });
    this.setState({ allJobScope: nextProps.allJobScope });

    this.setState({ allProjectRoleTypes: nextProps.allProjectRoleTypes });

    if (nextProps?.allProjectRoleTypes?.length > 0) {
      this.setState({
        enabledExternalProjectRoles: nextProps.allProjectRoleTypes.filter(
          (role) => !role.isSystemMaster && !role.trash && role.type === 'external'
        ),
      });
      this.setState({
        accountExecRole: nextProps.allProjectRoleTypes.find(
          (role) =>
            !role.isSystemMaster && !role.trash && role.type === 'internal' && role.code === 'AX'
        ), clientRole: nextProps.allProjectRoleTypes.find(
          (role) =>
            !role.isSystemMaster && !role.trash && role.type === 'internal' && role.code === 'CL'
        ),
      });
    }

    this.setState({ accountInfo: nextProps.accountInfo });

    let projectData = nextProps.projectData;
    if (projectData?.objectId) {
      this.setState({ projectData });
    }

    let currentProjectStatus = {};
    if (projectData && nextProps.allProjectStatus?.allUsedJobStatus?.length > 0) {
      currentProjectStatus = nextProps.allProjectStatus.allUsedJobStatus.find(
        (item) => projectData.jobStatusCodes === item.objectId
      );

      if (currentProjectStatus) {
        this.setState({ currentProjectStatus: currentProjectStatus });
      }

      //If its an active project?
      let activeStatus =
        currentProjectStatus?.jobStatusSortIndex > appConstants.ActiveProjectSortIndex;

      if (activeStatus) {
        this.setState({ isProject: true });
      } else {
        this.setState({ isProject: false });
      }
    }

    if (nextProps.isAlert) {
      callAlertMessage(
        nextProps.success === 1 ? 'success' : 'error',
        nextProps.message
      );
    }

    if (nextProps.assignedProjectToPeopleList && nextProps.assignedProjectToPeopleList.length) {
      this.setState({
        assignedProjectToPeopleList: nextProps.assignedProjectToPeopleList,
      });
    }
  }

  renderMarkers(map, maps) {
    let { projectData, mapData } = this.state;
    let { lat, lng, jobName } = projectData;

    if (!map || !maps) {
      map = this.state.map;
      maps = this.state.maps;
    }

    mapData['center'] = [lat ? lat : 0.0, lng ? lng : -0.0];

    let marker = new maps.Marker({
      position: {
        lat: lat ? lat : 0.0,
        lng: lng ? lng : -0.0,
      },
      label: {
        color: 'white',
        fontWeight: 'bold',
        text: lat && lng ? jobName : 'NA',
      },
      map,
      title: lat && lng ? jobName : 'NA',
    });

    this.setState({ mapData, map, maps });
  }

  shouldComponentUpdate(prevProps, nextProps) {
    let { location } = prevProps;
    let { state } = location;
    if (state && state.row) {
      let { row } = state;
      if (row && row.objectId !== this.state.objectId) {
        if (this.props.location.state.search) {
          recentSearch(this.props.location.state.search);
        }
        this.setState({ objectId: row.objectId });
        this.setState({ projectId: row.objectId });
        return true;
      }
    }
    return true;
  }

  handleAddLeadModalToggle = () =>
    this.setState({ isAddLeadModalOpen: !this.state.isAddLeadModalOpen });

  onOpenEditModal = (section) => {
    this.onEditProjectClick(section);
  };

  onEditProjectClick = (initialSection = '') => {
    this.setState({ isAddLeadModalOpen: true, initialSection });
  };

  render() {
    const { projectData } = this.state;

    let tableOption = appConstants.TableOption;
    tableOption['afterDeleteRow'] = this.handleDeleteButtonClick;
    tableOption['paginationShowsTotal'] = this.renderShowsTotal;
    tableOption['onRowClick'] = this.selectedRow1;

    let jobScope = {};

    if (this.state.allJobScope.length > 0) {
      jobScope = this.state.allJobScope.filter((v) => {
        if (
          v.objectId !== '' &&
          v.jobScopeName !== '' &&
          v.isSystemMaster === false &&
          v.trash === false &&
          this.state?.projectData?.scopeArr?.map((scope) => scope.objectId).includes(v.objectId)
        )
          return v;
      });
    }

    let accountExec = '';
    let accountExecs = [];
    let clients = [];
    let client = '';

    const accountExecRoleName = this.state.accountExecRole?.roleName ?? 'Account Executive';
    const clientRoleName = this.state.clientRoleName?.roleName ?? 'Client';

    if (
      this.state.projectData &&
      this.state.assignedProjectToPeopleList?.length > 0 &&
      this.state.accountExecRole
    ) {
      this.state.assignedProjectToPeopleList.map((item) => {
        if (item.projectRoleId === this.state.accountExecRole.objectId) {
          accountExecs = accountExecs.concat(item);
          if (accountExec !== '') {
            accountExec = accountExec + ', ';
          }
          accountExec = accountExec + formatInternalTeamContact(item);
        }
      });
    }

    if (
      this.state.projectData &&
      this.state.assignedProjectToPeopleList?.length > 0 &&
      this.state.clientRole
    ) {
      this.state.assignedProjectToPeopleList.map((item) => {
        if (item.projectRoleId === this.state.clientRole.objectId) {
          clients = clients.concat(item);
          if (client !== '') {
            client = client + ', ';
          }
          client = client + formatInternalTeamContact(item);
        }
      });
    }

    const accountExecEnabled =
      accountExecs?.length > 0 ||
      (this.state.accountExecRole?.isShowOnLeads && !this.state.accountExecRole.trash);

      const clientEnabled =
      clients?.length > 0 ||
      (this.state.clientRole?.isShowOnLeads && !this.state.clientRole.trash);

    const userInfo_AX = accountExecs?.length > 0 && accountExecs[0];

    const isJobStatusExist = !!projectData?.jobStatusCodesName;
    const isJobStatusLead =
      isJobStatusExist && projectData.jobStatusCodesCode === appConstants.jobStatusCode.L;
    const isJobStatusNoPursue =
      isJobStatusExist && projectData.jobStatusCodesCode === appConstants.jobStatusCode.NP;

    let printData;
    let leadPrint;

    const ChangeLeadToNoBid = [
      {
        title: 'Cancel',
        loading: 'Loading...',
        loadingActive: buttonActive,
        onClickFun: this.changeLeadToNoBid,
        'data-type': 'SAVE',
        className: 'btn btn-primary ',
      },
      {
        title: 'Update ',
        loading: 'Loading...',
        loadingActive: buttonActive,
        onClickFun: (e) => this.handleChangeLeadToNoBid(e),
        'data-type': 'SAVE',
        className: 'btn btn-primary ',
      },
    ];

    const ChangeNoBidToLead = [
      {
        title: 'Cancel',
        loading: 'Loading...',
        loadingActive: buttonActive,
        onClickFun: this.changeNoBidToLead,
        'data-type': 'SAVE',
        className: 'btn btn-primary',
      },
      {
        title: 'Update ',
        loading: 'Loading...',
        loadingActive: buttonActive,
        onClickFun: this.handleChangeNoBidToLead,
        'data-type': 'SAVE',
        className: 'btn btn-primary',
      },
    ];

    if (this.state.isProjectNotFound) {
      return <LeadNotFound />;
    }

    const canPromote =
      isAllowed(moduleConstants.B, 'assigned', projectData?.userAssignData) ||
      isAllowed(moduleConstants.P1, 'assigned', projectData?.userAssignData) ||
      isAllowed(moduleConstants.P2, 'assigned', projectData?.userAssignData) ||
      isAllowed(moduleConstants.P3, 'assigned', projectData?.userAssignData);

    const isCreator = projectData?.createdBy?.objectId === this.state.userId;
    const canEditDetails = isAllowed(
      moduleConstants.EDITPROJDETAILS,
      isCreator ? 'anyAssigned' : 'assigned',
      projectData?.userAssignData
    );

    return (
      <div className='ContactListReport'>
        <TitleComponent
          data={{
            title: `Lead Details - ${
              projectData && projectData.jobName ? projectData.jobName : ' NA'
            }`,
          }}
        />
        <Suspense fallback='loading'>
          <Header props={this.props} />
        </Suspense>
        <div className='detailsPage'>
          {this.state.fetchRequest1 ? (
            <div className='loading_bg'>
              <img className='ajax-loader' src={loaderImage} width='100' height='100' />
            </div>
          ) : (
            ''
          )}
          <div className='container'>
            <div className=''>
              <div className='row mb-3 mb-md-0'>
                <div className='col-md-6 mb-3 mb-md-0'>
                  <div className='d-flex'>
                    {/*
                    <ReactImageZoom {...props} />
                    {isAllowed(moduleConstants.EL) && (
                      <div className='changeImgBox'>
                        <div className='cit'>Change Image</div>
                        <input
                          type='file'
                          name='imageUrl'
                          className='field full upload-input'
                          label='Upload'
                          accept=''
                        //onChange={this.onFileChange}
                    />
                      </div>
                    )}
                    */}
                  </div>

                  {
                    //this.state.imageUploadStatus && <p className='waitText'> Please Wait..</p>}
                  }

                  <div className='p-1'>
                    <div className='heading2 mb-1'>{projectData?.jobName || ' NA'}</div>
                    <div className='profileSubInfo d-flex flex-wrap '>
                      <div className='profileStatus p-1'>
                        <strong>Status:&nbsp;</strong>
                        {this.state.currentProjectStatus?.jobStatusCodesName || 'NA'}
                      </div>
                    </div>
                  </div>
                </div>

                <div className='col-md-6'>
                  <div className='d-flex mt-md-3 mt-sm-0 flex-wrap justify-content-end gap-2 detailMenu'>
                    {isJobStatusExist && canPromote && (
                      <button onClick={this.changeLeadToBidding} className='btn'>
                        Promote to Bidding
                      </button>
                    )}

                    {isJobStatusLead &&
                      isAllowed(moduleConstants.L, 'assigned', projectData?.userAssignData) && (
                        <button onClick={this.changeLeadToNoBid} className='btn'>
                          Mark Not Pursued
                        </button>
                      )}

                    {isJobStatusNoPursue &&
                      isAllowed(moduleConstants.L, 'assigned', projectData?.userAssignData) && (
                        <button onClick={this.changeNoBidToLead} className='btn'>
                          Mark Lead
                        </button>
                      )}

                    {canEditDetails && (
                      <button className='btn' onClick={() => this.onEditProjectClick('')}>
                        Edit project
                      </button>
                    )}

                    {isJobStatusExist && (
                      <>
                        <div className='dropdown px-2 detailMenu'>
                          <a
                            className='dropdown-toggle btn'
                            data-bs-toggle='dropdown'
                            href='#'
                            role='button'
                            aria-expanded='false'
                          >
                            <i className='fa fa-ellipsis-v' aria-hidden='true'></i>
                          </a>
                          <ul className='dropdown-menu  ml-auto'>
                            {isAllowed(
                              reportAccess.LS,
                              'assigned',
                              projectData?.userAssignData
                            ) && (
                              <>
                                <li className='dropdown-item'>
                                  <Link
                                    to={{
                                      pathname: '/project-lead-sheet',
                                      state: { ...this.props.rowData },
                                    }}
                                  >
                                    View Lead Sheet
                                  </Link>
                                </li>
                                <div className='dropdown-divider' />
                              </>
                            )}
                            <li className='dropdown-item'>
                              <ReactToPrint
                                trigger={() => <a>Print Lead Page</a>}
                                content={() => this.componentRef}
                              />
                            </li>
                          </ul>
                        </div>
                      </>
                    )}

                    {projectData && (
                      <PrintThisLead
                        ref={(el) => (this.componentRef = el)}
                        props={this.props}
                        useAuth={useAuth}
                        leadPrint={leadPrint}
                        statusType={printData}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col-md-6 col-lg-7 col-xl-7'>
                <div className='projectDetailSidebar'>
                  <div className='pDetail leadDetails'>
                    <div className='row'>
                      <div className='col'>
                        <div className='row'>
                          <div className='col-lg-3 col-md-4 col-sm-5 col-xs-6 mb-1'>Lead cohort:</div>
                          <div className='col-lg-9 col-md-8 col-sm-7 col-xs-6 mb-1'>
                            {projectData?.leadCohort || '-'}
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-lg-3 col-md-4 col-sm-5 col-xs-6 mb-1'>Lead date:</div>
                          <div className='col-lg-9 col-md-8 col-sm-7 col-xs-6 mb-1'>
                            {projectData?.leadDate?.iso
                              ? formatDate(projectData.leadDate.iso)
                              : '-'}
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-lg-3 col-md-4 col-sm-5 col-xs-6 mb-1'>Lead source:</div>
                          <div className='col-lg-9 col-md-8 col-sm-7 col-xs-6 mb-1'>
                            {projectData?.leadSource || '-'}
                          </div>
                        </div>
                        {clientEnabled && (
                          <div className='row'>
                            <div className='col-lg-3 col-md-4 col-sm-5 col-xs-6 mb-1'>{clientRoleName}:</div>
                            <div className='col-lg-9 col-md-8 col-sm-7 col-xs-6 mb-1'>
                            {client ? renderHTML(client) : '-'}
                            </div>
                          </div>
                        )}

                        {accountExecEnabled && (
                          <div className='row'>
                            <div className='col-lg-3 col-md-4 col-sm-5 col-xs-6 mb-1'>
                              {accountExecRoleName}:
                            </div>
                            <div className='col-lg-9 col-md-8 col-sm-7 col-xs-6 mb-1'>
                              {accountExec ? renderHTML(accountExec) : '-'}
                            </div>
                          </div>
                        )}

                      </div>
                    </div>
                    {accountExec && userInfo_AX?.profileImage && (
                      <div className='ms-auto'>
                        <Avatar alt={`${userInfo_AX.userFullName}`} src={userInfo_AX?.profileImage}>
                          {`${getAcronymCode(userInfo_AX.userFullName)}`}
                        </Avatar>
                      </div>
                    )}
                  </div>

                  <SiteInfo
                    useAuth={useAuth}
                    projectStage={'Lead'}
                    projectData={projectData}
                    props={this.props}
                    onOpenEditModal={this.onOpenEditModal}
                  />

                  <div className='pDetail'>
                    <h6>Dates</h6>
                    <div className='row'>
                      <div className='col-lg-3 col-md-4 col-sm-5 col-xs-6 mb-1'>Bid Due</div>
                      <div className='col'>
                        {this.state.projectData?.bidDue?.iso
                          ? formatBidDueDate(this.state.projectData.bidDue.iso)
                          : '-'}
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-lg-3 col-md-4 col-sm-5 col-xs-6 mb-1'>Start Date</div>
                      <div className='col'>
                        {this.state.projectData?.startDate?.iso
                          ? formatDate(this.state.projectData.startDate.iso)
                          : '-'}
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-lg-3 col-md-4 col-sm-5 col-xs-6 mb-1'>End Date</div>
                      <div className='col'>
                        {this.state.projectData?.endDate?.iso
                          ? formatDate(this.state.projectData.endDate.iso)
                          : '-'}
                      </div>
                    </div>
                    {canEditDetails && (
                      <a
                        className='editBtn'
                        onClick={() => this.onEditProjectClick('lead-project-dates')}
                      >
                        <i className='fa fa-pencil' aria-hidden='true'></i>
                      </a>
                    )}
                  </div>

                  {(isAllowed(
                    moduleConstants.VIEWCONTRACTVAL,
                    'assigned',
                    projectData.userAssignData
                  ) ||
                    isAllowed(
                      moduleConstants.VIEWPROFIT,
                      'assigned',
                      projectData.userAssignData
                    )) && (
                    <>
                      <div className='pDetail'>
                        <h6>Dollars</h6>
                        {isAllowed(
                          moduleConstants.VIEWCONTRACTVAL,
                          'assigned',
                          projectData.userAssignData
                        ) && (
                          <div className='row'>
                            <div className='col-lg-3 col-md-4 col-sm-5 col-xs-6 mb-1'>
                              Bid Amount [Estimate]:
                            </div>
                            <div className='col'>
                              {this.state.projectData?.estimateBidAmount
                                ? formatMoney(this.state.projectData?.estimateBidAmount)
                                : '-'}
                            </div>
                          </div>
                        )}
                        {isAllowed(
                          moduleConstants.VIEWPROFIT,
                          'assigned',
                          projectData.userAssignData
                        ) && (
                          <div className='row'>
                            <div className='col-lg-3 col-md-4 col-sm-5 col-xs-6 mb-1'>
                              Gross Profit [Estimate]:
                            </div>
                            <div className='col'>
                              {this.state.projectData?.estimateGrossProfit
                                ? formatMoney(this.state.projectData?.estimateGrossProfit)
                                : '-'}
                            </div>
                          </div>
                        )}
                        {canEditDetails && (
                          <a
                            className='editBtn'
                            onClick={() => this.onEditProjectClick('lead-project-dates')}
                          >
                            <i className='fa fa-pencil' aria-hidden='true'></i>
                          </a>
                        )}
                      </div>
                    </>
                  )}
                  <InternalProjectTeam
                    useAuth={useAuth}
                    projectData={projectData}
                    props={this.props}
                    isLead
                    onOpenEditModal={this.onOpenEditModal}
                  />
                  <ProjectTeam
                    useAuth={useAuth}
                    projectData={projectData}
                    props={this.props}
                    isLead={true}
                    onOpenEditModal={this.onOpenEditModal}
                  />
                </div>
              </div>
              <div className='col-md-6 col-lg-5 col-xl-5'>
                <div className='projectTabsBox'>
                  {this.state.projectData && this.state.projectData.objectId && (
                    <DetailsRightSection
                      typeRef={'PROJECT'}
                      typeId={this.state.projectData.objectId}
                      data={projectData}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />

        <Modal
          backdrop='static'
          keyboard={true}
          isOpen={this.state.changeLeadToNoBidModal}
          toggle={(e) => {
            this.changeLeadToNoBid();
          }}
          className={this.props.className}
          id='projectStatusUpdatePopup'
        >
          <ModalHeader
            className='modalHeader'
            toggle={(e) => {
              this.changeLeadToNoBid();
            }}
          >
            <div className='col-md-12'>
              <div className='pageinfo modalHeader'>
                <h2>Update status to 'Not Pursued'</h2>
              </div>
            </div>
          </ModalHeader>
          <ModalBody>
            <div className='modalBody'>
              <div className='ContactListReport'>
                <div className='addContact'>
                  <div className=''>
                    <div className='row'>
                      <div className='col-md-12'>
                        <label>
                          Lead Name:{' '}
                          <strong>
                            {' '}
                            {this.state.projectData && this.state.projectData.jobName
                              ? this.state.projectData.jobName
                              : 'NA'}
                          </strong>{' '}
                        </label>
                      </div>
                    </div>
                    <div className='clearfix'></div>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter className='modalFooter'>
            <div className='Colbox-12'>
              <div className='form-group'>
                <InputFieldSubmit actions={ChangeLeadToNoBid} />
              </div>
            </div>
          </ModalFooter>
        </Modal>

        <Modal
          backdrop='static'
          keyboard={true}
          isOpen={this.state.changeNoBidToLeadModal}
          toggle={this.changeNoBidToLead}
          className={this.props.className}
          id='projectStatusUpdatePopup'
        >
          <ModalHeader className='modalHeader' toggle={this.changeNoBidToLead}>
            <div className='col-md-12'>
              <div className='pageinfo modalHeader'>
                <h2>Update status to 'Lead'</h2>
              </div>
            </div>
          </ModalHeader>
          <ModalBody>
            <div className='modalBody'>
              <div className='ContactListReport'>
                <div className='addContact'>
                  <div className='row'>
                    <div className='col-md-12'>
                      <br />
                      <label>
                        Lead Name: <strong>{projectData?.jobName || 'NA'}</strong>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <InputFieldSubmit actions={ChangeNoBidToLead} />
          </ModalFooter>
        </Modal>

        {this.state.isAddLeadModalOpen && (
          <AddLeadModal
            open={this.state.isAddLeadModalOpen}
            onClose={this.handleAddLeadModalToggle}
            projectId={this.props.match.params.objectId}
            initialSection={this.state.initialSection}
          />
        )}

        <PromoteToBiddingModal
          open={this.state.changeLeadToBiddingModal}
          onClose={this.changeLeadToBidding}
          projectId={this.props.match.params.objectId}
          allProjectStatus={this.state.allProjectStatus}
          onSubmit={(data) => {
            this.setState({
              promotionData: data,
              addProjectModalOpened: true,
            });
          }}
        />

        <AddProjectModal
          open={this.state.addProjectModalOpened}
          onClose={() => this.setState({ addProjectModalOpened: false })}
          projectId={this.props.match.params.objectId}
          allProjectStatus={this.state.allProjectStatus}
          promotionData={this.state.promotionData}
          onPromote={() => {
            this.props.history.push(
              this.props.location.pathname.replace('lead-details', 'project-details')
            );
          }}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    buttonActive: state.project.buttonActive,
    projectData: state.project.projectData,
    isUpdateStatus: state.project.isUpdateStatus,
    allProjectStatus: state.jobStatusCode,
    allJobStatusCodes: state.jobStatusCode.allJobStatus,
    reasonCodes: state.reasonCode,
    fetchRequest: state.project.fetchRequest,
    allJobScope: state.jobScope.allJobScope,
    accountInfo: selectGeneralInfo(state),
    assignedProjectToPeopleList: state.assignProjectToPeople.assignedProjectToPeopleList,
    allProjectRoleTypes: state.projectRoleType.allProjectRoleTypes,
    isProjectUpdated: state.project.isProjectUpdated,
    isProjectNotFound: state.project.projectData?.isProjectNotFound,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    getExistingProjectAction,
    getAllJobStatusAction,
    getAllJobScopeAction,
    getAllAssignProjectToPeopleAction,
    getAccountSettingsAction,
    getAllProjectRoleAction,
    updateLeadStatusAction,
  })(ViewLeadDetails)
);
