import React, {Suspense} from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import Header from '../../Layout/Header.js';
import Footer from '../../Layout/Footer.js';
import SidebarSettings from '../../Layout/SidebarSettings.jsx';
import { appConstants } from '../../../_constants/index.js';
import {
  createJobStatusAction,
  getAllJobStatusAction,
  updateJobStatusAction,
  deleteJobStatusAction,
  copyJobStatusAction,
  listImportableJobStatusAction,
} from '../../../actions/jobStatusCode.js';
import ReactLoading from 'react-loading';
import { callAlertMessage, UpperCaseName } from '../../Auth/Auth.js';
import { SwatchesPicker } from 'react-color';
import reactCSS from 'reactcss'
import $ from 'jquery'
import CustomTable from "../../Common/CustomTable.jsx";
import TitleComponent from  "../../Common/TitleComponent.js";
// eslint-disable-next-line no-undef
let Validator = require('validatorjs');
let styleData = { color: 'red' };
let formArr = {};
let rules = {
  jobStatusCodesCode: 'required',
  jobStatusCodesName: 'required',
};
let mess = {
  required: 'This field is required',
  alpha: 'This field may only contain letters',
};
let validation = [];
validation = new Validator([], rules, mess);
validation.passes();
validation.fails();

class JobStatusLegacy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hidden: true, // for use objectid hide in table
      sessionToken: localStorage.getItem('sessionToken'),
      userId: localStorage.getItem('userId'),
      accountId: localStorage.getItem('accountId'),
      list: [],
      listMaster:[],
      formArr: [],
      modal: false,
      modalLoad: false,
      saveType: 'POST',
      formData: {},
      formDataMaster:{},
      fetchRequest: false,
      isCreated: false,
      successRedirect: false,
      masterTypeArr: [],
      loadCalled: false,
      allJobStatusList: [],
      displayColorPicker: false,
      color: {
        r: '241',
        g: '112',
        b: '19',
        a: '1',
      },
      colorOriginal:{
        r: '241',
        g: '112',
        b: '19',
        a: '1',
      },
      counter: 0,
      copyId: '',
      selectedRows: []
    };
    this.toggle = this.toggle.bind(this);
    this.toggleLoad = this.toggleLoad.bind(this);
    this.copyJobStatus = this.copyJobStatus.bind(this);
    this.listImportableJobStatus = this.listImportableJobStatus.bind(this);
    this.handleRowSelection = this.handleRowSelection.bind(this);
  }

  componentDidMount() {
    this.list();
    this.JqueryInitiate();
    this.list('SUPERADMIN');    
  }

  JqueryInitiate(str) {
    if (str == 'copy') {
      var obj = this;
      $('#' + obj.state.copyId).parents('tr').empty().remove();
      if (obj.state.counter == 1) {
        this.toggleLoad();
      }
      this.setState({ counter: 0 });
    }
  }

  copyJobStatus(cell,row){
    return (<button className="btn btn-primary copyCode" id={row.jobStatusCodesName} onClick={()=>this.copyJobStatusCode(row)}>{row.action}</button>);
  }

  copyJobStatusCode(row) {
    this.setState({ counter: parseInt($('.copyCode').length) });
    this.setState({ copyId: row.jobStatusCodesName });
    let copyData = {};
    if (row === 'all') {
      copyData.data = {};
      copyData = { ...copyData, ...this.state.allJobStatusList };
    }
    else {
      copyData['data'] = {};      
      copyData['data'][0] = row;
    }
    for (let k in copyData['data']) {
      copyData['data'][k]['accountId'] = this.state.accountId;
      copyData['data'][k]['userId'] = this.state.userId;
    }
    this.props.copyJobStatusAction(copyData);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.allJobStatusList) {
      this.setState({ allJobStatusList: Object.values(nextProps.allJobStatusList).map((m => m)) });
    }

    if (nextProps.importableJobStatusList) {
      this.setState({ importableJobStatusList: Object.values(nextProps.importableJobStatusList).map((m => m)) });
    }

    

    if (nextProps.copyJobStatusList) {
      this.toggleLoad();
    }
    if (nextProps.allJobStatus) {
      this.setState({ fetchRequest: true });
    }

    if (nextProps.allJobStatus && nextProps.allJobStatus.length > 0) {
      var masterTypeArr = this.state.masterTypeArr;
        
        var listArr = []; 
        var listMaster = this.state.listMaster;         
      nextProps.allJobStatusList.map((v, i) => {
        if (v && v.isSystemMaster === true) {
          masterTypeArr.push(v.objectId);
        }

        if (v?.isSystemMaster === false && v?.trash === false){
          listArr.push(v);
        }

        if (v?.isSystemMaster === true) {          
          listMaster.push(v);
        }
        return true;
      });
      this.setState({
        masterTypeArr: masterTypeArr
      });
      this.setState({ list: listArr.map((p) => ({ ...p, disabled: p.isSystemMaster === true })), fetchRequest: true });
    }
    if (nextProps.success === 1 && nextProps.isCreatedCopy && nextProps.message !== '') {
      let _this = this;
      callAlertMessage("success", nextProps.message);
      this.JqueryInitiate('copy');
      if (_this.state.modal) {
        _this.setState({ modal: false });
      }
      this.list();
    }

    if (nextProps.isCreated !== this.state.isCreated && nextProps.isCreated === true) {
      this.setState({
        isCreated: true,
      });
      let _this = this;

      if (nextProps.success === 1 && this.state.successRedirect && nextProps.message !== '') {
        callAlertMessage('success', nextProps.message);
        if (_this.state.modal) {
          _this.setState({ modal: false });
        }
        this.list();
      }
      if (nextProps.success === 0 && nextProps.message !== '') {
        callAlertMessage('error', nextProps.message);
        if (_this.state.modal) _this.setState({ modal: true });

        this.list();
      }
    }
  }

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker })
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false })
  };

  handleChangeColor = (color) => {
    this.setState({ color: color.rgb })
    this.setState({ displayColorPicker: false })
  };

  list(type) {
    this.setState({list:[]})
    let data = {};
    data['className'] = 'jobStatusCodes';
    data['accountId'] = this.state.accountId;
    if(type==='SUPERADMIN'){
      this.setState({listMaster:[]})
      data['type'] = 'SUPERADMIN';
    }
    this.props.getAllJobStatusAction(data);
  }

  toggle(val) {
    if (val === 'POST') {
      this.setState({ formData: {} }); //Open modal htmlFor add
    } else {
      this.setState({ saveType: 'PUT' });
      this.setState({ formData: this.state.formData }); //Open modal htmlFor edit
      
      
      if (this.state.formDataMaster.jobColorCode) {
        this.setState({ colorOriginal: this.state.formDataMaster.jobColorCode })
      }
      
      if (this.state.formData.jobColorCode) {
        this.setState({ color: this.state.formData.jobColorCode })
      }
      else {
        let tempColor = {};
        tempColor.r = 0.5;
        tempColor.g = 0;
        tempColor.b = 0.5;
        tempColor.a = 1;
        this.setState({ color: tempColor });
      }
    }
    this.setState({ modal: !this.state.modal, formArr: [] });
  }

  listImportableJobStatus() {
    let data = {};
    data['className'] = "jobStatusCodes";
    data['accountId'] = this.state.accountId;
    data['userId'] = this.state.userId;
    this.props.listImportableJobStatusAction(data);
    this.toggleLoad()
  }

  toggleLoad() {
    this.setState({ modalLoad: !this.state.modalLoad });
  }

  selectedRow1 = (row) => {
    let _this = this;
    let formDataTemp = {...row}
    let listMaster = _this.state.listMaster;    
    let obj = listMaster.find(o => o.objectId === formDataTemp.parentObjectId);
    this.setState({ formData:formDataTemp,formDataMaster:{...obj} });
    setTimeout( () => {
      _this.toggle("PUT");
    }, 100);
  }

  addJobStatus(event) {
    event.preventDefault();
    let formData = this.state.formData;
    formData['className'] = 'jobStatusCodes';
    formData['userId'] = this.state.userId;
    formData['accountId'] = this.state.accountId;
    formData['jobColorCode'] = this.state.color;

    formData['isSystemMaster'] = false; // Only ever add non-admin

    if (this.allValidate(false)) {
      if (this.state.saveType === 'POST') {
        this.props.createJobStatusAction(formData);
      } else {
        this.props.updateJobStatusAction(formData);
      }
      this.setState({ successRedirect: true, isCreated: false });
    }
  }

  allValidate(check) {
    if (!check) {
      formArr = [];
      Object.keys(rules).forEach(function (key) {
        formArr[key] = 'TT';
      });
      this.setState({
        formArr,
      });
    }
    if (validation.passes()) {
      return 1;
    }
  }

  handleChange = (e) => {
    e.preventDefault();
    let formDataTemp = {}
    formDataTemp = this.state.formData;
    formDataTemp[e.target.name] = e.target.value;

    if (e.target.name === 'jobStatusCodesName') {
      formDataTemp[e.target.name] = UpperCaseName(e.target.value);
    }
    this.setState({ formData:formDataTemp });
  };

  handleDeleteButtonClick = () => {
    let data = {};
    const { selectedRows, list } = this.state;
    const keysToDelete = list.filter((f, index) => selectedRows.includes(index.toString())).map((k) => ({
      objectId: k.objectId
    }));
    data['className'] = 'jobStatusCodes';
    data['dataArr'] = keysToDelete;
    const confirm = window.confirm("Are you sure to delete?");
    if(confirm) {
      this.props.deleteJobStatusAction(data);
      this.setState({ successRedirect: true, isCreated: false });
    } else {
      console.log({ confirm });
    }
  };

  handleRowSelection = (ids) => {
    this.setState({ selectedRows: ids });
  }

  _setTableOption() {
    if (this.state.fetchRequest) {
      return 'No data found..';
    } else {
      return (
        <ReactLoading
          className="table-loader"
          type={appConstants.LOADER_TYPE}
          color={appConstants.LOADER_COLOR}
          height={appConstants.LOADER_HEIGHT}
          width={appConstants.LOADER_WIDTH}
        />
      );
    }
  }

  displayColors(cell, row, enumObject, rowIndex) {
    if (row && row.jobColorCode) {
      let colorRGB = ('rgba' + '(' + row.jobColorCode.r + ',' + row.jobColorCode.g + ',' + row.jobColorCode.b + ',' + row.jobColorCode.a + ')');
      return (<div className="color-box" style={{ backgroundColor: colorRGB, minHeight: '20px', borderRadius: '10px' }}></div>);
    }
    return '-';
  }

  editJobStatusCol(cell, row, enumObject, rowIndex) {
    return (<div><i className="fa fa-pencil"></i></div>);
  }

  copyActionCall = (e,name) => {
    let formDataTemp = this.state.formData;
    formDataTemp[name] = this.state.formDataMaster[name]
    this.setState({formData:formDataTemp})

    if(name==='jobColorCode'){
      if(this.state.formDataMaster[name]){        
        this.setState({ color: this.state.formDataMaster[name] })
      }else{
        this.setState({ color: this.state.colorOriginal })
      }
      
    }
    
  }
  

  render() {

    const styles = reactCSS({
      'default': {
        colorCurrent: {
          width: '36px',
          height: '14px',
          borderRadius: '5px',
          background: `rgba(${this.state.color.r}, ${this.state.color.g}, ${this.state.color.b}, ${this.state.color.a})`,
        },
        colorOriginal: {
          width: '36px',
          height: '14px',
          borderRadius: '5px',
          background: `rgba(${this.state.colorOriginal.r}, ${this.state.colorOriginal.g}, ${this.state.colorOriginal.b}, ${this.state.colorOriginal.a})`,
        },

        swatch: {
          padding: '5px',
          background: '#fff',
          borderRadius: '1px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer',
        },
        popover: {
          position: 'absolute',
          zIndex: '2',
        },
        cover: {
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px',
        },
      },
    });

    validation = new Validator(this.state.formData, rules, mess);
    validation.passes();
    validation.fails();

    const selectRowProp = {
      mode: 'checkbox',
      unselectable: this.state.masterTypeArr,
    };


    //show option, delete, pagination, number of data, search
    let tableOption = appConstants.TableOption;
    tableOption['afterDeleteRow'] = this.handleDeleteButtonClick;
    tableOption['paginationShowsTotal'] = this.renderShowsTotal;
    tableOption['onRowClick'] = this.selectedRow1;
    tableOption['noDataText'] = this._setTableOption();

    const columns = [
      {
        Header: "Code",
        accessor: "jobStatusCodesCode",
        width: 160
      },
      {
        Header: "Name",
        accessor: "jobStatusCodesName",
        width: 260
      },
      {
        Header: "Definition",
        accessor: "jobStatusCodesDefinition",
        width: 200
      },
      {
        Header: "Display Color",
        accessor: "jobColorCode",
        disableSortBy: true,
        width: 210,
        Cell: (props) => this.displayColors(props.column.id, props.row.original, props.value)
      },
      {
        Header: "Edit Color",
        accessor: "jobColorCode1",
        disableSortBy: true,
        width: 90,
        Cell: (props) => this.editJobStatusCol(props.column.id, props.row.original, props.value)
      },
    ];

    const importJobColumns = [
      {
        Header: "Code",
        accessor: "jobStatusCodesCode",
        width: 160
      },
      {
        Header: "Name",
        accessor: "jobStatusCodesName",
        width: 260
      },
      {
        Header: "Action",
        accessor: "action",
        width: 200,
        Cell: (props) => this.copyJobStatus(props.column.id, props.row.original, props.value)
      }
    ];

    return (
      <div className="ContactListReport">
        <TitleComponent data={{title:`Job Status Code Settings`}}/>
        <Suspense fallback="loading"><Header props={this.props} /></Suspense>
        <div className="contactBox">
          <div className="container">
            <div className="row">
              <div className="col-sm-2">
                <div className="sidebarBox">
                  <SidebarSettings props={this.props} />
                </div>
              </div>
              <div className="col-sm-10">
                <div className="rightTableBox">
                  <div id="content" className="tab-content" role="tablist">
                    <div id="pane-1" className="pane" role="tabpanel" aria-labelledby="tab-1">
                      <div id="collapse-1" className="" role="tabpanel" aria-labelledby="heading-1">
                        <div className="cardBody">
                          <div className="headingbox">
                            <h2 className="heading2">Job Status Codes</h2>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <button className="btn btnRight" onClick={() => this.listImportableJobStatus('POST')}>
                                Import Status Codes
                              </button>
                              {/*<button className="btn btnRight" onClick={() =>this.toggle('POST')}>Add</button>*/}
                              <button className="btn btn-primary" style={{cursor: 'pointer', padding: '5px'}} disabled={this.state.selectedRows?.length < 1} onClick={() => this.handleDeleteButtonClick()}>
                                <i className="fa fa-trash" aria-hidden="true"></i>&nbsp;Delete
                              </button>
                              <CustomTable
                                  columns={columns}
                                  list={this.state.list}
                                  isLoading={!this.state.fetchRequest}
                                  canShowColumnSettings={false}
                                  canShowDateFilter={false}
                                  canShowExport={false}
                                  canShowPagination={false}
                                  onSavePreferences={()=>{}}
                                  canShowRowSelection={true}
                                  onChangeRowSelection={this.handleRowSelection}
                                  onRowClicked={(cell, row, e) => this.selectedRow1(row.original)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />

        <Modal
          backdrop="static"
          keyboard={true}
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggle}>
            {this.state.formData.objectId ? 'Edit Job Status' : 'Add Job Status'}
          </ModalHeader>
          <ModalBody>
            <div className="container">
              <div className="row">
                <div className="col-md-8">
                  <div className="form-group p-rel">
                    <label htmlFor="jobStatusCodesName">
                      Name<span className="requiredField">*</span>
                    </label>
                    <input
                      type="text"
                      disabled={true}
                      className="form-control"
                      name="jobStatusCodesName"
                      id="jobStatusCodesName"
                      placeholder="Name"
                      value={this.state.formData.jobStatusCodesName}
                    />
                    <span style={styleData}>
                      {this.state.formArr.jobStatusCodesName &&
                        validation.errors.first('jobStatusCodesName')}
                    </span>
                  </div>{' '}
                </div>
                
                <div className="col-md-4">
                  <div className="form-group p-rel">
                    <label htmlFor="jobStatusCodesCode">
                      Code<span className="requiredField">*</span>
                    </label>
                    <input
                      type="text"
                      disabled={true}
                      className="form-control"
                      name="jobStatusCodesCode"
                      id="jobStatusCodesCode"
                      placeholder="Code"
                      value={this.state.formData.jobStatusCodesCode}
                    />
                    <span style={styleData}>
                      {this.state.formArr.jobStatusCodesCode &&
                        validation.errors.first('jobStatusCodesCode')}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group p-rel" >
                    <label htmlFor="jobStatusCodesDefinition">Definition</label>
                    <textarea                     
                      type="text"
                      className="form-control"
                      name="jobStatusCodesDefinition"
                      id="jobStatusCodesDefinition"
                      placeholder="Definition"
                      value={this.state.formData.jobStatusCodesDefinition}
                      onChange={(e) => {
                        this.handleChange(e);
                      }}
                    />
                    <span style={styleData}>
                      {this.state.formArr.jobStatusCodesDefinition &&
                        validation.errors.first('jobStatusCodesDefinition')}
                    </span>
                  </div>
                </div>
                <div className="col-md-1">
                  <div className="form-group p-rel" >
                    <span className='arrow-ic' onClick={e=>this.copyActionCall(e,'jobStatusCodesDefinition')}>
                      <i className="fa fa-arrow-left" aria-hidden="true"></i>
                    </span>
                  </div>
                </div>
              
              
                <div className="col-md-5">
                  <div className="form-group p-rel" >
                      <label htmlFor="jobStatusCodesDefinition">Default Description</label>
                      <textarea
                        rows="5"
                        disabled={true}
                        type="text"
                        className="form-control"
                        name="jobStatusCodesDefinition"
                        id="jobStatusCodesDefinition"
                        placeholder="Status not available"
                        value={this.state.formDataMaster.jobStatusCodesDefinition}
                      />
                    </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group p-rel" >
                  <label htmlFor="jobStatusCodesDefinition">Display Color</label><br></br>
                      <div className="ml-2" style={styles.swatch} onClick={this.handleClick}>
                        <div style={styles.colorCurrent} />                        
                      </div>
                      {this.state.displayColorPicker && 
                        <div style={styles.popover}>
                          <div style={styles.cover} onClick={this.handleClose} />
                          <SwatchesPicker color={this.state.formData.jobColorCode} onChangeComplete={this.handleChangeColor} /> 
                        </div>
                      }
                  </div>
                </div>
                <div className="col-md-1">
                  <div className="form-group p-rel" >
                    <span className='arrow-ic' style={{"top":"7px"}} onClick={e=>this.copyActionCall(e,'jobColorCode')} >
                      <br/><i className="fa fa-arrow-left" aria-hidden="true"></i>
                    </span> 
                  </div>
                </div>
            
                <div className="col-md-5">
                  <div className="form-group p-rel" >
                    <label htmlFor="jobStatusCodesDefinition">Default Color</label><br/>
                        <div className="ml-8" style={styles.swatch} >
                          <div style={styles.colorOriginal} />                      
                        </div>
                       
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button className="btn btn-primary" onClick={this.toggle}>
              Cancel
            </button>
            <button className="btn btn-primary" onClick={(e) => this.addJobStatus(e)}>
              Submit
            </button>
          </ModalFooter>
        </Modal>

        <Modal backdrop="static" keyboard={true} isOpen={this.state.modalLoad} toggle={this.toggleLoad} className={this.props.className}>
          <ModalHeader toggle={this.toggleLoad}>{'Enable Job Status'}</ModalHeader>
          <ModalBody>
            <div className="container">
              <div className="row">
                <CustomTable  
                  columns={importJobColumns}
                  list={this.state.importableJobStatusList}
                  isLoading={!this.state.fetchRequest}
                  canShowColumnSettings={false}
                  canShowDateFilter={false}
                  canShowExport={false}
                  canShowPagination={false}
                  onSavePreferences={()=>{}}
                  canShowRowSelection={false}
                  onRowClicked={(cell, row, e) => this.selectedRow1(row.original)}
                />
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button className="btn btn-primary hideCopy" onClick={this.toggleLoad}>Cancel</button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    allJobStatusList: state.jobStatusCode.allUsedJobStatus,
    allJobStatus: state.jobStatusCode.allJobStatus,
    importableJobStatusList: state.jobStatusCode.importableJobStatusList,
    isCreated: state.jobStatusCode.isCreated,
    message: state.jobStatusCode.message,
    success: state.jobStatusCode.success,
    isCreatedCopy: state.jobStatusCode.isCreatedCopy
  };
}

export default withRouter(
  connect(mapStateToProps, {
    copyJobStatusAction,
    listImportableJobStatusAction,
    createJobStatusAction,
    getAllJobStatusAction,
    updateJobStatusAction,
    deleteJobStatusAction,
  })(JobStatusLegacy)
);
