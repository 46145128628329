import React, { Suspense } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Header from '../../Layout/Header.js';
import Footer from '../../Layout/Footer.js';
import { connect } from 'react-redux';
import { appConstants } from '../../../_constants';
import {
  GetDeliveredProjectReportAction,
  GetDeliveredProjectReportPrefAction,
  SetDeliveredProjectReportPrefAction,
} from '../../../actions/reports';

import TitleComponent from '../../Common/TitleComponent';
import { CustomTable, multiSelectFilter, customSortByKey } from '../../Common/CustomTable';
import { SelectColumnFilter } from '../../Common/CustomTableComponents/SelectColumnFilter';
import { DateColumnFilter } from '../../Common/CustomTableComponents/DateColumnFilter';
import {
  formatExternalContactData,
  formatInternalContactData,
  formatAddressText,
} from '../../../utils/contact-formatters';
import {
  formatDate,
  formatDuration,
} from '../../../utils/date-formatters';
import { matchDataForTable } from '../../../utils/match-for-table.jsx';

import { getAllProjectRoleAction } from '../../../actions/projectRole';
import { getAllProjectFieldsAction } from '../../../actions/projectFields';
import { isAllowed, moduleConstants } from '../../../_constants';
import {buildColumnForField, buildDynamicColumns, onCurrency} from '../../../utils/column-formatters';


let dataArr = [];
let paginationButton = [];
let lastBtn = 0;
var sortingOrder = '';
var sortOrders = 'desc';

class DeliveredProjectReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accountId: localStorage.getItem('accountId'),
      userId: localStorage.getItem('userId'),
      list: [],
      fetchRequest: true,
      comLogo: '',
      totalCount: 0,
      activeButton: 1,
      sortType: 'DESC',
      sortKey: '',
      contactData: {},
      projectRoleTypes: [],
      allProjectFieldTypes: [],
      dynamicColumns: [],
    };
    ///this.onSortChange = this.onSortChange.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps?.projectRoles?.allProjectRoleTypes) {
      this.setState({
        projectRoleTypes: nextProps.projectRoles.allProjectRoleTypes,
      });
    }

    if (nextProps?.projectFields?.allProjectFieldTypes?.length > 0) {
      this.setState({
        allProjectFieldTypes: nextProps.projectFields.allProjectFieldTypes,
      });
    }

    if (nextProps.jobScope) {
        this.setState({
          jobScope: nextProps.jobScope,
        });
      }

    if (nextProps.listData && !this.state.dataForExcel && !this.state.dataForPdf) {
      dataArr = [];
      if (nextProps.listData && nextProps.listData && nextProps.listData.success === 1) {
        let fieldsToMatch = {
          projectAssignments: { lookupLocation: 'contactData' },
        };

        let matchedDataForTable = matchDataForTable(
          fieldsToMatch,
          nextProps.listData.data,
          nextProps.listData.contactData,
          this.props
        );

        this.setState({
          list: matchedDataForTable,
          totalCount: nextProps.listData.count,
          contactData: nextProps.listData.contactData,
          fetchRequest: false,
        });
      }
      
      if (nextProps.listData && nextProps.listData.data && nextProps.listData.data.length == 0) {
        this.setState({ fetchRequest: false });
      }
    } else {
      dataArr = [];
      this.setState({ list: [] });
    }
  }

  componentDidMount() {
    let data = {};
    data['accountId'] = this.state.accountId;
    data['moduleName'] = appConstants.reportModuleName['23'];
    data['page'] = 0;
    data['displayLimit'] = 99999999;
    data['sortKey'] = this.state.sortKey;
    data['sortType'] = this.state.sortType;
    this.props.GetDeliveredProjectReportAction(data);

    this.props.getAllProjectRoleAction({
      className: 'projectRoleTypes',
      accountId: this.state.accountId,
    });

    this.props.getAllProjectFieldsAction({
      accountId: this.state.accountId,
      minimumStage: 'post-construction'
    });

    sortingOrder = '';

    if (
      localStorage.getItem('companyLogo') !== 'undefined' &&
      localStorage.getItem('companyLogo') !== null
    ) {
      this.setState({
        comLogo: localStorage.getItem('companyLogo'),
      });
    } else {
      let constImage = appConstants.CONTENT_CPOST_LOGO_URL;
      this.setState({ comLogo: constImage });
    }
    this.props.GetDeliveredProjectReportPrefAction();
  }

  buildColumnForRole(filteredRoles, roleCode, hidden = false) {
    const foundRole = filteredRoles.find((role) => role.code == roleCode);
    if (!foundRole) return;
    const isInternal = foundRole && foundRole.type === 'internal';

    if (foundRole && isInternal) {
      return (
        foundRole && {
          Header: foundRole?.shortName ?? roleCode,
          LongHeader: foundRole?.name,
          accessor: 'projectAssignmentsLabel' + roleCode,
          sortType: customSortByKey,
          sortKey: foundRole?.name ?? roleCode,
          Filter: SelectColumnFilter,
          filter: multiSelectFilter,
          width: 60,
          hidden: hidden,
          Cell: (props) => (
            <>
              {this.formatContactDataInternal(
                'projectAssignmentsMatched' + roleCode,
                props.row.original
              )}
            </>
          ),
        }
      );
    } else {
      return (
        foundRole && {
          Header: foundRole?.shortName ?? roleCode,
          LongHeader: foundRole?.name,
          accessor: 'projectAssignmentsLabel' + roleCode,
          sortType: customSortByKey,
          sortKey: foundRole?.name ?? roleCode,
          Filter: SelectColumnFilter,
          filter: multiSelectFilter,
          width: 60,
          hidden: hidden,
          Cell: (props) => (
            <>
              {this.formatContactDataExternal(
                'projectAssignmentsMatched' + roleCode,
                props.row.original
              )}
            </>
          ),
        }
      );
    }
  }

  formatContactDataInternal(cell, row) {
    if (row[cell] && row[cell].length > 0) {
      return formatInternalContactData(row[cell], this.state.contactData);
    } else {
      return <span>-</span>;
    }
  }

  formatContactDataExternal(cell, row) {
    if (row[cell] && row[cell].length > 0) {
      return formatExternalContactData(row[cell], this.state.contactData, false);
    } else {
      return <span>-</span>;
    }
  }

  redirectToProject(id, cell, row) {
    return <span>cell</span>;
  }


  renderSubRow(row) {
    const clientDetails = row['projectAssignmentsMatchedCL'];
    const billTo = row['projectAssignmentsMatchedBT'];

    const clName = this.state.projectRoleTypes.find((role) => role.code === 'CL')?.name ?? '';
    const btName = this.state.projectRoleTypes.find((role) => role.code === 'BT')?.name ?? '';

    return (
      <div className='container'>
        <div className='row'>
          <div className='col-6'>
            <div className='d-flex'>
              <p className='m-0 pr-3 flex-shrink-0'>
                <strong>{clName}:</strong>&nbsp;
              </p>
              {formatExternalContactData(clientDetails, this.state.contactData, true)}
            </div>
          </div>
          <div className='col-6'>
            <div className='d-flex'>
              <p className='m-0 pr-3 flex-shrink-0'>
                <strong>{btName}:</strong>&nbsp;
              </p>
              {formatExternalContactData(billTo, this.state.contactData, true)}
            </div>
          </div>
        </div>
      </div>
    );
  }

  createCustomButtonGroup = (props) => {
    return (
      <div className='pdfLogoBox'>
        <div className='reportHead'>
          <div className='logo'>
            <img src={this.state.comLogo} alt='Upload Logo' />
          </div>
          <h2>Delivered Projects Report</h2>
        </div>
      </div>
    );
  };

  renderArrayNames(key, row) {
    return (
      <>
        <Link to={`/project-details/${row?.objectId}`} data-tooltip-content={row[key]} data-tooltip-id={row[key]}>
          {row[key]}
        </Link>
      </>
    );
  }

  renderDate(key, row) {
    if (row && typeof row[key] === 'object') {
      return row[key] && row[key]['iso'] ? formatDate(row[key]['iso']) : '';
    } else {
      return <span>-</span>;
    }
  }

  onDuration(cell, row, enumObject, rowIndex) {
    if (row && row.endDate?.iso && row.deliveredDate?.iso) {
      let duration = formatDuration(row.endDate, row.deliveredDate);
      return duration ? duration + 'd' : <span>-</span>;
    } else {
      return <span>-</span>;
    }
  }


  buildReportColumns() {
    const hasContractValPermissions = isAllowed(moduleConstants.VIEWCONTRACTVAL, 'anyAssigned');

    const { list, allProjectFieldTypes } = this.state;

    const dynamicFieldsLoaded = allProjectFieldTypes?.length > 0;

    const defaultFieldCodes = ['SCOPE'];

    const customFieldColumns = dynamicFieldsLoaded ? buildDynamicColumns(allProjectFieldTypes.filter(field => !defaultFieldCodes.includes(field.code))) : [];

    const defaultFields = dynamicFieldsLoaded ? allProjectFieldTypes.filter(field => defaultFieldCodes.includes(field.code)) : [];

    
    const isLeadType = false;

    const rolesLoaded = this.state.projectRoleTypes?.length > 0;

    const defaultRolesCodes = ['PM', 'SI', 'EST', 'CL', 'BT'];

    const dynamicColumnsLoaded = dynamicFieldsLoaded && rolesLoaded;

    const enabledRoles = this.state.projectRoleTypes.filter(
      (role) => !defaultRolesCodes.includes(role.code) && (!isLeadType || role.isShowOnLeads)
    );
    const defaultRoles = this.state.projectRoleTypes.filter(
      (role) => defaultRolesCodes.includes(role.code) && (!isLeadType || role.isShowOnLeads)
    );

    const defaultColumns = [
      {
        Header: () => null,
        accessor: 'objectId',
        id: 'expander',
        className: 'no-print',
        disableSortBy: true,
        Cell: ({ row }) => {
          return (
            <span {...row.getToggleRowExpandedProps()} className='text-center'>
              {row.isExpanded ? (
                <i className='fa fa-minus-square-o no-print' />
              ) : (
                <i className='fa fa-plus-square-o no-print' />
              )}
            </span>
          );
        },
        width: 18,
      },
      {
        Header: 'Project#',
        accessor: 'jobNumFormatted',
        sortType: 'basic',
        width: 90,
        Cell: (props) => (
          <>
            <Link
              to={`/project-details/${props?.row?.original?.objectId}`}
              data-tooltip-content={props.value}
              data-tooltip-id={props.value}
            >
              {props?.value || ''}
            </Link>
          </>
        ),
      },
      {
        Header: 'Alt Job#',
        accessor: 'altJobNum',
        sortType: 'basic',
        width: 90,
        Cell: (props) => (
          <>
            <Link
              to={`/project-details/${props?.row?.original?.objectId}`}
              data-tooltip-content={props.value}
              data-tooltip-id={props.value}
            >
              {props.value}
            </Link>
          </>
        ),
      },

      {
        Header: 'Project Name',
        accessor: 'jobName',
        sortType: 'basic',
        width: 210,
        Cell: (props) => (
          <>{this.renderArrayNames(props.column.id, props.row.original, props.value)}</>
        ),
      },
      {
        Header: 'Site Address',
        accessor: (row) => formatAddressText(row),
        sortType: 'basic',
        width: 100,
        Cell: (props) => (
          <>
            <Link
              target='_blank'
              rel='noopener noreferrer'
              to={{
                pathname: `https://maps.google.com/?q=` + formatAddressText(props.row.original),
              }}
              data-tooltip-content={props.value}
              data-tooltip-id={props.value}
            >
              {props.value}
            </Link>
          </>
        ),
      },
      hasContractValPermissions&&{
        Header: 'Contract Amt.',
        accessor: 'currentContractAmount',
        sortType: 'basic',
        width: 120,
        Cell: (props) => (
          <>{onCurrency(props.column.id, props.row.original, props.value)}</>
        ),
      },
      this.buildColumnForRole(defaultRoles, 'PM'),
      this.buildColumnForRole(defaultRoles, 'SI'),
      this.buildColumnForRole(defaultRoles, 'EST'),

      {
        Header: 'Status',
        accessor: 'jobStatusCodesCode',
        sortType: 'basic',
        Filter: SelectColumnFilter,
        width: 80,
      },
      buildColumnForField(defaultFields, 'SCOPE', false),
      {
        Header: 'Start Date',
        accessor: (row) =>
          row?.startDate?.iso
            ? new Date(row.startDate.iso)
            : new Date(formatDate('1800-03-03T12:47:40.000Z')),
        id: 'startDate',
        width: 95,
        Cell: (props) => <>{this.renderDate(props.column.id, props.row.original)}</>,
        filter: DateColumnFilter,
        allowDateRangeFilter: true,
        sortType: 'datetime',
      },
      {
        Header: 'End Date',
        accessor: (row) =>
          row?.endDate?.iso
            ? new Date(row.endDate.iso)
            : new Date(formatDate('1800-03-03T12:47:40.000Z')),
        id: 'endDate',
        width: 95,
        Cell: (props) => <>{this.renderDate(props.column.id, props.row.original)}</>,
        filter: DateColumnFilter,
        allowDateRangeFilter: true,
        sortType: 'datetime',
      },
      {
        Header: 'Delivered Date',
        accessor: (row) =>
          row?.deliveredDate?.iso
            ? new Date(row.deliveredDate.iso)
            : new Date(formatDate('1800-03-03T12:47:40.000Z')),
        id: 'deliveredDate',
        width: 95,
        Cell: (props) => <>{this.renderDate(props.column.id, props.row.original)}</>,
        filter: DateColumnFilter,
        allowDateRangeFilter: true,
        sortType: 'datetime',
      },
      {
        Header: 'Variance',
        accessor: (row) => formatDuration(row.deliveredDate, row.endDate),
        id: 'deliveredVar',
        width: 95,
        Cell: (props) => <>{this.onDuration(props.column.id, props.row.original)}</>,
      },
    ];

    let columns = [];
    if (enabledRoles) {
      const extraColumns = enabledRoles.map((role) =>
        this.buildColumnForRole([role], role.code, true)
      );

      if (columns.length == 0) {
        columns = defaultColumns.filter((cols) => cols?.accessor);
      }

      for (let i in extraColumns) {
        const curColumnsAccessors = columns.map((cols) => cols.accessor);
        if (!curColumnsAccessors.includes(extraColumns[i].accessor)) {
          columns = columns.concat(extraColumns[i]);
        }
      }

      for (let i in customFieldColumns) {
        const curColumnsAccessors = columns.map((cols) => cols.accessor);
        if (!curColumnsAccessors.includes(customFieldColumns[i].accessor)) {
          columns = columns.concat(customFieldColumns[i]);
        }
      }
    } else {
      columns = defaultColumns.filter((cols) => cols?.accessor);
    }

    this.setState({ dynamicColumns: columns });

  }

  
  render() {
  
    const {
      list, allProjectFieldTypes, dynamicColumns
    } = this.state;


    const dynamicFieldsLoaded = allProjectFieldTypes?.length > 0;

    const rolesLoaded = this.state.projectRoleTypes?.length > 0;

    const dynamicColumnsLoaded = dynamicFieldsLoaded && rolesLoaded;

    
    const dynamicColumnsBuilt = dynamicColumnsLoaded && dynamicColumns.length > 0;

    if(dynamicColumnsLoaded && !dynamicColumnsBuilt){
      this.buildReportColumns();
    }
    
    
    const { listPref, SetDeliveredProjectReportPrefAction } = this.props;
    const initialExpandedRows = list.map((_, index) => ({ [index]: true }));

    return (
      <div>
        <Suspense fallback='loading'>
          <div className='ContactListReport'>
            <TitleComponent data={{ title: `Delivered Projects` }} />
            <div>
              <Header props={this.props} />
            </div>

            <div className='contactBox'>
              <div className='container'>
                <div className='row no-break-print'>
                  {dynamicColumnsBuilt && (
                    <div className='col-md-12'>
                      <div
                        className='table-responsive reportTableSection'
                        ref={(el) => (this.componentRef = el)}
                      >
                        {this.createCustomButtonGroup()}
                        <CustomTable
                          columns={dynamicColumns}
                          list={list}
                          preferences={listPref}
                          className='delivered-project-table'
                          customTableTitle='Delivered Project Report'
                          onChangeRowSelection={this.onChangeRowSelection}
                          onSavePreferences={SetDeliveredProjectReportPrefAction}
                          isLoading={this.state.fetchRequest}
                          canShowRowSelection={false}
                          renderRowSubComponent={(props) => (
                            <>{this.renderSubRow(props?.row?.original)}</>
                          )}
                          //initialExpanded={initialExpandedRows}
                          componentRef={this.componentRef}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </Suspense>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    listData: state.reports.deliveredReportList,
    listPref: state.reports.deliveredReportListPref,
    projectRoles: state.projectRoleType,
    projectFields: state.projectFields,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    GetDeliveredProjectReportAction,
    GetDeliveredProjectReportPrefAction,
    SetDeliveredProjectReportPrefAction,
    getAllProjectRoleAction,
    getAllProjectFieldsAction
  })(DeliveredProjectReport)
);
