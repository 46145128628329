import { useMemo } from 'react';

const ProjectRolesFilter = ({ column: { filterValue, setFilter, preFilteredRows, id } }: any) => {
  const columnValues = useMemo(() => {
    const values = [] as any;
    preFilteredRows.forEach((row) => {
      values.push(row.original[id]);
    });

    return [...values.flat()];
  }, [id, preFilteredRows]);

  const options = useMemo(() => {
    const result = columnValues.map((value) => {
      if (!value) {
        return {
          label: '-',
          value: '',
        };
      }

      const {
        contactId,
        companyId,
        companyName,
        contactFirstName,
        contactMiddleName,
        contactLastName,
        contactAffiliationId,
      } = value;
      let fullName = `${contactFirstName || ''} ${contactLastName || ''}`;
      if (contactMiddleName) {
        fullName = `${contactFirstName || ''} ${contactMiddleName[0]}. ${contactLastName || ''}`;
      }
      fullName = fullName.trim();

      if (contactId && companyId) {
        return {
          label: `${companyName} - ${fullName}`,
          value: contactAffiliationId,
        };
      }

      if (contactId && !companyId) {
        return {
          label: fullName,
          value: contactAffiliationId,
        };
      }

      if (!contactId && companyId) {
        return {
          label: companyName,
          value: contactAffiliationId,
        };
      }

      return {
        label: '-',
        value: contactAffiliationId,
      };
    });

    // return unique options by value

    return [...(new Map(result.map((item) => [item['value'], item])).values() as any)].sort(
      (a, b) => a.label?.localeCompare(b.label)
    );
  }, [columnValues]);

  return (
    <select
      className='w-100 no-print'
      onChange={(e) => {
        setFilter(e.target.value || undefined, 'contactAffiliationId');
      }}
      value={filterValue || ''}
    >
      <option value=''>All</option>
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

const handleProjectRolesFilter = (rows: any[], idArr: string[], filterValue: any) => {
  const id = idArr[0];
  return rows.filter((row) => {
    const cellValues = row.original[id] || [];

    return cellValues.some((value) => {
      return value.contactAffiliationId === filterValue;
    });
  });
};

const CodesFilter = (
  { column: { filterValue, setFilter, preFilteredRows, id } }: any,
  dataColumn: any
) => {
  const columnValues = useMemo(() => {
    const values = [] as any;
    preFilteredRows.forEach((row) => {
      values.push(row.original[id]);
    });

    return [...values.flat().filter((item) => item)];
  }, [id, preFilteredRows]);

  const options = useMemo(() => {
    const result = columnValues.map((value) => {
      const labels = dataColumn.options?.find((option) => option.value === value);
      return {
        label: `${labels?.labelField ?? ''} (${labels?.codeField ?? ''})`,
        value,
      };
    });

    // return unique options by value

    return [...(new Map(result.map((item) => [item['value'], item])).values() as any)].sort(
      (a, b) => a.label?.localeCompare(b.label)
    );
  }, [columnValues]);

  return (
    <select
      className='w-100 no-print'
      onChange={(e) => {
        setFilter(e.target.value || undefined, 'value');
      }}
      value={filterValue || ''}
    >
      <option value=''>All</option>
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

const CustomFieldsArrayFilter = (
  { column: { filterValue, setFilter, preFilteredRows, id } }: any,
  dataColumn
) => {
  const columnValues = useMemo(() => {
    const values = [] as any;
    preFilteredRows.forEach((row) => {
      values.push(row.original[id]?.value);
    });

    return [...values.flat().filter((item) => item)];
  }, [id, preFilteredRows]);

  const options = useMemo(() => {
    const dropdownOptions = dataColumn.customFieldDefinition?.valueDefinition?.options;
    const hasDataSrc = dataColumn.customFieldDefinition?.valueDefinition?.hasDataSrc;

    const result = columnValues.map((value) => {
      const labels = dropdownOptions?.find((option) => option.value === value);
      return {
        label: hasDataSrc
          ? `${labels?.labelField ?? ''} (${labels?.codeField ?? '-'})`
          : labels?.label,
        value,
      };
    });

    return [...(new Map(result.map((item) => [item['value'], item])).values() as any)].sort(
      (a, b) => a.label?.localeCompare(b.label)
    );
  }, [columnValues]);

  return (
    <select
      className='w-100 no-print'
      onChange={(e) => {
        setFilter(e.target.value || undefined, 'value');
      }}
      value={filterValue || ''}
    >
      <option value=''>All</option>
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

const handleCustomFieldsArrayFilter = (rows: any[], idArr: string[], filterValue: any) => {
  const id = idArr[0];
  return rows.filter((row) => {
    const cellValues = Array.isArray(row.original[id]?.value)
      ? [...(row.original[id]?.value as any)]
      : [row.original[id]?.value];

    return cellValues.some((value) => {
      return value === filterValue;
    });
  });
};

const handleCodesFilter = (rows: any[], idArr: string[], filterValue: any) => {
  const id = idArr[0];
  return rows.filter((row) => {
    const cellValues = Array.isArray(row.original[id] )
      ? [...(row.original[id] as any)]
      : [row.original[id]];

    return cellValues.some((value) => {
      return value === filterValue;
    });
  });
};

const SingleSelectTextFilter = ({ column: { filterValue, setFilter, preFilteredRows, id } }: any) => {

  const options = useMemo(() => {
    let optionsArr = [] as string[];
    preFilteredRows.forEach((row) => {
      const rowVals = row.values[id];
      const isArray = Array.isArray(rowVals);
      if (isArray) {
        rowVals.forEach((val) => {
          optionsArr.push(val?.Label ?? val);
        });
      } else if (rowVals) {
        optionsArr.push(String(rowVals));
      }
    });
    optionsArr.filter(Boolean).sort();
    optionsArr = Array.from(new Set(optionsArr));
    return optionsArr;
  }, [id, preFilteredRows]);

  // return unique options by value
  return (
    <select
      className='w-100 no-print'
      onChange={(e) => {
        setFilter(e.target.value || undefined, 'value');
      }}
      value={filterValue || ''}
    >
      <option value=''>All</option>
      {options.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
};




const handleDateFilter = (rows: any[], id: string, filterValue: any[]) => {
  if (!filterValue) {
    return rows;
  }

  return rows.filter((row) => {
    const value = row.original[id];
    const date = new Date(value);
    const startDate = new Date(filterValue[0]);
    const endDate = new Date(filterValue[1]);

    return date >= startDate && date <= endDate || !value;
  });
};

const handleCustomDateFilter = (rows: any[], id: string, filterValue: any[]) => {
  if (!filterValue) {
    return rows;
  }

  return rows.filter((row) => {
    const value = row.original[id]?.value;
    const date = new Date(value);
    const startDate = new Date(filterValue[0]);
    const endDate = new Date(filterValue[1]);

    return date >= startDate && date <= endDate || !value;
  });
};

const CustomFieldsBooleanFilter = ({ column: { filterValue, setFilter } }: any) => {
  return (
    <select
      className='w-100 no-print'
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      value={filterValue || ''}
    >
      <option value=''>All</option>
      <option value='true'>✔ Yes</option>
      <option value='false'>✖ No</option>
    </select>
  );
};

const handleCustomFieldsBooleanFilter = (rows: any[], id: string, filterValue: any) => {
  if (filterValue === '') {
    return rows;
  }

  return rows.filter((row) => {
    const value = row.original[id]?.value;

    if (filterValue === 'true') {
      return !!value;
    }

    if (filterValue === 'false') {
      return !value;
    }
  });
};

const CustomFieldsRateFilter = ({
  column: { filterValue, setFilter, preFilteredRows, id },
}: any) => {
  const columnValues = useMemo(() => {
    const values = [] as any;
    preFilteredRows.forEach((row) => {
      values.push(row.original[id]?.value);
    });

    return [...values.flat().filter((item) => item || item === 0)];
  }, [id, preFilteredRows]);

  const options = useMemo(() => {
    const result = columnValues.map((value) => {
      return {
        label: value.toString(),
        value,
      };
    });

    return [...(new Map(result.map((item) => [item['value'], item])).values() as any)].sort(
      (a, b) => a.label?.localeCompare(b.label)
    );
  }, [columnValues]);

  return (
    <select
      className='w-100 no-print'
      onChange={(e) => {
        setFilter(e.target.value || undefined, 'value');
      }}
      value={filterValue || ''}
    >
      <option value=''>All</option>
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

export const configureFilters = (column: any) => {
  const result = {
    Filter: () => null,
    filter: 'text',
  } as any;

  if (column.type === 'projectRole') {
    result.Filter = ProjectRolesFilter;
    result.filter = handleProjectRolesFilter;
    return result;
  }

  if (
    column.type === 'customField' &&
    Array.isArray(column.customFieldDefinition?.valueDefinition?.options)
  ) {
    result.Filter = (props) => CustomFieldsArrayFilter(props, column);
    result.filter = handleCustomFieldsArrayFilter;
    return result;
  }

  if (Array.isArray(column.options)) {
    result.Filter = (props) => CodesFilter(props, column);
    result.filter = handleCodesFilter;
    return result;
  }

  if (column.type === 'date') {
    result.filter = handleDateFilter;
    result.allowDateRangeFilter = true;
    result.sortType = (rowA, rowB) => {
      function compareBasic(a: any, b: any) {
        return a === b ? 0 : a > b ? 1 : -1
      }

      return compareBasic(
        (rowA.values[column.value] as Date)?.getTime(), 
        (rowB.values[column.value] as Date)?.getTime() 
      )
      };
  }

  if (
    column.type === 'customField' &&
    column.customFieldDefinition?.valueDefinition?.type === 'date'
  ) {
    result.filter = handleCustomDateFilter;
    result.allowDateRangeFilter = true;
  }

  if (
    column.type === 'customField' &&
    column.customFieldDefinition?.valueDefinition?.type === 'boolean'
  ) {
    result.Filter = CustomFieldsBooleanFilter;
    result.filter = handleCustomFieldsBooleanFilter;
  }

  if (column.type === 'customField' && column.customFieldDefinition?.controlType === 'RATE') {
    result.Filter = CustomFieldsRateFilter;
  }

  else if (column.type === 'string' && column.allowFilter){
    result.Filter = SingleSelectTextFilter;
  }

  return result;
};
