//import moment from 'moment';
import { useFormContext } from 'react-hook-form';
import FormInput from '../../../../shared/form-controls/form-input';
//import FormDatePicker from '../../../../shared/form-controls/form-date-picker/form-date-picker';
//import FormCheckbox from '../../../../shared/form-controls/form-checkbox/form-checkbox';
//import FormSelect from '../../../../shared/form-controls/form-select/form-select';
import { HorizontalLayout } from '../../../../shared/form-controls/control-layouts/horizontal-layout';

type TopFieldsProps = {
  isLocked: boolean;
};

export const TopFields = ({ isLocked = false }: TopFieldsProps) => {
  const { control } = useFormContext();

  return (
    <>
      {/*<FormInput label='Title' control={control} name='title' disabled={isLocked} Layout={HorizontalLayout} />
      <FormInput
        label='Contract #'
        control={control}
        name='contract'
        disabled={isLocked}
        Layout={HorizontalLayout}
      />
      <FormDatePicker
        label='Contract Date'
        name='contractDate'
        control={control}
        maxDate={moment()}
        disabled={isLocked}
        Layout={HorizontalLayout}
  />*/}
    {/*}
      <FormCheckbox
        name='primeContract'
        label='Prime Contract'
        control={control}
        disabled={isLocked}
        Layout={HorizontalLayout}
      />
      <FormSelect
        label='Contract type'
        control={control}
        options={[]}
        name='contractType'
        disabled={isLocked}
        Layout={HorizontalLayout}
      />
    
      <FormCheckbox
        name='isLumpSum'
        label='Is Lump Sum'
        control={control}
        disabled={isLocked}
        Layout={HorizontalLayout}
      />
      <FormInput
        type='number'
        label='Retainage'
        control={control}
        name='retainage'
        inputText='%'
        disabled={isLocked}
        Layout={HorizontalLayout}
/>*/}
      <FormInput
        textArea
        label='Description / Inclusions'
        control={control}
        name='description'
        disabled={isLocked}
        Layout={HorizontalLayout}
      />
    </>
  );
};
