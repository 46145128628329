import { use } from 'i18next';
import moment from 'moment';
import React, { Suspense, useState, useEffect, useCallback, useRef, forwardRef, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import ReactImageZoom from 'react-image-zoom';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import { Tooltip } from 'react-tooltip';
import { getContactDetailAction } from '../../actions/contact';
import { ManageEmployeeModal } from '../../modals/manage-employee-modal';
import AddContactModal from '../../modals/add-contact-modal/add-contact-modal';
import { deleteContact, deleteEmployee, getEmployeeInvitationStatus } from '../../modules/contacts';
import { selectGetEmployeeInvitationStatus } from '../../modules/contacts/selectors';
import { useConfirm } from '../../shared/confirmation-dialog';
import { useAppDispatch } from '../../shared/hooks/use-app-dispatch';
import { strictPhoneArray } from '../../utils/formatters';
import { isAllowed, moduleConstants, reportAccess } from '../../_constants';
import TitleComponent from '../Common/TitleComponent';
import avatarImage from '../static/images/avatar3.png';
import { DeleteContact } from './delete-contact';
import { DeleteEmployee } from './delete-employee';
import { dateFormatMMDDYYYY } from '../../utils/date-formatters';

const ContactToolbar =forwardRef(({  contactData, componentToPrint, fetchContactData}, ref) => {
  const editContactRef = useRef();

  useImperativeHandle(ref, () => ({
    onEditContactClick(initialSection) {
      onEditContactClick(initialSection);
    },
  }));
  
  const [state, setState] = useState({
    userId: localStorage.getItem('userId'),
    objectId: '',
    accountId: localStorage.getItem('accountId'),
    fetchRequest1: false,
    fetchRequest2: false,
    contactData: {},
    updateContactModalOpened: false,
    shouldMakeEmployee: false,
    addContactModalOpened: false,
    initialSection: '',
  });

  

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const allContacts = useSelector(state => state.contact.response);
  const employeeInvitationStatus = useSelector(selectGetEmployeeInvitationStatus);

  const prepareContactForModalView = (oldContactInfo) => {
    if (oldContactInfo?.objectId||oldContactInfo?._id)
      return {
        _id: oldContactInfo.objectId || oldContactInfo._id,
        phoneArr: strictPhoneArray(oldContactInfo.phoneArr),
        address1: oldContactInfo.address1,
        address2: oldContactInfo.address2,
        city: oldContactInfo.city,
        zipCode: oldContactInfo.zipCode,
        state: oldContactInfo.state,
        country: oldContactInfo.country,
        isEmployee: !!oldContactInfo.isEmployee,
        defaultAccess: !!oldContactInfo.defaultAccess,
        email: oldContactInfo.email,
        firstName: oldContactInfo.firstName,
        middleName: oldContactInfo.middleName,
        lastName: oldContactInfo.lastName,
        prefix: oldContactInfo.prefix,
        suffix: oldContactInfo.suffix,
        contactTitle: oldContactInfo.contactTitle,
        userType: oldContactInfo.userType?.objectId || oldContactInfo.userType?._id,
        contactRole: oldContactInfo.contactRole?.objectId || oldContactInfo.contactRole?._id,
        venderArr: oldContactInfo.venderArr,
        comments: oldContactInfo.comments,
        userImage: oldContactInfo.userImage,
        dateOfBirthday: oldContactInfo.dateOfBirthday && moment(oldContactInfo.dateOfBirthday.iso),
      };

    return null;
  };

  useEffect(() => {
    if (allContacts) {
      if (allContacts.contactsData && !allContacts.contactsData.length) {
        setState(prevState => ({
          ...prevState,
          contactData: allContacts.contactsData,
          modalEmployee: allContacts.contactsData.isEmployee,
          fetchRequest: false,
        }));
      } else if (allContacts && allContacts.length >= 1) {
        setState(prevState => ({
          ...prevState,
          userContactsData: allContacts,
          fetchRequest: false,
        }));
      } else if (allContacts && allContacts.length === 1) {
        setState(prevState => ({
          ...prevState,
          fetchRequest: false,
        }));
      }

      if (allContacts.accountData) {
        setState(prevState => ({
          ...prevState,
          accountData: allContacts.accountData,
          fetchRequest: false,
        }));
      }

      if (allContacts.venderArrData) {
        setState(prevState => ({
          ...prevState,
          venderArrData: allContacts.venderArrData,
          fetchRequest: false,
        }));
      }

      setState(prevState => ({ ...prevState, modal: false }));
    }
  }, [allContacts]);


  const onEditContactClick = (initialSection = '') => {
    setState({ updateContactModalOpened: true, initialSection });
  };

  let logo = contactData?.userImage ? contactData.userImage : avatarImage;

  const props = {
    width: 60,
    height: 60,
    scale: 4,
    img: logo,
    offset: { vertical: 0, horizontal: 5 },
  };

  const isUser = contactData?.linkedUserId;
  const contactFullName = [contactData.prefix, contactData.firstName, contactData.middleName, contactData.lastName, contactData.suffix].filter(Boolean).join(' ');

  const { isEmployeeDisabled, isContactDisabled, disabled: currentUserDisabled, userId } = employeeInvitationStatus.data;
  const isUserDisabled = currentUserDisabled || !(userId?.length > 0);
  const activeUserMustBeDisabledTooltipId = 'MENU4.SUBMENUS.SUBMENU12.Tooltip';
  const customDropdownItemClass = `${!isUserDisabled ? 'disabled' : ''} dropdown-item`;

  const isEmployee = contactData?.isEmployee && !isEmployeeDisabled;
  const isAdmin = isAllowed(moduleConstants.EA);

  return (
    <Suspense fallback='loading'>
      <TitleComponent data={{ title: `Contact Details - ${contactFullName}` }} />
      <div className='row mb-0 mb-md-0 mt-2'>
        <div className='col-md-6 mb-0 mb-md-0'>
          <div className='profilePN d-flex flex-row'>
            <div className='profileImag'>
              <ReactImageZoom {...props} />
            </div>
            <div></div>
            <div>
              <div className='ms-2 profileName'>
                {contactData && (
                  <div className='p-1'>
                    <div className='heading2 ml-2 mt-0 mb-1'>{contactFullName || ' NA'}</div>
                    <div className='profileSubInfo d-flex flex-wrap '>
                      <div className='ml-2 mb-0 mt-0'>
                        <p>
                          <small>Created On {dateFormatMMDDYYYY(contactData.createdAt)}</small>
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className='col-md-6'>
          <div className='d-flex flex-wrap mt-md-2 mt-sm-0 justify-content-end gap-2 detailMenu'>
            {((!isEmployee && isAllowed(moduleConstants.EC)) ||
              (isEmployee && isAllowed(moduleConstants.EE))) && (
                <button
                  className='btn btn-primary'
                  disabled={isContactDisabled}
                  onClick={() => onEditContactClick()}
                >
                  Edit contact details
                </button>
              )}
            {
              (isAllowed(moduleConstants.EU) || isAllowed(moduleConstants.EA)) && isEmployee && (
                <>
                  <button
                    className='btn btn-primary'
                    onClick={() => setState({ employeeModalOpened: true })}
                    role='button'
                    disabled={employeeInvitationStatus.data.isEmployeeDisabled}
                  >
                    {!isUser ? t('Invite to CommandPost') : t('Manage User')}
                  </button>
                </>
              )
            }

            {!isContactDisabled && (
              <li className='dropdown px-2'>
                <a
                  className='dropdown-toggle btn'
                  data-bs-toggle='dropdown'
                  href='#'
                  role='button'
                  aria-expanded='false'
                >
                  <i className='fa fa-ellipsis-v' aria-hidden='true'></i>
                </a>
                <ul className='dropdown-menu  ml-auto'>
                  {isAllowed(reportAccess.CJL, 'anyAssigned') && (
                    <li className='dropdown-item'>
                      <Link
                        className=''
                        to={{
                          pathname: `/contact-job-list-report`,
                          search: `?contactId=${contactData.objectId}`,
                          state: { modal: false },
                        }}
                        data-tooltip-content={t('MENU4.SUBMENUS.SUBMENU4.Tooltip')}
                        data-tooltip-id='MENU4.SUBMENUS.SUBMENU4.Tooltip'
                      >
                        {t('Contact Job List')}
                      </Link>
                      <Tooltip
                        id='MENU4.SUBMENUS.SUBMENU4.Tooltip'
                        place='right'
                        delayShow={1000}
                      />
                    </li>
                  )}
                  {isEmployee && isAllowed(reportAccess.TSF, 'anyAssigned') && (
                    <li className='dropdown-item'>
                      <Link
                        className=''
                        to={{
                          pathname: `/timesheet-report`,
                          search: `?contactId=${contactData.objectId}`,
                          state: { contactData: contactData, contactFullName },
                        }}
                        data-tooltip-content= {t('MENU4.SUBMENUS.SUBMENU7.Tooltip')}
                        data-tooltip-id='MENU4.SUBMENUS.SUBMENU7.Tooltip'
                      >
                        {t('Timesheet Form')}
                      </Link>
                      <Tooltip
                        id='MENU4.SUBMENUS.SUBMENU7.Tooltip'
                        place='right'
                        delayShow={1000}
                      />
                    </li>
                  )}
                  <hr />
                  <li className='dropdown-item'>
                    <ReactToPrint
                      trigger={() => <a>Print Details </a>}
                      content={() => componentToPrint.current}
                    />
                  </li>
                  <div data-tooltip-id={activeUserMustBeDisabledTooltipId} data-tooltip-content={t(activeUserMustBeDisabledTooltipId)}>
                    <li className={customDropdownItemClass}>
                      <DeleteEmployee
                        companyName={localStorage.getItem('companyName')}
                        contactId={contactData.objectId}
                        onSubmit={()=>fetchContactData()}
                        
                      />
                      {!isUserDisabled &&
                      <Tooltip id={activeUserMustBeDisabledTooltipId} place='right'  delayShow={500}/>}
                    </li>
                  </div>
                  {!isEmployee && isAllowed(moduleConstants.EE) && (
                    <div>
                      <div className='dropdown-divider'></div>
                      <li className='dropdown-item'>
                        <a
                          onClick={() =>
                            setState({
                              updateContactModalOpened: true,
                              shouldMakeEmployee: true,
                            })
                          }
                          role='button'
                          data-tooltip-id='Make Employee'
                          data-tooltip-content={t('Make Employee')}
                        >
                          {t('Make Employee')}
                        </a>
                        <Tooltip
                          id='Make Employee'
                          place='right'
                          delayShow={1000}
                       />
                      </li>
                    </div>
                  )}
                  {isAdmin && (
                  <div data-tooltip-id={activeUserMustBeDisabledTooltipId} data-tooltip-content={t(activeUserMustBeDisabledTooltipId)}>
                    <li className={customDropdownItemClass}>
                      <DeleteContact contactId={contactData.objectId} onSubmit={()=>fetchContactData()} />
                      {!isUserDisabled &&
                      <Tooltip id={activeUserMustBeDisabledTooltipId} place='right'  delayShow={500}/>
                      }
                    </li>
                  </div>
                  )}
                </ul>
              </li>
            )}
          </div>
        </div>
      </div>

      {/*Edit Contact popup*/}

      <AddContactModal
        onSubmit={() => {
          dispatch(getEmployeeInvitationStatus({ contactId: contactData.objectId }));
        }}
        isUpdate={true}
        contact={prepareContactForModalView(contactData)}
        open={state.updateContactModalOpened}
        onUpdateContactData={() => {}}
        onClose={() => setState({ updateContactModalOpened: false, shouldMakeEmployee: false })}
        makeEmployee={state.shouldMakeEmployee}
        initialSection={state.initialSection}
        ref={editContactRef}
      />

      <ManageEmployeeModal
        contactId={contactData.objectId}
        open={state.employeeModalOpened}
        onClose={() => setState({ employeeModalOpened: false })}
        onSubmit={()=>fetchContactData()}
      />
    </Suspense>
  );
});

export default ContactToolbar;
