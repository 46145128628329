import {
  CreateAccountData,
  UpdateAccountData,
  UpdateAccountProjectSettingsData,
  UpdateAccountSettingsData,
} from '@module/accounts';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { notification } from 'antd';
import { buildDefaultActionResult } from '../../utils/redux';
import { saveCompanyContactAffiliation } from '../companies';
import { saveContactAffiliationsRequest } from '../contacts/api';
import {
  createAccountRequest,
  getAccountEditInfoRequest,
  getAccountProjectSettingsRequest,
  getAllAccountsRequest,
  removeAccountLogoRequest,
  spreadAccountAffiliationBetweenContactsRequest,
  updateAccountLogoRequest,
  updateAccountProjectSettingsRequest,
  updateAccountRequest,
  updateAccountSettingsRequest,
  getSubscriptionDetails,
} from './api';

export const getAllAccounts = createAsyncThunk('accounts/getAllAccounts', () => {
  return getAllAccountsRequest();
});

export const createAccount = createAsyncThunk(
  'accounts/createAccount',
  async ({ data }: { data: CreateAccountData }, api) => {
    const result = await createAccountRequest(data);

    await api.dispatch(
      (saveCompanyContactAffiliation as any)({
        accountId: result.accountId,
        companyId: result.companyId,
        phoneArr: data.phoneArr.map((e, i) => ({ ...e, isPrimary: i === 0 })),
        addressArr: [
          {
            address1: data.address1,
            address2: data.address2,
            city: data.city,
            country: data.country,
            state: data.state,
            zipCode: data.zipCode,
            isPrimary: true,
            label: '',
          },
        ],
        emailArr: [
          {
            email: data.companyEmail,
            label: '',
            isPrimary: true,
          },
        ],
      })
    );

    notification.success({ message: 'Account created!' });

    await spreadAccountAffiliationBetweenContactsRequest({ accountId: result.accountId });

    await saveContactAffiliationsRequest({
      accountId: result.accountId,
      contactId: result.contactId,
      affiliations: [
        {
          selfAffiliated: true,
          active: true,
          phoneArr: data.adminPhoneArr || [],
          emailArr: [
            {
              label: '',
              email: data.adminEmail,
              isPrimary: true,
            },
          ],
          addressArr: [],
        },
      ],
    });

    api.dispatch(getAllAccounts());

    return result;
  }
);

export const updateAccount = createAsyncThunk(
  'accounts/updateAccount',
  async ({ data }: { data: UpdateAccountData }, api) => {
    const result = await updateAccountRequest(data);

    await api.dispatch(
      (saveCompanyContactAffiliation as any)({
        accountId: result.accountId,
        companyId: result.companyId,
        phoneArr: data.phoneArr.map((e, i) => ({ ...e, isPrimary: i === 0 })),
        addressArr: [
          {
            address1: data.address1,
            address2: data.address2,
            city: data.city,
            country: data.country,
            state: data.state,
            zipCode: data.zipCode,
            isPrimary: true,
            label: '',
          },
        ],
        emailArr: [
          {
            email: data.companyEmail,
            label: '',
            isPrimary: true,
          },
        ],
      })
    );

    // no longer needed
    // await spreadAccountAffiliationBetweenContactsRequest({ accountId: result.accountId });

    api.dispatch(getAllAccounts());
    
    return result;
  }
);

export const getAccountEditInfo = createAsyncThunk('functions/getAccountEditInfo', () => {
  return getAccountEditInfoRequest();
});

export const updateAccountSettings = createAsyncThunk(
  'accounts/updateAccountSettings',
  async (data: UpdateAccountSettingsData) => {
    const result = await updateAccountSettingsRequest(data);

    notification.success({ message: 'Account settings updated!' });

    return result;
  }
);

export const updateAccountLogo = createAsyncThunk(
  'accounts/updateAccountLogo',
  async (data: { accountId?: string; companyLogo: File }) => {
    const result = await updateAccountLogoRequest({
      accountId: data.accountId,
      companyLogo: data.companyLogo,
    });

    notification.success({ message: 'Account logo updated!' });

    return result;
  }
);

export const removeAccountLogo = createAsyncThunk(
  'accounts/removeAccountLogo',
  async (data: { accountId?: string }) => {
    const result = await removeAccountLogoRequest({
      accountId: data.accountId,
    });

    notification.success({ message: 'Account logo removed!' });

    return result;
  }
);

export const getAccountSubscriptionInfo = createAsyncThunk('parse/functions/getSubscriptionDetails', ({
  accountId,
}: {
  accountId: string;
}) => {
  return getSubscriptionDetails({accountId});
});

export const getAccountProjectSettings = createAsyncThunk(
  'accounts/getAccountProjectSettings',
  async (data?: { accountId?: string }) => {
    const result = await getAccountProjectSettingsRequest({ accountId: data?.accountId });

    return result;
  }
);

export const updateAccountProjectSettings = createAsyncThunk(
  'accounts/updateAccountProjectSettings',
  async (data: UpdateAccountProjectSettingsData) => {
    const result = await updateAccountProjectSettingsRequest({
      ...data,
    });

    notification.success({ message: 'Account project settings updated!' });

    return result;
  }
);

const accountsSlice = createSlice({
  name: 'accounts',
  initialState: {
    getAllAccounts: {
      data: [],
      error: null,
      loading: false,
    },
    createAccount: {
      data: null,
      error: null,
      loading: false,
    },
    updateAccount: {
      data: null,
      error: null,
      loading: false,
    },
    getAccountEditInfo: {
      data: null,
      error: null,
      loading: false,
    },
    updateAccountSettings: {
      data: null,
      error: null,
      loading: false,
    },
    updateAccountLogo: {
      data: '',
      error: null,
      loading: false,
    },
    removeAccountLogo: {
      data: '',
      error: null,
      loading: false,
    },
    getAccountProjectSettings: {
      data: {
        monthlyOverheadFixed: 0,
        monthlyOverheadVariable: 0,
        profitForecastChanged: 0,
        warrantyPeriod: 0,
        lienPeriod: 30,
        followUpPeriod: 0,
        initialJobNumberPre: '',
        initialJobNumber: 0,
        alternateJobNumber: false,
        sequentialJobNumber: true,
        requireNTP: false,
        enableEstimatingScheduling: false,
        requireConstructionDatesOn: '',
        restrictContractAdjustments: true,
        requireReasonCodes: true,
      },
      error: null,
      loading: false,
    },
    updateAccountProjectSettings: {
      data: null,
      error: null,
      loading: false,
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    buildDefaultActionResult('getAllAccounts', getAllAccounts, builder);
    buildDefaultActionResult('createAccount', createAccount, builder);
    buildDefaultActionResult('updateAccount', updateAccount, builder);
    buildDefaultActionResult('getAccountEditInfo', getAccountEditInfo, builder);
    buildDefaultActionResult('updateAccountSettings', updateAccountSettings, builder);
    buildDefaultActionResult('updateAccountLogo', updateAccountLogo, builder);
    buildDefaultActionResult('removeAccountLogo', removeAccountLogo, builder);
    buildDefaultActionResult('getAccountProjectSettings', getAccountProjectSettings, builder);
    buildDefaultActionResult('updateAccountProjectSettings', updateAccountProjectSettings, builder);
  },
});

export default accountsSlice.reducer;
