import React, {Suspense} from 'react';

import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from '../../Layout/Header.js';
import Footer from '../../Layout/Footer.js';
import { appConstants } from '../../../_constants';
import {
  GetAddressLabelReportAction,
  GetAddressLabelReportPrefAction,
  SetAddressLabelReportPrefAction,
} from '../../../actions/reports';
import ReactLoading from 'react-loading';

import TitleComponent from  "../../Common/TitleComponent";
import CustomTable from "../../Common/CustomTable";
import { formatAddressText } from '../../../utils/contact-formatters.jsx';
import {sortAsc, UpperCaseName} from '../../../components/Auth/Auth';


class AddressLabelReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: { sortType: 'ASC' },
      selectBox: {},
      sessionToken: localStorage.getItem('sessionToken'),
      accountId: localStorage.getItem('accountId'),
      userId: localStorage.getItem('userId'),
      list: [],
      fetchRequest: true,
      comLogo: '',
      totalCount: 0,
      currentPage: 1,
      sizePerPage: appConstants.TableSizePerPageList[2].value,
      searchText: '',
      OptionAddressFields: [],
      OptionAddressOperator: [],
      filterStatus: false,
      filterValue: '',
      dataForPdf: false,
      dataForExcel: false,
      pdfList: [],
    };
    this.onPageChange = this.onPageChange.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.filteredData = this.filteredData.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.listData &&
      nextProps.listData.success &&
      nextProps.listData.success === 1 &&
      !this.state.dataForExcel &&
      !this.state.dataForPdf
    ) {
      this.setState({
        totalCount: nextProps.listData.count,
        contactsList: nextProps.listData.contactsList,
        list: nextProps.listData.data,
        fetchRequest: false,
      });
    }

    if (
      nextProps.listData &&
      nextProps.listData.success &&
      nextProps.listData.success === 1 &&
      (this.state.dataForExcel || this.state.dataForPdf)
    ) {
      this.setState({
        dataForExcel: false,
        dataForPdf: false,
        contactsList: nextProps.listData.contactsList,
        pdfList: nextProps.listData.data,
      });
      this.makeDataForExportExcel(nextProps.listData.data);
    }

    if (nextProps.listData && nextProps.listData.data && nextProps.listData.data.length == 0) {
      this.setState({ fetchRequest: false });
    }
  }

  componentDidMount() {
    this.setState({ sizePerPage: appConstants.TableSizePerPageList[2].value });
    let data = {};
    data['accountId'] = this.state.accountId;
    data['moduleName'] = 'Address Label Report';
    data['page'] = 0;
    data['displayLimit'] = this.state.sizePerPage;
    this.props.GetAddressLabelReportAction(data);

    // Fields DD
    this.setState({ OptionAddressFields: appConstants.OptionAddressFields });

    // Operator DD
    this.setState({
      OptionAddressOperator: appConstants.OptionCustomOperatorAdd,
    });

    if (
      localStorage.getItem('companyLogo') !== 'undefined' &&
      localStorage.getItem('companyLogo') !== null
    ) {
      this.setState({
        comLogo: localStorage.getItem('companyLogo'),
      });
    } else {
      let constImage = appConstants.CONTENT_CPOST_LOGO_URL;
      this.setState({ comLogo: constImage });
    }
    this.props.GetAddressLabelReportPrefAction();
  }

  onCompanyName(cell, row, enumObject, rowIndex) {
    if (row && row.objectId) {
      return (
        <div>
          <Link
            to={{
              pathname: '/company-details/' + row.objectId,
              state: { row },
            }}
          >
            {row && row.companyName ? row.companyName : '-'}
          </Link>
        </div>
      );
    } else {
      return <span>-</span>;
    }
  }

  onContactName(cell, row, enumObject, rowIndex) {
    let contNameData = '';
    let contNameDataArr = [];
    let { contactsList } = this.state;
    if (contactsList && contactsList[row.objectId]) {
      let contactsData = contactsList[row.objectId];
      if (contactsData && contactsData.length > 0) {
        contactsData = sortAsc(contactsData, 'firstName');
        return (
          <div>
            {contactsData.map((v, i) => {
              if (v) {
                let accountExecName = v.firstName + ' ' + v.lastName;
                let comma;
                let row = v;
                if (i != 0) {
                  comma = ',  ';
                } else {
                  comma = '';
                }
                return (
                  <Link
                    to={{
                      pathname: '/contact-details/' + v.objectId,
                      state:  {row} ,
                    }}
                  >
                    {comma}
                    {accountExecName}
                  </Link>
                );
                //contNameDataArr.push(contNameData);
              }
            })}
          </div>
        );
      } else {
        return <span>-</span>;
      }
    } else {
      return <span>-</span>;
    }
  }

  onGetAddressInfo(cell, row, enumObject, rowIndex) {
    let data = '';
    if (row) {
      data +=
        '<span>' +
        (row.companyAddress1 ? row.companyAddress1 : '') +
        (row.companyAddress1 ? ', ' : '') +
        (row.companyAddress2 ? row.companyAddress2 : '') +
        (row.companyAddress2 ? ', ' : '') +
        '</span>';
      data +=
        '<span>' +
        (row.city ? row.city : '') +
        (row.city ? ', ' : '') +
        (row.state ? row.state : '') +
        (row.state ? ', ' : '') +
        (row.zipCode ? row.zipCode : '') +
        '</span>';
      return <div dangerouslySetInnerHTML={{ __html: data }} />;
    } else {
      return <span>-</span>;
    }
  }

  filteredData(e, totalCount) {
    console.log('totalCount', totalCount);
    this.setState({ fetchRequest: true, list: [] });
    this.setState({
      sizePerPage: appConstants.TableSizePerPageList[2].value,
      fetchRequest: true,
    });
    let formData = this.state.formData;
    let formVal = '';

    if (formData.operator === 'equals' || formData.operator === 'startsWith') {
      formVal = UpperCaseName(formData.value);
    } else {
      formVal = formData.value;
    }

    this.setState({ filterValue: formVal });

    let data = {};
    data['accountId'] = this.state.accountId;
    data['fieldToFilter'] = {
      field: formData.field,
      operator: formData.operator,
      value: formVal,
    };
    data['sortKey'] = this.state.formData.sortKey;
    data['sortType'] = this.state.formData.sortType;
    data['page'] = 0;
    data['displayLimit'] = this.state.sizePerPage;
    data['moduleName'] = 'Address Label Report';
    this.props.GetAddressLabelReportAction(data);
    this.setState({ filterStatus: true });
  }

  handleChangeSelectBox(name, value) {
    let formData = this.state.formData;
    let field = this.state.selectBox;
    field[name] = value;
    formData[name] = value.value;
    this.setState({ formData, selectBox: field });
  }

  handleChange = (e) => {
    e.preventDefault();
    let field = this.state.formData;
    field[e.target.name] = e.target.value;
    this.setState({
      formData: field,
    });
  };

  handleChangeRadio = (e) => {
    let field = this.state.formData;
    field['sortType'] = e.target.value;
    this.setState({
      formData: field,
    });
  };

  createCustomButtonGroup = (props) => {
    return (
      <div className="pdfLogoBox">
        <div className="reportHead">
          <div className="logo">
            <img src={this.state.comLogo} alt="Upload Logo" />
          </div>
          <h2>Address Label Report</h2>
        </div>
      </div>
    );
  };

  _setTableOption() {
    if (this.state.fetchRequest) {
      return (
        <ReactLoading
          className="table-loader"
          type={appConstants.LOADER_TYPE}
          color={appConstants.LOADER_COLOR}
          height={appConstants.LOADER_HEIGHT}
          width={appConstants.LOADER_WIDTH}
        />
      );
    } else {
      return 'No data found..';
    }
  }

  onPageChange(page, sizePerPage) {
    this.setState({ fetchRequest: true, list: [] });
    let formData = this.state.formData;
    let filterValue = this.state.filterValue;
    let data = {};

    data['accountId'] = this.state.accountId;

    if (this.state.filterStatus) {
      data['fieldToFilter'] = {
        field: formData.field ? formData.field : '',
        operator: formData.operator ? formData.operator : '',
        value: filterValue,
      };
      data['sortKey'] = formData.sortKey ? formData.sortKey : '';
      data['sortType'] = formData.sortType ? formData.sortType : '';
    }
    data['moduleName'] = 'Address Label Report';
    data['page'] = page === -1 ? 0 : page - 1;
    data['displayLimit'] = sizePerPage;
    data['search'] = this.state.searchText;
    this.setState({ sizePerPage: sizePerPage });
    this.setState({ currentPage: page });
    this.props.GetAddressLabelReportAction(data);
  }

  onSearchChange(text, sizePerPage) {
    this.setState({ fetchRequest: true, list: [] });
    let formData = this.state.formData;
    let filterValue = this.state.filterValue;
    text = text ? UpperCaseName(text.trim()) : '';
    if (text !== '') {
      let data = {};
      data['accountId'] = this.state.accountId;
      data['moduleName'] = 'Address Label Report';
      data['fieldToFilter'] = {
        field: formData.field ? formData.field : '',
        operator: formData.operator ? formData.operator : '',
        value: filterValue,
      };
      data['sortKey'] = this.state.formData.sortKey ? this.state.formData.sortKey : '';
      data['sortType'] = this.state.formData.sortType ? this.state.formData.sortType : '';
      data['page'] = 0;
      data['search'] = text;
      data['displayLimit'] = this.state.sizePerPage;
      this.setState({ sizePerPage: 50 });
      this.setState({ searchText: text });
      this.props.GetAddressLabelReportAction(data);
    } else {
      this.componentDidMount();
      this.setState({ searchText: text });
    }
  }

  excelExport() {
    let formData = this.state.formData;
    let filterValue = this.state.filterValue;
    let { totalCount, sizePerPage } = this.state;
    this.setState({
      filterStatus: false,
      dataForExcel: true,
      dataForPdf: false,
    });
    // this.filteredData(this,totalCount);
    let data = {};
    data['accountId'] = this.state.accountId;
    data['moduleName'] = 'Address Label Report';
    data['fieldToFilter'] = {
      field: formData.field ? formData.field : '',
      operator: formData.operator ? formData.operator : '',
      value: filterValue,
    };
    data['sortKey'] = this.state.formData.sortKey ? this.state.formData.sortKey : '';
    data['sortType'] = this.state.formData.sortType ? this.state.formData.sortType : '';
    data['page'] = 0;
    data['displayLimit'] = totalCount ? totalCount : sizePerPage;
    this.props.GetAddressLabelReportAction(data);
  }

  downloadPdf() {
    let formData = this.state.formData;
    let filterValue = this.state.filterValue;
    let { totalCount, sizePerPage } = this.state;
    this.setState({
      filterStatus: false,
      dataForExcel: false,
      dataForPdf: true,
    });
    let data = {};
    data['accountId'] = this.state.accountId;
    data['moduleName'] = 'Address Label Report';
    data['fieldToFilter'] = {
      field: formData.field ? formData.field : '',
      operator: formData.operator ? formData.operator : '',
      value: filterValue,
    };
    data['sortKey'] = this.state.formData.sortKey ? this.state.formData.sortKey : '';
    data['sortType'] = this.state.formData.sortType ? this.state.formData.sortType : '';
    data['page'] = 0;
    data['displayLimit'] = totalCount ? totalCount : sizePerPage;
    this.props.GetAddressLabelReportAction(data);
  }

  makeDataForExportExcel = (list) => {
    const multiDataSet = [
      {
        columns: [
          { title: 'Firm Name', style: { alignment: { wrapText: false } } }, //pixels width
          {
            title: 'Contact',
            style: { alignment: { wrapText: false } },
          }, //char width
          { title: 'Address', style: { alignment: { wrapText: false } } },
        ],
        data: [],
      },
    ];
    let dbHeader = [1, 2, 3];
    let dbDataArr = [];
    let dbDataArrB = [];
    var x = null;
    var y = null;
    let dataArr = list;
    if (dataArr && dataArr.length > 0) {
      let blank = '';
      for (x in dataArr) {
        dbDataArrB = [];
        let row = list[x];
        let firmNames = '';
        for (var k in dbHeader) {
          switch (dbHeader[k]) {
            case 1:
              let companyName = row && row.companyName ? row.companyName : '-';
              dbDataArrB.push({ value: companyName, style: blank });
              break;
            case 2:
              let contNameDataArr = [];
              let { contactsList } = this.state;
              if (contactsList && contactsList[row.objectId]) {
                let contactsData = contactsList[row.objectId];
                contactsData = sortAsc(contactsData, 'firstName');
                if (contactsData && contactsData.length > 0) {
                  contactsData.map((v, i) => {
                    if (v) {
                      let accountExecName = v.firstName + ' ' + v.lastName;
                      contNameDataArr.push(accountExecName);
                    }
                  });
                }
              }
              contNameDataArr = contNameDataArr.join(',');
              dbDataArrB.push({
                value: contNameDataArr ? contNameDataArr : '-',
                style: blank,
              });
              break;
            case 3:
              let data = '';
              if (row) {
                data +=
                  (row.companyAddress1 ? row.companyAddress1 : '') +
                  (row.companyAddress1 ? ', ' : '') +
                  (row.companyAddress2 ? row.companyAddress2 : '') +
                  (row.companyAddress2 ? ', ' : '');
                data +=
                  (row.city ? row.city : '') +
                  (row.city ? ', ' : '') +
                  (row.state ? row.state : '') +
                  (row.state ? ', ' : '') +
                  (row.zipCode ? row.zipCode : '');
              }
              dbDataArrB.push({ value: data ? data : '-', style: blank });
              break;
          }
        }
        dbDataArr.push(dbDataArrB);
      }
    }
    multiDataSet[0].data = dbDataArr;
    this.setState({ multiDataSet });
  };

  render() {
    let tableOption = {
      sizePerPage: this.state.sizePerPage,
      onPageChange: this.onPageChange,
      page: this.state.currentPage,
      sizePerPageList: appConstants.TableSizePerPageList,
      noDataText: this._setTableOption(),
      afterDeleteRow: this.handleDeleteButtonClick,
      prePage: 'Prev', // Previous page button text
      nextPage: 'Next', // Next page button text
      firstPage: 'First', // First page button text
      lastPage: 'Last', // Last page button text,
      paginationPosition: 'bottom', // default is bottom, top and both is all available
      alwaysShowAllBtns: true, // Always show next and previous buttondefault is bottom, top and both is all available
      onSearchChange: this.onSearchChange,
      btnGroup: this.createCustomButtonGroup,
    };

    let customData;
    let contactInfo = [];
    let { contactsList, list, fetchRequest } = this.state;
    if (this.state.list && this.state.list.length > 0) {
      customData = this.state.list.map((v, j) => {
        if (v) {
          let add1 = '';
          let add2 = '';
          if (contactsList && contactsList[v.objectId]) {
            let contactsData = contactsList[v.objectId];
            add1 =
              (v.companyAddress1 ? v.companyAddress1 : '') +
              ', ' +
              (v.companyAddress2 ? v.companyAddress2 : '');
            add2 =
              (v.city ? v.city : '') +
              ', ' +
              (v.state ? v.state : '') +
              ', ' +
              (v.zipCode ? v.zipCode : '');
            if (contactsData && contactsData.length > 0) {
              contactsData = sortAsc(contactsData, 'firstName');
              customData = contactsData.map((x, index) => {
                return (
                  <li key={index}>
                    <strong>{v.companyName ? v.companyName : '-'}</strong>
                    <br />
                    <strong>Attn:</strong>{' '}
                    {(x.firstName ? x.firstName : '') + ' ' + (x.lastName ? x.lastName : '-')}
                    <br />
                    {add1 ? add1 : ''}
                    <br />
                    {add2 ? add2 : ''}
                    <br />
                  </li>
                );
              });
            }
            return customData;
          }
        }
      });
    }
    let multiDataSet = this.state.multiDataSet;
    let multiDataSetPdf =
      this.state.pdfList && this.state.pdfList.length > 0 ? this.state.pdfList : '';

    const { listPref, SetAddressLabelReportPrefAction } = this.props;

    const columns = [
      {
        Header: "Firm Name",
        accessor: "companyName",
        width: 388,
        Cell: (props) => this.onCompanyName(props.column.id, props.row.original, props.value)
      },
      {
        Header: "Contact Person",
        accessor: "companyEmail",
        width: 388,
        Cell: (props) => this.onContactName(props.column.id, props.row.original, props.value)
      },
      {
        Header: "Address",
        accessor: (row) => (formatAddressText(row)),
        sortType: 'basic',
        width: 100,
        Cell: (props) => <>
          <Link target='_blank'
            rel='noopener noreferrer' to={{ pathname: (`https://maps.google.com/?q=` + formatAddressText(props.row.original)) }} data-tooltip-content={props.value} data-tooltip-id={props.value}>{props.value}</Link>
        </>
      },
    ];

    return (
      <div className="ContactListReport">
        <TitleComponent data={{title:`Address Labels`}}/>
        <Suspense fallback="loading"><Header props={this.props} /></Suspense>
        <div className="contactBox">
          <div className="container">

            <div className="row no-break-print">
              <div className="col-md-12 sortable-table">
                <div className="table-responsive reportTableSection">
                  {this.createCustomButtonGroup()}
                  <CustomTable
                    columns={columns}
                    list={list}
                    preferences={listPref}
                    className="contact-list-table"
                    customTableTitle="Address Label Report"
                    onSavePreferences={SetAddressLabelReportPrefAction}
                    isLoading={fetchRequest}
                    canShowRowSelection={false}
                    componentRef={this.componentRef}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="addressLabelPDFreport" ref={(el) => (this.componentRef = el)}>
                  <div className="pdfLogoBox">
                    <div className="reportHead">
                      <div className="logo">
                        <img src={this.state.comLogo} alt="Upload Logo" />
                      </div>
                      <h2>Address Label Report</h2>
                    </div>
                  </div>
                  <ul className="clearfix">{customData}</ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
       
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    listData: state.reports.AddressLabelList,
    listPref: state.reports.addressLabelListPref,
  };
}

export default (
  withRouter(connect(mapStateToProps, { GetAddressLabelReportAction,
    GetAddressLabelReportPrefAction,
    SetAddressLabelReportPrefAction })(AddressLabelReport))
);
