import * as actionTypes from '../actions/actionTypes';

const initState = {
  companyTypes: {},
  allCompanyTypes: [],
  message: '',
  isfetched: false,
  isfetchedError: false,
};

export default (state = initState, action = {}) => {
  switch (action.type) {
    case actionTypes.CREATE_COMPANY_TYPES_REQUEST:
      return {
        ...state,
        companyTypes: {},
        message: '',
      };

    case actionTypes.CREATE_COMPANY_TYPES_SUCCESS:
      return {
        allCompanyTypes: state.allCompanyTypes.concat(action.payload.response.result.data),
      };

    case actionTypes.CREATE_COMPANY_TYPES_ERROR:
      return {
        ...state,
        message: '',
      };

    case actionTypes.GET_ALL_COMPANY_TYPES_REQUEST:
      return {
        ...state,
        companyTypes: {},
        message: '',
      };

    case actionTypes.GET_ALL_COMPANY_TYPES_SUCCESS:
      return {
        ...state,
        allCompanyTypes: action.payload.response.result.data.data,
        message: '',
      };

    case actionTypes.GET_ALL_COMPANY_TYPES_ERROR:
      return {
        ...state,
        message: '',
      };

    case actionTypes.UPDATE_COMPANY_TYPES_REQUEST:
      return {
        ...state,
        companyTypes: {},
        message: '',
      };
    case actionTypes.UPDATE_COMPANY_TYPES_SUCCESS:
      return {
        ...state,
        allCompanyTypes: action.payload.response.result.contractTypes,
        message: '',
      };

    case actionTypes.UPDATE_COMPANY_TYPES_ERROR:
      return {
        ...state,
        message: '',
      };

    case actionTypes.DELETE_COMPANY_TYPES_REQUEST:
      return {
        ...state,
        companyTypes: {},
        message: '',
      };
    case actionTypes.DELETE_COMPANY_TYPES_SUCCESS:
      return {
        ...state,
        allCompanyTypes: state.allCompanyTypes,
        message: '',
      };

    case actionTypes.DELETE_COMPANY_TYPES_ERROR:
      return {
        ...state,
        message: '',
      };

    default:
      return state;
  }
};
