import { useState, useEffect } from 'react';
import { useAppDispatch } from '../../../shared/hooks/use-app-dispatch';
import { getCompanyCustomFieldsValuesForm } from '../../../modules/companies';
import { CompanyCustomFieldsView } from './company-custom-fields-view';
import styles from '../../../components/Company/view-company-detail-info/style.module.css';

type CompanyCustomFieldsProps = {
  data: any;
};

export const CompanyCustomFields = ({ data = {} }: CompanyCustomFieldsProps) => {
  const dispatch = useAppDispatch();

  const { objectId: companyId, customFields: customFieldsValues } = data;
  const [customFields, setCustomFields] = useState<Array<any>>([]);

  useEffect(() => {
    if (companyId) {
      dispatch(getCompanyCustomFieldsValuesForm({ companyId }))
        .unwrap()
        .then((data) => setCustomFields(data));
    }
  }, [companyId]);

  return (
    <table className={`${styles.affiliationTable} ${styles.twoColumns}`}>
      <tbody>
        <CompanyCustomFieldsView
          fields={customFields}
          values={customFieldsValues}
        />
      </tbody>
    </table>
  );
};
