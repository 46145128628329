import { ReactElement, useEffect, useReducer, useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { DefaultLayout } from '../control-layouts/default-layout';
import { LayoutProps } from '../control-layouts/layout-props';
import { convertIntervalToPreciseDuration } from '../../../utils/import-utils';
import { formatDateObjectOrNull } from '../../../utils/date-formatters';
import DurationPicker from '../../duration-picker';
import moment from 'moment';

type FormDurationPickerProps = {
  name: string;
  label: string;
  startDateFieldName: string;
  endDateFieldName: string;
  required?: boolean;
  disabled?: boolean;
  updateEndOnStartChange?: boolean;
  Layout?: (props: LayoutProps) => ReactElement;
};

type Duration = {
  years: number;
  months: number;
  days: number;
};

const FormDurationPicker = ({
  name,
  label,
  startDateFieldName,
  endDateFieldName,
  required = false,
  disabled = false,
  updateEndOnStartChange = true,
  Layout = DefaultLayout,
}: FormDurationPickerProps) => {
  const maxYearsDuration = 4;
  const form = useFormContext();

  if (!form) {
    throw new Error('FormDurationPicker must be used within a FormContext');
  }

  const watchStartDate = form.watch(startDateFieldName);
  const watchEndDate = form.watch(endDateFieldName);

  const initialDuration = convertIntervalToPreciseDuration(watchStartDate, watchEndDate);

  /* eslint-disable @typescript-eslint/no-unused-vars */
  const [_,forceUpdate] = useReducer(x => x + 1, 0);
  const stateRef = useRef<Duration>({ ...initialDuration });

  useEffect(() => { setDurationValues() }, []);
  useEffect(() => { 
    
    setDurationValues();
    // validate start and end dates
    form.trigger([startDateFieldName, endDateFieldName]);

   }, [watchEndDate]);
  useEffect(() => { 
    if(updateEndOnStartChange)
    {
      // add duration to start date and set end date
      const currentEndDate = formatDateObjectOrNull(watchEndDate);
      const currentStartDate = formatDateObjectOrNull(watchStartDate);
      if (currentStartDate) {
        let newEndDate = moment(currentStartDate).add({ years: stateRef.current.years, months: stateRef.current.months, days: stateRef.current.days });

        const { years: yearsDuration } = convertIntervalToPreciseDuration(watchStartDate, newEndDate);

        if (yearsDuration >= maxYearsDuration) {
          newEndDate = moment(watchStartDate).add({ years: maxYearsDuration });
        }

        if(newEndDate){

          if(newEndDate.isSame(currentEndDate, 'day')) return;

          form.setValue(endDateFieldName, newEndDate);
        }
      }
    }
    setDurationValues(); 
  }, [watchStartDate]);

  const setDurationValues = () => {

    if(!watchStartDate || !watchEndDate) return;

    const newDuration = convertIntervalToPreciseDuration(watchStartDate, watchEndDate);

    if(newDuration.years === stateRef.current.years && newDuration.months === stateRef.current.months && newDuration.days === stateRef.current.days) return;

    stateRef.current = { ...newDuration };
    
    forceUpdate();
  };



  const onDurationValueChange = ({years, months, days}) => {

    const currentEndDate = formatDateObjectOrNull(watchEndDate);
    const currentStartDate = formatDateObjectOrNull(watchStartDate);

    
    if (updateEndOnStartChange && currentStartDate) {
      let newEndDate = moment(currentStartDate).add({ years, months, days });

      const { years: yearsDuration } = convertIntervalToPreciseDuration(watchStartDate, newEndDate);

      if (yearsDuration >= maxYearsDuration) {
        newEndDate = moment(watchStartDate).add({ years: maxYearsDuration });
      }

      if(newEndDate){

        if(newEndDate.isSame(currentEndDate, 'day')) return;

        form.setValue(endDateFieldName, newEndDate);
      }
    }
    else if (!updateEndOnStartChange && currentEndDate){
      const newStartDate = moment(watchEndDate).subtract({ years, months, days });

      if (newStartDate) {
        if(newStartDate.isSame(currentStartDate, 'day')) return;
        
        form.setValue(startDateFieldName, newStartDate);
      }
    }

    // trigger form validation for start and end dates
    form.trigger([startDateFieldName, endDateFieldName]);
  };

  return (
    <Controller
      rules={{ required: required && `${label} is required` }}
      name={name}
      control={form.control}
      render={({ field, fieldState }) => {
        return (
          <Layout
            label={label}
            required={required}
            error={fieldState.error?.message}
            input={
              <DurationPicker
                name={field.name}
                duration={stateRef.current}
                maxYearsDuration={maxYearsDuration}
                onValueChange={onDurationValueChange}
                disabled={disabled}
              />
            }
          />
        );
      }}
    />
  );
};

export default FormDurationPicker;
