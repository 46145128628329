import React, {Suspense} from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Header from '../../Layout/Header.js';
import Header2 from '../../Layout/Header2.js';
import Footer from '../../Layout/Footer.js';
import {
  getHelpDetailsAction,
} from '../../../actions/help';
import './../../../index.css';
import renderHTML from 'html-react-parser';
import loaderImage1 from '../../static/images/loading_i.gif';
import TitleComponent from  "../../Common/TitleComponent";

var helpHtml;

class HelpList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hidden: true, // htmlFor use objectid hide in table
      sessionToken: localStorage.getItem('sessionToken'),
      userId: localStorage.getItem('userId'),
      accountId: localStorage.getItem('accountId'),
      list: [],
      modal: false,
      modalExcel: false,
      saveType: 'POST',
      isCreated: false,
      successRedirect: false,
      helpEditor: false,
      sideActive: 'Overview',
      helpData: [],
      fetchRequest: false,
    };
  }

  UNSAFE_componentWillMount() {
    let data = {
      /*accountId:this.state.accountId,
      userId:this.state.userId,*/
      helpType: 'Overview',
    };
    this.props.getHelpDetailsAction(data);
  }

  componentDidMount() {
    this.setState({ fetchRequest: true });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps && nextProps.helpInfo && nextProps.helpInfo.length > 0) {
      this.helpRender(nextProps.helpInfo);
      this.setState({ fetchRequest: false, helpData: nextProps.helpInfo });
    }

    if (nextProps && nextProps.helpInfo && nextProps.helpInfo.length === 0) {
      this.setState({ fetchRequest: false, helpData: [] });
      this.helpRender([]);
    }
  }

  helpRender(helpData) {
    var data = helpData;
    helpHtml = data.map((v, index) => this.pegHtml(v, index));
    //this.setState({fetchRequest:false})
  }

  pegHtml(v, i) {
    let result = v && v.description ? renderHTML(v.description) : '-';
    return (
      <div key={i}>
        <h3>
          {i + 1}.&nbsp;{v.title}
        </h3>
        <p>{result}</p>
      </div>
    );
  }

  helpGet(e, p) {
    this.setState({ fetchRequest: true });
    this.setState({
      sideActive: p,
    });
    let data = {
      accountId: this.state.accountId,
      userId: this.state.userId,
      helpType: p,
    };
    this.props.getHelpDetailsAction(data);
  }

  render() {
    return (
      <div className="ContactListReport">
         <TitleComponent data={{title:'Help'}}/>
        {this.state.userId ? <Suspense fallback="loading"><Header props={this.props} /></Suspense> : <Header2 props={this.props} />}
        <div className="contactBox">
          <div className="container">
            <div className="row">
              <div className="col-sm-2">
                <div className="sidebarBox">
                  {/*<SidebarHelp props={this.props} />*/}
                  <div className="headingbox">
                    <h2 className="heading2">Help</h2>
                  </div>
                  <ul className="sidebarlist">
                    <li className="nav-item" onClick={(e) => this.helpGet(e, 'Overview')}>
                      <a
                        className={
                          this.state.sideActive === 'Overview' ? 'nav-link helpActive' : 'nav-link'
                        }
                      >
                        <span className="menu-title">Overview</span>
                      </a>
                    </li>
                    <li className="nav-item" onClick={(e) => this.helpGet(e, 'CCP')}>
                      <a
                        className={
                          this.state.sideActive === 'CCP' ? 'nav-link helpActive' : 'nav-link'
                        }
                      >
                        <span className="menu-title">CommandPost</span>
                      </a>
                    </li>
                    <li className="nav-item" onClick={(e) => this.helpGet(e, 'Masters')}>
                      <a
                        className={
                          this.state.sideActive === 'Masters' ? 'nav-link helpActive' : 'nav-link'
                        }
                      >
                        <span className="menu-title">Masters</span>
                      </a>
                    </li>
                    <li className="nav-item" onClick={(e) => this.helpGet(e, 'People')}>
                      <a
                        className={
                          this.state.sideActive === 'People' ? 'nav-link helpActive' : 'nav-link'
                        }
                      >
                        <span className="menu-title">People</span>
                      </a>
                    </li>
                    <li className="nav-item" onClick={(e) => this.helpGet(e, 'PROJECT')}>
                      <a
                        className={this.state.sideActive === 'Project' ? 'helpActive' : 'nav-link'}
                      >
                        <span className="menu-title">Projects</span>
                      </a>
                    </li>
                    <li className="nav-item" onClick={(e) => this.helpGet(e, 'Lead')}>
                      <a
                        className={
                          this.state.sideActive === 'Lead' ? 'nav-link active' : 'nav-link'
                        }
                      >
                        <span className="menu-title">Leads</span>
                      </a>
                    </li>
                    <li className="nav-item" onClick={(e) => this.helpGet(e, 'Reports')}>
                      <a
                        className={this.state.sideActive === 'Reports' ? 'helpActive' : 'nav-link'}
                      >
                        <span className="menu-title">Reports</span>
                      </a>
                    </li>
                    <li className="nav-item" onClick={(e) => this.helpGet(e, 'FAQs')}>
                      <a className={this.state.sideActive === 'FAQs' ? 'helpActive' : 'nav-link'}>
                        <span className="menu-title">FAQs</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-sm-10">
                <div className="rightTableBox helpBox">
                  <div id="content" className="tab-content" role="tablist">
                    <div id="pane-1" className="pane" role="tabpanel" aria-labelledby="tab-1">
                      <div id="collapse-1" className="" role="tabpanel" aria-labelledby="heading-1">
                        <div className="cardBody">
                          <div className="headingbox">
                            <h2 className="heading2">{this.state.sideActive}</h2>
                          </div>

                          <div className="row">
                            <div className="col-md-12">
                              {this.state.helpData && this.state.helpData.length > 0 && helpHtml}

                              {this.state.helpData && this.state.helpData.length === 0 && (
                                <div className="msgBox1">
                                  <span>No data available!</span>
                                </div>
                              )}

                              {this.state.fetchRequest && (
                                <div id="loaderBox" className="msgBox">
                                  <img src={loaderImage1} width="100" height="100" />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    helpInfo: state.help.helpData,
    isCreated: state.help.isCreated,
    message: state.help.message,
    success: state.help.success,
  };
}
export default withRouter(
  connect(mapStateToProps, {
    getHelpDetailsAction,
  })(HelpList)
);
