import { RootState } from '../store';

export const getImportingContacts = (state: RootState) => state.rtk.contacts.importingContacts;

export const selectSaveContact = (state) => state.rtk.contacts.saveContact;

export const getViewContacts = (state) => state.rtk.contacts.viewContacts;

export const selectContactRolesDropdown = (state) => state.rtk.contacts.contactRolesDropdown;

export const selectUserRolesDropdown = (state) => state.rtk.contacts.userRolesDropdown;

export const selectSendUserInvite = (state) => state.rtk.contacts.sendUserInvite;

export const selectOldContactDetails = (state)=> state.contact.response;

export const selectResendVerificationEmail = (state) => state.rtk.contacts.resendVerificationEmail;

export const selectCancelVerificationEmail = (state) => state.rtk.contacts.cancelVerificationEmail;

export const selectInternalContactsDropdown = (state) =>
  state.rtk.contacts.getInternalContactsDropdown;

export const selectExternalContactsDropdown = (state) =>
  state.rtk.contacts.getExternalContactsDropdown;

export const selectGetContactAffiliations = (state) => state.rtk.contacts.getContactAffiliations;

export const selectGetEmployees = (state) => state.rtk.contacts.getEmployees;

export const selectGetEmployeesTotal = (state) => state.rtk.contacts.getEmployeesTotal;

export const selectGetEmployeeInvitationEmails = (state: RootState) =>
  state.rtk.contacts.getEmployeeInvitationEmails;

export const selectSendEmployeeInvitation = (state: RootState) =>
  state.rtk.contacts.sendEmployeeInvitation;

export const selectGetAccountEmail = (state: RootState) => state.rtk.contacts.getAccountEmail;

export const selectGetEmployeeInvitationStatus = (state: RootState) =>
  state.rtk.contacts.getEmployeeInvitationStatus;

export const selectResendEmployeeInvitation = (state: RootState) =>
  state.rtk.contacts.resendEmployeeInvitation;

export const selectCancelEmployeeInvitation = (state: RootState) =>
  state.rtk.contacts.cancelEmployeeInvitation;

export const selectUpdateEmployeeRole = (state: RootState) => state.rtk.contacts.updateEmployeeRole;

export const selectSendPasswordReset = (state: RootState) => state.rtk.contacts.sendPasswordReset;

export const selectDisableUser = (state: RootState) => state.rtk.contacts.disableUser;

export const selectEnableUser = (state: RootState) => state.rtk.contacts.enableUser;

export const selectGetManageUserInfo = (state: RootState) => state.rtk.contacts.getManageUserInfo;

export const selectGetContact = (state: RootState) => state.rtk.contacts.getContact;

export const selectGetNotificationActivity = (state: RootState) => state.rtk.contacts.getNotificationActivity;

export const selectGetTeamActivity = (state: RootState) => state.rtk.contacts.getTeamActivity;

export const selectGetAllContactAffiliations = (state: RootState) =>
  state.rtk.contacts.getAllContactAffiliations;
