import {
  ActionReducerMapBuilder,
  AsyncThunk,
} from '@reduxjs/toolkit';

/**
 * Default state example:
 * @example
 * {
 *      data: any,
 *      loading: false,
 *      error: null
 * }
 * @param {string} stateField 
 * @param {import("@reduxjs/toolkit").AsyncThunk} thunk 
 * @param {import("@reduxjs/toolkit").ActionReducerMapBuilder} builder

 */
export const buildDefaultActionResult = (
  stateField: string,
  thunk: AsyncThunk<any, any, any>,
  builder: ActionReducerMapBuilder<any>
) => {
  builder.addCase(thunk.pending, (state) => {
    state[stateField].loading = true;
    state[stateField].error = null;
  });
  builder.addCase(thunk.rejected, (state, action) => {
    state[stateField].loading = false;
    state[stateField].error = action.error;
  });
  builder.addCase(thunk.fulfilled, (state, action) => {
    state[stateField].data = action.payload;
    state[stateField].loading = false;
    state[stateField].error = null;
  });
};

/**
 * @param {string} stateField 
 * @param {import("@reduxjs/toolkit").ThunkAction} thunk 
 * @param {import("@reduxjs/toolkit").ActionReducerMapBuilder} builder
 * @param {any} argCallback

 */
export const buildDefaultActionResultForList = (
  stateField: string,
  thunk: AsyncThunk<any, any, any>,
  builder: ActionReducerMapBuilder<any>,
  argCallback: (arg: any) => any = () => {
    throw new Error('Please define arg callback');
  }
) => {
  builder.addCase(thunk.pending, (state, action) => {
    state[stateField][argCallback(action.meta.arg)] = {
      loading: true,
      error: null,
    };
  });
  builder.addCase(thunk.rejected, (state, action) => {
    state[stateField][argCallback(action.meta.arg)] = {
      loading: false,
      error: action.error,
    };
  });
  builder.addCase(thunk.fulfilled, (state, action) => {
    state[stateField][argCallback(action.meta.arg)] = {
      data: action.payload,
      error: null,
      loading: false,
    };
  });
};
