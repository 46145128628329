import * as actionTypes from '../../actions/Admin/actionTypes';
const initState = {
  contact: {},
  message: '',
  alert: {},
  isfetched: false,
  isfetchedError: false,
  contactSubmission: false,
  isStatus: false,
  isContactCreated: false,
  isContactUpdated: false,
  isContactError: false,
  isContactDeleted: false,
  isVenderCreated: false,
  success: null,

  isDashboard: false,
  isDashboardSuccess: false,
  isDashboardError: false,
};

export default (state = initState, action = {}) => {
  switch (action.type) {
    case actionTypes.GET_EXISTING_CONTACT_REQUEST:
      return {
        ...state,
        contact: {},
        alert: {},
        message: '',
        isfetched: false,
        isfetchedError: false,
        isStatus: false,
      };
    case actionTypes.GET_EXISTING_CONTACT_SUCCESS:
      return {
        ...state,
        contact: action.payload.response.results,
        message: 'Contact Already Existing',
        alert: {},
        isfetched: true,
        isfetchedError: false,
        isStatus: false,
      };

    case actionTypes.GET_EXISTING_CONTACT_ERROR:
      return {
        ...state,
        message: '',
        isfetched: false,
        alert: {},
        isfetchedError: true,
        isStatus: false,
      };
    case actionTypes.CREATE_CONTACT_REQUEST:
      return {
        ...state,
        response: [],
        message: '',
        alert: {},
        isStatus: false,
        isContactCreated: false,
      };
    case actionTypes.CREATE_CONTACT_SUCCESS:
      return {
        ...state,
        addContactData: action.payload.response.result.data,
        isStatus: true,
        isContactCreated: true,
        success: action.payload.response.result.success,
        message: action.payload.response.result.success
          ? 'Record Added successfully'
          : action.payload.response.result.msg,
      };
    case actionTypes.CREATE_CONTACT_ERROR:
      return {
        ...state,
        response: [],
        isStatus: false,
        alert: { message: action.payload.error, type: 'alert-danger' },
        isContactCreated: false,
        success: action.payload.error.success,
        message: action.payload.error.msg,
      };
    case actionTypes.GET_ALL_CONTACT_REQUEST:
      return {
        ...state,
        response: [],
        message: '',
        alert: {},
        isStatus: false,
        isfetched: false,
      };
    case actionTypes.GET_ALL_CONTACT_SUCCESS:
      return {
        ...state,
        response: action.payload.response.result.data,
        count: action.payload.response.result.count,
        message: '',
        isStatus: true,
        isfetched: true,
        alert: {},
      };
    case actionTypes.GET_ALL_CONTACT_ERROR:
      return {
        ...state,
        response: [],
        message: action.payload.error,
        isStatus: false,
        isfetched: false,
        alert: { message: action.payload.error, type: 'alert-danger' },
      };
    case actionTypes.UPDATE_CONTACT_REQUEST:
      return {
        ...state,
        response: [],
        message: '',
        isContactCreated: false,
      };
    case actionTypes.UPDATE_CONTACT_SUCCESS:
      return {
        ...state,
        response: action.payload.response.result,
        isContactCreated: true,
        success: action.payload.response.result.success,
        message: action.payload.response.result.success
          ? 'Record updated successfully'
          : action.payload.response.result.msg,
      };
    case actionTypes.UPDATE_CONTACT_ERROR:
      return {
        ...state,
        response: [],
        isStatus: false,
        isContactCreated: false,
        success: action.payload.error.success,
        message: action.payload.error.msg,
      };

    case actionTypes.DELETE_CONTACT_REQUEST:
      return {
        ...state,
        response: [],
        message: '',
        alert: {},
        isStatus: false,
      };
    case actionTypes.DELETE_CONTACT_SUCCESS:
      return {
        ...state,
        response: action.payload.response.result,
        isStatus: true,
        alert: { message: action.payload.response, type: 'alert-success' },
        isContactCreated: true,
        message: action.payload.response.result.success
          ? 'Record Deleted successfully'
          : action.payload.response.result.msg,
        success: action.payload.response.result.success,
      };
    case actionTypes.DELETE_CONTACT_ERROR:
      return {
        ...state,
        response: [],
        isStatus: false,
        success: action.payload.error.success,
        message: action.payload.error.msg,
      };

    // get dashboard api
    case actionTypes.ADMIN_DASHBOARD_REQUEST:
      return {
        ...state,
        message: '',
        success: '',
        isDashboard: false,
        isDashboardSuccess: false,
        isDashboardError: false,
      };
    case actionTypes.ADMIN_DASHBOARD_SUCCESS:
      return {
        ...state,
        isDashboard: action.payload.response.result.data,
        success: action.payload.response.result.success,
        message: action.payload.response.result.success
          ? 'listed data successfully'
          : action.payload.response.result.msg,
        isDashboardSuccess: true,
        isDashboardError: false,
      };

    case actionTypes.ADMIN_DASHBOARD_ERROR:
      return {
        ...state,
        isDashboard: false,
        isDashboardSuccess: false,
        isDashboardError: true,
        success: action.payload.error.success,
        message: action.payload.error.msg,
        // success: action.payload.response.result.success,
        // message: action.payload.response.result.msg,
      };

    default:
      return state;
  }
};
