import { joiResolver } from '@hookform/resolvers/joi';
import {
  useEffect,
  useRef,
  useState,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { getLastJobNumAction } from '../../actions/project';
import { selectProjectButtonActive } from '../../selectors/project';
import { useAppDispatch } from '../../shared/hooks/use-app-dispatch';
import { useAppSelector } from '../../shared/hooks/use-app-selector';
import { formatDateObject, formatDateObjectOrNull } from '../../utils/date-formatters';
import styles from './add-project-modal.module.css';
import { AddProjectModalSchema, addProjectModalSchema } from './add-project-modal.schema';
import { ProjectCompanyTeam } from './sections/project-company-team';
import { ProjectDealInformation } from './sections/project-deal-information';

import { getAccountSettingsAction } from '../../actions/accountSettings';
import { getProjectStagesOrder } from '../../shared/custom-fields-controls/constants';

import { ProjectDatesAndDollars } from './sections/project-dates-and-dollars';
import { ProjectInformation } from './sections/project-information';
import { ProjectMarketingInformation } from './sections/project-marketing-information';
import { ProjectAdditionalInformation } from './sections/project-additional-information';
import { ProjectCustomSection } from './sections/project-custom-section';
import { LeadJobAddress } from './sections/lead-job-address';

import { getAllJobScopeAction } from '../../actions/jobScope';
import { getAllJobStatusAction } from '../../actions/jobStatusCode';

import { UpperCaseName } from '../../components/Auth/Auth';
import { getAccountProjectSettings } from '../../modules/accounts';
import {
  createProject,
  getNextProjectNumber,
  getProjectCustomFieldsValuesForm,
  getUpdateProjectForm,
  promoteLeadToProject,
  updateProject,
} from '../../modules/projects';
import { useDebounce } from '../../shared/hooks/use-debounce';
import { useSubmit } from '../../shared/hooks/use-submit';
import { Minimap } from '../../shared/minimap';
import { getProjectStageByCode } from '../../shared/custom-fields-controls/constants';
import { getProjectRoles } from '../../modules/settings';
import { Userpilot } from 'userpilot';
import { ProjectCustomFieldsForSection } from './project-custom-fields';
import { selectAllJobStatusOptions } from '../../selectors/jobStatusCode';
import { numberToCurrency, currencyToNumber } from '../../utils/number-formatters';

const getDefaultValues = () => ({
  jobName: '',
  jobNumPrefix: '',
  jobNum: 1,
  altJobNum: '',
  relatedJobNum: '',
  relatedAltJobNum: '',
  altServiceNum: '',
  isWarrantyService: false,
  jobStatusCodes: null,
  jobStatusCodesCode: null,
  marketingDescription: '',
  jobNotes: '',
  isRef: false,
  startDate: null,
  endDate: null,
  bidDue: null,
  bidStart: null,
  bidSubmittedDate: null,
  ntpDate: null,
  deliveredDate: null,
  followUpDone: null,
  closeoutDate: null,
  contractAmount: null,
  grossProfit: null,
  sqFeet: null,
  jobAddress: '',
  jobAddress2: '',
  jobCity: '',
  jobState: '',
  jobZip: '',
  jobCountry: '',
  apNum: '',
  lat: 0,
  lng: 0,
  buildingType: null,
  scope: [],
  occupancyType: null,
  negotiationMethod: null,
  permitNo: '',
  isBondRqd: false,
  leadDate: formatDateObject(),
  planLoc: '',
  leadSource: '',
  leadCohort: '',
  warrPeriod: null,
  lienPeriod: null,
  projectTeam: {},
  createdFrom: 'WEB',
  timezone: '',
  updateComment: '',
});

const formatProjectData = (data) => ({
  jobName: data.jobName || '',
  jobNumPrefix: data.jobNumPrefix || '',
  jobNum: data.jobNum || 0,
  altJobNum: data.altJobNum || '',
  relatedJobNum: data.relatedJobNum || '',
  relatedAltJobNum: data.relatedAltJobNum || '',
  isWarrantyService: !!data.isWarrantyService,
  altServiceNum: data.altServiceNum || '',
  jobStatusCodes: data.jobStatusCodes,
  jobStatusCodesCode: data.jobStatusCodesCode,
  marketingDescription: data.marketingDescription || '',
  jobNotes: data.jobNotes || '',
  isRef: !!data.isRef,
  startDate: formatDateObjectOrNull(data.startDate),
  endDate: formatDateObjectOrNull(data.endDate),
  bidDue: formatDateObjectOrNull(data.bidDue),
  bidStart: formatDateObjectOrNull(data.bidStart),
  bidSubmittedDate: formatDateObjectOrNull(data.bidSubmittedDate),
  ntpDate: formatDateObjectOrNull(data.ntpDate),
  deliveredDate: formatDateObjectOrNull(data.deliveredDate),
  followUpDone: formatDateObjectOrNull(data.followUpDone),
  closeoutDate: formatDateObjectOrNull(data.closeoutDate),
  contractAmount: data.contractAmount?numberToCurrency(data.contractAmount):null,
  grossProfit: data.grossProfit?numberToCurrency(data.grossProfit):null,
  sqFeet: data.sqFeet || null,
  jobAddress: data.jobAddress || '',
  jobAddress2: data.jobAddress2 || '',
  jobCity: data.jobCity || '',
  jobState: data.jobState || '',
  jobZip: data.jobZip || '',
  jobCountry: data.jobCountry || '',
  apNum: data.apNum || '',
  lat: data.lat || 0,
  lng: data.lng || 0,
  buildingType: data.buildingType || null,
  scope: data.scope || [],
  occupancyType: data.occupancyType || null,
  negotiationMethod: data.negotiationMethod || null,
  permitNo: data.permitNo || '',
  isBondRqd: !!data.isBondRqd,
  leadDate: formatDateObjectOrNull(data.leadDate),
  planLoc: data.planLoc || '',
  leadSource: data.leadSource || '',
  leadCohort: data.leadCohort || '',
  warrPeriod: data.warrPeriod?.toString() || null,
  lienPeriod: data.lienPeriod?.toString() || null,
  projectTeam: data.projectTeam || {},
  createdFrom: data.createdFrom || 'WEB',
  timezone: data.timezone || '',
  updateComment: data.updateComment || '',
});

type AddProjectModalProps = {
  open: boolean;
  showSaveAndNext: boolean;
  onClose?: () => void;
  onManageBid?: () => void;
  projectId?: string;
  onSubmit?: (...args) => Promise<any>;
  onSubmitAndAdd?: (...args) => Promise<any>;
  initialSection?: string;
  promotionData?: any;
  onPromote?: (data?: any) => void;
  isImport?: boolean;
};

export const AddProjectModal = forwardRef(
  (
    {
      open = false,
      showSaveAndNext = true,
      onClose = () => void 0,
      onManageBid = () => void 0,
      projectId,
      onSubmit: onSubmitCb = () => Promise.resolve(),
      onSubmitAndAdd: onSubmitAndAddCb = () => Promise.resolve(),
      initialSection = '',
      promotionData = null,
      onPromote = () => void 0,
      isImport = false,
    }: AddProjectModalProps,
    ref
  ) => {
    const [companyName] = useState(() => localStorage.getItem('companyName'));
    //const [accountId] = useState(() => localStorage.getItem('accountId'));
    //const [userId] = useState(() => localStorage.getItem('userId'));

    const isUpdate = !!projectId;
    const isPromoting = !!promotionData && isUpdate;

    const directSubmit = isPromoting && promotionData.directSubmit;

    const dispatch = useAppDispatch();

    const projectButtonActive = useAppSelector(selectProjectButtonActive);
    const projectStatusCodes = useAppSelector(selectAllJobStatusOptions);
    const [accountSettings, setAccountSettings] = useState<any>({});

    const form = useForm<AddProjectModalSchema>({
      defaultValues: getDefaultValues(),
      resolver: joiResolver(addProjectModalSchema),
      context: { accountSettings },
    });

    const customFieldsForm = useForm();
    const customFormValues = customFieldsForm.watch();

    const jobName = form.watch('jobName', '');
    const bidDue = form.watch('bidDue');
    const startDate = form.watch('startDate');
    const deliveredDate = form.watch('deliveredDate');
    const endDate = form.watch('endDate');

    const [resetKey, setResetKey] = useState(0);

    useImperativeHandle(ref, () => ({ getProjectData, setProjectData }), [projectId]);

    useDebounce(async () => {
      if (!jobName) {
        return;
      }

      form.setValue('jobName', UpperCaseName(jobName));
    }, [jobName]);

    const [minimapActiveSection, setMinimapActiveSection] = useState<string>('');
    const [projectRoles, setProjectRoles] = useState([]);

    const userAffiliationId = localStorage.getItem('userContactAffiliationId');
    const userId = localStorage.getItem('userId');

    const [isAssigned, setIsAssigned] = useState(false);

    const [dividerRules, setDividerRules] = useState({});

    /// Initial data fetching
    useEffect(() => {
      if (!open) {
        return;
      }

      dispatch(getProjectRoles())
        .unwrap()
        .then((data) => {
          setProjectRoles(data);
        });

      dispatch(getAllJobStatusAction({ className: 'jobStatusCodes' }));
      dispatch(getAllJobScopeAction({ className: 'jobScopes' }));

      dispatch(getLastJobNumAction());

      dispatch(getProjectCustomFieldsValuesForm({ projectId }))
        .unwrap()
        .then((data) => {
          const defaultValues = data.reduce((acc, field) => {
            if (field.valueDefinition.isUrl) {
              acc[field._id] = {
                label: field.valueDefinition.defaultValue,
                url: field.valueDefinition.defaultValueUrl,
              };
              return acc;
            }

            acc[field._id] = field.valueDefinition.defaultValue;
            return acc;
          }, {});

          if (!isUpdate) {
            setCustomFieldsDefaultValues({ ...defaultValues });
            const existingValues = customFieldsForm.getValues();
            customFieldsForm.reset(
              { ...defaultValues, ...existingValues },
              { keepDefaultValues: true }
            );
          }

          data = data.sort((a, b) => {
            if (a.section === b.section) {
              return a.order - b.order;
            }

            return a.section.localeCompare(b.section);
          });

          let lastSection = '';
          let currentDivider = null;
          for (const field of data) {
            if (field.controlType === 'DIVIDER') {
              lastSection = field.section;
              currentDivider = field.name;
              field.currentDivider = currentDivider;

              if (field.valueDefinition.criteria) {
                setDividerRules((prev) => ({
                  ...prev,
                  [field.name]: {
                    criteriaCode: field.valueDefinition.criteria,
                    criteriaValue: field.valueDefinition.criteriaValue?.map(
                      (criteriaValue) => criteriaValue._id
                    ),
                  },
                }));
              }
            } else if (currentDivider && field.section == lastSection) {
              field.currentDivider = currentDivider;
            } else {
              field.currentDivider = field.section;
              currentDivider = field.section;
              lastSection = field.section;
            }
          }

          setCustomFields(data);
        });

      getProjectData();
      dispatch(getAccountSettingsAction());
    }, [open, isUpdate, isPromoting, projectId]);

    const [customFields, setCustomFields] = useState([]);
    const [customFieldsDefaultValues, setCustomFieldsDefaultValues] = useState([]);

    useEffect(() => {
      dividerRules &&
        Object.keys(dividerRules).forEach((divider) => {
          const customFieldCriteriaId = (
            customFields.find(
              (field: any) => field.code === dividerRules[divider].criteriaCode
            ) as any
          )?._id;
          dividerRules[divider].criteria = customFieldCriteriaId;
        });
    }, [customFields?.length]);

    const warrPeriod = form.watch('warrPeriod');

    useEffect(() => {
      // if line period or warranty period is null, set it to default value from accountSettings
      if (accountSettings.warrantyPeriod && !warrPeriod) {
        form.setValue('warrPeriod', accountSettings.warrantyPeriod.toString());
      }

      if (accountSettings.lienPeriod && !form.getValues().lienPeriod) {
        form.setValue('lienPeriod', accountSettings.lienPeriod);
      }
    }, [Object.keys(accountSettings).length, warrPeriod]);

    const setProjectData = (data) => {
      // as far as projectTeam is an object form.reset MERGES its properties leaving previous values if they missing in server response
      // here setting empty array for roles that missing in server response
      const emptyRoles = projectRoles
        .map((role: any) => role._id)
        .filter((key) => !Object.keys(data.projectTeam).includes(key));

      emptyRoles.forEach((roleId) => (data.projectTeam[roleId] = []));

      /*form.reset({
        startDate: null,
        endDate: null,
        bidDue: null,
        bidStart: null,
        bidSubmittedDate: null,
      });*/

      let allAssignments: any[] = [];
      for (const role in projectRoles as any) {
        const roleId: any = projectRoles[role]._id as string;
        allAssignments = allAssignments.concat(data.projectTeam[roleId] as Array<string>);
      }
      const isCreator = data?.createdBy === userId;

      setIsAssigned(allAssignments.includes(userAffiliationId) || isCreator);

      form.reset(formatProjectData({ ...data }), { keepDefaultValues: true });

      if (data.customFields) {
        customFieldsForm.reset(data.customFields, { keepDefaultValues: true });
      }
    };

    // TODO: rename to refreshForm
    const getProjectData = async () => {
      let data: any = getDefaultValues();

      if (isUpdate) {
        data = await dispatch(getUpdateProjectForm({ projectId })).unwrap();
      } else if (isImport) {
        data = form.getValues();
      }

      const settings = await dispatch(getAccountProjectSettings({})).unwrap();

      // eslint-disable-next-line prefer-const
      let { jobNum, jobNumPrefix, jobStatusCodes, jobStatusCodesCode, warrPeriod, lienPeriod } =
        data as any;

      warrPeriod = warrPeriod ?? settings.warrantyPeriod?.toString();
      lienPeriod = lienPeriod ?? settings.lienPeriod;

      if (Object.keys(accountSettings).length === 0) {
        setAccountSettings({ ...settings });
      }

      if (isImport && !isUpdate) {
        const foundCode = projectStatusCodes.find((code) => code.value === data.jobStatusCodes);

        if (foundCode) {
          jobStatusCodes = foundCode.value;
          jobStatusCodesCode = foundCode.code;
        }

        if (!data.jobNum) {
          const nextJobNum = await dispatch(getNextProjectNumber()).unwrap();
          jobNum = nextJobNum.jobNum;
          jobNumPrefix = nextJobNum.jobNumPrefix;
        }
      } else if (!isUpdate) {
        const nextJobNum = await dispatch(getNextProjectNumber()).unwrap();
        jobNum = nextJobNum.jobNum;
        jobNumPrefix = nextJobNum.jobNumPrefix;
      }

      if (isPromoting) {
        const foundCode = projectStatusCodes.find(
          (code) => code.value === promotionData.jobStatusCodesId
        );
        if (foundCode) {
          jobStatusCodes = foundCode.value;
          jobStatusCodesCode = foundCode.code;
        }

        jobNum = promotionData.jobNum;
        jobNumPrefix = promotionData.jobNumPrefix;
        data.updateComment = promotionData.updateComment;
      }

      setProjectData({
        ...data,
        jobNum,
        jobNumPrefix,
        jobStatusCodes,
        jobStatusCodesCode,
        warrPeriod,
        lienPeriod,
      });
      setMinimapActiveSection(initialSection);
    };

    const submitProject = async (isSaveAndAddNext = false) => {
      let projectData: any = null;
      let customFieldsData: any = null;
      await form.handleSubmit(async (data) => {
        projectData = data;
      }, console.log)();
      await customFieldsForm.handleSubmit(async (data) => {
        customFieldsData = data;
      }, console.log)();

      if (!projectData || !customFieldsData) {
        throw new Error('Invalid form data');
      }

      projectData.contractAmount = currencyToNumber(projectData.contractAmount);
      projectData.grossProfit = currencyToNumber(projectData.grossProfit);

      if (isPromoting) {
        const project = await dispatch(
          promoteLeadToProject({
            ...promotionData,
            jobNum: projectData.jobNum,
            jobNumPrefix: projectData.jobNumPrefix,
            jobStatusCodesId: projectData.jobStatusCodes,
            updateProjectData: {
              ...projectData,
              customFields: customFieldsData,
              projectId,
            },
          })
        ).unwrap();

        //Userpilot.track('Edited Project');
        Userpilot.track('Promoted Project');

        return project;
      }

      const project = await dispatch(
        isUpdate
          ? updateProject({ ...projectData, customFields: customFieldsData, projectId })
          : createProject({
              ...projectData,
              customFields: customFieldsData,
              isSaveAndAddNext,
            })
      ).unwrap();

      if (!isUpdate) {
        Userpilot.track('Added Project');
      } else if (isPromoting) {
        Userpilot.track('Promoted Lead');
      } else {
        Userpilot.track('Edited Project');
      }

      // freezes when uncommented
      if (isSaveAndAddNext) {
        form.reset({
          ...form.formState.defaultValues,
        });
      }

      setResetKey((prev) => prev + 1);

      customFieldsForm.reset({ ...customFieldsDefaultValues });

      return project;
    };

    const handleClose = () => {
      customFieldsForm.reset({ ...customFieldsDefaultValues });
      form.reset({ ...form.formState.defaultValues });
      onClose();
    };

    const [onSubmit, submitting] = useSubmit(async () => {
      const project = await submitProject();

      onClose();
      onSubmitCb(project);

      if (isPromoting) {
        onPromote();
      }
    }, [isUpdate, isPromoting, promotionData, projectId]);

    const [onSubmitAndAdd, submittingAndAdd] = useSubmit(async () => {
      const project = await submitProject(true);

      await dispatch(getLastJobNumAction());

      const { jobNum, jobNumPrefix } = await dispatch(getNextProjectNumber()).unwrap();

      customFieldsForm.reset({ ...customFieldsDefaultValues });
      form.reset({ ...form.formState.defaultValues });

      form.setValue('jobNumPrefix', jobNumPrefix);
      form.setValue('jobNum', jobNum);

      onSubmitAndAddCb(project);
    }, [isUpdate, projectId]);

    const minimapRef = useRef() as React.MutableRefObject<HTMLDivElement>;

    const customDateFieldsRefs = useRef<Array<any>>([]);

    useEffect(() => {
      if (!open) return;

      customDateFieldsRefs.current = [];
    }, [open]);

    // todo: find better way to pass project props into custom fields
    useEffect(() => {
      customDateFieldsRefs.current.forEach((ref) => {
        const fieldsData = { bidDue, startDate, endDate, deliveredDate };

        ref.onUpdate(fieldsData);
      });
    }, [customDateFieldsRefs?.current?.length, bidDue, startDate, deliveredDate, endDate]);

    const jobStatusCodesCode = form.watch('jobStatusCodesCode') || '';

    const projectStage = useMemo(
      () => getProjectStageByCode(jobStatusCodesCode),
      [jobStatusCodesCode]
    );

    const stagesOrder = getProjectStagesOrder();
    const projectStageIndex = useMemo(() => stagesOrder.indexOf(projectStage), [projectStage]);

    const getEarliestStageIndex = (stages: Array<string>) => {
      const stagesFiltered = stages.filter((stage) => stage !== 'all');
      if (stagesFiltered.length === 0) return 0;

      const stagesIndexes = stagesFiltered.map((stage) => stagesOrder.indexOf(stage));
      return Math.min(...stagesIndexes);
    };

    const belongToCurrentOrNextStage = useCallback(
      ({ stages }: { stages: Array<string> }) => {
        if (projectStage === 'service') return stages.includes('service');

        if (stages.length === 1 && stages[0] === 'service') return false;

        if (stages.includes('all')) {
          return true;
        }

        // if project stage is 'earlier' than earliest field stage - do not show or require field
        const fieldEarliestStageIndex = getEarliestStageIndex(stages);
        return fieldEarliestStageIndex <= projectStageIndex;
      },
      [projectStageIndex]
    );

    const shouldShowAdditionalInformation = useMemo(() => {
      const should =
        (customFields.filter((field: any) => field.section === 'additional') ?? []).filter(
          belongToCurrentOrNextStage
        ).length > 0;

      return !!should;
    }, [customFields, projectStage]);

    const [
      generalFields,
      locationFields,
      marketingFields,
      dealFields,
      additionalFields,
      generalFieldsCustomSection,
      locationFieldsCustomSection,
      marketingFieldsCustomSection,
      dealFieldsCustomSection,
      additionalFieldsCustomSection,
    ] = useMemo(() => {
      const generalFields = customFields.filter((field: any) => field.currentDivider === 'general');
      const locationFields = customFields.filter(
        (field: any) => field.currentDivider === 'location'
      );
      const marketingFields = customFields.filter(
        (field: any) => field.currentDivider === 'marketing'
      );
      const dealFields = customFields.filter((field: any) => field.currentDivider === 'deal');
      const additionalFields = customFields.filter(
        (field: any) => field.currentDivider === 'additional'
      );

      const generalFieldsCustom = customFields.filter(
        (field: any) => field.section === 'general' && field.currentDivider !== 'general'
      );
      const locationFieldsCustom = customFields.filter(
        (field: any) => field.section === 'location' && field.currentDivider !== 'location'
      );
      const marketingFieldsCustom = customFields.filter(
        (field: any) => field.section === 'marketing' && field.currentDivider !== 'marketing'
      );
      const dealFieldsCustom = customFields.filter(
        (field: any) => field.section === 'deal' && field.currentDivider !== 'deal'
      );
      const additionalFieldsCustom = customFields.filter(
        (field: any) => field.section === 'additional' && field.currentDivider !== 'additional'
      );

      const generalFieldsCustomSection = generalFieldsCustom.reduce((acc, field: any) => {
        if (field.controlType !== 'DIVIDER' && belongToCurrentOrNextStage(field)) {
          if (!acc[field.currentDivider]) {
            acc[field.currentDivider] = [];
          }

          acc[field.currentDivider].push(field);
        }

        return acc;
      }, {});

      const locationFieldsCustomSection = locationFieldsCustom.reduce((acc, field: any) => {
        if (field.controlType !== 'DIVIDER' && belongToCurrentOrNextStage(field)) {
          if (!acc[field.currentDivider]) {
            acc[field.currentDivider] = [];
          }

          acc[field.currentDivider].push(field);
        }

        return acc;
      }, {});

      const marketingFieldsCustomSection = marketingFieldsCustom.reduce((acc, field: any) => {
        if (field.controlType !== 'DIVIDER' && belongToCurrentOrNextStage(field)) {
          if (!acc[field.currentDivider]) {
            acc[field.currentDivider] = [];
          }

          acc[field.currentDivider].push(field);
        }

        return acc;
      }, {});

      const dealFieldsCustomSection = dealFieldsCustom.reduce((acc, field: any) => {
        if (field.controlType !== 'DIVIDER' && belongToCurrentOrNextStage(field)) {
          if (!acc[field.currentDivider]) {
            acc[field.currentDivider] = [];
          }

          acc[field.currentDivider].push(field);
        }

        return acc;
      }, {});

      const additionalFieldsCustomSection = additionalFieldsCustom.reduce((acc, field: any) => {
        if (field.controlType !== 'DIVIDER' && belongToCurrentOrNextStage(field)) {
          if (!acc[field.currentDivider]) {
            acc[field.currentDivider] = [];
          }

          acc[field.currentDivider].push(field);
        }

        return acc;
      }, {});

      return [
        generalFields,
        locationFields,
        marketingFields,
        dealFields,
        additionalFields,
        generalFieldsCustomSection,
        locationFieldsCustomSection,
        marketingFieldsCustomSection,
        dealFieldsCustomSection,
        additionalFieldsCustomSection,
      ];
    }, [customFields, projectStageIndex]);

    const [dividerHidden, setDividerHidden] = useState({});

    const handleDividerHidden = useCallback((divider, newVal) => {
      setDividerHidden((prev) => ({
        ...prev,
        [divider]: newVal,
      }));
    }, []);

    useEffect(() => {
      dividerRules &&
        Object.keys(dividerRules).forEach((divider) => {
          const rule = dividerRules[divider];
          if (rule.criteria) {
            const { criteria, criteriaValue } = rule;

            const value = customFormValues[criteria];
            if (Array.isArray(value)) {
              const hidden = !criteriaValue.some((v) => value.includes(v));
              if (dividerHidden[divider] !== hidden) handleDividerHidden(divider, hidden);
            } else if (typeof value === 'string') {
              const hidden = !criteriaValue.includes(value);
              if (dividerHidden[divider] !== hidden) handleDividerHidden(divider, hidden);
            } else {
              if (dividerHidden[divider] !== true) handleDividerHidden(divider, true);
            }
          }
        });
    }, [customFormValues?.length]);

    useEffect(() => {
      // if direct submit is enabled, automatically submit the form
      if (directSubmit) {
        onSubmit();
      }
    }, [directSubmit, isPromoting, jobName]);

    const validationError = useMemo(() => {
      const errors = [
        ...Object.values(form.formState.errors),
        ...Object.values(customFieldsForm.formState.errors),
      ];

      return <span className='text-danger'>{errors[0]?.message as string}</span>;
    }, [form.formState.errors, customFieldsForm.formState.errors]);

    const modalTitle = useMemo(() => {
      if (isPromoting) {
        return 'Promote lead';
      }

      if (isUpdate) {
        return 'Edit project details';
      }

      return 'Add project';
    }, [isUpdate, isPromoting]);

    return directSubmit ? null : (
      <Modal className={styles.modalDialog} backdrop='static' isOpen={open} toggle={handleClose}>
        <ModalHeader toggle={handleClose}>{modalTitle}</ModalHeader>
        <ModalBody>
          <div className='container' key={resetKey}>
            <div className='row'>
              <div className='col-md-4' ref={minimapRef}>
                <div className={styles.preloader}>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>

              <FormProvider {...form}>
                <div className='col-md-8'>
                  <Minimap
                    className='pe-md-4'
                    minimapWrapper={minimapRef.current}
                    minimapClassName='d-none d-md-block'
                    initialSection={minimapActiveSection}
                  >
                    <div
                      id='project-information'
                      data-minimap-area-label='Project information'
                      key={resetKey}
                    >
                      <ProjectInformation
                        projectId={projectId}
                        projectRoles={projectRoles}
                        customFields={
                          <FormProvider {...customFieldsForm}>
                            <ProjectCustomFieldsForSection
                              fields={generalFields}
                              projectStage={projectStage}
                              customDateFieldsRefs={customDateFieldsRefs}
                            />
                          </FormProvider>
                        }
                      />
                    </div>

                    {generalFieldsCustomSection &&
                      (Object.keys(generalFieldsCustomSection) ?? []).map((currentDivider) =>
                        dividerHidden[currentDivider] ? null : (
                          <div
                            id={currentDivider.replaceAll(' ', '-').replace('&', 'and')}
                            key={currentDivider.replaceAll(' ', '-').replace('&', 'and')}
                            data-minimap-area-label={'- ' + currentDivider}
                          >
                            <ProjectCustomSection
                              sectionName={currentDivider}
                              customFields={
                                <FormProvider {...customFieldsForm}>
                                  <ProjectCustomFieldsForSection
                                    fields={generalFieldsCustomSection[currentDivider]}
                                    projectStage={projectStage}
                                    customDateFieldsRefs={customDateFieldsRefs}
                                  />
                                </FormProvider>
                              }
                            />
                          </div>
                        )
                      )}

                    <div
                      id='location-and-description'
                      data-minimap-area-label='Location & description'
                      key={resetKey}
                    >
                      <LeadJobAddress
                        customFields={
                          <FormProvider {...customFieldsForm}>
                            <ProjectCustomFieldsForSection
                              fields={locationFields}
                              projectStage={projectStage}
                              customDateFieldsRefs={customDateFieldsRefs}
                            />
                          </FormProvider>
                        }
                      />
                    </div>

                    {locationFieldsCustomSection &&
                      (Object.keys(locationFieldsCustomSection) ?? []).map((currentDivider) =>
                        dividerHidden[currentDivider] ? null : (
                          <div
                            id={currentDivider.replaceAll(' ', '-').replace('&', 'and')}
                            key={currentDivider.replaceAll(' ', '-').replace('&', 'and')}
                            data-minimap-area-label={'- ' + currentDivider}
                          >
                            <ProjectCustomSection
                              sectionName={currentDivider}
                              customFields={
                                <FormProvider {...customFieldsForm}>
                                  <ProjectCustomFieldsForSection
                                    fields={locationFieldsCustomSection[currentDivider]}
                                    projectStage={projectStage}
                                    customDateFieldsRefs={customDateFieldsRefs}
                                  />
                                </FormProvider>
                              }
                            />
                          </div>
                        )
                      )}
                    <div
                      id='dates-and-dollars'
                      data-minimap-area-label='Dates & dollars'
                      key={resetKey}
                    >
                      <ProjectDatesAndDollars
                        projectId={projectId}
                        onManageBid={onManageBid}
                        isPromoting={isPromoting}
                        isAssigned={isAssigned}
                        accountSettings={accountSettings}
                        projectStage={projectStage}
                      />
                    </div>
                    <div
                      id='deal-information'
                      data-minimap-area-label='Deal information'
                      key={resetKey}
                    >
                      <ProjectDealInformation
                        customFields={
                          <FormProvider {...customFieldsForm}>
                            <ProjectCustomFieldsForSection
                              fields={dealFields}
                              projectStage={projectStage}
                              customDateFieldsRefs={customDateFieldsRefs}
                            />
                          </FormProvider>
                        }
                      />
                    </div>
                    {dealFieldsCustomSection &&
                      (Object.keys(dealFieldsCustomSection) ?? []).map((currentDivider) =>
                        dividerHidden[currentDivider] ? null : (
                          <div
                            id={currentDivider.replaceAll(' ', '-').replace('&', 'and')}
                            key={currentDivider.replaceAll(' ', '-').replace('&', 'and')}
                            data-minimap-area-label={'- ' + currentDivider}
                          >
                            <ProjectCustomSection
                              sectionName={currentDivider}
                              customFields={
                                <FormProvider {...customFieldsForm}>
                                  <ProjectCustomFieldsForSection
                                    fields={dealFieldsCustomSection[currentDivider]}
                                    projectStage={projectStage}
                                    customDateFieldsRefs={customDateFieldsRefs}
                                  />
                                </FormProvider>
                              }
                            />
                          </div>
                        )
                      )}
                    <div
                      id='marketing-information'
                      data-minimap-area-label='Marketing information'
                      key={resetKey}
                    >
                      <ProjectMarketingInformation
                        projectId={projectId}
                        customFields={
                          <FormProvider {...customFieldsForm}>
                            <ProjectCustomFieldsForSection
                              fields={marketingFields}
                              projectStage={projectStage}
                              customDateFieldsRefs={customDateFieldsRefs}
                            />
                          </FormProvider>
                        }
                      />
                    </div>
                    {marketingFieldsCustomSection &&
                      (Object.keys(marketingFieldsCustomSection) ?? []).map((currentDivider) =>
                        dividerHidden[currentDivider] ? null : (
                          <div
                            id={currentDivider.replaceAll(' ', '-').replace('&', 'and')}
                            key={currentDivider.replaceAll(' ', '-').replace('&', 'and')}
                            data-minimap-area-label={'- ' + currentDivider}
                          >
                            <ProjectCustomSection
                              sectionName={currentDivider}
                              customFields={
                                <FormProvider {...customFieldsForm}>
                                  <ProjectCustomFieldsForSection
                                    fields={marketingFieldsCustomSection[currentDivider]}
                                    projectStage={projectStage}
                                    customDateFieldsRefs={customDateFieldsRefs}
                                  />
                                </FormProvider>
                              }
                            />
                          </div>
                        )
                      )}
                    {shouldShowAdditionalInformation && (
                      <div
                        id='additional-information'
                        data-minimap-area-label='Additional information'
                      >
                        <ProjectAdditionalInformation
                          customFields={
                            <FormProvider {...customFieldsForm}>
                              <ProjectCustomFieldsForSection
                                fields={additionalFields}
                                projectStage={projectStage}
                                customDateFieldsRefs={customDateFieldsRefs}
                              />
                            </FormProvider>
                          }
                        />
                      </div>
                    )}
                    {additionalFieldsCustomSection &&
                      (Object.keys(additionalFieldsCustomSection) ?? []).map((currentDivider) =>
                        dividerHidden[currentDivider] ? null : (
                          <div
                            id={currentDivider.replaceAll(' ', '-').replace('&', 'and')}
                            key={currentDivider.replaceAll(' ', '-').replace('&', 'and')}
                            data-minimap-area-label={'- ' + currentDivider}
                          >
                            <ProjectCustomSection
                              sectionName={currentDivider}
                              customFields={
                                <FormProvider {...customFieldsForm}>
                                  <ProjectCustomFieldsForSection
                                    fields={additionalFieldsCustomSection[currentDivider]}
                                    projectStage={projectStage}
                                    customDateFieldsRefs={customDateFieldsRefs}
                                  />
                                </FormProvider>
                              }
                            />
                          </div>
                        )
                      )}

                    <div id='company-team' data-minimap-area-label={`${companyName} team`}>
                      <ProjectCompanyTeam
                        projectRoles={projectRoles}
                        isInternal={true}
                        isAssigned={isAssigned}
                        isUpdate={isUpdate}
                      />
                    </div>
                    <div id='external-team' data-minimap-area-label='External team'>
                      <ProjectCompanyTeam
                        projectRoles={projectRoles}
                        isAssigned={isAssigned}
                        isUpdate={isUpdate}
                      />
                    </div>
                  </Minimap>
                </div>
              </FormProvider>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          {validationError}
          <button type='button' className='btn btn-primary' onClick={handleClose}>
            Cancel
          </button>
          {isPromoting && (
            <button
              type='button'
              className='btn btn-primary'
              disabled={submitting || submittingAndAdd || projectButtonActive}
              onClick={onSubmit}
            >
              Promote
            </button>
          )}
          {!isPromoting && (
            <button
              type='button'
              className='btn btn-primary'
              disabled={submitting || submittingAndAdd || projectButtonActive}
              onClick={onSubmit}
            >
              Save
            </button>
          )}
          {!isUpdate && showSaveAndNext && (
            <button
              className='btn btn-primary'
              disabled={submitting || submittingAndAdd || projectButtonActive}
              onClick={onSubmitAndAdd}
            >
              Save & Add Next Project
            </button>
          )}
        </ModalFooter>
      </Modal>
    );
  }
);
