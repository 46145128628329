import React, { useEffect, useRef, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import FormInput from '../../../shared/form-controls/form-input/form-input';
import FormSelect from '../../../shared/form-controls/form-select';
import { useAppDispatch } from '../../../shared/hooks/use-app-dispatch';
import { useAppSelector } from '../../../shared/hooks/use-app-selector';
import {
  addUserSettings,
  updateUserSettings,
  disableUserSetting,
  enableUserSetting,
} from '../../../modules/settings';
import { useSubmit } from '../../../shared/hooks/use-submit';
import { useConfirm } from '../../../shared/confirmation-dialog';
import { Checkbox, notification } from 'antd';
import { fileUploadOnS3 } from '../../../utils/s3-attachment-handler';

const templateTypes = [
  { value: 'changeOrder', label: 'Change Order' },
  { value: 'bid', label: 'Bid' },
  { value: 'contract', label: 'Contract' },
  { value: 'project', label: 'Project' },
  { value: 'contact', label: 'Contact' },
  { value: 'company', label: 'Company' },
];

export const EditFormTemplateModal: React.FC<{
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  templateId: string | null;
  initialFile?: File | null;
}> = ({ open, onClose, onSubmit, templateId, initialFile }) => {
  const modelName = 'formTemplates';
  const isUpdate = !!templateId;
  const dispatch = useAppDispatch();
  const confirm = useConfirm();
  const isMounted = useRef(true);
  const [fileUploading, setFileUploading] = useState(false);
  const [dragActive, setDragActive] = useState(false);

  

  const form = useForm({
    defaultValues: {
      name: '',
      definition: '',
      type: '',
      formFileName: '',
      formUrl: '',
      isPrimaryBidForm: false,
      code: Math.random().toString(36).substring(7),
      trash: false,
    },
  });

  const template = useAppSelector((state) => {
    const templates = state.rtk.settings.getUserSettings.data;
    return templates.find((t) => (t as any)._id === templateId);
  });

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (!open || !isMounted.current) return;

    if (!template || !isUpdate) {
      form.reset({
        name: initialFile ? initialFile.name.split('.')[0] : '',
        definition: '',
        type: '',
        formFileName: initialFile ? initialFile.name : '',
        formUrl: '',
        isPrimaryBidForm: false,
        code: Math.random().toString(36).substring(7),
        trash: false,
      });

      if (initialFile) {
        handleFileUpload(initialFile);
      }
      return;
    }

    const currentUrl = (template as any).formUrl;

    form.reset({
      name: (template as any).name ?? '',
      definition: (template as any).definition ?? '',
      type: (template as any).type ?? 'bid',
      formFileName: currentUrl?.length > 0 ? (template as any).formFileName ?? '' : '',
      formUrl: currentUrl ?? '',
      isPrimaryBidForm: (template as any).isPrimaryBidForm ?? false,
      code: (template as any).code ?? Math.random().toString(36).substring(7),
      trash: (template as any).trash ?? false,
    });
  }, [template, isUpdate, open, initialFile]);

  const handleSubmit = form.handleSubmit(async (data) => {
    if (!isMounted.current) return;

    if (!data.formUrl) {
      notification.error({
        message: 'File Required',
        description: 'Please upload a file before saving.',
        duration: 3,
      });
      return;
    }

    try {
      if (isUpdate) {
        await dispatch(updateUserSettings([modelName, { ...data, _id: templateId }])).unwrap();
      } else {
        await dispatch(addUserSettings([modelName, data])).unwrap();
      }

      if (isMounted.current) {
        onSubmit();
        onClose();
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      notification.error({
        message: 'Error saving template',
        description: (error as Error).message,
        duration: 3,
      });
    }
  });

  const [handleToggle, toggleLoading] = useSubmit(async () => {
    if (!isMounted.current) return;

    const action = (template as any)?.trash ? 'enable' : 'disable';
    const confirmToggle = await confirm({
      title: `${action.charAt(0).toUpperCase() + action.slice(1)} Template`,
      question: `Are you sure you want to ${action} this template?`,
    });

    if (confirmToggle && isMounted.current) {
      try {
        if ((template as any)?.trash) {
          await dispatch(enableUserSetting([modelName, templateId])).unwrap();
        } else {
          await dispatch(disableUserSetting([modelName, templateId])).unwrap();
        }
        if (isMounted.current) {
          onSubmit();
        }
      } catch (error) {
        console.error('Error toggling template status:', error);
        notification.error({
          message: `Failed to ${action} template`,
          description: (error as Error).message,
          duration: 3,
        });
      }
    }
  }, [template, templateId]);

  const handleDrag = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFileUpload(e.dataTransfer.files[0]);
    }
  };

  const onFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      handleFileUpload(file);
    }
  };

  const formName = form.watch('name');

  const handleFileUpload = async (file: File) => {
    if (!isMounted.current) return;

    //const file = event.target.files?.[0];
    if (file) {
      setFileUploading(true);
      try {
        const { attachments, objectId, uploadSuccess } = await fileUploadOnS3(
          [file],
          templateId,
          'formTemplates',
          'FORMTEMPLATE',
          10000000,
          'FormTemplate'
        );

        console.log('new objectId:', objectId);

        if (uploadSuccess && attachments.length > 0) {
          form.setValue('formFileName', file.name);
          form.setValue('formUrl', attachments[0].fileKey);
          if(formName.length === 0) {
            form.setValue('name', file.name.split('.')[0]);
          }
          notification.success({
            message: 'File uploaded successfully',
            duration: 3,
          });
        } else {
          throw new Error('File upload failed');
        }
      } catch (error) {
        console.error('Error in file upload:', error);
        notification.error({
          message: 'File upload failed',
          description: (error as Error).message,
          duration: 3,
        });
      } finally {
        setFileUploading(false);
      }
    }
  };

  const handleRemoveFile = () => {
    form.setValue('formFileName', '');
    form.setValue('formUrl', '');
  };

  if (!open) return null;

  const presignedUrl = (template as any)?.presignedUrl;
  const fileName = form.watch('formFileName');
  const formUrl = form.watch('formUrl');

  return (
    <Modal isOpen={open} toggle={onClose} size="lg">
      <ModalHeader toggle={onClose}>{isUpdate ? 'Edit' : 'Add'} Form Template</ModalHeader>
      <ModalBody>
        <FormProvider {...form}>
          <div className="container">
            <div className="row">
              <div className="col-6">
                <FormInput label="Template name" name="name" required />
              </div>
              <div className="col-6">
                <FormSelect
                  label="Type"
                  name="type"
                  options={templateTypes}
                  required
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <FormInput label="Definition" name="definition" textArea />
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                {( formUrl?.length>0) ? (
                  <div className="form-group">
                    <label className="form-label">File</label>
                    <div className="d-flex align-items-center">
                      {presignedUrl?.includes(formUrl) ? (
                      <a href={presignedUrl} className="me-2">{fileName}</a>): (
                        <span className="me-2">{fileName}</span>)}
                      <button 
                        type="button" 
                        className="btn btn-sm btn-danger" 
                        onClick={handleRemoveFile}
                      >
                        <i className="fas fa-remove"></i>
                      </button>
                    </div>
                  </div>
                ) : (
                  <div 
                    className={`form-group file-drop-zone ${dragActive ? 'drag-active' : ''}`}
                    onDragEnter={handleDrag}
                    onDragLeave={handleDrag}
                    onDragOver={handleDrag}
                    onDrop={handleDrop}
                  >
                   <div className="form-group">
                     <label className="form-label">Upload File</label>
                     <input 
                        type="file" 
                        accept=".docx,.html"
                        onChange={onFileInputChange}
                        disabled={fileUploading}
                        className="form-control"
                      />
                   </div>
                  </div>
                )}
              </div>
              <div className="col-6">
                <Checkbox
                  checked={form.watch('isPrimaryBidForm')}
                  onChange={(e) => form.setValue('isPrimaryBidForm', e.target.checked)}
                >
                  Use as primary bid template
                </Checkbox>
              </div>
            </div>
          </div>
        </FormProvider>
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-secondary" onClick={onClose}>
          Cancel
        </button>
        {isUpdate && (
          <button 
            className={`btn ${(template as any)?.trash ? 'btn-success' : 'btn-danger'}`} 
            onClick={handleToggle} 
            disabled={toggleLoading}
          >
            {(template as any)?.trash ? 'Enable' : 'Disable'}
          </button>
        )}
        <button
          className="btn btn-primary"
          onClick={handleSubmit}
          disabled={form.formState.isSubmitting || !form.formState.isValid || fileUploading || !formUrl}
        >
          Save
        </button>
      </ModalFooter>
    </Modal>
  );
};