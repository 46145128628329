import * as actionTypes from '../../actions/Admin/actionTypes';

const initState = {
  jobStatus: {},
  allJobStatus: [],
  message: '',
  isCreated: false,
  success: null,
  fetchRequest: false,
};

export default (state = initState, action = {}) => {
  switch (action.type) {
    case actionTypes.CREATE_JOB_STATUS_REQUEST:
      return {
        ...state,
        jobStatus: {},
        message: '',
      };
    case actionTypes.CREATE_JOB_STATUS_SUCCESS:
      return {
        allJobStatus: state.allJobStatus.concat(action.payload.response.result.data),
        isCreated: true,
        success: action.payload.response.result.success,
        message: action.payload.response.result.success
          ? 'Record Added successfully'
          : action.payload.response.result.msg,
      };

    case actionTypes.CREATE_JOB_STATUS_ERROR:
      return {
        ...state,
        isCreated: false,
        success: action.payload.response.result.success,
        message: action.payload.response.result.msg,
      };

    case actionTypes.GET_ALL_JOB_STATUS_REQUEST:
      return {
        ...state,
        jobStatus: {},
        message: '',
        fetchRequest: true,
      };

    case actionTypes.GET_ALL_JOB_STATUS_SUCCESS:
      return {
        ...state,
        allJobStatus: action.payload.response.result.data.data,
        message: '',
        fetchRequest: false,
      };

    case actionTypes.GET_ALL_JOB_STATUS_ERROR:
      return {
        ...state,
        message: '',
        fetchRequest: false,
      };

    case actionTypes.UPDATE_JOB_STATUS_REQUEST:
      return {
        ...state,
        jobStatus: {},
        message: '',
      };
    case actionTypes.UPDATE_JOB_STATUS_SUCCESS:
      return {
        ...state,
        allJobStatus: action.payload.response.result.contractTypes,
        isCreated: true,
        success: action.payload.response.result.success,
        message: action.payload.response.result.success
          ? 'Record Updated successfully'
          : action.payload.response.result.msg,
      };

    case actionTypes.UPDATE_JOB_STATUS_ERROR:
      return {
        ...state,
        isCreated: false,
        success: action.payload.response.result.success,
        message: action.payload.response.result.msg,
      };

    case actionTypes.DELETE_JOB_STATUS_REQUEST:
      return {
        ...state,
        jobStatus: {},
        message: '',
      };
    case actionTypes.DELETE_JOB_STATUS_SUCCESS:
      return {
        ...state,
        allJobStatus: state.allJobStatus,
        isCreated: true,
        success: action.payload.response.result.success,
        message: action.payload.response.result.success
          ? 'Record Deleted successfully'
          : action.payload.response.result.msg,
      };

    case actionTypes.DELETE_JOB_STATUS_ERROR:
      return {
        ...state,
        isCreated: false,
        success: action.payload.response.result.success,
        message: action.payload.response.result.msg,
      };

    default:
      return state;
  }
};
