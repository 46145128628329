import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import formattedPhone from '../../shared/formatted-phone';

type PhoneMatchingContactsModalProps = {
  open: boolean;
  onClose: () => void;
  field?: any;
  data?: any[];
  type?: string;
};

export const PhoneMatchingContactsModal = ({
  open,
  onClose = () => void 0,
  field,
  data = [],
  type,
}: PhoneMatchingContactsModalProps) => {
  const list = data.map((contact) => {
    return (
      <div className='row py-2' key={contact._id}>
        <div className='col-4'>
          <a target='_blank' rel='noreferrer' href={`/contact-details/${contact.contactId}`}>
            {contact.contactFullName}
          </a>
        </div>
        <div className='col-4'>
          {!contact.selfAffiliated ? (
            <a target='_blank' rel='noreferrer' href={`/company-details/${contact.companyId}`}>
              {contact.companyName}
            </a>
          ) : (
            '(Personal)'
          )}
        </div>
        <div className='col-4'>{contact.label}</div>
      </div>
    );
  });

  const headerValue = () => {
    switch (type) {
      case 'phone':
        return field && formattedPhone({ phoneObject: field });
      case 'email':
        return field.email;
      default:
        return null;
    }
  };

  return (
    <Modal toggle={onClose} isOpen={open}>
      <ModalHeader toggle={onClose}>Matching contacts for {headerValue()}</ModalHeader>
      <ModalBody>
        <div className='container'>
          <div className='row'>
            <div className='col-4 fw-bold'>Name</div>
            <div className='col-4 fw-bold'>Company</div>
            <div className='col-4 fw-bold'>Label</div>
          </div>
          <div className='row'>
            <div className='col-12'>
              <hr />
            </div>
          </div>
          {list}
        </div>
      </ModalBody>
      <ModalFooter>
        <button className='btn btn-primary' onClick={onClose}>
          Close
        </button>
      </ModalFooter>
    </Modal>
  );
};
