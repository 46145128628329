import {
  AssignmentModelConfig,
  EventModelConfig,
  ResourceModelConfig,
} from '@bryntum/scheduler-thin';
import { createSelector } from '@reduxjs/toolkit';
import styles from '../../components/Calendar/Calendar.module.css';
import { appConstants } from '../../_constants';
import { RootState } from '../store';

type CalendarData = {
  resources: (Partial<ResourceModelConfig> & { raw: Record<string, any> })[];
  events: (Partial<EventModelConfig> & { raw: Record<string, any> })[];
  assignments: Partial<AssignmentModelConfig>[];
};

const defaultEmployeeColor = 'purple';
const defaultCompanyColor = 'orange';
const defaultContactColor = 'violet';
const defaultProjectColor = 'blue';

export const selectGetAllCalendar = (state: RootState) => state.rtk.calendar.getAllCalendar;

const selectCalendarContactData = createSelector(
  (state: RootState) => state.rtk.calendar.getAllCalendar.data.contactData,
  (state: RootState) => state.rtk.calendar.getAllCalendar.data.companyData,
  (contactData, companyData) => {
    return contactData
      .filter((contact) => contact.scheduledEvents.length > 0)
      .map(
        (contact) =>
          ({
            ...contact,
            scheduledEvents: contact.scheduledEvents.map((event) => ({
              ...event,
              scheduleTo: event.scheduleTo.map((to) => ({
                ...to,
                ...contactData.find((contact1) => contact1.objectId === to.objectId),
                ...companyData.find((company) => company.objectId === to.objectId),
              })),
            })),
          }) as (typeof contactData)[number]
      )
      .reduce<CalendarData>(
        (data, contact) => {
          data.resources.push({
            id: contact.objectId,
            name: contact.fullName,
            eventColor:
              contact.calendarColor ??
              (contact.isEmployee ? defaultEmployeeColor : defaultContactColor),
            raw: {
              order: contact.isEmployee ? 20 : 21,
              typeRef: 'CONTACT',
              groupName: contact.isEmployee ? 'User' : 'Contact',
            },
          });

          contact.scheduledEvents.forEach((event) => {
            data.events.push({
              id: event.objectId,
              name: event.title,
              startDate: event.startDate.iso,
              endDate: event.endDate.iso,
              cls: styles.bCalEventWrap,
              raw: event,
            });

            data.assignments.push({
              id: `${contact.objectId}-${event.objectId}`,
              resourceId: contact.objectId,
              eventId: event.objectId,
            });

            event.scheduleTo.forEach((to) => {
              const isCompany = companyData.some((company) => company.objectId === to.objectId);
              const isContact = contactData.some((contact1) => contact1.objectId === to.objectId);

              if (!isCompany && !isContact) {
                const toColor =
                  to.calendarColor ??
                  (to.isCompany
                    ? defaultCompanyColor
                    : to.isEmployee
                    ? defaultEmployeeColor
                    : defaultContactColor);
                const toName =
                  to.fullName?.length > 0
                    ? to.fullName
                    : isCompany && to.companyName?.length > 0
                    ? to.companyName
                    : to.value;
                data.resources.push({
                  id: to.objectId,
                  name: toName,
                  eventColor: toColor,
                  raw: {
                    order: to.isEmployee ? 20 : isCompany ? 21 : 22,
                    typeRef: isCompany ? 'COMPANY' : 'CONTACT',
                    groupName: isCompany ? 'Contact' : to.isEmployee ? 'User' : 'Contact',
                  },
                  imageUrl: to?.userImage ?? false,
                });
              }

              if (contact.objectId !== to.objectId) {
                data.assignments.push({
                  id: `${to.objectId}-${event.objectId}`,
                  resourceId: to.objectId,
                  eventId: event.objectId,
                });
              }
            });
          });

          return data;
        },
        { resources: [], events: [], assignments: [] }
      );
  }
);

const selectCalendarProjectData = createSelector(
  (state: RootState) => state.rtk.calendar.getAllCalendar.data.projectData,
  (state: RootState) => state.rtk.calendar.getAllCalendar.data.contactData,
  (state: RootState) => state.rtk.calendar.getAllCalendar.data.companyData,
  selectCalendarContactData,
  (projectData, contactData, companyData, calendarContactData) => {
    return projectData
      .filter((project) => project.bidDue || project.scheduledEvents.length > 0)
      .map(
        (project) =>
          ({
            ...project,
            scheduledEvents: project.scheduledEvents.map((event) => ({
              ...event,
              scheduleTo: event.scheduleTo.map((to) => ({
                ...to,
                ...contactData.find((contact) => contact.objectId === to.objectId),
                ...companyData.find((company) => company.objectId === to.objectId),
              })),
            })),
          }) as (typeof projectData)[number]
      )
      .reduce<CalendarData>(
        (data, project) => {
          data.resources.push({
            id: project.objectId,
            name: project.title,
            eventColor: defaultProjectColor,
            raw: {
              order: 10,
              typeRef:
                project.jobStatusCodesCode === appConstants.jobStatusCode.L ? 'LEAD' : 'PROJECT',
              groupName: 'Projects',
            },
          });

          const { scheduledEvents, ...projectData } = project;

          scheduledEvents.forEach((event) => {
            event.projectData = { ...projectData };

            data.events.push({
              id: event.objectId,
              name: event.title,
              startDate: event.startDate.iso,
              endDate: event.endDate.iso,
              cls: styles.bCalEventWrap,
              raw: event,
            });

            const projectAssignmentEntry = {
              id: `${project.objectId}-${event.objectId}`,
              resourceId: project.objectId,
              eventId: event.objectId,
            };

            const contactAssignmentEntry = [] as any[];

            const userAssignmentEntry = [] as any[];

            const assignedIds = [] as any[];

            event.scheduleTo.forEach((to) => {
              if (!calendarContactData.resources.some((resource) => resource.id === to.objectId)) {
                const isCompany = companyData.some((company) => company.objectId === to.objectId);
                const toName =
                  to.fullName?.length > 0
                    ? to.fullName
                    : isCompany && to.companyName?.length > 0
                    ? to.companyName
                    : to.value;
                const toColor =
                  to.calendarColor ??
                  (to.isCompany
                    ? defaultCompanyColor
                    : to.isEmployee
                    ? defaultEmployeeColor
                    : defaultContactColor);

                calendarContactData.resources.push({
                  id: to.objectId,
                  name: toName,
                  eventColor: toColor,
                  raw: {
                    order: to.isEmployee ? 20 : isCompany ? 21 : 22,
                    typeRef: isCompany ? 'COMPANY' : 'CONTACT',
                    groupName: isCompany ? 'Contact' : to.isEmployee ? 'User' : 'Contact',
                  },
                  imageUrl: to?.userImage ?? false,
                });
              }

              if(assignedIds.includes(to.objectId)){
                return;
              } else{
                assignedIds.push(to.objectId);
              }
            
              if (to.isEmployee) {
                userAssignmentEntry.push({
                  id: `${to.objectId}-${event.objectId}`,
                  resourceId: to.objectId,
                  eventId: event.objectId,
                });
              } else {
                contactAssignmentEntry.push({
                  id: `${to.objectId}-${event.objectId}`,
                  resourceId: to.objectId,
                  eventId: event.objectId,
                });
              }
            });

            if (userAssignmentEntry.length === 1) {
              data.assignments=data.assignments.concat(userAssignmentEntry);
              data.assignments.push(projectAssignmentEntry);
              data.assignments=data.assignments.concat(contactAssignmentEntry);
            }else{
              data.assignments.push(projectAssignmentEntry);
              data.assignments=data.assignments.concat(userAssignmentEntry);
              data.assignments=data.assignments.concat(contactAssignmentEntry);
            }
          });

          return data;
        },
        { resources: [], events: [], assignments: [] }
      );
  }
);

const selectCalendarCompanyData = createSelector(
  (state: RootState) => state.rtk.calendar.getAllCalendar.data.companyData,
  (state: RootState) => state.rtk.calendar.getAllCalendar.data.contactData,
  selectCalendarContactData,
  (companyData, contactData, calendarContactData) => {
    return companyData
      .filter((company) => company.scheduledEvents.length > 0)
      .map(
        (company) =>
          ({
            ...company,
            scheduledEvents: company.scheduledEvents.map((event) => ({
              ...event,
              scheduleTo: event.scheduleTo.map((to) => ({
                ...to,
                ...contactData.find((contact) => contact.objectId === to.objectId),
              })),
            })),
          }) as (typeof companyData)[number]
      )
      .reduce<CalendarData>(
        (data, company) => {
          data.resources.push({
            id: company.objectId,
            name: company.companyName,
            eventColor: defaultCompanyColor,
            raw: {
              order: 40,
              typeRef: 'COMPANY',
              groupName: 'Contact',
            },
          });

          company.scheduledEvents.forEach((event) => {
            data.events.push({
              id: event.objectId,
              name: event.title,
              startDate: event.startDate.iso,
              endDate: event.endDate.iso,
              cls: styles.bCalEventWrap,
              raw: event,
            });

            data.assignments.push({
              id: `${company.objectId}-${event.objectId}`,
              resourceId: company.objectId,
              eventId: event.objectId,
            });

            event.scheduleTo.forEach((to) => {
              if (!calendarContactData.resources.some((resource) => resource.id === to.objectId)) {
                const isCompany = companyData.some((company) => company.objectId === to.objectId);
                const toName =
                  to.fullName?.length > 0
                    ? to.fullName
                    : isCompany && to.companyName?.length > 0
                    ? to.companyName
                    : to.value;
                const toColor =
                  to.calendarColor ??
                  (to.isCompany
                    ? defaultCompanyColor
                    : to.isEmployee
                    ? defaultEmployeeColor
                    : defaultContactColor);

                calendarContactData.resources.push({
                  id: to.objectId,
                  name: toName,
                  eventColor: toColor,
                  raw: {
                    order: to.isEmployee ? 20 : isCompany ? 21 : 22,
                    typeRef: isCompany ? 'COMPANY' : 'CONTACT',
                    groupName: isCompany ? 'Contact' : to.isEmployee ? 'User' : 'Contact',
                  },
                  imageUrl: to?.userImage ?? false,
                });
              }

              data.assignments.push({
                id: `${to.objectId}-${event.objectId}`,
                resourceId: to.objectId,
                eventId: event.objectId,
              });
            });
          });

          return data;
        },
        { resources: [], events: [], assignments: [] }
      );
  }
);

export const selectCalendarData = createSelector(
  selectCalendarProjectData,
  selectCalendarContactData,
  selectCalendarCompanyData,
  (calendarProjectData, calendarContactData, calendarCompanyData) => {
    return {
      resources: [
        ...calendarProjectData.resources,
        ...calendarContactData.resources,
        ...calendarCompanyData.resources,
      ],
      events: [
        ...calendarProjectData.events,
        ...calendarContactData.events,
        ...calendarCompanyData.events,
      ],
      assignments: [
        ...calendarProjectData.assignments,
        ...calendarContactData.assignments,
        ...calendarCompanyData.assignments,
      ],
    };
  }
);
