import React, { useState } from 'react';
import { useCallback } from 'react';

export const columnDescriptor = {
  Header: ({ state }) => {
    return (
      <input
        type='checkbox'
        checked={state.getSelectedAll() && state.getSelectedSomething()}
        onChange={state.onSelectAllClick}
      />
    );
  },
  width: 20,
  id: 'selection',
  Cell: ({ row, state }) => {
    return (
      <input
        type='checkbox'
        checked={!!state.selected[row.index]}
        onChange={(e) => {
          e.persist();
          state.setSelected((prev) => ({ ...prev, [row.index]: e.target.checked }));
        }}
      />
    );
  },
};

const useBasicRowSelection = ({ data = [], keyField = '_id' }) => {
  const [selected, setSelected] = useState({});

  const getSelectedAll = useCallback(
    () => Object.values(selected).filter((s) => s).length === data.length,
    [selected, data]
  );
  const getSelectedSomething = useCallback(
    () => Object.values(selected).filter((s) => s).length > 0,
    [selected]
  );

  const onSelectAllClick = useCallback(() => {
    const isAllSelected = getSelectedAll();

    if (isAllSelected) return setSelected({});

    setSelected(
      Array.from(Array(data.length).keys()).reduce(
        (prev, curr) => Object.assign(prev, { [curr]: true }),
        {}
      )
    );
  }, [getSelectedAll]);

  const getSelectedKeys = useCallback(() => {
    return data.filter((value, index) => selected[index]).map((value) => value[keyField]);
  }, [data, selected]);

  return {
    selected,
    setSelected,
    getSelectedAll,
    onSelectAllClick,
    getSelectedSomething,
    getSelectedKeys,
  };
};

export default useBasicRowSelection;
