import React, { Suspense, useEffect } from 'react';

import ReactLoading from 'react-loading';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, withRouter } from 'react-router-dom';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import ReactToPrint from 'react-to-print';
import { Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { GetContactJobDDAction, GetContactJobListReportAction, GetTimesheetReportAction } from '../../../actions/reports';
import { formatInternalContactData } from '../../../utils/contact-formatters';
import { appConstants } from '../../../_constants';
import TitleComponent from '../../Common/TitleComponent';
import Footer from '../../Layout/Footer.js';
import Header from '../../Layout/Header.js';
import { getAllProjectRoleAction } from '../../../actions/projectRole';

let personName = '';
let jobStatusName = '';
let showContact = '';

let noData = true;
let dataArr = [];
let tableData = [];

var sortingOrder = '';
var sortOrders = 'desc';

// TODO: Needs very heavy refactoring!
class TimeSheetReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: { dayName: { value: 'M' } },
      accountId: localStorage.getItem('accountId'),
      userId: localStorage.getItem('userId'),
      list: [],
      modal: false,
      fetchRequest: true,
      comLogo: '',
      totalCount: 0,
      currentPage: 1,
      sizePerPage: 10,
      searchText: '',
      isReportDD: true,
      isReportDDError: true,
      optionDays: [],
      projectRoleTypes: [],
      period: '',
      allJob: null,
      contactName: null,
      allStatus: null,
      jobStatus: null,
      statusName: '',
      DDList: [],
      OptionProjectStatus: [],
      selectedDays: [],
      contactData: {},
    };

    this.toggle = this.toggle.bind(this);
    this.onSortChange = this.onSortChange.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    dataArr = [];
    tableData = [];

    if (nextProps?.projectRoles?.allProjectRoleTypes) {
      this.setState({
        projectRoleTypes: nextProps.projectRoles.allProjectRoleTypes,
      });
    }

    if (nextProps.listData && nextProps.listData.data && nextProps.listData.data.length > 0) {
      dataArr = [];
      tableData = [];
      this.setState({
        list: nextProps.listData.data,
        fetchRequest: false,
        totalCount: nextProps.listData.count,
        contactData: nextProps.listData.contactData,
      });
    } else {
      dataArr = [];
      tableData = [];
      this.setState({ list: [], totalCount: 0 });
    }

    if (nextProps.listData && nextProps.listData.data && nextProps.listData.data.length == 0) {
      this.setState({ fetchRequest: false });
    }

    if (nextProps.DDList && nextProps.DDList.data && nextProps.DDList.data.length > 0) {
      var formData = this.state.formData;
      let DDList = [];
      nextProps.DDList.data.map((v, i) => {
        if (v.objectId !== '' && v.firstName !== '' && v.lastName !== '') {
          DDList.push({
            value: v.objectId,
            label: v.firstName + ' ' + v.lastName,
          });
        }
        if (i === 0) {
          formData.contactName = {
            value: v.objectId,
            label: v.firstName + ' ' + v.lastName,
          };
        }
        this.setState({ DDList, formData: formData });
        return true;
      });
    }
  }

  async componentDidMount() {
    let data = {};
    data['accountId'] = this.state.accountId;
    //await this.props.GetContactJobDDAction(data);

    sortingOrder = '';

    this.props.getAllProjectRoleAction({
      className: 'projectRoleTypes',
      accountId: this.state.accountId,
    });

    // Job Status DD
    let OptionProjectStatus = [];
    var formData = this.state.formData;
    appConstants.OptionProjectStatus.map((v, i) => {
      if (v.name !== '' && v.value !== '') {
        OptionProjectStatus.push({ value: v.value, label: v.name });
      }
      if (i === 0) {
        formData.jobStatus = { value: v.value, label: v.name };
      }
      this.setState({ OptionProjectStatus, formData: formData });
      return true;
    });

    let optionDays = [];
    appConstants.OptionDays.map((v, i) => {
      if (v.name !== '' && v.value !== '') {
        optionDays.push({ value: v.value, label: v.name });
        if (i === 0) {
          formData.dayName = { value: v.value, label: v.name };
        }
      }
      this.setState({ optionDays, formData: formData });
      return true;
    });

    if (
      localStorage.getItem('companyLogo') !== 'undefined' &&
      localStorage.getItem('companyLogo') !== null
    ) {
      this.setState({ comLogo: localStorage.getItem('companyLogo') });
    } else {
      let constImage = appConstants.CONTENT_CPOST_LOGO_URL;
      this.setState({ comLogo: constImage });
    }

    let _this = this;
    setTimeout(function () {
      _this.setState({ fetchRequest: false });
    }, 2000);
  }

  UNSAFE_componentWillMount() {
    let dayArr = ['M', 'T', 'W', 'Th', 'F', 'S', 'Su'];
    let x;
    let days = [];

    if (this.state.formData.dayName && this.state.formData.dayName.value === 'M') {
      for (x in dayArr) {
        days.push(dayArr[x]);
      }
      this.setState({ selectedDays: days });
    }

    this.setState({ period: 'contactName', statusName: 'jobStatus' });
    this.toggle();
  }

  toggle(val) {
    this.setState({ modal: !this.state.modal });
  }

  filteredData = () => {
    this.setState({ fetchRequest: true });
    let data = {};
    data['accountId'] = this.state.accountId;
    data['moduleName'] = appConstants.reportModuleName['18'];
    data['page'] = 0;
    data['displayLimit'] = 2000;

    personName = this.state.period;
    jobStatusName = this.state.statusName;

    if (this.state.period === 'allJob') {
      data['contactId'] = '';
      showContact = '';
    } else if (this.state.period === 'contactName') {
      data['contactId'] = this.props.contactId;
      //showContact = this.state.formData.contactName.label;
    }

    if (this.state.statusName === 'allStatus') {
      data['jobStatusCodesName'] = '';
    } else if (this.state.statusName === 'jobStatus') {
      data['jobStatusCodesName'] = this.state.formData.jobStatus.value;
    }

    this.props.GetTimesheetReportAction(data);
  };

  handleChangePeriod(val) {
    this.setState({ period: val });
  }

  handleChangeStatus(val) {
    this.setState({ statusName: val });
  }

  dataCalculation() {
    var x = null;
    dataArr = this.state.list;
    if (dataArr.length > 0) {
      noData = false;
      tableData = [];

      for (x in dataArr) {
        // Project list data
        let row = dataArr[x];

        let projectMgr=[];
        let projectManagerArr = row.projectAssignments.filter(role=>role.projectRoleCode=='PM');
        if (projectManagerArr.length > 0) {
          projectMgr = formatInternalContactData(projectManagerArr, this.state.contactData);
        } else {
          projectMgr = <span>-</span>;
        }

        //Project projectSupervisorArr
        let projectSupr = [];
        let projectSupervisorArr = row.projectAssignments.filter(role=>role.projectRoleCode=='SI');
       
        if (projectSupervisorArr && projectSupervisorArr.length > 0) {
          projectSupr = formatInternalContactData(projectSupervisorArr, this.state.contactData);
        } else {
          projectSupr = <span>-</span>;
        }

        //Account executive
        let accountExec = [];
        let accountExecArr = row.projectAssignments.filter(role=>role.projectRoleCode=='AX');
       if (accountExecArr && accountExecArr.length > 0) {
          accountExec = formatInternalContactData(accountExecArr, this.state.contactData);
        } else {
          accountExec = <span>-</span>;
        }

        //Estimator
        let estimator = [];
        let estimatorArr = row.projectAssignments.filter(role=>role.projectRoleCode=='EST');
      if (estimatorArr && estimatorArr.length > 0) {
          estimator = formatInternalContactData(estimatorArr, this.state.contactData);
        } else {
          estimator = <span>-</span>;
        }

        // var td11         = <td></td>
        var jobNum = (
          <td>
            <Link
              to={{
                pathname: `/project-details/` + row.objectId,
                state: { row },
              }}
            >
              {dataArr[x].jobNumFormatted ? dataArr[x].jobNumFormatted : '-'}
            </Link>
          </td>
        );
        var altNum = (
          <td>
            <Link
              to={{
                pathname: `/project-details/` + row.objectId,
                state: { row },
              }}
            >
              {dataArr[x].altJobNum ? dataArr[x].altJobNum : '-'}
            </Link>
          </td>
        );
        var jobName = (
          <td>
            <Link
              to={{
                pathname: `/project-details/` + row.objectId,
                state: { row },
              }}
            >
              {dataArr[x].jobName ? dataArr[x].jobName : '-'}
            </Link>
          </td>
        );
        var accountExe = <td>{accountExec ? accountExec : '-'}</td>;
        projectMgr = <td>{projectMgr ? projectMgr : '-'}</td>;
        var isEstimator = <td>{estimator ? estimator : '-'}</td>;
        projectSupr = <td>{projectSupr ? projectSupr : '-'}</td>;
        var status = <td>{dataArr[x].jobStatusCodesCode ? dataArr[x].jobStatusCodesCode : '-'}</td>;
        var td1 = <td></td>;
        var td2 = <td></td>;
        var td3 = <td></td>;
        var td4 = <td></td>;
        var td5 = <td></td>;
        var td6 = <td></td>;
        var td7 = <td></td>;
        var td8 = <td></td>;
        var total = <td></td>;

        var tablerow = (
          <tr>
            {jobNum}
            {altNum}
            {jobName}
            {accountExe}
            {projectMgr}
            {isEstimator}
            {projectSupr}
            {status}
            {td1}
            {td2}
            {td3}
            {td4}
            {td5}
            {td6}
            {td7}
            {td8}
            {total}
          </tr>
        );
        tableData.push(tablerow);
      }
    }
  }

  createCustomButtonGroup = (props) => {
    if (this.state.comLogo === '') {
      let constImage = appConstants.CONTENT_CPOST_LOGO_URL;
      this.setState({
        comLogo: constImage,
      });
    }
    return (
      <div className='pdfLogoBox'>
        <div className='reportHead'>
          <div className='logo'>
            <img src={this.state.comLogo} alt='Upload Logo' />
          </div>
          <h2>Timesheet Form</h2>
          <div className='label-name'>Employee: {this.props.contactName}</div>
          <div className='field-row text-right timesheetPdfAlign'>
            <div className='label-name'>Hours worked, week, ending _____________</div>
          </div>
        </div>
      </div>
    );
  };

  handleChangeSelectBox(name, value) {
    let formData = this.state.formData;
    formData[name] = value;
    this.setState({ formData });
  }

  handleChangeDay(data) {
    let value = data.value;
    let dayArr = ['M', 'T', 'W', 'Th', 'F', 'S', 'Su'];
    let firstStart = false;
    let lastStart = true;
    let x;
    let days = [];
    for (x in dayArr) {
      if (dayArr[x] === value) {
        firstStart = true;
      }
      if (firstStart === true) {
        days.push(dayArr[x]);
      }
    }

    for (x in dayArr) {
      if (dayArr[x] === value) {
        lastStart = false;
      }
      if (lastStart === true) {
        days.push(dayArr[x]);
      }
    }

    this.setState({ selectedDays: days });
    let formData = this.state.formData;
    formData['dayName'] = data;
    this.setState({ formData });
  }

  _setTableOption() {
    if (this.state.fetchRequest) {
      return 'No data found..';
    } else {
      return (
        <ReactLoading
          className='table-loader'
          type={appConstants.LOADER_TYPE}
          color={appConstants.LOADER_COLOR}
          height={appConstants.LOADER_HEIGHT}
          width={appConstants.LOADER_WIDTH}
        />
      );
    }
  }

  onPageChange(page, sizePerPage) {
    this.setState({ fetchRequest: true });
    let data = {};
    data['accountId'] = this.state.accountId;

    if (personName === 'allJob') {
      data['contactId'] = '';
    } else if (personName === 'contactName') {
      data['contactId'] = this.state.formData.contactName.value;
    }

    if (jobStatusName === 'allStatus') {
      data['jobStatusCodesName'] = '';
    } else if (jobStatusName === 'jobStatus') {
      data['jobStatusCodesName'] = this.state.formData.jobStatus.value;
    }

    if (this.state.searchText === '') {
      data['page'] = page === -1 ? 0 : page - 1;
      data['displayLimit'] = sizePerPage;
    } else {
      data['page'] = page === -1 ? 0 : page - 1;
      data['displayLimit'] = sizePerPage;
      data['search'] = this.state.searchText;
    }

    this.setState({ sizePerPage: sizePerPage });
    this.setState({ currentPage: page });
    this.props.GetTimesheetReportAction(data);
  }

  onSortChange(sortName, sortOrder) {
    if (this.state && this.state.list && this.state.list.length > 0) {
      this.setState({ fetchRequest: true });
      let data = {};
      data['sortKey'] = sortName;
      if (sortName === 'jobNumFormatted') {
        data['sortKey'] = 'jobNum';
      }

      if (sortOrder === 'asc') {
        data['sortType'] = 'ASC';
        sortingOrder = '-asc';
        sortOrders = 'desc';
      } else {
        data['sortType'] = 'DESC';
        sortingOrder = '-desc';
        sortOrders = 'asc';
      }
      data['accountId'] = this.state.accountId;
      data['moduleName'] = appConstants.reportModuleName['18'];
      data['page'] = 0;
      data['displayLimit'] = 2000;

      personName = this.state.period;
      jobStatusName = this.state.statusName;

      if (this.state.period === 'allJob') {
        data['contactId'] = '';
        showContact = '';
      } else if (this.state.period === 'contactName') {
        data['contactId'] = this.state.formData.contactName.value;
        showContact = this.state.formData.contactName.label;
      }

      if (this.state.statusName === 'allStatus') {
        data['jobStatusCodesName'] = '';
      } else if (this.state.statusName === 'jobStatus') {
        data['jobStatusCodesName'] = this.state.formData.jobStatus.value;
      }

      this.props.GetTimesheetReportAction(data);
    }
  }

  render() {

    const rolesLoaded = this.state.projectRoleTypes?.length > 0;

    
    const axNameShort = (rolesLoaded && this.state.projectRoleTypes.find(role => role.code == 'AX')?.shortName) ?? 'Acc. Exec';
    const estNameShort = (rolesLoaded && this.state.projectRoleTypes.find(role => role.code == 'EST')?.shortName) ?? 'Estimator';
    const suptNameShort = (rolesLoaded && this.state.projectRoleTypes.find(role => role.code == 'SI')?.shortName) ?? 'Supt.';
    const projMgrNameShort = (rolesLoaded && this.state.projectRoleTypes.find(role => role.code == 'PM')?.shortName) ?? 'Proj. Mgr';
  

    this.dataCalculation();

    return (
      <div>
        <div className='ContactListReport'>
          <TitleComponent data={{ title: `Timesheet Form` }} />
          <div>
            <Suspense fallback='loading'>
              <Header props={this.props} />
            </Suspense>
          </div>
          <div className='contactBox'>
            <div className='container'>
              <div className='reportHead'>
                <h2>Timesheet Form</h2>
                <ReactToPrint
                  trigger={() => (
                    <a href='#/' className='btn rightalignmentBtn'>
                      <i className='fa fa-print' aria-hidden='true'></i> Print PDF
                    </a>
                  )}
                  content={() => this.componentRef}
                />
                <button className='btn rightalignmentBtn' onClick={() => this.toggle('POST')}>
                  <i className='fa fa-filter'></i> Apply Filters
                </button>
              </div>
              <div ref={(el) => (this.componentRef = el)}>
                <div className='row'>
                  <div className='col-md-12'>
                    <div className='pdfLogoBox'>
                      <div className='reportHead'>
                        <div className='logo'>
                          <img src={this.state.comLogo} alt='Upload Logo' />
                        </div>
                        <h2>Timesheet Form</h2>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='row searchbar'>
                  <div className='col-md-6'>
                    <div className='field-row'>
                      <div className='label-name'>Employee: {this.props.location.state.contactFullName }</div>
                      <div className='projectHistoryJob ph-field'></div>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='field-row text-right'>
                      <div className='label-name'>Hours worked, week, ending _____________</div>
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-md-12'>
                    <div className='profit-projection reportTableSection bigTable bigTable1'>
                      <div className=''>
                        <table width='100%'>
                          <thead>
                            <tr>
                              <th width='50'>
                                <span
                                  className='cursorPointer'
                                  onClick={(e) => {
                                    this.onSortChange('jobNum', sortOrders);
                                  }}
                                >
                                  Job Num
                                  <i className={'fa fa-sort' + sortingOrder} aria-hidden='true'></i>
                                </span>
                              </th>
                              <th width='50'>
                                <span
                                  className='cursorPointer'
                                  onClick={(e) => {
                                    this.onSortChange('altNum', sortOrders);
                                  }}
                                >
                                  Alt Job#
                                  <i className={'fa fa-sort' + sortingOrder} aria-hidden='true'></i>
                                </span>
                              </th>
                              <th>Job Name</th>
                              <th width='100'>
                                <span
                                  
                                 
                                >
                                  {axNameShort}
                                       </span>
                              </th>
                              <th width='100'>
                                <span
                                 
                                 
                                >
                                 {projMgrNameShort}
                                       </span>
                              </th>
                              <th width='80'>
                                <span
                               
                                >
                                  {estNameShort}
                                       </span>
                              </th>
                              <th width='80'>
                                <span
                                 
                                  
                                >
                                 {suptNameShort}
                                     </span>
                              </th>
                              <th width='50'>Status</th>
                              <th width='80'>Cost Code</th>
                              <th width='40'>{this.state.selectedDays[0]}</th>
                              <th width='40'>{this.state.selectedDays[1]}</th>
                              <th width='40'>{this.state.selectedDays[2]}</th>
                              <th width='40'>{this.state.selectedDays[3]}</th>
                              <th width='40'>{this.state.selectedDays[4]}</th>
                              <th width='40'>{this.state.selectedDays[5]}</th>
                              <th width='40'>{this.state.selectedDays[6]}</th>
                              <th>Total</th>
                            </tr>
                          </thead>
                          <tbody>{tableData}</tbody>
                        </table>
                        {this.state.fetchRequest && (
                          <ReactLoading
                            className='table-loader'
                            type={appConstants.LOADER_TYPE}
                            color={appConstants.LOADER_COLOR}
                            height={appConstants.LOADER_HEIGHT}
                            width={appConstants.LOADER_WIDTH}
                          />
                        )}
                        {!this.state.fetchRequest &&
                          this.state.list &&
                          this.state.list.length == 0 && (
                            <div className='textCenter'>No data found.</div>
                          )}

                        <div className='tsBottomBox'>
                          <div className='row searchbar'>
                            <div className='col-md-6'>
                              <div className='field-row'>
                                <div className='label-name'>
                                  Administrative:______________________________________________________________________________
                                </div>
                              </div>
                              <div className='field-row'>
                                <div className='label-name'>
                                  Marketing:__________________________________________________________________________________
                                </div>
                              </div>
                              <div className='field-row'>
                                <div className='label-name'>
                                  Other:________________________________________________________________________________________
                                </div>
                              </div>
                              <div className='field-row'>
                                <div className='label-name'>
                                  Other:________________________________________________________________________________________
                                </div>
                              </div>
                            </div>
                            <div className='col-md-6'>
                              <div className='field-row text-right'>
                                <div className='field-row text-right totaltext'>TOTAL:</div>
                                <table
                                  width='470'
                                  className='table table-bordered timesheetsmalltable'
                                >
                                  <tbody>
                                    <tr>
                                      <td width='40'></td>
                                      <td width='40'></td>
                                      <td width='40'></td>
                                      <td width='40'></td>
                                      <td width='40'></td>
                                      <td width='40'></td>
                                      <td width='40'></td>
                                      <td width='40'></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>

        <Modal
          backdrop='static'
          keyboard={true}
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggle}>Select</ModalHeader>
          <ModalBody>
            <div className='container'>
              {/* <div className='form-group'>
                <div className='row'>
                  <div className='col-md-12'>
                    <label>
                      <strong>1. Select employee to include</strong>
                    </label>
                  </div>
                </div>
              </div> */}
              {/* <div className='form-group'>
                <div className='row'>
                  <div className='col-md-12'>
                    {(this.state.period === 'contactName' || true) && (
                      <Select
                        name='contactName'
                        components={makeAnimated()}
                        value={this.state.formData.contactName}
                        options={this.state.DDList}
                        onChange={this.handleChangeSelectBox.bind(this, 'contactName')}
                      />
                    )}
                  </div>
                </div>
              </div> */}
              <div className='form-group'>
                <div className='row'>
                  <div className='col-md-12'>
                    <label>
                      <strong>1. Select job status to include</strong>
                    </label>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-12'>
                    <Input
                      type='radio'
                      name='statusName'
                      defaultChecked={this.state.statusName === 'allStatus'}
                      onClick={this.handleChangeStatus.bind(this, 'allStatus')}
                    />{' '}
                    All open jobs (Bidding, Bid pending, Phase 1-3, Active, Delivered, In warranty)
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-12'>
                    <Input
                      type='radio'
                      name='statusName'
                      defaultChecked={this.state.statusName === 'jobStatus'}
                      onClick={this.handleChangeStatus.bind(this, 'jobStatus')}
                    />{' '}
                    Select status
                  </div>
                </div>
              </div>
              <div className='form-group'>
                <div className='row'>
                  <div className='col-md-12'>
                    {this.state.statusName === 'jobStatus' && (
                      <Select
                        name='jobStatus'
                        components={makeAnimated()}
                        value={this.state.formData.jobStatus}
                        options={this.state.OptionProjectStatus}
                        onChange={this.handleChangeSelectBox.bind(this, 'jobStatus')}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className='form-group'>
                <div className='row'>
                  <div className='col-md-12'>
                    <label htmlFor='dayName'>
                      <strong>First day fo work week</strong>
                    </label>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-12'>
                    <Select
                      name='dayName'
                      components={makeAnimated()}
                      value={this.state.formData.dayName}
                      options={this.state.optionDays}
                      onChange={this.handleChangeDay.bind(this)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button className='btn btn-primary' onClick={this.toggle}>
              Cancel
            </button>
            <button
              className='btn btn-primary'
              onClick={() => {
                this.filteredData();
                this.toggle();
              }}
            >
              Next
            </button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    listData: state.reports.timeSheetList,
    DDList: state.reports.contactDropDownList,
    isReportDD: state.reports.isReportDD,
    isReportDDError: state.reports.isReportDDError,
    projectRoles: state.projectRoleType,
  };
}

const WithRouter = withRouter(
  connect(mapStateToProps, {
    GetTimesheetReportAction,
    //GetContactJobDDAction,
    getAllProjectRoleAction
  })(TimeSheetReport)
);

export default function (props) {
  const location = useLocation();

  const contactId = new URLSearchParams(location.search).get('contactId');

  return <WithRouter {...props} contactId={contactId} />;
}
