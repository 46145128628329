import { useState, useEffect } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { getTimeLineAction } from '../../actions/message';
import { EmailView, NoteView, ScheduleView, ProjectActivityView } from './RightSectionRender';
import { t } from 'i18next';
import { Tooltip } from 'react-tooltip';
import { LightBoxComponent } from './LightBox.component';
import styles from './right-section.module.scss';

const TimeLineTab = (props) => {
  const [timelineData, setTimeLineData] = useState([]);
  const [typeId, setTypeId] = useState('');
  const [typeRef, setTypeRef] = useState('');
  const [updatedAt, setUpdatedAt] = useState('');
  const [lightBoxOpen, setLightBoxOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [attachmentIndex, setAttachmentIndex] = useState(-1);
  const [attachmentItems, setAttachmentItems] = useState([]);
  const [filterText, setFilterText] = useState('');


  useEffect(() => {
    if (!props.isSave && props.timelineData && props.timelineData?.length>=0) {
      setTimeLineData(props.timelineData);
    }
  }, [props.isSave, props.timelineData, props.allMessages, props.allEmails, props.allSchedule]);
  

  useEffect(() => {
    setTypeId(props.typeId);
    setTypeRef(props.typeRef);
    setUpdatedAt(props.updatedAt);
  }, [props.typeId, props.typeRef,props.updatedAt]);

  useEffect(() => {
    getTimelineData();
  }, [typeId, typeRef,updatedAt]);

  const getTimelineData = () => {
    if (typeId?.length > 0) {
      const data = {
        typeId,
        typeRef,
        page: 0,
        displayLimit: 99999999,
      };
      props.getTimeLineAction(data);
    }
  };

  const formatEmailAttachments = (selectedEmail) => {
    return selectedEmail?.attachments?.map((attachment) => ({
      ...attachment,
      description:
        'Draft Email\n' +
        'to: ' +
        selectedEmail.emailTo?.map((to) => to.value).join(', ') +
        '\n' +
        'sub: ' +
        selectedEmail.emailSub +
        '\n' +
        selectedEmail.emailBody?.replace(/(<([^>]+)>)/gi, ''),
    }));
  };

  const formatNoteAttachments = (selectedNote) => {
    return selectedNote?.attachments?.map((attachment) => ({
      ...attachment,
      description:
        selectedNote.title + '\n' + selectedNote.description?.replace(/(<([^>]+)>)/gi, ''),
    }));
  };

  useEffect(() => {
    if(!lightBoxOpen) {
      setAttachmentIndex(null);
      setSelectedItem(null);
    }
  }, [lightBoxOpen]);

  const showHideLightBox = (item, index) => {
    if(!item) {
      setLightBoxOpen(false);
      return;
    }
    
    setSelectedItem(item);
    setAttachmentIndex(index);

    if (item.classType === 'cppEmails') {
      setAttachmentItems(formatEmailAttachments(item));
    } else if (item.classType === 'cppNotes') {
      setAttachmentItems(formatNoteAttachments(item));
    }

    setLightBoxOpen(!lightBoxOpen);
  };

  const sortTimelineItems = (items) => {
    items = items.sort((a, b) => {
      // pinned items first
      if (a.isPinned && !b.isPinned) return -1;
      if (!a.isPinned && b.isPinned) return 1;
  
      if (a.isPinned && b.isPinned) return new Date(b.pinDate) - new Date(a.pinDate);

      //otherwise creation date
      return new Date(b.createdAt) - new Date(a.createdAt);
  });
    let rootItems = items.filter((item) => !item.replyTo);

    let sortedItems = rootItems
      .map((item) => {
        let replies = items.filter((n) => n.parentNoteId === item.objectId);
        replies.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
        return [item, ...replies];
      })
      .flat();

    return sortedItems;
  };

  let timelineDataContent = '';

  if (timelineData !== undefined) {
    timelineDataContent = sortTimelineItems(timelineData).map((v) => {
      if (v.classType === 'cppEmails') return <EmailView emailData={v} showHideLightBox={showHideLightBox} filterText={filterText}></EmailView>;
      if (v.classType === 'cppNotes') return <NoteView noteData={v} showHideLightBox={showHideLightBox} filterText={filterText}></NoteView>;
      if (v.classType === 'cppSchedule') return <ScheduleView scheduleData={v} showHideLightBox={showHideLightBox} filterText={filterText}></ScheduleView>;
      if (v.eventChangeByUser) return <ProjectActivityView activityData={v} filterText={filterText}></ProjectActivityView>;
 
    });
  }

  const childProps = {
    props: props,
    state: timelineData,
    pathname: location.pathname,
  };

  const pathnameMap = new Map()
    .set('CONTACT', '/contact-details/')
    .set('LEAD', '/lead-details/')
    .set('PROJECT', '/project-details/')
    .set('COMPANY', '/company-details/')
    .set('USER', '/contact-details/');

  const thisURL = pathnameMap.get(typeRef) + typeId;

  const canFollowUp = location.pathname.includes('notes/followUp') && false;

  return (
    <>
      <div className='container'>
        <br />
        <div className='row'>
          <div className='col-md-12'>
            <div className='pageinfo d-flex'>
              <h2> All activities </h2>
              <div className='detailMenu ms-auto d-flex align-items-start'>
                <input
                  type='text'
                  className={`${styles.activitySearch} btn-group form-control-sm lh-base`}
                  placeholder='Search'
                  onChange={(e) => setFilterText(e.target.value)}
                />
                <li className='dropdown px-2'>
                  <a
                    className='dropdown-toggle rightSectionOverflowMenu'
                    data-bs-toggle='dropdown'
                    href='#'
                    role='button'
                    aria-hidden='true'
                    aria-expanded='false'
                  >
                    <button className='btn btn-primary'>Add</button>
                  </a>
                  <ul className='dropdown-menu dropdown-menu-end ml-auto'>
                    {true && (
                      <li className='dropdown-item'>
                        <Link
                          data-tooltip-content={t('Add a new note to this item')}
                          data-tooltip-id='AddNote'
                          to={{
                            pathname: thisURL + '/notes/add',
                            state: { objectId: typeId, showEditNote: true },
                          }}
                        >
                          Add Note
                        </Link>
                        <Tooltip id='AddNote' place='right' delayShow={1000} />
                      </li>
                    )}
                    {canFollowUp && (
                      <li className='dropdown-item'>
                        <Link
                          data-tooltip-content={t('Add a follow-up note to this item')}
                          data-tooltip-id='AddNote'
                          to={{
                            pathname: thisURL + '/notes/followUp',
                            state: { objectId: typeId, showEditNote: true, followUp: true },
                          }}
                        >
                          Add Follow-up Note
                        </Link>
                        <Tooltip id='AddNote' place='right' delayShow={1000} />
                      </li>
                    )}
                    <li className='dropdown-item'>
                      <Link
                        data-tooltip-content={t('Send a new email related to this item')}
                        data-tooltip-id='SendEmail'
                        to={{
                          pathname: thisURL + '/email/new',
                          state: { objectId: typeId, showEditEmail: true },
                        }}
                        {...childProps}
                      >
                        Send Email
                      </Link>
                      <Tooltip id='Send Email' place='right' delayShow={1000} />
                    </li>
                    {true && (
                      <li className='dropdown-item'>
                        <Link
                          to={{
                            pathname: thisURL + '/schedule/add',
                            state: { objectId: typeId, showEditMeeting: true },
                          }}
                          data-tooltip-content={t('Add a meeting or an event')}
                          data-tooltip-id='AddMeeting'
                          {...childProps}
                        >
                          Add Event
                        </Link>
                        <Tooltip id='AddMeeting' place='right' delayShow={1000} />
                      </li>
                    )}
                  </ul>
                </li>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Scrollbars style={{ height: 'calc(100vh)', minHeight:'500px'}}>
        <br />
        {timelineDataContent && timelineDataContent.length === 0
          ? 'No entries found'
          : timelineDataContent}
      </Scrollbars>
      <LightBoxComponent
        attachments={attachmentItems ?? []}
        shouldOpen={lightBoxOpen}
        initialIndex={attachmentIndex}
        closeCallback={showHideLightBox}
      />
    </>
  );
};

function mapStateToProps(state) {
  let { message } = state;
  let { timelineData } = message;
  return {
    timelineData: timelineData && timelineData.length ? timelineData : [],
  };
}

export default withRouter(
  connect(mapStateToProps, {
    getTimeLineAction,
  })(TimeLineTab)
);
