import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { isCurrentOrPrevStage } from '../../../shared/custom-fields-controls/constants';
import { HorizontalLayout } from '../../../shared/form-controls/control-layouts/horizontal-layout';
import FormDatePicker from '../../../shared/form-controls/form-date-picker/form-date-picker';
import FormInput from '../../../shared/form-controls/form-input';
import {
  formatDateObjectOrNull,
  formatDateAdd,
} from '../../../utils/date-formatters';
import { appConstants, isAllowed, moduleConstants } from '../../../_constants';
import FormDurationPicker from '../../../shared/form-controls/form-duration-picker/form-duration-picker';
import FormSelect from '../../../shared/form-controls/form-select/form-select';
import { CurrencyInput } from '../../../shared/form-controls/form-input/currency-input';
import FormCheckbox from '../../../shared/form-controls/form-checkbox/form-checkbox';

type ProjectDatesAndDollarsProps = {
  projectId?: string;
  onManageBid?: () => void;
  customFields?: any;
  isPromoting?: boolean;
  isAssigned?: boolean;
  accountSettings?: any;
  projectStage?: string;
};

export const ProjectDatesAndDollars = ({
  projectId,
  onManageBid,
  isPromoting = false,
  isAssigned = false,
  accountSettings = {},
  projectStage = '',
}: ProjectDatesAndDollarsProps) => {
  const form = useFormContext();

  const isUpdate = !!projectId;

  const [showMore, setShowMore] = useState<boolean>(false);


  const formValues = form.getValues();

  const watchStartDate = form.watch('startDate');
  const watchEndDate = form.watch('endDate');
  const watchBidDue = form.watch('bidDue');
  const watchBidStart = form.watch('bidStart');
  //const bidDue = form.watch('bidDue');
  const jobStatusCodesCode = form.watch('jobStatusCodesCode');
  const isWarrantyService = form.watch('isWarrantyService');



  //const bidDueFormatted = formatDateObjectOrNull(bidDue);
  const startDateFormatted = formatDateObjectOrNull(watchStartDate);
  const endDateFormatted = formatDateObjectOrNull(watchEndDate);
  const bidStartFormatted = formatDateObjectOrNull(watchBidStart);
  const bidDueFormatted = formatDateObjectOrNull(watchBidDue);

  const biddingStatuses = ['B', 'P1', 'P2', 'P3', 'OH'];
  const pendingStatuses = ['BP', 'P1P', 'P2P', 'P3P', 'PC', 'OHP'];
  const terminalStatuses = ['R', 'XC', 'NC'];
  const activeStatuses = ['A', 'SNS', 'SUSP'];
  const closeOutStatuses = ['D'];
  const postConstructionStatuses = ['W', 'C', 'XT'];
  const serviceStatuses = ['SVC', 'SVC-C','SVC-D'];

  const isBidding = biddingStatuses.includes(jobStatusCodesCode);
  const isPending = [...pendingStatuses, ...terminalStatuses].includes(jobStatusCodesCode);
  const isNotStarted = ['SNS'].includes(jobStatusCodesCode);
  const isActive = [...activeStatuses, ...closeOutStatuses, ...postConstructionStatuses].includes(
    jobStatusCodesCode
  );

  const isService = serviceStatuses.includes(jobStatusCodesCode);

  const startDateRangeProps = {
    //...(bidDueFormatted && !isActive && { minDate: bidDueFormatted }),
    ...(startDateFormatted && { startDate: startDateFormatted }),
    ...(endDateFormatted && { endDate: endDateFormatted }),
  };

  const bidDueProps = {
    ...(bidStartFormatted && { startDate: bidStartFormatted }),
    ...(bidDueFormatted && { endDate: bidDueFormatted }),
  };

  const bidStartProps = {
    ...(bidDueFormatted && { endDate: bidDueFormatted }),
    ...(bidStartFormatted && { startDate: bidStartFormatted }),
  };

  const endDateRangeProps = {
    ...(startDateFormatted && { startDate: startDateFormatted }),
    ...(endDateFormatted && { endDate: endDateFormatted }),
    //...(bidDueFormatted && !isActive && { minDate: bidDueFormatted.clone().add(1, 'day') }),
    ...(startDateFormatted && { minDate: startDateFormatted.clone().add(0, 'day') }),
    ...(startDateFormatted && { maxDate: startDateFormatted.clone().add(120, 'months') }),
  };

  const { requireNTP, enableEstimatingScheduling, requireConstructionDatesOn } = accountSettings;
  const requireConstructionDates = isCurrentOrPrevStage(projectStage, requireConstructionDatesOn);
  const shouldShowMore = isActive && !requireNTP;
  const isPendingUpdate = isPending && isUpdate;
  const shouldShowManageBid = (isBidding || isPending) && isUpdate;
  const assignedUserCheck = (!isUpdate || isAssigned) ? 'anyAssigned' : [];

  const isAllowedEBD = isAllowed(moduleConstants.EBD, assignedUserCheck);
  const isAllowedECD = isAllowed(moduleConstants.ECD, assignedUserCheck);
  const isAllowedEBAGP = isAllowed(moduleConstants.EBAGP, assignedUserCheck);
  const isAllowedViewProfit = isAllowed(moduleConstants.VIEWPROFIT, assignedUserCheck);
  const isAllowedViewContract = isAllowed(moduleConstants.VIEWCONTRACTVAL, assignedUserCheck);

  const dateTimeFormat = 'MM/dd/yyyy hh:mm a';

  const affectedFields = [
    {
      name: 'isWarrantyService',
      controlData: {
        label: 'Is Warranty Service',
      },
      controlType: FormCheckbox,
      isAllowed: false,
      showOn: [...serviceStatuses],
      canEditOn: [...serviceStatuses],
      isDisabled: false,
      hidden: !isService,
      underShowMore: false,
    },
    {
      name: 'bidStart',
      
      controlType: FormDatePicker,
      controlData: {
        label: 'Estimating Start',
        //showTimeSelect: true,
        dateFormat: dateTimeFormat,
        selectsStart: true,
        ...bidStartProps
      },
      isAllowed: isAllowedEBD || !isUpdate,
      showOn: [...biddingStatuses],
      canEditOn: [...biddingStatuses],
      hidden: !enableEstimatingScheduling,
      underShowMore: false,
    },
    {
      name: 'bidDue',
      controlData: {
        label: 'Bid Due Date',
        showTimeSelect: true,
        dateFormat: dateTimeFormat,
        selectsEnd: true,
        ...bidDueProps,
      },
      controlType: FormDatePicker,
      isAllowed: isAllowedEBD || !isUpdate,
      showOn: [...biddingStatuses, ...pendingStatuses, ...terminalStatuses],
      canEditOn: [...biddingStatuses],
      hidden: false,
      underShowMore: false,
    },
    {
      name: 'bidSubmittedDate',
      controlData: {
        label: 'Bid Submitted Date',
      },
      controlType: FormDatePicker,
      isAllowed: isAllowedEBD || !isUpdate,
      showOn: [...pendingStatuses],
      showOnIfExists: [...terminalStatuses],
      hidden: false,
      underShowMore: false,
    },
    {
      name: 'startDate',
      controlData: {
        label: isActive ? 'Construction Start Date' : isService? 'Service Start Date': 'Approximate Construction Start',
        required: requireConstructionDates,
        selectsStart: true,
        ...startDateRangeProps,
      },
      controlType: FormDatePicker,
      isAllowed: isAllowedECD || !isUpdate,
      showOn: [
        ...biddingStatuses,
        ...pendingStatuses,
        ...terminalStatuses,
        ...activeStatuses,
        ...closeOutStatuses,
        ...postConstructionStatuses,
        ...serviceStatuses
      ],
      canEditOn: [...biddingStatuses, ...serviceStatuses],
      hidden: false,
      underShowMore: false,
    },
    {
      name: 'duration',
      controlData: {
        label: 'Duration',
        startDateFieldName: 'startDate',
        endDateFieldName: 'endDate',
      },
      controlType: FormDurationPicker,
      isAllowed: isAllowedECD || !isUpdate,
      showOn: [
        ...biddingStatuses,
        ...pendingStatuses,
        ...terminalStatuses,
        ...activeStatuses,
        ...closeOutStatuses,
        ...postConstructionStatuses,
        ...serviceStatuses
      ],
      canEditOn: [...biddingStatuses, serviceStatuses],
      hidden: false,
      underShowMore: false,
    },
    {
      name: 'endDate',
      controlData: {
        label: isActive ? 'Construction End Date' : isService? 'Service End Date':'Approximate Construction End',
        required: requireConstructionDates,
        selectsEnd: true,
        ...endDateRangeProps,
      },
      controlType: FormDatePicker,
      isAllowed: isAllowedECD || !isUpdate,
      showOn: [
        ...biddingStatuses,
        ...pendingStatuses,
        ...terminalStatuses,
        ...activeStatuses,
        ...closeOutStatuses,
        ...postConstructionStatuses,
        ...serviceStatuses
      ],
      canEditOn: [...biddingStatuses, ...serviceStatuses],
      hidden: false,
      underShowMore: false,
    },
    {
      name: 'contractAmount',
      controlData: {
        label: isActive ? 'Contract Amount ($)' : 'Approximate Contract',
        CustomInput: CurrencyInput,
      },
      controlType: FormInput,
      isAllowed: isAllowedEBAGP || !isUpdate,
      showOn: isAllowedViewContract?[
        ...biddingStatuses,
        ...pendingStatuses,
        ...terminalStatuses,
        ...activeStatuses,
        ...closeOutStatuses,
        ...postConstructionStatuses,
        ...serviceStatuses
      ] : [],
      canEditOn: isPromoting ? [...biddingStatuses, ...serviceStatuses] : [],
      hidden: isWarrantyService,
      underShowMore: false,
    },
    {
      name: 'grossProfit',
      controlData: {
        label: isActive ? 'Gross Profit' : 'Approximate Gross Profit',
        CustomInput: CurrencyInput,
      },
      controlType: FormInput,
      isAllowed: isAllowedEBAGP || !isUpdate,
      showOn: isAllowedViewProfit?[
        ...biddingStatuses,
        ...pendingStatuses,
        ...terminalStatuses,
        ...activeStatuses,
        ...closeOutStatuses,
        ...postConstructionStatuses,
        ...serviceStatuses
      ] : [],
      canEditOn: isPromoting ? [...biddingStatuses, ...serviceStatuses] : [],
      hidden: isWarrantyService,
      underShowMore: false,
    },
    {
      name: 'ntpDate',
      controlData: {
        label: 'NTP Received Date',
        required: requireNTP && !isNotStarted,
      },
      controlType: FormDatePicker,
      isAllowed: isAllowedEBD,
      showOn: [...activeStatuses, ...closeOutStatuses, ...postConstructionStatuses],
      canEditOn: [...activeStatuses, ...closeOutStatuses, ...postConstructionStatuses],
      hidden: isWarrantyService,
      underShowMore: !requireNTP,
    },
    {
      name: 'deliveredDate',
      controlData: {
        label: 'Delivered Date',
      },
      controlType: FormDatePicker,
      isAllowed: isAllowedEBD,
      showOn: [...closeOutStatuses, ...postConstructionStatuses],
      canEditOn: [...closeOutStatuses, ...postConstructionStatuses],
      hidden: false,
      underShowMore: false,
    },
    {
      name: 'followUpDone',
      controlData: {
        label: 'Follow Up Done',
        minDate: formatDateAdd(-12, 'months'),
        maxDate: formatDateAdd(5, 'months'),
      },
      controlType: FormDatePicker,
      isAllowed: isAllowedEBD,
      showOn: [...closeOutStatuses, ...postConstructionStatuses],
      canEditOn: [...closeOutStatuses, ...postConstructionStatuses],
      hidden: false,
      underShowMore: false,
    },
    {
      name: 'closeoutDate',
      controlData: {
        label: 'Closeout Date',
      },
      controlType: FormDatePicker,
      isAllowed: isAllowedEBD,
      showOn: [...postConstructionStatuses],
      canEditOn: [...postConstructionStatuses],
      hidden: isWarrantyService,
      underShowMore: false,
    },
  ];

  const canShowField = (fieldData: any, showMore: boolean) => {
    const canShowByPerm = fieldData.isAllowed;
    const canShowByValue =
      fieldData.showOnIfExists?.includes(jobStatusCodesCode) && formValues[fieldData.name];
    const canShowByStatus = fieldData.showOn.includes(jobStatusCodesCode);
    const canShowHidden = !fieldData.hidden;
    const canShowByShowMore = !fieldData.underShowMore === !showMore;

    return canShowByPerm && (canShowByStatus || canShowByValue) && canShowHidden && canShowByShowMore;
  };

  const getBidRelatedFieldsByStatus = (showMore = false) => {
    return affectedFields
      .filter((fieldData) => canShowField(fieldData, showMore))
      .map((fieldData) => {
        const { name, controlData, canEditOn = [], isDisabled, controlType: Control } = fieldData;
        const disabled = !canEditOn.includes(jobStatusCodesCode) && isUpdate||isDisabled;

        return (
          <Control
            key={name}
            name={name}
            control={form.control}
            disabled={disabled}
            Layout={HorizontalLayout}
            {...(controlData as any)}
          />
        );
      });
  };

  return (
    <>
      <b>Dates & Dollars</b>
      <hr />
      {getBidRelatedFieldsByStatus()}

      {isAllowedEBAGP && shouldShowManageBid && !isPromoting && (
        <a className='my-1 d-flex' onClick={onManageBid}>
          {`Manage ${isPendingUpdate ? 'Pending ' : ''}Bid`}
        </a>
      )}

      {!isWarrantyService&&<><FormSelect
        label='Warranty Period'
        control={form.control}
        options={appConstants.WARRANTYPERIOD}
        name='warrPeriod'
        inputText='Months'
        Layout={HorizontalLayout}
      />
      <FormInput
        label='Lien Period'
        control={form.control}
        name='lienPeriod'
        inputText='Days'
        Layout={HorizontalLayout}
      /></>}

      {shouldShowMore && (
        <a onClick={() => setShowMore((s) => !s)}>{!showMore ? 'Show More' : 'Hide'}</a>
      )}
      {showMore && getBidRelatedFieldsByStatus(true)}
    </>
  );
};
