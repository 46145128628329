import * as actionTypes from '../actions/actionTypes';
const initState = {
  response: [],
  message: '',
  success: null,

  isfetched: false,
  isCompanyCreatedError: false,

  isGetSubscription: false,
  isGetSubscriptionError: false,

  isDemoAccount: false,
  isDemoAccountSuccess: false,
  isDemoAccountError: false,
};

export default (state = initState, action = {}) => {
  switch (action.type) {
    case actionTypes.REGISTER_COMPANY_REQUEST:
      return {
        ...state,
        buttonActive: true,
        response: [],
        message: '',
        isStatus: false,
        isfetched: false,
        isCompanyCreatedError: false,
      };
    case actionTypes.REGISTER_COMPANY_SUCCESS:
      return {
        ...state,
        buttonActive: false,
        response: action.payload.response.result.data,
        isStatus: true,
        isfetched: true,
        success: action.payload.response.result.success,
        message: action.payload.response.result.success
          ? 'Record Added successfully'
          : action.payload.response.result.msg,
        isCompanyCreatedError: false,
      };
    case actionTypes.REGISTER_COMPANY_ERROR:
      return {
        ...state,
        buttonActive: false,
        response: [],
        isStatus: false,
        isfetched: false,
        success: action.payload.response.result.success,
        message: action.payload.response.result.message,
        isCompanyCreatedError: true,
      };

    case actionTypes.GET_SUBSCRIPTION_PLAN_REQUEST:
      return {
        ...state,
        response: [],
        message: '',
        isGetSubscription: false,
        isGetSubscriptionError: false,
      };
    case actionTypes.GET_SUBSCRIPTION_PLAN_SUCCESS:
      return {
        ...state,
        response: action.payload.response.result.data,
        isGetSubscription: true,
        isGetSubscriptionError: false,
        success: action.payload.response.result.success,
      };
    case actionTypes.GET_SUBSCRIPTION_PLAN_ERROR:
      return {
        ...state,
        response: [],
        isGetSubscription: false,
        isGetSubscriptionError: true,
      };

    case actionTypes.GET_DEMO_ACCOUNT_REQUEST:
      return {
        ...state,
        isDemoAccount: [],
        message: '',
        isDemoAccountSuccess: false,
        isDemoAccountError: false,
      };
    case actionTypes.GET_DEMO_ACCOUNT_SUCCESS:
      return {
        ...state,
        isDemoAccount: action.payload.response.result.data,
        isDemoAccountSuccess: true,
        isDemoAccountError: false,
        message: action.payload.response.result.msg,
        success: action.payload.response.result.success,
      };
    case actionTypes.GET_DEMO_ACCOUNT_ERROR:
      return {
        ...state,
        isDemoAccount: [],
        isDemoAccountSuccess: false,
        isDemoAccountError: true,
        message: action.payload.response.result.msg,
        success: action.payload.response.result.success,
      };

    default:
      return state;
  }
};
