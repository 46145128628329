import { joiResolver } from '@hookform/resolvers/joi';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { ColorPicker } from '../../shared/color-picker';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import {
  cancelEmployeeInvitation,
  disableUser,
  enableUser,
  getEmployeeInvitationEmails,
  getEmployeeInvitationStatus,
  getManageUserInfo,
  resendEmployeeInvitation,
  sendEmployeeInvitation,
  sendPasswordReset,
  setCalendarColor,
  updateEmployeeRole,
  userRolesDropdown,
} from '../../modules/contacts';
import {
  selectCancelEmployeeInvitation,
  selectDisableUser,
  selectGetEmployeeInvitationEmails,
  selectGetEmployeeInvitationStatus,
  selectGetManageUserInfo,
  selectResendEmployeeInvitation,
  selectSendEmployeeInvitation,
  selectSendPasswordReset,
  selectUpdateEmployeeRole,
  selectUserRolesDropdown,
} from '../../modules/contacts/selectors';
import useSettingsData from '../../components/shared-settings-components/use-settings-data';
import { isAllowed, moduleConstants } from '../../_constants';
import { getAccountSettingsAction } from '../../actions/accountSettings';
import { selectGeneralInfo } from '../../selectors/accountSettings';
import { useConfirm } from '../../shared/confirmation-dialog';
import FormSelect from '../../shared/form-controls/form-select';
import { ManageEmployeeSchema, manageEmployeeSchema } from './manage-employee.schema';
import { Userpilot } from 'userpilot';

type ManageEmployeeModalProps = {
  open: boolean;
  onClose: () => unknown;
  onSubmit?: (contactId?: string) => unknown;
  contactId: string;
};

export const ManageEmployeeModal = ({
  open,
  onClose,
  contactId,
  onSubmit = () => void 0,
}: ManageEmployeeModalProps) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(userRolesDropdown());
    dispatch(getAccountSettingsAction());
  }, []);

  useEffect(() => {
    if (!contactId) return;

    dispatch(getEmployeeInvitationEmails({ contactId }));
    dispatch(getEmployeeInvitationStatus({ contactId }));
  }, [open, contactId]);

  const { control, trigger, getValues, reset, setValue, formState } = useForm<ManageEmployeeSchema>({
    defaultValues: {
      roleId: '',
      email: '',
    },
    resolver: joiResolver(manageEmployeeSchema),
  });

  const generalInfo = useSelector(selectGeneralInfo);
  const { data: roles } = useSelector(selectUserRolesDropdown);
  const { data: emails } = useSelector(selectGetEmployeeInvitationEmails);
  const { data: invitation } = useSelector(selectGetEmployeeInvitationStatus);
  const { loading: sendLoading } = useSelector(selectSendEmployeeInvitation);
  const { data: manageUserInfo } = useSelector(selectGetManageUserInfo) as any;
  const { rows } = useSettingsData({ modelName: 'cppRoles', isSuperAdmin: false });

  const isMe = localStorage.getItem('contactId') === contactId;
  const isAccountOwner = invitation.userId && invitation.userId === generalInfo.adminUserId;
  const isUserActivated = invitation.status === 'activated';

  const canIManageAdmins = isAllowed(moduleConstants.EA);
  const adminRoleIds: Array<string> = (rows || [])
    .filter((row) => !row.trash && row.originalRoleCode === 'ADMIN')
    .map((row) => row._id);

  const isInheritedAdminRole = isUserActivated ? adminRoleIds.includes(invitation?.roleId) : false;
  const isPermLack = isInheritedAdminRole && !canIManageAdmins;
  const isPermLackOrOwner = isAccountOwner || isPermLack;

  // if contact's user activated or current user can manage admins - show all roles. show non-admin roles otherwise
  const rolesFiltered =
    canIManageAdmins || (isUserActivated && isPermLackOrOwner)
      ? [...roles]
      : roles.filter((row) => !adminRoleIds.includes(row._id));

  // prefill field with primary email or invitationEmail
  useEffect(() => {
    const primaryEmail = (emails[0] as any)?.email;
    const invitationEmail = invitation.email;

    setValue('email', invitationEmail ?? primaryEmail);
    setValue('roleId', invitation.roleId || '');
  }, [emails, invitation]);

  useEffect(() => {
    if (!invitation || invitation.contactId !== contactId) return;

    if (invitation.status === 'activated') dispatch(getManageUserInfo({ contactId }));
  }, [invitation, contactId]);

  useEffect(() => {
    reset({
      roleId: invitation?.roleId || '',
      email: invitation?.email || '',
    });
  }, [invitation]);

  const handleClose = () => {
    reset();
    onClose();
  };

  const onInvitationSend = async () => {
    const ok = await trigger();

    if (!ok) return;

    const { email, roleId } = getValues();

    await dispatch(sendEmployeeInvitation({ email, roleId, contactId }) as any).unwrap();
    await dispatch(getEmployeeInvitationStatus({ contactId }));

    Userpilot.track("Invited User");

    await onSubmit(contactId);


    handleClose();
  };

  const { loading: resendLoading } = useSelector(selectResendEmployeeInvitation);
  const { loading: cancelLoading } = useSelector(selectCancelEmployeeInvitation);

  const onInvitationResend = async () => {
    await dispatch(resendEmployeeInvitation({ contactId }) as any).unwrap();
    await dispatch(getEmployeeInvitationStatus({ contactId }));
    await onSubmit(contactId);

    handleClose();
  };

  const onInvitationCancel = async () => {
    await dispatch(cancelEmployeeInvitation({ contactId }) as any).unwrap();
    await dispatch(getEmployeeInvitationStatus({ contactId }));
    await onSubmit(contactId);


    handleClose();
  };

  const { loading: roleSubmitting } = useSelector(selectUpdateEmployeeRole);

  const onRoleSubmit = async () => {
    const ok = await trigger('roleId');

    if (!ok) return;

    const { roleId } = getValues();

    await dispatch(updateEmployeeRole({ roleId, contactId }) as any).unwrap();
    await dispatch(getEmployeeInvitationStatus({ contactId }));

    await onSubmit(contactId);


    handleClose();
  };

  const { loading: resetPasswordSubmitting } = useSelector(selectSendPasswordReset);

  const onResetPasswordSubmit = async () => {
    await dispatch(sendPasswordReset({ contactId }) as any).unwrap();
    await dispatch(getEmployeeInvitationStatus({ contactId }));

    await onSubmit(contactId);


    handleClose();
  };

  const { loading: disableUserSubmitting } = useSelector(selectDisableUser);

  const confirm = useConfirm();

  const onDisableUserSubmit = async () => {
    const ok = await confirm({
      title: 'Disable User',
      question: 'Are you sure you want to disable this user?',
    });

    if (!ok) return;

    await dispatch(disableUser({ contactId }) as any).unwrap();

    await dispatch(getEmployeeInvitationStatus({ contactId }));
    await onSubmit(contactId);


    handleClose();
  };

  const onDisableAndRemoveUserSubmit = async () => {
    const ok = await confirm({
      title: 'Disable User and Remove Employee',
      question: 'Are you sure you want to disable this user and remove it as employee?',
    });

    if (!ok) return;

    await dispatch(disableUser({ contactId, isDisableAffiliation: true }) as any).unwrap();

    await dispatch(getEmployeeInvitationStatus({ contactId }));

    // wait 500ms
    await new Promise((resolve) => setTimeout(resolve, 500));
    await onSubmit(contactId);


    handleClose();
  };

  const { loading: enableUserSubmitting } = useSelector(selectDisableUser);

  const onEnableUserSubmit = async () => {
    await dispatch(enableUser({ contactId }) as any).unwrap();

    await dispatch(getEmployeeInvitationStatus({ contactId }));
    await onSubmit(contactId);


    handleClose();
  };

  const DisableButton = useCallback(() => {
    if (!isMe && !isPermLackOrOwner) {
      return (
        <button
          className='btn btn-danger'
          disabled={disableUserSubmitting}
          onClick={onDisableUserSubmit}
        >
          Disable User only
        </button>
      );
    }

    return null;
  }, [isMe, contactId, isPermLackOrOwner, disableUserSubmitting]);

  const DisableAndRemoveButton = useCallback(() => {
    if (!isMe && !isPermLackOrOwner) {
      return (
        <button
          className='btn btn-danger'
          disabled={disableUserSubmitting}
          onClick={onDisableAndRemoveUserSubmit}
        >
          Disable & Remove Employee
        </button>
      );
    }

    return null;
  }, [isMe, contactId, isPermLackOrOwner, disableUserSubmitting]);

  const ResetPasswordButton = useCallback(() => {
    if (!isPermLack) {
      return (
        <button
          className='btn btn-primary'
          disabled={resetPasswordSubmitting}
          onClick={onResetPasswordSubmit}
        >
          Send Password Reset
        </button>
      );
    }

    return null;
  }, [contactId, isPermLack, resetPasswordSubmitting]);

  const ReEnableButton = useCallback(() => {
    return (
      <button
        className='btn btn-primary'
        disabled={enableUserSubmitting}
        onClick={onEnableUserSubmit}
      >
        Re-enable User
      </button>
    );
  }, [contactId, enableUserSubmitting]);

  const ManageInvite = useCallback(() => {
    if (invitation.status === 'invited') {
      return (
        <>
          <button className='btn btn-danger' disabled={cancelLoading} onClick={onInvitationCancel}>
            Cancel Invite
          </button>

          <button className='btn btn-primary' disabled={resendLoading} onClick={onInvitationResend}>
            Resend Invite
          </button>
        </>
      );
    }

    return null;
  }, [contactId, invitation, cancelLoading, resendLoading]);

  const [currentCalendarColor, setCurrentCalendarColor] = useState({});

  const setUserCalendarColor = async (color: { r: number; b: number; g: number; a: number }) => {
    setCurrentCalendarColor(color);
    await dispatch(setCalendarColor({ contactId, 'calendarColor': color }) as any).unwrap();
  };

  useEffect(() => {
    if (manageUserInfo?.calendarColor?.a)
      setCurrentCalendarColor(manageUserInfo.calendarColor);
    else {
      setCurrentCalendarColor({ r: 0, g: 0, b: 0, a: 1 });
    }
  }, [manageUserInfo]);



  const AdditionalInfo = useCallback(() => {
    if (isUserActivated) {
      const dateFormat = 'MM/DD/YYYY';
      const { invitedAt, deactivatedAt, activatedAt, signedInAt } = manageUserInfo || {};

      return (
        <div className='row'>
          <hr />

          <div className='col-6'>

            Additional info
            {isAccountOwner && (<div><b>Account Owner</b></div>)}
            <div>
              <b>Invited at: </b>
              {moment(invitedAt).format(dateFormat)}
            </div>
            {invitation.disabled ? (
              <div>
                <b>Deactivated at: </b>
                {moment(deactivatedAt).format(dateFormat)}
              </div>
            ) : (
              <div>
                <b>Activated at: </b>
                {moment(activatedAt).format(dateFormat)}
              </div>
            )}
            <div>
              <b>Last login: </b>
              {moment(signedInAt).format(dateFormat)}
            </div>
          </div>
          <div className='col'>
            <div className='d-flex gap-3'>
              <label>Calendar color</label>

              <ColorPicker
                value={currentCalendarColor}
                onChange={setUserCalendarColor}
                name='calendarColor'
                aria-label='Calendar Color'
              />
            </div>
          </div>
        </div>
      );
    }

    return null;
  }, [isUserActivated, manageUserInfo, currentCalendarColor]);

  return (
    <Modal isOpen={open} toggle={handleClose} size='lg'>
      <ModalHeader toggle={handleClose}>Manage User</ModalHeader>
      <ModalBody>
        <div className='container'>
          <div className='row'>
            <div className='col-md-6' data-testid='RoleSelect'>
              <FormSelect
                label='User Type'
                options={rolesFiltered}
                name='roleId'
                control={control}
                disabled={isPermLackOrOwner}
              />
            </div>

            <div className='col-md-6' data-testid='EmailSelect'>
              <FormSelect
                disabled={invitation.status !== 'not-invited'}
                isClearable
                label='Email'
                options={emails}
                formatOptionLabel={(o) => `${o.email}${o.label ? ` (${o.label})` : ''}`}
                getOptionValue={(o) => o.email}
                control={control}
                name='email'
                creatable
                getNewOptionData={(v) => ({ email: v, label: 'new' } as any)}
                getExtraValue={(i) => ({ email: i, label: '' } as any)}
              />
            </div>
          </div>



          <AdditionalInfo />
        </div>
      </ModalBody>
      <ModalFooter>
        <ManageInvite />

        {isUserActivated && (
          <>
            {invitation.disabled
              ? <ReEnableButton />
              : <div className='d-flex gap-2 mb-3'>
                <DisableButton />
                <DisableAndRemoveButton />
                <ResetPasswordButton />
              </div>
            }
          </>
        )}

        <div className='d-flex gap-2 ms-auto'>
          <button className='btn btn-primary' onClick={handleClose}>
            Cancel
          </button>

          {invitation.status === 'not-invited' ? (
            <button className='btn btn-primary' disabled={sendLoading} onClick={onInvitationSend}>
              Invite User
            </button>
          ) : (
            <button
              className='btn btn-primary'
              disabled={!formState.dirtyFields.roleId || roleSubmitting}
              onClick={onRoleSubmit}
            >
              Submit
            </button>
          )}
        </div>
      </ModalFooter>
    </Modal>
  );
};
