import _ from 'lodash';
import { useFormContext } from 'react-hook-form';
import { useMemo, useState, useEffect } from 'react';
import { FormSelectPaging } from '../utils/form-select-paging';
import { useAppDispatch } from '../../../shared/hooks/use-app-dispatch';
import { findContactAffiliations } from '../../../modules/contacts';
import { isAllowed, moduleConstants } from '../../../_constants';

type ProjectCompanyTeamProps = {
  projectRoles: Array<any>;
  isInternal?: boolean;
  isLead?: boolean;
  showHeader?: boolean;
  disabled?: boolean;
  isAssigned?: boolean;
  isUpdate?: boolean;
  formatAsTextWhenLocked?: boolean
};

export const ProjectCompanyTeam = ({
  projectRoles = [],
  isInternal = false,
  isLead = false,
  showHeader = true,
  disabled = false,
  isAssigned = false,
  isUpdate = false,
  formatAsTextWhenLocked = false
}: ProjectCompanyTeamProps) => {
  const count = 20;
  const companyName = localStorage.getItem('companyName');

  const dispatch = useAppDispatch();
  const form = useFormContext();
  const projectTeam = form.watch('projectTeam', {});

  const [teamRoles, setTeamRoles] = useState<Array<any>>([]);
  const [optionsMap, setOptionsMap] = useState<Map<string, Array<any>>>(new Map());
  const [requestProjectTeam, setRequestProjectTeam] = useState<object>({});
  const [projectTeamLoaded, setProjectTeamLoaded] = useState<boolean>(false);
  const [defaultOptions, setDefaultOptions] = useState<Array<any>>([]);
  const [defaultOptionsLoading, setDefaultOptionsLoading] = useState<boolean>(false);

  const getPermissionsByCode = (code: string) =>
    isAllowed(code, !isUpdate || isAssigned ? 'anyAssigned' : []);

  const isAllowedInternal = getPermissionsByCode(moduleConstants.ASSIGNINTERNAL);
  const isAllowedExternal = getPermissionsByCode(moduleConstants.ASSIGNEXTERNAL);
  const isAllowedAssignEstimator = getPermissionsByCode(moduleConstants.ASSIGNEST);
  const isAllowedAssignProjMaster = getPermissionsByCode(moduleConstants.ASSIGNPM);
  const isAllowedAssignSupervisor = getPermissionsByCode(moduleConstants.ASSIGNPS);
  const isAllowedAssignProjEng = getPermissionsByCode(moduleConstants.ASSIGNPE);

  // if assigned projectTeam changes - load options anew
  useEffect(() => {
    if (!_.isEqual(projectTeam, requestProjectTeam)) {
      setRequestProjectTeam({ ...projectTeam });
    }
    if (projectTeam && Object.keys(projectTeam).length == 0) {
      setProjectTeamLoaded(false);
    }
  }, [projectTeam]);

  // filter roles based on incoming property
  useEffect(() => {
    if (projectRoles.length > 0) {
      const thisTeamRoles = projectRoles.filter(
        (role: any) =>
          role.type === (isInternal ? 'internal' : 'external') && (!isLead || role.isShowOnLeads)
      );
      setTeamRoles(thisTeamRoles);
    }
  }, [projectRoles, isInternal, isLead]);

  // load and set options for each control
  useEffect(() => {
    if (teamRoles.length > 0 && !projectTeamLoaded && defaultOptionsLoading) {
      (async () => {
        const newOptionsMap = new Map(optionsMap);

        if(newOptionsMap.size === 0 )
        {
          return;
        }

        const thisTeamRoleIds = teamRoles.map((role) => role._id);
        const assignedMemberIds = new Map(
          Object.entries(requestProjectTeam).filter(
            (entry) =>
              Array.isArray(entry[1]) && entry[1].length > 0 && thisTeamRoleIds.includes(entry[0])
          )
        ) as Map<string, Array<string>>;

        // if no assignments - do not run request for selected options
        if (assignedMemberIds.size === 0) {
          return;
        }

        // load selected options
        const assignedAffiliationsIds = Array.from(
          assignedMemberIds.values()
        ).flat() as Array<string>;
        const selectedOptions = await dispatch(
          findContactAffiliations({
            criteria: `${assignedAffiliationsIds.join(' ')}`,
            page: 1,
            count: assignedAffiliationsIds.length,
            isAccount: isInternal ? true : null,
            includeDeleted: true,
            fields: ['_id'],
          })
        ).unwrap();

        teamRoles.forEach(({ _id }) => {
          const roleAssignedMembersIds = assignedMemberIds.get(_id) || [];
          const roleSelectedOptions = selectedOptions.filter((option) =>
            roleAssignedMembersIds.includes(option._id)
          );

          newOptionsMap.set(_id, _.uniqBy([...defaultOptions, ...roleSelectedOptions], '_id'));
        });

        setProjectTeamLoaded(true);

        setOptionsMap(new Map(newOptionsMap));
      })();
    }
  }, [defaultOptions, teamRoles, requestProjectTeam, isInternal]);

  useEffect(() => {
    if (teamRoles.length > 0  && !defaultOptionsLoading) {
      (async () => {
        let newOptionsMap = new Map(optionsMap);
        if (optionsMap.size === 0 && !defaultOptionsLoading) {
          setDefaultOptionsLoading(true);
          // load default options
          const newDefaultOptions = await dispatch(
            findContactAffiliations({
              criteria: '',
              page: 1,
              count,
              isAccount: isInternal ? true : null,
              includeDeleted: false,
            })
          ).unwrap();

          newOptionsMap = new Map();
          teamRoles.forEach(({ _id }) => newOptionsMap.set(_id, [...newDefaultOptions]));

          setOptionsMap(new Map(newOptionsMap));
          setDefaultOptions(newDefaultOptions);
        }
      })();
    }
  }, [teamRoles, requestProjectTeam, isInternal]);

  const internalProjectRoles = useMemo(() => {
    const isDisabled = isInternal
      ? (isAllowedInternal
        ? disabled
        : true)
      : isAllowedExternal
      ? disabled
      : true;

    return teamRoles.map((role: any) => {
      let isRoleDisabled = isDisabled;

      if (!isRoleDisabled) {
        switch (role.code) {
          case 'EST':
            isRoleDisabled = !isAllowedAssignEstimator;
            break;
          case 'PM':
            isRoleDisabled = !isAllowedAssignProjMaster;
            break;
          case 'SI':
            isRoleDisabled = !isAllowedAssignSupervisor;
            break;
          case 'PE':
            isRoleDisabled = !isAllowedAssignProjEng;
            break;
        }
      }

      return (
        <FormSelectPaging
          key={role._id}
          role={role}
          name={`projectTeam.${role._id}`}
          count={count}
          isAccount={isInternal}
          initialOptions={[...(optionsMap.get(role._id) || [])]}
          disabled={isRoleDisabled}
          formatAsTextWhenLocked={formatAsTextWhenLocked}
          required={role.required}
          creatable={true}
        />
      );
    });
  }, [teamRoles, optionsMap, disabled]);

  const getHeader = () => {
    if (showHeader) {
      return (
        <>
          <b>{isInternal ? companyName : 'External'} team</b>
          <hr />
        </>
      );
    }

    return null;
  };

  return (
    <>
      {getHeader()}
      {internalProjectRoles}
    </>
  );
};
