import axios from 'axios';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { getAccountSubscriptionInfo } from '../../../modules/accounts';

import { appConstants } from '../../../_constants';
import { useAppDispatch } from '../../../shared/hooks/use-app-dispatch';

import { formatDate } from '../../../utils/date-formatters';


type BillingInfoPageProps = {
  accountId: string;
  embeddedMode?: boolean | null;
};

export const BillingInfoPage = ({ accountId, embeddedMode }: BillingInfoPageProps) => {
  const dispatch = useAppDispatch();

  const form = useForm({
    defaultValues: {
      billingAddress: {} as any,
      chargebeeId: '',
      customerEmail: '',
      companyName: '',
      status: '',
      nextBillingAt: new Date(0),
      nextBillingAmount: 0,
      billing_period: 0,
      billing_period_unit: '',
      planName: '',
      addonNames: [],
      amount: -999,
      trialEnd: new Date(0),
      created_at: new Date(0),
      last4: 'xxxx',
      chargebeePlatformName: 'commandtech',
      accessUrl: null,
      contactMasterStatus: null,
      projectMasterStatus: null,
    },
  });

  useEffect(() => {
    dispatch(getAccountSubscriptionInfo({ accountId: accountId }))
      .unwrap()
      .then((data) => {
        form.reset({
          billingAddress: data.result.billingAddress,
          customerEmail: data.result.customerEmail,
          companyName: data.result.companyName,
          status: data.result.status,
          chargebeeId: data.result.chargebeeId,
          nextBillingAmount: data.result.nextBillingAmount,
          billing_period: data.result.billing_period,
          billing_period_unit: data.result.billing_period_unit,
          planName: data.result.planName,
          addonNames: data.result.addonNames,
          amount: data.result.amount,
          trialEnd: data.result.trialEnd,
          created_at: data.result.created_at,
          nextBillingAt: data.result.nextBillingAt,
          chargebeePlatformName: data.result.chargebeePlatformName,
          last4: data.result.last4,
          accessUrl: data.result.accessUrl,
          contactMasterStatus: data.result.contactMasterStatus,
          projectMasterStatus: data.result.projectMasterStatus,
        });
      });
  }, []);

  const {
    status,
    nextBillingAt,
    planName,
    created_at,
    chargebeePlatformName,
    billingAddress,
    customerEmail,
    companyName,
    last4,
    accessUrl,
    addonNames,
    contactMasterStatus,
    projectMasterStatus
  } = form.getValues();

  const customerSince = formatDate(created_at);

  const nextBillingDate = formatDate(nextBillingAt);

  const chargebeePortalURL = 'https://' + chargebeePlatformName + '.chargebeeportal.com';

  const onCheckoutClick = async (checkoutType: string) => {
    // TODO: setup axios and redux toolkit
    const  {result}  = await axios.post(
      '/parse/functions/getChargebeeCheckout',
      {
        accountId: accountId,
        userId: localStorage.getItem('userId'),
        checkoutType: checkoutType+'-USD-Yearly'
      },
      {
        baseURL: appConstants.WEB_SERVICE_URL_WITHOUT_PARSE,
        headers: {
          'X-Parse-Application-Id': appConstants.PARSE_APPLICATION_ID,
          'X-Parse-REST-API-Key': appConstants.PARSE_REST_API_KEY,
          'X-Parse-Session-Token': localStorage.getItem('sessionToken'),
          authorization: localStorage.getItem('JWTTOKEN'),
        },
      }
    ).then((response) => response.data);

    console.log(result.hosted_page);
    if(result.hosted_page){

    const url = new URL(result.hosted_page);
      window.open(url);
    }
    
  };

  return (
    <div className='form-box mt-2'>
      <script src="https://js.chargebee.com/v2/chargebee.js"></script>
      {(!embeddedMode || accountId?.length > 0)  && (
        <>
          {!embeddedMode && (
            <div className='flex row mt-2'>
              <h2 className='heading2 col-8'>Billing & Subscription Information</h2>
            </div>
          )}
          <FormProvider {...form}>
            <div className='col-9  d-flex flex-column ml-0 '>
              <div className=''>
                {!embeddedMode ? <h4>Your Subscription Status</h4> : <h6>Subscription Status</h6>}
                <ul>
                  <li>
                    <strong>Subscription Plan:</strong> {planName}
                  </li>
                  <li>
                    <strong>ContactSync Status:</strong> {contactMasterStatus}
                  </li>
                  <li>
                    <strong>ProjectSync Status:</strong> {projectMasterStatus}
                  </li>
                  <li>
                    <strong>Addons:</strong>{' '}
                    {addonNames?.length > 0 ? addonNames.join(', ') : 'None'}
                  </li>
                  <li>
                    <strong>Member Since:</strong> {customerSince}
                  </li>
                  <li>
                    <strong>Next Billing Date:</strong> {nextBillingDate}
                  </li>
                  <li>
                    <strong>Subscription Status:</strong>{' '}
                    {status === 'active' ? (
                      <span className='badge bg-success px-2 py-1 mx-1'>Active</span>
                    ) : status === 'in_trial' ? (
                      <span className='badge bg-warning'>In Trial</span>
                    ) : (
                      <span className='badge bg-danger'>{status}</span>
                    )}
                  </li>
                </ul>
                <br />
                {!embeddedMode ? <h4>Billing Info</h4> : <h6>Billing Info</h6>}
                <ul>
                  <li>
                    <strong>Company:</strong> {companyName}
                  </li>
                  <li>
                    <strong>Name:</strong> {billingAddress?.first_name} {billingAddress?.last_name}
                  </li>
                  <li>
                    <strong>Address:</strong>{' '}
                    {[
                      billingAddress?.line1,
                      billingAddress?.line2,
                      billingAddress?.city,
                      billingAddress?.state,
                      billingAddress?.zip,
                    ]
                      .filter(Boolean)
                      .join(', ')}
                  </li>
                  <li>
                    <strong>Email:</strong> {customerEmail}
                  </li>
                  <li>
                    <strong>Last 4:</strong> {last4}
                  </li>
                </ul>
                <br />
                {!embeddedMode && false && (
                  <>
                    <p>
                      If you need to manage your subscription or make any changes,
                      <br /> please do so at the Command Post chargebee portal:
                    </p>{' '}
                  </>
                )}

                  {embeddedMode&&(
                <a
                  className='subscription-link'
                  target='_blank'
                  rel='noreferrer'
                  href={accessUrl ?? chargebeePortalURL}
                >
                  Manage Subscription
                </a>
                )}
                  <br/>
                  {embeddedMode&&( <div> 
                <button onClick={() => onCheckoutClick('Quickbooks')} >Quickbooks checkout test</button><br/>
                <button onClick={() => onCheckoutClick('Procore')} >Procore checkout test</button><br/>
                <button onClick={() => onCheckoutClick('Contact-Master')} >The Masters checkout test</button>

                </div>)}
              </div>
            </div>
          </FormProvider>
        </>
      )}
    </div>
  );
};
