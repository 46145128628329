import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { getAllScheduleAction, cancelScheduleAction } from '../../actions/message';
import _ from 'lodash';
import { LightBoxComponent } from './LightBox.component';
import ScheduleEditor from '../Dialogs/schedule-editor-modal';
import { ScheduleView } from './RightSectionRender';
//import SchedulePopUp from '../Common/SchedulePopUp';

const ScheduleTab = ({
  location,
  typeId,
  typeRef,
  getAllScheduleAction,
  cancelScheduleAction,
  allSchedule,
  data = {},
}) => {
  const [accountId] = useState(localStorage.getItem('accountId'));
  const [commentList, setCommentList] = useState([]);
  const [showEditSchedule, setShowEditSchedule] = useState(false);
  const [lightBoxOpen, setLightBoxOpen] = useState(false);
  const [selectedSchedule, setSelectedSchedule] = useState(null);
  const [attachmentIndex, setAttachmentIndex] = useState(-1);
  const [scheduleAction, setScheduleAction] = useState('new');
  const [scheduleObjectId, setScheduleObjectId] = useState(null);
  const [showScheduleDetailModal, setShowScheduleDetailModal] = useState(false);
  const [showScheduleDetailId, setShowScheduleDetailId] = useState(null);
  const [filterText, setFilterText] = useState('');

  useEffect(() => {
    if (location.pathname.includes('schedule/add')) {
      setShowEditSchedule(true);
    }else if(location.pathname.includes('schedule/view')){
      const scheduleId = location.pathname.split('/').pop();
      setShowScheduleDetailId(scheduleId);
      setShowScheduleDetailModal(true);
    }else if(location.pathname.includes('schedule/edit')){
      const scheduleId = location.pathname.split('/').pop();
      editEvent({ objectId: scheduleId });
      setShowEditSchedule(true);
    }
    getAllSchedule();
  }, [location.pathname, typeId, typeRef]);

  useEffect(() => {
    if (allSchedule !== commentList) {
      setCommentList(allSchedule);
    }
  }, [allSchedule]);

  const formatScheduleAttachments = (selectedSchedule) => {
    return selectedSchedule?.attachments?.map((attachment) => ({
      ...attachment,
      description:
        'Draft Schedule\n' +
        'to: ' +
        selectedSchedule.scheduleTo?.map((to) => to.value).join(', ') +
        '\n' +
        'sub: ' +
        selectedSchedule.scheduleSub +
        '\n' +
        selectedSchedule.scheduleBody?.replace(/(<([^>]+)>)/gi, ''),
    }));
  };

  const getAllSchedule = () => {
    const data = {
      accountId,
      typeId: typeId,
      typeRef: typeRef,
      userId: localStorage.getItem('userId'),
      page: 0,
      displayLimit: 99999999,
    };
    getAllScheduleAction(data);
  };

  const cancelDelete = (scheduleData, confirm) => async () => {
    const confirmed =
      (await confirm?.({
        title: 'Delete draft',
        question: 'Are you sure you want to Delete this draft?',
      })) ?? true;

    if (!confirmed) {
      return;
    }

    const formData = {
      objectId: scheduleData.objectId,
      typeRef: scheduleData.typeRef,
      typeId: scheduleData.typeId,
    };

    await cancelScheduleAction(formData);
    getAllSchedule();
  };

  const editEvent = (data) => {
    const objectId = data.objectId;
    setScheduleObjectId(objectId);

    setScheduleAction('EDIT');
    setShowEditSchedule(true);
  };

  const copyEvent = (scheduleData) => {
    const scheduleObjectId = scheduleData.objectId;
    setScheduleObjectId(scheduleObjectId);

    setScheduleAction('COPY');
    setShowEditSchedule(true);
  };

  const showEditScheduleCallback = () => {
    setShowEditSchedule(true);
    setScheduleObjectId('');
    setScheduleAction('new');
  };

  const closeWindowCallback = () => {
    setShowEditSchedule(false);
    setScheduleObjectId('');
    setScheduleAction('new');
  };

  const showHideLightBox = (schedule, attachmentIndex) => {
    setLightBoxOpen(!lightBoxOpen);
    setSelectedSchedule(schedule);
    setAttachmentIndex(attachmentIndex);
  };

  const closeDetailModal = () => {
    setShowScheduleDetailModal(false);
    setShowScheduleDetailId(null);
  };

  const sortSchedules = (schedules) => {
    let rootSchedules = schedules.filter((schedule) => !schedule.replyTo);

    let sortedSchedules = rootSchedules
      .map((schedule) => {
        let replies = schedules.filter((n) => n.parentScheduleId === schedule.objectId);
        replies.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
        return [schedule, ...replies];
      })
      .flat();
    return sortedSchedules;
  };

  const commentListData =
    commentList?.length > 0 ? (
      sortSchedules(commentList).map((v) => (
        <ScheduleView
          scheduleData={v}
          editEvent={editEvent}
          copyEvent={copyEvent}
          cancelDelete={cancelDelete}
          showHideLightBox={showHideLightBox}
          filterText={filterText}
        ></ScheduleView>
      ))
    ) : (
      <div className='outputBox scheduleHtml'>No scheduled events</div>
    );

  const showEditScheduleComponent = showEditSchedule || scheduleObjectId;


  return (
    <div>
      {!showEditScheduleComponent ? (
        <div className='container'>
          <br />
          <div className='row'>
            <div className='col-md-12'>
              <div className='pageinfo d-flex'>
                <h2> Events </h2>
                <div className='ms-auto'>
                  <input
                    type='text'
                    className='btn-group form-control-sm'
                    placeholder='Search'
                    onChange={(e) => setFilterText(e.target.value)}
                    />
                    <button className='btn btn-primary' onClick={showEditScheduleCallback}>
                      Add event
                    </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <br />
          <ScheduleEditor
            showEditSchedule={showEditScheduleComponent}
            typeId={typeId}
            typeRef={typeRef}
            objectId={scheduleObjectId}
            scheduleAction={scheduleAction}
            closeWindowCallback={closeWindowCallback}
            data={data}
          ></ScheduleEditor>
        </div>
      )}
      <Scrollbars style={{ height: 'calc(100vh)', minHeight:'500px' }}>{commentListData}</Scrollbars>
      <LightBoxComponent
        attachments={formatScheduleAttachments(selectedSchedule) ?? []}
        shouldOpen={lightBoxOpen}
        initialIndex={attachmentIndex}
        closeCallback={showHideLightBox}
      />
      {showScheduleDetailModal && showScheduleDetailId && (
        <ScheduleView
          scheduleId={showScheduleDetailId}
          typeId={typeId}
          typeRef={typeRef}
          asModal={true}
          editEvent={editEvent}
          copyEvent={copyEvent}
          cancelDelete={cancelDelete}
          showHideLightBox={showHideLightBox}
          toggle={closeDetailModal}
          showModal={true}
        ></ScheduleView>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { message } = state;
  const { allSchedule } = message;
  return {
    allSchedule: allSchedule && allSchedule.length ? allSchedule : [],
  };
};

export default withRouter(
  connect(mapStateToProps, {
    getAllScheduleAction,
    cancelScheduleAction,
  })(ScheduleTab)
);
