import { useCallback, useMemo, useRef, useState } from 'react';
import { Controller, useFormContext, Control } from 'react-hook-form';
import Select, { createFilter, components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { DefaultLayout } from '../control-layouts/default-layout';
import { DndContext } from '@dnd-kit/core';
import { useSortable, arrayMove, SortableContext } from '@dnd-kit/sortable';

const filterConfig = { ignoreCase: true, ignoreAccents: true, trim: true };

const SortableMultiValue = (props) => {
  const { attributes, listeners, setNodeRef, transform } = useSortable({
    id: props.data.value,
  });

  return (
    <div
      {...attributes}
      ref={setNodeRef}
      style={{ transform }}
      onMouseDown={(e) => {
        e.stopPropagation(); // Remove this line to allow event propagation
      }}
    >
      <components.MultiValue {...props} innerRef={setNodeRef} {...listeners} />
    </div>
  );
};

const SortableMultiValueLabel = (props) => {
  const { attributes, listeners, setNodeRef, transform } = useSortable({
    id: props.data.value,
  });

  const handleDragStart = (event) => {
    // Calling the onDragStart listener from useSortable
    if (listeners.onDragStart) {
      listeners.onDragStart(event);
    }
  };

  return (
    <div
      {...attributes}
      {...listeners}
      ref={setNodeRef}
      style={{ transform }}
      onMouseDown={(e) => {
        e.stopPropagation(); // Remove this line to allow event propagation
      }}
      onDragStart={handleDragStart}
      draggable
    >
      <components.MultiValueLabel {...props} innerRef={setNodeRef} />
    </div>
  );
};

/**
 * @param {{
 * name: string,
 * control?: Control,
 * label: string,
 * options: any[],
 * required?: boolean,
 * placeholder?: string,
 * creatable?: boolean,
 * onAfterSelection?: (selections: any[], name: string) => any,
 * onMenuScrollToBottom?: () => Promise<void>,
 * onInputChange?: (criteria: string, action: string) => any,
 * filterOption?: (option: any, rawInput: string) => boolean,
 * hideLabelName?: boolean,
 * styles?: Record<string, unknown>,
 * getExtraValue?: (input: string) => any,
 * formatCreateLabel?: (label: string) => string,
 * useCustomCreateOption?: boolean,
 * onCreateOptionCustom?: (input: string, field: { onChange: (value: string[]) => void, value: string[] }) => void,
 * getOptionLabel?: (opt: any) => any,
 * getOptionValue?: (opt: any) => any,
 * isOptionDisabled?: (opt: any) => boolean,
 * isSolo?: boolean,
 * isLoading?: boolean,
 * Layout?: React.FC<{ name: string, label: string, required: boolean, input: React.ReactNode, error: string }>,
 * rendererExtraProps?: Record<string, unknown>,
 * labelWidth?: number,
 * controlWidth?: number,
 * disabled?: boolean,
 * useObjectValue?: boolean,
 * onClick?: () => void,
 * formatAsTextWhenLocked?: boolean
 * }}
 * @description
 * FormMultiSelect is a wrapper around react-select component.
 * It provides a way to use it with react-hook-form.
 * It also provides a way to add custom options to the list.
 */
const FormMultiSelect = ({
  name,
  control,
  label,
  options: initOptions = [],
  required = false,
  placeholder = label,
  creatable = false,
  onAfterSelection = (selections, name) => selections,
  onMenuScrollToBottom = () => Promise.resolve(),
  onInputChange = (criteria, action) => criteria,
  filterOption = createFilter(filterConfig),
  hideLabelName = false,
  styles = {},
  getExtraValue = (input) => ({ value: input, label: input }),
  formatCreateLabel = (label) => `Add "${label}"`,
  useCustomCreateOption = false,
  onCreateOptionCustom = (input, field) => input,
  getOptionLabel = (opt) => opt.label,
  getOptionValue = (opt) => opt.value,
  isOptionDisabled = (opt) => false,
  isSolo = false,
  isLoading = false,
  Layout = DefaultLayout,
  rendererExtraProps = {},
  labelWidth = 4,
  controlWidth = 8,
  disabled = false,
  useObjectValue = false,
  formatAsTextWhenLocked = false,
  onClick = () => {},
}) => {
  const form = useFormContext();

  if (!control && !form) {
    throw new Error('FormMultiSelect must be used within a FormProvider or have a control prop');
  }

  const elementRef = useRef();
  const [shouldPlaceTop, setShouldPlaceTop] = useState(false);

  const maxMenuHeight = 300; // this is default value but setting it strictly to be able to compare
  const optionHeight = 75; // approx option height

  const [extraOptions, setExtraOptions] = useState([]);
  const options = useMemo(
    () => [...initOptions, ...(creatable ? extraOptions : [])],
    [initOptions, extraOptions, creatable]
  );

  const map = useMemo(() => new Map(options.map((opt) => [getOptionValue(opt), opt])), [options]);

  const SelectRenderer = useMemo(
    () => (!isSolo ? (creatable ? CreatableSelect : Select) : creatable ? CreatableSelect : Select),
    [creatable]
  );

  const handleInsert = useCallback(
    (input, field) => {
      const newOption = getExtraValue(input);

      setExtraOptions((old) => [...old, newOption]);

      const result = [useObjectValue ? newOption : getOptionValue(newOption)];

      if (field.value?.length) {
        result.unshift(...field.value);
      }

      field.onChange(result);
    },
    [useObjectValue]
  );

  const [selected, setSelected] = useState([]);

  const onSelectionsChange = useCallback(
    (selections, field) => {
      if (!selections) selections = [];

      if (!Array.isArray(selections)) selections = [selections];

      const result = selections.map((selection) =>
        useObjectValue ? selection : getOptionValue(selection)
      );

      field.onChange(result);
      onAfterSelection(selections);
      setSelected(selections);
    },
    [useObjectValue]
  );

  const onSortEnd = ({ oldIndex, newIndex }, selections, field) => {
    const newValue = arrayMove(selections, oldIndex, newIndex);
    onSelectionsChange(newValue, field);
  };

  const getValue = useCallback(
    (field) => {
      const value = Array.isArray(field.value) ? field.value : [];

      if (!useObjectValue) {
        return (
          value
            .map((v) => {
              const existingOption = map?.get(v);

              // if option already in options map - return it
              if (existingOption) {
                return existingOption;
              }

              // if control is creatable and there is new option in value - create it
              if (creatable) {
                const newOption = getExtraValue(v);

                if (!newOption) return null;

                // set new option as extra option
                setExtraOptions((old) => {
                  // do not add duplicates
                  const existingExtraOption = old.find((opt) => opt.value === newOption.value);
                  return existingExtraOption ? old : [...old, newOption];
                });

                return newOption;
              }
            })
            .filter(Boolean) || []
        );
      }

      return value || [];
    },
    [map, useObjectValue, creatable, extraOptions]
  );

  const onMenuOpen = () => {
    if (elementRef.current) {
      try {
        // get ref to select DOM element
        const controlRef =
          elementRef.current.select.controlRef ?? elementRef.current.select.select.controlRef;
        // compare distance between container of scroll element and select element to bottom of the screen
        const spaceBelow =
          controlRef.closest('.container').getBoundingClientRect().bottom -
          controlRef.getBoundingClientRect().bottom;

        let approxMenuHeight = (elementRef.current.props.options.length || 1) * optionHeight;

        if (approxMenuHeight > maxMenuHeight) {
          approxMenuHeight = maxMenuHeight;
        }

        setShouldPlaceTop(spaceBelow < approxMenuHeight);
      } catch (e) {
        // We want application to not crush, but don't care about the message
      }
    }
  };

  const onCreateOption = useCustomCreateOption ? onCreateOptionCustom : handleInsert;

  const getTextValue = (value) => {
    if (Array.isArray(value) && value.length > 0) {
      return value
        .filter((opt) => opt)
        .map(({ label }) => label)
        .join(', ');
    }
  };

  const shouldFormatAsText = formatAsTextWhenLocked && disabled;

  // should return al values by line as a text string

  const renderAsText = (value) => {
    if (Array.isArray(value) && value.length > 0) {
      if (getOptionLabel) {
        return (
          <div className='d-flex flex-wrap gap-2'>
            {value.map((item, index) => (
              <div className='mb-1'>
                <div key={index}>{getOptionLabel(item, true)}</div>
              </div>
            ))}
          </div>
        );
      }
    } else {
      return <>N/A</>;
    }
  };

  const [activeId, setActiveId] = useState(null);

  return (
    <Controller
      rules={{ required: required && `${label} is required` }}
      name={name}
      control={control || form.control}
      onChange={([, data]) => data}
      render={({ field, fieldState }) => {
        const value = getValue(field);

        const getIndex = (id) => value.findIndex((item) => item.value === id);
        const isMulti = !isSolo || value.length > 1;

        return (
          <Layout
            name={field.name}
            label={!hideLabelName && label}
            required={required}
            textValue={getTextValue(value)}
            input={
              shouldFormatAsText ? (
                <>{renderAsText(value)} </>
              ) : (
                <DndContext
                  onDragStart={({ active }) => {
                    if (active) {
                      setActiveId(active.id);
                    }
                  }}
                  onDragEnd={({ active, over }) => {
                    if (over && active.id !== over.id) {
                      onSortEnd(
                        {
                          oldIndex: getIndex(active.id),
                          newIndex: getIndex(over.id),
                        },
                        value,
                        field
                      );
                    }
                    setActiveId(null);
                  }}
                  onDragCancel={() => setActiveId(null)}
                >
                  <SortableContext items={value}>
                    <SelectRenderer
                      ref={elementRef}
                      maxMenuHeight={maxMenuHeight}
                      menuPlacement={shouldPlaceTop ? 'top' : 'bottom'}
                      className='form-react-select'
                      closeMenuOnSelect={isSolo}
                      blurInputOnSelect={isSolo}
                      isMulti={isMulti}
                      isClearable={isMulti || isSolo}
                      creatable={creatable}
                      isLoading={isLoading}
                      styles={styles}
                      placeholder={placeholder}
                      inputId={field.name}
                      name={field.name}
                      options={options}
                      value={value}
                      isOptionDisabled={(option) => (isSolo && isMulti) || isOptionDisabled(option)}
                      onChange={(selections, info) => onSelectionsChange(selections, field, info)}
                      onCreateOption={(input) => onCreateOption(input, field)}
                      formatCreateLabel={formatCreateLabel}
                      getOptionValue={getOptionValue}
                      getOptionLabel={getOptionLabel}
                      onMenuOpen={onMenuOpen}
                      onMenuScrollToBottom={onMenuScrollToBottom}
                      onInputChange={onInputChange}
                      filterOption={filterOption}
                      isDisabled={disabled}
                      onClick={onClick}
                      useDragHandle={isMulti}
                      onSortEnd={({ oldIndex, newIndex }) =>
                        onSortEnd({ oldIndex, newIndex }, value, field)
                      }
                      components={{
                        MultiValue: SortableMultiValue,
                        MultiValueLabel: SortableMultiValueLabel,
                      }}
                      {...rendererExtraProps}
                    />
                  </SortableContext>
                </DndContext>
              )
            }
            labelWidth={labelWidth}
            controlWidth={controlWidth}
            error={fieldState.error?.message}
          />
        );
      }}
    />
  );
};

export default FormMultiSelect;
