import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import type { RuleGroupType, RuleType } from 'react-querybuilder';
import { QueryBuilder, defaultOperators } from 'react-querybuilder';
import 'react-querybuilder/dist/query-builder.scss';
import './styles.scss';
import 'bootstrap/scss/bootstrap.scss';
import { CustomFieldSelector, CustomOperatorSelector, CustomValueEditor } from './CustomValueEditor';
import { ColorPicker } from '../../../shared/color-picker';

type HighlightRuleGroup = {
  highlightColor: { r: number; g: number; b: number; a: number };
  highlightTextColor?: { r: number; g: number; b: number; a: number };
  enabled: boolean;
  rules: RuleGroupType;
  name: string;
};


interface ReportQueryBuilderModalProps {
  open: boolean;
  filterRules: RuleGroupType;
  fields: any[];
  setFilterRules?: (value: RuleGroupType) => void;
  onClose: () => void;
  isHighlightRule?: boolean;
  highlightRuleName?: string;
  highlightRuleColor?: { r: number; g: number; b: number; a: number };
  highlightRuleTextColor?: { r: number; g: number; b: number; a: number };
  setHighlightRule?: (value: HighlightRuleGroup) => void;
  deleteHighlightRule?: () => void;
}

const controlClassnames = {
  addRule: 'btn btn-primary btn-sm shadow-none',
  addGroup: 'btn btn-secondary btn-sm shadow-none',
  removeRule: 'btn btn-danger btn-sm shadow-none',
  removeGroup: 'btn btn-danger btn-sm shadow-none',
  combinators: 'form-select form-select-sm shadow-none',
  fields: 'form-select form-select-sm shadow-none',
  operators: 'form-select form-select-sm shadow-none',
  value: 'form-control form-control-sm shadow-none',
};

const ReportQueryBuilderModal: React.FC<ReportQueryBuilderModalProps> = ({
  open,
  onClose,
  fields,
  setFilterRules = () => void 0,
  filterRules,
  isHighlightRule,
  highlightRuleName,
  highlightRuleColor,
  highlightRuleTextColor,
  setHighlightRule = () => void 0,
  deleteHighlightRule = () => void 0,
}) => {

  const validator = (r: RuleType) => !!r.value;

  const fieldArray = fields
    .map((field) => {
      if (field.type.includes('role')) return null;
      else if (field.type === 'date')
        return {
          name: field.value,
          label: field.label,
          datatype: 'string',
          inputType: 'dateSelect',
          operators: defaultOperators.filter((op) =>
            ['<', '<=', '>', '>=', '=', '!=', 'null', 'notNull'].includes(op.name)
          ),
        };
      else if (field.type === 'number')
        return {
          name: field.value,
          label: field.label,
          inputType: 'number',
          datatype: field.type,
          operators: defaultOperators.filter((op) =>
            ['<', '<=', '>', '>=', '=', '!=', 'null', 'notNull', 'between', 'notBetween'].includes(op.name)
          ),
          validator,
        };
      else if (field.type === 'string' && field.options?.length == 0)
        return {
          name: field.value,
          label: field.label,
          datatype: field.type,
          operators: defaultOperators.filter((op) =>
            ['contains', 'doesNotContain', '=', '!=', '>', '<', 'null', 'notNull'].includes(op.name)
          ),
          validator,
        };
      else if (field.type === 'string' && field.options?.length > 0)
        return {
          name: field.value,
          label: field.label,
          datatype: field.type,
          inputType: 'select',
          options: field.options,
          operators: defaultOperators.filter((op) =>
            ['contains', 'doesNotContain','=','!=', 'in', 'notIn', 'null', 'notNull'].includes(op.name)
          ),
          validator,
        };
      else if (field.type === 'array')
        return {
          name: field.value,
          label: field.label,
          valueEditorType: 'multiselect',
          datatype: field.type,
          options: field.options,
          operators: defaultOperators.filter((op) =>
            ['contains', 'doesNotContain', 'in', 'notIn', 'null', 'notNull'].includes(op.name)
          ),
        };
      else if (field.type === 'boolean')
        return {
          name: field.value,
          label: field.label,
          valueEditorType: 'checkbox',
          operators: defaultOperators.filter((op) => ['=', '!='].includes(op.name)),
          defaultValue: false,
        };
    })
    .filter(Boolean); //.map(toFullOption);

  const [query, setQuery] = useState(filterRules ?? { combinator: 'and', rules: [] });
  const [currentHighlightRuleName, setCurrentHighlightRuleName] = useState<string>(
    (highlightRuleName ?? '') as string
  );
  const [currentHighlightRuleColor, setCurrentHighlightRuleColor] = useState(
    highlightRuleColor ?? { r: 0, g: 0, b: 0, a: 1 }
  );
  const [currentHighlightRuleTextColor, setCurrentHighlightRuleTextColor] = useState(
    highlightRuleTextColor ?? { r: 0, g: 0, b: 0, a: 1 }
  );

  useEffect(() => {
    setQuery(filterRules);
  }, [filterRules]);

  useEffect(() => {
    setCurrentHighlightRuleName(highlightRuleName ?? '');
    setCurrentHighlightRuleColor(highlightRuleColor ?? { r: 0, g: 0, b: 0, a: 1 });
    setCurrentHighlightRuleTextColor(highlightRuleTextColor ?? { r: 0, g: 0, b: 0, a: 1 });
  }, [highlightRuleName, highlightRuleColor,highlightRuleTextColor]);


  const onDelete = () => {
    if (isHighlightRule) {
      deleteHighlightRule();
    }
  
    onClose();
  };

  const onSave = () => {
    if (isHighlightRule) {
      setHighlightRule({
        name: currentHighlightRuleName,
        rules: query,
        highlightColor: currentHighlightRuleColor ?? { r: 0, g: 0, b: 0, a: 1 },
        highlightTextColor: currentHighlightRuleTextColor ?? { r: 0, g: 0, b: 0, a: 1 },
        enabled: true,
      });
    }
    else {
      setFilterRules(query);
    }

    onClose();
  };


  return (
    <Modal isOpen={open} toggle={onClose} backdrop='static' size='lg'>
      <ModalHeader>Report Query Builder</ModalHeader>
      <ModalBody>
        {//add fields for highlight rule name}
        }
        {isHighlightRule && (
          <>
            <div className='row'>
              <div className='col mb-3'>
                <label htmlFor='highlightRuleName' className='form-label'>
                  Highlight Rule Name
                </label>
                <input
                  type='text'
                  className='form-control'
                  id='highlightRuleName'
                  value={currentHighlightRuleName}
                  onChange={(e) => setCurrentHighlightRuleName(e.target.value)}
                />
              </div>
              <div className='col mb-3'>
                <label className='form-label'>Highlight Color</label>

                <ColorPicker
                  value={currentHighlightRuleColor}
                  onChange={setCurrentHighlightRuleColor}
                  name='highlightColor'
                  aria-label='Highlight Color'
                />
              </div>
              <div className='col mb-3'>
                <label className='form-label'>Text Color</label>

                <ColorPicker
                  value={currentHighlightRuleTextColor}
                  onChange={setCurrentHighlightRuleTextColor}
                  name='highlightTextColor'
                  aria-label='Highlight Text Color'
                />
              </div>
            </div>
          </>
        )}
        {
          //  <QueryBuilderBootstrap>
        }
        <div className='col'>
          {isHighlightRule && (
            <div className='d-flex gap-3 mt-3'>
              <label>Highlight Rules</label>
            </div>)}
          <QueryBuilder
            fields={fieldArray as any[]}
            query={query}
            onQueryChange={(q) => setQuery(q)}
            controlElements={{ valueEditor: CustomValueEditor, fieldSelector: CustomFieldSelector, operatorSelector:CustomOperatorSelector, addGroupAction: () => null }}
            controlClassnames={controlClassnames}
          />
        </div>
        {
          // </QueryBuilderBootstrap>
        }
      </ModalBody>
      <ModalFooter>
        <button className='btn btn-secondary' onClick={onClose}>
          Cancel
        </button>
        {isHighlightRule && (highlightRuleColor?.a!=0) && (
          <button className='btn btn-primary' onClick={onDelete}>
            Delete Rule
          </button>)}
        <button className='btn btn-primary' onClick={onSave}>
          Save
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default ReportQueryBuilderModal;
