import { useMemo, useState } from 'react';
import { Control, Controller, useFormContext } from 'react-hook-form';
import { useDisabledFieldsContext } from '../../disabled-fields';
import { DefaultLayout } from '../control-layouts/default-layout';
import { LayoutProps } from '../control-layouts/layout-props';
import { Input } from 'antd';
import DatePickerWrapper from '../../datepicker-wrapper/datepicker-wrapper';
import { formatDateObjectOrNull } from '../../../utils/date-formatters';

export type FormInputProps = {
  onKeyDown?: any;
  onPaste?: any;
  name: string;
  accept?: string;
  control?: Control<any>;
  label?: string;
  required?: boolean;
  placeholder?: string;
  disabled?: boolean;
  textArea?: boolean;
  type?: string;
  className?: string;
  inputText?: string | React.ReactNode | JSX.Element;
  Layout?: (props: LayoutProps) => JSX.Element;
  inputClassName?: string;
  CustomInput?: (...props: any) => JSX.Element;
  rows?: number;
  min?: number;
  validate?: (value: any, formValues: any) => unknown;
  labelWidth?: number;
  controlWidth?: number;
  initialValue?: number | string;
  prefix?: string | React.ReactNode | JSX.Element;
  urlLabel?: string;
  onChange?: (value: any) => void;
  autoSize?: boolean | { minRows: number; maxRows: number } | undefined;
  value?: any;
  autoComplete?: string;
};

const FormInput = ({
  name,
  control,
  label = '',
  required = false,
  placeholder = label,
  disabled = false,
  textArea = false,
  type = 'text',
  className = '',
  inputText,
  prefix,
  Layout = DefaultLayout,
  inputClassName = 'form-control',
  CustomInput,
  rows = 5,
  accept = '',
  min,
  labelWidth = 4,
  controlWidth = 8,
  onKeyDown,
  onPaste,
  initialValue,
  autoSize = false,
  onChange: propsOnChange = () => void 0,
  value = null,
  autoComplete="off"
}: FormInputProps) => {
  const forceDisabled = useDisabledFieldsContext();

  const formContext = useFormContext();

  if (!control && !formContext) {
    throw new Error('FormInput must be used within a FormContext or have a control prop');
  }

  // should be memoized
  const InputComponent = useMemo(
    () => (props) => {

      if (CustomInput) return <CustomInput {...props} />;

      if (textArea) return <Input.TextArea {...props} autoSize={autoSize} rows={rows} />;

      if (type === 'checkbox')  {
        return <input type={type} checked={props?.value ? true : false} {...props} />;
    }

    if(type === 'date'){
      return <DatePickerWrapper selected={props?.value?.length>0?formatDateObjectOrNull(props?.value):null} {...props} dateFormat='MM/dd/yyyy' />;
    }
    
      return <input type={type} min={min} {...props} autoComplete={props.autoComplete} />;
    },
    [textArea, rows]
  );

  const [lastInitialValue, setLastInitialValue] = useState(initialValue);


  const getValue = (field: any, property?: string) => {

    if (property) {
      if (typeof field.value === 'object' && field.value?.[property]) {
        return field.value[property];
      } else {
        return null;
      }
    }
    else if (typeof field.value === 'object' && field.value?.url) {
      field.value = field.value.url;
    }

    if (field.value === lastInitialValue && initialValue !== undefined && lastInitialValue != undefined && initialValue !== lastInitialValue) {
      field.onChange(initialValue);
      setLastInitialValue(initialValue);
      return initialValue;
    }
    

    // checkbox
    if (type === 'checkbox') {
      if (initialValue !== undefined && !isNaN(initialValue as any)) {
        field.onChange(initialValue);
        setLastInitialValue(initialValue);
        return initialValue;
      }
      else {

        return field.value;
      }
    }

    if (field.value === 0) {
      if (initialValue !== undefined && !isNaN(initialValue as any)) {
        field.onChange(initialValue);
        setLastInitialValue(initialValue);
        return initialValue;
      }
      else {
        return field.value;
      }
    } else if (field.value?.length === 0 && initialValue !== undefined && (initialValue as any)?.length > 0) {
      field.onChange(initialValue);
      setLastInitialValue(initialValue);
      return initialValue;
    }


    return field.value || '' || value;
  };

  const onChange = (field, e: any) => {
    if (typeof (e) === 'object' && e?.target) {
      // for checkboxes
      if (e?.target?.type && e.target.type === 'checkbox') {
        field.onChange(e.target.checked);
        propsOnChange(e.target.checked);
      } else {
        field.onChange(e.target.value);
        propsOnChange(e.target.value);
      }
    } else if(type==='date'){
      const newVal = e ? e.toISOString() : null;
      field.onChange(newVal);
      propsOnChange(newVal);
    } else {
      field.onChange(e);
      propsOnChange(e);
    }
  }

  return (
    <Controller
      rules={{ required: required && `${label} is required` }}
      name={name}
      control={control || formContext.control}
      render={({ field, fieldState }) => {
        return (
          <Layout
            name={field.name}
            label={label}
            inputText={inputText}
            prefix={prefix}
            urlLabel={getValue(field, 'label') || 'link'}
            textValue={getValue(field)}
            input={
              <InputComponent
                className={inputClassName}
                name={field.name}
                id={field.name}
                placeholder={placeholder}
                value={getValue(field)}
                onChange={onChange.bind(null, field)}
                disabled={disabled || forceDisabled}
                onKeyDown={onKeyDown}
                onPaste={onPaste}
                autoComplete={autoComplete}
                accept={accept}
              />
            }
            error={fieldState.error?.message}
            required={required}
            className={className}
            labelWidth={labelWidth}
            controlWidth={controlWidth}
          />
        );
      }}
    />
  );
};

export default FormInput;
