import { useCallback, useEffect, useMemo, useState } from 'react';
import { joiResolver } from '@hookform/resolvers/joi';
import { useDispatch } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { useSubmit } from '../../shared/hooks/use-submit';
import { createChangeOrderAsync, viewChangeOrdersAsync } from '../../modules/orders';
import { userRolesDropdown } from '../../modules/contacts';

import {
  ChangeOrderModalTypeSchema,
  changeOrderModalValidationSchema,
} from './change-order-modal.schema';
import { ChangeOrderFields } from './sections/change-order-fields';
import { ChangeOrderTable } from './sections/change-order-table';
import {
  formatDateUTC,
  formatDateObject,
  formatDateObjectOrNull,
} from '../../utils/date-formatters';
import { formatEstData } from '../../utils/number-formatters';
import styles from './change-order-modal.module.scss';
import _ from 'lodash';
import { useWithPermissions } from '../../shared/with-permissions';
import { appConstants, moduleConstants } from '../../_constants';
import { FillTemplateModal } from '../../components/Layout/TemplateFiller/FillTemplateModal';
import PropTypes from 'prop-types';

type ChangeOrderModalProps = {
  isOpen: boolean;
  toggle: () => void;
  onSubmit?: (...args) => Promise<any>;
  onSuggestProgress?: (data: any) => void;
  project: any;
  coId?: string;
};

export const ChangeOrderModal = ({
  isOpen = false,
  toggle,
  onSubmit: onSubmitCb = () => Promise.resolve(),
  onSuggestProgress = () => null,
  project = {},
  coId = '',
}: ChangeOrderModalProps) => {

  const defaultValues = {
    coNumber: null,
    coCostAmount: 0,
    profitDollars: 0,
    coTotalAmount: 0,
    comment: '',
    changeOrderReason: '',
    exclusions: '',
    effectiveDate: formatDateObject(),
    scheduleImpact: 0,
    wasAccepted: false,
    acceptedDate: null,
    acceptedBy: '',
    existingChangeOrders: [],
    scopeArr:[],
    estTotalCost: 0,
    estTotalProfit: 0,
    estTotalContract: 0,
    estData: [],
    createEstimate: false,
  };



  const form = useForm<ChangeOrderModalTypeSchema>({
    mode: 'onChange',
    defaultValues,
    resolver: joiResolver(changeOrderModalValidationSchema),
  });


  const [isViewChangeOrdersLoading, setIsViewChangeOrdersLoading] = useState<boolean>(true);
  const [changeOrders, setChangeOrders] = useState<Array<any>>([]);
  const [currentCO, setCurrentCO] = useState<any>({...defaultValues,coCostAmount: 0, profitDollars: 0, coTotalAmount: 0});
  const [isLocked, setIsLocked] = useState<boolean>(false);

  const PCOTitle = useMemo(
    () => `${currentCO.isPCO ? 'Potential ' : ''}Change Order #${currentCO.coNumber}`,
    [currentCO]
  );

  const isNew = !coId;
  const wasAccepted = form.watch('wasAccepted');
  const title = isNew ? 'Enter Change Order' : PCOTitle;
  const submitting = form.formState.isSubmitting;
  const allowedChangeApproved = useWithPermissions({ required: [moduleConstants.MANAGECONTRACT] });
 
  const dispatch = useDispatch();

  const [nextBestCO, setNextBestCO] = useState<number>(1);

  useEffect(() => {
    dispatch(userRolesDropdown());
  }, []);

  useEffect(() => {
    if (isOpen && project.objectId) {
      setIsLocked(!isNew && !(currentCO.isPCO));

      (async () => {
        const { payload = [] } = await dispatch((viewChangeOrdersAsync as any)(project.objectId));
        setChangeOrders(payload);

        const coNumbers = payload.map(({ coNumber }) => coNumber);
        form.setValue('existingChangeOrders', coNumbers);
        setIsViewChangeOrdersLoading(false);

        setNextBestCO(coNumbers.length ? Math.max(...coNumbers) + 1 : 1);

        if(coId){

          const existingCO = payload.find((co: any) => co.objectId === coId);

          setCurrentCO(existingCO || {});
        }else{
          setCurrentCO({...defaultValues,coCostAmount: 0, profitDollars: 0, coTotalAmount: 0});
        }

      })();
    } else {
      form.reset();
      setIsLocked(false);
    }
  }, [isOpen, project.objectId, coId, isNew,currentCO.isPCO]);

  

  useEffect(() => {
    if (_.isEmpty(currentCO)) {
      form.setValue('effectiveDate', formatDateObject());
      form.setValue('existingChangeOrders', changeOrders.map(({ coNumber }) => coNumber));
    } else {
      form.reset(
        {
          effectiveDate: formatDateObject(currentCO.effectiveDate),
          existingChangeOrders: changeOrders
            .filter((co) => co.objectId !== currentCO.objectId)
            .map(({ coNumber }) => coNumber),
          coNumber: currentCO.coNumber??nextBestCO,
          comment: currentCO.comment,
          exclusions: currentCO.exclusions,
          changeOrderReason: currentCO.reasonCodeId ?? currentCO.reasonCode?.objectId ,
          coCostAmount: currentCO.coCostAmount,
          //profitPercentage: +currentCO.profitPercentage.toFixed(2),
          profitDollars: currentCO.profitDollars,
          coTotalAmount: currentCO.coTotalAmount,
          estTotalContract: currentCO.coTotalAmount,
          estTotalCost: currentCO.coCostAmount,
          estTotalProfit: currentCO.profitDollars,
          estData: formatEstData(currentCO.estData,true),
          scheduleImpact: currentCO.scheduleImpact,
          wasAccepted: currentCO.wasAccepted,
          acceptedDate: formatDateObjectOrNull(currentCO.acceptedDate),
          acceptedBy: currentCO.acceptedBy || '',
          scopeArr: currentCO.scopeArr??[],
          createEstimate: false,
        },
        { keepDefaultValues: true }
      );
    }
  }, [currentCO, changeOrders]);

  const formatPostData = (data: any, isPCO: boolean, notApproved: boolean) => {
   // const profitDollars = currencyToNumber(data.profitDollars || 0);
    //const coCostAmount = currencyToNumber(data.coCostAmount || 0);
    //const coTotalAmount = currencyToNumber(data.coTotalAmount || 0);

    return {
      objectId: currentCO.objectId,
      projectId: project.objectId,
      reasonCode: data.changeOrderReason,
      exclusions: data.exclusions,
      coNumber: +(data.coNumber || 0),
      coCostAmount: data.estTotalCost,
      coTotalAmount: data.estTotalContract,
      //profitPercentage: +(data.profitPercentage || 0),
      profitDollars: data.estTotalProfit,
      projectStatus: {
        ObjectId: project.jobStatusCodes?.objectId || project.jobStatusCodes,
        jobStatusCodesCode: project.jobStatusCodesCode,
        jobStatusCodesName: project.jobStatusCodesName,
      },
      estData: formatEstData(data.estData,false),
      currentContractAmount: project.currentContractAmount + data.estTotalContract,
      currentGrossProfit: project.currentGrossProfit + data.estTotalProfit,
      comment: data.comment,
      effectiveDate: data.effectiveDate,
      acceptedBy: data.acceptedBy || '',
      wasAccepted: data.wasAccepted,
      acceptedDate: data.acceptedDate,
      scheduleImpact: data.scheduleImpact,
      createEstimate: data.createEstimate,
      trash: project.trash,
      changeOrderDate: formatDateUTC(),
      isPCO,
      notApproved,
    };
  };

  const processNewCOChanges = useCallback((data: any) => {
    const { scheduleImpact, coTotalAmount, profitDollars } = data;
    const isTotalChanged = coTotalAmount !== 0;
    const isScheduleChanged = scheduleImpact !== 0;
    const isProfitChanged = profitDollars !== 0;

    if (isScheduleChanged ||  isTotalChanged || isProfitChanged) {
      return { isCO: true, scheduleImpact, profitDollars, coTotalAmount, projectId: data.projectId};
    }

    return null;
  }, []);

  const processEditCOChanges = useCallback((data: any) => {
    const { scheduleImpact: newScheduleImpact, coTotalAmount: newCoTotalAmount, profitDollars: newProfitDollars } = data;
    const { scheduleImpact: prevScheduleImpact, coTotalAmount: prevCoTotalAmount, estTotalProfit: prevProfitDollars } = currentCO;

    const scheduleImpact = newScheduleImpact - prevScheduleImpact;
    const coTotalAmount = newCoTotalAmount - prevCoTotalAmount;
    const profitDollars = newProfitDollars - prevProfitDollars;

    const isTotalChanged = coTotalAmount !== 0;
    const isScheduleChanged = scheduleImpact !== 0;

    if (isScheduleChanged ||  isTotalChanged) {
      return { isCO: true, scheduleImpact, profitDollars, coTotalAmount, projectId: data.projectId};
    }

    return null;
  }, [currentCO]);

  const suggestUpdateProgress = useCallback(async (data: any) => {
    // if this is a new approved CO or we are approving PCO
    const changes = (isNew || currentCO.isPCO)
      ? processNewCOChanges(data)
      : processEditCOChanges(data);

    changes && onSuggestProgress && onSuggestProgress(changes);
  }, [isNew, currentCO]);

  const submitCO = async (isPCO: boolean, notApproved: boolean) => {
    let data: any = null;
    await form.handleSubmit(async (result) => {
      data = result;
    }, console.log)();

    if (!data) {
      throw new Error('Invalid form data');
    }

    const postData = formatPostData(data, isPCO, notApproved);

    await dispatch((createChangeOrderAsync as any)(postData)).unwrap();

    form.reset({ ...form.formState.defaultValues });

    !isPCO && !notApproved && suggestUpdateProgress(postData);
    onSubmitCb();
    toggle();
  };

  const [onSaveCO] = useSubmit(
    async () => await submitCO(currentCO.isPCO, currentCO.notApproved),
    [project, currentCO]
  );

  const [onApproveCO] = useSubmit(async () => await submitCO(false, false), [project, currentCO]);

  const [onSubmitAsPCO] = useSubmit(async () => await submitCO(true, false), [project, currentCO]);

  const [onDeclinePCO] = useSubmit(async () => await submitCO(true, true), [project, currentCO]);

  const mainAction = useMemo(() => {
    let label = 'Save';
    let action = onSaveCO;

    if (isNew) {
      label = `Save ${wasAccepted ? 'Approved' : 'Potential'} C/O`;
      action = wasAccepted ? onApproveCO : onSubmitAsPCO;
    } else {
      if (wasAccepted) {
        label = 'Save Approved C/O';
        action = onApproveCO;
      }
    }

    return (
      <button className='btn btn-primary' disabled={submitting} onClick={action}>
        {submitting ? 'Saving...' : label}
      </button>
    );
  }, [isNew, wasAccepted, submitting, currentCO]);

  const menuAction = useMemo(() => {
    if (isNew || wasAccepted) return null;

    const { notApproved } = currentCO;

    const label = notApproved ? 'Mark Potential' : 'Mark Declined';
    const action = notApproved ? onSubmitAsPCO : onDeclinePCO;

    return (
      <>
        <button
          type='button'
          className='btn btn-secondary dropdown-toggle dropdown-toggle-split'
          data-bs-toggle='dropdown'
          aria-expanded='false'
        >
          <span className='visually-hidden'>Toggle Dropdown</span>
        </button>
        <ul className='dropdown-menu ml-auto'>
          <li className='dropdown-item'>
            <a className='statusBtn' onClick={action}>
              {label}
            </a>
          </li>
        </ul>
      </>
    );
  }, [currentCO, wasAccepted, isNew]);

  const [detailedMode, setDetailedMode] = useState(true);

  const [fillTemplateOpen, setFillTemplateOpen] = useState(false);
  const toggleFillTemplate = () => {
    setFillTemplateOpen(!fillTemplateOpen);
  }

  const ModalHeader = ({ toggleFn, toggleFillTemplate }) => {
    return (
      <div className="modal-header">
        <h5 className="modal-title">
          {title}
        </h5>
        <div className="ms-auto align-right">
         {!(appConstants.IS_PRODUCTION)&&(<span
            onClick={toggleFillTemplate}
            //variant="outline"
            className="ms-auto px-2 py-1"
          >
            <i className='fa fa-file-text-o'/>
          </span>)}
          <button className="btn-close" aria-label="Close" onClick={toggleFn} />
        </div>
      </div>
    );
  };
  
  ModalHeader.propTypes = {
    toggleFn: PropTypes.func.isRequired,
    toggleFillTemplate: PropTypes.func.isRequired,
  };


  return (
    <>
    <Modal className={styles.coForm} backdrop='static' isOpen={isOpen} toggle={toggle} size={detailedMode ? 'xl' : 'lg'}>
    { ModalHeader({ toggleFn: toggle, toggleFillTemplate }) }
    <ModalBody style={{ padding: '16px 30px' }}>
        <FormProvider {...form}>
          <ChangeOrderFields isDisabled={isLocked} project={project} currentCO={currentCO} setDetailedModeCallback = {setDetailedMode} />
          
          <ChangeOrderTable
            project={project}
            changeOrders={changeOrders}
            isLoading={isViewChangeOrdersLoading}
            coId={coId}
          />
        </FormProvider>
      </ModalBody>
      <ModalFooter>
        <button type='button' className='btn btn-primary' onClick={toggle}>
          {isLocked ? 'Close' : 'Cancel'}
        </button>
        {!isLocked && (
          <div className='btn-group'>
            {mainAction}
            {menuAction}
          </div>
        )}
        {isLocked && allowedChangeApproved && (
          <button type='button' className='btn btn-primary' onClick={() => setIsLocked(false)}>
            Unlock & Edit
          </button>
        )}
      </ModalFooter>
    </Modal>
     {fillTemplateOpen && currentCO.objectId && (
      <FillTemplateModal
        open={fillTemplateOpen}
        toggle={() => toggleFillTemplate()}
        objectId={currentCO.objectId}
        formType={'changeOrder'}
      ></FillTemplateModal>
    )}
    </>
  );
};