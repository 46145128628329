import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { appConstants } from '../../../_constants';
import {
  createNotificationTypeAction,
  disableNotificationTypesAction,
  getAllNotificationTypesAction,
} from '../../../actions/Admin/notificationType.actions.js';
import CustomTable from '../../Common/CustomTable';
import ReactLoading from 'react-loading';
import loaderImage from '../../static/images/loading_spinner.gif';
import TitleComponent from '../../Common/TitleComponent';
import { TextEditorField } from '../../Common/TextEditorField';
import { Checkbox, notification } from 'antd';
import { callAlertMessage } from '../../../components/Auth/Auth';

// eslint-disable-next-line no-undef
let Validator = require('validatorjs');
let styleData = { color: 'red' };
let formArr = {};
let rules = {
  notificationName: 'required',
  orderId: 'required|numeric',
  notificationDescription: 'required',
  moduleCode: 'required',
};
let mess = {
  required: 'This field is required',
  alpha: 'This field may only contain letters',
  numeric: 'This field must be a number',
};
let validation = [];
validation = new Validator([], rules, appConstants.RULESMESSAGE);

validation.passes();
validation.fails();

class NotificationTypesList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectBox: {},
      sessionToken: localStorage.getItem('sessionToken'),
      userId: localStorage.getItem('userId'),
      list: [],
      formArr: [],
      formData: {},
      modal: false,
      showProcessing: false,
      totalCount: 0,
      currentPage: 1,
      selectedRows: [],
    };
    this.toggle = this.toggle.bind(this);
    this.getNotificationTypeList = this.getNotificationTypeList.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.handleRowSelection = this.handleRowSelection.bind(this);
  }

  componentDidMount() {
    this.getNotificationTypeList();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.isNotificationTypeListSuccess !== this.props.isNotificationTypeListSuccess) {
      if (
        nextProps.isNotificationTypeList &&
        nextProps.isNotificationTypeList.data &&
        nextProps.isNotificationTypeList.data.length > 0
      ) {
        this.setState({ list: nextProps.isNotificationTypeList.data, fetchRequest: true });
      }
    }
    //else{
    //    this.setState({list:[]});
    // }

    if (
      nextProps.isNotificationTypeAddSuccess !== this.props.isNotificationTypeAddSuccess &&
      nextProps.isNotificationTypeAdd.success === 1
    ) {
      this.setState({
        formData: {},
        selectBox: {},
        formArr: [],
        showProcessing: false,
        modal: false,
      });
      callAlertMessage('success', nextProps.message);
      this.getNotificationTypeList();
    }

    if (
      nextProps.isNotificationTypeAddSuccess !== this.props.isNotificationTypeAddSuccess &&
      nextProps.isNotificationTypeAdd.success === 0
    ) {
      callAlertMessage('error', nextProps.message);
      this.setState({ showProcessing: false });
    }

    if (
      nextProps.isNotificationTypeDisableSuccess !== this.props.isNotificationTypeDisableSuccess &&
      nextProps.isNotificationTypeDisable.success === 1
    ) {
      this.setState({ showProcessing: false });
      callAlertMessage('success', nextProps.message);
      this.getNotificationTypeList();
    }

    if (
      nextProps.isNotificationTypeDisableSuccess !== this.props.isNotificationTypeDisableSuccess &&
      nextProps.isNotificationTypeDisable.success === 0
    ) {
      callAlertMessage('error', nextProps.message);
      this.setState({ showProcessing: false });
    }
  }

  toggle(val) {
    if (val === 'POST') {
      this.setState({ formData: {} }); //Open modal htmlFor add
    } else {
      this.setState({ saveType: 'PUT' });
      this.setState({ formData: this.state.formData }); //Open modal htmlFor edit
    }
    this.setState({ modal: !this.state.modal, formArr: [] });
  }

  cancelFun(e) {
    e.preventDefault();
    this.setState({ modal: !this.state.modal, formArr: [] });
    this.getNotificationTypeList();
  }

  getNotificationTypeList() {
    let notificationTypeRequest = {};
    notificationTypeRequest['type'] = 'SUPERADMIN';
    notificationTypeRequest['adminId'] = this.state.userId;
    notificationTypeRequest['page'] = 0;
    notificationTypeRequest['displayLimit'] = this.state.sizePerPage;
    this.props.getAllNotificationTypesAction(notificationTypeRequest);
  }

  addNotificationTypes(evt) {
    evt.preventDefault();
    let { formData } = this.state;

    if (this.allValidate(false)) {
      formData['adminId'] = this.state.userId;
      formData['type'] = 'SUPERADMIN';
      formData['orderId'] = formData.orderId ? Number(formData.orderId) : '';
      formData['notificationName'] = formData.notificationName;
      formData['notificationCode'] = formData.notificationCode;
      formData['notificationDescription'] = formData.notificationDescription;
      formData['moduleCode'] = formData.moduleCode;
      formData['trash'] = formData.trash ?? false;
      formData['settings'] = formData.settings ?? {};
      this.props.createNotificationTypeAction(formData);
      this.setState({ showProcessing: true });
    }
  }

  allValidate(check) {
    if (!check) {
      formArr = [];
      Object.keys(rules).forEach(function (key) {
        formArr[key] = 'TT';
      });
      this.setState({
        formArr,
      });
    }
    if (validation.passes()) {
      return 1;
    }
  }

  handleChange = (e) => {
    e.preventDefault();
    let field = this.state.formData;
    field[e.target.name] = e.target.value;
    this.setState({ formData: field });
  };

  handleChangeSelectBox = (name, value) => {
    let formData = this.state.formData;
    let selectBox = this.state.selectBox;
    selectBox[name] = value;
    formData[name] = value.value;
    this.setState({ formData, selectBox });
  };

  handleDisableButtonClick = () => {
    let data = {};
    const { selectedRows, list } = this.state;
    data['adminId'] = this.state.userId;
    data['dataArr'] = list
      .filter((f, index) => selectedRows.includes(index.toString()))
      .map((k) => ({
        objectId: k.objectId,
      }));
    const confirm = window.confirm('Are you sure to disable?');
    if (confirm) {
      this.props.disableNotificationTypesAction(data);
      this.setState({ submit: true, isNotificationTypesCreated: false });
    } else {
      console.log({ confirm });
    }
  };

  handleRowSelection = (ids) => {
    this.setState({ selectedRows: ids });
  };

  _setTableOption() {
    if (this.state.fetchRequest) {
      return 'No data found..';
    } else {
      return (
        <ReactLoading
          className='table-loader'
          type={appConstants.LOADER_TYPE}
          color={appConstants.LOADER_COLOR}
          height={appConstants.LOADER_HEIGHT}
          width={appConstants.LOADER_WIDTH}
        />
      );
    }
  }

  getTimeLine(cell, row, enumObject, rowIndex) {
    return row.notificationTypeLimit + ' ' + row.notificationTypeLimitType;
  }

  selectedRow1 = (row) => {
    this.toggle('PUT');
    let selectBox = {};
    selectBox['notificationTypeLimitType'] = {
      label: row.notificationTypeLimitType,
      value: row.notificationTypeLimitType,
    };
    this.setState({ formData: row, selectBox });
  };

  onPageChange(page, sizePerPage) {
    let formData = this.state.formData;
    let data = {};
    data['adminId'] = this.state.userId;
    data['type'] = 'SUPERADMIN';
    data['page'] = page === -1 ? 0 : page - 1;
    data['displayLimit'] = sizePerPage;
    data['search'] = this.state.searchText;
    this.setState({ sizePerPage: sizePerPage });
    this.setState({ currentPage: page });
    this.props.getAllNotificationTypesAction(data);
  }

  showTrueFalseTrash(row, name) {
    return (
      <div style={{ textAlign: 'center' }}>
        {row[name] === true ? (
          <i className='fa fa-check fa-lg' style={{ color: 'green' }} aria-hidden='true'></i>
        ) : (
          <i className='fa fa-trash fa-lg' style={{ color: 'red' }} aria-hidden='true'></i>
        )}
      </div>
    );
  }

  handleChange1(name, value) {
    let field = this.state.formData;
    if (value?.target) {
      field[name] = value.target.checked;
      this.setState({
        formData: field,
        [name]: value.target.checked,
      });
    } else {
      field[name] = value;
      this.setState({
        formData: field,
        [name]: value,
      });
    }
  }

  render() {
    validation = new Validator(this.state.formData, rules, mess);
    validation.passes();
    validation.fails();

    

    const selectRowProp = { mode: 'checkbox' };
    // let tableOption = appConstants.TableOption;
    // tableOption['afterDisableRow']= this.handleDisableButtonClick;
    // tableOption['paginationShowsTotal']= this.renderShowsTotal;
    // tableOption['onRowClick']= this.selectedRow1;

    let tableOption = appConstants.TableOption;
    tableOption['afterDisableRow'] = this.handleDisableButtonClick;
    tableOption['paginationShowsTotal'] = this.renderShowsTotal;
    tableOption['onRowClick'] = this.selectedRow1;
    tableOption['noDataText'] = this._setTableOption();

    const columns = [
      {
        Header: 'Notification Name',
        accessor: 'notificationName',
        width: 150,
      },
      {
        Header: 'Notification Code',
        accessor: 'notificationCode',
        width: 100,
      },
      {
        Header: 'Order',
        accessor: 'orderId',
        width: 100,
      },
      {
        Header: 'Description',
        accessor: 'notificationDescription',
        width: 320,
        //Cell: (props) => this.getTimeLine(props.column.id, props.row.original, props.value)
      },
      {
        Header: 'moduleCode',
        accessor: 'moduleCode',
        width: 160,
      },
      {
        Header: 'moduleId',
        accessor: 'moduleId',
        width: 80,
      },
      {
        Header: 'Deleted',
        accessor: 'trash',
        width: 60,
        Cell: (props) => this.showTrueFalseTrash(props.row.original, 'trash'),
      },
    ];

    return (
      <div>
        <TitleComponent data={{ title: `Administration Notification Types` }} />
        <div
          className='noteProcessing'
          style={{ display: this.state.showProcessing ? 'block' : 'none' }}
        >
          <img src={loaderImage} alt='Loading' />
        </div>
        <div className='headingbox'>
          <h2 className='heading2'>Notification Type List</h2>
        </div>
        <div className='row'>
          <div className='col-md-12 sortable-table'>
            <button className='btn btnRight' onClick={() => this.toggle('POST')}>
              Add
            </button>
            <button
              className='btn btn-primary'
              style={{ cursor: 'pointer', padding: '5px' }}
              disabled={this.state.selectedRows?.length < 1}
              onClick={() => this.handleDisableButtonClick()}
            >
              <i className='fa fa-trash' aria-hidden='true'></i>&nbsp;Disable
            </button>
            <CustomTable
              columns={columns}
              list={this.state.list}
              canShowColumnSettings={false}
              canShowDateFilter={false}
              canShowExport={false}
              canShowPagination={false}
              onSavePreferences={() => {}}
              isLoading={!this.state.fetchRequest}
              canShowRowSelection={true}
              onRowClicked={(cell, row, e) => this.selectedRow1(row.original)}
              onChangeRowSelection={this.handleRowSelection}
            />
          </div>
        </div>

        <Modal
          backdrop='static'
          keyboard={true}
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={this.props.className}
          size='lg'
        >
          <ModalHeader toggle={this.toggle}>Add New Notification Type</ModalHeader>
          <ModalBody>
            <div className='container'>
              <div className='row'>
                <div className='col-md-6'>
                  <div className='form-group'>
                    <label htmlFor='firstName'>
                      Notification Type Name<span className='requiredField'>*</span>
                    </label>
                    <input
                      type='text'
                      className='form-control'
                      name='notificationName'
                      id='notificationName'
                      placeholder='Notification Type Name'
                      value={this.state.formData.notificationName}
                      onChange={(e) => {
                        this.handleChange(e);
                      }}
                    />
                    <span style={styleData} className='error'>
                      {this.state.formArr.notificationName && validation.errors.first('notificationName')}
                    </span>
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='form-group'>
                    <label htmlFor='firstName'>
                      Notification Type Code<span className='requiredField'>*</span>
                    </label>
                    <input
                      type='text'
                      className='form-control'
                      name='notificationCode'
                      id='notificationCode'
                      placeholder='Notification Type Code'
                      value={this.state.formData.notificationCode}
                      onChange={(e) => {
                        this.handleChange(e);
                      }}
                    />
                    <span style={styleData} className='error'>
                      {this.state.formArr.notificationCode && validation.errors.first('notificationCode')}
                    </span>
                  </div>
                </div>
              </div>

              <div className='row'>
                <div className='col-md-6'>
                  <div className='form-group'>
                    <label>
                      Module code<span className='requiredField'>*</span>
                    </label>
                    <input
                      type='text'
                      className='form-control'
                      name='moduleCode'
                      id='moduleCode'
                      placeholder='Module code'
                      value={this.state.formData?.moduleCode}
                      onChange={(e) => {
                        this.handleChange(e);
                      }}
                    />
                    <span style={styleData} className='error'>
                      {this.state.formArr.moduleCode &&
                        validation.errors.first('moduleCode')}
                    </span>
                  </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <label>
                        Module Id
                      </label>
                      <input
                        type='text'
                        className='form-control'
                        name='moduleId'
                        id='moduleId'
                        placeholder='Module Id'
                        value={this.state.formData?.moduleId}
                        disabled={true}
                      />
                     
                    </div>
                </div>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <label>
                        Order<span className='requiredField'>*</span>
                      </label>
                      <input
                        type='text'
                        className='form-control'
                        name='orderId'
                        id='orderId'
                        placeholder='Order'
                        value={this.state.formData.orderId}
                        onChange={(e) => {
                          this.handleChange(e);
                        }}
                      />
                      <span style={styleData} className='error'>
                        {this.state.formArr.orderId && validation.errors.first('orderId')}
                      </span>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <label>
                        Disabled<span className='requiredField'>*</span>
                      </label>
                      <Checkbox
                        checked={this.state.formData.trash == true}
                        onChange={this.handleChange1.bind(this, 'trash')}
                        className='form-input'
                      />
                    </div>
                  </div>
                </div>
                <div className='col-md-12'>
                  <div className='form-group'>
                    <label htmlFor='notificationDescription'>
                      Description<span className='requiredField'>*</span>
                    </label>
                    <div className='row'>
                      <div className='col-md-12'>
                        <div className='form-group'>
                          <TextEditorField
                            //title='Description'
                            name='notificationDescription'
                            value={this.state.formData?.notificationDescription}
                            onChange={(e) => this.handleChange(e)}
                          />
                        </div>
                      </div>
                    </div>
                    <span style={styleData} className='error'>
                      {this.state.formArr.notificationDescription &&
                        validation.errors.first('notificationDescription')}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button className='btn btn-primary' onClick={(e) => this.cancelFun(e)}>
              Cancel
            </button>
            <button className='btn btn-primary' onClick={(e) => this.addNotificationTypes(e)}>
              Submit
            </button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isNotificationTypeList: state.notificationTypes?.isNotificationTypeList,
    isNotificationTypeListSuccess: state.notificationTypes?.isNotificationTypeListSuccess,

    isNotificationTypeAdd: state.notificationTypes?.isNotificationTypeAdd,
    isNotificationTypeAddSuccess: state.notificationTypes?.isNotificationTypeAddSuccess,
    isNotificationTypeAddError: state.notificationTypes?.isNotificationTypeAddError,

    isNotificationTypeDisable: state.notificationTypes?.isNotificationTypeDisable,
    isNotificationTypeDisableSuccess: state.notificationTypes?.isNotificationTypeDisableSuccess,
    isNotificationTypeDisableError: state.notificationTypes?.isNotificationTypeDisableError,

    success: state.notificationTypes?.success,
    message: state.notificationTypes?.message,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    createNotificationTypeAction,
    disableNotificationTypesAction,
    getAllNotificationTypesAction,
  })(NotificationTypesList)
);
