import { PropsWithChildren, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import styles from './minimap.module.css';

type ScrollspyProps = {
  minimapWrapper: HTMLElement;
  heightCoef?: number;
  className?: string;
  minimapClassName?: string;
  initialSection?: string ;
};

export const Minimap = ({
  minimapWrapper,
  heightCoef = 0.4,
  className = '',
  minimapClassName = '',
  initialSection = '',
  children,
}: PropsWithChildren<ScrollspyProps>) => {
  const scrollContentRef = useRef<HTMLDivElement>(null);
  const visibleAreaRef = useRef<HTMLDivElement>(null);
  const minimapAreaRef = useRef<HTMLDivElement>(null);

  const [observer, setObserver] = useState<any>(null);

  useEffect(() => {
    const section = initialSection ? `#${initialSection}` : '';
    onScrollTo(section, 'auto');
  }, [initialSection]);

  useEffect(() => {
    if (minimapWrapper) {
      ReactDOM.render(renderMinimapElements(), minimapWrapper);
    }
  }, [children]);


  const [scrollHeight, setScrollHeight] = useState<number>(500);
  const [scrollOffsetHeight, setScrollOffsetHeight] = useState<number>(500);
  const [visibleAreaInPers, setVisibleAreaInPers] = useState<number>(100);
  const [miniMapHeight, setMiniMapHeight] = useState<number>(500);

  useEffect(() => {
   
    if (scrollContentRef.current) {
      updateMinimapHeights();
    }
  }, [scrollContentRef.current]);

  const updateMinimapHeights = () => {
    if (!scrollContentRef.current || !minimapAreaRef.current || !visibleAreaRef.current) {
      return;
    }

    if (scrollContentRef.current) {
      const height = scrollContentRef.current.scrollHeight;
      const offset = scrollContentRef.current.offsetHeight;
      if(height === scrollHeight && offset === scrollOffsetHeight){
        return;
      }
      setScrollHeight(height);
      setScrollOffsetHeight(offset);
      setVisibleAreaInPers(offset * 100 / height);
      
    }
  }

  useEffect(() => {
    if (!scrollContentRef.current || !minimapAreaRef.current || !visibleAreaRef.current) {
      return;
    }


    

    

    const arrEl = Array.from(scrollContentRef.current.children)
    let minimapHeightCalculated =0;
    arrEl.forEach((ch: any) => {
        if (minimapAreaRef.current) {
          const el: any = minimapAreaRef.current.querySelector(`[href='#${ch.getAttribute('id')}']`);
          if (el) {
            el.style.height = visibleAreaInPers * ch.offsetHeight / 100 * heightCoef + 'px';
            minimapHeightCalculated += visibleAreaInPers * ch.offsetHeight / 100 * heightCoef;
          }
        }
    });

    minimapAreaRef.current.style.height = minimapHeightCalculated + 'px';
    const mapPlaceHInPx = visibleAreaInPers * minimapHeightCalculated / 100;
    visibleAreaRef.current.style.height = mapPlaceHInPx + 'px';

    setMiniMapHeight(minimapHeightCalculated);
  }, [visibleAreaInPers,scrollOffsetHeight,scrollHeight]);

  useEffect(() => {
    if (observer) {
      observer.observe(scrollContentRef.current, { subtree: true, childList: true });
      return () => {
        if (observer) {
          observer.disconnect();
        }
      };
    }
  }, [observer, scrollContentRef.current]);

  useEffect(() => {
    if (scrollContentRef && scrollContentRef.current && minimapWrapper) {
      minimapWrapper.innerHTML = '';
      ReactDOM.render(renderMinimapElements(), minimapWrapper);

      const obs = new MutationObserver(observerCallback);
      setObserver(obs);

      scrollContentRef.current.addEventListener("scroll", (e) => onScroll(e));
    }
  }, [scrollContentRef, minimapWrapper]);

  const observerCallback = () => {
    if (scrollContentRef.current) {
      updateMinimapHeights();
    }
  }

  const onScrollTo = (section: string, behavior: ScrollBehavior) => {
    if (scrollContentRef.current) {
      if (section) {
        const targetSection = scrollContentRef.current.querySelector(`${section}`);
        if (targetSection) {
          targetSection.scrollIntoView({ behavior, inline: "nearest" });
        }
      } else {
        scrollContentRef.current.scrollTop = 0;
      }
    }
  };

  const onClick = (ev: any) => {
    const href = ev.currentTarget.getAttribute('href');
    onScrollTo(href, 'smooth');
  };

  const onScroll = (event) => {
    if (scrollContentRef.current && visibleAreaRef.current && minimapAreaRef.current) {
      const offsetTop = event.target.scrollTop * minimapAreaRef.current.scrollHeight/scrollContentRef.current.scrollHeight;
      visibleAreaRef.current.style.top = `${offsetTop}px`;
    }
  };

  const renderMinimapElements = (() => {
    if (!scrollContentRef.current || !minimapWrapper) {
      return <></>;
    }

    return (
      <div className={`${styles.minimap} ${minimapClassName}`} ref={minimapAreaRef} style={{height: `${miniMapHeight}px`}}>
        <span ref={visibleAreaRef} style={{height: `${visibleAreaInPers}%`}}></span>
        {Array.from(scrollContentRef.current.children).map((child: any) => {
          const areaLabel = child.dataset.minimapAreaLabel;
          const attrs = {
            href: `#${child.getAttribute('id')}`,
            style: {
              height: visibleAreaInPers * child.offsetHeight / 100 * heightCoef + 'px'
            }
          };

          return <div key={`#${child.getAttribute('id')}`} {...attrs} onClick={onClick}><span>{ areaLabel }</span></div>
        })}
      </div>
    )
  });

  return (
    <div ref={scrollContentRef} className={`${styles.scrollcontent} ${className}`}>{children}</div>
  );
};
