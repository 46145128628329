import moment from 'moment';
import { useFormContext } from 'react-hook-form';
import FormAvatar from '../../shared/form-controls/form-avatar';
import FormDatePicker from '../../shared/form-controls/form-date-picker';
import FormInput from '../../shared/form-controls/form-input';
import { ReactElement, useCallback, useRef, useState } from 'react';
import { Minimap } from '../../shared/minimap';
import { HorizontalLayout } from '../../shared/form-controls/control-layouts/horizontal-layout';

type UpdateContactFormProps = {
  companySlot: React.ReactNode;
  searchAffiliationSlot: React.ReactNode;
  personalSlot: React.ReactNode;
  isShowAddPersonalInfoButton: boolean;
  personalContactInfoToggle: () => void;
  CustomFields: (field: any, name: string, index: number, isAdditional: boolean) => null | ReactElement;
  initialSection?: string;
};

export const UpdateContactForm = ({
  personalSlot,
  searchAffiliationSlot,
  companySlot,
  isShowAddPersonalInfoButton,
  personalContactInfoToggle,
  CustomFields,
  initialSection = '',
}: UpdateContactFormProps) => {
  const { control } = useFormContext();
  const controlForm = useFormContext();
  const minimapRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const firstName = controlForm.watch('firstName');
  const middleName = controlForm.watch('middleName');
  const lastName = controlForm.watch('lastName');
  const prefix = controlForm.watch('prefix');
  const suffix = controlForm.watch('suffix');

  const [showExtendedPersonalInfo, setShowExtendedPersonalInfo] = useState(false);

  const dateOfBirthday = controlForm.watch('dateOfBirthday');

  const fullName = [prefix, firstName, middleName, lastName, suffix?(', '+suffix):'', '\u200b'].filter(Boolean).join(' ');

  const customFieldsFilter = (stage: string) => (field: any) => field.stages.includes(stage);

  const renderCustomFieldsByStage = useCallback(
    (stage: string) => CustomFields(customFieldsFilter(stage), 'affiliations', 0, true),
    [CustomFields]
  );

  const customFields = renderCustomFieldsByStage('contact');

  return (
    <div className='container'>
      <div className='row'>

        <div className='col-md-3 col-sm-2 d-none d-md-block' ref={minimapRef}>
          <div>
            <div></div>
          </div>
        </div>

        <div className='col-md-9 col-sm-11 col-xs-12'>
          <Minimap
            className='pe-md-4'
            minimapWrapper={minimapRef.current}
            initialSection={initialSection}
          >
            <div id='contact-information' data-minimap-area-label='Contact Information'>
              <div className='row mb-3'>
                <div className='col-md-4 mb-2'>
                  <FormAvatar
                    name='userImage'
                    control={control}
                    onFileSelect={(file) => controlForm.setValue('pictureFile', file)}
                    onFileRemove={() => {
                      controlForm.setValue('userImage', null);
                      controlForm.setValue('pictureFile', null);
                    }}
                  />
                </div>
                <div className='col-md-7 fs-5 d-flex flex-column gap-4 align-items-start'>
                  <span className='fw-bolder fs-4'>{fullName}</span>
                  <div className='col-md-9 fs-6 gap-1 d-flex'>
                    <span></span>
                  </div>
                </div>
              </div>
              {(showExtendedPersonalInfo || prefix) &&
                <FormInput
                  label='Prefix'
                  name='prefix'
                  control={control}
                  labelWidth={3}
                  Layout={HorizontalLayout}
                />
              }
              <FormInput
                label='First Name'
                name='firstName'
                control={control}
                labelWidth={3}
                Layout={HorizontalLayout}
              />
              <FormInput
                label='Middle Name'
                name='middleName'
                control={control}
                labelWidth={3}
                Layout={HorizontalLayout}
              />
              <FormInput
                label='Last Name'
                name='lastName'
                control={control}
                labelWidth={3}
                Layout={HorizontalLayout}
              />
              {(showExtendedPersonalInfo || suffix) &&
                <FormInput
                  label='Suffix'
                  name='suffix'
                  control={control}
                  labelWidth={3}
                  Layout={HorizontalLayout}
                />
              }
              {(showExtendedPersonalInfo || dateOfBirthday) &&
                <div className='mt-2'>
                  <FormDatePicker
                    name='dateOfBirthday'
                    label='Birthday'
                    showMonthDropdown
                    showYearDropdown
                    control={control}
                    maxDate={moment()}
                    labelWidth={3}
                    Layout={HorizontalLayout}
                  />
                </div>
              }
              {!showExtendedPersonalInfo ?
                <div className='mb-2'><a onClick={() => setShowExtendedPersonalInfo(true)}>Show extended personal information</a></div>
                :
                <div className='mb-2'><a onClick={() => setShowExtendedPersonalInfo(false)}>Hide extended personal information</a></div>
              }
              {isShowAddPersonalInfoButton
                ? <a onClick={() => personalContactInfoToggle()}>Add personal contact information</a>
                : <>
                  {personalSlot}
                </>
              }
            </div>

            {!!customFields && (
              <div id='additional-information' data-minimap-area-label='Additional Information'>
                <hr />
                <>{customFields}</>
              </div>
            )}

            <>{companySlot}</>

            <div id='contact-comments' data-minimap-area-label='Comments' className='pb-5'>
              <hr />
              <>{searchAffiliationSlot}</>
              <hr />
              <FormInput
                label='Comments'
                name='comments'
                textArea={true}
                control={control}
                placeholder='Comments'
                labelWidth={3}
                Layout={HorizontalLayout}
              />
            </div>
          </Minimap>
        </div>
      </div>
    </div>
  );
};
