import { useState, useEffect, useMemo, useCallback } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { appConstants, moduleConstants, isAllowed } from '../../../_constants';
import { ProjectCustomFieldsView } from '../../../pages/project-detail-page/custom-fields/custom-fields-view';
import GoogleMapReact from 'google-map-react';
import { getProjectCustomFieldsValuesForm } from '../../../modules/projects';
import { useAppDispatch } from '../../../shared/hooks/use-app-dispatch';
import { getProjectStageByCode } from '../../../shared/custom-fields-controls/constants';

function SiteInfo({ projectData, onOpenEditModal, isHideFields = false }) {
  const [projectId, setProjectId] = useState('');

  const [state] = useState({
    userId: localStorage.getItem('userId'),
    mapData: {
      center: [34.052235, -118.243683],
      zoom: 18,
    },
  });


  const [streetViewLoaded, setStreetViewLoaded] = useState(false);
  const [panorama, setPanorama] = useState(null);

  const [mapApiLoaded, setMapApiLoaded] = useState(false);
  const [mapInstance, setMapInstance] = useState(null);
  const [mapApi, setMapApi] = useState(null);

  const apiHasLoaded = (map, maps) => {
    setMapInstance(map);
    setMapApi(maps);
    setMapApiLoaded(true);
  };



  const dispatch = useAppDispatch();

  const [customFields, setCustomFields] = useState([]);

  useEffect(() => {
    if (projectData?.lat && projectData?.lng && mapApiLoaded)
      renderMarkers();

  }, [mapApiLoaded]);

  useEffect(() => {

    if (projectData) {

      dispatch(getProjectCustomFieldsValuesForm({ projectId }))
        .unwrap()
        .then((data) => {
          setCustomFields(data);
        });
    }

  }, [projectId]);

  useEffect(() => {
    if (projectData?.objectId) {
      setProjectId(projectData.objectId);
    }
    else if (projectData?.projectId?.objectId) {
      setProjectId(projectData.projectId.objectId);
    }

  }, [projectData?.objectId]);


  const [mapMarkers, setMapMarkers] = useState([]);

  const loadStreetView = () => {
    if (window.google && window.google.maps) {
      const { lat, lng } = projectData;
      const streetViewService = new window.google.maps.StreetViewService();
    
    streetViewService.getPanorama({ location: { lat, lng }, radius: 50, source: 'outdoor' }, (data, status) => {
      if (status === 'OK') {
        const panorama = new window.google.maps.StreetViewPanorama(
          document.getElementById('street-view'),
          {
            position: data.location.latLng,
            pov: { heading: 165, pitch: 0 },
            zoom: 1,
            visible: true
          }
        );

        const heading = window.google.maps.geometry.spherical.computeHeading(
          data.location.latLng,
          new window.google.maps.LatLng(lat, lng)
        );

        panorama.setPov({ heading: heading, pitch: 0 });

        setPanorama(panorama);
        setStreetViewLoaded(true);
      } else {
        console.error('Street View data not found for this location.');
        // Handle the error case, maybe show a message to the user or fall back to a default view
      }
    });
  }
};

  const [showMap, setShowMap] = useState(true);

  useEffect(() => {
    if (projectData?.lat && projectData?.lng && !streetViewLoaded && !showMap) {
      loadStreetView();
    }else if(showMap){
      setStreetViewLoaded(false);
    }
  }, [projectData, streetViewLoaded, showMap]);


  const renderMarkers = () => {
    if (projectData && mapInstance && mapApiLoaded && mapApi) {
      const { lat, lng, jobName } = projectData;

      mapData['center'] = [lat ? lat : 0.0, lng ? lng : -0.0];

      const markerText = lat && lng ? jobName : 'NA';


      let marker = new mapApi.Marker({
        position: {lat, lng},
        map:mapInstance,
        //icon:image,
        title: markerText,
      });

      setMapMarkers([marker]);
    }
  };

  const Marker = () => {
    return <><div className="mapMarker">{projectData?.jobName}</div>
    </>
  }

  const formatAddress = (projectData) => {
    return [
      projectData?.jobAddress,
      projectData?.jobAddress2,
      projectData?.jobCity,
      projectData?.jobState,
      projectData?.jobZip,
      projectData?.jobCountry,
    ]
      .filter(Boolean)
      .join(', ');
  };

  const { marker, mapData } = state;

  const projectStage = useMemo(
    () => getProjectStageByCode(projectData?.jobStatusCodesCode),
    [projectData]
  );

  const showsOnCurrentStage = useCallback(
    ({ stages }) => stages.includes('all') || stages.includes(projectStage),
    [projectStage]
  );

  const shouldShowMarketingInformation = useMemo(() => {
    const should =
      projectStage != 'lead' ||
      customFields?.filter(showsOnCurrentStage)?.find((field) => field.section === 'marketing');

    return !!should;
  }, [customFields, projectStage]);

  const shouldShowProjectInformation = useMemo(() => {
    const should = customFields
      ?.filter(showsOnCurrentStage)
      ?.find((field) => field.section === 'general');

    return !!should;
  }, [customFields, projectStage]);

  const shouldShowDealInformation = useMemo(() => {
    const should =
      projectStage != 'lead' ||
      customFields?.filter(showsOnCurrentStage)?.find((field) => field.section === 'deal');

    return !!should;
  }, [customFields, projectStage]);

  const shouldShowAdditionalInformation = useMemo(() => {
    const should = customFields
      ?.filter(showsOnCurrentStage)
      ?.find((field) => field.section === 'additional');

    return !!should;
  }, [customFields, projectStage]);

  const isCreator = projectData?.createdBy?.objectId === state.userId;
  const canEditDetails = isAllowed(
    moduleConstants.EDITPROJDETAILS,
    isCreator ? 'anyAssigned' : 'assigned',
    projectData?.userAssignData
  );





  return (
    <div className=''>
      <div className='pDetail'>
        <div className='addressBox'>
          <div className='row'>

            {projectData?.lat && projectData?.lng ? (
              <div className='col-md-6'>
                <div className='mapBox'>
                  {/*<div className='map-toggle-buttons'>
                    <button onClick={() => setShowMap(false)} className={!showMap ? 'active' : ''}>Street View</button>
                    <button onClick={() => setShowMap(true)} className={showMap ? 'active' : ''}>Map</button>
                  </div>*/}
                  <div className='google-map'>
                    {showMap ? <GoogleMapReact
                      style={{ height: '250px', width: '100%' }}
                      bootstrapURLKeys={{ key: appConstants.GOOGLE_MAPS_API_KEY }}
                      center={mapData.center}
                      zoom={mapData.zoom}
                      options={{
                        mapTypeId: 'hybrid',
                        streetViewControl: true,
                        labels: true,
                        styles: [{ featureType: 'road', stylers: [{ visibility: 'on' }] }]
                      }}
                      yesIWantToUseGoogleMapApiInternals
                      onGoogleApiLoaded={({ map, maps }) => apiHasLoaded(map, maps)}
                    >
                      {/*mapMarkers.map((marker, index) => (
                        <Marker key={index} lat={projectData.lat} lng={projectData.lng} marker={marker} />
                      ))*/}
                    </GoogleMapReact> :
                      <div id="street-view" style={{ height: '250px', width: '100%' }}></div>
                    }
                  </div>
                </div>
              </div>
            ) : (
              <div className='col-lg-3 col-md-4 col-sm-5 col-xs-6 mb-1'><>Site Location:</></div>
            )}

            <div className='col'>
              {projectData &&
                (projectData.jobAddress ||
                  projectData.jobCity ||
                  projectData.jobState ||
                  projectData.jobZip ||
                  projectData.jobCountry) ? (
                <>
                  <Link
                    target='_blank'
                    rel='noopener noreferrer'
                    to={{
                      pathname: `https://maps.google.com/?q=` + formatAddress(projectData),
                    }}
                    data-tooltip-content={projectData}
                    data-tooltip-id={projectData}
                  >
                    <p>{projectData?.jobAddress ? `${projectData.jobAddress} ` : ''}</p>
                    <p>{projectData?.jobAddress2 ? `${projectData.jobAddress2} ` : ''}</p>
                    <p>
                      {projectData?.jobCity ? `${projectData.jobCity}, ` : ''}

                      {projectData?.jobState ? `${projectData.jobState} ` : ''}

                      {projectData?.jobZip ? `${projectData.jobZip} ` : ''}

                      {projectData?.jobCountry ?? ''}
                    </p>
                  </Link>
                </>
              ) : (
                'No Location Specified'
              )}

              <p>---</p>
              <p>
                {projectData?.marketingDescription?.length > 0
                  ? projectData.marketingDescription
                  : 'No Description'}
              </p>
            </div>
          </div>
          <div className='mt-2'>
            <ProjectCustomFieldsView projectData={projectData} customFields={customFields} section='location' />
          </div>
        </div>
        {canEditDetails && (
          <a className='editBtn' onClick={() => onOpenEditModal('location-and-description')}>
            <i className='fa fa-pencil' aria-hidden='true'></i>
          </a>
        )}
      </div>

      {shouldShowProjectInformation && (
        <div className='pDetail'>
          <div className='row'>
            <div className='col-md-12'>
              <h6>Project Info</h6>
            </div>
          </div>
          <ProjectCustomFieldsView projectData={projectData} customFields={customFields} section='general' onEditRef={canEditDetails && onOpenEditModal} isHideFields={isHideFields} />
          {canEditDetails && (
            <a className='editBtn' onClick={() => onOpenEditModal('project-information')}>
              <i className='fa fa-pencil' aria-hidden='true'></i>
            </a>
          )}
        </div>
      )}

      {shouldShowDealInformation && (
        <div className='pDetail'>
          <div className='row'>
            <div className='col-md-12'>
              <h6>Deal Info</h6>
            </div>
          </div>
          <ProjectCustomFieldsView projectData={projectData} customFields={customFields} section='deal' isHideFields={isHideFields} />
          {canEditDetails && (
            <a className='editBtn' onClick={() => onOpenEditModal('deal-information')}>
              <i className='fa fa-pencil' aria-hidden='true'></i>
            </a>
          )}
        </div>
      )}

      {shouldShowMarketingInformation && (
        <div className='pDetail'>
          <div className='row'>
            <div className='col-lg-3 col-md-5 col-sm-7 col-xs-8'>
              <h6>Marketing Info</h6>
            </div>
            <div className='col'>
              {canEditDetails && (
                <a className='editBtn' onClick={() => onOpenEditModal('marketing-information')}>
                  <i className='fa fa-pencil' aria-hidden='true'></i>
                </a>

              )}</div>
          </div>

          <ProjectCustomFieldsView projectData={projectData} customFields={customFields} section='marketing' isHideFields={isHideFields} />

        </div>
      )}

      {shouldShowAdditionalInformation && (
        <div className='pDetail'>
          <div className='row'>
            <div className='col-md-12'>
              <h6>Additional Info</h6>
            </div>
          </div>

          <ProjectCustomFieldsView projectData={projectData} customFields={customFields} section='additional' onEditRef={canEditDetails && onOpenEditModal} isHideFields={isHideFields} />
          {canEditDetails && (
            <a className='editBtn' onClick={() => onOpenEditModal('additional-information')}>
              <i className='fa fa-pencil' aria-hidden='true'></i>
            </a>
          )}
        </div>
      )}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    projectData: state.project.projectData,
    isProjectUpdated: state.project.isProjectUpdated,
  };
}

export default withRouter(
  connect(mapStateToProps, {

  })(SiteInfo)
);
