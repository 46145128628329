import React, { useState } from 'react';

// TODO: reuse for all file imports, add styles
export const FileDropZone = ({ onFileDrop, children }: {
  onFileDrop: (files: any) => void;
  children: React.ReactNode;
}) => {
  const [dragging, setDragging] = useState(false);

  const handleDragEnter = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragging(false);

    onFileDrop(e.dataTransfer.files);
  };

  return (
    <div
      className={`file-drop-zone ${dragging ? 'dragging' : ''}`}
      onDragEnter={handleDragEnter}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      {children}
    </div>
  );
};