import React from 'react';
import { Link } from 'react-router-dom';
class LeadNotFound extends React.Component {    
    render() {
        return (
            <div>
                <div className="page-error text-center">
                    <div className="container" style={{padding: '10%'}}>
                    <div style={{textAlign: 'center'}}>
                        <div className="logo">
                        <a href="/home">
                            <img style={{width: '46%'}} src="/static/media/Cobilogo.png" alt="logo" />
                        </a>
                        </div>
                    </div>
                    <h1 style={{color: 'red'}}>Lead Not Found</h1>
                        <Link className="" to={{ pathname: "/projects-list/leads" }}>
                            {' '}
                            BACK TO LEADS{' '}
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}
export default (LeadNotFound);
