import joi from 'joi';
import { joiAnyString, } from '../../utils/validation';

export type UpdateContactAffiliationSchema = {
  _id?: string;
  title?: string;
  contactRole?: string | null;
  emailArr: {
    _id?: string;
    label: string;
    email: string;
    isPrimary: boolean;
  }[];
  addressArr: {
    _id?: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zipCode: string;
    country: string;
    isPrimary: boolean;
  }[];
  phoneArr: any[];
  active?: boolean;
  companyId?: string;
  companyName?: string;
  selfAffiliated?: boolean;
  employeeId?: string;
  isIndependentContractor?: boolean;
  department?: string;
  isAccountAffiliation?: boolean;
  order?: number;
  companyDeleted?: boolean;
  isPrimary: boolean;
  customFields?: any;
};

export const updateContactAffiliationSchema = joi.object<UpdateContactAffiliationSchema>({
  _id: joi.string().optional().allow(),
  selfAffiliated: joi.boolean().allow(),
  title: joi.alternatives().conditional('selfAffiliated', {
    is: true,
    then: joi.forbidden().failover(null),
    otherwise: joi.alternatives().conditional('isAccountAffiliation', {
      is: true,
      then: joi.forbidden().failover(null),
      otherwise: joi.string().optional().allow('', null).empty('').label('Title'),
    }),
  }),
  contactRole: joi.alternatives().conditional('selfAffiliated', {
    is: true,
    then: joi.forbidden().failover(null),
    otherwise: joi.alternatives().conditional('isAccountAffiliation', {
      is: true,
      then: joi.any(),
      otherwise: joi.string().optional().allow('', null).empty('').label('Contact Role'),
    }),
  }),
  emailArr: joi
    .array()
    .items(
      joi.object({
        _id: joi.allow(),
        email: joi.string().email({ tlds: false }).trim().allow('', null).empty('').label('Email'),
        isPrimary: joi.boolean().required().failover(false),
        label: joiAnyString().label('Label'),
      })
    )
    .unique((a, b) => a.isPrimary === true && b.isPrimary === true)
    .allow(null)
    .empty(null)
    .default([])
    .custom((value) => {
      const filtered = value.filter((item: any) => item.email);

      if (!filtered.length) return [];

      if (!filtered.find((item: any) => item.isPrimary)) {
        filtered[0].isPrimary = true;
      }

      return filtered;
    })
    .label('Email'),
  addressArr: joi
    .array()
    .items(
      joi.object({
        _id: joi.allow(),
        address1: joiAnyString().label('Address 1'),
        address2: joiAnyString().label('Address 2'),
        city: joiAnyString().label('City'),
        state: joiAnyString().label('State'),
        zipCode: joiAnyString().label('Zip Code'),
        country: joiAnyString().label('Country'),
        isPrimary: joi.boolean().required().failover(false),
        label: joiAnyString().label('Label'),
      })
    )
    .optional()
    .allow(null)
    .empty(null)
    .default([])
    .custom((value) => {
      const filtered = value.filter(
        (item: any) =>
          item.address1 || item.address2 || item.city || item.state || item.zipCode || item.country
      );

      if (!filtered.length) return [];

      if (!filtered.find((item: any) => item.isPrimary)) {
        filtered[0].isPrimary = true;
      }

      return filtered;
    })
    .label('Address'),
  phoneArr: joi
    .array()
    .items(
      joi.object({
        _id: joi.allow(),
        phone: joiAnyString().label('Phone Number'),
        type: joiAnyString().label('Phone Type'),
        ext: joiAnyString().label('Country Code'),
        extPhone: joiAnyString().label('Extension Phone Number'),
        isPrimary: joi.boolean().required().failover(false),
        country: joiAnyString().label('Country'),
        fullPhone: joiAnyString().label('Full Phone'),
        phoneClean: joiAnyString().label('Phone Clean'),
      })
    )
    .optional()
    .allow(null)
    .empty(null)
    .default([])
    .custom((value) => {
      const filtered = value.filter((item: any) => item.phone || item.extPhone);

      if (!filtered.length) return [];

      if (!filtered.find((item: any) => item.isPrimary)) {
        filtered[0].isPrimary = true;
      }

      return filtered;
    })
    .label('Phones'),
  companyId: joi.alternatives().conditional('selfAffiliated', {
    is: true,
    then: joi.allow(null),
    otherwise: joi.string().required(),
  }),
  active: joi
    .alternatives()
    .conditional('selfAffiliated', {
      is: true,
      then: joi.boolean().required().valid(true).failover(true),
      otherwise: joi.alternatives().conditional('isAccountAffiliation', {
        is: true,
        then: joi.boolean().required().valid(true).failover(true),
        otherwise: joi.boolean().required(),
      }),
    })
    .failover(true),
  companyName: joi.string().allow(),
  /*employeeId: joi.alternatives().conditional('selfAffiliated', {
    is: true,
    then: joi.conditional('employeeId').forbidden().label('Something'),
    otherwise: joiAnyString().label('Employee ID'),
  }),*/
  employeeId: joi.string().allow('', null).empty('').label('Employee ID'),
  isIndependentContractor: joi.alternatives().conditional('isAccountAffiliation', {
    is: true,
    then: joi.boolean().failover(false),
    otherwise: joiAnyString(),
  }).label('Independent Contractor'),
  department: joi.string().allow('', null).empty('').label('Department'),
  /*department: joi.alternatives().conditional('selfAffiliated', {
    is: true,
    then: joi.forbidden(),
    otherwise: joiAnyString().label('Department'),
  }),*/
  isAccountAffiliation: joi.allow(),
  order: joi.allow(),
  companyDeleted: joi.allow().empty(joi.any()),
  isPrimary: joi.boolean().label('Primary affiliation'),
  customFields: joi.allow(),
});
