import * as actionTypes from './actionTypes';
import * as API from '../api/project';
import axios from 'axios';
import { appConstants } from '../_constants';
import { callAlertMessage } from '../components/Auth/Auth';
import history from '../routes/history';


//get all user details
export const getExistingProjectRequest = () => ({
  type: actionTypes.GET_EXISTING_PROJECT_REQUEST,
});

export const getExistingProjectSuccess = (response) => ({
  type: actionTypes.GET_EXISTING_PROJECT_SUCCESS,
  payload: {
    response,
  },
});

export const getExistingProjectError = (error) => ({
  type: actionTypes.GET_EXISTING_PROJECT_ERROR,
  payload: {
    error,
  },
});

export const getExistingProjectAction = (data) => {
  return (dispatch) => {
    dispatch(getExistingProjectRequest());
    return API.getExistingProject(data)
      .then((response) => {
        dispatch(getExistingProjectSuccess(response.data.result));
      })
      .catch((error) => {
        dispatch(
          getExistingProjectError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

//get all user details
export const getAllProjectRequest = () => ({
  type: actionTypes.GET_ALL_PROJECT_REQUEST,
});

export const getAllProjectSuccess = (response, listType) => ({
  type: actionTypes.GET_ALL_PROJECT_SUCCESS,
  payload: {
    response,
    listType,
  },
});

export const getAllProjectError = (error) => ({
  type: actionTypes.GET_ALL_PROJECT_ERROR,
  payload: {
    error,
  },
});

export const getAllProjectAction = (data) => {
  return (dispatch) => {
    dispatch(getAllProjectRequest());
    return API.getAllProjects(data)
      .then((response) => {
        dispatch(getAllProjectSuccess(response.data, data.type));
      })
      .catch((error) => {
        dispatch(
          getAllProjectError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

//get all user details
export const createRequest = () => ({
  type: actionTypes.CREATE_PROJECT_REQUEST,
});

export const createSuccess = (response, nextLead) => ({
  type: actionTypes.CREATE_PROJECT_SUCCESS,
  payload: {
    response: response,
  },
});

export const createError = (error) => ({
  type: actionTypes.CREATE_PROJECT_ERROR,
  payload: {
    error,
  },
});

export const createProjectAction = (data) => {
  return (dispatch) => {
    dispatch(createRequest());

    return API.createProject(data).then((response) => {
      callAlertMessage(
        response.data.result.success === 1 ? 'success' : 'error',
        response.data.result.msg === 'Error'
          ? response.data.result.msg + ' : ' + response.data.result.data
          : response.data.result.msg
      );
      if (response.data.result.success === 1) {
        dispatch(createSuccess(response));
      } else {
        console.log('response', response);
        dispatch(createError(response));
      }
    });
  };
};

//updates user details
export const updateProjectRequest = () => ({
  type: actionTypes.UPDATE_PROJECT_REQUEST,
});

export const updateProjectSuccess = (response) => ({
  type: actionTypes.UPDATE_PROJECT_SUCCESS,
  payload: {
    response,
  },
});

export const updateProjectError = (error) => ({
  type: actionTypes.UPDATE_PROJECT_ERROR,
  payload: {
    error,
  },
});

export const updateProjectAction = (data, onSuccess = () => {}) => {
  return (dispatch) => {
    dispatch(updateProjectRequest());
    return API.updateProject(data)
      .then((response) => {
        if (response.data.result.success === 1) {
          onSuccess();
        }

        callAlertMessage(
          response.data.result.success === 1 ? 'success' : 'error',
          response.data.result.msg === 'Error'
            ? response.data.result.msg + ' : ' + response.data.result.data
            : response.data.result.msg
        );
        dispatch(updateProjectSuccess(response.data.result.msg));
      })
      .catch((error) => {
        dispatch(updateProjectError(error));
      });
  };
};

//Delete user details
export const deleteProjectRequest = () => ({
  type: actionTypes.DELETE_PROJECT_REQUEST,
});

export const deleteProjectSuccess = (response) => ({
  type: actionTypes.DELETE_PROJECT_SUCCESS,
  payload: {
    response,
  },
});

export const deleteProjectError = (error) => ({
  type: actionTypes.DELETE_PROJECT_ERROR,
  payload: {
    error,
  },
});

export const deleteProjectAction = (data) => {
  return (dispatch) => {
    dispatch(deleteProjectRequest());
    return API.deleteProject(FormData)
      .then((response) => {
        dispatch(deleteProjectSuccess(response.data.result.msg));
      })
      .catch((error) => {
        dispatch(deleteProjectError(error.response.data.error.msg));
      });
  };
};

export const updateLeadSectionRequest = () => ({
  type: actionTypes.UPDATE_LEAD_SECTION_REQUEST,
});

export const updateLeadSectionSuccess = (response) => ({
  type: actionTypes.UPDATE_LEAD_SECTION_SUCCESS,
  payload: {
    response,
  },
});

export const updateLeadSectionError = (error) => ({
  type: actionTypes.UPDATE_LEAD_SECTION_ERROR,
  payload: {
    error,
  },
});

export const updateLeadSectionAction = (data, props) => {
  let propsTemp = props;
  return (dispatch) => {
    dispatch(updateLeadSectionRequest());
    return API.updateLeadSection(data)
      .then((response) => {
        callAlertMessage(
          response.data.result.success === 1 ? 'success' : 'error',
          response.data.result.msg === 'Error'
            ? response.data.result.msg + ' : ' + response.data.result.data
            : response.data.result.msg
        );
        dispatch(updateLeadSectionSuccess(response.data.result.msg));
      })
      .catch((error) => {
        dispatch(updateLeadSectionError(error));
      });
  };
};

export const getLastJobNumRequest = () => ({
  type: actionTypes.GET_LAST_JOB_NUM_REQUEST,
});

export const getLastJobNumSuccess = (response) => ({
  type: actionTypes.GET_LAST_JOB_NUM_SUCCESS,
  payload: {
    response,
  },
});

export const getLastJobNumError = (error) => ({
  type: actionTypes.GET_LAST_JOB_NUM_ERROR,
  payload: {
    error,
  },
});

export const getLastJobNumAction = (data) => {
  return (dispatch) => {
    dispatch(getLastJobNumRequest());
    return API.getLastJobNum(data)
      .then((response) => {
        dispatch(getLastJobNumSuccess(response.data));
      })
      .catch((error) => {
        dispatch(getLastJobNumError(error));
      });
  };
};

export const promoteLeadRequest = () => ({
  type: actionTypes.PROMOTE_LEAD_REQUEST,
});

export const promoteLeadSuccess = (response) => ({
  type: actionTypes.PROMOTE_LEAD_SUCCESS,
  payload: {
    response,
  },
});

export const promoteLeadError = (error) => ({
  type: actionTypes.PROMOTE_LEAD_ERROR,
  payload: {
    error,
  },
});

export const promoteLeadAction = (data) => {
  return (dispatch) => {
    dispatch(promoteLeadRequest());
    return API.promoteLead(data)
      .then((response) => {
        callAlertMessage(
          response.data.result.success === 1 ? 'success' : 'error',
          response.data.result.msg === 'Error'
            ? response.data.result.msg + ' : ' + response.data.result.data
            : response.data.result.msg
        );
        if (response.data.result.success === 1) {
          let row = [];
          row['objectId'] = response.data.result.data.objectId;
          if (
            response.data.result.data.jobStatusCodesCode === appConstants.jobStatusCode.L ||
            response.data.result.data.jobStatusCodesCode === appConstants.jobStatusCode.NP
          ) {
            history.replace({
              pathname: history.location.pathname,
              state: { row },
            });
          } else {
            history.push({
              pathname: '/project-details'+'/'+response.data.result.data.objectId,
              state: { row: row },
            });
          }

          dispatch(promoteLeadSuccess(response.data.result.msg));
        } else {
          dispatch(promoteLeadError(response.data.result.msg));
        }
      })
      .catch((error) => {
        return false;
      });
  };
};

export const updateLeadStatusRequest = () => ({
  type: actionTypes.UPDATE_LEAD_STATUS_REQUEST,
});

export const updateLeadStatusSuccess = (response) => ({
  type: actionTypes.UPDATE_LEAD_STATUS_SUCCESS,
  payload: {
    response,
  },
});

export const updateLeadStatusError = (error) => ({
  type: actionTypes.UPDATE_LEAD_STATUS_ERROR,
  payload: {
    error,
  },
});


export const updateLeadStatusAction = (data, props) => {
  let propsTemp = props;
  return (dispatch) => {
    dispatch(updateLeadStatusRequest());
    return API.updateLeadStatus(data)
      .then((response) => {
        callAlertMessage(
          response.data.result.success === 1 ? 'success' : 'error',
          response.data.result.msg === 'Error'
            ? response.data.result.msg + ' : ' + response.data.result.data
            : response.data.result.msg
        );
        if (response.data.result.success === 1) {
          let row = [];
          row['objectId'] = response.data.result.data.objectId;
          if (
            response.data.result.data.jobStatusCodesCode === appConstants.jobStatusCode.L ||
            response.data.result.data.jobStatusCodesCode === appConstants.jobStatusCode.NP
          ) {
            history.replace({
              pathname: location.pathname,
              state: { row },
            });
          } else {
            history.push({
              pathname: '/lead-details'+'/'+response.data.result.data.objectId,
              state: { row: row },
            });
          }

          dispatch(updateLeadStatusSuccess(response.data.result.msg));
        } else {
          dispatch(updateLeadStatusError(response.data.result.msg));
        }
      })
      .catch((error) => {
        return false;
      });
  };
};

export const updatedEventDateRequest = () => ({
  type: actionTypes.UPDATE_EVENT_DATE_REQUEST,
});

export const updatedEventDateSuccess = (response) => ({
  type: actionTypes.UPDATE_EVENT_DATE_SUCCESS,
  payload: {
    response,
  },
});

export const updatedEventDateError = (error) => ({
  type: actionTypes.UPDATE_EVENT_DATE_ERROR,
  payload: {
    error,
  },
});

export const updatedEventDateAction = (data) => {
  return (dispatch) => {
    dispatch(updatedEventDateRequest());
    return API.updatedEventDate(data)
      .then((response) => {
        dispatch(updatedEventDateSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          updatedEventDateError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

export const updatedFILTEREventSuccess = (response, status) => ({
  type: actionTypes.UPDATE_FILTER_SECTION_SUCCESS,
  payload: {
    response,
    status,
  },
});

export const updatedEventAction = (data, status) => {
  return (dispatch) => {
    dispatch(updatedFILTEREventSuccess(data, status));
  };
};
