import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import renderHTML from 'html-react-parser';
import { withRouter } from 'react-router-dom';
import { getAllProjectRoleAction } from '../../../actions/projectRole';
import {
  formatInternalTeamContact,
} from '../../../utils/contact-formatters';
import { moduleConstants, isAllowed } from '../../../_constants';

function InternalProjectTeam(props) {
  const [userId] = useState(localStorage.getItem('userId'));
  const [accountId] = useState(localStorage.getItem('accountId'));
  const [assignedProjectToPeopleList, setAssignedProjectToPeopleList] = useState([]);
  const [projectData, setProjectData] = useState({});

  const [allInternalProjectRoles, setAllInternalProjectRoles] = useState([]);
  const [enabledInternalProjectRoles, setEnabledInternalProjectRoles] = useState([]);
  const [isHideFields, setIsHideFields] = useState(false);

  useEffect(() => {
    const projectData = props.projectData;
    setProjectData(projectData);
    props.getAllProjectRoleAction({
      className: 'projectRoleTypes',
      accountId: accountId,
      includeDisabled: true,
    });
  }, [props.projectData, accountId]);

  useEffect(() => {
    setIsHideFields(props.isHideFields);
  }, [props.isHideFields]);

  useEffect(() => {
    if (props.allProjectRoleTypes?.length > 0) {
      const newEnabledInternalProjectRoles = props.allProjectRoleTypes.filter((role) => {
        const isEnabledInternal = !role.isSystemMaster && !role.trash && role.type === 'internal';

        if (props.isLead) {
          return isEnabledInternal && role.isShowOnLeads && (role.code !== 'AX');
        }

        return isEnabledInternal;
      });

      setEnabledInternalProjectRoles(newEnabledInternalProjectRoles);

      setAllInternalProjectRoles(
        props.allProjectRoleTypes.filter((role) => !role.isSystemMaster && role.type === 'internal')
      );
    }

    setAssignedProjectToPeopleList(props.assignedProjectToPeopleList);

    if (props.projectData?.objectId) {
      const projectData = props.projectData;
      setProjectData(projectData);
    }
  }, [props.projectData, props.allProjectRoleTypes, props.isLead, props.assignedProjectToPeopleList]);

  const projectAssignments = assignedProjectToPeopleList || [];

  const enabledProjRoles = enabledInternalProjectRoles?.length > 0 ? enabledInternalProjectRoles : [];

  const enabledProjectRoleIds = enabledProjRoles?.map((assignment) => assignment.objectId);
  const presentProjectRoleIds = projectAssignments?.map((assignment) => assignment.projectRoleId);

  const enabledAndPresentProjectRoleIds = [
    ...new Set(presentProjectRoleIds.concat(enabledProjectRoleIds)),
  ];

  const displayProjectRoles =
    allInternalProjectRoles?.length > 0
      ? allInternalProjectRoles.filter(
        (role) =>
          enabledAndPresentProjectRoleIds.includes(role.objectId) &&
          (!props.isLead || role.code !== 'AX')
      )
      : [];

  const anyRolesPresent = displayProjectRoles.length > 0;

  const isCreator = projectData?.createdBy?.objectId === userId;
  const canEditDetails =
    isAllowed(moduleConstants.EDITPROJDETAILS, isCreator ? 'anyAssigned' : 'assigned', projectData?.userAssignData) &&
    isAllowed(moduleConstants.ASSIGNINTERNAL, isCreator ? 'anyAssigned' : 'assigned', projectData?.userAssignData);

  return (
    anyRolesPresent && (
      <div className="">
        <div className="pDetail">
          <h6>Internal Project Team</h6>

          <div>
            {displayProjectRoles.map((role) => {
              const roleAssignments = projectAssignments.filter(
                (item) =>
                  item.projectRoleId === role.objectId ||
                  (role.name.toLowerCase() === item.userRole.toLowerCase() && role.type === 'internal')
              );


              let numRolesDisplayed = 0;
              if(isHideFields && roleAssignments.length === 0) return null;

              return (
                <div key={role.objectId} className='row'>
                  <div className='col-lg-3 col-md-4 col-sm-5 col-xs-6 mb-1' >

                    {role.name}:
                  </div>
                  <div className='col-lg-9 col-md-8 col-sm-7 col-xs-6 mb-1'>
                    {roleAssignments?.length > 0 && (
                      roleAssignments.map((item) => {

                        numRolesDisplayed = numRolesDisplayed + 1;
                        return renderHTML(formatInternalTeamContact(item) + '<br/>');

                      })
                    )}
                    {numRolesDisplayed === 0 && <>-</>}
                  </div>
                </div>
              );
            })}
          </div>
          {canEditDetails && (
            <a className="editBtn" onClick={() => props.onOpenEditModal('company-team')}>
              <i className="fa fa-pencil" aria-hidden="true"></i>
            </a>
          )}
        </div>
      </div>
    )
  );
}

function mapStateToProps(state) {
  return {
    projectData: state.project.projectData,
    assignedProjectToPeopleList: state.assignProjectToPeople.assignedProjectToPeopleList,
    fetchRequest: state.project.fetchRequest,
    allProjectRoleTypes: state.projectRoleType.allProjectRoleTypes,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    getAllProjectRoleAction,
  })(InternalProjectTeam)
);
