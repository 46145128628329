import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import FormInput from '../../form-controls/form-input';
import { useState } from 'react';
import { CustomFieldsCommonProps } from '../constants';

const EnterUrlModal = ({ open, onClose, urlFieldName, urlLabelName }: any) => {
  return (
    <Modal isOpen={open} toggle={onClose}>
      <ModalHeader toggle={onClose}>Enter URL</ModalHeader>
      <ModalBody>
        <FormInput name={urlFieldName} label='Enter URL' />
      </ModalBody>
      <ModalBody>
        <FormInput name={urlLabelName} label='Enter Label' />
      </ModalBody>
      <ModalFooter>
        <button className='btn btn-primary' onClick={onClose}>
          OK
        </button>
      </ModalFooter>
    </Modal>
  );
};

type CustomTextInputProps = {
  isUrl: boolean;
  control: any;
  label: string;
  Layout: any;
  isTextArea?: boolean;
  placeholder?: string;
  minRows?: number;
  maxRows?: number;
} & CustomFieldsCommonProps;

export const CustomTextInput = ({ isUrl, ...props }: CustomTextInputProps) => {
  const [urlModalOpen, setUrlModalOpen] = useState(false);

  const placeholder = ((props?.placeholder as any)?.length>0) ? props.placeholder : props.label;

  return (
    <>{props.isTextArea ? <FormInput {...props} name={props.name} textArea autoSize={{minRows:props.minRows??2,maxRows:props.maxRows??6}} placeholder={placeholder} /> : <>
      <FormInput
        {...props}
        name={isUrl ? `${props.name}.url` : props.name}
        placeholder={placeholder} 
        inputText={
          isUrl && !props.disabled ? (
            <span role='button' onClick={() => setUrlModalOpen(true)}>
              <i className='fa fa-link'></i>
            </span>
          ) : null
        }
      />
      <EnterUrlModal
        urlFieldName={`${props.name}.url`}
        urlLabelName={`${props.name}.label`}
        open={urlModalOpen}
        onClose={() => setUrlModalOpen(false)}
      />
      </>}
    </>
  );
};
