import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { formatBidDueDate } from '../../../utils/date-formatters';
import { getBidMissedWidgetAction } from '../../../actions/home';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

const BidDateMissed = () => {
  const [missedList, setMissedList] = useState([]);
  const [accountId] = useState(localStorage.getItem('accountId'));
  const [userId] = useState(localStorage.getItem('userId'));
  const [fetchRequest, setFetchRequest] = useState(false);
  const [showProcessing, setShowProcessing] = useState(true);
  const [showError, setShowError] = useState(false);

  const dispatch = useDispatch();
  const missedData = useSelector((state) => state.home.dashboardReport);

  useEffect(() => {
    const data = {
      accountId,
      userId,
      page: 0,
      displayLimit: 5,
    };

    dispatch(getBidMissedWidgetAction(data));
    setShowProcessing(true);
    setFetchRequest(true);
  }, [dispatch, accountId, userId]);

  useEffect(() => {
    if (missedData && fetchRequest) {
      if (missedData.success) {
        setMissedList(missedData.data.bidDateMissed);
        setShowProcessing(false);
        
        setFetchRequest(false);
      }
    }
  }, [missedData, fetchRequest]);

  return (
    <div className='table-responsive'>
      <table width='100%'>
        <thead>
          <tr>
            <th>Job#</th>
            <th>Bid Due Date/Time</th>
            <th>Job Name</th>
          </tr>
        </thead>
        <tbody>
          {missedList.map((v, i) => {
            let row = v;
            return (
              <tr key={i}>
                <td>
                  <Link
                    to={{
                      pathname: `/project-details/${row.objectId}`,
                      state: { row },
                    }}
                  >
                    {v.jobNumFormatted}
                  </Link>
                </td>
                <td>{formatBidDueDate(v.bidDue)}</td>
                <td>
                  <Link
                    to={{
                      pathname: `/project-details/${row.objectId}`,
                      state: { row },
                    }}
                  >
                    {v.jobName}
                  </Link>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default withRouter(BidDateMissed);
