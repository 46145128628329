import { useFormContext } from 'react-hook-form';
import { HorizontalLayout } from '../../../shared/form-controls/control-layouts/horizontal-layout';
import FormDatePicker from '../../../shared/form-controls/form-date-picker/form-date-picker';
//import FormInput from '../../../shared/form-controls/form-input/form-input';
import { formatDateAdd } from '../../../utils/date-formatters';

type ProjectMarketingInformationProps = {
  projectId?: string;
  customFields?: any;
 // hasHidden?: boolean;
  onShowHiddenCustomFields?: () => void;
};

export const ProjectMarketingInformation = ({
  projectId,
  customFields,
  //hasHidden = false,
 // onShowHiddenCustomFields,
}: ProjectMarketingInformationProps) => {
  const form = useFormContext();

  const isUpdate = !!projectId;

  return (
    <>
      <b>Marketing information</b>
      <hr />

      {!isUpdate && (
        <FormDatePicker
          label='Lead Date'
          name='leadDate'
          control={form.control}
          //minDate={formatDateAdd(-12, 'months')}
          maxDate={formatDateAdd(1, 'months')}
          Layout={HorizontalLayout}
        />
      )}

      {customFields}
    </>
  );
};
