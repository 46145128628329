import { Checkbox } from 'antd';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import ReactLoading from 'react-loading';
import { useBlockLayout, useSortBy, useTable } from 'react-table';
import { FixedSizeList as List } from 'react-window';
import '../../..//shared/data-grid/data-grid-table.css';
import useBoundingRect from '../../../shared/hooks/use-bounding-rect';
import useTableSelection from '../../../shared/table-hooks/use-table-selection';
import { getScrollbarWidth } from '../../../utils/get-scrollbar-width';
import { heavySearch } from '../../../utils/heavy-search';
import { appConstants } from '../../../_constants';
import styles from './settings-table.module.scss';

const selectionColumn = {
  id: 'selection',
  width: 30,
  Header: ({ state }) => (
    <Checkbox
      checked={state.getCheckedAll()}
      onChange={(e) => state.toggleCheckAll(e.target.checked)}
    />
  ),
  Cell: ({ state, row }) => {
    if (!state.getSelectableRow(row.original)) return null;

    return (
      <Checkbox
        checked={state.getCheckedRow(row.original)}
        onChange={(e) => state.toggleCheck(e.target.checked, row.original)}
      />
    );
  },
};

export const SettingsTable = ({
  columns = [],
  data = [],
  itemHeight = 40,
  isLoading = false,
  onRowClicked = (row) => {},
  searchKeyword = '',
  selectable = false,
  getSelectableRow = (row) => true,
  getIndex = (row) => row.objectId,
  onSelectedAny = () => {},
  onSelectedRowsChange = () => [],
  selectedAny = false,
}) => {
  const [preparedData, setPreparedData] = useState([]);

  const bounce = _.debounce(() => {
    if (!searchKeyword) return setPreparedData([...data]);

    heavySearch(
      columns.map((c) => ({ accessor: c.accessor, searchable: c.searchable })),
      data,
      searchKeyword
    ).then((filteredData) => {
      setPreparedData(filteredData);
    });
  }, 500);

  useEffect(() => {
    bounce();
  }, [searchKeyword, data, columns]);

  const { wrapperRef, height } = useBoundingRect();

  const maxTableHeight = useMemo(
    () => height - itemHeight - getScrollbarWidth(),
    [height, itemHeight]
  );

  const tableHeight = useMemo(() => {
    const calculatedHeight = preparedData.length * itemHeight;

    return calculatedHeight <= maxTableHeight ? calculatedHeight : maxTableHeight;
  }, [preparedData, maxTableHeight]);

  const preparedColumns = useMemo(() => {
    return selectable ? [selectionColumn, ...columns] : columns;
  }, [columns]);

  const selectionState = useTableSelection({ getSelectableRow, getIndex, preparedData });
  const { getSelectedAny, selectedRows, toggleCheckAll } = selectionState;

  useEffect(() => {
    onSelectedAny(getSelectedAny());
  }, [getSelectedAny]);

  useEffect(() => {
    onSelectedRowsChange(selectedRows);
  }, [selectedRows]);

  useEffect(() => {
    if (selectedAny !== getSelectedAny()) {
      toggleCheckAll(false);
    }
  }, [selectedAny]);

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable(
    {
      columns: preparedColumns,
      data: preparedData,
      useControlledState: (state) => ({
        ...state,
        ...selectionState,
      }),
    },
    useSortBy,
    useBlockLayout
  );

  return (
    <div className={styles.tableWrapper} ref={wrapperRef}>
      <div className={`${rows?.length ? 'react-table-empty' : ''}`} style={{ overflowY: 'auto' }}>
        <table className='ms-auto ml-auto mr-auto me-auto mt-0' {...getTableProps()}>
          <thead style={{ backgroundColor: 'rgb(245,245,245)' }}>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps({
                      style: {
                        paddingLeft: 6,
                      },
                    })}
                  >
                    <span>{column.render('Header')}</span>
                    <span {...column.getSortByToggleProps()}>
                      {column.id !== 'trash' && column.canSort && (
                        <span className='ms-1'>
                          <i
                            className={`fa fa-sort${
                              column.isSorted ? (column.isSortedDesc ? '-desc' : '-asc') : ''
                            }`}
                            aria-hidden='true'
                          />
                        </span>
                      )}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {isLoading ? (
              <tr>
                <td colSpan={50} style={{ width: '100%', textAlign: 'center' }}>
                  <div>
                    <ReactLoading
                      className='table-loader'
                      type={appConstants.LOADER_TYPE}
                      color={appConstants.LOADER_COLOR}
                      height={appConstants.LOADER_HEIGHT}
                      width={appConstants.LOADER_WIDTH}
                    />
                  </div>
                </td>
              </tr>
            ) : (
              <>
                {rows?.length && !isLoading ? (
                  <>
                    {
                      <List
                        itemKey={(props) => getIndex(preparedData[props])}
                        itemData={preparedData}
                        itemCount={preparedData.length}
                        itemSize={itemHeight}
                        height={tableHeight}
                        width={`calc(100% + ${getScrollbarWidth() + 1}px)`}
                      >
                        {({ data, index, style }) => {
                          const row = rows[index];
                          prepareRow(row);

                          const rowProps = row.getRowProps({
                            style: {
                              ...style,
                              minWidth: `calc(100% - ${getScrollbarWidth() + 1}px))`,
                            },
                          });

                          return (
                            <div className={`is-edit-table ${styles.row}`} {...rowProps}>
                              {row.cells.map((cell, index) => {
                                const cellProps = cell.getCellProps({
                                  style: {
                                    height: 40,
                                  },
                                  onClick: (e) => onRowClicked(row.original),
                                });

                                return (
                                  <div
                                    {...cellProps}
                                    className={`${styles.cell} is-edit-table-cell`}
                                    data-last-cell={index === row.cells.length - 1}
                                  >
                                    {cell.render('Cell')}
                                  </div>
                                );
                              })}
                            </div>
                          );
                        }}
                      </List>
                    }
                  </>
                ) : (
                  <>{!preparedData.length && <p>No data found!</p>}</>
                )}
              </>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SettingsTable;
