import { Store } from '@bryntum/core-thin';
import { BryntumScheduler, BryntumSchedulerProps } from '@bryntum/scheduler-react-thin';
import '@bryntum/core-thin/core.stockholm.css';
import '@bryntum/grid-thin/grid.stockholm.css';
import '@bryntum/scheduler-thin/scheduler.stockholm.css';
import _ from 'lodash';
import printJS from 'print-js';
import { useEffect, useMemo, useRef, useState } from 'react';
import {
  selectGetResourceSchedule,
  selectResourceSchedulerData,
} from '../../../../modules/reports/selectors';
import { useAppSelector } from '../../../../shared/hooks/use-app-selector';
import { formatDate } from '../../../../utils/date-formatters';
import styles from './ResourceScheduler.module.css';
import { appConstants } from '../../../../_constants';

export default function ResourceScheduler() {
  const { data: resourceSchedule } = useAppSelector(selectGetResourceSchedule);


  const schedulerData = useAppSelector(selectResourceSchedulerData);

  const pdfExportURL = appConstants.pdfExportURL;

  const uniqueEventNames = useMemo(
    () => _.uniqBy(schedulerData.events, 'name'),
    [schedulerData.events]
  );

  const schedulerRef = useRef<BryntumScheduler>(null);
  const schedulerViewPresetRef = useRef('');

  useEffect(() => {
    const scheduler = schedulerRef.current?.instance;

    if (!scheduler || !resourceSchedule.startDate || !resourceSchedule.endDate) {
      return;
    }

    scheduler.setTimeSpan(
      new Date(resourceSchedule.startDate.iso),
      new Date(resourceSchedule.endDate.iso)
    );
  }, [resourceSchedule.endDate, resourceSchedule.startDate]);

  useEffect(() => {
    const scheduler = schedulerRef.current?.instance;

    if (!scheduler || !resourceSchedule.monthYear) {
      return;
    }

    if (resourceSchedule.monthYear.length > 12) {
      schedulerViewPresetRef.current = 'manyYears'; // years
    }

    if (resourceSchedule.monthYear.length === 12) {
      schedulerViewPresetRef.current = 'monthAndYear'; // year
    }

    if (resourceSchedule.monthYear.length === 3) {
      schedulerViewPresetRef.current = 'weekDateAndMonth'; // quarter
    }

    if (resourceSchedule.monthYear.length === 1) {
      schedulerViewPresetRef.current = 'weekAndDay'; // month
    }

    if (schedulerViewPresetRef.current) {
      scheduler.viewPreset = schedulerViewPresetRef.current;
    }
  }, [resourceSchedule.monthYear]);

  useEffect(() => {
    const scheduler = schedulerRef.current?.instance;

    if (!scheduler) {
      return;
    }

    if ('get' in scheduler.columns) {
      scheduler.columns.get('status').getData('filterable').filterField.items = _.uniq(
        schedulerData.resources.flatMap((resource) =>
          resource.children.map((child) => child.status)
        )
      );
    }
  }, [schedulerData.resources]);

  useEffect(() => {
    const scheduler = schedulerRef.current?.instance;

    if (!scheduler) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    scheduler.features.timeAxisHeaderMenu.items.eventsFilter.menu.items.nameFilter.items =
      uniqueEventNames;
  }, [uniqueEventNames]);

  const handleBeforePresetChange = (event) => {
    const scheduler = schedulerRef.current?.instance;

    if (!scheduler) {
      return;
    }

    // prevent scheduler from breaking dates on reset view preset
    if (event.to.id === schedulerViewPresetRef.current) {
      event.to.options.startDate = scheduler.startDate;
      event.to.options.endDate = scheduler.endDate;
    }
  };

  const handlePdfExport = async (event) => {
    const pdf = await event.response.json();

    if (pdf?.url) {
      printJS(pdf.url);
    }
  };

  const dateRenderer = (recordData) => (recordData.value ? formatDate(recordData.value) : '');

  const [schedulerConfig] = useState<BryntumSchedulerProps>({
    barMargin: 8,
    rowHeight: 40,

    autoAdjustTimeAxis: false,

    eventLayout: 'mixed',

    zoomKeepsOriginalTimespan: true,
    maxZoomLevel: 15,

    bodyCls: styles.schedulerBody,

    readOnly: true,
    filterFeature: true,
    treeFeature: true,
    nonWorkingTimeFeature: true,
    timeRangesFeature: true,
    eventEditFeature: false,

    timeAxisHeaderMenuFeature: {
      items: {
        eventsFilter: {
          text: 'Filter by Status',
          menu: {
            items: {
              nameFilter: {
                type: 'combo',
                multiSelect: true,
                editable: false,
                label: '',
                valueField: 'name',
                displayField: 'name',
              },
            },
          },
        },
        dateRange: false,
        currentTimeLine: false,
      },
    },

    pdfExportFeature: {
      exportServer: pdfExportURL+'/',
      //translateURLsToAbsolute : 'https://cpost-bryntum-print-server.herokuapp.com:443/resources',
      openAfterExport: false,
      orientation: 'landscape',
      alignRows: true,
      repeatHeader: true,
      exporterType: 'multipagevertical',
      paperFormat: 'Letter',

      exportDialog: {
        items: {
          columnsField: {
            editable: false,
            clearable: true,
          },
          fileFormatField: {
            hidden: true,
          },
        },
        bbar: {
          itemCls: styles.bExportItem,
          items: {
            exportButton: { text: 'Print', weight: 201 },
          },
        },
      },
    },

    resourceStore: {
      fields: [
        'status',
        'statusCode',
        'startDate',
        'deliveredDate',
        'endDate',
        'jobNumber',
        'alternateJobNumber',
        'leadDate',
        'bidDueDate',
        'warrExpiration',
      ],
    },

    columns: [
      {
        type: 'tree',
        text: 'Project Name',
        field: 'jobName',
        minWidth: 128,
        align: 'left',
        htmlEncode: false,
        autoWidth: true,
        searchable: true,
        filterable: {
          filterField: {
            placeholder: 'Filter Project Name',
          },
        },
        renderer: (recordData) => {
          if (recordData.record.data.unassigned) {
            return recordData.value;
          }

          let to = 'project-details';
          let slug = recordData.record.data.projectId;

          if (recordData.record.isParent&&!recordData.record.isCompany) {
            to = 'contact-details';
            slug = recordData.record.linkId;
          }else if(recordData.record.isParent&&recordData.record.isCompany){
            to = 'company-details';
            slug = recordData.record.linkId;
          }

          return `<a href="/${to}/${slug}" target="_blank" rel="noreferrer">${recordData.value}</a>`;
        },
      },
      {
        text: 'Status',
        field: 'status',
        align: 'center',
        autoWidth: true,
        filterable: {
          filterField: {
            type: 'combo',
            placeholder: 'Select Status',
          },
        },
      },
      {
        text: 'Status code',
        field: 'statusCode',
        align: 'center',
        hidden: true,
        filterable: {
          filterField: {
            placeholder: 'Filter by Status Code',
          },
        },
      },
      {
        text: 'Start Date',
        field: 'startDate',
        align: 'center',
        renderer: dateRenderer,
        filterable: false,
      },
      {
        text: 'Delivered Date',
        field: 'deliveredDate',
        hidden: true,
        align: 'center',
        renderer: dateRenderer,
        filterable: false,
      },
      {
        text: 'End Date',
        field: 'endDate',
        align: 'center',
        renderer: dateRenderer,
        filterable: false,
      },
      {
        text: 'Job number',
        field: 'jobNumber',
        align: 'center',
        hidden: true,
        filterable: {
          filterField: {
            placeholder: 'Filter by Job #',
          },
        },
      },
      {
        text: 'Alternate job number',
        field: 'alternateJobNumber',
        align: 'center',
        hidden: true,
        filterable: {
          filterField: {
            placeholder: 'Filter by Alt. Job #',
          },
        },
      },
      {
        text: 'Lead date',
        field: 'leadDate',
        align: 'center',
        renderer: dateRenderer,
        filterable: false,
        hidden: true,
      },
      {
        text: 'Bid due date',
        field: 'bidDueDate',
        align: 'center',
        renderer: dateRenderer,
        filterable: false,
        hidden: false,
      },
      {
        text: 'Warranty Expir.',
        field: 'warrExpiration',
        align: 'center',
        renderer: dateRenderer,
        filterable: false,
        hidden: true,
      },
    ],
    fillLastColumn: false,
    autoHeight: true,
    fixedRowHeight: true,

    tbar: {
      itemCls: styles.bToolbarItem,

      items: {
        viewPreviousPeriodButton: {
          type: 'button',
          icon: 'b-fa-angle-left',
          onClick: () => {
            const scheduler = schedulerRef.current?.instance;

            if (!scheduler) {
              return;
            }

            scheduler.shiftPrevious();
          },
        },
        viewNextPeriodButton: {
          type: 'button',
          icon: 'b-fa-angle-right',
          onClick: () => {
            const scheduler = schedulerRef.current?.instance;

            if (!scheduler) {
              return;
            }

            scheduler.shiftNext();
          },
        },
        resetViewButton: {
          type: 'button',
          text: 'Reset View',
          onClick: () => {
            const scheduler = schedulerRef.current?.instance;

            if (!scheduler) {
              return;
            }

            scheduler.viewPreset = schedulerViewPresetRef.current;
          },
        },
        //spaceBetween: {type:'splitter',text:'->'},
        todayBarCheckbox: {
          type: 'checkbox',
          text: 'Today bar',
          style: 'margin-left: auto',
          checked: false,
          onChange: (recordData) => {
            const scheduler = schedulerRef.current?.instance;

            if (!scheduler) {
              return;
            }

            const timeRangeStore = scheduler.timeRangeStore as Store;

            if (recordData.checked) {
              timeRangeStore.add({
                id: 'todayBar',
                cls: styles.shaded,
                startDate: new Date(0),
                endDate: new Date(),
                showHeaderElements: false,
              });
            } else {
              timeRangeStore.getById('todayBar').remove();
            }
          },
        },
        print: {
          type: 'button',
          icon: 'fa fa-print',
          text: 'Print',
          onClick: (event) => {
            const scheduler = event.source.parent.parent;

            scheduler.features.pdfExport.showExportDialog();
          },
        },
      },
    },
  });

  return (
    <BryntumScheduler
      ref={schedulerRef}
      {...schedulerConfig}
      resources={schedulerData.resources}
      events={schedulerData.events}
      onBeforePresetChange={handleBeforePresetChange}
      onPdfExport={handlePdfExport}
    />
  );
}
