import React, { Suspense } from 'react';

import { connect } from 'react-redux';
import Header from '../Layout/Header.js';
import Footer from '../Layout/Footer.js';

import { withRouter } from 'react-router-dom';
import loaderImage from '../static/images/loading_i.gif';
import TitleComponent from '../Common/TitleComponent';
import SidebarPreferences from '../Layout/SidebarPreferences.js';
import { ResetUserPasswordAction } from '../../actions/user';
import { callAlertMessage } from '../../components/Auth/Auth';


class ChangeUserPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      sessionToken: localStorage.getItem('sessionToken'),
      userId: localStorage.getItem('userId'),
      userInfo: localStorage.getItem('userInfo'),
      formArr: [],
      formData: {},
      passwordError: null,
      confirmPassword: null,
      email: '',
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    let user = JSON.parse(localStorage.getItem('userInfo'));
    if (user && user.username) {
      this.setState({ email: user.username });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.isResetPwdSuccess !== this.props.isResetPwdSuccess &&
      nextProps.isResetPwd.success === 1
    ) {
      callAlertMessage('success', nextProps.message);
      this.props.history.push(`/admin/logout`);
    }

    if (
      nextProps.isResetPwdSuccess !== this.props.isResetPwdSuccess &&
      nextProps.isResetPwd.success === 0
    ) {
      callAlertMessage('error', nextProps.message);
    }
  }

  handleChange = (e) => {
    e.preventDefault();
    let field = this.state.formData;

    if (e.target.name === 'newPassword') {
      if (e.target.value && e.target.value.length <= 4) {
        this.setState({
          passwordError: (
            <small className='text-danger'>
              You must enter a password of at least 5 characters.
            </small>
          ),
        });
      } else {
        this.setState({ passwordError: null });
      }

      if (e.target.value !== field.confirmPassword) {
        this.setState({
          confirmPassword: <small className='text-danger'>Passwords do not match.</small>,
        });
      } else {
        this.setState({ confirmPassword: null });
      }
    }

    if (e.target.name === 'confirmPassword') {
      if (e.target.value !== field.newPassword) {
        this.setState({
          confirmPassword: <small className='text-danger'>Passwords do not match.</small>,
        });
      } else {
        this.setState({ confirmPassword: null });
      }
    }

    field[e.target.name] = e.target.value;

    this.setState({ formData: field });
  };

  handleSubmit(e) {
    e.preventDefault();
    let { userId } = this.state;

    let data = {
      userIdToReset: userId,
    };

    if (true) {
      // formData['email'] = email;
      this.props.ResetUserPasswordAction(data);
    }
  }

  render() {
    const { passwordError, confirmPassword } = this.state;

    return (
      <div className='ContactListReport'>
        <TitleComponent data={{ title: `My Profile` }} />
        <Suspense fallback='loading'>
          <Header props={this.props} />
        </Suspense>

        <div className='contactBox'>
          {false && (
            <div className='loading_bg'>
              <img className='ajax-loader' src={loaderImage} width='100' height='100' />
            </div>
          )}

          <div className='container'>
            <div className='row'>
              <div className='col-sm-2'>
                <div className='heading2'>Preferences</div>

                <div className='sidebarBox'>
                  <SidebarPreferences props={this.props} />
                </div>
              </div>
              <div className='col-sm-10'>
                <div className='rightTableBox'>
                  <div className='formBox'>
                    <h2 className='heading2'>Request Password Reset</h2>
                  </div>

                  <button
                    className='btn btn-primary'
                    disabled={passwordError || confirmPassword}
                    onClick={(e) => this.handleSubmit(e)}
                  >
                    Reset
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    message: state.profile.message,
    success: state.profile.success,

    isChangePwd: state.account.isChangePwd,
    isChangePwdSuccess: state.account.isChangePwdSuccess,
    isChangePwdError: state.account.isChangePwdError,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    ResetUserPasswordAction,
  })(ChangeUserPassword)
);
