import { Rate } from 'antd';
import { Controller, useFormContext } from 'react-hook-form';
import styles from './styles.module.scss';
import { DefaultLayout } from '../control-layouts/default-layout';

type FormRateProps = {
  name: string;
  control?: any;
  label?: string;
  count?: number;
  required?: boolean;
  disabled?: boolean;
  allowHalf?: boolean;
  character?: React.ReactNode | JSX.Element | string;
  Layout?: any;
};

export const FormRate = ({
  name,
  control,
  label,
  count = 5,
  required = false,
  disabled = false,
  allowHalf = false,
  character,
  Layout = DefaultLayout,
}: FormRateProps) => {
  const form = useFormContext();

  if (!control && !form.control)
    throw new Error('FormRate: component must be wrapped in FormProvider or pass control prop');

  return (
    <Controller
      rules={{ required: required && `${label} is required` }}
      name={name}
      control={control || form.control}
      render={({ field, fieldState }) => (
        <Layout
          label={label}
          name={name}
          error={fieldState.error?.message}
          required={required}
          input={
            <Rate
              value={field.value}
              onChange={field.onChange}
              count={count}
              allowHalf={allowHalf}
              className={`${styles.rateInput} ${disabled ? '' : 'py-1'}`}
              disabled={disabled}
              character={character}
            />
          }
        />
      )}
    />
  );
};
