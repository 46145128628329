import React from 'react';
import bannerImage from '../static/images/cpostLogo.png';
import queryString from 'query-string';
import Footer from '../Layout/Footer.js';
import appImage from '../static/images/app-screenshot.png';
import { useAuth } from '../Auth/Auth';

class AppStoreDemo extends React.Component {
  componentDidMount() {
  }

  goTo(route) {
    this.props.history.replace(`/${route}`);
  }
  login() {
    const { login } = useAuth();
    login();
  }
  logout() {
    const { logout } = useAuth();
          logout();
  }

  render() {
    const { isAuthenticated } = useAuth();
    return (
      <div>
        {!isAuthenticated() && (
          <div className="wrapperBox">
            <header className="frontLoginHeader">
              <div className="container">
                <div className="row">
                  <div className="col-sm-6">
                    <div className="logo">
                      <a href="/home">
                        <img src={bannerImage} alt="Logo" />
                      </a>
                      <span className="tagline">Business Intelligence Software</span>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <form action="#">
                      <div className="signBtn">
                        <a className="btn cancel-btn" onClick={this.login.bind(this)}>
                          Sign In
                        </a>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </header>
            <div className="homeContainer">
              <div className="container">
                <div className="row">
                  <div className="col-md-4">
                    <div className="home-sidebar">
                      <img src={appImage} alt="img" />
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="HomeDesc mobileApp">
                      <h2>Everything you need, on the go.</h2>
                      <p>
                        With Command Post, critical business information is always at your
                        fingertips.
                      </p>
                      <a className="btn btn-primary">App Store </a>
                      <a
                        href="https://play.google.com/store/apps/details?id=st.ccp"
                        target="_blank"
                        className="btn btn-primary"
                      >
                        Play Store
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="fixfooter"></div>
            <Footer />
          </div>
        )}
      </div>
    );
  }
}
export default AppStoreDemo;
