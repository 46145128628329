import { postRequest, postRequestForSearch, postRequestNode } from './helper';
import apiClient from '../utils/api-client';

export const getAddressLabelReport = (data) => apiClient.post('/parse/functions/addressLabelReport', data); //address Label Report

export const getLeadSummaryReport = (data) => apiClient.post('/parse/functions/leadsSummaryReport', data); // lead summary report

export const getContactListReport = (data) =>
  postRequestForSearch('functions/contactListReport', data); //Contact list Report
export const getLeadSourceReport = (data) => apiClient.post('/parse/functions/leadSourceReport', data); // lead source report
export const getClientListReport = (data) => apiClient.post('/parse/functions/clientListReport', data); //client  list Report
export const getCompanyListReport = (data) =>
  postRequestForSearch('functions/companyListReport', data); //company project list Report

export const getCustomizeProjectListReport = (data) => apiClient.post('reportMaster', data); //customize project list Report

export const getBidDueReport = (data) => apiClient.post('/parse/functions/bidDueReport',data); //bid due report
export const getBidHistoryReport = (data) => apiClient.post('/parse/functions/bidHistoryReport', data); //bid due report

export const getNoBidReport = (data) => apiClient.post('reportMaster', data); //get no bid list Report(search)

export const getBidPendingReport = (data) => apiClient.post('bidPendingReport', data); //Bid pending report
//export const getBidResultRecapReport = (data) => apiClient.post('/parse/functions/bidResultRecapReport',data); //Bid result recap report
export const getBidResultRecapReport = (data) => apiClient.post('reportMaster', data); //Bid result recap report
//export const getLostJobReport = (data) => postRequestForSearch('functions/lostJobReport',data); //get Lost Job list Report
export const getLostJobReport = (data) => apiClient.post('reportMaster', data); //get Lost Job list Report

// export const getActiveProjectReport = (data) => apiClient.post('/parse/functions/activeProjectReport',data);  //active Project Report
export const getActiveProjectReport = (data) => apiClient.post('reportMaster', data); //active Project Report

export const getMasterConstructionList = (data) =>
  postRequestForSearch('functions/masterConstructionSchedule', data); //Master construction Schedule
export const getContactJobListReport = (data) =>
  postRequestForSearch('functions/contactJobReport', data); //Contact Job List
  export const getCompanyJobListReport = (data) =>
  postRequestForSearch('functions/companyJobReport', data); //Company Job List
export const getProjectPrelimInfoList = (data) =>
  apiClient.post('/parse/functions/projectPrelimInfoReport', data); //Project Prelim Info
export const getProjectInfoHotSheetReport = (data) =>
  apiClient.post('/parse/functions/projectInfoHotSheet', data); //Project InfoHotsheet
export const getTimeSheetList = (data) => apiClient.post('reportMaster', data); //timeSheet
export const getProjectHistoryReport = (data) => apiClient.post('/parse/functions/projectHistoryReport', data); //Report for Project history
export const getProfitProjectionReport = (data) =>
apiClient.post('/parse/functions/profitProjectionReport', data); //get Profit Projection Report
export const getProfitProjectionReportForecast = (data) =>
apiClient.post('/parse/functions/profitProjectionReport', data); //get Profit Projection Report
export const getProfitSalesReport = (data) =>
  apiClient.post('/parse/functions/profitSalesAnalysisReport', data); //Profit and sales report
// export const getProfitPerWeekReport = (data) => apiClient.post('/parse/functions/profitPerWeekReport',data); //profit per week report
export const getProfitPerWeekReport = (data) => apiClient.post('reportMaster', data); //profit per week report

export const getDeliveredProjectReport = (data) => apiClient.post('reportMaster', data); //get delivered list Report
export const getLienPeriodReport = (data) => apiClient.post('reportMaster', data); //get lien period Report

export const getWarrantyStatusReport = (data) => apiClient.post('reportMaster', data); //get Warranty Status Report Report
export const getFollowUpProjectReport = (data) => apiClient.post('reportMaster', data); //get followUp project list Report

export const getClientReferenceList = (data) => apiClient.post('reportMaster', data); //Client Reference
export const getDataCheckList = (data) => apiClient.post('reportMaster', data); //Data check
export const getEmployeeRosterReport = (data) =>
  postRequestForSearch('functions/employeeRosterReport', data); //employee roster report
// export const geMasterProjectList = (data) => postRequestForSearch('functions/masterProjectLog',data); //Master Project Log
export const geMasterProjectList = (data) => apiClient.post('reportMaster', data); //Master Project Log
export const getContactJobDDList = (data) => apiClient.post('/parse/functions/contactDropDownList', data); //Contact DropDown (DD)

export const getWipProjectListReport = (data) => apiClient.post('/parse/functions/wipProjectList/', data); //get WIP Report
export const updateWipProjectListReport = (data) =>
  apiClient.post('/parse/functions/updateWipProjectList/', data); //Update Wip  Report
