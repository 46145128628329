import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { deleteEmployee, getEmployeeInvitationStatus } from '../../../modules/contacts';
import { selectGetEmployeeInvitationStatus } from '../../../modules/contacts/selectors';
import { useConfirm } from '../../../shared/confirmation-dialog';
import { useAppDispatch } from '../../../shared/hooks/use-app-dispatch';

type DeleteEmployeeProps = {
  companyName: string;
  contactId: string;
  onSubmit: (contactId:string) => void;
};

export const DeleteEmployee = ({ companyName, contactId, onSubmit }: DeleteEmployeeProps) => {
  const confirm = useConfirm();
  const dispatch = useAppDispatch();

  const { data } = useSelector(selectGetEmployeeInvitationStatus);

  const onDeleteClick = useCallback(async () => {
    const ok = await confirm({
      title: 'Remove Employee',
      question: (
        <>
          Are you sure you want to remove this contact from <b>{companyName}</b>?
        </>
      ),
    });

    if (!ok) return;

    await dispatch(deleteEmployee({ contactId })).unwrap();

    await dispatch(getEmployeeInvitationStatus({ contactId }));

    await onSubmit( contactId );
  }, []);

  if (!data.isEmployee || data.isEmployeeDisabled) return null;

  return (
    <a className='link link-danger' onClick={onDeleteClick}>
      Remove Employee
    </a>
  );
};
