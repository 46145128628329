import $ from 'jquery';
import React from 'react';
import { Button } from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { getAllContactAction } from '../../actions/contact';
import { getAllContactRoleAction } from '../../actions/contactRole';
import { getAllCppRoleAction } from '../../actions/cppRole';
import {
  createContactSocialOutlookAction,
  createMultipleContactAction,
  getContactSocialMediaAction,
  importContactSocialMediaAction,
  skipOnboardingAction,
} from '../../actions/multipleContact';
import { appConstants } from '../../_constants';
import { UploadCsvContactsModal } from '../Dialogs';
import Footer from '../Layout/Footer.js';
import Header from '../Layout/Header.js';
import HeaderOnboarding from '../Layout/HeaderOnboarding.js';

import TitleComponent from '../Common/TitleComponent';
import csv_icon_download from '../static/images/csv_icon_download.png';
import csv_icon_upload from '../static/images/csv_icon_upload.png';

var csvHeaders = [
  'First Name',
  'Middle Name',
  'Last Name',
  'Company Name',
  'Contact Role',
  'Department',
  'Title',
  'Phone Type',
  'Phone Country Code',
  'Phone Number',
  'Phone Ext',
  'Email Label',
  'Email',
  'Address Label',
  'Address Line 1',
  'Address Line 2',
  'City',
  'State',
  'Country',
  'Zip Code',
  'Birthday',
  'Comments',
  'Employee ID',
  'Independent Contractor',
  'Phone 2 Type',
  'Phone 2 Country Code',
  'Phone 2 Number',
  'Phone 2 Ext',
  'Email 2 Label',
  'Email 2',
  'Address 2 Label',
  'Address 2 Line 1',
  'Address 2 Line 2',
  'City 2',
  'State 2',
  'Country 2',
  'Zip Code 2',
];

var csvData = [];
csvData[0] = [
  'Steven',
  'H',
  'Smith',
  'ACME Corp',
  'Tenant',
  '',
  'VP. Operations',
  'Work',
  '1',
  '1234512345',
  '',
  'Work',
  'xyz@xyz.com',
  'Office',
  '1234 Main st',
  'Apartment 5',
  'Springvale',
  'MA',
  'USA',
  '12345',
  '7/4/1969',
  'Repeat customer',
  '',
  'FALSE',
  'Mobile',
  '1',
  '1234512345',
  '',
  'Office',
  'xyz@xyz.com',
  'Office',
  '1234 Main st',
  'Apartment 5',
  'Springvale',
  'MA',
  'USA',
  '12345',
];

let formArr = {};

let rules = {
  username: 'required|alpha',
  password: 'required|alpha',
  //venderName: 'required'
};

let mess = {
  required: 'This field is required',
  alpha: 'This field may only contain letters',
  numeric: 'This field must be a number',
};

// eslint-disable-next-line no-undef
let Validator = require('validatorjs');
let validation = [];
validation = new Validator([], rules, appConstants.RULESMESSAGE);
validation.passes();
validation.fails();

class OnboardContactAdd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      modalOutlook: false,
      modalError: false,
      sessionToken: localStorage.getItem('sessionToken'),
      userId: localStorage.getItem('userId'),
      accountId: localStorage.getItem('accountId'),
      successUploading: true,
      errorUploading: true,
      showSkip: false,
      socialContact: false,
      counter: -1,
      multipleContact: [],
      userInfo: JSON.parse(localStorage.getItem('userInfo')),
      onboardingStatus: Number(localStorage.getItem('onboardingStatus')),
      outlookUrl: '',
      csvModal: false,
    };
    this.toggle = this.toggle.bind(this);
    this.toggleError = this.toggleError.bind(this);
    this.toggleOutlook = this.toggleOutlook.bind(this);
    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.appPlayStore = this.appPlayStore.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
      successUploading: true,
      errorUploading: true,
    });
  }

  UNSAFE_componentWillMount() {
    //console.log("console.log(window.location.href)", console.log(window.location.hostname));
    let data = {};
    data['accountId'] = this.state.accountId;
    data['page'] = 0;
    data['displayLimit'] = 10;
    this.props.getAllContactAction(data);
    this.props.getAllCppRoleAction({ className: 'cppRoles', accountId: this.state.accountId });
    this.props.getAllContactRoleAction({
      className: 'contactRoles',
      accountId: this.state.accountId,
    });
  }

  componentDidMount() {
    const script = document.createElement('script');
    script.src = 'https://apis.google.com/js/client.js';
    script.async = true;
    document.body.appendChild(script);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.isOnboarding !== this.props.isOnboarding) {
      this.props.history.push({
        pathname: '/import-projects',
      });
    }

    if (nextProps.isOnboardingError !== this.props.isOnboardingError) {
      this.props.history.push({
        pathname: '/import-projects',
      });
    }

    if (nextProps.contact && nextProps.contact?.response?.length > 0) {
      this.setState({
        showSkip: true,
      });
    }
    if (nextProps.outlookResponse && nextProps.outlookResponse.success === 1) {
      this.setState({
        outlookUrl: nextProps.outlookResponse.data,
      });

      window.location.assign(nextProps.outlookResponse.data);
      //this.props.history.push({ pathname: nextProps.outlookResponse.data })
      nextProps.outlookResponse.success = 0;
    }

    /*if (nextProps.allCppRole && nextProps.allCppRole.length > 0) {
            let allCppRole = [];
            nextProps.allCppRole.map((v) => {
                if (v.objectId !== '' && v.roleName !== '') {
                    allCppRole.push(v.roleName);
                }
                return true;
            });
            csvHeaders[10] = 'Internal Role :' + allCppRole.join(', ');
        }
        if (nextProps.allContactRole && nextProps.allContactRole.length > 0) {
            let contactRole = [];
            nextProps.allContactRole.map((v) => {
                if (v.objectId !== '' && v.contactRole !== '') {
                    contactRole.push(v.contactRoleName);
                }
                return true;
            });
            csvHeaders[11] = 'External Role:' + contactRole.join(', ');
        }*/

    if (nextProps.contactResponse && nextProps.contactResponse.count > 0) {
      this.setState({ socialContact: true });
    }
  }

  appPlayStore(store) {
    /* if(store=="apple"){
             this.props.history.push({ pathname: "https://www.apple.com/in/ios/app-store/"})
         }else if(store=="google"){
             this.props.history.push({ pathname: "https://play.google.com/store?hl=en"})
         }*/
  }

  NextStep = (e) => {
    e.preventDefault();
    let data = {
      userId: this.state.userId,
      status: 3,
    };
    this.props.skipOnboardingAction(data);
  };

  cancelButton() {
    if (this.props.location.state.redirectOn) {
      this.props.history.push({
        pathname: this.props.location.state.redirectOn,
        //state:{"row":row}
      });
    }
  }

  toggleOutlook() {
    //var redirectUrl = "https://"+window.location.hostname+"/view-contact-outlook";

    let data = {
      redirectUrl: 'https://' + window.location.hostname + '/view-contact-outlook',
      accountId: this.state.accountId,
    };
    this.props.createContactSocialOutlookAction(data);
  }

  toggleError() {
    this.setState({
      modalError: !this.state.modalError,
      successUploading: true,
      errorUploading: true,
    });
  }

  handleSubmit() {
    if (this.allValidate(false)) {
      alert('true');
    }
    alert('false');
  }

  allValidate(check) {
    if (!check) {
      formArr = [];
      Object.keys(rules).forEach(function (key) {
        formArr[key] = 'TT';
      });
      this.setState({
        formArr,
      });
    }
    if (validation.passes()) {
      return 1;
    }
  }

  auth() {
    var config = {
      client_id: 'appConstants.GOOGLE_CLIENT_ID',
      scope: 'https://www.google.com/m8/feeds',
    };
    let _this = this;
    window.gapi.auth.authorize(config, function () {
      _this.fetch(window.gapi.auth.getToken());
    });
  }

  fetch(token) {
    let _this = this;
    $.ajax({
      url: 'https://www.google.com/m8/feeds/contacts/default/full?alt=json',
      dataType: 'json',
      data: token,
    }).done(function (response) {
      //process the response here
      var entries = response.feed.entry;
      var contacts = [];
      for (var i = 0; i < entries.length; i++) {
        var contactEntry = entries[i];
        var contact = { emailArr: [], phoneArr: [] };

        console.log('iii', contactEntry);
        // Get Full Name.
        if (typeof contactEntry.gd$name !== 'undefined') {
          if (typeof contactEntry.gd$name.gd$fullName !== 'undefined') {
            if (typeof contactEntry.gd$name.gd$fullName.$t !== 'undefined') {
              var stringParts = contactEntry.gd$name.gd$fullName.$t;
              contact['firstName'] = stringParts.substr(0, stringParts.indexOf(' '));
              contact['lastName'] = stringParts.substr(stringParts.indexOf(' ') + 1);
            }
          }
        }

        if (contactEntry.title && contactEntry.title.$t) {
          var str = contactEntry.title.$t;
          contact['firstName'] = str.substr(0, str.indexOf(' '));
          contact['lastName'] = str.substr(str.indexOf(' ') + 1);
        }

        // Get Phone Number
        if (typeof contactEntry['gd$phoneNumber'] !== 'undefined') {
          var phoneNumber = contactEntry['gd$phoneNumber'];
          for (var j = 0; j < phoneNumber.length; j++) {
            if (typeof phoneNumber[j]['$t'] !== 'undefined') {
              var phone = phoneNumber[j]['$t'];
              var a = Number(phoneNumber[j]['$t']);
              if (typeof a === 'number') {
                contact['phoneNumber'] = phone;
                if (typeof contact['phoneArr'][j] === 'undefined') {
                  contact['phoneArr'][j] = {};
                }
                contact['phoneArr'][j]['phone'] = phone;
                contact['phoneArr'][j]['type'] = 'Work';
                contact['phoneArr'][j]['ext'] = '+1';
              }
            }
          }
        }

        // get Email Address
        if (typeof contactEntry['gd$email'] !== 'undefined') {
          var emailAddresses = contactEntry['gd$email'];
          for (var jj = 0; jj < emailAddresses.length; jj++) {
            if (typeof emailAddresses[jj]['address'] !== 'undefined') {
              var emailAddress = emailAddresses[jj]['address'];
              contact['email'] = emailAddress;
              if (contact['email'] !== emailAddress) {
                contact['emailArr'].push(emailAddress);
              }
            }
          }
        }
        contacts.push(contact);
      }
      let postData = {};
      postData['accountId'] = _this.state.accountId;
      postData['userId'] = _this.state.userId;
      postData['dataArr'] = contacts;

      console.log('postData', postData);
      _this.props.importContactSocialMediaAction(postData, _this.props);
    });
  }

  handleChangeInput(e) {
    e.preventDefault();
    let formData = this.state.formData;
    formData[e.target.name] = e.target.value;
    this.setState({
      formData: formData,
    });
  }

  closeCSVModal = () => {
    this.setState({
      csvModal: false,
    });
  };

  render() {
    const { location } = this.props;
    validation = new Validator(this.state.formData, rules, mess);
    validation.passes();
    validation.fails();
    return (
      <div className='ContactListReport'>
        <TitleComponent data={{ title: `Import Contacts` }} />
        {(true||this.state.onboardingStatus > 2) && <Header props={this.props} />}
        {/*this.state.onboardingStatus <= 2 && <HeaderOnboarding props={this.props} />*/}
        <div className='container'>
          <div className='addContact onboarding-section'>
            <div className='contactBox'>
              <div className='row'>
                <div className='col-md-10'>
                  <div className='pageinfo'>
                    <h2>Import Contacts</h2>
                    <p>
                      Importing your contacts is the fastest way to get all your team members into
                      Command Post so you can add them to projects and have complete vision into
                      your business. Command Post takes your privacy very seriously. We will not
                      share your contacts information in any way for any reason, We will not spam
                      your contacts.
                    </p>
                  </div>
                </div>
                {/*this.state.onboardingStatus <= 2 && (
                  <div className='onboardingSkip-bt col-sm-1'>
                    <Button onClick={(e) => this.NextStep(e)}> Skip</Button>
                  </div>
                )}

                {location && location.state && location.state.redirectOn && (
                  <div className='onboardingSkip-bt  col-sm-1'>
                    <Button onClick={(e) => this.cancelButton(e)}> Cancel</Button>
                  </div>
                )*/}
              </div>

              <div className='row'>
                {/*}
                                <div className='col-md-4'>
                                    <div className='Colbox onboarding-box'>
                                        <h4>IMPORT FROM YOUR PHONE</h4>
                                        <div className='textdesc paragraph'>
                                            <p>
                                                Importing contacts is easiest with our mobile app.
                                                Just a few taps and you're done!
                                            </p>
                                        </div>
                                        <div className='form-group'>
                                            <input
                                                type='button'
                                                onClick={this.appPlayStore('apple')}
                                                value='App Store'
                                                className='btn btn-primary mr-2'
                                            />
                                            <input
                                                type='button'
                                                onClick={this.appPlayStore('google')}
                                                value='Play Store'
                                                className='btn btn-primary'
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div className='Colbox onboarding-box'>
                                        <h4>CONNECT TO ONLINE ACCOUNTS</h4>
                                        <div className='textdesc paragraph'>
                                            <p>
                                                Connect to an online account to automatically gather
                                                your contacts.
                                            </p>
                                        </div>
                                        <div className='form-group'>
                                            <input
                                                type='button'
                                                onClick={this.useAuth().bind(this)}
                                                value='Gmail'
                                                className='btn btn-primary'
                                            />
                                            <input
                                                type='button'
                                                onClick={this.toggleOutlook}
                                                value='Outlook'
                                                className='btn btn-primary'
                                            />
                                            {/*
                                           <input type="button" onclick="window.location = 'add-multiple-contact.php'" value="Mail" className="btn btn-primary" />
                                           
                                        </div>
                                    </div>
                                </div>
                                */}
                <div className='col-md-4'>
                  <div className='Colbox onboarding-box'>
                    <h4>IMPORT FROM FILES</h4>
                    <div className='row'>
                      <div className='col-sm-6'>
                        <p>
                          <CSVLink
                            headers={csvHeaders}
                            data={csvData}
                            filename={'addContact.csv'}
                            className=''
                          >
                            <img
                              src={csv_icon_download}
                              className='rounded mx-auto'
                              alt='download-csv'
                              width='100'
                              height='120'
                            ></img>
                            <br></br>
                            <br />
                            Download the template
                          </CSVLink>
                          <br />, add your contact and their details, then upload to Command Post.
                        </p>
                        {this.state.socialContact && (
                          <p>
                            <Link
                              to={{
                                pathname: `/social-contact-view/` + this.state.accountId,
                              }}
                            >
                              See Temporary Contacts of Social Media (Gmail and Outlook) &amp;
                              Phonebook in the list
                            </Link>
                          </p>
                        )}
                      </div>
                      <div className='col-sm-6'>
                        <span
                          className='fileHyperLink'
                          onClick={() => {
                            this.setState({
                              csvModal: true,
                            });
                          }}
                        >
                          <img
                            src={csv_icon_upload}
                            className='rounded mx-auto'
                            alt='download-csv'
                            width='100'
                            height='120'
                          ></img>
                          <br />
                          <br></br>Upload Your Contacts
                        </span>{' '}
                        to CommandPost using the completed form<br></br>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <p className='mb-0'>
                    <button className="btn btn-primary" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                      Tips & Tricks
                    </button>
                  </p>
                  <div className="collapse" id="collapseExample">
                    <div className="row row-cols-1 row-cols-md-3 g-5 mb-5 m-auto w-75">
                      <div className="col">
                        <div className="card text-dark bg-light h-100">
                          <div className="card-header">Contact data assignment</div>
                          <img className="card-img-top" src='/static/media/importContactsGuide/companyName.jpg' title="Contact data assignment" alt="Contact data assignment" />
                          <div className="card-body">
                            <h5 className="card-title">"Company Name" column</h5>
                            <p className="card-text">When "Company Name" column is empty contact data like emails/phones/addresses would be assigned to the contact's self affiliation.</p>
                          </div>
                        </div>
                      </div>
                      <div className="col">
                        <div className="card text-dark bg-light h-100">
                          <div className="card-header">Multiple contact data</div>
                          <img className="card-img-top" src='/static/media/importContactsGuide/miltipleEmail.jpg' title="Multiple contact data" alt="Multiple contact data" />
                          <div className="card-body">
                            <h5 className="card-title">"Email/Phone/Address" columns</h5>
                            <p className="card-text">To assign few emails/phones/addresses to the contact just create additional columns for corresponding fields. E.g. "Email" and "Email Label" for first email and "Email 2" and "Email 2 Label" for second one.</p>
                          </div>
                        </div>
                      </div>
                      <div className="col">
                        <div className="card text-dark bg-light h-100">
                          <div className="card-header">Multiple companies</div>
                          <img className="card-img-top" src='/static/media/importContactsGuide/multipleCompany.jpg' title='Multiple companies' alt="Multiple companies" />
                          <div className="card-body">
                            <h5 className="card-title">"Company Name" column</h5>
                            <p className="card-text">In order to create multiple companies affiliations, create a new row in CSV file for each affiliation, separating contacts with empty row (or providing another name for contact). Affiliations name fields could be empty or contain the same values as the first one.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />

        <UploadCsvContactsModal open={this.state.csvModal} onClose={this.closeCSVModal} />

        <Modal
          backdrop='static'
          keyboard={false}
          isOpen={this.state.modalError}
          toggle={this.toggleError}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggleError}>Error</ModalHeader>
          <ModalBody>
            <p>Please download the correct sample format file and upload it again</p>
          </ModalBody>
          <ModalFooter>
            <Button className='btn btn-primary' onClick={this.toggleError}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>

        {/* model for outlook user*/}
        <Modal
          backdrop='static'
          keyboard={false}
          isOpen={this.state.modalOutlook}
          toggle={this.toggleOutlook}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggleOutlook}>Sign in</ModalHeader>
          <ModalBody>
            <div className='main-div'>
              <div className='panel'>
                <p>Please enter your email and password</p>
              </div>
              <form name='form' onSubmit={this.handleSubmit}>
                <div className='form-group'>
                  <input
                    type='email'
                    className='form-control'
                    name='username'
                    placeholder='Email Address'
                    value={this.state.username}
                    onChange={(e) => {
                      this.handleChangeInput(e);
                    }}
                  />
                </div>
                <div className='form-group'>
                  <input
                    type='password'
                    className='form-control'
                    name='password'
                    placeholder='Password'
                    value={this.state.password}
                    onChange={(e) => {
                      this.handleChangeInput(e);
                    }}
                  />
                </div>
              </form>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button className='btn btn-primary' onClick={this.toggleOutlook}>
              Cancel
            </Button>
            <Button type='submit' className='btn btn-primary' onClick={this.handleSubmit}>
              Login
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    contact: state.contact,
    allContractType: state.contractType.allContractType,
    outlookResponse: state.multipleContact.outlookResponse,
    allCppRole: state.cppRole.allCppRole,
    reducerType: state.multipleContact.reducerType,
    contactResponse: state.multipleContact.response,
    onboarding: state.multipleContact.onboarding,
    isOnboarding: state.multipleContact.isOnboarding,
    isOnboardingError: state.multipleContact.isOnboardingError,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    getAllContactAction,
    createMultipleContactAction,
    importContactSocialMediaAction,
    createContactSocialOutlookAction,
    getAllCppRoleAction,
    getAllContactRoleAction,
    getContactSocialMediaAction,
    skipOnboardingAction,
  })(OnboardContactAdd)
);
