/* eslint-disable react/display-name */
import { notification } from 'antd';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { loadCompanies } from '../../modules/companies';
import { DataGridTable } from '../../shared/data-grid';
import useRowSelection from '../../shared/data-grid/use-row-selection';
import { columnDescriptor } from '../../shared/data-grid/use-row-selection/use-row-selection';
import { cellFormatter, uploadDescriptor } from '../../shared/data-grid/import-components';
import { getImportingCompanies } from '../../modules/companies/selectors';

const ImportCompaniesView = () => {
  const { data: jsonRows } = useSelector(getImportingCompanies);

  const selectionState = useRowSelection({ data: jsonRows });

  const { selected, getSelectedSomething, setSelected } = selectionState;

  const isSomethingSelected = getSelectedSomething();

  const columns = useMemo(
    () => [
      columnDescriptor,
      uploadDescriptor,
      {
        Header: 'Company',
        accessor: 'companyName',
        Cell: cellFormatter(),
        width: 250,
      },
      {
        Header: 'DBA Name',
        accessor: 'dba',
        Cell: cellFormatter(),
        width: 250,
      },
      {
        Header: 'Website',
        accessor: 'companyWebsite',
        Cell: cellFormatter(),
        width: 120,
      },
      {
        Header: 'Email',
        accessor: 'companyEmail',
        Cell: cellFormatter(),
        width: 120,
      },
      {
        Header: 'Phone Type',
        accessor: 'phoneType',
        Cell: cellFormatter(),
        width: 100,
      },
      {
        Header: 'Country Code',
        accessor: 'countryCode',
        Cell: cellFormatter(),
        width: 80,
      },
      {
        Header: 'Phone Number',
        accessor: 'phoneNumber',
        Cell: cellFormatter(),
        width: 100,
      },
      {
        Header: 'Tax ID',
        accessor: 'taxId',
        Cell: cellFormatter(),
        width: 100,
      },
      {
        Header: 'Street Address',
        accessor: 'companyAddress1',
        Cell: cellFormatter(),
        width: 200,
      },
      {
        Header: 'Suite/Unit',
        accessor: 'companyAddress2',
        Cell: cellFormatter(),
        width: 200,
      },
      {
        Header: 'City',
        accessor: 'city',
        Cell: cellFormatter(),
        width: 120,
      },
      {
        Header: 'State',
        accessor: 'state',
        Cell: cellFormatter(),
        width: 80,
      },
      {
        Header: 'Zip Code',
        accessor: 'zipCode',
        Cell: cellFormatter(),
        width: 120,
      },
      {
        Header: 'Country',
        accessor: 'country',
        Cell: cellFormatter(),
        width: 120,
      },
      {
        Header: 'Description',
        accessor: 'description',
        Cell: cellFormatter(),
        width: 120,
      },
      {
        Header: 'originId',
        accessor: 'originId',
        hidde: true,
        Cell: cellFormatter(),
        width: 120,
      }
    ],
    []
  );

  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!jsonRows.length) history.push('/import-companies');
  }, [jsonRows]);

  const [done, setDone] = useState(false);

  const onUploadClick = async () => {
    try {
      setLoading(true);
      const { successCount, total } = await dispatch(loadCompanies([jsonRows, selected])).unwrap();

      notification.success({
        message: `Uploaded ${successCount} / ${total} companies`,
      });

      setSelected({});

      setDone(true);
    } catch (e) {
      console.log(e);
    } finally {
      setDone(true);
      setLoading(false);
    }
  };

  const onContinueClick = () => {
    history.push('/view-companies');
  };

  const onBackClick = () => {
    history.goBack();
  };

  return (
    <div className='container'>
      
      <div className='d-flex justify-content-end py-3 gap-2'>
        <button className='btn btn-primary mr-auto' onClick={onBackClick}>
          Back
        </button>
        <span className='mr-auto align-self-center'><b>Import Companies</b>&emsp;&emsp;Total records: {jsonRows?.length || '0'}</span>
        <button
          className='btn btn-primary'
          disabled={!isSomethingSelected || loading}
          onClick={onUploadClick}
        >
          {loading ? 'Loading ...' : 'Upload'}
        </button>
        <button className='btn btn-primary' disabled={!done} onClick={onContinueClick}>
          Continue
        </button>
      </div>

      <DataGridTable
        useControlledState={(state) => ({
          ...state,
          ...selectionState,
        })}
        columns={columns}
        data={jsonRows}
      />
    </div>
  );
};

export default ImportCompaniesView;
