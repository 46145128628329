import _ from 'lodash';
import { notification } from 'antd';
import { joiResolver } from '@hookform/resolvers/joi';
import { FormProvider, useForm } from 'react-hook-form';
import { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import { useAppDispatch } from '../../shared/hooks/use-app-dispatch';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { getAllJobScopeAction } from '../../actions/jobScope';
import { getExistingProjectAction } from '../../actions/project';
import { getAccountProjectSettings } from '../../modules/accounts';
import {
  getUpdateProjectForm,
  getProjectOrigContract,
  updateProjectOrigContract,
  getProjectBid,
} from '../../modules/projects';
import {
  ManageContractTypeSchema,
  manageContractValidationSchema,
} from './manage-contract-modal.schema';
import { TopFields } from './sections/top-fields';
import { Header } from '../bid-modal/sections/header';
import { JobScopeLineItems } from '../bid-modal/sections/scope-estimates';
import { ProjectScheduler } from '../bid-modal/sections/project-scheduler';
import { formatDateObjectOrNull } from '../../utils/date-formatters';
import { formatEstData, calculateEstTotals } from '../../utils/number-formatters';
import {
  getProjectStageByCode,
  isCurrentOrPrevStage,
} from '../../shared/custom-fields-controls/constants';
import { BottomFields } from './sections/bottom-fields';
import { useWithPermissions } from '../../shared/with-permissions';
import { appConstants, moduleConstants } from '../../_constants';
import PropTypes from 'prop-types';
import { FillTemplateModal } from '../../components/Layout/TemplateFiller/FillTemplateModal';
//import moment from 'moment';

type ManageContractModalProps = {
  projectId: string;
  isOpen: boolean;
  toggle: () => void;
  onSubmit: (projectData: any) => void;
  onSuggestProgress?: (data: any) => void;
};

export const ManageContractModal = ({
  projectId = '',
  isOpen = false,
  toggle,
  onSubmit = () => null,
  //onSuggestProgress = () => null,
}: ManageContractModalProps) => {
  const dispatch = useAppDispatch();

  const defaultValues = {
    title: '',
    contract: '',
    contractDate: null,
    primeContract: false,
    contractType: null,
    isLumpSum: false,
    retainage: 0,
    description: '',
    exclusions: '',
    comment: '',
    startDate: null,
    endDate: null,
    projectDuration: '',
    estTotalCost: 0,
    estTotalProfit: 0,
    estTotalContract: 0,
    estData: [],
    revNum: 0,
    createEstimate: false,
  };

  const jobScopesRef = useRef<any>(null);
  const projectScheduleRef = useRef<any>(null);
  const [projectData, setProjectData] = useState<any>({});
  const [isLocked, setIsLocked] = useState<boolean>(false);
  const [, setAccountSettings] = useState<any>({});
  const [origContractData, setOrigContractData] = useState<any>({});
  const [isActiveProject, setIsActiveProject] = useState<boolean>(false);

  const hasManageContractPermission =  useWithPermissions({required: [moduleConstants.MANAGECONTRACT]}) || true;

  const form = useForm<ManageContractTypeSchema>({
    mode: 'onChange',
    defaultValues,
    resolver: joiResolver(manageContractValidationSchema),
  });

  const isSubmitting = form.formState.isSubmitting;

  const revNumWatch = form.watch('revNum', 0);

  const initialEstData = useCallback(() => {
    return (projectData.scope ?? [])
      .map((id: string, index: number) =>
        jobScopesRef.current?.buildScope(id, index === 0, false, projectData, origContractData)
      )
      .filter(Boolean);
  }, [projectData, jobScopesRef.current]);

  useEffect(() => {
    dispatch(getAllJobScopeAction({ className: 'jobScopes', includeDisabled: true }));
    dispatch(getAccountProjectSettings({}))
      .unwrap()
      .then((data: any) => setAccountSettings({ ...data }));
  }, []);

  useEffect(() => {
    if (isOpen && !isSubmitting) {
      form.reset({ ...defaultValues });
      dispatch(getUpdateProjectForm({ projectId }))
        .unwrap()
        .then((data) => {
          setProjectData({
            ...data,
            currentGrossProfit: data.originalGrossProfit, // for totals
            currentContractAmount: data.originalContractAmount, // for totals
          });

          const { jobStatusCodesCode } = data;

          const projectStage = getProjectStageByCode(jobStatusCodesCode);
          const isActive = isCurrentOrPrevStage(projectStage, 'active');

          setIsLocked(isActive);
          setIsActiveProject(isActive);

          if (isActive) {
            dispatch(getProjectOrigContract({ projectId }))
              .unwrap()
              .then((data) => {
                // if data is an object that is not empty, assign data
                if (_.isObject(data) && !_.isEmpty(data)) {
                  setOrigContractData({ ...data });
                }else{
                  // show notification if no data is returned
                  notification.error({ message: 'No contract data found' });
                }
              });
          } else if (data.curBidId || data.lastBidId) {
            dispatch(getProjectBid({ projectId, bidId: data.curBidId || data.lastBidId }))
              .unwrap()
              .then((data) => {

                const origDataFromBid = {
                  description: data.comment,
                  exclusions: data.exclusions,
                  baselineStartDate: data.estStartDate,
                  baselineEndDate: data.estEndDate,
                  estTotalCost: data.estTotalCost,
                  estTotalProfit: data.estTotalProfit,
                  estTotalContract: data.estTotalContract,
                  estData: formatEstData(data.estData),
                };

                setOrigContractData({ ...origDataFromBid });
              });
          }
        });

      projectScheduleRef.current?.setIsExpanded(true);
    } else {
      setProjectData({});
      setOrigContractData({});
      setContractLoaded(false);
      form.reset({ ...defaultValues });
    }
  }, [isOpen, projectId, projectScheduleRef.current]);

  const [contractLoaded, setContractLoaded] = useState(false);

  useEffect(() => {
    if (isOpen && !_.isEmpty(projectData) && !isSubmitting && !contractLoaded) {
      const { contractDate = new Date(), estData = [] } = origContractData;
      const { cost, profit, amount } = calculateEstTotals(estData);

      const { baselineStartDate, baselineEndDate } = projectData;
      const currEstData = estData.length === 0 ? initialEstData() : estData;

      form.reset(
        {
          title: origContractData.title || '',
          contract: origContractData.contract || '',
          contractDate: formatDateObjectOrNull(contractDate),
          primeContract: !!origContractData.primeContract,
          contractType: origContractData.contractType || '',
          isLumpSum: !!origContractData.isLumpSum,
          retainage: origContractData.retainage || 0,
          description: origContractData.description || '',
          exclusions: origContractData.exclusions || '',
          comment: '', // always empty by default
          estTotalCost: cost,
          estTotalProfit: profit,
          estTotalContract: amount,
          estData: formatEstData(currEstData),
          startDate: formatDateObjectOrNull(baselineStartDate),
          endDate: formatDateObjectOrNull(baselineEndDate),
          revNum: origContractData.revNum,
          createEstimate: false,
        },
        { keepDefaultValues: true }
      );

      setContractLoaded(true);
    }else if (!isOpen) {
      setContractLoaded(false);
    }
  }, [isOpen, projectData, origContractData, projectId]);

  /*const areDatesChanged = useCallback(
    (values: any) => {
      const { baselineStartDate, baselineEndDate } = projectData;
      const initialBaselineStartDateObj = moment(formatDateObjectOrNull(baselineStartDate)).startOf(
        'day'
      );
      const initialBaselineEndDateObj = moment(formatDateObjectOrNull(baselineEndDate)).startOf(
        'day'
      );

      const currentBaselineStartDateObj = moment(formatDateObjectOrNull(values.startDate)).startOf(
        'day'
      );
      const currentBaselineEndDateObj = moment(formatDateObjectOrNull(values.endDate)).startOf(
        'day'
      );

      const startDateDifference = currentBaselineStartDateObj
        ?.startOf('day')
        .diff(initialBaselineStartDateObj, 'days');
      const endDateDifference = currentBaselineEndDateObj
        ?.startOf('day')
        .diff(initialBaselineEndDateObj, 'days');

      return { startDateChange: startDateDifference, endDateChange: endDateDifference };
    },
    [projectData]
  );*/

  /*const suggestUpdateProgress = useCallback(async () => {
    const values = form.getValues();
    const { restrictContractAdjustments } = accountSettings;

    const { estData: currentEstData } = values;
    const { estData: initialEstData } = origContractData;

    let { profit: initProfit, amount: initAmount } = calculateEstTotals(initialEstData || []);

    // in case if it was new project without scopes
    if (!initialEstData) {
      initProfit = projectData.grossProfit;
      initAmount = projectData.contractAmount;
    }

    const { profit: currProfit, amount: currAmount } = calculateEstTotals(currentEstData || []);

    const { startDateChange, endDateChange } = areDatesChanged(values);
    const profitChanged = initProfit !== currProfit;
    const amountChanged = initAmount !== currAmount;
    const datesChanged = startDateChange !== 0 || endDateChange !== 0;

    if (!datesChanged && !profitChanged && amountChanged && restrictContractAdjustments) {
      return;
    }

    if (datesChanged || profitChanged || amountChanged) {
      const updateData = {
        isCO: false,
        projectId,
        startDate: values.startDate,
        startDateChange: startDateChange,
        endDate: values.endDate,
        endDateChange: endDateChange,
        total: currAmount,
        profit: currProfit,
        totalChange: currAmount - initAmount,
        profitChange: currProfit - initProfit,
      };

      onSuggestProgress && onSuggestProgress(updateData);
    }
  }, [projectId, projectData, origContractData, accountSettings]);*/

  const validationError = useMemo(() => {
    const errors = [...Object.values(form.formState.errors)];

    return <span className='text-danger'>{errors[0]?.message as string}</span>;
  }, [form.formState.errors]);

  const onFormSubmit = () => {
    console.log("Form submission started");

    if (form.formState.errors && Object.keys(form.formState.errors).length > 0) {
      console.log("Form validation errors:", form.formState.errors);
    }

    form.trigger().then(() => {
      console.log("Form triggered, proceeding with submission");

      form.handleSubmit(
        async (data) => {
          console.log("Form submitted successfully, data:", data);

          if (isLocked) {
            console.log("Form is locked, returning");
            return;
          }

          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const estData = data.estData.map(({ allowChangeScope, ...rest }) => rest);

          if (estData.length == 0) {
            console.log("No job scope added");
            notification.error({ message: 'Please add job scope' });
            return;
          }

          const { cost, profit, amount } = calculateEstTotals(estData);

          const projectOrigData = {
            projectId,
            title: data.title,
            contract: data.contract,
            contractDate: formatDateObjectOrNull(data.contractDate),
            primeContract: data.primeContract,
            contractType: data.contractType,
            isLumpSum: data.isLumpSum,
            retainage: data.retainage,
            description: data.description,
            exclusions: data.exclusions,
            comment: data.comment,
            baselineStartDate: data.startDate,
            baselineEndDate: data.endDate,
            originalCost: cost,
            originalGrossProfit: profit,
            originalContractAmount: amount,
            estData: formatEstData(estData, false),
            createEstimate: data.createEstimate,
          };

          try {
            await dispatch(updateProjectOrigContract({ ...projectOrigData })).unwrap();
            //await suggestUpdateProgress();
            toggle();
            onSubmit(projectOrigData);
            await dispatch(getExistingProjectAction({ projectId }));
          } catch (error) {
            console.error("Error during form submission:", error);
            notification.error({ message: 'An error occurred while saving the contract' });
          }
        },
        (errors) => {
          console.log("Form validation failed, errors:", errors);
          notification.error({ message: 'Please fill out all required fields' });
          const projectScheduleFields = ['startDate', 'endDate'];
          const fieldsWithError = Object.keys(errors);

          if (_.intersection(projectScheduleFields, fieldsWithError).length > 0) {
            projectScheduleRef.current.setIsExpanded(true);
          }
        }
      )();
    }).catch((error) => {
      console.error("Error during form trigger:", error);
    });
  };

  const accountSettingsMock = { requireConstructionDatesOn: 'active' };

  const [detailedMode, setDetailedMode] = useState(true);


  const [fillTemplateOpen, setFillTemplateOpen] = useState(false);
  const toggleFillTemplate = () => {
    setFillTemplateOpen(!fillTemplateOpen);
  }

  const ModalHeader = ({ toggleFn, toggleFillTemplate, isLocked }) => {
    return (
      <div className="modal-header">
        <h5 className="modal-title">
          {isLocked ? 'View' : 'Manage'} original contract
        </h5>
        <div className="ms-auto align-right">
         {!(appConstants.IS_PRODUCTION)&&(<span
            onClick={toggleFillTemplate}
            //variant="outline"
            className="ms-auto px-2 py-1"
          >
            <i className='fa fa-file-text-o'/>
          </span>)}
          <button className="btn-close" aria-label="Close" onClick={toggleFn} />
        </div>
      </div>
    );
  };
  
  ModalHeader.propTypes = {
    toggleFn: PropTypes.func.isRequired,
    toggleFillTemplate: PropTypes.func.isRequired,
    isLocked: PropTypes.bool.isRequired,
  };


  return (
    <>
    <Modal backdrop='static' isOpen={isOpen} toggle={toggle} size={detailedMode ? 'xl' : 'lg'}>
      { ModalHeader({ toggleFn: toggle, toggleFillTemplate, isLocked }) }
      <ModalBody>
        <div className='container'>
          <FormProvider {...form}>
            <Header projectData={projectData} />
            <br />
            <TopFields isLocked={isLocked} />
            <BottomFields isLocked={isLocked} isActiveProject={isActiveProject} />
            <ProjectScheduler
              isLocked={isLocked}
              projectStage='active'
              accountSettings={accountSettingsMock}
              ref={projectScheduleRef}
            />
            <JobScopeLineItems
              isLocked={isLocked}
              projectData={projectData}
              ref={jobScopesRef}
              projectBidData={origContractData}
              toggleDetailedModeCallback={setDetailedMode}
              isChangeOrder={false}
            />
            <br />
          </FormProvider>
        </div>
      </ModalBody>
      <ModalFooter>
        <div>
          <span className='fw-bold'>Revision: {revNumWatch + 1}</span>
        </div>
        {validationError}
        <button className='ms-auto btn btn-primary' onClick={toggle}>
          {isLocked ? 'Close' : 'Cancel'}
        </button>
        {hasManageContractPermission ? (
        isLocked ? (
          <button className='btn btn-primary' onClick={() => setIsLocked(false)}>
            {isSubmitting ? 'Loading...' : 'Unlock & Edit Contract'}
          </button>
        ) : (
          <button className='btn btn-primary' disabled={isSubmitting} onClick={onFormSubmit}>
            {isSubmitting ? 'Saving...' : 'Save'}
          </button>
        )
        ) : null}
      </ModalFooter>
    </Modal>
    {fillTemplateOpen && (
      <FillTemplateModal
        open={fillTemplateOpen}
        toggle={() => toggleFillTemplate()}
        objectId={origContractData?.id || projectId}
        formType={'contract'}
      ></FillTemplateModal>
    )}
    </>
  );
};
