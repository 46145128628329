import { useEffect,  useState } from 'react';

type IntegerInputProps = {
  digits?: number;
  strictDigits?: boolean;
  onChange?: any;
  value: string;
};

export const IntegerInput = ({
  digits = 10,
  strictDigits = false,
  ...props
}: IntegerInputProps) => {
  const [focused, setFocused] = useState(false);

  const onKeyPress = (e: React.KeyboardEvent) => {
    if (e.key.length > 1) return;

    const digit = Number(e.key);

    if (isNaN(digit) || e.key === ' ') {
      e.preventDefault();
      return;
    }
  };

  useEffect(() => {
    if (focused) return;
    if (!strictDigits) return;

    props.onChange(String(props.value).padStart(digits, '0'));

  }, [props.value, focused]);

  return (
    <input
      maxLength={digits}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      onKeyDown={onKeyPress}
      {...props}
    />
  );
};
