import axios from 'axios';
import cpostLogo from '../../../components/static/images/cpostLogo.png';
import iqbLogo from '../../../components/static/images/iqb_logo.png';
import pcLogo from '../../../components/static/images/pc_logo.png';
//import sageLogo from '../../../components/static/images/sage_logo.png';
//import emLogo from '../../../components/static/images/em_logo.png';
import spLogo from '../../../components/static/images/sp_logo.png';
//import cmLogo from '../../../components/static/images/cm_logo.png';
//import pmLogo from '../../../components/static/images/pm_logo.png';
import styles from './styles.module.scss';

import { useEffect } from 'react';
import { appConstants } from '../../../_constants';
import { getAccountSubscriptionInfo } from '../../../modules/accounts';
import { useAppDispatch } from '../../../shared/hooks/use-app-dispatch';
import { useForm } from 'react-hook-form';

type AppConnectionsPageProps = {
  accountId: string;
};

export const AppConnectionsPage = ({ accountId }: AppConnectionsPageProps) => {
  const dispatch = useAppDispatch();

  const form = useForm({
    defaultValues: {
      itemIds: [],
      chargebeeId: '',
      customerEmail: '',
      companyName: '',
      status: '',
      nextBillingAt: new Date(0),
      nextBillingAmount: 0,
      billing_period: 0,
      billing_period_unit: '',
      planName: '',
      addonNames: [],
      amount: -999,
      trialEnd: new Date(0),
      created_at: new Date(0),
      chargebeePlatformName: 'commandtech',
      accessUrl: null,
      contactMasterStatus: null,
      projectMasterStatus: null,
    },
  });

  const contactMasterURL = process.env.REACT_APP_CONTACTMASTER_URL;
  const projectMasterURL = process.env.REACT_APP_PROJECTMASTER_URL;
  const entityMasterURL = process.env.REACT_APP_ENTITYMASTER_URL;

  const getConnectionStatusFromSubscription = ({
    connectionName,
    systemName,
    subscriptionInfo,
  }: any) => {
    let connectionStatus;
    if (systemName === 'ContactMaster') {
      connectionStatus = subscriptionInfo.contactMasterStatus;

      if (['sage'].includes(connectionName)) {
        return 'comingSoon';
      }
      if (['sharepoint'].includes(connectionName)) {
        return 'NA';
      }
    } else if (systemName === 'ProjectMaster') {
      connectionStatus = subscriptionInfo.projectMasterStatus;

      if (['sage'].includes(connectionName)) {
        return 'comingSoon';
      }
    } else if (systemName === 'EntityMaster') {
      connectionStatus = subscriptionInfo.entityMasterStatus;
      return 'comingSoon';
    }

    if (!connectionStatus && subscriptionInfo.status && connectionName === 'commandpost')
      return 'upgrade';

    if (!connectionStatus) return 'comingSoon';



    if (['active', 'in_trial'].includes(connectionStatus)) {
      return 'manage';
    } else {
      return 'upgrade';
    }

    /*const connectionMap = new Map()
      .set('commandpost', 'CP-Pro')
      .set('quickbooks', 'Quickbooks')
      .set('procore', 'Procore')
      .set('sharepoint', 'Sharepoint')
      .set('sage', 'Sage')*/
  };

  const ConnectionButton = ({ connectionName, systemName, subscriptionInfo }: any) => {
    let action = 'comingSoon';

    // check connection strings here
    action = getConnectionStatusFromSubscription({ connectionName, systemName, subscriptionInfo });

    const actionTextMap = new Map()
      .set('upgrade', 'Upgrade')
      .set('connect', 'Connect')
      .set('reconnect', 'Reconnect')
      .set('comingSoon', 'Coming Soon')
      .set('manage', 'Manage');

    const systemURLmap = new Map()
      .set('ContactMaster', contactMasterURL)
      .set('ProjectMaster', projectMasterURL)
      .set('EntityMaster', entityMasterURL);

    if (action === 'upgrade') {
      const checkoutItemName = new Map()
        .set('commandpost', 'Command-Post')
        .set('quickbooks', 'Quickbooks')
        .set('procore', 'Procore')
        .set('sharepoint', 'Sharepoint')
        .set('sage', 'Sage');



      return (
        <div className={styles.connectionButtonWrapper}>
          <a
            onClick={() => onCheckoutClick(systemName as string, checkoutItemName.get(connectionName) as string)}
            rel='noreferrer'
            target='_blank'
            data-action={action}
          >
            {actionTextMap.get(action)}
          </a>
        </div>
      );
    } else if (action === 'comingSoon') {
      return (
        <div className={styles.connectionButtonWrapper}>
          <a data-action={action}> {actionTextMap.get(action)}</a>
        </div>
      );
    }



    const baseUrl = systemURLmap.get(systemName) as string;
    const accessUrl = (baseUrl ?? '?') + '/connection/' + (connectionName ?? '?') + '/manage';

    return (
      <div className={styles.connectionButtonWrapper}>
        <a href={accessUrl} rel='noreferrer' target='_blank' data-action={action}>
          {actionTextMap.get(action)}
        </a>
      </div>
    );
  };

  useEffect(() => {
    dispatch(getAccountSubscriptionInfo({ accountId: accountId }))
      .unwrap()
      .then((data) => {
        form.reset({
          itemIds: data.result.itemIds,
          customerEmail: data.result.customerEmail,
          companyName: data.result.companyName,
          status: data.result.status,
          chargebeeId: data.result.chargebeeId,
          nextBillingAmount: data.result.nextBillingAmount,
          billing_period: data.result.billing_period,
          billing_period_unit: data.result.billing_period_unit,
          planName: data.result.planName,
          addonNames: data.result.addonNames,
          amount: data.result.amount,
          trialEnd: data.result.trialEnd,
          created_at: data.result.created_at,
          nextBillingAt: data.result.nextBillingAt,
          chargebeePlatformName: data.result.chargebeePlatformName,
          accessUrl: data.result.accessUrl,
          contactMasterStatus: data.result.contactMasterStatus,
          projectMasterStatus: data.result.projectMasterStatus,
        });
      });
  }, []);

  // const {
  //status,
  //  nextBillingAt,
  //   planName,
  //  created_at,
  //  chargebeePlatformName,
  //   customerEmail,
  //  companyName,
  //  accessUrl,
  //  addonNames,
  // } = form.getValues();

  //  const customerSince = formatDate(created_at);

  // const nextBillingDate = formatDate(nextBillingAt);

  const onCheckoutClick = async (systemName: string, checkoutType: string) => {
    // TODO: setup axios and redux toolkit

    const systemIdMap = new Map()
      .set('contactmaster', 'ContactMaster')
      .set('projectmaster', 'Project-Master')
      .set('entitymaster', 'EntityMaster');

    systemName = (systemName ?? '').toLowerCase();
    const systemId = systemIdMap.get(systemName) as string;


    const { result } = await axios
      .post(
        '/parse/functions/getChargebeeCheckout',
        {
          accountId: accountId,
          userId: localStorage.getItem('userId'),
          checkoutSystem: systemId + '-USD-Yearly',
          checkoutApp: checkoutType,
        },
        {
          baseURL: appConstants.WEB_SERVICE_URL_WITHOUT_PARSE,
          headers: {
            'X-Parse-Application-Id': appConstants.PARSE_APPLICATION_ID,
            'X-Parse-REST-API-Key': appConstants.PARSE_REST_API_KEY,
            'X-Parse-Session-Token': localStorage.getItem('sessionToken'),
            authorization: localStorage.getItem('JWTTOKEN'),
          },
        }
      )
      .then((response) => response.data);

    console.log(result.hosted_page);
    if (result.hosted_page) {
      const url = new URL(result.hosted_page);
      window.open(url);
    }
  };

  return (
    <div className='container'>
      <script src='https://js.chargebee.com/v2/chargebee.js'></script>
      <div className='flex row mt-2'>
        <h2 className='heading2 col-8'>Command Post Hub</h2>
      </div>
      <div className='row'>
        <div className='col-md-4'>
          <div className={styles.titleWrapper}>
            <span></span>
          </div>
        </div>
        <div className='col-md-4'>
          <div className={styles.connectionLogoWrapper}>
          <h2 className='heading2'> Contacts</h2>
            {/*<a href='#' target='_blank'>
              <img src={cmLogo} alt='ContactMaster' />
              
            </a>*/}
          </div>
        </div>
        <div className='col-md-4'>
          <div className={styles.connectionLogoWrapper}>
          <h2 className='heading2'> Projects</h2>
            {/* <a href='#' target='_blank'>
              <img src={pmLogo} alt='ProjectMaster' />
              
            </a>*/}
          </div>
        </div>
        {/*<div className='col-md-4'>
          <div className={styles.connectionLogoWrapper}>

          <h2 className='heading2'> Entity Sync</h2>
            {/* <a href='#' target='_blank'>
              <img src={pmLogo} alt='EntityMaster' />
              
            </a>}

            <b>Coming Soon</b>
          </div>
        </div>*/}
      </div>

      <div className='row'>
        <div className='col-12'>
          <div className={styles.divider}></div>
        </div>
      </div>

      <div className='row mb-4'>
        <div className='col-md-4'>
          <div className={styles.connectionLogoWrapper}>
            <a href='#' target='_blank'>
              <img src={cpostLogo} alt='CommandPost' />
            </a>
          </div>
        </div>
        <div className='col-md-4'>
          <ConnectionButton
            connectionName='commandpost'
            systemName='ContactMaster'
            subscriptionInfo={form.getValues()}
          />
        </div>
        <div className='col-md-4'>
          <ConnectionButton
            connectionName='commandpost'
            systemName='ProjectMaster'
            subscriptionInfo={form.getValues()}
          />
        </div>
        {/*<div className='col-md-4'>
          <ConnectionButton
            connectionName='commandpost'
            systemName='EntityMaster'
            subscriptionInfo={form.getValues()}
          />
        </div>*/}
      </div>

      <div className='row mb-3'>
        <div className='col-md-4'>
          <div className={styles.connectionLogoWrapper}>
            <a href='#' target='_blank'>
              <img src={pcLogo} alt='ProCore' />
            </a>

            
          </div>
        </div>
        <div className='col-md-4'>
          <ConnectionButton
            connectionName='procore'
            systemName='ContactMaster'
            subscriptionInfo={form.getValues()}
          />
        </div>
        <div className='col-md-4'>
          <ConnectionButton
            connectionName='procore'
            systemName='ProjectMaster'
            subscriptionInfo={form.getValues()}
          />
        </div>
         {/*<div className='col-md-4'>
          <ConnectionButton
            connectionName='commandpost'
            systemName='EntityMaster'
            subscriptionInfo={form.getValues()}
          />
        </div>*/}
      </div>

      <div className='row mb-0'>
        <div className='col-md-4'>
          <div className={styles.connectionLogoWrapper}>
            <a href='#' target='_blank'>
              <img src={iqbLogo} alt='QuickBooks' />
            </a>

            
          </div>
        </div>
        <div className='col-md-4'>
          <ConnectionButton
            connectionName='quickbooks'
            systemName='ContactMaster'
            subscriptionInfo={form.getValues()}
          />
        </div>
        <div className='col-md-4'>
          <ConnectionButton
            connectionName='quickbooks'
            systemName='ProjectMaster'
            subscriptionInfo={form.getValues()}
          />
        </div>
         {/*<div className='col-md-4'>
          <ConnectionButton
            connectionName='commandpost'
            systemName='EntityMaster'
            subscriptionInfo={form.getValues()}
          />
        </div>*/}
      </div>

      <div className='row mb-1'>
        <div className='col-md-4'>
          <div className={styles.connectionLogoWrapper}>
            <a href='#' target='_blank'>
              <img src={spLogo} alt='SharePoint' />
            </a>

            
          </div>
        </div>
        <div className='col-md-4'>
          <ConnectionButton
            connectionName='sharepoint'
            systemName='ContactMaster'
            subscriptionInfo={form.getValues()}
          />
        </div>
        <div className='col-md-4'>
          <ConnectionButton
            connectionName='sharepoint'
            systemName='ProjectMaster'
            subscriptionInfo={form.getValues()}
          />
        </div>
         {/*<div className='col-md-4'>
          <ConnectionButton
            connectionName='commandpost'
            systemName='EntityMaster'
            subscriptionInfo={form.getValues()}
          />
        </div>*/}
      </div>

      {/*<div className='row'>
        <div className='col-md-4'>
          <div className={styles.connectionLogoWrapper}>
            <a href='#' target='_blank'>
              <img src={sageLogo} alt='Sage' />
            </a>
          </div>
        </div>
        <div className='col-md-4'>
          <ConnectionButton
            connectionName='sage'
            systemName='ContactMaster'
            subscriptionInfo={form.getValues()}
          />
        </div>
        <div className='col-md-4'>
          <ConnectionButton
            connectionName='sage'
            systemName='ProjectMaster'
            subscriptionInfo={form.getValues()}
          />
        </div>
        <div className='col-md-4'>
          <ConnectionButton
            connectionName='sage'
            systemName='EntityMaster'
            subscriptionInfo={form.getValues()}
          />
        </div>
      </div>*/}
    </div>
  );
};
