import * as actionTypes from '../actions/actionTypes';
const initState = {
  response: [],
  message: '',
  isCreated: false,
  isUpdated: false,
  isCreatedError: false,
  isUpdatedError: false,
  submitted: false,

  isCompany: false,
  isCompanyError: false,

  isCompanyDD: false,
  isCompanyDDError: false,
  companyDD: false,
};

export default (state = initState, action = {}) => {
  switch (action.type) {
    case actionTypes.GET_ALL_COMPANY_REQUEST:
      return {
        ...state,
        response: [],
        message: '',
        isCreated: false,
        isUpdated: false,
        isCreatedError: false,
        isUpdatedError: false,
        submitted: false,
      };
    case actionTypes.GET_ALL_COMPANY_SUCCESS:
      return {
        ...state,
        // response: action.payload.response.result.data.map(item=>{
        //               return item.companies
        //             }),
        response: action.payload.response.result,
        message: '',
        isCreated: false,
        isUpdated: false,
        isCreatedError: false,
        isUpdatedError: false,
        submitted: false,
      };

    case actionTypes.GET_ALL_COMPANY_ERROR:
      return {
        ...state,
        response: undefined,
        message: '',
        isCreated: false,
        isUpdated: false,
        isCreatedError: false,
        isUpdatedError: false,
        submitted: false,
      };

    case actionTypes.CREATE_COMPANY_REQUEST:
      return {
        ...state,
        buttonActive: true,
        message: '',
        isCreated: false,
        isCreatedError: false,
        submitted: false,
        isCompany: false,
        isCompanyError: false,
      };
    case actionTypes.CREATE_COMPANY_SUCCESS:
      return {
        ...state,
        buttonActive: false,
        addCompanyData: action.payload.response.result.data,
        submitted: true,
        isCreated: true,
        isCreatedError: false,
        message: action.payload.response.result.msg,
        success: action.payload.response.result.success,
        isCompany: true,
        isCompanyError: false,
      };

    case actionTypes.CREATE_COMPANY_ERROR:
      return {
        ...state,
        buttonActive: false,
        isCreated: false,
        isCreatedError: true,
        submitted: false,
        message: action.payload.response.result.msg,
        success: action.payload.response.result.success,
        isCompany: false,
        isCompanyError: false,
      };

    case actionTypes.GET_ALL_COMPANY_USER_REQUEST:
      return {
        ...state,

        response: [],
        message: '',
        isCreated: false,
        isUpdated: false,
        isCreatedError: false,
        isUpdatedError: false,
        submitted: false,
      };
    case actionTypes.GET_ALL_COMPANY_USER_SUCCESS:
      return {
        ...state,

        response: action.payload.response.result,
        message: '',
        isCreated: false,
        isUpdated: false,
        isCreatedError: false,
        isUpdatedError: false,
        submitted: false,
      };

    case actionTypes.GET_ALL_COMPANY_USER_ERROR:
      return {
        ...state,
        response: undefined,
        message: '',
        isCreated: false,
        isUpdated: false,
        isCreatedError: false,
        isUpdatedError: false,
        submitted: false,
      };

    case actionTypes.DELETE_COMPANY_REQUEST:
      return {
        ...state,
        response: [],
        message: '',
        submitted: false,
      };
    case actionTypes.DELETE_COMPANY_SUCCESS:
      return {
        ...state,
        response: action.payload.response.result,
        message: action.payload.response.result.success
          ? 'Record Deleted successfully'
          : action.payload.response.result.msg,
        success: action.payload.response.result.success,
        submitted: true,
      };
    case actionTypes.DELETE_COMPANY_ERROR:
      return {
        ...state,
        response: [],
        message: action.payload.response.result.success
          ? 'Record Deleted successfully'
          : action.payload.response.result.msg,
        success: action.payload.response.result.success,
        submitted: false,
      };

    case actionTypes.GET_COMPANY_DD_REQUEST:
      return {
        ...state,
        response: [],
        message: '',
        isCreated: false,
        isCreatedError: false,
        submitted: false,
        isCompanyDD: false,
        isCompanyDDError: false,
        companyDD: false,
      };
    case actionTypes.GET_COMPANY_DD_SUCCESS:
      return {
        ...state,
        response: action.payload.response.result.data,
        companyDD: action.payload.response.result,
        message: '',
        isCreated: true,
        isCreatedError: false,
        submitted: true,
        isCompanyDD: true,
        isCompanyDDError: false,
      };

    case actionTypes.GET_COMPANY_DD_ERROR:
      return {
        ...state,
        response: undefined,
        message: '',
        isCreated: false,
        isCreatedError: true,
        submitted: false,
        isCompanyDD: false,
        isCompanyDDError: true,
        companyDD: false,
      };

    default:
      return state;
  }
};
