import { notification } from 'antd';

import React, { useEffect, useState } from 'react';

import DatePickerWrapper from '../../shared/datepicker-wrapper/datepicker-wrapper';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { getProjectHistoryQuickAction } from '../../actions/changeOrder';
import { updatedEventDateAction } from '../../actions/project';
import {
  selectProjectButtonActive,
  selectProjectEventDateMessage,
  selectProjectEventDateSuccess,
  selectProjectIsUpdateStatus,
} from '../../selectors/project';
import { appConstants } from '../../_constants';
import { formatDateObject,formatDate, formatDateUTC } from '../../utils/date-formatters';

function ChangeEventDateModal({
  isOpen = false,
  toggle = () => {},
  eventDateData = {},
}) {
  const [startDate, setStartDate] = useState(formatDateObject());

  const dispatch = useDispatch();

  const projectIsUpdateStatus = useSelector(selectProjectIsUpdateStatus);
  const projectEventDateMessage = useSelector(selectProjectEventDateMessage);
  const projectEventDateSuccess = useSelector(selectProjectEventDateSuccess);
  const projectButtonActive = useSelector(selectProjectButtonActive);

  useEffect(() => {
    if (
      projectIsUpdateStatus &&
      projectEventDateMessage !== '' &&
      eventDateData.projectId?.objectId
    ) {
      if (projectEventDateSuccess === 1) {
        notification.success({
          message: projectEventDateMessage,
          duration: 3,
        });

        dispatch(
          getProjectHistoryQuickAction({
            projectId: eventDateData.projectId.objectId,
          })
        );

        toggle();
      }

      if (projectEventDateSuccess === 0) {
        notification.error({
          message: projectEventDateMessage,
          duration: 3,
        });
      }
    }
  }, [
    dispatch,
    eventDateData.projectId?.objectId,
    projectEventDateMessage,
    projectEventDateSuccess,
    projectIsUpdateStatus,
    toggle,
  ]);

  const handleChange = (date) => {
    setStartDate(date);
  };

  const handleSubmit = () => {
    const data = {
      userId: localStorage.getItem('userId'),
      accountId: localStorage.getItem('accountId'),
      projectId: eventDateData.projectId.objectId,
      projectStatusId: eventDateData.objectId,
      eventDate: formatDateUTC(startDate),
    };

    dispatch(updatedEventDateAction(data));
  };

  return (
    <Modal backdrop='static' isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Change Event Date</ModalHeader>
      <ModalBody>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='form-group'>
                <label htmlFor='eventDateModal'>
                  <strong>Event Date:</strong>
                </label>
                <span>
                  {' '}
                  {eventDateData.eventDate && formatDate(eventDateData.eventDate.iso)}
                </span>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-12'>
              <div className='form-group'>
                <label htmlFor='startDate'>Select Event Date</label>
                <DatePickerWrapper
                  className='form-control'
                  selected={startDate}
                  onChange={handleChange}
                  dateFormat={appConstants.DATEFORMATPICKER}
                  required
                  todayButton="Today"
                  showMonthDropdown
                  placeholderText='Click to select a date'
                />
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button className='btn btn-primary' onClick={toggle}>
          Cancel
        </button>
        <button className='btn btn-primary' disabled={projectButtonActive} onClick={handleSubmit}>
          {projectButtonActive ? 'Loading...' : 'Save'}
        </button>
      </ModalFooter>
    </Modal>
  );
}

export default ChangeEventDateModal;
