import { Store } from '@bryntum/core-thin';
import '@bryntum/core-thin/core.stockholm.css';
import '@bryntum/grid-thin/grid.stockholm.css';
import { BryntumScheduler, BryntumSchedulerProps } from '@bryntum/scheduler-react-thin';
import '@bryntum/scheduler-thin/scheduler.stockholm.css';
import printJS from 'print-js';
import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react';
import loaderImage from '../../components/static/images/loading_i.gif';
import styles from './ResourceScheduler.module.scss';
import { useAppSelector } from '../hooks/use-app-selector';
import { selectGetProjectRoles } from '../../modules/settings/selectors';
import moment from 'moment';
import { useAppDispatch } from '../hooks/use-app-dispatch';
import { getProjectRoles } from '../../modules/settings';
import lo from 'lodash';
import { appConstants, isAllowed, moduleConstants } from '../../_constants';
import { getRoleName } from '../../utils/report-helper';

type SchedulerProps = {
  events: any[];
  resources: any[];
  resourceStore: any;
  columns: any[];
  startDate: string;
  endDate: string;
  viewPreset?: 'monthAndYear' | 'weekAndDay' | 'weekDateAndMonth' | 'manyYears' | string;
  loading?: boolean;
  title?: any;
  onApplyClick?: (...args: any) => any;
  groupBy?: any;
  isGroupShared?: boolean;
  onColumnHide?: (columnName: string) => any;
  onColumnShow?: (columnName: string) => any;
  onSaveReassign?: (data: any) => any;
  onBeforeEventDropFinalize?: (data: any) => any;
  visibleColumns?: string[];
  eventMenuFeature?: any;
  eventDragFeature?: any;
  columnNames?: any[];
};

export const Scheduler = forwardRef(({
  events,
  resources,
  resourceStore,
  columns,
  startDate,
  endDate,
  viewPreset = 'monthAndYear',
  loading = false,
  title = '',
  groupBy,
  onApplyClick = () => void 0,
  isGroupShared = false,
  onColumnHide = () => void 0,
  onColumnShow = () => void 0,
  onSaveReassign = () => void 0,
  onBeforeEventDropFinalize = () => void 0,
  visibleColumns = [],
  eventMenuFeature = {},
  eventDragFeature = {},
  columnNames = [],
}: SchedulerProps, ref) => {
  const schedulerRef = useRef<BryntumScheduler>(null);
  const schedulerViewPresetRef = useRef('');
  const wrapperRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();

  const [todayBarChecked, setTodayBarChecked] = useState<boolean>(false);
  const [viewLocked, setViewLocked] = useState<boolean>(true);
  const [isViewDefault, setIsViewDefault] = useState<boolean>(true);
  const [isReadOnly, setIsReadOnly] = useState<boolean>(true);
  const [isReassignAllowed, setIsReassignAllowed] = useState<boolean>(false);
  const [tmpStore, setTmpStore] = useState<string>('[]');

  useImperativeHandle(ref, () => ({ setIsReadOnly }), []);

  const pdfExportURL = appConstants.PDF_PRINT_URL;

  const getPermissionsByCode = (code: string) => !!isAllowed(code, 'anyAssigned', []);

  const isAllowedInternal = getPermissionsByCode(moduleConstants.ASSIGNINTERNAL);
  const isAllowedExternal = getPermissionsByCode(moduleConstants.ASSIGNEXTERNAL);
  const isAllowedAssignEstimator = getPermissionsByCode(moduleConstants.ASSIGNEST);
  const isAllowedAssignProjMaster = getPermissionsByCode(moduleConstants.ASSIGNPM);
  const isAllowedAssignSupervisor = getPermissionsByCode(moduleConstants.ASSIGNPS);
  const isAllowedAssignProjEng = getPermissionsByCode(moduleConstants.ASSIGNPE);

  useEffect(() => {
    dispatch(getProjectRoles());
  }, []);

  const { data: roles } = useAppSelector(selectGetProjectRoles);

  useEffect(() => {
    if (roles && groupBy) {
      const groupedByRole = roles?.find((r: any) => r._id === groupBy);

      if (groupedByRole) {
        const { type, code } = groupedByRole;
        const isAllowedByType = type === 'internal' ? isAllowedInternal : isAllowedExternal;
        let isAllowedByCode = true;

        switch (code) {
          case 'EST':
            isAllowedByCode = isAllowedAssignEstimator;
            break;
          case 'PM':
            isAllowedByCode = isAllowedAssignProjMaster;
            break;
          case 'SI':
            isAllowedByCode = isAllowedAssignSupervisor;
            break;
          case 'PE':
            isAllowedByCode = isAllowedAssignProjEng;
            break;
        }

        setIsReassignAllowed(isAllowedByType && isAllowedByCode);
      }
    }
  }, [
    roles,
    groupBy,
    isAllowedInternal,
    isAllowedExternal,
    isAllowedAssignEstimator,
    isAllowedAssignProjMaster,
    isAllowedAssignSupervisor,
    isAllowedAssignProjEng,
  ]);

  const roleName = useMemo(
    () => getRoleName(roles, groupBy),
    [roles, groupBy]
  );

  useEffect(() => {
    if (!schedulerRef.current?.instance) {
      return;
    }

    schedulerRef.current.instance.startDate = startDate as any;
    schedulerRef.current.instance.endDate = endDate as any;

    schedulerRef.current.instance.viewPreset = viewPreset;

  }, [viewPreset, startDate, endDate]);

  useEffect(() => {
    const scheduler = schedulerRef.current?.instance;

    if (!scheduler) {
      return;
    }

  }, [resources]);

  useEffect(() => {
    // reset filters when groupShared is changed
    const scheduler = schedulerRef.current?.instance;

    if (!scheduler) {
      return;
    }

    scheduler.resourceStore.clearFilters();
  }, [isGroupShared]);

  const customEventRenderer = ({ eventRecord, renderData }) => {

    const eventColor = eventRecord?.eventColor || eventRecord?.resource?.eventColor;

    const isDarkColor = (color) => {
      const rgb = color.match(/\d+/g);
      return rgb[0] * 0.299 + rgb[1] * 0.587 + rgb[2] * 0.114 > 186;
    }

    const isDark = isDarkColor(eventColor);

    const textColor = isDark ? 'white' : 'black';

    if(isDark){
      renderData.cls += ' dark-text-scheduler';
    }

    renderData.style = {
      backgroundColor: eventColor,
      color: textColor,

    };

    
    return (eventRecord?.eventStyle ==='minimal'||(eventRecord?.milestoneWidth===1)) ? '' : eventRecord.name;
    

    //return eventRecord.name;
    // if milestone, return empty string
    
  };


  const handleBeforePresetChange = (event) => {
    const scheduler = schedulerRef.current?.instance;

    if (!scheduler) {
      return;
    }

    // prevent scheduler from breaking dates on reset view preset
    if (event.to.id === schedulerViewPresetRef.current) {
      event.to.options.startDate = scheduler.startDate;
      event.to.options.endDate = scheduler.endDate;
    }
  };

  const handlePdfExport = async (event) => {
    const pdf = await event.response.json();

    if (pdf?.url) {
      printJS(pdf.url);
    }
  };

  const getPrintPreset = (preset: string) => {
    switch (preset) {
      case 'monthAndYear':
        return {
          headers: [
            {
              unit: 'year',
              dateFormat: 'YYYY',
            },
            {
              unit: 'month',
              dateFormat: 'MMM',
            },
          ],

          timeResolution: {
            unit: 'day',
            increment: 1,
          },
        };

      case 'weekAndDay':
        return {
          headers: [
            {
              unit: 'year',
              dateFormat: 'YYYY MMM',
            },
            {
              unit: 'day',
              dateFormat: 'dd',
            },
          ],

          timeResolution: {
            unit: 'day',
            increment: 3,
          },
        };

      case 'weekDateAndMonth':
        return {
          headers: [
            {
              unit: 'month',
              dateFormat: 'YYYY MMM',
            },
            {
              unit: 'day',
              increment: 7,
              dateFormat: 'dd',
            },
          ],

          columnLinesFor: 0,

          timeResolution: {
            unit: 'day',
            increment: 1,
          },
        };
    }
  };

  const [schedulerConfig] = useState<BryntumSchedulerProps>({
    barMargin: 8,
    rowHeight: 40,

    autoAdjustTimeAxis: false,

    resizeToFitIncludesHeader: true,

    eventLayout: 'mixed',

    //maximized: true,

    zoomKeepsOriginalTimespan: false,
    maxZoomLevel: 15,
    minZoomLevel: 1,

    bodyCls: styles.schedulerBody,
    cls: styles.scheduler,

    filterFeature: true,
    treeFeature: true,
    nonWorkingTimeFeature: true,
    timeRangesFeature: true,
    eventEditFeature: false,

    eventRenderer: customEventRenderer,

    timeAxisHeaderMenuFeature: {
      items: {
        eventsFilter: false,
        dateRange: false,
        currentTimeLine: false,
      },
    },
    viewPreset: 'monthAndYear',

    pdfExportFeature: {
      exportServer: pdfExportURL+'/',
      //translateURLsToAbsolute : 'https://cpost-bryntum-print-server.herokuapp.com:443/resources',
      openAfterExport: false,
      orientation: 'landscape',
      alignRows: true,
      repeatHeader: true,
      exporterType: 'multipagevertical',
      paperFormat: 'Letter',

      exportDialog: {
        items: {
          columnsField: {
            editable: false,
            clearable: true,
          },
          fileFormatField: {
            hidden: true,
          },
        },
        bbar: {
          itemCls: styles.bExportItem,
          items: {
            exportButton: { text: 'Print', weight: 201 },
          },
        },
      },
    },

    cellMenuFeature: false, // turn off context menu for resources
    scheduleMenuFeature: false, // turn off context menu for schedule
    eventResizeFeature: false, // turn off event resize
    eventDragCreateFeature: false, // turn off event creation on drag
    createEventOnDblClick: false, // turn off event creation on dbl click
    eventMenuFeature,
    eventDragFeature,
    onBeforeEventDropFinalize,

    resourceStore,

    preventTooltipOnTouch:true,

    // columns,
    fillLastColumn: true,
    autoHeight: true,
    fixedRowHeight: false,

    columnPickerFeature: true,

    columns: {
      data: columns,
      onColumnHide: (event) => {
        onColumnHide(event.column.field);
      },
      onColumnShow: (event) => {
        onColumnShow(event.column.field);
      },
    },
  });

  const onSaveRoles = useCallback(async (event: any) => {
    const modified = event.source?.parent?.parent?.resourceStore?.changes?.modified;

    if (Array.isArray(modified) && modified.length > 0) {
      onSaveReassign && onSaveReassign(modified);
    }
  }, [groupBy]);

  const tbar = {
    itemCls: styles.bToolbarItem,

    items: {
      ...(title && {
        title: {
          type: 'widget',
          html: title,
        },
      }),
      viewPreviousPeriodButton: {
        type: 'button',
        icon: 'b-fa-angle-left',
        disabled: !isReadOnly,
        onClick: () => {
          const scheduler = schedulerRef.current?.instance;

          if (!scheduler) {
            return;
          }

          scheduler.shiftPrevious();
        },
      },
      viewNextPeriodButton: {
        type: 'button',
        icon: 'b-fa-angle-right',
        disabled: !isReadOnly,
        onClick: () => {
          const scheduler = schedulerRef.current?.instance;

          if (!scheduler) {
            return;
          }

          scheduler.shiftNext();
        },
      },
      zoomInButton: {
        type: 'button',
        icon: 'b-fa-plus',
        disabled: viewLocked||!isReadOnly,
        hidden: !isReadOnly||viewLocked,
        onClick: () => {
          const scheduler = schedulerRef.current?.instance;

          if (!scheduler) {
            return;
          }

          scheduler.zoomIn();
        },
      },
      zoomOutButton: {
        type: 'button',
        icon: 'b-fa-minus',
        hidden: !isReadOnly||viewLocked,
        disabled: viewLocked||!isReadOnly,
        onClick: () => {
          const scheduler = schedulerRef.current?.instance;

          if (!scheduler) {
            return;
          }

          scheduler.zoomOut();
        },
      },
      fitViewButton: {
        type: 'button',
        text: viewLocked?'Unlock View':'Lock View',
        disabled: !isReadOnly,
        onClick: () => {
          const scheduler = schedulerRef.current?.instance;

          if (!scheduler) {
            return;
          }

          setIsViewDefault(false);

          if(!viewLocked){
            scheduler.setStartDate(scheduler.visibleDateRange.startDate,false);
            scheduler.setEndDate(scheduler.visibleDateRange.endDate,false);
          }

       //   scheduler.viewPreset.star
          scheduler.forceFit = !viewLocked;

          setViewLocked(!viewLocked);
        },
      },
      
      
      resetViewButton: {
        type: 'button',
        text: 'Reset View',
        hidden: !isReadOnly||isViewDefault,
        disabled: !isReadOnly,
        onClick: () => {
          const scheduler = schedulerRef.current?.instance;

          if (!scheduler) {
            return;
          }

          setIsViewDefault(true);
 
          scheduler.setStartDate(startDate as any);
          scheduler.setEndDate(endDate as any);
      
          scheduler.viewPreset = viewPreset;

          scheduler.forceFit = true;

          setViewLocked(true);
        },
      },
      todayBarCheckbox: {
        type: 'checkbox',
        text: 'Today bar',
        style: 'margin-left: auto',
        checked: todayBarChecked,
        onChange: (recordData) => {
          const scheduler = schedulerRef.current?.instance;

          if (!scheduler) {
            return;
          }

          const timeRangeStore = scheduler.timeRangeStore as Store;

          if (recordData.checked) {
            timeRangeStore.add({
              id: 'todayBar',
              cls: styles.shaded,
              startDate: new Date(0),
              endDate: new Date(),
              showHeaderElements: false,
              showTooltip:false,
            });
          } else {
            timeRangeStore.getById('todayBar').remove();
          }

          setTodayBarChecked(recordData.checked);
        },
      },
      testCheckbox: {
        type: 'widget',
        html: <div id='test-checkbox' />,
      },
      unlockRoles: {
        type: 'button',
        icon: 'fa-solid fa-user-lock',
        text: 'Unlock Roles',
        hidden: !isReadOnly || !groupBy, // hide button if no grouping or we editing roles
        disabled: !isReassignAllowed || isGroupShared,
        onClick: (event: any) => {
          const store = event.source?.parent?.parent?.resourceStore;

          if (store) {
            setTmpStore(store.json);
            setIsReadOnly(!isReadOnly);
          }
        },
      },
      cancelRolesDnd: {
        type: 'button',
        text: 'Cancel',
        hidden: isReadOnly,
        onClick: (event: any) => {
          const store = event.source?.parent?.parent?.resourceStore;

          if (store) {
            store.data = JSON.parse(tmpStore); // restore data that was before dnd
            setIsReadOnly(!isReadOnly);
          }
        },
      },
      saveRolesDnd: {
        type: 'button',
        icon: 'fa-solid fa-user-lock',
        text: 'Save Roles',
        hidden: isReadOnly,
        onClick: onSaveRoles,
      },
      print: {
        type: 'button',
        icon: 'fa fa-print',
        text: 'Print',
        disabled: !isReadOnly,
        onClick: (event) => {
          const scheduler = event.source.parent.parent;

          scheduler.features.pdfExport.showExportDialog();
        },
      },
      apply: {
        type: 'button',
        icon: 'fa fa-filter',
        text: 'Apply Filters',
        disabled: !isReadOnly,
        onClick: onApplyClick,
      },
    },
  };

  useEffect(() => {
    const scheduler = schedulerRef.current?.instance;

    if (!scheduler) {
      return;
    }

    scheduler.forceFit = viewLocked;

    if ('get' in scheduler.columns) {
      const colNames = scheduler.columns.data.map((col) => col['field']);
      for (const c in colNames) {
        if (
          scheduler.columns.get(colNames[c])?.getData('filterable')?.filterField?.type == 'combo'
        ) {
          if (groupBy && !isGroupShared) {
            scheduler.columns.get(colNames[c]).getData('filterable').filterField.items = lo
              .uniq(
                resources?.flatMap(
                  (resource) => resource?.children?.map((child) => child[colNames[c]])
                )
              )
              .sort();
          } else {
            scheduler.columns.get(colNames[c]).getData('filterable').filterField.items = lo
              .uniq(resources?.map((resource) => resource[colNames[c]]))
              .sort();
          }
        }
      }
    }
  }, [resources]);

  // override ctrl + p
  useEffect(() => {
    const handleKeyDown = async (event) => {
      if ((event.ctrlKey && event.key === 'p') || (event.metaKey && event.key === 'p')) {
        event.preventDefault();

        const scheduler = schedulerRef.current?.instance;

        if (!scheduler || !wrapperRef.current) {
          return;
        }


        scheduler.zoomToFit();

        setScheduleStyle({
          display: 'flex-grow',
          flexDirection: 'row',
          flex: '1 1 auto !important',
          flexGrow: 1,
          position: 'relative',
        });


        scheduler.viewPreset = getPrintPreset(viewPreset) as any;

        // temporary fix for scheduler not rendering properly

        await new Promise((resolve) => setTimeout(resolve, 500));

        const overlay = document.getElementById('webpack-dev-server-client-overlay');

        if (overlay) {
          overlay.style.display = 'none';
        }

        window.print();

        setScheduleStyle({
          height: 'calc(100% - 140px)',
        });

        scheduler.viewPreset = viewPreset;
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [viewPreset]);

  const accountLogo = localStorage.getItem('companyLogo');

  useEffect(() => {
    const scheduler = schedulerRef.current?.instance;

    if (!scheduler) {
      return;
    }

    if ('get' in scheduler.columns) {
      const colNames = scheduler.columns.data.map((col) => col['field']);

      for (const c in colNames) {
        const column = scheduler.columns.get(colNames[c]);

        if (!column) {
          continue;
        }

        if (visibleColumns.includes(colNames[c])) {
          column.hidden = false;
        } else {
          column.hidden = true;
        }
      }
    }
  }, [visibleColumns]);

  useEffect(() => {
    const scheduler = schedulerRef.current?.instance;

    if (!scheduler) {
      return;
    }

    if ('get' in scheduler.columns) {
      columnNames.forEach(({ text, field }) => {
        const column = (scheduler.columns as any).get(field);

        if (!column) {
          return;
        }

        column.text = text;
      });
    }
  }, [columnNames]);

  const [scheduleStyle, setScheduleStyle] = useState({
    height: '1000px',
  } as any);

  useEffect(() => {
    if(!loading && events.length>0)
     { setScheduleStyle({
      height: 'calc(100% - 140px)',
    });
  }
  }, [loading,events]);

  return (
    // height to bottom of the page
    <div ref={wrapperRef} style={scheduleStyle}>
      <div className={styles.print}>
        <div className={styles.logoWrapper}>
          <img src={accountLogo as string} />
        </div>

        <div>
          <h4 className='p-0 m-0'>{title}</h4>
          <div>
            <strong>Analyzed By:</strong> {roleName}
          </div>
          <div>
            <div>
              <strong>Date: </strong>

              <span>
                {moment(startDate).format('MMM dd, YYYY')} to{' '}
                {moment(endDate).format('MMM dd, YYYY')}
              </span>
            </div>
          </div>
        </div>
      </div>
      {loading && (
        <div className='loading_bg'>
          <img className='ajax-loader' src={loaderImage} width='100' height='100' />
        </div>
      )}
      <BryntumScheduler
        ref={schedulerRef}
        {...schedulerConfig}
        readOnly={isReadOnly}
        resources={resources}
        events={events}
        onBeforePresetChange={handleBeforePresetChange}
        onPdfExport={handlePdfExport}
        viewPreset='monthAndYear'
        bodyCls='scheduler-body'
        tbar={tbar}
        height={'100%'}
      />
    </div>
  );
});
