import moment from 'moment';

export const DateColumnFilter = (rows, id, filterValue) => {
  if (!filterValue) {
    return rows;
  }

  const dynamicId = 'dynamicFieldData.'+id;

  return rows
    .filter((r) => (r.original && (r.original[id]?.iso?.length > 0 || r.original[id]))|| r.values?.[id]|| r.values?.[dynamicId])
    .filter((row) => {
      const rowDate = row.original?.[id]?.iso || row.original[id] || row.values?.[id] || row.values?.[dynamicId];
      return moment(rowDate).isBetween(
        filterValue[0].toISOString(),
        filterValue[1].toISOString(),
        undefined,
        '[]'
      );
    });
};

