import React from 'react';
import { Link } from 'react-router-dom';


class AccessDenied extends React.Component {
  render() {
    const divStyle = { padding: '10%' };
    const colorRed = { color: 'red' };
    const textAlign = { 'text-align': 'center' };
    const width46P = { width: '46%' };
    return (
      <section>
        <div className="page-error text-center">
          <div className="container" style={divStyle}>
            <div style={textAlign}>
              <div className="logo">
                <a href="/home">
                  <img style={width46P} src="/static/media/Cobilogo.png" alt="logo" />
                </a>
              </div>
            </div>
            <h1 style={colorRed}>403 - ACCESS DENIED</h1>
            <p>
              A web server may return a 403 Forbidden HTTP status code in response to a request from
              a client for a web page or resource to indicate that the server can be reached and
              understood the request, but refuses to take any further action. Status code 403
              responses are the result of the web server being configured to deny access, for some
              reason, to the requested resource by the client.
            </p>
            <Link className="" to={{ pathname: `/home`, state: { ...this.props.rowData } }}>
              {' '}
              BACK TO HOME{' '}
            </Link>
          </div>
        </div>
      </section>
    );
  }
}

export default (AccessDenied);
