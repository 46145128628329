import { useEffect, useState } from 'react';
import { AvatarComponent } from '../form-controls/form-avatar/form-avatar';

export type MemoizedAvatarProps = {
  pictureRef: any;
  image: string;
};

export const MemoizedAvatar = ({ pictureRef, image }: MemoizedAvatarProps) => {
  const [avatarUrl, setAvatarUrl] = useState('');

  useEffect(() => {
    setAvatarUrl(image);
  }, [image]);

  useEffect(() => {
    pictureRef.current.url = avatarUrl;
  }, [avatarUrl]);

  return (
    <AvatarComponent
      picture={avatarUrl}
      onPictureSelect={setAvatarUrl as any}
      onFileSelect={(file) => (pictureRef.current.file = file)}
      onFileRemove={() => {
        pictureRef.current.file = null;
        pictureRef.current.url = null;
      }}
      isRectangular={true}
      width='100%'
    />
  );
};
