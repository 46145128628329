import * as actionTypes from '../actions/actionTypes';

const initState = {
  reasonCode: {},
  allReasonCode: [],
  message: '',
  fetchRequest: false,
  isCreated: false,
  success: null,
};

export default (state = initState, action = {}) => {
  switch (action.type) {
    case actionTypes.CREATE_REASON_CODE_REQUEST:
      return {
        ...state,
        reasonCode: {},
        message: '',
      };
    case actionTypes.CREATE_REASON_CODE_SUCCESS:
      return {
        allReasonCode: action.payload.response.result.data,
        isCreated: true,
        success: action.payload.response.result.success,
        message: action.payload.response.result.success
          ? 'Record Added successfully'
          : action.payload.response.result.msg,
      };

    case actionTypes.CREATE_REASON_CODE_ERROR:
      return {
        ...state,
        isCreated: false,
        success: action.payload.response.result.success,
        message: action.payload.response.result.msg,
      };

    case actionTypes.GET_ALL_REASON_CODE_REQUEST:
      return {
        ...state,
        reasonCode: {},
        message: '',
        fetchRequest: true,
      };

    case actionTypes.GET_ALL_REASON_CODE_SUCCESS:
      return {
        ...state,
        allReasonCode: action.payload.response.result.data,
        message: '',
        fetchRequest: false,
      };

    case actionTypes.GET_ALL_REASON_CODE_ERROR:
      return {
        ...state,
        message: '',
        fetchRequest: false,
      };

    case actionTypes.UPDATE_REASON_CODE_REQUEST:
      return {
        ...state,
        reasonCode: {},
        message: '',
      };
    case actionTypes.UPDATE_REASON_CODE_SUCCESS:
      return {
        ...state,
        allReasonCode: action.payload.response.result.contractTypes,
        isCreated: true,
        success: action.payload.response.result.success,
        message: action.payload.response.result.success
          ? 'Record Updated successfully'
          : action.payload.response.result.msg,
      };

    case actionTypes.UPDATE_REASON_CODE_ERROR:
      return {
        ...state,
        isCreated: false,
        success: action.payload.response.result.success,
        message: action.payload.response.result.msg,
      };

    case actionTypes.DELETE_REASON_CODE_REQUEST:
      return {
        ...state,
        reasonCode: {},
        message: '',
      };
    case actionTypes.DELETE_REASON_CODE_SUCCESS:
      return {
        ...state,
        allReasonCode: state.allReasonCode,
        isCreated: true,
        success: action.payload.response.result.success,
        message: action.payload.response.result.success
          ? 'Record Deleted successfully'
          : action.payload.response.result.msg,
      };

    case actionTypes.DELETE_REASON_CODE_ERROR:
      return {
        ...state,
        isCreated: false,
        success: action.payload.response.result.success,
        message: action.payload.response.result.msg,
      };

    case actionTypes.GET_ALL_REASON_CODE_BY_STATUS_CODE_REQUEST:
      return {
        ...state,
        reasonCode: {},
        message: '',
      };
    case actionTypes.GET_ALL_REASON_CODE_BY_STATUS_CODE_SUCCESS:
      return {
        ...state,
        allReasonCodeByStatusCode: action.payload.response.result,
        isCreated: true,
        success: action.payload.response.result.success,
        message: action.payload.response.result.success
          ? 'Get records successfully'
          : action.payload.response.result.msg,
      };

    case actionTypes.GET_ALL_REASON_CODE_BY_STATUS_CODE_ERROR:
      return {
        ...state,
        isCreated: false,
        success: action.payload.response.result.success,
        message: action.payload.response.result.msg,
      };

    default:
      return state;
  }
};
