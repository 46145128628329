import { Dispatch, SetStateAction, useCallback, useState } from 'react';


// eslint-disable-next-line no-undef
export const useLocalStorage = <T = undefined>(key: string, initialValue?: T) => {
  // eslint-disable-next-line no-undef
  const [storedValue, setStoredValue] = useState<T>(() => {
    if (typeof window === 'undefined') {
      return initialValue;
    }

    try {
      const item = window.localStorage.getItem(key);

      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.warn(`Error reading localStorage key "${key}":`, error);

      return initialValue;
    }
  });

  
  const setValue: Dispatch<SetStateAction<T>> = useCallback(
    (value) => {
      try {
        const newValue = value instanceof Function ? value(storedValue) : value;

        window.localStorage.setItem(key, JSON.stringify(newValue));

        setStoredValue(newValue);
      } catch (error) {
        console.warn(`Error setting localStorage key "${key}":`, error);
      }
    },
    [key, storedValue]
  );

  // eslint-disable-next-line no-undef
  return [storedValue, setValue] as const;
};
