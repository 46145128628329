import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { callAlertMessage } from '../../components/Auth/Auth';
import { buildDefaultActionResult } from '../../utils/redux';
import { createChangeOrderRequest, viewChangeOrdersRequest } from './api';

export const createChangeOrderAsync = createAsyncThunk(
  'orders/createChangeOrderAsync',
  async (data) => {
    const {
      data: { result },
    } = await createChangeOrderRequest(data);

    callAlertMessage(result.success === 1 ? 'success' : 'error', result.msg);

    return result.data ?? {};
  }
);

export const viewChangeOrdersAsync = createAsyncThunk(
  'orders/viewChangeOrdersAsync',
  async (projectId) => {
    const {
      data: { result },
    } = await viewChangeOrdersRequest(projectId);

    if (result.success === 0) {
      callAlertMessage('error', result.msg);
    }

    return result.data ?? [];
  }
);

export const ordersSlice = createSlice({
  name: 'orders',
  initialState: {
    createChangeOrder: {
      data: {},
      loading: false,
      error: null,
    },
    viewChangeOrders: {
      data: [],
      loading: false,
      error: null,
    },
  },
  extraReducers: (builder) => {
    buildDefaultActionResult('createChangeOrder', createChangeOrderAsync, builder);

    buildDefaultActionResult('viewChangeOrders', viewChangeOrdersAsync, builder);
  },
});

export default ordersSlice.reducer;
