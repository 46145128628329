import React, { Suspense } from 'react';
import { formatDate, formatBidDueDate } from '../../../utils/date-formatters.js';
import { DateColumnFilter } from '../../Common/CustomTableComponents/DateColumnFilter.js';
import { Link, withRouter } from 'react-router-dom';
import Header from '../../Layout/Header.js';
import Footer from '../../Layout/Footer.js';
import { connect } from 'react-redux';
import { appConstants } from '../../../_constants/index.js';
import {
  GetCompanyListReportAction,
  GetCompanyListViewPrefAction,
  SetCompanyListViewPrefAction,
} from '../../../actions/reports.js';
import ReactLoading from 'react-loading';

import { Tooltip } from 'react-tooltip';
import TitleComponent from '../../Common/TitleComponent.js';
import { customSortByKey, CustomTable, multiSelectFilter } from '../../Common/CustomTable.jsx';
import { SelectColumnFilter } from '../../Common/CustomTableComponents/SelectColumnFilter.js';
import { formatPhoneArrayHTML } from '../../../utils/contact-formatters.jsx';
import { getAllContactFieldsAction } from '../../../actions/contactFields.js';
import {buildColumnForField, buildDynamicColumns} from '../../../utils/column-formatters.js';

var sortingOrder = '';

class CompanyListViewReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accountId: localStorage.getItem('accountId'),
      userId: localStorage.getItem('userId'),
      list: [],
      fetchRequest: true,
      comLogo: '',
      totalCount: 0,
      currentPage: 1,
      searchText: '',
      sizePerPage: appConstants.TableSizePerPageList[2].value,
      sortType: 'DESC',
      sortKey: '',
      allContactFieldTypes: [],
      dynamicColumns: [],
    };
    this.onPageChange = this.onPageChange.bind(this);
    this.onSortChange = this.onSortChange.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.listData && nextProps.listData.success && nextProps.listData.success === 1) {
      this.setState({
        totalCount: nextProps.listData.count,
        list: nextProps.listData.data,
        fetchRequest: false,
      });
    }

    if (nextProps.listData && nextProps.listData.data && nextProps.listData.data.length === 0) {
      this.setState({ fetchRequest: false });
    }

    if (nextProps?.contactFields?.allContactFieldTypes?.length > 0) {
      this.setState({
        allContactFieldTypes: nextProps.contactFields.allContactFieldTypes,
      });
    }
  }

  componentDidMount() {
    this.setState({ sizePerPage: appConstants.TableSizePerPageList[2].value });
    let data = {};
    data['accountId'] = this.state.accountId;
    data['moduleName'] = appConstants.reportModuleName['6'];
    data['page'] = 0;
    data['displayLimit'] = 9999999999;
    data['search'] = this.state.searchText;
    data['sortKey'] = this.state.sortKey;
    data['sortType'] = this.state.sortType;
    this.props.GetCompanyListReportAction(data);

    sortingOrder = '';

    if (
      localStorage.getItem('companyLogo') !== 'undefined' &&
      localStorage.getItem('companyLogo') !== null
    ) {
      this.setState({
        comLogo: localStorage.getItem('companyLogo'),
      });
    } else {
      let constImage = appConstants.CONTENT_CPOST_LOGO_URL;
      this.setState({ comLogo: constImage });
    }
    this.props.GetCompanyListViewPrefAction();

    this.props.getAllContactFieldsAction({
      accountId: this.state.accountId,
    });
  }

  onContactPhone(name, row, cell, rowIndex) {
    let phn = '';
    if (row && row.phoneArr && row.phoneArr.length > 0) {
      return <div dangerouslySetInnerHTML={{ __html: formatPhoneArrayHTML(row.phoneArr) }} />;
    } else {
      return <span>-</span>;
    }
  }

  onGetAddressInfo(cell, row, enumObject, rowIndex) {
    let data = '';
    if (row && row.addressArr?.length > 0) {
      let addressArr = row.addressArr;
      return addressArr.map((v, i) => {
        if (v.address1 || v.address2 || v.city || v.state || v.zipCode) {
          return (
            <div>
              {v.label?.length > 0 && (
                <>
                  <span>
                    <strong> {v.label}</strong>
                  </span>
                  <br></br>
                </>
              )}
              <span>
                {(v.address1 ? v.address1 : '') +
                  (v.address1 ? ', ' : '') +
                  (v.address2 ? v.address2 : '') +
                  (v.address2 ? ', ' : '')}
              </span>
              <br />
              <span>
                {(v.city ? v.city : '') +
                  (v.city ? ', ' : '') +
                  (v.state ? v.state : '') +
                  (v.state ? ', ' : '') +
                  (v.zipCode ? v.zipCode : '')}
              </span>
              <br />
            </div>
          );
        }
      });
    } else {
      return '-';
    }
  }

  onCompanyName(cell, row, enumObject, rowIndex) {
    if (row) {
      return (
        <div>
          <Link
            to={{
              pathname: `/company-details/` + row.companyId,
              //state: { row },
            }}
          >
            {row && row.companyName ? row.companyName : '-'}
          </Link>
        </div>
      );
    } else {
      return <span>-</span>;
    }
  }

  onCompanyEmail(cell, row, enumObject, rowIndex) {
    let emailHtml = '';
    if (row && row.emailArr && row.emailArr.length > 0) {
      row.emailArr.map((item) => {
        if (emailHtml !== '') {
          emailHtml = emailHtml + '<br/>';
        }
        if (item.email?.length > 0) {
          emailHtml = emailHtml + "<a href=mailto:'" + item.email + '>' + item.email + '</a>';
        }
        return true;
      });
      return <div dangerouslySetInnerHTML={{ __html: emailHtml.length > 0 ? emailHtml : '-' }} />;
    } else {
      return <span>-</span>;
    }
  }

  createCustomButtonGroup = (props) => {
    return (
      <div className='pdfLogoBox'>
        <div className='reportHead'>
          <div className='logo'>
            <img src={this.state.comLogo} alt='Upload Logo' />
          </div>
          <h2>Companies (List View)</h2>
        </div>
      </div>
    );
  };

  onPageChange(page, sizePerPage) {
    let data = {};
    data['accountId'] = this.state.accountId;
    data['moduleName'] = appConstants.reportModuleName['6'];
    // if(this.state.searchText === ""){
    //   data['page'] = (page===-1)?0:page-1;
    //   data['displayLimit'] = sizePerPage;
    // }else{
    data['page'] = page === -1 ? 0 : page - 1;
    data['displayLimit'] = sizePerPage;
    data['search'] = this.state.searchText;
    data['sortKey'] = this.state.sortKey;
    data['sortType'] = this.state.sortType;
    //}
    this.setState({ fetchRequest: true, list: [] });
    this.setState({ sizePerPage: sizePerPage });
    this.setState({ currentPage: page });
    this.props.GetCompanyListReportAction(data);
  }


  onSortChange(sortName, sortOrder) {
    if (this.state && this.state.list && this.state.list.length > 0) {
      this.setState({ fetchRequest: true });
      let data = {};
      data['sortKey'] = sortName;
      // if(sortName==="jobNumFormatted"){
      //  data['sortKey'] = "jobNum";
      // }

      this.setState({ sortKey: data['sortKey'] });

      if (sortOrder === 'asc') {
        data['sortType'] = 'ASC';
        sortingOrder = '-asc';
        this.setState({ sortType: 'ASC' });
      } else {
        data['sortType'] = 'DESC';
        sortingOrder = '-desc';
        this.setState({ sortType: 'DESC' });
      }

      data['moduleName'] = appConstants.reportModuleName['3'];
      data['search'] = this.state.searchText;
      data['accountId'] = this.state.accountId;
      data['page'] = this.state.currentPage - 1;
      data['displayLimit'] = this.state.sizePerPage;
      this.props.GetCompanyListReportAction(data);
    }
  }

  _setTableOption() {
    if (this.state.fetchRequest) {
      return (
        <ReactLoading
          className='table-loader'
          type={appConstants.LOADER_TYPE}
          color={appConstants.LOADER_COLOR}
          height={appConstants.LOADER_HEIGHT}
          width={appConstants.LOADER_WIDTH}
        />
      );
    } else {
      return 'No data found..';
    }
  }

  onWebsite(cell, row, enumObject, rowIndex) {
    const companyWebsite = row[cell];

    const url =
      companyWebsite?.length > 1
        ? companyWebsite?.startsWith('http')
          ? companyWebsite
          : 'http://' + companyWebsite
        : '';

    if (url.length > 0) {
      return (
        <tr key={9999}>
          <td>
            <a href={url} target='_blank' rel='noreferrer'>
              {companyWebsite.trim()}
            </a>
          </td>
          <td></td>
        </tr>
      );
    } else {
      return <td>-</td>;
    }
  }

  formatDateCell(cell, row, accessor) {
    if (row && row[accessor]) {
      return <div>{formatDate(row[accessor]) ?? ''}</div>;
    } else {
      return <span>-</span>;
    }
  }


  

  buildReportColumns() {
    const { list, allContactFieldTypes } = this.state;

    const dynamicFieldsLoaded = allContactFieldTypes?.length > 0;

    const defaultFieldCodes = [];

    const customFieldColumns = dynamicFieldsLoaded
      ? buildDynamicColumns(
          allContactFieldTypes.filter(
            (field) => !defaultFieldCodes.includes(field.code) && field.stages.includes('company')
          )
        )
      : [];

    const defaultFields = dynamicFieldsLoaded
      ? allContactFieldTypes.filter((field) => defaultFieldCodes.includes(field.code))
      : [];

    const defaultColumns = [
      {
        Header: 'Company Name',
        accessor: 'companyName',
        width: 130,
        Cell: (props) => this.onCompanyName(props.column.id, props.row.original, props.value),
      },
      {
        Header: 'DBA',
        accessor: 'dba',
        width: 130,
        hidden: true,
        Cell: (props) => this.onCompanyName(props.column.id, props.row.original, props.value),
      },
      {
        Header: 'Website',
        accessor: 'companyWebsite',
        width: 80,
        hidden: true,
        Cell: (props) => this.onWebsite(props.column.id, props.row.original, props.value),
      },
      {
        Header: 'Email',
        accessor: 'emailArr',
        exportField: 'email',
        width: 130,
        Cell: (props) => this.onCompanyEmail(props.column.id, props.row.original, props.value),
      },
      {
        Header: 'Phone Number',
        accessor: 'phoneArr',
        exportField: 'phone',
        width: 130,
        Cell: (props) => this.onContactPhone(props.column.id, props.row.original, props.value),
      },
      {
        Header: 'Address',
        accessor: 'addressArr',
        width: 130,
        exportField: 'address1',
        Cell: (props) => this.onGetAddressInfo(props.column.id, props.row.original, props.value),
      },
      {
        Header: 'Tags',
        accessor: 'companyTags',
        width: 130,
        exportField: 'companyTags',
        Filter: SelectColumnFilter,
        filter: multiSelectFilter,
        Cell: ({ value }) => <>{value?.length > 0 ? value.join(', ') : ''}</>,
      },
      {
        Header: '# Contacts',
        accessor: 'numContacts',
        width: 130,
        exportField: 'numContacts',
        hidden: true,
        Cell: ({ value }) => <>{value != 0 ? value : ''}</>,
      },
      {
        Header: '# Projects',
        accessor: 'numProjects',
        width: 130,
        exportField: 'numProjects',
        hidden: true,
        Cell: ({ value }) => <>{value != 0 ? value : ''}</>,
      },
      {
        Header: 'Added',
        exportField: 'createdAt',
        hidden: true,
        accessor: 'createdAt',
        width: 60,
        filter: DateColumnFilter,
        allowDateRangeFilter: true,
        Cell: (props) => (
          <>{this.formatDateCell(props.column.id, props.row.original, 'createdAt')}</>
        ),
      },
      {
        Header: 'Updated',
        exportField: 'updatedAt',
        hidden: true,
        accessor: 'updatedAt',
        width: 60,
        filter: DateColumnFilter,
        allowDateRangeFilter: true,
        Cell: (props) => (
          <>{this.formatDateCell(props.column.id, props.row.original, 'updatedAt')}</>
        ),
      },
    ];

    let columns = [];
    if (dynamicFieldsLoaded) {
      if (columns.length == 0) {
        columns = defaultColumns.filter((cols) => cols?.accessor);
      }

      for (let i in customFieldColumns) {
        const curColumnsAccessors = columns.map((cols) => cols.accessor);
        if (!curColumnsAccessors.includes(customFieldColumns[i].accessor)) {
          columns = columns.concat(customFieldColumns[i]);
        }
      }
    } else {
      columns = defaultColumns.filter((cols) => cols?.accessor);
    }

    
    this.setState({ dynamicColumns: columns });

  }

  
  render() {
  
    const {
      list, allContactFieldTypes, dynamicColumns, fetchRequest
    } = this.state;

    
    const dynamicFieldsLoaded = allContactFieldTypes?.length > 0;
    
    const dynamicColumnsBuilt = dynamicFieldsLoaded && dynamicColumns.length > 0;

    if(dynamicFieldsLoaded && !dynamicColumnsBuilt){
      this.buildReportColumns();
    }


    const { listPref, SetCompanyListViewPrefAction } = this.props;


    return (
      <div>
        <div className='ContactListReport'>
          <TitleComponent data={{ title: `Company List Report` }} />
          <div>
            <Suspense fallback='loading'>
              <Header props={this.props} />
            </Suspense>
          </div>
          <div className='contactBox'>
            <div className='container'>
              <div>
                <div className='row no-break-print'>
                  {dynamicColumnsBuilt && (
                    <div className='col-md-12'>
                      <div
                        className='table-responsive reportTableSection'
                        ref={(el) => (this.componentRef = el)}
                      >
                        {this.createCustomButtonGroup()}
                        <CustomTable
                          columns={dynamicColumns}
                          list={list}
                          preferences={listPref}
                          className='company-job-list-table'
                          customTableTitle='Companies (List View)'
                          onSavePreferences={SetCompanyListViewPrefAction}
                          isLoading={this.state.fetchRequest}
                          canShowRowSelection={false}
                          componentRef={this.componentRef}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    listData: state.reports.companiesList,
    listPref: state.reports.companyListViewPref,
    isCompanyCreated: state.reports.isCompanyCreated,
    contactFields: state.contactFields,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    GetCompanyListReportAction,
    GetCompanyListViewPrefAction,
    SetCompanyListViewPrefAction,
    getAllContactFieldsAction,
  })(CompanyListViewReport)
);
