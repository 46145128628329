import joi from 'joi';
import moment from 'moment';

const updateContactSchema = joi.object({
  _id: joi.string().allow(),
  email: joi
    .string()
    .optional()
    .email({ tlds: { allow: false } })
    .allow('')
    .allow(null)
    .empty('')
    .trim()
    .label('Email'),
  firstName: joi.string().trim().required().label('First Name'),
  middleName: joi.string().trim().optional().allow('').allow(null).empty('').label('Middle Name'),
  lastName: joi.string().trim().optional().allow('').allow(null).empty('').label('Last Name'),
  suffix: joi.string().trim().optional().allow('').allow(null).empty('').label('Suffix'),
  prefix: joi.string().trim().optional().allow('').allow(null).empty('').label('Prefix'),
  originId: joi.string().optional().allow('').allow(null).empty('').label('Origin ID'),
  gender: joi.string().optional().allow('').allow(null).empty('').label('Gender'),
  _contactId: joi.string().optional().allow('').allow(null).empty('').label('Import Command Post internal Contact ID'),
  _created_at_override: joi.date().optional().allow('').allow(null).empty('').label('ImportCreated At Override'),
  _updated_at_override: joi.date().optional().allow('').allow(null).empty('').label('Import Updated At Override'),
  isEmployee: joi.boolean(),
  contactTitle: joi.string().optional().allow('',null).trim().empty('').label('Title'),
  contactRole: joi.string().optional().allow('',null).trim().empty('').label('Contact Role'),
  userType: joi.alternatives().conditional('isEmployee', {
    is: true,
    then: joi.string().optional().allow('',null).empty(''),
    otherwise: joi
      .any()
      .allow('',null)
      .custom(() => void 0),
  }),
  defaultAccess: joi.alternatives().conditional('isEmployee', {
    is: true,
    then: joi.boolean().required(),
    otherwise: joi.boolean().custom(() => void 0),
  }),
  phoneArr: joi
    .array()
    .items(
      joi.object({
        _id: joi.allow(),
        phone: joi.string().optional().allow('').allow(null).empty('').label('Phone Number'),
        isPrimary: joi.boolean().custom(() => void 0),
        type: joi
          .string()
          .optional()
          .allow('')
          .allow(null)
          .empty('')
          .default('Mobile')
          .label('Phone Type'),
        ext: joi
          .string()
          .optional()
          .allow('')
          .allow(null)
          .empty('')
          .default('1')
          .label('Country Code'),
        extPhone: joi
          .string()
          .optional()
          .allow('')
          .allow(null)
          .empty('')
          .default('')
          .label('Extension Phone Number'),
        country: joi
          .string()
          .optional()
          .allow('')
          .allow(null)
          .empty('')
          .default('')
          .label('Country'),
        fullPhone: joi
          .string()
          .optional()
          .allow('')
          .allow(null)
          .empty('')
          .default('')
          .label('Full Phone'),
        phoneClean: joi
          .string()
          .optional()
          .allow('')
          .allow(null)
          .empty('')
          .default('')
          .label('Cleaned Phone Number'),
      })
    )
    .label('Phones'),
  address1: joi.string().trim().optional().allow('').allow(null).empty('').label('Address 1'),
  address2: joi.string().trim().optional().allow('').allow(null).empty('').label('Address 2'),
  city: joi.string().trim().optional().allow('').allow(null).empty('').label('City'),
  state: joi.string().trim().optional().allow('').allow(null).empty('').label('State'),
  country: joi.string().trim().optional().allow('').allow(null).empty('').label('Country'),
  zipCode: joi.string().trim().optional().allow('').allow(null).empty('').label('Zip Code'),
  venderArr: joi.array().items(
    joi.object({
      venderId: joi.string().optional().allow('').allow(null).empty(''),
      label: joi.string().optional().allow('').allow(null).empty(''),
      contactRole: joi.string().optional().allow('').allow(null).empty(''),
    })
  ),
  comments: joi.string().trim().optional().allow('').allow(null).empty('').label('Comments'),
  dateOfBirthday: joi
    .any()
    .optional()
    .allow('')
    .allow(null)
    .custom((date, helper) => {
      if (date.isBefore(moment())) {
        return date;
      } else {
        return helper.message('Date cannot be in the future' as any);
      }
    }),
  pictureFile: joi.allow(),
  userImage: joi.allow(),
  customFields: joi.object().optional().allow('').allow(null).empty(''),
});

export default updateContactSchema
