import { uniqueId, isEmpty } from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { usePageTitle } from '../../layouts/title-context';
import { ResourceScheduleFilterModal } from '../../modals/resource-schedule-filter-modal';
import { getEstimatingScheduleReport } from '../../modules/reports';
import { selectGetEstimatingScheduleReport } from '../../modules/reports/selectors';
import { useAppDispatch } from '../../shared/hooks/use-app-dispatch';
import { useAppSelector } from '../../shared/hooks/use-app-selector';
import { Scheduler, useSchedulerResources } from '../../shared/scheduler';
import { formatDate } from '../../utils/date-formatters';
import { affiliationLinkFormatter } from '../../utils/formatters';
import { AddProjectModal } from '../../modals/add-project-modal';
import { BidModal } from '../../modals/bid-modal/bid-modal';
import { getExistingProjectAction } from '../../actions/project';
import { getProjectRoles } from '../../modules/settings';
import { getAccountProjectSettings } from '../../modules/accounts';
import { getDragMessage, getRoleName, onBeforeEventDropFinalize } from '../../utils/report-helper';
import { ManageContractModal } from '../../modals/manage-contract-modal';
import ProjectStatusModal from '../../components/Common/ProjectStatusModal.jsx';
import { ProjectStatusModalProps } from '../../components/Common/ProjectStatusModalProps.d';
import { ReassignmentModal } from '../../modals/reassignment-modal';
import { reassignProjects } from '../../modules/projects';

export const EstimatingScheduleReportPage = () => {
  usePageTitle('Estimating Schedule Report');

  const dispatch = useAppDispatch();
  const [projectRoles, setProjectRoles] = useState<any>([]);

  const [formDefaultValues, setFormDefaultValues] = useState<any>({
    showBy: 'days',
    startDateOption: 'today',
    groupByOption: 'estimator',
    jobStatusOption: ['bidding'],
    startDate: moment().startOf('day'),
    groupBy: null,
    isGroupShared: false,
  });

  const [open, setOpen] = useState(false);
  const [filters, setFilters] = useState<any>({});
  const [editProjectId, setEditProjectId] = useState<string>('');
  const [schedulerFilters, setSchedulerFilters] = useState<any>({});
  const [reassignmentChanges, setReassignmentChanges] = useState<Array<any>>([]);
  const [isReassignModalOpen, setIsReassignModalOpen] = useState<boolean>(false);
  const [isAddProjectModalOpen, setIsAddProjectModalOpen] = useState<boolean>(false);
  const [isBidManagementModalOpen, setIsBidManagementModalOpen] = useState<boolean>(false);
  const [isManageContractModalOpen, setIsManageContractModalOpen] = useState<boolean>(false);
  const [isPromoteToBiddingProcess, setIsPromoteToBiddingProcess] = useState<boolean>(false);
  const [accountSettings, setAccountSettings] = useState<any>({});

  const schedulerRef = useRef<{ setIsReadOnly: (isReadOnly: boolean) => void }>({
    setIsReadOnly: (isReadOnly: boolean) => isReadOnly,
  });

  const addProjectModalRef = useRef<{ getProjectData: () => void }>({
    getProjectData: () => undefined,
  });

  const projectStatusModalRef = useRef<ProjectStatusModalProps>();

  const getData = useCallback(() => {
    if (!isEmpty(filters)) {
      dispatch(getEstimatingScheduleReport(filters)).unwrap();
    } else {

      // i don't think this is necessary, but filters is always empty
      const filtersStorage = localStorage.getItem('estimatingScheduleReportFilters');
      if (filtersStorage) {
        const settings = JSON.parse(filtersStorage);

        const { dataFilters } = settings;

        if (dataFilters) {
          dispatch(getEstimatingScheduleReport(dataFilters)).unwrap();
        }
      }
    }
  }, [filters]);

  useEffect(() => {
    const filtersStorage = localStorage.getItem('estimatingScheduleReportFilters');

    if (!filtersStorage) {
      setOpen(true);
      return;
    }

    const settings = JSON.parse(filtersStorage);

    const { dataFilters, schedulerSettings, formSettings } = settings;

    setFilters(dataFilters);
    setSchedulerFilters(schedulerSettings);
    setFormDefaultValues({ ...formSettings, startDate: moment(formSettings.startDate) });
    dispatch(getProjectRoles())
      .unwrap()
      .then((roles) => setProjectRoles(roles));
    dispatch(getAccountProjectSettings({}))
      .unwrap()
      .then((data) => setAccountSettings({ ...data }));
  }, []);

  useEffect(() => {
    getData();
  }, [filters]);

  const dateRenderer = (recordData) => (recordData.value ? formatDate(recordData.value) : '');

  const { data: report, loading: scheduleLoading } = useAppSelector(
    selectGetEstimatingScheduleReport
  );

  const getProjectFields = (project) => ({
    jobName: project.jobName,
    statusName: project.statusName,
    startDate: project.startDate?.length > 0 ? moment.utc(project.startDate).toDate() : '',
    projectId: project._id,
    endDate: project.endDate?.length > 0 ? moment.utc(project.endDate).toDate() : '',
    bidDue: project.bidDue?.length > 0 ? moment.utc(project.bidDue).toDate() : '',
    markedResolved:
      project.markedResolved?.length > 0 ? moment.utc(project.markedResolved).toDate() : '',
    jobNumber: project.jobNumber,
    altJobNum: project.altJobNum,
    jobAddress: project.jobAddress,
    jobAddress2: project.jobAddress2,
    jobCity: project.jobCity,
    jobState: project.jobState,
    jobZip: project.jobZip,
    jobCountry: project.jobCountry,
    lat: project.lat,
    lon: project.lon,
    timezone: project.timezone,
    bidStart: project.bidStart?.length > 0 ? moment.utc(project.bidStart).toDate() : '',
    statusCode: project.statusCode,
    estimators: project.estimators,
    accountExecs: project.accountExecs,
    bidSubmittedDate:
      project.bidSubmittedDate?.length > 0 ? moment.utc(project.bidSubmittedDate).toDate() : '',
    clients: project.clients,
    isProject: true,
  });

  const getContactFields = (contact) => ({
    contactAffiliationId: contact._id,
    contactFirstName: contact.contactFirstName,
    contactLastName: contact.contactLastName,
    companyName: contact.companyName,
    contactId: contact.contactId,
    companyId: contact.companyId,
    isAccountAffiliation: contact.isAccountAffiliation,
    isInternal: contact.isInternal,
    eventColor: contact.calendarColor,
    imageUrl: contact.userImage,
  });

  const resources = useSchedulerResources({
    groupBy: schedulerFilters.groupBy,
    isGroupShared: schedulerFilters.isGroupShared,
    report,
    getProjectFields,
    getContactFields,
  });

  const events = useMemo(() => {
    const result: any[] = [];

    const getResourcePrefix = (contact, project) => {
      if (schedulerFilters.isGroupShared || !schedulerFilters.groupBy) {
        return `${project._id}`;
      }

      return `${contact._id}-${project._id}`;
    };

    const pushEvent = (contact, project) => {
      const {
        statusCode,
        bidStart,
        bidDue,
        markedResolved,
        statusColor,
        bidStatusName,
        bidStatusCode,
        bidStatusColor,
        pendingStatusCode,
        pendingStatusName,
        pendingStatusColor,
        resolvedStatusCode,
        resolvedStatusName,
        resolvedStatusColor,
        bidSubmittedDate,
      } = project;

      const { r, g, b, a } = statusColor || { r: 0, g: 0, b: 0, a: 1 };

      const {
        r: bpr,
        g: bpg,
        b: bpb,
        a: bpa,
      } = pendingStatusColor || statusColor || { r: 0, g: 0, b: 0, a: 1 };

      const {
        r: br,
        g: bg,
        b: bb,
        a: ba,
      } = bidStatusColor || statusColor || { r: 0, g: 0, b: 0, a: 1 };

      const {
        r: rr,
        g: rg,
        b: rb,
        a: ra,
      } = resolvedStatusColor || statusColor || { r: 0, g: 0, b: 0, a: 1 };

      const today = new Date().toISOString();

      const isResolved = ['A', 'SNS', 'XC', 'R', 'SUSP', 'D', 'W', 'C'].includes(statusCode);
      const isNotPending = ['B', 'P1', 'P2', 'P3', 'OH', 'PC'].includes(statusCode) || isResolved;
      const isPending = ['BP', 'P1P', 'P2P', 'P3P', 'OHP'].includes(statusCode);
      //const isOnHold = ['OH', 'PC', 'OHP'].includes(statusCode);

      const showBidDue = () => {
        if (!bidDue || moment(bidDue).isSameOrBefore(moment(bidStart))) {
          return bidStart;
        }

        return bidDue;
      };

      const showBidStart = () => {
        if (!bidStart || moment(bidStart).isSameOrAfter(moment(bidDue))) {
          return bidDue;
        }

        return bidStart;
      };

      // If project is pending and has no bidSubmitted date, use bidDue date instead

      const showBidSubmittedDate = () => {
        if (!bidSubmittedDate) {
          return bidDue;
        }

        return bidSubmittedDate;
      };

      const showResolvedDate = () => {
        if (!markedResolved) {
          return null;
        }

        return markedResolved;
      };

      const isCurrent = isNotPending && showBidDue() && showBidDue() >= today;
      const isOverdue = !isResolved && isNotPending && showBidDue() && showBidDue() < today;
      const wasOverdue = isResolved && showBidDue() && showBidSubmittedDate() > showBidDue();

      const bidDateIsMilestone = moment(showBidStart()).isSame(showBidDue());

      const showPendingPeriod = isResolved && showBidSubmittedDate() < showResolvedDate();

      const resourcePrefix = getResourcePrefix(contact, project);

      if (isResolved && !wasOverdue) {
        result.push({
          id: uniqueId(`${resourcePrefix}-${statusCode}-${bidStatusCode}-`),
          resourceId: resourcePrefix,
          name: bidStatusName?.length > 0 ? bidStatusName : 'Bidding',
          startDate: showBidStart(),
          endDate: showBidSubmittedDate(),
          milestoneWidth: bidDateIsMilestone ? 1 : 10,
          eventColor: `rgba(${br}, ${bg}, ${bb}, ${ba})`,
        });

        if (showPendingPeriod) {
          result.push({
            id: uniqueId(`${resourcePrefix}-${statusCode}-${pendingStatusCode}-`),
            resourceId: resourcePrefix,
            name: pendingStatusName?.length > 0 ? pendingStatusName : 'Pending',
            startDate: showBidSubmittedDate(),
            endDate: showResolvedDate(),
            eventColor: `rgba(${bpr}, ${bpg}, ${bpb}, ${bpa / 3})`,
          });
        }

        result.push({
          id: uniqueId(`${resourcePrefix}-${statusCode}-${resolvedStatusCode}-RS`),
          resourceId: resourcePrefix,
          name: 'Marked as ' + resolvedStatusName,
          startDate: showResolvedDate(),
          endDate: showResolvedDate(),
          eventColor: `rgba(${rr}, ${rg}, ${rb}, ${ra})`,
        });
      }

      if (isCurrent) {
        result.push({
          id: uniqueId(`${resourcePrefix}-${statusCode}-${project.statusCode}-`),
          resourceId: resourcePrefix,
          name: project.statusName,
          startDate: showBidStart(),
          endDate: showBidDue(),
          eventColor: `rgba(${r}, ${g}, ${b}, ${a})`,
        });
        if(!bidDateIsMilestone){
          result.push({
            id: uniqueId(`${resourcePrefix}-${statusCode}-Milestone`),
            resourceId: `${resourcePrefix}`,
            name: '',
            startDate: showBidDue(),
            endDate: showBidDue(),
            milestoneWidth: 1,
            eventColor: `rgba(0, 0, 0, 255)`,
          });
        }
      }

      if (isOverdue) {
        result.push({
          id: uniqueId(`${resourcePrefix}-${statusCode}-`),
          resourceId: `${resourcePrefix}`,
          name: project.statusName,
          startDate: showBidStart(),
          endDate: showBidDue(),
          milestoneWidth: bidDateIsMilestone ? 1 : 10,
          eventColor: `rgba(${r}, ${g}, ${b}, ${a})`,
        });

        if(!bidDateIsMilestone){
          result.push({
            id: uniqueId(`${resourcePrefix}-${statusCode}-Milestone`),
            resourceId: `${resourcePrefix}`,
            name: '',
            startDate: showBidDue(),
            endDate: showBidDue(),
            milestoneWidth: 1,
            eventColor: `rgba(0, 0, 0, 255)`,
          });
        }

        // push red line from bidDue to today

        result.push({
          id: uniqueId(`${resourcePrefix}-${statusCode}-`),
          resourceId: `${resourcePrefix}`,
          name: `${project.statusName} (Overdue)`,
          startDate: showBidDue(),
          endDate: today,
          eventColor: `rgba(255, 0, 0, 1)`,
        });
      }

      if (wasOverdue) {
        result.push({
          id: uniqueId(`${resourcePrefix}-${statusCode}-${bidStatusCode}-`),
          resourceId: `${resourcePrefix}`,
          name: bidStatusName,
          startDate: showBidStart(),
          endDate: showBidDue(),
          milestoneWidth: bidDateIsMilestone ? 1 : 10,
          eventColor: `rgba(${br}, ${bg}, ${bb}, ${ba})`,
        });

        // push red line from bidDue to today

        result.push({
          id: uniqueId(`${resourcePrefix}-${statusCode}-${bidStatusCode}-OD`),
          resourceId: `${resourcePrefix}`,
          name: `${bidStatusName} (Overdue)`,
          startDate: showBidDue(),
          endDate: showBidSubmittedDate(),
          eventColor: `rgba(255, 0, 0, 1)`,
        });
      }

      if (isPending) {
        result.push({
          id: uniqueId(`${resourcePrefix}-${statusCode}-${bidStatusCode}-`),
          resourceId: `${resourcePrefix}`,
          name: bidStatusName?.length > 0 ? bidStatusName : 'Bidding',
          startDate: showBidStart(),
          endDate: showBidSubmittedDate(),
          milestoneWidth: bidDateIsMilestone ? 1 : 10,
          eventColor: `rgba(${br}, ${bg}, ${bb}, ${ba})`,
        });

        if(showBidSubmittedDate()){
          result.push({
            id: uniqueId(`${resourcePrefix}-${statusCode}-${bidStatusCode}-Submitted_Milestone`),
            resourceId: `${resourcePrefix}`,
            name: '',
            startDate: showBidSubmittedDate(),
            endDate: showBidSubmittedDate(),
            milestoneWidth: 1,
            eventColor: `rgba(0, 0, 0, 255)`,
          });
        }

        

        result.push({
          id: uniqueId(`${resourcePrefix}-${statusCode}-${pendingStatusCode}-`),
          resourceId: `${resourcePrefix}`,
          name: `${pendingStatusName}`,
          startDate: showBidSubmittedDate(),
          endDate: today,
          eventColor: `rgba(${r}, ${g}, ${b}, ${a})`,
        });
      }

      if (
        (!isPending && moment(project.startDate).isAfter(moment(bidDue))) ||
        (isPending && moment(project.startDate).isAfter(moment(bidSubmittedDate)))
      ) {
        result.push({
          id: uniqueId(`${resourcePrefix}-${statusCode}-ProjectStart`),
          resourceId: `${resourcePrefix}`,
          name: `Project Start`,
          startDate: project.startDate,
          endDate: project.startDate,
          hideText: true,
          eventStyle: moment(project.startDate).isBefore(today) ? 'minimal' : 'hollow',
          eventColor: `rgba(${r / 2}, ${g / 2}, ${b / 2}, ${a})`, // make start color darker
        });
      }
    };

    if (!schedulerFilters.isGroupShared && schedulerFilters.groupBy) {
      report?.forEach((contact) => {
        contact.projects?.forEach((project) => pushEvent(contact, project));
      });
    } else {
      report?.forEach((project) => {
        pushEvent(null, project);
      });
    }

    return result;
  }, [report, schedulerFilters.isGroupShared, schedulerFilters.groupBy, scheduleLoading]);

  const columns = useMemo(
    () => [
      {
        type: 'tree',
        text: 'Project Name',
        field: 'jobName',
        minWidth: 300,
        align: 'left',
        htmlEncode: false,
        autoWidth: true,
        searchable: true,
        filterable: {
          filterField: {
            placeholder: 'Filter Project Name',
          },
        },
        renderer: (recordData) => {
          const { record } = recordData;

          const isContact = record.data.isContact;
          if (isContact) {
            const { contactAffiliationId } = record.data;

            if (contactAffiliationId === 'unassigned') {
              return '<span>Unassigned</span>';
            }

            return affiliationLinkFormatter(record.data);
          }

          const slug = recordData.record.data.projectId;

          return (
            <a
              href={`/project-details/${slug}`}
              target='_blank'
              rel='noreferrer'
              data-disable-bullets={recordData.record.data.disableBullets || false}
            >
              {recordData.record.data.jobName}
            </a>
          );
        },
      },
      {
        text: 'Est. Start',
        field: 'bidStart',
        align: 'center',
        type: 'date',
        hidden: !accountSettings.enableEstimatingScheduling,
        filterable: {
          filterField: {
            type: 'date',
          },
        },
        renderer: dateRenderer,
      },
      {
        text: 'Due date',
        field: 'bidDue',
        align: 'center',
        type: 'date',
        filterable: {
          filterField: {
            type: 'date',
          },
        },
        renderer: dateRenderer,
        hidden: false,
      },
      {
        text: 'Submitted',
        field: 'bidSubmittedDate',
        align: 'center',
        renderer: dateRenderer,
        type: 'date',
        filterable: {
          filterField: {
            type: 'date',
          },
        },
        hidden: false,
      },
      {
        text: 'Resolved',
        field: 'markedResolved',
        align: 'center',
        renderer: dateRenderer,
        type: 'date',
        filterable: {
          filterField: {
            type: 'date',
          },
        },
        hidden: true,
      },
      {
        text: 'Status',
        field: 'statusCode',
        align: 'center',
        autoWidth: false,
        hidden: false,
        width: 40,
        filterable: {
          filterField: {
            type: 'combo',
            multiSelect: true,
            operator: '=',
            inlinePicker: true,
            placeholder: 'Select Status',
          },
          filterFn: (record) =>
            !(record.value?.length > 0) ||
            record.value.includes(record.record.data[record.property]),
        },
      },
      {
        text: 'Status Name',
        field: 'statusName',
        align: 'center',
        autoWidth: true,
        hidden: true,
        filterable: {
          filterField: {
            type: 'combo',
            multiSelect: true,
            operator: '=',
            inlinePicker: true,
            placeholder: 'Select Status',
          },
          filterFn: (record) =>
            !(record.value?.length > 0) ||
            record.value.includes(record.record.data[record.property]),
        },
      },
      {
        text: 'Job Number',
        field: 'jobNumber',
        align: 'center',
        hidden: true,
      },
      {
        text: 'Alternate Job Number',
        field: 'altJobNum',
        align: 'center',
        hidden: true,
      },
      {
        text: 'Project Start',
        field: 'startDate',
        align: 'center',
        type: 'date',
        renderer: dateRenderer,
        filterable: {
          filterField: {
            type: 'date',
          },
        },
        hidden: true,
      },
      {
        text: 'Address',
        field: 'jobAddress',
        align: 'center',
        hidden: true,
        renderer: (recordData) => {
          const { record } = recordData;
          const { originalData } = record;

          let jobAddressString =
            (originalData.jobAddress ? originalData.jobAddress : '') +
            (originalData.jobAddress ? ', ' : '') +
            (originalData.jobAddress2 ? originalData.jobAddress2 : '') +
            (originalData.jobAddress2 ? ', ' : '');
          jobAddressString +=
            (originalData.jobCity ? originalData.jobCity : '') +
            (originalData.jobCity ? ', ' : '') +
            (originalData.jobState ? originalData.jobState : '') +
            (originalData.jobState ? ', ' : '') +
            (originalData.jobZip ? originalData.jobZip : '');

          return (
            <div className='d-flex flex-column px-1'>
              <span>{jobAddressString}</span>
            </div>
          );
        },
      },
      {
        text: 'Estimator',
        field: 'estimators',
        type: 'widget',
        sortable: (rec1: any, rec2: any) => {
          const data1 = rec1?.data?.estimators?.['0'];
          const data2 = rec2?.data?.estimators?.['0'];

          const name1 =
            data1 &&
            [data1?.companyName, data1?.contactFirstName, data1?.contactLastName]
              .filter(Boolean)
              .join(' ');
          const name2 =
            data2 &&
            [data2?.companyName, data2?.contactFirstName, data2?.contactLastName]
              .filter(Boolean)
              .join(' ');

          if (name1 && name2) {
            return name1.localeCompare(name2);
          } else if (name1 && !name2) {
            return 1;
          } else if (name2 && !name1) {
            return -1;
          } else return 0;
        },
        autoWidth: true,
        minWidth: 150,
        hidden: true,
        // render as jsx list of links
        renderer: (recordData) => {
          const { record } = recordData;

          const estimators = record.data.estimators || [];

          recordData.size.height = Math.max(estimators.length * 20, 40);

          return (
            <div className='d-flex flex-column px-1'>
              {estimators.map((estimator) => (
                <React.Fragment key={estimator._id}>
                  {affiliationLinkFormatter(estimator)}
                </React.Fragment>
              ))}
            </div>
          );
        },
      },
      {
        text: 'Account Executive',
        field: 'accountExecs',
        type: 'widget',
        sortable: (rec1: any, rec2: any) => {
          const data1 = rec1?.data?.accountExecs?.['0'];
          const data2 = rec2?.data?.accountExecs?.['0'];

          const name1 =
            data1 &&
            [data1?.companyName, data1?.contactFirstName, data1?.contactLastName]
              .filter(Boolean)
              .join(' ');
          const name2 =
            data2 &&
            [data2?.companyName, data2?.contactFirstName, data2?.contactLastName]
              .filter(Boolean)
              .join(' ');

          if (name1 && name2) {
            return name1.localeCompare(name2);
          } else if (name1 && !name2) {
            return 1;
          } else if (name2 && !name1) {
            return -1;
          } else return 0;
        },
        hidden: true,
        autoWidth: true,
        minWidth: 150,
        renderer: (recordData) => {
          const { record } = recordData;

          const accountExecs = record.data.accountExecs || [];

          recordData.size.height = Math.max(accountExecs.length * 20, 40);

          return (
            <div className='d-flex flex-column px-1'>
              {accountExecs.map((accountExec) => (
                <React.Fragment key={accountExec._id}>
                  {affiliationLinkFormatter(accountExec)}
                </React.Fragment>
              ))}
            </div>
          );
        },
      },
      {
        text: 'Client',
        field: 'clients',
        type: 'widget',
        hidden: true,
        autoWidth: true,
        sortable: (rec1: any, rec2: any) => {
          const data1 = rec1?.data?.clients?.['0'];
          const data2 = rec2?.data?.clients?.['0'];

          const name1 =
            data1 &&
            [data1?.companyName, data1?.contactFirstName, data1?.contactLastName]
              .filter(Boolean)
              .join(' ');
          const name2 =
            data2 &&
            [data2?.companyName, data2?.contactFirstName, data2?.contactLastName]
              .filter(Boolean)
              .join(' ');

          if (name1 && name2) {
            return name1.localeCompare(name2);
          } else if (name1 && !name2) {
            return 1;
          } else if (name2 && !name1) {
            return -1;
          } else return 0;
        },
        minWidth: 150,
        renderer: (recordData) => {
          const { record } = recordData;

          const clients = record.data.clients || [];

          recordData.size.height = Math.max(clients.length * 20, 40);

          return (
            <div className='d-flex flex-column px-1'>
              {clients.map((client) => (
                <React.Fragment key={client._id}>{affiliationLinkFormatter(client)}</React.Fragment>
              ))}
            </div>
          );
        },
      },
    ],
    [accountSettings]
  );

  const resourceStore = useMemo(
    () => ({
      fields: [
        'jobName',
        'statusName',
        'startDate',
        'endDate',
        'bidDue',
        'jobNumber',
        'bidStart',
        'statusCode',
        'altJobNum',
        'jobAddress',
        'jobAddress2',
        'jobCity',
        'jobState',
        'jobZip',
        'jobCountry',
        'lat',
        'lon',
        'timezone',
        'estimators',
        'accountExecs',
        'clients',
        'bidSubmittedDate',
      ],
    }),
    []
  );

  const onFiltersApplied = async (settings) => {
    const { dataFilters, schedulerSettings } = settings;

    setFilters(dataFilters);
    setSchedulerFilters(schedulerSettings);
    await dispatch(getEstimatingScheduleReport(dataFilters)).unwrap();

    setFormDefaultValues(settings.formSettings);
    localStorage.setItem('estimatingScheduleReportFilters', JSON.stringify(settings));
  };

  const modalConfig = useMemo(
    () => ({
      statusOptions: [
        {
          value: 'bidding',
          label: 'Bidding, P1, P2, P3',
          defaultChecked: true,
        },
        {
          value: 'bidsPending',
          label: 'Bids pending, P1P, P2P, P3P',
        },
        {
          value: 'onHold',
          label: 'On Hold',
        },
        {
          value: 'resolvedBids',
          label: 'Awarded, Lost, Rescinded',
        },
      ],
      getStatusCodesFromOptions: (options) =>
        options
          .map((option: string) => {
            switch (option) {
              case 'bidding':
                return ['B', 'P1', 'P2', 'P3'];
              case 'bidsPending':
                return ['BP', 'P1P', 'P2P', 'P3P'];
              case 'onHold':
                return ['OH', 'OHP', 'OC'];
              case 'resolvedBids':
                return ['A', 'SNS', 'XC', 'R', 'SUSP', 'D', 'W', 'C'];
              default:
                return [];
            }
          })
          .flat(),
      groupByOptions: [
        {
          value: 'none',
          code: null,
          label: 'None',
        },
        {
          value: 'estimator',
          code: 'EST',
          label: 'Estimator',
          defaultChecked: true,
        },
        {
          value: 'accountExecutive',
          code: 'AX',
          label: 'Account Executive',
        },
        {
          value: 'client',
          code: 'CL',
          label: 'Client',
        },
      ],
    }),
    []
  );

  const visibleColumns = useMemo(() => {
    const storedColumns = localStorage.getItem('estimatingScheduleReportColumns');

    if (storedColumns) {
      return JSON.parse(storedColumns);
    }

    return columns.filter((column) => !column.hidden).map((column) => column.field);
  }, [columns]);

  const handleColumnHide = (columnName) => {
    const preferences = localStorage.getItem('estimatingScheduleReportColumns');

    if (preferences) {
      const parsedPreferences = JSON.parse(preferences);

      const visibleColumns = parsedPreferences.filter((column) => column !== columnName);

      localStorage.setItem('estimatingScheduleReportColumns', JSON.stringify(visibleColumns));
    } else {
      localStorage.setItem(
        'estimatingScheduleReportColumns',
        JSON.stringify(visibleColumns.filter((column) => column !== columnName))
      );
    }
  };

  const handleColumnShow = (columnName) => {
    const preferences = localStorage.getItem('estimatingScheduleReportColumns');

    if (preferences) {
      const parsedPreferences = JSON.parse(preferences);

      const visibleColumns = [...parsedPreferences, columnName];

      localStorage.setItem('estimatingScheduleReportColumns', JSON.stringify(visibleColumns));
    } else {
      localStorage.setItem(
        'estimatingScheduleReportColumns',
        JSON.stringify([...visibleColumns, columnName])
      );
    }
  };

  const columnNames = useMemo(() => {
    return [
      {
        field: 'estimators',
        text: projectRoles?.find((role) => role.code === 'EST')?.name || 'Estimator',
      },
      {
        field: 'accountExecs',
        text: projectRoles?.find((role) => role.code === 'AX')?.name || 'Account Executive',
      },
      {
        field: 'clients',
        text: projectRoles?.find((role) => role.code === 'CL')?.name || 'Client',
      },
    ];
  }, [projectRoles]);

  const onEditSubmit = useCallback(async () => {
    getData();
  }, [getData]);

  const onOpenBidModalClick = useCallback((projectId = '') => {
    if (projectId) {
      setEditProjectId(projectId);
      setIsBidManagementModalOpen(true);
    }
  }, []);

  const onEditProjectButtonClick = useCallback((projectId = '') => {
    if (projectId) {
      setEditProjectId(projectId);
      setIsAddProjectModalOpen(true);
    }
  }, []);

  const onBidManagementModalSubmit = useCallback(() => {
    addProjectModalRef.current.getProjectData();
    getData();
  }, []);

  const getProjectId = useCallback(
    (event: any) =>
      event.eventRecord?.data?.resourceId.includes('-')
        ? event.eventRecord?.data?.resourceId?.split('-')?.[1]
        : event.eventRecord?.data?.resourceId,
    []
  );

  const [statusModal, setStatusModal] = useState(false);
  const [statusUpdateType, setStatusModalUpdateType] = useState('');

  const toggleStatusUpdate = (updateType) => {
    if (!statusModal) {
      setStatusModalUpdateType(updateType);
    } else {
      setStatusModalUpdateType('');
    }

    setStatusModal(!statusModal);
  };

  const onUpdateStatus = useCallback(async (projectId: string, statusUpdateType: string) => {
    if (projectId) {
      await dispatch(getExistingProjectAction({ projectId }));
      setEditProjectId(projectId);
      toggleStatusUpdate(statusUpdateType);
    }
  }, []);

  const getProjectStatusCode = useCallback(
    (eventRecord: any) =>
      eventRecord?.data?.id.includes('-') ? eventRecord?.data?.id?.split('-')?.[2] : '',
    []
  );

  const eventMenuFeature = {
    items: {
      copyEvent: false,
      cutEvent: false,
      deleteEvent: false,
      unassignEvent: false,
    },
    processItems({ eventRecord, items }) {
      const statusCode = getProjectStatusCode(eventRecord);

      const isResolved = ['A', 'SNS', 'XC', 'R', 'SUSP', 'D', 'W', 'C'].includes(statusCode);
      //const isNotPending = ['B', 'P1', 'P2', 'P3', 'OH', 'PC'].includes(statusCode) || isResolved;
      const isPending = ['BP', 'P1P', 'P2P', 'P3P', 'OHP'].includes(statusCode);

      items.editProject = {
        text: 'Edit Project',
        icon: 'fa fa-pencil',
        onItem: (event: any) => onEditProjectButtonClick(getProjectId(event)),
      };
      if (!isResolved) {
        items.manageBid = {
          text: 'Manage Bid',
          icon: 'fa fa-clock',
          onItem: (event: any) => onOpenBidModalClick(getProjectId(event)),
        };
      }

      if (!isResolved) {
        const actionMenuItems = [] as any[];

        if (isPending) {
          actionMenuItems.push({
            text: 'Selected/Awarded',
            icon: 'fa fa-clock',
            onItem: (event: any) => onUpdateStatus(getProjectId(event), 'Approved'),
          });
          actionMenuItems.push({
            text: 'Rebid',
            icon: 'fa fa-clock',
            onItem: (event: any) => onUpdateStatus(getProjectId(event), 'Rebid'),
          });
          actionMenuItems.push({
            text: 'Lost',
            icon: 'fa fa-clock',
            onItem: (event: any) => onUpdateStatus(getProjectId(event), 'Lost'),
          });
          actionMenuItems.push({
            text: 'Rescinded',
            icon: 'fa fa-clock',
            onItem: (event: any) => onUpdateStatus(getProjectId(event), 'Rescinded'),
          });
          actionMenuItems.push({
            text: 'On Hold',
            icon: 'fa fa-clock',
            onItem: (event: any) => onUpdateStatus(getProjectId(event), 'bidding'),
          });
        } else {
          actionMenuItems.push({
            text: 'Submitted',
            icon: 'fa fa-clock',
            onItem: (event: any) => onUpdateStatus(getProjectId(event), 'Submitted'),
          });
          actionMenuItems.push({
            text: 'Selected/Awarded',
            icon: 'fa fa-clock',
            onItem: (event: any) => onUpdateStatus(getProjectId(event), 'DirectPromote'),
          });
          actionMenuItems.push({
            text: 'Not Bid',
            icon: 'fa fa-clock',
            onItem: (event: any) => onUpdateStatus(getProjectId(event), 'Closed'),
          });
          actionMenuItems.push({
            text: 'Lost',
            icon: 'fa fa-clock',
            onItem: (event: any) => onUpdateStatus(getProjectId(event), 'DirectLost'),
          });
          actionMenuItems.push({
            text: 'Rebid',
            icon: 'fa fa-clock',
            onItem: (event: any) => onUpdateStatus(getProjectId(event), 'DirectReschedule'),
          });
          actionMenuItems.push({
            text: 'On Hold',
            icon: 'fa fa-clock',
            onItem: (event: any) => onUpdateStatus(getProjectId(event), 'On Hold'),
          });
        }

        items.actionMenu = {
          text: 'Mark as ...',
          menu: actionMenuItems,
        };
      }
    },
  };

  const eventDragFeature = useMemo(
    () => ({
      constrainDragToTimeSlot: true,
      validatorFn({ eventRecords, newResource }) {
        const sourceParentId = eventRecords[0]?.resource.parentId;
        const targetParentId = newResource.parentId;
        const targetIsRoot = targetParentId === null;
        const targetResource = targetIsRoot ? newResource : newResource.parent;

        const valid = targetIsRoot
          ? newResource.id !== sourceParentId
          : sourceParentId !== targetParentId;
        const message = getDragMessage(targetResource.data, valid);

        return { valid, message };
      },
    }),
    []
  );

  const ProjectStatusModalComponent: React.ComponentType<ProjectStatusModalProps> =
    ProjectStatusModal;

  const afterStatusUpdate = () => {
    getData();
  };

  const onSaveReassign = useCallback(
    async (changes: any) => {
      setReassignmentChanges(changes);
      setIsReassignModalOpen(true);
    },
    [schedulerFilters.groupBy, resources]
  );

  const onAfterSaveReassign = useCallback(
    async (data: any) => {
      schedulerRef.current.setIsReadOnly(true);
      await dispatch(reassignProjects({ roleId: schedulerFilters.groupBy, data })).unwrap();
      getData(); // refresh data from server
    },
    [schedulerFilters.groupBy]
  );

  const roleName = useMemo(
    () => getRoleName(projectRoles, schedulerFilters.groupBy),
    [projectRoles, schedulerFilters.groupBy]
  );

  const onNextProjectStatusModalClick = () => {
    projectStatusModalRef.current?.toggleModal();
    setIsManageContractModalOpen(true);
  };

  const onSubmitManageContract = (currentProjectData) => {
    //if (this.state.isPromoteToActiveProcess) {
    projectStatusModalRef.current?.submitUpdateProject({}, currentProjectData, 'Approved');
    //}
  };

  const onAfterPromoteToBidding = () => {
    setIsBidManagementModalOpen(true);
    setIsPromoteToBiddingProcess(true);
  };

  const onToggleBidModal = () => {
    setIsBidManagementModalOpen(false);
    setIsPromoteToBiddingProcess(false);
  };

  return (
    <>
      <ResourceScheduleFilterModal
        open={open}
        onClose={() => setOpen(false)}
        onFiltersApplied={onFiltersApplied}
        defaultValues={formDefaultValues}
        config={modalConfig}
      />
      <AddProjectModal
        open={isAddProjectModalOpen}
        showSaveAndNext={false}
        onClose={() => setIsAddProjectModalOpen(false)}
        projectId={editProjectId}
        onSubmit={onEditSubmit}
        onManageBid={() => onOpenBidModalClick(editProjectId)}
        ref={addProjectModalRef}
      />
      <BidModal
        isOpen={isBidManagementModalOpen}
        toggle={onToggleBidModal}
        toggleStatusUpdate={(updateType) => toggleStatusUpdate(updateType)}
        onSubmit={onBidManagementModalSubmit}
        bidScheduleExpanded={true}
        projectId={editProjectId}
        showSaveSubmit={!isAddProjectModalOpen}
        isPromote={isPromoteToBiddingProcess}
      />
      <ManageContractModal
        isOpen={isManageContractModalOpen}
        projectId={editProjectId}
        toggle={() => {
          setIsManageContractModalOpen(false);
          projectStatusModalRef.current?.toggleStatusUpdateReset();
        }}
        onSubmit={(projectData) => onSubmitManageContract(projectData)}
        //onSuggestProgress={(changes) => this.onSuggestProgress(changes)}
      />
      <ProjectStatusModalComponent
        projectId={editProjectId}
        modalStatusUpdate={statusModal}
        initialUpdateType={statusUpdateType}
        onNext={onNextProjectStatusModalClick}
        toggle={toggleStatusUpdate}
        onAfterUpdate={afterStatusUpdate}
        onAfterPromoteToBidding={onAfterPromoteToBidding}
        onAfterSubmit={getData}
        childRef={(ref) => (projectStatusModalRef.current = ref)}
      />
      <ReassignmentModal
        changes={reassignmentChanges}
        resources={resources}
        onAfterSave={onAfterSaveReassign}
        roleName={roleName}
        isOpen={isReassignModalOpen}
        toggle={() => setIsReassignModalOpen(false)}
      />

      <Scheduler
        title='Estimating Schedule Report'
        loading={scheduleLoading}
        startDate={schedulerFilters.startDate}
        endDate={schedulerFilters.endDate}
        resourceStore={resourceStore}
        columns={columns}
        resources={resources}
        events={events}
        eventMenuFeature={eventMenuFeature}
        eventDragFeature={eventDragFeature}
        viewPreset={schedulerFilters.viewPreset}
        onApplyClick={() => setOpen(true)}
        groupBy={schedulerFilters.groupBy}
        isGroupShared={schedulerFilters.isGroupShared}
        visibleColumns={visibleColumns}
        onColumnHide={handleColumnHide}
        onColumnShow={handleColumnShow}
        onSaveReassign={onSaveReassign}
        onBeforeEventDropFinalize={onBeforeEventDropFinalize}
        columnNames={columnNames}
        ref={schedulerRef}
      />
    </>
  );
};
