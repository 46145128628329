import { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { TextEditorField } from '../Common/TextEditorField';
import { InputFieldSubmit } from '../Common/InputFieldSubmit';
import Creatable from 'react-select/creatable';
import { useAppDispatch } from '../../shared/hooks/use-app-dispatch';
import { getAllUserListAction } from '../../actions/user';
import makeAnimated from 'react-select/animated';
import { formatContactDataHTMLDropdown } from '../../utils/contact-formatters';
import { getProjectRoles } from '../../modules/settings';
import Select from 'react-select';
import DatePickerWrapper from '../../shared/datepicker-wrapper/datepicker-wrapper';
import {
    formatDateAdd,
    formatDateObject,
    formatDateObjectWithTime,
    formatDateUTC,
  } from '../../utils/date-formatters';
import SearchPlaceAutocomplete from '../../shared/search-place-autocomplete';
import { createScheduleAction, getAllScheduleAction, cancelScheduleAction } from '../../actions/message';
import { Userpilot } from 'userpilot';
import { Confirm } from '../../shared/confirmation-dialog';

import renderHTML from 'html-react-parser';
import _ from 'lodash';
import axios from 'axios';
import { appConstants } from '../../_constants';
import ReactLoading from 'react-loading';
import {useAuth} from '../../components/Auth/Auth';
import { fileUploadOnS3 } from '../../utils/s3-attachment-handler';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import { findContactAffiliations, getContacts } from '../../modules/contacts';
import { getCompanyAffiliations } from '../../modules/companies';
import { AffiliationLookupModal } from '../../modals/affiliation-lookup-modal';
import {
  createGroup,
  GroupHeading,
  MenuList,
  formatGroupLabel,
  getContactOptions,
  getCompanyOptions,
  getProjectOptions,
} from './email-editor-helper';


window.Buffer = window.Buffer || require('buffer').Buffer;


let Validator = require('validatorjs');
let scheduleValidation = [];
let mess = {
  required: 'This field is required',
  alpha: 'This field may only contain letters',
  numeric: 'This field must be a number',
};
let rules = {
  scheduleTo: 'required',
  scheduleTitle: 'required',
  //scheduleTo: 'required',
  //scheduleLabel: 'required',
};
scheduleValidation = new Validator([], rules, mess);
scheduleValidation.passes();
scheduleValidation.fails();

const SCHEDULEREMINDER = [
    { label: 'No Reminder', value: -999 },
    { label: '5 minutes before', value: 5 },
    { label: '15 minutes before', value: 15 },
    { label: '30 minutes before', value: 30 },
    { label: '1 hour before', value: 60 },
    { label: '1 day before', value: 1440 },
  ];
  const MEETINGREPEAT = [
    { label: 'Does not repeat', value: -999 },
    { label: 'Daily', value: 1 },
    { label: 'Weekly', value: 7 },
    { label: 'Monthly', value: 30 },
  ];

const ScheduleEditor = (props) => {
  const dispatch = useAppDispatch();

  const [accountId] = useState(localStorage.getItem('accountId'));
  const [userId] = useState(localStorage.getItem('userId'));
  const [comName] = useState(localStorage.getItem('companyName'));
  const [scheduleTitle, setScheduleTitle] = useState('');
  const [formData, setFormData] = useState({});
  const [userList, setUserList] = useState([]);
  const [contactListData, setContactListData] = useState([]);
  const [, setFilesCount] = useState(0);
  const [userCommentError, setUserCommentError] = useState('');
  const [isSave, setIsSave] = useState(false);
  const [time, setTime] = useState(false);
  const [nowDate, setNowDate] = useState(false);
  const [buttonActive, setButtonActive] = useState(true);
  const [, setFilesUploaded] = useState(true);
  const [filesUploading, setFilesUploading] = useState(false);
  const [showEditSchedule, setShowEditSchedule] = useState(false);
  const [scheduleAction, setScheduleAction] = useState('NEW');
  const [objectId, setObjectId] = useState(null);
  const [asModal, setAsModal] = useState(false);
  //const [attachmentIndex, setAttachmentIndex] = useState(-1);
  const [typeId, setTypeId] = useState(null);
  const [typeRef, setTypeRef] = useState(null);
  const [scheduleTo, setScheduleTo] = useState(null);
  const [scheduleDataLoaded, setScheduleDataLoaded] = useState(false);
  const [scheduleLocation, setScheduleLocation] = useState('');
  const [scheduleReminder, setScheduleReminder] = useState(SCHEDULEREMINDER[0]);
  const [startDate, setStartDate] = useState(formatDateObjectWithTime());
  const [endDate, setEndDate] = useState(formatDateObjectWithTime());
  const [projectRoles, setProjectRoles] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [assignments, setAssignments] = useState([]);
  const [initialTags, setInitialTags] = useState([]);
  const [initialFiles, setInitialFiles] = useState([]);
  const [isAffiliationSearchOpen, setIsAffiliationSearchOpen] = useState(false);
  const [showToolbar, setShowToolbar] = useState(true);

  const errorStyle = { color: 'red' };
  const attachmentLimit = 20000000;

  const getProjectContacts = () => {
    const assignData = props.data?.userAssignData || [];

    if (Array.isArray(assignData) && assignData.length > 0) {
      setAssignments(assignData);

      const assignedIds = assignData.map((assign) => assign.contactAffiliationId);

      dispatch(
        findContactAffiliations({
          criteria: `${assignedIds.join(' ')}`,
          page: 1,
          count: assignedIds.length,
          isAccount: false,
          includeDeleted: true,
          fields: ['_id'],
          requiredFields: ['primaryEmail.email'],
        })
      )
        .unwrap()
        .then((data) => setContacts(data));
    }
  };

  const getCompanyContacts = () => {
    const { objectId: companyId } = props.data;

    if (companyId) {
      dispatch(getCompanyAffiliations({ companyId }))
      .unwrap()
      .then((affiliations) => {
        setAssignments(affiliations);

        const contactIds = affiliations
          .filter((aff) => aff.contactId && aff.active)
          .map((aff) => aff.contactId);

        if (contactIds.length) {
          dispatch(getContacts({ contactIds }))
            .unwrap()
            .then((contacts) => setContacts(contacts));
        }
      });
    }
  };

  useEffect(() => {
    props.getAllUserListAction({ accountId, userId, page: 0, displayLimit: 999999999 });

    dispatch(getProjectRoles()).unwrap().then((data) => setProjectRoles(data));
  }, []);

  useEffect(() => {
    switch (typeRef) {
      case 'LEAD':
      case 'PROJECT':
        getProjectContacts();
        break;
      case 'COMPANY':
        getCompanyContacts();
        break;
    }
  }, [typeRef]);

  useEffect(() => {
    if (contacts.length > 0 || typeRef === 'CONTACT') {
      let options = [];

      switch (typeRef) {
        case 'LEAD':
        case 'PROJECT':
          options = getProjectOptions(contacts, assignments, projectRoles);
          break;
        case 'COMPANY':
          options = getCompanyOptions(contacts, assignments, props.data);
          break;
        case 'CONTACT':
          options = getContactOptions(props.data);
      }

      tryAddCurrentUserOption(options);
      setContactListData(options);
    }
  }, [contacts, assignments, props.data, projectRoles]);

  const tryAddCurrentUserOption = (data) => {
    const userInfoObject = JSON.parse(localStorage.getItem('userInfo'));
    const userContactId = userInfoObject.contactId;
    let currentUserOption = data.find((c) => c.objectId === userContactId);

    if (!currentUserOption) {
      // attempt to safely read userInfo JSON and set its data as an predefined option for CC field
      // as well as existing option to select even if it was removed accidentally by user
      try {
        if (!userInfoObject.email) {
          userInfoObject.email = userInfoObject.userEmail;
        }

        userInfoObject.companyName = comName;

        const cData = formatContactDataHTMLDropdown(userInfoObject);
        currentUserOption = {
          label: renderHTML(cData),
          value: userInfoObject.email,
          objectId: userInfoObject.contactId,
        };

        data.push(createGroup('Current User', [currentUserOption]));
      } catch (e) {}
    }

    if (currentUserOption && (!scheduleTo || scheduleTo.length == 0)) {
      setScheduleTo([currentUserOption]);
    }
  };

  useEffect(() => {
    if (props.initialTags?.length > 0) {
      setInitialTags(props.initialTags);
    }
  }, [props.initialTags]);

  useEffect(() => {
    if (props.asModal) {
      setAsModal(props.asModal);
    }
  }, [props.asModal]);

  useEffect(() => {
    if (props.initialFiles?.length > 0) {
      setInitialFiles(props.initialFiles);
    }
  }, [props.initialFiles]);

  const removeFileConfirm = (attachmentData, attachmentIndex, confirm) => async () => {
    const confirmed =
      (await confirm?.({
        title: 'Remove attachment',
        question: 'Are you sure you want to remove ' + attachmentData.name + '?',
      })) ?? true;

    if (!confirmed) {
      return;
    }

    deleteFile(attachmentIndex);
  };

  useEffect(() => {
    if (props.showEditSchedule && props.showEditSchedule !== showEditSchedule) {
      setShowEditSchedule(true);
    } else if (!props.showEditSchedule && showEditSchedule) {
      setShowEditSchedule(false);
      resetFields();
    }

    const typeIdChanged = props.typeId && props.typeId !== typeId;
    const scheduleActionChanged = props.scheduleAction && props.scheduleAction !== scheduleAction;
    const objectIdChanged =
      props.objectId && props.objectId?.length > 0 ? props.objectId !== objectId : !objectId;

    if (typeIdChanged) {
      setTypeId(props.typeId);
      setTypeRef(props.typeRef);
    }

    if (scheduleActionChanged) {
      setScheduleAction(props.scheduleAction);
    }

    if (objectIdChanged) {
      setObjectId(props.objectId?.length > 0 ? props.objectId : null);
    }

    const isSaveAction = props.isSave && !isSave;
    setIsSave(isSaveAction);

    if (props.allUserList?.length > 0 && userList.length == 0) {
      let userList = [];
      for (let i in props.allUserList) {
        if (props.allUserList[i].emailCheckStatus === true) {
          const userData = props.allUserList[i];
          userList.push({
            id: userData.userId,
            value: userData.firstName + ' ' + userData.lastName + ' (' + userData.schedule + ')',
          });
        }
      }
      setUserList(userList);
    }
  }, [props]);

  useEffect(() => {
    if (props.typeId && props.typeId !== typeId) {
      setTypeId(props.typeId);
      setTypeRef(props.typeRef);
    }
  }, [props.typeId, props.typeRef]);

  useEffect(() => {
    const hasObjectId = objectId && objectId?.length > 0;
    if ((typeId || scheduleAction !== 'new' || objectId) && hasObjectId) {
      resetFields();
      getScheduleData({ objectId: objectId, typeRef: typeRef, typeId: typeId });
    } else if (scheduleAction === 'new' && !hasObjectId && typeId) {
      resetFields();
      setScheduleDataLoaded(true);
      //getScheduleData({ objectId: null, typeRef: typeRef, typeId: typeId});
    }
  }, [typeId, typeRef, scheduleAction, objectId]);

  const onFileChange = (e) => {
    let files = e.target.files;
    let field = formData;

    if (typeof field.tempUploadSchedule === 'undefined') {
      field.tempUploadSchedule = [];
    }
    for (let i = 0; i < files.length; i++) {
      field.tempUploadSchedule.push(files[i]);
    }

    setFormData({ ...formData, tempUploadSchedule: field.tempUploadSchedule });
    setFilesCount(field.tempUploadSchedule.length);
    setFilesUploaded(false);
  };

  const onToolbarToggle = () => {
    setShowToolbar(!showToolbar);
  };

  const deleteFile = (fileIndex) => {
    //e.preventDefault();
    let localFormData = { ...formData };
    let tempUploadSchedule = localFormData.tempUploadSchedule ?? [];
    let scheduleAttachments = localFormData.scheduleAttachments ?? [];

    if (typeof tempUploadSchedule[fileIndex] != 'undefined') {
      localFormData.tempUploadSchedule = _.cloneDeep(tempUploadSchedule.filter(Boolean));
      localFormData.scheduleAttachments = _.cloneDeep(scheduleAttachments.filter(Boolean));

      delete localFormData.tempUploadSchedule[fileIndex];
      delete localFormData.scheduleAttachments[fileIndex];

      localFormData.tempUploadSchedule = localFormData.tempUploadSchedule.filter(Boolean);
      localFormData.scheduleAttachments = localFormData.scheduleAttachments.filter(Boolean);
    }

    setFormData(localFormData);
    setFilesCount(localFormData.tempUploadSchedule.length);
  };

  const onDrop = (files) => {
    let field = formData;
    const fileType = 'tempUploadSchedule';

    if (typeof field[fileType] === 'undefined') {
      field[fileType] = [];
    }
    for (let i = 0; i < files.length; i++) {
      field[fileType].push(files[i]);
    }

    setFormData({ ...formData, [fileType]: field[fileType] });
    setFilesCount(field[fileType].length);
    setFilesUploaded(false);
  };

  const saveSchedule = async (e, saveType) => {
    setButtonActive(false);
    if (!scheduleTo) {
      setScheduleTo([]);
      //triggers error
    }
 

    if (!(formData.scheduleDescription?.length > 0)) {
      setUserCommentError('This field is required');
      setButtonActive(true);
    }

    if (
      !(formData.scheduleDescription?.length > 0) ||
      !scheduleTo||scheduleTo.length == 0 ||
      scheduleTitle.length == 0
    ) {
      setButtonActive(true);
      return;
    }

    let uploadSuccess = true;
    if (formData?.tempUploadSchedule?.length || 0) {
      uploadSuccess = await uploadFiles();
    }

    let messageData = {};

    if (uploadSuccess) {
      messageData['objectId'] = formData.objectId;
      messageData['accountId'] = accountId;
      messageData['userId'] = userId;
      messageData['receiverId'] = userId;
      messageData['typeId'] = typeId;
      messageData['typeRef'] = typeRef;
      //messageData['sourceName'] = this.props.state.title;

      messageData['title'] = scheduleTitle;
      messageData['scheduleTo'] = scheduleTo;
      messageData['reminder'] = scheduleReminder?.value??-999;
      messageData['location'] = scheduleLocation;
      messageData['startDate'] = formatDateUTC(startDate);
      messageData['endDate'] = formatDateUTC(endDate);
      messageData['tags'] = formData.scheduleTags;
      messageData['meetingRepeat'] = 1; /*this.state.formData.scheduleMeetingRepeat;*/
    
      messageData['description'] = formData.scheduleDescription;
      messageData['copyOf'] = formData?.copyOf ?? null;

      let parser = new DOMParser();
      const doc = parser.parseFromString(formData.scheduleDescription, 'text/html');
      const links = doc.getElementsByClassName('mention');
      messageData['mentions'] = [];
      for (let x = 0; x < links.length; x++) {
        let dataset = links[x].dataset;
        messageData['mentions'].push({
          id: dataset.id,
          denotationChar: dataset.denotationChar,
          value: dataset.value,
        });
      }

      const isDraft = saveType === 'DRAFT';

      messageData['isDraft'] = isDraft;
      messageData['isRead'] = 0;
      messageData['status'] = 'Enabled';
      messageData['trash'] = false;
      messageData['attachments'] = formData['scheduleAttachments'];

      if(objectId)
        Userpilot.track('Update Schedule Meeting');
      else{
        Userpilot.track('Add Scheduled Meeting');
      }
      await props.createScheduleAction(messageData, useAuth);

      resetFields();
      getAllSchedules();
      showHideEditSchedule();

      setButtonActive(true);
    }
  };

  const uploadFiles = async () => {
    let currentForm = formData;
    setFilesUploading(true);
    let { attachments, objectId, uploadSuccess } = await fileUploadOnS3(
      formData?.tempUploadSchedule,
      formData['objectId'],
      typeId,
      typeRef,
      attachmentLimit,
      'Schedule',
    );

    if (!uploadSuccess) {
      return false;
    }

    currentForm['scheduleAttachments'] = attachments;
    currentForm['objectId'] = objectId;

    setFormData(currentForm);

    setFilesCount(attachments.length);
    setFilesUploading(false);
    setFilesUploaded(true);

    return true;
  };

  const getAllSchedules = async () => {
    let contData = {};
    contData['accountId'] = accountId;
    contData['userId'] = userId;
    contData['typeId'] = typeId;
    contData['typeRef'] = typeRef;
    contData['page'] = 0;
    contData['displayLimit'] = 999999999;
    await props.getAllScheduleAction(contData);
  };

  const getScheduleData = async ({ objectId, typeRef, typeId }) => {
    const hasObjectId = objectId && objectId?.length > 0;
    if (!accountId || !typeId || !typeRef || !hasObjectId) return;

    setScheduleDataLoaded(false);

    const { data } = await axios.post(
      'parse/functions/getCpostSchedule',
      {
        userId: userId,
        accountId: accountId,
      },
      {
        baseURL: appConstants.WEB_SERVICE_URL_WITHOUT_PARSE,
        headers: {
          'X-Parse-Application-Id': appConstants.PARSE_APPLICATION_ID,
          'X-Parse-REST-API-Key': appConstants.PARSE_REST_API_KEY,
          'X-Parse-Session-Token': localStorage.getItem('sessionToken'),
          authorization: localStorage.getItem('JWTTOKEN'),
        },
        params: {
          accountId: accountId,
          typeId: typeId,
          typeRef: typeRef,
          objectId: objectId,
        },
      }
    );

    setScheduleDataLoaded(true);

    if (data?.result?.data?.objectId) {
      if (scheduleAction === 'COPY') {
        editCopyData(data.result.data, objectId);
      } else if (scheduleAction === 'EDIT') {
        editScheduleData(data.result.data);
      }
    }

    if (initialFiles?.length > 0) {
      onDrop(initialFiles);
    }

    if (initialTags?.length > 0) {
      setFormData({
        ...formData,
        scheduleTags: initialTags.map((tag) => ({ value: tag, label: tag })),
      });
    }
  };

  const cancelDelete = (scheduleData, confirm) => async () => {
    const confirmed =
      (await confirm?.({
        title: 'Cancel event',
        question: 'Are you sure you want to cancel this event?',
      })) ?? true;

    if (!confirmed) {
      return;
    }

    let formData = {};
    formData['objectId'] = scheduleData.objectId;
    formData['typeRef'] = scheduleData.typeRef;
    formData['typeId'] = scheduleData.typeId;

    await props.deleteScheduleAction(formData);

    resetFields();
  };

  const editCopyData = (scheduleData, scheduleObjectId) => {
    let editFormData = { ...formData };
    editFormData.objectId = null;
    setScheduleTitle(scheduleData.title ?? '');
    //setScheduleTo(scheduleData.scheduleTo);
    editFormData.scheduleDescription = scheduleData.description;
    setScheduleLocation(scheduleData.location ?? '');
    setScheduleReminder(SCHEDULEREMINDER.find((r) => r.value === scheduleData.reminder)??SCHEDULEREMINDER[0]);
    editFormData.scheduleAttachments = scheduleData.attachments??[];
    editFormData.tempUploadSchedule = scheduleData.attachments?.map((attachment) => ({
      name: attachment.fileName,
      type: attachment.fileType,
      lastModifiedDate: attachment.fileModified,
      size: attachment.fileSize,
      fileKey: attachment.fileKey,
      alreadyUploaded: true,
      copiedFromOtherSchedule: true,
    }))??[];
    editFormData.copyOf = scheduleObjectId;

    setFormData(editFormData);
    handleChangeScheduleSelection(scheduleData.scheduleTo);
     };

  const editScheduleData = (scheduleData, isCopy = false) => {
    let editFormData = { ...formData };
    editFormData.objectId = scheduleData.objectId;
    setScheduleTitle(scheduleData.title ?? '');
    setScheduleLocation(scheduleData.location ?? '');
    setScheduleReminder(SCHEDULEREMINDER.find((r) => r.value === scheduleData.reminder)??SCHEDULEREMINDER[0]);
    //setScheduleTo(scheduleData.scheduleTo);
    editFormData.scheduleDescription = scheduleData.description;
    editFormData.scheduleAttachments = scheduleData.attachments??[];
    editFormData.tempUploadSchedule = scheduleData?.attachments?.map((attachment) => ({
      name: attachment.fileName,
      type: attachment.fileType,
      lastModifiedDate: attachment.fileModified,
      size: attachment.fileSize,
      fileKey: attachment.fileKey,
      alreadyUploaded: true,
    }))??[];

    setFormData(editFormData);
    handleChangeScheduleSelection(scheduleData.scheduleTo);
   };

  const resetFields = () => {
    /*Reset form data */
    let newFormData = { ...formData };
    Object.keys(newFormData).map((key, index) => {
      if (typeof newFormData[key] === 'object') newFormData[key] = [];
      else newFormData[key] = '';
      return true;
    });

    setScheduleTo([]);
    setScheduleTitle('');
    newFormData.scheduleDescription = null;
    newFormData.copyOf = null;

    setFormData(newFormData);
    tryAddCurrentUserOption(contactListData);
    setUserCommentError('');
  };

  const handleChangeScheduleBody = (name, value) => {
    if (!isSave) {
      let userCommentError = '';
      if (value && value === '<p><br></p>') {
        userCommentError = 'This field is required.';
        value = '';
      }
      let newFormData = { ...formData };
      newFormData[name] = value;
      setFormData(newFormData);
      setUserCommentError(userCommentError);
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    if(e?.target?.name==='scheduleLocation'){
        setScheduleLocation(e.target.value);
    }
    else if (!isSave) {
      let newFormData = { ...formData };
      newFormData[e.target.name] = e.target.value;
      setFormData(newFormData);
    }
  };

  const showHideEditSchedule = () => {
    setShowEditSchedule(false);
    setObjectId(null);
    resetFields();
    props.closeWindowCallback();
  };

  let filesListSchedule = '';
  if (formData.tempUploadSchedule !== undefined && formData.tempUploadSchedule.length > 0) {
    filesListSchedule = formData.tempUploadSchedule.map((v, index) => (
      <Confirm
        render={(confirm) => (
          <div className='filesList'>
            <label className={v.alreadyUploaded ? '' : 'time-left'}>{v.name}</label>
            <span onClick={removeFileConfirm(v, index, confirm)}>
              <i className='fa fa-remove'></i>
            </span>
          </div>
        )}
      />
    ));
  }

  const scheduleSaveBtn = [
    {
      title: 'Send event',
      loading: 'Loading...',
      loadingActive: !buttonActive,
      onClick: (e) => saveSchedule(e, 'SEND'),
      'data-type': 'SAVE',
      className: 'btn btn-primary custom-button',
    },
  ];


  const existingScheduleSaveBtn = [
    {
      title: 'Cancel event',
      loading: 'Loading...',
      loadingActive: buttonActive,
      onClickFun: cancelDelete(),
      'data-type': 'SAVE',
      className: 'btn custom-button',
    },
    {
      title: 'Update',
      loading: 'Loading...',
      loadingActive: buttonActive,
      onClick: (e) => saveSchedule(e, 'SEND'),
      'data-type': 'SAVE',
      className: 'btn btn-primary custom-button',
    },
  ];

  const validateSchedule = (schedule) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(schedule).toLowerCase());
  };

  const handleDatePickerWrapper = (name, date) => {

    if (name === 'startDate') {
      if (date === null) {
        setEndDate(formatDateObjectWithTime());
      } else {
        //field["endDate"]  = date;
        setEndDate(moment(date).add(1, 'hours'));
      }
    }

    if (date === null) {
        date = formatDateObjectWithTime();
      }
    
    const dateA = formatDateObjectWithTime();
    const dateB = formatDateObjectWithTime(startDate);
    const dateC = formatDateObjectWithTime(date);
    if (dateA.diff(dateC) >= 0) {
      setNowDate(true);
      setStartDate(formatDateObjectWithTime());
      setEndDate(formatDateObjectWithTime());
    } else {
        setNowDate(false);
    }
    setTime( moment.duration(dateB.diff(dateC)).asDays() > -1);
    if(name==='startDate'){
        setStartDate(date);
    }else{
        setEndDate(date);
    }
  };

  const handlePlaceSelect = (place) => {
    setScheduleLocation(place[0].formatted_address);
  };

  const handleChangeScheduleSelection = (value) => {
    const newValues = value
      .filter((data) => validateSchedule(data.value))
      .map((data) => ({
        label: data.objectId ? renderHTML([data.label[0], data.value].join('<br/>')) : data.label,
        value: data.value,
        objectId: data.objectId ? data.objectId : '',
      }));

    setScheduleTo(newValues);
  };

  const handleChangeTitle = (e) => {
    setScheduleTitle(e.target.value);
  };

  const onGroupHeadingClick = (props) => {
    const options = props?.data?.options || [];
    const newValues = [...(scheduleTo || []), ...options];
    handleChangeScheduleSelection(newValues);
  };

  const onAfterSubmit = (options) => {
    const optionsWithEmail = options
      .filter((option) => option.primaryEmail)
      .map((option) => {
        const email = option.primaryEmail.email;
        const updatedOption = { ...option, email };

        return {
          label: renderHTML(formatContactDataHTMLDropdown(updatedOption)),
          value: email,
          objectId: option._id,
        };
    });

    const newValues = [...(scheduleTo || []), ...optionsWithEmail];
    handleChangeScheduleSelection(newValues);
  };

  const MenuListCustom = (props) => (
    <MenuList {...props} onClick={() => setIsAffiliationSearchOpen(true)} />
  );

  const GroupHeadingCustom = (props) => (
    <GroupHeading {...props} onClick={() => onGroupHeadingClick(props)} />
  );

  const scheduleBody = () => {
    return !scheduleDataLoaded ? (
      <ReactLoading
        className='table-loader'
        type={appConstants.LOADER_TYPE}
        color={appConstants.LOADER_COLOR}
        height={appConstants.LOADER_HEIGHT}
        width={appConstants.LOADER_WIDTH}
      />
    ) : (
      <div>
        {/*<FileDropZone
          onFileDrop={(e) => {
            onDrop(e);
          }}
        >*/}
            <div className='row'>
            <div className='col-md-12'>
              <div className='form-group'>
                <label htmlFor='endDT'>Title</label>
                <input
                  type='text'
                  className='form-control'
                  name='scheduleTitle'
                  id='scheduleTitle'
                  value={scheduleTitle}
                  onChange={(e) => {
                    handleChangeTitle(e);
                  }}
                />
                <span style={errorStyle}>
                  {scheduleTitle?.length == 0 && scheduleValidation.errors.first('scheduleTitle')}
                </span>
              </div>
            </div>
          </div>

          <div className='row'>
            <div className='col-md-12'>
              <div className='form-group'>
                <label htmlFor='startDT'> To </label>
                <Creatable
                  name='scheduleTo'
                  formatCreateLabel={(userInput) => `Schedule '${userInput}'`}
                  value={scheduleTo}
                  onChange={handleChangeScheduleSelection}
                  options={contactListData}
                  isMulti
                  components={{ GroupHeading: GroupHeadingCustom, MenuList: MenuListCustom }}
                  formatGroupLabel={formatGroupLabel}
                  createOptionPosition={'first'}
                />
                <span style={errorStyle}>
                  {scheduleTo?.length == 0 && scheduleValidation.errors.first('scheduleTo')}
                </span>
              </div>
            </div>
          </div>
          
          <div className='row'>
              <div className='col-md-6'>
                <div className='form-group'>
                  <label htmlFor='isDraft'>Starts</label>
                  <DatePickerWrapper
                    className='form-control react-input-start'
                    name='startDate'
                    selected={startDate}
                    onChange={handleDatePickerWrapper.bind(this, 'startDate')}
                    timeFormat='h:mm a'
                    timeIntervals={15}
                    dateFormat={appConstants.DATEFORMATPICKERTIME}
                    required
                    selectsStart
                    todayButton='Today'
                    showMonthDropdown
                    startDate={startDate}
                    endDate={endDate}
                    minDate={formatDateObject()}
                    showTimeSelect
                  />
                  <span className='error' style={errorStyle}>
                    {startDate && scheduleValidation.errors.first('startDate')}
                  </span>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='form-group react-input-end'>
                  <label htmlFor='isDraft'>Ends</label>
                  <DatePickerWrapper
                    className='form-control'
                    name='endDate'
                    selected={endDate}
                    onChange={handleDatePickerWrapper.bind(this, 'endDate')}
                    timeFormat='h:mm a'
                    timeIntervals={15}
                    //dateFormat={appConstants.DATEFORMATWITHTIME}
                    dateFormat={appConstants.DATEFORMATPICKERTIME}
                    required
                    selectsEnd
                    todayButton='Today'
                    showMonthDropdown
                    startDate={formatDateObjectWithTime(startDate)}
                    endDate={formatDateObjectWithTime(endDate)}
                    showTimeSelect
                    minDate={formatDateObjectWithTime(startDate)}
                    maxDate={formatDateAdd().add(5, 'years')}
                    minTime={
                      time
                        ? formatDateObjectWithTime(startDate)
                        : formatDateObjectWithTime(endDate).startOf('day')
                    }
                    maxTime={formatDateObjectWithTime(endDate).endOf(
                      'day'
                      
                    )}
                  />
                  <span className='error' style={errorStyle}>
                    {endDate && scheduleValidation.errors.first('endDate')}
                  </span>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-6'>
                <div className='form-group'>
                  <label htmlFor='endDT'> Reminder </label>
                  <Select
                    name='scheduleReminder'
                    components={makeAnimated()}
                    value={scheduleReminder}
                    onChange={setScheduleReminder}
                    options={SCHEDULEREMINDER}
                  />
                  <span className='error' style={errorStyle}>
                    {scheduleReminder &&
                      scheduleValidation.errors.first('scheduleReminder')}
                  </span>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='form-group'>
                  <label htmlFor='endDT'>Location</label>
                  <SearchPlaceAutocomplete
                    name='scheduleLocation'
                    value={scheduleLocation}
                    onChange={handleChange}
                    onSelect={handlePlaceSelect}
                  />
                  <span className='error'>
                    {scheduleLocation &&
                      scheduleValidation.errors.first('scheduleLocation')}
                  </span>
                </div>
              </div>
              </div>

          {userList.length > 0 && (
            <TextEditorField
              fieldData={{
                onChange: handleChangeScheduleBody.bind(this, 'scheduleDescription'),
                value: formData.scheduleDescription,
                name: 'scheduleDescription',
                errorMsg: formData.scheduleDescription?.length > 0 ? '' : userCommentError,
                mentionList: userList,
              }}
              toolbarEnabled={showToolbar}
            />
          )}

          <div className='row'>
            <div className='col-md-12'>
              <div className='form-group'>
                <div className='filesBox'>
                  {filesListSchedule.length > 0 && (
                    <div>
                      <div className=' row'>
                        <label className=''>Attachments</label>
                      </div>
                      <div className='ms-2 filesList row'>{filesListSchedule}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          {false&&(<div className='row'>
            <div className='col-md-6 mt-4'>
              <div id='upload_button'>
                <label>
                  <input
                    type='file'
                    name='userImage'
                    multiple
                    className='field full'
                    label='Upload'
                    accept=''
                    title='Attach Files'
                    onChange={(e) => {
                      onFileChange(e);
                    }}
                  />
                  <span>
                    Add attachment <i className='fa fa-paperclip' aria-hidden='true'></i>
                  </span>
                </label>
              </div>
              {false && (
                <div id='show_hide_toolbar_button'>
                  <label className='upload-button'>
                    <div
                      name='toggle'
                      label='toggleToolbar'
                      onClick={() => {
                        onToolbarToggle();
                      }}
                      title={showToolbar ? 'Hide formatting' : 'Show formatting'}
                    >
                      <i
                        className={showToolbar ? 'fa fa-font' : 'fa fa-paragraph'}
                        aria-hidden='true'
                      ></i>
                    </div>
                  </label>
                </div>
              )}
            </div>
          </div>)}
        {/*</FileDropZone>*/}
      </div>
    );
  };

  const scheduleHeaderText = () => {
    if (scheduleAction === 'EDIT') {
      return 'Edit event';
    }

    return 'Add event';
  };

  const scheduleFooter = () => {
    return (
      <div className='row mb-2 mt-3'>
        <div className='d-flex addProjectPopupBottomBtnBox'>
          <button className='btn btn-primary custom-button' onClick={showHideEditSchedule.bind(this)}>
            Close
          </button>
          <div className='ms-auto'>
            {scheduleAction === 'EDIT' ? <InputFieldSubmit actions={existingScheduleSaveBtn} disabled={filesUploading} />:<InputFieldSubmit actions={scheduleSaveBtn} disabled={filesUploading} />}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className='no-print'>
      {showEditSchedule ? (
        !asModal ? (
          <div>
            <div className='row'>
              <div className='col-md-12'>
                <div className='pageinfo'>
                  <h2>{scheduleHeaderText()}</h2>
                </div>
              </div>
            </div>
            {scheduleBody()}
            {scheduleFooter()}
          </div>
        ) : (
          <Modal isOpen={showEditSchedule} onHide={showHideEditSchedule} size='lg'>
            <ModalHeader closeButton>{scheduleHeaderText()}</ModalHeader>
            <ModalBody>
              <div className='container'>{scheduleBody()}</div>
            </ModalBody>
            <ModalFooter>{scheduleFooter()}</ModalFooter>
          </Modal>
        )
      ) : (
        ''
      )}

      <AffiliationLookupModal
        isOpen={isAffiliationSearchOpen}
        toggle={() => setIsAffiliationSearchOpen(false)}
        onAfterSubmit={onAfterSubmit}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  const { message } = state;

  return {
    isSave: message.isSaveM,
    buttonActive: message.buttonActive,
    allUserList: state.user.allUserList,
  };
};
export default withRouter(
  connect(mapStateToProps, {
    getAllScheduleAction,
    cancelScheduleAction,
    createScheduleAction,
    getAllUserListAction,
  })(ScheduleEditor)
);
