import React, { useCallback, useContext, useEffect, useState } from 'react';

export const TitleContext = React.createContext({ title: 'CommandPost App', setTitle: () => {} });

export const TitleProvider = ({ children }) => {
  const [titleValue, setTitleValue] = useState('CommandPost App');

  const setTitle = useCallback((newTitle) => {
    setTitleValue(newTitle);
  }, []);

  return (
    <TitleContext.Provider value={{ title: titleValue, setTitle }}>
      {children}
    </TitleContext.Provider>
  );
};

export const usePageTitle = (title) => {
  const context = useContext(TitleContext);

  if (!context) throw new Error('Context must be used inside provider');

  useEffect(() => {
    if (title) context.setTitle(title);
  }, [title]);
};
