import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { loginAction } from '../../../actions/Admin/user.actions.js';
import { appConstants } from '../../../_constants';
import TitleComponent from  "../../Common/TitleComponent";

import { useAuthAdmin } from '../../../components/Admin/Auth/Auth';
import { callAlertMessage } from '../../../components/Auth/Auth';

// eslint-disable-next-line no-undef
require('./login.css');

// eslint-disable-next-line no-undef
let Validator = require('validatorjs');
let styleData = { color: 'red' };
let formArr = {};
let rules = {
  username: 'required|email',
  password: 'required',
};
let mess = {
  required: 'This field is required',
  alpha: 'This field may only contain letters',
  numeric: 'This field must be a number',
  email: 'This field must be a valid email address.',
};
let validation = [];
validation = new Validator([], rules, appConstants.RULESMESSAGE);

validation.passes();
validation.fails();

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    const { logout } = useAuthAdmin();
          logout();
    this.state = {
      username: '',
      password: '',
      formArr: [],
      formData: {},
      submitted: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { auth, history, loginUser } = nextProps;
    if (nextProps.success === 1 && this.state.submitted) {
      this.setState({ submitted: false });

      callAlertMessage('success', nextProps.message);
      history.push({pathname:`/admin/home`,state: { login_info: loginUser }});
      
    }

    if (nextProps.success === 0 && this.state.submitted) {
      this.setState({ submitted: false });
      callAlertMessage('error', nextProps.message);
    }
  }

  handleChange(e) {
    e.preventDefault();
    let field = this.state.formData;
    field[e.target.name] = e.target.value;
    this.setState({ formData: field });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({ submitted: true });
    const { formData } = this.state;

    if (this.allValidate(false)) {
      this.props.loginAction(formData);
    }

    this.setState({ submitted: true });

    // if (username && password) {
    //     this.props.loginAction({username:username, password:password});
    // }
  }

  allValidate(check) {
    if (!check) {
      formArr = [];
      Object.keys(rules).forEach(function (key) {
        formArr[key] = 'TT';
      });
      this.setState({
        formArr,
      });
    }
    if (validation.passes()) {
      return 1;
    }
  }

  render() {
    const { formData, username, password, formArr } = this.state;

    validation = new Validator(this.state.formData, rules, mess);
    validation.passes();
    validation.fails();

    return (
      <div className="container">
        <TitleComponent data={{title:`Site Administration`}}/>
        <h1 className="form-heading">login Form</h1>
        <div className="login-form">
          <div className="main-div">
            <div className="panel">
              <h2>Sign In</h2>
            </div>
            <div className="form-group">
              <input
                type="email"
                className="form-control"
                name="username"
                placeholder="Email"
                value={formData.username}
                onChange={(e) => this.handleChange(e)}
              />
              <span style={styleData} className="error">
                {formArr.username && validation.errors.first('username')}
              </span>
            </div>
            <div className="form-group">
              <input
                type="password"
                className="form-control"
                name="password"
                placeholder="Password"
                value={formData.password}
                onChange={(e) => this.handleChange(e)}
              />
              <span style={styleData} className="error">
                {formArr.password && validation.errors.first('password')}
              </span>
            </div>
            <div className="forgot">
              <Link
                className=""
                to={{
                  pathname: `/admin/forgot-password`,
                  state: { ...this.state },
                }}
              >
                Forgot password?
              </Link>
            </div>
            <button
              type="submit"
              className="btn btn-primary"
              onClick={(e) => {
                this.handleSubmit(e);
              }}
            >
              Login
            </button>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isLogin: state.user.isLogin,
    isLoginSuccess: state.user.isLoginSuccess,
    isLoginError: state.user.isLoginError,

    loginUser: state.user.userData,

    success: state.user.success,
    message: state.user.message,
  };
}

export default withRouter(connect(mapStateToProps, { loginAction })(LoginPage));
