import { IMask } from 'react-imask';

import useCountryCodes from '../shared/hooks/use-country-codes';

export const strictPhoneArray = (phoneArr) =>
  phoneArr?.map((p) => ({
    ...p,
    phone: p.phone?.replace(/\D+/g, '') || '',
    ext: p.ext?.toString().replace(/\D+/g, '') || '1',
  }));

export const formatPhoneForCall = (phoneObject = {}) => {
  let { ext, phone, extPhone } = phoneObject;
  const clearRegEx = /[^0-9]/g;

  if (phone) {
    ext = (ext || '1').toString().replace(clearRegEx, '');
    phone = (phone || '').toString().replace(clearRegEx, '');
    extPhone = (extPhone || '').toString().replace(clearRegEx, '');
    const extPostfix = extPhone ? `,,${extPhone}` : '';
    return `+${ext}${phone}${extPostfix}`;
  }

  return null;
};

export const formatPhoneNoHook = (phoneObject, isAddExt = false) => {
  const countriesHook = useCountryCodes();
  return formatPhone(phoneObject, countriesHook, isAddExt);
};

export const formatPhone = (phoneObject, countries, isAddExt = false) => {
  if (!phoneObject) {
    return '-';
  }


  if (!countries || countries?.length == 0) {
    countries = [
      {
        name: 'United States',
        code: '1',
        iso: 'US',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/us.svg',
        mask: '(000) 000-0000',
        fullMask: '0 (000) 000-0000',
      },
    ];
  }

  const { ext, code, phone, extPhone } = phoneObject;

  if (!phone && !extPhone) {
    return '-';
  }

  /*if ((!ext && !code) || !phone) {
    return `(+1) ${phone || '--- --- ----'}`;
  }*/

  const formattedCode = (ext || code)?.toString().replace('+', '') ?? '1';
  const filteredCountries = countries.filter((country) => {
    return country.code === formattedCode;
  });

  if (!filteredCountries?.length) {
    return `(+1) ${phone || '--- --- ----'}`;
  }

  let mask;

  if (filteredCountries?.length > 0 && formattedCode === '1') {
    mask = filteredCountries.find((country) => country.iso === 'US').fullMask;
  } else {
    mask = filteredCountries[0].fullMask;
  }
  const formattedPhone = phone?.toString().replace('-', '');

  const masked = IMask.createMask({ mask });
  const fullPhone = formattedCode.concat(formattedPhone);
  masked.resolve(fullPhone);

  const maskedValue = masked.value;

  const extPostfix = isAddExt && extPhone ? `, ext. ${extPhone}` : '';

  return `+${maskedValue}${extPostfix}`;
};

export const affiliationLinkFormatter = ({
  contactId,
  companyId,
  contactFirstName,
  contactLastName,
  companyName,
  isAccountAffiliation,
  isInternal,
  imageUrl,
}) => {
  const contactName = `${contactFirstName || ''} ${contactLastName || ''}`.trim() || 'N/A';

  const companyLink = companyId ? (
    <a href={`/company-details/${companyId}`} target='_blank' rel='noreferrer'>
      {companyName}
    </a>
  ) : null;
  const contactLink = contactId ? (
    <a href={`/contact-details/${contactId}`} target='_blank' rel='noreferrer'>
      {contactName}
    </a>
  ) : null;

  const imageIcon = imageUrl ? (
    <> <img src={imageUrl} alt='Contact Avatar' style={{ width: '20px', height: '20px' }} className={'rounded-circle'} />&nbsp;</>
  ) : null;

  if (companyLink && contactLink && !isAccountAffiliation && !isInternal) {
    return (
      <div>
        {imageIcon}
        {companyLink}
        <span>&nbsp;-&nbsp;</span>
        {contactLink}
      </div>
    );
  }

  if (contactLink) {
    return <div>{imageIcon}{contactLink}</div>;
  }

  if (companyLink) {
    return <div>{imageIcon}{companyLink}</div>;
  }

  return '';
};
