import { useEffect, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';;
import {
  addEditVariableMasterAction,
  addVariableData,
  getVariableMasterAction,
  updateVariableDataByIndex,
} from '../../../actions/accountSettings';
import { callAlertMessage } from '../../../components/Auth/Auth';
import { selectVariableAddEdit, selectVariableData } from '../../../selectors/accountSettings';
import { yearPickerValue } from '../../../utils/date-formatters';
import styles from './style.module.scss';

export const OverheadCostsPage = () => {
  const [searchFor, setSearchFor] = useState('');
  const [editableData, setEditableData] = useState({});

  const dispatch = useDispatch();

  const { data: variableHistory = [] } = useSelector(selectVariableData);
  const variableAddEdit = useSelector(selectVariableAddEdit);

  useEffect(() => {
      dispatch(
        getVariableMasterAction({
          accountId: localStorage.getItem('accountId'),
          userId: localStorage.getItem('userId'),
        })
      );
    
  }, [dispatch]);

  useEffect(() => {
    if (!variableAddEdit.success) {
      return;
    }

    (async () => {
      variableAddEdit.success = 0;

      await dispatch(
        getVariableMasterAction({
          accountId: localStorage.getItem('accountId'),
          userId: localStorage.getItem('userId'),
          search: searchFor?.value,
        })
      );

      callAlertMessage('success', variableAddEdit.msg);
    })();
  }, [dispatch, variableAddEdit]);

  const searchVarHistory = (value) => {
    if(value){
        setSearchFor(value);
    }else{
        setSearchFor('');
    }
    

    dispatch(
      getVariableMasterAction({
        accountId: localStorage.getItem('accountId'),
        userId: localStorage.getItem('userId'),
        search: value?.value,
      })
    );
  };

  const handleChangeVar = (x) => (e) => {
    e.persist();

    if (e.target.value.toString().length < 14) {
      dispatch(updateVariableDataByIndex({ [e.target.name]: e.target.value }, x));
    }
  };

  const handleChangeAddVar = (x, name) => (value) => {
    dispatch(updateVariableDataByIndex({ [name]: value }, x));
  };

  const editUpdateVar = (val, x) => () => {
    const valueClone = { ...val };
    setEditableData({ ...editableData, [x]: valueClone });
    dispatch(updateVariableDataByIndex({ editTrue: true }, x));
  };

  const handleValidation = (type, row) => {
    if (type === 'row') {
      dispatch(
        updateVariableDataByIndex(
          {
            monthClass: variableHistory[row].monthNum,
            yearClass: variableHistory[row].year,
          },
          row
        )
      );

     
    }
    return true;
  };

  const saveUpdateVar = (data, x) => () => {
    if (data.monthData && data.yearData) {
      data.monthlyOverheadVariable =
        data.monthlyOverheadVariable === '' ? 0 : parseInt(data.monthlyOverheadVariable, 10);
      data.monthlyOverheadFixed =
        data.monthlyOverheadFixed === '' ? 0 : parseInt(data.monthlyOverheadFixed, 10);
      data.year = data.yearData.value;
      data.monthNum = data.monthData.value;
      data.accountId = localStorage.getItem('accountId');
      data.userId = localStorage.getItem('userId');
    }

    if (handleValidation('row', x)) {
      dispatch(addEditVariableMasterAction(data));
    }
  };

  const cancelUpdateVar = (data, x) => () => {
    if(data.objectId)
    {
        dispatch(updateVariableDataByIndex({
          editTrue: false,
          monthlyOverheadVariable: editableData[x].monthlyOverheadVariable.toString(),
          monthlyOverheadFixed: editableData[x].monthlyOverheadFixed.toString(),
        }, x));
    }else{
      dispatch(
        getVariableMasterAction({
          accountId: localStorage.getItem('accountId'),
          userId: localStorage.getItem('userId'),
        })
      );
    }
  }

  

  const variableAddMore = () => {
    const dateNow = new Date();
    const curMonth = dateNow.getMonth();
    const curMonthName = dateNow.toLocaleString('default', { month: 'short' });
    const curYear = dateNow.getFullYear();
    dispatch(
      addVariableData({
        editTrue: true,
        monthNum: curMonth,
        year: curYear,
        yearData:{label:String(curYear),value:curYear},
        monthData: {label:curMonthName,value:curMonth},
        monthlyOverheadVariable: '',
        monthlyOverheadFixed: '',
        monthClass: 'true',
        yearClass: 'true',
        monthlyExVarClass: 'true',
        monthlyExFixedClass: 'true',
      })
    );
  };

  const optionYears = yearPickerValue([]);

  const monthNameArr = [...Array(12).keys()].map((key,idx) => ({label:new Date(0, key).toLocaleString('en', { month: 'short' }),value:idx}))

  const data = variableHistory.map((val, idx) => {
    let attr = true;
    let showEdit = true;
    let selectDisabled = true;

    if (val.editTrue) {
      showEdit = false;
    }
    if (!val.objectId) {
      showEdit = false;
      selectDisabled = false;
    }
    if (!val.objectId || val.editTrue) {
      attr = false;
    }

    const yearData = {label:val.yearData?.label??val.year,value:val.yearData?.value??val.year}
    const monthData = {label:val.monthData?.label??monthNameArr[val.monthNum].label,value:val.monthData?.value??val.monthNum}

    return (
      <tr key={val.objectId ?? idx} className={styles.tableRow}>
        <td width='20%'>
          <Select
            name='year'
            className={val.yearClass || yearData ? '' : ' error'}
            styles={{
              menuList: (base) => ({ ...base, maxHeight: 128 }),
            }}
            components={makeAnimated()}
            value={yearData}
            onChange={handleChangeAddVar(idx, 'yearData')}
            options={optionYears}
            isDisabled={selectDisabled}
          />
        </td>
        <td width='20%'>
          <Select
            name='month'
            className={val.monthClass || monthData ? '' : ' error'}
            styles={{
              menuList: (base) => ({ ...base, maxHeight: 128 }),
            }}
            components={makeAnimated()}
            value={monthData}
            onChange={handleChangeAddVar(idx, 'monthData')}
            options={monthNameArr}
            isDisabled={selectDisabled}
          />
        </td>
        <td width='25%' className='woVarFixed'>
        <input
            type='number'
            placeholder='Enter %'
            className='form-control text-end'
            id='monthlyOverheadVariable'
            name='monthlyOverheadVariable'
            value={val.monthlyOverheadVariable}
            onChange={handleChangeVar(idx)}
            maxlength='4'
            disabled={attr}
          />
        </td>
        <td width='25%' className='woVarFixed'>
        <input
            type='number'
            placeholder='Enter $'
            className='form-control text-end'
            id='monthlyOverheadFixed'
            name='monthlyOverheadFixed'
            value={val.monthlyOverheadFixed}
            onChange={handleChangeVar(idx)}
            maxlength='12'
            disabled={attr}
          />
        </td>
        <td width='10%'>
          {!attr && <div><button onClick={saveUpdateVar(val, idx)}>✓</button> <button onClick={cancelUpdateVar(val, idx)}>X</button></div>}

          {showEdit && (
            <div>
              <button onClick={editUpdateVar(val, idx)}>
                <i className='fa fa-pencil' aria-hidden='true'></i>
              </button>
            </div>
          )}
        </td>
      </tr>
    );
  });

  return (
      
    <div className='position-relative h-100'>

    
      <div className='flex row mt-2'>
        <h2 className='heading2 col-8'>Estimated Fixed & Variable Overhead </h2>
      </div>
            <div className='col-lg-8 col-md-10'>
          <div className='row'>
          <div className='ms-auto col-md-3 mb-1'>
                Filter: 
                <Select
                  name='year'
                  components={makeAnimated()}
                  value={searchFor}
                  onChange={searchVarHistory}
                  options={optionYears}
                  placeholder='Filter By Year'
                  isClearable 
                />
              </div>
              </div>
              <div className='row'>
            <div className=''>
             

              <div className='ms-3 csTableHead col-12'>
                <table width='100%'>
                  <tr>
                    <th width='20%'>Year</th>
                    <th width='20%'>Month</th>
                    <th width='25%'>Est. Variable Overhead (%)</th>
                    <th width='25%'>Fixed Monthly Overhead ($)</th>
                    <th width='10%'>Action</th>
                  </tr>
                </table>
              </div>

              <div className='ms-3 csTableBody col-12'>
                <table width='100%'>
                  <tbody>
                    {data.length === 0 && <h6 className='text-center'>No data found...</h6>}

                    <Scrollbars style={{ minHeight: 350 }}>{data}</Scrollbars>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
       
       <div className='col-sm-3 ms-auto mt-2'>
        <button className='btn btn-primary' onClick={variableAddMore}>
          Add More
        </button>
        </div>
        </div>
        </div>
  );
};

export default OverheadCostsPage;
