import React, {Suspense} from 'react';
import { Link, withRouter } from 'react-router-dom';
import Header from '../../Layout/Header.js';
import Footer from '../../Layout/Footer.js';
import { Input, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { connect } from 'react-redux';
import { appConstants } from '../../../_constants';
import { GetProjectPrelimInfoAction } from '../../../actions/reports';
import ReactToPrint from 'react-to-print';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import loaderImage from '../../static/images/loading_i.gif';
import bannerImage from '../../static/images/Cobilogo.png';
import renderHTML from 'html-react-parser';
import '../../../portrait.css';
import TitleComponent from  "../../Common/TitleComponent";
import { formatDateWithTime,formatDateObject,formatDate } from '../../../utils/date-formatters';

class ProjectPrelimInfoReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accountId: localStorage.getItem('accountId'),
      userId: localStorage.getItem('userId'),
      list: [],
      objectId: '',
      modal: false,
      errorUploading: true,
      successUploading: true,
      fetchRequest: false,
      comLogo: '',
      isReport: true,
      isReportError: true,
      isReportDD: true,
      isReportDDError: true,
      showProcessing: false,
      projectData: {},
      period: '',
      jobNumFormatted: null,
      jobName: null,
      venderList: {},      
      isLoading:true
    };
    this.toggle = this.toggle.bind(this);
    this.internalContact = this.internalContact.bind(this);
    this.externalContacts = this.externalContacts.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.listData !== this.props.listData) {
      this.setState({ fetchRequest: true });
    }


    if (nextProps.listData && nextProps.listData.data) {
      this.setState({
        projectData: nextProps.listData.data,
        venderList: nextProps.listData.venderData,
      });
    }

    if (this.state.showProcessing === true) {
      this.setState({ showProcessing: false });
    }
  }

  componentDidMount() {
    
    let data = {};
    data['accountId'] = this.state.accountId;
    this.setState({ period: 'jobNumFormatted' });

    if (
      this.props &&
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.projectData &&
      this.props.location.state.projectData.objectId
    ) {
      this.setState({ projectData: this.props.location.state.projectData });
      
      data['projectId'] = this.props.location.state.projectData.objectId;
      this.props.GetProjectPrelimInfoAction(data);
    }

    if (this.props?.location?.state?.objectId) {
      this.setState({ objectId: this.props?.location?.state?.projectData });
    }

    if (
      localStorage.getItem('companyLogo') !== 'undefined' &&
      localStorage.getItem('companyLogo') !== null
    ) {
      this.setState({ comLogo: localStorage.getItem('companyLogo') });
    } else {
      let constImage = appConstants.CONTENT_CPOST_LOGO_URL;
      this.setState({ comLogo: constImage });
    }
  }

  UNSAFE_componentWillMount() {
    //this.toggle();
  }

  toggle(val) {
    this.setState({ modal: !this.state.modal });
  }

  filteredData(e) {
    e.preventDefault();

    let data = {};
    data['accountId'] = this.state.accountId;
    data['moduleName'] = appConstants.reportModuleName['16'];

    if (this.state.period === 'jobNumFormatted') {
      data['projectId'] = this.state.jobNumObj.value;
    } else if (this.state.period === 'jobName') {
      data['projectId'] = this.state.jobNameObj.value;
    }

    this.toggle();
    this.setState({ showProcessing: true });
    this.props.GetProjectPrelimInfoAction(data);
  }

  handleChangePeriod(val) {
    this.setState({ period: val });
  }

  handleChangeJobNum(name, value) {
    let jobNumObj = this.state.jobNumObj;
    jobNumObj = value;
    this.setState({ jobNumObj });
  }

  handleChangeJobName(name, value) {
    let jobNameObj = this.state.jobNameObj;
    jobNameObj = value;
    this.setState({ jobNameObj });
  }

  createCustomButtonGroup = (props) => {
    return (
      <div className="pdfLogoBox">
        <div className="reportHead">
          <div className="logo">
            <img src={this.state.comLogo} alt="Upload Logo" />
          </div>
          <h2>Preliminary Info Report</h2>
        </div>
      </div>
    );
  };

  internalContact(data) {
    let externalArr = [];
    let contactName = '';
    let finalData = '';

    data.map((v) => {
      if (contactName !== '') {
        contactName = contactName + ', <br/>';
      }
      contactName = v && v.firstName && v.lastName ? v.firstName + ' ' + v.lastName : '-';
      let phnC = '';
      if (v && v.phoneArr && v.phoneArr.length > 0) {
        v.phoneArr.map((item) => {
          if (phnC !== '') {
            phnC = phnC + '<br/>';
          }
          let phon;
          if (item.phone) {
            phon =
              phnC +
              item.type +
              ': (' +
              item.ext +
              ')' +
              ' <a href="tel:' +
              item.ext +
              item.phone +
              '">' +
              item.phone +
              '</a>';
          } else {
            phon =
              phnC + item.type + ':' + ' <a href="tel:' + item.phone + '">' + item.phone + '</a>';
          }
          phnC = phon;
        });
      }
      externalArr.push(
        <div>
          <span>
            <strong>
              {' '}
              <Link to={{ pathname: `/contact-details/` + v.objectId }}>{contactName}</Link>
            </strong>
          </span>
          <br />
          <span className="phoneNumberBox">{phnC ? renderHTML(phnC) : '-'}</span>
        </div>
      );
    });
    return externalArr;
  }

  externalContacts(data) {
    let externalName = '';
    let comData = {};
    let externalContactArr = [];

    data.map((v) => {
      if (externalName !== '') {
        externalName = externalName + ', <br/>';
      }
      externalName = v && v.firstName && v.lastName ? v.firstName + ' ' + v.lastName : '-';

      if (v && v.venderArr && v.venderArr.length > 0) {
        comData = v.venderArr[v.venderArr.length - 1];

        if (this.state.venderList[comData.venderId]) {
          var companyData = this.state.venderList[comData.venderId]
            ? this.state.venderList[comData.venderId]
            : '';
          let phnC = '';
          if (companyData && companyData.phoneArr && companyData.phoneArr.length > 0) {
            companyData.phoneArr.map((item) => {
              if (phnC !== '') {
                phnC = phnC + '<br/>';
              }
              let phon;
              if (item.ext) {
                phon =
                  phnC +
                  item.type +
                  ': (' +
                  item.ext +
                  ')' +
                  ' <a href="tel:' +
                  item.ext +
                  item.phone +
                  '">' +
                  item.phone +
                  '</a>';
              } else {
                phon =
                  phnC +
                  item.type +
                  ':' +
                  ' <a href="tel:' +
                  item.phone +
                  '">' +
                  item.phone +
                  '</a>';
              }
              phnC = phon;
            });
          }
          externalContactArr.push(
            <div>
              <div className="ppirEP left">
                <span>
                  <strong>{companyData.companyName ? companyData.companyName : '-'}</strong>
                  <br />
                </span>
                <span>
                  <Link to={{ pathname: `/contact-details/` + v.objectId }}>{externalName}</Link>{' '}
                  {v.title ? ', ' + v.title : ''}
                </span>
                <br />
                <span>
                  {(companyData.companyAddress1 ? companyData.companyAddress1 : '') +
                    (companyData.companyAddress2 ? ',' + companyData.companyAddress2 : '')}
                </span>
                <br />
                <span>
                  {(companyData.city ? companyData.city : '') +
                    (companyData.state ? ',' + companyData.state : '') +
                    (companyData.zipCode ? ',' + companyData.zipCode : '')}
                </span>
              </div>
              <div className="ppirEP text-right right">
                <span className="phoneNumberBox">{phnC ? renderHTML(phnC) : '-'}</span>
                <br />
                <span className="phoneNumberBox">
                  <strong>E-mail:</strong>{' '}
                  {companyData.companyEmail ? companyData.companyEmail : ''}
                </span>
              </div>
            </div>
          );
        } else {
          externalContactArr.push(
            <div>
              <span>
                <strong>
                  {externalName} {v.title ? ', ' + v.title : ''}
                </strong>
              </span>
              <br />
              <span>-</span>
            </div>
          );
        }
      }
    });
    return externalContactArr;
  }

  render() {
    const { jobNumObj, jobNameObj } = this.state;

    //** project data
    let row = this.state.projectData;

    //** project phone number
    let phnData = '';
    if (row && row.phoneArr && row.phoneArr.length > 0) {
      row.phoneArr.map((item) => {
        if (phnData !== '') {
          phnData = phnData + '<br/>';
        }
        let phon;
        if (item.ext) {
          phon =
            phnData +
            item.type +
            ': (' +
            item.ext +
            ')' +
            ' <a href="tel:' +
            item.ext +
            item.phone +
            '">' +
            item.phone +
            '</a>';
        } else {
          phon =
            phnData + item.type + ':' + ' <a href="tel:' + item.phone + '">' + item.phone + '</a>';
        }
        return (phnData = phon);
      });
    }

    //** start date
    let startDate = '';
    if (row && row.startDate && row.startDate.iso) {
      startDate = formatDate(row.startDate.iso);
    }

    // ** end date
    let endDate = '';
    if (row && row.endDate && row.endDate.iso) {
      endDate = formatDate(row.endDate.iso);
    }

    // ** Account executive
    let accountExecName;
    if (
      row &&
      row.teamData &&
      row.teamData['account executive'] &&
      row.teamData['account executive'].length > 0
    ) {
      accountExecName = this.internalContact(row.teamData['account executive']);
    } else {
      accountExecName = <div>-</div>;
    }

    // ** Project Manager
    let projectMgrName;
    if (
      row &&
      row.teamData &&
      row.teamData['project manager'] &&
      row.teamData['project manager'].length > 0
    ) {
      projectMgrName = this.internalContact(row.teamData['project manager']);
    } else {
      projectMgrName = <div>-</div>;
    }

    // ** Project Manager
    let projectSuprName;
    if (
      row &&
      row.teamData &&
      row.teamData['project supervisor'] &&
      row.teamData['project supervisor'].length > 0
    ) {
      projectSuprName = this.internalContact(row.teamData['project supervisor']);
    } else if (
      row &&
      row.teamData &&
      row.teamData['general superintendent'] &&
      row.teamData['general superintendent'].length > 0
    ) {
      projectSuprName = this.internalContact(row.teamData['general superintendent']);
    } else {
      projectSuprName = <div>-</div>;
    }

    // ** building Owner
    let buildingData = '';
    if (
      row &&
      row.teamData &&
      row.teamData['property owner'] &&
      row.teamData['property owner'].length > 0
    ) {
      buildingData = this.externalContacts(row.teamData['property owner']);
    } else {
      buildingData = <div>-</div>;
    }

    // ** Architect
    let architectData = '';
    if (row && row.teamData && row.teamData.architect && row.teamData.architect.length > 0) {
      architectData = this.externalContacts(row.teamData.architect);
    } else {
      architectData = <div>-</div>;
    }

    // ** Lender
    let lenderData = '';
    if (row && row.teamData && row.teamData.lender && row.teamData.lender.length > 0) {
      lenderData = this.externalContacts(row.teamData.lender);
    } else {
      lenderData = <div>-</div>;
    }

    return (
      <div className="ContactListReport">
        <TitleComponent data={{title:`Preliminary Info Report`}}/>
        <div>
          <Suspense fallback="loading"><Header props={this.props} /></Suspense>
          <div
            className="noteProcessing"
            style={{ display: this.state.showProcessing ? 'block' : 'none' }}
          >
            <img src={loaderImage} alt="Loading" />
          </div>
          <div className="contactBox">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="reportHead reportHeadPR">
                    <h2>Preliminary Info Report</h2>
                    <ReactToPrint
                      trigger={() => (
                        <a href="#/" className="btn rightalignmentBtn">
                          <i className="fa fa-print" aria-hidden="true"></i> Print PDF
                        </a>
                      )}
                      content={() => this.componentRef}
                    />
                    <button className="btn rightalignmentBtn" onClick={() => this.toggle('POST')}>
                      <i className="fa fa-filter"></i> Apply Filters
                    </button>
                  </div>
                </div>
              </div>

              <div ref={(el) => (this.componentRef = el)}>
                <div className="jobSiteInfoBox">
                  <div className="row">
                    <div className="pdfLogoBox">
                      <div className="reportHead">
                        <div className="logo">
                          <img src={this.state.comLogo} alt="Upload Logo" />
                        </div>
                        <h2>Preliminary Info Report</h2>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="jobTitle">Job Site Info</div>
                    </div>
                    <div className="col-md-10">
                      <div className="clearfix jsibRight">
                        <div className="jobN">
                          <label>Job Number:</label>
                          <div className="job-num">{row.jobNumFormatted}</div>
                        </div>
                        <div className="jobN">
                          <label>Alt Job Number:</label>
                          <div className="alt-job-num">{row.altJobNum}</div>
                        </div>
                        <div className="proName">
                          <label>Project Name</label>
                          <h1>
                            <Link
                              to={{
                                pathname: `/project-details/` + row.objectId,
                                state: { row },
                              }}
                            >
                              {row.jobName}
                            </Link>
                          </h1>
                        </div>
                        <div className="row clear contact">
                          <div className="col-md-8">
                            <div className="address-box">
                              <p>
                                {row.jobAddress ? row.jobAddress : ''}{' '}
                                {row.jobAddress2 ? '' + row.jobAddress2 : ''}
                              </p>
                              <div className="address-text">
                                {row.jobCity} {row.jobState ? '' + row.jobState : ''}{' '}
                                {row.jobZip ? ', ' + row.jobZip : ''}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="address-box">
                              <p>
                                <span className="small-text"></span>
                                <span className="phone-text">{renderHTML(phnData)}</span>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-4">
                            <div className="mapConstruct">
                              <p>
                                Map Coordinates:
                                <strong>
                                  <span>Lat.:</span> {row.lat}
                                </strong>
                                <strong>
                                  <span>Long.:</span> {row.lng}
                                </strong>
                              </p>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="mapConstruct">
                              <p>
                                Construction Start: <strong>{startDate}</strong>
                              </p>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="mapConstruct">
                              <p>
                                Construction Stop: <strong>{endDate}</strong>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="jobSiteInfoBox">
                  <div className="row">
                    <div className="col-md-2">
                      <div className="jobTitle">General Contractor Contacts</div>
                    </div>
                    <div className="col-md-10">
                      <div className="clearfix jsibRight">
                        <div className="row">
                          <div className="col-md-4">
                            <div className="gcc">
                              <p>{appConstants.accountExecLabelName}</p>
                              <p>{accountExecName}</p>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="gcc">
                              <p>Project Mgr.</p>
                              <p>{projectMgrName}</p>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="gcc">
                              <p>Supt.</p>
                              <p>{projectSuprName}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="jobSiteInfoBox">
                  <div className="row">
                    <div className="col-md-2">
                      <div className="jobTitle">Property Owner</div>
                    </div>
                    <div className="col-md-10">
                      <div className="clearfix jsibRight">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="gcc ">
                              <p>{buildingData}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/*<div className="jobSiteInfoBox">
                             <div className="row">
                                <div className="col-md-2">
                                  <div className="jobTitle">Architect</div>
                                </div>
                                <div className="col-md-10">
                                  <div className="clearfix jsibRight">
                                    <div className="row">
                                      <div className="col-md-12">
                                        <div className="gcc">
                                         <p>{architectData}</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                           </div> */}

                <div className="jobSiteInfoBox">
                  <div className="row">
                    <div className="col-md-2">
                      <div className="jobTitle">Lender</div>
                    </div>
                    <div className="col-md-10">
                      <div className="clearfix jsibRight">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="gcc">
                              <p>{lenderData}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="commentsBoxReports">
                  <label>Comment</label>
                  <div className="jobSiteInfoBox">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="job-comment-Box"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>

        <Modal
          backdrop="static"
          keyboard={true}
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggle}>Select</ModalHeader>
          <ModalBody className="reportPopupFilter">
            <div className="container">
              <div className="form-group margin0">
                <div className="row">
                  <div className="col-md-4 lineheight">
                    <Input
                      type="radio"
                      name="period"
                      checked={this.state.period === 'jobNumFormatted'}
                      onClick={this.handleChangePeriod.bind(this, 'jobNumFormatted')}
                    />{' '}
                    Select Job Number
                  </div>
                  <div className="col-md-8">
                    {this.state.period === 'jobNumFormatted' && (
                      <Select
                        name="jobNumFormatted"
                        components={makeAnimated()}                        
                        options={this.state.DDList}
                        onChange={this.handleChangeJobNum.bind(this, 'jobNumFormatted')}
                        isLoading={this.state.isLoading}
                        className="basic-single"
                        classNamePrefix="select"
                        placeholder='Search Job Number'
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group margin0">
                <div className="row">
                  <div className="col-md-4 lineheight">
                    <Input
                      type="radio"
                      name="period"
                      checked={this.state.period === 'jobName'}
                      onClick={this.handleChangePeriod.bind(this, 'jobName')}
                    />{' '}
                    Select Job Name
                  </div>
                  <div className="col-md-8">
                    {this.state.period === 'jobName' && (
                      <Select
                        name="jobName"
                        components={makeAnimated()}
                        options={this.state.DDJobNameList}
                        onChange={this.handleChangeJobName.bind(this, 'jobName')}
                        isLoading={this.state.isLoading}
                        className="basic-single"
                        classNamePrefix="select"
                        placeholder='Search Job Name'
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button className="btn btn-primary" onClick={this.toggle}>
              Cancel
            </button>
            <button className="btn btn-primary" onClick={this.filteredData.bind(this)}>
              Next
            </button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {  
  return {
    listData: state.reports.projectPrelimInfoList,
    isReport: state.reports.isReport,
    isReportError: state.reports.isReportError,
    
  };
}

export default withRouter(
  connect(mapStateToProps, {
    GetProjectPrelimInfoAction,
  })(ProjectPrelimInfoReport)
);
