import moment, { Moment } from 'moment-timezone';
import { useEffect, useRef, useState, forwardRef } from 'react';
import DatePicker from 'react-datepicker';
import { InputMask } from '@react-input/mask';
import 'react-datepicker/dist/react-datepicker.css'
//import { formatDate, formatDateWithTime } from '../../utils/date-formatters'

type DatePickerWrapperProps = {
  //control?: Control<any>;
  label: string;
  startDate?: Date | null | moment.Moment | undefined;
  endDate?: Date | null | moment.Moment | undefined;
  // Layout?: (props: LayoutProps) => ReactElement;
  labelWidth?: number;
  controlWidth?: number;
  inputText?: string;
  children?: any;
  name: string;
  required?: boolean;
  disabled?: boolean;
  placeholderText?: string;
  selectsStart?: boolean;
  selectsEnd?: boolean;
  minDate?: Date | null | moment.Moment | undefined;
  maxDate?: Date | null | moment.Moment | undefined;
  selected?: Date | null | moment.Moment | undefined;
  showYearDropdown?: boolean;
  showMonthDropdown?: boolean;
  showTimeSelect?: boolean;
  dateFormat?: string;
  timeFormat?: string;
  timeCaption?: string;
  timeIntervals?: number;
  todayButton?: string;
  customInputFormat?: string;
  allowSameDay?: boolean;
  className?: string;
  withPortal?: boolean;
  onChange?: (date: moment.Moment | null) => void;
  //type?:string;
};

const DatePickerWrapper = ({
  name,
  //control,
  label,
  required,
  disabled,
  placeholderText = label,
  selectsStart = false,
  selectsEnd =false,
  startDate,
  endDate,
  minDate,
  maxDate,
  showYearDropdown,
  showMonthDropdown,
  showTimeSelect = false,
  dateFormat = 'mm/dd/yyyy',
  timeFormat = 'h:mm a',
  timeCaption = 'time',
  timeIntervals = 15,
  todayButton = 'Today',
  allowSameDay,
  children,
  className,
  selected,
  withPortal,
  onChange,
  //type='date',
}: DatePickerWrapperProps) => {
  // const formContext = useFormContext();
  const userTimeZone = moment.tz.guess();
  const ref = useRef<any>(null);

  dateFormat = dateFormat.replace('YYYY', 'yyyy').replace('DD', 'dd');

  
  const [isCleared, setIsCleared] = useState(false);

  interface CustomInputProps {
    value?: string;
    onClick?: () => void;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    className?: string;
    onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  }

  const CustomInput = forwardRef<HTMLInputElement, CustomInputProps>(
    ({ value, onClick, onKeyDown,onChange, className }, inputRef) => (
      <InputMask
        className={className}
        mask={showTimeSelect ? "mm/dd/yyyy hh:mm _M" : "mm/dd/yyyy"}
        replacement={{
          _: /[AaPpM]/,  // AM/PM indicator
          m: /\d/,  // Minute
          d: /\d/,  // Day
          y: /\d/,  // Year
          ...(showTimeSelect ? { 
            h: /\d/,  // Hour
          } : {})
        }}
        showMask={!isCleared}
        separate
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            onClose();
            setIsCleared(false);
          }
          onKeyDown && onKeyDown(e);
        }}
        value={value}
        onClick={onClick}
        onChange={(e) => {
          onChange && onChange(e);
        }}
        onFocus={(e) => {
          // if lose focus, clear the input
          if (!e.target.value) {
            setIsCleared(true);
          }
        }
      }
        ref={inputRef}
      />
    )
  );
  
  function momentToDateOrUndefined(date: any) {
    if (!date) {
      return undefined;
    }
    if (date instanceof Date && !isNaN(date.getTime())) {
      return date;
    } else {
      return date ? moment(date).toDate() : undefined;
    }

  }

  const [startDateInternal, setStartDateInternal] = useState<Date | undefined>(momentToDateOrUndefined(startDate));
  const [endDateInternal, setEndDateInternal] = useState<Date | undefined>(momentToDateOrUndefined(endDate));
  const [minDateInternal, setMinDateInternal] = useState<Date | undefined>(momentToDateOrUndefined(minDate));
  const [maxDateInternal, setMaxDateInternal] = useState<Date | undefined>(momentToDateOrUndefined(maxDate));

  useEffect(() => {
    if (startDate) {
      setStartDateInternal(momentToDateOrUndefined(startDate));
    }
  }, [startDate]);

  useEffect(() => {
    if (endDate) {
      setEndDateInternal(momentToDateOrUndefined(endDate));
    }
  }, [endDate]);

  useEffect(() => {
    if (minDate) {
      setMinDateInternal(momentToDateOrUndefined(minDate));
    }
  }, [minDate]);

  useEffect(() => {
    if (maxDate) {
      setMaxDateInternal(momentToDateOrUndefined(maxDate));
    }
  }, [maxDate]);


  const onChangeLocal = (date: Date | null) => {
    if (!onChange) {
      return;
    }
    if (date && !isOutOfBounds(date)) {
      onChange(moment(date).tz(userTimeZone));
      setIsCleared(false);
    } else {
      onChange(null);
      setIsCleared(true);
    }
    setHasSelectedVal(date !== null && date !== undefined);
  };

  const [hasSelectedVal, setHasSelectedVal] = useState(selected !== null && selected !== undefined);
  const validateSelected = (field: any) => {
    if (isCleared) return undefined;
  
    const date = momentToDateOrUndefined(field);
  
    if (date) {
      if (!isOutOfBounds(date)) {
        return date;
      }
  
      if (minDateInternal && date < minDateInternal) {
        return minDateInternal;
      }
  
      if (maxDateInternal && date > maxDateInternal) {
        return maxDateInternal;
      }
    }
  
    return undefined;
  };

  const isOutOfBounds = (date: Date) => {
    return date&&(minDateInternal && date < minDateInternal || (maxDateInternal && date > maxDateInternal));
  };

  const onClose = () => ref.current?.setOpen(false);

  const Children = children;

  useEffect(() => {
    setIsCleared(selected === null || selected === undefined);
  }, [selected]);

  /*const getTextValue = (field: any) => {
    const value = validateSelected(field);

    if (value) {
      return showTimeSelect ? formatDateWithTime(value) : formatDate(value);
    }
  }*/

  return (
    <>
      <DatePicker
        ref={ref}
        className={className}
        id={name}
        name={name}
        selectsEnd={selectsEnd}
        selectsStart={selectsStart}
        selected={validateSelected(selected)}
        startDate={startDateInternal}
        endDate={endDateInternal}
        onChange={(date) => onChangeLocal(date)}
        allowSameDay={allowSameDay}
        dateFormat={dateFormat}
        placeholderText={placeholderText}
        minDate={minDateInternal}
        maxDate={maxDateInternal}
        showYearDropdown={showYearDropdown}
        showMonthDropdown={showMonthDropdown}
        showTimeSelect={showTimeSelect}
        timeFormat={timeFormat}
        timeCaption={timeCaption}
        timeIntervals={timeIntervals}
        dropdownMode='select'
        withPortal={withPortal}
        todayButton={todayButton}
        customInput={<CustomInput />}
        disabled={disabled}
        autoComplete="off"
        showPopperArrow={false}
        isClearable={hasSelectedVal && !required&& !disabled}
        popperPlacement={showTimeSelect ? 'bottom-start' : 'bottom'}
      >
        {children ? <Children name={name} onClose={onClose} /> : null}
      </DatePicker></>
  );
};

export default DatePickerWrapper;
