import Lightbox from 'yet-another-react-lightbox';
import Captions from 'yet-another-react-lightbox/plugins/captions';
import Thumbnails from 'yet-another-react-lightbox/plugins/download';
import Video from 'yet-another-react-lightbox/plugins/video';
import { useEffect, useState, useRef } from 'react';
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';
import 'yet-another-react-lightbox/plugins/captions.css';
import { useLightboxState } from 'yet-another-react-lightbox';
import { FileIcon } from 'react-file-icon';
import { Document, Page, pdfjs } from 'react-pdf';
import { FileLinkModal } from './FileLinkModal';

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

import './lightboxSlides.css';
import { set } from 'lodash';

function isGenericFileSlide(slide) {
  return slide.type === 'genericFile';
}

function isFileLinkSlide(slide) {
  return slide.type === 'fileLink';
}

function isPdfFileSlide(slide) {
  return slide.type === 'pdfFile';
}

function getFileExtension(fileName) {
  if (!fileName) return '';

  const lastIndex = fileName.lastIndexOf('.');
  return fileName.substring(lastIndex + 1);
}

export const LightBoxComponent = ({
  attachments = [],
  shouldOpen = false,
  initialIndex = -1,
  closeCallback,
}) => {
  const [open, setOpen] = useState(false);
  const [index, setIndex] = useState(-1);

  const [slides, setSlides] = useState([]);

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [documentLoaded, setDocumentLoaded] = useState(false);

  const onDocumentLoad = ({ numPages }) => {
    setNumPages(numPages);
    setDocumentLoaded(true);
  };

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = 'https://app.commandpost.ai/pdf.worker.min.mjs';
  });

  useEffect(() => {
    setPageNumber(1);
  }, [numPages]);

  function renderLinkSlide(slide) {
    const slideIndex = slide.slideIndex;
    const { currentIndex } = useLightboxState();
    return slideIndex === currentIndex ? (
      <div className = 'row'>
        <div className='col-2'>
            &nbsp;
            </div>
            <div className='col-8'>
      <div className='modal-content' style={{ color: 'white', borderColor:'yellow', borderWidth:'1px'}}>
        
        <div className='row mt-4'>
          
      
            <b>Note:</b>
            <br />
            <div className='ms-2 me-2 mt-2'>
              Due to security restrictions, you can only open local file links if you have enabled
              this in your browser with an appropriate plugin or browser extension If you are using
              Chrome, you can use the "Local File System Links" extension to enable this feature.
            </div>
          </div>
        </div>
        <hr />
        <div className='row mt-2 mb-4'>
        
          <div className='justify-contents-center'>
         <center><a href={slide.downloadUrl} className='btn btn-primary'>
            Open shortcut to {slide.linkFilePath}
          </a>
          </center> 
          </div>
        </div>
        </div>

        <div className='col-2'>
            &nbsp;
          </div>
      </div>
    ) : null;
  }

  function renderPdfSlide(slide) {
    const slideIndex = slide.slideIndex;

    const { currentIndex } = useLightboxState();

    return slideIndex === currentIndex ? (
      <div className='pdfFileContainer'>
        <div className='pdfFileControls'>
          <div className='row'>
            <p>
              {pageNumber > 1 && (
                <button onClick={() => setPageNumber(pageNumber < numPages ? pageNumber + 1 : 1)}>
                  Prev
                </button>
              )}
            </p>
          </div>
        </div>
        <Document className={'pdfFile'} file={slide.downloadUrl} onLoadSuccess={onDocumentLoad}>
          {documentLoaded && (
            <>
              <Page pageNumber={pageNumber} />
            </>
          )}
        </Document>

        <div className='pdfFileControls'>
          <div className='row'>
            <p>
              {numPages > 1 && (
                <button onClick={() => setPageNumber(pageNumber < numPages ? pageNumber + 1 : 1)}>
                  Next
                </button>
              )}
            </p>
          </div>
        </div>
      </div>
    ) : null;
  }

  function renderFileSlide(slide) {
    const slideIndex = slide.slideIndex;

    const { currentIndex } = useLightboxState();

    return slideIndex === currentIndex ? (
      <div className='genericFileSlideContainer'>
        <div className='non-image-item-content'>
          <p className='file-icon'>
            <FileIcon extension={getFileExtension(slide.downloadFilename)} />
          </p>
          <br />
          <p className='file-name'>{slide.downloadFilename}</p>
        </div>
      </div>
    ) : null;
  }

  useEffect(() => {
    if (!open && shouldOpen) {
      setOpen(true);
    }
  }, [shouldOpen]);

  useEffect(() => {
    setIndex(initialIndex);
  }, [initialIndex]);

  const mapAttachmentsToLightbox = (attachments) => {
    const numAttachments = attachments.length;
    return attachments.map((attachment, index) => {
      const isImage = attachment.fileType?.includes('image');
      const isVideo =
        attachment.fileType?.includes('vid') || attachment.fileType?.includes('audio');
      const isPdf = attachment.fileType?.includes('pdf');
      const isFileLink = attachment?.fileLink?.length > 0;
      const isGenericFile = !isImage && !isVideo && !isPdf;

      return {
        type: isImage
          ? 'image'
          : isVideo
          ? 'video'
          : isPdf
          ? 'pdfFile'
          : isFileLink
          ? 'fileLink'
          : 'genericFile',
        src: attachment.url,
        downloadUrl: attachment.url ?? attachment.fileLink,
        downloadFilename: attachment.fileName,
        linkFilePath: attachment.filePath,
        title:
          attachment.fileName +
          (numAttachments > 1 ? ' (' + (index + 1) + '/' + numAttachments + ')' : ''),
        description: attachment?.description ?? '',
        slideIndex: index,
        width: isVideo ? 1280 : undefined,
        height: isVideo ? 720 : undefined,
        sources: isVideo
          ? [
              {
                src: attachment.url,
                type: attachment.fileType,
              },
            ]
          : undefined,
      };
    });
  };

  const closeLightbox = () => {
    setOpen(false);
    setIndex(-1);
    closeCallback && closeCallback();
  };

  useEffect(() => {
    setSlides(mapAttachmentsToLightbox(attachments));
  }, [attachments]);

  const thumbnailsRef = useRef(null);
  const captionsRef = useRef(null);

  const numAttachments = attachments.length;

  return (
    <div className='lightBoxPreview'>
      <Lightbox
        open={index >= 0 && open}
        close={closeLightbox}
        slides={slides}
        thumbnails={{ ref: thumbnailsRef }}
        captions={{ ref: captionsRef }}
        on={{
          click: () => {
            (captionsRef.current?.visible
              ? captionsRef.current?.hide
              : captionsRef.current?.show)?.();
          },
          view: ({ index }) => {
            setIndex(index);
          },
        }}
        index={index}
        render={{
          slide: ({ slide }) =>
            isGenericFileSlide(slide)
              ? renderFileSlide(slide)
              : isPdfFileSlide(slide)
              ? renderPdfSlide(slide)
              : isFileLinkSlide(slide)
              ? renderLinkSlide(slide)
              : null,
          buttonPrev: numAttachments <= 1 ? () => null : undefined,
          buttonNext: numAttachments <= 1 ? () => null : undefined,
        }}
        plugins={[Thumbnails, Captions, Video]}
      />
    </div>
  );
};
