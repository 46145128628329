import { Suspense, useState, useEffect, useMemo } from 'react';
import ReactLoading from 'react-loading';
import { Pagination } from 'antd';
import TitleComponent from '../../components/Common/TitleComponent';
import { default as Footer } from '../../components/Layout/Footer.js';
import { default as Header } from '../../components/Layout/Header.js';
import { selectJobStatusesDropdown, selectGetTemporaryProjects } from '../../modules/projects/selectors';
import { getTemporaryProjects, jobStatusesDropdown } from '../../modules/projects';
import { useAppDispatch } from '../../shared/hooks/use-app-dispatch';
import { useAppSelector } from '../../shared/hooks/use-app-selector';
import { getProjectRoles } from '../../modules/settings';
import { appConstants, isAllowed, moduleConstants } from '../../_constants';
import ProjectView from './project-update/project-view';
import styles from './styles.module.scss';

export const EditPendingProjectImportPage = (props) => {
  if (!isAllowed(moduleConstants.IMPORTPROJECTS)) {
    props.history.push({ pathname: '/' });
  }

  const pageSize = 3;
  const dispatch = useAppDispatch();
  const { loading: tmpProjectsLoading, data: tmpProjects } = useAppSelector(selectGetTemporaryProjects);
  const { loading: jobStatusesLoading, data: jobStatuses } = useAppSelector(selectJobStatusesDropdown);

  const allowedJobStatuses = jobStatuses.filter((opt) => !['OH', 'OHP', 'SUSP'].includes(opt.jobStatusCodesCode));

  const [projectRoles, setProjectRoles] = useState([]);
  const [page, setPage] = useState(1);

  const displayProjects = useMemo(() => tmpProjects.slice((page - 1) * pageSize, page * pageSize));

  useEffect(() => {
    dispatch(getProjectRoles())
      .unwrap()
      .then((data) => setProjectRoles(data));
    dispatch(getTemporaryProjects());
    dispatch(jobStatusesDropdown());
  }, []);

  const [autoAddChecked, setAutoAddChecked] = useState(false);



  const getProjectsView = () => {
    if (tmpProjectsLoading || jobStatusesLoading) {
      const { LOADER_TYPE, LOADER_COLOR, LOADER_HEIGHT, LOADER_WIDTH } = appConstants;

      return (
        <ReactLoading
          className='table-loader'
          type={LOADER_TYPE}
          color={LOADER_COLOR}
          height={LOADER_HEIGHT}
          width={LOADER_WIDTH}
        />
      );
    }
    
    const allowAutoAdd = appConstants.AUTO_IMPORT_ENABLED;

    return (
      <div className='contactBox'>
        <div className='container'>
          <div className='d-flex justify-content-end'>

            {allowAutoAdd && <div className='form-check mt-2'>
              <input
                className='form-check-input'
                type='checkbox'
                value={autoAddChecked}
                onChange={() => setAutoAddChecked(!autoAddChecked)}
              />
              <label className='form-check-label' htmlFor='autoAddChecked'>
                Auto Add
              </label>
              
            </div>}
            <Pagination
              current={page}
              onChange={(page) => setPage(page)}
              pageSize={pageSize}
              total={tmpProjects.length}
            />
          </div>
          <div className={styles.projectList}>
            {displayProjects.map((project) => (
              <ProjectView project={project} projectRoles={projectRoles} jobStatuses={allowedJobStatuses} autoAdd={autoAddChecked}/>
            ))}
            {displayProjects.length === 0 && <div className={styles.noProjects}>No projects found</div>}
          </div>
        </div>
      </div>
    );
  };

  return (
    <Suspense fallback='loading'>
      <div className={styles.wrapper}>
        <TitleComponent data={{ title: 'Import Projects: Add Details' }} />
        <Header props={props} />
        {getProjectsView()}
        <Footer />
      </div>
    </Suspense>
  );
};
