import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { selectNavLinkAction } from '../../actions/sidebar.js';
import { appConstants, isAllowed, moduleConstants } from '../../_constants';

function SidebarSettings({ location, selectNavLinkAction }) {
  const [userInfo] = useState(() => JSON.parse(localStorage.getItem('userInfo')));
  const [projectSettingsExpanded, setProjectSettingsExpanded] = useState(false);
  const [companySettingsExpanded, setCompanySettingsExpanded] = useState(false);
  const [contactSettingsExpanded, setContactSettingsExpanded] = useState(false);

  const handleClick = (event, index) => {
    selectNavLinkAction(index);
  };

  const getClassByPath = (path) => (location?.pathname === path ? 'active' : '');

  let pathname = location.pathname;

  return (
    <ul className='sidebarlist masterSection mt-2'>
      <div className='flex row'>
        <h2 className='heading2 col-2'>Settings</h2>
      </div>
      <li className='nav-item'>
        <span
          id='companySettings'
          className='accordion nav-link menu-title'
          data-toggle='collapse'
          data-bs-toggle='collapse'
          data-bs-target='#companySettingsCollapse'
          role='button'
          onClick={() => setCompanySettingsExpanded(!companySettingsExpanded)}
        >
          <Link>Command Post</Link>
          &ensp;
          <i className={`fa fa-light fa-angle-${companySettingsExpanded ? 'down' : 'right'}`}></i>
        </span>
        <ul
          id='companySettingsCollapse'
          className='accordion-collapse collapse ps-3'
          aria-labelledby='companySettings'
        >
          <li className='nav-item active mt-3' onClick={(e) => handleClick(e, 1)}>
            <span
              className={`nav-link menu-title ${getClassByPath('/settings/company-info')}`}
            >
              <Link to='/settings/company-info'>Company Profile</Link>
            </span>
          </li>
          <li className='nav-item' onClick={(e) => void 0}>
            <span
              className={`nav-link menu-title ${getClassByPath('/settings/report-settings')}`}
            >
              <Link to='/settings/report-settings'>Report Settings</Link>
            </span>
          </li>
          <li className='nav-item active' onClick={(e) => handleClick(e, 1)}>
            <span
              className={`nav-link menu-title ${getClassByPath('/settings/company-finance')}`}
            >
              <Link to='/settings/company-finance'>Overhead</Link>
            </span>
          </li>
          <li className='nav-item' onClick={(e) => handleClick(e, 10)}>
            <span
              className={`nav-link menu-title ${getClassByPath('/settings/role-types')}`}
            >
              <Link to='/settings/role-types'>User Types</Link>
            </span>
          </li>
          {isAllowed(moduleConstants.PERM) && (
            <li className='nav-item active' onClick={(e) => handleClick(e, 1)}>
              <span
                className={
                  /\/permissions/.test(pathname)
                    ? 'nav-link menu-title active'
                    : 'nav-link menu-title'
                }
              >
                <Link to='/settings/permissions/status-change'>User Permissions</Link>
              </span>
            </li>
          )}
          {isAllowed(moduleConstants.BILL) && (
            <li className='nav-item active' onClick={(e) => handleClick(e, 1)}>
              <span
                className={`nav-link menu-title ${getClassByPath('/settings/billing')}`}
              >
                <Link to='/settings/billing'>Billing</Link>
              </span>
            </li>
          )}
          {isAllowed(moduleConstants.BILL) && (
            <li className='nav-item' onClick={(e) => {}}>
              <span
                className={`nav-link menu-title ${getClassByPath('/settings/app-connections')}`}
              >
                <Link to='/settings/app-connections'>Command Post Hub</Link>
              </span>
            </li>
          )}
        </ul>
      </li>

      <li className='nav-item'>
        <span
          id='contactSettings'
          className='accordion nav-link menu-title'
          data-toggle='collapse'
          data-bs-toggle='collapse'
          data-bs-target='#contactSettingsCollapse'
          role='button'
          onClick={() => setContactSettingsExpanded(!contactSettingsExpanded)}
        >
          <Link>Contacts</Link>
          &ensp;
          <i className={`fa fa-light fa-angle-${contactSettingsExpanded ? 'down' : 'right'}`}></i>
        </span>
        <ul
          id='contactSettingsCollapse'
          className='accordion-collapse collapse ps-3'
          aria-labelledby='contactSettings'
        >
          <li className='nav-item  mt-3' onClick={(e) => handleClick(e, 3)}>
            <span
              className={`nav-link menu-title ${pathname === '/settings/contact-role' ? 'active' : ''}`}
            >
              <Link to='/settings/contact-role'>Contact Roles </Link>
            </span>
          </li>
          <li className='nav-item' onClick={(e) => handleClick(e, 9)}>
            <span
              className={`nav-link menu-title ${getClassByPath('/settings/contact-custom-fields')}`}
            >
              <Link to='/settings/contact-custom-fields'>Contact/Company Fields</Link>
            </span>
          </li>
        </ul>
      </li>

      <li className='nav-item'>
        <span
          id='projectSettings'
          className='accordion nav-link menu-title'
          data-toggle='collapse'
          data-bs-toggle='collapse'
          data-bs-target='#projectSettingsCollapse'
          role='button'
          onClick={() => setProjectSettingsExpanded(!projectSettingsExpanded)}
        >
          <Link>Projects</Link>
          &ensp;
          <i className={`fa fa-light fa-angle-${projectSettingsExpanded ? 'down' : 'right'}`}></i>
        </span>
        <ul
          id='projectSettingsCollapse'
          className='accordion-collapse collapse ps-3'
          aria-labelledby='projectSettings'
        >
          <li className='nav-item mt-3' onClick={(e) => handleClick(e, 1)}>
            <span
              className={`nav-link menu-title ${getClassByPath('/settings/project-profit-settings')}`}
            >
              <Link to='/settings/project-profit-settings'>General Settings</Link>
            </span>
          </li>
          <li className='nav-item' onClick={(e) => handleClick(e, 7)}>
            <span
              className={`nav-link menu-title ${getClassByPath('/settings/job-status')}`}
            >
              <Link to='/settings/job-status'>Status Codes</Link>
            </span>
          </li>
          <li className='nav-item' onClick={(e) => handleClick(e, 9)}>
            <span
              className={`nav-link menu-title ${getClassByPath('/settings/project-custom-fields')}`}
            >
              <Link to='/settings/project-custom-fields'>Project Fields</Link>
            </span>
          </li>
          {isAllowed(moduleConstants.PERM) && (
            <li className='nav-item' onClick={(e) => handleClick(e, 2)}>
              <span
                className={
                  /\/project-roles/.test(pathname)
                    ? 'nav-link menu-title active'
                    : 'nav-link menu-title'
                }
              >
                <Link to='/settings/project-roles'>Project Roles</Link>
              </span>
            </li>
          )}
          <li className='nav-item' onClick={(e) => handleClick(e, 3)}>
            <span
              className={`nav-link menu-title ${getClassByPath('/settings/line-items')}`}
            >
              <Link to='/settings/line-items'>Line Items</Link>
            </span>
          </li>
          <li className='nav-item' onClick={(e) => handleClick(e, 3)}>
            <span
              className={`nav-link menu-title ${getClassByPath('/settings/job-scope')}`}
            >
              <Link to='/settings/job-scope'>Job Scopes</Link>
            </span>
          </li>
          <li className='nav-item' onClick={(e) => handleClick(e, 4)}>
            <span
              className={`nav-link menu-title ${getClassByPath('/settings/building-type')}`}
            >
              <Link to='/settings/building-type'>Building Types</Link>
            </span>
          </li>
          <li className='nav-item mt-3' onClick={(e) => handleClick(e, 5)}>
            <span
              className={`nav-link menu-title ${getClassByPath('/settings/occupancy-type')}`}
            >
              <Link to='/settings/occupancy-type'>Occupancy Types</Link>
            </span>
          </li>
          <li className='nav-item' onClick={(e) => handleClick(e, 6)}>
            <span
              className={`nav-link menu-title ${getClassByPath('/settings/negotiation-method')}`}
            >
              <Link to='/settings/negotiation-method'>Negotiating Methods</Link>
            </span>
          </li>

          <li className='nav-item' onClick={(e) => handleClick(e, 8)}>
            <span
              className={`nav-link menu-title ${getClassByPath('/settings/reason-codes')}`}
            >
              <Link to='/settings/reason-codes'>Reason Codes</Link>
            </span>
          </li>

          {(!appConstants.IS_PRODUCTION)&&(<li className='nav-item' onClick={(e) => handleClick(e, 9)}>
            <span
              className={`nav-link menu-title ${getClassByPath('/settings/form-templates')}`}
            >
              <Link to='/settings/form-templates'>Form Templates</Link>
            </span>
          </li>)
}
        </ul>
      </li>
    </ul>
  );
}

function mapStateToProps(state) {
  return {
    isActive: state.sidebar.isActive,
  };
}

export default withRouter(connect(mapStateToProps, { selectNavLinkAction })(SidebarSettings));
