import * as actionTypes from './actionTypes';
import * as API from '../api/widget';


import { notification } from 'antd';


export const getAllWidgetPreferencesRequest = () => ({
  type: actionTypes.GET_ALL_WIDGET_PREFERENCES_REQUEST,
});

export const getAllWidgetPreferencesSuccess = (response) => ({
  type: actionTypes.GET_ALL_WIDGET_PREFERENCES_SUCCESS,
  payload: {
    response,
  },
});

export const getAllWidgetPreferencesError = (error) => ({
  type: actionTypes.GET_ALL_WIDGET_PREFERENCES_ERROR,
  payload: {
    error,
  },
});

export const getAllWidgetPreferencesAction = (data) => {
  return (dispatch) => {
    dispatch(getAllWidgetPreferencesRequest());
    return API.getAllWidgetPreferences(data)
      .then((response) => {
        dispatch(getAllWidgetPreferencesSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          getAllWidgetPreferencesError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

export const updateWidgetPreferenceRequest = () => ({
  type: actionTypes.UPDATE_WIDGET_PREFERENCE_REQUEST,
});

export const updateWidgetPreferenceSuccess = (response) => ({
  type: actionTypes.UPDATE_WIDGET_PREFERENCE_SUCCESS,
  payload: {
    response,
  },
});

export const updateWidgetPreferenceError = (error) => ({
  type: actionTypes.UPDATE_WIDGET_PREFERENCE_ERROR,
  payload: {
    error,
  },
});

export const updateWidgetPreferenceAction = (data) => {
  return (dispatch) => {
    dispatch(updateWidgetPreferenceRequest());
    return API.updateWidgetPreferences(data)
      .then((response) => {
        dispatch(updateWidgetPreferenceSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          updateWidgetPreferenceError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};


export const setWidgetOrderRequest = () => ({
  type: actionTypes.SET_WIDGET_ORDER_REQUEST,
});

export const setWidgetOrderSuccess = (response) => ({
  type: actionTypes.SET_WIDGET_ORDER_SUCCESS,
  payload: {
    response,
  },
});

export const setWidgetOrderError = (error) => ({
  type: actionTypes.SET_WIDGET_ORDER_ERROR,
  payload: {
    error,
  },
});

export const setWidgetOrderAction = (data) => {
  return (dispatch) => {
    dispatch(setWidgetOrderRequest());
    return API.setWidgetOrder(data)
      .then((response) => {
        notification.success({ message: response.data.result.msg });
        dispatch(setWidgetOrderSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          setWidgetOrderError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};
