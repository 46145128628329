import React, { useState, useEffect } from 'react';
import { ModalHeader, ModalBody, ModalFooter, Modal } from 'reactstrap';

export const FileLinkModal =  ({url, name, closeCallback}) =>{

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if(url.length>0){
      setIsOpen(true);
    }else{
      setIsOpen(false);
    }
   
  },[url]);

  const onClose = () => {
    setIsOpen(false);
    closeCallback();
  };

  return (
    <div>
        <Modal backdrop='static' isOpen={isOpen} toggle={onClose}>
      <ModalHeader toggle={onClose}>Open Local File link</ModalHeader>

        <ModalBody>
          <div className='row'>
              <b>Note:</b>
              <br/>
              <div className='ms-2 me-2 mt-2'>
              Due to security restrictions, you can only open local file links if you have enabled this in your browser with an appropriate plugin or browser extension
              If you are using Chrome, you can use the "Local File System Links" extension to enable this feature.
              </div>
            </div>
            <hr/>
            <div className='row mt-2'>
        <a href={url}  className='btn btn-primary'>
            Open shortcut to {name}
            </a>
            </div>
        </ModalBody>
    
      <ModalFooter>
        <button type='button' className='btn btn-primary' onClick={onClose}>
          Cancel
        </button>
      
      </ModalFooter>
    </Modal>
     
    </div>
  );
}