import { useForm, FormProvider } from 'react-hook-form';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import FormInput from '../../../shared/form-controls/form-input/form-input';
import { useCallback, useEffect, useState } from 'react';
import { useAppDispatch } from '../../../shared/hooks/use-app-dispatch';
import { useAppSelector } from '../../../shared/hooks/use-app-selector';
import {
  addUserSettings,
  disableUserSetting,
  enableUserSetting,
  getPermissionMatrixRows,
  resetDefaultPermissions,
  resetUserSetting,
  updateUserSettings,
} from '../../../modules/settings';
import { useSubmit } from '../../../shared/hooks/use-submit';
import { isAllowed, moduleConstants } from '../../../_constants';
import FormSelect from '../../../shared/form-controls/form-select';
import { useConfirm } from '../../../shared/confirmation-dialog';

const useDefaultPermissionsOptions = ({ isUpdate }) => {
  const dispatch = useAppDispatch();
  const [options, setOptions] = useState([]);
  useEffect(() => {
    if (!isUpdate)
      dispatch(getPermissionMatrixRows({ isSuperAdmin: true }))
        .unwrap()
        .then((adminRoles) =>
          setOptions(adminRoles.map((role) => ({ value: role._id, label: role.roleName })))
        );
  }, [isUpdate]);

  return options;
};

const useUserRoles = ({ isUpdate, setting }) => {
  const dispatch = useAppDispatch();
  const confirm = useConfirm();

  const adminRoleCode = 'ADMIN';
  const { originalRoleCode, roleCode } = setting || {};

  const canManageAdmins = isAllowed(moduleConstants.EA);
  const isAdminRole = roleCode === adminRoleCode;
  const isInheritedAdminRole = roleCode !== adminRoleCode && originalRoleCode === adminRoleCode;
  const isNotEditableAdmin = isAdminRole || (isInheritedAdminRole && !canManageAdmins);
  const showToggleRoleButton = isUpdate && !isNotEditableAdmin;
  const showResetDefaultButton = isUpdate && setting?.isLocalCopy && !isNotEditableAdmin;
  const showResetPermissionsButton = isUpdate && setting?.isLocalCopy && !isNotEditableAdmin;

  const adminRoles = useDefaultPermissionsOptions({ isUpdate });

  const [onResetPermissionsClick, resetPermissionsLoading] = useSubmit(async () => {
    if (!setting) return;

    const confirmed = await confirm({
      title: 'Reset default permissions',
      question: 'Are you sure you want to restore all permissions for this role?',
    });

    if (!confirmed) return;

    dispatch(resetDefaultPermissions({ roleId: setting._id }));
  }, [setting]);

  return {
    adminRoles,
    showToggleRoleButton,
    showResetDefaultButton,
    showResetPermissionsButton,
    onResetPermissionsClick,
    resetPermissionsLoading,
  };
};

export const EditSettingModal = ({
  open = false,
  onClose = () => {},
  onSubmit = () => {},
  settingId,
}) => {
  const modelName = 'cppRoles';

  const isUpdate = !!settingId;
  const dispatch = useAppDispatch();

  const form = useForm({
    defaultValues: {
      name: '',
      code: '',
      definition: '',
    },
  });

  // TODO: should be replaced with dispatch
  const setting = useAppSelector((state) => {
    const settings = state.rtk.settings.getUserSettings.data;

    const setting = settings.find((s) => s._id === settingId);

    return setting;
  });

  useEffect(() => {
    if (!open) return;

    if (!setting || !isUpdate) {
      form.reset({
        name: '',
        code: '',
        definition: '',
        defaultRoleId: null,
      });

      return;
    }

    form.reset({
      name: setting.name,
      code: setting.code,
      definition: setting.definition,
      defaultRoleId: setting.defaultRoleId,
    });
  }, [setting, isUpdate, open]);

  const {
    adminRoles,
    showToggleRoleButton,
    showResetDefaultButton,
    showResetPermissionsButton,
    onResetPermissionsClick,
    resetPermissionsLoading,
  } = useUserRoles({ isUpdate, setting });

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = form.handleSubmit(async (data) => {
    if (isUpdate) {
      await dispatch(updateUserSettings([modelName, { ...data, _id: settingId }])).unwrap();
    } else {
      await dispatch(addUserSettings([modelName, { ...data, _id: settingId }])).unwrap();
    }

    onSubmit();
    handleClose();
  });

  const [handleToggle, toggleLoading] = useSubmit(async () => {
    if (setting.trash) {
      await dispatch(enableUserSetting([modelName, settingId])).unwrap();
    } else {
      await dispatch(disableUserSetting([modelName, settingId])).unwrap();
    }
    onSubmit();
  }, [setting, setting]);

  const [handleReset, resetLoading] = useSubmit(async () => {
    await dispatch(resetUserSetting([modelName, settingId])).unwrap();

    onSubmit();
  }, [settingId]);

  return (
    <Modal isOpen={open} toggle={onClose} size='lg'>
      <ModalHeader toggle={onClose}>{isUpdate ? 'Edit' : 'Add'} User Type</ModalHeader>
      <ModalBody>
        <FormProvider {...form}>
          <div className='container'>
            <div className='row'>
              <div className='col-6'>
                <FormInput label='Name' name='name' required />
              </div>

              <div className='col-6'>
                <FormInput label='Code' name='code' required disabled={isUpdate} />
              </div>
            </div>

            <div className='row'>
              <div className='col-12'>
                <FormInput label='Definition' name='definition' textArea />
              </div>
            </div>

            <div className='row'>
              <div className='col-12'>
                {showResetPermissionsButton && (
                  <button
                    className='btn btn-primary mt-3'
                    disabled={resetPermissionsLoading}
                    onClick={onResetPermissionsClick}
                  >
                    Reset default permissions
                  </button>
                )}
              </div>
            </div>

            <div className='row'>
              <div className='col-12'>
                {!isUpdate && (
                  <FormSelect
                    isClearable
                    label='Inherit Role Permissions From'
                    options={adminRoles}
                    name='defaultRoleId'
                  />
                )}
              </div>
            </div>
          </div>
        </FormProvider>
      </ModalBody>
      <ModalFooter>
        {showResetDefaultButton && (
          <button className='btn btn-primary me-auto' onClick={handleReset} disabled={resetLoading}>
            Reset to default
          </button>
        )}

        <button className='btn btn-primary' onClick={onClose}>
          Cancel
        </button>
        {showToggleRoleButton && (
          <button className='btn btn-primary' onClick={handleToggle} disabled={toggleLoading}>
            {setting.trash ? 'Enable' : 'Disable'}
          </button>
        )}
        <button
          className='btn btn-primary'
          onClick={handleSubmit}
          disabled={
            form.formState.isSubmitting || !form.formState.isValid || !form.formState.isDirty
          }
        >
          Save
        </button>
      </ModalFooter>
    </Modal>
  );
};
