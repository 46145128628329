import { Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { useLocation } from 'react-router-dom';
import { deleteContactAction } from '../../actions/contact';
import AddContactModal from '../../modals/add-contact-modal/add-contact-modal';
import { ManageEmployeeModal } from '../../modals/manage-employee-modal';
import { getEmployees, getEmployeesTotal } from '../../modules/contacts/index';
import { selectGetEmployees, selectGetEmployeesTotal } from '../../modules/contacts/selectors';
import RemotePagination from '../../shared/data-grid/remote-pagination/remote-pagination.jsx';
import SearchComponent from '../../shared/data-grid/search-component';
import useBasicRowSelection from '../../shared/data-grid/use-basic-row-selection/use-basic-row-selection.jsx';
import usePagination from '../../shared/data-grid/use-pagination';
import useSorting, { headerWithSort } from '../../shared/data-grid/use-sorting/use-sorting.jsx';
import FormattedPhoneArr from '../../shared/formatted-phone/formatted-phone-arr.jsx';
import { appConstants, isAllowed, moduleConstants, reportAccess } from '../../_constants';

import { withTranslation } from 'react-i18next';
import CustomTable from '../Common/CustomTable';
import {Tooltip} from 'react-tooltip';
import TitleComponent from '../Common/TitleComponent';
import { default as Footer } from '../Layout/Footer.js';
import { default as Header } from '../Layout/Header.js';
import './../../index.css';

const EmployeesTable = ({ rowSelection, sortingState, manageUserCallback }) => {
  const { data: { employees, personalAffiliations }, loading } = useSelector(selectGetEmployees);

  const getPrimaryItem = (data, key) => {
    const accountData = data?.[key].find(email => email.isPrimary);
    return accountData || personalAffiliations?.[data?.contactId]?.[key]?.find(email => email.isPrimary);
  };

  const cellFullName = (dataWithoutObjectId) => {
    const dataWithObjectId = { ...dataWithoutObjectId, objectId: dataWithoutObjectId._id };

    const { firstName, lastName, middleName } = dataWithObjectId;

    // fullName is a firstName, first letter of middleName and lastName
    // if middleName is not defined, then it is not displayed

    const fullName = middleName
      ? `${firstName || ''} ${middleName[0]}. ${lastName || ''}`
      : `${firstName || ''} ${lastName || ''}`;

    return (
      <Link
        to={{
          pathname: `/contact-details/` + dataWithObjectId.objectId,
          state: { row: dataWithObjectId },
        }}
      >
        {fullName}
      </Link>
    );
  };

  const cellEmail = (data) => {
    const primaryEmail = getPrimaryItem(data, 'emailArr');

    if (primaryEmail) {
      return <a href={'mailto:' + primaryEmail.email}>{primaryEmail.email}</a>;
    } else {
      return <div>-</div>;
    }
  };

  const cellPhone = (data) => {
    const primaryPhone = getPrimaryItem(data, 'phoneArr');

    return <FormattedPhoneArr phoneArr={primaryPhone ? [primaryPhone] : []} />;
  };

  const cellAccess = (data) => {
    return (
      <div style={{ textAlign: 'center' }} onClick={() => manageUserCallback(data.contactId)}>
        {data?.isEmployee === true && data?.userEmailActivation === true ? (
          <i className='fa fa-check fa-lg' style={{ color: 'green' }} aria-hidden='true'></i>
        ) : data?.isEmployee === true && data?.userEmailActivation === false ? (
          <i
            className='fa fa-user-plus fa-lg'
            style={{ color: 'goldenrod' }}
            aria-hidden='true'
          ></i>
        ) : (
          <i className='fa fa-minus fa-lg' style={{ color: 'grey' }} aria-hidden='true'></i>
        )}
      </div>
    );
  };

  const cellRole = (data) => {
    if (data?.isEmployee === true && data?.userEmailActivation === true ) {
      return <div onClick={() => manageUserCallback(data.contactId)}>{data.roleName}</div>;
    }else if (data?.isEmployee === true && data?.userEmailActivation === false ) {
      return <div onClick={() => manageUserCallback(data.contactId)}>Inivited ({data.roleName})</div>;
    }
     else if(isAllowed(moduleConstants.EU)) {
      return  <a onClick={() => manageUserCallback(data.contactId)}>Invite</a>;
     }
    else return <div>-</div>;
  };

  const columns = useMemo(
    () => [
      {
        Header: headerWithSort('Name', 'contactFullName'),
        accessor: 'contactFullName',
        width: 100,
        disableSortBy: true,
        Cell: (props) => cellFullName(props.row.original),
      },
      {
        Header: headerWithSort('Title', 'title'),
        accessor: 'title',
        width: 100,
        disableSortBy: true,
        Cell: ({value}) => <div>{value?.length>0?value:''}</div>,
      },
      
      {
        Header: headerWithSort('Email', 'emailArr'),
        accessor: 'emailArr',
        width: 100,
        disableSortBy: true,
        Cell: (props) => cellEmail(props.row.original),
      },
      {
        Header: headerWithSort('Phone', 'phoneArr'),
        accessor: 'phoneArr',
        width: 100,
        disableSortBy: true,
        Cell: (props) => cellPhone(props.row.original),
      },
      {
        Header: headerWithSort('Access', 'isEmployee&userEmailActivation'),
        accessor: 'isEmployee&userEmailActivation',
        width: 30,
        disableSortBy: true,
        Cell: (props) => cellAccess(props.row.original),
      },
      {
        Header: headerWithSort('User Type', 'roleName'),
        accessor: 'roleName',
        width: 100,
        disableSortBy: true,
        Cell: (props) => cellRole(props.row.original),
      },
    ],
    [sortingState]
  );

  return (
    <CustomTable
      columns={columns}
      list={employees}
      inlineEdit={false}
      canShowExport={false}
      canShowPagination={false}
      canShowGlobalFilter={false}
      canShowColumnSettings={false}
      canShowDateFilter={false}
      canShowRowSelection={false}
      isLoading={loading}
      useControlledState={(state) => ({ ...state, ...rowSelection, ...sortingState })}
    />
  );
};

const EmployeeList = (props) => {
  
  const [redirect, setRedirect] = useState(false);
  const [showFormerEmployees, setShowFormerEmployees] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  const location = useLocation();

  const [addContactModalOpened, setAddContactModalOpened] = useState(location?.state?.openAddContactModal??false);
  const [employeeModalOpened, setEmployeeModalOpened] = useState(false);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState(null);

  const dispatch = useDispatch();
  const accountId = localStorage.getItem('accountId');
  const { data: { employees } } = useSelector(selectGetEmployees);
  const {
    data: { total },
  } = useSelector(selectGetEmployeesTotal);

  const paginationState = usePagination({ total });

  const { offset, limit, setPage } = paginationState;

  const sortingState = useSorting();

  const { sortingField, sortingOrder } = sortingState;

  const rowSelection = useBasicRowSelection({ limit, data: employees, keyField: '_id' });

  const { getSelectedKeys } = rowSelection;

  const preparePreferences = () => {
    const preferences = {
      pagination: { offset, limit },
      active: !showFormerEmployees,
      filter: searchValue,
    };

    let sorting = null;

    switch (sortingOrder) {
      case 'asc':
        sorting = 1;
        break;
      case 'desc':
        sorting = -1;
        break;
      default:
        sorting = null;
        break;
    }

    if (sorting !== null) {
      preferences.sort = {};

      const keysOfSorting = sortingField.split('&');

      keysOfSorting.forEach((key) => {
        preferences.sort[key] = sorting;
      });
    }

    return preferences;
  };

  const requestEmployees = () => {
    const preferences = preparePreferences();

    dispatch(getEmployees(preferences));
  };

  const requestEmployeesTotal = () =>
    dispatch(getEmployeesTotal({ active: !showFormerEmployees, filter: searchValue }));

  useEffect(() => {
    requestEmployees();
  }, [limit, offset, sortingField, sortingOrder, searchValue, showFormerEmployees]);

  useEffect(() => {
    requestEmployeesTotal();
  }, [searchValue, showFormerEmployees]);

  useEffect(() => {
    setPage(1);
  }, [searchValue, limit, showFormerEmployees]);

  useEffect(() => {
    if (!employees?.length) return;

    setSelectedRows(getSelectedKeys());
  }, [getSelectedKeys]);

  const handleDeleteButtonClick = () => {
    const deleteResponse = window.confirm('Do you really want to delete the selected record?');

    if (deleteResponse) {
      const employeesToDelete = selectedRows.map((id) => ({
        objectId: id,
      }));

      const data = {
        accountId,
        dataArr: employeesToDelete,
      };

      dispatch(deleteContactAction(data));
      requestEmployeesTotal();
      requestEmployees();
    }
  };

  const handleAddEmployeesModalSubmit = (affiliations) => {
    affiliations?.[0]?.contactId &&
      props.history.push(`/contact-details/${affiliations[0].contactId}`);
  };

  const { t } = props;

  return (
    <div className='ContactListReport'>
      <TitleComponent data={{ title: 'Users' }} />
      <Suspense fallback='loading'>
        <Header props={props} />
      </Suspense>
      <div className='contactBox'>
        <div className='container'>
          <div className='row'>
            <div>
              <div className='container'>
                <div id='content' className='tab-content' role='tablist'>
                  <div id='pane-1' className='pane' role='tabpanel' aria-labelledby='tab-1'>
                    <div id='collapse-1' className='' role='tabpanel' aria-labelledby='heading-1'>
                      <div className='cardBody'>
                        <div className='d-flex'>
                          <div className='p-2'>
                            <h2 className='heading2'>Employees</h2>
                          </div>
                          <div className='ms-auto'></div>

                          {isAllowed(moduleConstants.EE) && (
                            <>
                              <div className='p-2 no-print'>
                                <button
                                  className='btn btn-primary'
                                  style={{
                                    // temporary fix for the delete button
                                    cursor: 'pointer', padding: '5px', display: 'none'
                                  }}
                                  disabled={selectedRows?.length < 1}
                                  onClick={() => handleDeleteButtonClick(selectedRows)}
                                >
                                  <i className='fa fa-trash' aria-hidden='true'></i>&nbsp;Delete
                                </button>
                              </div>
                              <div className='p-2'>
                                <button
                                  className='btn btn-primary'
                                  onClick={() =>
                                    setShowFormerEmployees(
                                      (showFormerEmployees) => !showFormerEmployees
                                    )
                                  }
                                >
                                  {`Show ${showFormerEmployees ? 'Current' : 'Former'} Employees`}
                                </button>
                              </div>
                              <div className='p-2'>
                                <button
                                  className='btn btn-primary addContactBtn'
                                  onClick={() => setAddContactModalOpened(true)}
                                >
                                  Add Employee
                                </button>
                              </div>
                            </>
                          )}
                          { (
                            <div className='p-2 detailMenu'>
                              <li className=''>
                                <a
                                  className='dropdown-toggle btn'
                                  data-bs-toggle='dropdown'
                                  href='#'
                                  role='button'
                                  aria-expanded='false'
                                >
                                  <i className='fa fa-ellipsis-v' aria-hidden='true'></i>
                                </a>
                                <ul className='dropdown-menu dropdown-menu-end'>
                                  {isAllowed(reportAccess.ER, 'anyAssigned') && (
                                    <li className='dropdown-item nav-item'>
                                      <Link
                                        className=''
                                        to={{
                                          pathname: `/employee-roster-report`,
                                        }}
                                        data-tooltip-content={t('MENU7.SUBMENUS.SUBMENU3.Tooltip')}
                                        data-tooltip-id='MENU7.SUBMENUS.SUBMENU3.Tooltip'
                                      >
                                        {t('Employee Roster')}
                                      </Link>
                                      <Tooltip
                                        id='MENU7.SUBMENUS.SUBMENU3.Tooltip'
                                        place='right'
                                        delayShow={1000}
                                      />
                                    </li>
                                  )}
                                </ul>
                              </li></div>)}
                        </div>
                        <div className='row'>
                          <div className='col-md-12 sortable-table'>
                            <SearchComponent onSearchSubmit={setSearchValue} />
                            <EmployeesTable
                              rowSelection={rowSelection}
                              sortingState={sortingState}
                              manageUserCallback={(id) => {
                                if(isAllowed(moduleConstants.EU)){
                                  setSelectedEmployeeId(id);
                                  setEmployeeModalOpened(true);
                                
                                }
                              }}
                            />
                            <RemotePagination {...paginationState} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />

      <AddContactModal
        open={addContactModalOpened}
        contact={{ defaultAccess: false }}
        onClose={() => {
          setAddContactModalOpened(false);
          setRedirect((redirect) => !redirect);
        }}
        onSubmit={(affiliations) => {
          requestEmployeesTotal();
          requestEmployees();
          handleAddEmployeesModalSubmit(affiliations);
        }}
        makeEmployee
      />

      
      <ManageEmployeeModal
          contactId={selectedEmployeeId}
          open={employeeModalOpened}
          onClose={() => setEmployeeModalOpened(false)}
          onSubmit={() =>
            requestEmployees()
          }
        />
    </div>
  );
};

export default withTranslation()(EmployeeList);
