import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useMatchingContactsContext } from '../../forms/affiliation-form/matching-contacts-context';
import { useMemo } from 'react';
import FormattedPhone from '../../shared/formatted-phone';

type ConfirmMatchingContactsModalProps = {
  open: boolean;
  onClose?: () => void;
  onConfirm?: () => void;
};

export const ConfirmMatchingContactsModal = ({
  open,
  onClose = () => void 0,
  onConfirm = () => void 0,
}: ConfirmMatchingContactsModalProps) => {
  const matchingContactsContext = useMatchingContactsContext();
  const { groupedValidationData } = matchingContactsContext;

  const list = useMemo(
    () =>
      groupedValidationData.map((element) => {
        let header: any = null;
        let key: any = null;

        if (element.type === 'phone') {
          header = <FormattedPhone phoneObject={element.value} />;
          key = element.value.phoneClean;
        }

        if (element.type === 'email') {
          header = element.value.email;
          key = element.value.email;
        }

        const subList = element.data.map((contact) => {
          return (
            <div className='row ' key={contact._id}>
              <div className='col-4'>
                <a target='_blank' rel='noreferrer' href={`/contact-details/${contact.contactId}`}>
                  {contact.contactFullName}
                </a>
              </div>
              <div className='col-4'>
                {!contact.selfAffiliated ? (
                  <a
                    target='_blank'
                    rel='noreferrer'
                    href={`/company-details/${contact.companyId}`}
                  >
                    {contact.companyName}
                  </a>
                ) : (
                  '(Personal)'
                )}
              </div>
              <div className='col-4'>{contact.label}</div>
            </div>
          );
        });

        return (
          <div className='mb-1 d-flex flex-column gap-2' key={key}>
            <div className='row'>
              <div className='col-12 h5 fw-bold'>{header}</div>
            </div>

            <div className='row'>
              <div className='col-4 fw-bold'>Name</div>
              <div className='col-4 fw-bold'>Company</div>
              <div className='col-4 fw-bold'>Label</div>
            </div>

            {subList}
          </div>
        );
      }),
    [groupedValidationData]
  );

  return (
    <Modal isOpen={open} toggle={onClose}>
      <ModalHeader toggle={onClose}>Duplicate Contact Info Warning</ModalHeader>
      <ModalBody>
      <div>Other contacts with duplicate information used by this contact have been detected, please confirm that that matching contact information is valid.</div>
      <hr/>
        <div className='container'>{list}</div>
        
       </ModalBody>
      <ModalFooter>
        <button className='btn btn-secondary' onClick={onClose}>
          Cancel
        </button>
        <button className='btn btn-primary' onClick={onConfirm}>
          Save Anyway
        </button>
      </ModalFooter>
    </Modal>
  );
};
