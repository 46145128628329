import * as actionTypes from './actionTypes';
import * as API from '../api/reports';
//get all user details

/* Start Get Bid History Reports*/
export const GetBidHistoryReportRequest = () => ({
  type: actionTypes.GET_BID_HISTORY_REPORT_REQUEST,
});

export const GetBidHistoryReportSuccess = (response) => ({
  type: actionTypes.GET_BID_HISTORY_REPORT_SUCCESS,
  payload: {
    response,
  },
});

export const GetBidHistoryReportError = (error) => ({
  type: actionTypes.GET_BID_HISTORY_REPORT_ERROR,
  payload: {
    error,
  },
});

export const GetBidHistoryReportAction = (data) => {
  return (dispatch) => {
    dispatch(GetBidHistoryReportRequest());
    return API.getBidHistoryReport(data)
      .then((response) => {
        dispatch(GetBidHistoryReportSuccess(response));
      })
      .catch((error) => {
        dispatch(
          GetBidHistoryReportError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

/* Start Get Bid Due Reports*/
export const GetBidDueReportRequest = () => ({
  type: actionTypes.GET_BID_DUE_REPORT_REQUEST,
});

export const GetBidDueReportSuccess = (response) => ({
  type: actionTypes.GET_BID_DUE_REPORT_SUCCESS,
  payload: {
    response,
  },
});

export const GetBidDueReportError = (error) => ({
  type: actionTypes.GET_BID_DUE_REPORT_ERROR,
  payload: {
    error,
  },
});

export const GetBidDueReportAction = (data) => {
  return (dispatch) => {
    dispatch(GetBidDueReportRequest());
    return API.getBidDueReport(data)
      .then((response) => {
        dispatch(GetBidDueReportSuccess(response));
      })
      .catch((error) => {
        dispatch(
          GetBidDueReportError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

export const GetBidDueReportPrefRequest = () => ({
  type: actionTypes.GET_BID_DUE_PREF_REQUEST,
});

export const GetBidDueReportPrefSuccess = (preferences) => ({
  type: actionTypes.GET_BID_DUE_PREF_SUCCESS,
  payload: {
    preferences,
  },
});

export const GetBidDueReportPrefAction = (preferences) => {
  return (dispatch) => {
    dispatch(GetBidDueReportPrefRequest());
    const userId = localStorage.getItem('userId');
    const persistedData = localStorage.getItem('bidsDuePref'+userId) || localStorage.getItem('bidsDuePref') || '{}';
    const parsedData = JSON.parse(persistedData);
    dispatch(GetBidDueReportPrefSuccess(parsedData));
  }
}

export const SetBidDueReportPrefAction = (preferences) => {
  return (dispatch) => {
    const userId = localStorage.getItem('userId');
    const data = JSON.stringify(preferences);
    localStorage.setItem('bidsDuePref'+userId, data);
    return dispatch(GetBidDueReportPrefSuccess(preferences));
  }
}


export const GetBidHistoryReportPrefRequest = () => ({
  type: actionTypes.GET_BID_HISTORY_PREF_REQUEST,
});

export const GetBidHistoryReportPrefSuccess = (preferences) => ({
  type: actionTypes.GET_BID_HISTORY_PREF_SUCCESS,
  payload: {
    preferences,
  },
});

export const GetBidHistoryReportPrefAction = (preferences) => {
  return (dispatch) => {
    dispatch(GetBidHistoryReportPrefRequest());
    const userId = localStorage.getItem('userId');
    const persistedData = localStorage.getItem('bidHistoryPref'+userId) || localStorage.getItem('bidHistoryPref') || '{}';
    const parsedData = JSON.parse(persistedData);
    dispatch(GetBidHistoryReportPrefSuccess(parsedData));
  }
}

export const SetBidHistoryReportPrefAction = (preferences) => {
  return (dispatch) => {
    const userId = localStorage.getItem('userId');
    const data = JSON.stringify(preferences);
    localStorage.setItem('bidHistoryPref'+userId, data);
    return dispatch(GetBidHistoryReportPrefSuccess(preferences));
  }
}

/* End Get Bid Due Reports*/
/* Start Get Lead Source Reports*/
export const GetLeadSourceReportRequest = () => ({
  type: actionTypes.GET_LEAD_SOURCE_REPORT_REQUEST,
});

export const GetLeadSourceReportSuccess = (response) => ({
  type: actionTypes.GET_LEAD_SOURCE_REPORT_SUCCESS,
  payload: {
    response,
  },
});

export const GetLeadSourceReportError = (error) => ({
  type: actionTypes.GET_LEAD_SOURCE_REPORT_ERROR,
  payload: {
    error,
  },
});

export const GetLeadSourceReportAction = (data) => {
  return (dispatch) => {
    dispatch(GetLeadSourceReportRequest());
    return API.getLeadSourceReport(data)
      .then((response) => {
        dispatch(GetLeadSourceReportSuccess(response));
      })
      .catch((error) => {
        dispatch(
          GetLeadSourceReportError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

export const GetLeadSourceReportPrefRequest = () => ({
  type: actionTypes.GET_LEAD_SOURCE_PREF_REQUEST,
});

export const GetLeadSourceReportPrefSuccess = (preferences) => ({
  type: actionTypes.GET_LEAD_SOURCE_PREF_SUCCESS,
  payload: {
    preferences,
  },
});

export const GetLeadSourceReportPrefAction = (preferences) => {
  return (dispatch) => {
    dispatch(GetLeadSourceReportPrefRequest());
    const userId = localStorage.getItem('userId');
    const persistedData = localStorage.getItem('leadSourceReportPref'+userId) || localStorage.getItem('leadSourceReportPref') || '{}';
    const parsedData = JSON.parse(persistedData);
    dispatch(GetLeadSourceReportPrefSuccess(parsedData));
  }
}

export const SetLeadSourceReportPrefAction = (preferences) => {
  return (dispatch) => {
    const userId = localStorage.getItem('userId');
    const data = JSON.stringify(preferences);
    localStorage.setItem('leadSourceReportPref'+userId, data);
    return dispatch(GetLeadSourceReportPrefSuccess(preferences));
  }
}

/* End Get Lead Source Reports*/
/* Start Get Project Lead Reports*/
export const GetLeadSummaryReportRequest = () => ({
  type: actionTypes.GET_PROJECT_LEAD_REPORT_REQUEST,
});

export const GetLeadSummaryReportSuccess = (response) => ({
  type: actionTypes.GET_PROJECT_LEAD_REPORT_SUCCESS,
  payload: {
    response,
  },
});

export const GetLeadSummaryReportError = (error) => ({
  type: actionTypes.GET_PROJECT_LEAD_REPORT_ERROR,
  payload: {
    error,
  },
});

export const GetLeadSummaryReportAction = (data) => {
  return (dispatch) => {
    dispatch(GetLeadSummaryReportRequest());
    return API.getLeadSummaryReport(data)
      .then((response) => {
        dispatch(GetLeadSummaryReportSuccess(response));
      })
      .catch((error) => {
        dispatch(
          GetLeadSummaryReportError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

export const GetLeadSummaryReportPrefRequest = () => ({
  type: actionTypes.GET_PROJECT_LEAD_PREF_REQUEST,
});

export const GetLeadSummaryReportPrefSuccess = (preferences) => ({
  type: actionTypes.GET_PROJECT_LEAD_PREF_SUCCESS,
  payload: {
    preferences,
  },
});

export const GetLeadSummaryReportPrefAction = (preferences) => {
  return (dispatch) => {
    dispatch(GetLeadSummaryReportPrefRequest());
    const userId = localStorage.getItem('userId');
    const persistedData = localStorage.getItem('projectLeadPref'+userId) || localStorage.getItem('projectLeadPref') || '{}';
    const parsedData = JSON.parse(persistedData);
    dispatch(GetLeadSummaryReportPrefSuccess(parsedData));
  }
}

export const SetLeadSummaryReportPrefAction = (preferences) => {
  return (dispatch) => {
    const userId = localStorage.getItem('userId');
    const data = JSON.stringify(preferences);
    localStorage.setItem('projectLeadPref'+userId, data);
    return dispatch(GetLeadSummaryReportPrefSuccess(preferences));
  }
}

export const GetProfitPerWeekReportRequest = () => ({
  type: actionTypes.GET_PROFIT_PER_WEEK_REPORT_REQUEST,
});

export const GetProfitPerWeekReportSuccess = (response) => ({
  type: actionTypes.GET_PROFIT_PER_WEEK__REPORT_SUCCESS,
  payload: {
    response,
  },
});

export const GetProfitPerWeekReportError = (error) => ({
  type: actionTypes.GET_PROFIT_PER_WEEK__REPORT_ERROR,
  payload: {
    error,
  },
});

export const GetProfitPerWeekReportAction = (data) => {
  return (dispatch) => {
    dispatch(GetProfitPerWeekReportRequest());
    return API.getProfitPerWeekReport(data)
      .then((response) => {
        dispatch(GetProfitPerWeekReportSuccess(response));
      })
      .catch((error) => {
        dispatch(
          GetProfitPerWeekReportError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

/* End Get Project Lead Reports*/

/* Start Bid pending report */
export const GetBidPendingReportRequest = () => ({
  type: actionTypes.GET_BID_PENDING_REPORT_REQUEST,
});

export const GetBidPendingReportSuccess = (response) => ({
  type: actionTypes.GET_BID_PENDING_REPORT_SUCCESS,
  payload: {
    response,
  },
});

export const GetBidPendingReportError = (error) => ({
  type: actionTypes.GET_BID_PENDING_REPORT_ERROR,
  payload: {
    error,
  },
});

export const GetBidPendingReportAction = (data) => {
  return (dispatch) => {
    dispatch(GetBidPendingReportRequest());
    return API.getBidPendingReport(data)
      .then((response) => {
        dispatch(GetBidPendingReportSuccess(response));
      })
      .catch((error) => {
        dispatch(
          GetBidPendingReportError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

export const GetBidPendingReportPrefRequest = () => ({
  type: actionTypes.GET_BID_PENDING_PREF_REQUEST,
});

export const GetBidPendingReportPrefSuccess = (preferences) => ({
  type: actionTypes.GET_BID_PENDING_PREF_SUCCESS,
  payload: {
    preferences,
  },
});

export const GetBidPendingReportPrefAction = (preferences) => {
  return (dispatch) => {
    dispatch(GetBidPendingReportPrefRequest());
    const userId = localStorage.getItem('userId');
    const persistedData = localStorage.getItem('bidsPendingPref'+userId) || localStorage.getItem('bidsPendingPref') || '{}';
    const parsedData = JSON.parse(persistedData);
    dispatch(GetBidPendingReportPrefSuccess(parsedData));
  }
}

export const SetBidPendingReportPrefAction = (preferences) => {
  return (dispatch) => {
    const userId = localStorage.getItem('userId');
    const data = JSON.stringify(preferences);
    localStorage.setItem('bidsPendingPref'+userId, data);
    return dispatch(GetBidPendingReportPrefSuccess(preferences));
  }
}

/* End Get Bid pending report*/

/* Start employee roster report */
export const GetEmployeeRosterReportRequest = () => ({
  type: actionTypes.GET_EMP_ROSTER_REPORT_REQUEST,
});

export const GetEmployeeRosterReportSuccess = (response) => ({
  type: actionTypes.GET_EMP_ROSTER_REPORT_SUCCESS,
  payload: {
    response,
  },
});

export const GetEmployeeRosterReportError = (error) => ({
  type: actionTypes.GET_EMP_ROSTER_REPORT_ERROR,
  payload: {
    error,
  },
});

export const GetEmployeeRosterReportAction = (data) => {
  return (dispatch) => {
    dispatch(GetEmployeeRosterReportRequest());
    return API.getEmployeeRosterReport(data)
      .then((response) => {
        dispatch(GetEmployeeRosterReportSuccess(response));
      })
      .catch((error) => {
        dispatch(
          GetEmployeeRosterReportError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

export const GetEmployeeRosterReportPrefRequest = () => ({
  type: actionTypes.GET_EMP_ROSTER_PREF_REQUEST,
});

export const GetEmployeeRosterReportPrefSuccess = (preferences) => ({
  type: actionTypes.GET_EMP_ROSTER_PREF_SUCCESS,
  payload: {
    preferences,
  },
});

export const GetEmployeeRosterReportPrefAction = (preferences) => {
  return (dispatch) => {
    dispatch(GetEmployeeRosterReportPrefRequest());
    const userId = localStorage.getItem('userId');
    const persistedData = localStorage.getItem('employeeRosterPref'+userId) || localStorage.getItem('employeeRosterPref') || '{}';
    const parsedData = JSON.parse(persistedData);
    dispatch(GetEmployeeRosterReportPrefSuccess(parsedData));
  }
}

export const SetEmployeeRosterReportPrefAction = (preferences) => {
  return (dispatch) => {
    const userId = localStorage.getItem('userId');
    const data = JSON.stringify(preferences);
    localStorage.setItem('employeeRosterPref'+userId, data);
    return dispatch(GetEmployeeRosterReportPrefSuccess(preferences));
  }
}

/* End Get employee roster report*/

/* Start active project report */
export const GetActiveProjectReportRequest = () => ({
  type: actionTypes.GET_ACTIVE_PROJECT_REPORT_REQUEST,
});

export const GetActiveProjectReportSuccess = (response) => ({
  type: actionTypes.GET_ACTIVE_PROJECT_REPORT_SUCCESS,
  payload: {
    response,
  },
});

export const GetActiveProjectReportError = (error) => ({
  type: actionTypes.GET_ACTIVE_PROJECT_REPORT_ERROR,
  payload: {
    error,
  },
});

export const GetActiveProjectReportAction = (data) => {
  return (dispatch) => {
    dispatch(GetActiveProjectReportRequest());
    return API.getActiveProjectReport(data)
      .then((response) => {
        dispatch(GetActiveProjectReportSuccess(response));
      })
      .catch((error) => {
        dispatch(
          GetActiveProjectReportError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

export const GetActiveProjectReportPrefRequest = () => ({
  type: actionTypes.GET_ACTIVE_PROJECT_PREF_REQUEST,
});

export const GetActiveProjectReportPrefSuccess = (preferences) => ({
  type: actionTypes.GET_ACTIVE_PROJECT_PREF_SUCCESS,
  payload: {
    preferences,
  },
});

export const GetActiveProjectReportPrefAction = (preferences) => {
  return (dispatch) => {
    dispatch(GetActiveProjectReportPrefRequest());
    const userId = localStorage.getItem('userId');
    const persistedData = localStorage.getItem('activeProjectPref'+userId) || localStorage.getItem('activeProjectPref') || '{}';
    const parsedData = JSON.parse(persistedData);
    dispatch(GetActiveProjectReportPrefSuccess(parsedData));
  }
}

export const SetActiveProjectReportPrefAction = (preferences) => {
  return (dispatch) => {
    const userId = localStorage.getItem('userId');
    const data = JSON.stringify(preferences);
    localStorage.setItem('activeProjectPref'+userId, data);
    return dispatch(GetActiveProjectReportPrefSuccess(preferences));
  }
}

/* End Get active project report*/

/* Start address label report */
export const GetAddressLabelReportRequest = () => ({
  type: actionTypes.GET_ADDRESS_LABEL_REPORT_REQUEST,
});

export const GetAddressLabelReportSuccess = (response) => ({
  type: actionTypes.GET_ADDRESS_LABEL_REPORT_SUCCESS,
  payload: {
    response,
  },
});

export const GetAddressLabelReportError = (error) => ({
  type: actionTypes.GET_ADDRESS_LABEL_REPORT_ERROR,
  payload: {
    error,
  },
});

export const GetAddressLabelReportAction = (data) => {
  return (dispatch) => {
    dispatch(GetAddressLabelReportRequest());
    return API.getAddressLabelReport(data)
      .then((response) => {
        dispatch(GetAddressLabelReportSuccess(response));
      })
      .catch((error) => {
        dispatch(
          GetAddressLabelReportError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

export const GetAddressLabelReportPrefRequest = () => ({
  type: actionTypes.GET_ADDRESS_LABEL_PREF_REQUEST,
});

export const GetAddressLabelReportPrefSuccess = (preferences) => ({
  type: actionTypes.GET_ADDRESS_LABEL_PREF_SUCCESS,
  payload: {
    preferences,
  },
});

export const GetAddressLabelReportPrefAction = (preferences) => {
  return (dispatch) => {
    dispatch(GetAddressLabelReportPrefRequest());
    const userId = localStorage.getItem('userId');
    const persistedData = localStorage.getItem('addressLabelListPref'+userId) || localStorage.getItem('addressLabelListPref') || '{}';
    const parsedData = JSON.parse(persistedData);
    dispatch(GetAddressLabelReportPrefSuccess(parsedData));
  }
}

export const SetAddressLabelReportPrefAction = (preferences) => {
  return (dispatch) => {
    const userId = localStorage.getItem('userId');
    const data = JSON.stringify(preferences);
    localStorage.setItem('addressLabelListPref'+userId, data);;
    return dispatch(GetAddressLabelReportPrefSuccess(preferences));
  }
}

/* End Get address label report*/

/* Start contact list report */
export const GetContactListReportRequest = () => ({
  type: actionTypes.GET_CONTACT_LIST_REPORT_REQUEST,
});

export const GetContactListReportSuccess = (response) => ({
  type: actionTypes.GET_CONTACT_LIST_REPORT_SUCCESS,
  payload: {
    response,
  },
});

export const GetContactListReportError = (error) => ({
  type: actionTypes.GET_CONTACT_LIST_REPORT_ERROR,
  payload: {
    error,
  },
});

export const GetContactListInfoReportAction = (data) => {
  return (dispatch) => {
    dispatch(GetContactListReportRequest());
    return API.getContactListReport(data)
      .then((response) => {
        dispatch(GetContactListReportSuccess(response));
      })
      .catch((error) => {
        dispatch(
          GetContactListReportError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

export const GetContactListReportPrefRequest = () => ({
  type: actionTypes.GET_CONTACT_LIST_PREF_REQUEST,
});

export const GetContactListReportPrefSuccess = (preferences) => ({
  type: actionTypes.GET_CONTACT_LIST_PREF_SUCCESS,
  payload: {
    preferences,
  },
});

export const GetContactListInfoReportPrefAction = (preferences) => {
  return (dispatch) => {
    dispatch(GetContactListReportPrefRequest());
    const userId = localStorage.getItem('userId');
    const persistedData = localStorage.getItem('contactListPref'+userId) || localStorage.getItem('contactListPref') || '{}';
    const parsedData = JSON.parse(persistedData);
    dispatch(GetContactListReportPrefSuccess(parsedData));
  }
}

export const SetContactListInfoReportPrefAction = (preferences) => {
  return (dispatch) => {
    const userId = localStorage.getItem('userId');
    const data = JSON.stringify(preferences);
    localStorage.setItem('contactListPref'+userId, data);;
    return dispatch(GetContactListReportPrefSuccess(preferences));
  }
}

/* End Get contact list report*/

/* Start contact list report */
export const GetCustomizeProjectListReportRequest = () => ({
  type: actionTypes.GET_CUSTOMIZE_PROJECT_LIST_REPORT_REQUEST,
});

export const GetCustomizeProjectListReportSuccess = (response) => ({
  type: actionTypes.GET_CUSTOMIZE_PROJECT_LIST_REPORT_SUCCESS,
  payload: {
    response,
  },
});

export const GetCustomizeProjectListReportError = (error) => ({
  type: actionTypes.GET_CUSTOMIZE_PROJECT_LIST_REPORT_ERROR,
  payload: {
    error,
  },
});

export const GetCustomizeProjectListReportAction = (data) => {
  return (dispatch) => {
    dispatch(GetCustomizeProjectListReportRequest());
    return API.getCustomizeProjectListReport(data)
      .then((response) => {
        dispatch(GetCustomizeProjectListReportSuccess(response));
      })
      .catch((error) => {
        dispatch(
          GetCustomizeProjectListReportError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

export const GetCustomizeProjectListReportPrefRequest = () => ({
  type: actionTypes.GET_CUSTOMIZE_PROJECT_LIST_PREF_REQUEST,
});

export const GetCustomizeProjectListReportPrefSuccess = (preferences) => ({
  type: actionTypes.GET_CUSTOMIZE_PROJECT_LIST_PREF_SUCCESS,
  payload: {
    preferences,
  },
});

export const GetCustomizeProjectListInfoReportPrefAction = (preferences) => {
  return (dispatch) => {
    dispatch(GetCustomizeProjectListReportPrefRequest());
    const userId = localStorage.getItem('userId');
    const persistedData = localStorage.getItem('customizeProjectPref'+userId) || localStorage.getItem('customizeProjectPref') || '{}';
    const parsedData = JSON.parse(persistedData);
    dispatch(GetCustomizeProjectListReportPrefSuccess(parsedData));
  }
}

export const SetCustomizeProjectListInfoReportPrefAction = (preferences) => {
  return (dispatch) => {
    const userId = localStorage.getItem('userId');
    const data = JSON.stringify(preferences);
    localStorage.setItem('customizeProjectPref'+userId, data);;
    return dispatch(GetCustomizeProjectListReportPrefSuccess(preferences));
  }
}

/* End Get contact list report*/

/* Start Client list report */
export const GetClientListReportRequest = () => ({
  type: actionTypes.GET_CLIENT_LIST_REPORT_REQUEST,
});

export const GetClientListReportSuccess = (response) => ({
  type: actionTypes.GET_CLIENT_LIST_REPORT_SUCCESS,
  payload: {
    response,
  },
});

export const GetClientListReportError = (error) => ({
  type: actionTypes.GET_CLIENT_LIST_REPORT_ERROR,
  payload: {
    error,
  },
});

export const GetClientListReportAction = (data) => {
  return (dispatch) => {
    dispatch(GetClientListReportRequest());
    return API.getClientListReport(data)
      .then((response) => {
        dispatch(GetClientListReportSuccess(response));
      })
      .catch((error) => {
        dispatch(
          GetClientListReportError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

/* End Get Client list report*/

/* Start Company list report */
export const GetCompanyListReportRequest = () => ({
  type: actionTypes.GET_COMPANY_LIST_REPORT_REQUEST,
});

export const GetCompanyListReportSuccess = (response) => ({
  type: actionTypes.GET_COMPANY_LIST_REPORT_SUCCESS,
  payload: {
    response,
  },
});

export const GetCompanyListReportError = (error) => ({
  type: actionTypes.GET_COMPANY_LIST_REPORT_ERROR,
  payload: {
    error,
  },
});

export const GetCompanyListReportAction = (data) => {
  return (dispatch) => {
    dispatch(GetCompanyListReportRequest());
    return API.getCompanyListReport(data)
      .then((response) => {
        dispatch(GetCompanyListReportSuccess(response));
      })
      .catch((error) => {
        dispatch(
          GetCompanyListReportError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

/* Start Contact Job List */
export const GetContactJobListReportRequest = () => ({
  type: actionTypes.GET_CONTACT_JOB_LIST_REPORT_REQUEST,
});

export const GetContactJobListReportSuccess = (response) => ({
  type: actionTypes.GET_CONTACT_JOB_LIST_REPORT_SUCCESS,
  payload: {
    response,
  },
});

export const GetContactJobListReportError = (error) => ({
  type: actionTypes.GET_CONTACT_JOB_LIST_REPORT_ERROR,
  payload: {
    error,
  },
});

export const GetContactJobListReportAction = (data) => {
  return (dispatch) => {
    dispatch(GetContactJobListReportRequest());
    return API.getContactJobListReport(data)
      .then((response) => {
        dispatch(GetContactJobListReportSuccess(response));
      })
      .catch((error) => {
        dispatch(
          GetContactJobListReportError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

export const GetContactJobListPrefRequest = () => ({
  type: actionTypes.GET_CONTACT_JOB_LIST_PREF_REQUEST,
});

export const GetContactJobListPrefSuccess = (preferences) => ({
  type: actionTypes.GET_CONTACT_JOB_LIST_PREF_SUCCESS,
  payload: {
    preferences,
  },
});

export const GetContactJobListPrefAction = () => {
  return (dispatch) => {
    dispatch(GetContactJobListPrefRequest());
    const userId = localStorage.getItem('userId');
    const persistedData = localStorage.getItem('contactJobListPref'+userId) || localStorage.getItem('contactJobListPref') || '{}';
    const parsedData = JSON.parse(persistedData);
    dispatch(GetContactJobListPrefSuccess(parsedData));
  }
}

export const SetContactJobListPrefAction = (preferences) => {
  return (dispatch) => {
    const userId = localStorage.getItem('userId');
    const data = JSON.stringify(preferences);
    localStorage.setItem('contactJobListPref'+userId, data);
    return dispatch(GetContactJobListPrefSuccess(preferences));
  }
}


/* Start Company Job List */
export const GetCompanyJobListReportRequest = () => ({
  type: actionTypes.GET_COMPANY_JOB_LIST_REPORT_REQUEST,
});

export const GetCompanyJobListReportSuccess = (response) => ({
  type: actionTypes.GET_COMPANY_JOB_LIST_REPORT_SUCCESS,
  payload: {
    response,
  },
});

export const GetCompanyJobListReportError = (error) => ({
  type: actionTypes.GET_COMPANY_JOB_LIST_REPORT_ERROR,
  payload: {
    error,
  },
});

export const GetCompanyJobListReportAction = (data) => {
  return (dispatch) => {
    dispatch(GetCompanyJobListReportRequest());
    return API.getCompanyJobListReport(data)
      .then((response) => {
        dispatch(GetCompanyJobListReportSuccess(response));
      })
      .catch((error) => {
        dispatch(
          GetCompanyJobListReportError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

export const GetCompanyJobListPrefRequest = () => ({
  type: actionTypes.GET_COMPANY_JOB_LIST_PREF_REQUEST,
});

export const GetCompanyJobListPrefSuccess = (preferences) => ({
  type: actionTypes.GET_COMPANY_JOB_LIST_PREF_SUCCESS,
  payload: {
    preferences,
  },
});

export const GetCompanyJobListPrefAction = () => {
  return (dispatch) => {
    dispatch(GetCompanyJobListPrefRequest());
    const userId = localStorage.getItem('userId');
    const persistedData = localStorage.getItem('companyJobListPref'+userId) || localStorage.getItem('companyJobListPref') || '{}';
    const parsedData = JSON.parse(persistedData);
    dispatch(GetCompanyJobListPrefSuccess(parsedData));
  }
}

export const SetCompanyJobListPrefAction = (preferences) => {
  return (dispatch) => {
    const userId = localStorage.getItem('userId');
    const data = JSON.stringify(preferences);
    localStorage.setItem('companyJobListPref'+userId, data);;
    return dispatch(GetCompanyJobListPrefSuccess(preferences));
  }
}

/* company list view report*/

export const GetCompanyListViewPrefRequest = () => ({
  type: actionTypes.GET_COMPANY_LIST_VIEW_PREF_REQUEST,
});

export const GetCompanyListViewPrefSuccess = (preferences) => ({
  type: actionTypes.GET_COMPANY_LIST_VIEW_PREF_SUCCESS,
  payload: {
    preferences,
  },
});

export const GetCompanyListViewPrefAction = () => {
  return (dispatch) => {
    dispatch(GetCompanyListViewPrefRequest());
    const userId = localStorage.getItem('userId');
    const persistedData = localStorage.getItem('companyListViewPref'+userId) || localStorage.getItem('companyListViewPref') || '{}';
    const parsedData = JSON.parse(persistedData);
    dispatch(GetCompanyListViewPrefSuccess(parsedData));
  }
}

export const SetCompanyListViewPrefAction = (preferences) => {
  return (dispatch) => {
    const userId = localStorage.getItem('userId');
    const data = JSON.stringify(preferences);
    localStorage.setItem('companyListViewPref'+userId, data);;
    return dispatch(GetCompanyListViewPrefSuccess(preferences));
  }
}


/* Start Contact job DD list report */
export const GetContactJobDDRequest = () => ({
  type: actionTypes.GET_CONTACT_JOB_DD_LIST_REQUEST,
});

export const GetContactJobDDSuccess = (response) => ({
  type: actionTypes.GET_CONTACT_JOB_DD_LIST_SUCCESS,
  payload: {
    response,
  },
});

export const GetContactJobDDError = (error) => ({
  type: actionTypes.GET_CONTACT_JOB_DD_LIST_ERROR,
  payload: {
    error,
  },
});

export const GetContactJobDDAction = (data) => {
  return (dispatch) => {
    dispatch(GetContactJobDDRequest());
    return API.getContactJobDDList(data)
      .then((response) => {
        dispatch(GetContactJobDDSuccess(response));
      })
      .catch((error) => {
        dispatch(
          GetContactJobDDError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

/* Start Company list report */
export const GetNoBidReportRequest = () => ({
  type: actionTypes.GET_NO_BID_REPORT_REQUEST,
});

export const GetNoBidReportSuccess = (response) => ({
  type: actionTypes.GET_NO_BID_REPORT_SUCCESS,
  payload: {
    response,
  },
});

export const GetNoBidReportError = (error) => ({
  type: actionTypes.GET_NO_BID_REPORT_ERROR,
  payload: {
    error,
  },
});

export const GetNoBidReportAction = (data) => {
  return (dispatch) => {
    dispatch(GetNoBidReportRequest());
    return API.getNoBidReport(data)
      .then((response) => {
        dispatch(GetNoBidReportSuccess(response));
      })
      .catch((error) => {
        dispatch(
          GetNoBidReportError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

export const GetNoBidReportPrefRequest = () => ({
  type: actionTypes.GET_NO_BID_PREF_REQUEST,
});

export const GetNoBidReportPrefSuccess = (preferences) => ({
  type: actionTypes.GET_NO_BID_PREF_SUCCESS,
  payload: {
    preferences,
  },
});

export const GetNoBidReportPrefAction = (preferences) => {
  return (dispatch) => {
    dispatch(GetNoBidReportPrefRequest());
    const userId = localStorage.getItem('userId');
    const persistedData = localStorage.getItem('noBidReportPref'+userId) || localStorage.getItem('noBidReportPref') || '{}';
    const parsedData = JSON.parse(persistedData);
    dispatch(GetNoBidReportPrefSuccess(parsedData));
  }
}

export const SetNoBidReportPrefAction = (preferences) => {
  return (dispatch) => {
    const userId = localStorage.getItem('userId');
    const data = JSON.stringify(preferences);
    localStorage.setItem('noBidReportPref'+userId, data);
    return dispatch(GetNoBidReportPrefSuccess(preferences));
  }
}

/* End Get Company list report*/

/* Start Delivered Project List report */
export const GetDeliveredProjectReportRequest = () => ({
  type: actionTypes.GET_DELIVERED_REPORT_REQUEST,
});

export const GetDeliveredProjectReportSuccess = (response) => ({
  type: actionTypes.GET_DELIVERED_REPORT_SUCCESS,
  payload: {
    response,
  },
});

export const GetDeliveredProjectReportError = (error) => ({
  type: actionTypes.GET_DELIVERED_REPORT_ERROR,
  payload: {
    error,
  },
});

export const GetDeliveredProjectReportAction = (data) => {
  return (dispatch) => {
    dispatch(GetDeliveredProjectReportRequest());
    return API.getDeliveredProjectReport(data)
      .then((response) => {
        dispatch(GetDeliveredProjectReportSuccess(response));
      })
      .catch((error) => {
        dispatch(
          GetDeliveredProjectReportError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

export const GetDeliveredProjectReportPrefRequest = () => ({
  type: actionTypes.GET_DELIVERED_PREF_REQUEST,
});

export const GetDeliveredProjectReportPrefSuccess = (preferences) => ({
  type: actionTypes.GET_DELIVERED_PREF_SUCCESS,
  payload: {
    preferences,
  },
});

export const GetDeliveredProjectReportPrefAction = (preferences) => {
  return (dispatch) => {
    dispatch(GetDeliveredProjectReportPrefRequest());
    const userId = localStorage.getItem('userId');
    const persistedData = localStorage.getItem('deliveredProjectPref'+userId) || localStorage.getItem('deliveredProjectPref') || '{}';
    const parsedData = JSON.parse(persistedData);
    dispatch(GetDeliveredProjectReportPrefSuccess(parsedData));
  }
}

export const SetDeliveredProjectReportPrefAction = (preferences) => {
  return (dispatch) => {
    const userId = localStorage.getItem('userId');
    const data = JSON.stringify(preferences);
    localStorage.setItem('deliveredProjectPref'+userId, data);
    return dispatch(GetDeliveredProjectReportPrefSuccess(preferences));
  }
}


/* End Delivered Project List report*/

/* Start Delivered Project List report */
export const GetLienPeriodReportRequest = () => ({
  type: actionTypes.GET_LIEN_PERIOD_REPORT_REQUEST,
});

export const GetLienPeriodReportSuccess = (response) => ({
  type: actionTypes.GET_LIEN_PERIOD_REPORT_SUCCESS,
  payload: {
    response,
  },
});

export const GetLienPeriodReportError = (error) => ({
  type: actionTypes.GET_LIEN_PERIOD_REPORT_ERROR,
  payload: {
    error,
  },
});

export const GetLienPeriodReportAction = (data) => {
  return (dispatch) => {
    dispatch(GetLienPeriodReportRequest());
    return API.getLienPeriodReport(data)
      .then((response) => {
        dispatch(GetLienPeriodReportSuccess(response));
      })
      .catch((error) => {
        dispatch(
          GetLienPeriodReportError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};


export const GetLienPeriodReportPrefRequest = () => ({
  type: actionTypes.GET_LIEN_PERIOD_PREF_REQUEST,
});

export const GetLienPeriodReportPrefSuccess = (preferences) => ({
  type: actionTypes.GET_LIEN_PERIOD_PREF_SUCCESS,
  payload: {
    preferences,
  },
});

export const GetLienPeriodReportPrefAction = (preferences) => {
  return (dispatch) => {
    dispatch(GetLienPeriodReportPrefRequest());
    const userId = localStorage.getItem('userId');
    const persistedData = localStorage.getItem('lienPeriodPref'+userId) || localStorage.getItem('lienPeriodPref') || '{}';
    const parsedData = JSON.parse(persistedData);
    dispatch(GetLienPeriodReportPrefSuccess(parsedData));
  }
}

export const SetLienPeriodReportPrefAction = (preferences) => {
  return (dispatch) => {
    const userId = localStorage.getItem('userId');
    const data = JSON.stringify(preferences);
    localStorage.setItem('lienPeriodPref'+userId, data);
    return dispatch(GetLienPeriodReportPrefSuccess(preferences));
  }
}

/* Start Warranty status List report */
export const GetWarrantyPeriodReportRequest = () => ({
  type: actionTypes.GET_WARRANTY_STATUS_REPORT_REQUEST,
});

export const GetWarrantyPeriodReportSuccess = (response) => ({
  type: actionTypes.GET_WARRANTY_STATUS_REPORT_SUCCESS,
  payload: {
    response,
  },
});

export const GetWarrantyPeriodReportError = (error) => ({
  type: actionTypes.GET_WARRANTY_STATUS_REPORT_ERROR,
  payload: {
    error,
  },
});

export const GetWarrantyPeriodReportAction = (data) => {
  return (dispatch) => {
    dispatch(GetWarrantyPeriodReportRequest());
    return API.getWarrantyStatusReport(data)
      .then((response) => {
        dispatch(GetWarrantyPeriodReportSuccess(response));
      })
      .catch((error) => {
        dispatch(
          GetWarrantyPeriodReportError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

export const GetWarrantyPeriodReportPrefRequest = () => ({
  type: actionTypes.GET_WARRANTY_STATUS_PREF_REQUEST,
});

export const GetWarrantyPeriodReportPrefSuccess = (preferences) => ({
  type: actionTypes.GET_WARRANTY_STATUS_PREF_SUCCESS,
  payload: {
    preferences,
  },
});

export const GetWarrantyPeriodReportPrefAction = (preferences) => {
  return (dispatch) => {
    dispatch(GetWarrantyPeriodReportPrefRequest());
    const userId = localStorage.getItem('userId');
    const persistedData = localStorage.getItem('warrantyPeriodPref'+userId) || localStorage.getItem('warrantyPeriodPref') || '{}';
    const parsedData = JSON.parse(persistedData);
    dispatch(GetWarrantyPeriodReportPrefSuccess(parsedData));
  }
}

export const SetWarrantyPeriodReportPrefAction = (preferences) => {
  return (dispatch) => {
    const userId = localStorage.getItem('userId');
    const data = JSON.stringify(preferences);
    localStorage.setItem('warrantyPeriodPref'+userId, data);
    return dispatch(GetWarrantyPeriodReportPrefSuccess(preferences));
  }
}

/* Start Follow up project List report */
export const GetFollowUpProjectReportRequest = () => ({
  type: actionTypes.GET_FOLLOW_UP_PROJECT_REPORT_REQUEST,
});

export const GetFollowUpProjectReportSuccess = (response) => ({
  type: actionTypes.GET_FOLLOW_UP_PROJECT_REPORT_SUCCESS,
  payload: {
    response,
  },
});

export const GetFollowUpProjectReportError = (error) => ({
  type: actionTypes.GET_FOLLOW_UP_PROJECT_REPORT_ERROR,
  payload: {
    error,
  },
});

export const GetFollowUpProjectReportAction = (data) => {
  return (dispatch) => {
    dispatch(GetFollowUpProjectReportRequest());
    return API.getFollowUpProjectReport(data)
      .then((response) => {
        dispatch(GetFollowUpProjectReportSuccess(response));
      })
      .catch((error) => {
        dispatch(
          GetFollowUpProjectReportError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};


export const GetFollowUpProjectReportPrefRequest = () => ({
  type: actionTypes.GET_FOLLOW_UP_PROJECT_PREF_REQUEST,
});

export const GetFollowUpProjectReportPrefSuccess = (preferences) => ({
  type: actionTypes.GET_FOLLOW_UP_PROJECT_PREF_SUCCESS,
  payload: {
    preferences,
  },
});

export const GetFollowUpProjectReportPrefAction = (preferences) => {
  return (dispatch) => {
    dispatch(GetFollowUpProjectReportPrefRequest());
    const userId = localStorage.getItem('userId');
    const persistedData = localStorage.getItem('followUpProjectPref'+userId) || localStorage.getItem('followUpProjectPref') || '{}';
    const parsedData = JSON.parse(persistedData);
    dispatch(GetFollowUpProjectReportPrefSuccess(parsedData));
  }
}

export const SetFollowUpProjectReportPrefAction = (preferences) => {
  return (dispatch) => {
    const userId = localStorage.getItem('userId');
    const data = JSON.stringify(preferences);
    localStorage.setItem('followUpProjectPref'+userId, data);
    return dispatch(GetFollowUpProjectReportPrefSuccess(preferences));
  }
}

/* End Follow up project List report*/

/* Start Lost Job List report */
export const GetLostJobReportRequest = () => ({
  type: actionTypes.GET_LOST_JOB_REPORT_REQUEST,
});

export const GetLostJobReportSuccess = (response) => ({
  type: actionTypes.GET_LOST_JOB_REPORT_SUCCESS,
  payload: {
    response,
  },
});

export const GetLostJobReportError = (error) => ({
  type: actionTypes.GET_LOST_JOB_REPORT_ERROR,
  payload: {
    error,
  },
});

export const GetLostJobReportAction = (data) => {
  return (dispatch) => {
    dispatch(GetLostJobReportRequest());
    return API.getLostJobReport(data)
      .then((response) => {
        dispatch(GetLostJobReportSuccess(response));
      })
      .catch((error) => {
        dispatch(
          GetLostJobReportError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

export const GetLostJobPrefRequest = () => ({
  type: actionTypes.GET_LOST_JOB_PREF_REQUEST,
});

export const GetLostJobPrefSuccess = (preferences) => ({
  type: actionTypes.GET_LOST_JOB_PREF_SUCCESS,
  payload: {
    preferences,
  },
});

export const GetLostJobPrefAction = (preferences) => {
  return (dispatch) => {
    dispatch(GetLostJobPrefRequest());
    const userId = localStorage.getItem('userId');
    const persistedData = localStorage.getItem('lostJobPref'+userId) || localStorage.getItem('lostJobPref') || '{}';
    const parsedData = JSON.parse(persistedData);
    dispatch(GetLostJobPrefSuccess(parsedData));
  }
}

export const SetLostJobPrefAction = (preferences) => {
  return (dispatch) => {
    const userId = localStorage.getItem('userId');
    const data = JSON.stringify(preferences);
    localStorage.setItem('lostJobPref'+userId, data);
    return dispatch(GetLostJobPrefSuccess(preferences));
  }
}

// SetCompanyJobListReportPrefAction, GetCompanyJobListReportPrefAction

/* End  Lost Job List report*/

/* Start Profit Projection report */
export const GetProfitProjectionReportRequest = () => ({
  type: actionTypes.GET_PROFIT_PROJECTION_REPORT_REQUEST,
});

export const GetProfitProjectionReportRequestForecast = () => ({
  type: actionTypes.GET_PROFIT_PROJECTION_FORECAST_REPORT_REQUEST,
});

export const GetProfitProjectionReportSuccess = (response) => ({
  type: actionTypes.GET_PROFIT_PROJECTION_REPORT_SUCCESS,
  payload: {
    response,
  },
});
export const GetProfitProjectionReportSuccessForecast = (response) => ({
  type: actionTypes.GET_PROFIT_PROJECTION_REPORT_FORECAST_SUCCESS,
  payload: {
    response,
  },
});

export const GetProfitProjectionReportErrorForecast = (response) => ({
  type: actionTypes.GET_PROFIT_PROJECTION_REPORT_FORECAST_ERROR,
  payload: {
    response,
  },
});

export const GetProfitProjectionReportError = (error) => ({
  type: actionTypes.GET_PROFIT_PROJECTION_REPORT_ERROR,
  payload: {
    error,
  },
});

export const GetProfitProjectionReportActionForecast = (data) => {
  return (dispatch) => {
    dispatch(GetProfitProjectionReportRequestForecast());
    return API.getProfitProjectionReportForecast(data)
      .then((response) => {
        dispatch(GetProfitProjectionReportSuccessForecast(response));
      })
      .catch((error) => {
        dispatch(
          GetProfitProjectionReportErrorForecast(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};


export const GetProfitProjectionPrefRequest = () => ({
  type: actionTypes.GET_PROFIT_PROJECTION_PREF_REQUEST,
});

export const GetProfitProjectionPrefSuccess = (preferences) => ({
  type: actionTypes.GET_PROFIT_PROJECTION_PREF_SUCCESS,
  payload: {
    preferences,
  },
});

export const GetProfitProjectionPrefAction = (preferences) => {
  return (dispatch) => {
    dispatch(GetProfitProjectionPrefRequest());
    const userId = localStorage.getItem('userId');
    const persistedData = localStorage.getItem('profitProjectionPref'+userId) || localStorage.getItem('profitProjectionPref') || '{}';
    const parsedData = JSON.parse(persistedData);
    dispatch(GetProfitProjectionPrefSuccess(parsedData));
  }
}

export const SetProfitProjectionPrefAction = (preferences) => {
  return (dispatch) => {
    const userId = localStorage.getItem('userId');
    const data = JSON.stringify(preferences);
    localStorage.setItem('profitProjectionPref'+userId, data);
    return dispatch(GetProfitProjectionPrefSuccess(preferences));
  }
}

export const GetProfitProjectionReportAction = (data) => {
  return (dispatch) => {
    dispatch(GetProfitProjectionReportRequest());
    return API.getProfitProjectionReport(data)
      .then((response) => {
        dispatch(GetProfitProjectionReportSuccess(response));
      })
      .catch((error) => {
        dispatch(
          GetProfitProjectionReportError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

/* End  Profit Projection report*/

/* Start Wip View Project List report */
export const GetWipProjectListReportRequest = () => ({
  type: actionTypes.GET_WIP_PROJECT_LIST_REPORT_REQUEST,
});

export const GetWipProjectListReportSuccess = (response) => ({
  type: actionTypes.GET_WIP_PROJECT_LIST_REPORT_SUCCESS,
  payload: {
    response,
  },
});

export const GetWipProjectListReportError = (error) => ({
  type: actionTypes.GET_WIP_PROJECT_LIST_REPORT_ERROR,
  payload: {
    error,
  },
});

export const GetWipProjectListReportAction = (data) => {
  return (dispatch) => {
    dispatch(GetWipProjectListReportRequest());
    return API.getWipProjectListReport(data)
      .then((response) => {
        dispatch(GetWipProjectListReportSuccess(response));
      })
      .catch((error) => {
        dispatch(
          GetWipProjectListReportError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

/* End Wip View Project List report*/

/* Start Wip Update Project List report */

/* Start Project History DD report */
export const GetProjectHistoryDDRequest = () => ({
  type: actionTypes.GET_PROJECT_HISTORY_DD_REQUEST,
});

export const GetProjectHistoryDDSuccess = (response) => ({
  type: actionTypes.GET_PROJECT_HISTORY_DD_SUCCESS,
  payload: {
    response,
  },
});

export const GetProjectHistoryDDError = (error) => ({
  type: actionTypes.GET_PROJECT_HISTORY_DD_ERROR,
  payload: {
    error,
  },
});


export const GetProjectHistoryRequest = () => ({
  type: actionTypes.GET_PROJECT_HISTORY_REQUEST,
});

export const GetProjectHistorySuccess = (response) => ({
  type: actionTypes.GET_PROJECT_HISTORY_SUCCESS,

  payload: {
    response,
  },
});

export const GetProjectHistoryError = (error) => ({
  type: actionTypes.GET_PROJECT_HISTORY_ERROR,

  payload: {
    error,
  },
});
export const GetProjectHistoryAction = (data) => {
  return (dispatch) => {
    dispatch(GetProjectHistoryRequest());
    return API.getProjectHistoryReport(data)
      .then((response) => {
        dispatch(GetProjectHistorySuccess(response));
      })
      .catch((error) => {
        dispatch(
          GetProjectHistoryError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

export const GetProjectHistoryPrefRequest = () => ({
  type: actionTypes.GET_PROJECT_HISTORY_PREF_REQUEST,
});

export const GetProjectHistoryPrefSuccess = (preferences) => ({
  type: actionTypes.GET_PROJECT_HISTORY_PREF_SUCCESS,
  payload: {
    preferences,
  },
});

export const GetProjectHistoryPrefAction = (preferences) => {
  return (dispatch) => {
    dispatch(GetProjectHistoryPrefRequest());
    const userId = localStorage.getItem('userId');
    const persistedData = localStorage.getItem('projectHistoryPref'+userId) || localStorage.getItem('projectHistoryPref') || '{}';
    const parsedData = JSON.parse(persistedData);
    dispatch(GetProjectHistoryPrefSuccess(parsedData));
  }
}

export const SetProjectHistoryPrefAction = (preferences) => {
  return (dispatch) => {
    const userId = localStorage.getItem('userId');
    const data = JSON.stringify(preferences);
    localStorage.setItem('projectHistoryPref'+userId, data);
    return dispatch(GetProjectHistoryPrefSuccess(preferences));
  }
}

/* Start Wip Update Project List report */
export const UpdateWipProjectListReportRequest = () => ({
  type: actionTypes.UPDATE_WIP_PROJECT_LIST_REPORT_REQUEST,
});

export const UpdateWipProjectListReportSuccess = (response) => ({
  type: actionTypes.UPDATE_WIP_PROJECT_LIST_REPORT_SUCCESS,
  payload: {
    response,
  },
});

export const UpdateWipProjectListReportError = (error) => ({
  type: actionTypes.UPDATE_WIP_PROJECT_LIST_REPORT_ERROR,
  payload: {
    error,
  },
});

export const UpdateWipProjectListReportAction = (data) => {
  return (dispatch) => {
    dispatch(UpdateWipProjectListReportRequest());
    return API.updateWipProjectListReport(data)
      .then((response) => {
        dispatch(UpdateWipProjectListReportSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          UpdateWipProjectListReportError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

/* End Wip Update Project List report*/

/* Start Timesheet Form */
export const GetTimesheetReportRequest = () => ({
  type: actionTypes.GET_TIMESHEET_REQUEST,
});

export const GetTimesheetReportSuccess = (response) => ({
  type: actionTypes.GET_TIMESHEET_SUCCESS,
  payload: {
    response,
  },
});

export const GetTimesheetReportError = (error) => ({
  type: actionTypes.GET_TIMESHEET_ERROR,
  payload: {
    error,
  },
});

export const GetTimesheetReportAction = (data) => {
  return (dispatch) => {
    dispatch(GetTimesheetReportRequest());
    return API.getTimeSheetList(data)
      .then((response) => {
        dispatch(GetTimesheetReportSuccess(response));
      })
      .catch((error) => {
        dispatch(
          GetTimesheetReportError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

/* Start Master Construction Report */

export const GetMasterConstructionRequest = () => ({
  type: actionTypes.GET_MASTER_CONSTRUCTION_REQUEST,
});

export const GetMasterConstructionSuccess = (response) => ({
  type: actionTypes.GET_MASTER_CONSTRUCTION_SUCCESS,
  payload: {
    response,
  },
});

export const GetMasterConstructionError = (error) => ({
  type: actionTypes.GET_MASTER_CONSTRUCTION_ERROR,
  payload: {
    error,
  },
});

export const GetMasterConstructionAction = (data) => {
  return (dispatch) => {
    dispatch(GetMasterConstructionRequest());
    return API.getMasterConstructionList(data)
      .then((response) => {
        dispatch(GetMasterConstructionSuccess(response));
      })
      .catch((error) => {
        dispatch(
          GetMasterConstructionError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};


export const GetMasterConstructionPrefRequest = () => ({
  type: actionTypes.GET_MASTER_CONSTRUCTION_PREF_REQUEST,
});

export const GetMasterConstructionPrefSuccess = (preferences) => ({
  type: actionTypes.GET_MASTER_CONSTRUCTION_PREF_SUCCESS,
  payload: {
    preferences,
  },
});

export const GetMasterConstructionPrefAction = (preferences) => {
  return (dispatch) => {
    dispatch(GetMasterConstructionPrefRequest());
    const userId = localStorage.getItem('userId');
    const persistedData = localStorage.getItem('masterConstructionPref'+userId) || localStorage.getItem('masterConstructionPref') || '{}';
    console.log('pData',persistedData);
    const parsedData = JSON.parse(persistedData);
    dispatch(GetMasterConstructionPrefSuccess(parsedData));
  }
}

export const SetMasterConstructionPrefAction = (preferences) => {
  return (dispatch) => {
    const userId = localStorage.getItem('userId');
    const data = JSON.stringify(preferences);
    localStorage.setItem('masterConstructionPref'+userId, data);
    return dispatch(GetMasterConstructionPrefSuccess(preferences));
  }
}

/* Start Data Check report */
export const GetDataCheckRequest = () => ({
  type: actionTypes.GET_DATA_CHECK_REQUEST,
});

export const GetDataCheckSuccess = (response) => ({
  type: actionTypes.GET_DATA_CHECK_SUCCESS,
  payload: {
    response,
  },
});

export const GetDataCheckError = (error) => ({
  type: actionTypes.GET_DATA_CHECK_ERROR,
  payload: {
    error,
  },
});

export const GetDataCheckAction = (data) => {
  return (dispatch) => {
    dispatch(GetDataCheckRequest());
    return API.getDataCheckList(data)
      .then((response) => {
        dispatch(GetDataCheckSuccess(response));
      })
      .catch((error) => {
        dispatch(
          GetDataCheckError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

export const GetDataCheckPrefRequest = () => ({
  type: actionTypes.GET_DATA_CHECK_PREF_REQUEST,
});

export const GetDataCheckPrefSuccess = (preferences) => ({
  type: actionTypes.GET_DATA_CHECK_PREF_SUCCESS,
  payload: {
    preferences,
  },
});

export const GetDataCheckPrefAction = (preferences) => {
  return (dispatch) => {
    dispatch(GetDataCheckPrefRequest());
    const userId = localStorage.getItem('userId');
    const persistedData = localStorage.getItem('dataCheckPref'+userId) || localStorage.getItem('dataCheckPref') || '{}';
    const parsedData = JSON.parse(persistedData);
    dispatch(GetDataCheckPrefSuccess(parsedData));
  }
}

export const SetDataCheckPrefAction = (preferences) => {
  return (dispatch) => {
    const userId = localStorage.getItem('userId');
    const data = JSON.stringify(preferences);
    localStorage.setItem('dataCheckPref'+userId, data);
    return dispatch(GetDataCheckPrefSuccess(preferences));
  }
}

/* Start Client References report */
export const GetClientReferenceRequest = () => ({
  type: actionTypes.GET_CLIENT_REFERENCES_REQUEST,
});

export const GetClientReferenceSuccess = (response) => ({
  type: actionTypes.GET_CLIENT_REFERENCES_SUCCESS,
  payload: {
    response,
  },
});

export const GetClientReferenceError = (error) => ({
  type: actionTypes.GET_CLIENT_REFERENCES_ERROR,
  payload: {
    error,
  },
});

export const GetClientReferenceAction = (data) => {
  return (dispatch) => {
    dispatch(GetClientReferenceRequest());
    return API.getClientReferenceList(data)
      .then((response) => {
        dispatch(GetClientReferenceSuccess(response));
      })
      .catch((error) => {
        dispatch(
          GetClientReferenceError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

/* Start Master Project Log report */
export const GetMasterProjectRequest = () => ({
  type: actionTypes.GET_MASTER_PROJECT_LOG_REQUEST,
});

export const GetMasterProjectSuccess = (response) => ({
  type: actionTypes.GET_MASTER_PROJECT_LOG_SUCCESS,
  payload: {
    response,
  },
});

export const GetMasterProjectError = (error) => ({
  type: actionTypes.GET_MASTER_PROJECT_LOG_ERROR,
  payload: {
    error,
  },
});

export const GetMasterProjectAction = (data) => {
  return (dispatch) => {
    dispatch(GetMasterProjectRequest());
    return API.geMasterProjectList(data)
      .then((response) => {
        dispatch(GetMasterProjectSuccess(response));
      })
      .catch((error) => {
        dispatch(
          GetMasterProjectError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

export const GetMasterProjectPrefRequest = () => ({
  type: actionTypes.GET_MASTER_PROJECT_PREF_REQUEST,
});

export const GetMasterProjectPrefSuccess = (preferences) => ({
  type: actionTypes.GET_MASTER_PROJECT_PREF_SUCCESS,
  payload: {
    preferences,
  },
});

export const GetMasterProjectPrefAction = (preferences) => {
  return (dispatch) => {
    dispatch(GetMasterProjectPrefRequest());
    const userId = localStorage.getItem('userId');
    const persistedData = localStorage.getItem('masterProjectPref'+userId) || localStorage.getItem('masterProjectPref') || '{}';
    const parsedData = JSON.parse(persistedData);
    dispatch(GetMasterProjectPrefSuccess(parsedData));
  }
}

export const SetMasterProjectPrefAction = (preferences) => {
  return (dispatch) => {
    const userId = localStorage.getItem('userId');
    const data = JSON.stringify(preferences);
    localStorage.setItem('masterProjectPref'+userId, data);
    return dispatch(GetMasterProjectPrefSuccess(preferences));
  }
}

/* Start Preliminary Info Report */
export const GetProjectPrelimInfoRequest = () => ({
  type: actionTypes.GET_PROJECT_PRELIM_INFO_REQUEST,
});

export const GetProjectPrelimInfoSuccess = (response) => ({
  type: actionTypes.GET_PROJECT_PRELIM_INFO_SUCCESS,
  payload: {
    response,
  },
});

export const GetProjectPrelimInfoError = (error) => ({
  type: actionTypes.GET_PROJECT_PRELIM_INFO_ERROR,
  payload: {
    error,
  },
});

export const GetProjectPrelimInfoAction = (data) => {
  return (dispatch) => {
    dispatch(GetProjectPrelimInfoRequest());
    return API.getProjectPrelimInfoList(data)
      .then((response) => {
        dispatch(GetProjectPrelimInfoSuccess(response));
      })
      .catch((error) => {
        dispatch(
          GetProjectPrelimInfoError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

/* Start Project info hot sheet report */
export const GetProjectInfoHotSheetRequest = () => ({
  type: actionTypes.GET_PROJECT_INFO_HOT_SHEET_REQUEST,
});

export const GetProjectInfoHotSheetSuccess = (response) => ({
  type: actionTypes.GET_PROJECT_INFO_HOT_SHEET_SUCCESS,
  payload: {
    response,
  },
});

export const GetProjectInfoHotSheetError = (error) => ({
  type: actionTypes.GET_PROJECT_INFO_HOT_SHEET_INFO_ERROR,
  payload: {
    error,
  },
});

export const GetProjectInfoHotSheetAction = (data) => {
  return (dispatch) => {
    dispatch(GetProjectInfoHotSheetRequest());
    return API.getProjectInfoHotSheetReport(data)
      .then((response) => {
        dispatch(GetProjectInfoHotSheetSuccess(response));
      })
      .catch((error) => {
        dispatch(
          GetProjectInfoHotSheetError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

/* Start Project info hot sheet report */
export const GetBidResultRecapRequest = () => ({
  type: actionTypes.GET_BID_RESULT_RECAP_REQUEST,
});

export const GetBidResultRecapSuccess = (response) => ({
  type: actionTypes.GET_BID_RESULT_RECAP_SUCCESS,
  payload: {
    response,
  },
});

export const GetBidResultRecapError = (error) => ({
  type: actionTypes.GET_BID_RESULT_RECAP_ERROR,
  payload: {
    error,
  },
});

export const GetBidResultRecapAction = (data) => {
  return (dispatch) => {
    dispatch(GetBidResultRecapRequest());
    return API.getBidResultRecapReport(data)
      .then((response) => {
        dispatch(GetBidResultRecapSuccess(response));
      })
      .catch((error) => {
        dispatch(
          GetBidResultRecapError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

/* Start Profit and sales report */

export const GetProfitSalesReportPrefRequest = () => ({
  type: actionTypes.GET_PROFIT_SALES_PREF_REQUEST,
});

export const GetProfitSalesReportPrefSuccess = (preferences) => ({
  type: actionTypes.GET_PROFIT_SALES_PREF_SUCCESS,
  payload: {
    preferences,
  },
});

export const GetProfitSalesReportPrefAction = () => {
  return (dispatch) => {
    dispatch(GetProfitSalesReportPrefRequest());
    const userId = localStorage.getItem('userId');
    const persistedData = localStorage.getItem('profitSalesPref'+userId) || localStorage.getItem('profitSalesPref') || '{}';
    const parsedData = JSON.parse(persistedData);
    dispatch(GetProfitSalesReportPrefSuccess(parsedData));
  }
}

export const SetProfitSalesReportPrefAction = (preferences) => {
  return (dispatch) => {
    const userId = localStorage.getItem('userId');
    const data = JSON.stringify(preferences);
    localStorage.setItem('profitSalesPref'+userId, data);
    return dispatch(GetProfitSalesReportPrefSuccess(preferences));
  }
}

export const GetProfitSalesRequest = () => ({
  type: actionTypes.GET_PROFIT_SALES_REQUEST,
});

export const GetProfitSalesSuccess = (response) => ({
  type: actionTypes.GET_PROFIT_SALES_SUCCESS,
  payload: {
    response,
  },
});

export const GetProfitSalesError = (error) => ({
  type: actionTypes.GET_PROFIT_SALES_ERROR,
  payload: {
    error,
  },
});

export const GetProfitSalesAction = (data) => {
  return (dispatch) => {
    dispatch(GetProfitSalesRequest());
    return API.getProfitSalesReport(data)
      .then((response) => {
        dispatch(GetProfitSalesSuccess(response));
      })
      .catch((error) => {
        dispatch(
          GetProfitSalesError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

