import React, { useState } from 'react';

export const headerWithSort =
  (header, field) =>
  ({ state }) => {
    const orderIconClass =
      state.sortingField === field
        ? new Map([
            ['asc', 'fa fa-sort-asc'],
            ['desc', 'fa fa-sort-desc'],
            [null, 'fa fa-sort'],
          ]).get(state.sortingOrder)
        : 'fa fa-sort';

    return (
      <>
        {header}
        <span className='sort-icon-wrapper' role='button' onClick={() => state.onSortClick(field)}>
          <i className={orderIconClass} aria-hidden='true' />
        </span>
      </>
    );
  };

const useSorting = (field = null, order = null) => {
  const [sortingField, setSortingField] = useState(() => field);
  const [sortingOrder, setSortingOrder] = useState(() => order);

  const onSortClick = (field) => {
    const orderMap = new Map([
      [null, 'asc'],
      ['asc', 'desc'],
      ['desc', null],
    ]);

    setSortingOrder((oldOrder) => orderMap.get(oldOrder));
    setSortingField(field);
  };

  return { onSortClick, sortingField, sortingOrder };
};

export default useSorting;