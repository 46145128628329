import apiClient from '../utils/api-client';
import { postRequest, postRequestNode } from './helper';

export const getExistingProject = (data) => postRequest('functions/projectDetails', data);
export const createProject = (data) => apiClient.post('/parse/functions/createProject', data);
export const updateProject = (data) => apiClient.post('/parse/functions/updateProject', data);
export const getAllProjects = (data) => postRequest(`functions/viewProjectList`, data);
export const deleteProject = (data) => postRequest(`functions/deleteProjects`, data);
export const updateLeadSection = (data) => postRequest(`functions/editLead`, data);
export const getLastJobNum = (data) => apiClient.post('/parse/functions/getLastJobNum', data);
export const updateLeadStatus = (data) => postRequest(`functions/updateLeadStatus`, data);
export const promoteLead = (data) => postRequest(`functions/promoteLeadToProject`, data);
export const updatedEventDate = (data) => postRequest(`functions/changeProjectStatusEvent`, data);
