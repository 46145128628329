import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { routerMiddleware } from 'react-router-redux';
import oldReducer from '../reducers';

import history from '../routes/history';
import bids from './bids';
import calendar from './calendar';
import companies from './companies';
import contacts from './contacts';
import orders from './orders';
import projects from './projects';
import reports from './reports';
import settings from './settings';
import accounts from './accounts';

const rootReducer = combineReducers({
  ...oldReducer,
  rtk: combineReducers({
    bids,
    calendar,
    contacts,
    companies,
    orders,
    projects,
    reports,
    settings,
    accounts
  }),
});

export const initStore = () =>
  configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      // temporary disable `immutableCheck` due to errors in the old codebase
      getDefaultMiddleware({ immutableCheck: false, serializableCheck: false }).concat(routerMiddleware(history)),
  });

const store = initStore();

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export default store;
