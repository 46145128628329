import { postRequest, putRequest } from './helper';
import { postRequestWithoutSession } from './helper-without-session';

export const getUserEmailById = (data) => postRequestWithoutSession('functions/getUserEmailById', data);
export const getSignInUser = (data) => postRequest(`functions/getUserInfo`, data);
export const emailVerification = (data) =>
  postRequestWithoutSession(`functions/userEmailActivation`, data);
  
export const signInUser = (data) => postRequestWithoutSession(`functions/cpostLogin`, data);
export const signUpUser = (data) => postRequest(`users`, data);
export const updateContactStatus = (id, data) => putRequest(`classes/contacts/${id}`, data);

export const getUserCppRole = (data) => postRequest('functions/getModulesByRoleId', data);
export const getAllUserList = (data) => postRequest('functions/viewUsers', data);


export const changeUserPassword = (data) => postRequest(`functions/changeUserPassword`, data);
export const resetUserPassword = (data) => postRequest(`functions/resetAuth0Password`, data);
