import { useForm, FormProvider } from 'react-hook-form';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import FormInput from '../../../shared/form-controls/form-input/form-input';
import { useEffect } from 'react';
import { useAppDispatch } from '../../../shared/hooks/use-app-dispatch';
import { useAppSelector } from '../../../shared/hooks/use-app-selector';
import {
  addUserSettings,
  disableUserSetting,
  enableUserSetting,
  resetUserSetting,
  updateUserSettings,
} from '../../../modules/settings';
import { useSubmit } from '../../../shared/hooks/use-submit';
import FormSelect from '../../../shared/form-controls/form-select';
import FormCheckbox from '../../../shared/form-controls/form-checkbox';

export const EditSettingModal = ({
  open = false,
  onClose = () => {},
  onSubmit = () => {},
  settingId,
  lineItemData = [],
}) => {
  const modelName = 'jobScopes';

  const isUpdate = !!settingId;
  const dispatch = useAppDispatch();

  const form = useForm({
    defaultValues: {
      name: '',
      code: '',
      definition: '',
    },
  });

  // TODO: should be replaced with dispatch
  const setting = useAppSelector((state) => {
    const settings = state.rtk.settings.getUserSettings.data;

    const setting = settings.find((s) => s._id === settingId);

    return setting;
  });

  useEffect(() => {
    if (!open) return;

    if (!setting || !isUpdate) {
      form.reset({
        name: '',
        code: '',
        definition: '',
        lineItemId: '',
        isBundle: false,
      });

      return;
    }

    form.reset({
      name: setting.name,
      code: setting.code,
      definition: setting.definition,
      lineItemId: setting.lineItemId,
      isBundle: setting.isBundle,
    });
  }, [setting, isUpdate, open]);

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = form.handleSubmit(async (data) => {
    if (isUpdate) {
      await dispatch(updateUserSettings([modelName, { ...data, _id: settingId }])).unwrap();
    } else {
      await dispatch(addUserSettings([modelName, { ...data, _id: settingId }])).unwrap();
    }

    onSubmit();
    handleClose();
  });

  const [handleToggle, toggleLoading] = useSubmit(async () => {
    if (setting.trash) {
      await dispatch(enableUserSetting([modelName, settingId])).unwrap();
    } else {
      await dispatch(disableUserSetting([modelName, settingId])).unwrap();
    }
    onSubmit();
  }, [setting, setting]);

  const [handleReset, resetLoading] = useSubmit(async () => {
    await dispatch(resetUserSetting([modelName, settingId])).unwrap();

    onSubmit();
  }, [settingId]);

  const lineItemId = form.watch('lineItemId');

  useEffect(() => {
    // if lien item id changes to a bundle, set isBundle to true
    const lineItem = lineItemData.find((item) => item._id === lineItemId);

    if (lineItem) {
      form.setValue('isBundle', lineItem.type==='Group');
    }
  }, [lineItemId]);

  return (
    <Modal isOpen={open} toggle={onClose} size='lg'>
      <ModalHeader toggle={onClose}>{isUpdate ? 'Edit' : 'Add'} Job Scope</ModalHeader>
      <ModalBody>
        <FormProvider {...form}>
          <div className='container'>
            <div className='row'>
              <div className='col-6'>
                <FormInput label='Name' name='name' required />
              </div>

              <div className='col-6'>
                <FormInput label='Code' name='code' required />
              </div>
            </div>

            <div className='row'>
              <div className='col-12'>
                <FormInput label='Definition' name='definition' textArea />
              </div>
            </div>

{/*
            <div className='row'>
              <div className='col-6'>
                <FormSelect
                  label='Default line item'
                  name='lineItemId'
                  options={lineItemData.map((item) => ({ value: item._id, label: item.name }))}
                />
              </div>
              <div className='col-6'>
                <FormCheckbox label='Is Item Group' name='isBundle' disabled/>
              </div>
            </div>
*/}
          </div>
        </FormProvider>
      </ModalBody>
      <ModalFooter>
        {isUpdate && !!setting.isLocalCopy && (
          <button className='btn btn-primary me-auto' onClick={handleReset} disabled={resetLoading}>
            Reset to default
          </button>
        )}

        <button className='btn btn-primary' onClick={onClose}>
          Cancel
        </button>
        {isUpdate && (
          <button className='btn btn-primary' onClick={handleToggle} disabled={toggleLoading}>
            {setting.trash ? 'Enable' : 'Disable'}
          </button>
        )}
        <button
          className='btn btn-primary'
          onClick={handleSubmit}
          disabled={
            form.formState.isSubmitting || !form.formState.isValid || !form.formState.isDirty
          }
        >
          Save
        </button>
      </ModalFooter>
    </Modal>
  );
};
