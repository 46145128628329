import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { GetProfitSalesAction } from '../../../actions/reports';
import { ResponsivePie } from '@nivo/pie';
import { BoxLegendSvg } from '@nivo/legends';
import loaderImage from '../../static/images/loading_i.gif';
import { numberToCurrency } from '../../../utils/number-formatters';

const SalesAnalysisWidget = ({ componentData, salesList, GetProfitSalesAction }) => {
  const [formData, setFormData] = useState({
    whatToAnalyze: 'sales',
    jobStatus: false,
    dateType: 'dateType',
    analyzeBy: '',
    jobBasedOn: '',
  });

  const [profitData, setProfitData] = useState(componentData?.profitData || '');
  const [fetchRequest, setFetchRequest] = useState(true);
  const [dataLength, setDataLength] = useState(false);
  const [graphData, setGraphData] = useState([]);

  const options = {
    width: 340,
    height: 220,
  };

  useEffect(() => {
    if (profitData) {
      fetchProfitData(profitData);
    }
  }, [profitData]);

  useEffect(() => {
    if (salesList && salesList.data) {
      if (salesList.data.length > 0) {
        profitCalculation(salesList.data);
        setFetchRequest(false);
        setDataLength(false);
      } else {
        setFetchRequest(true);
      }

      if (salesList.data.length === 0) {
        setDataLength(true);
      }
    }
  }, [salesList]);

  const fetchProfitData = (profitData) => {
    const data = {
      analyzeBy: 'PM',
      whatToAnalyze: profitData,
      jobStatus: false,
      jobBasedOn: 'activeAndDelivered',
      activeAndDeliveredStatus: false,
    };
    GetProfitSalesAction(data);
  };

  const profitCalculation = (salesArr) => {
    let finalSum = {};
    finalSum.FBidContractSum = 0;
    finalSum.FBidProfitSum = 0;
    finalSum.FBidProfitPerSum = 0;

    if (salesArr.length > 0) {
      for (let x in salesArr) {
        salesArr[x].allContract = 0;
        salesArr[x].allProfit = 0;
        salesArr[x].analyzer = '';

        //*** Analyser name
        if (salesArr[x].itemInfo) {
          salesArr[x].analyzer = salesArr[x].itemInfo?.displayName ?? 'Unassigned';
        }

        for (let y in salesArr[x].projects) {
          salesArr[x].projects[y].perProjectBidProfit = 0;
          salesArr[x].projects[y].selectedDate = '';

          //**** Total Bid amount and profit amount
          salesArr[x].allContract += salesArr[x].projects[y].currentContractAmount;
          salesArr[x].allProfit += salesArr[x].projects[y].currentGrossProfit;

          //**** Total won amount and profit amount
          var jobBasedOn = formData.jobBasedOn;
          salesArr[x].projects[y].selectedDate = salesArr[x].projects[y][jobBasedOn];
        }

        //**** bid Profit percentage
        if (salesArr[x].bidProfitPercent === undefined) {
          if (isNaN(salesArr[x].allProfit / salesArr[x].allContract) === true) {
            salesArr[x].bidProfitPercent = 0.0;
          } else {
            salesArr[x].bidProfitPercent = (salesArr[x].allProfit / salesArr[x].allContract) * 100;
            salesArr[x].bidProfitPercent = salesArr[x].bidProfitPercent.toFixed(2);
          }
        }

        //*** Total project, Net Amount of Bid Contract/Profit/ProfitPercentage, Won Contract/Profit/ProfitPercentage, Bid hit ratio and Profit
        finalSum.FBidContractSum += salesArr[x].allContract;
        finalSum.FBidProfitSum += salesArr[x].allProfit;
        finalSum.FBidProfitPerSum += Math.round(salesArr[x].bidProfitPercent);
      }

      let graphDataNew = [];

      let pieChartTotal = 0;
      for (let x in salesArr) {
        //********** graph data Calculation
        let pieChartPer = 0;
        if (formData.whatToAnalyze === 'sales') {
          if (salesArr[x].allContract > 0) {
            pieChartPer = salesArr[x].allContract;
          }
        }

        pieChartTotal += pieChartPer;

        if (salesArr[x].analyzer === 'Unassigned') {
          graphDataNew.unshift({ id: salesArr[x].analyzer, value: pieChartPer });
        } else {
          graphDataNew.push({ id: salesArr[x].analyzer, value: pieChartPer });
        }
      }

      const minPercentage = 5;
      const minValue = pieChartTotal * (minPercentage / 100);
      const otherData = graphDataNew.filter((item) => item.value < minValue);
      const otherDataTotal = otherData.reduce((acc, item) => acc + item.value, 0);
      const otherDataItem = { id: 'Other', value: otherDataTotal };
      const filteredData = graphDataNew.filter((item) => item.value >= minValue);
      const graphDataNewFinal = [...filteredData, otherDataItem];

      if (otherDataTotal > 0) {
        setGraphData(graphDataNewFinal);
      } else {
        setGraphData(graphDataNew);
      }
    }
  };

  const [legends, setLegends] = useState([]);


  return (
    <div>
      {graphData && graphData.length > 0 && (
        <div className='row' style={{ minHeight: 300, fill: 'white' }}>
          <div className='col-md-8 svgGraph'>
            <ResponsivePie
              data={graphData}
              margin={{ top: 30, right: 30, bottom: 30, left: 30 }}
              innerRadius={0.7}
              cornerRadius={3}
              colors={{ scheme: 'tableau10' }}
              borderWidth={1}
              borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
              padAngle={2}
              arcLabelsTextColor={'#ffffff'}
              arcLabelsSkipAngle={1}
              enableArcLabels={false}
              enableArcLinkLabels={false}
              forwardLegendData={setLegends}
              legends={[
                {
                  anchor: 'right',
                  direction: 'column',
                  justify: false,
                  translateX: -3000,
                  translateY: 0,
                  itemWidth: 100,
                  itemHeight: 20,
                  itemsSpacing: 0,
                  symbolSize: 18,
                  symbolShape: 'circle',
                  itemDirection: 'left-to-right',
                },
              ]}
              // format values with commas and dollars
              valueFormat={(value) => numberToCurrency(value, '$')}
              arcLabel={(e) => numberToCurrency(Math.round(e.value / 1000), '$') + 'k'}
            />
          </div>
          <div className='col-md-4'>
            {legends.length > 0 && (
              <div className='row mb-4' style={{ minHeight: '30px' }}>
                {legends.map((legend, index) => {
                  return (
                    <div key={'legend_' + index}>
         
                          <div style={{ width: '12px', height: '12px', borderRadius: '50%',display: 'inline-block', backgroundColor: legend.color }}></div>

                          <div style={{ fontSize: '10pt', display: 'inline', verticalAlign:'middle' }} > {legend.label}</div>
                          <div style={{ fontSize: '10pt',  }} > {legend.data.formattedValue?? legend.data.value}</div>
                    </div>
                  );
                })}
              </div>
            )}
            <div className='row'>
              <ul className='clearfix'>
                <li>
                  <strong>Report Period:</strong> As of today
                </li>
                <li>
                  <strong>Grouped by:</strong> Active, Delivered, and Pre-Con Jobs{' '}
                </li>
                <li>
                  <strong>Analyzed by:</strong> Project Manager
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}

      {fetchRequest && !dataLength && (
        <div className='msgBox'>
          <img src={loaderImage} width='100' height='100' alt='loading' />
        </div>
      )}

      {fetchRequest && dataLength && (
        <div className='msgBox'>
          <div>No data found!</div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  salesList: state.reports.profitAndSalesList,
  isSPReport: state.reports.isSPReport,
  isSPReportError: state.reports.isSPReportError,
});

export default withRouter(connect(mapStateToProps, { GetProfitSalesAction })(SalesAnalysisWidget));
