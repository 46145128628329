import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { getAccountAffiliation } from '../../modules/companies';
import { getContactAffiliations } from '../../modules/contacts';
import { updateContactAffiliationSchema } from './update-contact-affiliation-schema';

export const useAffiliationForm = ({ open, makeEmployee, isUpdate = false, isImport = false, contactId }) => {
  const affiliationForm = useForm<any>({
    defaultValues: {
      affiliations: [],
      formerAffiliations: [],
      affiliationsFetched: false,
    },
    resolver: joiResolver(
      Joi.object({
        affiliations: Joi.array()
          .items(updateContactAffiliationSchema)
          .custom((values, helper) => {
            const personal = values.filter((v: any) => v.selfAffiliated);

            if (!personal.length)
              return helper.message({ custom: 'Personal contact info should exist in list' });

            if (personal.length > 1)
              return helper.message({ custom: 'Only one personal item allowed' });

            const account = values.filter((v: any) => v.isAccountAffiliation);

            if (account.length > 1)
              return helper.message({ custom: 'Only one account item allowed' });

            return values;
          }),
        formerAffiliations: Joi.allow(),
        affiliationsFetched: Joi.optional(),
      })
    ),
  });

  const dispatch: any = useDispatch();

  useEffect(() => {
    if (!open) return affiliationForm.reset(undefined, { keepDefaultValues: true });
    if (isImport) return;

    (async () => {
      const accountAffiliation = await dispatch(getAccountAffiliation()).unwrap();
      let fetchedAffiliations = [];

      if (contactId) {
        fetchedAffiliations = await dispatch(getContactAffiliations({ contactId })).unwrap();
      }

      const { affiliations, formerAffiliations } = processAffiliations(fetchedAffiliations, accountAffiliation, makeEmployee, isUpdate);

      affiliationForm.setValue('formerAffiliations', formerAffiliations);
      affiliationForm.setValue('affiliations', affiliations);
      affiliationForm.setValue('affiliationsFetched', true); // TODO: try to get rid of this property
    })();
  }, [open, makeEmployee, isUpdate, contactId]);

  return affiliationForm;
};

export const processAffiliations = (fetchedAffiliations: Array<any>, accountAffiliation: any, makeEmployee: boolean, isUpdate: boolean) => {
  let affiliations = [] as any[];
  let formerAffiliations = [] as any[];

  if (fetchedAffiliations.length && isUpdate) {
    formerAffiliations = fetchedAffiliations.filter((a) => !a.selfAffiliated && !a.active);
    affiliations = fetchedAffiliations.filter((a) => a.selfAffiliated || a.active);
  }

  if (!affiliations.find((a) => a.selfAffiliated)) {
    affiliations = [
      {
        selfAffiliated: true,
        phoneArr: [],
        addressArr: [],
        emailArr: [],
        active: true,
        isAccountAffiliation: false,
        order: -10,
      },
      ...affiliations,
    ];
  }

  if (makeEmployee && accountAffiliation) {
    const existingAccountAffiliation = [...affiliations, ...formerAffiliations].find((a: any) => a.isAccountAffiliation);

    if (!existingAccountAffiliation) {
      const [personal, ...rest] = affiliations;
      const accountAffiliationCopy = { ...accountAffiliation, active: true, order: -5 };
      accountAffiliationCopy.emailArr = [];
      accountAffiliationCopy.phoneArr = [];
      accountAffiliationCopy.addressArr = [];

      affiliations = [personal, accountAffiliationCopy, ...rest];
    }else if(!existingAccountAffiliation.active){
      const accountAffiliationCopy = { ...existingAccountAffiliation, active: false, order: -5 };
      
      formerAffiliations = [accountAffiliationCopy,...formerAffiliations.filter((a: any) => !a.isAccountAffiliation)];
      
    }
  }

  return { affiliations, formerAffiliations };
};
