import { HorizontalLayout } from '../../form-controls/control-layouts/horizontal-layout';
import { CustomCheckbox } from './custom-checkbox';
import { CustomMultiSelect } from './custom-multi-select';
import { CustomNumberInput } from './custom-number-input';
import { CustomRadio } from './custom-radio';
import { CustomRate } from './custom-rate';
import { CustomSingleSelect } from './custom-single-select';
import { CustomDivider } from './custom-divider';
import { CustomTextInput } from './custom-text-input';
import { CustomDateInput } from './custom-date-input';
import { forwardRef, useImperativeHandle, useRef } from 'react';
import { useFormContext } from 'react-hook-form';

export const CustomFieldControl = forwardRef(
  ({ fieldDefinition, control, Layout = HorizontalLayout, required, disabled, prefix, isHideFields=false, onEditRef }: any, ref) => {
    const controlRef = useRef<{ onUpdate: (data: any) => void }>({ onUpdate: () => undefined });

    useImperativeHandle(ref, () => ({ onUpdate }), []);

    const name = prefix ? `${prefix}.${fieldDefinition._id}` : fieldDefinition._id;

    const onUpdate = (fieldsData: any) => controlRef.current?.onUpdate(fieldsData);

    const controlType = fieldDefinition.controlType;
    const valueDefinition = fieldDefinition.valueDefinition;

    const commonProps = {
      ref: controlRef,
      control,
      Layout,
      label: fieldDefinition.name,
      name,
      required,
      disabled,
    };

    const formContext = useFormContext();

    const curValue = formContext.getValues(name);

    const isEmpty = fieldDefinition.controlType!== 'DIVIDER' && (!curValue || curValue?.length === 0|| curValue === ''  || (valueDefinition?.isUrl && !(curValue?.url?.length>0)));

    if(isHideFields && isEmpty) return null;

    switch (controlType) {
      case 'DIVIDER':
        return <CustomDivider onEditRef={onEditRef} {...commonProps}/>;
      case 'TEXT_INPUT':
        return <CustomTextInput {...commonProps} isUrl={valueDefinition.isUrl} isTextArea={valueDefinition.isTextArea} placeholder={valueDefinition.placeholder} minRows={valueDefinition.minRows} maxRows={valueDefinition.maxRows} />;
      case 'NUMBER_INPUT':
        return (
          <CustomNumberInput
            {...commonProps}
            allowDecimal={valueDefinition.allowDecimal}
            allowNegative={valueDefinition.allowNegative}
            showCommas={valueDefinition.showCommas}
            prefix={valueDefinition.prefix}
            postfix={valueDefinition.postfix}
          />
        );
      case 'SINGLE_SELECT':
        return (
          <CustomSingleSelect
            {...commonProps}
            options={valueDefinition.options}
            creatable={valueDefinition.creatable}
          />
        );
      case 'MULTI_SELECT':
        return (
          <CustomMultiSelect
            {...commonProps}
            options={valueDefinition.options}
            creatable={valueDefinition.creatable}
          />
        );
      case 'CHECKBOX':
        return <CustomCheckbox {...commonProps} />;
      case 'RADIO':
        return <CustomRadio {...commonProps} options={valueDefinition.options} />;
      case 'RATE':
        return (
          <CustomRate
            {...commonProps}
            color={valueDefinition.color}
            count={valueDefinition.count}
            allowHalf={valueDefinition.allowHalf}
            char={valueDefinition.character}
            icon={valueDefinition.icon}
          />
        );
      case 'DATE_INPUT':
        return (
          <CustomDateInput
            {...commonProps}
            allowTime={valueDefinition.allowTime}
            lessThanEqual={valueDefinition.lessThanEqual}
            greaterThanEqual={valueDefinition.greaterThanEqual}
          />
        );
      default:
        return <CustomTextInput {...commonProps} isUrl={valueDefinition.isUrl} />;
    }
  }
);
