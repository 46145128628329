import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { callAlertMessage } from '../../components/Auth/Auth';
import { buildDefaultActionResult } from '../../utils/redux';
import {
  getActiveScheduleReportRequest,
  getEstimatingScheduleReportRequest,
  getResourceScheduleFiltersRequest,
  getResourceScheduleRequest,
  processTemplateFileRequest,
  fetchProjectTemplateTagsRequest,
} from './api';

export const getResourceScheduleFiltersAsync = createAsyncThunk(
  'reports/getResourceScheduleFiltersAsync',
  async () => {
    const {
      data: { result },
    } = await getResourceScheduleFiltersRequest();

    if (result.success === 0) {
      callAlertMessage('error', result.msg);
    }

    return result.data ?? {};
  }
);

export const getResourceScheduleAsync = createAsyncThunk(
  'reports/getResourceScheduleAsync',
  async (data) => {
    const {
      data: { result },
    } = await getResourceScheduleRequest(data);

    if (result.success === 0) {
      callAlertMessage('error', result.msg);
    }

    return result.data ?? {};
  }
);

export const getEstimatingScheduleReport = createAsyncThunk(
  'reports/getEstimatingScheduleReport',
  async (/** @type {any} */ data) => {
    const report = await getEstimatingScheduleReportRequest(data);

    return report;
  }
);

export const processTemplateFile = createAsyncThunk(
  'reports/processTemplateFile',
  async (/** @type {any} */ data) => {
    const report = await processTemplateFileRequest(data);

    return report;
  }
);

export const fetchProjectTemplateTags = createAsyncThunk(
  'reports/fetchProjectTemplateTags',
  async (/** @type {any} */ data) => {
    const report = await fetchProjectTemplateTagsRequest(data);

    return report;
  }
);


export const getActiveScheduleReport = createAsyncThunk(
  'reports/getActiveScheduleReport',
  async (/** @type {any} */ data) => {
    const report = await getActiveScheduleReportRequest(data);

    return report;
  }
);

export const reportsSlice = createSlice({
  name: 'reports',
  initialState: {
    getResourceScheduleFilters: {
      data: {},
      loading: false,
      error: null,
    },
    getResourceSchedule: {
      data: {},
      loading: false,
      error: null,
    },
    getEstimatingScheduleReport: {
      data: [],
      loading: false,
      error: null,
    },
    getActiveScheduleReport: {
      data: [],
      loading: false,
      error: null,
    },
    processTemplateFile: {
      data: [],
      loading: false,
      error: null,
    },
    fetchProjectTemplateTags: {
      data: [],
      loading: false,
      error: null,
    },
  },
  extraReducers: (builder) => {
    buildDefaultActionResult(
      'getResourceScheduleFilters',
      getResourceScheduleFiltersAsync,
      builder
    );
    buildDefaultActionResult('getResourceSchedule', getResourceScheduleAsync, builder);
    buildDefaultActionResult('getEstimatingScheduleReport', getEstimatingScheduleReport, builder);
    buildDefaultActionResult('getActiveScheduleReport', getActiveScheduleReport, builder);
    buildDefaultActionResult('processTemplateFile', processTemplateFile, builder);
    buildDefaultActionResult('fetchProjectTemplateTags', fetchProjectTemplateTags, builder);
  },
});

export default reportsSlice.reducer;
