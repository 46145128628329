import { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import { useDisabledFieldsContext } from '../../shared/disabled-fields';
import { appConstants } from '../../_constants';
import PhoneInput from '../phone-input';
import Star from '../form-controls/field-actions/star/star';
import Add from '../form-controls/field-actions/add/add';
import Copy from '../form-controls/field-actions/copy/copy';
import Delete from '../form-controls/field-actions/delete/delete';
import styles from '../form-controls/field-actions/field-actions.module.scss';
import { Warning } from '../form-controls/field-actions/warning';
import _ from 'lodash';

type PhoneArrayProps = {
  name: string;
  copyData?: any;
  onEditCompany?: () => void;
  onValidate?: (value: any) => Promise<any>;
  onWarningClick?: (data: any, field: any) => void;
  onValidationDataChange?: (data: any[], fieldData: any[]) => void;
};

const PhoneArray = ({
  name = 'phoneArr',
  copyData = {},
  onEditCompany = () => void 0,
  onValidate = () => Promise.resolve(),
  onWarningClick = () => void 0,
  onValidationDataChange = () => void 0,
}: PhoneArrayProps) => {
  const { watch, setValue } = useFormContext();
  const forceDisabled = useDisabledFieldsContext();
  const dataArr = watch(name, []);

  const [validationDataArr, setValidationDataArr] = useState<any[]>([]);

  const validate = useCallback(
    _.debounce(async (value: any, index: number) => {
      const data = await onValidate(value);

      setValidationDataArr((prev) => {
        const newArr = [...prev];
        newArr[index] = data;
        return newArr;
      });
    }, 500),
    []
  );

  useEffect(() => {
    onValidationDataChange(validationDataArr, dataArr);
  }, [validationDataArr]);

  useEffect(() => {
    if (dataArr.length === 0) {
      setValue(name, [{ ...appConstants.defaultFieldValues.phone, _id: uuidv4() }]);
    }
  }, [dataArr.length]);

  const controlsWrapperClassName = `col-md-1 ${styles.rowControlsWrapper}`;
  const dataRowClassName = `d-flex gap-0 row align-items-baseline ${styles.rowWrapper} ${styles.selectWrapper}`;

  return (
    <div data-testid='PhoneArray' className={styles.wrapper}>
      {dataArr.map((field, index) => {
        return (
          <div className={dataRowClassName} data-testid={`PhoneItem-${index}`} key={field._id}>
            <span className='col-md-2 pe-0'>{index === 0 ? 'Phone number' : ''}</span>
            <div className={`${controlsWrapperClassName} justify-content-end d-none d-sm-flex`}>
              <Star name={name} index={index} />
            </div>
            <PhoneInput name={`${name}.${index}`} onChange={(v) => validate(v, index)} />
            {forceDisabled || (
              <div className={`${controlsWrapperClassName} justify-content-start col-1`}>
                <Delete name={name} index={index} itemType="phone number" requireConfirmation={false} />
                <Warning
                  data={validationDataArr[index]}
                  onClick={(data) => onWarningClick(data, field)}
                />
              </div>
            )}
          </div>
        );
      })}
      <div className={dataRowClassName}>
        <div className='col-md-8 offset-md-3 d-flex gap-2'>
          <Copy name={name} type='phone' data={copyData} onEditCompany={onEditCompany} />
          <Add name={name} type='phone' />
          {/* waring icon */}
        </div>
      </div>
    </div>
  );
};

export default PhoneArray;
