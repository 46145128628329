import React, { Suspense } from 'react';

import { Link, withRouter } from 'react-router-dom';
import Header from '../../Layout/Header.js';
import Footer from '../../Layout/Footer.js';
import { Input, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { connect } from 'react-redux';
import { appConstants } from '../../../_constants';

import { Tooltip } from 'react-tooltip';
import { getAllProjectRoleAction } from '../../../actions/projectRole';
import {
  GetMasterConstructionAction,
  GetContactJobDDAction,
  GetMasterConstructionPrefAction,
  SetMasterConstructionPrefAction,
} from '../../../actions/reports';

import { matchDataForTable } from '../../../utils/match-for-table';
import ReactLoading from 'react-loading';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import NotePopUp from '../../Common/NotePopUp';
import renderHTML from 'html-react-parser';
import TitleComponent from '../../Common/TitleComponent';
import { customSortByKey, CustomTable, multiSelectFilter } from '../../Common/CustomTable';
import { SelectColumnFilter } from '../../Common/CustomTableComponents/SelectColumnFilter';

import { DateColumnFilter } from '../../Common/CustomTableComponents/DateColumnFilter';
import {
  formatDate,
  formatDuration,
} from '../../../utils/date-formatters';
import {
  formatExternalContactData,
  formatInternalContactData,
} from '../../../utils/contact-formatters';
import { formatPhoneArrayHTML } from '../../../utils/contact-formatters.jsx';
import { getAllProjectFieldsAction } from '../../../actions/projectFields';
import { buildDynamicColumns, onDate} from '../../../utils/column-formatters';

let contactPersonName = '';
let jobStatusName = '';
let showContact = '';
let showJobStatus = '';
var sortingOrder = '';

class MasterConstructionReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {},
      accountId: localStorage.getItem('accountId'),
      userId: localStorage.getItem('userId'),
      list: [],
      modal: false,
      errorUploading: true,
      successUploading: true,
      fetchRequest: true,
      comLogo: '',
      isReport: true,
      isReportError: true,
      isReportDD: true,
      isReportDDError: true,
      totalCount: 0,
      currentPage: 1,
      sizePerPage: appConstants.TableSizePerPageList[2].value,
      searchText: '',
      period: '',
      allJob: null,
      contactName: null,
      allStatus: null,
      jobStatus: null,
      statusName: '',
      DDList: [],
      OptionProjectStatus: [],
      dynamicColumns: [],
      projectRoleTypes: [],

      allProjectFieldTypes: [],
      sortType: 'DESC',
      sortKey: 'endDate',
      noteModal: false,
    };

    this.onPageChange = this.onPageChange.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.toggle = this.toggle.bind(this);
    this.onSortChange = this.onSortChange.bind(this);
    this.getModalOpen = this.getModalOpen.bind(this);
    this.onNoteModalClose = this.onNoteModalClose.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.listData &&
      nextProps.listData.success &&
      nextProps.listData.success === 1 
    ) {
      this.setState({
        totalCount: nextProps.listData.count,
        list: nextProps.listData.data,
        fetchRequest: false,
      });
    }

    if (nextProps?.projectRoles?.allProjectRoleTypes) {
      this.setState({
        projectRoleTypes: nextProps.projectRoles.allProjectRoleTypes,
      });
    }

    if (nextProps?.projectFields?.allProjectFieldTypes?.length > 0) {
      this.setState({
        allProjectFieldTypes: nextProps.projectFields.allProjectFieldTypes,
      });
    }

    if (
      nextProps.listData &&
      nextProps.listData.success &&
      nextProps.listData.success === 1
    ) {
      let fieldsToMatch = {
        projectAssignments: { lookupLocation: 'contactData' },
      };

      let matchedDataForTable = matchDataForTable(
        fieldsToMatch,
        nextProps.listData.data,
        nextProps.listData.contactData,
        this.props
      );

      this.setState({
        totalCount: nextProps.listData.count,
        list: matchedDataForTable,
        contactData: nextProps.listData.contactData,
        fetchRequest: false,
      });
    }

    if (nextProps.DDList && nextProps.DDList.data && nextProps.DDList.data.length > 0) {
      let DDList = [];
      var formData = this.state.formData;
      nextProps.DDList.data.map((v, i) => {
        if (v.objectId !== '' && v.firstName !== '' && v.lastName !== '') {
          DDList.push({
            value: v.objectId,
            label: v.firstName + ' ' + v.lastName,
          });
          if (i === 0) {
            formData.contactName = {
              value: v.objectId,
              label: v.firstName + ' ' + v.lastName,
            };
          }
        }
        this.setState({ DDList, formData: formData });
        return true;
      });
    }
  }

  buildColumnForRole(filteredRoles, roleCode, hidden = false) {
    const foundRole = filteredRoles.find((role) => role.code == roleCode);
    if (!foundRole) return;
    const isInternal = foundRole && foundRole.type === 'internal';

    if (foundRole && isInternal) {
      return (
        foundRole && {
          Header: foundRole?.shortName ?? roleCode,
          LongHeader: foundRole?.name,
          accessor: 'projectAssignmentsLabel' + roleCode,
          sortType: customSortByKey,
          sortKey: foundRole?.name ?? roleCode,
          Filter: SelectColumnFilter,
          filter: multiSelectFilter,
          width: 60,
          hidden: hidden,
          Cell: (props) => (
            <>
              {this.formatContactDataInternal(
                'projectAssignmentsMatched' + roleCode,
                props.row.original
              )}
            </>
          ),
        }
      );
    } else {
      return (
        foundRole && {
          Header: foundRole?.shortName ?? roleCode,
          LongHeader: foundRole?.name,
          accessor: 'projectAssignmentsLabel' + roleCode,
          sortType: customSortByKey,
          sortKey: foundRole?.name ?? roleCode,
          Filter: SelectColumnFilter,
          filter: multiSelectFilter,
          width: 60,
          hidden: hidden,
          Cell: (props) => (
            <>
              {this.formatContactDataExternal(
                'projectAssignmentsMatched' + roleCode,
                props.row.original
              )}
            </>
          ),
        }
      );
    }
  }

  formatContactDataInternal(cell, row) {
    if (row[cell] && row[cell].length > 0) {
      return formatInternalContactData(row[cell], this.state.contactData);
    } else {
      return <span>-</span>;
    }
  }

  formatContactDataExternal(cell, row) {
    if (row[cell] && row[cell].length > 0) {
      return formatExternalContactData(row[cell], this.state.contactData, false);
    } else {
      return <span>-</span>;
    }
  }

  componentDidMount() {
    let data = {};
    data['accountId'] = this.state.accountId;
    data['moduleName'] = 'Master Constructions Schedule Report';
    this.props.GetContactJobDDAction(data);

    sortingOrder = '';

    // Job Status DD
    let OptionProjectStatus = [];
    var formData = this.state.formData;
    appConstants.OptionProjectStatus.map((v, i) => {
      if (v.name !== '' && v.value !== '') {
        OptionProjectStatus.push({ value: v.value, label: v.name });
        if (i === 0) {
          formData.jobStatus = { value: v.value, label: v.name };
        }
      }
      this.setState({ OptionProjectStatus, formData: formData });
      return true;
    });

    this.props.getAllProjectRoleAction({
      className: 'projectRoleTypes',
      accountId: this.state.accountId,
    });

    this.props.getAllProjectFieldsAction({
      accountId: this.state.accountId,
      minimumStage: 'post-construction'
    });

    if (localStorage.getItem('companyLogo') !== 'undefined')
      this.setState({
        comLogo: localStorage.getItem('companyLogo'),
      });

    let _this = this;
    setTimeout(function () {
      _this.setState({ fetchRequest: false });
    }, 2000);

    this.props.GetMasterConstructionPrefAction();
  }

  UNSAFE_componentWillMount() {
    this.toggle();
  }

  toggle(val) {
    if (this.state.modal === true) {
      document.body.classList.remove('modal-open');
    }
    if (this.state.period == '') this.setState({ period: 'allJob', statusName: 'allStatus' });

    if (this.state.statusName == '') this.setState({ statusName: 'allStatus' });

    this.setState({ modal: !this.state.modal });
  }

  filteredData(e) {
    e.preventDefault();
    this.setState({ fetchRequest: true });
    this.setState({ sizePerPage: appConstants.TableSizePerPageList[2].value });
    contactPersonName = this.state.period;
    jobStatusName = this.state.statusName;

    let data = {};
    data['accountId'] = this.state.accountId;
    data['page'] = 0;
    data['displayLimit'] = this.state.sizePerPage;
    data['moduleName'] = appConstants.reportModuleName['14'];
    // data['sortKey'] = "startDate";
    // data['sortType'] = "ASC";
    data['sortKey'] = this.state.sortKey;
    data['sortType'] = this.state.sortType;
    data['search'] = this.state.searchText;

    if (this.state.period === 'allJob') {
      data['contactId'] = '';
      showContact = 'Company-wide';
    } else if (this.state.period === 'contactName') {
      let { formData } = this.state;
      let { contactName } = formData;
      let { value, label } = contactName;
      data['contactId'] = value;
      showContact = 'Projects for: ' + label;
    }

    if (this.state.statusName === 'allStatus') {
      data['jobStatusCodesName'] = '';
      showJobStatus = '- all projects underway';
    } else if (this.state.statusName === 'jobStatus') {
      data['jobStatusCodesName'] = this.state.formData.jobStatus.value;
      showJobStatus = '- ' + this.state.formData.jobStatus.label + ' projects only';
    }
    this.toggle();
    this.props.GetMasterConstructionAction(data);
  }

  handleChangePeriod(val) {
    this.setState({ period: val });
  }

  handleChangeStatus(val) {
    this.setState({ statusName: val });
  }

  handleChangeSelectBox(name, value) {
    let formData = this.state.formData;
    formData[name] = value;
    this.setState({ formData });
  }

  redirectToProject(cell, enumObject, row, rowIndex) {
    if (cell === 'jobNumFormatted' && row) {
      return (
        <div>
          <Link
            to={{
              pathname: `/project-details/` + row.objectId,
              state: { row },
            }}
          >
            {row.jobNumFormatted ? row.jobNumFormatted : '-'}
          </Link>
        </div>
      );
    }

    if (cell === 'altJobNum' && row) {
      return (
        <div>
          <Link
            to={{
              pathname: `/project-details/` + row.objectId,
              state: { row },
            }}
          >
            {row.altJobNum ? row.altJobNum : '-'}
          </Link>
        </div>
      );
    }
  }


  onActivityName(cell, row, enumObject, rowIndex) {
    let data = '';
    let phn = '';
    if (row) {
      data +=
        '<div>' +
        (row.jobAddress ? row.jobAddress : '') +
        (row.jobAddress ? ', ' : '') +
        (row.jobAddress2 ? row.jobAddress2 : '') +
        (row.jobAddress2 ? ', ' : '') +
        '</div>';
      data +=
        '<div>' +
        (row.jobCity ? row.jobCity : '') +
        (row.jobCity ? ', ' : '') +
        (row.jobState ? row.jobState : '') +
        (row.jobState ? ', ' : '') +
        (row.jobZip ? row.jobZip : '') +
        '</div>';
      data +=
        '<div>' +
        (row.clientData && row.clientData.firstName && row.clientData.lastName
          ? row.clientData.firstName + ' ' + row.clientData.lastName
          : '') +
        '</div>';
      // data += "</br>"
      if (row.clientData && row.clientData.phoneArr && row.clientData.phoneArr.length > 0) {
        data += formatPhoneArrayHTML(row.clientData.phoneArr);
      }
      return (
        <>
          <span>
            <strong>
              <Link to={{ pathname: `/project-details/` + row.objectId, state: { row } }}>
                {row.jobName || ''}
              </Link>
            </strong>
          </span>
          <div dangerouslySetInnerHTML={{ __html: data }} />
        </>
      );
    } else {
      return <span>-</span>;
    }
  }

  onNoteDetail(cell, row, enumObject, rowIndex) {
    // let data = row && row.cppNotes ? row.cppNotes : '-';
    // return data;
    const notes =
      row.cppNotes.replace(/<[^>]+>/g, '').length > 25
        ? `${row.cppNotes.substring(0, 25)}...`
        : row.cppNotes;
    return row && row.cppNotes ? (
      <a
        className='anchorNote '
        data-type={'reminder'}
        onClick={(e) => {
          this.getModalOpen(e, row);
        }}
      >
        {renderHTML(notes)}
      </a>
    ) : (
      '-'
    );
  }

  onNoteModalClose() {
    this.setState({ noteModal: false });
  }

  getModalOpen(e, row) {
    let rowData = row && row.cppNotes ? row.cppNotes : '-';
    this.setState({ noteModal: true, noteData: rowData });
  }

  onDuration(cell, row, enumObject, rowIndex) {
    if (row && row.startDate?.iso && row.endDate?.iso) {
      let duration = formatDuration(row.startDate, row.endDate);
      return duration ? duration + 'd' : <span>-</span>;
    } else {
      return <span>-</span>;
    }
  }

  createCustomButtonGroup = (props) => {
    if (this.state.comLogo === null) {
      let constImage = appConstants.CONTENT_CPOST_LOGO_URL;
      this.setState({
        comLogo: constImage,
      });
    }
    return (
      <div className='pdfLogoBox'>
        <div className='reportHead'>
          <div className='logo'>
            <img src={this.state.comLogo} alt='Upload Logo' />
          </div>
          <h2>Master Constructions Schedule Report</h2>
          <div>
            {showContact} {showJobStatus}
          </div>
        </div>
      </div>
    );
  };

  onPageChange(page, sizePerPage) {
    this.setState({ fetchRequest: true, list: [] });
    let data = {};
    data['accountId'] = this.state.accountId;
    data['moduleName'] = appConstants.reportModuleName['14'];
    if (contactPersonName === 'allJob') {
      data['contactId'] = '';
    } else if (contactPersonName === 'contactName') {
      data['contactId'] = this.state.formData.contactName.value;
    }

    if (jobStatusName === 'allStatus') {
      data['jobStatusCodesName'] = '';
    } else if (jobStatusName === 'jobStatus') {
      data['jobStatusCodesName'] = this.state.formData.jobStatus.value;
    }

    data['page'] = page === -1 ? 0 : page - 1;
    data['displayLimit'] = sizePerPage;
    data['search'] = this.state.searchText;

    data['sortType'] = this.state.sortType;
    data['sortKey'] = this.state.sortKey;

    this.setState({ sizePerPage: sizePerPage });
    this.setState({ currentPage: page });
    this.props.GetMasterConstructionAction(data);
  }

  onSearchChange(text, sizePerPage) {
    this.setState({ fetchRequest: true, list: [] });
    text = text ? text.trim() : '';
    let data = {};

    data['accountId'] = this.state.accountId;
    data['moduleName'] = appConstants.reportModuleName['14'];
    if (contactPersonName === 'allJob') {
      data['contactId'] = '';
    } else if (contactPersonName === 'contactName') {
      data['contactId'] = this.state.formData.contactName.value;
    }

    if (jobStatusName === 'allStatus') {
      data['jobStatusCodesName'] = '';
    } else if (jobStatusName === 'jobStatus') {
      data['jobStatusCodesName'] = this.state.formData.jobStatus.value;
    }

    data['page'] = 0;
    data['displayLimit'] = this.state.sizePerPage;

    data['sortType'] = this.state.sortType;
    data['sortKey'] = this.state.sortKey;
    console.log(text, 'text', text.length);
    if (text.length >= 1) {
      data['search'] = text;
      this.setState({ sizePerPage: 50 });
    } else {
      data['search'] = '';
    }
    this.setState({ searchText: text });
    this.props.GetMasterConstructionAction(data);
  }

  onSortChange(sortName, sortOrder) {
    if (this.state && this.state.list && this.state.list.length > 0) {
      this.setState({ fetchRequest: true, list: [] });
      let data = {};
      data['sortKey'] = sortName;
      if (sortName === 'jobNumFormatted') {
        data['sortKey'] = 'jobNum';
      }

      this.setState({ sortKey: data['sortKey'] });

      if (sortOrder === 'asc') {
        data['sortType'] = 'ASC';
        sortingOrder = '-asc';
        this.setState({ sortType: 'ASC' });
      } else {
        data['sortType'] = 'DESC';
        sortingOrder = '-desc';
        this.setState({ sortType: 'DESC' });
      }
      data['accountId'] = this.state.accountId;
      data['moduleName'] = appConstants.reportModuleName['14'];
      if (this.state.period === 'allJob') {
        data['contactId'] = '';
        showContact = 'Company-wide';
      } else if (this.state.period === 'contactName') {
        data['contactId'] = this.state.formData.contactName.value;
        showContact = 'Projects for: ' + this.state.formData.contactName.label;
      }

      if (this.state.statusName === 'allStatus') {
        data['jobStatusCodesName'] = '';
        showJobStatus = '- all projects underway';
      } else if (this.state.statusName === 'jobStatus') {
        data['jobStatusCodesName'] = this.state.formData.jobStatus.value;
        showJobStatus = '- ' + this.state.formData.jobStatus.label + ' projects only';
      }

      data['search'] = this.state.searchText;
      data['page'] = this.state.currentPage - 1;
      data['displayLimit'] = this.state.sizePerPage;
      this.props.GetMasterConstructionAction(data);
    }
  }

  _setTableOption() {
    if (this.state.fetchRequest) {
      return (
        <ReactLoading
          className='table-loader'
          type={appConstants.LOADER_TYPE}
          color={appConstants.LOADER_COLOR}
          height={appConstants.LOADER_HEIGHT}
          width={appConstants.LOADER_WIDTH}
        />
      );
    } else {
      return 'No data found..';
    }
  }


  

  buildReportColumns() {
    //const hasContractValPermissions = isAllowed(moduleConstants.VIEWCONTRACTVAL, 'anyAssigned');
    //const hasProfitValPermissions = isAllowed(moduleConstants.VIEWPROFIT, 'anyAssigned');

    const { list, allProjectFieldTypes } = this.state;

    const dynamicFieldsLoaded = allProjectFieldTypes?.length > 0;

    const defaultFieldCodes = [];

    const customFieldColumns = dynamicFieldsLoaded
      ? buildDynamicColumns(
          allProjectFieldTypes.filter((field) => !defaultFieldCodes.includes(field.code))
        )
      : [];

    const defaultFields = dynamicFieldsLoaded
      ? allProjectFieldTypes.filter((field) => defaultFieldCodes.includes(field.code))
      : [];

    const isLeadType = false;

    const rolesLoaded = this.state.projectRoleTypes?.length > 0;

    const defaultRolesCodes = [];

    const dynamicColumnsLoaded = dynamicFieldsLoaded && rolesLoaded;

    const enabledRoles = this.state.projectRoleTypes.filter(
      (role) => !defaultRolesCodes.includes(role.code) && (!isLeadType || role.isShowOnLeads)
    );
    const defaultRoles = this.state.projectRoleTypes.filter(
      (role) => defaultRolesCodes.includes(role.code) && (!isLeadType || role.isShowOnLeads)
    );

    const defaultColumns = [
      {
        Header: 'Activity Name',
        accessor: 'jobName',
        width: 300,
        Cell: (props) => this.onActivityName(props.value, props.row.original),
      },
      {
        Header: 'Job No.',
        accessor: 'jobNumFormatted',
        width: 100,
        Cell: (props) => this.redirectToProject(props.column.id, props.value, props.row.original),
      },
      {
        Header: 'Alt Job#',
        accessor: 'altJobNum',
        width: 100,
        Cell: (props) => this.redirectToProject(props.column.id, props.value, props.row.original),
      },
      {
        Header: 'Start Date',
        accessor: (row) =>
          row?.startDate?.iso
            ? new Date(row.startDate.iso)
            : new Date(formatDate('1800-03-03T12:47:40.000Z')),
        id: 'startDate',
        width: 100,
        Cell: (props) => <>{onDate(props.column.id, props.row.original, props.value)}</>,
        filter: DateColumnFilter,
        allowDateRangeFilter: true,
        sortType: 'datetime',
      },
      {
        Header: 'End Date',
        accessor: (row) =>
          row?.endDate?.iso
            ? new Date(row.endDate.iso)
            : new Date(formatDate('1800-03-03T12:47:40.000Z')),
        id: 'endDate',
        width: 100,
        Cell: (props) => <>{onDate(props.column.id, props.row.original, props.value)}</>,
        filter: DateColumnFilter,
        allowDateRangeFilter: true,
        sortType: 'datetime',
      },
      {
        Header: 'Duration',
        accessor: (row) => formatDuration(row.startDate, row.endDate),
        width: 80,
        Cell: (props) => this.onDuration(props.value, props.row.original),
      },
      {
        Header: 'Notes',
        accessor: 'jobNotes',
        hidden: false,
        width: 240,
        Cell: (props) => (
          <>
            <span
              data-tooltip-html={props.value}
              data-tooltip-id={props.column.id + ':' + props.row.id}
            >
              {this.onNoteDetail(props.column.id, props.row.original, props.value)}
            </span>
            <Tooltip
              id={props.column.id + ':' + props.row.id}
              delayShow={1000}
              style={{ color: '#fff !important' }}
              place='bottom'
            />
          </>
        ),
      },
    ];

    let columns = [];
    if (enabledRoles) {
      const extraColumns = enabledRoles.map((role) =>
        this.buildColumnForRole([role], role.code, true)
      );

      if (columns.length == 0) {
        columns = defaultColumns.filter((cols) => cols?.accessor);
      }

      for (let i in extraColumns) {
        const curColumnsAccessors = columns.map((cols) => cols.accessor);
        if (!curColumnsAccessors.includes(extraColumns[i].accessor)) {
          columns = columns.concat(extraColumns[i]);
        }
      }

      for (let i in customFieldColumns) {
        const curColumnsAccessors = columns.map((cols) => cols.accessor);
        if (!curColumnsAccessors.includes(customFieldColumns[i].accessor)) {
          columns = columns.concat(customFieldColumns[i]);
        }
      }
    } else {
      columns = defaultColumns.filter((cols) => cols?.accessor);
    }

    
    this.setState({ dynamicColumns: columns });

  }

  
  render() {
  
    const {
      list, allProjectFieldTypes, dynamicColumns
    } = this.state;


    const dynamicFieldsLoaded = allProjectFieldTypes?.length > 0;

    const rolesLoaded = this.state.projectRoleTypes?.length > 0;

    const dynamicColumnsLoaded = dynamicFieldsLoaded && rolesLoaded;

    
    const dynamicColumnsBuilt = dynamicColumnsLoaded && dynamicColumns.length > 0;

    if(dynamicColumnsLoaded && !dynamicColumnsBuilt){
      this.buildReportColumns();
    }
    

    
    const { listPref, SetMasterConstructionPrefAction } = this.props;

    return (
      <div>
        <div className='ContactListReport'>
          <TitleComponent data={{ title: `Master Construction Report` }} />
          <div>
            <Suspense fallback='loading'>
              <Header props={this.props} />
            </Suspense>
          </div>

          <div className='contactBox'>
            <div className='container'>
              <div className='reportHead no-print'>
                <h2>Master Construction Report</h2>
                <button className='btn rightalignmentBtn' onClick={() => this.toggle('POST')}>
                  <i className='fa fa-filter'></i> Apply Filters
                </button>
              </div>

              <div className='row searchbar no-print'>
                <div className='col-md-12'>
                  <div className='masterPDFalign'>
                    <strong>
                      {showContact} {showJobStatus}
                    </strong>
                  </div>
                </div>
              </div>
              <div className='row'>
                {dynamicColumnsBuilt && (
                  <div className='col-md-12'>
                    <div
                      className='table-responsive reportTableSection MasterConstructionsScheduleReport mt-0'
                      ref={(el) => (this.componentRef = el)}
                    >
                      {this.createCustomButtonGroup()}
                      <CustomTable
                        columns={dynamicColumns}
                        list={list}
                        preferences={listPref}
                        className='master-construction-table'
                        customTableTitle=' '
                        onSavePreferences={SetMasterConstructionPrefAction}
                        isLoading={this.state.fetchRequest}
                        canShowRowSelection={false}
                        componentRef={this.componentRef}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {this.state.noteModal && (
            <NotePopUp
              isOpen={this.state.noteModal}
              onClick={this.onNoteModalClose}
              noteInfo={this.state.noteData}
            />
          )}
          <Footer />
        </div>

        <Modal
          backdrop='static'
          keyboard={true}
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggle}>Select</ModalHeader>
          <ModalBody>
            <div className='container'>
              <div className='form-group'>
                <div className='row'>
                  <div className='col-md-12'>
                    <label>
                      <strong>1. Select employee(s) to include</strong>
                    </label>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-12'>
                    <Input
                      type='radio'
                      name='period'
                      checked={this.state.period === 'allJob' ? 'checked' : false}
                      onClick={this.handleChangePeriod.bind(this, 'allJob')}
                    />{' '}
                    Include jobs for all employees.
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-12'>
                    <Input
                      type='radio'
                      name='period'
                      checked={this.state.period === 'contactName' ? 'checked' : false}
                      onClick={this.handleChangePeriod.bind(this, 'contactName')}
                    />{' '}
                    Jobs for one employee.
                  </div>
                </div>
              </div>
              <div className='form-group'>
                <div className='row'>
                  <div className='col-md-12'>
                    {this.state.period === 'contactName' && (
                      <Select
                        name='contactName'
                        components={makeAnimated()}
                        value={this.state.formData.contactName}
                        options={this.state.DDList}
                        onChange={this.handleChangeSelectBox.bind(this, 'contactName')}
                      />
                    )}
                  </div>
                </div>
              </div>

              <div className='form-group'>
                <div className='row'>
                  <div className='col-md-12'>
                    <label>
                      <strong>2. Select job status to include</strong>
                    </label>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-12'>
                    <Input
                      type='radio'
                      name='statusName'
                      checked={this.state.statusName === 'allStatus' ? 'checked' : false}
                      onClick={this.handleChangeStatus.bind(this, 'allStatus')}
                    />{' '}
                    All active jobs (Phase 1-3, Active, Delivered &amp; Precon)
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-12'>
                    <Input
                      type='radio'
                      name='statusName'
                      checked={this.state.statusName === 'jobStatus' ? 'checked' : false}
                      onClick={this.handleChangeStatus.bind(this, 'jobStatus')}
                    />{' '}
                    Select status
                  </div>
                </div>
              </div>
              <div className='form-group'>
                <div className='row'>
                  <div className='col-md-12'>
                    {this.state.statusName === 'jobStatus' && (
                      <Select
                        name='jobStatus'
                        components={makeAnimated()}
                        value={this.state.formData.jobStatus}
                        options={this.state.OptionProjectStatus}
                        onChange={this.handleChangeSelectBox.bind(this, 'jobStatus')}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button className='btn btn-primary' onClick={this.toggle}>
              Cancel
            </button>
            <button className='btn btn-primary' onClick={this.filteredData.bind(this)}>
              Next
            </button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    listData: state.reports.masterConstructionList,
    listPref: state.reports.masterConstructionListPref,
    isReport: state.reports.isReport,
    isReportError: state.reports.isReportError,
    DDList: state.reports.contactDropDownList,
    isReportDD: state.reports.isReportDD,
    projectRoles: state.projectRoleType,
    isReportDDError: state.reports.isReportDDError,
    projectFields: state.projectFields,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    GetMasterConstructionAction,
    GetMasterConstructionPrefAction,
    SetMasterConstructionPrefAction,
    GetContactJobDDAction,
    getAllProjectRoleAction,
    getAllProjectFieldsAction,
  })(MasterConstructionReport)
);
