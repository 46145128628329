import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { appConstants } from '../../../_constants';
import {
  createWidgetsAction,
  disableWidgetsAction,
  getAllWidgetsAction,
} from '../../../actions/Admin/widget.actions.js';
import CustomTable from '../../Common/CustomTable';
import ReactLoading from 'react-loading';
import loaderImage from '../../static/images/loading_spinner.gif';
import TitleComponent from '../../Common/TitleComponent';
import { TextEditorField } from '../../Common/TextEditorField';
import { Checkbox } from 'antd';
import { callAlertMessage, UpperCaseName } from '../../../components/Auth/Auth';

// eslint-disable-next-line no-undef
let Validator = require('validatorjs');
let styleData = { color: 'red' };
let formArr = {};
let rules = {
  widgetName: 'required',
  orderId: 'required|numeric',
  widgetDescription: 'required',
  moduleCode: 'required',
};
let mess = {
  required: 'This field is required',
  alpha: 'This field may only contain letters',
  numeric: 'This field must be a number',
};
let validation = [];
validation = new Validator([], rules, appConstants.RULESMESSAGE);

validation.passes();
validation.fails();

class WidgetsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectBox: {},
      sessionToken: localStorage.getItem('sessionToken'),
      userId: localStorage.getItem('userId'),
      list: [],
      formArr: [],
      formData: {},
      modal: false,
      showProcessing: false,
      totalCount: 0,
      currentPage: 1,
      selectedRows: [],
    };
    this.toggle = this.toggle.bind(this);
    this.getWidgetList = this.getWidgetList.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.handleRowSelection = this.handleRowSelection.bind(this);
  }

  componentDidMount() {
    this.getWidgetList();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.isWidgetListSuccess !== this.props.isWidgetListSuccess) {
      if (
        nextProps.widgetList?.length > 0
      ) {
        this.setState({ list: nextProps.widgetList, fetchRequest: true });
      }
    }
    //else{
    //    this.setState({list:[]});
    // }

    if (
      nextProps.isWidgetAddSuccess !== this.props.isWidgetAddSuccess &&
      nextProps.isWidgetAdd.success === 1
    ) {
      this.setState({
        formData: {},
        selectBox: {},
        formArr: [],
        showProcessing: false,
        modal: false,
      });
      callAlertMessage('success', nextProps.message);
      this.getWidgetList();
    }

    if (
      nextProps.isWidgetAddSuccess !== this.props.isWidgetAddSuccess &&
      nextProps.isWidgetAdd.success === 0
    ) {
      callAlertMessage('error', nextProps.message);
      this.setState({ showProcessing: false });
    }

    if (
      nextProps.isWidgetDisableSuccess !== this.props.isWidgetDisableSuccess &&
      nextProps.isWidgetDisable.success === 1
    ) {
      this.setState({ showProcessing: false });
      callAlertMessage('success', nextProps.message);
      this.getWidgetList();
    }

    if (
      nextProps.isWidgetDisableSuccess !== this.props.isWidgetDisableSuccess &&
      nextProps.isWidgetDisable.success === 0
    ) {
      callAlertMessage('error', nextProps.message);
      this.setState({ showProcessing: false });
    }
  }

  toggle(val) {
    if (val === 'POST') {
      this.setState({ formData: {} }); //Open modal htmlFor add
    } else {
      this.setState({ saveType: 'PUT' });
      this.setState({ formData: this.state.formData }); //Open modal htmlFor edit
    }
    this.setState({ modal: !this.state.modal, formArr: [] });
  }

  cancelFun(e) {
    e.preventDefault();
    this.setState({ modal: !this.state.modal, formArr: [] });
    this.getWidgetList();
  }

  getWidgetList() {
    let widgetRequest = {};
    widgetRequest['type'] = 'SUPERADMIN';
    widgetRequest['adminId'] = this.state.userId;
    widgetRequest['page'] = 0;
    widgetRequest['displayLimit'] = this.state.sizePerPage;
    this.props.getAllWidgetsAction(widgetRequest);
  }

  addWidgets(evt) {
    evt.preventDefault();
    let { formData } = this.state;

    if (this.allValidate(false)) {
      formData['adminId'] = this.state.userId;
      formData['type'] = 'SUPERADMIN';
      formData['orderId'] = formData.orderId ? Number(formData.orderId) : '';
      formData['widgetName'] = formData.widgetName;
      formData['widgetCode'] = formData.widgetCode;
      formData['widgetDescription'] = formData.widgetDescription;
      formData['moduleCode'] = formData.moduleCode;
      formData['trash'] = formData.trash ?? false;
      formData['settings'] = formData.settings ?? {};
      this.props.createWidgetsAction(formData);
      this.setState({ showProcessing: true });
    }
  }

  allValidate(check) {
    if (!check) {
      formArr = [];
      Object.keys(rules).forEach(function (key) {
        formArr[key] = 'TT';
      });
      this.setState({
        formArr,
      });
    }
    if (validation.passes()) {
      return 1;
    }
  }

  handleChange = (e) => {
    e.preventDefault();
    let field = this.state.formData;
    field[e.target.name] = e.target.value;
    this.setState({ formData: field });
  };

  handleChangeSelectBox = (name, value) => {
    let formData = this.state.formData;
    let selectBox = this.state.selectBox;
    selectBox[name] = value;
    formData[name] = value.value;
    this.setState({ formData, selectBox });
  };

  handleDisableButtonClick = () => {
    let data = {};
    const { selectedRows, list } = this.state;
    data['adminId'] = this.state.userId;
    data['dataArr'] = list
      .filter((f, index) => selectedRows.includes(index.toString()))
      .map((k) => ({
        objectId: k.objectId,
      }));
    const confirm = window.confirm('Are you sure to disable?');
    if (confirm) {
      this.props.disableWidgetsAction(data);
      this.setState({ submit: true, isWidgetsCreated: false });
    } else {
      console.log({ confirm });
    }
  };

  handleRowSelection = (ids) => {
    this.setState({ selectedRows: ids });
  };

  _setTableOption() {
    if (this.state.fetchRequest) {
      return 'No data found..';
    } else {
      return (
        <ReactLoading
          className='table-loader'
          type={appConstants.LOADER_TYPE}
          color={appConstants.LOADER_COLOR}
          height={appConstants.LOADER_HEIGHT}
          width={appConstants.LOADER_WIDTH}
        />
      );
    }
  }

  getTimeLine(cell, row, enumObject, rowIndex) {
    return row.widgetLimit + ' ' + row.widgetLimitType;
  }

  selectedRow1 = (row) => {
    this.toggle('PUT');
    let selectBox = {};
    selectBox['widgetLimitType'] = {
      label: row.widgetLimitType,
      value: row.widgetLimitType,
    };
    this.setState({ formData: row, selectBox });
  };

  onPageChange(page, sizePerPage) {
    let formData = this.state.formData;
    let data = {};
    data['adminId'] = this.state.userId;
    data['type'] = 'SUPERADMIN';
    data['page'] = page === -1 ? 0 : page - 1;
    data['displayLimit'] = sizePerPage;
    data['search'] = this.state.searchText;
    this.setState({ sizePerPage: sizePerPage });
    this.setState({ currentPage: page });
    this.props.getAllWidgetsAction(data);
  }

  showTrueFalseTrash(row, name) {
    return (
      <div style={{ textAlign: 'center' }}>
        {row[name] === true ? (
          <i className='fa fa-check fa-lg' style={{ color: 'green' }} aria-hidden='true'></i>
        ) : (
          <i className='fa fa-trash fa-lg' style={{ color: 'red' }} aria-hidden='true'></i>
        )}
      </div>
    );
  }

  onSearchChange(text, sizePerPage) {
    let formData = this.state.formData;
    let filterValue = this.state.filterValue;
    text = text ? UpperCaseName(text.trim()) : '';
    if (text !== '') {
      let data = {};
      data['adminId'] = this.state.userId;
      data['type'] = 'SUPERADMIN';
      data['sortKey'] = this.state.sortKey;
      data['sortType'] = this.state.sortType;
      data['page'] = 0;
      data['search'] = text;
      data['displayLimit'] = this.state.sizePerPage;
      this.setState({ sizePerPage: 50 });
      this.setState({ searchText: text });
      this.props.getAllWidgetsAction(data);
    } else {
      this.componentDidMount();
      this.setState({ searchText: text });
    }
  }

  handleChange1(name, value) {
    let field = this.state.formData;
    if (value?.target) {
      field[name] = value.target.checked;
      this.setState({
        formData: field,
        [name]: value.target.checked,
      });
    } else {
      field[name] = value;
      this.setState({
        formData: field,
        [name]: value,
      });
    }
  }

  render() {
    validation = new Validator(this.state.formData, rules, mess);
    validation.passes();
    validation.fails();


    const selectRowProp = { mode: 'checkbox' };
    // let tableOption = appConstants.TableOption;
    // tableOption['afterDisableRow']= this.handleDisableButtonClick;
    // tableOption['paginationShowsTotal']= this.renderShowsTotal;
    // tableOption['onRowClick']= this.selectedRow1;

    let tableOption = appConstants.TableOption;
    tableOption['afterDisableRow'] = this.handleDisableButtonClick;
    tableOption['paginationShowsTotal'] = this.renderShowsTotal;
    tableOption['onRowClick'] = this.selectedRow1;
    tableOption['noDataText'] = this._setTableOption();

    const columns = [
      {
        Header: 'Widget Name',
        accessor: 'widgetName',
        width: 150,
      },
      {
        Header: 'Widget Code',
        accessor: 'widgetCode',
        width: 100,
      },
      {
        Header: 'Order',
        accessor: 'orderId',
        width: 100,
      },
      {
        Header: 'Description',
        accessor: 'widgetDescription',
        width: 320,
        //Cell: (props) => this.getTimeLine(props.column.id, props.row.original, props.value)
      },
      {
        Header: 'moduleCode',
        accessor: 'moduleCode',
        width: 160,
      },
      {
        Header: 'moduleId',
        accessor: 'moduleId',
        width: 80,
      },
      {
        Header: 'Deleted',
        accessor: 'trash',
        width: 60,
        Cell: (props) => this.showTrueFalseTrash(props.row.original, 'trash'),
      },
    ];

    return (
      <div>
        <TitleComponent data={{ title: `Administration Widgets Settings` }} />
        <div
          className='noteProcessing'
          style={{ display: this.state.showProcessing ? 'block' : 'none' }}
        >
          <img src={loaderImage} alt='Loading' />
        </div>
        <div className='headingbox'>
          <h2 className='heading2'>Widgets List</h2>
        </div>
        <div className='row'>
          <div className='col-md-12 sortable-table'>
            <button className='btn btnRight' onClick={() => this.toggle('POST')}>
              Add
            </button>
            <button
              className='btn btn-primary'
              style={{ cursor: 'pointer', padding: '5px' }}
              disabled={this.state.selectedRows?.length < 1}
              onClick={() => this.handleDisableButtonClick()}
            >
              <i className='fa fa-trash' aria-hidden='true'></i>&nbsp;Disable
            </button>
            <CustomTable
              columns={columns}
              list={this.state.list}
              canShowColumnSettings={false}
              canShowDateFilter={false}
              canShowExport={false}
              canShowPagination={false}
              onSavePreferences={() => {}}
              isLoading={!this.state.fetchRequest}
              canShowRowSelection={true}
              onRowClicked={(cell, row, e) => this.selectedRow1(row.original)}
              onChangeRowSelection={this.handleRowSelection}
            />
          </div>
        </div>

        <Modal
          backdrop='static'
          keyboard={true}
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggle}>Add New Widget</ModalHeader>
          <ModalBody>
            <div className='container'>
              <div className='row'>
                <div className='col-md-6'>
                  <div className='form-group'>
                    <label htmlFor='firstName'>
                      Widget Name<span className='requiredField'>*</span>
                    </label>
                    <input
                      type='text'
                      className='form-control'
                      name='widgetName'
                      id='widgetName'
                      placeholder='Widget Name'
                      value={this.state.formData.widgetName}
                      onChange={(e) => {
                        this.handleChange(e);
                      }}
                    />
                    <span style={styleData} className='error'>
                      {this.state.formArr.widgetName && validation.errors.first('widgetName')}
                    </span>
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='form-group'>
                    <label htmlFor='firstName'>
                      Widget Code<span className='requiredField'>*</span>
                    </label>
                    <input
                      type='text'
                      className='form-control'
                      name='widgetCode'
                      id='widgetCode'
                      placeholder='Widget Code'
                      value={this.state.formData.widgetCode}
                      onChange={(e) => {
                        this.handleChange(e);
                      }}
                    />
                    <span style={styleData} className='error'>
                      {this.state.formArr.widgetCode && validation.errors.first('widgetCode')}
                    </span>
                  </div>
                </div>
              </div>

              <div className='row'>
                <div className='col-md-6'>
                  <div className='form-group'>
                    <label>
                      Module code<span className='requiredField'>*</span>
                    </label>
                    <input
                      type='text'
                      className='form-control'
                      name='moduleCode'
                      id='moduleCode'
                      placeholder='Module code'
                      value={this.state.formData?.moduleCode}
                      onChange={(e) => {
                        this.handleChange(e);
                      }}
                    />
                    <span style={styleData} className='error'>
                      {this.state.formArr.moduleCode &&
                        validation.errors.first('moduleCode')}
                    </span>
                  </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <label>
                        Module Id
                      </label>
                      <input
                        type='text'
                        className='form-control'
                        name='moduleId'
                        id='moduleId'
                        placeholder='Module Id'
                        value={this.state.formData?.moduleId}
                        disabled={true}
                      />
                      
                    </div>
                </div>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <label>
                        Order<span className='requiredField'>*</span>
                      </label>
                      <input
                        type='text'
                        className='form-control'
                        name='orderId'
                        id='orderId'
                        placeholder='Order'
                        value={this.state.formData.orderId}
                        onChange={(e) => {
                          this.handleChange(e);
                        }}
                      />
                      <span style={styleData} className='error'>
                        {this.state.formArr.orderId && validation.errors.first('orderId')}
                      </span>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <label>
                        Disabled<span className='requiredField'>*</span>
                      </label>
                      <Checkbox
                        checked={this.state.formData.trash == true}
                        onChange={this.handleChange1.bind(this, 'trash')}
                        className='form-input'
                      />
                    </div>
                  </div>
                </div>
                <div className='col-md-12'>
                  <div className='form-group'>
                    <label htmlFor='widgetDescription'>
                      Description<span className='requiredField'>*</span>
                    </label>
                    <div className='row'>
                      <div className='col-md-12'>
                        <div className='form-group'>
                          <TextEditorField
                            name='widgetDescription'
                            value={this.state.formData.widgetDescription}
                            onChange={(e) => this.handleChange(e)}
                          />
                        </div>
                      </div>
                    </div>
                    <span style={styleData} className='error'>
                      {this.state.formArr.widgetDescription &&
                        validation.errors.first('widgetDescription')}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button className='btn btn-primary' onClick={(e) => this.cancelFun(e)}>
              Cancel
            </button>
            <button className='btn btn-primary' onClick={(e) => this.addWidgets(e)}>
              Submit
            </button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    widgetList: state.widgets?.widgetList,
    isWidgetListSuccess: state.widgets?.isWidgetListSuccess,

    isWidgetAdd: state.widgets?.isWidgetAdd,
    isWidgetAddSuccess: state.widgets?.isWidgetAddSuccess,
    isWidgetAddError: state.widgets?.isWidgetAddError,

    isWidgetDisable: state.widgets?.isWidgetDisable,
    isWidgetDisableSuccess: state.widgets?.isWidgetDisableSuccess,
    isWidgetDisableError: state.widgets?.isWidgetDisableError,

    success: state.widgets?.success,
    message: state.widgets?.message,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    createWidgetsAction,
    disableWidgetsAction,
    getAllWidgetsAction,
  })(WidgetsList)
);
