import * as actionTypes from './actionTypes';
import * as API from '../api/notificationPreferences';


import { notification } from 'antd';

export const getNotificationPreferencesRequest = () => ({
  type: actionTypes.GET_ALL_NOTIFICATION_PREFERENCES_REQUEST,
});

export const getNotificationPreferencesSuccess = (response) => ({
  type: actionTypes.GET_ALL_NOTIFICATION_PREFERENCES_SUCCESS,
  payload: {
    response,
  },
});

export const getNotificationPreferencesError = (error) => ({
  type: actionTypes.GET_ALL_NOTIFICATION_PREFERENCES_ERROR,
  payload: {
    error,
  },
});

export const getNotificationPreferencesAction = (data) => {
  return (dispatch) => {
    dispatch(getNotificationPreferencesRequest());
    return API.getNotificationPreferences(data)
      .then((response) => {
        
        dispatch(getNotificationPreferencesSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          getNotificationPreferencesError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

export const setNotificationPreferencesRequest = () => ({
  type: actionTypes.SET_NOTIFICATION_PREFERENCE_REQUEST,
});

export const setNotificationPreferencesSuccess = (response) => ({
  type: actionTypes.SET_NOTIFICATION_PREFERENCE_SUCCESS,
  payload: {
    response,
  },
});

export const setNotificationPreferencesError = (error) => ({
  type: actionTypes.SET_NOTIFICATION_PREFERENCE_ERROR,
  payload: {
    error,
  },
});

export const setNotificationPreferencesAction = (data) => {
  return (dispatch) => {
    dispatch(setNotificationPreferencesRequest());
    return API.setNotificationPreferences(data)
      .then((response) => {
        notification.success({ message: response.data.result.msg });
        dispatch(setNotificationPreferencesSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          setNotificationPreferencesError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};
