import { useDispatch } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { getTemporaryProjects, removeTemporaryProject } from '../../../modules/projects';
import { notification } from 'antd';
import { useSelector } from 'react-redux';
import { selectRemoveTemporaryProject } from '../../../modules/projects/selectors';

const RemoveTemporaryProjectModal = ({ open = false, onClose = () => {}, project = null }) => {
  const dispatch = useDispatch();

  const { loading } = useSelector(selectRemoveTemporaryProject);

  const onContinueClick = async () => {
    await dispatch(removeTemporaryProject(project._id)).unwrap();
    notification.info({ message: 'Project deleted!' });
    onClose();
    dispatch(getTemporaryProjects());
  };

  if (!project) return null;

  return (
    <Modal isOpen={open} toggle={onClose}>
      <ModalHeader toggle={onClose}>Remove project</ModalHeader>
      <ModalBody>
        Do you want to remove <b>{project.jobName}</b> without saving?
      </ModalBody>
      <ModalFooter>
        <button className='btn btn-primary' onClick={onClose}>
          Cancel
        </button>
        <button disabled={loading} className='btn btn-primary' onClick={onContinueClick}>
          Confirm
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default RemoveTemporaryProjectModal;
