import { joiResolver } from '@hookform/resolvers/joi';
import {
  useEffect,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
  useMemo,
  useCallback,
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { getExistingProjectAction } from '../../actions/project';
import {
  createProject,
  getProjectCustomFieldsValuesForm,
  getUpdateProjectForm,
  updateProject,
} from '../../modules/projects';
import { selectAllLeadJobStatusOptions } from '../../selectors/jobStatusCode';
import { selectProjectButtonActive, selectProjectCreateSuccess } from '../../selectors/project';
import { useAppDispatch } from '../../shared/hooks/use-app-dispatch';
import { useAppSelector } from '../../shared/hooks/use-app-selector';
import { useSubmit } from '../../shared/hooks/use-submit';
import { Minimap } from '../../shared/minimap';
import { formatDateObjectOrNull } from '../../utils/date-formatters';
import { appConstants } from '../../_constants';
import styles from './add-lead-modal.module.css';
import { AddLeadModalSchema, addLeadModalSchema } from './add-lead-modal.schema';
import { LeadDealDetails } from './sections/lead-deal-details';
import { LeadInformation } from './sections/lead-information';
import { LeadJobAddress } from './sections/lead-job-address';
import { ProjectCompanyTeam } from '../add-project-modal/sections/project-company-team';
import { LeadMisc } from './sections/lead-misc';
import { LeadProjectDates } from './sections/lead-project-dates';
import { getProjectRoles } from '../../modules/settings';
import { Userpilot } from 'userpilot';
import { ProjectCustomFieldsForSection } from '../add-project-modal/project-custom-fields';
import { LeadProjectInfo } from './sections/lead-project-info';
import { getAccountProjectSettings } from '../../modules/accounts';
import { getAllJobScopeAction } from '../../actions/jobScope';
import { getAllJobStatusAction } from '../../actions/jobStatusCode';
import { numberToCurrency, currencyToNumber } from '../../utils/number-formatters';
import { ProjectCustomSection } from '../add-project-modal/sections/project-custom-section';
import moment from 'moment';

type AddLeadModalProps = {
  open: boolean;
  showSaveAndNext: boolean;
  onClose: () => void;
  projectId?: string;
  onSubmit?: (...args) => Promise<any>;
  onSubmitAndAdd?: (...args) => Promise<any>;
  initialSection?: string;
  isImport?: boolean;
};

export const AddLeadModal = forwardRef(
  (
    {
      open,
      showSaveAndNext = true,
      onClose,
      projectId,
      onSubmit: onSubmitCb = () => Promise.resolve(),
      onSubmitAndAdd: onSubmitAndAddCb = () => Promise.resolve(),
      initialSection = '',
      isImport = false,
    }: AddLeadModalProps,
    ref
  ) => {
    const dispatch = useAppDispatch();

    useImperativeHandle(ref, () => ({ getProjectData, setProjectData }), []);

    const [companyName] = useState(() => localStorage.getItem('companyName'));

    const minimapRef = useRef() as React.MutableRefObject<HTMLDivElement>;

    const projectButtonActive = useAppSelector(selectProjectButtonActive);
    const projectCreateSuccess = useAppSelector(selectProjectCreateSuccess);

    const isUpdate = !!projectId;
    const curUserId = localStorage.getItem('userId');
    const userAffiliationId = localStorage.getItem('userContactAffiliationId');

    const [accountSettings, setAccountSettings] = useState<any>({});
    const [isAssigned, setIsAssigned] = useState(false);
    const accountId = localStorage.getItem('accountId');

    const allJobStatuses = useAppSelector(selectAllLeadJobStatusOptions);
    const [, setLeadStatusCode] = useState([]);

    const defaultValues = {
      jobName: '',
      leadSource: '',
      leadCohort: '',
      leadDate: null,
      marketingDescription: '',
      bidDue: null,
      startDate: null,
      endDate: null,
      jobAddress: '',
      jobAddress2: '',
      jobCity: '',
      jobState: '',
      jobZip: '',
      lat: 0,
      lng: 0,
      jobCountry: '',
      sqFeet: null,
      apNum: '',
      estimateBidAmount: '',
      estimateGrossProfit: '',
      negotiationMethod: '',
      scope: [],
      buildingType: '',
      occupancyType: '',
      jobNotes: '',
      accountId: accountId,
      createdBy: curUserId,
      userId: curUserId,
      jobStatusCodes: null,
      moduleName: 'New Lead Entered',
      isBondRqd: false,
      isRef: false,
      objectId: '',
      projectTeam: {},
      createdFrom: 'WEB',
      timezone: '',
    };

    const form = useForm<AddLeadModalSchema>({
      defaultValues,
      resolver: joiResolver(addLeadModalSchema),
      context: { accountSettings },
    });

    const [customFields, setCustomFields] = useState<any[]>([]);
    const customFieldsForm = useForm();
    const customFormValues = customFieldsForm.watch();

    const [projectRoles, setProjectRoles] = useState([]);

    const [minimapActiveSection, setMinimapActiveSection] = useState<string>('');

    useEffect(() => {
      if (!open) {
        return;
      }

      if (allJobStatuses.length > 0 && !isUpdate) {
        const leadStatusCodeLocal = allJobStatuses?.find(
          (status) => status.code === appConstants.jobStatusCode.L
        )?.value;

        if (leadStatusCodeLocal) {
          setLeadStatusCode(leadStatusCodeLocal);
          form.resetField('jobStatusCodes', { defaultValue: leadStatusCodeLocal });
        }
      } else {
        return;
      }
    }, [allJobStatuses, open, isUpdate]);

    useEffect(() => {
      if (!open) {
        return;
      }

      if (projectId) {
        dispatch(getExistingProjectAction({ projectId }));
      }

      dispatch(getProjectRoles())
        .unwrap()
        .then((data) => {
          setProjectRoles(data);
        });

      dispatch(getAllJobStatusAction({ className: 'jobStatusCodes' }));
      dispatch(getAllJobScopeAction({ className: 'jobScopes' }));

      dispatch(getAccountProjectSettings({}))
        .unwrap()
        .then((data) => {
          setAccountSettings({ ...data });
        });

      dispatch(getProjectCustomFieldsValuesForm({ projectId }))
        .unwrap()
        .then((data) => {
          const defaultValues = data.reduce((acc, field) => {
            if (field.valueDefinition.isUrl) {
              acc[field._id] = {
                label: field.valueDefinition.defaultValue,
                url: field.valueDefinition.defaultValueUrl,
              };
              return acc;
            }

            acc[field._id] = field.valueDefinition.defaultValue;
            return acc;
          }, {});

          if (!isUpdate) customFieldsForm.reset(defaultValues);

          data = data.sort((a, b) => {
            if (a.section === b.section) {
              return a.order - b.order;
            }

            return a.section.localeCompare(b.section);
          });

          let lastSection = '';
          let currentDivider = null;
          for (const field of data) {
            if (field.controlType === 'DIVIDER') {
              lastSection = field.section;
              currentDivider = field.name;
              field.currentDivider = currentDivider;

              if (field.valueDefinition.criteria) {

                setDividerRules((prev) => ({
                  ...prev,
                  [field.name]: { criteriaCode: field.valueDefinition.criteria, criteriaValue: field.valueDefinition.criteriaValue?.map((criteriaValue) => criteriaValue._id) },
                }));
              }
            }

            if (currentDivider && field.section == lastSection) {
              field.currentDivider = currentDivider;
            } else {
              field.currentDivider = field.section;
              currentDivider = field.section;
              lastSection = field.section;
            }
          }

          setCustomFields(data);
        });
    }, [dispatch, open, isUpdate]);

    const [dividerRules, setDividerRules] = useState({});
    useEffect(() => {
      dividerRules && Object.keys(dividerRules).forEach((divider) => {
        const customFieldCriteriaId = (customFields.find((field: any) => field.code === dividerRules[divider].criteriaCode) as any)?._id;
        dividerRules[divider].criteria = customFieldCriteriaId;
      });

    }, [customFields]);

    const [dividerHidden, setDividerHidden] = useState({});

    const handleDividerHidden = useCallback((divider, newVal) => {
      setDividerHidden((prev) => ({
        ...prev,
        [divider]: newVal,
      }));
    }, []);



    useEffect(() => {
      dividerRules && Object.keys(dividerRules).forEach((divider) => {
        const rule = dividerRules[divider];
        if (rule.criteria) {
          const { criteria, criteriaValue } = rule;

          const value = customFormValues[criteria];
          if (Array.isArray(value)) {
            const hidden = !criteriaValue.some((v) => value.includes(v));
            if (dividerHidden[divider] !== hidden)
              handleDividerHidden(divider, hidden);
          } else if (typeof value === 'string') {
            const hidden = !criteriaValue.includes(value);
            if (dividerHidden[divider] !== hidden)
              handleDividerHidden(divider, hidden);
          } else {
            if (dividerHidden[divider] !== true)
              handleDividerHidden(divider, true);
          }
        }
      })
    }, [customFormValues]);

    useEffect(() => {
      if (!open) {
        return;
      }

      form.reset(form.formState.defaultValues);

      if (!isUpdate) {
        return;
      }

      if (!isImport) {
        getProjectData();
      }

      setMinimapActiveSection('');
    }, [dispatch, form, open, projectCreateSuccess, isUpdate]);

    const setProjectData = (projectData) => {
      form.reset({
        jobName: projectData.jobName || '',
        leadSource: projectData.leadSource || '',
        leadCohort: projectData.leadCohort || '',
        leadDate: formatDateObjectOrNull(projectData.leadDate),
        marketingDescription: projectData.marketingDescription || '',
        bidDue: formatDateObjectOrNull(projectData.bidDue),
        startDate: formatDateObjectOrNull(projectData.startDate),
        endDate: formatDateObjectOrNull(projectData.endDate),
        jobAddress: projectData.jobAddress || '',
        jobAddress2: projectData.jobAddress2 || '',
        jobCity: projectData.jobCity || '',
        jobState: projectData.jobState || '',
        jobZip: projectData.jobZip || '',
        jobCountry: projectData.jobCountry || '',
        apNum: projectData.apNum || '',
        lat: projectData.lat || 0,
        lng: projectData.lng || 0,
        sqFeet: projectData.sqFeet || null,
        estimateBidAmount: numberToCurrency(projectData.estimateBidAmount) || '',
        estimateGrossProfit: numberToCurrency(projectData.estimateGrossProfit) || '',
        negotiationMethod: projectData.negotiationMethod || '',
        scope: projectData.scope || [],
        buildingType: projectData.buildingType || '',
        occupancyType: projectData.occupancyType || '',
        jobNotes: projectData.jobNotes || '',
        accountId: accountId,
        createdBy: curUserId,
        userId: curUserId,
        jobStatusCodes: projectData.jobStatusCodes,
        moduleName: projectData.moduleName || 'New Lead Entered',
        isBondRqd: !!projectData.isBondRqd,
        isRef: !!projectData.isRef,
        objectId: projectData.objectId || '',
        projectTeam: projectData.projectTeam || {},
        createdFrom: projectData.createdFrom || 'WEB',
        timezone: projectData.timezone || '',
      });

      if (projectData.customFields) {
        customFieldsForm.reset(projectData.customFields, { keepDefaultValues: true });
      }

      let allAssignments: any[] = [];
      for (const role in projectRoles as any) {
        const roleId: any = projectRoles[role]._id as string;
        allAssignments = allAssignments.concat(projectData.projectTeam[roleId] as Array<string>);
      }
      const isCreator = projectData?.createdBy === curUserId;

      setIsAssigned(allAssignments.includes(userAffiliationId) || isCreator);
    };

    const getProjectData = () => {
      dispatch(getUpdateProjectForm({ projectId }))
        .unwrap()
        .then((data) => setProjectData(data))
        // set active section after data loaded because fields might be shifting
        .then(() => setMinimapActiveSection(initialSection));
    };

    const customDateFieldsRefs = useRef<Array<any>>([]);

    const bidDue = form.watch('bidDue');
    const startDate = form.watch('startDate');
    const endDate = form.watch('endDate');

    const [resetKey, setResetKey] = useState(0);

    useEffect(() => {
      if (!open) return;

      customDateFieldsRefs.current = [];
    }, [open]);

    // todo: find better way to pass project props into custom fields
    useEffect(() => {
      customDateFieldsRefs.current.forEach((ref) => {
        const fieldsData = { bidDue, startDate, endDate };

        ref.onUpdate(fieldsData);
      });
    }, [customDateFieldsRefs, bidDue, startDate, endDate]);

    const submitProject = async (isSaveAndAddNext = false) => {
      let projectData: any = null;
      let customFieldsData: any = null;
      await form.handleSubmit(async (data) => {
        projectData = data;
      }, console.log)();
      await customFieldsForm.handleSubmit(async (data) => {
        customFieldsData = data;
      }, console.log)();

      if (!projectData || !customFieldsData) {
        throw new Error('Invalid form data');
      }

      projectData.estimateBidAmount = currencyToNumber(projectData.estimateBidAmount);
      projectData.estimateGrossProfit = currencyToNumber(projectData.estimateGrossProfit);

      const project = await dispatch(
        isUpdate
          ? updateProject({ ...projectData, customFields: customFieldsData, projectId })
          : createProject({
            ...projectData,
            customFields: customFieldsData,
            isSaveAndAddNext,
          })
      ).unwrap();

      if (!isUpdate) {
        Userpilot.track('Added Lead');
      } else {
        Userpilot.track('Edited Lead');
      }

      
      form.reset({
        ...form.formState.defaultValues,
      });

      setResetKey((prev) => prev + 1);

      customFieldsForm.reset();

      return project;
    };

    const [onSubmit, submitting] = useSubmit(async () => {
      const project = await submitProject();

      await dispatch(getExistingProjectAction({ projectId }));

      onClose();
      onSubmitCb(project);
    }, [isUpdate, projectId]);

    const [onSubmitAndAdd, submittingAndAdd] = useSubmit(async () => {
      const project = await submitProject(true);

      onSubmitAndAddCb(project);
    });

    const [internalRoles, externalRoles] = useMemo(() => {
      const internalRoles = projectRoles.filter(
        (role: any) => role.isShowOnLeads && role.type === 'internal' && role.code !== 'AX'
      );
      const externalRoles = projectRoles.filter(
        (role: any) => role.isShowOnLeads && role.type === 'external'
      );

      return [internalRoles, externalRoles];
    }, [projectRoles]);

    const projectStage = 'lead';

    const belongToCurrentStage = useCallback(
      ({ stages }: { stages: Array<string> }) =>
        stages.includes('all') || stages.includes(projectStage),
      [projectStage]
    );

    const [
      generalFields,
      locationFields,
      marketingFields,
      dealFields,
      additionalFields,
      generalFieldsCustomSection,
      locationFieldsCustomSection,
      marketingFieldsCustomSection,
      dealFieldsCustomSection,
      additionalFieldsCustomSection,
    ] = useMemo(() => {
      const generalFields = customFields.filter((field: any) => field.currentDivider === 'general');
      const locationFields = customFields.filter(
        (field: any) => field.currentDivider === 'location'
      );
      const marketingFields = customFields.filter(
        (field: any) => field.currentDivider === 'marketing'
      );
      const dealFields = customFields.filter((field: any) => field.currentDivider === 'deal');
      const additionalFields = customFields.filter(
        (field: any) => field.currentDivider === 'additional'
      );

      const generalFieldsCustom = customFields.filter(
        (field: any) => field.section === 'general' && field.currentDivider !== 'general'
      );
      const locationFieldsCustom = customFields.filter(
        (field: any) => field.section === 'location' && field.currentDivider !== 'location'
      );
      const marketingFieldsCustom = customFields.filter(
        (field: any) => field.section === 'marketing' && field.currentDivider !== 'marketing'
      );
      const dealFieldsCustom = customFields.filter(
        (field: any) => field.section === 'deal' && field.currentDivider !== 'deal'
      );
      const additionalFieldsCustom = customFields.filter(
        (field: any) => field.section === 'additional' && field.currentDivider !== 'additional'
      );

      const generalFieldsCustomSection = generalFieldsCustom.reduce((acc, field: any) => {
        if (field.controlType !== 'DIVIDER' && belongToCurrentStage(field)) {
          if (!acc[field.currentDivider]) {
            acc[field.currentDivider] = [];
          }

          acc[field.currentDivider].push(field);
        }

        return acc;
      }, {});

      const locationFieldsCustomSection = locationFieldsCustom.reduce((acc, field: any) => {
        if (field.controlType !== 'DIVIDER' && belongToCurrentStage(field)) {
          if (!acc[field.currentDivider]) {
            acc[field.currentDivider] = [];
          }

          acc[field.currentDivider].push(field);
        }

        return acc;
      }, {});

      const marketingFieldsCustomSection = marketingFieldsCustom.reduce((acc, field: any) => {
        if (field.controlType !== 'DIVIDER' && belongToCurrentStage(field)) {
          if (!acc[field.currentDivider]) {
            acc[field.currentDivider] = [];
          }

          acc[field.currentDivider].push(field);
        }

        return acc;
      }, {});

      const dealFieldsCustomSection = dealFieldsCustom.reduce((acc, field: any) => {
        if (field.controlType !== 'DIVIDER' && belongToCurrentStage(field)) {
          if (!acc[field.currentDivider]) {
            acc[field.currentDivider] = [];
          }

          acc[field.currentDivider].push(field);
        }

        return acc;
      }, {});

      const additionalFieldsCustomSection = additionalFieldsCustom.reduce((acc, field: any) => {
        if (field.controlType !== 'DIVIDER' && belongToCurrentStage(field)) {
          if (!acc[field.currentDivider]) {
            acc[field.currentDivider] = [];
          }

          acc[field.currentDivider].push(field);
        }

        return acc;
      }, {});

      return [
        generalFields,
        locationFields,
        marketingFields,
        dealFields,
        additionalFields,
        generalFieldsCustomSection,
        locationFieldsCustomSection,
        marketingFieldsCustomSection,
        dealFieldsCustomSection,
        additionalFieldsCustomSection,
      ];
    }, [customFields, projectStage]);

    const shouldShowAdditionalInformation = useMemo(() => {
      const should = (customFields.filter((field: any) => field.section === 'additional') ?? []).filter(belongToCurrentStage).length > 0;

      return !!should;
    }, [customFields, projectStage]);

    const shouldShowMarketingInformation = useMemo(() => {
      const should = marketingFields.find((field: any) => field.section === 'marketing');

      return !!should;
    }, [marketingFields, projectStage]);

    useEffect(() => {
      // set lead date to today
      form.setValue('leadDate', moment());
    },[resetKey]);

    const shouldShowDealInformation = useMemo(() => {
      const should = dealFields.find((field: any) => field.section === 'deal');

      return !!should;
    }, [dealFields, projectStage]);

    const validationError = useMemo(() => {
      const errors = [
        ...Object.values(form.formState.errors),
        ...Object.values(customFieldsForm.formState.errors),
      ];

      return <span className='text-danger'>{errors[0]?.message as string}</span>;
    }, [form.formState.errors, customFieldsForm.formState.errors]);

    return (
      <Modal className={styles.modalDialog} backdrop='static' isOpen={open} toggle={onClose}>
        <ModalHeader toggle={onClose}>{isUpdate ? 'Edit project' : 'Add lead'} </ModalHeader>
        <ModalBody>
          <div className='container' key={'reset'+resetKey.toString()}>
            <div className='row'>
              <div className='col-md-4' ref={minimapRef}>
                <div className={styles.preloader}>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
              <FormProvider {...form}>
                <div className='col-md-8'>
                  <Minimap
                    className='pe-md-4'
                    minimapWrapper={minimapRef.current}
                    minimapClassName='d-none d-md-block'
                    initialSection={minimapActiveSection}
                  >
                    <div id='project-information' data-minimap-area-label='Lead Information' key={resetKey}>
                      <LeadInformation
                        projectRoles={projectRoles.filter((role: any) => role.code === 'AX')}
                        projectId={projectId}
                        isUpdate={isUpdate}
                        isAssigned={isAssigned}
                      />

                      <FormProvider {...customFieldsForm}>
                        <ProjectCustomFieldsForSection
                          fields={generalFields}
                          projectStage='lead'
                          customDateFieldsRefs={customDateFieldsRefs}
                        />
                      </FormProvider>
                    </div>
                    {generalFieldsCustomSection &&
                      (Object.keys(generalFieldsCustomSection) ?? []).map((currentDivider) => (
                        dividerHidden[currentDivider] ? null : (
                          <div
                            id={currentDivider.replaceAll(' ', '-').replace('&', 'and')}
                            key={currentDivider.replaceAll(' ', '-').replace('&', 'and')}
                            data-minimap-area-label={'- ' + currentDivider}
                          >
                            <ProjectCustomSection
                              sectionName={currentDivider}
                              customFields={
                                <FormProvider {...customFieldsForm}>
                                  <ProjectCustomFieldsForSection
                                    fields={generalFieldsCustomSection[currentDivider]}
                                    projectStage={projectStage}
                                    customDateFieldsRefs={customDateFieldsRefs}
                                  />
                                </FormProvider>
                              }
                            />
                          </div>
                        )))}
                    <div
                      id='location-and-description'
                      data-minimap-area-label='Location & Description'
                    >
                      <LeadJobAddress />

                      <FormProvider {...customFieldsForm}>
                        <ProjectCustomFieldsForSection
                          fields={locationFields}
                          projectStage='lead'
                          customDateFieldsRefs={customDateFieldsRefs}
                        />
                      </FormProvider>
                    </div>

                    {locationFieldsCustomSection &&
                      (Object.keys(locationFieldsCustomSection) ?? []).map((currentDivider) => (
                        dividerHidden[currentDivider] ? null : (<div
                          id={currentDivider.replaceAll(' ', '-').replace('&', 'and')}
                          key={currentDivider.replaceAll(' ', '-').replace('&', 'and')}
                          data-minimap-area-label={'- ' + currentDivider}
                        >
                          <ProjectCustomSection
                            sectionName={currentDivider}
                            customFields={
                              <FormProvider {...customFieldsForm}>
                                <ProjectCustomFieldsForSection
                                  fields={locationFieldsCustomSection[currentDivider]}
                                  projectStage={projectStage}
                                  customDateFieldsRefs={customDateFieldsRefs}
                                />
                              </FormProvider>
                            }
                          />
                        </div>
                      )))}
                    <div id='lead-project-dates' data-minimap-area-label='Dates & Dollars' key={resetKey}>
                      <LeadProjectDates accountSettings={accountSettings} isAssigned isUpdate />
                    </div>

                    {shouldShowDealInformation && !!dealFields?.length && (
                    <div id='deal-information' data-minimap-area-label='Deal Information' key={resetKey}>
                        <LeadDealDetails />

                        <FormProvider {...customFieldsForm}>
                          <ProjectCustomFieldsForSection
                            fields={dealFields}
                            projectStage='lead'
                            customDateFieldsRefs={customDateFieldsRefs}
                          />
                        </FormProvider>
                      </div>
                    )}

                    {dealFieldsCustomSection &&
                      (Object.keys(dealFieldsCustomSection) ?? []).map((currentDivider) => (
                        dividerHidden[currentDivider] ? null : (
                        <div
                          id={currentDivider.replaceAll(' ', '-').replace('&', 'and')}
                          key={currentDivider.replaceAll(' ', '-').replace('&', 'and')}
                          data-minimap-area-label={'- ' + currentDivider}
                        >
                          <ProjectCustomSection
                            sectionName={currentDivider}
                            customFields={
                              <FormProvider {...customFieldsForm}>
                                <ProjectCustomFieldsForSection
                                  fields={dealFieldsCustomSection[currentDivider]}
                                  projectStage={projectStage}
                                  customDateFieldsRefs={customDateFieldsRefs}
                                />
                              </FormProvider>
                            }
                          />
                        </div>
                      )))}
                    {shouldShowMarketingInformation && !!marketingFields?.length && (
                      <div
                        id='marketing-information'
                        data-minimap-area-label='Marketing Information'
                        key={resetKey}
                      >
                        <LeadProjectInfo />

                        <FormProvider {...customFieldsForm}>
                          <ProjectCustomFieldsForSection
                            fields={marketingFields}
                            projectStage='lead'
                            customDateFieldsRefs={customDateFieldsRefs}
                          />
                        </FormProvider>
                      </div>
                    )}

                    {marketingFieldsCustomSection &&
                      (Object.keys(marketingFieldsCustomSection) ?? []).map((currentDivider) => (
                        dividerHidden[currentDivider] ? null : (
                        <div
                          id={currentDivider.replaceAll(' ', '-').replace('&', 'and')}
                          key={currentDivider.replaceAll(' ', '-').replace('&', 'and')}
                          data-minimap-area-label={'- ' + currentDivider}
                        >
                          <ProjectCustomSection
                            sectionName={currentDivider}
                            customFields={
                              <FormProvider {...customFieldsForm}>
                                <ProjectCustomFieldsForSection
                                  fields={marketingFieldsCustomSection[currentDivider]}
                                  projectStage={projectStage}
                                  customDateFieldsRefs={customDateFieldsRefs}
                                />
                              </FormProvider>
                            }
                          />
                        </div>
                      )))}

                    {shouldShowAdditionalInformation && (
                      <div
                        id='additional-information'
                        data-minimap-area-label='Additional Information'
                        key={resetKey}
                      >
                        <LeadMisc />

                        <FormProvider {...customFieldsForm}>
                          <ProjectCustomFieldsForSection
                            fields={additionalFields}
                            projectStage='lead'
                            customDateFieldsRefs={customDateFieldsRefs}
                          />
                        </FormProvider>
                      </div>
                    )}

                    {additionalFieldsCustomSection &&
                      (Object.keys(additionalFieldsCustomSection) ?? []).map((currentDivider) => (
                        dividerHidden[currentDivider] ? null : (
                        <div
                          id={currentDivider.replaceAll(' ', '-').replace('&', 'and')}
                          key={currentDivider.replaceAll(' ', '-').replace('&', 'and')}
                          data-minimap-area-label={'- ' + currentDivider}
                        >
                          <ProjectCustomSection
                            sectionName={currentDivider}
                            customFields={
                              <FormProvider {...customFieldsForm}>
                                <ProjectCustomFieldsForSection
                                  fields={additionalFieldsCustomSection[currentDivider]}
                                  projectStage={projectStage}
                                  customDateFieldsRefs={customDateFieldsRefs}
                                />
                              </FormProvider>
                            }
                          />
                        </div>
                      )))}

                    {!!internalRoles?.length && (
                      <div id='company-team' data-minimap-area-label={`${companyName} team`}>
                        <ProjectCompanyTeam
                          projectRoles={internalRoles}
                          isInternal
                          isLead
                          isUpdate
                          isAssigned
                        />
                      </div>
                    )}

                    {!!externalRoles?.length && (
                      <div id='external-team' data-minimap-area-label='External Contacts'>
                        <ProjectCompanyTeam
                          projectRoles={externalRoles}
                          isLead
                          isUpdate
                          isAssigned
                        />
                      </div>
                    )}
                  </Minimap>
                </div>
              </FormProvider>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          {validationError}
          <button type='button' className='btn btn-primary' onClick={onClose}>
            Cancel
          </button>
          <button
            type='button'
            className='btn btn-primary'
            disabled={submitting || submittingAndAdd || projectButtonActive}
            onClick={onSubmit}
          >
            Save
          </button>
          {!isUpdate && showSaveAndNext && (
            <button
              className='btn btn-primary'
              disabled={submitting || submittingAndAdd || projectButtonActive}
              onClick={onSubmitAndAdd}
            >
              Save & Add Next Lead
            </button>
          )}
        </ModalFooter>
      </Modal>
    );
  }
);
