import * as actionTypes from '../actions/actionTypes';

const initState = {
  searchData: {},
  message: '',
  isSearchData: false,
  isSearchDataError: false,
};

export default (state = initState, action = {}) => {
  switch (action.type) {
    case actionTypes.SEARCH_REQUEST:
      return {
        ...state,
        searchData: {},
        message: '',
        isSearchData: false,
        isSearchDataError: false,
      };
    case actionTypes.SEARCH_SUCCESS:
      return {
        ...state,
        searchData: action.payload.response.result.data,
        message: 'Search Data Listed',
        isSearchData: true,
        isSearchDataError: false,
      };

    case actionTypes.SEARCH_ERROR:
      return {
        ...state,
        message: '',
        isSearchData: false,
        isSearchDataError: true,
      };
    default:
      return state;
  }
};
