import { useCallback, useEffect, useState } from 'react';
import {
  disableJobStatusCode,
  enableJobStatusCode,
  getJobStatusCodesSettings,
} from '../../../modules/settings';
import { ColorPickerButton } from '../../../shared/color-picker';
import { useConfirm } from '../../../shared/confirmation-dialog';
import { useAppDispatch } from '../../../shared/hooks/use-app-dispatch';
import { EditJobStatusCodeModal } from './edit-job-status-code-modal';
import { JobStatusCode } from '@module/settings';
import { usePageTitle } from '../../../layouts/title-context';
import { SettingsCodesLayoutNext } from '../../../layouts/settings-codes-layout/settings-codes-layout-next';

const columns = [
  {
    Header: 'Code',
    accessor: 'jobStatusCodesCode',
    width: 100,
    searchable: true,
  },
  {
    Header: 'Name',
    accessor: 'jobStatusCodesName',
    width: 250,
    searchable: true,
  },
  {
    Header: 'Definition',
    accessor: 'jobStatusCodesDefinition',
    width: 300,
    Cell: ({ value }: any) => {
      return <div className='text-truncate'>{value}</div>;
    },
    searchable: true,
  },
  {
    Header: 'Display Color',
    accessor: 'jobColorCode',
    minWidth: 250,
    maxWidth: 500,
    Cell: ({ value }: any) => {
      return <ColorPickerButton value={value} />;
    },
  },
];

export const JobStatusCodesPage = () => {
  const dispatch = useAppDispatch();
  usePageTitle('Job Status Code Settings');

  const confirm = useConfirm();

  const [rows, setRows] = useState<any[]>([]);
  const [editModalOpen, setEditModalOpen] = useState(false);

  const [statusCodeId, setStatusCodeId] = useState<string | null>(null);

  const refreshTable = async () => {
    const data = await dispatch(getJobStatusCodesSettings({ includeDisabled: true })).unwrap();

    setRows(data);
  };

  useEffect(() => {
    refreshTable();
  }, []);

  const onToggle = useCallback(async (trash: boolean, rowValue: any) => {
    if (trash) {
      const ok = await confirm({
        title: 'Disable Job Status Code',
        question: 'Are you sure you want to disable this job status code?',
      });

      if (!ok) return;

      await dispatch(disableJobStatusCode({ statusCodeId: rowValue._id })).unwrap();
    } else {
      await dispatch(enableJobStatusCode({ statusCodeId: rowValue._id })).unwrap();
    }

    refreshTable();
  }, []);

  const onRowClick = useCallback((row: JobStatusCode) => {
    if (row.trash) return;

    setStatusCodeId(row._id);
    setEditModalOpen(true);
  }, []);

  return (
    <>
      <SettingsCodesLayoutNext
        tableTitle='Job Status Codes'
        columns={columns}
        rows={rows}
        loading={false}
        onToggle={onToggle}
        onRowClick={onRowClick}
      />

      <EditJobStatusCodeModal
        statusCodeId={statusCodeId}
        open={editModalOpen}
        onClose={() => setEditModalOpen(false)}
        onSubmit={() => refreshTable()}
      />
    </>
  );
};
