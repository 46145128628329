import * as actionTypes from '../../actions/Admin/actionTypes';

const initState = {
  OccupancyType: {},
  allOccupancyType: [],
  message: '',
  isfetched: false,
  isCreated: false,
  success: null,
};

export default (state = initState, action = {}) => {
  switch (action.type) {
    case actionTypes.CREATE_OCCUPANCY_TYPE_REQUEST:
      return {
        ...state,
        OccupancyType: {},
        message: '',
      };
    case actionTypes.CREATE_OCCUPANCY_TYPE_SUCCESS:
      return {
        allOccupancyType: state.allOccupancyType.concat(action.payload.response.result.data),
        isCreated: true,
        success: action.payload.response.result.success,
        message: action.payload.response.result.success
          ? 'Record Added successfully'
          : action.payload.response.result.msg,
      };

    case actionTypes.CREATE_OCCUPANCY_TYPE_ERROR:
      return {
        ...state,
        isCreated: false,
        success: action.payload.error.success,
        message: action.payload.error.msg,
      };

    case actionTypes.GET_ALL_OCCUPANCY_TYPE_REQUEST:
      return {
        ...state,
        OccupancyType: {},
        message: '',
      };

    case actionTypes.GET_ALL_OCCUPANCY_TYPE_SUCCESS:
      return {
        ...state,
        allOccupancyType: action.payload.response.result.data.data,
      };

    case actionTypes.GET_ALL_OCCUPANCY_TYPE_ERROR:
      return {
        ...state,
        message: '',
      };

    case actionTypes.UPDATE_OCCUPANCY_TYPE_REQUEST:
      return {
        ...state,
        OccupancyType: {},
        message: '',
      };
    case actionTypes.UPDATE_OCCUPANCY_TYPE_SUCCESS:
      return {
        ...state,
        allOccupancyType: action.payload.response.result.allOccupancyType,
        isCreated: true,
        success: action.payload.response.result.success,
        message: action.payload.response.result.success
          ? 'Record Updated successfully'
          : action.payload.response.result.msg,
      };

    case actionTypes.UPDATE_OCCUPANCY_TYPE_ERROR:
      return {
        ...state,
        isCreated: false,
        success: action.payload.error.success,
        message: action.payload.error.msg,
      };

    case actionTypes.DELETE_OCCUPANCY_TYPE_REQUEST:
      return {
        ...state,
        OccupancyType: {},
        message: '',
      };
    case actionTypes.DELETE_OCCUPANCY_TYPE_SUCCESS:
      return {
        ...state,
        allOccupancyType: action.payload.response.result.allOccupancyType,
        isCreated: true,
        success: action.payload.response.result.success,
        message: action.payload.response.result.success
          ? 'Record Deleted successfully'
          : action.payload.response.result.msg,
      };

    case actionTypes.DELETE_OCCUPANCY_TYPE_ERROR:
      return {
        ...state,
        isCreated: false,
        success: action.payload.error.success,
        message: action.payload.error.msg,
      };

    default:
      return state;
  }
};
