import * as actionTypes from './actionTypes';
import * as API from '../api/search';

//get all user details
export const searchRequest = () => ({
  type: actionTypes.SEARCH_REQUEST,
});

export const searchSuccess = (response) => ({
  type: actionTypes.SEARCH_SUCCESS,
  payload: {
    response,
  },
});

export const searchError = (error) => ({
  type: actionTypes.SEARCH_ERROR,
  payload: {
    error,
  },
});

export const searchAction = (data) => {
  return async (dispatch) => {
    dispatch(searchRequest());
    return await API.getSearchData(data)
      .then((response) => {
        dispatch(searchSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          searchError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

// export const searchAction = (data) => {
// 	const CancelToken = axios.CancelToken;
// 	const source = CancelToken.source();
//     return dispatch => {
//         dispatch(searchRequest())
//
//         let params = data;
//         params.cancelToken = source.token;
//         let headers = {};
//        headers =   { headers : {
// 			    'crossDomain': true,
// 			    "X-Parse-Application-Id": appConstants.PARSE_APPLICATION_ID,
// 			    "X-Parse-REST-API-Key": appConstants.PARSE_REST_API_KEY,
// 			    "X-Parse-Session-Token": localStorage.getItem("sessionToken"),
// 			    "authorization":localStorage.getItem("JWTTOKEN")
// 			  }};
// 	    let url = `${appConstants.WEB_SERVICE_URL}/`+"functions/globalSearching";
//        return axios.post(url, params, headers,2000)
// 		.then(response => {
// 			if(axios.isCancel(response)){
// 		    console.log('im canceled GGGGGGGGGGGGGGGGGG');
// 		  }
// 		  else{
// 		    	console.log('SSSSSSSSSSSSSSSSSSSSSSSSS');
//                 dispatch(searchSuccess(response.data))
// 		  }
//
//          })
// 		.catch(function(err){
// 		  if(axios.isCancel(err)){
// 		    console.log('im canceled FFFFFFFFFF');
// 		  }
// 		  else{
// 		    console.log('im server response error');
// 		    dispatch(searchError(err.response !== undefined ? err.response.data.error : "Internet Connection Error"))
// 		  }
// 		});
// 		if (source) {
// 	      source.cancel('Operation canceled by the user.');
// 	    }
//     }
//
// }
