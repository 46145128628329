import { Controller, useFormContext } from 'react-hook-form';
import { ColorPicker } from '../../color-picker';

export const FormColorPicker = ({
  name,
  control,
  'aria-label': ariaLabel,
  label,
  className,
}: any) => {
  const form = useFormContext();

  if (!control && !form.control)
    throw new Error(
      'FormColorPicker: component must be wrapped in FormProvider or pass control prop'
    );

  return (
    <Controller
      name={name}
      control={control || form.control}
      render={({ field: { onChange, value } }) => {
        return (
          <div className={`form-group d-flex gap-3 align-items-center mb-2 ${className}`}>
            {label ? <label>{label}</label> : null}
            <ColorPicker name={name} value={value} onChange={onChange} aria-label={ariaLabel} />
          </div>
        );
      }}
    />
  );
};
