import joi from 'joi';

export type ManageEmployeeSchema = {
  roleId: string;
  email: string;
};

export const manageEmployeeSchema = joi.object({
  roleId: joi.string().required().label('Role'),
  email: joi.string().email({ tlds: false }).required().label('Email'),
});
