import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import {appConstants} from '../../../_constants';
import {createPlansAction, deletePlansAction, getAllPlansAction,} from '../../../actions/Admin/Plan.actions.js';
import CustomTable from "../../Common/CustomTable";
import ReactLoading from 'react-loading';
import loaderImage from '../../static/images/loading_spinner.gif';
import TitleComponent from  "../../Common/TitleComponent";
import { callAlertMessage, UpperCaseName } from '../../../components/Auth/Auth';

// eslint-disable-next-line no-undef
let Validator = require('validatorjs');
let styleData = { color: 'red' };
let formArr = {};
let rules = {
  planName: 'required|alpha',
  planCost: 'required|numeric',
  planLimit: 'required|numeric',
  planLimitType: 'required',
  cppProjectsCount: 'required|numeric',
  userCount: 'required|numeric',
  contactsCount: 'required|numeric',
  venderCount: 'required|numeric',
};
let mess = {
  required: 'This field is required',
  alpha: 'This field may only contain letters',
  numeric: 'This field must be a number',
  email: 'This field must be a valid email address.',
};
let validation = [];
validation = new Validator([], rules, appConstants.RULESMESSAGE);

validation.passes();
validation.fails();

class PlansList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectBox: {},
      sessionToken: localStorage.getItem('sessionToken'),
      userId: localStorage.getItem('userId'),
      list: [],
      formArr: [],
      formData: {},
      modal: false,
      showProcessing: false,
      totalCount: 0,
      currentPage: 1,
      selectedRows: [],
    };
    this.toggle = this.toggle.bind(this);
    this.getPlanList = this.getPlanList.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.handleRowSelection = this.handleRowSelection.bind(this);
  }

  componentDidMount() {
    this.getPlanList();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.isPlanListSuccess !== this.props.isPlanListSuccess) {
      if (
        nextProps.isPlanList &&
        nextProps.isPlanList.data &&
        nextProps.isPlanList.data.length > 0
      ) {
        this.setState({ list: nextProps.isPlanList.data, fetchRequest: true });
      }
    }
    //else{
    //    this.setState({list:[]});
    // }

    if (
      nextProps.isPlanAddSuccess !== this.props.isPlanAddSuccess &&
      nextProps.isPlanAdd.success === 1
    ) {
      this.setState({
        formData: {},
        selectBox: {},
        formArr: [],
        showProcessing: false,
        modal: false,
      });
      callAlertMessage('success', nextProps.message);
      this.getPlanList();
    }

    if (
      nextProps.isPlanAddSuccess !== this.props.isPlanAddSuccess &&
      nextProps.isPlanAdd.success === 0
    ) {
      callAlertMessage('error', nextProps.message);
      this.setState({ showProcessing: false });
    }

    if (
      nextProps.isPlanDeleteSuccess !== this.props.isPlanDeleteSuccess &&
      nextProps.isPlanDelete.success === 1
    ) {
      this.setState({ showProcessing: false });
      callAlertMessage('success', nextProps.message);
      this.getPlanList();
    }

    if (
      nextProps.isPlanDeleteSuccess !== this.props.isPlanDeleteSuccess &&
      nextProps.isPlanDelete.success === 0
    ) {
      callAlertMessage('error', nextProps.message);
      this.setState({ showProcessing: false });
    }
  }

  toggle(val) {
    if (val === 'POST') {
      this.setState({ formData: {} }); //Open modal htmlFor add
    } else {
      this.setState({ saveType: 'PUT' });
      this.setState({ formData: this.state.formData }); //Open modal htmlFor edit
    }
    this.setState({ modal: !this.state.modal, formArr: [] });
  }

  cancelFun(e) {
    e.preventDefault();
    this.setState({ modal: !this.state.modal, formArr: [] });
    this.getPlanList();
  }

  getPlanList() {
    let companyUserList = {};
    companyUserList['type'] = 'SUPERADMIN';
    companyUserList['adminId'] = this.state.userId;
    companyUserList['page'] = 0;
    companyUserList['displayLimit'] = this.state.sizePerPage;
    this.props.getAllPlansAction(companyUserList);
  }

  addPlans(evt) {
    evt.preventDefault();
    let { formData } = this.state;

    if (this.allValidate(false)) {
      formData['adminId'] = this.state.userId;
      formData['type'] = 'SUPERADMIN';
      formData['planCost'] = formData.planCost ? Number(formData.planCost) : '';
      formData['planLimit'] = formData.planLimit ? Number(formData.planLimit) : '';
      formData['cppProjectsCount'] = formData.cppProjectsCount
        ? Number(formData.cppProjectsCount)
        : '';
      formData['contactsCount'] = formData.contactsCount ? Number(formData.contactsCount) : '';
      formData['userCount'] = formData.userCount ? Number(formData.userCount) : '';
      formData['venderCount'] = formData.venderCount ? Number(formData.venderCount) : '';
      this.props.createPlansAction(formData);
      this.setState({ showProcessing: true });
    }
  }

  allValidate(check) {
    if (!check) {
      formArr = [];
      Object.keys(rules).forEach(function (key) {
        formArr[key] = 'TT';
      });
      this.setState({
        formArr,
      });
    }
    if (validation.passes()) {
      return 1;
    }
  }

  handleChange = (e) => {
    e.preventDefault();
    let field = this.state.formData;
    field[e.target.name] = e.target.value;
    this.setState({ formData: field });
  };

  handleChangeSelectBox = (name, value) => {
    let formData = this.state.formData;
    let selectBox = this.state.selectBox;
    selectBox[name] = value;
    formData[name] = value.value;
    this.setState({ formData, selectBox });
  };

  handleDeleteButtonClick = () => {
    let data = {};
    const { selectedRows, list } = this.state;
    data['dataArr'] = list.filter((f, index) => selectedRows.includes(index.toString())).map((k) => ({
      objectId: k.objectId
    }));
    const confirm = window.confirm("Are you sure to delete?");
    if(confirm) {
      this.props.deletePlansAction(data);
      this.setState({ submit: true, isPlansCreated: false });
    } else {
      console.log({ confirm });
    }
  };

  handleRowSelection = (ids) => {
    this.setState({ selectedRows: ids });
  }

  _setTableOption() {
    if (this.state.fetchRequest) {
      return 'No data found..';
    } else {
      return (
        <ReactLoading
          className="table-loader"
          type={appConstants.LOADER_TYPE}
          color={appConstants.LOADER_COLOR}
          height={appConstants.LOADER_HEIGHT}
          width={appConstants.LOADER_WIDTH}
        />
      );
    }
  }

  getTimeLine(cell, row, enumObject, rowIndex) {
    return row.planLimit + ' ' + row.planLimitType;
  }

  selectedRow1 = (row) => {
    this.toggle('PUT');
    let selectBox = {};
    selectBox['planLimitType'] = {
      label: row.planLimitType,
      value: row.planLimitType,
    };
    this.setState({ formData: row, selectBox });
  };

  onPageChange(page, sizePerPage) {
    let formData = this.state.formData;
    let data = {};
    data['adminId'] = this.state.userId;
    data['type'] = 'SUPERADMIN';
    data['page'] = page === -1 ? 0 : page - 1;
    data['displayLimit'] = sizePerPage;
    data['search'] = this.state.searchText;
    this.setState({ sizePerPage: sizePerPage });
    this.setState({ currentPage: page });
    this.props.getAllPlansAction(data);
  }

  onSearchChange(text, sizePerPage) {
    let formData = this.state.formData;
    let filterValue = this.state.filterValue;
    text = text ? UpperCaseName(text.trim()) : '';
    if (text !== '') {
      let data = {};
      data['adminId'] = this.state.userId;
      data['type'] = 'SUPERADMIN';
      data['sortKey'] = this.state.sortKey;
      data['sortType'] = this.state.sortType;
      data['page'] = 0;
      data['search'] = text;
      data['displayLimit'] = this.state.sizePerPage;
      this.setState({ sizePerPage: 50 });
      this.setState({ searchText: text });
      this.props.getAllPlansAction(data);
    } else {
      this.componentDidMount();
      this.setState({ searchText: text });
    }
  }

  render() {
    validation = new Validator(this.state.formData, rules, mess);
    validation.passes();
    validation.fails();

    const selectRowProp = { mode: 'checkbox' };
    // let tableOption = appConstants.TableOption;
    // tableOption['afterDeleteRow']= this.handleDeleteButtonClick;
    // tableOption['paginationShowsTotal']= this.renderShowsTotal;
    // tableOption['onRowClick']= this.selectedRow1;

    let tableOption = appConstants.TableOption;
    tableOption['afterDeleteRow'] = this.handleDeleteButtonClick;
    tableOption['paginationShowsTotal'] = this.renderShowsTotal;
    tableOption['onRowClick'] = this.selectedRow1;
    tableOption['noDataText'] = this._setTableOption();

    const columns = [
      {
        Header: "Plan Name",
        accessor: "planName",
        width: 230
      },
      {
        Header: "Price",
        accessor: "planCost",
        width: 200,
      },
      {
        Header: "Time Period",
        accessor: "planLimit",
        width: 320,
        Cell: (props) => this.getTimeLine(props.column.id, props.row.original, props.value)
      },
      {
        Header: "Project Limit",
        accessor: "cppProjectsCount",
        width: 160,
      },
      {
        Header: "Contact Limit",
        accessor: "contactsCount",
        width: 140,
      },
      {
        Header: "User Limit",
        accessor: "userCount",
        width: 140,
      },
      {
        Header: "Company Limit",
        accessor: "venderCount",
        width: 140,
      },
      {
        Header: "Deleted",
        accessor: "trash",
        width: 100
      },
    ];

    return (
      <div>
        <TitleComponent data={{title:`Administration Subscription Plan Settings`}}/>
        <div
          className="noteProcessing"
          style={{ display: this.state.showProcessing ? 'block' : 'none' }}
        >
          <img src={loaderImage} alt="Loading" />
        </div>
        <div className="headingbox">
          <h2 className="heading2">Subscription List</h2>
        </div>
        <div className="row">
          <div className="col-md-12 sortable-table">
            <button className="btn btnRight" onClick={() => this.toggle('POST')}>
              Add
            </button>
            <button className="btn btn-primary" style={{ cursor: 'pointer', padding: '5px' }} disabled={this.state.selectedRows?.length < 1} onClick={() => this.handleDeleteButtonClick()}>
              <i className="fa fa-trash" aria-hidden="true"></i>&nbsp;Delete
            </button>
            <CustomTable
              columns={columns}
              list={this.state.list}
              canShowColumnSettings={false}
              canShowDateFilter={false}
              canShowExport={false}
              canShowPagination={false}
              onSavePreferences={() => { }}
              isLoading={!this.state.fetchRequest}
              canShowRowSelection={true}
              onRowClicked={(cell, row, e) => this.selectedRow1(row.original)}
              onChangeRowSelection={this.handleRowSelection} />
          </div>
        </div>

        <Modal
          backdrop="static"
          keyboard={true}
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggle}>Add New Plan</ModalHeader>
          <ModalBody>
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="firstName">
                      Plan Name<span className="requiredField">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="planName"
                      id="planName"
                      placeholder="Plan Name"
                      value={this.state.formData.planName}
                      onChange={(e) => {
                        this.handleChange(e);
                      }}
                    />
                    <span style={styleData} className="error">
                      {this.state.formArr.planName && validation.errors.first('planName')}
                    </span>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      Price<span className="requiredField">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="planCost"
                      id="planCost"
                      placeholder="Price"
                      value={this.state.formData.planCost}
                      onChange={(e) => {
                        this.handleChange(e);
                      }}
                    />
                    <span style={styleData} className="error">
                      {this.state.formArr.planCost && validation.errors.first('planCost')}
                    </span>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      Period Time<span className="requiredField">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="planLimit"
                      id="planLimit"
                      placeholder="Period Time"
                      value={this.state.formData.planLimit}
                      onChange={(e) => {
                        this.handleChange(e);
                      }}
                    />
                    <span style={styleData} className="error">
                      {this.state.formArr.planLimit && validation.errors.first('planLimit')}
                    </span>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="planLimitType">
                      Period Type<span className="requiredField">*</span>
                    </label>
                    <Select
                      name="planLimitType"
                      components={makeAnimated()}
                      value={this.state.selectBox.planLimitType}
                      onChange={this.handleChangeSelectBox.bind(this, 'planLimitType')}
                      options={appConstants.planList}
                    />
                    <span style={styleData} className="error">
                      {this.state.formArr.planLimitType && validation.errors.first('planLimitType')}
                    </span>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="planLimitType">
                      Project Limit<span className="requiredField">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="cppProjectsCount"
                      id="cppProjectsCount"
                      placeholder="Project Limit"
                      value={this.state.formData.cppProjectsCount}
                      onChange={(e) => {
                        this.handleChange(e);
                      }}
                    />
                    <span style={styleData} className="error">
                      {this.state.formArr.cppProjectsCount &&
                        validation.errors.first('cppProjectsCount')}
                    </span>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="planLimitType">
                      User Limit<span className="requiredField">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="userCount"
                      id="userCount"
                      placeholder="User Limit"
                      value={this.state.formData.userCount}
                      onChange={(e) => {
                        this.handleChange(e);
                      }}
                    />
                    <span style={styleData} className="error">
                      {this.state.formArr.userCount && validation.errors.first('userCount')}
                    </span>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="planLimitType">
                      Contact Limit<span className="requiredField">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="contactsCount"
                      id="contactsCount"
                      placeholder="Contact Limit"
                      value={this.state.formData.contactsCount}
                      onChange={(e) => {
                        this.handleChange(e);
                      }}
                    />
                    <span style={styleData} className="error">
                      {this.state.formArr.contactsCount && validation.errors.first('contactsCount')}
                    </span>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="planLimitType">
                      Company Limit<span className="requiredField">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="venderCount"
                      id="venderCount"
                      placeholder="Company Limit"
                      value={this.state.formData.venderCount}
                      onChange={(e) => {
                        this.handleChange(e);
                      }}
                    />
                    <span style={styleData} className="error">
                      {this.state.formArr.venderCount && validation.errors.first('venderCount')}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button className="btn btn-primary" onClick={(e) => this.cancelFun(e)}>
              Cancel
            </button>
            <button className="btn btn-primary" onClick={(e) => this.addPlans(e)}>
              Submit
            </button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isPlanList: state.plans.isPlanList,
    isPlanListSuccess: state.plans.isPlanListSuccess,

    isPlanAdd: state.plans.isPlanAdd,
    isPlanAddSuccess: state.plans.isPlanAddSuccess,
    isPlanAddError: state.plans.isPlanAddError,

    isPlanDelete: state.plans.isPlanDelete,
    isPlanDeleteSuccess: state.plans.isPlanDeleteSuccess,
    isPlanDeleteError: state.plans.isPlanDeleteError,

    success: state.plans.success,
    message: state.plans.message,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    createPlansAction,
    deletePlansAction,
    getAllPlansAction,
  })(PlansList)
);
