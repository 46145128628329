import * as actionTypes from '../actions/actionTypes';

const initState = {
  cppRole: {},
  allCppRole: [],
  message: '',
  isfetched: false,
  isfetchedError: false,

  isPemStatus: false,
  isPemStatusSuccess: false,
  isPemStatusError: false,
};

export default (state = initState, action = {}) => {
  switch (action.type) {
    case actionTypes.CREATE_CPOST_ROLE_REQUEST:
      return {
        ...state,
        cppRole: {},
        message: '',
      };
    case actionTypes.CREATE_CPOST_ROLE_SUCCESS:
      return {
        allCppRole: state.allCppRole.concat(action.payload.response.result.data),
      };

    case actionTypes.CREATE_CPOST_ROLE_ERROR:
      return {
        ...state,
        message: '',
      };

    case actionTypes.GET_ALL_CPOST_ROLE_REQUEST:
      return {
        ...state,
        cppRole: {},
        message: '',
      };

    case actionTypes.GET_ALL_CPOST_ROLE_SUCCESS:
      return {
        ...state,
        allCppRole: action.payload.response.result.data,
        message: '',
      };

    case actionTypes.GET_ALL_CPOST_ROLE_ERROR:
      return {
        ...state,
        message: '',
      };

    case actionTypes.UPDATE_CPOST_ROLE_REQUEST:
      return {
        ...state,
        cppRole: {},
        message: '',
      };
    case actionTypes.UPDATE_CPOST_ROLE_SUCCESS:
      return {
        ...state,
        cppRole: action.payload.response.result.cppRole,
        message: '',
      };

    case actionTypes.UPDATE_CPOST_ROLE_ERROR:
      return {
        ...state,
        message: '',
      };

    case actionTypes.GET_PERMISSION_STATUS_REQUEST:
      return {
        ...state,
        isPemStatus: false,
        isPemStatusSuccess: false,
        isPemStatusError: false,
        message: '',
      };
    case actionTypes.GET_PERMISSION_STATUS_SUCCESS:
      return {
        ...state,
        message: '',
        isPemStatus: action.payload.response.result,
        isPemStatusSuccess: true,
        isPemStatusError: false,
      };

    case actionTypes.GET_PERMISSION_STATUS_ERROR:
      return {
        ...state,
        message: '',
        isPemStatus: false,
        isPemStatusSuccess: false,
        isPemStatusError: true,
      };

    default:
      return state;
  }
};
