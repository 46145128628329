import { useCallback } from 'react';
import { FormRate } from '../../form-controls/form-rate';
import { HeartFilledIcon } from '../../svg/heart-filled-icon';
import { StarFilledIcon } from '../../svg/star-filled-icon';
import { CustomFieldsCommonProps } from '../constants';

type CustomRateProps = {
  icon: string;
  char: string;
  color: any;
  allowHalf: boolean;
  count: number;
} & CustomFieldsCommonProps;

export const CustomRate = ({
  icon,
  char,
  color,
  allowHalf,
  count,
  ...props
}: CustomRateProps) => {
  const width = props.disabled ? 20 : 36;

  const getIconComponent = (icon: string) => {
    const iconOptions = [
      {
        value: 'star-filled',
        label: (
          <>
            <StarFilledIcon width={24} /> Star Filled
          </>
        ),
        component: ({ color, width }) => <StarFilledIcon color={color} width={width} />,
      },
      {
        value: 'heart-filled',
        label: (
          <>
            <HeartFilledIcon width={24} /> Heart Filled
          </>
        ),
        component: ({ color, width }) => <HeartFilledIcon color={color} width={width} />,
      },
    ];

    const iconOption = iconOptions.find((o) => o.value === icon);

    if (!iconOption) return () => null;

    return iconOption.component;
  };

  const getRateCharacter = useCallback(() => {
    switch (icon) {
      case 'character':
        return (
          <span style={{ color: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`, width }}>
            {char}
          </span>
        );
      default:
        return getIconComponent(icon)({
          color: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`,
          width: width,
        });
    }
  }, [icon, char, color]);

  return <FormRate {...props} character={getRateCharacter()} allowHalf={allowHalf} count={count} />;
};
