import { MonthView, Print, EventTip } from '@bryntum/calendar-thin';
import { DateHelper, Collection } from '@bryntum/core-thin';
import { BryntumCalendar, BryntumCalendarProps } from '@bryntum/calendar-react-thin';
import { ResourceFilter, ResourceStoreConfig } from '@bryntum/scheduler-thin';
import '@bryntum/core-thin/core.stockholm.css';
import '@bryntum/grid-thin/grid.stockholm.css';
import '@bryntum/scheduler-thin/scheduler.stockholm.css';
import '@bryntum/calendar-thin/calendar.stockholm.css';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { getAllCalendarAsync } from '../../modules/calendar';
import { selectCalendarData, selectGetAllCalendar } from '../../modules/calendar/selectors';
import { useAppDispatch } from '../../shared/hooks/use-app-dispatch';
import { useAppSelector } from '../../shared/hooks/use-app-selector';
import loaderImage from '../static/images/loading_i.gif';
import { AddProjectModal } from '../../modals/add-project-modal';
import { BidModal } from '../../modals/bid-modal/bid-modal';
import { ManageContractModal } from '../../modals/manage-contract-modal';
import { AddLeadModal } from '../../modals/add-lead-modal';
import { getAllJobScopeAction } from '../../actions/jobScope';
import { isAllowed, moduleConstants } from '../../_constants';
import { getProjectCustomFieldsValuesForm } from '../../modules/projects';
import { projectSections } from '../../shared/custom-fields-controls/constants';
import styles from './Calendar.module.css';
import ProjectStatusModal from '../../components/Common/ProjectStatusModal.jsx';
import { ProjectStatusModalProps } from '../../components/Common/ProjectStatusModalProps.d';

enum EventRoute {
  LEAD = 'lead-details',
  PROJECT = 'project-details',
  CONTACT = 'contact-details',
  USER = 'contact-details',
  COMPANY = 'company-details',
}

export default function Calendar() {
  const history = useHistory();
  const params = useParams<{
    viewMode?: string;
    year?: string;
    month?: string;
    date?: string;
  }>();

  const dispatch = useAppDispatch();

  const { loading: isGetAllCalendarLoading } = useAppSelector(selectGetAllCalendar);
  const calendarData = useAppSelector(selectCalendarData);

  const calendarRef = useRef<BryntumCalendar>(null);
  const prevSelectAllItemRef = useRef(true);
  const customFields = useRef<Array<any>>([]);
  const addProjectModalRef = useRef<{ getProjectData: () => void }>({
    getProjectData: () => undefined,
  });
  const projectStatusModalRef = useRef<ProjectStatusModalProps>();

  const [userId] = useState<string>(localStorage.getItem('userId') || '');

  const [initialSection, setInitialSection] = useState<string>('');
  const [editProjectId, setEditProjectId] = useState<string>('');
  const [isBidModalOpen, setIsBidModalOpen] = useState<boolean>(false);
  const [isAddLeadModalOpen, setIsAddLeadModalOpen] = useState<boolean>(false);
  const [isAddProjectModalOpen, setIsAddProjectModalOpen] = useState<boolean>(false);
  const [isManageContractModalOpen, setIsManageContractModalOpen] = useState<boolean>(false);
  const [isPromoteToBiddingProcess, setIsPromoteToBiddingProcess] = useState<boolean>(false);


  const paramsDate = useMemo(() => {
    const newParamsDate = new Date(
      Number(params.year),
      Number(params.month) - 1,
      Number(params.date)
    );

    return DateHelper.isValidDate(newParamsDate) ? newParamsDate : new Date();
  }, [params.date, params.month, params.year]);

  useEffect(() => {
    dispatch(getAllJobScopeAction({ className: 'jobScopes' }));

    dispatch(getProjectCustomFieldsValuesForm({ projectId: '' }))
      .unwrap()
      .then((data) => (customFields.current = [...data]));

    getData();
  }, [dispatch]);

  const getData = () => {
    const calendar = calendarRef.current?.instance;

    if (!calendar) {
      return;
    }

    const calendarMonthView = calendar.widgetMap.month;
    const currentMode = calendar.mode;

    if (calendarMonthView instanceof MonthView) {
      if (currentMode === 'month') {
        dispatch(
          getAllCalendarAsync({
            startTime: calendarMonthView.firstVisibleDate.getTime(),
            endTime: calendarMonthView.lastVisibleDate.getTime(),
          })
        );
      } else if (currentMode === 'list') {
        dispatch(
          getAllCalendarAsync({
            startTime: new Date(paramsDate.getFullYear(), paramsDate.getMonth(), 0).getTime(),
            endTime: new Date(paramsDate.getFullYear(), paramsDate.getMonth() + 1, 0).getTime(),
          })
        );
      } else if (currentMode === 'day') {
        dispatch(
          getAllCalendarAsync({
            startTime: new Date(
              paramsDate.getFullYear(),
              paramsDate.getMonth(),
              paramsDate.getDate()
            ).getTime(),
            endTime: new Date(
              paramsDate.getFullYear(),
              paramsDate.getMonth(),
              paramsDate.getDate() + 1
            ).getTime(),
          })
        );
      } else if (currentMode === 'week' || currentMode === 'resource' || currentMode === 'dayresource') {
        const dayOfWeek = paramsDate.getDay();
        const diff = paramsDate.getDate() - dayOfWeek;
        const firstDayOfWeek = new Date(paramsDate.getFullYear(), paramsDate.getMonth(), diff);
        const lastDayOfWeek = new Date(paramsDate.getFullYear(), paramsDate.getMonth(), diff + 6);
        lastDayOfWeek.setHours(23, 59, 59, 999);

        dispatch(
          getAllCalendarAsync({
            startTime: firstDayOfWeek.getTime(),
            endTime: lastDayOfWeek.getTime(),
          })
        );
      } else if (currentMode === 'year' || currentMode === 'agenda') {
        dispatch(
          getAllCalendarAsync({
            startTime: new Date(paramsDate.getFullYear(), 0, 1).getTime(),
            endTime: new Date(paramsDate.getFullYear() + 1, 0, 0).getTime(),
          })
        );
      }
    }
  };

  const getWeek = function (date: Date) {
    const startDate = new Date(date.getFullYear(), 0, 1);
    const daysUntilTargetDate = (date.getTime() - startDate.getTime()) / (24 * 60 * 60 * 1000);
    const weekNumber = Math.ceil((daysUntilTargetDate + 1 + startDate.getDay()) / 7);

    return weekNumber;
  };

  const handleDateChange = (event) => {
    const calendar = event.source;

    const currentMode = event?.activeItem?._ref ?? calendar.mode;

    let forceChange = false;

    if (event?.prevActiveItem) {
      // if change in active items, force refresh
      forceChange = true;
    }

    const currentYear = paramsDate.getFullYear();
    const currentMonth = paramsDate.getMonth() + 1;
    const currentWeek = getWeek(paramsDate);
    const currentDate = paramsDate.getDate();

    const nextYear = event.date.getFullYear();
    const nextMonth = event.date.getMonth() + 1;
    const nextWeek = getWeek(event.date);
    const nextDate = event.date.getDate();

    history.push(`/calendar/${currentMode}/${nextYear}/${nextMonth}/${nextDate}`);

    if (currentMode === 'year' || currentMode === 'agenda') {
      if (forceChange || nextYear !== currentYear) {
        dispatch(
          getAllCalendarAsync({
            startTime: new Date(nextYear, 0, 1).getTime(),
            endTime: new Date(nextYear + 1, 0, 0).getTime(),
          })
        );
      }
    } else if (currentMode === 'month') {
      if (forceChange || nextYear !== currentYear || nextMonth !== currentMonth) {
        dispatch(
          getAllCalendarAsync({
            startTime: calendar.widgetMap.month.firstVisibleDate.getTime(),
            endTime: calendar.widgetMap.month.lastVisibleDate.getTime(),
          })
        );
      }
    } else if (currentMode === 'list') {
      if (forceChange || nextYear !== currentYear || nextMonth !== currentMonth) {
        dispatch(
          getAllCalendarAsync({
            startTime: new Date(nextYear, nextMonth - 1, 0).getTime(),
            endTime: new Date(nextYear, nextMonth, 0).getTime(),
          })
        );
      }
    } else if (currentMode === 'week' || currentMode === 'resource') {
      if (forceChange || nextWeek !== currentWeek) {
        const dayOfWeek = event.date.getDay();
        const diff = event.date.getDate() - dayOfWeek;
        const firstDayOfWeek = new Date(event.date.getFullYear(), event.date.getMonth(), diff);
        const lastDayOfWeek = new Date(event.date.getFullYear(), event.date.getMonth(), diff + 6);
        lastDayOfWeek.setHours(23, 59, 59, 999);

        dispatch(
          getAllCalendarAsync({
            startTime: firstDayOfWeek.getTime(),
            endTime: lastDayOfWeek.getTime(),
          })
        );
      }
    } else if (currentMode === 'day') {
      if (
        forceChange ||
        nextYear !== currentYear ||
        nextMonth !== currentMonth ||
        nextWeek !== currentWeek ||
        nextDate !== currentDate
      ) {
        dispatch(
          getAllCalendarAsync({
            startTime: new Date(nextYear, nextMonth - 1, nextDate).getTime(),
            endTime: new Date(nextYear, nextMonth - 1, nextDate).getTime(),
          })
        );
      }
    }
  };

  const listEventRenderer = ({ eventRecord }) => {
    return [
      {
        text:
          eventRecord.name +
          (eventRecord.raw?.meetingSubject ? ` (${eventRecord.raw?.meetingSubject})` : ''),
      },
    ];
  };

  const handleBeforeActiveItemChange = (event) => {
    const calendar = event.source.parent;

    const currentMode = calendar.mode;
    const nextMode = event.activeItem.modeName;

    const currentYear = calendar.date.getFullYear();
    const currentMonth = calendar.date.getMonth() + 1;
    const currentDate = calendar.date.getDate();

    event.date = calendar.date;

    if (nextMode != currentMode) {
      handleDateChange(event);
    }

    history.push(`/calendar/${nextMode}/${currentYear}/${currentMonth}/${currentDate}`);
  };

  const getEditDialogSection = useCallback((eventData: any) => {
    const { typeRef, isCustomField, fieldId } = eventData;
    const isProject = typeRef === 'PROJECT';

    if (!isCustomField) {
      return isProject ? 'dates-and-dollars' : 'lead-project-dates';
    }

    const customField = customFields.current.find((field) => field._id === fieldId);

    if (customField) {
      const projectSection = projectSections.find(
        (section) => section.value === customField.section
      );

      if (projectSection) {
        return projectSection.section;
      }
    }

    return '';
  }, []);

  // TODO: replace with `BryntumCalendarProps` if Bryntum adds missing `ResourceStoreConfig` type for `resourceStore` property
  const [calendarConfig] = useState<
    Omit<BryntumCalendarProps, 'resourceStore'> & {
      resourceStore: BryntumCalendarProps['resourceStore'] | Partial<ResourceStoreConfig>;
    }
  >({
    readOnly: true,

    cls: styles.bCalendar,

    resourceStore: {
      onAdd: (event) => {
        const calendar = calendarRef.current?.instance;

        if (!calendar) {
          return;
        }

        const resourceFilter = calendar.widgetMap.resourceFilter;

        if (prevSelectAllItemRef.current && resourceFilter instanceof ResourceFilter) {
          (resourceFilter.selected as Collection).add(event.records);
        }
      },
    },

    date: paramsDate,


    mode: params.viewMode ?? 'month',
    modes: {
      day: {
        eventRenderer: listEventRenderer,
        coreHours: {
          start: 6,
          end: 21,
          overlayDay: true,
        },
        zoomOnMouseWheel: true,
        fitHours: true,
      },
      week: {
        eventRenderer: listEventRenderer,
        zoomOnMouseWheel: true,
        fitHours: true,
        coreHours: {
          start: 6,
          end: 21,
          overlayDay: true,
        },
      },
      resource: {
        eventRenderer: listEventRenderer,
        zoomOnMouseWheel: true,
        fitHours: true,
        coreHours: {
          start: 6,
          end: 21,
          overlayDay: true,
        },
      },
      month: {
        eventRenderer: listEventRenderer,
        overflowPopup: {
          onlyShowOverflow: true,
        },
        sixWeeks: true,
      },
      year: { eventRenderer: listEventRenderer },
      agenda: { eventRenderer: listEventRenderer },
      dayResource: {
        eventRenderer: listEventRenderer,
        zoomOnMouseWheel: true,
        fitHours: true,
        coreHours: {
          start: 6,
          end: 21,
          overlayDay: true,
        },
      },
      list: {

        columns: [
          {
            field: 'name',

            //flex  : '0 0 12em',
            renderer({ record }) {
              return [
                {
                  tag: 'i',
                  className: 'b-icon b-icon-circle',
                  style: `color:${record.resource?.eventColor}`,
                },
                {
                  text: record.name,
                },
              ];
            },
          },
          {
            field: 'raw.meetingSubject',
            text: 'Subject',
            flex: '0 0 20em',
            renderer({ record }) {
              return [
                {
                  text: record.raw?.meetingSubject ?? '',
                  html: (
                    <a
                      href={`/${EventRoute[(record as any).raw.typeRef]}/${record.raw.typeId}`}
                      target='_blank'
                      rel='noreferrer'
                    >
                      {record.raw.meetingSubject}
                    </a>
                  ),
                },
              ];
            },
          },
          {
            field: 'raw.description',
            text: 'Description',
            flex: '0 0 12em',
            hidden: true,
            renderer({ record }) {
              return [
                {
                  text: record.raw?.description ?? '',
                },
              ];
            },
          },
          {
            field: 'raw.location',
            text: 'Location',
            flex: '0 0 15em',
            renderer({ record }) {
              return [
                {
                  text: record.raw?.location ?? '',
                },
              ];
            },
          },
        ],
        features: {
          columnPicker: {
            //createColumnsFromModel: true,
          },

        },
        fillLastColumn: true,

      },
    },

    eventEditFeature: false,
    eventTooltipFeature: {
      tooltip: {
        tools: {
          delete: false,
          customEdit: {
            cls: 'b-fa b-fa-pencil text-decoration-none text-reset',
            href: '',
            onPaint: (event) => {
              const { eventRecord } = event.source.parent as EventTip;

              (event.source.element as HTMLLinkElement).href = `/${EventRoute[(eventRecord as any).raw.typeRef]
                }/${(eventRecord as any).raw.typeId}/schedule/edit/${(eventRecord as any).raw.objectId
                }`;
              (event.source.element as HTMLLinkElement).target = '_blank';
              (event.source.element as HTMLLinkElement).rel = 'noreferrer';
            },
          },
          customEditDialog: {
            cls: 'b-fa b-fa-pencil text-decoration-none text-reset',
            href: '',
            onClick: (event) => {
              const { eventRecord } = event.source.parent as EventTip;
              const eventData = (eventRecord as any).raw;
              const { objectId, isCustomField, isBid, typeId, typeRef } = eventData;
              const projectId = isCustomField || isBid ? typeId : objectId;
              const dialogInitialSection = getEditDialogSection(eventData);

              setInitialSection(dialogInitialSection);
              onEditButtonClick(projectId, typeRef === 'LEAD');
            },
          },
          customEditBidDialog: {
            cls: 'b-fa b-fa-clock text-decoration-none text-reset',
            href: '',
            onClick: (event) => {
              const { eventRecord } = event.source.parent as EventTip;
              const { objectId, isCustomField, isBid, typeId } = (eventRecord as any).raw;
              const projectId = isCustomField || isBid ? typeId : objectId;

              onOpenBidModalClick(projectId);
            },
          },
        },
      },
      renderer: (renderData) => {
        const rawRecord = (renderData.eventRecord as any).raw;
        if (rawRecord.isBid) {
          return (
            <dl className='mb-0'>
              <dt>Bid due:</dt>
              <dd>
                {DateHelper.format(renderData.eventRecord.startDate as Date, 'ddd MMM D h:mm A')}
              </dd>
              <dt>Project:</dt>
              <dd>
                <a
                  href={`/${EventRoute[rawRecord.typeRef]}/${EventRoute[rawRecord.typeId]}`}
                  target='_blank'
                  rel='noreferrer'
                >
                  {rawRecord.meetingSubject}
                </a>
              </dd>
              {rawRecord.address?.length > 0 ? (
                <>
                  <dt>Project Location:</dt>
                  <dd>
                    <dd>{rawRecord.address}</dd>
                  </dd>
                </>
              ) : (
                ''
              )}

              {rawRecord.scheduleTo?.length > 0 && (
                <>
                  <dt>Assigned:</dt>
                  <dd>
                    <ul>
                      {rawRecord.scheduleTo?.map((to) => (
                        <li key={to.objectId}>
                          {to.fullName ? (
                            <>
                              <a
                                href={`/${EventRoute.CONTACT}/${to.objectId}`}
                                target='_blank'
                                rel='noreferrer'
                              >
                                {to.fullName}
                              </a>{' '}
                              {(to.value ?? to.email) && (
                                <>
                                  (
                                  <a href={`mailto:${to.value ?? to.email}`}>
                                    {to.value ?? to.email}
                                  </a>
                                  )
                                </>
                              )}
                              {(to.roleNames?.length > 0) && (
                                <>
                                  &nbsp;<i>({to.roleNames.filter(Boolean).join(',')})</i>
                                  
                                </>
                              )}
                            </>
                          ) : (
                            <a href={`mailto:${to.value}`}>{to.value}</a>
                          )}
                        </li>
                      ))}
                    </ul>
                  </dd>
                </>
              )}
            </dl>
          );
        } else if (rawRecord.isCustomField) {
          return (
            <dl className='mb-0'>
              <dt>Event Type:</dt>
              <dd>
                <a
                  href={`/${EventRoute[rawRecord.typeRef]}/${rawRecord.typeId}/schedule/view/${rawRecord.objectId
                    }`}
                  target='_blank'
                  rel='noreferrer'
                >
                  {renderData.eventRecord.name}
                </a>
              </dd>
              {rawRecord?.description?.length > 0 && (
                <>
                  <dt>Description:</dt>
                  <dd>{rawRecord.description}</dd>
                </>
              )}
              <dt>
                {rawRecord.typeRef === 'PROJECT' || rawRecord.typeRef === 'LEAD'
                  ? 'Project:'
                  : 'Subject'}
              </dt>
              <dd>
                <a
                  href={`/${EventRoute[rawRecord.typeRef]}/${rawRecord.typeId}`}
                  target='_blank'
                  rel='noreferrer'
                >
                  {rawRecord.meetingSubject}
                </a>
              </dd>
              <dt>Time:</dt>
              <dd>
                {DateHelper.format(renderData.eventRecord.startDate as Date, 'ddd MMM D h:mm A')} -
                {DateHelper.format(renderData.eventRecord.endDate as Date, 'h:mm A')}
              </dd>
              {rawRecord?.location?.length > 0 && (
                <>
                  <dt>Location:</dt>
                  <dd>{rawRecord.location}</dd>
                </>
              )}
              <dt>Assigned:</dt>
              <dd>
                <ul>
                  {rawRecord.scheduleTo?.map((to) => (
                    <li key={to.objectId}>
                      {to.fullName ? (
                        <>
                          <a
                            href={`/${EventRoute.CONTACT}/${to.objectId}`}
                            target='_blank'
                            rel='noreferrer'
                          >
                            {to.fullName}
                          </a>{' '}
                          {(to.value ?? to.email) && (
                            <>
                              (<a href={`mailto:${to.value ?? to.email}`}>{to.value ?? to.email}</a>
                              )
                            </>
                          )}
                          {(to.roleNames?.length > 0) && (
                            <>
                              (<i>{to.roleNames.join(',')}</i>
                              )
                            </>
                          )}
                        </>
                      ) : (
                        <a href={`mailto:${to.value}`}>{to.value}</a>
                      )}
                    </li>
                  ))}
                </ul>
              </dd>
            </dl>
          );
        } else {
          return (
            <dl className='mb-0'>
              <dt>Title:</dt>
              <dd>
                <a
                  href={`/${EventRoute[rawRecord.typeRef]}/${rawRecord.typeId}/schedule/view/${rawRecord.objectId
                    }`}
                  target='_blank'
                  rel='noreferrer'
                >
                  {renderData.eventRecord.name}
                </a>
              </dd>
              {rawRecord?.description?.length > 0 && (
                <>
                  <dt>Description:</dt>
                  <dd>{rawRecord.description}</dd>
                </>
              )}
              <dt>Subject:</dt>
              <dd>
                <a
                  href={`/${EventRoute[rawRecord.typeRef]}/${renderData.eventRecord.resource.id}`}
                  target='_blank'
                  rel='noreferrer'
                >
                  {renderData.eventRecord.resource.name}
                </a>
              </dd>
              <dt>Time:</dt>
              <dd>
                {DateHelper.format(renderData.eventRecord.startDate as Date, 'ddd MMM D h:mm A')} -
                {DateHelper.format(renderData.eventRecord.endDate as Date, 'h:mm A')}
              </dd>
              {rawRecord?.location?.length > 0 && (
                <>
                  <dt>Location:</dt>
                  <dd>{rawRecord.location}</dd>
                </>
              )}
              <dt>Attendees:</dt>
              <dd>
                <ul>
                  {rawRecord.scheduleTo?.map((to) => (
                    <li key={to.objectId}>
                      {to.fullName ? (
                        <>
                          <a
                            href={`/${EventRoute.CONTACT}/${to.objectId}`}
                            target='_blank'
                            rel='noreferrer'
                          >
                            {to.fullName}
                          </a>{' '}
                          {(to.value ?? to.email) && (
                            <>
                              (<a href={`mailto:${to.value ?? to.email}`}>{to.value ?? to.email}</a>
                              )
                            </>
                          )}
                        </>
                      ) : (
                        <a href={`mailto:${to.value}`}>{to.value}</a>
                      )}
                    </li>
                  ))}
                </ul>
              </dd>
            </dl>
          );
        }
      },
      onBeforeShow: (event: any) => {
        const eventData = event.source.ownerFeature.eventRecord.raw;
        const { isBid, isCustomField, projectData, typeRef } = eventData;
        const data = { ...(projectData || eventData) };

        let allowEditDialog = false;
        let allowEditBid = false;
        if (['PROJECT', 'LEAD'].includes(typeRef)) {
          allowEditDialog = canEditProject(data);
          allowEditBid = hasBidProject(data);
        }

        if (isBid) {
          event.source.header = 'Project Bid Due';
          event.source.widgetMap.customEdit.hidden = true;
          event.source.widgetMap.customEditDialog.hidden = !allowEditDialog;
          event.source.widgetMap.customEditBidDialog.hidden = !allowEditBid;
        } else if (isCustomField) {
          if(data.typeRef === 'PROJECT' || data.typeRef === 'LEAD') {
          event.source.header = 'Project Date';
          }else if(data.typeRef === 'CONTACT' || data.typeRef === 'COMPANY') {
            event.source.header = 'Event Date';
          }
          event.source.widgetMap.customEdit.hidden = true;
          event.source.widgetMap.customEditDialog.hidden = !allowEditDialog;
          event.source.widgetMap.customEditBidDialog.hidden = true;
        } else {
          event.source.header = 'Scheduled Meeting';
          event.source.widgetMap.customEdit.hidden = false;
          event.source.widgetMap.customEditDialog.hidden = true;
          event.source.widgetMap.customEditBidDialog.hidden = true;
        }
      },
    },
    printFeature: true,

    tbar: {
      items: {
        eventHighlight: {
          type: 'textfield',
          weight: 660,
          placeholder: 'Highlight events',
          clearable: true,
          keyStrokeChangeDelay: 100,
          triggers: {
            filter: {
              align: 'start',
              cls: 'b-fa b-fa-search',
            },
          },
          onChange: (event) => {
            const calendar = event.source.parent.parent;
            const value = event.value.toLowerCase();

            calendar.eventStore.suspendEvents();

            calendar.eventStore.forEach((eventModel) => {
              const resourceNames = eventModel.resources.map((res) => res.name?.toLowerCase() ?? '').join(' ');
              const meetingSubject = eventModel.raw?.meetingSubject?.toLowerCase() ?? '';
              const itemName = eventModel.name.toLowerCase();
              const searchTerm = [resourceNames, meetingSubject, itemName].join(' ');
              eventModel.cls.toggle(
                styles.bMatch,
                value !== '' &&
                (searchTerm.includes(value))
              );
            });

            calendar.eventStore.resumeEvents();
            calendar.eventStore.trigger('change');

            calendar.element.classList.toggle(styles.bEventHighlight, value.length > 0);
          },
        },
        print: {
          type: 'button',
          icon: 'b-fa b-fa-print',
          text: 'Print',
          onClick: 'up.print' as unknown as Print['print'],
        },
      },
    },
    sidebar: {
      items: {
        datePicker: {
          tbar: {
            // Hide the next/prev year buttons for a bit cleaner UI
            items: {
              prevYear: false,
              nextYear: false,
            },
          },
        },
        eventFilter: {
          ignoreParentReadOnly: true,
          triggers: {
            filter: {
              align: 'start',
              cls: 'b-fa b-fa-filter',
            },
          },
          filterFunction(record, value) {
            value = value.toLowerCase().trim();
            const resourceNames = record.resources.map((res) => res.name?.toLowerCase() ?? '').join(' ');
            const meetingSubject = record.raw?.meetingSubject?.toLowerCase() ?? '';
            const itemName = record.name.toLowerCase();
            const searchTerm = [resourceNames, meetingSubject, itemName].join(' ');
            return value == '' || (searchTerm.includes(value));
          }
        },
        resourceFilter: {
          flex: '1 1 0',
          selectAllItem: true,
          store: {
            groupers: [{ field: 'raw.groupName', ascending: false }],
            sorters: [
              { field: 'raw.order', ascending: true },
              { field: 'name', ascending: true },
            ],
          },
          onItem: (event) => {
            prevSelectAllItemRef.current = !!event.source.allSelected;
          },
        },
      },
    },
  });

  const onCalendarEventClick = (ev) => {
    const resourceId = ev.domEvent.target.dataset.id;
    const resource = ev.eventRecord.resources.find((res) => res.data.id === resourceId);

    if (resource && resource.raw.typeRef) {
      window.open(`/${EventRoute[resource.raw.typeRef]}/${resourceId}`, '_blank');
    }
  };

  const getAssignments = useCallback((data: any) => {
    const { internalRoles = {}, externalRoles = {} } = data;

    const internalAssignmentsContactIds = Object.values(internalRoles)
      .flat()
      .map((role: any) => role.contactId);
    const externalAssignmentsContactIds = Object.values(externalRoles)
      .flat()
      .map((role: any) => role.contactId);

    return [...internalAssignmentsContactIds, ...externalAssignmentsContactIds];
  }, []);

  const canEditProject = useCallback(
    (data: any) => {
      if (data) {
        const { createdBy = {} } = data;

        const assignments = getAssignments(data);
        const assignedType = createdBy.objectId === userId ? 'anyAssigned' : 'assigned';

        return !!isAllowed(moduleConstants.EDITPROJDETAILS, assignedType, assignments);
      }

      return false;
    },
    [userId]
  );

  const hasBidProject = useCallback(
    (data: any) => {
      if (data) {
        const { curBidId, lastBidId, createdBy, jobStatusCodesCode } = data;

        const assignments = getAssignments(data);
        const assigned = createdBy.objectId === userId ? 'anyAssigned' : 'assigned';
        const isAllowedOpenDialog = isAllowed(moduleConstants.EBAGP, assigned, assignments);
        const isProjectStatus = !['L', 'NP'].includes(jobStatusCodesCode);

        if ((curBidId || lastBidId) && isProjectStatus && isAllowedOpenDialog) {
          return true;
        }
      }

      return false;
    },
    [userId]
  );

  const onOpenBidModalClick = useCallback((projectId: string) => {
    if (projectId) {
      setEditProjectId(projectId);
      setIsBidModalOpen(true);
    }
  }, []);

  const onEditButtonClick = useCallback((projectId: string, isLead: boolean) => {
    if (projectId) {
      setEditProjectId(projectId);
      isLead ? setIsAddLeadModalOpen(true) : setIsAddProjectModalOpen(true);
    }
  }, []);

  const onEditSubmit = useCallback(async () => {
    const calendar = calendarRef.current?.instance;

    if (calendar) {
      getData();
    }
  }, [getData]);

  const onBidManagementModalSubmit = useCallback(() => {
    addProjectModalRef.current.getProjectData();
    onEditSubmit();
  }, []);

  const [statusModal, setStatusModal] = useState(false);
  const [statusUpdateType, setStatusModalUpdateType] = useState('');

  const toggleStatusUpdate = (updateType) => {
    if (!statusModal) {
      setStatusModalUpdateType(updateType);
    } else {
      getData();
      setStatusModalUpdateType('');
    }

    setStatusModal(!statusModal);
  };

  const ProjectStatusModalComponent: React.ComponentType<ProjectStatusModalProps> =
    ProjectStatusModal;

  const onNextProjectStatusModalClick = () => {
    projectStatusModalRef.current?.toggleModal();
    setIsManageContractModalOpen(true);
  };

  const onSubmitManageContract = (currentProjectData) => {
    //if (this.state.isPromoteToActiveProcess) {
    projectStatusModalRef.current?.submitUpdateProject({}, currentProjectData, 'Approved');
    //}
  };

  const onAfterPromoteToBidding = useCallback(() => {
    setIsBidModalOpen(true);
    setIsPromoteToBiddingProcess(true);
  }, []);

  const onToggleBidModal = useCallback(() => {
    setIsBidModalOpen(false);
    setIsPromoteToBiddingProcess(false);
  }, []);

  return (
    <div className='position-relative h-100'>
      {isGetAllCalendarLoading && (
        <div className='loading_bg'>
          <img className='ajax-loader' src={loaderImage} alt='' width='100' height='100' />
        </div>
      )}

      <BryntumCalendar
        ref={calendarRef}
        // TODO: remove `as` part after use `BryntumCalendarProps` type for `calendarConfig` variable
        {...(calendarConfig as BryntumCalendarProps)}
        resources={calendarData.resources}
        events={calendarData.events}
        assignments={calendarData.assignments}
        onDateChange={handleDateChange}
        onBeforeActiveItemChange={handleBeforeActiveItemChange}
        onEventClick={onCalendarEventClick}
      />

      <AddProjectModal
        open={isAddProjectModalOpen}
        showSaveAndNext={false}
        onClose={() => setIsAddProjectModalOpen(false)}
        projectId={editProjectId}
        onSubmit={onEditSubmit}
        onManageBid={() => onOpenBidModalClick(editProjectId)}
        initialSection={initialSection}
        ref={addProjectModalRef}
      />
      <AddLeadModal
        open={isAddLeadModalOpen}
        showSaveAndNext={false}
        onClose={() => setIsAddLeadModalOpen(false)}
        projectId={editProjectId}
        onSubmit={onEditSubmit}
        initialSection={initialSection}
      />
      <BidModal
        isOpen={isBidModalOpen}
        toggle={onToggleBidModal}
        toggleStatusUpdate={(updateType) => toggleStatusUpdate(updateType)}
        onSubmit={onBidManagementModalSubmit}
        bidScheduleExpanded={true}
        projectId={editProjectId}
        showSaveSubmit={!isAddProjectModalOpen}
        isPromote={isPromoteToBiddingProcess}
      />
      <ManageContractModal
        isOpen={isManageContractModalOpen}
        projectId={editProjectId}
        toggle={() => {
          setIsManageContractModalOpen(false);
          projectStatusModalRef.current?.toggleStatusUpdateReset();
        }}
        onSubmit={(projectData => onSubmitManageContract(projectData))}
      //onSuggestProgress={(changes) => this.onSuggestProgress(changes)}
      />
      <ProjectStatusModalComponent
        projectId={editProjectId}
        modalStatusUpdate={statusModal}
        initialUpdateType={statusUpdateType}
        toggle={toggleStatusUpdate}
        onNext={onNextProjectStatusModalClick}
        onAfterPromoteToBidding={onAfterPromoteToBidding}
        childRef={(ref) => (projectStatusModalRef.current = ref)}
      />
    </div>
  );
}
