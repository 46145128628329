import React, { Suspense } from 'react';
import { connect } from 'react-redux';
import renderHTML from 'html-react-parser';
import { Link, withRouter } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { getAllProjectRoleAction } from '../../../actions/projectRole';
import { getAllProjectFieldsAction } from '../../../actions/projectFields';
import { getAllJobStatusAction } from '../../../actions/jobStatusCode';
import { getAllReasonCodeAction } from '../../../actions/reasonCode';
import {
  GetBidDueReportAction,
  GetBidDueReportPrefAction,
  SetBidDueReportPrefAction,
} from '../../../actions/reports';
import {
  formatExternalContactData,
  formatInternalContactData,
  formatAddressText,
} from '../../../utils/contact-formatters';
import { matchDataForTable } from '../../../utils/match-for-table';
import { formatNote } from '../../../utils/note-formatters';
import { appConstants } from '../../../_constants';
import { customSortByKey, CustomTable, multiSelectFilter } from '../../Common/CustomTable';
import { DateColumnFilter } from '../../Common/CustomTableComponents/DateColumnFilter';
import { SelectColumnFilter } from '../../Common/CustomTableComponents/SelectColumnFilter';
import NotePopUp from '../../Common/NotePopUp';
import TitleComponent from '../../Common/TitleComponent';
import Footer from '../../Layout/Footer';
import Header from '../../Layout/Header';
import loaderImage1 from '../../../components/static/images/loading_i.gif';
import { isAllowed, moduleConstants } from '../../../_constants';
import { buildColumnForField, buildDynamicColumns, onCurrency, onDate, onDateTime } from '../../../utils/column-formatters';

class BidDueReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {},
      accountId: localStorage.getItem('accountId'),
      userId: localStorage.getItem('userId'),
      list: [],
      fetchRequest: true,
      projectStatus: false,
      comLogo: '',
      project: { projectData: [] },
      currentPage: 1,
      sizePerPage: appConstants.TableSizePerPageList[2].value,
      searchText: '',
      totalCount: 0,
      projectObjectId: '',
      allProjectStatus: [],
      reasonCodes: [],
      noteModal: false,
      noteData: '',
      sortType: 'DESC',
      sortKey: '',
      contactData: {},
      projectRoleTypes: [],
      allProjectFieldTypes: [],
      dynamicColumns: [],
    };

    this.getModalOpen = this.getModalOpen.bind(this);
    this.onNoteModalClose = this.onNoteModalClose.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps?.projectRoles?.allProjectRoleTypes) {
      this.setState({
        projectRoleTypes: nextProps.projectRoles.allProjectRoleTypes,
      });
    }

    if (nextProps?.projectFields?.allProjectFieldTypes?.length > 0) {
      this.setState({
        allProjectFieldTypes: nextProps.projectFields.allProjectFieldTypes,
      });
    }

    if (nextProps.reasonCodes) {
      this.setState({
        reasonCodes: nextProps.reasonCodes,
      });
    }

    if (nextProps.allProjectStatus) {
      this.setState({
        allProjectStatus: nextProps.allProjectStatus,
      });
    }

    if (nextProps?.listData?.success && nextProps.listData.success === 1) {
      let fieldsToMatch = {
        projectAssignments: { lookupLocation: 'contactData' },
      };

      let matchedDataForTable = matchDataForTable(
        fieldsToMatch,
        nextProps.listData.data,
        nextProps.listData.contactData,
        this.props
      );

      this.setState({
        totalCount: nextProps.listData.count,
        list: matchedDataForTable,
        contactData: nextProps.listData.contactData,
        fetchRequest: false,
      });
    }

    if (nextProps.listData && nextProps.listData.data && nextProps.listData.data.length == 0) {
      this.setState({ fetchRequest: false });
    }
  }

  componentDidMount() {
    this.setState({ sizePerPage: appConstants.TableSizePerPageList[2].value });
    let data = {};
    data['accountId'] = this.state.accountId;
    data['moduleName'] = appConstants.reportModuleName['8'];
    data['page'] = 0;
    data['displayLimit'] = this.props?.props?.isWidget ? 5 : this.state.sizePerPage;
    data['sortKey'] = this.state.sortKey;
    data['sortType'] = this.state.sortType;
    data['search'] = this.state.searchText;
    this.props.GetBidDueReportAction(data);

    if (
      localStorage.getItem('companyLogo') !== 'undefined' &&
      localStorage.getItem('companyLogo') !== null
    ) {
      this.setState({
        comLogo: localStorage.getItem('companyLogo'),
      });
    } else {
      let constImage = appConstants.CONTENT_CPOST_LOGO_URL;
      this.setState({ comLogo: constImage });
    }

    this.props.getAllProjectRoleAction({
      className: 'projectRoleTypes',
      accountId: this.state.accountId,
    });

    this.props.getAllProjectFieldsAction({
      accountId: this.state.accountId,
      minimumStage: 'bidding',
    });

    this.props.getAllJobStatusAction({
      className: 'jobStatusCodes',
      accountId: this.state.accountId,
    });
    this.props.getAllReasonCodeAction({
      className: 'reasonCodes',
      accountId: this.state.accountId,
    });

    this.props.GetBidDueReportPrefAction();
  }

  

  buildColumnForRole(filteredRoles, roleCode, hidden = false, showContactInfo=false) {
    const foundRole = filteredRoles.find((role) => role.code == roleCode);
    if (!foundRole) return;
    const isInternal = foundRole && foundRole.type === 'internal';

    if (foundRole && isInternal) {
      return (
        foundRole && {
          Header: foundRole?.shortName ?? roleCode,
          LongHeader: foundRole?.name,
          accessor: 'projectAssignmentsLabel' + roleCode,
          sortType: customSortByKey,
          sortKey: foundRole?.name ?? roleCode,
          Filter: SelectColumnFilter,
          filter: multiSelectFilter,
          width: 60,
          hidden: hidden,
          Cell: (props) => (
            <>
              {this.formatContactDataInternal(
                'projectAssignmentsMatched' + roleCode,
                props.row.original
              )}
            </>
          ),
        }
      );
    } else {
      return (
        foundRole && {
          Header: foundRole?.shortName ?? roleCode,
          LongHeader: foundRole?.name,
          accessor: 'projectAssignmentsLabel' + roleCode,
          sortType: customSortByKey,
          sortKey: foundRole?.name ?? roleCode,
          Filter: SelectColumnFilter,
          filter: multiSelectFilter,
          width: 60,
          hidden: hidden,
          Cell: (props) => (
            <>
              {this.formatContactDataExternal(
                'projectAssignmentsMatched' + roleCode,
                props.row.original, showContactInfo
              )}
            </>
          ),
        }
      );
    }
  }

  formatContactDataInternal(cell, row) {
    if (row[cell] && row[cell].length > 0) {
      return formatInternalContactData(row[cell], this.state.contactData);
    } else {
      return <span>-</span>;
    }
  }

  formatContactDataExternal(cell, row, showContactInfo) {
    if (row[cell] && row[cell].length > 0) {
      return formatExternalContactData(row[cell], this.state.contactData, showContactInfo);
    } else {
      return <span>-</span>;
    }
  }


  onJobStatus(cell, row, enumObject, rowIndex) {
    return (
      <div className='linkText'>{row && row.jobStatusCodesCode ? row.jobStatusCodesCode : '-'}</div>
    );

    //return (row && row.jobStatusCodesCode) ? row.jobStatusCodesCode : '-';
  }

  redirectToProject(cell, enumObject, row, rowIndex) {
    if (cell === 'jobNumFormatted' && row) {
      return (
        <Link
          to={{
            pathname: `/project-details/${row.objectId}`,
            state: { row },
          }}
        >
          {row.jobNumFormatted ? row.jobNumFormatted : '-'}
        </Link>
      );
    }

    if (cell === 'jobName' && row) {
      return (
        <div>
          <Link
            to={{
              pathname: `/project-details/` + row.objectId,
              state: { row },
            }}
          >
            {row.jobName ? row.jobName : '-'}
          </Link>
        </div>
      );
    }

    if (cell === 'altJobNum' && row) {
      return (
        <div>
          <Link
            to={{
              pathname: `/project-details/` + row.objectId,
              state: { row },
            }}
          >
            {row.altJobNum ? row.altJobNum : '-'}
          </Link>
        </div>
      );
    }
  }



  onNoteDetail(cell, row, enumObject, rowIndex) {
    const notes = formatNote(row?.jobNotes);
    return notes ? (
      <a
        className='anchorNote '
        data-type={'reminder'}
        onClick={(e) => {
          this.getModalOpen(e, row);
        }}
      >
        {renderHTML(notes)}
      </a>
    ) : (
      '-'
    );
  }

  onNoteModalClose() {
    this.setState({ noteModal: false });
  }

  getModalOpen(e, row) {
    let noteText = row && row.jobNotes ? row.jobNotes : '-';
    let lastNoteId = row && row.lastNoteId ? row.lastNoteId : null;
    this.setState({ noteModal: true, noteData: {objectId:lastNoteId,description: noteText,typeId: row.objectId, typeRef: 'PROJECT'} });
  }

  createCustomButtonGroup = (props) => {
    return (
      <div className='pdfLogoBox'>
        <div className='reportHead'>
          <div className='logo'>
            <img src={this.state.comLogo} alt='Upload Logo' />
          </div>
          <h2>Bids Due Report</h2>
        </div>
      </div>
    );
  };
  

   buildReportColumns() {

    const hasContractValPermissions = isAllowed(moduleConstants.VIEWCONTRACTVAL, 'anyAssigned');
    const hasProfitValPermissions = isAllowed(moduleConstants.VIEWPROFIT, 'anyAssigned');

    const { allProjectFieldTypes } = this.state;

    const dynamicFieldsLoaded = allProjectFieldTypes?.length > 0;

    const defaultFieldCodes = ['OCCTYPE','SCOPE'];

    const customFieldColumns = dynamicFieldsLoaded
      ? buildDynamicColumns(
          allProjectFieldTypes.filter((field) => !defaultFieldCodes.includes(field.code))
        )
      : [];

    const defaultFields = dynamicFieldsLoaded
      ? allProjectFieldTypes.filter((field) => defaultFieldCodes.includes(field.code))
      : [];

    const isLeadType = false;

    const defaultRolesCodes = ['EST', 'CL', 'PM'];

    const enabledRoles = this.state.projectRoleTypes.filter(
      (role) => !defaultRolesCodes.includes(role.code) && (!isLeadType || role.isShowOnLeads)
    );
    const defaultRoles = this.state.projectRoleTypes.filter(
      (role) => defaultRolesCodes.includes(role.code) && (!isLeadType || role.isShowOnLeads)
    );

    const defaultColumns = [
      {
        Header: 'Job No.',
        accessor: 'jobNumFormatted',
        sortType: 'basic',
        width: 80,
        Cell: (props) => (
          <>
            <span data-tooltip-content={props.value} data-tooltip-id={props.value}>
              {this.redirectToProject(props.column.id, props.value, props.row.original)}
            </span>
            <Tooltip id={props.value} delayShow={1000} place='bottom' />
          </>
        ),
      },
      {
        Header: 'Alt Job#',
        accessor: 'altJobNum',
        width: 80,
        hidden: true,
        Cell: (props) => this.redirectToProject(props.column.id, props.value, props.row.original),
      },
      {
        Header: 'Bid Start',
        accessor: (row) => (row?.bidStart?.iso ? new Date(row.bidStart.iso) : null),
        id: 'bidStart',
        width: 70,
        Cell: (props) => <>{onDate(props.column.id, props.row.original, props.value)}</>,
        filter: DateColumnFilter,
        sortType: customSortByKey,
        allowDateRangeFilter: true,
        hidden: true,
      },
      {
        Header: 'Bid Due Date',
        accessor: (row) => (row?.bidDue?.iso ? new Date(row.bidDue.iso) : null),
        id: 'bidDue',
        width: 170,
        Cell: (props) => (
          <>{onDateTime(props.column.id, props.row.original, props.value)}</>
        ),
        filter: DateColumnFilter,
        sortType: customSortByKey,
        allowDateRangeFilter: true,
        //sortType: 'datetime',
      },
      {
        Header: 'Job Name',
        accessor: 'jobName',
        width: 120,
        Cell: (props) => this.redirectToProject(props.column.id, props.value, props.row.original),
      },
      hasContractValPermissions && {
        Header: 'Est. Contract',
        accessor: 'currentContractAmount',
        width: 100,
        Cell: (props) => (
          <>{onCurrency(props.column.id, props.row.original, props.value)}</>
        ),
      },
      hasProfitValPermissions && {
        Header: 'Est. Profit',
        accessor: 'currentGrossProfit',
        width: 100,
        hidden: true,
        Cell: (props) => (
          <>{onCurrency(props.column.id, props.row.original, props.value)}</>
        ),
      },
      this.buildColumnForRole(defaultRoles, 'EST'),
      this.buildColumnForRole(defaultRoles, 'PM'),
      buildColumnForField(defaultFields, 'SCOPE', false),
      {
        Header: 'Lead Source',
        accessor: 'leadSource',
        sortType: customSortByKey,
        width: 80,
        hidden: true,
        Filter: SelectColumnFilter,
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: 'Lead Cohort',
        accessor: 'leadCohort',
        sortType: customSortByKey,
        width: 80,
        hidden: true,
        Filter: SelectColumnFilter,
        Cell: (props) => <>{props.value}</>,
      },
      buildColumnForField(defaultFields, 'OCCTYPE', false),
      {
        Header: 'Status',
        accessor: 'jobStatusCodesName',
        width: 60,
        Filter: SelectColumnFilter,
        Cell: (props) => <>{this.onJobStatus(props.column.id, props.row.original, props.value)}</>,
      },
      {
        Header: 'Start Date',
        accessor: (row) => (row?.startDate?.iso ? new Date(row.startDate.iso) : null),
        id: 'startDate',
        width: 90,
        Cell: (props) => <>{onDate(props.column.id, props.row.original, props.value)}</>,
        filter: DateColumnFilter,
        allowDateRangeFilter: true,
        sortType: customSortByKey,
      },
      this.buildColumnForRole(defaultRoles, 'CL',false,true),
      {
        Header: 'Site Address',
        accessor: (row) => formatAddressText(row),
        sortType: 'basic',
        width: 100,
        Cell: (props) => (
          <>
            <Link
              target='_blank'
              rel='noopener noreferrer'
              to={{
                pathname: `https://maps.google.com/?q=` + formatAddressText(props.row.original),
              }}
              data-tooltip-content={props.value}
              data-tooltip-id={props.value}
            >
              {props.value}
            </Link>
          </>
        ),
      },
      {
        Header: 'Notes',
        accessor: 'jobNotes',
        hidden: true,
        width: 120,
        Cell: (props) => (
          <>
            <span
              data-tooltip-html={props.value}
              data-tooltip-id={props.column.id + ':' + props.row.id}
            >
              {this.onNoteDetail(props.column.id, props.row.original, props.value)}
            </span>
            <Tooltip
              id={props.column.id + ':' + props.row.id}
              delayShow={1000}
              style={{ color: '#fff !important' }}
              place='bottom'
            />
          </>
        ),
      },
    ];

    let columns = [];
    if (enabledRoles) {
      const extraColumns = enabledRoles.map((role) =>
        this.buildColumnForRole([role], role.code, true)
      );

      if (columns.length == 0) {
        columns = defaultColumns.filter((cols) => cols?.accessor);
      }

      for (let i in extraColumns) {
        const curColumnsAccessors = columns.map((cols) => cols.accessor);
        if (!curColumnsAccessors.includes(extraColumns[i].accessor)) {
          columns = columns.concat(extraColumns[i]);
        }
      }

      for (let i in customFieldColumns) {
        const curColumnsAccessors = columns.map((cols) => cols.accessor);
        if (!curColumnsAccessors.includes(customFieldColumns[i].accessor)) {
          columns = columns.concat(customFieldColumns[i]);
        }
      }
    } else {
      columns = defaultColumns.filter((cols) => cols?.accessor);
    }

    this.setState({ dynamicColumns: columns });

  }
  render() {
  
    const {
      list, allProjectFieldTypes, dynamicColumns
    } = this.state;

    const { listPref, SetBidDueReportPrefAction } = this.props;

    const dynamicFieldsLoaded = allProjectFieldTypes?.length > 0;

    const rolesLoaded = this.state.projectRoleTypes?.length > 0;

    const dynamicColumnsLoaded = dynamicFieldsLoaded && rolesLoaded;

    
    const dynamicColumnsBuilt = dynamicColumnsLoaded && dynamicColumns.length > 0;

    if(dynamicColumnsLoaded && !dynamicColumnsBuilt){
      this.buildReportColumns();
    }

    const isWidget = !!this.props?.props?.isWidget;

    return (
      <div>
        <Suspense fallback='loading'>
          <div className='ContactListReport'>
            <TitleComponent data={{ title: `Bids Due Report` }} />
            {!isWidget && <Header props={this.props} />}
            <div className='contactBox'>
              <div className='container'>
                <div className='row no-break-print'>
                  {!dynamicColumnsBuilt && (
                    <div id='loaderBox' className='msgBox'>
                      <img src={loaderImage1} width='100' height='100' />
                    </div>
                  )}
                  {dynamicColumnsBuilt && (
                    <div className='col-md-12'>
                      <div
                        className='table-responsive reportTableSection'
                        ref={(el) => (this.componentRef = el)}
                      >
                        {!isWidget && this.createCustomButtonGroup()}
                        <CustomTable
                          columns={dynamicColumns}
                          list={list}
                          preferences={listPref}
                          className='bid-due-table'
                          customTableTitle={!isWidget ? 'Bids Due Report' : ''}
                          onSavePreferences={SetBidDueReportPrefAction}
                          isLoading={this.state.fetchRequest}
                          canShowRowSelection={false}
                          componentRef={this.componentRef}
                          canShowHeaderItems={!isWidget}
                          pageSizeArray={isWidget ? [5] : [50, 100, 200]}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {this.state.noteModal && (
              <NotePopUp
                isOpen={this.state.noteModal}
                onClick={this.onNoteModalClose}
                noteInfo={this.state.noteData}
              />
            )}

            {!isWidget && <Footer />}
          </div>
        </Suspense>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    listData: state.reports.bidsDueList,
    listPref: state.reports.bidsDueListPref,
    projectData: state.project.projectData,
    allReasonCodeByStatusCode: state.reasonCode.allReasonCodeByStatusCode,
    allProjectStatus: state.jobStatusCode,
    reasonCodes: state.reasonCode,
    projectRoles: state.projectRoleType,
    projectFields: state.projectFields,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    GetBidDueReportAction,
    getAllJobStatusAction,
    getAllReasonCodeAction,
    GetBidDueReportPrefAction,
    SetBidDueReportPrefAction,
    getAllProjectRoleAction,
    getAllProjectFieldsAction,
  })(BidDueReport)
);
