import React from 'react';
import ReactLoading from 'react-loading';

import { connect } from 'react-redux';
import renderHTML from 'html-react-parser';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { SelectColumnFilter } from '../../Common/CustomTableComponents/SelectColumnFilter';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { callAlertMessage, UpperCaseName } from '../../../components/Auth/Auth';
import {

  getModuleDetailsAction,
  editModuleDetailsAction
} from '../../../actions/Admin/moduleDetails.actions.js';
import { appConstants } from '../../../_constants';
import CustomTable from '../../Common/CustomTable';
import TitleComponent from '../../Common/TitleComponent';
import './../../../index.css';
import { TextEditorField } from '../../Common/TextEditorField';


// eslint-disable-next-line no-undef
let Validator = require('validatorjs');
let styleData = { color: 'red' };
let formArr = {};
let rules = {
  moduleName: 'required',
  moduleNameWeb: 'required',
  defaultValue: 'required',
  moduleType: 'required',
  moduleDefinition: 'required',
  orderId: 'required',
  moduleCode: 'required',
  trash: 'required'
};

let validation = [];
validation = new Validator([], rules, appConstants.RULESMESSAGE);
validation.passes();
validation.fails();

class ModuleOptions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hidden: true, // htmlFor use objectid hide in table
      sessionToken: localStorage.getItem('sessionToken'),
      userId: localStorage.getItem('userId'),
      list: [],
      formArr: [],
      modal: false,
      modalExcel: false,
      saveType: 'POST',
      formData: {},
      excelType: {},
      fetchRequest: true,
      isCreated: false,
      successRedirect: false,
      masterTypeArr: [],
      selectBox: {},
      optionModuleType: [],
      uploadFile: false,
      moduleEditor: false,
      isDeleted: false,
      ExcelTypeSelect: '',
      description: {},
      selectedRows: [],
      modules:{moduleData:{}},
    };
    this.toggle = this.toggle.bind(this);
    this.toggleExcelSample = this.toggleExcelSample.bind(this);
    this.handleChange1 = this.handleChange1.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.handleRowSelection = this.handleRowSelection.bind(this);
  }


  componentDidMount() {
    this.list();

    let optionModuleType = [];
    appConstants.OptionModuleType.map((v) => {
      if (v.name !== '' && v.value !== '') {
        optionModuleType.push({ value: v.value, label: v.name });
      }
      this.setState({ optionModuleType });
      return true;
    });

    let optionModuleDefaultPermission = [];
    appConstants.OptionModuleDefaultPermission.map((v) => {
      if (v.name !== '' && v.value !== '') {
        optionModuleDefaultPermission.push({ value: v.value, label: v.name });
      }
      this.setState({ optionModuleDefaultPermission });
      return true;
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.moduleData && nextProps.moduleData.length > 0) {
      this.setState({ list: nextProps.moduleData, fetchRequest: false });
      nextProps.moduleData.map((v, i) => {
        if (v && v.isSystemMaster === true) {
          this.state.masterTypeArr.push(v.objectId);
        }
        return true;
      });
    }

    if (nextProps.moduleData && nextProps.moduleData.length === 0) {
      let _this = this;
      setTimeout(function () {
        _this.setState({ fetchRequest: false });
      }, 3000);
    }

    if (nextProps.isCreated !== this.state.isCreated && nextProps.isCreated === true) {
      this.setState({
        isCreated: true,
      });
      let _this = this;
      if (nextProps.success === 1 && this.state.successRedirect && nextProps.message !== '') {
        callAlertMessage('success', nextProps.message);
        if (_this.state.modal) {
          _this.setState({ modal: false });
        }
        this.list();
      }
      if (nextProps.success === 0 && nextProps.message !== '') {
        callAlertMessage('error', nextProps.message);
        if (_this.state.modal) _this.setState({ modal: true });
      }
    }
  }

  list() {
    let data = {};
    data['accountId'] = this.state.accountId;
    data['userId'] = this.state.userId;
    this.props.getModuleDetailsAction(data);
  }

  toggleExcelSample() {
    this.setState({ modalExcel: !this.state.modalExcel });
  }

  toggle(val) {
    if (val === 'POST') {
      this.setState({ formData: {}, description: {}, selectBox: {} }); //Open modal htmlFor add
    } else {
      this.setState({ saveType: 'PUT' });
      this.setState({ formData: this.state.formData }); //Open modal htmlFor edit
    }
    this.setState({ modal: !this.state.modal, formArr: [] });
  }

  selectedRow1 = (row) => {
    let _this = this;
    let selectBox = this.state.selectBox;

    if (row) {
      this.setState({ formData: row });
    }

    if (row && row.moduleType) {
      selectBox.moduleType = [{ value: row.moduleType, label: row.moduleType }];
    }

    if (row && row.defaultValue) {
      selectBox.defaultValue = [{ value: row.defaultValue, label: row.defaultValue }];
    }

    

    this.setState({
      selectBox: selectBox,
      moduleDefinition: row.moduleDefinition,
    });

    if (row) {
      setTimeout(function () {
        _this.toggle('PUT');
      }, 100); // Select row to open edit modal
    } else {
      return false;
    }
  };

  editModuleData(event) {
    event.preventDefault();
    if (this.state.uploadFile === false) {
      let formData = this.state.formData;
      formData['userId'] = this.state.userId;
      formData['accountId'] = this.state.accountId;

      formData['isSystemMaster'] = true;
      formData['orderId']= Number.parseInt(formData['orderId']);
      
      if (this.allValidate(false)) {
        if (this.state.saveType === 'POST') {
          this.props.editModuleDetailsAction(formData);
          this.setState({ uploadFile: false, moduleEditor: false });
        } else {
          this.props.editModuleDetailsAction(formData);
          this.setState({ uploadFile: false, moduleEditor: false });
        }
        this.setState({ successRedirect: true, isCreated: false });
        this.setState({ modal: !this.state.modal, formArr: [] });
      }
    }
    
  }


  allValidate(check) {
    if (!check) {
      formArr = [];
      Object.keys(rules).forEach(function (key) {
        formArr[key] = 'TT';
      });
      this.setState({
        formArr,
      });
    }
    if (validation.passes()) {
      return 1;
    }
  }

  onRenderDes(cell, row, enumObject) {
    if (row) {
      return (
        <div className='helptext-wrapper'>
          <span>
            {renderHTML(row.moduleDefinition ? row.moduleDefinition : '-')}
          </span>
        </div>
      );
    }
  }

  onInputChange(e) {
    this.setState({ uploadFile: true });
  }

  handleChange1(name, value) {
    let field = this.state.formData;
    field[name] = value;
    this.setState({
      formData: field,
      [name]: value,
    });
  }

  handleChange = (e) => {
    e.preventDefault();
    let formData = this.state.formData;

    if(e.target.name==='orderId'){
      formData[e.target.name] = Number(e.target.value);
    }
      else{
        e.preventDefault();
        let field = this.state.formData;
        field[e.target.name] = e.target.value;
        this.setState({ formData: field });
      }
    
  };

  handleChangeStateType = (name, state) => {
    
      let field1 = this.state.selectBox;
      let formData = this.state.formData;
      formData['moduleType'] = state.value;
      field1['moduleType'] = state;
      this.setState({
        selectBox: field1,
        formData: formData,
        moduleEditor: true,
        uploadFile: false,
      });
  };

  handleChangeStateDefault = (name, state) => {
    
    let field1 = this.state.selectBox;
    let formData = this.state.formData;
    formData['defaultValue'] = state.value;
    field1['defaultValue'] = state;
    this.setState({
      selectBox: field1,
      formData: formData,
      moduleEditor: true,
      uploadFile: false,
    });
};

  handleRowSelection = (ids) => {
    this.setState({ selectedRows: ids });
  };

  handleDeleteButtonClick = () => {
    let data = {};
    const { selectedRows, list } = this.state;
    const keysToDelete = list
      .filter((f, index) => selectedRows.includes(index.toString()))
      .map((k) => ({
        objectId: k.objectId,
      }));
    data['accountId'] = this.state.accountId;
    data['userId'] = this.state.userId;
    data['dataArr'] = keysToDelete;
    const confirm = window.confirm('Are you sure to delete?');
    if (confirm) {
      console.log('success');
      //this.props.deleteHelpDetailsAction(data);
      this.setState({ successRedirect: true, isCreated: false });
    } else {
      console.log({ confirm });
    }
  };

  _setTableOption() {
    if (this.state.fetchRequest) {
      return (
        <ReactLoading
          className='table-loader'
          type={appConstants.LOADER_TYPE}
          color={appConstants.LOADER_COLOR}
          height={appConstants.LOADER_HEIGHT}
          width={appConstants.LOADER_WIDTH}
        />
      );
    } else {
      return 'No data found..';
    }
  }


  showTrueFalseTrash(row,name) {
    return (
      <div style={{ textAlign: 'center' }}>
        {row[name] === true ? (
          <i className='fa fa-check fa-lg' style={{ color: 'green' }} aria-hidden='true'></i>
        ) : <i className='fa fa-trash fa-lg' style={{ color: 'red' }} aria-hidden='true'></i>}
      </div>
    );
  }

  

  handleChangeSelect = (name, state) => {
    
    if (name) {
      let formData = this.state.formData;

      if(name==='trash'){
        formData[name] = state.value==='true';
      }
      
      this.setState({
        formData
      });
    }
    };

  render() {
    validation = new Validator(this.state.formData, rules, appConstants.RULESMESSAGE);
    validation.passes();
    validation.fails();


    const selectRowProp = {
      mode: 'checkbox',
      bgColor: function (row, isUnSelect) {
        if (row.isSystemMaster === true) {
          return 'disabled';
        }
        return null;
      },
      unselectable: this.state.masterTypeArr, // give rowkeys for unselectable row
    };

    //show option, delete, pagination, number of data, search
    let tableOption = appConstants.TableOption;
    tableOption['afterDeleteRow'] = this.handleDeleteButtonClick;
    tableOption['paginationShowsTotal'] = this.renderShowsTotal;
    tableOption['onRowClick'] = this.selectedRow1;
    tableOption['noDataText'] = this._setTableOption();
    let disabledCheck;
    let ExcelTypeSelects = '';
    if (this.state.excelType.label === undefined) {
      disabledCheck = true;
      ExcelTypeSelects = 'This field is required';
    } else {
      ExcelTypeSelects = '';

      disabledCheck = false;
    }

    const columns = [
      {
        Header: 'ObjId',
        accessor: 'objectId',
        width: 55,
        disableSortBy: true,
        Cell: (props) =>(<div>{props.row.original?.objectId}</div>),
        },
      {
          Header: "Order",
          accessor: "orderId",
          width: 25,
          Cell: (props) => (<div>{props.row.original?.orderId}</div>),
        },
        {
          Header: "Type",
          accessor: "moduleType",
          width: 55,
          Filter: SelectColumnFilter,
          Cell: (props) => (<div>{props.row.original?.moduleType}</div>),
        },
        {
          Header: "Name",
          accessor: "moduleName",
          width: 55,
          Cell: (props) => (<div>{props.row.original?.moduleName}</div>),
        },
        {
          Header: "DisplayName",
          accessor: "moduleNameWeb",
          width: 55,
          Cell: (props) => (<div>{props.row.original?.moduleNameWeb}</div>),
        },
        {
          Header: "Code",
          accessor: "moduleCode",
          width: 55,
          Cell: (props) => (<div>{props.row.original?.moduleCode}</div>),
        },
        {
          Header: "defaultValue",
          accessor: "defaultValue",
          width: 55,
          Cell: (props) => (<div>{props.row.original?.defaultValue}</div>),
        },{
        Header: 'Desc',
        accessor: 'moduleDefinition',
        width: 300,
        disableSortBy: true,
        Cell: (props) => {
          return this.onRenderDes(props.column.id, props.row.original, props.value);
        },
        
      },
      {
        Header: 'Deleted',
        accessor: 'trash',
        width: 60,
        Cell: (props) => this.showTrueFalseTrash(props.row.original,'trash'),
      },
    ];

    const TrueFalseOpt = [{label:'true',value:'true'},{label:'false',value:'false'}];

    const deletedVal={label:this.state.formData.trash?'true':'false',value:this.state.formData.trash?'true':'false'};

    return (
      <div className='ContactListReport'>
        <TitleComponent data={{ title: `Module Field Settings` }} />
        <div className="container-fluid">
          <div className='row'>
            <div className='col-md-12'>

              <div className='headingbox'>
                <h2 className='heading2'>Edit CommandPost Module Permissions</h2>

              </div>
              <div className='d-flex justify-content-between'>
                <button
                  className='btn btn-primary'
                  style={{ cursor: 'pointer', padding: '5px' }}
                  disabled={this.state.selectedRows?.length < 1}
                  onClick={() => this.handleDeleteButtonClick()}
                >
                  <i className='fa fa-trash' aria-hidden='true'></i>&nbsp;Delete
                </button>
                <button className='btn btn-primary' onClick={() => this.toggle('POST')}>
                  Add
                </button>
              </div>
              <div className='row'>
                <CustomTable
                  columns={columns}
                  list={this.state.list}
                  canShowColumnSettings={false}
                  canShowDateFilter={false}
                  onChangeRowSelection={this.handleRowSelection}
                  canShowExport={true}
                  onRowClicked={(cell, row) => this.selectedRow1(row.original)}
                  pageSizeArray={[50, 100]}
                />
              </div>
            </div>
          </div>
        </div>
        {/* <Footer /> */}

        <Modal
          backdrop='static'
          keyboard={true}
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={this.props.className}
          size={'lg'}
        >
          <ModalHeader toggle={this.toggle}>
            {this.state.formData.objectId ? 'Edit Module' : 'Add Module'}
          </ModalHeader>
          <ModalBody>
            <div className='container'>
              <div className={this.state.uploadFile ? 'help' : 'show'}></div>

              <div className='row'>
                <div className='col-md-6'>
                  <div className='fieldBox'>
                    <label htmlFor='moduleName'>
                      Name<span className='requiredField'>*</span>
                    </label>
                    <input
                      type='text'
                      className='form-control'
                      name='moduleName'
                      id='moduleName'
                      value={this.state.formData && this.state.formData.moduleName}
                      onChange={(e) => {
                        this.handleChange(e);
                      }}
                      placeholder='Title'
                    />
                    <span style={styleData} className='error'>
                      {this.state.formArr.moduleName && validation.errors.first('moduleName')}
                    </span>
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='fieldBox'>
                    <label htmlFor='moduleNameWeb'>
                      Display Name<span className='requiredField'>*</span>
                    </label>
                    <input
                      type='text'
                      className='form-control'
                      name='moduleNameWeb'
                      id='moduleNameWeb'
                      value={this.state.formData && this.state.formData.moduleNameWeb}
                      onChange={(e) => {
                        this.handleChange(e);
                      }}
                      placeholder='Display Name'
                    />
                    <span style={styleData} className='error'>
                      {this.state.formArr.moduleNameWeb && validation.errors.first('moduleNameWeb')}
                    </span>
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='fieldBox'>
                    <label htmlFor='moduleType'>
                      Permission Group<span className='requiredField'>*</span>
                    </label>
                    <Select
                      name='moduleType'
                      components={makeAnimated()}
                      value={this.state.selectBox.moduleType}
                      onChange={this.handleChangeStateType.bind(this, 'moduleType')}
                      options={this.state.optionModuleType}
                    />
                    <span style={styleData} className='error'>
                      {this.state.formArr.moduleType && validation.errors.first('moduleType')}
                    </span>
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='fieldBox'>
                    <label htmlFor='defaultValue'>
                      Default To<span className='requiredField'>*</span>
                    </label>
                    <Select
                      name='defaultValue'
                      components={makeAnimated()}
                      value={this.state.selectBox.defaultValue}
                      onChange={this.handleChangeStateDefault.bind(this, 'defaultValue')}
                      options={this.state.optionModuleDefaultPermission}
                    />
                    <span style={styleData} className='error'>
                      {this.state.formArr.defaultValue && validation.errors.first('defaultValue')}
                    </span>
                  </div>
                </div>
              
              <div className='col-md-6'>
                  <div className='fieldBox'>
                    <label htmlFor='orderId'>
                      OrderId<span className='requiredField'>*</span>
                    </label>
                    <input
                      type='text'
                      className='form-control'
                      name='orderId'
                      id='orderId'
                      value={this.state.formData && this.state.formData.orderId}
                      onChange={(e) => {
                        this.handleChange(e);
                      }}
                      placeholder='OrderId'
                    />
                    <span style={styleData} className='error'>
                      {this.state.formArr.orderId && validation.errors.first('orderId')}
                    </span>
                  </div>
                  <div className='check'>
                      <label htmlFor='trash'>Deleted</label>
                      <Select
                      name="trash"
                     
                      value={deletedVal}
                      options={TrueFalseOpt}
                      onChange={this.handleChangeSelect.bind(this, 'trash')}
                    />
                      <span style={styleData}>
                        {this.state.formArr.trash && validation.errors.first('trash')}
                      </span>
                    </div>
                </div>
                </div>
                <div className='col-md-6'>
                  <div className='fieldBox'>
                    <label htmlFor='moduleCode'>
                      Permission Code<span className='requiredField'>*</span>
                    </label>
                    <input
                      type='text'
                      className='form-control'
                      name='moduleCode'
                      id='moduleCode'
                      value={this.state.formData && this.state.formData.moduleCode}
                      onChange={(e) => {
                        this.handleChange(e);
                      }}
                      placeholder='Display Name'
                    />
                    <span style={styleData} className='error'>
                      {this.state.formArr.moduleCode && validation.errors.first('moduleCode')}
                    </span>
                  </div>
                </div>
              <div className='row'>
                <div className='col-md-12'>
                  <div className='fieldBox'>
                    <label htmlFor='contactRoleDefinition'>
                      Description<span className='requiredField'>*</span>
                    </label>
                    
                      <div className='row'>
                        <div className='col-md-12'>
                          <div className='form-group'>
                            <TextEditorField 
                              title='Description'
                              name='moduleDefinition'
                              value={this.state.formData?.moduleDefinition}
                              onChange={(e) => this.handleChange(e)}
                            />
                          </div>
                        </div>
                      </div>
                   
                    <span style={styleData} className='error'>
                      {this.state.formArr.moduleDefinition && validation.errors.first('moduleDefinition')}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button className='btn' onClick={this.toggle}>
              Cancel
            </button>
            <button className='btn' onClick={(e) => this.editModuleData(e)}>
              Submit
            </button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    moduleData: state.siteModules.moduleData,
    isCreated: state.siteModules.isCreated,
    message: state.siteModules.message,
    success: state.siteModules.success,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    getModuleDetailsAction,
    editModuleDetailsAction,
  })(ModuleOptions)
);