import * as actionTypes from '../actions/actionTypes';
const initState = {
  response: [],
  message: '',
  dashboardData: false,
  isDashboardList: false,
  isDashboardListError: false,
  dashboardReport: false,
  isDashboardReport: false,
  isDashboardReportError: false,
  isMissingData: false,
  isMissingDataSuccess: false,
  isMissingDataError: false,
  isCloseData: false,
  isCloseSuccess: false,
  isCloseError: false,
};

export default (state = initState, action = {}) => {
  switch (action.type) {
    case actionTypes.GET_ALL_HOME_REQUEST:
      return {
        ...state,

        response: [],
        message: '',
        isDashboardList: false,
        isDashboardListError: false,
      };
    case actionTypes.GET_ALL_HOME_SUCCESS:
      return {
        ...state,

        dashboardData: action.payload.response.result,
        message: '',
        isDashboardList: true,
        isDashboardListError: false,
      };

    case actionTypes.GET_ALL_HOME_ERROR:
      return {
        ...state,
        response: undefined,
        message: '',
        isDashboardList: false,
        isDashboardListError: true,
      };

    //Dashboard report
    case actionTypes.GET_BID_MISSED_WIDGET_REQUEST:
      return {
        ...state,
        response: [],
        dashboardReport: [],
        isDashboardReport: false,
        isDashboardListError: false,
      };
    case actionTypes.GET_BID_MISSED_WIDGET_SUCCESS:
      return {
        ...state,
        dashboardReport: action.payload.response.result,
        isDashboardReport: true,
        isDashboardReportError: false,
      };

    case actionTypes.GET_BID_MISSED_WIDGET_ERROR:
      return {
        ...state,
        response: undefined,
        dashboardReport: [],
        isDashboardReport: false,
        isDashboardReportError: true,
      };

    //Dashboard report
    case actionTypes.GET_MISSING_DATA_REQUEST:
      return {
        ...state,
        isMissingData: [],
        isMissingDataSuccess: false,
        isMissingDataError: false,
      };
    case actionTypes.GET_MISSING_DATA_SUCCESS:
      return {
        ...state,
        isMissingData: action.payload.response.result,
        isMissingDataSuccess: true,
        isMissingDataError: false,
      };

    case actionTypes.GET_MISSING_DATA_ERROR:
      return {
        ...state,
        isMissingData: undefined,
        isMissingDataSuccess: false,
        isMissingDataError: true,
      };

    //Dashboard close welcome section
    case actionTypes.UPDATE_CLOSE_WELCOME_REQUEST:
      return {
        ...state,
        isCloseData: [],
        isCloseSuccess: false,
        isCloseError: false,
      };
    case actionTypes.UPDATE_CLOSE_WELCOME_SUCCESS:
      return {
        ...state,
        isCloseData: action.payload.response.result,
        isCloseSuccess: true,
        isCloseError: false,
      };

    case actionTypes.UPDATE_CLOSE_WELCOME_ERROR:
      return {
        ...state,
        isCloseData: undefined,
        isCloseSuccess: false,
        isCloseError: true,
      };

    default:
      return state;
  }
};
