// import { Checkbox } from 'antd';
import moment from 'moment';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { getProjectRoles } from '../../modules/settings';
import { useAppDispatch } from '../../shared/hooks/use-app-dispatch';
import { GroupByControl } from './controls/group-by-control';
import { IncludeProjectsControl } from './controls/include-projects-control';
import { ShowByControl } from './controls/show-by-control';
import { StartDateControl } from './controls/start-date-control';

type ResourceScheduleFilterModalProps = {
  open: boolean;
  onClose: () => void;
  onFiltersApplied?: (args: any) => void;
  ref?: any;
  defaultValues?: any;
  config?: any;
  title?: string;
};

export const ResourceScheduleFilterModal = ({
  open,
  onClose,
  onFiltersApplied = () => void 0,
  defaultValues,
  config,
  title = 'Estimating Schedule Preferences',
}: ResourceScheduleFilterModalProps) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!open) return;

    dispatch(getProjectRoles());
  }, [open]);

  useEffect(() => {
    if (defaultValues) {
      form.reset(defaultValues);
    }
  }, [defaultValues]);

  const form = useForm({
    defaultValues: {
      showBy: 'days',
      startDateOption: 'today',
      groupByOption: 'estimator',
      jobStatusOption: ['bidding'],
      startDate: moment().startOf('day'),
      groupBy: null,
      isGroupShared: false,
    },
  });

  const submit = form.handleSubmit(async (data) => {
    const jobStatusCode = config.getStatusCodesFromOptions(data.jobStatusOption);

    const startDate = (() => {
      if (data.startDateOption === 'other') return data.startDate.startOf('day');

      // should be beginning of the day
      switch (data.startDateOption) {
        case 'today':
          return moment().startOf('day');
        case 'tomorrow':
          return moment().clone().add(1, 'days').startOf('day');
        case 'yestreday':
          return moment().clone().subtract(1, 'days').startOf('day');
        case 'thisWeek':
          return moment().startOf('week');
        case 'nextWeek':
          return moment().add(1, 'week').startOf('week');
        case 'thisMonth':
          return moment().startOf('month');
        case 'nextMonth':
          return moment().add(1, 'month').startOf('month');
        case 'thisYear':
          return moment().startOf('year');
        case 'nextYear':
          return moment().add(1, 'year').startOf('year');
        default:
          return moment().startOf('day');
      }
    })();

    // Days will show 3 days prior, initial day, + 10 days foward
    // Weeks will show 2 weeks prior, selected week, + 11 forward weeks
    // Months will show previous month, selected month, + 12 forward months
    const startDateGLTE = (() => {
      const showBy = data.showBy;

      switch (showBy) {
        case 'hours':
          return {
            gte: startDate.clone().startOf('day'),
            lte: startDate.clone().endOf('day'),
          };

        case 'days':
          return {
            gte: startDate.clone().subtract(3, 'days').startOf('day'),
            lte: startDate.clone().add(10, 'days').endOf('day'),
          };

        case 'weeks':
          return {
            gte: startDate.clone().subtract(2, 'weeks').startOf('week'),
            lte: startDate.clone().add(11, 'weeks').endOf('week'),
          };
        case 'months':
          return {
            gte: startDate.clone().subtract(1, 'month').startOf('month'),
            lte: startDate.clone().add(12, 'month').endOf('month'),
          };
        default:
          return {
            gte: startDate.clone().subtract(3, 'days').startOf('day'),
            lte: startDate.clone().add(10, 'days').endOf('day'),
          };
      }
    })();

    const payload = {
      startDate: startDateGLTE,
      jobStatusCode,
      groupBy: data.groupBy,
      isGroupShared: data.isGroupShared,
    };

    const schedulerSettings = {
      startDate: startDateGLTE.gte.toISOString(),
      endDate: startDateGLTE.lte.toISOString(),
      isGroupShared: data.isGroupShared,
      groupBy: data.groupBy,
      viewPreset: (() => {
        switch (data.showBy) {
          case 'hours':
            return 'hourAndDay';
          case 'days':
            return 'weekAndDay';
          case 'weeks':
            return 'weekDateAndMonth';
          case 'months':
            return 'monthAndYear';
          default:
            return 'weekAndDay';
        }
      })(),
    };

    onClose();
    onFiltersApplied({
      dataFilters: payload,
      schedulerSettings,
      formSettings: data,
    });
  });

  return (
    <Modal isOpen={open} toggle={onClose} size='lg'>
      <ModalHeader toggle={onClose}>{title}</ModalHeader>
      <ModalBody>
        <FormProvider {...form}>
          {/* grid 2 x 2 */}
          <div className='container'>
            <div className='row g-3'>
              <div className='col-md-6'>
                <ShowByControl />
              </div>

              <div className='col-md-6'>
                <StartDateControl />
              </div>
              <div className='col-md-6'>
                <IncludeProjectsControl options={config.statusOptions} />
              </div>

              <div className='col-md-6'>
                <GroupByControl options={config.groupByOptions} />
              </div>
            </div>
          </div>
        </FormProvider>
      </ModalBody>
      <ModalFooter>
        {/* 
          /// Temporary hide this feature
        <Checkbox className='ms-auto me-auto' value={1}>
          Allow drag and drop roles (display only)
        </Checkbox> */}

        <button className='btn btn-outline-secondary' onClick={submit}>
          View
        </button>
      </ModalFooter>
    </Modal>
  );
};
