import * as actionTypes from './actionTypes';
import * as API from '../api/projectTasks';

export const createProjectTasksRequest = () => ({
  type: actionTypes.CREATE_PROJECT_TASKS_REQUEST,
});

export const createProjectTasksSuccess = (response) => ({
  type: actionTypes.CREATE_PROJECT_TASKS_SUCCESS,
  payload: {
    response,
  },
});

export const createProjectTasksError = (error) => ({
  type: actionTypes.CREATE_PROJECT_TASKS_ERROR,
  payload: {
    error,
  },
});

export const createProjectTasksAction = (data) => {
  return (dispatch) => {
    dispatch(createProjectTasksRequest());
    return API.createProjectTasks(data)
      .then((response) => {
        dispatch(createProjectTasksSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          createProjectTasksError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

export const getAllProjectTasksRequest = () => ({
  type: actionTypes.GET_ALL_PROJECT_TASKS_REQUEST,
});

export const getAllProjectTasksSuccess = (response) => ({
  type: actionTypes.GET_ALL_PROJECT_TASKS_SUCCESS,
  payload: {
    response,
  },
});

export const getAllProjectTasksError = (error) => ({
  type: actionTypes.GET_ALL_PROJECT_TASKS_ERROR,
  payload: {
    error,
  },
});

export const getAllProjectTasksAction = (data) => {
  return (dispatch) => {
    dispatch(getAllProjectTasksRequest());
    return API.getAllProjectTasks(data)
      .then((response) => {
        dispatch(getAllProjectTasksSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          getAllProjectTasksError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

export const updateProjectTasksRequest = () => ({
  type: actionTypes.UPDATE_PROJECT_TASKS_REQUEST,
});

export const updateProjectTasksSuccess = (response) => ({
  type: actionTypes.UPDATE_PROJECT_TASKS_SUCCESS,
  payload: {
    response,
  },
});

export const updateProjectTasksError = (error) => ({
  type: actionTypes.UPDATE_PROJECT_TASKS_ERROR,
  payload: {
    error,
  },
});

export const updateProjectTasksAction = (data) => {
  return (dispatch) => {
    dispatch(updateProjectTasksRequest());
    return API.updateProjectTasks(data)
      .then((response) => {
        dispatch(updateProjectTasksSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          updateProjectTasksError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

export const deleteProjectTasksRequest = () => ({
  type: actionTypes.DELETE_PROJECT_TASKS_REQUEST,
});

export const deleteProjectTasksSuccess = (response) => ({
  type: actionTypes.DELETE_PROJECT_TASKS_SUCCESS,
  payload: {
    response,
  },
});

export const deleteProjectTasksError = (error) => ({
  type: actionTypes.DELETE_PROJECT_TASKS_ERROR,
  payload: {
    error,
  },
});

export const deleteProjectTasksAction = (data) => {
  return (dispatch) => {
    dispatch(deleteProjectTasksRequest());
    return API.deleteProjectTasks(data)
      .then((response) => {
        dispatch(deleteProjectTasksSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          deleteProjectTasksError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};
