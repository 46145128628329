import FormCheckbox from '../../form-controls/form-checkbox';
import { useAppSelector } from '../../hooks/use-app-selector';
import { selectGetCustomSelectOptions } from '../../../modules/settings/selectors';
import { useFormContext } from 'react-hook-form';
import FormMultiselect from '../../form-controls/form-multiselect';
import FormSelect from '../../form-controls/form-select';
import { useEffect } from 'react';

export const DividerSection = () => {
    const { data: selectOptions } = useAppSelector(selectGetCustomSelectOptions);
    const form = useFormContext();


    const criteria = form.watch('valueDefinition.criteriaType');

    useEffect(() => {

        if (!form.formState.dirtyFields.valueDefinition?.criteriaType) return;
        const resetValueDefinition = (newValues) => {
            const prevValues = form.getValues();

            form.reset({
                ...prevValues,
                restrictVisibility: true,
                valueDefinition: {
                    ...newValues,
                },
            });
        };

        switch (criteria) {
            case 'jobScope':
                resetValueDefinition({
                    criteria: 'SCOPE',
                    criteriaValue: [],
                    hasDataSrc: true,
                    dataSrc: 'jobScopes',
                    criteriaType: 'jobScope',
                    restrictVisibility: true,
                });
                break;
            default:
                break;
        }
    }
        , [criteria]);

    const restrictVisibility = form.watch('valueDefinition.restrictVisibility');
    const hasDataSrc = restrictVisibility;

    const options = hasDataSrc ? selectOptions : form.watch('valueDefinition.options');


    return (
        <div>
            <div className='row'>
                <div className='col-12'>
                    <label className='form-label'>Divider Options</label>
                </div>


                <div className='col-6'>
                    <div className='row'>
                        <div className='col-12'>
                            <FormCheckbox
                                className='flex-row-reverse mb-1'
                                name='valueDefinition.restrictVisibility'
                                label='Conditional section visibility'
                            />
                        </div>


                    </div>
                </div>

            </div>
            {restrictVisibility && (
                <div className='row'>
                    <div className='col-6'>
                        <FormSelect
                            label='Section visibility depends on'
                            name='valueDefinition.criteriaType'
                            options={[{ label: 'Job Scope', value: 'jobScope' }]}
                        />
                    </div>
                    <div className='col-6'>
                        <FormMultiselect
                            label='When value is'
                            name='valueDefinition.criteriaValue'
                            options={options}
                            useObjectValue
                        />
                    </div>
                </div>
            )}
        </div>
    );
};
