import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Tooltip } from '@material-ui/core';
import { formatDate, formatDateObject } from '../../../utils/date-formatters';
import styles from '../change-order-modal.module.scss';
import { formatMoney } from '../../../utils/number-formatters';

type ChangeOrderModalProps = {
  project: any;
  changeOrders: Array<any>;
  isLoading: boolean;
  coId?: string;
};

export const ChangeOrderTable = ({
  project = {},
  changeOrders = [],
  isLoading = true,
  coId = '',
}: ChangeOrderModalProps) => {

  const { watch } = useFormContext();

  const coNumber = watch('coNumber');
  const coTotalAmount = watch('estTotalContract');
  const scheduleImpact = watch('scheduleImpact');
  

  const [isRowExpanded, setIsRowExpanded] = useState<boolean>(false);

  const { baselineEndDate, baselineStartDate, originalContractAmount } = project;
  const startDate = baselineStartDate?.iso || baselineStartDate; // for legacy api
  const endDate = baselineEndDate?.iso || baselineEndDate; // for legacy api

  const changeOrdersFiltered = changeOrders.filter((co) => !co.isPCO); // calculate only approved COs
  const changeOrdersTotal = changeOrdersFiltered.reduce((acc, cur) => acc + cur.coTotalAmount, 0);
  const sortedChangeOrders = [...changeOrdersFiltered].sort((a, b) => a.coNumber - b.coNumber);
  const sumPrevCODays = sortedChangeOrders.reduce((acc, cur) => acc + (+cur.scheduleImpact || 0), 0);
  const daysInProject = formatDateObject(endDate).diff(formatDateObject(startDate), 'days');
  const isNewOrPCO = !coId || changeOrders.find((co) => co.objectId === coId)?.isPCO;

  const numberWithSymbol = (value: number) => `${value >= 0 ? '+' : ''}${value}`;

  const getPreviousChangeOrdersMarkup = () => {
    if (isLoading) {
      return <tr className={styles.thickBorder}><td colSpan={3}>Loading...</td></tr>;
    }

    if (sortedChangeOrders.length === 0) {
      return <tr className={styles.thickBorder}><td colSpan={3}>No Previous Change Orders</td></tr>;
    }

    const prevCOrows = sortedChangeOrders.map((changeOrder, index) => {
      const isCOnegative = changeOrder.coTotalAmount < 0;
      const totalAmountMoney = formatMoney(Math.abs(changeOrder.coTotalAmount));
      const rowClassName = index === 0 ? `${styles.thickBorder}` : '';
      const isCurrent = changeOrder.objectId === coId;

      return (
        <tr className={rowClassName} key={changeOrder.objectId}>
          <Tooltip placement='left' arrow classes={{ tooltip: styles.tooltip }}
            title={
              <ul>
                <li>{`CO: #${changeOrder.coNumber}`}</li>
                <li>{`Date: ${formatDate(changeOrder.changeOrderDate.iso)}`}</li>
                <li>{`Reason: ${changeOrder.reasonName || 'No particular reason'}`}</li>
                <li>{`Description: ${changeOrder.comment || 'No particular description'}`}</li>
                <li>{`Cost: ${formatMoney(changeOrder.coCostAmount)}`}</li>
                <li>{`Profit: ${formatMoney(changeOrder.profitDollars)}`}</li>
                <li>{`Total: ${formatMoney(changeOrder.coTotalAmount)}`}</li>
              </ul>
            }
          >
            <td>{`CO #${changeOrder.coNumber} ${isCurrent ? '(this CO)' : ''}`}</td>
          </Tooltip>
          {isCOnegative
              ? <td className='text-danger'>({totalAmountMoney})</td>
              : <td>{totalAmountMoney}</td>
          }
          <td>{numberWithSymbol(changeOrder.scheduleImpact || 0)} days</td>
        </tr>
      )
    });

    const subtotalAmount = sortedChangeOrders.reduce((acc, { coTotalAmount }) => acc += coTotalAmount, 0);
    const subtotalDuration = sortedChangeOrders.reduce((acc, { scheduleImpact = 0 }) => acc += scheduleImpact, 0);

    return (
      <>
        {prevCOrows}
        <tr className={styles.thickBorder}>
          <td className='text-end'>Subtotal</td>
          <td>{formatMoney(subtotalAmount)}</td>
          <td>{numberWithSymbol(subtotalDuration || 0)} days</td>
        </tr>
      </>
    );
  };

  const revisedDays = +daysInProject + +sumPrevCODays + (isNewOrPCO ? +scheduleImpact : 0);
  const revisedTotal = originalContractAmount + changeOrdersTotal + (isNewOrPCO ? coTotalAmount : 0);

  return (
    <table className={styles.coTable} data-testid='changeOrderModal-table'>
      <thead>
        <tr>
          <th>Description</th>
          <th>Amount ($)</th>
          <th>Duration (calendar days)</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Original Contract</td>
          <td>{formatMoney(originalContractAmount)}</td>
          <td>{daysInProject} days</td>
        </tr>
        <tr>
          <td>
            <span onClick={() => setIsRowExpanded(!isRowExpanded)} className={styles.expandable} data-testid='changeOrderModal-toggler'>
              <i className={`fa fa-chevron-${isRowExpanded ? 'up' : 'down'}`} aria-hidden='true'></i>
              {!isRowExpanded && 'Sum'} Approved Change Orders
            </span>
          </td>
          <td>{isLoading ? 'Loading...' : isRowExpanded ? '' : formatMoney(changeOrdersTotal)}</td>
          <td>{isRowExpanded ? '' : `${numberWithSymbol(sumPrevCODays)} days`}</td>
        </tr>
        {isRowExpanded && getPreviousChangeOrdersMarkup()}
        {isNewOrPCO && <tr>
          <td>{`This Change Order ${coNumber ? `(#${coNumber})` : ''}`}</td>
          <td>{formatMoney(coTotalAmount)}</td>
          <td>{numberWithSymbol(scheduleImpact || 0)} days</td>
        </tr>}
        <tr className={styles.thickBorder}>
          <td>Revised Contract</td>
          <td>{formatMoney(revisedTotal)}</td>
          <td>{revisedDays} days</td>
        </tr>
      </tbody>
    </table>
  );
};
