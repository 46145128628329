import { useEffect, useState } from 'react';
import { getTeamActivity } from '../../../../actions/Admin/user.activity';
import { selectGetTeamActivity } from '../../../../modules/contacts/selectors';
import { useAppDispatch } from '../../../../shared/hooks/use-app-dispatch';
import { useAppSelector } from '../../../../shared/hooks/use-app-selector';
import { CustomTable } from '../../../Common/CustomTable';
import { useTeamActivityPage } from './column-helper';

import TitleComponent from '../../../Common/TitleComponent';
import { Suspense } from 'react';

import usePagination from '../../../../shared/data-grid/use-pagination';
import RemotePagination from '../../../../shared/data-grid/remote-pagination';

export const UserActivityPage = () => {
  const dispatch = useAppDispatch();

  const [teamActivityData, setTeamActivityData] = useState([]);
  const [total, setTotal] = useState(0);

  const pagination = usePagination({ total });

  const { page, limit } = pagination;
  const { loading } = useAppSelector(selectGetTeamActivity);
  const [columns] = useTeamActivityPage();

  useEffect(() => {
    dispatch(getTeamActivity({count:limit, page}))
      .unwrap()
      .then((data) => {
        setTeamActivityData(data.teamActivity);
        setTotal(data.total);
      });
  }, [page, limit]);

  return (
    <div className='ContactListReport'>
      <Suspense fallback='loading'>
        <TitleComponent data={{ title: `Team Activity` }} />
        <div className='row contactBox ms-2 me-2 row'>
    
            <div className='col'>
              <div className='container'>
                <h3>Team Activity</h3>
                <CustomTable
                  columns={columns}
                  list={teamActivityData}
                  inlineEdit={false}
                  canShowExport={false}
                  canShowPagination={false}
                  canShowGlobalFilter={false}
                  canShowColumnSettings={false}
                  canShowDateFilter={false}
                  canShowRowSelection={false}
                  isLoading={loading}
                />
                <RemotePagination {...pagination} />
              </div>
            </div>
        
        </div>
     
      </Suspense>
    </div>
  );
};

export default UserActivityPage;
