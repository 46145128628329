import { useMemo, useState } from 'react';
import { formatMoney } from '../../../../utils/number-formatters';
import coStyles from '../../summary-financial-modal.module.scss';

type ChangeOrdersProps = {
  changeOrders: Array<any>;
  isPotential: boolean;
  onOpenCOModal: any;
};

export const ChangeOrders = ({
  changeOrders = [],
  isPotential = true,
  onOpenCOModal,
}: ChangeOrdersProps) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(isPotential);

  changeOrders.sort((coA, coB) => coA.coNumber - coB.coNumber);

  const coRows = useMemo(
    () =>
      changeOrders
        .filter((co) => (co.isPCO && (isPotential ? !co.notApproved : co.notApproved)))
        .map((co) => {
          const {
            objectId,
            coNumber,
            coCostAmount,
            profitDollars,
            coTotalAmount,
            scheduleImpact,
            notApproved,
          } = co;

          return (
            <tr key={coNumber} role='button' onClick={() => onOpenCOModal(objectId)}>
              <td>{notApproved ? '(not approved)' : '(pending)'}</td>
              <td>PCO {coNumber}</td>
              <td className='text-end'>{formatMoney(coCostAmount)}</td>
              <td className='text-end'>{formatMoney(profitDollars)}</td>
              <td className='text-end'>{formatMoney(coTotalAmount)}</td>
              <td className='text-end'>{scheduleImpact} days</td>
            </tr>
          );
        }),
    [changeOrders]
  );

  const togglableHeaderProps = isPotential
    ? {}
    : {
        onClick: () => setIsExpanded(!isExpanded),
        role: 'button',
      };

  if (coRows.length === 0) {
    return null;
  }

  return (
    <div>
      <span className='d-flex justify-content-between' {...togglableHeaderProps}>
        <b>{isPotential ? 'Potential' : 'Not-Approved'} Change Orders</b>{' '}
        {!isPotential && (
          <div className={`col-md-2 ${coStyles.chevron}`}>
            <i
              className={`fa-solid fa-chevron-${isExpanded ? 'down' : 'right'}`}
              data-title={isExpanded ? 'Collanse' : 'Expand'}
            ></i>
          </div>
        )}
      </span>
      {isExpanded && (
        <table className={`${coStyles.coTable} ${coStyles.clickable}`}>
          <thead>
            <tr>
              <th>Date</th>
              <th>Name</th>
              <th>Cost</th>
              <th>Profit</th>
              <th>Total</th>
              <th>Delay</th>
            </tr>
          </thead>
          <tbody>{coRows}</tbody>
        </table>
      )}
    </div>
  );
};
