import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { scheduleHtml } from '../../components/Layout/RightSectionRender';

type SchedulePopUpProps = {
  data: any;
  isOpen: boolean;
  toggle: any;
};

export const SchedulePopUp = ({ data, isOpen, toggle }: SchedulePopUpProps) => {
  const ScheduleHtml = scheduleHtml;

  if (!data) return null;

  return (
    <>
      <Modal backdrop='static' isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>{'Note Details'}</ModalHeader>
        <ModalBody>
          <div className='noteContainer container'>
            <ScheduleHtml {...data}></ScheduleHtml>
          </div>
        </ModalBody>
        <ModalFooter>
          <button className='btn btn-primary' onClick={toggle}>
            Close
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
};
