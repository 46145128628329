import { postRequest, postRequestForSearch, postRequestWithoutParseData } from '../helper';

export const getAllAccount = (data) => postRequestForSearch('functions/adminAccountsData', data);
export const AllAccountActiveDeactivated = (data) => postRequest('functions/adminActivateDeactivate', data);
export const registerAdminCompany = (data) => postRequest(`functions/registerNewAccount`, data);

// Add terms and condition
export const addTermsCondition = (data) => postRequest('functions/addTermCondition', data);
export const editTermsCondition = (data) => postRequest('functions/editTermCondition', data);
export const getTermsCondition = (data) => postRequest('functions/getTermConditionData', data);

//Change Password
export const resetPassword = (data) => postRequest('functions/adminChangePassword', data);
