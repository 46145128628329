import React, { Suspense } from 'react';

import { Link, withRouter } from 'react-router-dom';
import Header from '../../Layout/Header.js';
import Footer from '../../Layout/Footer.js';
import { connect } from 'react-redux';
import { appConstants } from '../../../_constants/index.js';
import {
  GetContactListInfoReportAction,
  GetContactListInfoReportPrefAction,
  SetContactListInfoReportPrefAction,
} from '../../../actions/reports.js';
import ReactLoading from 'react-loading';
import TitleComponent from '../../Common/TitleComponent.js';

import {  CustomTable } from '../../Common/CustomTable.jsx';
import { getAllContactFieldsAction } from '../../../actions/contactFields.js';
import {
  formatPhoneArrayHTML,
  formatAddressText,
  formatAddressHTML,
} from '../../../utils/contact-formatters.jsx';
import { formatPhone } from '../../../utils/formatters.js';
import { formatDate } from '../../../utils/date-formatters.js';
import { DateColumnFilter } from '../../Common/CustomTableComponents/DateColumnFilter.js';
import {buildColumnForField, buildDynamicColumns} from '../../../utils/column-formatters.js';
import {sortAsc, UpperCaseName} from '../../Auth/Auth.js';

var sortingOrder = '';

class ContactListInfoReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {},
      accountId: localStorage.getItem('accountId'),
      userId: localStorage.getItem('userId'),
      list: [],
      fetchRequest: true,
      comLogo: '',
      currentPage: 1,
      sizePerPage: appConstants.TableSizePerPageList[2].value,
      searchText: '',
      totalCount: 0,
      subtitle: '',
      sortType: 'DESC',
      sortKey: '',
      allContactFieldTypes: [],
      dynamicColumns: [],
    };
    this.onPageChange = this.onPageChange.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.onSortChange = this.onSortChange.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.listData && nextProps.listData.success && nextProps.listData.success === 1) {
      this.setState({
        totalCount: nextProps.listData.count,
        list: nextProps.listData.data,
        fetchRequest: false,
      });
    }

    if (nextProps.listData && nextProps.listData.data && nextProps.listData.data.length == 0) {
      this.setState({ fetchRequest: false });
    }

    if (nextProps?.contactFields?.allContactFieldTypes?.length > 0) {
      this.setState({
        allContactFieldTypes: nextProps.contactFields.allContactFieldTypes,
      });
    }
  }

  componentDidMount() {
    this.setState({
      sizePerPage: appConstants.TableSizePerPageList[2].value,
      currentPage: 0,
    });
    let data = {};
    data['accountId'] = this.state.accountId;
    data['moduleName'] = appConstants.reportModuleName['3'];
    data['page'] = 0;
    data['displayLimit'] = this.state.sizePerPage;
    data['sortKey'] = this.state.sortKey;
    data['sortType'] = this.state.sortType;
    data['search'] = this.state.searchText;
    this.props.GetContactListInfoReportAction(data);

    sortingOrder = '';

    if (
      localStorage.getItem('companyLogo') !== 'undefined' &&
      localStorage.getItem('companyLogo') !== null
    ) {
      this.setState({
        comLogo: localStorage.getItem('companyLogo'),
      });
    } else {
      let constImage = appConstants.CONTENT_CPOST_LOGO_URL;
      this.setState({ comLogo: constImage });
    }
    this.props.GetContactListInfoReportPrefAction();

    this.props.getAllContactFieldsAction({
      accountId: this.state.accountId,
    });
  }

  onGetAddressInfo(cell, row, enumObject, rowIndex) {
    if (row.original) {
      row = row.original;
      if (row && row.addressArr) {
        let addressArr = row.addressArr.map((v) => formatAddressText(v));
        return addressArr?.join('\n');
      }
    }
    if (row && row.addressArr) {
      let addressArr = row.addressArr.map((v) => formatAddressHTML(v));

      return addressArr;
    } else {
      return <span>-</span>;
    }
  }

  onContactEmail(cell, row, enumObject, rowIndex) {
    let emailHtml = '';
    let isExport = false;
    if (row.original) {
      row = row.original;
      isExport = true;
    }
    if (row && row.emailArr && row.emailArr.length > 0) {
      if (!isExport) {
        row.emailArr.map((item) => {
          if (emailHtml !== '') {
            emailHtml = emailHtml + '<br/>';
          }
          emailHtml = emailHtml + "<a href=mailto:'" + item.email + '>' + item.email + '</a>';
          return true;
        });
        return <div dangerouslySetInnerHTML={{ __html: emailHtml }} />;
      } else {
        return row.emailArr.map((email) => email.email).join(', ');
      }
    } else {
      return <span>-</span>;
    }
  }

  formatDateCell(cell, row, accessor) {
    if (row && row[accessor]) {
      return <div>{formatDate(row[accessor]) ?? ''}</div>;
    } else {
      return <span>-</span>;
    }
  }

  onContactPhone(cell, row, enumObject, rowIndex) {
    let phn = '';
    if (row.original) {
      row = row.original;
      if (row && row.phoneArr && row.phoneArr.length > 0) {
        return row.phoneArr.map((phone) => formatPhone(phone, [], true)).join(', ');
      } else {
        return <span>-</span>;
      }
    }
    if (row && row.phoneArr && row.phoneArr.length > 0) {
      return <div dangerouslySetInnerHTML={{ __html: formatPhoneArrayHTML(row.phoneArr) }} />;
    } else {
      return <span>-</span>;
    }
  }
  onVenderName(cell, row, enumObject, rowIndex) {
    if (row && row.venderArr && row.venderArr.length > 0) {
      let venderArr = sortAsc(row.venderArr, 'label');

      return (
        <div>
          {venderArr.map((item, i) => {
            let comma;
            if (i != 0) {
              comma = ', ';
            } else {
              comma = '';
            }
            return (
              <Link to={{ pathname: `/company-details/` + item.venderId }}>
                {comma}
                {item.label}{' '}
              </Link>
            );
          })}
        </div>
      );
    } else {
      return <span>-</span>;
    }
  }
  onContactName(cell, row, enumObject, rowIndex) {
    if (row?.contactId) {
      return (
        <div>
          <Link to={{ pathname: `/contact-details/` + row.contactId }}>{row.displayName}</Link>
        </div>
      );
    } else {
      return <span>Company Profile</span>;
    }
  }

  onTextField(cell, row, enumObject, rowIndex) {
    if (row && row[cell]) {
      return <div>{row[cell]}</div>;
    } else {
      return <span>-</span>;
    }
  }

  createCustomButtonGroup = (props) => {
    return (
      <div className='pdfLogoBox'>
        <div className='reportHead'>
          <div className='logo'>
            <img src={this.state.comLogo} alt='Upload Logo' />
          </div>
          <h2>Contact List Report</h2>
        </div>
        {this.state.subtitle && (
          <div>
            <h5>
              <strong>Subtitle:</strong> {this.state.subtitle}{' '}
            </h5>
          </div>
        )}
      </div>
    );
  };

  handleChange = (e) => {
    e.preventDefault();
    let subtitle = this.state.subtitle;
    subtitle = e.target.value;
    this.setState({ subtitle });
  };

  _setTableOption() {
    if (this.state.fetchRequest) {
      return (
        <ReactLoading
          className='table-loader'
          type={appConstants.LOADER_TYPE}
          color={appConstants.LOADER_COLOR}
          height={appConstants.LOADER_HEIGHT}
          width={appConstants.LOADER_WIDTH}
        />
      );
    } else {
      return 'No data found..';
    }
  }

  onPageChange(page, sizePerPage) {
    this.setState({ fetchRequest: true, list: [] });
    let data = {};
    data['accountId'] = this.state.accountId;
    data['moduleName'] = appConstants.reportModuleName['3'];
    // if (this.state.searchText === "") {
    //     data['page'] = (page === -1) ? 0 : page - 1;
    //     data['displayLimit'] = sizePerPage;
    // } else {
    data['page'] = page === -1 ? 0 : page - 1;
    data['displayLimit'] = sizePerPage;
    data['search'] = this.state.searchText;
    data['sortKey'] = this.state.sortKey;
    data['sortType'] = this.state.sortType;
    //  }
    this.setState({ sizePerPage: sizePerPage });
    this.setState({ currentPage: page });
    this.props.GetContactListInfoReportAction(data);
  }

  onSearchChange(text, sizePerPage) {
    this.setState({ fetchRequest: true, list: [] });
    text = text ? UpperCaseName(text.trim()) : '';
    if (text !== '') {
      let data = {};
      data['accountId'] = this.state.accountId;
      data['moduleName'] = appConstants.reportModuleName['3'];
      data['page'] = 0;
      data['search'] = text;
      data['displayLimit'] = this.state.sizePerPage;
      data['sortKey'] = this.state.sortKey;
      data['sortType'] = this.state.sortType;
      this.setState({ sizePerPage: 50 });
      //this.setState({ searchText: text });
      //this.props.GetContactListInfoReportAction(data);
    } else {
      this.componentDidMount();
      this.setState({ searchText: text });
    }
  }

  onSortChange(sortName, sortOrder) {
    if (this.state && this.state.list && this.state.list.length > 0) {
      this.setState({ fetchRequest: true, list: [] });
      let data = {};
      data['sortKey'] = sortName;
      this.setState({ sortKey: data['sortKey'] });

      if (sortOrder === 'asc') {
        data['sortType'] = 'ASC';
        sortingOrder = '-asc';
        this.setState({ sortType: 'ASC' });
      } else {
        data['sortType'] = 'DESC';
        sortingOrder = '-desc';
        this.setState({ sortType: 'DESC' });
      }
      data['moduleName'] = appConstants.reportModuleName['3'];
      data['search'] = this.state.searchText;
      data['accountId'] = this.state.accountId;
      //data['page'] = this.state.currentPage;
      data['page'] = this.state.currentPage === 0 ? 0 : this.state.currentPage - 1;
      data['displayLimit'] = this.state.sizePerPage;
      this.props.GetContactListInfoReportAction(data);
    }
  }


  

  buildReportColumns() {
    const { list, allContactFieldTypes, fetchRequest } = this.state;

    const dynamicFieldsLoaded = allContactFieldTypes?.length > 0;

    const defaultFieldCodes = ['TITLE', 'DEPT'];

    const customFieldColumns = dynamicFieldsLoaded
      ? buildDynamicColumns(
          allContactFieldTypes.filter(
            (field) => !defaultFieldCodes.includes(field.code) && (field.stages.includes('contact')||(field.stages.includes('affiliation')))
          )
        )
      : [];

    const defaultFields = dynamicFieldsLoaded
      ? allContactFieldTypes.filter((field) => defaultFieldCodes.includes(field.code))
      : [];

    const defaultColumns = [
      {
        Header: 'Firm Name',
        accessor: 'companyName',
        width: 120,
        Cell: (props) => this.onVenderName(props.column.id, props.row.original, props.value),
      },
      {
        Header: 'Contact Person',
        accessor: 'displayName',
        width: 60,
        Cell: (props) => this.onContactName(props.column.id, props.row.original, props.value),
      },
      buildColumnForField(defaultFields, 'TITLE', false),
      buildColumnForField(defaultFields, 'DEPT', false),
      {
        Header: 'Contact Phone (Ext)',
        accessor: 'phoneArr',
        width: 100,
        Cell: (props) => this.onContactPhone(props.column.id, props.row.original, props.value),
        getCellExportValue: (row, col) => this.onContactPhone(col, row),
      },
      {
        Header: 'Email',
        accessor: 'emailArr',
        width: 80,
        Cell: (props) => this.onContactEmail(props.column.id, props.row.original, props.value),
        getCellExportValue: (row, col) => this.onContactEmail(col, row),
      },
      {
        Header: 'Address',
        accessor: (row) => row.addressArr.map((v) => formatAddressText(v)),
        width: 130,
        Cell: (props) => this.onGetAddressInfo(props.column.id, props.row.original, props.value),
        getCellExportValue: (row, col) => this.onGetAddressInfo(col, row),
      },
      {
        Header: 'Added',
        exportField: 'createdAt',
        hidden: true,
        accessor: 'createdAt',
        width: 60,
        filter: DateColumnFilter,
        allowDateRangeFilter: true,
        Cell: (props) => (
          <>{this.formatDateCell(props.column.id, props.row.original, 'createdAt')}</>
        ),
      },
      {
        Header: 'Updated',
        exportField: 'updatedAt',
        hidden: true,
        accessor: 'updatedAt',
        width: 60,
        filter: DateColumnFilter,
        allowDateRangeFilter: true,
        Cell: (props) => (
          <>{this.formatDateCell(props.column.id, props.row.original, 'updatedAt')}</>
        ),
      },
    ];

    let columns = [];
    if (dynamicFieldsLoaded) {
      if (columns.length == 0) {
        columns = defaultColumns.filter((cols) => cols?.accessor);
      }

      for (let i in customFieldColumns) {
        const curColumnsAccessors = columns.map((cols) => cols.accessor);
        if (!curColumnsAccessors.includes(customFieldColumns[i].accessor)) {
          columns = columns.concat(customFieldColumns[i]);
        }
      }
    } else {
      columns = defaultColumns.filter((cols) => cols?.accessor);
    }

    this.setState({ dynamicColumns: columns });

  }

  
  render() {
  
    const {
      list, allContactFieldTypes, dynamicColumns, fetchRequest
    } = this.state;


    const dynamicFieldsLoaded = allContactFieldTypes?.length > 0;
    
    const dynamicColumnsBuilt = dynamicFieldsLoaded && dynamicColumns.length > 0;

    if(dynamicFieldsLoaded && !dynamicColumnsBuilt){
      this.buildReportColumns();
    }

    
    const { listPref, SetContactListInfoReportPrefAction } = this.props;


    return (
      <div>
        <div className='ContactListReport'>
          <TitleComponent data={{ title: `Contact List` }} />
          <div>
            <Suspense fallback='loading'>
              <Header props={this.props} />
            </Suspense>
          </div>
          <div className='contactBox clrReport'>
            <div className='container'>
              <div className='row no-break-print'>
                {dynamicColumnsBuilt && (
                  <div className='col-md-12'>
                    <div
                      className='table-responsive reportTableSection'
                      ref={(el) => (this.componentRef = el)}
                    >
                      {this.createCustomButtonGroup()}
                      <CustomTable
                        columns={dynamicColumns}
                        list={list}
                        preferences={listPref}
                        className='contact-list-table'
                        customTableTitle='Contact List Report'
                        onSavePreferences={SetContactListInfoReportPrefAction}
                        isLoading={fetchRequest}
                        canShowRowSelection={false}
                        componentRef={this.componentRef}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    listData: state.reports.contactReportList,
    listPref: state.reports.contactListPref,
    contactFields: state.contactFields,
  };
}
export default withRouter(
  connect(mapStateToProps, {
    GetContactListInfoReportAction,
    GetContactListInfoReportPrefAction,
    SetContactListInfoReportPrefAction,
    getAllContactFieldsAction
  })(ContactListInfoReport)
);
