import apiClient from '../../utils/api-client';

export const importContactsParseRequest = (formData) => {
  formData.append('accountId', localStorage.getItem('accountId'));
  return apiClient.post('/contacts/importContacts/parse', formData);
};

export const setCalendarColorRequest = (data) => {
  return apiClient.post('/contacts/setCalendarColor', data);
};

export const saveContactRequest = (data) => {
  if (!data._id) return apiClient.post('/contacts/createContact', { ...data });

  return apiClient.post('/contacts/updateContact', {
    _id: data._id,
    firstName: data.firstName,
    lastName: data.lastName,
    middleName: data.middleName,
    prefix: data.prefix,
    suffix: data.suffix,
    
    email: data.email,
    contactTitle: data.contactTitle,
    comments: data.comments,
    dateOfBirthday: data.dateOfBirthday ? new Date(data.dateOfBirthday) : null,
  });
};

export const updateContactPictureRequest = (file, contactId) => {
  const formData = new FormData();
  formData.append('accountId', localStorage.getItem('accountId'));
  formData.append('contactId', contactId);
  formData.append('picture', file);

  return apiClient.post('/contacts/updateContactPicture', formData);
};

export const removeContactPictureRequest = (contactId) =>
  apiClient.post('/contacts/removeContactPicture', { contactId });

export const importContactsLoadRequest = (data) =>
  apiClient.post('/contacts/importContacts/load', {
    data,
    accountId: localStorage.getItem('accountId'),
  });

export const convertRowToContactRequest = (data) =>
  apiClient.post('/contacts/importContacts/convertRowToContact', {
    data,
    accountId: localStorage.getItem('accountId'),
  });

export const getInternalContactsRequest = () =>
  apiClient.post('/contacts/getInternalContacts').then(({ data }) => data);

export const getExternalContactsRequest = () =>
  apiClient.post('/contacts/getExternalContacts').then(({ data }) => data);

export const viewContactsRequest = ({ limit, offset, sortingField, sortingOrder, searchValue }) =>
  apiClient.post('/contacts/viewContacts', {
    accountId: localStorage.getItem('accountId'),
    params: {
      limit,
      offset,
      sortingField,
      sortingOrder,
      searchValue,
      searchFields: ['firstName', 'lastName', 'companyName', 'email', 'phoneArr.combinedPhone'],
    },
  });

export const contactRolesDropdownRequest = () =>
  apiClient
    .post('/parse/functions/viewMasters', {
      className: 'contactRoles',
    })
    .then(({ data }) =>
      data.result.data.data.map(({ contactRoleName, objectId }) => ({ contactRoleName, objectId }))
    );

export const userRolesDropdownRequest = () =>
  apiClient.post('/contacts/getEmployeeRoles').then(({ data }) => data);

export const updateContactRequest = (data) => apiClient.post('/parse/functions/editContact', data);

export const sendUserInviteRequest = (data) =>
  apiClient.post('/parse/functions/sendUserInvite', data).then((r) => r.data?.result);

export const resendVerificationEmailRequest = (data) =>
  apiClient.post('/parse/functions/userEmailVerifyResendMail', data).then((r) => r.data?.result);

export const cancelVerificationEmailRequest = (data) =>
  apiClient.post('/parse/functions/userEmailVerifyCancel', data).then((r) => r.data?.result);

export const getContactAffiliationsRequest = ({ contactId }) =>
  apiClient.post('/contacts/getContactAffiliations', { contactId }).then((r) => {
    return r.data;
  });

export const saveContactAffiliationsRequest = ({ contactId, affiliations = [], accountId = '' }) =>
  apiClient.post('/contacts/saveContactAffiliations', { accountId, contactId, affiliations });

export const getEmployeesRequest = (options) => {
  return apiClient.post('/contacts/getEmployees', {
    accountId: localStorage.getItem('accountId'),
    ...options,
  });
};

export const getEmployeesTotalRequest = (options) => {
  return apiClient.post('/contacts/getEmployeesTotal', {
    accountId: localStorage.getItem('accountId'),
    ...options,
  });
};

export const getEmployeeInvitationEmailsRequest = ({ contactId }) =>
  apiClient.post('/contacts/getEmployeeInvitationEmails', { contactId }).then((r) => r.data);

export const sendEmployeeInvitationRequest = ({ contactId, roleId, email }) =>
  apiClient
    .post('/contacts/sendEmployeeInvitation', { contactId, roleId, email })
    .then((r) => r.data);

export const getAccountEmailRequest = () =>
  apiClient.post('/contacts/getAccountEmail').then((r) => r.data);

export const getEmployeeInvitationStatusRequest = ({ contactId }) =>
  apiClient.post('/contacts/getEmployeeInvitationStatus', { contactId }).then((r) => r.data);

export const resendEmployeeInvitationRequest = ({ contactId }) =>
  apiClient.post('/contacts/resendEmployeeInvitation', { contactId }).then((r) => r.data);

export const cancelEmployeeInvitationRequest = ({ contactId }) =>
  apiClient.post('/contacts/cancelEmployeeInvitation', { contactId }).then((r) => r.data);

export const updateEmployeeRoleRequest = ({ contactId, roleId }) =>
  apiClient.post('/contacts/updateEmployeeRole', { contactId, roleId }).then((r) => r.data);

export const sendPasswordResetRequest = ({ contactId }) =>
  apiClient.post('/contacts/sendPasswordReset', { contactId });

export const disableUserRequest = ({ contactId, isDisableAffiliation }) =>
  apiClient.post('/contacts/disableUser', { contactId, isDisableAffiliation });

export const enableUserRequest = ({ contactId }) =>
  apiClient.post('/contacts/enableUser', { contactId });

export const getManageUserInfoRequest = ({ contactId }) =>
  apiClient.post('/contacts/getManageUserInfo', { contactId }).then((r) => r.data);

export const getContactRequest = ({ contactId }) =>
  apiClient.post('/contacts/getContact', { contactId }).then((r) => r.data);

export const getContactsRequest = ({ contactIds }) =>
  apiClient.post('/contacts/getContacts', { contactIds }).then((r) => r.data);

export const deleteEmployeeRequest = ({ contactId }) =>
  apiClient.post('/contacts/deleteEmployee', { contactId }).then((r) => r.data);

export const deleteContactRequest = ({ contactId }) =>
  apiClient.post('/contacts/deleteContact', { contactId }).then((r) => r.data);

export const getNotificationActivityRequest = ({ page, count }) => {
  return apiClient.post('/contacts/getNotificationActivity', { page, count }).then((r) => r.data);
};

export const getTeamActivityRequest = ({ page, count }) => {
  return apiClient.post('/contacts/getTeamActivity', { page, count }).then((r) => r.data);
};

export const getAllContactAffiliationsRequest = () => {
  return apiClient.post('/contacts/getAllContactAffiliations').then((r) => r.data);
};

export const findContactAffiliationsRequest = ({
  criteria,
  page,
  count,
  isAccount,
  includeDeleted,
  fields,
  requiredFields,
}) => {
  return apiClient
    .post('/contacts/findContactAffiliations', {
      criteria,
      page,
      count,
      isAccount,
      includeDeleted,
      fields,
      requiredFields,
    })
    .then((r) => r.data);
};

export const getContactProjectsRequest = ({ contactId }) =>
  apiClient.post('/contacts/getContactProjects', { contactId }).then((r) => r.data);

export const getContactCustomFieldsValuesFormRequest = () => {
  return apiClient.post('/contacts/getContactCustomFieldsValuesForm').then(({ data }) => data);
};

export const validateContactPhoneNumberRequest = ({ phoneValue, contactId }) => {
  return apiClient
    .post('/contacts/validateContactPhoneNumber', {
      phoneValue,
      contactId,
    })
    .then(({ data }) => data);
};

export const validateContactEmailRequest = ({ emailValue, contactId }) => {
  return apiClient
    .post('/contacts/validateContactEmail', {
      emailValue,
      contactId,
    })
    .then(({ data }) => data);
};
