import React, { Suspense } from 'react';
import { Tooltip } from 'react-tooltip';
import { connect } from 'react-redux';
import renderHTML from 'html-react-parser';
import { Link, withRouter } from 'react-router-dom';
import { getAllJobScopeAction } from '../../../actions/jobScope';
import { getAllJobStatusAction } from '../../../actions/jobStatusCode';

import { getAllProjectRoleAction } from '../../../actions/projectRole';
import {
  getAllReasonCodeAction,
  getReasonCodesByStatusCodeAction,
} from '../../../actions/reasonCode';
import {
  GetBidHistoryReportAction,
  GetBidHistoryReportPrefAction,
  SetBidHistoryReportPrefAction,
} from '../../../actions/reports';
import {
  formatExternalContactData,
  formatInternalContactData,
} from '../../../utils/contact-formatters';
import { formatDate } from '../../../utils/date-formatters';
import { matchDataForTable } from '../../../utils/match-for-table';
import { formatNote } from '../../../utils/note-formatters';
import { appConstants } from '../../../_constants';
import { customSortByKey, CustomTable, multiSelectFilter } from '../../Common/CustomTable';
import { DateColumnFilter } from '../../Common/CustomTableComponents/DateColumnFilter';
import { SelectColumnFilter } from '../../Common/CustomTableComponents/SelectColumnFilter';
import NotePopUp from '../../Common/NotePopUp';
import TitleComponent from '../../Common/TitleComponent';
import Footer from '../../Layout/Footer';
import Header from '../../Layout/Header';
import { isAllowed, moduleConstants } from '../../../_constants';
import { onCurrency, onDate, onDateTime } from '../../../utils/column-formatters';
import { BidModal } from '../../../modals/bid-modal/bid-modal';

class BidHistoryReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accountId: localStorage.getItem('accountId'),
      userId: localStorage.getItem('userId'),
      list: [],
      fetchRequest: true,
      currentPage: 1,
      sizePerPage: appConstants.TableSizePerPageList[2].value,
      allJobStatusCodes: [],
      jobScope: [],
      reasonCodes: [],
      modalOpen: false,
      noteData: '',
      sortType: 'DESC',
      sortKey: '',
      contactData: {},
      projectRoleTypes: [],
      bidId: '',
      projectId: '',
      isBidManagementModalOpen: false,
      dynamicColumns: [],
    };

    this.onJobStatus = this.onJobStatus.bind(this);
    this.getModalOpen = this.getModalOpen.bind(this);
    this.onNoteModalClose = this.onNoteModalClose.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps?.projectRoles?.allProjectRoleTypes) {
      this.setState({
        projectRoleTypes: nextProps.projectRoles.allProjectRoleTypes,
      });
    }


    if (nextProps.allJobStatusCodes) {
      this.setState({
        allJobStatusCodes: nextProps.allJobStatusCodes,
      });
    }
    if (nextProps.reasonCodes) {
      this.setState({
        reasonCodes: nextProps.reasonCodes,
      });
    }

    if (nextProps.jobScope) {
      this.setState({
        jobScope: nextProps.jobScope,
      });
    }


    if (nextProps.listData?.success ===1) {
      let fieldsToMatch = {
        //clientArr: { lookupLocation: 'contactData' },
        //estimatorArr: { lookupLocation: 'contactData' },
        projectTeam: { lookupLocation: 'contactData' },
        scopeArr: {
          lookupLocation: 'jobScope',
          collectionName: 'allJobScope',
          labelField: 'jobScopeName',
        },
        bidProjectStatus: {
          lookupLocation: 'allJobStatusCodes',
          collectionName: null,
          labelField: 'jobStatusCodesCode',
        },
        pendingStatusCode: {
          lookupLocation: 'allJobStatusCodes',
          collectionName: null,
          labelField: 'jobStatusCodesCode',
        },
        resolvedStatus: {
          lookupLocation: 'allJobStatusCodes',
          collectionName: null,
          labelField: 'jobStatusCodesCode',
        },
      };

      let matchedDataForTable = matchDataForTable(
        fieldsToMatch,
        nextProps.listData.biddingActivity,
        nextProps.listData.contactData,
        this.props
      );

      this.setState({
        totalCount: nextProps.listData.bidCount,
        list: matchedDataForTable,
        contactData: nextProps.listData.contactData,
        projectData: nextProps.listData.projectData,
        fetchRequest: false,
      });
    }

    if (
      nextProps.listData &&
      nextProps.listData.biddingActivity &&
      nextProps.listData.biddingActivity.length == 0
    ) {
      this.setState({ fetchRequest: false });
    }
  }

  buildColumnForRole(filteredRoles, roleCode, hidden = false, showContactInfo = false) {
    const foundRole = filteredRoles.find((role) => role.code == roleCode);
    if (!foundRole) return;
    const isInternal = foundRole && foundRole.type === 'internal';

    if (foundRole && isInternal) {
      return (
        foundRole && {
          Header: foundRole?.shortName ?? roleCode,
          LongHeader: foundRole?.name,
          accessor: 'projectTeamLabel' + foundRole.objectId,
          sortType: customSortByKey,
          sortKey: foundRole?.name ?? foundRole.objectId,
          Filter: SelectColumnFilter,
          filter: multiSelectFilter,
          width: 60,
          hidden: hidden,
          Cell: (props) => (
            <>
              {this.formatContactDataInternal(
                'projectTeamMatched' + foundRole.objectId,
                props.row.original
              )}
            </>
          ),
        }
      );
    } else {
      return (
        foundRole && {
          Header: foundRole?.shortName ?? roleCode,
          LongHeader: foundRole?.name,
          accessor: 'projectTeamLabel' + foundRole.objectId,
          sortType: customSortByKey,
          sortKey: foundRole?.name ?? foundRole.objectId,
          Filter: SelectColumnFilter,
          filter: multiSelectFilter,
          width: 60,
          hidden: hidden,
          Cell: (props) => (
            <>
              {this.formatContactDataExternal(
                'projectTeamMatched' + foundRole.objectId,
                props.row.original, showContactInfo
              )}
            </>
          ),
        }
      );
    }
  }

  formatContactDataInternal(cell, row) {
    if (row[cell] && row[cell].length > 0) {
      return formatInternalContactData(row[cell], this.state.contactData);
    } else {
      return <span>-</span>;
    }
  }

  formatContactDataExternal(cell, row, showContactInfo) {
    if (row[cell] && row[cell].length > 0) {
      return formatExternalContactData(row[cell], this.state.contactData, showContactInfo);
    } else {
      return <span>-</span>;
    }
  }

  componentDidMount() {
    this.props.GetBidHistoryReportPrefAction();


    const projectId = this.props?.match?.params?.objectId;


    let data = {};

    if (projectId) {


      this.setState({ projectId: projectId });

      this.setState({ sizePerPage: appConstants.TableSizePerPageList[2].value });
      data['projectId'] = projectId;
      data['page'] = 0;
      data['displayLimit'] = this.props?.props?.isWidget ? 5 : this.state.sizePerPage;
      this.props.GetBidHistoryReportAction(data);
    }

    this.props.getAllProjectRoleAction({
      className: 'projectRoleTypes',
      accountId: this.state.accountId,
    });

    if (
      localStorage.getItem('companyLogo') !== 'undefined' &&
      localStorage.getItem('companyLogo') !== null
    ) {
      this.setState({
        comLogo: localStorage.getItem('companyLogo'),
      });
    } else {
      let constImage = appConstants.CONTENT_CPOST_LOGO_URL;
      this.setState({ comLogo: constImage });
    }

    this.props.getAllJobStatusAction({
      className: 'jobStatusCodes',
      includeDisabled:true,
      accountId: this.state.accountId,
    });
    this.props.getAllReasonCodeAction({
      className: 'reasonCodes',
      includeDisabled:true,
      accountId: this.state.accountId,
    });
    this.props.getAllJobScopeAction({
      className: 'jobScopes',
      accountId: this.state.accountId,
      includeDisabled:true,
    });
  }

  onText(id, row, value) {
    let data = value ? value : '-';

    return <div className='textLeft'>{data}</div>;

    //return <div className="textRight">{data}</div>;
  }

  onNumeric(id, row, value) {
    let data = value ? value : value === 0 ? '0' : '-';

    return <div className='textRight'>{data}</div>;

    //return <div className="textRight">{data}</div>;
  }


  showScopeStatus(cell, row, enumObject, rowIndex) {
    let curJobScope;
    if (row && row.estData && this.props.jobScope && this.props.jobScope.allJobScope) {
      let curEstScopes = row.estData.map((est) => est.scopeId);
      curJobScope = this.props.jobScope.allJobScope.filter((x) =>
        curEstScopes.includes(x.objectId)
      );
      curJobScope = curJobScope.map((curScope) => curScope.jobScopeName);
    }

    return <div>{curJobScope.length > 0 ? curJobScope.join('\n') : '-'}</div>;
  }

  onJobStatus(statusCodeVar, row, enumObject, rowIndex) {
    let curJobStatus = '';

    let curJobStatusCode = row[statusCodeVar];
    curJobStatus = this.props.allJobStatusCodes.filter((x) => curJobStatusCode === x.objectId);
    if (curJobStatus) {
      curJobStatus = curJobStatus[0];
    }

    return <div className='linkText'>{curJobStatus?.jobStatusCodesCode ?? curJobStatusCode}</div>;

    //return (row && row.jobStatusCodesCode) ? row.jobStatusCodesCode : '-';
  }

  redirectToProject(cell, enumObject, row, rowIndex) {
    if (cell === 'jobNumFormatted' && row) {
      return (
        <Link
          to={{
            pathname: `/project-details/${row.objectId}`,
            state: { row },
          }}
        >
          {row.jobNumFormatted ? row.jobNumFormatted : '-'}
        </Link>
      );
    }

    if (cell === 'jobName' && row) {
      return (
        <div>
          <Link
            to={{
              pathname: `/project-details/` + row.objectId,
              state: { row },
            }}
          >
            {row.jobName ?? '-'}
          </Link>
        </div>
      );
    }

    if (cell === 'altJobNum' && row) {
      return (
        <div>
          <Link
            to={{
              pathname: `/project-details/` + row.objectId,
              state: { row },
            }}
          >
            {row.altJobNum ? row.altJobNum : '-'}
          </Link>
        </div>
      );
    }
  }

  onCommentDetail(cell, row, value) {
    const notes = formatNote(value);
    return notes ? (
      <a
        className='anchorNote '
        data-type={'reminder'}
        onClick={(e) => {
          this.getModalOpen(e, value);
        }}
      >
        {renderHTML(notes)}
      </a>
    ) : (
      '-'
    );
  }

  onBidUrl(cell, row, enumObject, rowIndex) {
    if (row.bidURL && row.bidURL.length > 0) {
      let strippedUrl = row.bidURL;
      if (strippedUrl > 25) {
        strippedUrl.substring(0, strippedUrl) + '...';
      }

      const url = row.bidURL?.startsWith('http') ? row.bidURL : 'http://' + row.bidURL;
      return url ? (
        <a className='anchorNote ' data-type={'reminder'} href={url} target='_blank'>
          {strippedUrl}
        </a>
      ) : (
        '-'
      );
    } else {
      return <div>-</div>;
    }
  }

  onUser(accessorId, row, value, rowIndex) {
    const user = row[accessorId];
    if (value?.length > 0) {
      return <Link
        to={{
          pathname: `/contact-details/` + user?.contactId,
          state: { row },
        }}
      >
        {value ?? '-'}
      </Link>;
    } else {
      return <div>-</div>;
    }
  }

  onNoteModalClose() {
    this.setState({ modalOpen: false });
  }

  getModalOpen(e, notesData) {
    this.setState({ modalOpen: true, noteData: notesData ?? '-' });
  }

  createCustomButtonGroup = (props) => {
    return (
      <div className='pdfLogoBox'>
        <div className='reportHead'>
          <div className='logo'>
            <img src={this.state.comLogo} alt='Upload Logo' />
          </div>
          <h2>Bid History Report</h2>
        </div>
      </div>
    );
  };


  bidHistoryReportHeader = (props) => {
    const projectAssignments = this.state.projectData?.projectAssignments;
    const contactData = this.state.projectData?.contactData;

    const accountExecRoleName = this.state.projectRoleTypes.find((role) => role.code == 'AX')?.name;
    const estimatorRoleName = this.state.projectRoleTypes.find((role) => role.code == 'EST')?.name;

    const accountExecNames =
      projectAssignments &&
      formatExternalContactData(
        projectAssignments?.filter((assign) => assign.projectRoleCode == 'AX'),
        contactData,
        false,
        false
      );

    const estimatorNames =
      contactData &&
      formatExternalContactData(
        projectAssignments?.filter((assign) => assign.projectRoleCode == 'EST'),

        contactData,
        false,
        false
      );

    return (
      <div className='pdfLogoBox'>
        <div className='reportHead'>
          <div className='logo'>
            <img src={this.state.comLogo} alt='Upload Logo' />
          </div>
          <h2>Bid History Report</h2>
          <div className='row searchbar'>
            <div className='col-md-6 pdfAlignInline'>
              <div className='field-row'>
                <div className='label-name'>Job Name:</div>
                <div className='projectHistoryJob ph-field'>{this.state.projectData?.jobName}</div>
              </div>
              <div className='field-row'>
                <div className='label-name'>Job Address:</div>
                <div className='ph-field'>data1</div>
              </div>
            </div>
            <div className='col-md-6 pdfAlignInline last'>
              <div className='text-right'>
                <div className='field-row '>
                  <div className='label-name'>Job Number:</div>
                  <div className='ph-field'>{this.state.projectData.jobNumFormatted}</div>
                </div>
                <div className='field-row '>
                  <div className='label-name'>Alt Job Number:</div>
                  <div className='ph-field'>{this.state.projectData.altJobNum}</div>
                </div>

                {accountExecRoleName?.length > 0 && (
                  <div className='field-row'>
                    <div className='label-name'>{accountExecRoleName}:</div>
                    <div className='ph-field'>{accountExecNames}</div>
                  </div>
                )}
                {estimatorRoleName?.length > 0 && (
                  <div className='field-row'>
                    <div className='label-name'>{estimatorRoleName}:</div>
                    <div className='ph-field'>{estimatorNames}</div>
                  </div>
                )}

              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  onOpenBidModalClick(bidData) {
    if (bidData) {
      const { projectId, objectId, _id } = bidData;

      this.setState({
        projectId,
        bidId: objectId || _id,
        isBidManagementModalOpen: true,
      });
    }
  }

  buildReportColumns() {
    const projectAssignments = this.state.projectData?.projectAssignments;

    const hasContractValPermissions = isAllowed(
      moduleConstants.VIEWCONTRACTVAL,
      'assigned',
      projectAssignments
    );
    const hasProfitValPermissions = isAllowed(
      moduleConstants.VIEWPROFIT,
      'assigned',
      projectAssignments
    );


    const roles = this.state.projectRoleTypes;

    if (!roles) return;

    const defaultRolesCodes = ['EST'];

    const defaultRoles = this.state.projectRoleTypes.filter(
      (role) => defaultRolesCodes.includes(role.code) && (role.isLinkToBids)
    );

    const enabledRoles = this.state.projectRoleTypes.filter(
      (role) => !defaultRolesCodes.includes(role.code) && (role.isLinkToBids)
    );

    const defaultColumns = [
      {
        Header: 'Bid Start',
        accessor: (row) =>
          row?.bidStart?.iso
            ? new Date(row.bidStart.iso)
            : new Date(formatDate('1800-03-03T12:47:40.000Z')),
        id: 'bidStart',
        width: 170,
        Cell: (props) => <>{onDate(props.column.id, props.row.original, props.value)}</>,
        filter: DateColumnFilter,
        allowDateRangeFilter: true,
        sortType: 'datetime',
        hidden: true,
      },
      {
        Header: 'Bid Due Date',
        accessor: (row) =>
          row?.bidDue?.iso
            ? new Date(row.bidDue.iso)
            : new Date(formatDate('1800-03-03T12:47:40.000Z')),
        id: 'bidDue',
        width: 170,
        Cell: (props) => <>{onDateTime(props.column.id, props.row.original, props.value)}</>,
        filter: DateColumnFilter,
        allowDateRangeFilter: true,
        sortType: 'datetime',
      },
      {
        Header: 'Bid Submitted Date',
        accessor: (row) =>
          row?.bidSubmittedDate?.iso
            ? new Date(row.bidSubmittedDate.iso)
            : new Date(formatDate('1800-03-03T12:47:40.000Z')),
        id: 'bidSubmittedDate',
        width: 170,
        Cell: (props) => <>{onDate(props.column.id, props.row.original, props.value)}</>,
        filter: DateColumnFilter,
        allowDateRangeFilter: true,
        sortType: 'datetime',
      },
      {
        Header: 'Bid Name',
        accessor: 'bidId',
        width: 100,
        Cell: (props) => <>{this.onText(props.column.id, props.row.original, props.value)}</>,
      },

      hasContractValPermissions && {
        Header: 'Est. Contract',
        accessor: 'estTotalContract',
        width: 100,
        Cell: (props) => <>{onCurrency(props.column.id, props.row.original, props.value)}</>,
      },
      hasProfitValPermissions && {
        Header: 'Est. Profit',
        accessor: 'estTotalProfit',
        width: 100,
        Cell: (props) => <>{onCurrency(props.column.id, props.row.original, props.value)}</>,
      },
      this.buildColumnForRole(defaultRoles, 'EST'),
      {
        Header: 'Scope',
        accessor: 'scopeArrLabel',
        sortType: customSortByKey,
        width: 80,
        Filter: SelectColumnFilter,

        filter: multiSelectFilter,
        Cell: (props) => (
          <>{this.showScopeStatus(props.column.id, props.row.original, props.value)}</>
        ),
      },
      {
        Header: 'Bid Code',
        accessor: 'bidProjectStatusLabel',
        width: 60,
        Filter: SelectColumnFilter,
        Cell: (props) => (
          <>{this.onJobStatus('bidProjectStatus', props.row.original, props.value)}</>
        ),
      },
      {
        Header: 'Pending Status',
        accessor: 'pendingStatusCodeLabel',
        width: 60,
        hidden: true,
        Filter: SelectColumnFilter,
        Cell: (props) => (
          <>{this.onJobStatus('pendingStatusCode', props.row.original, props.value)}</>
        ),
      },
      {
        Header: 'Resolved Status',
        accessor: 'resolvedStatusLabel',
        width: 60,
        hidden: true,
        Filter: SelectColumnFilter,
        Cell: (props) => <>{this.onJobStatus('resolvedStatus', props.row.original, props.value)}</>,
      },
      {
        Header: 'Outcome',
        accessor: 'Outcome',
        width: 100,
        Cell: (props) => <>{this.onText(props.column.id, props.row.original, props.value)}</>,
      },
      {
        Header: 'Bid #',
        accessor: 'bidCount',
        width: 60,
        Cell: (props) => <>{this.onNumeric(props.column.id, props.row.original, props.value)}</>,
      },
      {
        Header: 'Rev #',
        accessor: 'bidRev',
        width: 60,
        Cell: (props) => <>{this.onNumeric(props.column.id, props.row.original, props.value)}</>,
      },
      {
        Header: 'Const. Start',
        accessor: (row) =>
          row?.estStartDate?.iso
            ? new Date(row.estStartDate.iso)
            : new Date(formatDate('1800-03-03T12:47:40.000Z')),
        id: 'estStartDate',
        width: 90,
        Cell: (props) => <>{onDate(props.column.id, props.row.original, props.value)}</>,
        filter: DateColumnFilter,
        allowDateRangeFilter: true,
        sortType: 'datetime',
      },
      {
        Header: 'Description',
        accessor: 'comment',
        hidden: true,
        width: 120,
        Cell: (props) => (
          <>
            <span
              data-tooltip-html={props.value}
              data-tooltip-id={props.column.id + ':' + props.row.id}
            >
              {this.onCommentDetail(props.column.id, props.row.original, props.value)}
            </span>
            <Tooltip id={props.column.id + ':' + props.row.id} delayShow={1000} place='bottom' />
          </>
        ),
      },
      {
        Header: 'Submitted Comment',
        accessor: 'pendingComment',
        hidden: true,
        width: 120,
        Cell: (props) => (
          <>
            <span
              data-tooltip-content={props.value}
              data-tooltip-id={props.column.id + ':' + props.row.id}
            >
              {this.onCommentDetail(props.column.id, props.row.original, props.value)}
            </span>
            <Tooltip id={props.column.id + ':' + props.row.id} delayShow={1000} place='bottom' />
          </>
        ),
      },
      {
        Header: 'Submitted Reason',
        accessor: 'pendingReasonCode',
        hidden: true,
        width: 120,
        Cell: (props) => (
          <>
            <span
              data-tooltip-content={props.value}
              data-tooltip-id={props.column.id + ':' + props.row.id}
            >
              {this.onCommentDetail(props.column.id, props.row.original, props.value)}
            </span>
            <Tooltip id={props.column.id + ':' + props.row.id} delayShow={1000} place='bottom' />
          </>
        ),
      },
      {
        Header: 'Outcome Comment',
        accessor: 'resolvedComment',
        hidden: true,
        width: 120,
        Cell: (props) => (
          <>
            <span
              data-tooltip-content={props.value}
              data-tooltip-id={props.column.id + ':' + props.row.id}
            >
              {this.onCommentDetail(props.column.id, props.row.original, props.value)}
            </span>
            <Tooltip id={props.column.id + ':' + props.row.id} delayShow={1000} place='bottom' />
          </>
        ),
      },
      {
        Header: 'Outcome Reason',
        accessor: 'resolvedReasonCode',
        hidden: true,
        width: 120,
        Cell: (props) => (
          <>
            <span
              data-tooltip-content={props.value}
              data-tooltip-id={props.column.id + ':' + props.row.id}
            >
              {this.onCommentDetail(props.column.id, props.row.original, props.value)}
            </span>
            <Tooltip id={props.column.id + ':' + props.row.id} delayShow={1000} place='bottom' />
          </>
        ),
      },
      {
        Header: 'Bid URL',
        accessor: 'bidURL',
        hidden: true,
        width: 40,
        Cell: (props) => (
          <>
            <span
              data-tooltip-content={props.value}
              data-tooltip-id={props.column.id + ':' + props.row.id}
            >
              {this.onBidUrl(props.column.id, props.row.original, props.value)}
            </span>
          </>
        ),
      },
      {
        Header: 'Created By',
        accessor: 'createdByUser.fullName',
        accessorID: 'createdByUser',
        hidden: true,
        width: 100,
        Cell: (props) => (
          <>
            <span
              data-tooltip-content={props.value}
              data-tooltip-id={props.column.id + ':' + props.row.id}
            >
              {this.onUser(props.column.accessorID, props.row.original, props.value)}
            </span>
          </>
        ),
      },
      {
        Header: 'Submitted By',
        accessor: 'submittedByUser.fullName',
        accessorID: 'submittedByUser',
        hidden: true,
        width: 100,
        Cell: (props) => (
          <>
            <span
              data-tooltip-content={props.value}
              data-tooltip-id={props.column.id + ':' + props.row.id}
            >
              {this.onUser(props.column.id, props.row.original, props.value)}
            </span>
          </>
        ),
      },
      {
        Header: 'Resolved By',
        accessor: 'resolvedByUser.fullName',
        accessorID: 'resolvedByUser',
        hidden: true,
        width: 100,
        Cell: (props) => (
          <>
            <span
              data-tooltip-content={props.value}
              data-tooltip-id={props.column.id + ':' + props.row.id}
            >
              {this.onUser(props.column.id, props.row.original, props.value)}
            </span>
          </>
        ),
      },
      {
        Header: 'Action',
        accessor: 'action',
        width: 120,
        Cell: ({ row }) => (
          <div className='d-flex gap-2'>
            <span onClick={() => this.onOpenBidModalClick(row.original)} className='rowControl'>
              <i className='fa fa-clock' aria-hidden='true'></i>
            </span>
          </div>
        ),
      },
    ];

    let columns = [];
    if (enabledRoles) {
      const extraColumns = enabledRoles.map((role) =>
        this.buildColumnForRole([role], role.code, true)
      );

      if (columns.length == 0) {
        columns = defaultColumns.filter((cols) => cols?.accessor);
      }

      for (let i in extraColumns) {
        const curColumnsAccessors = columns.map((cols) => cols.accessor);
        if (!curColumnsAccessors.includes(extraColumns[i].accessor)) {
          columns = columns.concat(extraColumns[i]);
        }
      }

    } else {
      columns = defaultColumns.filter((cols) => cols?.accessor);
    }

    this.setState({ dynamicColumns: columns.filter(Boolean) });
  }

  render() {
    const { list, dynamicColumns, projectData,projectRoleTypes } = this.state;

    const projectAssignments = projectData?.projectAssignments;

    const rolesLoaded = projectRoleTypes?.length > 0;

    const { listPref, SetBidHistoryReportPrefAction } = this.props;

    const dynamicColumnsBuilt = rolesLoaded && dynamicColumns.length > 0;

    if (rolesLoaded && !dynamicColumnsBuilt) {
      this.buildReportColumns();
    }

    const isWidget = !!this.props?.props?.isWidget;

    const contactData = projectData?.contactData;

    const accountExecRoleName = projectRoleTypes.find((role) => role.code == 'AX')?.name;
    const estimatorRoleName = projectRoleTypes.find((role) => role.code == 'EST')?.name;

    const accountExecNames =
      projectAssignments &&
      formatExternalContactData(
        projectAssignments?.filter((assign) => assign.projectRoleCode == 'AX'),
        contactData,
        false,
        false
      );
    const estimatorNames =
      contactData &&
      formatExternalContactData(
        projectAssignments?.filter((assign) => assign.projectRoleCode == 'EST'),
        contactData,
        false,
        false
      );

    const row = this.state.projectData;
    let jobAddressData = [];
    if (row) {
      jobAddressData =
        (row.jobAddress ? row.jobAddress : '') +
        (row.jobAddress ? ', ' : '') +
        (row.jobAddress2 ? row.jobAddress2 : '') +
        (row.jobAddress2 ? ', ' : '');
      jobAddressData +=
        (row.jobCity ? row.jobCity : '') +
        (row.jobCity ? ', ' : '') +
        (row.jobState ? row.jobState : '') +
        (row.jobState ? ', ' : '') +
        (row.jobZip ? row.jobZip : '');
    } else {
      jobAddressData = '-';
    }

    return (
      <div>
        <Suspense fallback='loading'>
          <div className='ContactListReport'>
            <TitleComponent data={{ title: `Bid History Report` }} />
            {!isWidget && <Header props={this.props} />}
            <div className='contactBox'>
              <div className='container'>
                <div className='no-print no-break-print'>
                  <div className='reportHead'>
                    <h2>Bid History Report</h2>
                  </div>

                  <div className='row searchbar no-print'>
                    <div className='col-md-4'>
                      <div className='field-row'>
                        <div className='label-name'>Job Name:</div>
                        <div className='projectHistoryJob ph-field'>
                          <Link
                            to={{
                              pathname: `/project-details/` + row?.objectId,
                              state: { row },
                            }}
                          >
                            {row?.jobName ?? '-'}
                          </Link>
                        </div>
                      </div>
                      <div className='field-row'>
                        <div className='label-name'>Job Address:</div>
                        <div className='ph-field'>{jobAddressData}</div>
                      </div>
                    </div>
                    <div className='col-md-4'>
                      <div className='text-right'>
                        <div className='field-row '>
                          <div className='label-name'>Job Number:</div>
                          <div className='ph-field'>
                            <Link
                              to={{
                                pathname: `/project-details/` + row?.objectId,
                                state: { row },
                              }}
                            >
                              {projectData?.jobNumFormatted
                                ?projectData.jobNumFormatted
                                : '-'}
                            </Link>
                          </div>
                        </div>
                        <div className='field-row '>
                          <div className='label-name'>Alt Job Number:</div>
                          <div className='ph-field'>
                            <Link
                              to={{
                                pathname: `/project-details/` + row?.objectId,
                                state: { row },
                              }}
                            >
                              {projectData?.altJobNum
                                ? projectData.altJobNum
                                : '-'}
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-md-4'>
                      <div className='text-right'>
                        {accountExecRoleName?.length > 0 && (
                          <div className='field-row'>
                            <div className='label-name'>{accountExecRoleName}:</div>
                            <div className='ph-field'>{accountExecNames}</div>
                          </div>
                        )}
                        {estimatorRoleName?.length > 0 && (
                          <div className='field-row'>
                            <div className='label-name'>{estimatorRoleName}:</div>
                            <div className='ph-field'>{estimatorNames}</div>
                          </div>
                        )}

                      </div>
                    </div>
                  </div>
                </div>
                <div className='row no-break-print'>
                  <div className='col-md-12'>
                    <div
                      className='table-responsive reportTableSection'
                      ref={(el) => (this.componentRef = el)}
                    >
                      {dynamicColumnsBuilt && (
                        <>
                          {!isWidget && this.createCustomButtonGroup()}
                          <CustomTable
                            columns={dynamicColumns}
                            list={list}
                            preferences={listPref}
                            className='bid-history-table'
                            customTableTitle={!isWidget ? '' : ''}
                            onSavePreferences={SetBidHistoryReportPrefAction}
                            isLoading={this.state.fetchRequest}
                            canShowRowSelection={false}
                            componentRef={this.componentRef}
                            canShowHeaderItems={!isWidget}
                            pageSizeArray={isWidget ? [5] : [50, 100, 200]}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <BidModal
              isOpen={this.state.isBidManagementModalOpen}
              toggle={() => this.setState({ isBidManagementModalOpen: false })}
              toggleStatusUpdate={() => true}
              onSubmit={() => true}
              showSaveSubmit={false}
              bidId={this.state.bidId}
              projectId={this.state.projectId}
            />

            {this.state.modalOpen && (
              <NotePopUp
                isOpen={this.state.modalOpen}
                onClick={this.onNoteModalClose}
                noteInfo={this.state.noteData}
              />
            )}

            {!isWidget && <Footer />}
          </div>
        </Suspense>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    listData: state.reports.bidHistoryList,
    listPref: state.reports.bidHistoryListPref,
    projectData: state.project.projectData,
    allJobStatusCodes: state.jobStatusCode.allJobStatus,
    allReasonCodeByStatusCode: state.reasonCode.allReasonCodeByStatusCode,
    jobScope: state.jobScope,
    reasonCodes: state.reasonCode,
    projectRoles: state.projectRoleType,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    GetBidHistoryReportAction,
    getAllJobStatusAction,
    getAllReasonCodeAction,
    getReasonCodesByStatusCodeAction,
    getAllJobScopeAction,
    getAllProjectRoleAction,
    GetBidHistoryReportPrefAction,
    SetBidHistoryReportPrefAction,
  })(BidHistoryReport)
);
