import moment from 'moment';
import { useMemo, useEffect, useState, useCallback } from 'react';
import { useAppDispatch } from '../../shared/hooks/use-app-dispatch';
import { cellFormatter } from '../../shared/data-grid/import-components';
import { getProjectRoles } from '../../modules/settings';
import { getProjectCustomFieldsValuesForm } from '../../modules/projects';

const hiddenStaticFields = [  {
  label: 'originId',
  name: 'originId',
  type: 'text',
  hidden: true,
},
{
  label: 'overrideJobNum',
  name: 'overrideJobNum',
  type: 'text',
  hidden: true,
}];

const generalStaticFields = [
  {
    label: 'Project Status',
    name: 'jobStatusCodes',
    type: 'id',
  },
  {
    label: 'Job Name',
    name: 'jobName',
    type: 'text',
  },
  {
    label: 'Alt Job Number',
    name: 'altJobNum',
    type: 'text',
  },
  {
    label: 'Job Notes',
    name: 'jobNotes',
    type: 'text',
  },
];

const locationStaticFields = [
  {
    label: 'Address 1',
    name: 'jobAddress',
    type: 'text',
  },
  {
    label: 'Address 2',
    name: 'jobAddress2',
    type: 'text',
  },
  {
    label: 'City',
    name: 'jobCity',
    type: 'text',
  },
  {
    label: 'State',
    name: 'jobState',
    type: 'text',
  },
  {
    label: 'Zip Code',
    name: 'jobZip',
    type: 'text',
  },
  {
    label: 'Country',
    name: 'jobCountry',
    type: 'text',
  },
  {
    label: 'Latitude',
    name: 'lat',
    type: 'text',
  },
  {
    label: 'Longitude',
    name: 'lng',
    type: 'text',
  },
  {
    label: 'Marketing Description',
    name: 'marketingDescription',
    type: 'text',
  },
];

const datesAndDollarsStaticFields = [
  {
    label: 'Estimating Start',
    name: 'bidStart',
    type: 'date',
  },
  {
    label: 'Bid Due Date',
    name: 'bidDue',
    type: 'date',
  },
  {
    label: 'Bid Submitted Date',
    name: 'bidSubmittedDate',
    type: 'date',
  },
  {
    label: 'Construction Start',
    name: 'startDate',
    type: 'date',
  },
  {
    label: 'Construction End',
    name: 'endDate',
    type: 'date',
  },
  {
    label: 'Approximate Contract',
    name: 'contractAmount',
    type: 'text',
  },
  {
    label: 'Approximate Gross Profit',
    name: 'grossProfit',
    type: 'text',
  },
  {
    label: 'NTP Received Date',
    name: 'ntpDate',
    type: 'date',
  },
  {
    label: 'Delivered Date',
    name: 'deliveredDate',
    type: 'date',
  },
  {
    label: 'Follow Up Done',
    name: 'followUpDone',
    type: 'date',
  },
  {
    label: 'Closeout Date',
    name: 'closeoutDate',
    type: 'date',
  },
  {
    label: 'Warranty Period',
    name: 'warrPeriod',
    type: 'text',
  },
  {
    label: 'Lien Period',
    name: 'lienPeriod',
    type: 'text',
  },
];

const marketingStaticFields = [
  {
    label: 'Lead Source',
    name: 'leadSource',
    type: 'text',
  },
  {
    label: 'Lead Cohort',
    name: 'leadCohort',
    type: 'text',
  },
  {
    label: 'Lead Date',
    name: 'leadDate',
    type: 'date',
  },
];

export const contactFormatter = (contact) => {
  if (!contact) {
    return '';
  }

  if (typeof contact === 'string' || contact instanceof String) {
    return `<${contact}>`;
  }

  if (!Array.isArray(contact)) {
    contact = [contact];
  }

  return contact.reduce((acc, curr) => {
    if (!curr.contactId) {
      return `${acc} ${curr.company.companyName}`;
    }

    const contactText = !curr.isAccountAffiliation
      ? ` (${curr?.company?.companyName || 'Personal'})`
      : '';

    return `${acc} ${curr.contactFullName} ${contactText}`.trim();
  }, '');
};

const dateFormatter = (value, valueDefinition = {}) => {
  if (value) {
    const dateObject = moment(value);

    if (dateObject?.isValid()) {
      const format = valueDefinition.allowTime ? 'MM/DD/YYYY hh:mm A' : 'MM/DD/YYYY';
      return dateObject.format(format);
    }
  }

  return value;
};

export const useImportProjectsColumns = () => {
  const dispatch = useAppDispatch();

  const [fieldsData, setFieldsData] = useState([]);
  const [projectRoles, setProjectRoles] = useState([]);
  const [customFields, setCustomFields] = useState([]);

  const fieldToCell = (field) => {
    const { _id: fieldName, name, valueDefinition } = field;
    const { type, hasDataSrc, isUrl } = valueDefinition;

    let fieldType = 'text';

    if (hasDataSrc) {
      fieldType = 'id';
    } else if (isUrl) {
      fieldType = 'url';
    }

    switch (type) {
      case 'date':
        fieldType = 'date';
        break;
      case 'boolean':
        fieldType = 'checkbox';
        break;
      case 'array':
        fieldType = 'array';
        break;
    }

    return {
      label: name,
      name: fieldName,
      type: fieldType,
      isCustom: true,
      valueDefinition,
    };
  };

  const [generalFields, locationFields, marketingFields, dealFields, additionalFields] = useMemo(() => {
    const generalFields = customFields
      .filter((field) => field.section === 'general')
      .map(fieldToCell);
    const locationFields = customFields
      .filter((field) => field.section === 'location')
      .map(fieldToCell);
    const marketingFields = customFields
      .filter((field) => field.section === 'marketing')
      .map(fieldToCell);
    const dealFields = customFields.filter((field) => field.section === 'deal').map(fieldToCell);
    const additionalFields = customFields
      .filter((field) => field.section === 'additional')
      .map(fieldToCell);

    return [generalFields, locationFields, marketingFields, dealFields, additionalFields];
  }, [customFields]);

  useEffect(() => {
    const roleFields = projectRoles.map(({ _id, name }) => ({
      label: name,
      name: _id,
      type: 'affiliation',
    }));

    const newFieldsData = [
      ...hiddenStaticFields,
      ...generalStaticFields,
      ...generalFields,
      ...locationStaticFields,
      ...locationFields,
      ...datesAndDollarsStaticFields,
      ...dealFields,
      ...marketingStaticFields,
      ...marketingFields,
      ...roleFields,
      ...additionalFields,
    ];

    setFieldsData(newFieldsData);
  }, [projectRoles, customFields]);

  useEffect(() => {
    dispatch(getProjectCustomFieldsValuesForm())
      .unwrap()
      .then((data) => {
        setCustomFields(data.filter((field) => !(field.valueDefinition)||(field.valueDefinition.type!=='divider') ));
      });

    dispatch(getProjectRoles())
      .unwrap()
      .then((data = []) => {
        setProjectRoles(data);
      });
  }, []);

  const getCellFormatter = useCallback(
    (field) => {
      const { type, name, valueDefinition, hidden } = field;
      const getOptionLabelById = (id) =>
        valueDefinition.options.find((opt) => opt.value === id)?.label || id;

      switch (type) {
        case 'affiliation':
          return cellFormatter(contactFormatter);
        case 'date':
          return cellFormatter((value) => dateFormatter(value, valueDefinition));
        case 'text':
          return cellFormatter();
        case 'checkbox':
          return cellFormatter((value) => <input type='checkbox' checked={value} />);
        case 'url':
          return cellFormatter((value) => value?.label || value);
        case 'id':
          switch (name) {
            case 'jobStatusCodes':
              return cellFormatter((value) => value?.jobStatusCodesName||'Unknown');
            default:
              return cellFormatter((id) => getOptionLabelById(id));
          }
        case 'array':
          return cellFormatter((value) => {
            if (Array.isArray(value)) {
              return value.map((id) => getOptionLabelById(id)).join(', ');
            }

            return value;
          });
      }
    },
    [customFields]
  );

  return [fieldsData, getCellFormatter];
};
