import { memo, useCallback, useState, useMemo } from 'react';
import { CustomFieldControl } from '../../shared/custom-fields-controls/form-controls/custom-field-control';
import { getProjectStagesOrder } from '../../shared/custom-fields-controls/constants';

export const ProjectCustomFieldsForSection = memo(
  ({ fields = [], projectStage, customDateFieldsRefs }: any) => {
    const [showMore, setShowMore] = useState<boolean>(false);

    const stagesOrder = getProjectStagesOrder();
    const projectStageIndex = useMemo(() => stagesOrder.indexOf(projectStage), [projectStage]);

    const getEarliestStageIndex = (stages: Array<string>) => {
      const stagesFiltered = stages.filter((stage) => stage !== 'all');
      if (stagesFiltered.length === 0) return 0;

      const stagesIndexes = stagesFiltered.map((stage) => stagesOrder.indexOf(stage));
      return Math.min(...stagesIndexes);
    };

    const belongToCurrentOrNextStage = useCallback(
      ({ stages }: { stages: Array<string> }) => {
        if (projectStage === 'service') return stages.includes('service');

        if(stages.length===1&&stages[0]==='service') return false;

        if (stages.includes('all')) {
          return true;
        }

        // if project stage is 'earlier' than earliest field stage - do not show or require field
        const fieldEarliestStageIndex = getEarliestStageIndex(stages);
        return fieldEarliestStageIndex <= projectStageIndex;
      },
      [projectStageIndex]
    );

    const shouldBeHidden = useCallback(
      (field: any) => {
        const { hideInitially, required, stages } = field;
        // if field  is required, never hide and require field
        if (required) return false;
        // if 'always in show more', never show field by default

       

        if (hideInitially) return true;

        //return false;
        
        // if project stage is specifically included in field stages - show field
        if (stages.includes('all') || stages.includes(projectStage)) return false;
        // hide field
        return false;
      },
      [projectStage]
    );

    const currStageFields = fields.filter(belongToCurrentOrNextStage);
    const hiddenFields = currStageFields.filter(shouldBeHidden);
    const notHiddenFields = currStageFields.filter((field: any) => !shouldBeHidden(field));

    const shouldShowMore = hiddenFields.length > 0;

    const renderList = useCallback(
      (fields: any[] = []) =>
        fields.map((field: any) => {
          const { _id, controlType, required } = field;

          return (
            <CustomFieldControl
              key={_id}
              required={required}
              fieldDefinition={field}
              ref={(el) =>
                el && controlType === 'DATE_INPUT' && customDateFieldsRefs?.current.push(el)
              }
            />
          );
        }),
      []
    );

    return (
      <>
        {renderList(notHiddenFields)}
        {shouldShowMore && (
          <a onClick={() => setShowMore((s) => !s)}>{!showMore ? 'Show More' : 'Hide'}</a>
        )}
        {showMore && renderList(hiddenFields)}
      </>
    );
  }
);
