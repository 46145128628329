import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { deleteContact, getEmployeeInvitationStatus } from '../../../modules/contacts';
import { selectGetEmployeeInvitationStatus } from '../../../modules/contacts/selectors';
import { useConfirm } from '../../../shared/confirmation-dialog';
import { useAppDispatch } from '../../../shared/hooks/use-app-dispatch';

type DeleteContactProps = {
  contactId: string;
};

export const DeleteContact = ({ contactId }: DeleteContactProps) => {
  const confirm = useConfirm();
  const dispatch = useAppDispatch();

  const { data } = useSelector(selectGetEmployeeInvitationStatus);

  const onDeleteClick = useCallback(async () => {
    const ok = await confirm({
      title: 'Delete Contact',
      question: 'Are you sure you want to delete this contact?',
    });

    if (!ok) return;

    await dispatch(deleteContact({ contactId })).unwrap();

    await dispatch(getEmployeeInvitationStatus({ contactId }));
  }, []);

  if (data.isContactDisabled) return null;

  return (
    <a className='link link-danger' onClick={onDeleteClick}>
      Delete Contact
    </a>
  );
};
