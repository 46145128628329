import { Radio, Space } from 'antd';
import { Controller, useFormContext } from 'react-hook-form';
import FormDatePicker from '../../../shared/form-controls/form-date-picker';

export const StartDateControl = () => {
  const form = useFormContext();

  const showBy = form.watch('showBy');

  const renderOptions = () => {
    switch (showBy) {
      case 'hours':
        return (
          <>
            <Radio value={'today'}>Today</Radio>
            <Radio value={'tomorrow'}>Tomorrow</Radio>
            <Radio value={'yesterday'}>Yesterday</Radio>
          </>
        );
      case 'days':
        return (
          <>
            <Radio value={'today'}>Today</Radio>
            <Radio value={'thisWeek'}>Beginning of this week</Radio>
            <Radio value={'nextWeek'}>Beginning of next week</Radio>
          </>
        );
      case 'weeks':
        return (
          <>
            <Radio value={'thisWeek'}>Beginning of this week</Radio>
            <Radio value={'thisMonth'}>Beginning of this month</Radio>
            <Radio value={'nextMonth'}>Beginning of next month</Radio>
          </>
        );
      case 'months':
        return (
          <>
            <Radio value={'thisMonth'}>Beginning of this month</Radio>
            <Radio value={'thisYear'}>Beginning of this year</Radio>
            <Radio value={'nextYear'}>Beginning of next year</Radio>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className='d-flex flex-column'>
      <span className='h6'>Starting date:</span>
      <Controller
        name='startDateOption'
        control={form.control}
        render={({ field }) => (
          <Radio.Group
            className='ms-3'
            value={field.value}
            onChange={(e) => field.onChange(e.target.value)}
          >
            <Space direction='vertical'>
              {renderOptions()}
              <Radio value={'other'}>
                <div className='d-flex gap-3'>
                  <span className={`${field.value === 'other' && 'mt-2'}`}>Other</span>
                  {field.value === 'other' && (
                    <FormDatePicker
                      label=''
                      //className='w-100'
                      name='startDate'
                      control={form.control}
                    />
                  )}
                </div>
              </Radio>
            </Space>
          </Radio.Group>
        )}
      />
    </div>
  );
};
