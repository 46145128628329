import * as actionTypes from '../actions/actionTypes';
const initState = {
  allContacts: [],
  allInternalContacts: [],
  message: '',
  alert: {},
  isfetched: false,
  isfetchedError: false,
  success: null,
  isContactList: false,
  lastFetched: null,
  lastFetchedInternal: null,
  wasUpdated: false,
};

export default (state = initState, action = {}) => {
  switch (action.type) {
    case actionTypes.GET_ALL_CONTACT_REQUEST:
      return {
        ...state,
        response: [],
        isContactList: false,
        wasUpdated: false,
        // message: '',
        alert: {},
        isStatus: false,
        isfetched: false,
      };
    case actionTypes.GET_ALL_CONTACT_SUCCESS_NOT_UPDATED:
      return {
        ...state,
        response: action.payload.response.result.data,
        count: action.payload.response.result.count,
        wasUpdated: action.payload.response.result.updateNeeded,
        //lastFetched: action.payload.response.result.lastFetched,
        //  response: action.payload.response.result,
        // message: '',
        isContactList: action.payload.response.result,
        isStatus: true,
        isfetched: true,
        alert: {},
      };
      case actionTypes.GET_ALL_CONTACT_SUCCESS:
        return {
          ...state,
          response: action.payload.response.result.data,
          count: action.payload.response.result.count,
          wasUpdated: action.payload.response.result.updateNeeded,
          allContacts: action.payload.response.result.data,
          lastFetched: action.payload.response.result.lastFetched,
          typeId: action.payload.response.result.typeId,
          //  response: action.payload.response.result,
          // message: '',
          isContactList: action.payload.response.result,
          isStatus: true,
          isfetched: true,
          alert: {},
        };
    case actionTypes.GET_ALL_INTERNAL_CONTACT_SUCCESS:
        return {
          ...state,
          response: action.payload.response.result.data,
          count: action.payload.response.result.count,
          wasUpdated: action.payload.response.result.updateNeeded,
          allInternalContacts: action.payload.response.result.data,
          lastFetchedInternal: action.payload.response.result.lastFetched,
          //  response: action.payload.response.result,
          // message: '',
          isContactList: action.payload.response.result,
          isStatus: true,
          isfetched: true,
          alert: {},
        };
    case actionTypes.GET_ALL_CONTACT_ERROR:
      return {
        ...state,
        response: [],
        message: action.payload.error,
        isStatus: false,
        isfetched: false,
        isContactList: false,
        alert: { message: action.payload.error, type: 'alert-danger' },
      };
   
    default:
      return state;
  }
};
