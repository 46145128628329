import { useCallback, useMemo } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { getAssignmentName } from '../../utils/report-helper';
import styles from './reassignment-modal.module.scss';

type ReassignmentModalProps = {
  changes: Array<any>;
  resources: Array<any>;
  roleName: string;
  isOpen: boolean;
  toggle: () => void;
  onAfterSave: (data: any) => Promise<void>;
};

export const ReassignmentModal = ({
  changes = [],
  resources = [],
  roleName = '',
  isOpen = false,
  toggle,
  onAfterSave,
}: ReassignmentModalProps) => {
  const unassignedLabel = 'Unassigned';
  const confirmationMessage = 'Are you sure you want to save changes?';

  const reassignments = useMemo(
    () =>
      changes.reduce((acc: any, curr: any) => {
        const { data: newData, originalData: prevData } = curr;
        const projectId = newData.projectId;
        const newAffiliationId = newData.parentId;
        const prevAffiliationId = prevData.parentId;

        if (!acc[projectId]) {
          acc[projectId] = { added: [], removed: [] };
        }

        if (newAffiliationId === 'unassigned') {
          acc[projectId].removed.push(prevAffiliationId);
        } else if (prevAffiliationId === 'unassigned') {
          acc[projectId].added.push(newAffiliationId);
        } else {
          acc[projectId].removed.push(prevAffiliationId);
          acc[projectId].added.push(newAffiliationId);
        }

        return acc;
      }, {}),
    [changes]
  );

  const reassignmentsRows = useMemo(() => {
    const assignGroups = resources.map((resource) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { children, ...rest } = resource;
      return rest;
    });

    const projects = resources.map((resource) => [...(resource.children || [])]).flat();

    return Object.entries(reassignments)
      .map((reassignment) => {
        const [projectId, changes] = reassignment;

        const projectData = projects.find((project) => project.projectId === projectId);

        if (!projectData) return null;

        const { added, removed } = changes as any;

        const addedTo = assignGroups.find((group) => group.contactAffiliationId === added[0]);
        const removedFrom = assignGroups.find((group) => group.contactAffiliationId === removed[0]);

        const { jobNumber, jobName } = projectData;
        const addedToName = addedTo ? getAssignmentName(addedTo) : unassignedLabel;
        const removedFromName = removedFrom ? getAssignmentName(removedFrom) : unassignedLabel;
        const key = `${projectId}-${addedTo?.contactAffiliationId}-${removedFrom?.contactAffiliationId}`;

        return (
          <tr key={key}>
            <td className={jobNumber ? '' : 'fst-italic'}>{jobNumber || 'N/A'}</td>
            <td>{jobName}</td>
            <td className={removedFrom ? '' : 'fst-italic'}>{removedFromName}</td>
            <td>
              <i className='fa-solid fa-arrow-right'></i>
            </td>
            <td className={addedTo ? '' : 'fst-italic'}>{addedToName}</td>
          </tr>
        );
      })
      .filter(Boolean);
  }, [reassignments, resources, changes]);

  const onSubmit = useCallback(() => {
    toggle();
    onAfterSave && onAfterSave(reassignments);
  }, [onAfterSave, reassignments]);

  return (
    <Modal backdrop='static' isOpen={isOpen} toggle={toggle} size={'lg'}>
      <ModalHeader toggle={toggle}>Confirm {roleName} reassignments</ModalHeader>
      <ModalBody className={styles.wrapper}>
        <span>{confirmationMessage}</span>
        <table className='table'>
          <thead>
            <tr>
              <th>Job Number</th>
              <th>Job Name</th>
              <th>Unassigned From</th>
              <th></th>
              <th>Assigned To</th>
            </tr>
          </thead>
          <tbody>{reassignmentsRows}</tbody>
        </table>
      </ModalBody>
      <ModalFooter>
        <button type='button' className='btn btn-primary' onClick={toggle}>
          Close
        </button>
        <button type='button' className='btn btn-primary ms-auto' onClick={onSubmit}>
          Save
        </button>
      </ModalFooter>
    </Modal>
  );
};
