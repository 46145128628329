import joi from 'joi';
import { joiAnyString } from '../../../utils/validation';

export const editJobStatusCodeSchema = joi.object({
  jobColorCode: joi.object({
    r: joi.number().required(),
    g: joi.number().required(),
    b: joi.number().required(),
    a: joi.number().required(),
  }),
  jobStatusCodesDefinition: joiAnyString().label('Definition'),
  jobStatusCodesCode: joi.allow(), // require if not disabled
  jobStatusCodesName: joi.allow(), // require if not disabled
});
