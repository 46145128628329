import * as actionTypes from './actionTypes';
import * as API from '../api/reasonCode';

export const createReasonCodeRequest = () => ({
  type: actionTypes.CREATE_REASON_CODE_REQUEST,
});

export const createReasonCodeSuccess = (response) => ({
  type: actionTypes.CREATE_REASON_CODE_SUCCESS,
  payload: {
    response,
  },
});

export const createReasonCodeError = (error) => ({
  type: actionTypes.CREATE_REASON_CODE_ERROR,
  payload: {
    error,
  },
});

export const createReasonCodeAction = (data) => {
  return (dispatch) => {
    dispatch(createReasonCodeRequest());
    return API.createReasonCode(data)
      .then((response) => {
        dispatch(createReasonCodeSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          createReasonCodeError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

export const getAllReasonCodeRequest = () => ({
  type: actionTypes.GET_ALL_REASON_CODE_REQUEST,
});

export const getAllReasonCodeSuccess = (response) => ({
  type: actionTypes.GET_ALL_REASON_CODE_SUCCESS,
  payload: {
    response,
  },
});

export const getAllReasonCodeError = (error) => ({
  type: actionTypes.GET_ALL_REASON_CODE_ERROR,
  payload: {
    error,
  },
});

export const getAllReasonCodeAction = (data) => {
  return (dispatch) => {
    dispatch(getAllReasonCodeRequest());
    return API.getAllReasonCode(data)
      .then((response) => {
        dispatch(getAllReasonCodeSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          getAllReasonCodeError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

export const updateReasonCodeRequest = () => ({
  type: actionTypes.UPDATE_REASON_CODE_REQUEST,
});

export const updateReasonCodeSuccess = (response) => ({
  type: actionTypes.UPDATE_REASON_CODE_SUCCESS,
  payload: {
    response,
  },
});

export const updateReasonCodeError = (error) => ({
  type: actionTypes.UPDATE_REASON_CODE_ERROR,
  payload: {
    error,
  },
});

export const updateReasonCodeAction = (data) => {
  return (dispatch) => {
    dispatch(updateReasonCodeRequest());
    return API.updateReasonCode(data)
      .then((response) => {
        dispatch(updateReasonCodeSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          updateReasonCodeError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

export const deleteReasonCodeRequest = () => ({
  type: actionTypes.DELETE_REASON_CODE_REQUEST,
});

export const deleteReasonCodeSuccess = (response) => ({
  type: actionTypes.DELETE_REASON_CODE_SUCCESS,
  payload: {
    response,
  },
});

export const deleteReasonCodeError = (error) => ({
  type: actionTypes.DELETE_REASON_CODE_ERROR,
  payload: {
    error,
  },
});

export const deleteReasonCodeAction = (data) => {
  return (dispatch) => {
    dispatch(deleteReasonCodeRequest());
    return API.deleteReasonCode(data)
      .then((response) => {
        dispatch(deleteReasonCodeSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          deleteReasonCodeError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

export const getAllReasonCodeByStatusCodeRequest = () => ({
  type: actionTypes.GET_ALL_REASON_CODE_BY_STATUS_CODE_REQUEST,
});

export const getAllReasonCodeByStatusCodeSuccess = (response) => ({
  type: actionTypes.GET_ALL_REASON_CODE_BY_STATUS_CODE_SUCCESS,
  payload: {
    response,
  },
});

export const getAllReasonCodeByStatusCodeError = (error) => ({
  type: actionTypes.GET_ALL_REASON_CODE_BY_STATUS_CODE_ERROR,
  payload: {
    error,
  },
});

export const getReasonCodesByStatusCodeAction = (data) => {
  return (dispatch) => {
    dispatch(getAllReasonCodeByStatusCodeRequest());
    return API.getReasonCodesByStatusCode(data)
      .then((response) => {
        dispatch(getAllReasonCodeByStatusCodeSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          getAllReasonCodeByStatusCodeError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};
