import React, { Suspense } from 'react';
import ReactLoading from 'react-loading';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { Tooltip } from 'react-tooltip';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { getAllJobStatusAction } from '../../../actions/jobStatusCode';
import { getAllProjectRoleAction } from '../../../actions/projectRole';
import { getExistingProjectAction } from '../../../actions/project';
import {
  getAllReasonCodeAction,
  getReasonCodesByStatusCodeAction,
} from '../../../actions/reasonCode';
import {
  GetDataCheckAction,
  GetDataCheckPrefAction,
  SetDataCheckPrefAction,
} from '../../../actions/reports';
import {
  formatExternalContactData,
  formatInternalContactData,
} from '../../../utils/contact-formatters';
import { formatBidDueDate, formatDate } from '../../../utils/date-formatters';
import { appConstants } from '../../../_constants';
import {useAuth} from '../../Auth/Auth';
import { customSortByKey, CustomTable, multiSelectFilter } from '../../Common/CustomTable';
import { SelectColumnFilter } from '../../Common/CustomTableComponents/SelectColumnFilter';

import { DateColumnFilter } from '../../Common/CustomTableComponents/DateColumnFilter';
import TitleComponent from '../../Common/TitleComponent';
import Footer from '../../Layout/Footer';
import Header from '../../Layout/Header';
import { matchDataForTable } from '../../../utils/match-for-table';
import { getAllProjectFieldsAction } from '../../../actions/projectFields';
import {buildColumnForField, buildDynamicColumns, onDate} from '../../../utils/column-formatters';

let selectedDate = '';
var sortingOrder = '';
var statusName = '';
var statusValue = '';
var fStatus = {};

class DataCheckReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {},
      accountId: localStorage.getItem('accountId'),
      userId: localStorage.getItem('userId'),
      list: [],
      selectBox: {},
      modal: false,
      fetchRequest: true,
      comLogo: '',
      totalCount: 0,
      currentPage: 1,
      sizePerPage: appConstants.TableSizePerPageList[2].value,
      searchText: '',
      OptionDataCheckStatus: [],
      projectStatus: false,
      projectObjectId: '',
      allJobStatusCodes: [],
      allProjectStatus: [],
      reasonCodes: [],
      filterStatus: false,
      sortType: 'DESC',
      sortKey: '',
      contactData: {},
      allJobStatusCodes: [],
      projectRoleTypes: [],
      allProjectFieldTypes: [],
      dynamicColumns: [],
      selectedDate:'',
    };

    this.onPageChange = this.onPageChange.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.toggle = this.toggle.bind(this);
    this.onExternalData = this.onExternalData.bind(this);
    this.onSortChange = this.onSortChange.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    //this.setState({list:[]});
    if (!nextProps.modalRequestStatus && this.state.projectStatus) {
      this.setState({
        projectStatus: false,
        project: [],
        projectObjectId: '',
      });
    }

    if (nextProps?.projectRoles?.allProjectRoleTypes) {
      this.setState({
        projectRoleTypes: nextProps.projectRoles.allProjectRoleTypes,
      });
    }


    if (nextProps?.projectFields?.allProjectFieldTypes?.length > 0) {
      this.setState({
        allProjectFieldTypes: nextProps.projectFields.allProjectFieldTypes,
      });
    }


    

    if (
      nextProps.listData &&
      nextProps.listData.success &&
      nextProps.listData.success === 1 &&
      !this.state.projectStatus
    ) {
      let fieldsToMatch = {
        projectAssignments: { lookupLocation: 'contactData' },
      };

      let matchedDataForTable = matchDataForTable(
        fieldsToMatch,
        nextProps.listData.data,
        nextProps.listData.contactData,
        this.props
      );

      this.setState({
        totalCount: nextProps.listData.count,
        list: matchedDataForTable,
        contactData: nextProps.listData.contactData,
        fetchRequest: false,
      });
    }

    if (nextProps.allJobStatusCodes) {
      this.setState({
        allJobStatusCodes: nextProps.allJobStatusCodes,
      });
    }
    if (nextProps.reasonCodes) {
      this.setState({
        reasonCodes: nextProps.reasonCodes,
      });
    }

    if (nextProps.allProjectStatus) {
      this.setState({
        allProjectStatus: nextProps.allProjectStatus,
      });
    }
   



    if (nextProps.listData && nextProps.listData.data && nextProps.listData.data.length == 0) {
      this.setState({ fetchRequest: false });
    }
  }

  componentDidMount() {
    this.props.getAllJobStatusAction({
      className: 'jobStatusCodes',
      accountId: this.state.accountId,
    });
    this.props.getAllReasonCodeAction({
      className: 'reasonCodes',
      accountId: this.state.accountId,
    });

    
    this.props.getAllProjectRoleAction({
      className: 'projectRoleTypes',
      accountId: this.state.accountId,
    });


    this.props.getAllProjectFieldsAction({
      accountId: this.state.accountId,
      minimumStage: 'post-construction',
    });

    sortingOrder = '';

    this.props.GetDataCheckPrefAction();
  }

  UNSAFE_componentWillMount() {
    this.toggle();
    let OptionDataCheckStatus = [];
    var formData = this.state.formData;
    appConstants.OptionDataCheckStatus.map((v, i) => {
      if (v.name !== '' && v.value !== '') {
        OptionDataCheckStatus.push({ value: v.value, label: v.name });
      }
      if (i === 0) {
        formData.status = { value: v.value, label: v.name };
      }
      this.setState({ OptionDataCheckStatus, formData: formData });
      return true;
    });

    if (
      localStorage.getItem('companyLogo') !== 'undefined' &&
      localStorage.getItem('companyLogo') !== null
    ) {
      this.setState({
        comLogo: localStorage.getItem('companyLogo'),
      });
    } else {
      let constImage = appConstants.CONTENT_CPOST_LOGO_URL;
      this.setState({ comLogo: constImage });
    }

    let _this = this;
    setTimeout(function () {
      _this.setState({ fetchRequest: false });
    }, 3000);
  }

  

  buildColumnForRole(filteredRoles, roleCode, hidden = false) {
    const foundRole = filteredRoles.find((role) => role.code == roleCode);
    if(!foundRole)
      return
    const isInternal = foundRole&& foundRole.type === 'internal';

    if (foundRole && isInternal) {
      return (foundRole &&
      {
        Header: foundRole?.shortName ?? roleCode,
          LongHeader: foundRole?.name,
        accessor: 'projectAssignmentsLabel' + roleCode,
        sortType: customSortByKey,
        sortKey: foundRole?.name ?? roleCode,
        Filter: SelectColumnFilter,
        filter: multiSelectFilter,
        width: 60,
        hidden: hidden,
        Cell: (props) => <>{this.formatContactDataInternal('projectAssignmentsMatched' + roleCode, props.row.original)}</>,
      })
    } else {
      return (foundRole &&
      {
        Header: foundRole?.shortName ?? roleCode,
          LongHeader: foundRole?.name,
        accessor: 'projectAssignmentsLabel' + roleCode,
        sortType: customSortByKey,
        sortKey: foundRole?.name ?? roleCode,
        Filter: SelectColumnFilter,
        filter: multiSelectFilter,
        width: 60,
        hidden: hidden,
        Cell: (props) => <>{this.formatContactDataExternal('projectAssignmentsMatched' + roleCode, props.row.original)}</>,
      })
    }
  }

  formatContactDataInternal(cell, row) {
    if (row[cell] && row[cell].length > 0) {
      return formatInternalContactData(row[cell], this.state.contactData);
    } else {
      return <span>-</span>;
    }
  }

  formatContactDataExternal(cell, row) {
    if (row[cell] && row[cell].length > 0) {
      return formatExternalContactData(row[cell], this.state.contactData,false);
    } else {
      return <span>-</span>;
    }
  }


  toggle(val) {
    this.setState({ modal: !this.state.modal });
  }

  filteredData(e) {
    e.preventDefault();
    this.setState({ fetchRequest: true });
    this.setState({ sizePerPage: appConstants.TableSizePerPageList[2].value });

    let formData = this.state.formData;

    statusName =
      this.state.formData.status && this.state.formData.status.label
        ? this.state.formData.status.label
        : '';
    statusValue =
      this.state.formData.status && this.state.formData.status.value
        ? this.state.formData.status.value
        : '';

    let data = {};
    data['accountId'] = this.state.accountId;
    data['moduleName'] = appConstants.reportModuleName['28'];
    data['type'] = this.state.formData.status.value;
    data['page'] = 0;
    data['displayLimit'] = this.state.sizePerPage;


    let selectedDate='';
    if (statusValue === 'bidding_passed_biddate') {
      selectedDate = 'Bid Due Date';
      data['sortKey'] = 'bidDue';
    } else if (statusValue === 'bidpending_passed_startdate') {
      selectedDate = 'Start Date';
      data['sortKey'] = 'startDate';
    } else if (statusValue === 'active_passed_enddate') {
      selectedDate = 'End Date';
      data['sortKey'] = 'endDate';
    } else if (statusValue === 'warranty_passed_warrantydate') {
      selectedDate = 'Warranty Exp. Date';
      data['sortKey'] = 'warrExpiration';
    }
    data['sortType'] = this.state.sortType;
    data['search'] = this.state.searchText;

    fStatus = {
      status: statusName,
    };

    this.setState({ sortKey: data['sortKey'], selectedDate });


    this.toggle();
    this.props.GetDataCheckAction(data);
  }

  onGetDate(cell, row, rowIndex) {
    if (row && typeof row[cell] === 'object' && cell === 'bidDue') {
      return row[cell] && row[cell].iso
          ? formatBidDueDate(row[cell].iso)
          : '';
    }
    else if (row && typeof row[cell] === 'object') {
      return row[cell] && row[cell].iso
          ? formatDate(row[cell].iso)
          : '';
    } else {
      return <span>-</span>;
    }
  }



  onExternalData(data) {
    return formatExternalContactData(data, this.state.contactData);
  }

  redirectToProject(cell, enumObject, row, rowIndex) {
    if (cell === 'jobNumFormatted' && row) {
      return (
        <div>
          <Link to={{ pathname: `/project-details/${row.objectId}`, state: { row } }}>
            {row.jobNumFormatted ? row.jobNumFormatted : '-'}
          </Link>
        </div>
      );
    }

    if (cell === 'jobName' && row) {
      return (
        <div>
          <strong>
            <Link
              to={{
                pathname: `/project-details/` + row.objectId,
                state: { row },
              }}
            >
              {row.jobName ? row.jobName : '-'}
            </Link>
          </strong>
        </div>
      );
    }

    if (cell === 'altJobNum' && row) {
      return (
        <div>
          <strong>
            <Link
              to={{
                pathname: `/project-details/` + row.objectId,
                state: { row },
              }}
            >
              {row.altJobNum ? row.altJobNum : '-'}
            </Link>
          </strong>
        </div>
      );
    }
  }

  createCustomButtonGroup = (props) => {
    return (
      <div className='pdfLogoBox'>
        <div className='reportHead'>
          <div className='logo'>
            <img src={this.state.comLogo} alt='Upload Logo' />
          </div>
          <h2>Data Check Report</h2>
          <div>
            Data Check Report:{' '}
            {this.state.formData.status && this.state.formData.status.label
              ? this.state.formData.status.label
              : ''}{' '}
          </div>
        </div>
      </div>
    );
  };

  handleChangeSelectBox(name, value) {
    let formData = this.state.formData;
    formData[name] = value;
    this.setState({ formData });
  }

  _setTableOption() {
    if (this.state.fetchRequest) {
      return (
        <ReactLoading
          className='table-loader'
          type={appConstants.LOADER_TYPE}
          color={appConstants.LOADER_COLOR}
          height={appConstants.LOADER_HEIGHT}
          width={appConstants.LOADER_WIDTH}
        />
      );
    } else {
      return 'No data found..';
    }
  }

  onPageChange(page, sizePerPage) {
    this.setState({ fetchRequest: true });
    let data = {};

    data['accountId'] = this.state.accountId;
    data['moduleName'] = appConstants.reportModuleName['28'];
    data['type'] = this.state.formData.status && this.state.formData.status.value;
    //
    // if (this.state.searchText === "") {
    //     data['page'] = (page === -1) ? 0 : page - 1;
    //     data['displayLimit'] = sizePerPage;
    // } else {
    data['page'] = page === -1 ? 0 : page - 1;
    data['displayLimit'] = sizePerPage;
    data['search'] = this.state.searchText;
    //}
    data['sortKey'] = this.state.sortKey;
    data['sortType'] = this.state.sortType;
    this.setState({ sizePerPage: sizePerPage });
    this.setState({ currentPage: page });
    this.props.GetDataCheckAction(data);
  }

  onSearchChange(text, sizePerPage) {
    this.setState({ fetchRequest: true });
    text = text ? text.trim() : '';
    //  if (text !== "") {
    // if (text.length >= 3) {
    let data = {};
    data['accountId'] = this.state.accountId;
    data['moduleName'] = appConstants.reportModuleName['28'];
    data['type'] = this.state.formData.status.value;
    data['page'] = 0;
    data['search'] = text;
    data['displayLimit'] = this.state.sizePerPage;
    data['sortKey'] = this.state.sortKey;
    data['sortType'] = this.state.sortType;
    this.setState({ sizePerPage: 50 });
    // }
    //}
    this.setState({ searchText: text });
    this.props.GetDataCheckAction(data);
  }

  onSortChange(sortName, sortOrder) {
    if (this.state && this.state.list && this.state.list.length > 0) {
      this.setState({ fetchRequest: true });
      let data = {};
      data['sortKey'] = sortName;
      if (sortName === 'jobNumFormatted') {
        data['sortKey'] = 'jobNum';
      } else if (sortName === appConstants.roleList.estimatorArr) {
        data['sortKey'] = appConstants.roleList.estimatorName;
      } else if (sortName === appConstants.roleList.accountExecArr) {
        data['sortKey'] = appConstants.roleList.accountExecName;
      } else if (sortName === appConstants.roleList.clientArr) {
        data['sortKey'] = appConstants.roleList.clientName;
      }

      this.setState({ sortKey: data['sortKey'] });

      if (sortOrder === 'asc') {
        data['sortType'] = 'ASC';
        sortingOrder = '-asc';
        this.setState({ sortType: 'ASC' });
      } else {
        data['sortType'] = 'DESC';
        sortingOrder = '-desc';
        this.setState({ sortType: 'DESC' });
      }
      data['accountId'] = this.state.accountId;
      data['search'] = this.state.searchText;
      data['page'] = this.state.currentPage - 1;
      data['moduleName'] = appConstants.reportModuleName['28'];
      data['type'] = this.state.formData.status.value;
      data['displayLimit'] = this.state.sizePerPage;
      this.props.GetDataCheckAction(data);
    }
  }


  

  getIdFromSelectedDate(selectedDate) {
    switch (selectedDate) {
      case 'Bid Due Date':
        return 'bidDue';
      case 'Start Date':
        return 'startDate';
      case 'End Date':
        return 'endDate';
      case 'Warranty Exp. Date':
        return 'warrExpiration';
      default:
        return 'bidDue';
    }
  }

  buildReportColumns() {
    //const hasContractValPermissions = isAllowed(moduleConstants.VIEWCONTRACTVAL, 'anyAssigned');
    //const hasProfitValPermissions = isAllowed(moduleConstants.VIEWPROFIT, 'anyAssigned');
    
    const {
      list, allProjectFieldTypes,fetchRequest
    } = this.state;
    
    const dynamicFieldsLoaded = allProjectFieldTypes?.length > 0;

    const defaultFieldCodes = [];

    const customFieldColumns = dynamicFieldsLoaded ? buildDynamicColumns(allProjectFieldTypes.filter(field => !defaultFieldCodes.includes(field.code))) : [];

    const defaultFields = dynamicFieldsLoaded ? allProjectFieldTypes.filter(field => defaultFieldCodes.includes(field.code)) : [];


    const isLeadType = false;

    const rolesLoaded = this.state.projectRoleTypes?.length > 0;

    const defaultRolesCodes = ['AX','EST', 'CL'];

    const dynamicColumnsLoaded = dynamicFieldsLoaded && rolesLoaded;


    const enabledRoles = this.state.projectRoleTypes.filter(
      (role) => !defaultRolesCodes.includes(role.code) && (!isLeadType || role.isShowOnLeads)
    );
    const defaultRoles = this.state.projectRoleTypes.filter((role) =>
      defaultRolesCodes.includes(role.code) && (!isLeadType || role.isShowOnLeads)
    );



    const keyForSelectedDate = this.getIdFromSelectedDate(selectedDate);

    const defaultColumns = [
      {
        Header: this.state.selectedDate || 'Date',
        accessor: (row) =>
          row?.[keyForSelectedDate]
            ? new Date(formatBidDueDate(row[keyForSelectedDate]?.iso))
            : new Date(formatDate('1800-03-03T12:47:40.000Z')),
        id: keyForSelectedDate,
        width: 125,
        Cell: (props) => <>{this.onGetDate(keyForSelectedDate, props.row.original, props.value)}</>,
        filter: DateColumnFilter,
        allowDateRangeFilter: true,
        sortType: 'datetime',
      },
      {
        Header: 'Job No.',
        accessor: 'jobNumFormatted',
        width: 110,
        Cell: (props) => this.redirectToProject(props.column.id, props.value, props.row.original),
      },
      {
        Header: 'Alt Job#',
        accessor: 'altJobNum',
        width: 120,
        Cell: (props) => this.redirectToProject(props.column.id, props.value, props.row.original),
      },
      {
        Header: 'Job Name',
        accessor: 'jobName',
        width: 360,
        Cell: (props) => this.redirectToProject(props.column.id, props.value, props.row.original),
      },
      this.buildColumnForRole(defaultRoles, 'AX'),
      this.buildColumnForRole(defaultRoles, 'EST'),
      this.buildColumnForRole(defaultRoles, 'CL'),
    ];

    
    let columns = [];
    if (enabledRoles) {
      const extraColumns = enabledRoles.map((role) => (
        this.buildColumnForRole([role], role.code,true)));

      if (columns.length == 0) {
        columns = defaultColumns.filter(cols => cols?.accessor);
      }

      for (let i in extraColumns) {
        const curColumnsAccessors = columns.map((cols) => cols.accessor);
        if (!curColumnsAccessors.includes(extraColumns[i].accessor)) {
          columns = columns.concat(extraColumns[i]);
        }
      }

      for (let i in customFieldColumns) {
        const curColumnsAccessors = columns.map((cols) => cols.accessor);
        if (!curColumnsAccessors.includes(customFieldColumns[i].accessor)) {
          columns = columns.concat(customFieldColumns[i]);
        }
      }
    } else {
      columns = defaultColumns.filter(cols => cols?.accessor);
    }

    this.setState({ dynamicColumns: columns });

  }

  
  render() {
  
    const {
      list, allProjectFieldTypes, dynamicColumns, fetchRequest
    } = this.state;


    const dynamicFieldsLoaded = allProjectFieldTypes?.length > 0;

    const rolesLoaded = this.state.projectRoleTypes?.length > 0;

    const dynamicColumnsLoaded = dynamicFieldsLoaded && rolesLoaded;

    
    const dynamicColumnsBuilt = dynamicColumnsLoaded && dynamicColumns.length > 0;

    if(dynamicColumnsLoaded && !dynamicColumnsBuilt){
      this.buildReportColumns();
    }
    

    const { listPref, SetDataCheckPrefAction } = this.props;

    return (
      <div>
        <div className='ContactListReport'>
          <TitleComponent data={{ title: `Data Check` }} />
          <div>
            <Suspense fallback='loading'>
              <Header props={this.props} />
            </Suspense>
          </div>

          <div className='contactBox'>
            <div className='container'>
              <div className='reportHead no-print'>
                <h2>Data Check Report</h2>
                <button className='btn rightalignmentBtn' onClick={() => this.toggle('POST')}>
                  <i className='fa fa-filter'></i> Apply Filters
                </button>
              </div>
              <div className='row searchbar no-print'>
                <div className='col-md-12'>
                  <div>{statusName ? statusName : ''} </div>
                </div>
              </div>
              <div className='row'>
              {dynamicColumnsBuilt && (<div className='col-md-12'>
                  <div
                    className='table-responsive reportTableSection'
                    ref={(el) => (this.componentRef = el)}
                  >
                    {this.createCustomButtonGroup()}
                    <CustomTable
                      columns={dynamicColumns}
                      list={list}
                      className='data-check-table'
                      customTableTitle=' '
                      canShowRowSelection={false}
                      preferences={listPref}
                      onSavePreferences={(pref) => SetDataCheckPrefAction(pref)}
                      isLoading={fetchRequest}
                      componentRef={this.componentRef}
                    />
                  </div>
                </div>)}
              </div>
            </div>
          </div>
          <Footer />
        </div>

        <Modal
          backdrop='static'
          keyboard
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggle}>Select Report</ModalHeader>
          <ModalBody>
            <div className='container'>
              <div className='row'>
                <div className='col-md-12'>
                  <div className='form-group'>
                    <span>
                      DataCheck reports will help identify missing or out of date information in
                      your job database. Select a report, then click on prepare report to see the
                      results.
                    </span>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-md-12'>
                  <div className='form-group'>
                    <label htmlFor='jobNumFormatted'>Select a report</label>
                    <Select
                      name='jobNumFormatted'
                      components={makeAnimated()}
                      value={this.state.formData.status}
                      options={this.state.OptionDataCheckStatus}
                      onChange={this.handleChangeSelectBox.bind(this, 'status')}
                    />
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button className='btn btn-primary' onClick={this.toggle}>
              Cancel
            </button>
            <button className='btn btn-primary' onClick={(e) => this.filteredData(e)}>
              Prepare Report
            </button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    listData: state.reports.dataCheckReportList,
    listPref: state.reports.dataCheckReportListPref,
    isReport: state.reports.isReport,
    isReportError: state.reports.isReportError,
    projectData: state.project.project,
    allJobStatusCodes: state.jobStatusCode.allJobStatus,
    allReasonCodeByStatusCode: state.reasonCode.allReasonCodeByStatusCode,
    allProjectStatus: state.jobStatusCode,
    changeOrder: state.changeOrder,
    reasonCodes: state.reasonCode,
    projectRoles: state.projectRoleType,
    projectFields: state.projectFields,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    GetDataCheckAction,
    GetDataCheckPrefAction,
    SetDataCheckPrefAction,
    getExistingProjectAction,
    getAllJobStatusAction,
    getAllReasonCodeAction,
    getReasonCodesByStatusCodeAction,
    getAllProjectRoleAction,
    getAllProjectFieldsAction
  })(DataCheckReport)
);
