import { useEffect, useRef, useState } from 'react';

const useBoundingRect = () => {
  const wrapperRef = useRef(null);
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    if (wrapperRef.current) {
      const rect = wrapperRef.current.getBoundingClientRect();
      setHeight(window.innerHeight - rect.top);
      setWidth(rect.width);
    }
  }, [wrapperRef.current]);

  useEffect(() => {
    const cb = (event) => {
      if (wrapperRef.current) {
        const rect = wrapperRef.current.getBoundingClientRect();
        setHeight(event.target.innerHeight - rect.top);
        setWidth(rect.width);
      }
    };
    window.addEventListener('resize', cb);

    return () => window.removeEventListener('resize', cb);
  }, []);

  return { wrapperRef, height, width };
};

export default useBoundingRect;
