import { GetEmployeesBody } from '@module/contacts';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { notification } from 'antd';
import {
  getExternalContactDropdownLabel,
  getInternalContactDropdownLabel,
} from '../../utils/contact-formatters';
import { handleUpload, setLoadResultReducer } from '../../utils/import-utils';
import { buildDefaultActionResult } from '../../utils/redux';
import {
  cancelEmployeeInvitationRequest,
  cancelVerificationEmailRequest,
  contactRolesDropdownRequest,
  deleteContactRequest,
  deleteEmployeeRequest,
  disableUserRequest,
  enableUserRequest,
  getAccountEmailRequest,
  getAllContactAffiliationsRequest,
  findContactAffiliationsRequest,
  getContactAffiliationsRequest,
  getContactRequest,
  getContactsRequest,
  getEmployeeInvitationEmailsRequest,
  getEmployeeInvitationStatusRequest,
  getEmployeesRequest,
  getEmployeesTotalRequest,
  getExternalContactsRequest,
  getInternalContactsRequest,
  getManageUserInfoRequest,
  getTeamActivityRequest,
  getNotificationActivityRequest,
  importContactsLoadRequest,
  convertRowToContactRequest,
  importContactsParseRequest,
  removeContactPictureRequest,
  resendEmployeeInvitationRequest,
  resendVerificationEmailRequest,
  saveContactAffiliationsRequest,
  saveContactRequest,
  setCalendarColorRequest,
  sendEmployeeInvitationRequest,
  sendPasswordResetRequest,
  sendUserInviteRequest,
  updateContactPictureRequest,
  updateEmployeeRoleRequest,
  userRolesDropdownRequest,
  viewContactsRequest,
  getContactProjectsRequest,
  getContactCustomFieldsValuesFormRequest,
  validateContactPhoneNumberRequest,
  validateContactEmailRequest
} from './api';

export const getInternalContactsDropdown = createAsyncThunk(
  'contacts/getInternalContactsDropdown',
  async () => {
    const rows = await getInternalContactsRequest();
    return rows.map((row) => ({
      ...row,
      value: row._id,
      label: getInternalContactDropdownLabel(row),
    }));
  }
);

export const getExternalContactsDropdown = createAsyncThunk(
  'contacts/getExternalContactsDropdown',
  async () => {
    const rows = await getExternalContactsRequest();
    return rows.map((row) => ({
      ...row,
      value: row._id,
      label: getExternalContactDropdownLabel(row),
    }));
  }
);

export const uploadCsvContacts = createAsyncThunk(
  'contacts/uploadCsvContacts',
  async (formData) => {
    const {
      data: { jsonRows, errors },
    } = await importContactsParseRequest(formData);

    return jsonRows.map((d, i) => {
      const rowErrors = errors[i];
      const errorsCount = Object.values(rowErrors).filter((error: any) => !error.isWarning).length;

      return {
        ...d,
        _loadResult: null,
        _rowIndex: i,
        _isAvailable: errorsCount === 0,
        _rowErrors: rowErrors,
      };
    });
  }
);

export const saveContact = createAsyncThunk(
  'contacts/saveContact',
  async ({ pictureFile, contactData }: any) => {
    const { data } = await saveContactRequest({ ...contactData });

    if (pictureFile) {
      await updateContactPictureRequest(pictureFile, data._id).catch(() => void 0);
    }

    return {
      _id: data._id,
      firstName: data.firstName,
      lastName: data.lastName,
      middleName: data.middleName,
      prefix: data.prefix,
      suffix: data.suffix,
      contactTitle: data.contactTitle,
      comments: data.comments,
      dateOfBirthday: data.dateOfBirthday,
    };
  }
);

export const saveContactAffiliations = createAsyncThunk(
  'contacts/saveContactAffiliations',
  async ({ affiliations = [], formerAffiliations = [], contactId }: any) => {
    const { data } = await saveContactAffiliationsRequest({
      contactId,
      affiliations: [...affiliations, ...formerAffiliations],
    });

    return data;
  }
);

export const updateContactPicture = createAsyncThunk(
  'contacts/updateContactPicture',
  async ({ contactId, pictureFile }: any) => {
    await updateContactPictureRequest(pictureFile, contactId);
  }
);

export const removeContactPicture = createAsyncThunk(
  'contacts/removeContactPicture',
  async (contactId: string) => {
    await removeContactPictureRequest(contactId);
  }
);

export const loadContacts = createAsyncThunk(
  'contacts/loadContacts',
  async ([rows, selected]: any, api) => {
    const skipProps = ['_isAvailable', '_loadResult', '_rowErrors', '_rowIndex'];
    const rowsProps: Array<string> = rows.map((row) => Object.keys(row)).flat();
    const fieldNames = Array.from(new Set(rowsProps)).filter(
      (prop) => skipProps.indexOf(prop) === -1
    );
    const [selectedResult, data] = await handleUpload(
      [...fieldNames],
      rows,
      selected,
      importContactsLoadRequest
    );

    api.dispatch(setLoadResult([selectedResult, rows] as any));

    return data;
  }
);

export const convertRowToContact = createAsyncThunk(
  'contacts/convertRowToContact',
  async (data: object) => {
    const result = await convertRowToContactRequest({ ...data });

    return result.data;
  }
);

export const viewContacts = createAsyncThunk(
  'contacts/viewContacts',
  async ({ limit, offset, sortingField, sortingOrder, searchValue }: any) => {
    const { data } = await viewContactsRequest({
      limit,
      offset,
      sortingField,
      sortingOrder,
      searchValue,
    });

    return data;
  }
);

export const contactRolesDropdown = createAsyncThunk('contacts/contactRolesDropdown', async () => {
  const roles = await contactRolesDropdownRequest();
  return roles.map((r) => ({ ...r, label: r.contactRoleName, value: r.objectId || r._id }));
});

export const userRolesDropdown = createAsyncThunk('contacts/userRolesDropdown', async () => {
  const roles = await userRolesDropdownRequest();
  return roles.map((r) => ({ ...r, label: r.roleName, value: r.objectId || r._id }));
});

export const resendVerificationEmail = createAsyncThunk(
  'contacts/resendVerificationEmail',
  async ({ contactID, linkedUserId }: any) => {
    return resendVerificationEmailRequest({ contactID, linkedUserId });
  }
);

export const sendUserInvite = createAsyncThunk(
  'contacts/sendUserInvite',
  async ({ objectId, email, userType }: any) => {
    return sendUserInviteRequest({ objectId, email, userType });
  }
);

export const cancelVerificationEmail = createAsyncThunk(
  'contacts/cancelVerificationEmail',
  async ({ contactID, linkedUserId }: any) => {
    return cancelVerificationEmailRequest({ contactID, linkedUserId });
  }
);

export const getContactAffiliations = createAsyncThunk(
  'contacts/getContactAffiliations',
  async ({ contactId }: any) => {
    const data = await getContactAffiliationsRequest({ contactId });

    const result = data?.map((item) => ({
      ...item,
    }));

    return result;
  }
);

export const getEmployees = createAsyncThunk(
  'contacts/getEmployees',
  async (body: GetEmployeesBody) => {
    const response = await getEmployeesRequest(body);

    return response?.data;
  }
);

export const getEmployeesTotal = createAsyncThunk(
  'contacts/getEmployeesTotal',
  async (body: { active: boolean; filter?: string }) => {
    const response = await getEmployeesTotalRequest(body);

    return response?.data;
  }
);

export const setCalendarColor = createAsyncThunk(
  'contacts/setCalendarColor',
  async ({ contactId, calendarColor }: { contactId: string; calendarColor: {r:number; g:number; b:number; a:number; } }) => {
    const res = await setCalendarColorRequest({ contactId, calendarColor });

    notification.success({ message: 'Calendar color set!' });

    return res;
  }
);

export const getEmployeeInvitationEmails = createAsyncThunk(
  'contacts/getEmployeeInvitations',
  async ({ contactId }: { contactId: string }) => {
    const emails = await getEmployeeInvitationEmailsRequest({ contactId });

    return emails;
  }
);

export const sendEmployeeInvitation = createAsyncThunk(
  'contacts/sendEmployeeInvitation',
  async ({ contactId, roleId, email }: { contactId: string; email: string; roleId: string }) => {
    const res = await sendEmployeeInvitationRequest({ contactId, roleId, email });

    notification.success({ message: 'Invitation sent successfully!' });

    return res;
  }
);

export const resendEmployeeInvitation = createAsyncThunk(
  'contacts/resendEmployeeInvitation',
  async ({ contactId }: { contactId: string }) => {
    const res = resendEmployeeInvitationRequest({ contactId });

    notification.success({ message: 'Invitation re-sent successfully!' });

    return res;
  }
);

export const cancelEmployeeInvitation = createAsyncThunk(
  'contacts/cancelEmployeeInvitation',
  async ({ contactId }: { contactId: string }) => {
    const res = cancelEmployeeInvitationRequest({ contactId });

    notification.success({ message: 'Invitation cancelled successfully!' });

    return res;
  }
);

export const getAccountEmail = createAsyncThunk('contacts/getAccountEmail', async () => {
  const { email } = await getAccountEmailRequest();

  return email;
});

export const getEmployeeInvitationStatus = createAsyncThunk(
  'contacts/getEmployeeInvitationStatus',
  async (arg: { contactId: string }) => {
    const { contactId } = arg;

    const {
      status,
      email,
      userId,
      roleId,
      disabled,
      isEmployee,
      isEmployeeDisabled,
      isContactDisabled,
    } = await getEmployeeInvitationStatusRequest({
      contactId,
    });

    return {
      status,
      email,
      userId,
      roleId,
      contactId,
      disabled,
      isEmployee,
      isEmployeeDisabled,
      isContactDisabled,
    };
  }
);

export const updateEmployeeRole = createAsyncThunk(
  'contacts/updateEmployeeRole',
  async (arg: { contactId: string; roleId: string }) => {
    const { contactId, roleId } = arg;

    const res = await updateEmployeeRoleRequest({ contactId, roleId });

    notification.success({ message: 'Role updated successfully!' });

    return res;
  }
);

export const sendPasswordReset = createAsyncThunk(
  'contacts/sendPasswordReset',
  async (arg: { contactId: string }) => {
    const { contactId } = arg;

    const res = await sendPasswordResetRequest({ contactId });

    notification.success({ message: 'Password reset email sent successfully!' });

    return res;
  }
);

export const disableUser = createAsyncThunk(
  'contacts/disableUser',
  async (arg: { contactId: string; isDisableAffiliation?: boolean }) => {
    const { contactId, isDisableAffiliation = false } = arg;

    const res = await disableUserRequest({ contactId, isDisableAffiliation });

    const message = isDisableAffiliation
      ? 'Employee removed successfully!'
      : 'User disabled successfully!';
    notification.success({ message });

    return res;
  }
);

export const enableUser = createAsyncThunk(
  'contacts/enableUser',
  async (arg: { contactId: string }) => {
    const { contactId } = arg;

    const res = await enableUserRequest({ contactId });

    notification.success({ message: 'User enabled successfully!' });

    return res;
  }
);

export const getContact = createAsyncThunk(
  'contacts/getContact',
  async (arg: { contactId: string }) => {
    const { contactId } = arg;

    const res = await getContactRequest({ contactId });

    return res;
  }
);

export const getContacts = createAsyncThunk(
  'contacts/getContacts',
  async (arg: { contactIds: Array<string> }) => {
    const { contactIds } = arg;

    const res = await getContactsRequest({ contactIds });

    return res;
  }
);

export const getManageUserInfo = createAsyncThunk(
  'contacts/getManageUserInfo',
  async (arg: { contactId: string }) => {
    const { contactId } = arg;

    const res = await getManageUserInfoRequest({ contactId });

    return res;
  }
);

export const deleteEmployee = createAsyncThunk(
  'contacts/deleteEmployee',
  async (arg: { contactId: string }) => {
    const { contactId } = arg;

    const res = await deleteEmployeeRequest({ contactId });

    notification.success({ message: 'Employee removed successfully!' });

    return res;
  }
);

export const deleteContact = createAsyncThunk(
  'contacts/deleteContact',
  async (arg: { contactId: string }) => {
    const { contactId } = arg;

    const res = await deleteContactRequest({ contactId });

    notification.success({ message: 'Contact deleted successfully!' });

    return res;
  }
);

export const getNotificationActivity = createAsyncThunk(
  'contacts/getNotificationActivity',
  async (arg: { count: number; page: number }) => {
    const { page, count } = arg;
    const res = await getNotificationActivityRequest({ page, count });

    return res;
  }
);

export const getTeamActivity = createAsyncThunk(
  'contacts/getTeamActivity',
  async (arg: { count: number; page: number }) => {
    const { page, count } = arg;
    const res = await getTeamActivityRequest({ page, count });

    return res;
  }
);

export const getAllContactAffiliations = createAsyncThunk(
  'contacts/getAllContactAffiliations',
  async () => {
    const res = await getAllContactAffiliationsRequest();

    return res;
  }
);

export const findContactAffiliations = createAsyncThunk(
  'contacts/findContactAffiliations',
  async (arg: {
    criteria: string;
    page: number;
    count: number;
    isAccount: boolean | null;
    includeDeleted: boolean | null;
    fields?: Array<string>;
    requiredFields?: Array<string>;
  }) => {
    const { criteria, page, count, isAccount, includeDeleted, fields, requiredFields } = arg;

    const res = await findContactAffiliationsRequest({
      criteria,
      page,
      count,
      isAccount,
      includeDeleted,
      fields,
      requiredFields,
    });

    return res;
  }
);

export const getContactProjects = createAsyncThunk(
  'contacts/getContactProjects',
  async (arg: { contactId: string }) => {
    const { contactId } = arg;

    const res = await getContactProjectsRequest({ contactId });

    return res;
  }
);

export const getContactCustomFieldsValuesForm = createAsyncThunk(
  'projects/getContactCustomFieldsValuesForm',
  async () => {
    const result = await getContactCustomFieldsValuesFormRequest();

    return result;
  }
);

export const validateContactPhoneNumber = createAsyncThunk(
  'contacts/validateContactPhoneNumber',
  async (arg: { phoneValue: any, contactId?: string }) => {
    const { phoneValue, contactId } = arg;

    const result = await validateContactPhoneNumberRequest({ phoneValue, contactId });

    return result;
  }
)

export const validateContactEmail = createAsyncThunk(
  'contacts/validateContactEmail',
  async (arg: { emailValue: any, contactId?: string }) => {
    const { emailValue, contactId } = arg;

    const result = await validateContactEmailRequest({ emailValue, contactId });

    return result;
  }
)

export const contactsSlice = createSlice({
  name: 'contacts',
  initialState: {
    importingContacts: {
      data: [],
      loading: false,
      error: null,
    },
    saveContact: {
      data: null,
      loading: false,
      error: null,
    },
    contactsLoad: {
      data: [],
      loading: false,
      error: null,
    },
    viewContacts: {
      loading: false,
      error: null,
      data: { total: 0, contacts: [] },
    },
    getInternalContactsDropdown: {
      loading: false,
      error: null,
      data: [],
    },
    getExternalContactsDropdown: {
      loading: false,
      error: null,
      data: [],
    },
    contactRolesDropdown: {
      loading: false,
      error: null,
      data: [],
    },
    userRolesDropdown: {
      loading: false,
      error: null,
      data: [],
    },
    resendVerificationEmail: {
      loading: false,
      error: null,
      data: null,
    },
    cancelVerificationEmail: {
      loading: false,
      error: null,
      data: null,
    },
    sendUserInvite: {
      loading: false,
      error: null,
      data: null,
    },
    getContactAffiliations: {
      loading: false,
      error: null,
      data: [],
    },
    saveContactAffiliations: {
      loading: false,
      error: null,
      data: [],
    },
    getEmployees: {
      loading: false,
      error: null,
      data: {},
    },
    getEmployeesTotal: {
      loading: false,
      error: null,
      data: {},
    },
    getEmployeeInvitationEmails: {
      loading: false,
      data: [],
      error: null,
    },
    sendEmployeeInvitation: {
      loading: false,
      data: null,
      error: null,
    },
    getAccountEmail: {
      loading: false,
      data: '',
      error: null,
    },
    getEmployeeInvitationStatus: {
      loading: false,
      data: {
        status: '',
        email: '',
        userId: '',
        roleId: '',
        contactId: '',
        disabled: false,
        isEmployee: false,
        isEmployeeDisabled: true,
        isContactDisabled: true,
        isAdmin: false,
      },
      error: null,
    },
    resendEmployeeInvitation: {
      loading: false,
      data: null,
      error: null,
    },
    cancelEmployeeInvitation: {
      loading: false,
      data: null,
      error: null,
    },
    updateEmployeeRole: {
      loading: false,
      data: null,
      error: null,
    },
    sendPasswordReset: {
      loading: false,
      data: null,
      error: null,
    },
    disableUser: {
      loading: false,
      data: null,
      error: null,
    },
    enableUser: {
      loading: false,
      data: null,
      error: null,
    },
    getManageUserInfo: {
      loading: false,
      data: null,
      error: null,
    },
    getContact: {
      loading: false,
      data: null,
      error: null,
    },
    deleteEmployee: {
      loading: false,
      data: null,
      error: null,
    },
    deleteContact: {
      loading: false,
      data: null,
      error: null,
    },
    getNotificationActivity: {
      loading: false,
      data: {
        notificationActivity: [],
        total: 0,
      },
      error: null,
    },
    getTeamActivity: {
      loading: false,
      data: {
        teamActivity: [],
        total: 0,
      },
      error: null,
    },
    getAllContactAffiliations: {
      loading: false,
      data: [],
      error: null,
    },
  },
  reducers: {
    setLoadResult: setLoadResultReducer('importingContacts'),
    clearSaveContact: (state) => {
      state.saveContact = {
        data: null,
        error: null,
        loading: false,
      };
    },
    clearGetContactAffiliations: (state) => {
      state.getContactAffiliations = {
        data: [],
        error: null,
        loading: false,
      };
    },
  },
  extraReducers: (builder) => {
    buildDefaultActionResult('importingContacts', uploadCsvContacts, builder);
    buildDefaultActionResult('saveContact', saveContact, builder);
    buildDefaultActionResult('contactsLoad', loadContacts, builder);
    buildDefaultActionResult('viewContacts', viewContacts, builder);
    buildDefaultActionResult('getInternalContactsDropdown', getInternalContactsDropdown, builder);
    buildDefaultActionResult('getExternalContactsDropdown', getExternalContactsDropdown, builder);
    buildDefaultActionResult('contactRolesDropdown', contactRolesDropdown, builder);
    buildDefaultActionResult('userRolesDropdown', userRolesDropdown, builder);
    buildDefaultActionResult('sendUserInvite', sendUserInvite, builder);
    buildDefaultActionResult('resendVerificationEmail', resendVerificationEmail, builder);
    buildDefaultActionResult('cancelVerificationEmail', cancelVerificationEmail, builder);
    buildDefaultActionResult('getContactAffiliations', getContactAffiliations, builder);
    buildDefaultActionResult('saveContactAffiliations', saveContactAffiliations, builder);
    buildDefaultActionResult('getEmployees', getEmployees, builder);
    buildDefaultActionResult('getEmployeesTotal', getEmployeesTotal, builder);
    buildDefaultActionResult('getEmployeeInvitationEmails', getEmployeeInvitationEmails, builder);
    buildDefaultActionResult('sendEmployeeInvitation', sendEmployeeInvitation, builder);
    buildDefaultActionResult('getAccountEmail', getAccountEmail, builder);
    buildDefaultActionResult('getEmployeeInvitationStatus', getEmployeeInvitationStatus, builder);
    buildDefaultActionResult('resendEmployeeInvitation', resendEmployeeInvitation, builder);
    buildDefaultActionResult('cancelEmployeeInvitation', cancelEmployeeInvitation, builder);
    buildDefaultActionResult('updateEmployeeRole', updateEmployeeRole, builder);
    buildDefaultActionResult('sendPasswordReset', sendPasswordReset, builder);
    buildDefaultActionResult('disableUser', disableUser, builder);
    buildDefaultActionResult('enableUser', enableUser, builder);
    buildDefaultActionResult('getManageUserInfo', getManageUserInfo, builder);
    buildDefaultActionResult('getContact', getContact, builder);
    buildDefaultActionResult('deleteEmployee', deleteEmployee, builder);
    buildDefaultActionResult('deleteContact', deleteContact, builder);
    buildDefaultActionResult('getTeamActivity', getTeamActivity, builder);
    buildDefaultActionResult('getNotificationActivity', getNotificationActivity, builder);
    buildDefaultActionResult('getAllContactAffiliations', getAllContactAffiliations, builder);
  },
});

export default contactsSlice.reducer;

export const { setLoadResult, clearSaveContact, clearGetContactAffiliations } =
  contactsSlice.actions;
