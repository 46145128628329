import * as actionTypes from './../Admin/actionTypes';
import * as API from '../../api/Admin/help';

export const getHelpDetailsRequest = () => ({
  type: actionTypes.GET_HELP_REQUEST,
});

export const getHelpDetailsSuccess = (response) => ({
  type: actionTypes.GET_HELP_SUCCESS,
  payload: {
    response,
  },
});

export const getHelpDetailsError = (error) => ({
  type: actionTypes.GET_HELP_ERROR,
  payload: {
    error,
  },
});

export const getHelpDetailsAction = (data) => {
  return (dispatch) => {
    dispatch(getHelpDetailsRequest());
    return API.getHelpDetailData(data)
      .then((response) => {
        dispatch(getHelpDetailsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          getHelpDetailsError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

// ***** create help ******
export const createHelpDetailsRequest = () => ({
  type: actionTypes.ADD_HELP_REQUEST,
});

export const createHelpDetailsSuccess = (response) => ({
  type: actionTypes.ADD_HELP_SUCCESS,
  payload: {
    response,
  },
});

export const createHelpDetailsError = (error) => ({
  type: actionTypes.ADD_HELP_ERROR,
  payload: {
    error,
  },
});

export const createHelpDetailsAction = (data) => {
  return (dispatch) => {
    dispatch(createHelpDetailsRequest());
    return API.createHelpDetailData(data)
      .then((response) => {
        dispatch(createHelpDetailsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          createHelpDetailsError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};


// ***** Update help ******
export const updateHelpDetailsRequest = () => ({
  type: actionTypes.UPDATE_HELP_REQUEST,
});

export const updateHelpDetailsSuccess = (response) => ({
  type: actionTypes.UPDATE_HELP_SUCCESS,
  payload: {
    response,
  },
});

export const updateHelpDetailsError = (error) => ({
  type: actionTypes.UPDATE_HELP_ERROR,
  payload: {
    error,
  },
});

export const updateHelpDetailsAction = (data) => {
  return (dispatch) => {
    dispatch(updateHelpDetailsRequest());
    return API.updateHelpDetailData(data)
      .then((response) => {
        dispatch(updateHelpDetailsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          updateHelpDetailsError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};

// ***** Delete help ******
export const deleteHelpDetailsRequest = () => ({
  type: actionTypes.DELETE_HELP_REQUEST,
});

export const deleteHelpDetailsSuccess = (response) => ({
  type: actionTypes.DELETE_HELP_SUCCESS,
  payload: {
    response,
  },
});

export const deleteHelpDetailsError = (error) => ({
  type: actionTypes.DELETE_HELP_ERROR,
  payload: {
    error,
  },
});

export const deleteHelpDetailsAction = (data) => {
  return (dispatch) => {
    dispatch(deleteHelpDetailsRequest());
    return API.deleteHelpDetailData(data)
      .then((response) => {
        dispatch(deleteHelpDetailsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          deleteHelpDetailsError(
            error.response !== undefined ? error.response.data.error : 'Internet Connection Error'
          )
        );
      });
  };
};
